import type { RouteRecordRaw, NavigationGuard, RouteParams, LocationQuery } from 'vue-router'

import { session } from '@/composables/useSession'
import { getAgentSelfReviewRoute, redirectToMessageView } from '@/utils/routes'
import { sidebarRoutes } from '@/utils/defineSidebarRoutes'
import { TICKET_PATH } from '@/composables/useTicketRouteParams'

const checkCalibrationEnabled: NavigationGuard = (_to, _from, next) =>
  session.workspace.settings.calibrationEnabled ? next() : next({ name: 'conversations' })

const getProps = (params: RouteParams, query: LocationQuery) => {
  const { sort, externalTicketId, singleTicket } = query

  return {
    sort,
    externalTicketId,
    createFilter: 'createFilter' in query,
    calibrationSessionId: params.calibrationSessionId,
    singleTicketMode: !!singleTicket,
  }
}

const defineCalibrationSidebar = () => import('@/modules/shared/TicketContent/CalibrationSidebar.vue')

export default (): RouteRecordRaw[] => [
  {
    path: '/conversations/' + TICKET_PATH,
    name: 'conversations',
    redirect: { name: 'conversations.review' },
    beforeEnter(to, from, next) {
      const route = getAgentSelfReviewRoute(to)
      if (route) {
        next(route)
      } else {
        redirectToMessageView('conversations')(to, from, next)
      }
    },
    component: () => import('./ConversationsIndexView.vue'),
    meta: {
      requiresPayment: true,
      includeInCommandPalette: true,
      requiresSufficientConversations: true,
    },
    props: ({ params, query }) => getProps(params, query),
    children: [
      ...sidebarRoutes('conversations', '/conversations'),
      {
        path: 'calibration/:calibrationSessionId?',
        name: 'conversations.calibration',
        beforeEnter: checkCalibrationEnabled,
        component: defineCalibrationSidebar,
        meta: { requiredFlag: 'calibrationPro' },
        props: ({ params }) => ({ calibrationSessionId: params.calibrationSessionId }),
      },
      {
        path: 'calibration/:calibrationSessionId?/csat',
        name: 'conversations.calibration.csat',
        beforeEnter: checkCalibrationEnabled,
        component: () => import('@/modules/shared/TicketContent/SurveySidebar.vue'),
        meta: { requiredFlag: 'calibrationPro' },
        props: ({ params }) => ({ calibrationSessionId: params.calibrationSessionId }),
      },
    ],
  },
  {
    path: '/conversations/' + TICKET_PATH,
    name: 'conversations',
    redirect: { name: 'conversations.review' },
    beforeEnter(to, from, next) {
      const route = getAgentSelfReviewRoute(to)
      if (route) {
        next(route)
      } else {
        redirectToMessageView('conversations')(to, from, next)
      }
    },
    component: () => import('./ConversationsIndexView.vue'),
    meta: {
      requiresPayment: true,
      includeInCommandPalette: true,
      requiresSufficientConversations: true,
    },
    props: ({ params, query }) => getProps(params, query),
    children: [
      ...sidebarRoutes('conversations', '/conversations'),
      {
        path: 'calibration/:calibrationSessionId?',
        name: 'conversations.calibration',
        beforeEnter: checkCalibrationEnabled,
        component: defineCalibrationSidebar,
        meta: { requiredFlag: 'calibrationPro' },
        props: ({ params }) => ({ calibrationSessionId: params.calibrationSessionId }),
      },
      {
        path: 'calibration/:calibrationSessionId?/csat',
        name: 'conversations.calibration.csat',
        beforeEnter: checkCalibrationEnabled,
        component: () => import('@/modules/shared/TicketContent/SurveySidebar.vue'),
        meta: { requiredFlag: 'calibrationPro' },
        props: ({ params }) => ({ calibrationSessionId: params.calibrationSessionId }),
      },
    ],
  },
]
