import { UiDropdownTypes } from '@klausapp/ui-kit'
import type { FilterBarFilter, MultipleModeFilterBarFilter, SortedDashboardFilter } from './types'

export function toSortedFilters(filters: FilterBarFilter[]): SortedDashboardFilter {
  return {
    filterbarFilters: filters.filter((f) => !f.isSidebarFilter),
    sidebarFilters: filters.filter((f) => f.isSidebarFilter),
  }
}

export function isMultipleModeFilter(filter: FilterBarFilter): filter is MultipleModeFilterBarFilter {
  return (filter as MultipleModeFilterBarFilter).mode === UiDropdownTypes.Modes.Multiple
}
