export function sortByParticipation(participantIds: (string | undefined)[]) {
  const frequency = [...new Set(participantIds)].reduce((freq, id) => {
    freq[id] = 0
    return freq
  }, {})

  participantIds.forEach((id) => {
    frequency[id] += 1
  })

  return participantIds
    .filter((id, idx) => participantIds.indexOf(id) === idx)
    .sort((idA, idB) => {
      // Sort equal participation frequency by most recent replier
      if (frequency[idA] === frequency[idB]) {
        const recentParticipants = participantIds.slice().reverse()
        return recentParticipants.indexOf(idA) - recentParticipants.indexOf(idB)
      }

      return frequency[idB] - frequency[idA]
    })
}
