import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { watch } from 'vue'
import { type AssignmentV2_Assignment } from '@/modules/workspace/views/assignments/types/assignments'
import i18n from '@/i18n'
import { toast } from '@/components/toast'
import {
  getAssignments,
  deleteAssignment,
  duplicateAssignment,
  toggleAssignmentStatus,
} from '@/modules/workspace/api/assignmentsV2'
import alertDialog from '@/components/alertDialog'
import type { Tippy } from '@/components/Tippy'

export default createSharedComposable((props) => {
  const {
    data,
    isPending: isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['assignments', props.workspaceId],
    queryFn: () => getAssignments({ workspaceId: props.workspaceId }),
    retry: false,
    enabled: !!props.workspaceId,
  })

  const onDuplicate = async (assignmentId: string, tippyRef: InstanceType<typeof Tippy>) => {
    tippyRef?.tip.hide()

    await duplicateAssignment({ assignmentId, workspaceId: props.workspaceId })

    refetch()
    toast({ status: 'success', message: i18n.t('settings.workspaces.assignments.duplicated') })
  }

  const onDelete = async (assignment: AssignmentV2_Assignment, tippyRef: InstanceType<typeof Tippy>) => {
    tippyRef?.tip.hide()

    const { isConfirmed } = await alertDialog(i18n.t('settings.workspaces.assignments.delete_dialog_body'), {
      title: i18n.t('settings.workspaces.assignments.delete_dialog_title', { assignmentName: assignment.name }),
      confirmButtonText: i18n.t('universal.delete'),
      redConfirmButton: true,
      allowHtml: true,
    })

    if (!isConfirmed) return

    await deleteAssignment({ workspaceId: props.workspaceId, assignmentId: assignment.id })

    refetch()
    toast({ status: 'success', message: i18n.t('settings.workspaces.assignments.deleted') })
  }

  const toggleStatus = async (assignmentId: string, tippyRef: InstanceType<typeof Tippy>) => {
    tippyRef?.tip.hide()

    await toggleAssignmentStatus({ assignmentId, workspaceId: props.workspaceId })

    refetch()
  }

  watch(
    () => isError,
    (showError) => showError && toast({ status: 'success', message: i18n.t('settings.workspaces.assignments.failed') }),
  )

  return {
    assignmentsList: data,
    deleteAssignment: onDelete,
    duplicateAssignment: onDuplicate,
    isLoading,
    toggleStatus,
  }
})
