import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_progress_graph = _resolveComponent("progress-graph")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "coaching-insights-progress-card",
    title: _ctx.$t('coaching.sessions.insights.progress_card.title'),
    "api-method": _ctx.getInsightsProgress,
    params: _ctx.params,
    "timestep-toggle": "",
    "hide-pin-button": "",
    "data-rows-prop": "iqs"
  }, {
    center: _withCtx(() => [
      _createVNode(_component_q_toggle, {
        modelValue: _ctx.role,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role) = $event)),
        options: _ctx.roleOptions,
        condensed: ""
      }, null, 8 /* PROPS */, ["modelValue", "options"])
    ]),
    rawData: _withCtx(({ data, timeStep }) => [
      data
        ? (_openBlock(), _createBlock(_component_progress_graph, {
            key: 0,
            data: _ctx.processData(data),
            "time-step": timeStep
          }, null, 8 /* PROPS */, ["data", "time-step"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "api-method", "params"]))
}