import { UiDropdownTypes } from '@klausapp/ui-kit'
import { hasWorkspaceRole } from '@/utils/roleUtils'

import GroupPicker from '../GroupPicker.vue'
import HashtagPicker from '../HashtagPicker.vue'
import TimePeriodPicker from '../TimePeriodPickerV2.vue'
import UserPicker from '../UserPickerV2.vue'
import WorkspacePicker from '../WorkspacePickerV2.vue'
import type { FilterBarFilter } from './types'
import { toSortedFilters } from './util'
import { filterKey } from './keys'

const filters: FilterBarFilter[] = [
  {
    component: TimePeriodPicker,
    key: filterKey.timePicker,
  },
  {
    component: WorkspacePicker,
    key: filterKey.workspacePicker,
    mode: UiDropdownTypes.Modes.Multiple,
  },
  {
    component: GroupPicker,
    key: filterKey.groupPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    hideFilter: hasWorkspaceRole('AGENT'),
  },
  {
    component: UserPicker,
    key: filterKey.userPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    hideFilter: hasWorkspaceRole('AGENT'),
  },
  {
    component: HashtagPicker,
    key: filterKey.hashtagPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    onlyDisputeHashtags: true,
  },
]

export default toSortedFilters(filters)
