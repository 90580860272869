import type { Feedback as FeedbackFromServices } from '@zendesk/zqa-services/reviews'
import type { ReviewStatus } from '@/types/review'

interface FeedbackBase extends Omit<FeedbackFromServices, 'tags' | 'thread' | 'reviewStatus'> {
  reviewStatus?: ReviewStatus
}

export interface CommentTag {
  tag: string
  userId?: number
}

export enum ReactionType {
  Comment = 'comments',
  Review = 'reviews',
}

export interface FeedbackComment extends Omit<FeedbackBase, 'id' | 'tags' | 'thread'> {
  id?: string
  tags: CommentTag[]
  thread: FeedbackComment[]
}

export interface Rating {
  categoryId: string
  rating: number
  cause?: string[]
  ratingMax: number
  weight: number
  critical: boolean
}

interface Review {
  isFinal?: boolean
}

export type Feedback = Review & FeedbackComment & FeedbackBase

export interface Comment {
  id?: string
  conversationId: string
  messageId?: string
  comment: string
  commentTags: CommentTag[]
}
