import { type JSONContent } from '@tiptap/vue-3'
import { type CommentTag } from '../../TicketContent/types/feedback'

export const extractMentionsAndHashtags = (obj: JSONContent | JSONContent[]) => {
  const array: JSONContent[] = Array.isArray(obj) ? obj : [obj]

  return array
    .filter(({ type }) => type !== 'codeBlock') // Disable suggestions inside code block
    .filter(({ marks }) => !(marks && marks.some(({ type }) => type === 'code'))) // Disable suggestions inside code
    .reduce<CommentTag[]>((commentTags, value) => {
      if (value.type && value.type === 'mention') {
        const found = commentTags.some((el) => {
          return String(el.userId) === String(value.attrs?.id)
        })
        if (!found) {
          commentTags.push(toCommentTag(value.attrs!))
        }
      } else if (value.type && value.type === 'hashtag') {
        const found = commentTags.some((el) => {
          return el.tag === `#${value.attrs?.label}`
        })
        if (!found) {
          commentTags.push(toCommentTag(value.attrs!))
        }
      } else if (value.content) {
        commentTags = commentTags.concat(extractMentionsAndHashtags(value.content))
      }
      return commentTags
    }, [])
}

const toCommentTag = (attrs: Record<string, any>): CommentTag => {
  if (attrs.id) {
    return {
      tag: `@${attrs.label}`,
      userId: attrs.id,
    }
  }

  return { tag: `#${attrs.label}` }
}
