import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    "model-value": _ctx.selectedTags,
    mode: "tags",
    multiline: "",
    label: "name",
    theme: _ctx.theme,
    placeholder: _ctx.placeholder || _ctx.$t('settings.survey.edit.sections.delivery.modal.placeholder'),
    "no-results-text": _ctx.$t('dashboard.sidebar.helpdesk_tags_empty'),
    "close-on-select": false,
    selectable: (option) => option.id,
    options: _ctx.tagOptions,
    class: _normalizeClass(_ctx.brandTheme && _ctx.$style.brandTheme),
    "filter-results": false,
    "open-direction": _ctx.openDirection,
    "onUpdate:modelValue": _ctx.tagsSelected,
    onSearchChange: _ctx.getTags
  }, {
    afterlist: _withCtx(() => [
      (_ctx.moreCount > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('universal.n_more', { n: _ctx.$n(_ctx.moreCount) }, _ctx.moreCount)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    tag: _withCtx(({ option }) => [
      _renderSlot(_ctx.$slots, "tag", { option: option }, () => [
        _createTextVNode(_toDisplayString(option.name), 1 /* TEXT */)
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["model-value", "theme", "placeholder", "no-results-text", "selectable", "options", "class", "open-direction", "onUpdate:modelValue", "onSearchChange"]))
}