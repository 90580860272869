import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, resolveDirective as _resolveDirective } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_action_editor = _resolveComponent("action-editor")!
  const _component_comment_preview = _resolveComponent("comment-preview")!
  const _component_check_icon = _resolveComponent("check-icon")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass([_ctx.$style.action, _ctx.$style.removable]),
    onClick: _cache[4] || (_cache[4] = (e) => _ctx.clickActionEditor(e))
  }, [
    _createVNode(_component_ui_checkbox, {
      checked: _ctx.action.completed,
      class: "w-7",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle', $event)))
    }, null, 8 /* PROPS */, ["checked"]),
    (_ctx.action.editable)
      ? (_openBlock(), _createBlock(_component_action_editor, {
          key: 0,
          ref: "editor",
          value: _ctx.action.title,
          class: _normalizeClass(["text-sm", _ctx.$style.actionInput]),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', $event, _ctx.idx))),
          onEnter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('enter')))
        }, null, 8 /* PROPS */, ["value", "class"]))
      : (_openBlock(), _createBlock(_component_comment_preview, {
          key: 1,
          comment: _ctx.sanitizedComment,
          title: _ctx.cleanComment,
          class: _normalizeClass([_ctx.$style.actionLabel, !_ctx.action.editable && _ctx.$style.disabledPreview])
        }, null, 8 /* PROPS */, ["comment", "title", "class"])),
    (!_ctx.isCreatedByCurrentUser(_ctx.action))
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["text-sm", _ctx.$style.createdBy])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.dot)
          }, "·", 2 /* CLASS */),
          _createTextVNode(" " + _toDisplayString(_ctx.getCreatedBy(_ctx.action)), 1 /* TEXT */)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_transition_fade, {
      appear: "",
      mode: "out-in",
      duration: 300
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_check_icon, { class: "icon-s mr-3" }, null, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.action.showSavedIndicator]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.action.editable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          tabindex: "-1",
          type: "button",
          class: _normalizeClass(_ctx.$style.removeAction),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('remove', _ctx.action.id, _ctx.idx)))
        }, [
          _createVNode(_component_x_icon, { class: "icon-s" })
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */)), [
    [_directive_tippy, {
      content: _ctx.action.completed ? _ctx.tooltipContent : _ctx.cleanComment,
      target: _ctx.action.editable ? '.ProseMirror' : 'p',
      delay: _ctx.delay,
    }]
  ])
}