import { CsatOwnerSetting, DisplayDateSetting } from '@/modules/dashboard/csat/types'

export interface ViewSettings {
  dashboard: {
    cardsVisible: {
      overview: boolean
      workspaces: boolean
      users: boolean
      scoresByCategory: boolean
      scoresOverTime: boolean
      categoryScoresOverTime: boolean
      scoresByReviews: boolean
    }
  }
  csatDashboard: {
    csatOwnerSetting: CsatOwnerSetting
    displayDateSetting: DisplayDateSetting
    cardsVisible: {
      csat: boolean
      surveyFunnel: boolean
      csatOverTime: boolean
      csatTrendsBy: boolean
      tags: boolean
      wordCloud: boolean
      csatByUsers: boolean
      csatBy: boolean
      surveyReplies: boolean
    }
  }
}

export const defaultViewSettings: ViewSettings = {
  dashboard: {
    cardsVisible: {
      overview: true,
      workspaces: true,
      users: true,
      scoresByCategory: true,
      scoresOverTime: true,
      categoryScoresOverTime: true,
      scoresByReviews: true,
    },
  },
  csatDashboard: {
    csatOwnerSetting: CsatOwnerSetting.ticketAssignee,
    displayDateSetting: DisplayDateSetting.ticketCreated,
    cardsVisible: {
      csat: true,
      surveyFunnel: true,
      csatOverTime: true,
      csatTrendsBy: true,
      tags: true,
      wordCloud: true,
      csatByUsers: true,
      csatBy: true,
      surveyReplies: true,
    },
  },
}
