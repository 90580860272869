import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/coaching/sessions/:id',
    name: 'coaching.session',
    component: () => import('./SessionView.vue'),
    meta: { requiresPayment: true, requiredFlag: 'coaching', requiresSufficientConversations: true },
    redirect: (to) => ({ name: 'coaching.session.metrics', params: to.params }),
    props: ({ query, params }) => ({ ...params, query }),
    children: [
      {
        path: 'metrics',
        name: 'coaching.session.metrics',
        component: () => import('./components/sidebar/SessionMetrics.vue'),
      },
      {
        path: 'pins',
        name: 'coaching.session.pins',
        component: () => import('./components/sidebar/SessionPins.vue'),
      },
      {
        path: 'feedback',
        name: 'coaching.session.feedback',
        component: () => import('./components/sidebar/SessionFeedback.vue'),
      },
    ],
  },
  {
    path: '/coaching/sessions',
    name: 'coaching.sessions',
    component: () => import('./insights/InsightsView.vue'),
    meta: {
      requiresPayment: true,
      requiredFlag: 'coaching',
      includeInCommandPalette: true,
      requiresSufficientConversations: true,
    },
  },
  {
    path: '/coaching',
    redirect: '/coaching/sessions',
  },
]

export default routes
