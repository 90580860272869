import imagePlaceholder from './imagePlaceholder'

export default function (img) {
  const loaded = img.complete && img.naturalHeight !== 0
  if (loaded) return

  const loader = new Image()
  const src = img.src
  loader.src = src
  img.classList.add('loading')
  img.style.opacity = '0'

  loader.onload = () => {
    clearInterval(poll)
    img.src = src

    img.onload = () => {
      img.classList.remove('loading')
      img.style.objectFit = ''
      img.style.aspectRatio = ''
      img.style.opacity = '1'
    }
  }

  const poll = setInterval(() => {
    if (loader.naturalWidth) {
      clearInterval(poll)

      img.src = imagePlaceholder

      img.onload = () => {
        img.style.opacity = '1'
        img.width = loader.naturalWidth
        img.height = loader.naturalHeight
        img.style.aspectRatio = `${loader.naturalWidth / loader.naturalHeight}`
        img.style.objectFit = 'none'
      }
    }
  }, 33)
}
