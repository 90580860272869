import { AutoInsightType } from '@zendesk/zqa-services/tickets'
import useReviewSidebar from '@/composables/useReviewSidebar'
import useMessageHighlighting from '@/composables/useMessageHighlighting'

export default function () {
  const { state: sidebarState } = useReviewSidebar()
  const { visibleRepetitionIconId, showIconMap } = useMessageHighlighting()

  function empathyIconVisible(commentExternalId: string) {
    return showIconMap.showEmpathyIcon && !!sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.empathy
  }

  function greetingIconVisible(commentExternalId: string) {
    return showIconMap.showGreetingIcon && !!sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.greeting
  }

  function closingIconVisible(commentExternalId: string) {
    return showIconMap.showClosingIcon && !!sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.closing
  }

  function readabilityIconVisible(commentExternalId: string) {
    return (
      showIconMap.showReadabilityLongSentencesIcon &&
      Number(sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.longSentencesCount) !== 0
    )
  }

  function repetitionIconVisible(commentExternalId: string) {
    return (
      visibleRepetitionIconId.value &&
      !!sidebarState.ticket.autoInsights
        .find((i) => i.type === AutoInsightType.BOT_REPETITION)
        ?.metadata?.botRepetitionMessageIds.includes(commentExternalId)
    )
  }

  return {
    empathyIconVisible,
    greetingIconVisible,
    closingIconVisible,
    readabilityIconVisible,
    repetitionIconVisible,
  }
}
