import { ref, computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { getCalibrationSessions as apiGetCalibrationSessions, type CalibrationSession } from '@/api/calibration'
import { session } from '@/composables/useSession'
import { hasWorkspaceRole, hasAccountRole } from '@/utils/roleUtils'
import useTicketRouteParams from '@/composables/useTicketRouteParams'

const defaultSession = {
  title: '',
  dueAt: '',
  createdBy: null,
  createdAt: '',
  updatedBy: null,
  updatedAt: '',
}

export default createSharedComposable(() => {
  const { calibrationSessionId } = useTicketRouteParams()
  const calibrationSessions = ref<CalibrationSession[]>([])
  const calibrating = computed(() => !!calibrationSessionId.value)

  const canSeeCalibrations = computed(() => {
    const hasRequiredRole =
      hasWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER') ||
      (!session.workspace?.role && hasAccountRole('ADMIN', 'MANAGER'))
    const featureEnabled = session.features.calibrationPro
    const settingEnabled = session.workspace.settings.calibrationEnabled

    return hasRequiredRole && featureEnabled && settingEnabled
  })

  const getCalibrationSessions = async (): Promise<CalibrationSession[]> => {
    if (!canSeeCalibrations.value) return []

    const { data } = await apiGetCalibrationSessions(session.workspace.id)
    calibrationSessions.value = data
    return calibrationSessions.value
  }

  const calibrationSessionDetails = computed(
    () =>
      calibrationSessions.value.find(({ sessionId }) => sessionId.toString() === calibrationSessionId.value) ||
      defaultSession,
  )

  return {
    calibrating,
    canSeeCalibrations,
    calibrationSessions,
    calibrationSessionDetails,
    getCalibrationSessions,
  }
})
