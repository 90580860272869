import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "login w-full" }
const _hoisted_2 = {
  key: 0,
  class: "login__header"
}
const _hoisted_3 = { class: "login__title text-gray-80 font-bold" }
const _hoisted_4 = { class: "login__header-cta text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ui_alert = _resolveComponent("ui-alert")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.title || _ctx.$slots['header-cta'])
      ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "header-cta")
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.fullWidth ? 'login__fluid-content' : 'login__content')
    }, [
      (_ctx.directInstallName)
        ? (_openBlock(), _createBlock(_component_ui_alert, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_i18n_t, {
                keypath: "auth.complete_setup",
                tag: "div"
              }, {
                direct_install_name: _withCtx(() => [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.directInstallName), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "content")
    ], 2 /* CLASS */)
  ]))
}