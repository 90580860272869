import pMemoize from 'p-memoize'
import ExpiryMap from 'expiry-map'

import { oldApi } from '@/api'
import { session } from '@/composables/useSession'
import type { ConstructedFilterOption, CustomCategoryFilterOption } from '../utils/filters'

const getRequestPrefix = () => `payments/${session.account.id}/teams/${session.workspace.id}`

export interface FilterValue {
  label: string
  value: string
  type?: string
}

interface FilterOption {
  option: string
  condition: string
  type: string
  values: FilterValue[]
  externalFieldTicketId?: string
  ticketFieldName?: string
  optionId: number
  ticketFieldId: string
  externalTicketFieldId?: string
}

export interface BaseFilter {
  isPublic: boolean
  name: string
  created: string
  filterId: number
  myFilter: boolean
  optionCount: number
  ownerId: number
  ownerName: string
  position: number
  updateByName: string
  updated: string
  updatedById: number
}
export interface FilterOptions extends BaseFilter {
  options: (Partial<FilterOption> | ConstructedFilterOption)[]
  autoQaOptions: (Partial<FilterOption> | ConstructedFilterOption)[]
  spotlightOptions: (Partial<FilterOption> | ConstructedFilterOption)[]
  customCategoryOptions: (Partial<FilterOption> | ConstructedFilterOption)[]
}

export interface DefaultFilterOption {
  allowedMultiple: boolean
  conditions: {
    condition: string
    labelCode: string
    valueRequired: boolean
  }[]
  filterOption: string
  labelCode: string
  name?: string
  id?: string
  customPhrases?: string[]
  customSpotlightId?: string
  positiveCustomPhrases?: string[]
  negativeCustomPhrases?: string[]
  options?: CustomCategoryFilterOption[]
  validSourceType: string[]
}

export interface Options {
  defaultFilterOptions: DefaultFilterOption[]
  autoQaFilterOptions: DefaultFilterOption[]
  spotlightFilterOptions: DefaultFilterOption[]
  customCategoryFilterOptions: DefaultFilterOption[]
}

export const createFilter = (data: Partial<FilterOptions>) =>
  oldApi.post(`${getRequestPrefix()}/filters`, { json: data }).json<{ data: number }>()

export const editFilter = (payload: { id: number; data: Partial<FilterOptions> }) =>
  oldApi.put(`${getRequestPrefix()}/filters/${payload.id}`, { json: payload.data }).json<{ data: number }>()

export const deleteFilter = (id: number) => oldApi.delete(`${getRequestPrefix()}/filters/${id}`).json()

export const cloneFilter = (id: number) =>
  oldApi.put(`${getRequestPrefix()}/filters/${id}/duplicate`, { json: {} }).json<{ data: number }>()

export const setPublicFilterPosition = (payload: { id: number; position: number }) =>
  oldApi.put(`${getRequestPrefix()}/public-filters/${payload.id}/position/${payload.position}`)

export const setPrivateFilterPosition = (payload: { id: number; position: number }) =>
  oldApi.put(`${getRequestPrefix()}/private-filters/${payload.id}/position/${payload.position}`)

export const getFilterOptions = () => oldApi.get(`${getRequestPrefix()}/filter-options`).json<{ data: Options }>()

export const getFilterOptionValues = (opt: string, type = 'default') =>
  oldApi.get(`${getRequestPrefix()}/filter-options/${type}/${opt}`).json<{ data: any }>()

export const getFilterDetails = (id: number, validateConditions = true) =>
  oldApi
    .get(`${getRequestPrefix()}/filters/${id}`, { searchParams: { validateConditions } })
    .json<{ data: FilterOptions }>()

export const getPublicFilters = () =>
  oldApi.get(`${getRequestPrefix()}/public-filters`).json<{ data: BaseFilter[]; total: number }>()

export const getPrivateFilters = () =>
  oldApi.get(`${getRequestPrefix()}/private-filters`).json<{ data: BaseFilter[]; total: number }>()

const getFilterTicketCount = (data: Partial<FilterOptions>) =>
  oldApi
    .post(`${getRequestPrefix()}/tickets/filter-count/v2`, {
      json: data,
    })
    .json<{ data: number }>()

export const memGetFilterTicketCount = pMemoize(getFilterTicketCount, {
  cache: new ExpiryMap(5 * 60 * 1000),
  cacheKey: JSON.stringify,
})
