import qs from 'query-string'
import type {
  AgentDistributionCategory,
  AgentStatisticalDistribution,
  TicketListItemExt,
  StatisticalDistributionPeriod,
  TicketDistributionCategory,
  TicketStatisticalDistribution,
} from '@/modules/conversations/types'
import { api, oldApi } from '@/api'
import { session } from '@/composables/useSession'

import { FilterTimePeriod } from '@/modules/dashboard/types'
import type { FilterOptions } from './filters'

const getRequestPrefix = () => `payments/${session.account.id}/teams/${session.workspace.id}`

interface TicketQuery {
  filterId: number
  sort: string
  limit: number
  offset: number
}

export interface GetTicketsResponse {
  data: TicketListItemExt[]
  total: number
}

export let getTicketsController: AbortController
export const getTickets = (data: TicketQuery) => {
  getTicketsController = new AbortController()
  const { signal } = getTicketsController

  // TS doesn't like that TicketQuery values can be numbers
  const query = new URLSearchParams(data as any)
  const path = `${getRequestPrefix()}/tickets/v2`
  return oldApi.get(`${path}?${query.toString()}`, { signal, timeout: 120 * 1000 }).json<GetTicketsResponse>()
}

const getTicketsWithDynamicFilter = (payload: { query: string; data: Partial<FilterOptions>; signal?: AbortSignal }) =>
  oldApi
    .post(`${getRequestPrefix()}/tickets/filter/v2?${payload.query}`, {
      json: payload.data,
      signal: payload.signal,
    })
    .json<GetTicketsResponse>()

const getTicketsCountWithDynamicFilter = (payload: { query: string; data: Partial<FilterOptions> }) =>
  oldApi
    .post(`${getRequestPrefix()}/tickets/filter-count/v2?${payload.query}`, { json: payload.data })
    .json<{ data: number }>()

export const findTicketIdByCustomField = (field: string) =>
  api
    .get(`ticket/externalFieldValueSearch?fieldValue=${field}`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json<{ data: { connectionId: number; externalId: string }[] }>()

export const searchTicketsByExternalId = (ids: string[]) => {
  const data = {
    isPublic: true,
    name: '_dynamic_ticket_search',
    options: [
      {
        type: 'DEFAULT',
        option: 'TICKET_EXTERNAL_ID',
        condition: 'STRING_LIST_EQUALS',
        values: ids.map((value) => ({ value, label: null })),
      },
    ],
  }
  return getTicketsWithDynamicFilter({ query: '', data })
}

const getTimeConditions = ({
  timePeriod,
  fromDate,
  toDate,
}: {
  timePeriod?: string
  fromDate?: string
  toDate?: string
}) => {
  const timePeriodMap = {
    [FilterTimePeriod.ThisWeek]: 'DATE_THIS_WEEK',
    [FilterTimePeriod.LastWeek]: 'DATE_LAST_WEEK',
    [FilterTimePeriod.Week]: 'DATE_LAST_7_DAYS',
    [FilterTimePeriod.ThisMonth]: 'DATE_THIS_MONTH',
    [FilterTimePeriod.LastMonth]: 'DATE_LAST_MONTH',
    [FilterTimePeriod.Month]: 'DATE_30_DAYS',
    [FilterTimePeriod.Custom]: 'DATE_FROM/DATE_TO',
  }

  if (!timePeriod) return []

  if (timePeriod === FilterTimePeriod.Custom)
    return [
      {
        condition: 'DATE_FROM',
        type: 'DEFAULT',
        option: 'TICKET_CREATED_DATE',
        values: [{ value: fromDate, label: fromDate }],
      },
      {
        condition: 'DATE_TO',
        type: 'DEFAULT',
        option: 'TICKET_CREATED_DATE',
        values: [{ value: toDate, label: toDate }],
      },
    ]

  return [
    {
      condition: timePeriodMap[timePeriod],
      type: 'DEFAULT',
      option: 'TICKET_CREATED_DATE',
    },
  ]
}

export const getTicketsByRelatedUser = (payload: {
  userId?: number
  timePeriod?: FilterTimePeriod
  fromDate?: string
  toDate?: string
  params: TicketQuery
  activeFilterId?: number
}) => {
  getTicketsController = new AbortController()
  const { signal } = getTicketsController
  // TS doesn't like that TicketQuery values can be numbers
  const query = new URLSearchParams(payload.params as any)

  return getTicketsWithDynamicFilter({
    query: query.toString(),
    signal,
    data: {
      isPublic: true,
      name: 'placeholder',
      options: [
        ...(payload.userId
          ? [
              {
                condition: 'LONG_LIST_EQUALS',
                type: 'DEFAULT',
                option: 'RELATED',
                values: [
                  {
                    label: 'placeholder',
                    value: String(payload.userId),
                  },
                ],
              },
            ]
          : []),
        ...getTimeConditions(payload),
      ],
    },
  })
}

export const getTicketsCount = (filterId: number): Promise<number> => {
  const signal = getTicketsController?.signal

  return oldApi
    .post(getRequestPrefix() + '/tickets/filter-count/v2', {
      searchParams: `filterId=${filterId}`,
      signal,
    })
    .json<{ data: number }>()
    .then((r) => r.data)
}

export const getTicketsCountByRelatedUser = (payload: {
  userId?: number
  timePeriod?: FilterTimePeriod
  fromDate?: string
  toDate?: string
  params: TicketQuery
}) => {
  // TS doesn't like that TicketQuery values can be numbers
  const query = new URLSearchParams(payload.params as any)

  return getTicketsCountWithDynamicFilter({
    query: query.toString(),
    data: {
      isPublic: true,
      name: 'placeholder',
      options: [
        ...(payload.userId
          ? [
              {
                condition: 'LONG_LIST_EQUALS',
                type: 'DEFAULT',
                option: 'RELATED',
                values: [
                  {
                    label: 'placeholder',
                    value: String(payload.userId),
                  },
                ],
              },
            ]
          : []),
        ...getTimeConditions(payload),
      ],
    },
  })
}

export let getTicketStatisticalDistributionController: AbortController
export const getTicketStatisticalDistribution = (
  period: StatisticalDistributionPeriod,
  categories: TicketDistributionCategory[],
  filters,
): Promise<TicketStatisticalDistribution> => {
  getTicketStatisticalDistributionController = new AbortController()
  const { signal } = getTicketStatisticalDistributionController

  const allValuesUnchecked = filters.some((f) => f.values.length === 0)
  if (allValuesUnchecked) return Promise.resolve({ name: 'root', value: '', category: 'ROOT', size: '0', children: [] })

  return api
    .post('tickets/statistics/distribution/tickets', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { period, categories, filters },
      signal,
    })
    .json<{ data: TicketStatisticalDistribution }>()
    .then(({ data }) => data)
}

export let getAgentStatisticalDistributionController: AbortController
export const getAgentStatisticalDistribution = (
  period: StatisticalDistributionPeriod,
  categories: AgentDistributionCategory[],
  filters,
): Promise<AgentStatisticalDistribution> => {
  getAgentStatisticalDistributionController = new AbortController()
  const { signal } = getAgentStatisticalDistributionController

  const allValuesUnchecked = filters.some((f) => f.values.length === 0)
  if (allValuesUnchecked) return Promise.resolve({ name: 'root', value: '', category: 'ROOT', size: '0', children: [] })

  return api
    .post('tickets/statistics/distribution/agents', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { period, categories, filters },
      signal,
    })
    .json<{ data: AgentStatisticalDistribution }>()
    .then(({ data }) => data)
}

interface TicketListRequest {
  sort: string
  limit: number
  offset: number
  statisticalDistribution: {
    period: StatisticalDistributionPeriod
    ticketFilters: { category: TicketDistributionCategory; values: string[] }[]
    agentFilters: { category: AgentDistributionCategory; values: string[] }[]
  }
}

export let getTicketListController: AbortController
export const getTicketList = (json: TicketListRequest): Promise<GetTicketsResponse> => {
  const { ticketFilters, agentFilters } = json.statisticalDistribution
  const allTicketCategoryValuesUnchecked = ticketFilters.some((f) => f.values.length === 0)
  const allAgentsCategoryValuesUnchecked = agentFilters.some((f) => f.values.length === 0)
  if (allTicketCategoryValuesUnchecked || allAgentsCategoryValuesUnchecked)
    return Promise.resolve({ data: [], total: 0 })

  getTicketListController = new AbortController()
  const { signal } = getTicketListController

  return api
    .post('tickets/list', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json,
      signal,
    })
    .json<{ tickets: TicketListItemExt[] }>()
    .then((data) => ({
      data: data.tickets.map((t: TicketListItemExt) => ({
        ...t,
        id: Number(t.id),
      })),
      total: 0,
    }))
}

interface TicketRequest {
  connectionId: string
  ticketExternalIdList: string[]
}

export let getTicketsByExternalIdController: AbortController

export const getTicketsByExternalId = (data: TicketRequest[]): Promise<{ tickets: TicketListItemExt[] }> => {
  getTicketsByExternalIdController = new AbortController()
  const { signal } = getTicketsByExternalIdController

  return api
    .post('tickets/filter/list', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { ticketRequest: data },
      signal,
    })
    .json<{ tickets: TicketListItemExt[] }>()
}

interface StarredQuery {
  sort: string
  limit: number
  offset: number
  assigneeId?: string
}

export let getStarredTicketsController: AbortController

export const getStarredTickets = (data: StarredQuery): Promise<{ tickets: TicketListItemExt[] }> => {
  getStarredTicketsController = new AbortController()
  const { signal } = getStarredTicketsController

  return api
    .get('ticket/starred', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      searchParams: qs.stringify(data),
      signal,
    })
    .json()
}

export const getInitialSync = () => api.get('connections/initial-sync').json<{ completed: boolean }>()
