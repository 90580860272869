import type { RouteRecordRaw } from 'vue-router'
import { session } from '@/composables/useSession'
import { hasAccountRole, hasWorkspaceRole, hasHighestWorkspaceRole } from '@/utils/roleUtils'
import { getLookerDashboardByName } from '@/modules/dashboard/looker/utils/lookup'
import { getLookerDashboards } from './api'

const canAccessLookerDashboard = (lookerDashboardId: string) => {
  const {
    lookerReviewsDashboard,
    lookerReviewNoPassRateDashboard,
    lookerAutoQaDashboard,
    lookerBotQaDashboard,
    lookerAssignmentsDashboard,
    autoQa,
    botQa,
    assignments,
  } = session.features

  const isAgentInAllWorkspaces = !hasAccountRole('ADMIN', 'MANAGER') && hasHighestWorkspaceRole('AGENT')
  const isNotAgent = hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER', 'LEAD')

  switch (lookerDashboardId) {
    case 'manual_reviews::reviews':
      return lookerReviewsDashboard && !lookerReviewNoPassRateDashboard
    case 'manual_reviews::reviews_limited':
      return lookerReviewsDashboard && lookerReviewNoPassRateDashboard
    case 'manual_reviews_agent::reviews':
      return hasWorkspaceRole('AGENT') && lookerReviewsDashboard && !lookerReviewNoPassRateDashboard
    case 'manual_reviews_agent::reviews_limited':
      return hasWorkspaceRole('AGENT') && lookerReviewsDashboard && lookerReviewNoPassRateDashboard
    case 'autoqa::autoqa':
      return autoQa && !isAgentInAllWorkspaces && lookerAutoQaDashboard
    case 'botqa::botqa':
      return botQa && !isAgentInAllWorkspaces && lookerBotQaDashboard
    case 'assignments::assignments':
      return isNotAgent && assignments && lookerAssignmentsDashboard
    default:
      return false
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    name: 'dashboard.layout',
    redirect: { name: 'dashboard' },
    component: () => import('./Layout.vue'),
    meta: { requiresPayment: true, includeChildrenOnlyInCommandPalette: true, requiresSufficientConversations: true },
    props: ({ params }) => ({ categoryId: params.id || null }),
    children: [
      {
        path: 'category/:id?',
        name: 'dashboard.category',
        props: ({ params }) => ({ id: params.id }),
        component: () => import('./CategoryView.vue'),
      },
      {
        path: ':id',
        name: 'dashboard.looker',
        component: () => import('./looker/LookerDashboard.vue'),
        beforeEnter: (to, _from, next) => {
          const lookerDashboardId = Array.isArray(to.params.id) ? to.params.id[0] : to.params.id

          if (canAccessLookerDashboard(lookerDashboardId)) return next()
          next({ name: 'dashboard' })
        },
        props: true,
      },
      {
        path: 'assignments',
        name: 'dashboard.assignments',
        component: () => import('./assignments/AssignmentsView.vue'),
        meta: { requiredFlag: 'assignments', requiredHighestWorkspaceRole: ['MANAGER', 'LEAD'] },
        beforeEnter: async (_to, _from, next) => {
          if (!session.features.lookerAssignmentsDashboard) next()

          const { dashboards } = await getLookerDashboards()

          if (!dashboards?.length) next()

          const lookerAssignmentsDashboard = getLookerDashboardByName(dashboards, 'assignments')

          next({ name: 'dashboard.looker', params: { id: lookerAssignmentsDashboard.id } })
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        beforeEnter: async (_to, _from, next) => {
          if (!session.features.lookerReviewsDashboard) next()

          const { dashboards } = await getLookerDashboards()

          if (!dashboards?.length) next()

          let dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited' : 'reviews'

          if (hasWorkspaceRole('AGENT')) {
            dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited_agent' : 'reviews_agent'
          }

          const lookerReviewsDashboard = getLookerDashboardByName(dashboards, dashboardName)

          next({ name: 'dashboard.looker', params: { id: lookerReviewsDashboard.id } })
        },
        component: () => import('./DashboardIndexView.vue'),
      },
      {
        path: 'surveys',
        name: 'dashboard.surveys',
        component: () => import('./csat/CsatView.vue'),
      },
      {
        path: 'disputes',
        name: 'dashboard.disputes',
        component: () => import('./disputes/DisputesView.vue'),
        meta: { requiredFlag: 'disputes' },
      },
      {
        path: 'calibration',
        name: 'dashboard.calibration',
        component: () => import('./calibration/CalibrationView.vue'),
        meta: { requiredFlag: 'calibrationPro', requiredHighestWorkspaceRole: ['MANAGER', 'LEAD', 'REVIEWER'] },
      },
    ],
  },
]

export default routes
