import { computed, ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import { hasAccountRole } from '@/utils/roleUtils'

export default createSharedComposable(() => {
  const disabledTooltipContent = computed(() => {
    if (session.features.readOnlyConnections) return i18n.t('team.connection.contact_klaus')
    if (hasAccountRole('USER')) return i18n.t('team.connection.contact_admin')
    return null
  })

  const showAddConnectionModal = ref(false)

  const canManage = computed(() => hasAccountRole('ADMIN', 'MANAGER') && !session.features.readOnlyConnections)

  return {
    canManage,
    showAddConnectionModal,
    disabledTooltipContent,
  }
})
