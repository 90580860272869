import { snakeCase } from 'lodash-es'

const options = {
  newest: '-createdAt',
  oldest: 'createdAt',
  recent: '-updatedAt',
  recentReply: '-lastReplyDate',
  mostReplies: '-replyCount',
  leastReplies: 'replyCount',
  random: 'random',
}

export default options

export const sortPickerOptions = (invalidOptions: string[] = []) => {
  const keys = Object.keys(options).filter((key) => !invalidOptions.includes(key))

  const opts = {}
  for (const key of keys) {
    const translationKey = `conversations.ticket_list.sort_${snakeCase(key)}`
    opts[translationKey] = key
  }

  return opts
}
