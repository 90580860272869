import i18n from '@/i18n'

export const formatCents = (cents: number, currency = 'EUR', digits = 0) =>
  i18n.n(cents / 100, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: digits,
  })

export const cardTypes = {
  visa: 'Visa',
  mastercard: 'Mastercard',
  american_express: 'American Express',
  discover: 'Discover',
  jcb: 'JCB',
  diners_club: "Diner's Club",
  bancontact: 'Bancontact',
  other: i18n.t('universal.other'),
}
