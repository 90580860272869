import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'

import i18n from '@/i18n'
import { FilterTimeStep, FilterDirection, type FilterParams } from '../types'

dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)
dayjs.extend(isoWeek)

/**
 * Format data time label
 */
export const getDataLabel = (step: FilterTimeStep) => {
  let format: string

  switch (step) {
    case FilterTimeStep.Day:
      format = 'MMM D'
      break
    case FilterTimeStep.Week:
      format = '[W]W'
      break
    case FilterTimeStep.Month:
      format = 'MMM'
      break
    case FilterTimeStep.Quarter:
      format = '[Q]Q'
      break
    case FilterTimeStep.Year:
      format = 'YYYY'
  }

  return (label: string) => dayjs.utc(label).format(format)
}

export const getReviewPartyLabel = (filters: FilterParams) =>
  i18n.t(`dashboard.${filters.direction === FilterDirection.Given ? 'reviewer' : 'reviewee'}`)
