import type { TicketSearchFilterRequest_AutoQAFilterOptionRequest } from '@zendesk/zqa-services/tickets'
import {
  AssignmentV2_Settings_RevieweeType,
  AssignmentV2_Settings_RevieweeClass as RevieweeClass,
} from '@zendesk/zqa-services/assignments_v2'
import { session } from '@/composables/useSession'
import { ALL_USERS_ID, ALL_BOTS_ID } from '../constants'
import { type AssignmentV2Form } from '../types/assignments'

export function toSubmittableAssignmentFormV2(form: Partial<AssignmentV2Form>): Partial<AssignmentV2Form> {
  const allReviewersSelected = form.participants?.reviewers.some((r) => r.id === ALL_USERS_ID)
  const allRevieweesSelected = form.participants?.reviewees.some((r) => r.id === ALL_USERS_ID || r.id === ALL_BOTS_ID)

  const options = form.filter.options?.map(({ type, option, condition, externalTicketFieldId, values }) => {
    return {
      externalTicketFieldId,
      type: externalTicketFieldId ? 'TICKET_FIELD' : type,
      option,
      condition,
      values: values ?? undefined,
    }
  })
  const autoQaOptions = form.filter.autoQaOptions?.map(({ option, condition, values }) => {
    return {
      option,
      condition,
      values: Array.isArray(values)
        ? values.map(({ label, value }) => {
            return { label, value }
          })
        : values,
    }
  }) as TicketSearchFilterRequest_AutoQAFilterOptionRequest[]

  const reviewers = allReviewersSelected ? [] : form.participants?.reviewers || []
  const reviewees = allRevieweesSelected ? [] : form.participants?.reviewees || []
  const reviewerGroups = allReviewersSelected ? [] : form.participants?.reviewerGroups || []
  const revieweeGroups = allRevieweesSelected ? [] : form.participants?.revieweeGroups || []
  const botReviewees = allRevieweesSelected ? [] : form.participants?.botReviewees || []

  const excludeReviewers = form.participants?.excludeReviewers || []
  const excludeReviewees = form.participants?.excludeReviewees || []
  const excludeReviewerGroups = form.participants?.excludeReviewerGroups || []
  const excludeRevieweeGroups = form.participants?.excludeRevieweeGroups || []
  const excludeBotReviewees = form.participants?.excludeBotReviewees || []

  const isBotsReviewees = session.features.botQa2Dev && form.settings.revieweeClass === RevieweeClass.BOT
  const selfReviewGoal = isBotsReviewees ? false : form.settings.goal.config.allowSelfAssignment
  const revieweeType = isBotsReviewees ? AssignmentV2_Settings_RevieweeType.PARTICIPANT : form.settings.revieweeType

  return {
    ...form,
    participants: {
      reviewers,
      excludeReviewers,
      reviewees,
      excludeReviewees,
      reviewerGroups,
      revieweeGroups,
      excludeReviewerGroups,
      excludeRevieweeGroups,
      botReviewees,
      excludeBotReviewees,
    },
    filter: {
      ...form.filter,
      options,
      autoQaOptions,
    },
    settings: {
      ...form.settings,
      revieweeType,
      goal: {
        ...form.settings.goal,
        config: {
          ...form.settings.goal.config,
          allowSelfAssignment: selfReviewGoal,
        },
      },
    },
  }
}
