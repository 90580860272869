import { ref, computed } from 'vue'

import { isActiveFilter } from '@/components/filters/utils'
import { type Condition, type FilterItemValue, FilterValueType, type FilterOption } from '@/components/filters/types'
import { type DeconstructedOption, isValidOption } from '../utils/filters'
import { getEmptyFilterOption } from '../components/filters/utils'

export default function useSelectedFilterOptions() {
  const visibleFilters = ref<DeconstructedOption[]>([])

  const selectedOptionNames = computed(() => {
    return visibleFilters.value.map((filter) => filter.option.name)
  })

  const removeFilter = (i: string) => {
    const index = visibleFilters.value.findIndex(({ id }) => id === i)
    if (index === -1) return
    return visibleFilters.value.splice(index, 1)
  }

  const getFilterById = (filterId: string) => {
    return visibleFilters.value.find(({ id }) => id === filterId)
  }

  const setFilterCondition = (filterId: string, condition: Condition) => {
    const filter = getFilterById(filterId)
    if (!filter) return

    filter.condition = condition
  }

  const setFilterValue = (filterId: string, filterType: FilterValueType, value: FilterItemValue) => {
    const filter = getFilterById(filterId)
    if (filterType === FilterValueType.Text && (filter.option.isCustom || filter.option.isSpotlight)) {
      if (filter.values[0]) filter.values[0].value = value
      else filter.values[0] = { value }
    } else filter.values = value
  }

  const replaceOrAddFilter = (filter: DeconstructedOption) => {
    const index = visibleFilters.value.findIndex(({ id }) => id === filter.id)
    if (index === -1) visibleFilters.value.push(filter)
    else visibleFilters.value[index] = filter
  }

  const getLastFilter = () => {
    return visibleFilters.value[visibleFilters.value.length - 1]
  }

  const addEmptyFilter = (option: FilterOption) => {
    const newFilter = {
      ...getEmptyFilterOption(),
      option,
    }

    visibleFilters.value.push(newFilter)
  }

  const validVisibleFilters = computed(() => visibleFilters.value.filter(isValidOption))

  const setSelectedOptions = (options: DeconstructedOption[]) => {
    visibleFilters.value = options
  }

  const getFilteredItems = (filterFn: (opt: DeconstructedOption['option']) => boolean) => {
    return visibleFilters.value.filter(({ option }) => filterFn(option))
  }

  const applyActiveFilter = () => {
    visibleFilters.value = visibleFilters.value.filter((f) => isActiveFilter(f.values, f.condition))
  }

  return {
    visibleFilters,
    validVisibleFilters,
    selectedOptionNames,
    removeFilter,
    setFilterCondition,
    getFilterById,
    setFilterValue,
    replaceOrAddFilter,
    getLastFilter,
    addEmptyFilter,
    setSelectedOptions,
    getFilteredItems,
    applyActiveFilter,
  }
}
