import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "containerRef" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_review_link = _resolveComponent("review-link")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_comment_cell = _resolveComponent("comment-cell")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_category_label = _resolveComponent("category-label")!
  const _component_percentage_cell = _resolveComponent("percentage-cell")!
  const _component_data_grid_data = _resolveComponent("data-grid-data")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_data_card = _resolveComponent("data-card")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_data_card, {
      id: "dashboard-scores-by-ticket-card",
      title: _ctx.$t('dashboard.card_labels.scores_by_ticket'),
      class: "m-6",
      params: _ctx.params,
      "api-method": _ctx.getReviews,
      "csv-api-method": _ctx.getReviewsCsv
    }, {
      help: _withCtx(() => [
        _createVNode(_component_i18n_t, {
          tag: "p",
          keypath: "dashboard.card_labels.help_reviews"
        }, {
          default: _withCtx(() => [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t('dashboard.card_labels.help_reviews_bold')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("a", {
          class: "content-link",
          href: _ctx.$t('support_links.dashboard_calculations_breakdown'),
          rel: "noopener noreferrer",
          target: "_blank"
        }, _toDisplayString(_ctx.$t('universal.read_more')), 9 /* TEXT, PROPS */, _hoisted_2)
      ]),
      default: _withCtx(({ data, sortBy, setPage }) => [
        _createVNode(_component_data_grid, {
          data: data,
          "sort-by": sortBy,
          style: {"--label-horizontal-padding":"12px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_data_grid_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_data_grid_column, {
                  id: "reviewId",
                  width: _ctx.isNarrow ? '248px' : '300px',
                  label: _ctx.$t('dashboard.review_id'),
                  align: "left"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_review_link, {
                      "ticket-id": row.conversationExternalId,
                      "message-id": row.messageExternalId,
                      "connection-id": row.connectionId,
                      "source-type": row.sourceType,
                      "workspace-id": parseFloat(row.workspaceId),
                      "search-mode": ""
                    }, null, 8 /* PROPS */, ["ticket-id", "message-id", "connection-id", "source-type", "workspace-id"]),
                    _createElementVNode("div", {
                      class: _normalizeClass([_ctx.$style.reviewers, _ctx.isNarrow && _ctx.$style.narrowReviewers])
                    }, [
                      (row.reviewer)
                        ? _withDirectives((_openBlock(), _createBlock(_component_q_avatar, {
                            key: 0,
                            class: _normalizeClass([_ctx.$style.avatar, _ctx.filterParams.direction === _ctx.FilterDirection.Given && _ctx.$style.activeParty]),
                            size: 24,
                            src: row.reviewer.avatar,
                            username: row.reviewer.name
                          }, null, 8 /* PROPS */, ["class", "src", "username"])), [
                            [_directive_tippy, { content: row.reviewer.name }]
                          ])
                        : _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style.unknownParty)
                          }, [
                            _createTextVNode("?")
                          ], 2 /* CLASS */)), [
                            [_directive_tippy, { content: _ctx.$t('dashboard.former_member') }]
                          ]),
                      _createVNode(_component_chevron_right_icon),
                      (row.reviewee)
                        ? _withDirectives((_openBlock(), _createBlock(_component_q_avatar, {
                            key: 2,
                            class: _normalizeClass([_ctx.$style.avatar, _ctx.filterParams.direction === _ctx.FilterDirection.Received && _ctx.$style.activeParty]),
                            size: 24,
                            src: row.reviewee.avatar,
                            username: row.reviewee.name
                          }, null, 8 /* PROPS */, ["class", "src", "username"])), [
                            [_directive_tippy, { content: row.reviewee.name }]
                          ])
                        : _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: 3,
                            class: _normalizeClass(_ctx.$style.unknownParty)
                          }, [
                            _createTextVNode("?")
                          ], 2 /* CLASS */)), [
                            [_directive_tippy, { content: _ctx.$t('dashboard.former_member') }]
                          ])
                    ], 2 /* CLASS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["width", "label"]),
                _createVNode(_component_data_grid_column, {
                  id: "lastComment",
                  label: _ctx.$t('dashboard.comment', 1),
                  align: "left",
                  width: _ctx.isNarrow ? '140px' : undefined
                }, {
                  default: _withCtx(({ value }) => [
                    _createVNode(_component_comment_cell, { comment: value }, null, 8 /* PROPS */, ["comment"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "width"]),
                (_ctx.session.features.timeTracking && _ctx.params.direction === 'given')
                  ? (_openBlock(), _createBlock(_component_data_grid_column, {
                      key: 0,
                      id: "reviewTime",
                      label: _ctx.$t('dashboard.review_time'),
                      "not-sortable": ""
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_number_cell, {
                          value: !value ? _ctx.$t('dashboard.review_time_value_na') : _ctx.formatDuration(Number(value)),
                          "no-format": "",
                          color: !value ? 'gray' : undefined
                        }, null, 8 /* PROPS */, ["value", "color"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_data_grid_data, {
              "label-tooltip": "",
              "not-sortable": "",
              width: 120,
              "format-label": 
              (label, idx, data) =>
                _ctx.isCategoryActive(idx, data.categories, data.active) ? label : `${label} (archived)`
            
            }, {
              header: _withCtx(({ label, idx, active, critical, categories }) => [
                _createVNode(_component_category_label, {
                  label: label,
                  active: _ctx.isCategoryActive(idx, categories, active),
                  critical: _ctx.isCategoryCritical(idx, categories, critical),
                  category: categories[idx],
                  align: "center"
                }, null, 8 /* PROPS */, ["label", "active", "critical", "category"])
              ]),
              default: _withCtx(({ value }) => [
                _createVNode(_component_percentage_cell, { value: value }, null, 8 /* PROPS */, ["value"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["format-label"]),
            _createVNode(_component_data_grid_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_data_grid_column, {
                  id: "score",
                  label: _ctx.$t('dashboard.score'),
                  width: _ctx.isNarrow && _ctx.hasPassRate ? '96px' : '140px'
                }, {
                  default: _withCtx(({ value }) => [
                    _createVNode(_component_percentage_cell, {
                      bold: "",
                      value: value
                    }, null, 8 /* PROPS */, ["value"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "width"]),
                (_ctx.hasPassRate)
                  ? (_openBlock(), _createBlock(_component_data_grid_column, {
                      key: 0,
                      id: "reviewStatus",
                      label: _ctx.$t('dashboard.review_status.title'),
                      width: _ctx.isNarrow ? (_ctx.session.features.specialPassRateAnalytics ? '128px' : '86px') : '140px'
                    }, {
                      default: _withCtx(({ value }) => [
                        _createVNode(_component_number_cell, {
                          value: 
                  value ? _ctx.$t(`dashboard.review_status.${_ctx.snakeCase(value)}`) : _ctx.$t('dashboard.review_status.value_na')
                ,
                          "bg-color": _ctx.statusColorMap[value as keyof typeof statusColorMap]
                        }, null, 8 /* PROPS */, ["value", "bg-color"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "width"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "sort-by"]),
        (data.pagination)
          ? (_openBlock(), _createBlock(_component_q_pagination, {
              key: 0,
              page: data.pagination.page,
              "page-size": data.pagination.pageSize,
              total: data.pagination.total,
              "item-type-i18n-key": 'dashboard.pagination_item_with_number',
              "data-testid": "dashboard-ticket-pagination",
              onChange: setPage
            }, null, 8 /* PROPS */, ["page", "page-size", "total", "onChange"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "params", "api-method", "csv-api-method"])
  ], 512 /* NEED_PATCH */))
}