import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["text-gray-60 text-sm font-medium leading-none", _ctx.$style.wrapper])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass({ [_ctx.$style.active]: _ctx.modelValue === 'year' })
    }, _toDisplayString(_ctx.$t('settings.subscription.duration_toggle.prefix')), 3 /* TEXT, CLASS */),
    _createElementVNode("input", _mergeProps({
      class: [_ctx.$style.input, 'sr-only']
    }, _ctx.$attrs, {
      checked: _ctx.modelValue === 'month',
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }), null, 16 /* FULL_PROPS */, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.toggle)
    }, null, 2 /* CLASS */),
    _createElementVNode("span", {
      class: _normalizeClass({ [_ctx.$style.active]: _ctx.modelValue === 'month' })
    }, _toDisplayString(_ctx.$t('settings.subscription.duration_toggle.suffix')), 3 /* TEXT, CLASS */)
  ], 2 /* CLASS */))
}