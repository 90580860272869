export default {
  "activity": {
    "empty_states": {
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reviews_given.open_conversation"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reviews_given.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reviews_given.subtitle"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reviews_received.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reviews_received.title"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.feedback_received.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.feedback_received.title"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reactions_received.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.reactions_received.title"])}
      },
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_all.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_all.title"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_received.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_received.title"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_started.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_started.title"])}
      },
      "comments_given": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.comments_given.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.comments_given.subtitle"])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.comments_given.filter"])}
      },
      "comments_received": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.comments_received.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.comments_received.subtitle"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.comments_not_found"])},
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.filter_suggestion"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.user_suggestion"])},
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_filter.accepted"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_filter.open"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_filter.rejected"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.empty_states.disputes_filter.resolved"])}
      }
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.prompt_title.comments"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.prompt_title.reviews"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.prompt_title.disputes"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.prompt_title.feedback"])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.success.comments"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.success.reviews"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.success.disputes"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.success.feedback"])}
      },
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.comments"])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.cta"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.disputes"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.feedback"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.prompt_submit"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.mark_all_read.reviews"])}
    },
    "reactions": {
      "agents_reacted_to": {
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.agents_reacted_to.review"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.agents_reacted_to.comment"])},
        "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.agents_reacted_to.reply"])}
      },
      "emoji_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.emoji_alt"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.received"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.comment"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.reply"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reactions.review"])}
    },
    "subnav": {
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.received_uppercase"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.given_uppercase"])},
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.disputes_uppercase"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.all"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.comments"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.disputes"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.feedback"])},
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.given"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.reactions"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.received"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.reviews"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.subnav.started"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.title"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.toggle_list"])},
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reviews.given"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.reviews.received"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.comments.given"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.comments.received"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.feedback.received"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.disputes.all"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.disputes.received"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.disputes.started"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.disputes.type_review"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.activity.disputes.type_reviewee"])}
    }
  },
  "assignments": {
    "completed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.completed_description"])},
    "completed_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.completed_title"])},
    "empty_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.empty_link"])},
    "not_enough_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.not_enough_tickets"])},
    "not_enough_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.not_enough_description"])},
    "counts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.counts"])},
    "restarts_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.restarts_in"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.time_left"])},
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.filters.value_na"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.add"])},
    "empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.empty_description"])},
    "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.empty_title"])},
    "not_enough_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.not_enough_title"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.ticket_list.heading"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.toggle_list"])},
    "sidebar": {
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.sidebar.sort_option_alphabetically"])},
      "sort_option_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.sidebar.sort_option_time"])},
      "sort_option_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments.sidebar.sort_option_reviews"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auto_qa.time_period_warning"])}
  },
  "auth": {
    "account_exists": {
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.to_join_ask_for_invite"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.join_company"])},
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.company_has_account_v2"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.ask_for_invite"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.cancel_flow_prompt"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.cancel_signup"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.company_has_account"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.copy_instructions"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.create_account"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.account_exists.existing_account"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.complete_setup"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.confirm_email"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.continue.title"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.continue.works_best_on_desktop"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.continue.book_demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.continue.or_book_demo_with_us"])}
    },
    "errors": {
      "bad_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.bad_credentials"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.expired_invite"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.failed_to_load_invite"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.invalid_signup"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.max_attempts"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.unverified_email"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.disabled_auth_method"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.expired_invite_v2"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.expired_invite_request_new"])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.bad_credentials_v2"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.bad_credentials_try_again"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.bad_credentials_link"])},
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.errors.account_not_found"])}
    },
    "form": {
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.log_in"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.login_code"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.send_magic_link"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.sign_up"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.reset"])},
      "fields": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.fields.email.required"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.fields.email.invalid"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.fields.password.required"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.send_magic_link_v2"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.email"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.work_email"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.form.your_work_email"])}
    },
    "invite": {
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.hold_up"])},
      "howdy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.howdy"])},
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.password.invalid"])}
      },
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.main_service_agreement.url"])}
      },
      "privacy_notice": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.privacy_notice.url"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.privacy_notice.link"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.main_services_agreement.link"])}
      },
      "join_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite.join_organization"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite_existing.title"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite_existing.cancel_flow_prompt"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite_existing.cancel"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite_existing.description"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite_existing.primary_button"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.invite_existing.secondary_button"])}
    },
    "klaus_image_alt_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.klaus_image_alt_text"])},
    "login": {
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.email_verified"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.magic_link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.title"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.google_v2"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.magic_link_v2"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.slack_v2"])},
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.email_verified_v2"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.invalid_link"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.return_button"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.zendesk"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.account_deleted"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login.dont_have_account"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.login_again"])},
    "magic_link": {
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.email_with_code"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.email_without_code"])},
      "login_traditionally": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.login_traditionally.link"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.login_traditionally.template"])},
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.login_traditionally.link_v2"])}
      },
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.sending_error"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.title"])},
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.title_v2"])},
      "email": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.email.required"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.email.invalid"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.login_code"])},
      "code": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.code.required"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.magic_link.code.invalid"])}
      }
    },
    "password_field": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.placeholder"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.max_length"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.create_password"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.rules_title"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.one_lower_case_v2"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.one_upper_case_v2"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.8_characters_minimum"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.create_your_password"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.forgot_password"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.one_lower_case"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.one_number"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.one_upper_case"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_field.password"])}
    },
    "password_reset": {
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.remembered_your_password"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.reset_link_error"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.existing_password.template"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.existing_password.bold"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.reset_link.template"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.reset_link.bold"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.title_v2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.description"])},
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.reset_v2"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.cancel"])},
      "email": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.email.required"])},
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.email.invalid"])}
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.success.title"])},
        "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.success.description_1"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.success.description_2"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.success.return_button"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.password_reset.title"])}
    },
    "signup": {
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.agreement.privacy_policy"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.agreement.template"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.agreement.terms_of_service"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.have_account"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.magic_link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.signup_with_work_email"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.title"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.magic_link_v2"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.zendesk_account"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.signup.google"])}
    },
    "zendesk_sso": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auth.zendesk_sso.title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auth.zendesk_sso.description"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.blocked.disabled_access_to_your_account_zd_only"])},
      "disabled_access_to_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.blocked.disabled_access_to_your_account"])},
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.blocked.image_alt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.auth.blocked.title"])}
    }
  },
  "components": {
    "command_palette": {
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.failed_to_load"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.not_found"])},
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.pre_placeholder"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.found_by_id"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.loading"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.placeholder"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.type_conversation"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.type_public_filter"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.command_palette.type_private_filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.helpdesk_icon.generic_source"])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.helpdesk_icon.manual_import"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.pagination.wrapper"])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.subnav.hide"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.subnav.show"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.components.subnav.toggle"])}
    }
  },
  "conversations": {
    "calibration_sidebar": {
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.calibration_sidebar.empty_state.message"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.calibration_sidebar.empty_state.title"])}
      },
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.calibration_sidebar.review_disabled_reviewed"])},
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.calibration_sidebar.review_disabled_expired"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.calibration_sidebar.feedback_form_title"])},
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.calibration_sidebar.close_sidebar"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.empty_body"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.custom_survey"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.empty_short"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.empty_title"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.rating"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.response_received"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.tag_label"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.title"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.survey_sidebar.reason"])}
    },
    "filters": {
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.active"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.older"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.title"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.no_visible_items"])},
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.empty_old"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.no_sessions"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.no_active_sessions"])},
        "create_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.create_modal.title"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.create_modal.edit_title"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.create_modal.name_label"])},
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.create_modal.date_label"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.create_modal.submit"])}
        },
        "delete_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.delete_modal.title"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.delete_modal.body"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.calibrations.delete_modal.confirm"])}
        }
      },
      "form": {
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.downgraded_filter_tooltip"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.loading_error"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.read_more"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.recommended_filter"])},
        "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.ticket_count"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.ticket_count_label"])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.ticket_count_na"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.value_outdated"])},
        "filter_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.filter_created"])},
        "filter_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.filter_updated"])},
        "no_filters_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.no_filters_message"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.over_last_30_days"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.timeframe_tooltip"])},
        "options": {
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.options.users"])},
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.options.bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.options.groups"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.options.bots_empty"])}
        },
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.add_condition"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.condition_placeholder"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.create_heading"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.create_submit"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.ai_fields_note"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.custom_fields_note"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.filters_field_label"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.loading_options_message"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.name_field_description"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.name_field_label"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.option_picker"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.option_placeholder"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.public_option_label"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.private_option_label"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.convert"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.update_heading"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.update_submit"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.value_empty"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.value_loading"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.value_not_found"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.value_placeholder"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.form.visibility_toggle_label"])}
      },
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.add_connection_first"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.auto_qa.value_na"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.add_filter_title"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.add_inline"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.add_private_filter"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.add_public_filter"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.add_success_toast"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.clone_filter"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.delete_filter"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.delete_modal.body"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.delete_modal.title"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.delete_modal.confirm_button"])}
      },
      "disputes": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.disputes.heading"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.disputes.all"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.disputes.open"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.disputes.created"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.disputes.assignee"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.drafts"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.edit_filter"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.empty_filter"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.empty_private_filter"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.empty_public_filter"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.heading"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.private_filters"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.public_filters"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.temporary_filter_message.action"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.temporary_filter_message.action_button"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.temporary_filter_message.description"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.temporary_filter_message.loading_message"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.update_success_toast"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.filters.visual_filters"])}
    },
    "sidebar": {
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.action_finish_assignment"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.assigned_to"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_required"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.conditional_scorecard_info"])},
      "disputes": {
        "disputed_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.disputed_review"])},
        "started_review_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.started_review_dispute"])},
        "disputed_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.disputed_reviewee"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.started_reviewee_disputed"])},
        "labels": {
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.approve_reviewee"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.dispute_review"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.edit_review"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.dispute_reviewee"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.approve_review"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.reject_review"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.labels.reject_reviewee"])}
        },
        "comment_placeholder": {
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.approve_reviewee"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.dispute_reviewee"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.dispute_review"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.approve_review"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.reject_review"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.edit_review"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.comment_placeholder.reject_reviewee"])}
        },
        "approved_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.approved_by"])},
        "partially_accepted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.partially_accepted_by"])},
        "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.assigned_to"])},
        "rejected_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.rejected_by"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.status.approved"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.status.partially_accepted"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.status.rejected"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.status.new"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.modal.user_label"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.modal.save"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.modal.title"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.modal.type_ratings"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.modal.type_reviewee"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.modal.user_placeholder"])}
        },
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.actions_disabled"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.approve"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.approve_scores_message"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.delete"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.dispute_resolution_time"])},
        "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.discard"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.delete_prompt"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.delete_title"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.dispute"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.reject"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.view"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.review_message"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.review_trigger"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.reviewee_trigger"])},
        "titles": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.dispute_review"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.approve_review"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.reject_review"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.edit_review"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.dispute_reviewee"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.approve_reviewee"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.disputes.titles.reject_reviewee"])}
        }
      },
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.formerly_assigned_to"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.gif_loader_end"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.gif_not_found"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.no_feedback_subtitle"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.paused_subscription"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.posted_by"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_time_tooltip"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_required"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.root_cause_missing"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.scorecard_label"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.no_ratings_comments"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.add"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.add_emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.collapse"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.hide_formatting"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.hide_panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.show_formatting"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.show_panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.submit"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.comment_maximize"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tooltips.comment_minimize"])}
      },
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.unrated_category_tooltip"])},
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.unrated_categories_tooltip"])},
      "auto_qa": {
        "feedback_updated_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.feedback_updated_message"])},
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.custom_category_popup"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.highlight_toggle"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.no_mistakes"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.na_popup"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.show_complex_words"])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.complex_words"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.show_long_sentences"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.long_sentences"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.auto_qa.feedback_updated_tooltip"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.call_preview"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.corrected"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.deleted"])},
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.edited"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_modified_tooltip"])},
      "review_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_recipient"])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_reviewed"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.spotlight.undetermined"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.spotlight.title"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.spotlight.tooltip_description"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.next_ticket"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.action_highlight"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.action_highlight_short"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.action_next_assignment"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.action_skip_assignment"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.back_to_ticket_review"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.baseline_review"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_delete"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_delete_title"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_deleted"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_edit"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_edit_placeholder"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_list.comment_author"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_list.hashtag_not_found"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_placeholder"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.comment_submit"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.conditional_scorecard"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.confirm_comment_delete"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.confirm_comment_delete_button"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.empty_message_preview"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.feedback_heading"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.message_feedback_heading"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.feedback_seen"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.feedback_not_seen"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.former_member"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.gif_placeholder"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.hashtag_create"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.hashtag_locked"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.highlight_tippy"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.mark_as_baseline"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.next_ticket_title"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.no_feedback_short"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.no_feedback_subtitle_old"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.no_feedback"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.no_other_feedback"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.not_rated"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.previous_assignee"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.previous_ticket_title"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.remove_as_baseline"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reply"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reply_placeholder"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_cancel"])},
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_submit"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_expand"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_collapse"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_delete_confirm"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_delete_confirm_button"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_delete_title"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_delete_toast"])},
      "review_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_deleted"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_disabled_completed"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_disabled_reviewee"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_disabled_reviewed"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_disabled_reassign"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_disabled_scorecard"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_disabled_self_review"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_draft_dialog.cancel"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_draft_dialog.confirm"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_draft_dialog.deny"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_draft_dialog.title"])}
      },
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_status.fail"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_status.other"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_status.pass"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_status.pass_with_minors"])}
      },
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_time_loading"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_title_comment"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_title_message"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_title_self"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_title_ticket"])},
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_draft_discard"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.review_draft_discard_confirm"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_assignee"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_author"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_depleted"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_disabled"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_empty"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_participants"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_dropdown_users"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_loading"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviewee_placeholder"])},
      "reviews_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.reviews_count"])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.select_new_ratings"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.self_reviews_disabled"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.tag_this_pin"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sidebar.total"])}
    },
    "ticket": {
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.deleted_subtitle"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.not_found_subtitle"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_add_to"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.unassigned"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.user_activity"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.user_reviewing"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.translate_to_language"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.URL"])},
      "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.ID"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.all_pins"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.assignee"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_in_session"])},
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_no_sessions"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_tooltip"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_toast_added"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_toast_removed"])},
      "calibration_modal": {
        "remove_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_modal.remove_title"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_modal.remove_description"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.calibration_modal.confirm_button"])}
      },
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.copy_link"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.description"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.deleted"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_brand"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_channel"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_client_email"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_client_id"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_client_name"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_form_name"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_frt"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_frt_tooltip"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_ttfr"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_ttfr_tooltip"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_group"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_priority"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_satisfaction_comment"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_satisfaction_score"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_tags"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.details_type"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.external_link"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.loading"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.message_404_toast"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.no_subject"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.no_ticket_image"])},
      "no_tickets_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.no_tickets_imported"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.not_found"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.original_conversation"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.original_conversation_disabled"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.participants"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.participants_empty"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.pin_it"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.read_review_message"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.review_message"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.reviewed_via_extension"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.satisfaction_survey"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.see_conversation"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.sentiment_negative"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.sentiment_positive"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.translate"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket.translate_undo"])}
    },
    "ticket_list": {
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.importing_tickets_title"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.importing_tickets_subtitle"])},
      "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.limit"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_complex_tickets_subtitle"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_complex_tickets_title"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_tickets_subtitle"])},
      "no_tickets_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_tickets_link"])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_calibration_tickets_subtitle"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_starred_tickets_subtitle"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.details.created_at"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.details.updated_at"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.details.channel"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.details.source"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.details.status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.details.type"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.filter_limit_button"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.review_period_daily"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.review_period_monthly"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.review_period_weekly"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.search"])},
      "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.search_title"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.someone_reviewed"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_commented"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_newest"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_oldest"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_random"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_recent"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_recent_reply"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_title"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_unread"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.you_reviewed"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_connections_title"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_connections_subtitle"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_connections_link"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_tickets_link_v2"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.reviewee_title"])},
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.date_title"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.customer"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.filter_limit_no_limit_label"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.heading"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.internal_note"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.loading_tickets"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.member_filter_next"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.member_filter_not_found"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.member_filter_option_prefix"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.member_filter_placeholder"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.member_filter_previous"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_tickets_title"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_calibration_tickets_title_old"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_calibration_tickets_title"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_starred_tickets_title"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_drafts_title"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.no_drafts_subtitle"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.not_found"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_least_replies"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_most_replies"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.sort_sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.toggle_list"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.workspace_members"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_list.return_to_selected_ticket"])}
    },
    "ticket_reply": {
      "audio_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.audio_error"])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.satisfaction_comment"])},
      "call_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.call_answered"])},
      "call_ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.call_ended"])},
      "call_placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.call_placed"])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.reviewed"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.reviewed_by_you"])},
      "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.bot"])},
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.bot_uppercase"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.audio_error_short"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.author"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.no_call"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.open_in_aircall"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.open_audio_in_helpdesk"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.playback_rate"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.review_call"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.system"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_reply.transcript"])}
    },
    "ticket_insights": {
      "types": {
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.churn_risk.description"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.churn_risk.title"])}
        },
        "recording_disclosure_missing": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.recording_disclosure_missing.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.recording_disclosure_missing.description"])}
        },
        "bot_repetition": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.bot_repetition.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.bot_repetition.description"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.bot_repetition.tooltip"])}
        },
        "positive_bot_communication_efficiency": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.positive_bot_communication_efficiency.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.positive_bot_communication_efficiency.description"])}
        },
        "negative_bot_communication_efficiency": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.negative_bot_communication_efficiency.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.negative_bot_communication_efficiency.description"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.sla_breached.description_with_examples"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.sla_breached.title_new"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.sla_breached.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.sla_breached.description"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.custom_spotlight.description"])}
        },
        "follow_up": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.follow_up.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.follow_up.description"])}
        },
        "escalation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.escalation.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.escalation.description"])}
        },
        "extra_mile": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.extra_mile.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.extra_mile.description"])}
        },
        "outlier": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.outlier.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.outlier.description"])}
        },
        "negative_sentiment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.negative_sentiment.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.negative_sentiment.description"])}
        },
        "positive_sentiment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.positive_sentiment.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.positive_sentiment.description"])}
        },
        "deadair_exceeded": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.deadair_exceeded.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.deadair_exceeded.description"])},
          "instances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.deadair_exceeded.instances"])},
          "longest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.types.deadair_exceeded.longest"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.feedback.applicable"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.feedback.not_applicable"])},
        "set_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.feedback.set_by"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.feedback.last_edited_by"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.feedback.feedback_toggle"])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.feedback.feedback_textarea_placeholder"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_insights.empty_state"])}
    },
    "visual_filters": {
      "categories": {
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.reviewed.label"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.reviewed.sub_categories.false"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.reviewed.sub_categories.true"])}
          }
        },
        "agent_performance": {
          "sub_categories": {
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.onemessage"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.not_a_klaus_user"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.superstar"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.high_performer"])},
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.good"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.lowperformer"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.sub_categories.sampler"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.label"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.tooltip"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_performance.tooltip_title"])}
        },
        "agent_csat": {
          "sub_categories": {
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.sub_categories.not_a_klaus_user"])},
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.sub_categories.high_satisfaction_score"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.sub_categories.medium_satisfaction_score"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.sub_categories.low_satisfaction_score"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.sub_categories.no_csat"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.label"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.tooltip"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_csat.tooltip_title"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.sub_categories.not_a_klaus_user"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.sub_categories.high_positive_sentiment"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.sub_categories.positive_negative_sentiment"])},
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.sub_categories.high_negative_sentiment"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.sub_categories.no_sentiment"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.label"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.tooltip"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_sentiment.tooltip_title"])}
        },
        "agent_reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_reviewed.label"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_reviewed.sub_categories.false"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.agent_reviewed.sub_categories.true"])}
          }
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.complexity.sub_categories.false"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.complexity.sub_categories.true"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.complexity.label"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.complexity.tooltip"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.complexity.tooltip_title"])}
        },
        "sentiment": {
          "sub_categories": {
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.sentiment.sub_categories.positive"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.sentiment.sub_categories.negative"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.sentiment.tooltip"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.sentiment.label"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.sentiment.tooltip_title"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.connection.label"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.language.label"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.language.tooltip"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.language.tooltip_title"])}
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.channel.label"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.categories.csat.label"])}
        }
      },
      "info": {
        "paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_2"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.read_more"])},
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_1_item"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.title"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_1"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_2_item"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.bullet_point_1"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.bullet_point_2"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_3"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_3_item_1"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_3_item_2"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_4"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.info.paragraph_4_item"])}
      },
      "perc_of_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.perc_of_parent"])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.sunburst_loading_error"])},
      "out_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.out_of"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.sunburst_root"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.unknown"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.unknown_category_name"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.agents_sunburst_title"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.conversations_sunburst_title"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.title"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.perc_of_total"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.visual_filters.sunburst_reload"])}
    },
    "sub_nav": {
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.starred"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.drafts_subtitle"])},
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.calibrations"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.heading"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.visual_filters"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.visual_filters_tooltip"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.drafts"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.calibration"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.sub_nav.add_calibration"])}
    },
    "ticket_event": {
      "macro_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.macro_used"])},
      "tag_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.tag_added"])},
      "assignee_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.assignee_change"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.assignee_removed"])},
      "team_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.team_change"])},
      "status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.status_change"])},
      "rating_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.rating_added"])},
      "redaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.redaction"])},
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.incomplete.description"])},
        "description_no_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.incomplete.description_no_edit"])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.incomplete.options_url"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.incomplete.tags_url"])}
      },
      "statuses": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.statuses.new"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.statuses.open"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.statuses.pending"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.statuses.solved"])},
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.statuses.closed"])}
      },
      "snoozed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.snoozed"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_event.unsnoozed"])}
    },
    "call_transcript": {
      "speaker_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.speaker_alias"])},
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.details_uppercase"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.summary_uppercase"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.transcript_uppercase"])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.customer"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.details"])},
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.edit_speaker"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.summary"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.transcript"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.message_review"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.speakers"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.return_to_playing_message"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.ws_users"])},
      "dead_air": {
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.dead_air.speaker"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.call_transcript.dead_air.seconds"])}
      }
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_deletion.button_label"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_deletion.confirmation_modal_body"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_deletion.confirmation_modal_body_v2"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_deletion.confirmation_modal_title"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.ticket_deletion.confirmation_modal_confirm_button"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.conversations.date_filter.disabled"])}
    }
  },
  "calibration_dashboard": {
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.baseline"])},
    "due_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.due_at"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.total"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.comment"])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.no_sessions.description"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.no_sessions.title"])}
    },
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.root_cause"])},
    "no_conversations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.no_conversations.title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.no_conversations.description"])}
    },
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.no_reviews_link"])},
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.no_reviews_can_see"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.calibration_dashboard.calibration_session_picker.no_sessions"])}
    }
  },
  "csat_dashboard": {
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_timeline.left_axis"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_timeline.right_axis"])}
    },
    "filter_bar": {
      "predicted_tags": {
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.emoji.only_emoji"])}
        },
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.confusing"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.support.not_helpful"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.support.bad"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.support.good"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.account.issue"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.agent_specific"])},
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.refund.issue"])}
        },
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.issue.not_solved"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.issue.solved"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.negative_sentiment"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.crumbs"])},
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.positive_sentiment"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.complaint"])},
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.speed.fast"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.speed.slow"])}
        },
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.outcome.bad"])}
        },
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.praise"])},
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.thanks"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_tags.product.bad"])}
        }
      },
      "pro_tooltip": {
        "cta_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.pro_tooltip.cta_non_admin"])},
        "second_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.pro_tooltip.second_row"])},
        "cta_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.pro_tooltip.cta_admin"])},
        "first_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.pro_tooltip.first_row"])}
      },
      "topic_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.topic_tooltip"])},
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.result_none"])},
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_dimensions.size.bitesize"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_dimensions.size.mid_length"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_dimensions.size.lengthy"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.predicted_dimensions.size.extended"])}
        }
      },
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.all_scores"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.all_surveys"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.surveys"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.scores_filter_empty"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.wordcloud_label"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.wordcloud_placeholder"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.language_label"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.language_placeholder"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.length_label"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.length_tooltip"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.length_placeholder"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.reason_label"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.reason_placeholder"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.topic_label"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.topic_placeholder"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.filter_bar.scores"])}
    },
    "timeline_card": {
      "overlay": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.overlay.title"])},
        "cta_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.overlay.cta_non_admin"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.overlay.content"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.overlay.button"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.title"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.average"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.bar_tooltip_title"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.sidebar_title.channel"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.sidebar_title.conversation_source"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.sidebar_title.helpdesk_tags"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.sidebar_title.csat_reasons"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.sidebar_title.predicted_tags"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline_card.sidebar_title.csat_language"])}
      }
    },
    "tags": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.conversations_count"])},
      "empty": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.empty.link"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.empty.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.empty.subtitle"])}
      },
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.sources.ces"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.sources.helpdesk"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.sources.csat"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.tags.title"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.word_cloud.conversations_count"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.word_cloud.title"])}
    },
    "breakdown_card": {
      "overlay": {
        "cta_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.overlay.cta_non_admin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.overlay.title"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.overlay.content"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.overlay.button"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.title"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.first_column_title.channel"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.first_column_title.conversation_source"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.first_column_title.helpdesk_tags"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.first_column_title.csat_reasons"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.first_column_title.predicted_tags"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_card.first_column_title.csat_language"])}
      }
    },
    "survey_replies": {
      "pagination_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.pagination_item"])},
      "comment_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.comment_categories"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.comment_categories_help"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.title"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.comment_size"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.comment_size_help"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.csat_time"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.user_title"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.comment_title"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.survey_replies.score_title"])}
    },
    "view_settings": {
      "owner_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.owner_type"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.date_type_reply"])},
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.date_type_csat_sent"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.owner_type_assignee"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.owner_type_most_replies"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.date_type"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.view_settings.date_type_creation"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.conversations.title"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.conversations.no_subject"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.help"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.title"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.set_target"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.edit_target"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.remove_target"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.target"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.donut.target_placeholder"])}
    },
    "funnel": {
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.tickets_count"])},
      "since_last_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.since_last_period"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.title"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.bar_conversations"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.bar_surveys"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.bar_na_surveys"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.bar_responses"])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.funnel.bar_comments"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline.average"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline.bars"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline.iqs"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline.iqs_average"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.timeline.target"])}
    },
    "workspaces_timeline_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.workspaces_timeline_card.title"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.workspaces_timeline_card.average"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.workspaces_timeline_card.bar_tooltip_title"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.workspaces_timeline_card.iqs"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.workspaces_timeline_card.sidebar_title"])}
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_sources.csat_reasons"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_sources.predicted_tags"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_sources.helpdesk_tags"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_sources.conversation_source"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_sources.channel"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.breakdown_sources.csat_language"])}
    },
    "csat_by_users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.title"])},
      "csat_ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.csat_ticket_count"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.change"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.users"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.ticket_count"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.response_rate"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_by_users.csat"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.n_of_total"])},
    "pagination_item_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.pagination_item_replies"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.pagination_item_replies_with_number"])},
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.ticket_count_na"])},
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.csat_value_na"])},
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.csat_by_users"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.donut"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.funnel"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.survey_replies"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.tags"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.timeline"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.card_labels.word_cloud"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.channel"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.conversation_source"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.helpdesk_tags"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.csat_reasons"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.predicted_tags"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.csat_language"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.csat_dashboard.missing_value.workspace"])}
    }
  },
  "dashboard": {
    "card_labels": {
      "csat_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.csat_help.title"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.csat_help.text"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.csat_help.formula"])}
      },
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.help_reviews_bold"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.iqs_help.title"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.iqs_help.formula"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.iqs_help.text"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.overview_error"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.overview_error_description"])},
      "pass_rate_help": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.text"])},
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.tooltip_text"])},
        "tooltip": {
          "pass_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.tooltip.pass_rule"])},
          "fail_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.tooltip.fail_rule"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.title"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.formula"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.pass_rate_help.special"])}
      },
      "quality_scores_received_help_line_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_received_help_line_4"])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_received_help_line_4_with_link"])},
      "quality_scores_given_help_line_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_given_help_line_4"])},
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_given_help_line_4_with_link"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category_received_help_line_2"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category_received_help_line_3"])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category_given_help_line_2"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category_given_help_line_3"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_received_help_line_3"])},
      "scores_by_category_received_help_line_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_received_help_line_4"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_received_help_line_4_with_link"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_given_help_line_3"])},
      "scores_by_category_given_help_line_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_given_help_line_4"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_given_help_line_4_with_link"])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_note"])},
      "category_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.category_ratings"])},
      "help_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.help_reviews"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_received_help_line_1"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_given_help_line_1"])},
      "responses_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.responses_count"])},
      "reviews_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.reviews_count"])},
      "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.baseline"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.critical_error_free"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.critical_error_free_help"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.internal_score"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.other_reasons"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.period_change.custom"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.period_change.day"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.period_change.month"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.period_change.week"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.period_change.year"])}
      },
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.root_causes"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.root_causes_all"])},
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_received_help_line_2"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_received_help_line_3"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_given_help_line_2"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.quality_scores_given_help_line_3"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.overview"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category_received_help_line_1"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.ratings_by_category_given_help_line_1"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_received_help_line_1"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_received_help_line_2"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_given_help_line_1"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_category_given_help_line_2"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.scores_by_ticket"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.tickets_count"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.users"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_labels.workspaces"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_load_error"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_no_data"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.monthly_change"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.auto_qa"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.assignments"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.calibration"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.categories"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.disputes"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.overview"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.reviews"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.navbar.title"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.rating_scale_change.info_link"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.rating_scale_change.message"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.average"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.average_minutes"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.average_hours"])},
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category_tooltip.archived"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category_tooltip.critical_category"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category_tooltip.description"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category_tooltip.rating_scale"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.custom_total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.exclude_self_reviews"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.export_all_tippy"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.feedback_direction_given"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.feedback_direction_received"])},
    "pagination_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.pagination_item"])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.pagination_item_default"])},
    "response_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.response_count"])},
    "review_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_count"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.show_more"])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.last_30_days"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.last_7_days"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.last_month"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.last_week"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.this_month"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.this_week"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_periods.custom"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_steps.days"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_steps.months"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_steps.quarters"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_steps.weeks"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.time_steps.years"])}
    },
    "looker": {
      "reviews": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.looker.reviews.banner.text"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.looker.reviews.banner.link"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.looker.reviews.banner.url"])}
        }
      },
      "botqa": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.looker.botqa.banner.text"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.looker.botqa.banner.link"])}
        }
      },
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.looker.assignments.banner.text"])}
        }
      }
    },
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.stats_card.na"])}
    },
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.pagination_item_with_number"])},
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.pagination_item_default_with_number"])},
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_time_value_na"])},
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_status.value_na"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_status.fail"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_status.other"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_status.pass"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_status.pass_with_minors"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_status.title"])}
    },
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.passed_reviews_value_na"])},
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.average_review_time_value_na"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.goal.value_na"])}
    },
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.percentage.value_na"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.all_pins_card"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.all_pins_dashboard"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.average_review_time"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.iqs_average"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_export_tippy"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.card_customize_tippy"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category"])},
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category_title"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.category_picker.no_categories"])}
    },
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.chart_toggle"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.comment"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.comments"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.conversations"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.data_grid_toggle"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.date"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.former_member"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.iqs"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.passed_reviews"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.pin_card"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.pin_dashboard"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.ratings"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.reason"])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_id"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.review_time"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.reviewee"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.reviewer"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.reviews"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.root_causes.no_causes_link"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.root_causes.no_causes_subtitle"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.root_causes.no_causes_title"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.score"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.score_total"])},
    "scores_by_category": {
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.labels.category"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.labels.category_group"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.labels.workspace"])}
      },
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.grouping_options.by_category"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.grouping_options.by_group"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.grouping_options.by_workspace"])}
      },
      "settings_modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.settings_modal.title"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.settings_modal.group_by_label"])},
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.settings_modal.customize_order_label"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.scores_by_category.settings_modal.select_all"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.self_reviews_exclude"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.self_reviews_include"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.self_reviews_only"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.clear"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.comment_hashtags_empty"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.comment_hashtags_label"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.comment_hashtags_placeholder"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.helpdesk_tags_empty"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.helpdesk_tags_label"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.helpdesk_tags_placeholder"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.scorecard_tags_empty"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.scorecard_tags_label"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.scorecard_tags_placeholder"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.self_reviews_label"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.ticket_source_empty"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.ticket_source_label"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.ticket_source_placeholder"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.title"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.sidebar.workspace_empty_text"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.total_given"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.user_filter_all_users"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.user_filter_placeholder"])},
    "user_group_filter": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.user_group_filter.placeholder"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.user_group_filter.empty"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.user_group_filter.all"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.user_group_filter.label"])}
    },
    "view_settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.title"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.show_decimal_places"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.date_type"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.date_type_review"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.date_type_ticket"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.trigger_text"])},
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.cards"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.view_settings.other"])}
    },
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.workspace_filter_label"])},
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.workspace_filter_all_workspaces"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.workspace_filter_whole_account"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.workspace_name"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.dashboard.workspace_picker.no_workspaces"])}
    }
  },
  "disputes_dashboard": {
    "tooltip": {
      "reviews_seen": {
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.tooltip.reviews_seen.disputers_card"])},
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.tooltip.reviews_seen.disputed_reviewers_card"])}
      }
    },
    "data_column_label": {
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.disputes_rejected"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.disputes_accepted"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.user"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.reviews_done"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.reviews_received"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.reviews_seen"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.disputes"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.disputes_partially_accepted"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.data_column_label.disputes_open"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.accepted"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.partially_accepted"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.rejected"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.open"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.percent_of_resolved"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.reviews"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.disputes"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.disputes_accepted"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.disputes_partially_accepted"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.disputes_rejected"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.legend.disputes_open"])}
    },
    "card": {
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_over_time"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_by_category"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputers"])},
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputed_reviewers"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_overview_total"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_overview_open"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_overview_resolved"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_overview_resolution_time"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_overview_resolution_time_minutes"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.card.disputes_overview_resolution_time_hours"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.disputes_dashboard.total"])}
  },
  "extension": {
    "add_connection": {
      "call_to_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.call_to_action"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.connected_domains"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.intro"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.no_host_message"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.no_permissions"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.connections"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.add_domain"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.connect"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.loading"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.add_connection.no_host_title"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.assignee"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.description"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.subject"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.custom_fields.no_name_error"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.custom_fields.no_value_error"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.custom_fields.title"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.custom_fields.name_placeholder"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.custom_fields.value_placeholder"])}
      },
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.subject_help"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.subject_placeholder"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.validation_error"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.button"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.button_loading"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.title"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.create_ticket.unassigned"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.disabled.settings_template"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.disabled.description"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.disabled.settings"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.footer.log_out"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.footer.log_out_tooltip"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.footer.workspace"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.footer.user"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.invalid_url"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.app_login_required"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.log_in"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.log_in_sso"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.opening_prompt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.title"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.wait"])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.authenticating"])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.no_account"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.login.sign_up"])}
    },
    "messages": {
      "reload": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.reload.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.reload.subtitle"])}
      },
      "reconnect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.reconnect.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.reconnect.subtitle"])}
      },
      "subscription": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.subscription.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.subscription.subtitle"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.subscription.action"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.invalid_url.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.invalid_url.title"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.connection_error.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.connection_error.title"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.connection_error.action"])}
      },
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.access.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.access.title"])}
      },
      "not_found": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.not_found.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.not_found.subtitle"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.messages.base_connection_error.subtitle"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.notifications.feedback.title"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.notifications.feedback.message"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.notifications.login.title"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.notifications.login.message"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.review.require_all_categories"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.review.highlight"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.review.loading"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.review.no_feedback"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.review.read_feedback"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.review.view_on_klaus"])}
    },
    "settings": {
      "notifications_wip_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.notifications_wip_label"])},
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.connections_connected_description"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.connections_empty_description"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.hash_setting_help"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.account_label"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.connections"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.domains_connected_description"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.domains"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.domains_description"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.domains_whitelisted"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.extension"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.hash_setting_label"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.no_connections_template"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.notifications"])},
      "notifications_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.notifications_description"])},
      "notification_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.notification_toggle"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.workspace_label"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.settings.plan_restriction"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.feedback"])},
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.tasks.assignment_back_to_list"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.activity.empty"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.activity.see_more"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.activity.title"])}
    },
    "assignments": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.assignments.back"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.nav.activity"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.nav.assignments"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.nav.settings"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.nav.review"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.extension.nav.ticket_details"])}
    }
  },
  "filter": {
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.date_condition_toast"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.empty_name_toast"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.name_empty"])},
    "option": {
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.autoqa_prefix"])},
      "description": {
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_churn_risk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_group"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.agent_most_public_messages"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.closing"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.empathy"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.grammar"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.greeting"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.issue_understanding"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.solution"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.tone"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.readability"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.bots"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.bot_type"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.bot_reply_count"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_recording_disclosure_missing"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.reviewee_with_bots"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.related_with_bots"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.bot_communication_efficiency"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.bot_repetition"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.custom_spotlight"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_sentiment"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_escalation"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.assignee"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.agent_message"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.agent_public_message_count"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.call_duration"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.comment_body"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.transcription_content"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.comment_channel"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.internal_comment_internal_tag"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.comment_status"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.commented_date"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.conversation_sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.csat_answered_date"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.dispute_status"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.end_user_channel"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.end_user_message_count"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.external_comment_created_date"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.from_type"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_deadair_exceeded"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_transcription"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.sla_breach"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.highlight_status"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.spotlight"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.trash"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.language"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.last_external_comment_date"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.rating_category"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.rating_status"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.related"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.related_name_email"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.private_message_count"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.public_character_count"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.public_word_count"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.public_message_count"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.public_participant"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.review_viewed"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.reviewed_by"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.reviewed_date"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.reviewee"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.satisfaction_comment"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.satisfaction_score_per_message"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.satisfaction_score"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.scorecard"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.source_type"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.survey_question"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.survey_title"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_assignee"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_brand"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_channel"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_closed_date"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_created"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_created_date"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_csat_answered"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_description"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_external_id"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_folder"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_form"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_id"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_mailbox"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_number"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_priority"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_reply_count"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_state"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_status"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_subject"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_support_rep_reply_count"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_tags"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_type"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_updated"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_updated_date"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.ticket_via"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.unique_agent_count"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.viewed_status"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_extra_mile"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_escalation"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.description.has_follow_up"])}
      },
      "label": {
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.grammar"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.highlight_status"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.closing"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.is_sunburst_trash"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.reviewed_by"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_via"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.transcription_content_v2"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.bots"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.bot_type"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.bot_reply_count"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_recording_disclosure_missing"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.sla_breach_v2"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.call_direction"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.bot_communication_efficiency"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.bot_repetition"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.deadair"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.client_message"])},
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.all_messages"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_escalation"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.assignee"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.agent_most_public_messages"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.agent_public_message_count"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.call_duration"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_churn_risk"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_deadair_exceeded"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.comment_body"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.transcription_content"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.comment_channel"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.comment_internal_tag"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.comment_status"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.commented_date"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.conversation_sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.csat_answered_date"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.dispute"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.end_user_channel"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.end_user_message_count"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.external_comment_created_date"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.from_type"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.internal.comment_internal_tag"])}
        },
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.empathy"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_escalation"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_extra_mile"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_follow_up"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.greeting"])},
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.is_complex"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.issue_understanding"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.agent_message"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.readability"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.language"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.last_external_comment_date"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.rated_date"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.rating_and_comment_status"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.rating_category"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.rating_status"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.has_transcription"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.sla_breach"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.related"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.related_name_email"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.related_user"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.private_message_count"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.public_mean_character_count"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.public_mean_word_count"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.public_message_count"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.public_participant"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.reply_count"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.review_received"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.review_scorecard"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.review_viewed"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.reviewed_date"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.reviewee"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.reviewer"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.satisfaction_comment"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.satisfaction_per_message"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.satisfaction_score"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.solution"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.source_type"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.subject"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.survey_question"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.survey_title"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_assignee"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_brand"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_brand_domain"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_channel"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_closed_date"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_created"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_created_date"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_csat_answered"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_csat_created"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_description"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_external_id"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_field"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_folder"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_form"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_freshdesk_id"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_group"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_help_scout_id"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_id"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_intercom_id"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_internal_tag"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_mailbox"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_number"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_priority"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_reply_count"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_state"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_status"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_subject"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_support_rep_reply_count"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_tags"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_tag"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_type"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_updated"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_updated_date"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.ticket_zendesk_id"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.tone"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.unique_agent_count"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.viewed_status"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.vulnerability_capability"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.vulnerability_health"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.label.vulnerability_life_event"])}
      },
      "concatenated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.option.concatenated"])}
    },
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.value.current_user"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.value.removed_workspace_users"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.value.workspace_users"])}
    },
    "condition": {
      "label": {
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.comment_commented"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.comment_commented_by_me"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.comment_not_commented"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.comment_not_commented_by_me"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.complex"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.contains"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_30_days"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_dynamic"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_from"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_last_14_days"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_last_24"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_last_30_days"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_last_7_days"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_last_month"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_last_week"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_this_month"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_this_week"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_to"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_today"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.date_yesterday"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.detected"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.exists"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.highlight_highlighted_by_me"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.highlight_not_highlighted_by_me"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.includes"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.integer_equals"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.integer_higher"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.integer_higher_equals"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.integer_lower"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.integer_lower_equals"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.integer_not_equals"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.long_list_equals"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.long_list_not_equals"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.breached_list_equals"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_breached_list_equals"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.negative"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_complex"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_exists"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_detected"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_includes"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_received"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.not_viewed"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.positive"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.rating_not_rated"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.rating_not_rated_by_me"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.rating_rated"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.rating_rated_by_me"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.received"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.spotlight"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_contains"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_equals"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_greater_than"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_greater_than_equals"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_less_than"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_less_than_equals"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_list_equals"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_list_not_equals"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_not_contains"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_not_equals"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.viewed"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_is_greater_than"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.bot_better"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.bot_worse"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_list_equals_any"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.all_strings_equal"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_list_contains"])},
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.all_strings_contain"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_list_not_equals_any"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.all_strings_not_equal"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.string_list_not_contains"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.all_strings_not_contain"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.true"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.false"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.comment_my_comment_has_reply"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.no"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.condition.label.yes"])}
      }
    },
    "groups": {
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.other"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.search_placeholder"])},
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.custom_categories"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.custom_spotlight"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.categories"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.conversation"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.date"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.helpdesk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.information"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.metrics"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.participants"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.review_and_feedback"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.spotlight"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.groups.show_all"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.add"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.apply"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.date_condition_warning"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.multiple_value_placeholder"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.filter.single_value_placeholder"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template"])},
          "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.days"])},
          "template_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template_today"])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template_today_zd_only"])},
          "template_1_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template_1_day"])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template_1_day_zd_only"])},
          "template_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template_days"])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.template_days_zd_only"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_end.link"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.user_limit.template"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.user_limit.link"])}
        },
        "change_language": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.change_language.template"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.change_language.link"])}
        },
        "trial_left": {
          "template_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_left.template_zd_only"])},
          "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.trial_left.days"])}
        },
        "demo": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.demo.title"])},
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.demo.template"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.demo.link"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.demo.modal_title"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.banners.demo.modal_subtitle"])}
        }
      },
      "error_cat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.subtitle"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.error_reference"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.description"])},
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.bullet1"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.bullet2"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.heading"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.error_cat.copy_debug"])}
      },
      "navbar": {
        "beta_features": {
          "description_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.description_body"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.title"])},
          "description_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.description_intro"])},
          "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.documentation"])},
          "description_bullets": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.description_bullets.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.description_bullets.item_2"])}
          },
          "description_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.description_cta"])},
          "menu_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.menu_item"])},
          "enable_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.enable_button"])},
          "disable_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.disable_button"])},
          "report_bugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.beta_features.report_bugs"])}
        },
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.changelog"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.log_out"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.shortcuts"])},
        "settings_submenu": {
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.settings_submenu.users"])},
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.settings_submenu.users_bots_workspaces"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.settings_submenu.settings"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.goal_daily"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.goal_monthly"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.goal_weekly"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.review_goal"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.activity"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.assignments"])},
        "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.book_demo"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.chat"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.chat_failure"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.command_palette"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.conversations"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.dashboard"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.help"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.reviews_given"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.reviews_received"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.support_center"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.switch_account"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.homepage"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.settings"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.navbar.tasks"])}
      },
      "pending_invites": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.title"])},
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.onboarding.template"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.onboarding.link"])}
        },
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.invitation_received.template"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.invitation_received.bold"])}
        },
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.use_klaus"])},
        "invite_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.invite_template"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.pending_invites.join"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.toast_message"])},
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.offline"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.add_pin"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.command_palette"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.expand_editor"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.hide_show_subnav"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.toggle_shortcuts"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.next_conversation"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.previous_conversation"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.open_original_conversation"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.rate_highest"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.rate_lowest"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.shuffle_list"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.suggest_shortcuts_link"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.shortcuts.title"])}
      },
      "update_toast": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.update_toast.message"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.application.update_toast.label"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.add_root_cause"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.optional"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.others_and_archived"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.back"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.cancel"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.other"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.save"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.ratings.reason.title"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.char_limit_reached"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.file_error_format_template"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.file_error_size_template"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.drag_overlay"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.async_loader_error"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.link"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.image"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.gif"])},
      "link_editor": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.link_editor.text"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.link_editor.link"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.template.manage"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.modules.comment_editor.template.no_templates"])}
      }
    }
  },
  "onboarding": {
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.errors.fill_required_fields"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.errors.agree_to_terms_and_privacy_policy"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.slack_channel"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.advertising"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.email"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.event"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.friend_or_colleague"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.help_desk_or_manager"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.other"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.referring_site"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.search_engine"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.first_hearing_options.social_media"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_1.subtitle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_1.title"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.own_data_button"])},
      "own_data_img_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.own_data_img_alt"])},
      "sample_data_img_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.sample_data_img_alt"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.sample_data_subtitle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.title"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.own_data_subtitle"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.own_data_title"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.sample_data_button"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_2.sample_data_title"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_3.subtitle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.step_3.title"])}
    },
    "form": {
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.company_number_help"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.country"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.first_name"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.last_name"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.phone_number"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.number_of_employees"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.company_name"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.company_number"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.first_hearing"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.first_hearing_placeholder"])},
      "number_of_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.number_of_agents"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.number_of_agents_placeholder"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.submit"])},
      "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.form.your_name"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.cancel_flow_prompt"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.onboarding.cancel_signup"])}
  },
  "pins": {
    "folder_content": {
      "cta": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.cta.text"])},
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.cta.admin_text"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.cta.no_pins_text"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.cta.no_pins_button"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.no_pins_body_1"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.delete_pin_with_comments_body"])},
      "new_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.new_messages"])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.no_pins_body_2"])},
      "pinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.pinned"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.pinned_2_1"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.pinned_2_2"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.pinned_2_3"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.you"])},
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.delete_cannot_be_undone"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.delete_pin_body"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.delete_pin_confirm_button"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.delete_pin_title"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.folder_name"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.no_pins_title"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.reply"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.sort_options.new_first"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.folder_content.sort_options.old_first"])}
      }
    },
    "modal": {
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.how_to_save_pins"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pins_for_coaching"])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.cta.title"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.cta.button"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.cta.link"])}
      },
      "pin_created_with_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_created_with_action"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.add_others_with"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.conversation_deleted"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.create_new_pin"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.create_pin"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.csat_dashboard_title"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.edit_pin"])},
      "go_to_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.go_to_pin"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.how_pins_work"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.no_access"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_card"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_conversation"])},
      "pin_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_created"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_dashboard"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_saved"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_this_card"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_this_conversation"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_this_dashboard"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.pin_under"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.search_user"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.modal.who_can_see_pins"])}
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.no_pins_subtitle"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.no_pins_subtitle_2"])},
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.are_invited"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.count"])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.mentioned_participants"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.participants"])},
      "remove_user_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.remove_user_body"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.do_nothing"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.do_nothing_tooltip"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.editor_placeholder"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.invite_them"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.invite_them_tooltip"])},
      "is_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.is_invited"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.remove_user_title"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.comments.thread"])}
    },
    "no_results_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.no_results_1"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.no_results_2"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.no_pins_title"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.pin_manual_button.text"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.pin_manual_button.tippy"])}
    },
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.cta"])},
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.messages"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.people"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.search_placeholder"])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.sort_option_alphabetically"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.sort_option_created"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.sort_option_edited"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pins.sidebar.sort_option_most_pins"])}
    }
  },
  "profile": {
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.updated_toast"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.avatar"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.avatar_info"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.email"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.language"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.start_of_week"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.general.title"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.template_view_options_error"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.title"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.name_field"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.show_in"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.content"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.template_name_error"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.create_template.template_content_error"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.button_label"])},
      "empty_state": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.empty_state.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.empty_state.description"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.edit_template"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.default.description"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.default.name"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.default.used_in"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.delete_template.button_label"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.delete_template.delete_confirmation_title"])}
      },
      "toast": {
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.toast.saved"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.toast.deleted"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.toast.created"])}
      },
      "types": {
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.types.review"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.types.pin"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.templates.types.coaching"])}
      }
    },
    "goal": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.updated_toast"])},
      "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.form"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.button_label"])},
      "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.conversations"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.description"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.label"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.period_daily"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.period_monthly"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.period_weekly"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.goal.title"])}
    },
    "logins": {
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.add_new_social_login"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.delete_confirm_active"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.delete_title"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.google_success"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.slack_success"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.login_subsection_title"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.title"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.active"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.add_google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.add_slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.create_password"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.delete_confirm"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.delete_confirm_button"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.method"])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.password_subsection_title"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.password_reset_intro"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.logins.password_reset_button"])}
    },
    "navigation": {
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.logins"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.title_uppercase"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.general"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.notifications"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.password"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.title"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.navigation.templates"])}
    },
    "notifications": {
      "sections": {
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.description"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.title"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.ratings"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.comments"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.thread_replies"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.mentions"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.slack.disputes"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.reminders.description"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.reminders.title"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.reminders.weekly_reminder"])}
        },
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.loading_channels"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.description"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.enabled_label"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.comments_label"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.threshold_label"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.channel_placeholder"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.praise.no_channel_results"])}
        },
        "reports": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.reports.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.reports.description"])},
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.reports.content_label"])}
        },
        "notifications": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.notifications.title"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.notifications.description"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.notifications.new_received_reviews"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.notifications.replies_in_threads"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.notifications.mentions_in_comments"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.sections.notifications.disputes_related_to_me"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.daily"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.monthly"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.weekly"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.title"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.daily"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.hours_3"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.hours_6"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.instant"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.monthly"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.off"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.feedback_steps.weekly"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.locked_notice"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.section_email"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.section_slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.notifications.select_period"])}
    },
    "password": {
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.confirmation_email"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.email"])},
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.primary_email"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.auth_0"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.email_associated_template"])},
      "change_email_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.change_email_template"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.google_oauth_2"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.profile.password.oauth_2"])}
    }
  },
  "quizzes": {
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.not_found"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.copy_link_success"])},
    "form": {
      "header": {
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.access_tooltip"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.preview"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.access"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.archive"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.title_new"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.exit_preview"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.save_as_draft"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.publish_quiz"])},
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.participate"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.duplicate"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.export"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.quiz_settings"])},
        "navbar": {
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.navbar.questions"])},
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.navbar.preview"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.navbar.responses"])}
        },
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.header.unarchive"])}
      },
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.questions_preview_share"])},
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.questions_preview_copy"])},
      "errors": {
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.errors.workspace_access_cta"])},
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.errors.workspace_access_description"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.errors.no_correct_option"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.errors.no_empty_field"])}
      },
      "toasts": {
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.toasts.published"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.toasts.draft"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.toasts.removed"])},
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.toasts.answer_submitted"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.options.title"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.options.add_option"])}
      },
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.title_placeholder"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.title_fallback"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.questions_preview_warning"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.description_placeholder"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.question_placeholder"])},
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.add_question"])},
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.clone_question"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.remove_question"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.move_question_up"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.move_question_down"])},
      "alerts": {
        "archive_quiz": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.archive_quiz.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.archive_quiz.message"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.archive_quiz.confirm"])}
        },
        "publish_quiz": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.publish_quiz.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.publish_quiz.message"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.publish_quiz.confirm"])}
        },
        "remove_quiz": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.remove_quiz.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.remove_quiz.message"])}
        },
        "submit_answer": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.submit_answer.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.submit_answer.message"])}
        },
        "unarchive_quiz": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.unarchive_quiz.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.unarchive_quiz.message"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.alerts.unarchive_quiz.confirm"])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.form.submit"])}
    },
    "responses": {
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.count"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.score"])},
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.no_response.archived_message_filtered"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.no_response.archived_title"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.no_response.archived_message"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.no_response.title"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.no_response.message"])}
      },
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.overview"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.responses.individual"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.title_cloned"])},
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.error.link"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.error.description"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.error.no_access"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.error.title"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.delete_prompt"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.delete_prompt_access_by_archiving"])},
      "tabs": {
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.tabs.published"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.tabs.draft"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.tabs.archived"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.title"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.duplicate"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.delete_button_confirm"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.delete_title"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.delete_prompt_archived"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.empty.subtitle"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.empty.description"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.empty.search"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.export"])},
      "heading": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.name"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.name_with_workspaces"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.published_date"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.participants"])},
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.avg_score"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.score"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.completed_date"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.heading.workspaces"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.search"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.toast.quiz_archived"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.toast.quiz_unarchived"])}
      },
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.list.answered"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.title"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.create_new"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.toggle_list"])},
    "leaderboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.title"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.export"])},
      "heading": {
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.heading.quiz"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.heading.name"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.heading.amount"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.heading.score"])}
      },
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.agent_quizzes_title"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.empty_search"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.quizzes.leaderboard.search"])}
    }
  },
  "reviews": {
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.received_empty_title"])},
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.received_empty_subtitle"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.comments_only"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.given_empty_title"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.given_empty_subtitle"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.given_reviews"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.reviews.received_reviews"])}
  },
  "settings": {
    "categories": {
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.closing.description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.closing.title"])},
        "limit_reached_popup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.closing.limit_reached_popup.title"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.closing.limit_reached_popup.body"])}
        }
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.greeting.description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.greeting.title"])},
        "limit_reached_popup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.greeting.limit_reached_popup.title"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.greeting.limit_reached_popup.body"])}
        }
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.grammar.description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.grammar.title"])}
      },
      "ignore_list": {
        "duplicate_lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.duplicate_lines"])},
        "grammar": {
          "phrase_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.phrase_exists"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.toast_added"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.add_button"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.save_button"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.first_column_title"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.search_placeholder"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.toast_saved"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.empty_search"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.grammar.empty_state"])}
        },
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.phrase_exists"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.add_button"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.save_button"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.first_column_title"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.search_placeholder"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.toast_added"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.toast_saved"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.empty_search"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.greeting.empty_state"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.phrase_exists"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.add_button"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.save_button"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.first_column_title"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.search_placeholder"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.toast_added"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.toast_saved"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.empty_search"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.closing.empty_state"])}
        },
        "modal": {
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.snippet_editor_help_1"])},
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.input_tip_v2"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.snippet_editor_help_first"])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.snippet_editor_help_second"])},
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.input_placeholder"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.closing.add_title"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.closing.edit_title"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.closing.input_label"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.grammar.add_title"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.grammar.edit_title"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.grammar.input_label"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.greeting.add_title"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.greeting.edit_title"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.greeting.input_label"])}
          },
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.language_picker_label"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.textarea_label"])},
          "input_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.input_tip"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.input_tip_description"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.snippet_editor_label"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.modal.snippet_editor_help_2"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.language.label"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.language.all"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.language.search_placeholder"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.add_exemption"])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.delete_exemption_title"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.delete_exemption_confirm"])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.edit_exemption"])},
        "long_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.long_input"])},
        "long_input_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.long_input_count"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.updated_by"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.ignore_list.updated"])}
      },
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.apply_rule"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.type"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.type_neutral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.type_positive"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.type_negative"])},
        "rule_radio": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.rule_radio.description"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.rule_radio.description_narrow"])},
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.rule_radio.all_conversations"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.rule_radio.specific_conversations"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.rule_radio.all"])},
          "filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.rule_radio.filtered"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.title_v2"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.call_direction.all_selected"])}
          },
          "agent_message": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.agent_message.placeholder"])},
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.agent_message.errors.empty"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.agent_message.errors.too_long"])}
            }
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.source_type.all_selected"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.ticket_channel.all_selected"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.filters.ticket_tags.all_selected"])}
          }
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.create_category"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.category_limit_reached"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.category_limit_reached_v2"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.spotlight"])},
        "spotlight_name": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.spotlight_name.name"])},
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.spotlight_name.errors.empty"])}
          }
        },
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.spotlight_filter.errors.empty"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.spotlight_limit_reached"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.spotlight_limit_reached_v2"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.description.label"])}
        },
        "conditions": {
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.if_agent_message"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.add_row_label"])},
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.add_condition_label"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.delete_condition_label"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.and"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.if"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.then"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.conditions.otherwise"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.dynamic_content_message"])},
        "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.create_button"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.title"])},
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.description_line_1"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.description_line_2"])},
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.name.label"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.name.placeholder"])},
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.name.errors.empty"])}
          }
        },
        "limit_reached": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.limit_reached.title"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.limit_reached.content"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.limit_reached.action"])}
        },
        "category_created": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.category_created.title"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.category_created.content"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.category_created.action"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.category_created.cancel"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.create.multiple_errors"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.available_for"])},
        "spotlight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.spotlight_setup"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.insight_setup"])},
        "spotlight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.spotlight_setup_description"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.insight_setup_description"])},
        "tags": {
          "predefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.predefined"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.predefined_v2"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.custom"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.spotlight"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.scorecard"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.voice"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.chat"])},
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.bot"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.tags.coming_soon"])}
        },
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.scorecard_setup"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.scorecard_setup_description"])},
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.save_changes"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.edit.saved"])}
      },
      "custom_category": {
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.custom_category.info_alert"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.custom_category.description"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.custom_category.description_with_bots"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.custom_category.description_transcriptions"])}
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.tab_title"])},
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.snippets.dynamic_content.label"])}
        }
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.description.create"])}
      },
      "delete_category_template": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.delete_category_template.title"])},
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.delete_category_template.cancel_button"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.delete_category_template.confirm_button"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.categories.toggle_list"])}
    },
    "company": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.updated_toast"])},
      "consents": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.title"])},
        "details": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.template"])},
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.open_ai.description"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.open_ai.support_link_label"])}
          },
          "cancel_open_ai": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.description"])},
            "categories": {
              "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.categories.main"])},
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.categories.highlight"])}
            },
            "root_causes": {
              "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.root_causes.main"])},
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.root_causes.highlight"])}
            },
            "historical_data": {
              "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.historical_data.main"])},
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.historical_data.highlight"])}
            },
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.cancel_open_ai.turn_off"])}
          },
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.automatically_scoring_categories"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.details.never_used_for_training_models"])}
        },
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.toast_success.removed"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.toast_success.given"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.subtitle"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.title"])},
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.message.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.message.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.message.item_3"])}
          },
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.footer.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.withdraw_dialog.footer.item_2"])}
          }
        },
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.open_ai_title"])},
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.open_ai_label"])},
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.open_ai.toast_success.given"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.open_ai.toast_success.removed"])}
          }
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.ms_azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.status_given"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.status_not_given"])},
        "give_consent_dialog": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.subtitle"])},
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.message.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.message.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.message.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.message.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.consents.give_consent_dialog.message.item_5"])}
          }
        }
      },
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.week_start_monday"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.week_start_sunday"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.default_workspace_label"])},
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.default_workspace_help"])},
      "time_format_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.time_format_12"])},
      "time_format_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.time_format_24"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.time_format_label"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.company.week_start_label"])}
    },
    "connections": {
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.api_token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.api_token_secret"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.blacklist_instructions.freshdesk"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.blacklist_instructions.help_scout"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.blacklist_instructions.kustomer"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.blacklist_instructions.live_agent"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.blacklist_instructions.wix"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.blacklist_instructions.zendesk"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_added.next_step"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_added.title"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_added.add_members"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_added.description"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_domain"])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_helpdesk_identifier"])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.could_not_authenticate"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.created"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.failed_jobs_template"])},
      "fields": {
        "account_id_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.account_id_help"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.client_id"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.client_secret"])},
        "consumer_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.consumer_key"])},
        "consumer_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.consumer_secret"])},
        "kayako_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.kayako_template"])},
        "zd_chat_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.zd_chat_instruction"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.zd_chat_instruction_with_link"])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.account_id"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.configuration"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.conversations"])},
        "kayako_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.kayako_help"])},
        "scopes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.scopes"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.fields.users"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.integration_placeholder"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.intercom_region_help"])},
      "login_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.login_email"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.mask_data_help"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.no_connections"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.roles"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_unique_id"])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.months_1"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.months_3"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.months_6"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.months_12"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.help"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.label"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.data_retention_period.placeholder"])}
      },
      "deletion_confirmation": {
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.deletion_confirmation.removed_data_info"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.deletion_confirmation.reviews_not_affected"])},
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.deletion_confirmation.action_irreversible"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.group_list_placeholder"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.include"])},
      "integration_in_beta_warning": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.integration_in_beta_warning.template"])},
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.integration_in_beta_warning.active_development"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.mailbox_list_placeholder"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.tag_list_placeholder"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.channel_list_placeholder"])},
      "genesys_region": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.label"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.placeholder"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.us-east-1"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.us-east-2"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.us-west-2"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.ca-central-1"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.eu-west-1"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.eu-west-2"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.eu-central-1"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.eu-central-2"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.ap-south-1"])},
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.ap-northeast-1"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.ap-northeast-2"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.ap-northeast-3"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.ap-southeast-2"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.sa-east-1"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.genesys_region.me-central-1"])}
      },
      "amazon_connect": {
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.add_checkbox.label"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.section_explanation"])},
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.validation_error.generic"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.validation_error.location_format"])}
        },
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.access_key_id.help"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.access_key_id.label"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.access_key_id.placeholder"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.secret_access_key.error"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.secret_access_key.label"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.secret_access_key.placeholder"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.region.help"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.region.label"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.region.placeholder"])}
        },
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.storage_location.help"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.storage_location.label"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.storage_location.placeholder"])}
        },
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.change_key.button"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.amazon_connect.cancel_changing_link"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.integration_exists"])},
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.add_custom"])},
      "contact_to_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.contact_to_add"])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.contact_to_add_link"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.start_connection"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.first_sync_alert"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.sync_only_v2"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.active"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.add"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.add_new"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.add_to_workspaces_label"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.add_to_workspaces_help"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.all_workspaces"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.api_key"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.api_key_secret"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_name"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.connection_deleted"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.created_at"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.created_cordless"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.csat_threshold"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.deletion_hint"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.deletion_string"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.delete_title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.description"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.edit_connection"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.exclude"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.excluded_names"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.failed_update"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.give_unique_name"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.group_list_label"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.hide_advanced_options"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.hide_sensitive_fields"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.ignore_attachment_help"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.ignore_attachment_label"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.ignore_content_help"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.ignore_content_label"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.ignore_subject_help"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.ignore_subject_label"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.inactive"])},
      "integration_in_beta_warning_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.integration_in_beta_warning_4"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.integration_subdomain"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.intercom_region_label"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.intercom_team_id_label"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.intercom_team_id_placeholder"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.loading"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.mailbox_list_label"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.manual_connection_update"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.mask_data_label"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.no_workspaces"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.placeholder"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.placeholder_zendesk"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.renew_connection.title"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.renew_tooltip"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.salesforce_sandbox_label"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.setting_up_with"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.show_advanced_options"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.show_csat_help"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.show_csat_label"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.sync_only"])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.sync_tooltip"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.tag_list_label"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.title"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.update_connection"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.updated"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.username"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.your_help_desk"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.connections.channel_list_label"])}
    },
    "survey": {
      "create": {
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.link_text"])},
        "draft": {
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.draft.mail_subject"])},
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.draft.intro_message"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.draft.name"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.draft.question"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.draft.thank_you_message"])}
        },
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.template_modal.title"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.template_modal.help"])}
        },
        "type": {
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.type.csat"])},
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.type.ces"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.type.custom"])}
        },
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.new_survey_button"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.description_title"])},
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.description_message"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.survey_name"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.create.toast"])}
      },
      "edit": {
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.list_item.not_visible"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.list_item.visible"])}
          },
          "heading": {
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.heading.languages"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.heading.name"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.heading.status"])},
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.heading.created_date"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.heading.surveys_sent"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.list.heading.surveys_sent_tooltip"])}
          }
        },
        "publish_disabled_tooltip": {
          "second_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.publish_disabled_tooltip.second_row"])}
        },
        "review_translations_modal": {
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.missing_text_tooltip"])},
          "sections": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.question"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.mail_subject"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.intro_message"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.left_scale_label"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.right_scale_label"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.reasons"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.sections.thank_you_message"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.subtitle"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.missing_text"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.translated"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.review_translations_modal.apply_button"])}
        },
        "errors": {
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.errors.no_empty_field"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.errors.incorrect_email"])},
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.errors.incorrect_color"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.errors.incorrect_url"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.ai_assist.title"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.ai_assist.help"])}
            },
            "define_type": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.placeholder"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.help"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.duplicate_error"])},
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.no_types_tooltip"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.label"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.empty_error"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.not_saved_error"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.define_type.edit"])}
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.privacy_policy_label"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.tos_label"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.ces.questions.1"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.ces.questions.2"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.ces.questions.3"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.ces.questions.4"])}
              }
            },
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.senders_name"])},
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.senders_email"])},
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.custom_question"])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.custom_question_placeholder"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.customize_urls"])},
            "edit_types": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.edit_types.title"])},
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.edit_types.content"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.title"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.email"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.description"])},
            "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.tokens"])},
            "tokens_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.tokens_description"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.email_subject"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.intro_question"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.survey_type"])},
            "survey_types": {
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.survey_types.csat"])},
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.survey_types.ces"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.survey_types.custom"])}
            },
            "question_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.question_label"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.custom_question_option"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.csat.questions.1"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.csat.questions.2"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.csat.questions.3"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.content.csat.questions.4"])}
              }
            }
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.description"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.upload_logo"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.logo_label"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.logo_info"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.logo_background"])},
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.buttons_and_links"])},
            "brand_name": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.brand_name.label"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.styling.brand_name.help"])}
            }
          },
          "delivery": {
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.intercom_help_article"])},
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.email_delay"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.email_delay_follow_up"])},
            "empty_state": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.empty_state.title"])},
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.empty_state.add_connection"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.empty_state.message"])}
            },
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.delivery_option"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.title"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.description"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.open_zendesk"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.copy_code"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.preview"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.language"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.unsaved_changes"])},
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.snippet_copied_toast"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.snippet_copy_error_toast"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.installation_guide"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.embedded_snippet.preview_modal.title"])}
              }
            },
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.when_to_send"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.when_to_send_follow_up"])},
            "links_expire": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.links_expire.label"])},
              "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.links_expire.days"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.links_expire.help"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.links_expire.expire_in"])}
            },
            "modal": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.title"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.title_follow_up"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.subtitle"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.brand_domain_help"])},
              "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.validation_error"])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.validation_error_tooltip"])},
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.and"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.validation_error_link"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.add_another_rule"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.confirm"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.condition_placeholder"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.values_placeholder"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.placeholder"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.tag_list_placeholder"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.brand_domain_placeholder"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.modal.validation_error_rules"])}
            },
            "errors": {
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_no_rules_error"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_disabled_text"])},
              "connection_disabled_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_disabled_action"])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_disabled_link"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_permissions_link"])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_masked_data_link"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.intercom_reauth_text"])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.intercom_reauth_action"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_disabled"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_permissions"])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.errors.connection_masked_data"])}
            },
            "conversation_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.conversation_rule"])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.deliver_via_info"])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.follow_up_unit"])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.follow_up_minutes"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.follow_up_hours"])},
            "follow_up_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.follow_up_duration"])},
            "intercom_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.intercom_rules"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.add_rule"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.add_brand_rule"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.authenticate"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.chat"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.connect_intercom"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.intercom_description"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.description"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.deliver_via"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.edit_rules"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.intercom_rules_cta"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.enabled"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.title"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.when_to_send_info"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.list.title"])},
              "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.delivery.list.validation_error"])}
            }
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.description"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.reasons_subtitle"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.title"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.numbers"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.emojis"])},
            "scale_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.scale_title"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.scale_subtitle"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.reasons_title"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.reasons_warning"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.reason_input_placeholder"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.reason_validation_message_empty"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.reason_validation_message_exists"])},
            "remove_reason_confirmation": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.remove_reason_confirmation.title"])},
              "title_with_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.remove_reason_confirmation.title_with_name"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.remove_reason_confirmation.message"])},
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.remove_reason_confirmation.confirmation_button"])}
            },
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.left_scale_text_label"])},
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.right_scale_text_label"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.rating_scales.scale_text_placeholder"])}
          },
          "languages": {
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.description.link"])},
              "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.description.template"])}
            },
            "remove_translation_modal": {
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.remove_translation_modal.message_1"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.remove_translation_modal.title"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.remove_translation_modal.message_2"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.remove_translation_modal.message_3"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.remove_translation_modal.confirm"])}
            },
            "language_disabled_tooltip": {
              "second_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.language_disabled_tooltip.second_row"])}
            },
            "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.created"])},
            "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.updated"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.title"])},
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.add_button"])},
            "unsaved_changes_warninig": {
              "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.unsaved_changes_warninig.template"])},
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.unsaved_changes_warninig.save"])}
            },
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.columns.language"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.columns.visibility"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.columns.translations"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.default"])},
            "translation_complete": {
              "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.translation_complete.template"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.translation_complete.review_link"])},
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.translation_complete.download_link"])}
            },
            "translation_incomplete": {
              "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.translation_incomplete.template"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.translation_incomplete.review_link"])},
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.translation_incomplete.download_link"])}
            },
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.download_translation"])},
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.upload_translation"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.languages.remove_translation"])}
          },
          "thank_you": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.thank_you.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.thank_you.description"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.thank_you.message_label"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.sections.thank_you.message_help"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.toasts.deleted"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.toasts.duplicated"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.toasts.preview_sent"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.toasts.published"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.toasts.updated"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.toasts.paused"])}
        },
        "add_language_modal": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.subtitle"])},
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.not_found.cta"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.not_found.title"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.not_found.contact_us"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.title"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.contact_us"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.add_language_modal.add_button"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.last_published"])},
        "last_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.last_saved"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.duplicate"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.title"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.pause"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.pause_survey"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview"])},
        "preview_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.subtitle"])},
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.email_label"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.email_placeholder"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.language_label"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.send"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.preview_modal.web_preview"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.publish"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.resume"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.save_as_draft"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.unsaved_changes"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.update_and_resume_survey"])},
        "snippets": {
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.snippets.customer_name"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.snippets.support_rep"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.snippets.support_id"])},
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.snippets.brand_name.label"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.snippets.brand_name.tooltip_text"])}
          }
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.statuses.active"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.statuses.deleted"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.statuses.draft"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.edit.statuses.paused"])}
        }
      },
      "preview": {
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.powered_by_klaus"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.recap.title"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.recap.label"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.recap.description"])}
        },
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.reasons_placeholder"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.terms_of_service"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.thanks_for_feedback"])},
        "open_in_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.open_in_web"])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.suggestion"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.email"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.feedback_label"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.feedback_placeholder"])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.high_effort"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.low_effort"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.open_in_web_link"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.privacy"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.question_placeholder"])},
        "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.score_title"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.submit"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.thank_you"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.unsubscribe"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.very_dissatisfied"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.preview.very_satisfied"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.duplicate_modal.content"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.duplicate_modal.title"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.pause.maybe_embedded_snippet"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.pause.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.pause.message"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.pause.with_embedded_snippet"])}
        },
        "delete": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.delete.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.delete.message"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.delete.maybe_embedded_snippet"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.delete.with_embedded_snippet"])},
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.delete.confirm_button"])}
        },
        "publish": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.publish.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.publish.message"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.publish.with_embedded_snippet"])}
        },
        "resume": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.resume.title"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.resume.message"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.resume.maybe_embedded_snippet"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.resume.with_embedded_snippet"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.update.title"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.modal.update.with_embedded_snippet"])}
        }
      },
      "delete_modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.delete_modal.title"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.delete_modal.message"])}
      },
      "upsell_tooltip": {
        "first_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.upsell_tooltip.first_row"])},
        "cta_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.upsell_tooltip.cta_admin"])}
      },
      "additional_fees_message": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.additional_fees_message.text"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.survey.additional_fees_message.link"])}
      }
    },
    "danger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.title"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.warning"])},
      "description": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.description.template"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.description.bold"])}
      },
      "can_not_delete_prompt": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.can_not_delete_prompt.template"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.can_not_delete_prompt.link"])}
      },
      "delete_prompt": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_prompt.template"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_prompt.remove_and_delete_all_data"])},
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_prompt.action_cannot_be_undone"])}
      },
      "active_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.active_subscription"])},
      "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_account"])},
      "delete_prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_prompt_submit"])},
      "delete_prompt_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_prompt_title"])},
      "delete_time_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_time_note"])},
      "delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.delete_toast"])},
      "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.hint_for_confirm_string"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.information"])},
      "settings_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.settings_link"])},
      "subscription_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.subscription_link_label"])},
      "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.danger.type_to_confirm_string"])}
    },
    "general": {
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.danger_copy_2"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.default_reviewee.help"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.default_reviewee.assignee"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.default_reviewee.dominant"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.default_reviewee.label"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.self_reviews_help"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.workspace_deleted_toast"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.workspace_updated_toast"])},
      "delete_workspace_dialog": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.template"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.remove_and_delete_all_data"])},
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.action_cannot_be_undone"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.confirm_button_text"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.hint_for_confirm_string"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.title"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace_dialog.type_to_confirm_string"])}
      },
      "danger_default_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.danger_default_workspace"])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.danger_default_workspace_link"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.calibration_help"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.calibration_label"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.color_label"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.danger_copy_1"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.danger_copy_3"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.delete_workspace"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.deleting_workspace_toast"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.name_label"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.general.self_reviews_label"])}
    },
    "integrate": {
      "callback_image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.callback_image_alt"])},
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.callback_redirect_message"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.callback_success_title"])},
      "error": {
        "retry_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.error.retry_template"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.error.title"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.error.contact"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.error.description"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.error.retry"])}
      },
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.invalid.action_template"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.invalid.description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.invalid.title"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.invalid.connections"])},
        "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.invalid.image_alt"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.callback_waiting_message"])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.loading.description"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.loading.meanwhile"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.integrate.loading.title"])}
      }
    },
    "logins": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.email"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.error"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.success_toast"])},
      "auth_providers": {
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.auth_providers.magic_link"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.auth_providers.password"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.auth_providers.slack"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.auth_providers.okta_sso"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.auth_providers.google"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.description"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.password"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.saml"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.logins.title"])}
    },
    "navigation": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.auto_qa"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.workspaces"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.workspaces_help"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.title_uppercase"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.reviews"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.connections"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.logins"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.notifications"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.scorecard"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.subscription"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.title"])},
      "changes_in_progress": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.changes_in_progress.message"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.changes_in_progress.confirm"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.navigation.changes_in_progress.cancel"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.slack.add"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.slack.disconnect"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.slack.disconnect_slack"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.slack.disconnect_dialog"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.slack.disconnect_toast"])}
      },
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.override_settings"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.override_settings_help"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.override_settings_dialog"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.description"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.updated_toast"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.webhooks.title"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.webhooks.trigger_critical"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.webhooks.trigger_csat_answered"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.webhooks.triggers"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.notifications.webhooks.url"])}
      }
    },
    "reset_demo": {
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.refresh_desc"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.remove"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.create"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.title"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.subtitle"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.refresh_data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reset_demo.refresh"])}
    },
    "scorecard": {
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.binary_scale"])},
      "granular_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.granular_scale"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.mocked_category_name_2"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.mocked_category_name_3"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.modal.by_updating_rating_scales_1"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.modal.by_updating_rating_scales_2"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.modal.by_updating_rating_scales_3"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.modal.clone_categories_label"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.modal.title"])}
      },
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.option.not_applicable"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.toast_updated_message_with_action"])},
      "categories_preview_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.categories_preview_label"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.description"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.display_emojis_label"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.display_na_label"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.mocked_category_name_1"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.scale_options_label"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.title"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.toast_cloned"])},
      "toast_updated_action_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.toast_updated_action_label"])},
      "toast_updated_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.toast_updated_message"])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.scorecard.workspace_settings"])}
    },
    "subscription": {
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.call_transcription_upsell_alert.template"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.call_transcription_upsell_alert.link"])}
      },
      "end_modal": {
        "title": {
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.title.paused"])},
          "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.title.cancelled"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.title.start"])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.title.pause"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.title.cancel"])}
        },
        "pause_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.pause_for"])},
        "pause_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.pause_success"])},
        "cancel_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.cancel_success"])},
        "pause_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.pause_body"])},
        "cancellation_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.cancellation_notice"])},
        "changed_mind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.changed_mind"])},
        "noted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.noted"])},
        "reasons": {
          "missing_functionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reasons.missing_functionality"])},
          "missing_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reasons.missing_integration"])},
          "pricing_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reasons.pricing_reasons"])},
          "priorities_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reasons.priorities_changed"])},
          "not_enough_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reasons.not_enough_value"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reasons.other"])}
        },
        "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.reason_placeholder"])},
        "comment_placeholder": {
          "missing_functionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.comment_placeholder.missing_functionality"])},
          "missing_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.comment_placeholder.missing_integration"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.end_modal.comment_placeholder.other"])}
        }
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.manual_cancellation"])},
      "plan": {
        "advanced": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.name"])},
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features_title"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.advanced.features.item_7"])}
          }
        },
        "professional": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.name"])},
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features_title"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_7"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_8"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_9"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_10"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_11"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_12"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_13"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_14"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_15"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_16"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_17"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_18"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.professional.features.item_19"])}
          }
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_7"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_8"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_9"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features.item_10"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.starter.features_title"])}
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.flat_fee.name"])}
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features_title"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_7"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.enterprise.features.item_8"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features_title"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_7"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_8"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.ai.features.item_9"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_7"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_8"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_9"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_10"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_11"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_12"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features.item_13"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.growth.features_title"])}
        },
        "zendesk": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.name"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.description"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_6"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_7"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_8"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan.zendesk.features.item_9"])}
          }
        }
      },
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.plan_label"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.quality_management"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.updated_toast"])},
      "billed_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_to"])},
      "billed_to_cycle_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_to_cycle_month"])},
      "billed_monthly": {
        "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_monthly.zero"])},
        "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_monthly.one"])},
        "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_monthly.two"])},
        "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_monthly.few"])},
        "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_monthly.many"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billed_monthly.other"])}
      },
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.ending_in"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.free_trial_expires_in"])},
      "from_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.from_price"])},
      "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.license"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.used_seats_warning"])},
      "min_seats_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.min_seats_error"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.min_seats_montly_total"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.month"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.per_agent_per_month"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.per_year"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.per_month"])},
      "responses_included": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.responses_included.month"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.responses_included.quarter"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.responses_included.year"])}
      },
      "subscribe_modal_active_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.subscribe_modal_active_survey"])},
      "usage_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.usage_tooltip"])},
      "usage_tooltip_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.usage_tooltip_trial"])},
      "volume_pricing_legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.volume_pricing_legend"])},
      "transcription": {
        "addon_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.transcription.addon_name"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.transcription.price"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.transcription.disclaimer"])},
        "upsell": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.transcription.upsell.label"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.transcription.upsell.link"])}
        }
      },
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.quarter"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.used"])},
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.subscription_cancelled_on"])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.subscription_cancels_on"])},
      "remove_cancellation_warning": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.remove_cancellation_warning.template"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.remove_cancellation_warning.link"])}
      },
      "trial_pill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.trial_pill"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.will_resume_on"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.year"])},
      "talk_to_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.talk_to_sales"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.zendesk_trial_end"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.zendesk_trial_end_days"])},
      "support_email": {
        "trial": {
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.support_email.trial.subject"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.support_email.trial.body"])}
        },
        "customer": {
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.support_email.customer.subject"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.support_email.customer.body"])}
        }
      },
      "change_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.change_help"])},
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.zendesk_subscription_end"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.add_credit_card"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.all_plans"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billing"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billing_info_link"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.billing_interval"])},
      "call_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.call_transcription"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.cancel_subscription"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.cancel_subscription_disclaimer"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.contact_us"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.contact_us_to_edit"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.current_plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.custom_pricing"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.downgrade"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.dropdown_annual_old"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.dropdown_annual"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.dropdown_monthly"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.duration_toggle.prefix"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.duration_toggle.suffix"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.edit_subscription"])},
      "expansion_pack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.expansion_pack"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.explore_plans"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.free_trial"])},
      "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.go_back"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.invoiced_manually"])},
      "klaus_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.klaus_surveys"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.manage"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.modal_review_desc"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.most_popular"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.no_payment_method"])},
      "no_transcription_addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.no_transcription_addon"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.on_trial"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.pause_subscription"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.pause_readonly_tooltip"])},
      "purchase_addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.purchase_addon"])},
      "upcoming_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.upcoming_payment"])},
      "received_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.received_responses"])},
      "cost_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.cost_response"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.trial"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.period.month"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.period.quarter"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.period.year"])}
      },
      "addon": {
        "survey_lite": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features.item_3"])}
          },
          "features_bottom": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features_bottom.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features_bottom.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features_bottom.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_lite.features_bottom.item_4"])}
          }
        },
        "survey_plus": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features.item_4"])}
          },
          "features_bottom": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features_bottom.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features_bottom.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features_bottom.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_plus.features_bottom.item_4"])}
          }
        },
        "survey_premium": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features.item_5"])}
          },
          "features_bottom": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features_bottom.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features_bottom.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features_bottom.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.survey_premium.features_bottom.item_4"])}
          }
        },
        "transcription": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.name"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.features.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.features.item_2"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.features.item_3"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.features.item_4"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.features.item_5"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.addon.transcription.features.item_6"])}
          }
        }
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.price"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.reactivate_subscription"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.resume_subscription"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.seats_label"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.status.active"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.status.cancelled"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.status.non_renewing"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.status.paused"])}
      },
      "remove_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.remove_cancellation"])},
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.subscription_status"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.start_trial_now"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.upgrade"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.subscription.upgrade_subscription"])}
    },
    "trial_ended": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.title"])},
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.image_alt"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.title_zd_only"])},
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.continue_zd_only"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.upgrade_now"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.continue_zd_only_non_admin"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.continue_using"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.continue_using_non_admin"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.trial_ended.manage_payment"])}
    },
    "unconfigured_account": {
      "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.image_alt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.title"])},
      "to_continue_using_klaus_manager": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.to_continue_using_klaus_manager.template"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.to_continue_using_klaus_manager.link"])}
      },
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.no_workspace"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.switch_account"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.unconfigured_account.to_continue_using_klaus"])}
    },
    "workspaces": {
      "assignments": {
        "form": {
          "conditions_pro_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.conditions_pro_tip"])},
          "workspace_settings_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.workspace_settings_link"])},
          "steps": {
            "participants": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.participants.description"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.participants.title"])},
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.participants.all_users"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.participants.all_users_except_agents"])}
            },
            "goal": {
              "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.total"])},
              "per_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.per_reviewee"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.title"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.description"])},
              "reviewee_assign_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.reviewee_assign_count"])},
              "reviewee_assign_count_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.reviewee_assign_count_label"])},
              "reviewer_count_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.reviewer_count_label"])},
              "reviewee_count_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.reviewee_count_label"])},
              "type_radio_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.type_radio_title"])},
              "type_radio_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.type_radio_reviewers"])},
              "type_radio_reviewers_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.type_radio_reviewers_help"])},
              "type_radio_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.type_radio_reviewees"])},
              "type_radio_reviewees_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.goal.type_radio_reviewees_help"])}
            },
            "general": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.general.title"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.general.description"])},
              "recurring_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.general.recurring_label"])}
            },
            "conditions": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.conditions.title"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps.conditions.description"])}
            }
          },
          "steps_summary": {
            "excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.excluded"])},
            "included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.included"])},
            "filter_coverage": {
              "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.filter_coverage.good"])},
              "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.filter_coverage.bad"])},
              "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.filter_coverage.partial"])}
            },
            "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.reviewers"])},
            "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.participants"])},
            "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.reviewees"])},
            "per_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.per_cycle"])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.per_reviewee_in_row"])},
            "nr_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.nr_conversations"])},
            "of_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.of_agents"])},
            "all_covered_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.all_covered_in"])},
            "empty_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.empty_name"])},
            "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.cycle_label"])},
            "assignment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.assignment_type"])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.self_reviews"])},
            "show_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.show_preview"])},
            "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.recurring"])},
            "not_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.not_recurring"])},
            "regular_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.regular_reviews"])},
            "peer_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.peer_reviews"])},
            "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.start"])},
            "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.end"])},
            "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.weekly"])},
            "bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.bi_weekly"])},
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.monthly"])},
            "goal_per_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.goal_per_reviewer"])},
            "total_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.total_coverage"])},
            "all_agents_row_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.all_agents_row_1"])},
            "all_agents_row_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.all_agents_row_2"])},
            "number_of_filters": {
              "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.number_of_filters.number"])},
              "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.number_of_filters.template"])}
            },
            "filters_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.steps_summary.filters_applied"])}
          },
          "cycle_duration_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.cycle_duration_description"])},
          "assignment_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.assignment_period"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.discard_changes_dialog.description"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.discard_changes_dialog.title"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.discard_changes_dialog.confirm"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.discard_changes_dialog.cancel"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.duration.label.recurring.weekly"])},
                "bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.duration.label.recurring.bi_weekly"])},
                "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.duration.label.recurring.monthly"])}
              }
            }
          },
          "conditions_pro_tip_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.conditions_pro_tip_body"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.cycle_label"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.cycle_label_weekly"])},
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.cycle_label_bi_weekly"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.cycle_label_monthly"])},
          "date_range_ends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.date_range_ends"])},
          "date_range_starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.date_range_starts"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.name_label"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.participants"])},
          "participants_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.participants_label"])},
          "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.random"])},
          "repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.repeats"])},
          "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewers"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewers_label"])},
          "reviewers_label_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewers_label_exclude"])},
          "participants_label_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.participants_label_exclude"])},
          "reviewers_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewers_placeholder"])},
          "reviewers_placeholder_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewers_placeholder_exclude"])},
          "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewees"])},
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewees_label"])},
          "reviewees_label_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewees_label_exclude"])},
          "reviewees_placeholder_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewees_placeholder_include"])},
          "reviewees_placeholder_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.reviewees_placeholder_exclude"])},
          "select_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.select_participants"])},
          "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.self_reviews_label"])},
          "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.self_reviews_help"])},
          "sort_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.sort_label"])},
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.title_base"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.title_edit"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.title_new"])},
          "type": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.type.title"])},
            "option_1_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.type.option_1_label"])},
            "option_1_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.type.option_1_help"])},
            "option_2_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.type.option_2_label"])},
            "option_2_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.form.type.option_2_help"])}
          }
        },
        "preview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_title"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.read_more"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.a_user"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.details"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_subtitle"])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_non_recurring"])},
        "preview_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_cycle"])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_cycle_non_recurring"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.reviewing"])},
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.read_more_url"])},
        "active_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.active_label"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.add"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.created"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.delete_dialog_body"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.delete_dialog_title"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.deleted"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.duplicated"])},
        "empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.empty_description"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.empty_title"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.failed"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.name"])},
        "preview_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_button"])},
        "preview_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.preview_empty"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.updated"])},
        "user_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments.user_removed"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.title"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.next.label"])}
            },
            "immediate": {
              "help_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.immediate.help_explanation"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.immediate.help_text"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.immediate.label"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.immediate.help"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.changes.submit"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.inactive_changes.description"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.inactive_changes.title"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.header.title_base"])}
        },
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.draft"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.inactive"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.active"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.drafted"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.inactivated"])},
        "steps": {
          "participants": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.title"])},
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.exclude"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.type_of_users.workspace_default.help"])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.type_of_users.workspace_default.label"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.type_of_users.label"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.type_of_users.most_active_agent.label"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.type_of_users.participant.label"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.type_of_users.assignee.label"])}
              }
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees_type.label"])}
            },
            "reviewees": {
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.user_reviewees"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.bot_reviewees"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.all_participants"])},
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.all_bots"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.label"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.placeholder"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.all"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewees.error"])}
            },
            "self_reviews": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.self_reviews.label"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.self_reviews.not_allowed"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.self_reviews.error"])}
            },
            "reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewers.label"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewers.placeholder"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewers.all"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.reviewers.error"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.exclude_reviewers.label"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.exclude_reviewers.placeholder"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.exclude_reviewees.label"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.exclude_reviewees.placeholder"])}
            },
            "dropdown_sections": {
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.dropdown_sections.users"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.dropdown_sections.groups"])},
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.participants.dropdown_sections.bots"])}
            }
          },
          "goal": {
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.approach.choose"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.approach.label"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.approach.assigned"])}
            },
            "review_goal": {
              "options": {
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.options.percent"])},
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.options.conversations"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.options.reviews"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.reviewer.append"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.reviewer.description"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.label"])},
              "errors": {
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.errors.between"])},
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.errors.at_least"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.conversation.description"])}
              },
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.review_goal.reviewee.description"])}
              }
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.minimum.append"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.minimum.label"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.minimum.error"])}
            },
            "replacing_conversations": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.replacing_conversations.text"])},
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.replacing_conversations.text_v2"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.replacing_conversations.label"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.replacing_conversations.description"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.integer_error"])},
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.advanced_settings"])},
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.self_reviews.description_v2"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.self_reviews.label"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.self_reviews.description"])}
            },
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.fill_goal.description"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.fill_goal.help_text"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.goal.title"])}
          },
          "summary": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.error"])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.self_reviews"])},
            "replacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.replacing"])},
            "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.approach"])},
            "goal": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.error"])},
              "conversation": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.conversation.conversations"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.conversation.percent"])},
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.conversation.all"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.reviewer"])},
              "reviewee": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.reviewee.conversations"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.reviewee.percent"])},
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.goal.reviewee.all"])}
              }
            },
            "cycle_never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_never"])},
            "cycle_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_daily"])},
            "cycle_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_other"])},
            "date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.date_conditions"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.other_conditions_no_conversation"])},
            "other_conditions_no_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.other_conditions_no_helpdesk"])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.other_conditions_no_helpdesk_condition"])},
            "other_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.other_conditions"])},
            "cycle_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_monday"])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_tuesday"])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_wednesday"])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_thursday"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_friday"])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_saturday"])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.cycle_sunday"])},
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.auto_fetching"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.title"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.no_name"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.summary.no_date_conditions"])}
          },
          "general": {
            "cycle_settings": {
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.start_label"])},
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.end_label"])},
              "repeats": {
                "recurring_same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.recurring_same"])},
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.monday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.monday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.monday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.monday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.monday.5"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.tuesday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.tuesday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.tuesday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.tuesday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.tuesday.5"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.wednesday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.wednesday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.wednesday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.wednesday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.wednesday.5"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.thursday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.thursday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.thursday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.thursday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.thursday.5"])}
                },
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.friday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.friday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.friday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.friday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.friday.5"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.saturday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.saturday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.saturday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.saturday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.saturday.5"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.sunday.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.sunday.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.sunday.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.sunday.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.sunday.5"])}
                },
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.recurring_start"])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.label"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.1"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.2"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.3"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.4"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.5"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.custom"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.day"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.weekday"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.first_of_every_month"])}
              },
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.title"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.timezone_label"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.cycle_settings.no_results"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.description"])},
            "basic_info": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.basic_info.title"])},
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.general.basic_info.name_label"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.matching_conversations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.title"])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.description.0"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.description.1"])}
            },
            "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.reviewers"])},
            "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.reviewees"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.no_reviewers"])},
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.preview.no_reviewees"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.steps.conditions.title"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.date_weekday_from_at_time"])},
        "form": {
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.form.cycle_label_one_off"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.form.cycle_label_daily"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.form.cycle_label_weekly"])},
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.form.cycle_label_bi_weekly"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.form.cycle_label_monthly"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.form.cycle_label"])}
        },
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.cancel"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.create"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.updated"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.created"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.next_cycle_warning"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.mark_active"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.mark_inactive"])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.publish_changes"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.status.active"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.status.draft"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.status.inactive"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.status.pending"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.assignments_v_2.update"])}
      },
      "rating_categories": {
        "categories_type_modal": {
          "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.cta"])},
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.explanation_dynamic"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.scorecard.chat"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.scorecard.phone"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.scorecard.onboarding"])}
          },
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.explanation_static"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.prompt.body"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.prompt.confirm_button"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.prompt.title"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.radio_label_dynamic"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.radio_label_static"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.subtitle"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.categories_type_modal.title"])}
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_needs_name_toast"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_needs_scorecard_toast"])},
        "edit": {
          "account_settings_info": {
            "closing": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.closing.description"])},
              "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.closing.count"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.closing.title"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.closing.modal_title"])}
            },
            "grammar": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.grammar.title"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.grammar.description"])},
              "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.grammar.count"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.grammar.modal_title"])}
            },
            "greeting": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.greeting.description"])},
              "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.greeting.count"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.greeting.title"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.account_settings_info.greeting.modal_title"])}
            }
          },
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_causes"])},
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_toggle"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_toggle_help"])},
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_category"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_category_placeholder"])},
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.greeting.42"])}
              },
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.grammar.4"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.grammar.42"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.closing.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.closing.42"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.empathy.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.empathy.42"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.readability.0"])},
                "1": {
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.readability.1.long_sentences"])},
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.readability.1.complex_words"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.readability.2"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.klaus_model.readability.42"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.issue_understanding.0"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.issue_understanding.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.issue_understanding.42"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.tone.0"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.tone.1"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.tone.2"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.tone.3"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.tone.4"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.tone.42"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.closing.0"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.closing.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.closing.42"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.empathy.0"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.empathy.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.empathy.42"])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.greeting.0"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.greeting.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.greeting.42"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.solution.0"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.solution.1"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_rating_descriptions.open_ai.solution.42"])}
              }
            }
          },
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_consent_hint.link"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_consent_hint.approved"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_consent_hint.removed"])}
          },
          "auto_categories": {
            "empathy": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.empathy.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.empathy.open_ai_model_description"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.empathy.name"])}
            },
            "grammar": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.grammar.name"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.grammar.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.grammar.open_ai_model_description"])},
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.grammar.klaus_model_description_with_bots"])}
            },
            "closing": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.closing.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.closing.open_ai_model_description"])},
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.closing.klaus_model_description_with_bots"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.closing.name"])}
            },
            "greeting": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.greeting.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.greeting.open_ai_model_description"])},
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.greeting.klaus_model_description_with_bots"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.greeting.name"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.tone.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.tone.open_ai_model_description"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.tone.name"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.solution.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.solution.open_ai_model_description"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.solution.name"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.issue_understanding.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.issue_understanding.open_ai_model_description"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.issue_understanding.name"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.readability.klaus_model_description"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.readability.open_ai_model_description"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.readability.name"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.readability.long_sentences_count.1"])},
                "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_categories.readability.long_sentences_count.count"])}
              }
            }
          },
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.choose_auto_qa_category_toast"])},
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.complex_words"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.long_sentences"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.grammar"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.misspelling"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.style"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.other"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.neutral_sorry"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.pos_professional"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.negative"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.pos_supportive"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.pos_calm"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.pos_cheerful"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_root_cause.pos_inquisitive"])}
          },
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.auto_qa_causes_help"])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.edit.manual_causes"])}
        },
        "category_delete_confirmation": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation.template"])},
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation.action_cannot_be_undone"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation.delete_all_the_ratings"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation.keep_statistics"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.description_placeholder"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.label"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.always_conflict"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.option.always"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.option.helpdesk_tag"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.option.satisfaction_score"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.option.source_type"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.option.ticket_channel"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.filter.option.placeholder"])}
          }
        },
        "warnings": {
          "change_scale_body": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.warnings.change_scale_body.item_1"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.warnings.change_scale_body.item_2"])}
          },
          "change_scale_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.warnings.change_scale_title"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.warnings.confirm"])}
        },
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.add_button"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.archive"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_added_toast"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_archive_confirmation_body"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_archive_confirmation_delete"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_archive_confirmation_title"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_archived"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation_delete"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation_hint"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation_string"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_delete_confirmation_title"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_deleted"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_duplicated"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_group_added"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_group_deleted"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_group_order_changed"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_group_updated"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_unarchived"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.category_updated"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.critical_help"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.critical_label"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.description"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.duplicate"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.empty_group"])},
        "existing_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.existing_reason"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.form_title_base"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.form_title_add"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.form_title_edit"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.group_delete_confirmation_checkbox"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.group_delete_confirmation_intro"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.group_delete_confirmation_title"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.group_delete_confirmation_warning"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.group_name_placeholder"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.name_placeholder"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.new_group_button"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.new_group_placeholder"])},
        "new_reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.new_reason_placeholder"])},
        "visibility": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.visibility.label"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.visibility.all"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.visibility.negative"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.visibility.negative_and_neutral"])}
        },
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.multiple_reasons_label"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.multiple_reasons_checkbox"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.multiple_reasons_explanation"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.add_category"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.update_category"])},
        "preview_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.preview_label"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.reason_label"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.reason_other"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.reason_add_other"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.reason_add_root_cause"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.reason_empty_error"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.reason_duplicate_error"])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.scale_label"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.tabs.active"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.tabs.archived"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.tags"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.tags_label"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.tags_label_placeholder"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.unarchive"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.weight"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.weight_info"])},
        "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.rating_categories.weight_label"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.read_more"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.more"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.calibration"])},
          "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.introduction"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.body"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.professional"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.advanced"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.summary"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.upsell.button"])}
        },
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.description"])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.enabled_label"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.visibility_title"])},
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.copy_existing_review_title"])},
        "copy_existing_review": {
          "no": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.copy_existing_review.no.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.copy_existing_review.no.help"])}
          },
          "yes": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.copy_existing_review.yes.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.copy_existing_review.yes.help"])}
          }
        },
        "reviewer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.reviewer.label"])},
          "own": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.reviewer.own.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.reviewer.own.help"])}
          },
          "all": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.reviewer.all.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.reviewer.all.help"])}
          }
        },
        "lead": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.lead.label"])},
          "own": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.lead.own.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.lead.own.help"])}
          },
          "all": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.lead.all.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.lead.all.help"])}
          }
        },
        "manager": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.manager.label"])},
          "own": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.manager.own.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.manager.own.help"])}
          },
          "all": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.manager.all.label"])},
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.manager.all.help"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.calibration.settings_updated_toast"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.threshold_explanation"])},
      "scorecard": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.scorecard.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.scorecard.description"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.scorecard.button"])}
      },
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.assignments"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.calibration"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.connections"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.general"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.members"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.scorecard"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.settings_titles.threshold"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.workspaces.threshold_updated_toast"])}
    },
    "spotlight": {
      "deadair": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.deadair.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.deadair.description"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.deadair.filter_description"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.deadair.description_v2"])}
      },
      "recording_disclosure": {
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.recording_disclosure.spotlight_setup_alert"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.recording_disclosure.description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.recording_disclosure.title"])}
      },
      "dead_air": {
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.dead_air.description_v2"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.dead_air.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.dead_air.description"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.dead_air.tooltip_description"])}
      },
      "delete": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.delete.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.delete.description"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.delete.success"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.delete.failure"])}
      },
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.create.message_type"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.description.create"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.name"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.parameters"])},
      "apply_rule_to_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.apply_rule_to_all"])},
      "apply_rule_to_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.spotlight.apply_rule_to_specific"])}
    },
    "category": {
      "delete": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.category.delete.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.category.delete.description"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.category.delete.description_warning"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.category.delete.success"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.category.delete.failure"])}
      }
    },
    "delete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.delete.title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.delete.description"])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.delete.description_link"])}
    },
    "deleted_conversations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.title"])},
      "body_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.body_v2"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.body"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.button_label"])},
      "list": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.list.title"])},
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.list.delete_button"])},
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.list.search_box_placeholder"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.list.ticket_item_id"])},
        "empty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.list.empty.title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.list.empty.subtitle"])}
        }
      },
      "delete_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.delete_dialog.title"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.delete_dialog.body"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.delete_dialog.confirm_button"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.deleted_conversations.delete_dialog.toast"])}
      }
    },
    "no_conversations_found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.no_conversations_found.title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.no_conversations_found.description"])}
    },
    "reviews": {
      "tabs": {
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.tabs.scorecards"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.tabs.categories"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.tabs.root_causes"])}
      },
      "create_new": {
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.create_new.scorecard"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.create_new.category"])}
      },
      "settings_modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.settings_modal.title"])},
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.settings_modal.allow_skip.all"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.settings_modal.allow_skip.all_help"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.settings_modal.allow_skip.specify"])}
        }
      },
      "pickers": {
        "workspaces": {
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.workspaces.no_results"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.workspaces.select_all"])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.workspaces.two"])},
          "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.workspaces.few"])},
          "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.workspaces.many"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.workspaces.other"])}
        },
        "types": {
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.types.select_all"])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.types.two"])},
          "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.types.few"])},
          "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.types.many"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.types.other"])}
        },
        "scorecards": {
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.scorecards.select_all"])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.scorecards.two"])},
          "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.scorecards.few"])},
          "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.scorecards.many"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.scorecards.other"])}
        },
        "categories": {
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.categories.select_all"])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.categories.two"])},
          "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.categories.few"])},
          "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.categories.many"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.categories.other"])}
        },
        "statuses": {
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.statuses.select_all"])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.statuses.two"])},
          "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.statuses.few"])},
          "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.statuses.many"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.pickers.statuses.other"])}
        }
      },
      "categories": {
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.search_placeholder"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.table.headings.category"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.table.headings.type"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.table.headings.type_tooltip"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.table.headings.scorecards"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.table.headings.update"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.types.auto.label"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.types.manual.label"])}
          }
        },
        "delete_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.delete_modal.title"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.delete_modal.description_1"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.delete_modal.description_2"])},
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.delete_modal.delete_button"])}
        },
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.delete_disabled_system"])},
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.edit_drawer.custom_category_type"])},
          "manual": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.edit_drawer.manual.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.edit_drawer.manual.description"])}
          },
          "exact": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.edit_drawer.exact.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.edit_drawer.exact.description"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.categories.edit_drawer.exact.disabled"])}
          }
        }
      },
      "root_causes": {
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.search_placeholder"])},
        "table": {
          "headings": {
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.table.headings.root_cause"])},
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.table.headings.categories"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.table.headings.update"])}
          }
        },
        "edit_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.edit_modal.title"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.edit_modal.name_label"])}
        },
        "delete_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.delete_modal.title"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.delete_modal.description_1"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.delete_modal.description_2"])},
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.root_causes.delete_modal.delete_button"])}
        }
      },
      "scorecards": {
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.search_placeholder"])},
        "table": {
          "headings": {
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.table.headings.scorecard"])},
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.table.headings.categories"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.table.headings.workspaces"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.table.headings.status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.table.headings.update"])}
          }
        },
        "delete_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.delete_modal.title"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.delete_modal.description_1"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.delete_modal.description_2"])},
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.delete_modal.delete_button"])}
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.title"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.publish"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.save_draft"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.name_placeholder"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.manual_workspaces_label"])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.auto_workspaces_label"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.auto_workspaces_disabled_tooltip"])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.auto_workspaces_enabled_tooltip"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.auto_workspaces_placeholder"])},
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.add_category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.add_group_section"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.review_critical_category"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.weight_tooltip"])},
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.group_critical_category"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.critical_category_group"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.critical_category_review"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.group_name_placeholder"])},
          "edit_category": {
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_category.remove"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_category.edit_general_settings"])},
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_category.category_label"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_category.scale_label"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_category.weight_label"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_category.category_placeholder"])}
          },
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.total_weight_label"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.total_weight_info"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.category_weight_info"])},
          "errors": {
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.errors.name_required"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.errors.no_workspaces"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.errors.no_categories"])},
            "bad_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.errors.bad_weight"])}
          },
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.critical_category_label"])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.conditional_category"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.critical_category_info_v2"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.root_causes_label"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.rating_heading"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.root_causes_heading"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.root_causes_tooltip"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.root_causes_placeholder"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.root_causes_other"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.root_causes_multiple"])},
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.edit_root_causes"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.category_criticality_change_info"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.create.published_successfully"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.edit.title"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.scorecards.delete_disabled"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.status.types.active.label"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.status.types.draft.label"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.status.types.inactive.label"])}
          }
        }
      },
      "no_results": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.no_results.subtitle"])},
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.no_results.scorecards.title"])}
        },
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.no_results.categories.title"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.no_results.root_causes.title"])}
        }
      },
      "table": {
        "actions": {
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.table.actions.mark_inactive"])},
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.table.actions.mark_active"])}
        }
      },
      "toast": {
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.changes_applied"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.scorecard_deleted"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.scorecard_inactive"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.scorecard_active"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.scorecard_duplicated"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.category_deleted"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.toast.root_cause_deleted"])}
      },
      "create_category_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.create_category_drawer.title"])},
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.create_category_drawer.submit_button"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.create_category_drawer.submit_message"])}
      },
      "edit_category_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.title"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.info"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.closing"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.issue_understanding"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.grammar"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.empathy"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.greeting"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.readability"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.solution"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_category_drawer.tone"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.reviews.edit_general_drawer.title"])}
      }
    },
    "auto_qa": {
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.auto_qa.dynamic_content_tooltip"])},
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.auto_qa.conflicting_error_msg"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.auto_qa.conflicting_error_msg_toast"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.autoqa.create.phrase_row_limit_tooltip"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.settings.title"])}
  },
  "team": {
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.integrate.template"])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.integrate.link_copy"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.linked_toast"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.attach_connection"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.attach_connections"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.connection"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.contact_admin"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.contact_klaus"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.no_connections"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.no_integrations_yet"])},
      "remove_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.remove_dialog.title"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.remove_dialog.body"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.remove_dialog.confirm_button"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.connection.select_placeholder"])}
    },
    "hashtags": {
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.add_hashtags_placeholder"])},
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.delete_dialog_body"])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.add_button"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.add_hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.add_new"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.allow_creation"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.column_header_hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.column_header_used"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.delete_dialog_confirm"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.delete_dialog_title"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.hashtag_creation_toast_locked"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.hashtag_creation_toast_unlocked"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.hashtag_deleted_toast"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.hashtag_renamed_toast"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.hashtags_added"])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.new_name"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.no_hashtags"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.rename"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.rename_hashtag"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.separate_by_commas"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.team.hashtags.tag_filter_placeholder"])}
    }
  },
  "universal": {
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.auto_qa"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.copy_success"])},
    "errors": {
      "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.401"])},
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.403"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.404"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.500"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.slow_down"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.401_v2"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.errors.access_denied"])}
    },
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.login_expired"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.logins"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.no_empty_field"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.no_items_found"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.no_users_found"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.read_more"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.read_more_arrow"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.read_more_dot"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.learn_more"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.register_copy"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.owner"])},
    "account_role": {
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.account_role.user"])},
      "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.account_role.manager"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.account_role.admin"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.account_role.null"])}
    },
    "workspace_role": {
      "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.workspace_role.manager"])},
      "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.workspace_role.lead"])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.workspace_role.reviewer"])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.workspace_role.agent"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.workspace_role.null"])}
    },
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.sent"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.sure"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.team_overview"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.updated"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.and"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.applied"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.connected"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.created_at"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.created_by"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.critical"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.done"])},
    "duration": {
      "days_hours_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.days_hours_minutes"])},
      "days_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.days_hours"])},
      "days_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.days_later"])},
      "days_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.days_minutes"])},
      "days_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.days_long"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.days"])},
      "hours_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.hours_later"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.hours_minutes_later"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.hours_minutes_seconds_later"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.hours_seconds_later"])},
      "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.later"])},
      "minutes_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.minutes_later"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.minutes_seconds_later"])},
      "months_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.months_later"])},
      "seconds_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.seconds_later"])},
      "years_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.years_later"])},
      "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.seconds"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.second"])},
      "later_with_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.later_with_time"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duration.now"])}
    },
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.equals"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.former_member"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.here"])},
    "items_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.items_selected"])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.last_updated"])},
    "n_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.n_more"])},
    "n_more_simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.n_more_simple"])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.new_feature"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.none"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.not_equals"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.or"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.removed"])},
    "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.replies"])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_2.0"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_2.1"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_3.0"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_3.1"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_3.2"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_4.0"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_4.1"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_4.2"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_4.3"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_5.0"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_5.1"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_5.2"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_5.3"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_emoji_labels.scale_5.4"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.today"])},
    "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.unlimited"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.user"])},
    "weekdays": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.monday"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.tuesday"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.wednesday"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.thursday"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.friday"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.saturday"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weekdays.sunday"])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.yesterday"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.save_success"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.unsaved_changes_made"])},
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.role_none"])},
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.create_dropdown_tag"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.add"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.all"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.anonymous_user"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.assignee"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.cancel"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.change"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.ces"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.close"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.coming_soon"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.connect"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.continue"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.copy_cta"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.copy_link"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.create"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.csat"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.danger_zone"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.delete"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.deny"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.description"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.dialog_default_title"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.disable"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.discard_unsaved_changes"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.languages"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.archive"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.restore"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.duplicate"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.email"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.enable"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.export"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.filter"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.frequency"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.goal"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.got_it"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.helpdesk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.invite"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.iqs"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.name"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.never"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.next"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.notifications"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.ok"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.other"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.pass_rate"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.personal_settings"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.previous"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.profile"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.read_less"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.remove"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.resend"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.reviewee"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.reviewees"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.reviewer"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.reviewers"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.role"])},
    "no_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.no_role"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.save"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.save_changes"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_labels.scale_2"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_labels.scale_3"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_labels.scale_4"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scale_labels.scale_5"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.scorecard"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.search"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.select_frequency"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.settings"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.show_less"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.show_more"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.skip"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.source"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.step"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.submit"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.survey_feedback"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.surveys"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.time_range"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.type"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.unsaved_changes"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.update"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.users"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.weight"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.workspaces"])},
    "you_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.universal.you_invited"])}
  },
  "user_management": {
    "account_users": {
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.bubble.link_scim"])},
        "content_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.bubble.content_scim"])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.permission_changed"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.permission_modal.learn_more_link"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.permission_modal.title"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.permission_modal.do_not_ask"])},
        "help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.permission_modal.help_1"])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.permission_modal.help_2"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.remove.toast"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.remove.description"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.remove.description_ws_manager"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.remove.title"])}
      },
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.zendesk_manage_users_body"])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.zendesk_manage_users_link"])},
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.account_permissions.payment_manage_description"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.account_permissions.teams_description"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.account_permissions.title"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.account_permissions.user_description"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.account_users.title"])}
    },
    "add_members": {
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.toast"])},
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.link_connect"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.title"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.body"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.cta"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.cta_manager"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.link"])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.no_users.link_invite"])}
      },
      "add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.add_users"])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.select_workspace_permission"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.subtitle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.add_members.title"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_help"])},
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_mismatch.bold"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_mismatch.message"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_mismatch.footer"])}
      },
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_match.bold"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_match.message"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.toasts.users_connected"])},
        "users_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.toasts.users_invited"])}
      },
      "connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.connect_btn"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.invite.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.invite.title"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.invite_users"])},
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_connect_btn"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_invite_btn"])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.emails"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.individually_btn"])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.skip_inviting"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.subtitle"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_subtitle"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.bulk_placeholder"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.tippy"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.connect_users.title"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.hint"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.label"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.placeholder"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.title"])},
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.confirm_button"])},
      "access_checkbox": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.access_checkbox.label"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.create_workspace.access_checkbox.help"])}
      }
    },
    "groups": {
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.groups_link"])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.empty_state_readonly.content_link_text"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.empty_state_readonly.content"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.permission_picking_1"])},
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.other_members_count"])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.activate"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.create"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.edit"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.archived_group"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.created"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.default_group_name"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.name_taken"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.name_taken_archived"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.name_placeholder"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.remove"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.saved"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.unsaved_badge"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.user_input_help"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.workspaces"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.delete_body"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.delete_confirm_button"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.delete_prompt"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.other_members_msg"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.users_add"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.user_picker_placeholder"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.archived"])},
        "unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.unarchived"])},
        "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.restored"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.group_lead"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.group_role"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.member"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.set_lead"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.remove_lead"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.hidden_label"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.hidden_help"])},
        "hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.hidden"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.hidden_title"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group.visible"])}
      },
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.create_new"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.permission_picking_2"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.help_text"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.empty_state.content"])}
      },
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.search_placeholder"])},
      "n_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.n_groups"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.no_groups_found"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.no_groups_found_body"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.no_groups_title"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.no_active_groups_title"])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.no_active_groups_body"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.no_archived_groups_title"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.title"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group_name"])},
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.group_members"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.members"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.tabs.active"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.tabs.archived"])}
      },
      "errors": {
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.errors.empty_name_input"])},
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.errors.empty_members_input"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.groups.errors.existing_group"])}
      }
    },
    "invite_email": {
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.add_users_to_workspace_help"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.bulk_text_placeholder.these_all_work"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.bulk_text_placeholder.paste_emails"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.toast"])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.workspace_permission_help"])},
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.account_permission_help"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.add_another"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.add_users_to_account"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.add_users_to_workspace"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.add_users_to_account_help"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.add_users_to_workspace_picker"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.bulk_invite_btn"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.individually_btn"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.invite_toggle_account"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.invite_toggle_workspace"])},
      "invite_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.invite_users"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_email.title"])}
    },
    "seats_left": {
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.seats_left.paused_message"])},
      "pill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.seats_left.pill"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.seats_left.message"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.seats_left.view_details"])}
    },
    "sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.title"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.title_bots_workspaces"])},
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.bots"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.workspaces_uppercase"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.account_users"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.groups"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.sidebar.workspaces"])}
    },
    "user_filter": {
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_filter.bulk_search_modal.subtitle"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_filter.bulk_search_modal.placeholder"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_filter.bulk_search_modal.search"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_filter.bulk_search_modal.title"])}
      },
      "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_filter.rows"])}
    },
    "user_state": {
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.action_needed"])},
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.admin"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.user"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.no_permissions"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.active"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.all"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.connected"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.pending"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.pending_invite"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.scim_setup"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_state.status.manual_setup"])}
      }
    },
    "workspaces": {
      "permission_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.permission_changed"])},
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.remove.toast"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.remove.title"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.remove.description"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.remove.confirm_button"])}
      },
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.edit_permissions.title"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.edit_permissions.button"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.edit_permissions.change_permission_to"])}
      },
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.add_members"])},
      "permission_picker": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.permission_picker.title"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.permission_picker.workspace_manager_description"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.permission_picker.lead_description"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.permission_picker.workspace_reviewer_description"])},
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspaces.permission_picker.agent_description"])}
      }
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_user.more"])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_user.change_permission_to"])},
      "reinvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_user.reinvite"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_user.title"])},
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_user.toast"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.resend_invite_tooltip"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.selected"])},
    "selected_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.selected_users"])},
    "bots": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.title"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.search"])},
      "empty_state": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.empty_state.title"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.empty_state.subtitle"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.empty_state.link"])},
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.empty_state.learn_more_link"])}
      },
      "table": {
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.first_column_title"])},
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.type.generative"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.type.workflow"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.type.unknown"])}
        },
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.last_chat"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.reviewable.label"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.reviewable.yes"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.reviewable.no"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.reviewable.tooltip"])}
        },
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.manually_marked"])},
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.actions.mark_as_user"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.table.actions.mark_as_user_disabled"])}
        }
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.no_bots_found"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.no_bots_found_body"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.search_v2"])},
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bots.mark_as_user_modal.content"])}
      }
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_details"])},
    "bulk_edit": {
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bulk_edit.remove_from_groups"])},
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bulk_edit.add_to_groups"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bulk_edit.select_group"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bulk_edit.no_group"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.bulk_edit.all_groups"])}
    },
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.label"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.tooltip"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.tooltip_multi_account"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.modal.description"])}
      },
      "review_checkbox": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.review_checkbox.label"])},
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.review_checkbox.helper"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.mark_as_bot.toast"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.search_by_user"])},
    "columns": {
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.workspace"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.groups"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.last_active"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.account_permission"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.add_to_workspaces"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.full_name"])},
      "groups_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.groups_count"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.permission_tooltip_generic"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.permission_tooltip_account"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.remove_from_workspaces"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.source"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.user"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.workspace_permission"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.workspace_permission_managed"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.columns.workspaces"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.all_workspaces"])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.deselect"])},
    "delete_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.delete_users"])},
    "edit_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.edit_workspaces"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.invite_via_email"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.no_workspaces"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.permission_placeholder"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.resend_invite"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_item.connected_user"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_item.pending_email"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.user_item.scim_user"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.user_management.workspace_placeholder"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "error": {
          "sessions_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.error.sessions_exist"])},
          "workspace_intersection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.error.workspace_intersection"])}
        },
        "toast_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.toast_created"])},
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.suggested"])},
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.coachee_score_na"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.new_session"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.create_session_button"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.create_session_cta"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.create_session_title"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.suggested_list"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.coach_placeholder"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.coachee"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.coachee_placeholder"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.details"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.session_name"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.session_date"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.csat"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.landing_page.score"])}
      },
      "leaderboard": {
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.leaderboard.empty_search_body"])},
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.leaderboard.empty_body"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.leaderboard.empty_search_title"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.leaderboard.empty_title"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.leaderboard.iqs"])}
      },
      "thread": {
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.notes.title"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.notes.updated"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.share.leave_session"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.share.title"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.share.placeholder"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.share.button"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.share.info"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.share.shared"])}
        },
        "toast": {
          "participant_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.toast.participant_removed"])},
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.toast.leave_session"])},
          "participants_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.toast.participants_added"])}
        },
        "delete_body": {
          "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.delete_body.template"])},
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.delete_body.delete_session"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.session_title"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.created"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.updated"])},
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.actions.completed"])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.actions.current_user"])},
          "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.actions.created_by"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.actions.title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.actions.subtitle"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.actions.placeholder"])}
        },
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.new_action_item.placeholder"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.delete_confirm_button"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.delete_title"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.note_placeholder"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.note_author_placeholder"])},
        "pins": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.pins.title"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.pins.subtitle"])}
        },
        "private_notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.private_notes.title"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.private_notes.placeholder"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.private_notes.updated"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.stats.period"])}
        },
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.thread.delete_thread"])}
      },
      "sidebar": {
        "feedback": {
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.feedback.end_of_comments"])},
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.feedback.empty_state.message"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.feedback.empty_state.title"])}
          }
        },
        "pins": {
          "info": {
            "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.info.template"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.info.bold"])}
          },
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.empty_state.message"])},
            "hint": {
              "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.empty_state.hint.template"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.empty_state.hint.link"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.empty_state.title"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.empty_state.coachee_placeholder"])}
          },
          "add_to_session": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.add_to_session.label"])},
            "success_with_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.add_to_session.success_with_cta"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.add_to_session.disabled"])},
            "scroll_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.add_to_session.scroll_cta"])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.add_to_session.success"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.pins.end_of_comments"])}
        },
        "metrics": {
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.duration_na"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.iqs_value_na"])},
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.csat_value_na"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.change"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.quizzes"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.categories"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.csat"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.rating_category"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.dashboard"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.empty_state"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.metrics.average_review_time"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.title"])},
        "tabs": {
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.tabs.metrics"])},
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.tabs.feedback"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sidebar.tabs.pins"])}
        }
      },
      "list": {
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.empty.description"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.empty.search_title"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.empty.search"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.empty.subtitle"])}
        },
        "delete_all_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.delete_all_prompt"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.end_of_list"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.title"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.delete_confirm_button"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.delete_thread"])},
        "heading": {
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.heading.sessions"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.heading.participants"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.list.heading.last_session"])}
        }
      },
      "insights": {
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.filters.coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.filters.coachee"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.filters.all"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.filters.shared"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.title"])},
          "empty": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.empty.title"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.action_items.empty.description"])}
          }
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.legend.iqs"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.legend.reviews"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.legend.csat"])}
          },
          "tooltip": {
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.tooltip.reviews_label"])},
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.tooltip.iqs_label"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.tooltip.csat_label"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.progress_card.title"])}
        },
        "overview_card": {
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.coachee"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.coach"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.done"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.title"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.total"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.last_session"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.action_items"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.statuses.done"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.statuses.ongoing"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.statuses.overdue"])}
          },
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.overview_card.view_all"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.insights.title"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sessions_list.overdue"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sessions_list.upcoming"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sessions_list.past"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sessions_list.title"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sessions_list.create_session"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.sessions_list.empty"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.title"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.coachee"])},
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.coach"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.unscheduled"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.done"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.mark_as_done"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.mark_as_undone"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.rename"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.remove"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.toggle_list"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sessions.new_thread.user_placeholder"])}
      }
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.sidebar.reply_placeholder_thread"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.coaching.heading"])}
  },
  "pickers": {
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.skin_tone"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.accepted"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.partially_accepted"])},
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.mentions"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.newest"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.oldest"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.open"])},
    "reacted_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.reacted_with"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.rejected"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.resolved"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.reviews"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.search"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_languages.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_languages.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_languages.select_all"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_wordcloud_words.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_wordcloud_words.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_wordcloud_words.select_all"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_predicted_drivers.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_predicted_drivers.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_predicted_drivers.select_all"])}
    },
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.all"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.comments"])},
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.loading_results"])},
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.reaction"])},
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.users.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.users.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.users.select_all"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.ticket_channel.label"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.ticket_channel.select_all"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.ticket_channel.no_results"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.groups.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.groups.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.groups.select_all"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.surveys.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.surveys.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.surveys.select_all"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.scores.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.scores.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.scores.select_all"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.workspaces.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.workspaces.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.workspaces.select_all"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.hashtags.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.hashtags.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.hashtags.select_all"])}
    },
    "calibration_session": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.calibration_session.no_results"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.calibration_session.label"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.categories.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.categories.no_results"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.filters.no_results"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.source.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.source.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.source.select_all"])}
    },
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.scorecards.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.scorecards.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.scorecards.select_all"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.helpdesk_tags.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.helpdesk_tags.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.helpdesk_tags.select_all"])}
    },
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.question_type.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.question_type.no_results"])}
    },
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_predicted_dimentions.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_predicted_dimentions.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.csat_predicted_dimentions.select_all"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.survey_reasons.label"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.survey_reasons.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.survey_reasons.select_all"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.helpdesk_custom_fields.no_results"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.helpdesk_custom_field_values.no_results"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.pickers.helpdesk_custom_field_values.select_all"])}
    }
  },
  "tasks": {
    "all_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.all_done"])},
    "assignments": {
      "empty": {
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_base"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_lead"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.title_completed"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.title_no_assignments"])},
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_completed"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_no_assignments"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.title_adjusted"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_adjusted"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.title"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_reviewer"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.empty.subtitle_edit"])}
      },
      "link_review": {
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.link_review.reviewed_user"])},
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.link_review.mark_as_done"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.goal_date.not_available"])}
      },
      "discard_changes_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.discard_changes_dialog.title"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.discard_changes_dialog.confirm"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.discard_changes_dialog.cancel"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.assignments.self_reviews_disabled"])}
    },
    "calibrations": {
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.calibrations.empty.subtitle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.calibrations.empty.title"])}
      },
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.calibrations.time_na"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.calibrations.due_date"])}
    },
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.submit"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.title"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.title_single"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.current_reviewer"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.to_be_moved"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.new_reviewer"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.new_goal"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.select_placeholder"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reassign.all_workspace_users"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.title"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.edit"])},
      "reviewers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.title"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.placeholder"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.reviewer_label"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.reviews_label"])},
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.goal_label"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.no_reviewers"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.reassign_to_me"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.reviewers.reassign_to_other"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.info.cycle.title"])}
      }
    },
    "pickers": {
      "status": {
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.status.draft"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.status.done"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.status.removed"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.status.all"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.status.to_do"])}
      },
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewees.bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewees.users"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewees.all"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewees.all_except_me"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewees.search"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewees.no_results"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewers.all"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewers.all_except_me"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewers.assigned_me"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewers.search"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.pickers.reviewers.no_results"])}
      }
    },
    "time_left_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_days"])},
    "time_left_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_hours"])},
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.navbar.add_assignment"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.navbar.add_calibration"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.navbar.personal_assignments.title"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.navbar.workspace_assignments.title"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.no_active_assignments"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.no_assignments"])},
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.title_na"])},
    "time_left_duration": {
      "minutes": {
        "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.minutes.zero"])},
        "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.minutes.one"])},
        "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.minutes.two"])},
        "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.minutes.few"])},
        "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.minutes.many"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.minutes.other"])}
      },
      "hours": {
        "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.hours.zero"])},
        "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.hours.one"])},
        "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.hours.two"])},
        "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.hours.few"])},
        "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.hours.many"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.hours.other"])}
      },
      "days": {
        "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.days.zero"])},
        "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.days.one"])},
        "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.days.two"])},
        "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.days.few"])},
        "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.days.many"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left_duration.days.other"])}
      }
    },
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.goal"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.personal_goal"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.hide_completed_cycles"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.hide_completed_sessions"])},
    "remove": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.remove.placeholder"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.remove.disabled"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.remove.submit"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.remove.title"])}
    },
    "replace": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.placeholder"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.disabled"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.discard_changes"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.cancel_title"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.keep_changes"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.keep"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.not_found"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.remove"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.replace_remove"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.submit"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.replace.title"])}
    },
    "review_actions": {
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.edit_draft"])},
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.delete_draft"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.reassign_me"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.reassign_other"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.reassign_review"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.remove"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.review"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.view_more"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_actions.view_less"])}
    },
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.reviews_done"])},
    "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.review_goal"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.show_completed_cycles"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.show_completed_sessions"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.time_left"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.title"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.toast.replaced"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.tasks.toggle_list"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.completed_tooltip"])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.labels.cycle"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.labels.period"])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.labels.reviewer"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.total"])},
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.empty.description"])}
    },
    "buttons": {
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.buttons.preview"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.assignments_dashboard.buttons.edit"])}
    }
  },
  "routes": {
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.conversations.label"])},
      "keyword": {
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.conversations.keyword.conversations"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.conversations.keyword.review"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.conversations.keyword.filters"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.conversations.keyword.calibration"])}
      }
    },
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.bot_qa"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.assignments"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.auto_qa"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.disputes"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.calibration"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.categories"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.overview"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.reviews"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.label.surveys"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.type"])},
      "keyword": {
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.keyword.statistics"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.keyword.iqs"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.keyword.scores"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.dashboard.keyword.metrics"])}
      }
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.assignments.label"])}
    },
    "activity": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.type"])},
      "label": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.all"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.activity"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.received"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.started"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.comments"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.reviews"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.survey_feedback"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.label.reactions"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.keyword.my_comments"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.keyword.my_reviews"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.keyword.my_disputes"])}
      },
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.breadcrumb.disputes"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.breadcrumb.given"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.activity.breadcrumb.received"])}
      }
    },
    "coaching": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.coaching.type"])},
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.coaching.label.pins"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.coaching.label.quizzes"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.coaching.label.sessions"])}
      }
    },
    "settings": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.type"])},
      "label": {
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.auto_qa"])},
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.authentications"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.connections"])},
        "transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.transcriptions"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.subscription"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.surveys"])},
        "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.quality_management"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.scorecard"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.notifications"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.new_category"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.general"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.label.spotlight"])}
      },
      "keyword": {
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.integrations"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.helpdesk"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.payment"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.seats"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.billing"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.settings"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.organization"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.account"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.company"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.keyword.subscription"])}
      },
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.breadcrumb.account"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.breadcrumb.subscription"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.settings.breadcrumb.auto_qa"])}
      }
    },
    "profile_settings": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.type"])},
      "label": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.label.login"])},
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.label.create_template"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.label.notifications"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.label.templates"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.label.general"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.label.goal"])}
      },
      "keyword": {
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.templates"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.reset"])},
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.create_password"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.login"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.credentials"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.email"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.e_mail"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.user"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.review_goal"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.google"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.keyword.slack"])}
      },
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.breadcrumb.personal"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.routes.profile_settings.breadcrumb.templates"])}
      }
    }
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.example_key"])},
  "support_links": {
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.zendesk_help"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.managing_users_and_workspaces"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.pins_for_coaching"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.find_conversations_to_review"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.coversation_insights_view"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.scores_over_time"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.scores_by_category"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.dashboard_calculations_breakdown"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.category_scores_over_time"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.rating_scale_changes"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.creating_multiple_scorecards"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.spotlight"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.tracking_review_time"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.setting_up_and_using_groups"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.calibration"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.autoqa_autoscoring"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.advanced_connection_settings"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.zendesk_help_widget"])},
    "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.bots"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.bots_v2"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.community"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.whats_new"])},
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.support_links.about_open_ai"])}
  },
  "zendesk_product_tray": {
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.lotus"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.guide"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.workforce_management"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.gather"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.chat"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.talk"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.explore"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.sell"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.quality_assurance"])},
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.ai_agents"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.zendesk_product_tray.central_admin"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.funnelgraph.na"])}
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.review.rating_na"])}
  },
  "emoji_picker": {
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.user_na"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.search"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.notfound"])},
    "category": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.search"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.recent"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.smileys"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.people"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.nature"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.foods"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.activity"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.objects"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.places"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.symbols"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.flags"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.emoji_picker.category.custom"])}
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.provisioning.errors.account_not_found.title"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.provisioning.errors.account_not_found.body"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.provisioning.errors.account_not_found.link"])}
      },
      "account_not_migrated": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.provisioning.errors.account_not_migrated.title"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.provisioning.errors.account_not_migrated.body"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klausapp.provisioning.errors.account_not_migrated.return_link"])}
      }
    }
  }
}