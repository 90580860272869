import { nextTick, reactive, ref, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { session } from '@/composables/useSession'

export default createSharedComposable(() => {
  const highlightingMap = reactive({
    showReadabilityComplexWords: false,
    showReadabilityLongSentences: false,
    showGrammarMistakes: false,
  })

  const showIconMap = reactive({
    showReadabilityLongSentencesIcon: false,
    showEmpathyIcon: false,
    showGreetingIcon: false,
    showClosingIcon: false,
  })

  const fadeAnimationMessageId = ref('')
  const visibleRepetitionIconId = ref('')

  const resetAutoQaHighlighting = () => {
    if (!session.features.autoQa) return

    resetIconToggles()
    resetMistakesHighlight()

    fadeAnimationMessageId.value = ''
  }

  const resetMistakesHighlight = (keyToIgnore?: string) => {
    Object.keys(highlightingMap).forEach((key) => {
      if (key !== keyToIgnore) highlightingMap[key] = false
    })
  }

  const resetIconToggles = (keyToIgnore?: string) => {
    Object.keys(showIconMap).forEach((key) => {
      if (key !== keyToIgnore) showIconMap[key] = false
    })

    if (keyToIgnore !== 'visibleRepetitionIconId') visibleRepetitionIconId.value = ''
  }

  const scrollToElement = async (selector: string) => {
    await nextTick()

    const ticketResponses = document.querySelectorAll('[data-testid=ticket-response]')

    for (const ticketResponse of ticketResponses) {
      const element = ticketResponse.querySelector(selector)
      if (!element) continue

      const rect = element.getBoundingClientRect()
      const rectOut = rect.top < 140 || rect.bottom > (window.innerHeight || document.documentElement.clientHeight)
      if (rectOut) element.scrollIntoView({ behavior: 'smooth', block: 'center' })

      fadeAnimationMessageId.value = ticketResponse.getAttribute('id')?.split('-')[1] || ''

      break
    }
  }

  watch(
    () => highlightingMap.showReadabilityComplexWords,
    (show) => {
      if (show) {
        resetMistakesHighlight('showReadabilityComplexWords')
        resetIconToggles()
        scrollToElement('[class*=highlighted-mistake-]')
      }
    },
  )

  watch(
    () => highlightingMap.showReadabilityLongSentences,
    (show) => {
      if (show) {
        resetMistakesHighlight('showReadabilityLongSentences')
        resetIconToggles()
        scrollToElement('[class*=highlighted-mistake-]')
      }
    },
  )

  watch(
    () => highlightingMap.showGrammarMistakes,
    (show) => {
      if (show) {
        resetMistakesHighlight('showGrammarMistakes')
        resetIconToggles()

        scrollToElement('[class*=highlighted-mistake-]')
      }
    },
  )

  watch(
    () => showIconMap.showReadabilityLongSentencesIcon,
    (show) => {
      if (show) {
        resetMistakesHighlight()
        resetIconToggles('showReadabilityLongSentencesIcon')
        scrollToElement('[data-id=auto-qa-readability-icon]')
      }
    },
  )

  watch(
    () => showIconMap.showEmpathyIcon,
    (show) => {
      if (show) {
        resetMistakesHighlight()
        resetIconToggles('showEmpathyIcon')
        scrollToElement('[data-id=auto-qa-empathy-icon]')
      }
    },
  )

  watch(
    () => showIconMap.showGreetingIcon,
    (show) => {
      if (show) {
        resetMistakesHighlight()
        resetIconToggles('showGreetingIcon')
        scrollToElement('[data-id=auto-qa-greeting-icon]')
      }
    },
  )

  watch(
    () => showIconMap.showClosingIcon,
    (show) => {
      if (show) {
        resetMistakesHighlight()
        resetIconToggles('showClosingIcon')
        scrollToElement('[data-id=auto-qa-closing-icon]')
      }
    },
  )

  watch(visibleRepetitionIconId, (id) => {
    if (id) {
      resetMistakesHighlight()
      resetIconToggles('visibleRepetitionIconId')
      scrollToElement(`[data-id=insights-repetition-icon-${id}]`)
    }
  })

  return {
    highlightingMap,
    showIconMap,
    fadeAnimationMessageId,
    visibleRepetitionIconId,
    resetAutoQaHighlighting,
  }
})
