import type { CsatStatisticsRequest_BreakdownSource as BreakdownSource } from '@zendesk/zqa-services/survey-dashboard'
import type { FilterTimeStep, PaginatedResponse, SortedResponse, CoreFilterParams } from '../types'

export enum CsatOwnerSetting {
  ticketAssignee = 'ticketAssignee',
  agentWithMostReplies = 'agentWithMostReplies',
}

export enum DisplayDateSetting {
  csatReply = 'csatReply',
  ticketCreated = 'ticketCreated',
  csatSent = 'csatCreated',
}

export interface CsatFilterParams extends CoreFilterParams {
  userIds: number[]
  page?: number
  pageSize?: number
  step?: FilterTimeStep
  surveyIds: string[]
  questionTypeId: number
  connectionIds: number[]
  ticketTags: string[]
  csatReasons: string[]
  wordCloudWords: string[]
  predictedDimensions: string[]
  predictedTags: string[]
  rawCsatScores: (string | number)[]
  csatOwnerSetting?: CsatOwnerSetting
  displayDateSetting?: DisplayDateSetting
  target?: number
  breakdownSource?: BreakdownSource
  languages: string[]
}

interface SurveyRepliesRow {
  connectionId: string
  conversationExternalId: string
  connectionType: string
  csatCreatedAt: string
  agentId: string
  agentAvatar: string
  agentName: string
  comment: string
  rawCsatScore: string
}

export interface SurveyReplies extends PaginatedResponse, SortedResponse {
  items: SurveyRepliesRow[]
}

export interface Conversation {
  accountId: string
  connectionId: string
  createdAt: string
  externalId: string
  rawCsatScore: string
  sourceType: string
  subject: string
  workspaces: string[]
}
export interface ConversationList extends PaginatedResponse, SortedResponse {
  items: Conversation[]
}

export interface OverviewItem {
  label: string
  value: number
  change: number | null
  count: string
  normalisedScore: number
  disabled?: boolean
}
export interface Overview {
  csat: number
  csatChange: number | null
  csatValues: OverviewItem[]
  totalCount: string
}

export interface FunnelItem {
  count: string
  relative: number
  change: number
  notApplicable: boolean
}

export interface Funnel {
  conversations: FunnelItem
  surveys: FunnelItem
  responses: FunnelItem
  comments: FunnelItem
}

interface TimelineDotTooltipItem {
  count: string
  label: string
  normalisedScore: string
  value: number
}

interface TimelineTemplate {
  labels: string[]
  averageCsat: (number | null)[]
  averageScores?: (number | null)[]
  totalReviews?: (number | null)[]
  totalResponses: string[]
}

export interface Line {
  id: string
  csat: (number | null)[]
  responses: string[]
}

export interface Timeline extends TimelineTemplate {
  lines: Line[]
}

interface WorkspaceLine extends Line {
  details: { values: TimelineDotTooltipItem[] }[]
}

export interface WorkspacesTimeline extends TimelineTemplate {
  workspaces: WorkspaceLine[]
}

export interface CsatTimelineBreakdown extends TimelineTemplate {
  breakdown: {
    id?: string
    label: string
    data: WorkspacesTimeline
  }[]
}

export interface LegendItem {
  id: string
  name: string
  color?: string
  naValue?: boolean
}

export interface WordCloud {
  words: {
    label: string
    value: string
  }[]
  conversationCount: string
}

export interface CsatByUsers extends PaginatedResponse, SortedResponse {
  agentStatistics: {
    agentId: string
    agentName: string
    agentAvatar: string
    tickets: string
    csatTickets: string
    responseRate: {
      value: number
      change: number
    }
    csat: {
      value: number
      change: number
    }
  }[]
}

export interface CsatBreakdown extends PaginatedResponse, SortedResponse {
  breakdownStatistics: {
    tickets: string
    csatTickets: string
    label: string | null
    responseRate: {
      value: number
      change: number
    }
    csat: {
      value: number
      change: number
    }
  }[]
}

export interface CsatTags {
  conversationCount: string
  conversationsWithTags: string
  tags: { label: string; value: string }[]
}

export interface CsatValueList {
  values: string[]
  total: string
}

export interface CsatSurveys {
  surveys: CsatSurvey[]
}
export interface CsatSurvey {
  id: string
  brand: string
}
