import { type NormalizedOptions } from 'ky'

import type { ConnectionsResponse } from '@zendesk/zqa-services/connections'
import { session } from '@/composables/useSession'
import { oldApi, api, APIError } from '@/api'

import type { TicketIdResponse } from './types'

export const getRequestPrefix = () => `payments/${session.account.id}/teams/${session.workspace.id}`

export const getDomains = async () => {
  const { data } = await oldApi
    .get(`payments/${session.account.id}/teams/${session.workspace.id}/extension-hosts`)
    .json<{ data: string[] }>()
  return data
}

const handleIdLookupErrors = async (_request: Request, _options: NormalizedOptions, response: Response) => {
  if (!response.ok) {
    const payload = await response.json()
    throw new APIError(response, payload)
  }
}

let getTicketIdController = new AbortController()
export const abortGetTicketId = () => getTicketIdController?.abort()

export const getTicketId = (url: string) => {
  abortGetTicketId()
  getTicketIdController = new AbortController()

  return oldApi
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [handleIdLookupErrors] } })
    .put(`${getRequestPrefix()}/extension-hash-to-id`, { json: { url }, signal: getTicketIdController.signal })
    .json<{ data: TicketIdResponse }>()
    .then((r) => r.data)
}

interface Payload {
  subject: string
  assigneeId: number
  url: string
  fields?: { name: string; value: string }[]
}

export const createCustomTicket = ({ subject, assigneeId, url, fields }: Payload) =>
  oldApi
    .post(`${getRequestPrefix()}/extension-hash-to-id`, { json: { url, subject, assigneeId, fields } })
    .json<{ data: { externalId: string; paymentTokenId: number } }>()
    .then((r) => r.data)

export const getWorkspaceConnections = async () => {
  const { connections } = await api
    .get('connections', { headers: { 'X-Klaus-Workspace': session.workspace.id.toString() } })
    .json<ConnectionsResponse>()
  return connections
}
