import { computed, onBeforeMount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { type AutoQaReview } from '@zendesk/zqa-services/autoqa'
import { createEventHook, refAutoReset, watchDeep, watchImmediate } from '@vueuse/core'
import { useMutation } from '@tanstack/vue-query'
import i18n from '@/i18n'
import { bus } from '@/utils/bus'
import { session } from '@/composables/useSession'
import useReviewSidebar, { type FeedbackState } from '@/composables/useReviewSidebar'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import useFeedbackNavigation from '../../TicketContent/composables/useFeedbackNavigation'
import { getSelectUserOption } from '../../TicketContent/utils/defaultReviewee'
import useDispute, { type DisputeDetails } from '../../TicketContent/composables/useDispute'
import {
  getDisputeChangedRatings,
  getDisputeInitialRatingsMap,
  hasSomeCategoriesRated,
  toRatingsMap,
} from '../utils/disputeForm'
import { type Rating, isRated, isValidComment } from '../utils'
import { rootCauseMissing } from '../utils/rootCauseMissing'
import {
  type DisputePayload,
  approveReviewDispute,
  approveRevieweeDispute,
  editDispute,
  rejectDispute,
} from '../../TicketContent/api/disputes'
import { type Dispute } from '../../TicketContent/types/dispute'
import { sendSubmitAnalytics } from '../utils/disputeAnalytics'

export const defaultFormState: FeedbackState = {
  comment: '',
  commentTags: [],
  ratings: {},
  user: getSelectUserOption(),
  scorecardId: undefined,
}

export default function useDisputeForm() {
  const router = useRouter()
  const { getDisputeRoute } = useFeedbackNavigation()
  const { state: sidebarState, categoryOptions, loadFeedback, allowSkip } = useReviewSidebar()
  const { createNewDispute, state: disputeState, getReviewDisputes } = useDispute()
  const { connectionId, conversationId, disputeId } = useTicketRouteParams()

  const submittedHook = createEventHook<void>()

  const formState = ref(defaultFormState)

  const { mutate, status: mutationStatus } = useMutation({
    mutationFn: submitForm,
    onSuccess: (data, variables) => handleSuccessfulMutation(data, variables.data.reviewId),
  })

  const autoQaReviewForReviewee = computed(getAutoQaReviewForReviewee)
  const originalRatings = computed(() => disputeState.dispute?.originalReview.ratings)
  const originalRatingsMap = computed(() => toRatingsMap(originalRatings.value as any))
  const ratingsChanged = computed(getRatingsChanged)
  const hasRatedCategories = computed(getHasRatedCategories)
  const activeRatingCategories = computed(getActiveRatingCategories)
  const noChangedRatings = computed(getNoChangedRatings)
  const commentExists = computed(getCommentExists)
  const missingRootCause = computed(getFirstMissingRootCause)
  const canSubmit = computed(getCanSubmit)
  const submitTooltip = computed(getSubmitTooltip)
  const hideRatings = computed(() => disputeState.dispute?.type === 'REVIEWEE' || disputeState.action === 'REJECT')
  const defaultSubmitStatus = computed<'pending' | undefined>(() =>
    mutationStatus.value === 'pending' ? 'pending' : undefined,
  )
  const submitStatus = refAutoReset<Exclude<typeof mutationStatus.value, 'idle'> | undefined>(defaultSubmitStatus, 1500)

  watch(
    () => [formState.value.ratings, autoQaReviewForReviewee.value],
    () => populateAutoQaRatings(),
  )

  watchImmediate(mutationStatus, (status) => {
    if (status === 'idle') return
    submitStatus.value = status
  })

  watchImmediate(
    () => disputeState.dispute,
    (dispute) => setDisputeFormUser(dispute!),
  )

  watchDeep(activeRatingCategories, () => (formState.value.ratings = getInitialRatings()))

  onBeforeMount(() => {
    formState.value.ratings = getInitialRatings()
    formState.value.comment = (disputeState.action === 'EDIT' && disputeState.dispute?.comment.comment) || ''
  })

  function populateAutoQaRatings() {
    if (!session.features.autoQa) return
    if (!autoQaReviewForReviewee.value) return

    Object.values(formState.value.ratings).forEach((r) => {
      if (!r.autoQaCategory) return

      const autoQaRatingCategory = autoQaReviewForReviewee.value?.categories.find(
        (cat) => Number(cat.id) === r.categoryId,
      )
      if (autoQaRatingCategory) formState.value.ratings[r.categoryId].autoQaRatingCategory = autoQaRatingCategory
    })
  }

  function setDisputeFormUser(dispute: DisputeDetails) {
    if (!dispute) return

    let userId: string
    const allowMissingUser = !!disputeState.action && disputeState.action !== 'EDIT'

    if (disputeState.action === 'APPROVE' && dispute.type === 'RATINGS') userId = dispute.originalReview.revieweeId!
    else if (disputeState.action && disputeState.action !== 'EDIT') userId = dispute.createdBy.id
    else userId = dispute.assignee.id

    const parsedUserId = parseInt(userId, 10)
    const user = sidebarState.workspaceUsers.find(({ id }) => parsedUserId === id)
    if (parsedUserId === session.user.id || (!user && !allowMissingUser)) return

    const disputes = getReviewDisputes(dispute.reviewId)
    const approvingReviewee = disputeState.action === 'APPROVE' && dispute.type === 'REVIEWEE'
    if ((disputes.length && !disputeState.action) || approvingReviewee) return

    if (user) {
      formState.value.user = user
    } else if (!user && allowMissingUser) {
      // Allow a dispute to be resolved even if the author of the dispute no longer belongs to this workplace
      formState.value.user = { id: Number(dispute.createdBy.id), name: 'Placeholder' }
    }
  }

  function handleSubmit(feedbackState: FeedbackState) {
    if (!canSubmit.value || !connectionId.value || !conversationId.value) return
    if (!feedbackState.user?.id || !feedbackState.ratings) throw new Error('Missing dispute assignee')

    mutate(getPayload(feedbackState) as any)
  }

  function submitForm(payload: DisputePayload) {
    const dispute = disputeState.dispute

    if (disputeState.action === 'APPROVE' && dispute?.type === 'RATINGS') {
      return approveReviewDispute(payload)
    } else if (disputeState.action === 'APPROVE' && dispute?.type === 'REVIEWEE') {
      return approveRevieweeDispute(payload)
    } else if (disputeState.action === 'REJECT') {
      return rejectDispute(payload)
    } else if (disputeState.action === 'EDIT') {
      return editDispute(payload)
    } else {
      return createNewDispute(payload)
    }
  }

  function getPayload({ user, comment, commentTags }: FeedbackState) {
    const reviewId = disputeState.dispute?.reviewId as string

    return {
      connectionId: connectionId.value,
      conversationId: conversationId.value,
      disputeId: disputeId.value,
      data: {
        reviewId,
        assigneeId: user.id?.toString(),
        revieweeId: user.id?.toString(),
        comment: {
          comment: comment || '',
          commentTags,
        },
        categories: disputeState.dispute?.type === 'RATINGS' ? getChangedRatings().map(mapRating) : undefined,
      },
    }
  }

  async function handleSuccessfulMutation({ disputes }: { disputes: Dispute[] }, reviewId: string) {
    disputeState.disputesList = disputes

    const changedDispute = disputes.find((d) => d.id === disputeId.value)
    sendSubmitAnalytics({
      action: disputeState.action!,
      type: disputeState.dispute?.type,
      status: changedDispute?.status ?? 'NEW',
      disputesCount: getReviewDisputes(disputeState.dispute?.reviewId ?? '').length,
      ratingsChanged: ratingsChanged.value,
    })

    resetFeedbackState()
    bus.$emit('refresh-activities-items', 'disputes')

    if (disputeState.action === 'APPROVE') {
      await loadFeedback()
    } else if (!disputeState.action) {
      const disputeId = disputes.find((dispute) => dispute.status === 'NEW' && dispute.reviewId === reviewId)?.id
      if (disputeId) {
        const disputeRoute = getDisputeRoute(disputeId)
        router.push(disputeRoute)
      }
    }

    submittedHook.trigger()
    disputeState.disputing = false
    disputeState.action = undefined
    bus.$emit('toggle-review-sidebar')
  }

  function getInitialRatings() {
    return getDisputeInitialRatingsMap({ disputeState, originalCategories: activeRatingCategories.value })
  }

  function resetFeedbackState() {
    formState.value.ratings = getInitialRatings()
    formState.value.comment = disputeState.dispute?.comment.comment || ''
    bus.$emit('comment-editor-reset')
  }

  function getHasRatedCategories() {
    if (!allowSkip.value) {
      const disputedRatings = (originalRatings.value ?? [])
        .map<Rating>(({ categoryId }) => formState.value.ratings[categoryId as any])
        .filter(Boolean)
      return disputedRatings.every(isRated)
    }

    return hasSomeCategoriesRated({
      categoriesToCheck: activeRatingCategories.value,
      originalRatings: toRatingsMap(originalRatings.value as any),
      updatedRatings: formState.value.ratings,
    })
  }

  function getChangedRatings() {
    const disputeFormRatings = Object.values(formState.value.ratings)
    return getDisputeChangedRatings({ disputeFormRatings, originalRatings: originalRatings.value as any })
  }

  function getAutoQaReviewForReviewee(): AutoQaReview | undefined {
    if (!session.features.autoQa) return
    if (!disputeState.dispute?.originalReview.revieweeId) return

    return sidebarState.autoQaReviews?.reviewPerUser[disputeState.dispute.originalReview.revieweeId]
  }

  function getRatingsChanged() {
    return Object.values(formState.value.ratings).some((r) => {
      const originalRating = originalRatings.value?.find((rat) => rat.categoryId === r.categoryId.toString())
      if (!originalRating) return false
      return originalRating.rating !== r.rating
    })
  }

  function getCommentExists() {
    if (!formState.value.comment.length) return false

    return isValidComment(formState.value.comment)
  }

  function getFirstMissingRootCause() {
    return hideRatings.value ? undefined : Object.values(formState.value.ratings).find(rootCauseMissing)
  }

  function getCanSubmit() {
    if (session.account.status === 'paused') return false

    if (noChangedRatings.value) return false
    if (disputeState.action !== 'APPROVE') return !!formState.value.user.id && commentExists.value

    if (disputeState.dispute?.type === 'REVIEWEE') return commentExists.value

    if (!commentExists.value) return false

    if (session.features.rootCauses && missingRootCause.value) return false

    return !!formState.value.user.id && hasRatedCategories.value
  }

  function getActiveRatingCategories() {
    return Object.values(categoryOptions.value).sort((a, b) => a.position - b.position)
  }

  function getSubmitTooltip() {
    if (session.account.status === 'paused') return i18n.t('conversations.sidebar.paused_subscription')
    if (canSubmit.value) return i18n.t('conversations.sidebar.tooltips.submit')

    if (!formState.value.user.id) return i18n.t('conversations.sidebar.reviewee_required')
    if (!hasRatedCategories.value) return i18n.t('conversations.sidebar.unrated_category_tooltip')
    if (!commentExists.value) return i18n.t('conversations.sidebar.comment_required')
    if (noChangedRatings.value) return i18n.t('conversations.sidebar.select_new_ratings')

    if (session.features.rootCauses && missingRootCause.value)
      return i18n.t('conversations.sidebar.root_cause_missing', [missingRootCause.value.categoryName])

    return null
  }

  function getNoChangedRatings() {
    return disputeState.dispute?.type === 'RATINGS' && disputeState.action && !getChangedRatings().length
  }

  return {
    formState,
    hideRatings,
    originalRatingsMap,
    canSubmit,
    submitTooltip,
    handleSubmit,
    submitStatus: computed(() => submitStatus.value),
    onSubmitted: submittedHook.on,
  }
}

const mapRating = (r: Rating) => ({
  id: r.categoryId,
  rootCause: r.cause || null,
  expectedRating: r.rating,
  note: undefined,
  autoQaRatingCategory: r.autoQaRatingCategory,
})
