import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_disputes_grid = _resolveComponent("disputes-grid")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "disputers-card",
    "api-method": _ctx.getDisputers,
    title: _ctx.$t('disputes_dashboard.card.disputers'),
    params: _ctx.filterParams
  }, {
    default: _withCtx(({ data, sortBy, setPage }) => [
      _createVNode(_component_disputes_grid, {
        data: data,
        "parent-key": "disputers_card",
        onSortBy: sortBy,
        onSetPage: setPage
      }, null, 8 /* PROPS */, ["data", "onSortBy", "onSetPage"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["api-method", "title", "params"]))
}