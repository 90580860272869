import type { WorkspaceRole } from '@/data/roles'
import type { Member } from '@/modules/dashboard/types'
import type { WorkspaceItem as Workspace } from '@/modules/user-management/types'

export enum GroupRole {
  Lead = 'LEAD',
  Member = 'MEMBER',
}

export interface UserGroupListItem {
  id: string
  name: string
  memberCount: number
  visibleMemberCount: number
  hidden: boolean
  archived: boolean
  readonly: boolean
}

export interface UserGroupsListResponse {
  userGroups: UserGroupListItem[]
  readonly: boolean
}

export interface UserGroupMember extends Member {
  role: GroupRole
}

export interface UserGroupForm {
  name: string
  members: UserGroupMember[]
  workspaceIds: number[]
  workspaceRole: WorkspaceRole
}

export interface UserGroup {
  id: string
  name: string
  members: UserGroupMember[]
  workspaces: Workspace[]
  workspaceRole?: WorkspaceRole
  readonly: boolean
}

interface MemberPayload {
  userId: string
  role: GroupRole
}

export interface UserGroupPayload {
  name: string
  members: MemberPayload[]
}

export interface UpdateSCIMUserGroupPayload extends Omit<UserGroupPayload, 'name'> {
  workspaceIds: string[]
  workspaceRole?: WorkspaceRole
}
