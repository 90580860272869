export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tên miền phụ Zendesk của bạn"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập vào Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công ty bạn đã có sẵn một tài khoản."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia công ty"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để tham gia một công ty hiện có, hãy yêu cầu quản trị viên gửi cho bạn lời mời."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu quản trị viên mời bạn"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi đã đổi ý."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy đăng ký"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công ty bạn đã có sẵn một tài khoản. Bạn có muốn tham gia tài khoản của công ty bạn hay tạo một tài khoản mới không?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép hướng dẫn"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo tài khoản mới"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã có sẵn tài khoản"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không thể truy cập tài khoản Zendesk QA của mình cho đến khi thanh toán hóa đơn đăng ký chưa thanh toán của mình."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký của bạn bị tạm dừng"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy tài khoản. Hãy thử lại."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhận một mật khẩu mới."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thử lại hoặc ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổ hợp email và mật khẩu không chính xác."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy yêu cầu một lời mời mới từ quản trị viên của bạn."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết mời này không hợp lệ hoặc đã được sử dụng."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Phương thức đăng nhập ", _interpolate(_named("method")), " đã bị hủy kích hoạt trong tài khoản của bạn."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời mời này không hợp lệ hoặc đã được sử dụng. Hãy yêu cầu một lời mời mới từ quản trị viên của bạn."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tải được thông tin lời mời."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký không thành công. Có thể đã tồn tại người dùng có email này."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản của bạn đã bị chặn sau nhiều lần đăng nhập liên tiếp. Hãy kiểm tra hộp thư đến của bạn để tìm email có hướng dẫn về cách bỏ chặn tài khoản."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy xác minh email của bạn trước"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tài khoản email hợp lệ"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường này là bắt buộc"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường này là bắt buộc"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt lại"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã đăng nhập"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi liên kết ma thuật"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email cơ quan"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email cơ quan của bạn"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Thỏa Thuận Dịch Vụ Chính)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu không khớp với các yêu cầu"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông Báo Về Quyền Riêng Tư"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chờ đã"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xin chào, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tham gia tổ chức ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email của bạn đã được xác minh. Bạn hiện có thể đăng nhập bằng tài khoản mới dưới đây."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục với Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết mời không hợp lệ"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng liên kết ma thuật"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại trang đăng nhập"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục với Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục sử dụng Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email của bạn đã được xác minh. Bạn hiện có thể đăng nhập bằng tài khoản mới dưới đây:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng liên kết ma thuật"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập vào Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản của bạn đã bị xóa"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không có tài khoản?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập mã hợp lệ"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường này là bắt buộc"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tài khoản email hợp lệ"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường này là bắt buộc"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã đăng nhập"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tất cả tùy chọn đăng nhập"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " bằng phương thức truyền thống"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng liên kết ma thuật"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã nhận được email chứa mã đăng nhập của mình."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã nhận được email mà bạn có thể truy cập để đăng nhập."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xảy ra lỗi khi gửi liên kết ma thuật. Hãy thử lại."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng email"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mật khẩu"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một ký tự viết thường"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một ký tự viết hoa"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu mới phải chứa"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Độ dài mật khẩu tối đa là 128 ký tự"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dễ nhớ, khó đoán"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tối thiểu 8 ký tự"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mật khẩu của bạn"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn quên mật khẩu?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một ký tự viết thường"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một chữ số"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một ký tự viết hoa"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để đặt lại mật khẩu, hãy nhập địa chỉ email và chúng tôi sẽ gửi email cho bạn kèm theo hướng dẫn."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tài khoản email hợp lệ"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường này là bắt buộc"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt lại mật khẩu"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Một email đã được gửi đến ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu email không được hiển thị trong hộp thư đến hoặc thư mục thư rác, vui lòng kiểm tra email bạn đã nhập."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại trang đăng nhập"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểm tra email của bạn"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt lại mật khẩu"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", bạn sẽ nhận được email đặt lại mật khẩu."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu tài khoản của bạn đã có sẵn mật khẩu"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nếu ", _interpolate(_named("bold")), ", chúng tôi sẽ gửi cho bạn liên kết đặt lại mật khẩu."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email đã được liên kết với một tài khoản"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có nhớ mật khẩu?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xảy ra lỗi khi gửi liên kết đặt lại mật khẩu. Hãy thử lại."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt lại mật khẩu"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký bằng liên kết ma thuật"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Quy định về sự riêng tư)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Khi đăng ký, bạn đồng ý với Zendesk QA ", _interpolate(_named("terms_of_service")), " và ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các điều khoản thỏa thuận về sử dụng dịch vụ"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã có tài khoản?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký bằng liên kết ma thuật"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký bằng email cơ quan của bạn"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký bằng Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký bằng Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi có một tài khoản Zendesk đang hoạt động"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đăng ký hoặc đăng nhập để hoàn thành thiết lập ", _interpolate(_named("direct_install_name")), " của bạn"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây có phải là bạn? Hãy kiểm tra hộp thư đến của bạn để nhận email xác nhận."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã thiết lập xong"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA hoạt động tốt nhất trên máy tính để bàn. Hãy đăng nhập vào máy tính để bàn để bắt đầu đánh giá các cuộc hội thoại."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lên lịch demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoặc lên lịch demo với chúng tôi và chúng tôi sẽ cho bạn thấy những việc bạn có thể thực hiện trong Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự hiện diện của bạn là cần thiết"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hủy"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tôi đã đổi ý và muốn ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " mời bạn tham gia tài khoản của họ."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia tài khoản"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo tài khoản mới"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử đăng nhập lại"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Phản ứng bạn nhận được cho ý kiến phản hồi của mình: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản ứng nhận được"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " đã phản ứng với bình luận của bạn"]), _normalize(["Một số người đã phản ứng với bình luận của bạn"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " đã phản ứng với câu trả lời của bạn"]), _normalize(["Một số người đã phản ứng với câu trả lời của bạn"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " đã phản ứng với đánh giá của bạn"]), _normalize(["Một số người đã phản ứng với đánh giá của bạn"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bình luận"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["câu trả lời"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHẢN ĐỐI"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐƯỢC ĐƯA RA"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐÃ NHẬN"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi khảo sát"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản ứng"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được bắt đầu"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Đánh dấu bình luận là đã đọc?"]), _normalize(["Đánh dấu tất cả các bình luận là đã đọc?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Đánh dấu ý kiến phản đối là đã đọc?"]), _normalize(["Đánh dấu tất cả các ý kiến phản đối là đã đọc?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Đánh dấu ý kiến phản hồi khảo sát là đã đọc?"]), _normalize(["Đánh dấu tất cả các ý kiến phản hồi khảo sát là đã đọc?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Đánh dấu đánh giá là đã đọc?"]), _normalize(["Đánh dấu tất cả các đánh giá là đã đọc?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bình luận được đánh dấu là đã đọc"]), _normalize(["Tất cả các bình luận được đánh dấu là đã đọc"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ý kiến phản đối được đánh dấu là đã đọc"]), _normalize(["Tất cả các ý kiến phản đối được đánh dấu là đã đọc"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ý kiến phản hồi khảo sát được đánh dấu là đã đọc"]), _normalize(["Tất cả các ý kiến phản hồi khảo sát được đánh dấu là đã đọc"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Đánh giá được đánh dấu là đã đọc"]), _normalize(["Tất cả các đánh giá được đánh dấu là đã đọc"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu tất cả là đã đọc"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là đã đọc"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi có người đưa ra ý kiến phản đối cho đánh giá, ý kiến phản đối đó sẽ hiển thị tại đây."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản đối nào"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi bạn được chỉ định giải quyết ý kiến phản đối, ý kiến phản đối sẽ hiển thị tại đây."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không nhận được ý kiến phản đối nào"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi bạn đưa ra ý kiến phản đối cho đánh giá, ý kiến phản đối sẽ hiển thị tại đây."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản đối nào được bắt đầu"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi được đưa ra cho bạn hiển thị tại đây. Chưa có gì để hiển thị."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không nhận được ý kiến phản hồi nào"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các phản ứng được đưa ra cho đánh giá và câu trả lời của bạn đều hiển thị tại đây. Chưa có gì để hiển thị."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không nhận được phản ứng nào"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở cuộc hội thoại"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi do bạn đưa ra đều hiển thị tại đây. Bắt đầu bằng cách để lại đánh giá trong danh sách xem Cuộc hội thoại."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có đánh giá nào được đưa ra"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi được đưa ra cho bạn hiển thị tại đây. Chưa có gì để hiển thị."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không nhận được đánh giá nào"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy bình luận nào"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi bạn để lại dưới dạng bình luận sẽ hiển thị tại đây."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bình luận nào được đưa ra"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy bình luận nào"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các bình luận dưới đánh giá mà bạn có, ", "@", "đề cập hoặc câu trả lời bình luận của bạn đều hiển thị tại đây."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không nhận được bình luận nào"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản đối nào được chấp nhận"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản đối nào đang mở"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản đối nào bị từ chối"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản đối nào đã được giải quyết"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử lại bằng cách chọn một tùy chọn khác từ bộ lọc trên đây."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử lại bằng cách chọn một người dùng khác từ bộ lọc trên đây."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận được đưa ra"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận đã nhận được"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả ý kiến phản đối"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối đã nhận được"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối được bắt đầu"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi khảo sát đã nhận được"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá được đưa ra"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi danh sách hoạt động"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize(["Đã đánh giá ", _interpolate(_list(0)), " / ", _interpolate(_list(1))]), _normalize(["Đã đánh giá ", _interpolate(_list(0)), " / ", _interpolate(_list(1))]), _normalize(["Đã đánh giá ", _interpolate(_list(0)), " / ", _interpolate(_list(1))])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Khởi động lại sau ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Còn lại ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập chỉ định"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi danh sách chỉ định"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Không thể tạo phiên hướng dẫn giữa ", _interpolate(_list(0)), " và ", _interpolate(_list(1)), " vì họ hiện không ở trong cùng một không gian làm việc."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Các phiên hướng dẫn giữa ", _interpolate(_list(0)), " và ", _interpolate(_list(1)), " đã tồn tại. Hãy liên hệ ", _interpolate(_list(0)), " để có quyền truy cập vào phiên."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đề xuất"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Một phiên mới giữa ", _interpolate(_list(0)), " và ", _interpolate(_list(1)), " đã được tạo."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người hướng dẫn"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người hướng dẫn"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được hướng dẫn"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người được hướng dẫn"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm phiên"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên phiên"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đề xuất"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quá hạn"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cũ"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sắp diễn ra"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy phiên nào"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian đánh giá trung bình"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có dữ liệu để hiển thị trong khoảng thời gian đã chọn"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm đánh giá"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã thêm ghim vào phiên. ", _interpolate(_named("buttonStart")), "Hiển thị cho tôi", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã xem đến ghim cuối trong khoảng thời gian đã chọn"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xem cách ghim hoạt động trong ", _interpolate(_named("link")), " này."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bài viết"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Có vẻ như bạn chưa ghim bất kỳ nội dung nào dưới ", _interpolate(_list(0)), ". Bạn có thể sử dụng ghim để chuẩn bị cho phiên 1:1 hoặc lưu nội dung để dành lúc khác."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người được hướng dẫn"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa thêm ghim nào"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đây là tất cả các ghim bạn đã tạo dưới tư cách ", _interpolate(_named("name")), ". Ghim được thêm vào phiên sẽ ", _interpolate(_named("bold")), " với người tham gia khác."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được chia sẻ ngay lập tức"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Có vẻ như ", _interpolate(_list(0)), " chưa nhận được bất kỳ ý kiến phản hồi nào về đánh giá cuộc hội thoại hoặc CSAT trong khoảng thời gian đã chọn."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản hồi nào"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã xem đến bình luận cuối trong khoảng thời gian đã chọn"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông số"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Số liệu của ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục thao tác mới"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được hoàn thành bởi ", _interpolate(_named("user")), " vào ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["bởi ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bạn"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập để thêm mục thao tác mới"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung nào được rút ra từ cuộc họp này? Bước tiếp theo của bạn là gì?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục thao tác"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được tạo bởi ", _interpolate(_named("name")), " vào ", _interpolate(_named("createdDate")), "."])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa phiên"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thao tác này sẽ ", _interpolate(_named("deleteSession")), " vĩnh viễn. Những người tham gia sẽ mất quyền truy cập vào phiên, bao gồm cả nội dung và ghi chú riêng tư."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã tạo phiên vào ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được chỉnh sửa lần cuối bởi ", _interpolate(_named("name")), " vào ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm thảo luận và ghi chú"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật ghi chú"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thoát phiên"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được chọn có quyền truy cập vào chi tiết phiên từ thời điểm này trở đi."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người dùng"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chia sẻ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ phiên"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bạn sẽ không còn là người tham gia phiên ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sẽ không còn là người tham gia phiên này."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " có quyền truy cập vào phiên này ngay bây giờ."]), _normalize([_interpolate(_named("n")), " người dùng mới có quyền truy cập vào phiên này ngay bây giờ"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa phiên"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa tất cả các phiên"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa phiên"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người hướng dẫn"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ghi chú được thêm bởi ", _interpolate(_list(0)), " sẽ hiển thị tại đây."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm các mục được ghim làm điểm thảo luận từ bảng bên phải"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi chú chỉ bạn có thể xem..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi chú cá nhân"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật ghi chú riêng tư"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ngày"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người hướng dẫn"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được hướng dẫn"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục thao tác"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên cuối cùng"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang diễn ra"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quá hạn"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan về phiên"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tất cả"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi: Người hướng dẫn"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi: Người được hướng dẫn"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các phiên"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên được chia sẻ"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử chuyển đổi bộ lọc để xem các mục thao tác."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có mục thao tác nào"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục thao tác"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được và đã xem"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận được · Đã xem"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiến trình sau các phiên"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có vẻ như các nhân viên hỗ trợ chưa nhận được bất kỳ ý kiến phản hồi nào."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác minh rằng bạn có tên chính xác của người dùng"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tìm thấy người dùng đó"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có dữ liệu"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS 30 ngày qua"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thao tác này sẽ <b>xóa vĩnh viễn tất cả các phiên</b> (2) với <b>", _interpolate(_named("name")), "</b>. Người tham gia sẽ mất quyền truy cập vào tất cả các phiên, bao gồm cả nội dung và ghi chú riêng tư."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã xem đến cuối của tất cả các phiên"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên hướng dẫn là một hoạt động nhóm giữa người hướng dẫn và người được hướng dẫn để thảo luận về hiệu suất, cơ hội, mục tiêu ngắn hạn và dài hạn."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể tìm kiếm các phiên theo cả tên người hướng dẫn và người được hướng dẫn."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy phiên nào"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có phiên nào được tạo ra"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa lịch sử hướng dẫn"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa lịch sử hướng dẫn "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên cuối cùng"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các phiên"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người hướng dẫn"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được hướng dẫn"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là đã hoàn tất"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là chưa hoàn tất"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo tên"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đổi tên"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi danh sách phiên"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được lên lịch"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trả lời tin nhắn này"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên hiệu chuẩn đã hết hạn. Không thể để lại thêm đánh giá nào."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã để lại đánh giá cho cuộc hội thoại này."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể đánh giá cuộc hội thoại này vì đã qua ngày đến hạn của phiên."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại này không có đánh giá nào"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đóng bảng"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để lại đánh giá hiệu chuẩn"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHI TIẾT"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÓM TẮT"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BẢN SAO"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Người nói ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " giây"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["khoảng lặng"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa loa"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá tin nhắn"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại phát tin nhắn"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loa"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản chép"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng không gian làm việc"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm kết nối trước để tạo các bộ lọc"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bot"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được tạo vào lúc ", _interpolate(_named("time")), " bởi ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được cập nhật lần cuối vào lúc ", _interpolate(_named("time")), " bởi ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Có vẻ như bạn không có bộ lọc nào. Để truy cập trang này, trước tiên bạn cần tạo bộ lọc. Bắt đầu với ", _interpolate(_named("recommendedFilter")), " để xem các cuộc hội thoại của riêng bạn. Tạo bộ lọc dưới đây để tiếp tục."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trong 30 ngày qua"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện \"Được tạo trong 30 ngày qua\" sẽ được thêm vào các bộ lọc được tạo mà không có khoảng ngày"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc không có sẵn trong gói này và sẽ không được áp dụng khi hiển thị kết quả"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hết thời gian tìm nạp các tùy chọn bộ lọc. Hãy thử lại."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về tính năng lọc"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách = người đã đăng nhập"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cuộc hội thoại được tìm thấy: ", _interpolate(_named("count"))]), _normalize(["Cuộc hội thoại được tìm thấy: ", _interpolate(_named("count"))]), _normalize(["Cuộc hội thoại được tìm thấy: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không khả dụng trong gói hiện tại của bạn. Hãy nâng cấp để sử dụng tính năng này."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm điều kiện"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bởi AI của Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi thành chỉ định"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo bộ lọc"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo bộ lọc"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường tùy chỉnh"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi đang tải các tùy chọn bộ lọc theo thiết lập cụ thể của bạn. Quá trình này có thể mất vài phút ở lần tải đầu tiên."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt tên mô tả cho bộ lọc của bạn"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên bộ lọc"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một tùy chọn"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riêng tư"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công khai"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa bộ lọc"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu các thay đổi"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có giá trị nào"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một giá trị"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ các phiên đang hoạt động ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị các phiên cũ hơn ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đến hạn"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa phiên hiệu chuẩn"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên hiệu chuẩn"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các đánh giá hiệu chuẩn được đưa ra trong phiên này sẽ bị xóa vĩnh viễn."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa phiên"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa phiên hiệu chuẩn ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có phiên nào được tạo"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có phiên nào đang hoạt động"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có phiên nào được tạo"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có phiên nào đang hoạt động"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bộ lọc"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo bộ lọc riêng tư"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo bộ lọc công khai"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm bộ lọc"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc này sẽ bị xóa vĩnh viễn."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bộ lọc"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa bộ lọc ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả ý kiến phản đối"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối được đưa ra cho tôi"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối được đưa ra bởi tôi"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối đang mở"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản nháp"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bộ lọc nào được thêm"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bộ lọc riêng tư nào được tạo"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bộ lọc công khai nào được tạo"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc riêng tư"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc công khai"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn muốn ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lưu bộ lọc này"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là chế độ xem tạm thời của bộ lọc."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xây dựng bộ lọc của bạn..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật bộ lọc"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục QA tự động tùy chỉnh"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cập nhật lần cuối vào ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ phức tạp"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng tự động đánh giá sẽ tự động cập nhật dựa trên các thay đổi về phiếu"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Làm nổi bật trong cuộc hội thoại"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu dài"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có điểm tự động nào được dự đoán"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện bất kỳ lỗi nào"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị từ phức tạp"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị câu dài"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại tiếp theo"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động không thể nhận xét danh mục này do không đủ ngữ cảnh. Đã đề xuất đánh giá thủ công."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu thông tin chuyên sâu về cuộc hội thoại tự động để tối ưu hóa quy trình đánh giá của bạn. Làm nổi bật các cuộc hội thoại quan trọng và hiển thị những ví dụ tích cực nổi bật cho đội của bạn."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Cuộc gọi]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sửa"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " đã chấp nhận ý kiến phản đối"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã gửi ý kiến phản đối đến ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thích lý do tại sao bạn cho rằng người được đánh giá này không phải là người nhận đúng của ý kiến phản hồi này"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bình luận và thiết lập người được đánh giá mới"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bình luận"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thích lý do tại sao bạn cho rằng điểm số này được đánh giá không công bằng (bắt buộc)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bình luận (bắt buộc)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thích lý do bạn từ chối ý kiến phản đối này"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bình luận"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi ý kiến phản đối đến"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi ý kiến phản đối đến"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi ý kiến phản đối đến"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người được đánh giá mới"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản đối ý kiến phản hồi"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản đối ý kiến phản hồi"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi người được đánh giá"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi ý kiến phản đối đến"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản đối đánh giá này"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi không đồng ý với điểm số này"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá này không dành cho tôi"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một người dùng"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " đã chấp nhận một phần ý kiến phản đối này"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " đã từ chối ý kiến phản đối này"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chấp nhận"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chấp nhận một phần"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bị từ chối"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang mở"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " đã bắt đầu một ý kiến phản đối: đánh giá"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " đã bắt đầu một ý kiến phản đối: sai người được đánh giá"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn cần giải quyết ý kiến phản đối đang mở để thực hiện các thao tác khác trong quá trình đánh giá."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chấp nhận"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn điểm số mới"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa ý kiến phản đối"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn loại bỏ ý kiến phản đối này không?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa ý kiến phản đối"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian giải quyết ý kiến phản đối"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ chối"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn điểm số hợp lý mới (không bắt buộc)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem xét ý kiến phản đối"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sai người được đánh giá"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chấp nhận ý kiến phản đối"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chấp nhận ý kiến phản đối"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản đối ý kiến phản hồi"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sai người được đánh giá"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản đối ý kiến phản hồi"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ chối ý kiến phản đối"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ chối ý kiến phản đối"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem ý kiến phản đối"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã chỉnh sửa"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " bởi ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["cho ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã được đánh giá"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chỉ định cho:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy để lại bình luận"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về thẻ điểm có điều kiện"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trước đây được chỉ định cho:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ chọn một"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tìm thấy bất kỳ GIF nào có tên đó"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy là người đầu tiên để lại đánh giá."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký của bạn hiện đang bị tạm dừng. Hãy kích hoạt lại đăng ký để có thể đưa ra ý kiến phản hồi."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đăng bởi:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần này cho biết lượng thời gian đã dành ra để đánh giá cuộc hội thoại này"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một người dùng"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Thiếu nguyên nhân gốc rễ cho danh mục: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá danh mục hoặc thêm bình luận"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu gọn"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở rộng trường bình luận"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu gọn trường bình luận"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn định dạng"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn bảng"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện định dạng"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện bảng"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi đánh giá"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá tất cả các danh mục"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá ít nhất một danh mục"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu cuộc hội thoại này để sau"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu để sau"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định tiếp theo"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay thế cuộc hội thoại trong chu kỳ chỉ định này"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá cuộc hội thoại"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá điểm gốc"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bình luận"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa bình luận"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn xóa bình luận này?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tác giả bình luận"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag này đã bị xóa hoặc đổi tên."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ những điều bạn yêu thích hoặc những điều có thể cải thiện..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có điều kiện"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn tất cả các bình luận."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bình luận"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Tin nhắn]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " chưa xem ý kiến phản hồi này"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " đã xem ý kiến phản hồi này"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cựu thành viên"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấn Enter để tạo hashtag mới."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các hashtag tùy chỉnh đã bị quản trị viên khóa."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sử dụng tùy chọn này để đánh dấu các trường hợp hỗ trợ và lọc ra sau"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là điểm gốc"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi tin nhắn"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại tiếp theo"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản hồi"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có đánh giá nào được hoàn tất"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để lại đánh giá dưới đây để bắt đầu."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không còn đánh giá nào khác"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được đánh giá"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách trước đó"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại trước đó"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa điểm gốc"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trả lời"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trả lời đánh giá này"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn thanh bên đánh giá"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn đánh giá và tất cả các bình luận đã thêm."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa đánh giá"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa đánh giá"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa đánh giá"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đánh giá đã bị xóa bởi ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại nhiệm vụ này cho chính bạn để để lại đánh giá"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã để lại đánh giá cho người dùng này."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bạn đã để lại đánh giá cho ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có danh mục nào để hiển thị cho cuộc hội thoại này."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự đánh giá không khả dụng cho không gian làm việc này."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không lưu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn lưu đánh giá này làm bản nháp?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại bỏ bản nháp này"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại bỏ"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị thanh bên đánh giá"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không đạt"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đạt"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đạt với ít lỗi"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vẫn đang xử lý số liệu..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để lại bình luận"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá tin nhắn này"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá bản thân"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá cuộc hội thoại này"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tác giả"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có người dùng để đánh giá"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tắt tự đánh giá"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy người dùng nào"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng không gian làm việc"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người dùng"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 đánh giá"]), _normalize([_interpolate(_named("count")), " đánh giá"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn nhận xét mới"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người quản lý không gian làm việc của bạn chưa cho phép tự đánh giá."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấn Enter để gắn thẻ đánh dấu cho ghim này"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ngày qua"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được gắn sao"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên hiệu chuẩn"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản nháp"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu cung cấp cho bạn bản trình bày trực quan về dữ liệu bộ phận trợ giúp của bạn. Sử dụng các biểu đồ tương tác để biết nơi cần tập trung nỗ lực QA của bạn, phát hiện các điểm mù và tìm nhân viên hỗ trợ phù hợp để đánh giá dựa trên hiệu suất của họ."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dịch sang ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa phân công"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện đang xem"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện đang đánh giá"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại này đã bị xóa và không còn khả dụng."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại bạn đang tìm kiếm không tồn tại trong Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ghim với cuộc hội thoại này"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong phiên"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa cuộc hội thoại"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác xóa cuộc hội thoại khỏi phiên sẽ xóa tất cả các đánh giá được đưa ra cho cuộc hội thoại đó."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa cuộc hội thoại khỏi ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có phiên hiệu chuẩn nào được tạo."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại này đã được thêm vào phiên"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại này đã bị xóa khỏi phiên"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào/xóa khỏi phiên hiệu chuẩn"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép liên kết đến cuộc hội thoại này"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào để hiển thị"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thương hiệu"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email của khách hàng"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID khách hàng"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên khách hàng"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên mẫu bên ngoài"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian phản hồi lần đầu tiên"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mức độ ưu tiên"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận về khảo sát"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm mức độ hài lòng"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian giải quyết lần đầu tiên"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gốc"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn này không tồn tại trong cuộc hội thoại này hoặc đã bị xóa"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có tiêu đề"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào để hiển thị"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy cuộc hội thoại nào"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở cuộc hội thoại gốc"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể liên kết đến cuộc hội thoại - thiếu URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có người tham gia"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim cuộc hội thoại"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấp để đọc đánh giá"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấp để đánh giá"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạo cuộc hội thoại thông qua tiện ích mở rộng trình duyệt"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem khảo sát mức độ hài lòng của khách hàng"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem cuộc hội thoại"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn có thái độ tiêu cực"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn có thái độ tích cực"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch sang tiếng Anh"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện bản gốc"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa cuộc hội thoại"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Việc xóa cuộc hội thoại sẽ có hiệu lực vĩnh viễn. Thao tác này cũng xóa tất cả dữ liệu liên quan, ví dụ như khảo sát, bản chép và đánh giá khỏi Zendesk QA, bao gồm cả bảng điều khiển.", _interpolate(_named("lineBreak")), "Bạn vẫn có thể truy cập cuộc hội thoại này trong Zendesk Support hoặc phần mềm bộ phận trợ giúp khác mà bạn đang sử dụng."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Việc xóa cuộc hội thoại sẽ có hiệu lực vĩnh viễn. Thao tác này cũng xóa tất cả dữ liệu liên quan, ví dụ: khảo sát, bản chép và đánh giá khỏi Zendesk QA, bao gồm cả bảng điều khiển. Nếu cuộc hội thoại thuộc nhiều không gian làm việc, cuộc hội thoại sẽ bị xóa khỏi tất cả các không gian làm việc.", _interpolate(_named("lineBreak")), "Bạn vẫn có thể truy cập cuộc hội thoại này trong Zendesk Support hoặc phần mềm bộ phận trợ giúp khác mà bạn đang sử dụng."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa cuộc hội thoại"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa cuộc hội thoại khỏi Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cuộc hội thoại này chưa kết thúc do tăng cấp. Truy cập ", _interpolate(_named("optionsUrl")), " và xác thực thẻ đánh dấu của bạn. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cuộc hội thoại này chưa kết thúc do tăng cấp. Nói chuyện với quản trị viên của bạn về việc xác thực thẻ đánh dấu của bạn. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối > Tùy chọn nâng cao"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về thẻ đánh dấu"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đóng"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mới"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang mở"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang chờ xử lý"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xong"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nhân viên phụ trách đã thay đổi thành ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa phân công"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macro ", _interpolate(_named("target")), " đã được áp dụng bởi ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cuộc hội thoại đã nhận được nhận xét ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Người dùng ", _interpolate(_named("target")), " đã che cuộc hội thoại này"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã đổi trạng thái thành ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được gắn thẻ đánh dấu với ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã đổi đội thành ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " đã tạm dừng cuộc hội thoại trong ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " đã hủy tạm dừng cuộc hội thoại"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bị mắc kẹt trong một vòng lặp, lặp lại liên tiếp một tin nhắn"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lặp lại bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lặp lại bot, trong đó một tin nhắn được lặp lại một hoặc nhiều lần liên tiếp"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm thấy các cụm từ trùng khớp"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Một nhân viên hỗ trợ trung bình xử lý cuộc hội thoại ", _interpolate(_named("percent")), " hiệu quả hơn bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu quả liên lạc bot"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot đã xử lý cuộc hội thoại ", _interpolate(_named("percent")), " hiệu quả hơn một nhân viên hỗ trợ trung bình"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu quả liên lạc bot"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại thiếu tuyên bố cần thiết để thông báo cho những người tham gia ghi âm, tuyên bố này bắt buộc phải có để đảm bảo tuân thủ và minh bạch"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếu tuyên bố tiết lộ bản ghi âm"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Thỏa thuận cấp độ dịch vụ (SLA) đã bị vi phạm trong ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA bị vi phạm"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đại lượng đo cho quy tắc SLA đã vượt quá thời gian mục tiêu"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dấu hiệu mất khách hàng. Khách hàng đang cân nhắc chuyển đổi hoặc nhất định sẽ rời khỏi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xác định được nguy cơ khách hàng rời đi"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cuộc hội thoại có những khoảng lặng kéo dài hơn ngưỡng đã đặt là ", _interpolate(_list(0)), " giây"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 phiên bản"]), _normalize([_interpolate(_named("count")), " phiên bản"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dài nhất ", _interpolate(_list(0)), " giây"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng lặng"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng muốn nhận được mức hỗ trợ cao hơn"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu cải thiện"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng tỏ ra vô cùng biết ơn hoặc rất hài lòng với hỗ trợ nhận được"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch vụ vượt trội"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng hoặc nhân viên hỗ trợ đã yêu cầu phiếu theo dõi một cách rõ ràng"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu phiếu theo dõi"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng tỏ ra không hài lòng"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ tiêu cực"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại không phổ biến hoặc cá biệt và phải trao đổi qua lại nhiều hơn để đạt được giải pháp"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã phát hiện thấy dữ liệu ngoại lai"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng tỏ ra hài lòng"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ tích cực"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áp dụng"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt thành không áp dụng"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được chỉnh sửa lần cuối bởi ", _interpolate(_named("author")), " vào ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bởi ", _interpolate(_named("author")), " vào ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lý do (không bắt buộc)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phát hiện thấy thông tin chuyên sâu nào"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm kết nối"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối bộ phận trợ giúp với tài khoản của bạn và tạo ít nhất một bộ lọc để xem danh sách các cuộc hội thoại."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào để hiển thị"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem cài đặt không gian làm việc"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật lần cuối"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Trong ngày)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tháng này)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tuần này)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm theo ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kết quả tìm kiếm cho ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đánh giá bởi những người khác"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có bình luận"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mới nhất"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cũ nhất"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngẫu nhiên"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được cập nhật gần đây"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được trả lời gần đây"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sắp xếp theo"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa đọc"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đánh giá bởi bạn"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quá trình nhập có thể mất một lát. Chúng tôi sẽ gửi email cho bạn khi quá trình hoàn tất."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA đang nhập các cuộc hội thoại của bạn"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Giới hạn: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bằng cách nhấp vào biểu tượng hiệu chuẩn trong tiêu đề của danh sách xem cuộc hội thoại."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có gì để báo cáo. Hãy thử lại bằng cách sử dụng một bộ lọc hoặc tùy chọn sắp xếp khác."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có dữ liệu ngoại lai để hiển thị."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm cuộc hội thoại có liên quan và nhấp vào biểu tượng ngôi sao trong tiêu đề để đánh dấu cuộc hội thoại đó tại đây."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử thay đổi bộ lọc hoặc mời các nhân viên hỗ trợ khác của bộ phận trợ giúp thêm các cuộc hội thoại."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không giới hạn"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi chú nội bộ"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi đang tải các cuộc hội thoại. Một số nội dung có thể vẫn sẽ bị thiếu."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng tiếp theo"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy người dùng nào"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã nhận được"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành viên của không gian làm việc"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng trước đó"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào được thêm"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có cuộc hội thoại nào được thêm"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá chưa hoàn thành sẽ hiển thị tại đây. Hãy đọc lại, sửa đổi và gửi đánh giá trong 30 ngày tới."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bản đánh giá nháp nào"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại được gắn sao"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào để hiển thị"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có kết quả nào phù hợp"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trở về tin nhắn đã chọn"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ít câu trả lời nhất"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiều câu trả lời nhất"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi danh sách cuộc hội thoại"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cuộc gọi được trả lời lúc ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cuộc gọi được kết thúc lúc ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cuộc gọi được thực hiện lúc ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã được đánh giá"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đánh giá bởi bạn"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Không tải được tập tin âm thanh. Hãy đảm bảo bạn đã đăng nhập vào ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận về khảo sát mức độ hài lòng của khách hàng"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi khi tải âm thanh"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tác giả bình luận"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc gọi nào diễn ra"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nghe trong bộ phận trợ giúp"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nghe trên Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tốc độ phát lại"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá cuộc gọi"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hệ thống"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản chép"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cao"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thấp"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phải là người dùng Zendesk QA "])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tự động xếp hạng các nhân viên hỗ trợ căn cứ vào CSAT trung bình hàng tháng của nhân viên hỗ trợ. Tìm các cuộc hội thoại được xử lý bởi những nhân viên hỗ trợ có CSAT thấp hơn các nhân viên hỗ trợ khác chỉ bằng một cú nhấp chuột."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xếp hạng theo CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cao"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thấp"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thấp nhất"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cao nhất"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phải là người dùng Zendesk QA "])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một phiếu"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tự động xếp hạng các nhân viên hỗ trợ thành các nhóm hiệu suất căn cứ vào số lượng cuộc hội thoại được nhân viên hỗ trợ xử lý mỗi tháng. Tìm các cuộc hội thoại được xử lý bởi những nhân viên hỗ trợ có số lượng thấp hơn các nhân viên hỗ trợ khác chỉ bằng một cú nhấp chuột."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xếp hạng theo khối lượng"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã được đánh giá"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa được đánh giá"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái đánh giá"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thấp"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cao"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình thường"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phải là người dùng Zendesk QA "])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tự động xếp hạng các nhân viên hỗ trợ dựa trên thái độ trung bình trong các cuộc hội thoại. Tìm các tương tác có nhân viên hỗ trợ đối phó với nhiều khách hàng bực bội hơn các nhân viên hỗ trợ khác chỉ bằng một cú nhấp chuột."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xếp hạng theo thái độ"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phát hiện thấy"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã phát hiện thấy"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu ngoại lai"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các cuộc hội thoại cần bạn chú ý. Mô hình máy học của chúng tôi xác định các tương tác phức tạp hoặc cá biệt để đánh giá thêm."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu ngoại lai"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu cực"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích cực"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn để đánh giá cuộc hội thoại mà khách hàng thể hiện thái độ vui vẻ hay bực bội. Sử dụng menu thả xuống để chọn loại thái độ bạn đang tìm kiếm trong các cuộc hội thoại của mình. Kéo thuộc tính bộ lọc lên trên cùng để xem tỷ lệ của thái độ đã chọn trong tất cả các tương tác của bạn."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái đánh giá"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa được đánh giá"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã được đánh giá"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tự động phát hiện ngôn ngữ trong các cuộc hội thoại của bạn. Sử dụng menu thả xuống để chọn các cuộc hội thoại bằng các ngôn ngữ cụ thể để đánh giá. Kéo thuộc tính bộ lọc lên trên cùng để xem tổng quan tất cả các ngôn ngữ được sử dụng trong bộ phận trợ giúp của bạn."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu về cuộc hội thoại"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Với Thông tin chuyên sâu, bạn có thể ", _interpolate(_named("paragraph_2_item")), " so sánh với các bộ lọc cuộc hội thoại thông thường. Nhận thông tin tổng quan trực quan của tất cả dữ liệu từ bộ phận trợ giúp của bạn và tìm hiểu những thông tin chuyên sâu như:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về cách sử dụng thông tin chuyên sâu về cuộc hội thoại"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiểu tầm quan trọng tương đối của các thuộc tính cụ thể của hội thoại trong mối quan hệ với những thuộc tính khác"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm các nhân viên hỗ trợ có hiệu suất cao nhất và thấp nhất với tính năng xếp hạng hiệu suất nhân viên hỗ trợ tích hợp sẵn"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " là một công cụ khai thác dữ liệu tương tác hiệu quả giúp bạn tìm thấy các cuộc hội thoại cần đánh giá nhất."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["làm được nhiều việc hơn"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Biểu đồ Cuộc hội thoại và Nhân viên hỗ trợ ", _interpolate(_named("paragraph_3_item_1")), " – khi cập nhật bộ lọc hoặc nhấp vào các lát biểu đồ được phân đoạn trên một trong hai biểu đồ, danh sách cuộc hội thoại sẽ tự động được cập nhật. ", _interpolate(_named("paragraph_3_item_2")), " để tìm hiểu những thông tin phân tích chi tiết hoặc tỷ lệ của các yếu tố khác nhau."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được kết nối với nhau"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sắp xếp lại các thuộc tính bộ lọc"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thông tin chuyên sâu về cuộc hội thoại chỉ hiển thị ", _interpolate(_named("paragraph_4_item")), ". Chúng tôi tự động xóa các tương tác không có giá trị đánh giá thực sự, như tin nhắn được tạo tự động, tin nhắn rác, tin nhắn trả lời của bot hoặc tin nhắn không có đối thoại phù hợp với đại diện hỗ trợ của bạn."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["các cuộc hội thoại rõ ràng và có lời kết"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung nào đang được hiển thị tại đây?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " của ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tìm nạp"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " không áp dụng"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " trong tổng số)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải lại"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhưng chúng tôi chắc chắn rằng điểm phản hồi khảo sát sẽ rất cao."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát tùy chỉnh"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không còn ý kiến phản hồi khảo sát nào"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện chưa có điểm ý kiến phản hồi khảo sát..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận xét"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhận được"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu ý kiến phản hồi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bình luận về ý kiến phản hồi"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã áp dụng bộ lọc khoảng ngày"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " câu trả lời"]), _normalize([_interpolate(_named("count")), " câu trả lời"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ phận trợ giúp"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tìm hiểu sâu hơn về lý do tại sao điểm ", _interpolate(_list(0)), " được đưa ra bằng cách thiết lập lý do trong cài đặt khảo sát."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Chưa có lý do cho ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về lý do khảo sát"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại có thẻ đánh dấu:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " bởi"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục bình luận"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lý do ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yếu tố thúc đẩy ", _interpolate(_named("surveyType")), " được dự đoán"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn cuộc hội thoại"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Phản hồi khảo sát (", _interpolate(_named("n")), ")"]), _normalize(["Phản hồi khảo sát (", _interpolate(_named("n")), ")"]), _normalize(["Phản hồi khảo sát (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có tiêu đề"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cuộc hội thoại với ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " bởi người dùng"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ phản hồi"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Điểm ", _interpolate(_list(0)), " là tổng điểm của tất cả các phản hồi chia cho tổng số điểm tối đa"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa mục tiêu"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa mục tiêu"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt mục tiêu"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm mục tiêu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngắn"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rất dài"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dài"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Độ dài trung bình"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khó hiểu"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hữu ích"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ không tốt"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ tốt"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ có biểu tượng cảm xúc"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi cho nhân viên hỗ trợ"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khiếu nại"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hoàn thiện"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự cố không được giải quyết"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự cố đã được giải quyết"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ tiêu cực"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết quả không tốt"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ tích cực"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời khen"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sản phẩm không tốt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoản tiền hoàn"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ nhanh chóng"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ chậm"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời cảm ơn"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tự động phân bổ dữ liệu ", _interpolate(_list(0)), " của bạn vào các danh mục được xác định trước. Do đó, bạn có thể hiểu được những thông tin chuyên sâu phức tạp và tìm hiểu nguyên nhân mà không cần sàng lọc thủ công các văn bản ý kiến phản hồi."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả điểm số"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả khảo sát"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn ngôn ngữ phản hồi"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích thước bình luận"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn kích thước bình luận"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Với 25% những bình luận chỉ bao gồm một từ, bắt buộc phải đọc những bình luận dài hơn. Tìm ý kiến phản hồi chuyên sâu hơn mà không cần thực hiện thao tác thủ công vì chúng tôi sẽ tự động thực hiện việc đó cho bạn."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do cho ý kiến phản hồi"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn lý do cho ý kiến phản hồi"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm số"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có điểm số phù hợp"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yếu tố thúc đẩy được dự đoán"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn danh mục bình luận"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ trong ma trận từ khóa"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn từ trong ma trận từ khóa"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kể từ khoảng thời gian trước"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " cuộc hội thoại"]), _normalize([_interpolate(_named("n")), " cuộc hội thoại"]), _normalize([_interpolate(_named("n")), " cuộc hội thoại"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tính tỷ lệ gửi khảo sát từ tất cả các cuộc hội thoại do thiếu dữ liệu."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản hồi"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã gửi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu đồ hình phễu về khảo sát"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yếu tố thúc đẩy ", _interpolate(_named("surveyType")), " được dự đoán"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tự động phân bổ dữ liệu ", _interpolate(_list(0)), " của bạn vào các danh mục được xác định trước. Do đó, bạn có thể hiểu được những thông tin chuyên sâu phức tạp và tìm hiểu nguyên nhân mà không cần sàng lọc thủ công các văn bản ý kiến phản hồi."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích thước bình luận"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Với 25% những bình luận chỉ bao gồm một từ, bắt buộc phải đọc những bình luận dài hơn. Tìm ý kiến phản hồi chuyên sâu hơn mà không cần thực hiện thao tác thủ công vì chúng tôi sẽ tự động thực hiện việc đó cho bạn."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm số"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản hồi khảo sát"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (trung bình)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng phản hồi"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (trung bình)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Xu hướng ", _interpolate(_list(0)), " theo"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng phản hồi"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yếu tố thúc đẩy"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ngày gửi khảo sát ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ngày trả lời khảo sát ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gán điểm ", _interpolate(_list(0)), " cho"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị dữ liệu dựa trên"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo cuộc hội thoại"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chủ sở hữu phiếu"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhân viên hỗ trợ có nhiều câu trả lời nhất"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (trung bình)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " theo thời gian"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng phản hồi"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " và IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng phản hồi"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các cuộc hội thoại có những từ sau:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma trận từ khóa"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT bởi người dùng"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan về CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu đồ hình phễu về khảo sát"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản hồi khảo sát"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT theo thời gian"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma trận từ khóa"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có kênh"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nguồn"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ngôn ngữ"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có lý do"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có thẻ đánh dấu"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có danh mục bình luận"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có không gian làm việc"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có sẵn dữ liệu vì không có chỉ định nào được tạo hoặc không có chu kỳ nào được bắt đầu. Kiểm tra lại sau hoặc điều chỉnh các bộ lọc."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu tính năng mới"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " mục"]), _normalize([_interpolate(_named("count")), " mục"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " cuộc hội thoại"]), _normalize([_interpolate(_named("count")), " cuộc hội thoại"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không đạt"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đạt"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đạt với ít lỗi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đạt/Không đạt"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình (giờ)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình (phút)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nhận xét ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT trung bình = [tổng điểm ý kiến phản hồi ÷ (số lượng tất cả các phản hồi × điểm tối đa có thể)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm CSAT Zendesk QA chuẩn hóa điểm ý kiến phản hồi của bạn trong thang điểm 0-100% và lấy trung bình điểm tiêu chuẩn."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Điểm mức độ hài lòng của khách hàng) định lượng mức độ hài lòng của khách hàng với dịch vụ của bạn."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Mỗi đánh giá đại diện cho đánh giá của một người trong một cuộc hội thoại. Một cuộc hội thoại có thể có nhiều đánh giá (nếu hai người đánh giá trở lên đánh giá cùng một cuộc hội thoại)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo dõi diễn biến điểm số của người đánh giá theo thời gian."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo dõi diễn biến điểm số của nhân viên hỗ trợ theo thời gian."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " phản hồi"]), _normalize([_interpolate(_named("n")), " phản hồi"]), _normalize([_interpolate(_named("n")), " phản hồi"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " đánh giá"]), _normalize([_interpolate(_named("n")), " đánh giá"]), _normalize([_interpolate(_named("n")), " đánh giá"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Điểm chất lượng nội bộ) là kết quả của các đánh giá cuộc hội thoại của bạn."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQ = tổng điểm đánh giá ÷ số lượng đánh giá"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm này là điểm tính trung bình, có tính đến tất cả điểm đánh giá (trung bình có trọng số của điểm danh mục) phù hợp với điều kiện lọc đã chọn."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tải được thẻ."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi bộ lọc của bạn để thử lại."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ đạt cho biết kết quả đánh giá cuộc hội thoại của bạn."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Không đạt nếu điểm đánh giá bằng hoặc thấp hơn ", _interpolate(_named("failScore"))])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đạt nếu điểm đánh giá bằng hoặc cao hơn ", _interpolate(_named("passScore"))])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ này được xác định bởi điểm gốc được xác định trước, nghĩa là bạn:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ đạt = Tổng số đánh giá đạt ÷ Tổng của tổng số đánh giá"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ đạt cho biết tỷ lệ phần trăm số lượng đánh giá đạt mà không trượt các danh mục quan trọng hoặc không quan trọng."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ví dụ: Nếu người đánh giá đưa ra 3 đánh giá trong ngày này thì số hiển thị là trung bình cộng của 3 điểm đó. Xem cách tính từng ô ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ví dụ: Nếu nhân viên hỗ trợ nhận được 3 đánh giá trong ngày này thì số hiển thị là trung bình cộng của 3 điểm đó. Xem cách tính từng ô ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tính đến: trọng số danh mục, trạng thái quan trọng (không giống như Điểm số theo thời gian)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vì các danh mục có thể được đánh dấu là không áp dụng trong đánh giá nên số lượng đánh giá cho mỗi nhóm có thể thấp hơn tổng số đánh giá của người đánh giá. Xem cách tính từng ô"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tính đến: trọng số danh mục, trạng thái quan trọng (không giống như Điểm số theo thời gian)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vì các danh mục có thể được đánh dấu là không áp dụng trong đánh giá nên số lượng đánh giá cho mỗi nhóm có thể thấp hơn tổng số đánh giá trên mỗi nhân viên hỗ trợ. Xem cách tính từng ô"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tính đến: trọng số danh mục, trạng thái quan trọng (không giống như Điểm số theo thời gian)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Các nhóm danh mục được hiển thị riêng. Điểm của mỗi nhóm được tính bằng điểm trung bình của danh mục đó trong cùng khoảng thời gian. Xem cách tính từng ô ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu ý:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tính đến: trọng số danh mục, trạng thái quan trọng (không giống như Điểm số theo thời gian)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Các nhóm danh mục được hiển thị riêng. Điểm của mỗi nhóm được tính bằng điểm trung bình của danh mục đó trong cùng khoảng thời gian. Xem cách tính từng ô ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Điểm gốc ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có lỗi nghiêm trọng"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ không có lỗi nghiêm trọng cho biết tỷ lệ phần trăm số lượng đánh giá đạt mà không trượt bất kỳ danh mục quan trọng nào."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm chất lượng nội bộ"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ \"khác\""])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi định kỳ"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi hàng ngày"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi hàng tháng"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi hàng tuần"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi hàng năm"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm số theo thời gian"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trong vài ngày hoặc vài tuần."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính = tổng điểm trong ngày hoặc tuần chia cho tổng số đánh giá được đưa ra"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trong vài ngày hoặc vài tuần."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính = tổng điểm trong ngày hoặc tuần chia cho tổng số đánh giá nhận được"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm số theo danh mục"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem điểm trung bình tổng hợp của tất cả nhận xét danh mục do người đánh giá đưa ra trong khoảng thời gian này."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem điểm trung bình tổng hợp của tất cả đánh giá nhận xét mà nhân viên hỗ trợ nhận được trong khoảng thời gian này."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ dẫn đến đánh giá thấp"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ dẫn đến đánh giá"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm danh mục theo thời gian"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích cách các danh mục so sánh theo thời gian."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem điểm trung bình được đưa ra cho mỗi danh mục vào ngày hoặc tuần đã chọn."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích cách các danh mục so sánh theo thời gian."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem điểm trung bình nhận được cho mỗi danh mục vào ngày hoặc tuần đã chọn."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm số theo đánh giá"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu trữ"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục quan trọng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm đánh giá"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung bình/Tổng số"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại trừ tự đánh giá"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất tất cả các thẻ"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đưa ra"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận được"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["cuộc hội thoại"]), _normalize(["cuộc hội thoại"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["mục"]), _normalize(["mục"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " phản hồi"]), _normalize([_interpolate(_named("n")), " phản hồi"]), _normalize([_interpolate(_named("n")), " phản hồi"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " đánh giá"]), _normalize([_interpolate(_named("n")), " đánh giá"]), _normalize([_interpolate(_named("n")), " đánh giá"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hiển thị thêm một"]), _normalize(["Hiển thị thêm ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ngày qua"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 ngày qua"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tháng trước"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuần trước"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tháng này"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuần này"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng thời gian tùy chỉnh"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tháng"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quý"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuần"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Năm"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tải được thẻ. Thay đổi bộ lọc của bạn để thử lại."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có dữ liệu để hiển thị."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi hàng tháng"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thông tin."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các bộ lọc đang hoạt động của bạn bao gồm dữ liệu trên các thang điểm nhận xét khác nhau."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả ghim thẻ với những bộ lọc này"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả ghim bảng điều khiển với những bộ lọc này"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian đánh giá trung bình"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải xuống CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có danh mục"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu về danh mục"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đồ thị"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bình luận"]), _normalize(["Bình luận"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma trận"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cựu thành viên"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm chất lượng nội bộ"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (trung bình)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đạt"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ ghim"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển ghim"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận xét"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nguyên nhân gốc rễ"]), _normalize(["Nguyên nhân gốc rễ"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID đánh giá"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian đánh giá"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập nguyên nhân gốc rễ"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ cho bạn biết lý do tại sao cuộc hội thoại nhận được điểm thấp"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nguyên nhân gốc rễ nào được thiết lập cho danh mục này"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm/Tổng"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm đánh giá"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm danh mục nhận xét"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm danh mục"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh đơn hàng"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm danh mục theo"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn tất cả"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại trừ tự đánh giá"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bao gồm tự đánh giá"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ hiển thị tự đánh giá"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bộ lọc"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy hashtag nào"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag bình luận"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn hashtag bình luận"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy thẻ đánh dấu nào"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm thẻ đánh dấu bộ phận trợ giúp"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy thẻ điểm nào"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn thẻ điểm"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự đánh giá"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có kết nối phù hợp"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn cuộc hội thoại"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn nguồn"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bộ lọc"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có không gian làm việc phù hợp"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số lần đưa ra"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả mọi người"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nhóm"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nhóm phù hợp."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nhóm"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị thẻ"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị dữ liệu dựa trên"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá ngày tạo"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo cuộc hội thoại"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị hai chữ số thập phân"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem cài đặt"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả không gian làm việc"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toàn bộ tài khoản"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên không gian làm việc"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có không gian làm việc"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cờ"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đồ ăn thức uống"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Động vật và thiên nhiên"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đối tượng"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con người và cơ thể"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du lịch và địa điểm"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thường xuyên sử dụng"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết quả tìm kiếm"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu tượng mặt cười và biểu tượng cảm xúc"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu tượng"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy biểu tượng cảm xúc"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở cuộc hội thoại"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại danh sách nhiệm vụ"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " hoặc duyệt danh sách ", _interpolate(_named("link")), " của bạn"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn hiện đang sử dụng không gian làm việc ", _interpolate(_named("workspace")), " không được kết nối với miền này. Hãy nhấp vào đây để kết nối ngay bây giờ."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết nối khả dụng"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn chưa thể đưa ra ý kiến phản hồi về ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt bộ phận trợ giúp của bạn làm tab đang hoạt động."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa thể đánh giá miền này. Nếu cần, hãy thông báo cho quản trị viên của bạn."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm miền này"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kết nối với ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy chờ trong giây lát, chúng tôi đang thiết lập cho bạn"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể lấy URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các công cụ đã được kết nối với tài khoản Zendesk QA của tổ chức."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có công cụ nào được kết nối với tài khoản Zendesk QA của tổ chức."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép bạn đánh giá các trang web có URL luôn giữ nguyên bằng cách thêm một dấu thăng duy nhất vào cuối trước khi để lại đánh giá"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi tài khoản"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miền của bạn"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các miền đã được kết nối với không gian làm việc này."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể đánh giá bất kỳ trang nào trên các miền sau"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miền khả dụng để đánh giá"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiện ích mở rộng"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL duy nhất"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chưa có bộ phận trợ giúp nào được kết nối, hãy truy cập ", _interpolate(_named("link")), " để thêm bộ phận trợ giúp."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn chỉ có thể sử dụng tiện ích mở rộng trình duyệt Zendesk QA\ntrên các gói Professional và Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi không gian làm việc"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cung cấp tên"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cung cấp giá trị"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường tùy chỉnh"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá trị"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là lần đầu tiên chúng tôi gặp kiểu cuộc hội thoại này. Hãy cung cấp một số chi tiết."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham khảo cuộc hội thoại:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yếu tố để xác định phiếu sau này"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoản tiền hoàn"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cung cấp cả nhân viên phụ trách và tiêu đề"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tạo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá cuộc hội thoại này"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa được chỉ định"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liên hệ với người quản lý của bạn hoặc chuyển sang không gian làm việc khác từ ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chức năng tự đánh giá bị tắt cho không gian làm việc này."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng xuất"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạm biệt"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không thể đánh giá URL này. Hãy điều hướng đến cuộc hội thoại."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập vào Zendesk QA qua nhà cung cấp SSO của bạn trước rồi thử lại."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang mở cửa sổ đăng nhập bật lên."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập bằng tài khoản Zendesk QA của bạn"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chờ..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang xác thực..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn chưa có tài khoản?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không có quyền truy cập vào cuộc hội thoại này. Nếu cần, hãy liên hệ với người quản lý của bạn."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có quyền truy cập"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểm tra xem kết nối của bạn đã được kích hoạt trong Zendesk QA chưa"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý kết nối của bạn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi kết nối"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều hướng đến cuộc hội thoại trong bộ phận trợ giúp của bạn để tiếp tục"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL không hợp lệ"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việc kết nối lại sắp hoàn tất."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy đợi một lát"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử tải lại trang để trở lại bình thường"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xảy ra sự cố"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải phóng bản thân khỏi biên giới ảo của bộ phận trợ giúp và đánh giá các cuộc hội thoại ở bất cứ đâu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp đăng ký của bạn để sử dụng Tiện ích mở rộng"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý đăng ký của bạn"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối bộ phận trợ giúp không được liên kết với không gian làm việc này"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ phận trợ giúp của bạn không nhận dạng được phiếu này hoặc Zendesk QA không có quyền truy cập vào phiếu này"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tìm thấy cuộc hội thoại"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động mới"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã nhận được một số ý kiến phản hồi"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập thành công"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể sử dụng ứng dụng ngay bây giờ"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá tất cả các danh mục"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sử dụng tùy chọn này để đánh dấu các trường hợp hỗ trợ và lọc ra sau"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ý kiến phản hồi cho cuộc hội thoại này"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đọc ý kiến phản hồi"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở trong Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi và thông báo sẽ hiển thị tại đây. Hiện chưa có gì..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem thêm tại Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết cuộc hội thoại"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chứa tất cả"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là tất cả"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không chứa tất cả"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phải tất cả"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tốt hơn cuộc hội thoại của nhân viên hỗ trợ"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kém hơn cuộc hội thoại của nhân viên hỗ trợ"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sai"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lớn hơn"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chứa bất kỳ"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là bất kỳ"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không chứa bất kỳ"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phải là bất kỳ"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đúng"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bị vi phạm"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["có bình luận"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["có bình luận của tôi"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chưa có bình luận"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không có bình luận của tôi"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quan trọng để đánh giá"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chứa"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ngày qua"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trong khoảng"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["từ"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 ngày qua"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 giờ qua"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ngày qua"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 ngày qua"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tháng trước"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuần trước"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tháng này"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuần này"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cho đến"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôm nay"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôm qua"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đã phát hiện thấy"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tồn tại"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được gắn sao bởi tôi"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không được gắn sao bởi tôi"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bao gồm"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lớn hơn"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bắt đầu từ"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhỏ hơn"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lên đến"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phải là"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phải là"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiêu cực"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không bị vi phạm"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phức tạp"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phát hiện thấy"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không tồn tại"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không bao gồm"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không nhận được"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chưa xem"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tích cực"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chưa được đánh giá"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không được đánh giá bởi tôi"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đã được đánh giá"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được đánh giá bởi tôi"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhận được"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quan trọng để đánh giá"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chứa"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lớn hơn"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bắt đầu từ"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhỏ hơn"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lên đến"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phải là"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không chứa"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không phải là"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đã xem"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bình luận của tôi có câu trả lời"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["có"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các nhóm tùy chỉnh"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm tùy chỉnh"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm điều kiện"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ phận trợ giúp"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông số"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá và ý kiến phản hồi"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị tất cả các điều kiện"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So sánh cách xử lý cuộc hội thoại của bot với các nhân viên hỗ trợ trung bình"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bị kẹt trong một vòng lặp, lặp lại một tin nhắn trong các lượt liên tiếp"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng bot trả lời trong các cuộc hội thoại"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một loại bot liên quan đến cuộc hội thoại"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một bot liên quan tới cuộc hội thoại"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nội dung cuộc hội thoại chứa cụm từ ", _interpolate(_list(0)), " trong (các) tin nhắn của nhân viên hỗ trợ"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động xác định các cuộc gọi thiếu tuyên bố tiết lộ bắt buộc, như \"Cuộc gọi này sẽ được ghi âm\" và tương tự."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người hoặc bot tham gia vào cuộc hội thoại"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ hoặc bot đang được đánh giá trong cuộc hội thoại"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng tìm kiếm sự hỗ trợ cấp cao hơn"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ tích cực và tiêu cực được phát hiện thấy trong cuộc hội thoại"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định nhân viên hỗ trợ tích cực nhất trong các cuộc hội thoại bằng phân tích AI"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên hỗ trợ có lịch sự kết thúc cuộc hội thoại hay không"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên có hiểu và ghi nhận cảm xúc của khách hàng không"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích lỗi ngữ pháp, chính tả và văn phong của nhân viên hỗ trợ"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích toàn bộ cuộc hội thoại để tìm các cụm từ chào hỏi điển hình"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên có hiểu thắc mắc hoặc mối quan ngại của khách hàng không"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích mức độ dễ hiểu của văn bản, xem xét độ phức tạp của từ và độ dài câu"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích toàn bộ cuộc hội thoại để đề nghị giải pháp"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích giọng điệu của nhân viên hỗ trợ trong suốt cuộc hội thoại"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dấu hiệu mất khách hàng. Khách hàng đang cân nhắc chuyển đổi hoặc nhất định sẽ rời khỏi."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm chứa cuộc hội thoại."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung hoặc văn bản tin nhắn của nhân viên hỗ trợ"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời của nhân viên hỗ trợ trong cuộc hội thoại"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ được chỉ định đánh giá cuộc hội thoại"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Độ dài cuộc hội thoại trong cuộc gọi tính bằng giây"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung hoặc văn bản cuộc hội thoại"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh liên lạc được sử dụng cho cuộc hội thoại (ví dụ: email, trò chuyện)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận được thêm vào cuộc hội thoại"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày thêm bình luận vào cuộc hội thoại"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ tích cực và tiêu cực được phát hiện thấy trong cuộc hội thoại"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày khách hàng trả lời khảo sát mức độ hài lòng của khách hàng"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá cuộc hội thoại có ý kiến phản đối không"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng đã sử dụng kênh để bắt đầu cuộc hội thoại"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng phản hồi của khách hàng trong các cuộc hội thoại"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày của tin nhắn cuối cùng trong cuộc hội thoại"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò hoặc loại người dùng tham gia vào cuộc hội thoại"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng lặng giữa nhân viên hỗ trợ và khách hàng đã vượt quá ngưỡng"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng muốn nhận được mức hỗ trợ cao hơn"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng tỏ ra vô cùng biết ơn hoặc rất hài lòng với hỗ trợ nhận được"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng hoặc nhân viên hỗ trợ đã yêu cầu phiếu theo dõi một cách rõ ràng"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản hoặc bản ghi toàn bộ cuộc hội thoại được thực hiện bằng lời nói"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại có được đánh dấu là được gắn sao không"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag được sử dụng trong các bình luận để phân loại nội dung"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI đã phát hiện thấy ngôn ngữ của cuộc hội thoại"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày của tin nhắn cuối cùng trong cuộc hội thoại"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng ghi chú riêng tư trong các cuộc hội thoại"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số ký tự trung bình trên mỗi tin nhắn trong các cuộc hội thoại"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời trực tiếp với khách hàng trong các cuộc hội thoại"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia có thông tin được hiển thị công khai"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số từ trung bình trên mỗi tin nhắn trong các cuộc hội thoại"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục được sử dụng để đánh giá cuộc hội thoại"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái của quá trình đánh giá cuộc hội thoại"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia vào cuộc hội thoại"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email được liên kết với cuộc hội thoại"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã được xem chưa"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá thực hiện đánh giá cuộc hội thoại"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đánh giá cuộc hội thoại lần cuối"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đang được đánh giá trong cuộc hội thoại"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bất kỳ bình luận hoặc ý kiến phản hồi cụ thể nào được khách hàng cung cấp cho biết mức độ hài lòng của khách hàng với cuộc hội thoại"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm mức độ hài lòng của khách hàng đối với cuộc hội thoại"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm mức độ hài lòng của khách hàng trên mỗi tin nhắn trong cuộc hội thoại"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm được sử dụng để đánh giá cuộc hội thoại"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA đã bị vi phạm"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại nguồn hoặc kết nối bắt nguồn cuộc hội thoại"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại không phổ biến hoặc cá biệt và phải trao đổi qua lại nhiều hơn để đạt được giải pháp"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi khảo sát liên quan đến nội dung cuộc hội thoại"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề hoặc tên của khảo sát liên quan đến cuộc hội thoại"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ được chỉ định đánh giá cuộc hội thoại"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thương hiệu hoặc sản phẩm/dịch vụ cụ thể liên quan đến cuộc hội thoại"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh liên lạc được sử dụng cho cuộc hội thoại (ví dụ: email, trò chuyện)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày kết thúc hoặc giải quyết cuộc hội thoại"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo cuộc hội thoại lần đầu"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo cuộc hội thoại lần đầu"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày khách hàng trả lời khảo sát mức độ hài lòng của khách hàng"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả ngắn gọn hoặc tóm tắt cuộc hội thoại"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID tham chiếu bên ngoài được liên kết với cuộc hội thoại"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thư mục hoặc nhóm cụ thể nơi cuộc hội thoại được sắp xếp"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mẫu hoặc biểu mẫu được sử dụng để cấu trúc hội thoại"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID tham chiếu bên ngoài được liên kết với cuộc hội thoại"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hộp thư hoặc tài khoản email nhận được cuộc hội thoại"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại được liên kết với cuộc hội thoại"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mức độ ưu tiên được gán cho cuộc hội thoại"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số câu trả lời trong cuộc hội thoại"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tình trạng hoặc trạng thái hiện tại của cuộc hội thoại"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái hiện tại của cuộc hội thoại"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề hoặc chủ đề của cuộc hội thoại"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời của nhân viên hỗ trợ trong cuộc hội thoại"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ được gán để phân loại cuộc hội thoại"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại hoặc danh mục cuộc hội thoại"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày cập nhật cuộc hội thoại lần cuối"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày cập nhật cuộc hội thoại lần cuối"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phương tiện hoặc kênh nơi diễn ra cuộc hội thoại"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung bản chép trong cuộc hội thoại"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại không đủ điều kiện để đánh giá thông qua phân tích AI"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng nhân viên hỗ trợ tham gia cuộc hội thoại"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại đã được xem chưa"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bất kỳ tin nhắn nào"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu quả liên lạc bot"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lặp lại bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số câu trả lời bot"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng cuộc gọi"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn của khách hàng"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng lặng"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếu tuyên bố tiết lộ bản ghi âm"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (Thỏa thuận mức dịch vụ)"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tăng cấp"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung bản chép cuộc gọi"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đóng"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được gắn sao"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không đủ điều kiện để đánh giá"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đánh giá bởi"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại qua"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính tả và ngữ pháp"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn của nhân viên hỗ trợ"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ hoạt động tích cực nhất"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời của nhân viên hỗ trợ"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời lượng cuộc gọi (giây)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung cuộc hội thoại"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh tin nhắn"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bình luận"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày bình luận"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thái độ"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày trả lời khảo sát CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự đồng cảm"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh khách hàng"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời của khách hàng"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày trả lời lần cuối"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại người dùng"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguy cơ khách hàng rời đi"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng lặng"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cải thiện"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch vụ vượt trội"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiếu theo dõi"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản chép"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag bình luận"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu ngoại lai"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự thấu hiểu"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày trả lời lần cuối"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng ghi chú riêng tư của cuộc hội thoại"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng ký tự trung bình"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng từ trung bình"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời công khai"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia công khai"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đánh giá"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận xét và bình luận"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm đánh giá"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái đánh giá"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính dễ đọc"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email liên quan"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên quan"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm được đánh giá"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã xem"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đánh giá"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận về khảo sát"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT trên mỗi tin nhắn"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm mức độ hài lòng (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải pháp được cung cấp"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại nguồn"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi khảo sát"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề khảo sát"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thương hiệu cuộc hội thoại"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miền thương hiệu"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh cuộc hội thoại"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày kết thúc"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày trả lời khảo sát CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày gửi khảo sát CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả cuộc hội thoại"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID bên ngoài"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường cuộc hội thoại"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thư mục cuộc hội thoại"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mẫu cuộc hội thoại"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm cuộc hội thoại"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID bên ngoài"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hộp thư cuộc hội thoại"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mức độ ưu tiên cuộc hội thoại"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời cuộc hội thoại"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tình trạng cuộc hội thoại"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái cuộc hội thoại"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề cuộc hội thoại"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng câu trả lời của nhân viên hỗ trợ"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại cuộc hội thoại"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày cập nhật"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày cập nhật"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung bản chép"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng nhân viên hỗ trợ tham gia"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái đã xem"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khả năng dễ bị tổn thương"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sức khỏe dễ bị tổn thương"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện cuộc sống dễ bị tổn thương"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục QA tự động"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một điều kiện liên quan đến ngày"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm tên vào bộ lọc của bạn"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điền tên bộ lọc"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đăng nhập"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cựu thành viên của không gian làm việc"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành viên của không gian làm việc"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thêm ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áp dụng bộ lọc"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn nên có ít nhất 1 bộ lọc liên quan đến ngày"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn tùy chọn"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một tùy chọn"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thời gian dùng thử của bạn sẽ kết thúc sau ", _interpolate(_named("n")), " ngày. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thời gian dùng thử của bạn sẽ kết thúc <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>sau ", _interpolate(_named("n")), " ngày</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thời gian dùng thử của bạn sẽ kết thúc sau ", _interpolate(_named("n")), " ngày. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thời gian dùng thử của bạn sẽ kết thúc <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>sau ", _interpolate(_named("n")), " ngày</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thời gian dùng thử của bạn kết thúc vào hôm nay. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian dùng thử của bạn kết thúc vào <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>hôm nay</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập đăng ký ngay bây giờ"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " ngày"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn còn ", _interpolate(_named("days")), " dùng thử"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đã đạt đến giới hạn người dùng cho gói của mình. ", _interpolate(_named("link")), " để tìm hiểu thêm hoặc nâng cấp gói của bạn."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấp vào đây"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết nối bộ phận trợ giúp của bạn"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chọn này sẽ cho phép chúng tôi nhập phiếu của bạn để bạn đánh giá."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối bộ phận trợ giúp của bạn"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đang sử dụng Zendesk QA với dữ liệu mẫu. Để xóa dữ liệu mẫu và nhập các cuộc hội thoại của riêng bạn, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu mẫu"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép thông tin hữu ích dưới đây"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chụp ảnh màn hình toàn bộ trang để cung cấp ngữ cảnh"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy liên hệ với đội hỗ trợ khách hàng của chúng tôi để giải quyết vấn đề này:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xảy ra sự cố"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham chiếu lỗi:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép thông tin hữu ích"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng, bot và không gian làm việc"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng và Không gian làm việc"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôm nay"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tháng này"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuần này"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã đánh giá ", _interpolate(_named("n")), "/", _interpolate(_named("total")), " trong ", _interpolate(_named("period"))]), _normalize(["Đã đánh giá ", _interpolate(_named("n")), "/", _interpolate(_named("total")), " trong ", _interpolate(_named("period"))]), _normalize(["Đã đánh giá ", _interpolate(_named("n")), "/", _interpolate(_named("total")), " trong ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin mới"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng xuất"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phím tắt"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trò chuyện với chúng tôi"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tải cuộc trò chuyện (Bạn có đang sử dụng trình chặn quảng cáo không?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truy cập..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cộng đồng"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trợ giúp"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang chủ"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá được đưa ra"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thư viện thông tin"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi tài khoản"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiệm vụ"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ứng dụng đã được cập nhật. ", _interpolate(_named("buttonStart")), "Tải lại", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn muốn bắt đầu sử dụng Zendesk QA trong không gian làm việc của mình:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chúng tôi muốn bạn biết rằng bạn đã nhận được lời mời sử dụng Zendesk QA trong không gian làm việc. Tất nhiên bạn có thể tiếp tục nếu muốn, nhưng hãy lưu ý rằng thao tác này sẽ tạo ra một ", _interpolate(_named("bold")), " cho bạn."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tổ chức riêng biệt"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vâng, hãy để tôi tiếp tục với ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tài khoản mới"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn chắc chắn chứ?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " yêu cầu bạn tham gia ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có kết nối internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm ghim mới"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều hướng nhanh"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tập trung và mở rộng trình biên tập bình luận"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn hoặc hiện bảng bên"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều hướng đến cuộc hội thoại tiếp theo"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở cuộc hội thoại gốc"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều hướng đến cuộc hội thoại trước đó"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đưa ra đánh giá cao nhất có thể cho tất cả các nhóm"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đưa ra đánh giá thấp nhất có thể cho tất cả các nhóm"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xáo trộn các cuộc hội thoại (sắp xếp lại ngẫu nhiên)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy cho chúng tôi biết"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phím tắt"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi phương thức lối tắt"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đã đạt đến giới hạn ", _interpolate(_named("charLimit")), " ký tự"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Không cho phép loại hình ảnh ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ")"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kích thước hình ảnh ", _interpolate(_named("name")), " quá lớn (cho phép 5MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tải được trình biên tập bình luận"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thả để tải lên"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hình ảnh"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý mẫu"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có mẫu nào được thêm"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nguyên nhân gốc rễ"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác bao gồm cả lý do đã xóa"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lí do nhận xét"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quốc gia"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Họ"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng nhân viên"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi sẽ liên hệ để hỗ trợ bạn thiết lập tài khoản"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên công ty"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số liên hệ công ty"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn biết đến Zendesk QA lần đầu qua đâu?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn kênh"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một số nhân viên hỗ trợ"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điền vào tất cả các trường bắt buộc"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn phải đồng ý là đã đọc và chấp thuận các điều khoản cũng như quy định về sự riêng tư của chúng tôi."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh Slack được hỗ trợ"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quảng cáo"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn bè hoặc đồng nghiệp"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thị trường bộ phận trợ giúp hoặc người quản lý tài khoản"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang giới thiệu"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công cụ tìm kiếm (Google, Bing, v.v.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mạng xã hội"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy cho chúng tôi biết về bản thân bạn và công ty của bạn."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết tài khoản"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi đã sẵn sàng."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duyệt quanh, tìm hiểu ứng dụng, xóa và thêm tính năng."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạo tài khoản. Bước tiếp theo là gì?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã sẵn sàng nhập những cuộc hội thoại thực sự của mình chưa?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm dữ liệu của riêng bạn"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắt đầu tìm hiểu"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dùng thử với dữ liệu mẫu"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để chúng tôi có thể bắt đầu nhập phiếu của bạn để bạn đánh giá."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối tài khoản bộ phận trợ giúp của bạn"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi đã đổi ý."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy đăng ký"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã tạo ghim. ", _interpolate(_named("buttonStart")), "Truy cập ghim", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim hướng dẫn được cung cấp trong gói Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp gói"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tất cả các gói"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu ghi chú của bạn để chuẩn bị cho phiên hướng dẫn hoặc phiên 1:1 tiếp theo."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim dành cho Hướng dẫn"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận hoặc thêm người khác bằng ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại này đã bị xóa"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo ghim mới"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo ghim"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa ghim"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cách ghim hoạt động"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không có quyền xem cuộc hội thoại này"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ ghim"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim cuộc hội thoại"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển ghim"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu ghim"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim thẻ này"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim cuộc hội thoại này"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim bảng điều khiển này"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim dưới tư cách người dùng"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một người dùng"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ bạn mới có thể xem nội dung mình ghim. Để chia sẻ với người khác, hãy ", "@", "đề cập đến họ trong phần bình luận."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " hiện đã được mời tham gia cuộc hội thoại này"]), _normalize([_interpolate(_named("agentsNames")), " hiện đã được mời tham gia cuộc hội thoại này"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 bình luận"]), _normalize([_interpolate(_named("n")), " bình luận"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bạn đã đề cập đến ", _interpolate(_named("participants")), " nhưng họ không tham gia cuộc hội thoại này."]), _normalize(["Bạn đã đề cập đến ", _interpolate(_named("participants")), " nhưng họ không tham gia cuộc hội thoại này."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " người tham gia"]), _normalize([_interpolate(_named("n")), " người tham gia"]), _normalize([_interpolate(_named("n")), " người tham gia"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn có muốn xóa quyền truy cập vào ghim này của ", _interpolate(_named("agentName")), " không?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thực hiện thao tác gì"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không mời họ tham gia cuộc hội thoại này"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bình luận"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời họ"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời họ tham gia cuộc hội thoại này"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa người dùng"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ đề"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể hoàn tác thao tác này."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này cũng sẽ xóa tất cả các bình luận dưới ghim này."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tin nhắn mới"]), _normalize([_interpolate(_named("n")), " tin nhắn mới"]), _normalize([_interpolate(_named("n")), " tin nhắn mới"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ bạn mới có thể xem ghim của mình. Để chia sẻ ghim, hãy ", "@", " đến người khác trong phần bình luận."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " đã ghim ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một bảng điều khiển"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một cuộc hội thoại"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một ghi chú"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp để nhận ghim hướng dẫn"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim hướng dẫn được cung cấp trong gói Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim hướng dẫn được cung cấp trong gói Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp để nhận tính năng"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuẩn bị cho phiên 1:1 của bạn hoặc lưu nội dung để dành lúc khác."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn ghim và tất cả các bình luận đã thêm."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa ghim"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa ghim"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên thư mục"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim nội dung để quay lại sau"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trả lời"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mới nhất trước"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cũ nhất trước"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu một xu hướng hoặc cuộc hội thoại thú vị trên bảng điều khiển để chuẩn bị cho phiên 1-1 của bạn hoặc ghi chú lại để dành lúc khác."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo ghim bằng cách nhấp vào nút ở trên hoặc biểu tượng ghim trong danh sách xem Cuộc hội thoại hoặc Bảng điều khiển."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có ghim nào được tạo"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Không có kết quả cho ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử sử dụng các từ khóa khác hoặc kiểm tra lỗi chính tả."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mới"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo ghim mới"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh bạ"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo người dùng, từ khóa..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo thứ tự bảng chữ cái"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được tạo gần đây"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chỉnh sửa gần đây"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được ghim nhiều nhất"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tôi sẽ đánh giá ", _interpolate(_named("n")), " cuộc hội thoại ", _interpolate(_named("schedule"))]), _normalize(["Tôi sẽ đánh giá ", _interpolate(_named("n")), " cuộc hội thoại ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật mục tiêu"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt mục tiêu"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể đặt mục tiêu cá nhân cho số lượng cuộc hội thoại bạn muốn đánh giá."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mỗi ngày"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mỗi tháng"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mỗi tuần"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá mục tiêu"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CÁ NHÂN"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chung"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mẫu"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải các kênh"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn kênh"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ chia sẻ ý kiến phản hồi về khảo sát kèm theo bình luận"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đề cao đồng nghiệp của bạn bằng cách làm nổi bật chất lượng dịch vụ vượt trội và ý kiến phản hồi tuyệt vời của khách hàng."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ ý kiến phản hồi tích cực về khảo sát lên Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy kênh nào"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động chia sẻ ý kiến phản hồi với điểm ở trên"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp bot khen"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận lời nhắc vào đầu tuần nếu bạn chưa hoàn tất bất kỳ đánh giá nào."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời nhắc"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời nhắc hàng tuần để thực hiện đánh giá"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận thông báo về các đánh giá, câu trả lời, đề cập và ý kiến phản đối mới trong Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đề cập"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận xét"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu trả lời chủ đề"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo về các đánh giá, câu trả lời, đề cập và ý kiến phản đối mới."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối liên quan đến tôi"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đề cập trong các bình luận"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá mới nhận được"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu trả lời trong chủ đề"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo kèm theo số liệu thống kê"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận báo cáo về các đánh giá được đưa ra và nhận được."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hàng ngày"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hàng tháng"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hàng tuần"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng ngày"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau mỗi 3 giờ"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau mỗi 6 giờ"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngay lập tức"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tháng"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tuần"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản trị viên tài khoản của bạn đã khóa cài đặt thông báo."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn khoảng thời gian"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo cá nhân"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (đang hoạt động)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm tùy chọn đăng nhập bằng tài khoản mạng xã hội mới vào tài khoản này:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác xóa phương thức đăng nhập đang hoạt động sẽ khiến bạn bị đăng xuất. Bạn có thể đăng nhập lại bằng email thay thế của mình."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa phương thức đăng nhập"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm Google làm tùy chọn đăng nhập thành công"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chọn đăng nhập"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm Slack làm tùy chọn đăng nhập thành công"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mật khẩu"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn chắc chắn chứ?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phương thức"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt lại mật khẩu"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Một email sẽ được gửi tới ", _interpolate(_named("email")), " để đặt lại mật khẩu của bạn."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email chính"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểm tra hộp thư đến của bạn để nhận email xác nhận của chúng tôi"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết ma thuật"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Để thay đổi, hãy thay đổi ", _interpolate(_named("link")), " của bạn trước."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email ", _interpolate(_named("email")), " sẽ được liên kết với mật khẩu này."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật hồ sơ"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ảnh hồ sơ"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để có kết quả tốt nhất, hãy tải lên ảnh vuông (256x256 pixel trở lên)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email chính"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đầu tuần"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt cá nhân"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn ít nhất một tùy chọn"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung mẫu"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên mẫu"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị mẫu trong"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nội dung mẫu"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm tên mẫu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mẫu"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mẫu"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mẫu bình luận để tăng tốc độ đánh giá, ghim các mục và hướng dẫn."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được dùng trong"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa mẫu"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn xóa vĩnh viễn mẫu này?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa mẫu"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mẫu bình luận để tăng tốc độ đánh giá, ghim các mục và hướng dẫn."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có mẫu nào được tạo"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạo mẫu bình luận"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa mẫu bình luận"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu mẫu bình luận"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá và ý kiến phản đối"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Để có quyền truy cập, hãy liên hệ với ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hỗ trợ khách hàng"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy tài khoản"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hiện tại, hãy sử dụng ", _interpolate(_named("link")), " để truy cập Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản chưa được di chuyển"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thông số"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điểm"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["số liệu thống kê"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hiệu chuẩn"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuộc hội thoại"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bộ lọc"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đưa ra"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận được"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bình luận của tôi"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ý kiến phản đối của tôi"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá của tôi"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản ứng"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận được"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được bắt đầu"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi khảo sát"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cá nhân"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mẫu"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tạo mật khẩu"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thông tin đăng nhập"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng nhập"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đặt lại"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá mục tiêu"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mẫu"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người dùng"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mẫu"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chung"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mẫu"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tài khoản"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hóa đơn"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["công ty"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ phận trợ giúp"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tổ chức"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["khoản thanh toán"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giấy phép"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cài đặt"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng ký"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chung"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục mới"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm thấy tin nhắn xung đột"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi hoặc xóa một trong các tin nhắn xung đột"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sử dụng biểu tượng ", _interpolate(_named("placeholder")), " để loại trừ dữ liệu nhạy cảm khỏi QA tự động. Ví dụ: thay vì “Bảo trọng nhé, John”, sử dụng “Bảo trọng nhé, ", _interpolate(_named("placeholder")), "” để đại diện cho tên bất kỳ."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tối đa ", _interpolate(_named("limit")), " hàng"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áp dụng quy tắc cho"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã đạt đến giới hạn 10 danh mục tùy chỉnh"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đã đạt giới hạn ", _interpolate(_named("limit")), " nhóm tùy chỉnh"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm điều kiện"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm hàng"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["và"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa điều kiện"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu tin nhắn của nhân viên hỗ trợ"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu không thì chấm điểm"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau đó chấm điểm"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo danh mục thẻ điểm"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sử dụng '/' để thêm một biến"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các hướng"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống tin nhắn của nhân viên hỗ trợ"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn không được dài hơn 300 ký tự"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nguồn"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các kênh"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả thẻ đánh dấu"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả cuộc hội thoại"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới hạn cuộc hội thoại áp dụng quy tắc của bạn."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại cụ thể"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo thông tin chuyên sâu về Tiêu điểm"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống tin nhắn của nhân viên hỗ trợ"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã đạt đến giới hạn 10 thông tin chuyên sâu tùy chỉnh"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đã đạt giới hạn ", _interpolate(_named("limit")), " thông tin chuyên sâu tùy chỉnh"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điền tên thông tin chuyên sâu"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên thông tin chuyên sâu"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo danh mục"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại thông tin chuyên sâu"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu cực"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình thường"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích cực"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truy cập cài đặt không gian làm việc"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để sau"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để bắt đầu sử dụng danh mục nhận xét này, hãy truy cập cài đặt thẻ điểm trong không gian làm việc của bạn và thêm danh mục đánh giá này vào thẻ điểm bạn chọn."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạo danh mục nhận xét"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động chấm điểm các cuộc hội thoại có lời kết bằng cách xác định các từ khóa hoặc cụm từ cụ thể."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hoạt động trên bản chép thoại."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hiểu"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xin chúc mừng! ✋ Bạn đã đạt mức tối đa các danh mục nhận xét tự động của mình là ", _interpolate(_named("limit")), ". Bạn là thuật sĩ phân loại! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đạt giới hạn danh mục"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy đảm bảo tất cả các trường đã được điền đúng"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt tên cho danh mục đánh giá của bạn"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên danh mục"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục nhận xét mới"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục nhận xét mới"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động chấm điểm các cuộc hội thoại có lời kết bằng cách xác định các từ khóa hoặc cụm từ cụ thể. Không hoạt động trên bản chép thoại."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động hiển thị các cuộc hội thoại cơ bản đã đóng bằng cách xác định các từ khóa hoặc cụm từ cụ thể. Chỉ hoạt động trên bản chép."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động chấm điểm các cuộc hội thoại bằng cách xác định các từ khóa hoặc cụm từ cụ thể trong tin nhắn của nhân viên hỗ trợ và bot."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để sử dụng danh mục nhận xét này, hãy truy cập cài đặt thẻ điểm trong không gian làm việc của bạn và thêm danh mục nhận xét này vào thẻ điểm."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động cho điểm cuộc hội thoại dựa trên các từ khóa hoặc cụm từ cụ thể trong tin nhắn."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dành cho"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập thông tin chuyên sâu"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu này hiển thị trong Tiêu điểm dựa trên các tiêu chí đã xác định."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập thẻ điểm"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể sử dụng danh mục này trên thẻ điểm dựa trên các tiêu chí đã xác định."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trò chuyện"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sắp ra mắt"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tùy chỉnh"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["định sẵn"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thẻ điểm"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiêu điểm"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thoại"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu các thay đổi"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu các thay đổi"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mẹo:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấp vào biểu tượng ", "{··}", " để loại trừ tên, chi tiết về công ty hoặc bất kỳ thông tin nhạy cảm nào khác trong quá trình kiểm tra lời kết của QA tự động."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lời kết được phê duyệt"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa lời kết được phê duyệt"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết được phê duyệt"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm trường hợp miễn"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa trường hợp miễn"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường hợp miễn"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lời chào được phê duyệt"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa lời chào được phê duyệt"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào được phê duyệt"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dán các từ vào đây và phân cách bằng dấu ngắt dòng"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấn Enter sau mỗi từ để nhập mới"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ áp dụng"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví dụ: thay vì \"Bảo trọng nhé, John\", bạn có thể sử dụng \"Bảo trọng nhé, ", "{··}", "\" để đại diện cho tên bất kỳ."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỗ trống cho nội dung động"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường hợp miễn"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã thêm 1 trường hợp miễn"]), _normalize(["Đã thêm ", _interpolate(_named("count")), " trường hợp miễn"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã thêm trường hợp miễn trước đó"]), _normalize(["Đã thêm các trường hợp miễn trước đó: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm trường hợp miễn"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy trường hợp miễn nào"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có trường hợp miễn nào được thêm"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường hợp miễn (phân biệt chữ hoa/chữ thường)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Thêm trường hợp miễn"]), _normalize(["Thêm 1 trường hợp miễn"]), _normalize(["Thêm ", _interpolate(_named("count")), " trường hợp miễn"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm từ hoặc cụm từ"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu trường hợp miễn"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ngôn ngữ"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả ngôn ngữ"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm ngôn ngữ"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã thêm lời kết trước đó"]), _normalize(["Đã thêm các lời kết trước đó: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lời kết"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy lời kết được phê duyệt nào"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có lời kết được phê duyệt"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lời kết"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm lời kết"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm lời kết"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu lời kết"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mẫu có các mục nhập trùng lặp: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã thêm lời chào trước đó"]), _normalize(["Đã thêm các lời chào trước đó: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lời chào"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy lời chào được phê duyệt nào"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có lời chào được phê duyệt"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lời chào"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm lời chào"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm lời chào"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu lời chào"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm trường hợp miễn"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Xóa trường hợp miễn"]), _normalize(["Xóa các trường hợp miễn"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bạn muốn xóa vĩnh viễn trường hợp miễn này?"]), _normalize(["Bạn muốn xóa vĩnh viễn các trường hợp miễn này?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa trường hợp miễn"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Một hoặc nhiều trường hợp miễn dài hơn ", _interpolate(_list(0)), " ký tự"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " ký tự"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày cập nhật"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người cập nhật"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biến"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định các lời kết của riêng bạn phù hợp với tiêu chuẩn dịch vụ và thương hiệu của bạn. Khi được thêm, QA tự động sẽ chỉ phát hiện những lời kết đã được phê duyệt. Các thay đổi được thực hiện áp dụng cho các cuộc hội thoại trong tương lai."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã đạt đến giới hạn tối đa là 100 lời kết được phê duyệt. Nếu bạn muốn bổ sung thêm, bạn nên xem lại và tinh chỉnh các lời kết hiện tại của bạn."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đạt đến giới hạn lời kết được phê duyệt"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết được phê duyệt"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Những từ và cụm từ bạn muốn QA tự động bỏ qua, không coi là lỗi chính tả hoặc ngữ pháp. Các thay đổi được thực hiện áp dụng cho các cuộc hội thoại trong tương lai."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh sách bỏ qua"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định các lời chào của riêng bạn phù hợp với tiêu chuẩn dịch vụ và thương hiệu của bạn. Khi được thêm, QA tự động sẽ chỉ phát hiện những lời chào đã được phê duyệt. Các thay đổi được thực hiện áp dụng cho các cuộc hội thoại trong tương lai."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã đạt đến giới hạn tối đa là 100 lời chào được phê duyệt. Nếu bạn muốn bổ sung thêm, bạn nên xem lại và tinh chỉnh các lời chào hiện tại của bạn."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đạt đến giới hạn lời chào được phê duyệt"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào được phê duyệt"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để sau"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa danh mục"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn xóa vĩnh viễn danh mục nhận xét này?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi danh sách danh mục"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục đã xóa sẽ không được hiển thị trên bất kỳ cuộc hội thoại và bộ lọc mới nào. Dữ liệu lịch sử trên bảng điều khiển và các cuộc hội thoại vẫn còn nguyên."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục sẽ bị xóa khỏi tất cả các thẻ điểm mà danh mục đã được thêm vào."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể xóa danh mục thẻ điểm"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa danh mục thẻ điểm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa danh mục thẻ điểm"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các danh mục QA tự động"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " được hỗ trợ bởi mô hình AI sẽ vẫn nằm trong thẻ điểm nhưng sẽ không còn được chấm điểm tự động nữa"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt tích hợp LLM không tắt hoàn toàn tính năng QA tự động . Sau đây là những điều sẽ xảy ra:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu lịch sử"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " sẽ vẫn còn trên bảng điều khiển QA tự động"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ được thêm thủ công"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " sẽ vẫn còn, nhưng tất cả các nguyên nhân gốc rễ dựa trên LLM sẽ bị xóa"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt QA tự động dựa trên LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động sử dụng AI và mô hình ngôn ngữ lớn (LLM) để tự động chấm điểm các danh mục cụ thể. Dữ liệu của bạn được xử lý theo Thỏa thuận xử lý dữ liệu (DPA) nghiêm ngặt và không bao giờ được sử dụng để đào tạo bất kỳ mô hình nào."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về AI tạo sinh"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["QA tự động sử dụng Dịch vụ Microsoft Azure OpenAI cho ", _interpolate(_named("automatically_scoring_categories")), ". Dữ liệu của bạn chỉ được xử lý theo DPA nghiêm ngặt để cung cấp QA tự động cho bạn và ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tự động chấm điểm một số danh mục"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuyệt đối không sử dụng để đào tạo bất kỳ mô hình OpenAI nào"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động dựa trên LLM đã bật"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động dựa trên LLM đã tắt"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động dựa trên LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động chấm điểm bằng AI trong QA tự động"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xử lý dữ liệu của các danh mục QA tự động dựa trên MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu hồi quyết định đồng ý sử dụng Microsoft Azure."])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đồng ý sử dụng Microsoft Azure. Cuộc hội thoại sẽ sớm nhận được nhận xét tự động."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn vẫn có thể sử dụng QA tự động, nhưng đây là những gì sẽ xảy ra"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác tắt dịch vụ MS Azure OpenAI sẽ không tắt chức năng QA tự động."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thực hiện thao tác đó trong danh sách xem danh mục thẻ điểm."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các danh mục QA tự động do mô hình cung cấp sẽ vẫn còn trên thẻ điểm nhưng sẽ không được chấm điểm tự động."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ mà bạn đã thêm thủ công sẽ vẫn còn. Tất cả các nguyên nhân gốc rễ do mô hình tạo ra sẽ bị loại bỏ."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu lịch sử sẽ vẫn còn trên bảng điều khiển QA tự động."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt xử lý dữ liệu bằng dịch vụ MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiều loại danh mục QA tự động hơn."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ đa ngôn ngữ tức thì."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm các danh mục mới nhanh chóng."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cải thiện độ chính xác của đánh giá. GPT-3.5 cho thấy nhận thức về ngữ cảnh ở cấp độ con người."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hưởng lợi từ những cải tiến mới nhất trong công nghệ AI."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là những lợi ích bạn sẽ nhận được"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bật xử lý dữ liệu bằng dịch vụ MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đồng ý"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không đồng ý"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng mới được chỉ định vào không gian làm việc này"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc mặc định"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Định dạng 12 giờ (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Định dạng 24 giờ (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng thời gian"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Hai"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ Nhật"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật hồ sơ công ty"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đầu tuần"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp tùy chỉnh"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID khóa truy cập và khóa bí mật thường được tìm thấy trong Quản lý truy cập > Người dùng"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID khóa truy cập AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ví dụ: AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy thay đổi khóa truy cập bí mật"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi giá trị khóa truy cập bí mật"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối chỉ cho phép xác định một khu vực, thường được tìm thấy trong Vị trí"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khu vực AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ví dụ: eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm khóa bí mật"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khóa truy cập bí mật AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ví dụ: wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể tìm thấy mọi thông tin liên quan trong Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thường được tìm thấy trong cài đặt phiên bản Amazon Connect và Trang lưu trữ dữ liệu"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vị trí lưu trữ bản ghi âm cuộc gọi"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ví dụ: amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xảy ra lỗi khi thêm Amazon Connect. Hãy kiểm tra xem tất cả các trường đã được điền đúng cách hay chưa và thử lại."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vị trí lưu trữ phải ở định dạng thùng/thư mục được phân tách bằng dấu gạch chéo."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Để thêm kết nối, hãy liên hệ với ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ khách hàng"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả phiếu Zendesk của bạn sẽ được đồng bộ tự động. Bạn có thể chọn để loại trừ các phiếu đã chọn."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Á - Thái Bình Dương (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Á - Thái Bình Dương (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Á - Thái Bình Dương (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Á - Thái Bình Dương (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Á - Thái Bình Dương (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (Miền Trung)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Âu (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Âu (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Âu (Ireland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Châu Âu (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vùng máy chủ"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung Đông (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn khu vực máy chủ"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam Mỹ (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miền Đông Hoa Kỳ (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miền Đông Hoa Kỳ 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miền Tây Hoa Kỳ (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối đã được thêm"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắt đầu kết nối"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ đồng bộ các cuộc hội thoại mà"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kênh một, kênh hai"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID duy nhất của kết nối này là ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 tháng"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 năm"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 tháng"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 tháng"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các cuộc hội thoại không hoạt động sẽ bị xóa sau khoảng thời gian lưu giữ đã chọn. Các đánh giá đã gửi sẽ vẫn hiển thị trong danh sách xem Hoạt động và Bảng điều khiển."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian lưu giữ"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn khoảng thời gian lưu giữ"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin lưu ý rằng bạn sẽ không thể hoàn tác thao tác này."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi hiểu rằng thao tác này sẽ xóa tất cả dữ liệu liên quan đến bộ phận trợ giúp cho kết nối này khỏi Zendesk QA, bao gồm cả các cuộc hội thoại, trường và thẻ đánh dấu."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các đánh giá đã gửi sẽ không bị ảnh hưởng và sẽ vẫn hiển thị trong các danh sách xem cụ thể."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhóm một, nhóm hai"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bao gồm"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phát triển tích cực"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tích hợp này vẫn đang được ", _interpolate(_named("activeDevelopment")), ". Một số chi tiết có thể bị thiếu (như tập tin đính kèm hoặc trường tùy chỉnh)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hộp thư một, hộp thư hai"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thẻ đánh dấu một, thẻ đánh dấu hai"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật mã của token API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm \"Tên trường phiếu\" trên Freshdesk trong Cài đặt → Trường phiếu"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm \"Custom Field Names\" trên Help Scout trong Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm \"Tên cuộc hội thoại Klass\" trên Kustomer trong Cài đặt → Klasses → Cuộc hội thoại"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm \"Tên trường tùy chỉnh\" trên Live Agent trong Cài đặt → Trường tùy chỉnh"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm \"Custom Field Name\" trên Wix trong Settings → Custom fields"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân cách các ID trường bằng dấu phẩy. Bạn có thể tìm thấy những ID này trong Zendesk trong trường Quản trị viên → Trường phiếu."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy kết nối một vài người dùng bộ phận trợ giúp để đánh giá các cuộc hội thoại đầu tiên của bạn. Đừng lo, chúng tôi sẽ không thông báo cho họ trừ khi bạn mời họ trở thành thành viên của không gian làm việc."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp thành công"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thành viên"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bây giờ chúng tôi sẽ bắt đầu nhập các cuộc hội thoại của bạn."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Miền: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mã nhận dạng bộ phận trợ giúp: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể xác thực kết nối của bạn"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả đã sẵn sàng. Đây là kết nối mới của bạn."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một số kết nối chưa được cập nhật hoặc không hoạt động. Hãy thử cập nhật hoặc xóa các kết nối này khỏi danh sách của bạn."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ứng dụng khách API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật mã của ứng dụng khách API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tạo token API mới trong Cài đặt → Tài khoản → API và SDK. URL chuyển hướng cần sử dụng là ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID tài khoản"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn bộ phận trợ giúp của bạn để bắt đầu"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu tài khoản Intercom của bạn được thiết lập để phục vụ từ Châu Âu hoặc Úc, hãy cập nhật thông tin này."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Email bạn sử dụng để đăng nhập vào ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn tên, email và số điện thoại của người dùng cuối. Thông tin đăng nhập ngân hàng đã được ẩn cho tất cả các kết nối bộ phận trợ giúp."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có tích hợp nào. Hãy thêm ngay một tích hợp."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn cần những vai trò sau:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo kết nối"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm kết nối mới"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác bỏ chọn tùy chọn này cho phép bạn thêm thủ công kết nối này vào các không gian làm việc cụ thể"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối với tất cả các không gian làm việc hiện có"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả không gian làm việc"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật mã của mã API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đã xóa kết nối ", _interpolate(_list(0))])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên kết nối"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể lấy token API của mình ở phía dưới."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngưỡng CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa kết nối ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập \"xóa kết nối\" để xóa kết nối này"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa kết nối"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý và giám sát các nguồn dữ liệu được kết nối của bạn."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa kết nối"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại trừ"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân cách các tên trường bằng dấu phẩy"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật không thành công"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt tên duy nhất cho tích hợp của bạn"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn các tùy chọn nâng cao"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn các trường dữ liệu nhạy cảm"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không bao giờ lưu trữ URL tập tin đính kèm"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ qua tập tin đính kèm"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không bao giờ lưu trữ nội dung cuộc hội thoại (bạn có thể nhập nội dung đó sau nếu bộ phận trợ giúp của bạn yêu cầu)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ qua nội dung cuộc hội thoại"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không bao giờ lưu trữ tiêu đề cuộc hội thoại (bạn có thể nhập tiêu đề đó sau nếu bộ phận trợ giúp của bạn yêu cầu)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ qua dòng tiêu đề"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hoạt động"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Miền phụ ", _interpolate(_list(0)), " của bạn"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khu vực của Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So khớp ID đội trong hộp thư đến"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID số của đội Intercom của bạn"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang trong tiến trình..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hộp thư"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật kết nối thủ công"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn dữ liệu khách hàng"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có không gian làm việc"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên trường nhạy cảm"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID trường nhạy cảm"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gia hạn kết nối"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gia hạn kết nối"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối với cơ chế bảo mật ảo sandbox Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Thiết lập kết nối với ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện các tùy chọn nâng cao"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ nhập các cuộc hội thoại có điểm thấp hơn hoặc bằng ngưỡng đã đặt"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áp dụng ngưỡng CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ đồng bộ hóa các cuộc hội thoại..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận cuộc hội thoại mới nhất"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật kết nối"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật kết nối"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên người dùng"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ phận trợ giúp của bạn"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Để hủy đăng ký và xóa tài khoản của bạn, hãy liên hệ ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ khách hàng"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa tài khoản"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa một phiếu trong Zendesk Support (hoặc một bộ phận trợ giúp khác) không ảnh hưởng đến các đánh giá cuộc hội thoại liên quan. Bạn vẫn có thể xem và quản lý chúng."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Việc xóa phiếu trong Zendesk QA cũng sẽ xóa các đánh giá cuộc hội thoại có liên quan.", _interpolate(_named("line_break")), "Việc xóa phiếu trong Zendesk Support (hoặc bộ phận trợ giúp khác) không ảnh hưởng đến các đánh giá cuộc hội thoại có liên quan. Bạn vẫn có thể xem và quản lý các cuộc hội thoại này."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý đánh giá"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa đánh giá là vĩnh viễn. Nó cũng ảnh hưởng đến dữ liệu trong bảng điều khiển."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa tất cả các đánh giá"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa tất cả"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các đánh giá đã bị xóa"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa tất cả"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi có đánh giá liên quan đến các phiếu đã bị xóa, bạn sẽ thấy chúng ở đây."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có đánh giá nào"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm ID phiếu"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID phiếu: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại đã bị xóa"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Không thể xóa không gian làm việc mặc định. Để xóa không gian làm việc này, chọn một không gian làm việc mặc định khác trong ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt tài khoản"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ không thể hoàn tác thao tác này."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loại bỏ và xóa tất cả dữ liệu"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tôi hiểu rằng thao tác này sẽ ", _interpolate(_named("removeAllData")), " liên quan đến không gian làm việc này, bao gồm cả người dùng và kết nối trong không gian làm việc."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa không gian làm việc"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập \"xóa không gian làm việc\" để xóa không gian làm việc này"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa không gian làm việc ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa không gian làm việc"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy chắc chắn bạn muốn thực hiện thao tác hoặc tham khảo ý kiến ​​chuyên gia trước."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn cách lựa chọn người được đánh giá mặc định dựa trên nhân viên hỗ trợ thường trực nhất hay nhân viên phụ trách hiện tại."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ hoạt động tích cực nhất"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá mặc định"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép mọi người đánh giá các cuộc hội thoại của chính mình và cho phép nhân viên hỗ trợ thực hiện đánh giá"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc đã bị xóa"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc đã được cập nhật"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập chấm điểm trung lập bằng cách ẩn các đánh giá do người đánh giá khác đưa ra trước đó"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chấm điểm không thiên vị"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Màu"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ không thể hoàn tác thao tác này."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin lưu ý rằng thao tác này sẽ loại bỏ và xóa tất cả dữ liệu liên quan đến không gian làm việc này, bao gồm cả người dùng và kết nối trong không gian làm việc."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa không gian làm việc"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa không gian làm việc..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự đánh giá"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết ma thuật"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết ma thuật"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể hủy kích hoạt phương thức đăng nhập hiện tại của bạn"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phương thức xác thực đã được cập nhật"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép một số loại phương thức xác thực nhất định cho tất cả người dùng."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÀI KHOẢN"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng và không gian làm việc"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo và quản lý không gian làm việc, mời hoặc kết nối người dùng."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ở lại trang"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rời khỏi trang"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn rời khỏi trang này và mất tất cả các thay đổi?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có vẻ như không có cuộc hội thoại nào để nhập. Bắt đầu bằng cách mở một số cuộc hội thoại với bộ phận trợ giúp của bạn, sau đó quay lại đây để phân tích."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy cuộc hội thoại nào"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không thể xóa nhóm hệ thống này"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa nhóm"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việc xóa nhóm là thao tác vĩnh viễn. Thao tác sẽ xóa tất cả dữ liệu nhóm từ báo cáo."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để khôi phục nhóm này, bạn sẽ cần tạo lại nó."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa nhóm"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm nhóm"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các thẻ điểm"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các nhóm tự động tự động điền thẻ điểm với các đánh giá được đề xuất để tiết kiệm thời gian xem xét. Các nhóm thủ công được những người đánh giá từ đầu điền."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật gần nhất"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thủ công"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự đồng cảm"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính tả và ngữ pháp"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự thấu hiểu"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính dễ đọc"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải pháp được cung cấp"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy nhóm nào"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy nguyên nhân gốc rễ"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy thẻ điểm nào"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thử tìm kiếm khác hoặc thay đổi bộ lọc"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nhóm"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nhóm"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nhóm"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nhóm"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nhóm"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " thẻ điểm"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " thẻ điểm"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " thẻ điểm"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các thẻ điểm"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " thẻ điểm"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " trạng thái"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " trạng thái"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " trạng thái"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các trạng thái"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " trạng thái"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " loại"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " loại"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " loại"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các loại"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " loại"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " không gian làm việc"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " không gian làm việc"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy không gian làm việc nào"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " không gian làm việc"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các không gian làm việc"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " không gian làm việc"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trọng số của ", _interpolate(_named("weight")), " đóng góp ", _interpolate(_named("percentage")), "% vào điểm tổng thể"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa thẻ điểm"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việc xóa thẻ điểm là thao tác vĩnh viễn. Thao tác này sẽ xóa tất cả dữ liệu thẻ điểm từ báo cáo."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để khôi phục thẻ điểm này, bạn sẽ cần phải tạo lại nó."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa thẻ điểm"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các nhóm"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật gần nhất"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài viết nháp"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hoạt động"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các nhóm"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các thẻ điểm"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã cập nhật thang điểm đánh giá. ", _interpolate(_named("buttonStart")), "Bắt đầu đánh giá", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA đặt thang điểm nhị phân theo mặc định, nhưng bạn cũng có thể chọn thang điểm chi tiết hơn:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nếu bạn muốn xem các tùy chọn thang điểm đánh giá khác nhau được định cấu hình theo nhóm, hãy truy cập ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự đồng cảm/Giọng điệu"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiến thức sản phẩm"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi cập nhật thang điểm nhận xét:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm nhận xét trước đó của bạn và các danh mục tương ứng sẽ được lưu trữ (không bị xóa)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một số dữ liệu bảng điều khiển sẽ bị ảnh hưởng do thang điểm cũ và mới của bạn có thể sẽ không thể so sánh được."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép thiết lập danh mục nhận xét hiện tại của tôi sang thang điểm mới"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn cập nhật thang điểm nhận xét không?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thay đổi định dạng hiển thị thẻ điểm (số hoặc emoji) và đặt danh mục thành bắt buộc hoặc không bắt buộc."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sử dụng emoji thay vì số"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép mọi người bỏ qua danh mục"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải pháp"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại thang điểm nhận xét"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Các danh mục cho 1 không gian làm việc đã được sao chép"]), _normalize(["Các danh mục cho ", _interpolate(_named("workspacesAmount")), " không gian làm việc đã được sao chép"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt không gian làm việc"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nếu ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động hiển thị các cuộc gọi mà nhân viên hỗ trợ không thông báo cho khách hàng về khoảng lặng trong cuộc hội thoại."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại có những khoảng lặng dài hơn ngưỡng đã đặt."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định ngưỡng khoảng lặng tối đa trong cuộc gọi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng lặng"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu bị xóa sẽ không xuất hiện trên bất kỳ cuộc hội thoại và bộ lọc mới nào. Dữ liệu lịch sử trên bảng điều khiển và các cuộc hội thoại vẫn còn nguyên."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể xóa thông tin chuyên sâu về Tiêu điểm"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa thông tin chi tiết về Tiêu điểm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa thông tin chuyên sâu về Tiêu điểm"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem các cuộc hội thoại có chứa các từ hoặc cụm từ cụ thể trong bản sao và tin nhắn."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động xác định các cuộc gọi thiếu tuyên bố tiết lộ bắt buộc, như \"Cuộc gọi này sẽ được ghi âm\" và tương tự."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhằm mục đích xác định tuyên bố tiết lộ bản ghi âm trong cuộc gọi bằng Mô hình ngôn ngữ lớn (LLM) có khả năng hiểu ngữ cảnh linh hoạt mà không cần các cụm từ được cài trước."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếu tuyên bố tiết lộ bản ghi âm"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động hiển thị các cuộc gọi mà nhân viên hỗ trợ không thông báo cho khách hàng về khoảng lặng trong cuộc hội thoại."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng lặng"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định những khoảng lặng trong cuộc gọi"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn cần thay đổi đăng ký của mình? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") và chúng tôi luôn sẵn sàng trợ giúp."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động phân tích 100% các cuộc hội thoại giữa các nhân viên hỗ trợ, BPO, các kênh và ngôn ngữ. AI phát hiện các vấn đề, lỗ hổng kiến thức và cơ hội hướng dẫn để cải thiện dịch vụ."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động phạm vi 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản hồi và hướng dẫn của nhân viên hỗ trợ"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu về AI Tiêu điểm"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo nâng cao và bảng điều khiển"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích và lọc thái độ"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản dịch theo yêu cầu"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các chỉ định đánh giá và thiết lập mục tiêu"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn và bài kiểm tra"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đảm bảo chất lượng Zendesk (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mọi tính năng trong Professional, cộng thêm"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (cũ)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook để thông báo ngay lập tức về các lỗi nghiêm trọng"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp kho dữ liệu"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phát triển tích hợp tùy chỉnh"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trình quản lý quan hệ khách hàng tận tâm"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp cận trực tiếp với các đội sản phẩm và kỹ thuật của chúng tôi thông qua Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mọi tính năng trong Growth Suite, cộng thêm"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động cho phạm vi 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu về cuộc hội thoại dựa trên AI giúp giải thích dữ liệu"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng bổ sung và lọc dữ liệu của bộ phận trợ giúp được cải tiến bằng AI"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm: Tự động phát hiện các tương tác phải đánh giá"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích và lọc thái độ"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo và thông tin chuyên sâu về hiệu suất do AI hỗ trợ"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch cuộc hội thoại theo yêu cầu"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo dõi thời gian đánh giá"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích tỷ lệ đạt"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mọi tính năng trong AI Suite, cộng thêm"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gói triển khai dành cho doanh nghiệp bao gồm tích hợp Salesforce và Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phát triển tích hợp tùy chỉnh"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp kho dữ liệu"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook để thông báo ngay lập tức về các lỗi nghiêm trọng"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trình quản lý quan hệ khách hàng tận tâm"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp cận trực tiếp với các đội sản phẩm và kỹ thuật của chúng tôi"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp cận sớm và tạo ảnh hưởng đến lộ trình của chúng tôi"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự động chỉ định đánh giá và thiết lập mục tiêu"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn và phân tích được nhắm mục tiêu"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra để quản lý học tập"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò người dùng và quản lý quyền"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn dữ liệu nhạy cảm"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các tùy chọn lọc nâng cao và tìm kiếm cuộc hội thoại"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm tùy chỉnh với nhiều nhận xét, trọng số điều chỉnh được và các tùy chọn tự động không đạt"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiện ích mở rộng trình duyệt để đánh giá cuộc hội thoại ở mọi nơi"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích nguyên nhân gốc rễ"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo hiệu suất để so sánh các nhân viên hỗ trợ, đội, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát CSAT và phân tích ý kiến phản hồi của khách hàng"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mọi tính năng trên Starter, cộng thêm"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (cũ)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiều thang điểm nhận xét cho mỗi cuộc hội thoại"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu về cuộc hội thoại mang tính tương tác dựa trên AI"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định đánh giá"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghim dành cho hướng dẫn"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên hướng dẫn"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích và lọc thái độ"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lọc theo độ phức tạp của cuộc hội thoại"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chuyên sâu về cuộc hội thoại"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm có điều kiện"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích nguyên nhân gốc rễ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích CSAT do AI nâng cao hỗ trợ"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn chấm điểm"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiện ích mở rộng trình duyệt để đánh giá cuộc hội thoại ở mọi nơi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm để tìm hiểu các cuộc hội thoại phải đánh giá"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch cuộc hội thoại theo yêu cầu"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo dõi thời gian đánh giá"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gói tùy chỉnh"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (cũ)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá cuộc hội thoại không giới hạn"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đồng nghiệp và tự đánh giá"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm có trọng số điều chỉnh được và tùy chọn tự động không đạt"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các tùy chọn lọc hiệu quả"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu đánh giá hàng ngày, hàng tuần hoặc hàng tháng"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát CSAT và phân tích cơ bản"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng điều khiển"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Báo cáo để so sánh các nhân viên hỗ trợ, đội, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò người dùng và quản lý đặc quyền"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi muốn thay đổi đăng ký Zendesk QA của mình.\n\nĐánh dấu hoặc chỉ ra một tùy chọn và cho biết số lượng giấy phép mà bạn muốn thay đổi:\nTôi muốn [thêm các giấy phép X] [xóa các giây phép X] [nâng cấp lên gói Zendesk QA]\n\nVui lòng chia sẻ tên công ty của bạn và tên được liên kết với tài khoản Zendesk QA của bạn, cũng như các chi tiết khác mà chúng tôi nên biết để xử lý thay đổi này."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi đăng ký Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi muốn mua đăng ký Zendesk QA.\n\nVui lòng chia sẻ tên công ty của bạn và tên được liên kết với tài khoản Zendesk QAcủa bạn, cũng như các chi tiết khác mà chúng tôi cần biết để xử lý yêu cầu này:\n\nVui lòng chia sẻ các câu hỏi cụ thể mà bạn muốn giải quyết trước khi mua:\n\nCảm ơn bạn!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mua đăng ký Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đăng ký của bạn kết thúc vào ", _interpolate(_named("date")), " (còn ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thời gian dùng thử của bạn kết thúc vào ", _interpolate(_named("date")), " (Còn ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ngày"]), _normalize([_interpolate(_named("n")), " ngày"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("dayOfMonth")), " hàng tháng."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("dayOfMonth")), " hàng tháng."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("dayOfMonth")), " hàng tháng."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("dayOfMonth")), " hàng tháng."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("dayOfMonth")), " hàng tháng."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("dayOfMonth")), " hàng tháng."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tính phí cho ", _interpolate(_named("card")), " (hết hạn vào ", _interpolate(_named("expires")), ") vào ngày ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["có đuôi"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã hết hạn"]), _normalize(["Hết hạn sau 1 ngày"]), _normalize(["Hết hạn sau ", _interpolate(_named("expiresIn")), " ngày"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Giá khởi điểm bao gồm ", _interpolate(_named("n")), " giấy phép người dùng"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["bắt đầu ở mức ", _interpolate(_named("sum")), "/tháng"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tháng"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mỗi người dùng / tháng"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mỗi năm"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mỗi tháng"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quý"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa tác vụ hủy đã lên lịch"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nếu bạn muốn tiếp tục sử dụng Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đăng ký của bạn đã bị hủy vào ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đăng ký của bạn được lên lịch hết hạn vào ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dùng thử ", _interpolate(_named("days")), " ngày mà không phải trả thêm phí!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đã sử dụng"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn có ", _interpolate(_named("n")), " người dùng. Số lượng giấy phép không thể thấp hơn số lượng người dùng."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đăng ký sẽ tiếp tục vào ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["năm"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gói Zendesk QA hiện tại của bạn (", _interpolate(_named("plan")), ") không bao gồm các tính năng do AI hỗ trợ. Để phát huy toàn bộ năng lực của tính năng trí tuệ thoại, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Để hủy đăng ký của bạn, hãy liên hệ với chúng tôi ", _interpolate(_named("link")), " hoặc trò chuyện với chúng tôi."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gói quản lý chất lượng"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý chất lượng"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật đăng ký"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thẻ tín dụng"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các gói"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hóa đơn"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem thông tin hóa đơn"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng cách thời gian lập hóa đơn"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy đăng ký"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký của bạn sẽ bị hủy vào cuối thời hạn lập hóa đơn."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên hệ với chúng tôi"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên hệ với chúng tôi để chỉnh sửa"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gói hiện tại"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá tùy chỉnh"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hạ cấp"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng năm (Tiết kiệm 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng năm"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tháng"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanh toán hàng năm"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tháng"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa đăng ký"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu các gói"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dùng thử miễn phí"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lập hóa đơn thủ công"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể xem lại và xác nhận bản tóm tắt đơn hàng chính thức ở bước tiếp theo."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phổ biến nhất"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không có phương thức thanh toán đang hoạt động."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang trong thời gian dùng thử"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục đăng ký hiện tại của bạn để thay đổi gói"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạm dừng đăng ký"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tháng"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng quý"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng năm"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích hoạt lại đăng ký"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục đăng ký"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giấy phép"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắt đầu dùng thử ngay"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hủy"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gia hạn"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạm dừng"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái đăng ký"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dùng thử"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp đăng ký"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng dành một phút để cung cấp ý kiến phản hồi về trải nghiệm hỗ trợ khách hàng của bạn. Xin chân thành cảm ơn trước — ", "{", "{", "agentName", "}", "}", " trân trọng ý kiến phản hồi của bạn!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin chào ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Khảo sát CSAT của ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đánh giá thế nào về hỗ trợ mà bạn nhận được từ ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Chúng tôi thực sự trân trọng ý kiến phản hồi của bạn. Chúng tôi sẽ sử dụng ý kiến phản hồi của bạn để cải thiện ", _interpolate(_list(0)), " cho mọi người."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về khảo sát"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn loại khảo sát"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thay đổi sau."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đo lường và cải thiện mức độ hài lòng của khách hàng với khảo sát CSAT tự động bằng nhiều ngôn ngữ."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có khảo sát nào được tạo"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo khảo sát"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên khảo sát"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã được tạo"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hãy thử thay đổi từ khóa hoặc ", _interpolate(_named("contact_us")), " nếu bạn muốn ngôn ngữ đó được liệt kê trong danh sách này"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hãy liên hệ với chúng tôi"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy ngôn ngữ"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chọn một hoặc nhiều ngôn ngữ từ danh sách dưới đây. ", _interpolate(_named("contact_us")), " nếu bạn không thấy ngôn ngữ mình muốn sử dụng trong danh sách này."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm ngôn ngữ"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy liên hệ với chúng tôi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm ngôn ngữ"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đăng tải lần cuối ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lưu lần cuối ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["câu hỏi ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi mở đầu"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhãn thang điểm bên trái"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề email"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhãn thang điểm bên phải"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cảm ơn bạn"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu khảo sát thiếu bất kỳ bản dịch nào thì văn bản của ngôn ngữ gốc sẽ được hiển thị thay thế"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áp dụng bản dịch"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếu bản dịch"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hãy nhớ xem lại tất cả các bản dịch của bạn trước khi áp dụng. Thực hiện các thay đổi trong tập tin đã tải xuống, lưu tập tin và tải lên lại nếu cần. Nếu token ", _interpolate(_named("token_example")), " không xuất hiện dưới dạng thẻ đánh dấu thì token đó có định dạng không chính xác hoặc nằm trong trường không hỗ trợ token."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem lại bản dịch"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã dịch"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tóm tắt cuộc hội thoại và hiển thị các đề xuất cho bình luận."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ AI của Zendesk QA (bằng tiếng Anh)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể chỉnh sửa các loại sau khi tạo"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tồn tại loại này. Hãy chọn một tên khác."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu loại tùy chỉnh làm tùy chọn khả dụng trong menu thả xuống Loại khảo sát và nhóm tất cả các khảo sát đã tạo trên bảng điều khiển."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập (các) từ và nhấn Enter để lưu"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa các loại tùy chỉnh"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống tên loại"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định loại"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại tùy chỉnh chưa được lưu. Hãy nhấn Enter để lưu."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email của người gửi"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên người gửi"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đồng ý ở mức độ nào với nhận định: “", _interpolate(_named("agentName")), " đã giúp tôi giải quyết vấn đề của mình dễ dàng”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cho đến nay việc nhận trợ giúp từ ", _interpolate(_named("brandName")), " dễ dàng đến mức nào?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhìn chung, việc giải quyết vấn đề của bạn ngày hôm nay dễ dàng đến mức nào?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việc tương tác với đội của chúng tôi dễ dàng đến mức nào?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Privacy Policy (Quy Định về Sự Riêng Tư) (không bắt buộc)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Các Điều khoản Thỏa thuận về Sử dụng Dịch vụ (không bắt buộc)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn nhận xét thế nào về hỗ trợ mà bạn nhận được từ ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch vụ của chúng tôi cho yêu cầu này thế nào?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn hài lòng đến mức nào với người đại diện hỗ trợ?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn hài lòng đến mức nào với dịch vụ nhận được ngày hôm nay từ ", _interpolate(_named("agentName")), "?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi tùy chỉnh"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xác định câu hỏi ", _interpolate(_named("type")), " tùy chỉnh của riêng bạn..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập câu hỏi tùy chỉnh"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt tin nhắn email của bạn"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác chỉnh sửa tên của loại câu hỏi tùy chỉnh sẽ cập nhật tên đó trong tất cả các khảo sát sử dụng tên đó mà không làm ảnh hưởng đến dữ liệu được thu thập."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa các loại tùy chỉnh"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề email"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi mở đầu"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["câu hỏi ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo loại tùy chỉnh mới"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token cá nhân hóa"]), _normalize(["Các token cá nhân hóa"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kéo và thả token cá nhân hóa vào trường dưới đây."]), _normalize(["Kéo và thả các token cá nhân hóa vào các trường dưới đây."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cuộc hội thoại ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện tại, bạn chỉ có thể gửi khảo sát CSAT qua email. Chúng tôi đang nỗ lực mang đến cho bạn nhiều cơ hội hơn như khảo sát CSAT của bộ phận trợ giúp, khảo sát qua SMS, v.v."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Đồng bộ hóa hoặc gia hạn kết nối"]), _normalize(["Đồng bộ hóa hoặc các gia hạn kết nối"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đồng bộ hóa hoặc gia hạn kết nối ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kết nối không được đồng bộ hóa chính xác"]), _normalize(["Các kết nối không được đồng bộ hóa chính xác."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bỏ chọn hộp kiểm tương ứng ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực ngay ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm ít nhất một quy tắc gửi khảo sát để kích hoạt kết nối"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kết nối này hiện đã bị tắt. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kết nối này ẩn dữ liệu nhạy cảm của khách hàng. Để bật tính năng gửi khảo sát,\n", _interpolate(_named("link")), " trong cài đặt."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kết nối này yêu cầu các quyền bổ sung để gửi khảo sát.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực ngay"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA yêu cầu nhiều quyền hơn cho Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " và ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 giờ"]), _normalize([_interpolate(_named("n")), " giờ"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " phút"]), _normalize(["1 phút"]), _normalize([_interpolate(_named("n")), " phút"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["giờ"]), _normalize(["giờ"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " để định cấu hình quy tắc gửi khảo sát."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" ngày"]), _normalize(["ngày"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết khảo sát đã hết hạn"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết hết hạn sau"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngăn trả lời khảo sát nếu thời gian trôi qua đã quá lâu"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["và"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["khảo sát ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân cách các thẻ đánh dấu bằng dấu phẩy hoặc nhấn enter sau khi viết. Hãy đảm bảo mỗi thẻ đánh dấu được liệt kê chính xác như trong Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt các điều kiện để gửi khảo sát."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quy tắc gửi khảo sát"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quy tắc gửi phiếu theo dõi khảo sát"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã tồn tại khảo sát ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nGiải quyết xung đột bằng cách sửa đổi các quy tắc tại đây hoặc ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn đã thiết lập kết nối với các quy tắc phù hợp cho khảo sát ", _interpolate(_named("name")), ". Giải quyết xung đột bằng cách sửa đổi các quy tắc tại đây hoặc ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm quy tắc khác"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thương hiệu"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn điều kiện"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác nhận"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm giá trị"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thẻ đánh dấu"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["so khớp với các quy tắc tương tự"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một giá trị"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sẽ được gửi sau"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiếu theo dõi email sẽ được gửi sau"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đoạn mã được nhúng và phiếu theo dõi email"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép mã"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đính kèm khảo sát Zendesk QA vào email của bạn bằng cách thêm mã đoạn mã vào thiết lập Zendesk của bạn."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hướng dẫn cài đặt"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trước"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trước đoạn mã"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép đoạn mã vào clipboard"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép đoạn mã không thành công"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đoạn mã được nhúng"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát này có các thay đổi chưa lưu. Hãy lưu lại để đảm bảo mã đoạn mã phản ánh những cập nhật mới nhất."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm kết nối"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có kết nối nào được thêm vào"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập email, cuộc trò chuyện trực tiếp, cuộc gọi điện thoại, v.v. vào Zendesk QA bằng các kết nối của bạn"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về việc gửi khảo sát"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quy tắc gửi khảo sát"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quy tắc gửi phiếu theo dõi khảo sát"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm quy tắc thương hiệu"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm quy tắc"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiếu theo dõi Messenger + email"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối tài khoản Intercom của bạn với Khảo sát CSAT của Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi qua"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian và người nhận khảo sát"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa quy tắc"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã bật"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát trong ứng dụng cho phép khách hàng của bạn hoàn thành khảo sát mà không cần rời khỏi cuộc trò chuyện."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khởi chạy Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quy tắc gửi cho mỗi kết nối"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã tồn tại khảo sát ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nGiải quyết xung đột bằng cách tắt kết nối hoặc sửa đổi quy tắc tại đây hoặc trong ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt các điều kiện để gửi khảo sát"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được tạo bởi ", _interpolate(_named("name")), " vào ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác xóa ngôn ngữ này đồng nghĩa với việc ngôn ngữ này sẽ không còn hiển thị với bất kỳ người trả lời nào và tất cả các văn bản đã dịch sẽ bị xóa."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa ngôn ngữ"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ không thể hoàn tác thao tác này."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuy nhiên, bạn có thể tắt ngôn ngữ này bằng cách ẩn ngôn ngữ khỏi khảo sát. Thao tác này sẽ cho phép bạn giữ nguyên tất cả các bản dịch của mình mà không cần hiển thị cho người trả lời."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được chỉnh sửa lần cuối bởi ", _interpolate(_named("name")), " vào ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về ngôn ngữ khảo sát"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trước khi thêm các ngôn ngữ khác, hãy đảm bảo bạn đã hoàn tất việc thực hiện các thay đổi trong các bước Tạo kiểu và Nội dung. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm ngôn ngữ"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường đã dịch"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị cho người trả lời"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mặc định"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải xuống tập tin bản dịch"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa ngôn ngữ"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tải xuống tập tin bản dịch"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem lại bản dịch"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bản dịch hoàn tất. ", _interpolate(_named("review_link")), " hoặc ", _interpolate(_named("download_link")), " để thực hiện bất kỳ thay đổi nào."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tải xuống tập tin bản dịch"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy xem lại nội dung còn thiếu"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Một số nội dung vẫn chưa được dịch và sẽ được thay thế bằng tiếng Anh. ", _interpolate(_named("review_link")), " hoặc ", _interpolate(_named("download_link")), " để thêm bản dịch còn thiếu."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu khảo sát"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Khảo sát này có các thay đổi chưa lưu. ", _interpolate(_named("save")), " để tải xuống tập tin bản dịch có các thay đổi mới nhất."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải lên tập tin bản dịch"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn thang điểm nhận xét ưu tiên"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cung cấp cho khách hàng menu thả xuống để chọn lý do đánh giá"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản thang điểm bên trái (không bắt buộc)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lý do"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống lý do"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tồn tại lý do này"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị menu thả xuống để biết lý do cho điểm của bạn"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác xóa lý do và thêm lại lý do cùng tên hoặc thao tác đổi tên lý do luôn tạo ra mục nhập mới. Do đó, những thao tác này sẽ ảnh hưởng đến dữ liệu được hiển thị trên bảng điều khiển của bạn."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa lý do"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do đã xóa vẫn còn trong báo cáo trên bảng điều khiển nhưng sẽ không hiển thị với những người trả lời khảo sát mới. Điều này cũng áp dụng cho các bản dịch liên quan."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa lý do"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Xóa lý do \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Văn bản thang điểm bên phải (không bắt buộc)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trên thang điểm 5, 1 và 2 được coi là điểm tiêu cực; 3 là bình thường; 4-5 là tích cực"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm văn bản thang điểm"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thang điểm ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm đánh giá"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA đang nhập các cuộc hội thoại của bạn. Quá trình này có thể mất đến 24 giờ."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên thương hiệu sẽ được sử dụng làm token cá nhân hóa trong suốt khảo sát."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên thương hiệu"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nút và liên kết"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùy chỉnh giao diện khảo sát của bạn"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nền biểu tượng"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ các định dạng JPG, PNG và GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu tượng"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo kiểu"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải lên biểu tượng"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng xuất theo phong cách"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập thông báo mà mọi người sẽ thấy sau khi gửi ý kiến phản hồi"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo cảm ơn"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời cảm ơn"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã màu hex không chính xác"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ email không chính xác"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống trường này"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url không chính xác"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hiển thị:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã gửi"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã gửi trong 30 ngày qua"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã bị xóa"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã được sao chép"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trước email đã gửi"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã được đăng tải"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã được cập nhật"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát đã bị tạm dừng"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạm dừng"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạm dừng khảo sát"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trước"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập email để gửi bản xem trước khảo sát"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "email.com của bạn"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn ngôn ngữ để xem trước"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi email bản xem trước"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi khảo sát mẫu đến email của bạn. Nhận xét từ bản xem trước khảo sát sẽ không được lưu trữ."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xem trước khảo sát ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản xem trước khảo sát qua email trên web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng tải"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu thành bản nháp"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên thương hiệu"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thêm tên thương hiệu cụ thể ở bước Tạo kiểu"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên khách hàng"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID hỗ trợ"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đại diện hỗ trợ"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản nháp"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạm dừng"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Khảo sát CSAT của ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thực hiện các thay đổi chưa lưu"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật và tiếp tục khảo sát"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nếu bạn không nhìn thấy chính xác email này, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi không hài lòng với hỗ trợ mà tôi nhận được, bởi..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được bảo trợ bởi Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn lý do"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tóm tắt cuộc hội thoại"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví dụ: Khách hàng gặp phải sự cố khi cập nhật phương thức thanh toán. Nhân viên hỗ trợ đã chia sẻ một bài viết hữu ích. Khách hàng đã xử lý được sự cố."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do AI của Zendesk QA hỗ trợ"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các điều khoản thỏa thuận về sử dụng dịch vụ"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cảm ơn ý kiến phản hồi của bạn!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do chính cho điểm của bạn là gì?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy cho chúng tôi biết lý do bạn đưa ra đánh giá này..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nỗ lực cao"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nỗ lực thấp"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hãy nhấp vào đây để mở khảo sát"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyền riêng tư"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi tùy chỉnh của bạn sẽ hiển thị tại đây"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bạn đã cho kinh nghiệm ", _interpolate(_list(0)), " của bạn điểm"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi ý kiến phản hồi"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời cảm ơn"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy đăng ký"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rất không hài lòng"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rất hài lòng"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin lưu ý rằng trạng thái khảo sát hiện tại và quy tắc gửi sẽ không được đưa vào bản sao khảo sát"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sao chép khảo sát \"", _interpolate(_list(0)), "\""])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn sử dụng đoạn mã email được nhúng, hãy nhớ xóa đoạn mã đó khỏi Zendesk trước khi tạm dừng khảo sát"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các câu trả lời sẽ không được thu thập khi khảo sát bị tạm dừng."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạm dừng khảo sát"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhớ xóa đoạn mã khỏi Zendesk trước khi tạm dừng khảo sát."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa khảo sát"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn sử dụng đoạn mã email được nhúng, hãy nhớ xóa đoạn mã đó khỏi Zendesk trước khi xóa khảo sát."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn khảo sát. Người dùng đã nhận được khảo sát vẫn có thể điền vào khảo sát đó và tất cả dữ liệu liên quan đến khảo sát sẽ vẫn còn trong Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa khảo sát ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhớ xóa đoạn mã khỏi Zendesk trước khi xóa khảo sát."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Những người trả lời mới sẽ nhìn thấy các thay đổi đã áp dụng của bạn."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng tải khảo sát"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhớ sao chép phiên bản mới nhất của đoạn mã được nhúng sau khi đăng tải khảo sát."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn sử dụng đoạn mã email được nhúng, hãy nhớ sao chép đoạn mã đó vào Zendesk sau khi tiếp tục khảo sát."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục gửi khảo sát và thu thập phản hồi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật và tiếp tục khảo sát"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhớ thêm đoạn mã được nhúng vào Zendesk sau khi tiếp tục khảo sát."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật khảo sát"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhớ sao chép phiên bản mới nhất của đoạn mã được nhúng sau khi cập nhật khảo sát."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn xóa khảo sát này không? Bạn sẽ không thể hoàn tác thao tác này."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn xóa khảo sát?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian dùng thử của bạn đã kết thúc nhưng bạn có thể tiếp tục sử dụng Zendesk QA bằng cách nâng cấp lên tài khoản đầy đủ chức năng."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian dùng thử của bạn đã kết thúc nhưng bạn có thể tiếp tục sử dụng Zendesk QA bằng cách nâng cấp lên tài khoản đầy đủ chức năng. Trao đổi với quản trị viên nội bộ của bạn để thiết lập tài khoản này."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian dùng thử của bạn đã kết thúc"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cấp ngay"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian dùng thử miễn phí của bạn đã kết thúc."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để tiếp tục sử dụng phiên bản đầy đủ của Zendesk QA, hãy nhập thông tin thanh toán của bạn."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để tiếp tục sử dụng phiên bản đầy đủ của Zendesk QA, quản trị viên tài khoản của bạn cần nhập thông tin thanh toán."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý khoản thanh toán"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người dùng"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Được tạo vào ", _interpolate(_named("date")), " bởi ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["chỉ định ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có vẻ như bạn vẫn chưa hoàn thành. Bạn sẽ mất tất cả thông tin đã thêm cho đến thời điểm này nếu rời đi mà không lưu các thay đổi."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chỉ định này sẽ lặp lại mỗi ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chỉ định này sẽ lặp lại mỗi ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tất cả người được đánh giá được bao phủ trong một chu kỳ"]), _normalize(["Tất cả những người được đánh giá được bao phủ trong ", _interpolate(_named("nr")), " chu kỳ"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " cuộc hội thoại"]), _normalize([_interpolate(_named("n")), " cuộc hội thoại"]), _normalize([_interpolate(_named("n")), " cuộc hội thoại"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["trên ", _interpolate(_named("nr")), " nhân viên hỗ trợ"]), _normalize(["trên ", _interpolate(_named("nr")), " nhân viên hỗ trợ"]), _normalize(["trên ", _interpolate(_named("nr")), " nhân viên hỗ trợ"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["người tham gia"]), _normalize(["người tham gia"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " cuộc hội thoại mỗi chu kỳ"]), _normalize([_interpolate(_named("n")), " cuộc hội thoại mỗi chu kỳ"]), _normalize([_interpolate(_named("n")), " cuộc hội thoại mỗi chu kỳ"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " trên mỗi người được đánh giá liên tiếp"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["người được đánh giá"]), _normalize(["người được đánh giá"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["người đánh giá"]), _normalize(["người đánh giá"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng không gian làm việc"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng không gian làm việc ngoại trừ nhân viên hỗ trợ"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chu kỳ ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không định kỳ"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Chỉ định ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về chỉ định"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo chỉ định"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn chỉ định và xóa dữ liệu của chỉ định khỏi bảng điều khiển. Tất cả các đánh giá liên quan đến chỉ định đó sẽ vẫn còn trong Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa chỉ định ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa chỉ định"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép chỉ định"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có chỉ định nào được thêm"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tạo chỉ định"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên chỉ định"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chu kỳ đã được làm mới và danh sách nhiệm vụ mới đã được tạo. Các đánh giá đã được đưa ra vẫn được tính vào mục tiêu."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngay lập tức"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng tải"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Khi bắt đầu chu kỳ tiếp theo vào ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn đăng tải các thay đổi vào lúc nào?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các thay đổi được thực hiện cho chỉ định này sẽ được đăng tải cùng với chỉ định này"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn đặt chỉ định thành đang hoạt động?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định kỳ vào ngày bắt đầu chỉ định"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Sáu đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Sáu thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Sáu thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Sáu thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Sáu cuối cùng của mỗi tháng"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Hai đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Hai thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Hai thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Hai thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Hai cuối cùng của mỗi tháng"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Bảy đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Bảy thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Bảy thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Bảy thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Bảy cuối cùng của mỗi tháng"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ Nhật đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ Nhật thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ Nhật thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ Nhật thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ Nhật cuối cùng của mỗi tháng"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Năm đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Năm thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Năm thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Năm thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Năm cuối cùng của mỗi tháng"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Ba đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Ba thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Ba thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Ba thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Ba cuối cùng của mỗi tháng"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Tư đầu tiên của mỗi tháng"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Tư thứ hai của mỗi tháng"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Tư thứ ba của mỗi tháng"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Tư thứ tư của mỗi tháng"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ Tư cuối cùng của mỗi tháng"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tần suất tùy chỉnh"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đầu tiên của mỗi tháng"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đầu tiên"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ hai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ ba"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ tư"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuối cùng"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lặp lại"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày trong tuần"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết thúc"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắt đầu"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có kết quả"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Múi giờ"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt chu kỳ"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên chỉ định"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin cơ bản"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi bắt đầu mỗi chu kỳ chỉ định, danh sách các cuộc hội thoại được xác định trước sẽ được tạo để đánh giá."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chung"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt nâng cao"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm nạp tự động các cuộc hội thoại mới khi mục tiêu đánh giá không đạt được"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể tìm nạp tự động với mục tiêu đánh giá là phần trăm (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phải là số nguyên, không phải số thập phân"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép thay thế các cuộc hội thoại (Vai trò người đánh giá trở lên)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay thế cuộc hội thoại"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép người đánh giá được chỉ định cuộc hội thoại của riêng họ để xem xét"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự đánh giá"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng đánh giá tối thiểu phải có ít nhất 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định đánh giá tối thiểu cho mỗi người được đánh giá"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuộc hội thoại"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phần trăm (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh giá tổng cộng"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mỗi người đánh giá phải thực hiện một số lượng đánh giá đã đặt"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá phải đánh giá chung một số lượng cuộc hội thoại đã đặt"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng đánh giá tối thiểu phải có ít nhất 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu đánh giá phải nằm trong khoảng từ 1 – 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu đánh giá"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phải đánh giá số lượng cuộc hội thoại tối thiểu trong tổng số cuộc hội thoại của mỗi người được đánh giá"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá có thể chọn các cuộc hội thoại từ nhóm chung"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá được chỉ định các cuộc hội thoại từ nhóm chung"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phương pháp chỉ định"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các bot"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả những người tham gia không gian làm việc"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng không gian làm việc"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm người được đánh giá"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai sẽ được đánh giá?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người được đánh giá"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người để đánh giá"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ cho phép loại trừ khi tất cả người dùng không gian làm việc hoặc nhóm được chọn"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá và người được đánh giá"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hiện tại: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc mặc định"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nên chọn loại người dùng nào làm người được đánh giá?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ hoạt động tích cực nhất"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại trừ người được đánh giá (không bắt buộc)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người dùng để loại trừ"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại trừ người đánh giá (không bắt buộc)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người dùng để loại trừ"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng không gian làm việc ngoại trừ nhân viên hỗ trợ"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm người đánh giá"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai sẽ thực hiện đánh giá?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người đánh giá"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không cho phép tự đánh giá trong không gian làm việc này"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép tự đánh giá"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không cho phép tự đánh giá trong không gian làm việc này."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cuộc hội thoại được so khớp hôm nay"]), _normalize(["Cuộc hội thoại được so khớp hôm nay"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị như thể chỉ định bắt đầu từ hôm nay."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số liệu trong tương lai có thể thay đổi."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có người được đánh giá nào được chọn"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có người đánh giá nào được chọn"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người được đánh giá"]), _normalize(["Người được đánh giá"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người đánh giá"]), _normalize(["Người đánh giá"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trước"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm nạp tự động: được phép nếu không đủ cuộc hội thoại"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các thứ Sáu từ ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các thứ Hai từ ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các thứ Bảy từ ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các Chủ nhật từ ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các thứ Năm từ ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các thứ Ba từ ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " vào các thứ Tư từ ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hàng ngày từ ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bắt đầu vào ", _interpolate(_named("startDateTime")), " và kết thúc vào ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " điều kiện ngày"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " cuộc hội thoại và điều kiện bộ phận trợ giúp"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Không có cuộc hội thoại nào và có ", _interpolate(_named("count")), " điều kiện bộ phận trợ giúp"]), _normalize(["Không có cuộc hội thoại nào và có ", _interpolate(_named("count")), " điều kiện bộ phận trợ giúp"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Có ", _interpolate(_named("count")), " cuộc hội thoại và không có điều kiện bộ phận trợ giúp"]), _normalize(["Có ", _interpolate(_named("count")), " cuộc hội thoại và không có điều kiện bộ phận trợ giúp"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Phương pháp: người đánh giá lựa chọn cuộc hội thoại"]), _normalize(["Phương pháp: người đánh giá được chỉ định cuộc hội thoại"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sửa các lỗi được làm nổi bật trong phần tóm tắt để tiếp tục"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu: người đánh giá phải đánh giá tất cả các cuộc hội thoại"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mục tiêu: người đánh giá phải đánh giá một cuộc hội thoại"]), _normalize(["Mục tiêu: người đánh giá phải đánh giá ", _interpolate(_named("count")), " cuộc hội thoại"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mục tiêu: người đánh giá phải đánh giá ", _interpolate(_named("count")), "% cuộc hội thoại"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu: không có mục tiêu đánh giá nào được đặt"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu: phải đánh giá tất cả các cuộc hội thoại của mỗi người được đánh giá"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mục tiêu: phải đánh giá ", _interpolate(_named("count")), " trong tổng số cuộc hội thoại của mỗi người được đánh giá"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mục tiêu: phải đánh giá ", _interpolate(_named("count")), "% trong tổng số cuộc hội thoại của mỗi người được đánh giá"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mục tiêu: mỗi người đánh giá phải thực hiện một đánh giá"]), _normalize(["Mục tiêu: mỗi người đánh giá phải thực hiện ", _interpolate(_named("count")), " đánh giá"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thay thế: người đánh giá không thể thay thế cuộc hội thoại"]), _normalize(["Thay thế: người đánh giá có thể thay thế cuộc hội thoại"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tự đánh giá: người đánh giá có thể được chỉ định cuộc hội thoại riêng"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có điều kiện ngày nào được đặt"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có tên chỉ định nào được đặt"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tóm tắt"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " từ ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai tuần một lần"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng ngày"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tháng"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không bao giờ"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàng tuần"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Độ dài chu kỳ"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt thành Đang hoạt động"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu thành Bản nháp"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu chỉ định thành Bản nháp"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định đã được đặt thành Không hoạt động"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt thành Không hoạt động"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo chỉ định"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tạo chỉ định"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là đang hoạt động"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là không hoạt động"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Các bản cập nhật đang chờ xử lý cho chỉ định này sẽ có hiệu lực trong chu kỳ tiếp theo, bắt đầu vào ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng tải các thay đổi"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản nháp"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hoạt động"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang chờ xử lý"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật chỉ định"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật chỉ định"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên hỗ trợ hoặc bot có kết thúc cuộc hội thoại hay không."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên hỗ trợ có lịch sự kết thúc cuộc hội thoại hay không"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem cuộc hội thoại đã kết thúc hay chưa"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích lỗi ngữ pháp, chính tả và phong cách trong tương tác của nhân viên hỗ trợ và bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích lỗi ngữ pháp, chính tả và văn phong của nhân viên hỗ trợ"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích lỗi ngữ pháp, chính tả và văn phong của nhân viên hỗ trợ"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính tả và ngữ pháp"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích cuộc hội thoại cho lời chào của nhân viên hỗ trợ và bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích toàn bộ cuộc hội thoại để tìm các cụm từ chào hỏi điển hình"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích toàn bộ cuộc hội thoại để tìm các lời chào điển hình"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên có hiểu và ghi nhận cảm xúc của khách hàng không"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định các trường hợp nhân viên ghi nhận thái độ của khách hàng hoặc sử dụng các từ thể hiện sự quan tâm như \"biết\" và \"hiểu\""])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự đồng cảm"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên có hiểu thắc mắc hoặc mối quan ngại của khách hàng không"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích xem nhân viên có hiểu thắc mắc hoặc mối quan ngại của khách hàng không"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự thấu hiểu"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích mức độ dễ hiểu của văn bản, xem xét độ phức tạp của từ và độ dài câu"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích mức độ dễ hiểu của văn bản, xem xét độ phức tạp của từ và độ dài câu"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin nhắn này chứa 1 câu quá dài gây khó khăn cho việc đọc"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tin nhắn này chứa ", _interpolate(_list(0)), " câu quá dài gây khó khăn cho việc đọc"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính dễ đọc"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích toàn bộ cuộc hội thoại để đề nghị giải pháp"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích toàn bộ cuộc hội thoại để đề nghị giải pháp"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải pháp được cung cấp"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích giọng điệu của nhân viên hỗ trợ trong suốt cuộc hội thoại"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích giọng điệu của nhân viên hỗ trợ trong suốt cuộc hội thoại"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện bất kỳ lỗi nào từ tin nhắn của nhân viên hỗ trợ"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể xác định lỗi ngữ pháp"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phát hiện thấy cụm lời chào nào. Hãy thêm những lời chào mới nếu cần thông qua quản trị viên của bạn trong Cài đặt."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã lịch sự kết thúc cuộc hội thoại"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không phát hiện thấy cụm lời kết nào. Hãy thêm những lời kết mới nếu cần thông qua quản trị viên của bạn trong Cài đặt"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã ghi nhận thái độ của khách hàng hoặc sử dụng các từ thể hiện sự quan tâm"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện được nhân viên hỗ trợ có ghi nhận thái độ của khách hàng hay sử dụng các từ thể hiện sự quan tâm hay không"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã sử dụng các câu dài và từ phức tạp có thể gây khó hiểu cho một số người"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã sử dụng các từ phức tạp có thể gây khó hiểu cho một số người"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã sử dụng các câu dài có thể gây khó hiểu cho một số người"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã sử dụng ngôn ngữ dễ hiểu"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không đủ dữ liệu để nhận xét danh mục này"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ gặp khó khăn trong việc nắm bắt câu hỏi/yêu cầu của khách hàng"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ hiểu rõ câu hỏi/yêu cầu của khách hàng"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện được nhân viên có hiểu câu hỏi/yêu cầu của khách hàng hay không"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu của nhân viên trong cuộc hội thoại"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu của nhân viên trong cuộc hội thoại"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu của nhân viên trong cuộc hội thoại"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu của nhân viên trong cuộc hội thoại"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giọng điệu của nhân viên trong cuộc hội thoại"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể xác định giọng điệu của nhân viên hỗ trợ"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại chưa kết thúc"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại đã kết thúc"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện được cuộc hội thoại đã kết thúc hay chưa"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã không thể hiện sự đồng cảm với khách hàng và vấn đề của khách hàng"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã thể hiện sự đồng cảm với khách hàng và vấn đề của khách hàng"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện được nhân viên hỗ trợ có thể hiện sự đồng cảm với khách hàng và vấn đề của khách hàng hay không "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng đã không nhận được lời chào trong cuộc hội thoại"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khách hàng đã nhận được lời chào trong cuộc hội thoại"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện lời chào điển hình"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã không cung cấp giải pháp cho khách hàng"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ đã cung cấp giải pháp cho khách hàng"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể phát hiện được nhân viên có cung cấp giải pháp cho khách hàng hay không"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ phức tạp"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu dài"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngữ pháp"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi chính tả"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu cực"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin lỗi"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điềm tĩnh"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui vẻ"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tò mò"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyên nghiệp"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phong cách"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 lời kết"]), _normalize([_interpolate(_named("n")), " lời kết"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động chỉ kiểm tra lời kết được phê duyệt. Nếu danh mục này được đặt là quan trọng thì việc không sử dụng các lời kết được phê duyệt sẽ khiến đánh giá không đạt."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết được phê duyệt"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời kết được phê duyệt"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 trường hợp miễn"]), _normalize([_interpolate(_named("n")), " trường hợp miễn"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Những từ và cụm từ mà QA tự động bỏ qua do lỗi chính tả hoặc ngữ pháp."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường hợp miễn chính tả và ngữ pháp"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trường hợp miễn QA tự động"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 lời chào"]), _normalize([_interpolate(_named("n")), " lời chào"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động chỉ kiểm tra lời chào được phê duyệt. Nếu danh mục này được đặt là quan trọng thì việc không sử dụng các lời chào được phê duyệt sẽ khiến đánh giá không đạt."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào được phê duyệt"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời chào được phê duyệt"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục QA tự động"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn danh mục QA tự động"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ được chỉ định tự động:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản ⭢ Cài đặt"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Một số danh mục tận dụng MS Azure, cung cấp thêm nhiều chức năng và ngôn ngữ được hỗ trợ. Hãy xem lại ", _interpolate(_named("link")), " hoặc liên hệ với quản trị viên của bạn để biết thêm thông tin."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Truy cập danh sách danh mục và ngôn ngữ được hỗ trợ mở rộng bằng cách tận dụng MS Azure. Hãy xem lại ", _interpolate(_named("link")), " của bạn hoặc liên hệ với quản trị viên để thực hiện."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sử dụng QA tự động"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Các danh mục QA tự động được chỉ định điểm tự động bằng thang điểm nhận xét cố định. Bạn có thể điều chỉnh nhận xét theo cách thủ công sau. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn danh mục QA tự động"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Điểm được chỉ định tự động tùy thuộc vào mức độ nghiêm trọng của lỗi và số lượng lỗi. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ những danh mục nhận xét phù hợp mới được hiển thị cho người đánh giá. Danh mục nhận xét được hiển thị theo các điều kiện bạn đặt khi tạo danh mục."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trò chuyện"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hướng dẫn"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điện thoại"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chọn loại danh mục nhận xét cho không gian làm việc \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá phải chọn thủ công thẻ điểm chính xác để xem các danh mục nhận xét phù hợp cho từng cuộc hội thoại."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn thay đổi loại danh mục nhận xét không?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi danh mục"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn thay đổi loại danh mục nhận xét?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục nhận xét có điều kiện"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục nhận xét tĩnh"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại danh mục nhận xét"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt danh mục nhận xét"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ không thể hoàn tác thao tác này."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa tất cả các nhận xét"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngoài ra, bạn có thể giữ số liệu thống kê của mình mà không phải xóa bất kỳ dữ liệu nào bằng cách lưu trữ danh mục."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tôi hiểu rằng thao tác này sẽ ", _interpolate(_named("deleteAllRatings")), " được đưa ra cho danh mục này."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ví dụ: Giọng điệu hoàn hảo"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ hiển thị danh mục nếu"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Luôn luôn\" sẽ bị bỏ qua. Không thể kết hợp điều kiện này với các điều kiện khác."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luôn luôn"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn điều kiện"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm mức độ hài lòng (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại nguồn"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh cuộc hội thoại"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục cần có tên"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm danh mục này vào thẻ điểm"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo danh mục"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo danh mục"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu trữ"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm danh mục nhận xét"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục đã lưu trữ sẽ không hiển thị trên thẻ điểm nhưng sẽ cho phép bạn giữ lại số liệu thống kê của mình mà không phải xóa bất kỳ dữ liệu nào."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu trữ danh mục"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lưu trữ danh mục ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu trữ danh mục nhận xét"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa danh mục"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập \"xóa danh mục\" để xóa danh mục này"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa danh mục"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Xóa danh mục ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa danh mục nhận xét"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép danh mục nhận xét"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thêm nhóm danh mục nhận xét"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa nhóm danh mục nhận xét"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thay đổi thứ tự nhóm danh mục nhận xét"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật nhóm danh mục nhận xét"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hủy lưu trữ danh mục nhận xét"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật danh mục nhận xét"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi nhân viên hỗ trợ không đạt một danh mục quan trọng thì tất cả các danh mục trong đánh giá đó sẽ tự động không đạt."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục nhận xét quan trọng"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có danh mục nào trong nhóm này"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo danh mục nhận xét mới"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa danh mục"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa tất cả các danh mục và xem lại dữ liệu trong nhóm này"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác xóa nhóm danh mục sẽ khiến tất cả các danh mục được liên kết với nhóm đó bị tách nhóm."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa nhóm danh mục ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác chọn xóa các danh mục trong nhóm sẽ dẫn đến mất tất cả dữ liệu đánh giá được liên kết với những danh mục này."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên nhóm"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá có thể chọn nhiều lý do làm nguyên nhân gốc rễ"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá có thể chọn nhiều lý do làm nguyên nhân gốc rễ"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiều lý do"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ví dụ: Giọng điệu"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nhóm danh mục mới"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm mới"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm \"Khác\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nguyên nhân gốc rễ"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tồn tại nguyên nhân gốc rễ này"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nguyên nhân gốc rễ"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nguyên nhân gốc rễ để giải thích nhận xét"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm đánh giá"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu trữ"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thẻ điểm vào danh mục nhận xét này"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy lưu trữ"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật danh mục"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả nhận xét"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận xét tiêu cực"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhận xét tiêu cực và bình thường"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có, cập nhật"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trọng số"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các danh mục quan trọng hơn đối với bạn có thể có trọng số lớn hơn trong việc tính điểm phiếu."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Trọng số (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về hiệu chuẩn"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về hiệu chuẩn"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việc tổ chức các phiên hiệu chuẩn định kỳ giúp người đánh giá giữ cho các kỹ thuật nhận xét của mình được nhất quán và đảm bảo các nhân viên hỗ trợ nhận được cùng một mức độ ý kiến phản hồi nhất quán và không thiên vị."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem các gói"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " là một quy trình giúp đội của bạn luôn thống nhất trong cách xử lý và đánh giá tất cả các tương tác với khách hàng."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hiệu chuẩn khả dụng cho các gói ", _interpolate(_list(0)), " và ", _interpolate(_list(1)), " của chúng tôi."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các đánh giá được đưa ra trước đó sẽ không được hiển thị khi cuộc hội thoại được thêm vào phiên."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hiển thị"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các đánh giá được đưa ra trước đó được coi là đánh giá hiệu chuẩn. Chỉ một đánh giá cuộc hội thoại trên mỗi người đánh giá sẽ được thêm vào phiên này. Đánh giá theo tin nhắn cụ thể sẽ không được hiển thị."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khả năng hiển thị của các đánh giá được đưa ra trước đó"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hiệu chuẩn là một quy trình giúp đội của bạn luôn thống nhất trong cách xử lý và đánh giá tất cả các tương tác với khách hàng. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng có quyền truy cập không giới hạn vào tất cả các đánh giá được đưa ra trong phiên hiệu chuẩn."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luôn xem tất cả các đánh giá"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trưởng nhóm"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi gửi đánh giá, người dùng sẽ thấy các đánh giá do những người đánh giá khác đưa ra trong phiên hiệu chuẩn."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tất cả các đánh giá hiệu chuẩn sau khi gửi đánh giá"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng có quyền truy cập không giới hạn vào tất cả các đánh giá được đưa ra trong phiên hiệu chuẩn."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luôn xem tất cả các đánh giá"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người quản lý"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi gửi đánh giá, người dùng sẽ thấy các đánh giá do những người đánh giá khác đưa ra trong phiên hiệu chuẩn."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tất cả các đánh giá hiệu chuẩn sau khi gửi đánh giá"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi gửi đánh giá, người dùng sẽ thấy các đánh giá do những người đánh giá khác đưa ra trong phiên hiệu chuẩn."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem tất cả các đánh giá hiệu chuẩn sau khi gửi đánh giá"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng sẽ chỉ nhìn thấy những đánh giá của riêng mình được đưa ra trong phiên hiệu chuẩn."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ xem đánh giá riêng"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật cài đặt hiệu chuẩn"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khả năng hiển thị của đánh giá hiệu chuẩn"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt ngưỡng cho thông số trên bảng điều khiển bằng cách lấp khoảng trống này theo hệ thống số liệu nội bộ của đội của bạn. Xem lại ngưỡng trên Bảng điều khiển."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu chuẩn"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chung"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành viên"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngưỡng"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật ngưỡng"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy chờ một lát..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hãy đảm bảo bạn có quyền của Quản trị viên và ", _interpolate(_named("retry")), " hoặc ", _interpolate(_named("contact")), " để được trợ giúp thêm."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liên hệ với chúng tôi"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có vẻ như chúng tôi không thể truy cập vào API của bộ phận trợ giúp của bạn."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hãy thử lại"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi đang kết nối bộ phận trợ giúp của bạn. Bạn sẽ được chuyển hướng trong giây lát."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đã ủy quyền ", _interpolate(_list(0))])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hãy quay lại ", _interpolate(_list(0)), " của bạn để thêm kết nối bộ phận trợ giúp mới."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có vẻ như bạn đã bỏ lỡ một hoặc hai bước."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tồn tại một tổ chức có miền email này"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trang kết nối"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang trong tiến trình..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy chờ một lát trong khi chúng tôi kiểm tra quyền của bạn."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang trong tiến trình..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tích hợp"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tạo không gian làm việc"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Để tiếp tục sử dụng Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gần như đã sẵn sàng để sử dụng"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy không gian làm việc nào"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi tài khoản"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để tiếp tục sử dụng Zendesk QA, người quản lý của bạn cần chỉ định bạn vào một không gian làm việc."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thao tác xóa tài khoản sẽ xóa vĩnh viễn tất cả dữ liệu chúng tôi có về bạn - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tất cả đánh giá được đưa ra, nội dung cuộc hội thoại, không gian làm việc, v.v."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngắt kết nối"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác ngắt kết nối tích hợp sẽ tắt tất cả thông báo Zendesk QA thông qua Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngắt kết nối Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tích hợp Slack đã bị ngắt kết nối khỏi Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác định cài đặt thông báo mặc định cho tất cả người dùng tài khoản mới."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi đè cài đặt cho tất cả người dùng"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn chuẩn bị ghi đè cài đặt thông báo cho tất cả người dùng tài khoản"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng sẽ không thể xác định cài đặt thông báo của riêng mình."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cập nhật cài đặt thông báo"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo khi một danh mục quan trọng không thành công"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo khi CSAT nhận được phản hồi"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ kích hoạt"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo không gian làm việc và dữ liệu demo"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo lại dữ liệu demo và giữ không gian làm việc demo."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa không gian làm việc và dữ liệu demo"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Làm mới dữ liệu demo"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Làm mới dữ liệu"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quản lý chế độ demo của tài khoản \"", _interpolate(_named("accountName")), "\""])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chế độ demo"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ở lại trang"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rời khỏi trang"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn rời khỏi trang này và mất tất cả các thay đổi?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã hoàn thành chỉ định"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo chỉ định"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hoàn thành chỉ định"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy chỉ định nào"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện không có nhiệm vụ nào để bạn đánh giá."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều chỉnh chỉ định hoặc đợi chu kỳ tiếp theo bắt đầu."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử điều chỉnh các bộ lọc"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa chỉ định"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy theo dõi trang này để cập nhật"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nhiệm vụ nào cho chu kỳ này"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào để hiển thị"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu nhiệm vụ là hoàn tất"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã đánh giá người dùng này."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bản tự đánh giá nào trong chỉ định này"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm bằng cách nhấp vào biểu tượng hiệu chuẩn trong tiêu đề của danh sách xem cuộc hội thoại."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có cuộc hội thoại nào được thêm"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày đến hạn"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo chỉ định"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo phiên hiệu chuẩn"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định của bạn"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các chỉ định khác"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có chỉ định nào đang hoạt động"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có chỉ định"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người được đánh giá"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người được đánh giá ngoại trừ tôi"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy người đánh giá nào"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm người được đánh giá"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản nháp"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả trạng thái"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cần thực hiện"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người đánh giá"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người đánh giá ngoại trừ tôi"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chỉ định cho tôi"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy người đánh giá nào"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm người đánh giá"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ngày"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ngày"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ngày"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ngày"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ngày"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ngày"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giờ"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giờ"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giờ"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giờ"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giờ"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giờ"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " phút"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " phút"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " phút"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " phút"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " phút"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " phút"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không áp dụng"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng không gian làm việc"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá hiện tại"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu mới cho chu kỳ này"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá mới"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người đánh giá"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại các cuộc hội thoại"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại cuộc hội thoại"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại dự định di chuyển"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chu kỳ hiện tại"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa chỉ định"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy người đánh giá nào"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm người đánh giá"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại cuộc hội thoại cho tôi"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại cuộc hội thoại cho người dùng khác"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ngày"]), _normalize([_interpolate(_named("n")), " ngày"]), _normalize([_interpolate(_named("n")), " ngày"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " giờ"]), _normalize([_interpolate(_named("n")), " giờ"]), _normalize([_interpolate(_named("n")), " giờ"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn chu kỳ đã hoàn thành"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn phiên đã hoàn thành"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu của bạn"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lý do để xóa cuộc hội thoại này"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thích lý do tại sao bạn muốn xóa cuộc hội thoại này"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa cuộc hội thoại"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có muốn giữ các thay đổi không?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm lý do để thay thế cuộc hội thoại này"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại bỏ các thay đổi"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giữ"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giữ các thay đổi"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy cuộc hội thoại thay thế"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thích lý do tại sao bạn muốn thay thế cuộc hội thoại này"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn vẫn muốn xóa cuộc hội thoại này khỏi chỉ định? Thao tác này sẽ làm giảm đi 1 mục tiêu."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay thế"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay thế cuộc hội thoại"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bản nháp"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa bản nháp"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại cho tôi"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại cho người dùng khác"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ định lại và đánh giá"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa cuộc hội thoại"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để lại đánh giá"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn bớt"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện thêm"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mục tiêu: Đã đánh giá ", _interpolate(_named("count")), "/", _interpolate(_named("goal"))])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã hoàn tất"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị chu kỳ đã hoàn thành"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiển thị phiên đã hoàn thành"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian còn lại"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiệm vụ"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thay thế cuộc hội thoại"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi danh sách nhiệm vụ"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " sau"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " giây"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " giây"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ngày"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ngày ", _interpolate(_named("hours")), " giờ"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ngày ", _interpolate(_named("hours")), " giờ ", _interpolate(_named("minutes")), " phút"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ngày"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ngày"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ngày ", _interpolate(_named("minutes")), " phút"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " giờ"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " giờ ", _interpolate(_named("minutes")), " phút"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " giờ ", _interpolate(_named("minutes")), " phút ", _interpolate(_named("seconds")), " giây"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " giờ ", _interpolate(_named("seconds")), " giây"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " phút"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " phút ", _interpolate(_named("seconds")), " giây"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " tháng"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " giây"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " năm"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vừa xong"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn không có quyền. Hãy liên hệ với quản trị viên của bạn để được trợ giúp."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang bạn đang tìm kiếm không tồn tại."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xảy ra lỗi ngoài dự kiến. Hãy liên hệ với bộ phận hỗ trợ để được trợ giúp."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truy cập bị từ chối. Không được phép thực hiện thao tác đó."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang bạn đang tìm kiếm không tồn tại hoặc bạn không có quyền truy cập trang."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đang thực hiện thao tác quá nhanh và chúng tôi không thể bắt kịp."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu các thay đổi"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thực hiện các thay đổi chưa lưu"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["và"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã áp dụng ", _interpolate(_named("n"))]), _normalize(["Đã áp dụng ", _interpolate(_named("n"))]), _normalize(["Đã áp dụng ", _interpolate(_named("n"))])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã kết nối"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày tạo"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tạo"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan trọng"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn tất"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bằng"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cựu thành viên"]), _normalize(["Cựu thành viên"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tại đây"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã chọn ", _interpolate(_named("n"))]), _normalize(["Đã chọn ", _interpolate(_named("n"))]), _normalize(["Đã chọn ", _interpolate(_named("n"))])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật lần cuối"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... và thêm 1"]), _normalize(["... và thêm ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["thêm 1"]), _normalize(["thêm ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mới"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không bằng"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoặc"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " câu trả lời"]), _normalize([_interpolate(_named("n")), " câu trả lời"]), _normalize([_interpolate(_named("n")), " câu trả lời"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôm nay"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người dùng"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thứ Sáu"]), _normalize(["Thứ Sáu"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thứ Hai"]), _normalize(["Thứ Hai"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thứ Bảy"]), _normalize(["Thứ Bảy"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Chủ nhật"]), _normalize(["Chủ Nhật"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thứ Năm"]), _normalize(["Thứ Năm"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thứ Ba"]), _normalize(["Thứ Ba"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thứ Tư"]), _normalize(["Thứ Tư"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôm qua"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Quản trị viên"]), _normalize(["Quản trị viên"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người quản lý tài khoản"]), _normalize(["Người quản lý tài khoản"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người dùng"]), _normalize(["Người dùng"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA tự động"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu thêm"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên của bạn đã hết hạn."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống trường này."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy mục nào."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có người phù hợp."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Chủ sở hữu"]), _normalize(["Chủ sở hữu"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu thêm"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu thêm"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu thêm."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trước khi bắt đầu, hãy đọc và đồng ý với các điều khoản của chúng tôi và bạn sẽ sẵn sàng."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã gửi"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc không?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã hoàn tất tổng thể"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày cập nhật"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nhân viên hỗ trợ"]), _normalize(["Nhân viên hỗ trợ"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Trưởng nhóm"]), _normalize(["Trưởng nhóm"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người quản lý"]), _normalize(["Người quản lý"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người đánh giá"]), _normalize(["Người đánh giá"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng ẩn danh"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu trữ"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên phụ trách"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đóng"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sớm"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhấp để sao chép"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép liên kết"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khu vực nguy hiểm"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ chối"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả (không bắt buộc)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu xác nhận"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn loại bỏ các thay đổi chưa lưu?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bật"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tần suất"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục tiêu"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hiểu"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ phận trợ giúp"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không bao giờ"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp theo"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khác"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ đạt"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt cá nhân"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hồ sơ"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn bớt"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi lại"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khôi phục"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người được đánh giá"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được/đã xem"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá được hoàn tất bởi thành viên không gian làm việc"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu các thay đổi"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm nhị phân"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm 3 điểm"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm 4 điểm"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thang điểm 5 điểm"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn tần suất"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn bớt"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện thêm"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ qua"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu điểm"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bước"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản hồi khảo sát"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khoảng thời gian"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã thực hiện các thay đổi chưa lưu"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trọng số"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bạn đã được ", _interpolate(_list(0)), " mời tham gia Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Người dùng được tự động kết nối từ Trung Tâm Quản Trị. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý người dùng"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về quản lý người dùng"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Một số người dùng được cấp phép và đồng bộ hóa tự động từ nhà cung cấp nhận dạng của bạn. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Các quyền cấp tài khoản của \"", _interpolate(_list(0)), "\" đã thay đổi"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về quyền của người dùng"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hỏi lại"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tất cả những thay đổi được thực hiện trên trang người dùng đều áp dụng cho không gian làm việc. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều này có nghĩa là quyền truy cập của người dùng vào một số phần nhất định của Zendesk QA có thể sẽ thay đổi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi quyền cấp tài khoản"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã xóa ", _interpolate(_named("n")), " người dùng"]), _normalize(["Đã xóa ", _interpolate(_named("n")), " người dùng"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Khi bạn xóa người dùng, người dùng sẽ mất quyền truy cập vào tài khoản của bạn. Đánh giá, bình luận và nhận xét của người dùng sẽ vẫn còn trong Zendesk QA. ", _interpolate(_named("linebreak")), "Thao tác xóa người dùng sẽ không ảnh hưởng đến số lượng giấy phép trong đăng ký của bạn."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Người dùng này sẽ bị xóa khỏi tất cả không gian làm việc mà bạn quản lý. ", _interpolate(_named("linebreak")), " Đánh giá, bình luận và nhận xét của người dùng sẽ vẫn được giữ nguyên."]), _normalize(["Người dùng sẽ bị xóa khỏi tất cả không gian làm việc mà bạn quản lý. ", _interpolate(_named("linebreak")), " Tất cả các đánh giá, bình luận và nhận xét của người dùng sẽ vẫn được giữ nguyên."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Xóa ", _interpolate(_named("name"))]), _normalize(["Xóa người dùng ", _interpolate(_named("name"))])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể xem mọi nội dung và quản lý tất cả cài đặt cũng như hóa đơn"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể xem mọi nội dung và quản lý cài đặt cá nhân cũng như không gian làm việc ngoại trừ hóa đơn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các quyền cấp tài khoản"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các quyền theo không gian làm việc cụ thể cho phép người dùng xem (và) chỉnh sửa dữ liệu trong không gian làm việc mà người dùng đang tham gia."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot của bạn hiển thị tại đây khi các cuộc hội thoại bot tham gia được đồng bộ hóa"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có bot nào được đồng bộ hóa"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác đánh dấu bot là người dùng sẽ cấp cho bot quyền đăng nhập. Dữ liệu bot trước đó sẽ vẫn khả dụng trên bảng điều khiển."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy bot nào như vậy"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử tìm kiếm lại bằng từ khóa hoặc cách viết khác."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm các bot"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo tên bot"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là người dùng"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ quản trị viên và người quản lý tài khoản mới có thể đánh dấu bot là người dùng"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên bot"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc trò chuyện cuối cùng"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được đánh dấu thủ công"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có thể đánh giá"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bị xóa khỏi phân tích tự động và bất kỳ chỉ định nào. Dữ liệu mới sẽ không xuất hiện trong bảng điều khiển, đảm bảo báo cáo chính xác và quản lý bot dễ dàng hơn."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot tạo sinh"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không xác định"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot quy trình làm việc"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào (các) nhóm"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nhóm"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nhóm"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa khỏi (các) nhóm"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn nhóm"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyền cấp tài khoản"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào (các) không gian làm việc"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Họ và tên"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động lần cuối"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò cấp tài khoản"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mỗi người dùng được chỉ định một quyền ở cấp Tài khoản và/hoặc Không gian làm việc. Quyền cấp Quản trị viên hiện đang ở cấp tài khoản, trong khi các quyền khác đang ở cấp Không gian làm việc."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa khỏi (các) không gian làm việc"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyền cấp không gian làm việc"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyền được quản lý trong nhóm"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa chi tiết"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh dấu là bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việc đánh dấu người dùng là bot sẽ xóa quyền truy cập để đăng nhập của họ. Dữ liệu người dùng lịch sử của họ sẽ vẫn khả dụng trên bảng điều khiển."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bot khỏi phân tích tự động và bất kỳ chỉ định nào. Dữ liệu mới sẽ không xuất hiện trong bảng điều khiển, đảm bảo báo cáo chính xác và quản lý bot dễ dàng hơn."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại trừ bot khỏi các đánh giá"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được đánh dấu là bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ loại quyền Người dùng có thể được đánh dấu là bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng có nhiều tài khoản không thể được đánh dấu là bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo người dùng"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng, bot và không gian làm việc"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KHÔNG GIAN LÀM VIỆC"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng và không gian làm việc"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản trị viên"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có quyền nào"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã kết nối"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được thêm thủ công"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang chờ xử lý"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời mời đang chờ xử lý"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu thao tác"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...và thêm một"]), _normalize(["...và thêm ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi quyền thành"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Xóa và mời lại để thay đổi vai trò của ", _interpolate(_named("n")), " người dùng lời mời đang chờ xử lý."]), _normalize(["Xóa và mời lại để thay đổi vai trò của ", _interpolate(_named("n")), " người dùng lời mời đang chờ xử lý"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Chỉnh sửa chi tiết cho ", _interpolate(_named("name"))]), _normalize(["Chỉnh sửa chi tiết cho ", _interpolate(_named("n")), " người dùng đã chọn"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã cập nhật ", _interpolate(_named("n")), " người dùng."]), _normalize(["Đã cập nhật ", _interpolate(_named("n")), " người dùng."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nhóm này chứa 1 người dùng từ một không gian làm việc khác."]), _normalize(["Nhóm này chứa ", _interpolate(_named("n")), " người dùng từ một không gian làm việc khác."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích hoạt"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã lưu trữ nhóm \"", _interpolate(_named("name")), "\""])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm đã lưu trữ"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo nhóm"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã tạo nhóm \"", _interpolate(_named("name")), "\""])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm không có tiêu đề"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn xóa vĩnh viễn nhóm này?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa nhóm"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa nhóm"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa nhóm"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trưởng nhóm"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò nhóm"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã ẩn nhóm \"", _interpolate(_named("name")), "\""])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm này sẽ không được hiển thị ở nơi nào khác trong Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ quản lý quyền cấp không gian làm việc"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm không hiển thị trong Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trưởng nhóm"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành viên"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm không có tiêu đề"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên này đã được đặt cho một nhóm khác"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên này đã được đặt cho một nhóm đã lưu trữ"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai trò của bạn chỉ cho phép quản lý các thành viên của nhóm này. Hãy liên hệ với người dùng có quyền cao hơn để thực hiện thay đổi."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa với tư cách trưởng nhóm"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã khôi phục nhóm \"", _interpolate(_named("name")), "\""])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã lưu nhóm \"", _interpolate(_named("name")), "\""])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt làm trưởng nhóm"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã hủy lưu trữ nhóm \"", _interpolate(_named("name")), "\""])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi chưa lưu"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm những người dùng hiện có vào nhóm này"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo tên hoặc email"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thành viên"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đã hiện nhóm \"", _interpolate(_named("name")), "\""])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mỗi thành viên là ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về nhóm"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Một số thuộc tính của nhóm được cấp phép và đồng bộ hóa tự động từ nhà cung cấp nhận dạng của bạn."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm hiểu về Nhóm"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo Nhóm"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dễ dàng sắp xếp các thành viên đội và mô phỏng cơ cấu tổ chức của bạn."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm ít nhất 1 thành viên"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm tên nhóm"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã tồn tại nhóm có tên này"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành viên nhóm"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên nhóm"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sắp xếp các thành viên đội và mô phỏng cơ cấu tổ chức của bạn."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành viên"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Không có nhóm"]), _normalize(["1 nhóm"]), _normalize([_interpolate(_named("count")), " nhóm"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay vào đó, hãy tạo một nhóm mới hoặc kích hoạt một nhóm đã lưu trữ."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nhóm nào đang hoạt động"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nhóm nào được lưu trữ"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy nhóm nào phù hợp"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy thử tìm kiếm lại bằng từ khóa hoặc cách viết khác."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có nhóm nào được thêm vào"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["trong"]), _normalize(["trong"]), _normalize(["trong"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo tên nhóm"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu trữ"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Có thể gửi lại ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Còn lại ", _interpolate(_named("n")), " giấy phép"]), _normalize(["Còn lại ", _interpolate(_named("n")), " giấy phép"]), _normalize(["Còn lại ", _interpolate(_named("n")), " giấy phép"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng giấy phép của bạn hiện bị giới hạn do đăng ký của bạn đang tạm dừng. Hãy kích hoạt lại đăng ký để thêm nhiều người dùng hơn."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã đạt đến giới hạn giấy phép trong tài khoản của mình. Bạn có thể thay thế người dùng bằng cách hủy kích hoạt một người dùng đang hoạt động hoặc thêm nhiều giấy phép hơn."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem chi tiết thanh toán"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã chọn ", _interpolate(_named("n"))]), _normalize(["Đã chọn ", _interpolate(_named("n"))])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã chọn ", _interpolate(_named("n")), " người dùng"]), _normalize(["Đã chọn ", _interpolate(_named("n")), " người dùng"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " hàng"]), _normalize([_interpolate(_named("n")), " hàng"]), _normalize([_interpolate(_named("n")), " hàng"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm hàng loạt người dùng theo tên hoặc địa chỉ email của người dùng."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dán dữ liệu vào đây và phân tách bằng dấu phẩy hoặc dòng mới"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm hàng loạt người dùng"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Chỉnh sửa quyền cấp không gian làm việc cho ", _interpolate(_named("name"))]), _normalize(["Chỉnh sửa quyền cấp không gian làm việc cho ", _interpolate(_named("n")), " thành viên đã chọn"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa quyền"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn quyền cấp không gian làm việc"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Xóa ", _interpolate(_named("name")), " khỏi không gian làm việc"]), _normalize(["Xóa ", _interpolate(_named("name")), " thành viên khỏi không gian làm việc"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã xóa ", _interpolate(_named("n")), " thành viên"]), _normalize(["Đã xóa ", _interpolate(_named("n")), " thành viên"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Xóa thành viên"]), _normalize(["Xóa các thành viên"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Người dùng này sẽ bị xóa khỏi không gian làm việc."]), _normalize(["Những người dùng này sẽ bị xóa khỏi không gian làm việc."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quyền cấp không gian làm việc của \"", _interpolate(_list(0)), "\" đã thay đổi."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm thành viên"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem các cuộc hội thoại và đánh giá liên quan"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem mọi nội dung trong không gian làm việc nhưng chỉ quản lý các bài kiểm tra, chỉ định, ý kiến phản đối và phiên hiệu chuẩn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyền cấp không gian làm việc"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem và quản lý mọi nội dung trong không gian làm việc"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem mọi nội dung trong không gian làm việc, ngoại trừ cài đặt không gian làm việc"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết nối người dùng"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng từ các không gian làm việc mà bạn quản lý đã được thêm vào không gian làm việc này."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu quản trị viên thực hiện."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy trực tiếp mời người dùng mới hoặc yêu cầu quản trị viên thực hiện."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " hoặc ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có người dùng để hiển thị"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" Đã thêm ", _interpolate(_named("n")), " người dùng"]), _normalize(["Đã thêm ", _interpolate(_named("n")), " người dùng"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Thêm thành viên"]), _normalize(["Thêm thành viên"]), _normalize(["Thêm ", _interpolate(_named("n")), " thành viên"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn quyền cấp không gian làm việc"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người dùng tham gia không gian làm việc"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Thêm thành viên vào ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dán các email vào đây và phân cách bằng dòng mới hoặc dấu phẩy"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đã khớp ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " email"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" với những người dùng đã kết nối:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Không thể khớp ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " email"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểm tra chính tả hoặc kết nối riêng sau"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" với người dùng bộ phận trợ giúp:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã kết nối ", _interpolate(_named("n")), " người dùng"]), _normalize(["Đã kết nối ", _interpolate(_named("n")), " người dùng"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã mời ", _interpolate(_named("n")), " người dùng"]), _normalize(["Đã mời ", _interpolate(_named("n")), " người dùng"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối hàng loạt"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời hàng loạt"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví dụ:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối từ (các) bộ phận trợ giúp của bạn để đánh giá những tương tác của họ"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kết nối người dùng"]), _normalize(["Kết nối người dùng"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối riêng"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn muốn người dùng đã kết nối của mình truy cập vào Zendesk QA, bạn có thể mời họ tại đây."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời người dùng đã kết nối"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mời người dùng"]), _normalize(["Mời người dùng"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ qua việc mời"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn người dùng để kết nối từ (các) bộ phận trợ giúp của bạn để đánh giá những tương tác của họ"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý đăng ký của bạn"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối người dùng bộ phận trợ giúp"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt tên duy nhất cho không gian làm việc của bạn"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu được bật, tất cả các kết nối tài khoản hiện có sẽ tự động được thêm vào không gian làm việc mới."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm tất cả các kết nối vào không gian làm việc này"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo không gian làm việc"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên không gian làm việc"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo không gian làm việc"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được thêm trực tiếp vào tài khoản (không chỉ định không gian làm việc) sẽ được chỉ định vai trò cấp Tài khoản. Bạn có thể thêm người dùng vào không gian làm việc sau."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được mời sẽ tự động được chỉ định vai trò Người dùng ở cấp Tài khoản. Bạn có thể nâng cấp người dùng lên vai trò Quản trị viên sau."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả đều hoạt động"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dán các email vào đây và phân cách bằng dòng mới hoặc dấu phẩy."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã mời ", _interpolate(_named("n")), " người dùng qua email"]), _normalize(["Đã mời ", _interpolate(_named("n")), " người dùng qua email"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được thêm vào không gian làm việc sẽ tự động được chỉ định vai trò Người dùng ở cấp Tài khoản. Bạn có thể nâng cấp người dùng lên vai trò Quản trị viên sau."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm người dùng khác"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ mời người dùng vào tài khoản mà không thêm người dùng vào không gian làm việc"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được mời chỉ được chỉ định quyền Tài khoản và không được thêm vào bất kỳ không gian làm việc nào."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời người dùng trực tiếp vào không gian làm việc và chỉ định quyền cấp không gian làm việc"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm người dùng vào không gian làm việc"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời hàng loạt"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời riêng"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ mời vào tài khoản"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời vào không gian làm việc"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mời người dùng"]), _normalize(["Mời người dùng"]), _normalize(["Mời ", _interpolate(_named("n")), " người dùng"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời hàng loạt người dùng qua email"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Xóa người dùng"]), _normalize(["Xóa người dùng"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ chọn"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mời người dùng qua email"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có không gian làm việc"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn quyền"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi lại lời mời"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng được kết nối"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng lời mời qua email đang chờ xử lý"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn không gian làm việc"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân viên hỗ trợ AI"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung tâm quản trị"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đảm bảo chất lượng"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý nhân lực"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã hoàn thành ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Đã hoàn thành ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Đã hoàn thành ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chu kỳ ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa chỉ định"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiết kiệm thời gian và thiết lập các chỉ định đánh giá tự động thay vì tìm và phân bổ các cuộc hội thoại để đánh giá thủ công."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đến hạn vào ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thêm phiên trong mục \"Hiệu chuẩn\" trong menu bên của danh sách xem cuộc hội thoại."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có phiên nào được thêm"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điểm gốc"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có phiên nào"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thêm cuộc hội thoại vào phiên bằng cách nhấp vào biểu tượng hiệu chuẩn trong tiêu đề của danh sách xem cuộc hội thoại."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có cuộc hội thoại nào được thêm vào"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Không có đánh giá nào được đưa ra. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để lại đánh giá hiệu chuẩn"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguyên nhân gốc rễ"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truy cập"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tải được bảng lệnh."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy kết quả nào."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được tìm thấy bằng ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải bảng lệnh..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trang, bộ lọc, cuộc hội thoại, cài đặt..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuộc hội thoại"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc riêng tư"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ lọc công khai"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Từ ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ API của bạn"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hiển thị ", _interpolate(_named("rangeStart")), " đến ", _interpolate(_named("rangeEnd")), " trong số ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Hiển thị ", _interpolate(_named("rangeStart")), " đến ", _interpolate(_named("rangeEnd")), " trong số ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ẩn bảng bên"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiện bảng bên"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi thanh bên"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối được chấp nhận"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối bị từ chối"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối đang mở"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối được chấp nhận một phần"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã hoàn tất"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã nhận được"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá đã xem"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được chấp nhận"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đang mở"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được chấp nhận một phần"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " trong tổng số"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bị từ chối"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chấp nhận"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang mở"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chấp nhận một phần"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bị từ chối"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["đánh giá"]), _normalize(["đánh giá"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng đánh giá của họ đã được người được đánh giá xem"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng đánh giá của họ đã được xem"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đánh giá nhận được ý kiến phản đối"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người đưa ra ý kiến phản đối"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối theo danh mục"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ý kiến phản đối theo thời gian"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang mở"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian giải quyết trung bình"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian giải quyết trung bình (giờ)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian giải quyết trung bình (phút)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã giải quyết"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số ý kiến phản đối"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chấp nhận"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ phản hồi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy ngôn ngữ nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả ngôn ngữ"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yếu tố thúc đẩy được dự đoán"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy yếu tố thúc đẩy nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả yếu tố thúc đẩy"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ trong ma trận từ khóa"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy từ nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các từ"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đề cập"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mới nhất"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cũ nhất"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang mở"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được chấp nhận một phần"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " đã phản ứng với ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bị từ chối"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã giải quyết"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn tông màu da"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy phiên nào"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh mục"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy danh mục nào"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích thước bình luận"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy kích thước nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả kích thước"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy bộ lọc nào"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhóm"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy nhóm nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nhóm"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy hashtag nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các hashtag"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy giá trị nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả giá trị"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy trường tùy chỉnh nào"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ đánh dấu bộ phận trợ giúp"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy thẻ đánh dấu bộ phận trợ giúp"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các thẻ đánh dấu bộ phận trợ giúp"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang tải kết quả..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại câu hỏi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy loại câu hỏi nào"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm phản ứng"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thẻ điểm"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy thẻ điểm nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả thẻ điểm"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy điểm nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả điểm"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nguồn"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy nguồn nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các nguồn"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do cho ý kiến phản hồi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy lý do nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả lý do"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khảo sát"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy khảo sát nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả khảo sát"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kênh"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy kênh nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các kênh"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy người dùng nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả người dùng"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy không gian làm việc nào"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả không gian làm việc"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quay lại Bài kiểm tra"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra đã bị xóa hoặc chưa được đăng tải. Hãy kiểm tra kỹ liên kết hoặc điều hướng trở lại danh sách bài kiểm tra."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra này chưa được chia sẻ với không gian làm việc mà bạn tham gia. Hãy yêu cầu quản trị viên thêm bạn vào không gian làm việc mà bài kiểm tra này có quyền truy cập."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra không khả dụng"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyền truy cập bài kiểm tra được cấp cho (các) không gian làm việc mà bạn không tham gia. Không thể lưu và đăng tải bài kiểm tra."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu quản trị viên lưu và đăng tải bài kiểm tra."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn nên đánh dấu ít nhất một trong các câu trả lời là đúng."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không được để trống trường này."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn hiện đang xem bản xem trước của bài kiểm tra."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn không gian làm việc có thể truy cập bài kiểm tra. Người dùng tham gia nhiều không gian làm việc chỉ có thể làm bài kiểm tra một lần. Việc chọn \"Tất cả không gian làm việc\" sẽ không bao gồm các không gian làm việc mới được tạo sau khi bài kiểm tra đã được đăng tải."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truy cập bài kiểm tra"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu trữ"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thoát khỏi chế độ xem trước"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trước"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản hồi"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Làm bài kiểm tra"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng tải bài kiểm tra"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cài đặt bài kiểm tra"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu thành bản nháp"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo bài kiểm tra mới"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy lưu trữ"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tùy chọn"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm tùy chọn"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn thấy đẹp rồi chứ?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu trả lời của bạn đã được gửi"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra của bạn đã được lưu"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra của bạn đã được đăng tải"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra của bạn đã bị xóa"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm câu hỏi"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu trữ bài kiểm tra"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra đã lưu trữ và kết quả của bài kiểm tra đó sẽ không còn hiển thị trong danh sách bài kiểm tra nữa. Bạn vẫn có thể truy cập kết quả của bài kiểm tra đã lưu trữ và hủy lưu trữ bất cứ khi nào cần."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu trữ bài kiểm tra"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng tải"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi đăng tải, bạn sẽ không thể thay đổi hoặc chỉnh sửa bài kiểm tra. Mọi người dùng trong tài khoản của bạn sẽ có thể truy cập tất cả các bài kiểm tra đã đăng tải."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn đăng tải bài kiểm tra?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn xóa bài kiểm tra này không? Bạn sẽ không thể hoàn tác thao tác này."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn xóa bài kiểm tra?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi gửi, bạn sẽ không thể thay đổi câu trả lời."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn muốn gửi bài kiểm tra?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy lưu trữ bài kiểm tra"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác hủy lưu trữ sẽ hiển thị bài kiểm tra trong phần tổng quan về bài kiểm tra. Thao tác này sẽ cho phép những người tham gia trước đó truy cập vào kết quả của họ và cho phép những người mới làm bài kiểm tra."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy lưu trữ bài kiểm tra"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép câu hỏi"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả (không bắt buộc)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển câu hỏi xuống dưới"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển câu hỏi lên trên"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ những câu hỏi có câu trả lời mới được hiển thị trong bản xem trước."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa câu hỏi"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi bài kiểm tra"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra không có tiêu đề"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề bài kiểm tra"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn bài kiểm tra và kết quả của bài kiểm tra."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuy nhiên, bạn vẫn có thể truy cập kết quả bằng cách lưu trữ bài kiểm tra."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã trả lời"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa bài kiểm tra"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này sẽ xóa vĩnh viễn bài kiểm tra và kết quả của bài kiểm tra."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Xóa bài kiểm tra ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các bài kiểm tra giúp nâng cao sự tự tin và kỹ năng của đội, mang lại trải nghiệm nhất quán và thu hút các thành viên đội mới."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể tìm kiếm bài kiểm tra theo tên bài kiểm tra hoặc tên tác giả."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có bài kiểm tra nào được tạo"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất danh sách bài kiểm tra"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm trung bình"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày hoàn thành"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra/Tác giả"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra / Tác giả / Không gian làm việc"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người tham gia"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã đăng tải"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gian làm việc"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo bài kiểm tra, tác giả"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã lưu trữ"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản nháp"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang hoạt động"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả bài kiểm tra"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra đã được lưu trữ."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra đã bị hủy lưu trữ."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra này không nhận được phản hồi nào từ người dùng trong (các) không gian làm việc đã chọn."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra này không nhận được phản hồi nào trước khi được lưu trữ."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có phản hồi"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tại đây bạn sẽ thấy thông tin phân tích các câu trả lời của bài kiểm tra.\nChưa có ai điền vào bài kiểm tra của bạn - việc chia sẻ bài kiểm tra chắc chắn sẽ hữu ích."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chưa có phản hồi"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phản hồi:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm trung bình:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cá nhân"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Sao chép"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép liên kết"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy kết quả nào"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bài kiểm tra của ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể tìm kiếm tên người tham gia trên bảng xếp hạng."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất bảng xếp hạng"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm theo người tham gia"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảng xếp hạng"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài kiểm tra"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi bảng xếp hạng"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thao tác này đồng nghĩa với việc bạn sẽ không thể lọc hashtag này trên bảng điều khiển và hashtag này sẽ không tự động hoàn thành trên các bình luận nữa. Bạn sẽ không thể hoàn tác thao tác này."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag,..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo hashtag"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo hashtag"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo hashtag"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho phép thành viên tạo hashtag mới"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sử dụng"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Xóa hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng tạo hashtag đã bị khóa"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tính năng tạo hashtag đã được mở khóa"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đã xóa hashtag \"", _interpolate(_list(0)), "\""])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Đã đổi tên hashtag \"", _interpolate(_list(0)), "\" thành \"", _interpolate(_list(1)), "\""])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Đã thêm 1 hashtag"]), _normalize(["Đã thêm ", _interpolate(_named("count")), " hashtag"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên mới"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có hashtag nào được tạo"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đổi tên"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đổi tên hashtag \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân cách hashtag bằng dấu phẩy hoặc ngắt dòng"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Đừng lo. Bạn có thể thực hiện ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ngay tại đây"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã liên kết kết nối với không gian làm việc"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đính kèm kết nối"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đính kèm kết nối vào không gian làm việc này"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết nối"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy liên hệ với quản trị viên của bạn để thực hiện bất kỳ thay đổi nào tại đây"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy liên hệ với Zendesk QA để thực hiện bất kỳ thay đổi nào tại đây"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có kết nối nào trong tài khoản"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có vẻ như bạn chưa tích hợp với nền tảng dịch vụ khách hàng."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thao tác xóa kết nối sẽ xóa các cuộc hội thoại khỏi không gian làm việc ", _interpolate(_named("workspaceName")), " và xóa tất cả dữ liệu liên quan đến cuộc hội thoại, bao gồm cả các đánh giá và ý kiến phản đối."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa kết nối"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Xóa kết nối ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một kết nối"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trong khoảng thời gian đã chọn, một số danh mục QA tự động đang hoạt động nhất định không hiển thị trên thẻ điểm của bạn. Các cuộc hội thoại trước thay đổi cuối cùng của bạn vào ", _interpolate(_named("date")), " có thể chưa được phân tích ở các danh mục này."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi được đưa ra cho bạn sẽ hiển thị tại đây. Chưa có gì để hiển thị."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không nhận được đánh giá nào."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ hiển thị hoạt động bình luận"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các ý kiến phản hồi do bạn đưa ra sẽ hiển thị tại đây. Bắt đầu trong danh sách xem Cuộc hội thoại."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có đánh giá nào được đưa ra."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá được đưa ra"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh giá nhận được"])}
  }
}