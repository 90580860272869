import type { CommentTemplateCreateRequest, CommentTemplates, CommentTemplate } from '@zendesk/zqa-services/users'
import { api } from '@/api'

export const getCommentTemplates = () => api.get('profile/comment-templates').json<CommentTemplates>()

export const createCommentTemplate = (template: CommentTemplateCreateRequest) =>
  api.post('profile/comment-templates', { json: template }).json<CommentTemplate>()

export const updateCommentTemplate = (template: CommentTemplate) =>
  api.put(`profile/comment-templates/${template.id}`, { json: template }).json<CommentTemplate>()

export const deleteCommentTemplate = (id: string) => api.delete(`profile/comment-templates/${id}`)
