import { type Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { debounce } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import type { TicketListItem } from '@zendesk/zqa-services/tickets'
import { searchTicketsByExternalId } from '@/modules/conversations/api/tickets'
import { bus } from '@/utils/bus'

export interface Conversation extends TicketListItem {
  uuid: string
}

export default function (query: Ref<string>) {
  const router = useRouter()
  const route = useRoute()
  const searching = ref(false)
  const conversations = ref<Conversation[]>([])

  watch(query, debounce(searchConversations, 200))

  async function searchConversations() {
    searching.value = true

    if (!query.value) {
      conversations.value = []
      searching.value = false
      return
    }

    const tickets = await searchTicketsByExternalId([query.value])
    conversations.value = tickets.data.map((t) => ({ ...t, uuid: uuid() })) || []
    searching.value = false
  }

  function openSearchResults() {
    if (route.name?.toString().startsWith('conversations')) {
      return bus.$emit('search-ticket', conversations.value[0].externalId)
    }

    router.push({ name: 'conversations', query: { externalTicketId: conversations.value[0].externalId } })
  }

  return { conversations, openSearchResults, searchConversations, searching }
}
