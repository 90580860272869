import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useTicketContent } from './useTicketContent'
import useTicketTranscripts from './useTicketTranscripts'

const AGENT_COLORS = ['blue', 'green', 'purple', 'teal', 'orange', 'pink', 'red'] as const

export type AgentColorMap<T extends object | string> = {
  [key in (typeof AGENT_COLORS)[number]]: T
}

export default createSharedComposable(() => {
  const { participatingAgentIds: ticketAgentIds } = useTicketContent()
  const { participatingAgentIds: transcriptionAgentIds } = useTicketTranscripts()

  const uniqueParticipantIds = computed(() =>
    Array.from(new Set([...ticketAgentIds.value, ...transcriptionAgentIds.value])),
  )

  const agentColors = computed(() => {
    return uniqueParticipantIds.value.reduce<Record<string, (typeof AGENT_COLORS)[number]>>((res, val, i) => {
      res[val] = AGENT_COLORS[i % AGENT_COLORS.length]

      return res
    }, {})
  })

  return agentColors
})
