export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना Zendesk उप डोमेन दर्ज करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk में साइन इन करें"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी कंपनी का एक मौजूदा खाता है।"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक कंपनी में शामिल हों"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी मौजूदा कंपनी में शामिल होने के लिए, अपने व्यवस्थापक से आपको आमंत्रण भेजने के लिए कहें।"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने व्यवस्थापक से आपको आमंत्रण भेजने के लिए कहें"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैंने अपना विचार बदल दिया।"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-अप रद्द करें"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी कंपनी का एक मौजूदा खाता है। क्या आप अपनी कंपनी के खाते में शामिल होना चाहते हैं या एक नया खाता बनाना चाहते हैं?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्देश कॉपी करें"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया खाता बनाएँ"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मौजूदा खाता है"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप अपने Zendesk QA खाते तक तब तक नहीं पहुंच सकते जब तक कि आपके बकाया सदस्यता चालान का भुगतान नहीं हो जाता।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी सदस्यता होल्ड पर है"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता नहीं मिला। दोबारा कोशिश करें।"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया पासवर्ड प्राप्त करें।"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["फिर से कोशिश करें या ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत ईमेल और पासवर्ड संयोजन।"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने व्यवस्थापक से एक नया अनुरोध करें।"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह आमंत्रण लिंक अमान्य है या पहले ही उपयोग किया जा चुका है।"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " आपके खाते में साइन-इन विधि निष्क्रिय कर दी गई है।"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह आमंत्रण अमान्य है या उपयोग किया गया है। अपने व्यवस्थापक से एक नया अनुरोध करें।"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रण जानकारी लोड करने में विफल।"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-अप विफल रहा। इस ईमेल के साथ उपयोगकर्ता पहले से मौजूद हो सकता है।"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लगातार कई साइन-इन प्रयासों के बाद आपका खाता ब्लॉक कर दिया गया है। किसी ईमेल के लिए अपना इनबॉक्स देखें, जिसमें उसे अनब्लॉक करने के निर्देशों के बारे में जानकारी दी गई हो।"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले अपना ईमेल सत्यापित करें"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मान्य ईमेल खाता दर्ज करें"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़ील्ड आवश्यक है"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़ील्ड आवश्यक है"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट करें"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन कोड"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक भेजें"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन अप करें"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यालय का ईमेल पता"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके कार्यालय का ईमेल पता"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (मुख्य सेवा अनुबंध)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड आवश्यकताओं से मेल नहीं खाता"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोपनीयता सूचना"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["होल्ड करें"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["नमस्ते, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("organization")), " संगठन में शामिल होएं"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका ईमेल सत्यापित किया गया है। अब आप नीचे अपने नए खाते से साइन इन कर सकते हैं:"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google के साथ जारी रखें"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमान्य आमंत्रण लिंक"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक का उपयोग करके साइन इन करें"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करने के लिए वापिस जाएं"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack के साथ जारी रखें"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk के साथ जारी रखें"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका ईमेल सत्यापित किया गया है। अब आप नीचे अपने नए खाते से साइन इन कर सकते हैं:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google के साथ साइन इन करें"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक के माध्यम से साइन इन करें"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack के साथ साइन इन करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA में साइन इन करें"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका खाता हटा दिया गया है"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाता नहीं है?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मान्य कोड दर्ज करें"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़ील्ड आवश्यक है"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मान्य ईमेल खाता दर्ज करें"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़ील्ड आवश्यक है"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन कोड"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी साइन-इन विकल्प देखें"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["पारंपरिक तरीकों के माध्यम से ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक का उपयोग करके साइन इन करें"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको एक ईमेल प्राप्त हुआ है जिसमें आपका साइन-इन कोड है।"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको एक ईमेल मिला है, जिसे आप साइन इन करने के लिए फ़ॉलो कर सकते हैं।"])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक भेजने में कोई त्रुटि थी। दोबारा कोशिश करें।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल के माध्यम से साइन इन करें"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड बनाएँ"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक छोटा अक्षर"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक बड़ा अक्षर"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नये पासवर्ड में यह शामिल होना चाहिए"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की अधिकतम लंबाई 128 वर्ण है"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["याद रखना आसान, अनुमान लगाना मुश्किल"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम 8 वर्ण"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना पासवर्ड बनाएँ"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या पासवर्ड भूल गए?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक छोटा अक्षर"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संख्या"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक बड़ा अक्षर"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना पासवर्ड रीसेट करने के लिए, अपना ईमेल पता दर्ज करें और हम आपको निर्देशों के साथ एक ईमेल भेजेंगे।"])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मान्य ईमेल खाता दर्ज करें"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़ील्ड आवश्यक है"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड रीसेट करें"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["एक ईमेल ", _interpolate(_named("email")), " को भेजा गया है"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि ईमेल आपके इनबॉक्स या स्पैम फ़ोल्डर में दिखाई नहीं देता है, तो कृपया आपके द्वारा दर्ज किए गए ईमेल की जांच करें।"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करने के लिए वापिस जाएं"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना ईमेल जांचे"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड रीसेट करें"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", फिर आपको एक पासवर्ड रीसेट ईमेल भेजा जाएगा।"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आपके खाते में कोई मौजूदा पासवर्ड है"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यदि वह ", _interpolate(_named("bold")), "है, तो हमने आपको एक पासवर्ड रीसेट लिंक भेजा है।"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल एक खाते से संबद्ध है"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका पासवर्ड याद है?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट लिंक भेजने में कोई त्रुटि हुई थी। दोबारा कोशिश करें।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना पासवर्ड रीसेट करें"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक का उपयोग करके साइन अप करें"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (गोपनीयता नीति)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["साइन अप करके, आप Zendesk QA ", _interpolate(_named("terms_of_service")), " और ", _interpolate(_named("privacy_policy")), " से सहमत होते हैं"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा की शर्तें"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे पास खाता है?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक के माध्यम से साइन अप करें"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने कार्य ईमेल के साथ साइन अप करें"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack के साथ साइन अप करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA में साइन अप करें"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google के साथ साइन अप करें"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे पास एक सक्रिय Zendesk खाता है"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अपना ", _interpolate(_named("direct_install_name")), " सेटअप पूरा करने के लिए साइन अप करें या साइन इन करें"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या यह वास्तव में आप हैं? पुष्टिकरण ईमेल के लिए अपना इनबॉक्स देखें।"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप सभी सेट अप हैं"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA डेस्कटॉप पर सबसे अच्छा काम करता है। बातचीतों की समीक्षा शुरू करने के लिए वहां साइन इन करें।"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक डेमो बुक करें"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या, हमारे साथ एक डेमो बुक करें और हम आपको दिखाएंगे कि आप Zendesk QA में क्या कर सकते हैं।"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी उपस्थिति का अनुरोध किया जाता है"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["मैंने अपना मन बदल दिया और ", _interpolate(_named("cancel")), " करना चाहता हूं"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " आपको उनके खाते में शामिल होने के लिए आमंत्रित करता है।"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते में शामिल होएं"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया खाता बनाएँ"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर से साइन इन करने की कोशिश करें"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपकी प्रतिक्रिया के लिए आपको मिली प्रतिक्रिया: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त प्रतिक्रियाएँ"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ने आपकी टिप्पणी पर प्रतिक्रिया दी"]), _normalize(["आपकी टिप्पणी पर कई लोगों ने प्रतिक्रिया दी"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ने आपके जवाब पर प्रतिक्रिया दी"]), _normalize(["आपके जवाब पर कई लोगों ने प्रतिक्रिया दी"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ने आपकी समीक्षा पर प्रतिक्रिया दी"]), _normalize(["कई लोगों ने आपकी समीक्षा पर प्रतिक्रिया दी"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जवाब दें"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करें"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिया गया"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियाँ"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रतिक्रिया"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रियाएँ"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू किया"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["टिप्पणी को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"]), _normalize(["सभी टिप्पणियों को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["तर्क-वितर्क को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"]), _normalize(["सभी तर्क-वितर्क को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["सर्वेक्षण प्रतिक्रिया को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"]), _normalize(["सभी सर्वेक्षण प्रतिक्रिया को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षा को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"]), _normalize(["सभी समीक्षाओं को पढ़े गए के रूप में चिह्नित करना चाहते हैं?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["टिप्पणी को पढ़े गए के रूप में चिह्नित किया गया"]), _normalize(["सभी टिप्पणियों को पढ़े गए के रूप में चिह्नित किया गया"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["तर्क-वितर्क को पढ़े गए के रूप में चिह्नित किया गया"]), _normalize(["सभी तर्क-वितर्कों को पढ़े गए के रूप में चिह्नित किया गया"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["सर्वेक्षण प्रतिक्रिया को पढ़े गए के रूप में चिह्नित किया गया"]), _normalize(["सभी सर्वेक्षण प्रतिक्रिया को पढ़े गए के रूप में चिह्नित किया गया"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षा को पढ़े गए के रूप में चिह्नित किया गया"]), _normalize(["सभी समीक्षा को पढ़े गए के रूप में चिह्नित किया गया"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी को पढ़े गए के रूप में चिह्नित करें"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पढ़े गए के रूप में चिह्नित करें"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब कोई समीक्षा पर तर्क-वितर्क करता है, तो वह यहां दिखाई देती है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई तर्क-वितर्क नहीं"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब आपको किसी तर्क-वितर्क को हल करने के लिए असाइन किया जाता है, तो वह यहाँ दिखाई देता है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्राप्त तर्क-वितर्क नहीं"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब आप किसी समीक्षा पर तर्क-वितर्क करते हैं, तो वह यहां दिखाई देती है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई तर्क-वितर्क शुरू नहीं हुआ"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको दी गई सभी प्रतिक्रिया यहां दिखाई देती है। अभी तक दिखाने के लिए कुछ भी नहीं है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रतिक्रिया प्राप्त नहीं हुई"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी समीक्षाओं और उत्तरों पर दी गई सभी प्रतिक्रियाएँ यहां दिखाई देती हैं। अभी तक दिखाने के लिए कुछ भी नहीं है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रतिक्रियाएँ नहीं मिली"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत शुरू करें"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा दी गई सभी प्रतिक्रिया यहां समाप्त होती है। बातचीत दृश्य में समीक्षा छोड़कर प्रारंभ करें।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षा नहीं दी गई"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको दी गई सभी प्रतिक्रिया यहां दिखाई देती है। अभी तक दिखाने के लिए कुछ भी नहीं है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्राप्त समीक्षा नहीं"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टिप्पणी नहीं मिली"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियों के रूप में आपके द्वारा छोड़ी गई सभी प्रतिक्रियाएँ यहां दिखाई देती हैं।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टिप्पणी नहीं दी गई है"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टिप्पणी नहीं मिली"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी समीक्षा के अंतर्गत सभी टिप्पणियाँ, ", "@", "उल्लेख, या आपकी टिप्पणियों के उत्तर यहां दिखाई देते हैं।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्राप्त टिप्पणी नहीं"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्वीकृत तर्क-वितर्क नहीं"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खुला तर्क-वितर्क नहीं"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खारिज तर्क-वितर्क नहीं"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई हल किया गया तर्क-वितर्क नहीं"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऊपर दिए गए फ़िल्टर से कोई अन्य विकल्प चुनकर पुनः प्रयास करें।"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऊपर दिए गए फ़िल्टर से कोई अन्य उपयोगकर्ता चुनकर पुनः प्रयास करें।"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई टिप्पणियाँ"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त टिप्पणियां"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी तर्क-वितर्क"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त हुए तर्क-वितर्क"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू हुए तर्क-वितर्क"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करें"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रतिक्रिया प्राप्त की गई"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई समीक्षाएँ"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त समीक्षाएँ"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि सूची टॉगल करें"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " समीक्षा हो गई"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " समीक्षा हो गई"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " समीक्षा हो गई"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " में पुनरारंभ होता है"])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["शेष ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक असाइनमेंट सेट करें"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट सूची को टॉगल करें"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " और ", _interpolate(_list(1)), " के बीच एक शिक्षा सत्र बनाना संभव नहीं है और क्योंकि वे वर्तमान में एक ही कार्यस्थान में नहीं हैं।"])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " और ", _interpolate(_list(1)), " के बीच शिक्षा सत्र पहले से मौजूद हैं। सत्र तक पहुंच प्राप्त करने के लिए ", _interpolate(_list(0)), " से संपर्क करें।"])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुझाया गया"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " और ", _interpolate(_list(1)), " के बीच एक नया सत्र बनाया गया है।"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षक"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोच का चयन करें"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षार्थी"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षक का चयन करें"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र बनाएँ"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक सत्र बनाएँ"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र जोड़ें"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र का नाम"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुझाया गया"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बकाया"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र बनाएँ"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सत्र नहीं मिला"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत समीक्षा समय"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलें"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित अवधि के लिए दिखाने के लिए कोई डेटा नहीं"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सत्र में पिन जोड़ा गया ", _interpolate(_named("buttonStart")), "मुझे दिखाएँ", _interpolate(_named("buttonEnd")), "।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["में जोड़ें...."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ा गया"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप चयनित अवधि से पिन के अंत तक पहुँच गए हैं"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["देखें कि ", _interpolate(_named("link")), " में पिन कैसे काम करते हैं।"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आलेख"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ऐसा लगता है कि आपने नीचे ", _interpolate(_list(0)), " कुछ भी पिन नहीं किया है। आप 1:1 की तैयारी के लिए या किसी अन्य समय के लिए कुछ सहेजने के लिए पिन का उपयोग कर सकते हैं।"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षार्थी"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई पिन नहीं जोड़ा गया"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यहां वे सभी पिन दिए गए हैं जिनको आपने ", _interpolate(_named("name")), " के तहत बनाया है। सत्रों में जोड़े गए पिन अन्य प्रतिभागी के साथ ", _interpolate(_named("bold")), " होंगे।"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुरंत साझा किया गया"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ऐसा लगता है कि ", _interpolate(_list(0)), " को चयनित अवधि के दौरान बातचीत समीक्षा या CSAT के लिए कोई प्रतिक्रिया नहीं मिली है।"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रतिक्रिया उपलब्ध नहीं है"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप चयनित अवधि से टिप्पणियों के अंत तक पहुँच गए हैं"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैट्रिक"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "की संख्या"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया एक्शन आइटम"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " को ", _interpolate(_named("user")), " द्वारा पूरा किया गया"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " द्वारा"])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नई एक्शन आइटम जोड़ने के लिए टाइप करें"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बैठक से क्या प्राप्त हुआ? आपके अगले कदम क्या हैं?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्शन आइटम"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), " को ", _interpolate(_named("name")), " के द्वारा बनाया गया।"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र हटाएँ"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यह स्थायी रूप से ", _interpolate(_named("deleteSession")), "होगा। प्रतिभागी सत्र तक पहुंच खो देते हैं, जिसमें इसकी सामग्री और निजी नोट्स शामिल हैं।"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " को सत्र बनाया गया"])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("updatedDate")), " को ", _interpolate(_named("name")), " द्वारा अंतिम बार संपादित किया गया"])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talking अंक और नोट्स"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोट अपडेट किया गया"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र छोड़ें"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साझा करें"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित उपयोगकर्ता इस बिंदु से सत्र विवरण तक पहुंच प्राप्त करते हैं।"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता का चयन करें"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साझा किया गया"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र साझा करें"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["अब आप ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " सत्र का हिस्सा नहीं होंगे।"])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " अब इस सत्र का हिस्सा नहीं होंगे।"])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " की अब इस सत्र तक पहुंच है।"]), _normalize([_interpolate(_named("n")), " नए उपयोगकर्ताओं को अब इस सत्र तक पहुँच प्राप्त है"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र हटाएं"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सत्र हटाएं"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र हटाएं"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षक"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " द्वारा जोड़े गए नोट्स यहां दिखाई देंगे।"])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन किए गए आइटम को दाईं ओर के पैनल से चर्चा बिंदुओं के रूप में जोड़ें"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोट्स केवल आप देख सकते हैं..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत नोट्स"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निजी नोट अपडेट किया गया"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 दिन"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षक"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षार्थी"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्शन आइटम"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम सत्र"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बकाया"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र अवलोकन"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी देखें"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं: शिक्षक"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं: शिक्षार्थी"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सत्र"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साझा सत्र"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्शन आइटम देखने के लिए फ़िल्टर स्विच करने का प्रयास करें।"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई एक्शन आइटम नहीं"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्शन आइटम"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा प्राप्त हुई और देखी गई"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त · देखा"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्रों के बाद प्रगति"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लगता है कि एजेंटों को कोई प्रतिक्रिया नहीं मिली है।"])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्यापित करें कि आपके पास उपयोगकर्ता का सही नाम है"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उस उपयोगकर्ता को नहीं ढूँढ सका"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई डेटा उपलब्ध नहीं है"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिनों का IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["इससे <b>", _interpolate(_named("name")), "</b> के साथ <b>सभी सत्र (2) स्थायी रूप से हटा</b> दिए जाएंगे। प्रतिभागी सामग्री और निजी नोट्स सहित सभी सत्रों तक पहुंच खो देते हैं।"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप सभी सत्रों के अंत तक पहुँच चुके हैं"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक शिक्षा सत्र प्रदर्शन, अवसरों, लघु और दीर्घकालिक लक्ष्यों पर चर्चा करने के लिए एक उपदेशक और एक शिक्षार्थी के बीच समूह गतिविधि है।"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप शिक्षक के नाम और प्रशिक्षित किए जा रहे व्यक्ति दोनों से सत्र खोज सकते हैं।"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सत्र नहीं मिला"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई सत्र नहीं बनाया गया"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा इतिहास हटाएँ"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा इतिहास हटाएँ "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम सत्र"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सत्र"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षक"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षार्थी"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण के रूप में चिह्नित करें"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ववत् के रूप में चिह्नित करें"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम के अनुसार खोजें"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालें"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम बदलें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र सूची टॉगल करें"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनियत"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस संदेश का जवाब दें"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्त कैलिब्रेशन सत्र। कोई अतिरिक्त समीक्षा नहीं छोड़ी जा सकती है।"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप इस बातचीत के लिए पहले ही एक समीक्षा छोड़ चुके हैं।"])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत की समीक्षा करना अब संभव नहीं है क्योंकि सत्र की नियत तारीख बीत चुकी है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत की कोई समीक्षा नहीं है"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैनल बंद करें"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक कैलिब्रेशन समीक्षा छोड़ें"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सारांश"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसक्रिप्ट"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["स्पीकर ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " से."])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूकावट/ब्रेक"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पीकर संपादित करें"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश की समीक्षा करें"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश चलाने के लिए वापस जाएँ"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पीकर"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसक्रिप्ट"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान के उपयोगकर्ता"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर बनाने के लिए पहले कनेक्शन जोड़ें"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बॉट नहीं"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " द्वारा ", _interpolate(_named("time")), " पर निर्मित"])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अंतिम बार ", _interpolate(_named("time")), " को ", _interpolate(_named("agentName")), " द्वारा अपडेट किया गया"])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ऐसा लगता है कि आपके पास कोई फ़िल्टर नहीं है। इस पेज तक पहुंचने के लिए, आपको सबसे पहले एक फ़िल्टर बनाना होगा। अपनी खुद की बातचीत देखने के लिए ", _interpolate(_named("recommendedFilter")), " शुरू करें। जारी रखने के लिए नीचे यह फ़िल्टर बनाएँ।"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिनों में"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"पिछले 30 दिनों में बनाया गया\" स्थिति को दिनांक सीमा के बिना बनाए गए फ़िल्टर में जोड़ दिया जाएगा"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर इस योजना में उपलब्ध नहीं है और परिणाम प्रदर्शित करते समय इसे ध्यान में नहीं रखा जाएगा"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर विकल्प प्राप्त करने का समय समाप्त हो गया है। दोबारा कोशिश करें।"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर करने के बारे में जानें"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी = साइन-इन व्यक्ति"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["बातचीत मिली: ", _interpolate(_named("count"))]), _normalize(["बातचीत मिली: ", _interpolate(_named("count"))]), _normalize(["बातचीत मिली: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी वर्तमान योजना में उपलब्ध नहीं है। इस सुविधा का उपयोग करने के लिए अपग्रेड करें।"])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति जोड़ें"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI द्वारा"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट में बदलें"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर बनाएं"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर बनाएं"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम फ़ील्ड"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थितियां"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हम आपके विशिष्ट सेटअप के अनुसार फ़िल्टर विकल्प लोड कर रहे हैं। प्रारंभिक लोड पर इसमें कुछ मिनट लग सकते हैं।"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने फ़िल्टर को वर्णनात्मक नाम दें"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर नाम"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई विकल्प चुनें"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निजी"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सार्वजनिक"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर संपादित करें"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव सहेजें"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मान उपलब्ध नहीं है"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोड हो रहा है..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं मिला"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मान चुनें"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दृश्यता"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सक्रिय सत्र ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुराने सत्र दिखाएं ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियत तारीख"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन सत्र संपादित करें"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र बनाएँ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन सत्र बनाएँ"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र के दौरान दी गई सभी कैलिब्रेशन समीक्षाओं को स्थायी रूप से हटा दिया जाएगा।"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र हटाएं"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), " कैलिब्रेशन सत्र हटाएं"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सत्र नहीं बनाया गया है"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सक्रिय सत्र नहीं"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सत्र नहीं बनाया गया"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सक्रिय सत्र नहीं"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर जोड़ें"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक निजी फ़िल्टर बनाएँ"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सार्वजनिक फ़िल्टर बनाएँ"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर जोड़ा गया"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएँ"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर स्थायी रूप से हटा दिया जाएगा।"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर हटाएं"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " फ़िल्टर हटाएं"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी तर्क-वितर्क"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे लिए तर्क-वितर्क"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे लिए तर्क-वितर्क"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुले तर्क-वितर्क"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई फ़िल्टर नहीं जोड़ा गया"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई निजी फ़िल्टर नहीं बनाया गया"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सार्वजनिक फ़िल्टर नहीं बनाया गया"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर्स"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निजी फ़िल्टर्स"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सार्वजनिक फ़िल्टर्स"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cta")), " करना चाहते हैं?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसे सहेजें"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह फ़िल्टर का अस्थायी दृश्य है।"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके फ़िल्टर का निर्माण किया जा रहा है..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर अपेडट किया गया"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insights"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम AutoQA श्रेणी"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["पिछली बार अपडेट किया गया ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जटिल शब्द"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकट परिवर्तनों के आधार पर ऑटो-समीक्षा गतिशील रूप से अपडेट होती है"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में हाइलाइट करें"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबे वाक्य"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई ऑटो स्कोर की भविष्यवाणी नहीं की गई"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी भी गलती का पता नहीं लगा सका"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जटिल शब्द दिखाएं"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबे वाक्य दिखाएं"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगली बातचीत"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपर्याप्त संदर्भ के कारण AutoQA इस श्रेणी को रेट नहीं कर सका। मैनुअल समीक्षा की सिफारिश की।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी समीक्षा प्रक्रिया को कारगर बनाने के लिए स्वचालित बातचीत insights खोजें। महत्वपूर्ण बातचीतों को हाइलाइट करना और अपनी टीम के लिए असाधारण सकारात्मक उदाहरण प्रदर्शित करना।"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[कॉल]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सही किया गया"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाया गया"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ने तर्क-वितर्क स्वीकार कर लिया"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " को तर्क-वितर्क भेजा गया"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझाएं कि आपको क्यों लगता है कि यह समीक्षादाता इस प्रतिक्रिया के लिए गलत प्राप्तकर्ता है"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक टिप्पणी जोड़ें और एक नया समीक्षादाता सेट करें"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी जोड़ें"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझाएं कि आप क्यों मानते हैं कि ये स्कोर गलत तरीके से रेट किए गए हैं (अनिवार्य)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक टिप्पणी जोड़ें (अनिवार्य)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझाएं कि आप इस तर्क-वितर्क को क्यों खारिज कर रहे हैं"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी जोड़ें"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनको तर्क-वितर्क भेजें"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनको तर्क-वितर्क भेजें"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनको तर्क-वितर्क भेजें"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए समीक्षादाता का चयन करें"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क प्रतिक्रिया"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क प्रतिक्रिया"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता बदलें"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनको तर्क-वितर्क भेजें"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समीक्षा पर तर्क-वितर्क करें"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं स्कोर से सहमत नहीं हूं"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह समीक्षा मेरे लिए नहीं थी"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी उपयोगकर्ता का चयन करें"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ने तर्क-वितर्क को आंशिक रूप से स्वीकार किया"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ने तर्क-वितर्क को खारिज कर दिया"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार किया गया"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से स्वीकार किया गया"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार किया गया"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुला"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " तर्क-वितर्क शुरू हुआ: समीक्षा"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " तर्क-वितर्क शुरू हुआ: गलत समीक्षादाता"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा पर अन्य कार्रवाई करने के लिए एक खुले तर्क-वितर्क को हल किया जाना चाहिए।"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार करें"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए स्कोर का चयन करें"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क हटाएं"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई इस तर्क-वितर्क को छोड़ना चाहते हैं?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क हटाएं"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क के समाधान का समय"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार करें"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए उचित स्कोर का चयन करें (वैकल्पिक)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क की समीक्षा"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत समीक्षादाता"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क स्वीकार करें"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क स्वीकार करें"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क प्रतिक्रिया"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत समीक्षादाता"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क प्रतिक्रिया"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क को अस्वीकार करें"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क को अस्वीकार करें"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क देखें"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित किया गया"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ", _interpolate(_named("date")), " द्वारा ", _interpolate(_named("action"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " में बदलें"])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हो गई"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी पूर्ण"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["को असाइन किया गया:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक टिप्पणी भी करें"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थितिगत स्कोरकार्ड के बारे में जानें"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले इनको असाइन किया गया:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बस एक चुनें"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उस नाम का कोई GIF नहीं मिला"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा छोड़ने वाले पहले व्यक्ति बनें।"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी सदस्यता अभी रूकी हुई है। प्रतिक्रिया देने में सक्षम करने के लिए इसे पुनः सक्रिय करें।"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनके द्वारा पोस्ट किया गया:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे पता चलता है कि इस बातचीत की समीक्षा करने में कितना समय व्यतीत किया गया"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी उपयोगकर्ता का चयन करें"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["श्रेणी के लिए अनुपलब्ध मूल कारण: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी श्रेणी को रेट करें या कोई टिप्पणी जोड़ें"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमोजी जोड़ें"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छोटा करें"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी फ़ील्ड विस्तृत करें"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी फ़ील्ड संक्षिप्त करें"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ॉर्मेटिंग छुपाएँ"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैनल छिपाएँ"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ॉर्मेटिंग दिखाएँ"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैनल दिखाएँ"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजें"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी श्रेणियों को रेट करें"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम से कम एक श्रेणी को रेट करें"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत को बाद के लिए सहेजें"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाद के लिए सहेजें"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला असाइनमेंट"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस असाइनमेंट साइकल में बातचीत बदलें"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की समीक्षा करें"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आधारभूत समीक्षा"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएँ"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी हटाएँ"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी हटा दी गई"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस टिप्पणी को हटा दें?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी लेखक"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस हैशटैग को हटा दिया गया है या इसका नाम बदल दिया गया है।"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साझा करें कि आपको क्या पसंद आया या क्या बेहतर हो सकता था ..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी करें"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थितिगत"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह सभी टिप्पणियों को स्थायी रूप से हटा देगा।"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी हटाएँ"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[संदेश]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ने अभी तक यह प्रतिक्रिया नहीं देखी है"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ने यह प्रतिक्रिया देखी है"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्व सदस्य"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF खोजें..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया हैशटैग बनाने के लिए एंटर दबाएं।"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम हैशटैग व्यवस्थापक द्वारा लॉक किए जाते हैं।"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मामलों को बुकमार्क करने और बाद में फ़िल्टर करने के लिए इसका उपयोग करें"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आधार रेखा के रूप में चिह्नित करें"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश प्रतिक्रिया"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगली बातचीत"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रतिक्रिया नहीं"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षा नहीं हुई"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आरंभ करने के लिए नीचे एक समीक्षा छोड़ दें।"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अन्य समीक्षा नहीं बची"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रेट नहीं किया गया"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला असाइनी"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछली बातचीत"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आधार रेखा के रूप में निकालें"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जवाब दें"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समीक्षा का जवाब दें"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करें"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा साइडबार छुपाएं"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह समीक्षा और सभी जोड़ी गई टिप्पणियों को स्थायी रूप से हटा देगा।"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हटाएं"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हटाएं"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हटाई गई"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " द्वारा समीक्षा हटाई गई"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हो गया!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा छोड़ने के लिए स्वयं को यह कार्य फिर से असाइन करें"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप इस उपयोगकर्ता के लिए पहले ही एक समीक्षा छोड़ चुके हैं।"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["आपने ", _interpolate(_list(0)), " के लिए एक समीक्षा छोड़ी है"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत के लिए दिखाने के लिए कोई श्रेणी नहीं।"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान के लिए स्व-समीक्षाएँ उपलब्ध नहीं हैं।"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मत सहेजें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समीक्षा को ड्राफ्ट के रूप में सहेजना चाहते हैं?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस ड्राफ्ट को छोड़ें"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छोड़ें"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा साइडबार दिखाएं"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विफल"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाबालिगों के साथ असफ़ल"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजें"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी भी संख्याओं को क्रंच कर रहा है ..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टिप्पणी दें"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस संदेश की समीक्षा करें"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वयं की समीक्षा करें"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत की समीक्षा करें"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेखक"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करने के लिए कोई उपयोगकर्ता नहीं"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा अक्षम"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई उपयोगकर्ता नहीं मिला"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान के उपयोगकर्ता"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोड हो रहा है..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता चुनें"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 समीक्षा"]), _normalize([_interpolate(_named("count")), " समीक्षाएँ"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए मूल्यांकन चुनें"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके कार्यस्थान प्रबंधक ने स्व-समीक्षा की अनुमति नहीं दी है।"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस पिन को टैग करने के लिए एंटर दबाएं"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिन"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारांकित"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन सत्र बनाएँ"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insights"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights आपको अपने सहायता डेस्क डेटा का विज़ुअल प्रतिनिधित्व देती है। यह समझने के लिए इंटरेक्टिव चार्ट का इस्तेमाल करें कि अपने QA प्रयासों पर कहां फ़ोकस करना है, ब्लाइंडस्पॉट को कैसे उजागर करना है, और उनके प्रदर्शन के आधार पर समीक्षा करने के लिए सही एजेंट कैसे ढूंढना है।"])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("language")), " में अनुवाद करें"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["में जोड़ें"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइन नहीं किए गए"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी देख रहे हैं"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी समीक्षा कर रहे हैं"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह बातचीत निकाल दी गई है और अब उपलब्ध नहीं है।"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप जिस बातचीत की तलाश कर रहे हैं वह Zendesk QA में मौजूद नहीं है।"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत के साथ सभी पिन"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र में"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत हटाएँ"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी सत्र से किसी बातचीत को हटाने से उसे दी गई सभी समीक्षाएं हट जाएंगी।"])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), " से बातचीत निकालें"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई कैलिब्रेशन सत्र नहीं बनाया गया।"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत को सत्र में जोड़ दिया गया है"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत को सत्र से निकाल दिया गया है"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन सत्र में जोड़ें/निकालें"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत का लिंक कॉपी करें"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई बातचीत नहीं"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रैंड"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक का ईमेल"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक की ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक का नाम"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी फ़ॉर्म का नाम"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहली प्रतिक्रिया देने का समय"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिकता"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण टिप्पणी"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संतुष्टि स्कोर"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले संकल्प का समय"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोड हो रहा है..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह संदेश इस बातचीत के अंतर्गत मौजूद नहीं है या इसे हटा दिया गया है"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई विषय नहीं है"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई बातचीत नहीं"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बातचीत नहीं मिली"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल बातचीत खोलें"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से लिंक नहीं किया जा सकता - अनुपलब्ध URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रतिभागी नहीं"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत पिन करें"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा पढ़ने के लिए क्लिक करें"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए क्लिक करें"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्राउज़र एक्सटेंशन के माध्यम से बनाई गई बातचीत"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक संतुष्टि सर्वेक्षण देखें"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत देखें"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक सेंटिमेंट के साथ संदेश"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सकारात्मक सेंटिमेंट के साथ संदेश"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंग्रेजी में अनुवाद करें"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल संदेश दिखाएं"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत हटाएँ"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["किसी बातचीत को हटाना स्थायी होता है। यह डैशबोर्ड सहित सभी संबंधित डेटा, जैसे सर्वेक्षण, ट्रांसक्रिप्शन और Zendesk QA से समीक्षाओं को भी हटा देता है।", _interpolate(_named("lineBreak")), "आप अभी भी इस बातचीत को Zendesk Support या आपके द्वारा उपयोग किए जा रहे किसी अन्य सहायता डेस्क सॉफ़्टवेयर में एक्सेस कर पाएंगे।"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["किसी बातचीत को हटाना स्थायी होता है। यह डैशबोर्ड सहित सभी संबंधित डेटा, जैसे सर्वेक्षण, ट्रांसक्रिप्शन और Zendesk QA से समीक्षाओं को भी हटा देता है। यदि बातचीत एकाधिक कार्यस्थानों से संबंधित है, तो इसे उन सभी से हटा दिया जाएगा।", _interpolate(_named("lineBreak")), "आप अभी भी इस बातचीत को Zendesk Support या आपके द्वारा उपयोग किए जा रहे किसी अन्य सहायता डेस्क सॉफ़्टवेयर में एक्सेस कर पाएंगे।"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत हटाएँ"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA से बातचीत हटाएँ"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["तनाव बढ़ने के कारण यह बातचीत अधूरी है। ", _interpolate(_named("optionsUrl")), " पर जाओ और अपने टैग मान्य करें। ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["तनाव बढ़ने के कारण यह बातचीत अधूरी है। अपने टैग सत्यापित करने के बारे में अपने व्यवस्थापक से बात करें। ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन > उन्नत विकल्प"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग के बारे में जानें"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद किया गया"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुला"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाधान किया गया"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["असाइनी को ", _interpolate(_named("target")), " में बदल दिया गया है"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइन नहीं किए गए"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " मैक्रो को ", _interpolate(_named("author")), " द्वारा लागू किया गया"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बातचीत को मूल्यांकन मिला ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["उपयोगकर्ता ", _interpolate(_named("target")), " ने बातचीत को संपादित किया"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["स्थिति ", _interpolate(_named("target")), " में बदली गई"])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " के साथ टैग किया गया"])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team ", _interpolate(_named("target")), " में बदली गई"])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ने ", _interpolate(_named("target")), " के लिए बातचीत को स्नूज़ किया"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ने बातचीत को स्नूज़ से हटा दिया"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट एक लूप में फंस गया, लगातार एक संदेश दोहरा रहा है"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट पुनरावृत्ति"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट पुनरावृत्ति, जहां एक ही संदेश लगातार एक या अधिक बार दोहराया जाता है"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिलते-जुलते वाक्यांश मिले"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["एक औसत एजेंट बॉट की तुलना में बातचीत ", _interpolate(_named("percent")), " को अधिक कुशलता से संभालता है"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट संचार दक्षता"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बॉट ने एक औसत एजेंट की तुलना में बातचीत ", _interpolate(_named("percent")), " को अधिक कुशलता से संभाला"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट संचार दक्षता"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में रिकॉर्डिंग के प्रतिभागियों को सूचित करने वाले आवश्यक कथन का अभाव है, जो अनुपालन और पारदर्शिता के लिए आवश्यक है"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिकॉर्डिंग प्रकटीकरण अनुपलब्ध"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " के लिए सेवा-स्तरीय समझौते (SLA) का उल्लंघन किया गया है।"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA का उल्लंघन"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA नियम की मीट्रिक अपने लक्ष्य समय से अधिक हो गई है"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक संघर्षण के संकेत। ग्राहक स्विच पर विचार कर रहा था या अलग होने का वादा किया था।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक द्वारा सदस्यता रद्द करने का जोखिम"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["बातचीत में रुकावट/ब्रेक के क्षण हैं जो ", _interpolate(_list(0)), " की निर्धारित सीमा से अधिक समय तक चलते हैं"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 उदाहरण"]), _normalize([_interpolate(_named("count")), " उदाहरण"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " सबसे लंबा"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूकावट/ब्रेक"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने उच्च स्तरीय सहायता मांगी"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वृद्धि का अनुरोध किया गया"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने अत्यधिक आभार व्यक्त किया या प्राप्त समर्थन से बहुत संतुष्ट था"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाधारण सेवा"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या तो ग्राहक या एजेंट ने स्पष्ट रूप से फ़ॉलो-अप का अनुरोध किया"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ॉलो-अप अनुरोध"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने निराशा दिखाई"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक सेंटिमेंट"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत असामान्य या विचित्र थी और संकल्प तक पहुंचने के लिए बहुत विचार-विमर्श थे"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपवाद का पता चला"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने संतुष्टि दिखाई"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सकारात्मक सेंटिमेंट"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयुक्त"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं के रूप में सेट करें"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " को ", _interpolate(_named("author")), " द्वारा अंतिम बार संपादित किया गया"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं होता है"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " को ", _interpolate(_named("author")), " द्वारा"])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक कारण जोड़ें (वैकल्पिक)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई insights नहीं मिले"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिनांक"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन जोड़ें"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने सहायता डेस्क को अपने खाते से कनेक्ट करें और बातचीतों की सूची देखने के लिए कम से कम एक फ़िल्टर बनाएं।"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई बातचीत नहीं"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान सेटिंग देखें"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाया गया"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछली बार अपडेट किया गया"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेट करें"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(एक दिन में)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(यह महीना)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(यह सप्ताह)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID से ढूंढें"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("searchQuery")), " के लिए परिणाम खोजें"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दूसरों द्वारा समीक्षित"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी की गई"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नवीनतम"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे पुराना"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यादृच्छिक"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाल ही में अपडेट किया गया"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाल ही में जवाब दिया"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके अनुसार क्रमित करें"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपठित"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा समीक्षित"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आयात प्रक्रिया में कुछ समय लग सकता है। जब यह पूरा हो जाएगा तो हम आपको ईमेल करेंगे।"])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA आपकी बातचीत आयात कर रहा है"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["लिमिट: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत दृश्य के हेडर में कैलिब्रेशन आइकन पर क्लिक करके जोड़ें।"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहां रिपोर्ट करने के लिए कुछ भी नहीं है। किसी भिन्न फ़िल्टर या सॉर्टिंग विकल्प का उपयोग करके पुनः प्रयास करें।"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई अपवाद नहीं।"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक प्रासंगिक बातचीत ढूंढें और इसे यहां बुकमार्क करने के लिए हेडर में स्टार आइकन पर क्लिक करें।"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर बदलने का प्रयास करें या अधिक बातचीत जोड़ने के लिए अन्य सहायता डेस्क एजेंटों को आमंत्रित करें।"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सीमा नहीं"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक नोट"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हम अभी बातचीत लोड कर रहे हैं। हो सकता है कि कुछ सामग्री अभी भी मौजूद न हो।"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला उपयोगकर्ता"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई उपयोगकर्ता नहीं मिला"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा प्राप्त की गई"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान के सदस्य"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला उपयोगकर्ता"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बातचीत नहीं जोड़ी गई"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बातचीत नहीं जोड़ी गई"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधूरी समीक्षा यहाँ समाप्त होती है। अगले 30 दिनों में इसे फिर से पढ़ें, संशोधित करें और भेजें।"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई ड्राफ्ट की गई समीक्षा नहीं"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई तारांकित बातचीत नहीं"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई बातचीत नहीं"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मेल नहीं"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित संदेश पर लौटें"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम उत्तर"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकांश उत्तर"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की सूची टॉगल करें"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी उपयोगकर्ता"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " पर कॉल का जवाब दिया गया"])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " पर कॉल समाप्त हुई"])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " पर कॉल की गई"])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हो गई"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा समीक्षित"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ऑडियो फ़ाइल लोड करने में विफल। सुनिश्चित करें कि आपने ", _interpolate(_list(0)), " में साइन इन किया है।"])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक संतुष्टि सर्वेक्षण टिप्पणी"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑडियो लोड करने में त्रुटि"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी लेखक"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कॉल नहीं की गई"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क में सुनें"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एयरकॉल पर सुनें"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्लेबैक गति"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल की समीक्षा करें"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रणाली"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसक्रिप्ट"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्न"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यम"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA उपयोगकर्ता नहीं"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA स्वचालित रूप से एजेंटों को उनके मासिक औसत CSAT के आधार पर रैंक करता है। एक क्लिक में उन एजेंटों द्वारा प्रबंधित बातचीतों को ढूंढें जिनका CSAT उनके साथियों से कम है।"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT द्वारा रैंकिंग"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यम"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्न"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्नतम"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्चतम"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA उपयोगकर्ता नहीं"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक टिकट"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वॉल्यूम"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA स्वचालित रूप से एजेंटों को प्रदर्शन समूहों में रैंक करता है कि वे प्रति माह कितनी बातचीत संभालते हैं। एक क्लिक में अपने साथियों की तुलना में कम वॉल्यूम वाले एजेंटों की बातचीत ढूंढें।"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वॉल्यूम के आधार पर रैंकिंग"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हो गई"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा नहीं हुई"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा की स्थिति"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्न"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तटस्थ"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA उपयोगकर्ता नहीं"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA स्वचालित रूप से बातचीत में औसत सेंटिमेंट के आधार पर एजेंटों को रैंक करता है। उन इंटरेक्शन का पता लगाएं जहां एजेंट एक क्लिक में अपने साथियों की तुलना में अधिक निराश ग्राहकों से डील करते हैं।"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट द्वारा रैंकिंग"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता नहीं चला"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता लगाया गया"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपवाद"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत जिसमें आपके ध्यान की आवश्यकता होती है। हमारा मशीन प्रशिक्षण मॉडल आगे की समीक्षा के लिए जटिल या एटिपिकल इंटरेक्शन की पहचान करता है।"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपवाद"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सकारात्मक"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए बातचीतों का चयन करें जहां ग्राहक ने खुशी या निराशा प्रदर्शित की। ड्रॉप-डाउन का उपयोग करके उस सेंटिमेंट के प्रकार का चयन करें जिसे आप अपनी बातचीत में खोज रहे हैं। अपनी सभी इंटरेक्शन में चुने गए सेंटिमेंट का अनुपात देखने के लिए फ़िल्टर विशेषता को शीर्ष पर खींचें।"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा की स्थिति"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा नहीं हुई"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हो गई"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA स्वचालित रूप से आपकी बातचीत में भाषाओं का पता लगाता है। समीक्षा के लिए विशिष्ट भाषाओं में बातचीतों का चयन करने के लिए ड्रॉप-डाउन का इस्तेमाल करें। अपने सहायता डेस्क में इस्तेमाल की जाने वाली सभी भाषाओं का संक्षिप्त विवरण प्राप्त करने के लिए फ़िल्टर विशेषता को शीर्ष पर खींचें।"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत Insights"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप नियमित बातचीत फ़िल्टर की तुलना में Insights के साथ ", _interpolate(_named("paragraph_2_item")), " कर सकते हैं। अपने सहायता डेस्क से सभी डेटा का एक दृश्य संक्षिप्त विवरण प्राप्त करें, और इनसाइट्स को एक्सप्लोर करें जैसे:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत insights का उपयोग करने के बारे में जानें"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दूसरों के संबंध में विशिष्ट बातचीत विशेषताओं के सापेक्ष महत्व को समझें"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल्ट-इन एजेंट प्रदर्शन रैंकिंग के साथ अपने शीर्ष और निम्न-प्रदर्शन करने वाले एजेंट खोजें"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " एक शक्तिशाली इंटरेक्टिव डेटा माइनिंग टूल है जो आपको उन बातचीतों को खोजने में सहायता करता है जिनकी सबसे अधिक समीक्षा करने की आवश्यकता होती है।"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और भी बहुत कुछ करें"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बाचतीत और एजेंट चार्ट हैं ", _interpolate(_named("paragraph_3_item_1")), " - फ़िल्टर अपडेट करना या किसी एक पर खंडित चार्ट स्लाइस पर क्लिक करना स्वचालित रूप से बातचीत सूची को अपडेट करेगा। ", _interpolate(_named("paragraph_3_item_2")), " विभिन्न तत्वों के विस्तृत ब्रेकडाउन या अनुपात को explore करना।"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परस्पर जुड़ा हुआ"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर विशेषताओं को पुनर्व्यवस्थित करें"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बातचीत insights केवल ", _interpolate(_named("paragraph_4_item")), "प्रदर्शित करते हैं। हम वास्तविक समीक्षा मूल्य के बिना स्वचालित रूप से इंटरेक्शन निकाल देते हैं, जैसे ऑटोजेनरेटेड संदेश, स्पैम, बॉट उत्तर या ऐसे संदेश जिनका आपके समर्थन प्रतिनिधि के साथ कोई उचित संवाद नहीं होता है।"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पष्ट और बंद की गई बातचीत"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं यहाँ क्या देख सकता हूँ?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), " में से ", _interpolate(_named("count"))]), _normalize([_interpolate(_named("total")), " में से ", _interpolate(_named("count"))]), _normalize([_interpolate(_named("total")), " में से ", _interpolate(_named("count"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " में से ", _interpolate(_named("percentage"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फेच करने में विफल"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " लागू नहीं"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(कुल का ", _interpolate(_named("percentage")), ")"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीलोड करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insights"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेकिन हमें यकीन है कि एक बार आने के बाद यह बहुत अच्छा होगा।"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम सर्वेक्षण"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सर्वेक्षण प्रतिक्रिया नहीं बची"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई सर्वेक्षण प्रतिक्रिया स्कोर उपलब्ध नहीं है ..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया टैग"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया टिप्पणी टैग"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा फ़िल्टर लागू होने की तारीख"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " जवाब दें"]), _normalize([_interpolate(_named("count")), " उत्तर"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["सर्वेक्षण सेटिंग्स के तहत कारणों को स्थापित करके ", _interpolate(_list(0)), " स्कोर क्यों दिया गया था, इस पर गहराई से चिंतन करें।"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["अभी तक कोई कारण नहीं ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण के कारणों के बारे में जानें"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग के साथ बातचीत:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["द्वारा ", _interpolate(_list(0))])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाएं"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी श्रेणी"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " कारण"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अनुमानित ", _interpolate(_named("surveyType")), " ड्राइवर"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का स्रोत"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["सर्वेक्षण प्रतिक्रियाएँ (", _interpolate(_named("n")), ")"]), _normalize(["सर्वेक्षण प्रतिक्रिया (", _interpolate(_named("n")), ")"]), _normalize(["सर्वेक्षण प्रतिक्रियाएं (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई विषय नहीं है"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " के साथ बातचीत"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["उपयोगकर्ताओं द्वारा ", _interpolate(_list(0))])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलें"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया दर"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " स्कोर अधिकतम स्कोर के योग से विभाजित सभी प्रतिक्रियाओं का योग है"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य संपादित करें"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य निकालें"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक लक्ष्य निर्धारित करें"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य जोड़ें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छोटा"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहुत लंबा"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबा"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य लंबाई"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेचीदा"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायक नहीं"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खराब सहायता"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अच्छी सहायता"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहुतायत भावनाएँ"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट के लिए प्रतिक्रिया"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिकायत"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टुकड़े"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समस्या हल नहीं हुई"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समस्या हल हो गई"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक सेंटिमेंट"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुरा परिणाम"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सकारात्मक सेंटिमेंट"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सराहना"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुरा उत्पाद"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धनवापसी"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुंरत सहायता"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धीमी सहायता"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धन्यवाद"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA स्वचालित रूप से आपके ", _interpolate(_list(0)), " डेटा को पूर्व-निर्धारित श्रेणियों में आवंटित करता है। तो आप जटिल insights को समझ सकते हैं और लिखित प्रतिक्रिया के माध्यम से मैन्युअल रूप से स्थानांतरित किए बिना कारणों का पता लगा सकते हैं।"])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्कोर"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सर्वेक्षण"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया की भाषा चुनें"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी का आकार"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी आकार का चयन करें"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल एक शब्द वाली 25% टिप्पणियों के साथ, लंबी टिप्पणियां अवश्य पढ़ी जानी चाहिए। मैन्युअल काम किए बिना अधिक व्यावहारिक प्रतिक्रिया प्राप्त करें, क्योंकि हम इसे आपके लिए स्वचालित रूप से करते हैं।"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया का कारण"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया का कारण चुनें"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मिलान स्कोर नहीं"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमानित ड्राइवर"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी श्रेणी का चयन करें"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्डक्लाउड शब्द"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्डक्लाउड शब्दों का चयन करें"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " पिछली अवधि से"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " बातचीत"]), _normalize([_interpolate(_named("n")), " बातचीत"]), _normalize([_interpolate(_named("n")), " बातचीतें"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियाँ"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा की कमी के कारण सभी बातचीतों से भेजे गए सर्वेक्षण अनुपात की गणना करने में असमर्थ।"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रियाएँ"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषित सर्वेक्षण"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण फ़़नल"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), " का ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("total")), " का ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("total")), " का ", _interpolate(_named("n"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अनुमानित ", _interpolate(_named("surveyType")), " ड्राइवर"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA स्वचालित रूप से आपके ", _interpolate(_list(0)), " डेटा को पूर्व-निर्धारित श्रेणियों में आवंटित करता है। तो आप जटिल insights को समझ सकते हैं और लिखित प्रतिक्रिया के माध्यम से मैन्युअल रूप से स्थानांतरित किए बिना कारणों का पता लगा सकते हैं।"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी का आकार"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल एक शब्द वाली 25% टिप्पणियों के साथ, लंबी टिप्पणियां अवश्य पढ़ी जानी चाहिए। मैन्युअल काम किए बिना अधिक व्यावहारिक प्रतिक्रिया प्राप्त करें, क्योंकि हम इसे आपके लिए स्वचालित रूप से करते हैं।"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रतिक्रियाएँ"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (औसत)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया गणना"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (औसत)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["द्वारा ", _interpolate(_list(0)), " रुझान"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया गणना"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाएं"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राइवर"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " सर्वेक्षण भेजे जाने का तारीख"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " सर्वेक्षण जवाब तारीख"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["इसको ", _interpolate(_list(0)), " स्कोर असाइन करें"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके आधार पर डेटा प्रदर्शित करें"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत करने की तारीख"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकट के मालिक"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकांश उत्तरों के साथ एजेंट"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (औसत)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " समय के साथ"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया गणना"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " और IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया गणना"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत जिनमें ये शब्द शामिल हैं:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ड क्लाउड"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं द्वारा CSAT"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT का संक्षिप्त विवरण"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण फ़़नल"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रतिक्रियाएँ"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय के साथ CSAT"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ड क्लाउड"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चैनल नहीं"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्रोत नहीं"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई भाषा नहीं"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कारण नहीं"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टैग नहीं"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टिप्पणी श्रेणी नहीं"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई डेटा उपलब्ध नहीं है क्योंकि कोई असाइनमेंट नहीं बनाया गया है या कोई साइकल प्रारंभ नहीं किया गया है। बाद में फिर जांच करें या फ़िल्टर समायोजित करें।"])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सप्लोर करें कि नया क्या है"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " वस्तु"]), _normalize([_interpolate(_named("count")), " वस्तुएं"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " बातचीत"]), _normalize([_interpolate(_named("count")), " बातचीतें"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विफल"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाबालिगों के साथ असफ़ल"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल/विफल"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत (मिनट)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " मूल्यांकन"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत CSAT = [फीडबैक स्कोर का योग ÷ (सभी प्रतिक्रियाओं की संख्या × अधिकतम संभव स्कोर)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA CSAT स्कोर आपके प्रतिक्रिया स्कोर को 0-100% के पैमाने के भीतर सामान्य करता है और Standard स्कोर का औसत लेता है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (ग्राहक संतुष्टि स्कोर) यह निर्धारित करता है कि आपके ग्राहक आपकी सेवाओं से कितने खुश हैं।"])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " प्रत्येक समीक्षा एक बातचीत पर एक व्यक्ति द्वारा समीक्षा का प्रतिनिधित्व करती है। बातचीत की एक से ज़्यादा समीक्षाएं हो सकती हैं (यदि दो या ज़्यादा समीक्षक एक ही बातचीत की समीक्षा करते हैं)।"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय के साथ समीक्षक के स्कोर के विकास को ट्रैक करें।"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय के साथ एजेंटों के स्कोर के विकास को ट्रैक करें।"])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " प्रतिक्रियाएं"]), _normalize([_interpolate(_named("n")), " प्रतिक्रिया"]), _normalize([_interpolate(_named("n")), " प्रतिक्रियाएं"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " समीक्षाएँ"]), _normalize([_interpolate(_named("n")), " समीक्षा"]), _normalize([_interpolate(_named("n")), " समीक्षाएँ"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (आंतरिक गुणवत्ता स्कोर) आपकी बातचीत की समीक्षाओं का परिणाम है।"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = समीक्षा स्कोर का योग ÷ समीक्षाओं की संख्या"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित फ़िल्टर स्थितियों से मेल खाने वाले सभी समीक्षा स्कोर (श्रेणी स्कोर का भारित औसत) को ध्यान में रखते हुए इसकी गणना औसत के रूप में की जाती है।"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड लोड करने में विफल।"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुनः प्रयास करने के लिए अपने फ़िल्टर बदलें।"])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पास दर आपकी बातचीत समीक्षाओं के परिणाम को इंगित करती है।"])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["समीक्षा स्कोर ", _interpolate(_named("failScore")), " या उससे कम होने पर विफल"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["समीक्षा स्कोर ", _interpolate(_named("passScore")), " या उससे अधिक होने पर पास"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह एक पूर्व-निर्धारित आधार रेखा द्वारा निर्धारित किया जाता है, जिसका अर्थ है कि आप:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पास दर = पारित समीक्षाओं का योग ÷ कुल समीक्षाओं का योग"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पास दर इस बात का प्रतिशत इंगित करती है कि कितनी समीक्षाएं महत्वपूर्ण या गैर-महत्वपूर्ण श्रेणियों में विफल हुए बिना पास होती हैं।"])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["उदाहरण के लिए यदि समीक्षक इस दिन 3 समीक्षाएं देता है, तो प्रदर्शित संख्या 3 अंकों का औसत है। देखें कि प्रत्येक सेल की गणना कैसे की ", _interpolate(_named("link")), "जाती है।"])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["उदाहरण के लिए यदि एजेंट को इस दिन 3 समीक्षाएं प्राप्त होती हैं, तो प्रदर्शित संख्या 3 अंकों का औसत है। देखें कि प्रत्येक सेल की गणना कैसे की ", _interpolate(_named("link")), "जाती है।"])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान में नहीं रखा गया: श्रेणी वजन, महत्वपूर्ण स्थिति (समय के साथ स्कोर के विपरीत)।"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चूंकि श्रेणियों को समीक्षाओं में n/a चिह्नित किया जा सकता है, इसलिए प्रति श्रेणी समीक्षाओं की संख्या समीक्षाकर्ता द्वारा कुल समीक्षाओं से कम हो सकती है। देखें कि प्रत्येक सेल की गणना कैसे की जाती है"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान में नहीं रखा गया: श्रेणी वजन, महत्वपूर्ण स्थिति (समय के साथ स्कोर के विपरीत)।"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चूंकि श्रेणियों को समीक्षाओं में n/a चिह्नित किया जा सकता है, इसलिए प्रति श्रेणी समीक्षाओं की संख्या प्रति एजेंट कुल समीक्षाओं से कम हो सकती है। देखें कि प्रत्येक सेल की गणना कैसे की जाती है"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान में नहीं रखा गया: श्रेणी वजन, महत्वपूर्ण स्थिति (समय के साथ स्कोर के विपरीत)।"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " श्रेणी समूह अलग से प्रदर्शित किए जाते हैं। प्रत्येक समूह के स्कोर की गणना उसी अवधि के दौरान उस समूह के लिए औसत श्रेणी स्कोर द्वारा की जाती है। देखें कि प्रत्येक सेल की गणना ", _interpolate(_named("link")), " कैसे की जाती है।"])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोट:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान में नहीं रखा गया: श्रेणी वजन, महत्वपूर्ण स्थिति (समय के साथ स्कोर के विपरीत)।"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " श्रेणी समूह अलग से प्रदर्शित किए जाते हैं। प्रत्येक समूह के स्कोर की गणना उसी अवधि के दौरान उस समूह के लिए औसत श्रेणी स्कोर द्वारा की जाती है। देखें कि प्रत्येक सेल की गणना ", _interpolate(_named("link")), " कैसे की जाती है।"])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बेसलाइन ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गंभीर त्रुटि मुक्त"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण त्रुटि मुक्त दर किसी भी महत्वपूर्ण श्रेणियों को विफल किए बिना समीक्षाओं के प्रतिशत को इंगित करती है।"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक गुणवत्ता स्कोर"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'अन्य' मूल कारण"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षिप्त विवरण"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवधिक परिवर्तन"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दैनिक परिवर्तन"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक परिवर्तन"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साप्ताहिक परिवर्तन"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वार्षिक परिवर्तन"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय के साथ स्कोर"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या तो दिनों या हफ्तों में देखें।"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गणना = दिन या सप्ताह के लिए कुल स्कोर को दी गई कुल समीक्षाओं से विभाजित किया जाता है"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या तो दिनों या हफ्तों में देखें।"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गणना = प्राप्त कुल समीक्षाओं से विभाजित दिन या सप्ताह के लिए कुल स्कोर"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी के अनुसार स्कोर"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस अवधि के दौरान समीक्षाकर्ता द्वारा दी गई सभी श्रेणी मूल्यांकनों का संयुक्त औसत देखें।"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस अवधि के दौरान प्रति एजेंट प्राप्त सभी श्रेणी मूल्यांकनों का संयुक्त औसत देखें।"])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम मूल्यांकन के मूल कारण"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन के मूल कारण"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय के साथ श्रेणी स्कोर"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करें कि समय के साथ श्रेणियों की तुलना कैसे होती है।"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक चुने हुए दिन या सप्ताह पर प्रत्येक श्रेणी के लिए दिए गए औसत स्कोर देखें।"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करें कि समय के साथ श्रेणियों की तुलना कैसे होती है।"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी चुने हुए दिन या सप्ताह में प्रत्येक श्रेणी के लिए प्राप्त औसत स्कोर देखें।"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाओं द्वारा स्कोर"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहित"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण श्रेणी"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन पैमाना"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत/कुल"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षाओं को बहिष्कृत करें"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्ड निर्यात करता है"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिया गया"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["बातचीत"]), _normalize(["बातचीतें"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["आइटम"]), _normalize(["आइटम"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " प्रतिक्रियाएं"]), _normalize([_interpolate(_named("n")), " प्रतिक्रिया"]), _normalize([_interpolate(_named("n")), " प्रतिक्रियाएं"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " समीक्षाएँ"]), _normalize([_interpolate(_named("n")), " समीक्षा"]), _normalize([_interpolate(_named("n")), " समीक्षाएँ"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["एक और दिखाएं"]), _normalize([_interpolate(_named("n")), " और दिखाएं"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिन"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 7 दिन"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला महीना"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला सप्ताह"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह महीना"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह सप्ताह"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम अवधि"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीने"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिमाहियाँ"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड लोड करने में विफल। पुनः प्रयास करने के लिए अपने फ़िल्टर बदलें।"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई डेटा नहीं।"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक परिवर्तन"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षिप्त विवरण"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक जानकारी"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके सक्रिय फ़िल्टर में अलग-अलग मूल्यांकन पैमाने का डेटा शामिल होता है।"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इन फ़िल्टर के साथ सभी कार्ड पिन"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इन फ़िल्टर के साथ सभी डैशबोर्ड पिन"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत समीक्षा समय"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टमाइज़ करें"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV डाउनलोड करें"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई श्रेणी नहीं"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी insights"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राफ़"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["टिप्पणी"]), _normalize(["टिप्पणियाँ"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियाँ"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैट्रिक्स"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्व सदस्य"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक गुणवत्ता स्कोर"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (औसत)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तीर्ण समीक्षा"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन कार्ड"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन डैशबोर्ड"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["मूल कारण"]), _normalize(["मूल कारण"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा समय"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारणों को सेट करें"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण आपको बताते हैं कि बातचीत कम स्कोर क्यों प्राप्त कर रही है"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस श्रेणी के लिए कोई मूल कारण सेट नहीं है"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर / कुल"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणियां"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी समूह"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी समूह"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आर्डर अनुकूलित करें"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियों को इनके अनुसार समूहित करें"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी चुनें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टमाइज़ करें"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षाओं को बहिष्कृत करें"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा शामिल करें"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल स्व-समीक्षा दिखाएं"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर साफ़ करें"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई हैशटैग नहीं मिला"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी हैशटैग"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी हैशटैग का चयन करें"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टैग नहीं मिला"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग खोजें"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्कोरकार्ड नहीं मिला"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड का चयन करें"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मिलान कनेक्शन नहीं"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का स्रोत"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत चुनें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक फ़िल्टर"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मेल खाने वाला कार्यस्थान नहीं"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिया गया कुल समय"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी उपयोगकर्ता"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समूह"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मेल खाने वाले समूह नहीं।"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समूह"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड प्रदर्शित करें"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके आधार पर डेटा प्रदर्शित करें"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्माण तारीख की समीक्षा करें"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत करने की तारीख"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दो दशमलव स्थान दिखाएँ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स देखें"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देखें"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरा खाता"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान का नाम"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़्लैग"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाद्य और पेय"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पशु और प्रकृति"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑब्जेक्ट"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोग और शरीर"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यात्रा और स्थान"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्सर इस्तेमाल किया"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज परिणाम"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्माइली और इमोटिकॉन"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई इमोजी नहीं मिला"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत शुरू करें"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य सूची पर वापस जाएं"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " या अपनी ", _interpolate(_named("link")), " की सूची ब्राउज़ करें"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप वर्तमान में उस ", _interpolate(_named("workspace")), " कार्यस्थान का उपयोग कर रहे हैं जो इस डोमेन से कनेक्ट नहीं है। इसे अभी कनेक्ट करने के लिए यहां क्लिक करें।"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपलब्ध कनेक्शन"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप अभी तक इस पर ", _interpolate(_named("host")), " प्रतिक्रिया नहीं दे सकते।"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय टैब के रूप में अपना सहायता डेस्क रखें।"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस डोमेन की अभी समीक्षा नहीं की जा सकती। यदि ऐसा होना चाहिए, तो अपने व्यवस्थापक को सूचित करें।"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह डोमेन जोड़ें"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("host")), " से कनेक्ट करें"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया प्रतीक्षा करें - आपको सेटअप किया जा रहा है"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL प्राप्त नहीं कर सका"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपकरण जो पहले से ही संगठन के Zendesk QA खाते से जुड़े हुए हैं।"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन के Zendesk QA खाते से कनेक्ट हुआ कोई उपकरण नहीं।"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको उन वेबसाइटों की समीक्षा करने की अनुमति देता है जहां समीक्षा छोड़ने से पहले अंत में एक अद्वितीय हैश जोड़कर URL हमेशा समान रहता है"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता स्विच करें"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके डोमेन"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डोमेन जो पहले से ही इस कार्यस्थान से कनेक्ट हैं।"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप निम्न डोमेन पर किसी भी पेज की समीक्षा कर सकते हैं"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए उपलब्ध डोमेन"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सटेंशन"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्वितीय URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अभी तक कोई सहायता डेस्क कनेक्ट नहीं हुआ है - सहायता डेस्क कनेक्ट करने के लिए ", _interpolate(_named("link")), " पर जाएं।"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA ब्राउज़र एक्सटेंशन का उपयोग\nकेवल व्यावसायिक और उन्नत योजनाओं पर किया जा सकता है।"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान स्विच करें"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नाम प्रदान करें"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मान प्रदान करें"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम फ़ील्ड"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मान"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह पहली बार है जब हम इस बातचीत में आए हैं। कुछ विवरण प्रदान करें।"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत संदर्भ:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाद में टिकट की पहचान करने के लिए कुछ"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धनवापसी"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी और एक विषय दोनों प्रदान करें"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएँ"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाना"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत की समीक्षा करें"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइन नहीं किए गए"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अपने प्रबंधक से संपर्क करें या", _interpolate(_named("settings")), " से किसी भिन्न कार्यस्थान पर स्विच करें।"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान के लिए स्वयं-समीक्षाएँ अक्षम हैं।"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन आउट करें"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अलविदा"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस URL की समीक्षा नहीं की जा सकती। किसी बातचीत पर नेविगेट करें।"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले अपने SSO प्रदाता के माध्यम से Zendesk QA में साइन इन करें और फिर पुनः प्रयास करें।"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO के साथ साइन इन करें"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन पॉपअप खोलना।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने Zendesk QA खाते से साइन इन करें"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा करें..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणित किया जा रहा है..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक खाता नहीं है?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन अप करें"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके पास इस बातचीत तक पहुँच नहीं है। यदि आपको करना चाहिए, तो अपने प्रबंधक से संपर्क करें।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई एक्‍सेस नहीं"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जांचें कि आपका कनेक्शन Zendesk QA में सक्रिय है"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने कनेक्शन प्रबंधित करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन में त्रुटि हुई"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखने के लिए अपने सहायता डेस्क में बातचीत पर नेविगेट करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमान्य URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर से कनेक्ट करने का कार्य लगभग पूरा हो गया है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक मिनट"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़्लो में वापस आने के लिए पेज को फिर से लोड करने का प्रयास करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई गड़बड़ी हुई है"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने सहायता डेस्क की आभासी सीमाओं से खुद को मुक्त करें और कहीं भी बातचीत की समीक्षा करें।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सटेंशन का उपयोग करने के लिए अपनी सदस्यता अपग्रेड करें"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी सदस्यता प्रबंधित करें"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क कनेक्शन इस कार्यस्थान से लिंक नहीं है"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका सहायता डेस्क इस टिकट को नहीं पहचानता है, या Zendesk QA के पास इसकी पहुंच नहीं है"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत नहीं ढूँढ सका"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई गतिविधि"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको कुछ प्रतिक्रिया मिली है"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन सफल रहा"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अब आप ऐप का उपयोग कर सकते हैं"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी श्रेणियों को रेट करें"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मामलों को बुकमार्क करने और बाद में फ़िल्टर करने के लिए इसका उपयोग करें"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोड हो रहा है..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत के लिए कोई प्रतिक्रिया नहीं"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया पढ़ें"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA में खोलें"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह वह जगह है जहां सभी प्रतिक्रियाएं और सूचनाएं दिखाई देंगी। अभी तक कुछ भी नहीं..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA में और देखें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त समीक्षाएँ"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करें"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का विवरण"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरा शामिल है"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के सभी है"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के सभी शामिल नहीं है"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के सभी नहीं है"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट से बेहतर"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट से भी बदतर"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत है"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे अधिक है"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनमें से कोई भी शामिल है"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनमें से कोई भी है"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनमें से कोई भी शामिल नहीं है"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनमें से कोई भी नहीं है"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सही है"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उल्लंघन हुआ"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी की गई"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे द्वारा टिप्पणी की गई"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी नहीं की गई है"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे द्वारा टिप्पणी नहीं की गई"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए महत्वपूर्ण"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शामिल हैं"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिन"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भीतर"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 14 दिन"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम 24 घंटे"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिन"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 7 दिन"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला महीना"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला सप्ताह"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह महीना"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह सप्ताह"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब तक"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला दिन"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता लगाया गया"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूद"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे द्वारा तारांकित"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे द्वारा तारांकित नहीं"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसमें शामिल हैं"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["है"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे अधिक"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से शुरू होगा"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे कम"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब तक"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं है"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["है"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं है"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उल्लंघन नहीं किया गया"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जटिल नहीं"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता नहीं चला"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूद नहीं है"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसमें शामिल नहीं है"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त नहीं हुआ"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं देखा"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सकारात्मक"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा नहीं हुई"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे द्वारा समीक्षा नहीं हुई"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हो गई"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे द्वारा समीक्षा हो गई"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए महत्वपूर्ण"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शामिल हैं"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["है"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे अधिक"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से शुरू होगा"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे कम"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब तक"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["है"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं है"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसमें शामिल नहीं है"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं है"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देखा गया"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी टिप्पणी में उत्तर हैं"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हां"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम श्रेणियां"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम स्पॉटलाइट"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति के लिए खोजें"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जानकारी"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैट्रिक"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा और प्रतिक्रिया"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्थितियाँ दिखाएं"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत एजेंटों के खिलाफ आपके बॉट की बातचीत से निपटने की तुलना करता है"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट एक लूप में फंस गया है, एक ही संदेश को लगातार दोहरा रहा है"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में बॉट जवाबों की संख्या"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में शामिल एक प्रकार का बॉट"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में शामिल एक बॉट"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["बातचीत सामग्री में एजेंटों के संदेश में वाक्यांश ", _interpolate(_list(0)), " शामिल है"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनिवार्य प्रकटीकरण कथन की अनुपस्थिति वाले कॉल की स्वचालित रूप से पहचान करें, जैसे 'यह कॉल रिकॉर्ड की जाएगी' और इसी तरह के अन्य कथन।"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में शामिल एक व्यक्ति या बॉट"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में समीक्षा किए जा रहे एजेंट या बॉट"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने उच्च स्तर की सहायता मांगी"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में सकारात्मक और नकारात्मक सेंटिमेंट का पता चला"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI विश्लेषण द्वारा बातचीत में सबसे सक्रिय एजेंट की पहचान करता है"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि क्या एजेंट ने बातचीत को विनम्रता से समाप्त किया"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि क्या एजेंट ग्राहकों की भावनाओं को समझता है और स्वीकार करता है"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट के व्याकरण, वर्तनी और शैली की गलतियों का विश्लेषण करता है"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट अभिवादन वाक्यांशों के लिए पूरी बातचीत का विश्लेषण करता है"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि एजेंट ग्राहक की क्वेरी या चिंता को समझता है या नहीं"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शब्द जटिलता और वाक्य की लंबाई को देखते हुए विश्लेषण करता है कि किसी पाठ को कितनी आसानी से समझा जा सकता है"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफ़र किए गए समाधान के लिए संपूर्ण बातचीत का विश्लेषण करता है"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट के स्वर का विश्लेषण करता है"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक संघर्षण के संकेत। ग्राहक स्विच पर विचार कर रहा था या अलग होने का वादा किया था।"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह समूह जिससे बातचीत संबंधित है।"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट संदेश की सामग्री या पाठ"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में एजेंट के उत्तरों की संख्या"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की समीक्षा करने के लिए असाइन किया गया एजेंट"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल बातचीत सेकंड में कितने समय तक चली"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की सामग्री या पाठ"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के लिए इस्तेमाल किया जाने वाला संचार चैनल (उदा., ईमेल, चैट)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में जोड़ी गई टिप्पणियां"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब बातचीत में कोई टिप्पणी जोड़ी गई थी"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में सकारात्मक और नकारात्मक सेंटिमेंट का पता चला"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब ग्राहक ने ग्राहक संतुष्टि सर्वेक्षण का उत्तर दिया"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की समीक्षा तर्क-वितर्क वाली है या नहीं"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत शुरू करने के लिए ग्राहक द्वारा उपयोग किया जाने वाला चैनल"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में ग्राहकों की प्रतिक्रियाओं की संख्या"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में अंतिम संदेश का तारीख"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में शामिल उपयोगकर्ता की भूमिका या प्रकार"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट और ग्राहक के बीच चुप्पी सीमा से अधिक हो गई"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने उच्च स्तरीय सहायता मांगी"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक ने अत्यधिक आभार व्यक्त किया या प्राप्त समर्थन से बहुत संतुष्ट था"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या तो ग्राहक या एजेंट ने स्पष्ट रूप से फ़ॉलो-अप का अनुरोध किया"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्वनि द्वारा आयोजित पूरी बातचीत का पाठ या रिकॉर्ड"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत को तारांकित के रूप में चिह्नित किया गया है या नहीं"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामग्री को वर्गीकृत करने के लिए टिप्पणियों में उपयोग किए जाने वाले हैशटैग"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI द्वारा पता लगाई गई बातचीत की भाषा"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में अंतिम संदेश का तारीख"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में निजी नोट्स की संख्या"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में प्रति संदेश वर्णों की औसत संख्या"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में ग्राहकों के उत्तरों की संख्या"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक प्रतिभागी जिसकी जानकारी सार्वजनिक रूप से दिखाई देती है"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में प्रति संदेश शब्दों की औसत संख्या"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत को रेट करने या उसका मूल्यांकन करने के लिए उपयोग की जाने वाली श्रेणियाँ"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की समीक्षा प्रक्रिया की स्थिति"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में शामिल एक प्रतिभागी या व्यक्ति"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से जुड़ा एक ईमेल"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा देखी गई है या नहीं"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता जिसने बातचीत का आकलन किया"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख, जब बातचीत की अंतिम बार समीक्षा हो गई थी"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में एजेंट की समीक्षा की जा रही है"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के साथ उनकी संतुष्टि के बारे में ग्राहक द्वारा प्रदान की गई कोई विशिष्ट टिप्पणी या प्रतिक्रिया"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के लिए ग्राहक का संतुष्टि स्कोर"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में ग्राहक संतुष्टि स्कोर प्रति संदेश"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की समीक्षा करने के लिए उपयोग किया गया स्कोरकार्ड"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिए गए SLA का उल्लंघन किया गया है"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत या कनेक्शन का प्रकार जिससे बातचीत उत्पन्न हुई"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत असामान्य या विचित्र थी और संकल्प तक पहुंचने के लिए बहुत विचार-विमर्श थे"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत सामग्री से संबंधित सर्वेक्षण प्रश्न"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से संबंधित सर्वेक्षण का शीर्षक या नाम"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की समीक्षा करने के लिए असाइन किया गया एजेंट"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से जुड़ा ब्रांड या विशिष्ट उत्पाद/सेवा"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के लिए इस्तेमाल किया जाने वाला संचार चैनल (उदा., ईमेल, चैट)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब बातचीत बंद या हल की गई थी"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब बातचीत प्रारंभ में बनाई गई थी"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब बातचीत प्रारंभ में बनाई गई थी"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब ग्राहक ने ग्राहक संतुष्टि सर्वेक्षण का उत्तर दिया"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का संक्षिप्त विवरण या सारांश"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से लिंक की गई बाहरी संदर्भ ID"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ोल्डर या विशिष्ट समूहीकरण जिसमें बातचीत व्यवस्थित की जाती है"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की संरचना के लिए उपयोग किया जाने वाला फ़ॉर्म या टेम्पलेट"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से लिंक की गई बाहरी संदर्भ ID"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेलबॉक्स या ईमेल खाता जिसके माध्यम से बातचीत प्राप्त की गई थी"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत से संबंधित फ़ोन नंबर"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में असाइन किया गया प्राथमिकता स्तर"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में उत्तरों की कुल संख्या"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की स्थिति या वर्तमान स्थिति"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की वर्तमान स्थिति"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का विषय या प्रसंग"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों में एजेंट के उत्तरों की संख्या"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत को श्रेणीबद्ध करने के लिए असाइन किए गए टैग"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का प्रकार या श्रेणी"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब बातचीत अंतिम बार अपडेट की गई थी"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह तारीख जब बातचीत अंतिम बार अपडेट की गई थी"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वह माध्यम या चैनल जिसके माध्यम से बातचीत हुई"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में ट्रांसक्रिप्शन की सामग्री"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत जो AI विश्लेषण के माध्यम से समीक्षा के लिए अयोग्य हैं"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में भाग लेने वाले एजेंटों की संख्या"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत देखी गई है या नहीं"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई भी संदेश"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट संचार दक्षता"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट पुनरावृत्ति"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट जवाब गणना"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट प्रकार"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल दिशा"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्लाइंट संदेश"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूकावट/ब्रेक"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिकॉर्डिंग प्रकटीकरण अनुपलब्ध"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वृद्धि"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल ट्रांसक्रिप्ट सामग्री"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारांकित"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए अयोग्य"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके द्वारा समीक्षा हो गई"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके माध्यम से बातचीत"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तनी और व्याकरण"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट संदेश"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे सक्रिय एजेंट"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट द्वारा जवाबों की संख्या"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल अवधि (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की सामग्री"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश चैनल"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी टैग"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी की तारीख"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT सर्वेक्षण जवाब तारीख"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समानुभूति"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक चैनल"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक द्वारा जवाबों की संख्या"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जवाब की अंतिम तारीख"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता प्रकार"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक द्वारा सदस्यता रद्द करने का जोखिम"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूकावट/ब्रेक"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वृद्धि"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाधारण सेवा"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ॉलो-अप"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसक्रिप्ट"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी हैशटैग"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपवाद"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझ"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जवाब की अंतिम तारीख"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत निजी नोट गणना"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत वर्ण गणना"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत शब्द गणना"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सार्वजनिक जवाब गणना"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सार्वजनिक प्रतिभागी"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रेटेड तारीख"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन और टिप्पणी"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा की स्थिति"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पठनीयता"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संबंधित ईमेल"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संबंधित"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर गणना"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा प्राप्त हुई"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षित स्कोरबोर्ड"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा देखी गई"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा हो गई तारीख"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण टिप्पणी"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT प्रति संदेश"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संतुष्टि स्कोर (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाधान ऑफ़र किया गया"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत प्रकार"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विषय"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रश्न"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण शीर्षक"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का ब्रांड"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रांड डोमेन"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत चैनल"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद होने की तारीख"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाए जाने की तारीख"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाए जाने की तारीख"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT सर्वेक्षण जवाब तारीख"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT सर्वेक्षण भेजने की तारीख"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का विवरण"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत फ़ील्ड"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत फ़ोल्डर"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत फ़ॉर्म"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ्रेशडेस्क ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत समूह"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्काउट ID में मदद करें"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटरकॉम ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत मेलबॉक्स"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ोन नंबर"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की प्राथमिकता"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में जवाबों की संख्या"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की स्थिति"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की स्थिति"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का विषय"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट द्वारा जवाबों की संख्या"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत का प्रकार"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट किए जाने की तारीख"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट किए जाने की तारीख"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वर"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसक्रिप्शन सामग्री"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाग लेने वाले एजेंटों की संख्या"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देखी गई स्थिति"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेद्यता क्षमता"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेद्यता स्वास्थ्य"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेद्यता जीवन घटना"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA श्रेणी"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख-संबंधित स्थिति का चयन करें"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने फ़िल्टर में एक नाम जोड़ें"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर नाम भरें"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन हुआ व्यक्ति"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्व कार्यस्थान सदस्य"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान के सदस्य"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " जोड़ें"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर लागू करें"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके पास कम से कम 1 तारीख-संबंधित फ़िल्टर होना चाहिए"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विकल्प चुनें"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई विकल्प चुनें"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका ट्रायल ", _interpolate(_named("n")), " दिनों में समाप्त हो रहा है। ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका ट्रायल <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), " दिनों </strong></a>में समाप्त हो रहा है।"])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका ट्रायल ", _interpolate(_named("n")), " दिनों में समाप्त हो रहा है। ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका ट्रायल <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), " दिनों </strong></a>में समाप्त हो रहा है।"])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका ट्रायल आज समाप्त हो रहा है। ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका ट्रायल <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>आज</strong></a> समाप्त हो रहा है।"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी सदस्यता सेट करें"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " दिन"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपके ट्रायल में ", _interpolate(_named("days")), " दिन बचे हैं"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप अपनी योजना के लिए उपयोगकर्ता सीमा तक पहुँच गए हैं। अधिक जानने के लिए या अपनी योजना को अपग्रेड करने के लिए ", _interpolate(_named("link")), "।"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ क्लिक करें"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी सहायता डेस्क कनेक्ट करें"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह हमें आपके लिए समीक्षा करने हेतु आपके टिकट प्राप्त करने में सक्षम करेगा।"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी सहायता डेस्क कनेक्ट करें"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप नमूना डेटा के साथ Zendesk QA का उपयोग कर रहे हैं। नमूना डेटा हटाने और अपने स्वयं की बातचीतों को आयात करने के लिए, ", _interpolate(_named("link")), "।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नमूना डेटा"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगी जानकारी नीचे कॉपी करें"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदर्भ प्रदान करने के लिए पूरे पृष्ठ का स्क्रीनशॉट लें"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समस्या को हल करने के लिए हमारी ग्राहक सहायता टीम से संपर्क करें:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई गड़बड़ी हुई है"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि संदर्भ:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगी जानकारी को कॉपी करें"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता, बॉट और कार्यस्थान"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता और कार्यस्थान"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह महीना"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह सप्ताह"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " समीक्षा हो गई ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " समीक्षा हो गई ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "समीक्षा हो गई ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया क्या है"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन आउट करें"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कीबोर्ड शॉटकट्स"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमसे Chat करें"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारी चैट लोड नहीं की जा सकी (क्या आप शायद विज्ञापन-ब्लॉकर का उपयोग कर रहे हैं?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पर जाएँ..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समुदाय"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुखपृष्ठ"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई समीक्षाएँ"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त समीक्षाएँ"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जानकारी का आधार"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता स्विच करें"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आवेदन अपडेट किया गया था। ", _interpolate(_named("buttonStart")), "फिर से लोड करें", _interpolate(_named("buttonEnd")), "।"])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप अपने कार्यस्थान के हिस्से के रूप में Zendesk QA का उपयोग शुरू करना चाहते हैं:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बस आपको बता दें कि आपको पहले से ही एक कार्यस्थान के हिस्से के रूप में Zendesk QA का उपयोग करने का निमंत्रण मिला है। यदि आप चाहें, तो आप निश्चित रूप से जारी रख सकते हैं, लेकिन ध्यान दें कि यह आपके लिए एक ", _interpolate(_named("bold")), " बनाएगा।"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अलग संगठन"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हां, मुझे ", _interpolate(_named("link")), "के साथ जारी रहने दीजिए।"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शामिल होएं"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप ऐसा करना चाहते हैं?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " आपको शामिल होने के लिए कहता है ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए खाते में"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई इंटरनेट कनेक्शन नहीं"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पिन जोड़ें"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुरंत नेविगेशन"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी संपादक पर फ़ोकस करें और उसको विस्तृत करें"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइडपैनल छिपाएं या दिखाएं"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगली बातचीत पर नेविगेट करें"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल बातचीत खोलें"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछली बातचीत पर नेविगेट करें"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी श्रेणियों को उच्चतम संभव मूल्यांकन दें"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी श्रेणियों को न्यूनतम संभव मूल्यांकन दें"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में फेरबदल करें (बेतरतीब ढंग से फिर से सॉर्ट करें)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमें बताओ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कीबोर्ड शॉटकट्स"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शॉर्टकट मोडल टॉगल करें"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप ", _interpolate(_named("charLimit")), "-वर्ण सीमा तक पहुँच गए हैं"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["छवि ", _interpolate(_named("name")), " प्रकार (", _interpolate(_named("type")), ") की अनुमति नहीं है"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["छवि ", _interpolate(_named("name")), " का आकार बहुत बड़ा है (5MB की अनुमति है)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी संपादक लोड करने में विफल रहा"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपलोड करने के लिए ड्रॉप करें"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छवि"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक करें"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक करें"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट प्रबंधित करें"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टेम्पलेट नहीं जोड़ा गया"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण जोड़ें"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीछे"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाए गए कारणों सहित अन्य"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन का कारण"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रथम नाम"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम नाम"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कर्मचारियों की संख्या"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ोन नंबर"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हम आपका खाता सेट करने में आपकी सहायता करने के लिए संपर्क करेंगे"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनी का नाम"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनी संपर्क नंबर"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने पहली बार Zendesk QA के बारे में कैसे सुना?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक चैनल चुनें"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कई एजेंटों का चयन करें"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी आवश्यक फ़ील्ड भरें"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको हमारी शर्तों और हमारी गोपनीयता नीति को पढ़ने और अनुमति देने के लिए सहमत होना चाहिए।"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support द्वारा संचालित Slack चैनल"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विज्ञापन"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईवेंट"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोस्त या सहकर्मी"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क मार्केटप्लेस या अकाउंट मैनेजर"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रेफ़रिंग साइट"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज इंजन (Google, Big, आदि)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सोशल मीडिया"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमें अपने और अपनी कंपनी के बारे में बताएं।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते का विवरण"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं तैयार हूँ।"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आसपास घूमकर ऐप को जानें, चीजें हटाएं और जोड़ें।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाया गया। आगे क्या है?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी वास्तविक बातचीत लाने के लिए तैयार हैं?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना खुद का डेटा जोड़ें"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सप्लोर करना शुरू करें"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नमूना डेटा के साथ इसे आज़माएं"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसलिए हम आपके लिए समीक्षा करने के लिए आपके टिकट प्राप्त करना शुरू कर सकते हैं।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना सहायता डेस्क खाता कनेक्ट करें"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैंने अपना विचार बदल दिया।"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-अप रद्द करें"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["पिन बनाया गया। ", _interpolate(_named("buttonStart")), "पिन पर जाएं", _interpolate(_named("buttonEnd")), "।"])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोचिंग पिन Professional योजना पर उपलब्ध है।"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["योजना अपग्रेड करें"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी योजनाएं देखें"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगले 1: 1 या शिक्षा सत्र की तैयारी के लिए अपने नोट्स सहेजें।"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा के लिए पिन"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी करें या अन्य लोगों को ", "@", " के साथ जोड़ें"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह बातचीत हटा दी गई है"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया पिन बनाएं"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन बनाएं"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन संपादित करें"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन कैसे काम करते हैं"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको यह बातचीत देखने की अनुमति नहीं है"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन कार्ड"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत पिन करें"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन डैशबोर्ड"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन सहेजा गया"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्ड को पिन करें"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत को पिन करें"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस डैशबोर्ड को पिन करें"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता के अंतर्गत पिन करें"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी उपयोगकर्ता का चयन करें"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप केवल वे चीज़ें देख सकते हैं जिन्हें आप पिन करते हैं। दूसरों के साथ साझा करने के लिए टिप्पणियों में उनका ", "@", "उल्लेख करें।"])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " अब इस बातचीत के लिए आमंत्रित किया गया है"]), _normalize([_interpolate(_named("agentsNames")), " अब इस बातचीत के लिए आमंत्रित हैं"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 टिप्पणी"]), _normalize([_interpolate(_named("n")), " टिप्पणियाँ"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["आपने ", _interpolate(_named("participants")), " का उल्लेख किया है, लेकिन वे इस बातचीत में नहीं हैं।"]), _normalize(["आपने ", _interpolate(_named("participants")), " का उल्लेख किया है, लेकिन वे इस बातचीत में नहीं हैं।"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " प्रतिभागी"]), _normalize([_interpolate(_named("n")), " प्रतिभागी"]), _normalize([_interpolate(_named("n")), " प्रतिभागी"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["क्या आप इस पिन को एक्सेस करने के लिए ", _interpolate(_named("agentName")), " को हटाना चाहते हैं?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ ना करें"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन्हें इस बातचीत में आमंत्रित न करें"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी जोड़ें"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन्हें आमंत्रित करें"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन्हें इस बातचीत में आमंत्रित करें"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता निकालें"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["थ्रेड"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसे पूर्ववत नहीं किया जा सकता।"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इससे इस पिन के नीचे मौजूद सभी टिप्पणियां भी हट जाएँगी।"])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " नए संदेश"]), _normalize([_interpolate(_named("n")), " नया संदेश"]), _normalize([_interpolate(_named("n")), " नए संदेश"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल आप अपने पिन देख सकते हैं। पिन साझा करने के लिए, टिप्पणियों में किसी व्यक्ति को ", "@", " करें।"])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " पिन किया गया ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोट"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोचिंग पिन प्राप्त करने के लिए अपग्रेड करें"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोचिंग पिन Professional योजना पर उपलब्ध है।"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोचिंग पिन Professional योजना पर उपलब्ध है"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुविधा प्राप्त करने के लिए अपग्रेड करें"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने 1:1 की तैयारी करें या किसी अन्य समय के लिए कुछ बचाएँ।"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह पिन और सभी जोड़ी गई टिप्पणियों को स्थायी रूप से हटा देगा।"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन हटाएं"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन हटाएं"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ोल्डर का नाम"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाद में वापस आने के लिए चीज़ों को पिन करें"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जवाब दें"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे नया सबसे पहला"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे पुराना सबसे पहला"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने 1-1 की तैयारी में एक दिलचस्प डैशबोर्ड प्रवृत्ति या बातचीत सहेजें, या इसे किसी अन्य समय के लिए नोट करें।"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत या डैशबोर्ड दृश्य में ऊपर दिए गए बटन या पिन आइकन पर क्लिक करके पिन बनाएँ।"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई पिन नहीं बनाया गया"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " के लिए कोई परिणाम नहीं"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विभिन्न कीवर्ड का उपयोग करने या टाइपो की जांच करने का प्रयास करें।"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया पिन बनाएं"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोग"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता द्वारा खोजें, कीवर्ड..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्णानुक्रम"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाल ही में बनाया गया"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाल ही में संपादित किया गया"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकांश पिन"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["मेरा लक्ष्य ", _interpolate(_named("n")), " बातचीत ", _interpolate(_named("schedule")), " की समीक्षा करना है"]), _normalize(["मेरा लक्ष्य ", _interpolate(_named("n")), " बातचीत ", _interpolate(_named("schedule")), " की समीक्षा करना है"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य अपडेट किया गया"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य सेट करें"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप उन बातचीतों की संख्या के लिए एक व्यक्तिगत लक्ष्य निर्धारित कर सकते हैं, जिनकी आप समीक्षा करना चाहते हैं।"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति दिन"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर सप्ताह"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा लक्ष्य"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल लोड हो रहे हैं"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल का चयन करें"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल टिप्पणियों के साथ सर्वेक्षण प्रतिक्रिया साझा करें"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने सहकर्मियों को उनकी असाधारण सेवा गुणवत्ता और उत्कृष्ट ग्राहक प्रतिक्रिया को उजागर करके सशक्त बनाएं।"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack को सकारात्मक सर्वेक्षण प्रतिक्रिया साझा करें"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चैनल नहीं मिले"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपरोक्त स्कोर के साथ स्वचालित रूप से प्रतिक्रिया साझा करें"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praisebot एकीकरण"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आपने कोई समीक्षा पूर्ण नहीं की है तो सप्ताह की शुरुआत में एक रिमाइंडर प्राप्त करें।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिमाइंडर"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करने के लिए साप्ताहिक रिमाइंडर"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack में नई समीक्षाओं, उत्तरों, उल्लेखों और तर्क-वितर्कों के बारे में सूचनाएं प्राप्त करें।"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियाँ"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उल्लेख"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["थ्रेड जवाब"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack एकीकरण"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई समीक्षाओं, उत्तरों, उल्लेखों और तर्क-वितर्कों के बारे में सूचित करें।"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुझसे जुड़े तर्क-वितर्क"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियों में उल्लेख"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई प्राप्त समीक्षाएँ"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["थ्रेड में जवाब"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंकड़ों के साथ रिपोर्ट"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई और प्राप्त की गई समीक्षाओं के बारे में रिपोर्ट प्राप्त करें।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिपोर्ट"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर दिन"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साप्ताहिक"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर दिन"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर 3 घंटे"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर 6 घंटे"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तुरंत"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साप्ताहिक"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके खाता व्यवस्थापक ने सूचना सेटिंग लॉक कर दी हैं।"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि चुनें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत सूचनाएं"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (सक्रिय)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस खाते में एक नया सामाजिक साइन-इन जोड़ें:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक सक्रिय साइन-इन विधि को निकालने से आप साइन आउट हो जाएंगे। आप अपनी वैकल्पिक ईमेल से फिर से साइन इन कर सकते हैं।"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन विधि निकालें"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google को साइन-इन विकल्प के रूप में सफलतापूर्वक जोड़ा गया"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन विकल्प"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack को साइन-इन विकल्प के रूप में सफलतापूर्वक जोड़ा गया"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google जोड़ें"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack जोड़ें"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड बनाएँ"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप ऐसा करना चाहते हैं?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालें"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विधि"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड रीसेट करें"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका पासवर्ड रीसेट करने के लिए ", _interpolate(_named("email")), " को एक ईमेल भेजा जाएगा।"])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिक ईमेल"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारे पुष्टिकरण ईमेल के लिए अपना इनबॉक्स देखें"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["इसे बदलने के लिए, पहले अपने ", _interpolate(_named("link")), " को बदलें।"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["इस पासवर्ड के साथ ईमेल ", _interpolate(_named("email")), " संबद्ध होगी।"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल अपडेट की गई"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल फोटो"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वोत्तम परिणामों के लिए एक वर्गाकार फ़ोटो (256x256 पिक्सेल या अधिक) अपलोड करें"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिक ईमेल"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह की शुरुआत"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत सेटिंग"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम से कम एक विकल्प चुनें"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट सामग्री"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट नाम"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसमें टेम्पलेट दिखाएँ"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट सामग्री जोड़ें"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट नाम जोड़ें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट बनाएं"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट बनाएं"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आइटम की समीक्षा करने, पिन करने और शिक्षा में तेजी लाने के लिए टिप्पणी टेम्पलेट बनाएँ।"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसमें इस्तेमाल किया गया"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट हटाएँ"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस टेम्पलेट को स्थायी रूप से हटा दें?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट संपादित करें"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आइटम की समीक्षा करने, पिन करने और शिक्षा में तेजी लाने के लिए टिप्पणी टेम्पलेट बनाएँ।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टेम्पलेट नहीं बनाया गया"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी टेम्पलेट बनाया गया"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी टेम्पलेट हटाया गया"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी टेम्पलेट सहेजा गया"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा और तर्क-वितर्क"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["एक्सेस प्राप्त करने के लिए, ", _interpolate(_named("link")), " के साथ संपर्क करें।"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक सहायता"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाता नहीं मिला"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अभी के लिए, Zendesk QA तक पहुँचने के लिए ", _interpolate(_named("link")), " का इस्तेमाल करें।"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk पर वापस जाएं"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता माइग्रेट नहीं किया गया"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षिप्त विवरण"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैट्रिक"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आँकड़े"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर्स"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करें"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिया गया"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी टिप्पणियाँ"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे तर्क-वितर्क"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी समीक्षाएँ"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियाँ"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रियाएँ"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू किया"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रतिक्रिया"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड बनाएँ"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडेंशियल"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ई-मेल"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगइन करें"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट करें"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा लक्ष्य"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट बनाएं"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगइन करें"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलिंग"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनी"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकीकरण"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीटें"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई श्रेणी"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विरोधाभासी संदेश मिला"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परस्पर विरोधी संदेशों में से किसी एक को बदलें या निकालें"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA से संवेदनशील डेटा को बाहर करने के लिए ", _interpolate(_named("placeholder")), "आइकन का उपयोग करें। उदाहरण के लिए, \"ध्यान रखें, John\" के स्थान पर आप किसी भी नाम को दर्शाने के लिए \"ध्यान रखें, ", _interpolate(_named("placeholder")), "\" का इस्तेमाल कर सकते हैं।"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अधिकतम ", _interpolate(_named("limit")), " पंक्तियां"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसपर नियम लागू करें"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप 10 कस्टम श्रेणी की सीमा तक पहुँच गए हैं"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप ", _interpolate(_named("limit")), " कस्टम श्रेणी की सीमा तक पहुँच गए हैं"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति जोड़ें"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंक्ति जोड़ें"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति हटाएँ"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि एजेंट संदेश"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्यथा स्कोर"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर स्कोर करें"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड श्रेणी बनाएं"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक वेरिएबल जोड़ने के लिए '/' का उपयोग करें"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी दिशाएँ"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट संदेश रिक्त नहीं हो सकता"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश 300 वर्णों से अधिक नहीं हो सकता"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्रोत"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी चैनल"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी टैग"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी बातचीतें"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह निर्धारित करें कि आपका नियम कहाँ लागू होता है।"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट बातचीत"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट इनसाइट बनाएँ"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट संदेश रिक्त नहीं हो सकता"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप 10 कस्टम इनसाइट की सीमा तक पहुँच गए हैं"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप ", _interpolate(_named("limit")), " कस्टम इनसाइट की सीमा तक पहुँच गए हैं"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनसाइट का नाम भरें"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight का नाम"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी बनाएं"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनसाइट प्रकार"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तटस्थ"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सकारात्मक"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान सेटिंग्ज़ पर जाएँ"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी नहीं"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस मूल्यांकन श्रेणी का उपयोग प्रारंभ करने के लिए, अपनी कार्यस्थान स्कोरकार्ड सेटिंग्स पर जाएँ और इसे अपनी पसंद के स्कोरकार्ड पर जोड़ें।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी बनाई गई"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट कीवर्ड या वाक्यांशों की पहचान करके बंद की गई बातचीतों को स्वचालित रूप से स्कोर करें।"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्वनि ट्रांसक्रिप्शन पर काम नहीं करता है।"])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझ गए"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["दो ताली! ✋ आपने अपनी स्वचालित मूल्यांकन श्रेणियों को ", _interpolate(_named("limit")), " पर अधिकतम किया है। आप वर्गीकरण के जादूगर हैं! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी सीमा पूरी हो गई"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुनिश्चित करें कि सभी फ़ील्ड सही ढंग से भरे गए हैं"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी मूल्यांकन श्रेणी को कोई नाम दें"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी का नाम"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई मूल्यांकन श्रेणी"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई मूल्यांकन श्रेणी"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट कीवर्ड या वाक्यांशों की पहचान करके बंद की गई बातचीतों को स्वचालित रूप से स्कोर करें। ध्वनि ट्रांसक्रिप्शन पर काम नहीं करता है।"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट कीवर्ड या वाक्यांशों की पहचान करके बंद की गई बातचीतों को स्वचालित रूप से स्कोर करें। केवल ट्रांसक्रिप्शन पर काम करता है।"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट और बॉट संदेशों दोनों में विशिष्ट कीवर्ड या वाक्यांशों की पहचान करके स्वचालित रूप से बातचीत स्कोर करें।"])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस मूल्यांकन श्रेणी का उपयोग करने के लिए, अपनी कार्यस्थान स्कोरकार्ड सेटिंग्स पर जाएँ और इसे स्कोरकार्ड पर जोड़ें।"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेशों में विशिष्ट कीवर्ड या वाक्यांशों के आधार पर स्वचालित रूप से बातचीत स्कोर करें।"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनके लिए उपलब्ध"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनसाइट सेटअप"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह इनसाइट परिभाषित मानदंडों के आधार पर स्पॉटलाइट में प्रदर्शित होती है।"])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड सेटअप"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस श्रेणी का उपयोग निर्धारित मानदंडों के आधार पर स्कोरकार्ड पर किया जा सकता है।"])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैट"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जल्द ही आ रहा है"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्व-परिभाषित"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्वनि"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव सहेजें"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव सहेजे गए"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA के समाप्ति चेक के दौरान नाम, कंपनी विवरण या किसी अन्य संवेदनशील जानकारी को बाहर करने के लिए ", "{··}", " आइकन पर क्लिक करें।"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्ति जोड़ें"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्ति को संपादित करें"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्ति"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट जोड़ें"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट संपादित करें"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन जोड़ें"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन संपादित करें"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाइन ब्रेक द्वारा अलग किए गए शब्दों को यहाँ चिपकाएँ"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई प्रविष्टि के लिए प्रत्येक शब्द के बाद एंटर दबाएँ"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू भाषा"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण के लिए, \"ध्यान रखें, John\" के स्थान पर आप किसी भी नाम को दर्शाने के लिए \"ध्यान रखें ", "{··}", "\" का इस्तेमाल कर सकते हैं।"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिशील सामग्री प्लेसहोल्डर"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 छूट जोड़ी गई"]), _normalize([_interpolate(_named("count")), " छूट जोड़ी गई"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["छूट पहले ही जोड़ी जा चुकी है"]), _normalize(["पहले से ही जोड़ी गई छूट: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालना जोड़ें"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई छूट नहीं मिली"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई छूट नहीं जोड़ी गई"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट (केस-संवेदनशील)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["छूट जोड़ें"]), _normalize(["1 छूट जोड़ें"]), _normalize([_interpolate(_named("count")), " छूट जोड़ें"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई शब्द या वाक्यांश खोजें"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट सहेजी गई"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी भाषाएं"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा के लिए खोजें"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["समाप्ति पहले ही जोड़ी जा चुकी है"]), _normalize(["समाप्ति पहले ही जोड़ी जा चुकी है: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति जोड़ें"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुमोदित समाप्ति नहीं मिली"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुमोदित समाप्ति नहीं"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति जोड़ें"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति के लिए खोजें"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति जोड़ी गई"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति सहेजी गई"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["फ़ॉर्म में डुप्लिकेट प्रविष्टियां हैं: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["अभिवादन पहले ही जोड़ा जा चुका है"]), _normalize(["अभिवादन पहले ही जोड़ा जा चुका है: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन जोड़ें"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुमोदित अभिवादन नहीं मिला"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुमोदित अभिवादन नहीं"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन जोड़ें"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन के लिए खोजें"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन जोड़ा गया"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन सहेजा गया"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालना जोड़ें"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["छूट हटाएँ"]), _normalize(["छूट हटाएँ"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["इस छूट को स्थायी रूप से हटाएँ?"]), _normalize(["इन छूटों को स्थायी रूप से हटा दें?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट संपादित करें"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["एक या अधिक छूट ", _interpolate(_list(0)), " वर्णों से अधिक लंबी है"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " वर्ण"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट किया गया"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसके द्वारा अपडेट किया गया"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वेरिएबल"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने स्वयं के साइन-ऑफ़ को परिभाषित करें, जो आपके ब्रांड और सेवा मानकों के अनुरूप हों। जोड़े जाने पर, AutoQA केवल स्वीकृत क्लोजिंग का पता लगाएगा। किए गए परिवर्तन भविष्य की बातचीत पर लागू होते हैं।"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप 100 स्वीकृत समाप्तियों की शीर्ष सीमा तक पहुँच गए हैं। यदि आप और जोड़ना चाहते हैं, तो यह आपकी मौजूदा समाप्तियों को फिर से देखने और परिष्कृत करने का समय हो सकता है।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्ति सीमा पूरी हो गई है"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्तियां"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तनी या व्याकरण की गलतियों के रूप में अनदेखा करने के लिए जिन शब्दों और वाक्यांशों के लिए आप AutoQA करना चाहते हैं। किए गए परिवर्तन भविष्य की बातचीत पर लागू होते हैं।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूची पर ध्यान न दें"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने स्वयं के अभिवादन निर्दिष्ट करें जो आपके ब्रांड और सेवा Standard के साथ संरेखित हों। जोड़े जाने पर, AutoQA केवल स्वीकृत अभिवादन का पता लगाएगा। किए गए परिवर्तन भविष्य की बातचीत पर लागू होते हैं।"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप 100 स्वीकृत अभिवादन की शीर्ष सीमा तक पहुँच गए हैं। यदि आप और अधिक जोड़ना चाहते हैं, तो यह आपके मौजूदा अभिवादन को फिर से देखने और परिष्कृत करने का समय हो सकता है।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन सीमा पूरी हो गई"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी नहीं"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी हटाएँ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या इस मूल्यांकन श्रेणी को स्थायी रूप से हटाना चाहते हैं?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियों की सूची टॉगल करें"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाई गई श्रेणी किसी भी नई बातचीत और फ़िल्टर पर दिखाई नहीं देगी। डैशबोर्ड और बातचीतों पर ऐतिहासिक डेटा बरकरार रहता है।"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी को उन सभी स्कोरकार्ड से हटा दिया जाएगा जिनमें इसे जोड़ा गया है।"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड श्रेणी हटाने में विफल"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड श्रेणी हटाई गई"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड श्रेणी हटाएँ"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA श्रेणियाँ"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AI मॉडल द्वारा संचालित ", _interpolate(_named("highlight")), " स्कोरकार्ड पर रहेगा लेकिन अब स्वचालित रूप से स्कोर नहीं किया जाएगा"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM एकीकरण को बंद करने से AutoQA सुविधा पूरी तरह से बंद नहीं होती है। यहाँ बताया गया है कि क्या होगा:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐतिहासिक डेटा"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " AutoQA डैशबोर्ड पर रहेगा"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैन्युअल रूप से जोड़े गए मूल कारण"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " रहेगा, लेकिन सभी LLM-आधारित मूल कारणों को हटा दिया जाएगा"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-आधारित AutoQA बंद करें"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA विशिष्ट श्रेणियों को स्वचालित रूप से स्कोर करने के लिए AI और बड़े भाषा मॉडल (LLMs) का इस्तेमाल करता है। आपका डेटा एक सख्त डेटा प्रोसेसिंग एग्रीमेंट (डीपीए) के तहत संसाधित किया जाता है और इसका इस्तेमाल कभी भी किसी भी मॉडल को प्रशिक्षित करने के लिए नहीं किया जाता है।"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जनरेटिव AI के बारे में जानें"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA ", _interpolate(_named("automatically_scoring_categories")), " के लिए Microsoft Azure OpenAI सेवा का उपयोग करता है। आपका डेटा केवल आपको और ", _interpolate(_named("never_used_for_training_models")), " को AutoQA प्रदान करने के लिए एक सख्त DPA के तहत संसाधित किया जाता है।"])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित रूप से कुछ श्रेणियों को स्कोर करना"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी भी OpenAI मॉडल को प्रशिक्षित करने के लिए इसका कभी भी उपयोग नहीं किया जाता है"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-आधारित AutoQA चालू हो गया"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-आधारित AutoQA बंद हो गया"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-आधारित AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA में AI के साथ ऑटोमेटेड स्कोरिंग"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure आधारित AutoQA श्रेणियों की डेटा प्रोसेसिंग"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure सहमति वापस ली गई"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure सहमति दी गई है। बातचीत को जल्द ही स्वचालित मूल्यांकन प्राप्त होगा।"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप अभी भी AutoQA का उपयोग कर सकते हैं, लेकिन यहां क्या होगा"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI सेवा को अक्षम करने से AutoQA कार्यक्षमता के रूप में बंद नहीं होगा।"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप स्कोरकार्ड श्रेणी दृश्य में ऐसा कर सकते हैं।"])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मॉडल द्वारा संचालित AutoQA श्रेणियां स्कोरकार्ड पर रहेंगी लेकिन स्वचालित रूप से स्कोर नहीं की जाएंगी।"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा मैन्युअल रूप से जोड़े गए मूल कारण बने रहेंगे। सभी मॉडल-जनित मूल कारणों को हटा दिया जाएगा।"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐतिहासिक डेटा AutoQA डैशबोर्ड पर रहता है।"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI सेवा द्वारा डेटा प्रोसेसिंग अक्षम करें"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA श्रेणियों की विस्तृत सीमा।"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आउट-ऑफ़-द-बॉक्स बहुभाषी समर्थन।"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई श्रेणियों का तेजी से जुड़ना।"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेहतर मूल्यांकन सटीकता। GPT-3.5 मानव-स्तरीय प्रासंगिक जागरूकता दिखाता है।"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI प्रौद्योगिकी में नवीनतम प्रगति से लाभ।"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ आपको क्या मिलेगा"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI सेवा द्वारा डेटा प्रोसेसिंग सक्षम करें"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई सहमति"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सहमति नहीं दी गई"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी नए उपयोगकर्ताओं को यह कार्यस्थान असाइन किया गया है"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिफ़ॉल्ट कार्यस्थान"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 घंटे का समय (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 घंटे का समय (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय का फ़ॉर्मेट"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सोम"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रवि"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनी प्रोफ़ाइल अपडेट की गई"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह की शुरुआत"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम एकीकरण"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहुँच कुंजी ID और गुप्त कुंजी आमतौर पर पहुँच प्रबंधन > उपयोगकर्ताओं के अंतर्गत पाए जाते हैं"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS पहुँच कुंजी ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जैसे AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect जोड़ें"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त पहुँच कुंजी परिवर्तित करना रद्द करें"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त पहुँच कुंजी मान परिवर्तित करें"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन केवल एक क्षेत्र को परिभाषित करने की अनुमति देता है जो आमतौर पर स्थान के तहत पाया जाता है"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS क्षेत्र"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जैसे eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई गुप्त कुंजी जोड़ें"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS गुप्त पहुँच कुंजी"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जैसे wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप Amazon Connect में सभी प्रासंगिक जानकारी पा सकते हैं।"])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमतौर पर Amazon Connect इंस्टेंस सेटिंग्स और डेटा स्टोरेज पेज के तहत पाया जाता है"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल रिकॉर्डिंग का संग्रहण स्थान"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जैसे amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect जोड़ते समय कोई त्रुटि हुई है। जाँचें कि क्या सभी फ़ील्ड्स सही तरीके से भरी गई हैं और पुनः प्रयास करें।"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण स्थान एक स्लैश द्वारा अलग की गई बकेट/फ़ोल्डर के प्रारूप में होना चाहिए।"])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कनेक्शन जोड़ने के लिए, ", _interpolate(_named("link")), " से संपर्क करें।"])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक सहायता"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके सभी Zendesk टिकट स्वचालित रूप से सिंक हो जाएंगे। आप चयनित टिकटों को बाहर करना चुन सकते हैं।"])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एशिया पैसिफ़िक (टोक्यो)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एशिया पैसिफ़िक (सियोल)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एशिया पैसिफ़िक (ओसाका)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एशिया पैसिफ़िक (मुंबई)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एशिया पैसिफ़िक (सिडनी)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनाडा (सेंट्रल)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूरोप (फ्रैंकफर्ट)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूरोप (ज्यूरिख)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूरोप (आयरलैंड)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूरोप (लंदन)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर क्षेत्र"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य पूर्व (यूएई)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर क्षेत्र का चयन करें"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दक्षिण अमेरिका (साओ पाउलो)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूएस ईस्ट (वर्जीनिया)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूएस ईस्ट 2 (ओहियो)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पश्चिमी अमेरिका (ऑरेगोन)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन पहले ही जोड़ा जा चुका है"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन प्रारंभ करें"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल उन बातचीतों को सिंक करें जो"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल एक, चैनल दो"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["इस कनेक्शन की यूनिक ID ", _interpolate(_named("tokenId")), " है"])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 महीना"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 वर्ष"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 महीने"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 महीने"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय बातचीत चयनित अवधारण अवधि के बाद हटा दिए जाएँगे। भेजी गई समीक्षाएँ गतिविधि और डैशबोर्ड दृश्यों में दिखाई देती रहती हैं।"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधारण अवधि"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधारण अवधि का चयन करें"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान दें कि यह क्रिया अपरिवर्तनीय है।"])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं समझता हूं कि यह Zendesk QA से इस कनेक्शन के लिए सभी सहायता डेस्क से संबंधित डेटा को हटा देगा, जिसमें बातचीत, फ़ील्ड और टैग शामिल हैं।"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजी गई समीक्षाएं प्रभावित नहीं होंगी और विशिष्ट दृश्यों में दिखाई देती रहेंगी।"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह एक, समूह दो"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसमें शामिल है"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय विकास"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यह एकीकरण अभी भी ", _interpolate(_named("activeDevelopment")), " में है। कुछ विवरण अनुपलब्ध हो सकते हैं (जैसे अनुलग्नक या कस्टम फ़ील्ड)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेलबॉक्स एक, मेलबॉक्स दो"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग एक, टैग दो"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API टोकन"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API टोकन गोपनीय"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स → टिकट फ़ील्ड के तहत फ्रेशडेस्क पर \"Ticket Field Names\" ढूंढें"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings→ Custom fields के तहत Help Scout पर \"Custom Field Names\" खोजें"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स → Klasses → बातचीत के तहत Kustomer पर \"Klass बातचीत नाम\" खोजें"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स → कस्टम फ़ील्ड के तहत लाइव एजेंट पर \"कस्टम फ़ील्ड नाम\" ढूंढें"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings → Custom fields के तहत Wix पर \"Custom Field Name\" खोजें"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड ID को अल्पविराम से अलग करें। आप इन ID को Zendesk में व्यवस्थापक → टिकट फ़ील्ड के तहत पा सकते हैं।"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी पहली बातचीत की समीक्षा करने के लिए अपने कुछ सहायता डेस्क उपयोगकर्ताओं को कनेक्ट करें। चिंता न करें, हम उन्हें तब तक सूचित नहीं करेंगे जब तक आप उन्हें कार्यस्थान सदस्य बनने के लिए आमंत्रित नहीं करते।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलतापूर्वक एकीकृत"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य जोड़ें"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अब हम आपकी बातचीत को खींचना शुरू करेंगे।"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["डोमेन: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["सहायता डेस्क पहचानकर्ता: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके कनेक्शन को प्रमाणित नहीं किया जा सका"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब तैयार है। यह आपका नया कनेक्शन है।"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ कनेक्शन अपडेट नहीं किए गए हैं या निष्क्रिय हो गए हैं। इन कनेक्शनों को अपडेट करने या उन्हें अपनी सूची से निकालने का प्रयास करें।"])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API क्लाइंट आईडी"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API क्लाइंट गोपनीय"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सेटिंग्स → खाता → API एवं SDK से नया API टोकन बनाएं। उपयोग करने के लिए रीडायरेक्ट URL है ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आरंभ करने के लिए अपनी सहायता डेस्क का चयन करें"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आपका इंटरकॉम खाता यूरोपीय संघ या ऑस्ट्रेलिया से सेवा प्राप्त करने के लिए सेट किया गया है, तो इसे अपडेट करें।"])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["वह ईमेल जिसका उपयोग आप साइन इन करने के लिए करते हैं ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम उपयोगकर्ता का नाम, ईमेल और फ़ोन नंबर बताएँ। सभी सहायता डेस्क-कनेक्शनों के लिए बैंक क्रेडेंशियल्स पहले से ही छिपाए हुए हैं।"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई एकीकरण नहीं। अभी एक जोड़ें"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको इन भूमिकाओं की आवश्यकता है:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन बनाएँ"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नया कनेक्शन जोड़ें"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसे अनचेक करने से आप इस कनेक्शन को केवल विशिष्ट कार्यस्थानों में मैन्युअल रूप से जोड़ सकते हैं"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मौजूदा कार्यस्थानों से कनेक्ट करें"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API कुंजी"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API कुंजी गोपनीय"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["कनेक्शन ", _interpolate(_list(0)), " हटा दिया गया"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन का नाम"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समय बनाया गया"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप नीचे अपना API टोकन ले सकते हैं।"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT थ्रेशोल्ड"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कनेक्शन हटाएँ ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कनेक्शन को हटाने के लिए \"कनेक्शन हटाएं\" टाइप करें"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन हटाएँ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने कनेक्ट किए गए डेटा स्रोतों को प्रबंधित और मॉनिटर करें।"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन संपादित करें"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालें"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अल्पविराम के साथ फ़ील्ड के नाम अलग करें"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट नहीं किया जा सका"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने एकीकरण को एक अद्वितीय नाम दें"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced विकल्प छुपाएं"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संवेदनशील डेटा फ़ील्ड छुपाएँ"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुलग्नक URL कभी संग्रहीत न करें"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुलग्नकों पर ध्यान न दें"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत सामग्री को कभी भी संग्रहीत न करें (आप बाद में अपने सहायता डेस्क से इसे मांग पर खींच सकते हैं)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत की सामग्री पर ध्यान न दें"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत विषय को कभी भी संग्रहीत न करें (आप बाद में अपने सहायता डेस्क से इसे मांग पर खींच सकते हैं)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विषय लाइन पर ध्यान न दें"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["आपका ", _interpolate(_list(0)), " उप डोमेन"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटरकॉम क्षेत्र"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनबॉक्स टीम ID का मिलान करें"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी इंटरकॉम टीम की संख्यात्मक ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रगति में है..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेलबॉक्स"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैन्युअल रूप से कनेक्शन अपडेट करें"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक डेटा छिपाएं"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संवेदनशील फ़ील्ड नाम"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संवेदनशील फ़ील्ड ID"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन नवीनीकृत करें"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन नवीनीकृत करें"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce सैंडबॉक्स से कनेक्ट करें"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " के साथ एक कनेक्शन स्थापित करना"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced विकल्प दिखाएं"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सेट की गई सीमा से निम्न या उसके बराबर स्कोर के साथ बातचीत खींचें"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT सीमा लागू करें"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल उन बातचीतों को सिंक करें जो..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नवीनतम बातचीतें प्राप्त करें"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन अपडेट करें"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन अपडेट किया गया"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता नाम"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका सहायता डेस्क"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अपनी सदस्यता रद्द करने और अपना खाता हटाने के लिए, ", _interpolate(_named("link")), " को संपर्क करें।"])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक सहायता"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता हटाएं"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Support (या किसी अन्य सहायता डेस्क) में टिकट हटाने से संबंधित बातचीत समीक्षाओं पर कोई प्रभाव नहीं पड़ता है। आप अभी भी उन्हें देख और प्रबंधित कर सकते हैं।"])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA में टिकट हटाने से संबंधित बातचीत समीक्षाएं भी हट जाती हैं।", _interpolate(_named("line_break")), "Zendesk Support (या किसी अन्य सहायता डेस्क) में टिकट हटाने से संबंधित बातचीत समीक्षाओं पर कोई प्रभाव नहीं पड़ता है। आप अभी भी उन्हें देख और प्रबंधित कर सकते हैं।"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएं प्रबंधित करें"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाओं को हटाना स्थायी है। यह डैशबोर्ड पर डेटा को भी प्रभावित करता है।"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समीक्षाएँ हटाएँ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी हटाएँ"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समीक्षाएँ हटा दी गईं"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी हटाएँ"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब हटाए गए टिकटों से जुड़ी समीक्षाएं होंगी, तो वे आपको यहां दिखाई देंगी।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई समीक्षा नहीं"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकट ID खोजें"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["टिकट ID: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाई गई बातचीतें"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["डिफ़ॉल्ट कार्यस्थान हटाए नहीं जा सकते। इस कार्यस्थान को हटाने के लिए, ", _interpolate(_named("link")), " में किसी अन्य डिफ़ॉल्ट कार्यस्थान का चयन करें।"])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते की सेटिंग"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस क्रिया को पूर्ववत् नहीं किया जा सकता है।"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी डेटा निकालें और हटाएं"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["मैं समझता हूं कि यह कार्यस्थान उपयोगकर्ताओं और कनेक्शन सहित इस कार्यस्थान से ", _interpolate(_named("removeAllData")), " संबंधित होगा।"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान हटाएँ"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान को हटाने के लिए \"कार्यक्षेत्र हटाएं\" लिखें"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कार्यस्थान हटाएँ ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान हटाएँ"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुनिश्चित करें कि आप क्या कर रहे हैं या पहले से किसी पेशेवर से परामर्श करें।"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयन करें कि डिफ़ॉल्ट समीक्षादाता का चयन सबसे अधिक आवृत्ति वाले एजेंट या वर्तमान असाइनी के आधार पर किया गया है या नहीं।"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे सक्रिय एजेंट"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिफ़ॉल्ट समीक्षादाता"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगों को अपने स्वयं की बातचीतों की समीक्षा करने की अनुमति दें और एजेंटों को समीक्षा करने में सक्षम करें"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान हटा दिया गया है"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान अपडेट किया गया है"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य समीक्षाकर्ताओं द्वारा पहले दी गई समीक्षाओं को छिपाकर तटस्थ ग्रेडिंग स्थापित करें"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्पक्ष ग्रेडिंग"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रंग"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस क्रिया को पूर्ववत् नहीं किया जा सकता है।"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान दें कि यह कार्यस्थान उपयोगकर्ताओं और कनेक्शनों सहित इस कार्यस्थान से संबंधित सभी डेटा को निकाल देगा और हटा देगा।"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान हटाएँ"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान हटाया जा रहा है..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैजिक लिंक"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी वर्तमान साइन-इन पद्धति को निष्क्रिय नहीं कर सकता"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण विधियों को अपडेट किया गया है"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी उपयोगकर्ताओं के लिए कुछ प्रकार की प्रमाणीकरण विधियों की अनुमति दें।"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता और कार्यस्थान"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान बनाएँ और प्रबंधित करें और उपयोगकर्ताओं को आमंत्रित या कनेक्ट करें।"])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेज पर बने रहें"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेज छोड़ें"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या इस पेज को छोड़कर सभी परिवर्तन खोना चाहते हैं?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लगता है कि आयात करने के लिए कोई बातचीत नहीं है। अपनी सहायता डेस्क के साथ कुछ बातचीत करके शुरू करें, फिर उनका विश्लेषण करने के लिए यहां लौटें।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बातचीत नहीं मिली"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप इस सिस्टम श्रेणी को हटा नहीं सकते"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी हटाएँ"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियों को हटाना स्थायी है। यह रिपोर्टिंग से सभी श्रेणी डेटा मिटा देगा।"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस श्रेणी को फिर से स्टोर करने के लिए, आपको इसे फिर से बनाना होगा।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी हटाएँ"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी खोजें"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑटो श्रेणियाँ स्कोरकार्ड को सुझाई गई रेटिंग से स्वचालित रूप से भर देती हैं ताकि समीक्षा करने में लगने वाला समय बच सके। मैनुअल श्रेणियाँ समीक्षाकर्ताओं द्वारा शुरू से ही भरी जाती हैं।"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला अपडेट"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑटो"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेन्युअल"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समानुभूति"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तनी और व्याकरण"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझ"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पठनीयता"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाधान ऑफ़र किया गया"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वर"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई श्रेणियाँ नहीं मिलीं"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मूल कारण नहीं मिला"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्कोरकार्ड नहीं मिला"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई दूसरा खोज करने या फ़िल्टर बदलने की कोशिश करें"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " श्रेणियाँ"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " श्रेणियाँ"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " श्रेणियाँ"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी श्रेणियाँ"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " श्रेणियाँ"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्कोरकार्ड"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्कोरकार्ड"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्कोरकार्ड"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्कोरकार्ड"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्कोरकार्ड"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्थितियां"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्थितियां"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्थितियां"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्थितियां"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " स्थितियां"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " प्रकार"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " प्रकार"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " प्रकार"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी प्रकार"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " प्रकार"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " कार्यस्थान"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " कार्यस्थान"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं मिला"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " कार्यस्थान"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " कार्यस्थान"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), " वजन समग्र स्कोर में ", _interpolate(_named("percentage")), "% का योगदान देता है"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड हटाएँ"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड हटाना स्थायी है। यह रिपोर्टिंग से सभी स्कोरकार्ड डेटा मिटा देगा।"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस स्कोरकार्ड को फिर से स्टोर करने के लिए, आपको इसे फिर से बनाने की आवश्यकता होगी।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड हटाएँ"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला अपडेट"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["रेटिंग स्केल अपडेट किया गया। ", _interpolate(_named("buttonStart")), "समीक्षा शुरू करें", _interpolate(_named("buttonEnd")), "।"])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA डिफ़ॉल्ट रूप से एक बाइनरी पैमाना सेट करता है, लेकिन आप अधिक गहन जानकारी वाले पैमाने का विकल्प भी चुन सकते हैं:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यदि आप श्रेणी के अनुसार कॉन्फ़िगर किए गए विभिन्न मूल्यांकन पैमाना विकल्प देखना चाहते हैं, तो अपने ", _interpolate(_named("link")), " पर जाएं"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहानुभूति/स्वर"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पाद जानकारी"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन पैमानों को अपडेट करके:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका पिछला मूल्यांकन पैमाना और संबंधित श्रेणियां संग्रहीत की जाएंगी (हटाई नहीं जाएंगी)।"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ डैशबोर्ड डेटा प्रभावित होंगे क्योंकि आपका पुराना और नया पैमाना तुलनीय नहीं हो सकता है।"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी मौजूदा मूल्यांकन श्रेणियों को नए पैमाने पर क्लोन करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई मूल्यांकन पैमाने को अपडेट करना चाहते हैं?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप स्कोरकार्ड डिस्प्ले फ़ॉर्मेट (संख्या या इमोजी) को बदल सकते हैं और श्रेणियों को अनिवार्य या वैकल्पिक बना सकते हैं।"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्याओं के बजाय इमोजी का उपयोग करें"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगों को श्रेणियां छोड़ने की अनुमति दें"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाधान"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन पैमाना प्रकार"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 कार्यस्थान के लिए श्रेणियां क्लोन की गई हैं"]), _normalize([_interpolate(_named("workspacesAmount")), " कार्यस्थानों के लिए श्रेणियाँ क्लोन की गई हैं"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान सेटिंग"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यदि ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित रूप से होने वाली कॉलें जहां एजेंट ग्राहक को बातचीत में चुप्पी की उम्मीद करने के लिए संकेत नहीं देता है।"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत में निर्धारित सीमा से अधिक समय तक चलने वाली रूकावट/ब्रेक होते हैं।"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल में अधिकतम रूकावट/ब्रेक की सीमा को परिभाषित करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूकावट/ब्रेक"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाई गई जानकारी किसी भी नई बातचीत और फ़िल्टर पर दिखाई नहीं देगी। डैशबोर्ड और बातचीतों पर ऐतिहासिक डेटा बरकरार रहता है।"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट इनसाइट को हटाने में विफल"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट इनसाइट हटा दी गई"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट इनसाइट हटाएँ"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसक्रिप्ट और संदेशों में विशिष्ट शब्दों या वाक्यांशों वाली बातचीतें देखें।"])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनिवार्य प्रकटीकरण कथन की अनुपस्थिति वाले कॉल की स्वचालित रूप से पहचान करें, जैसे 'यह कॉल रिकॉर्ड की जाएगी' और इसी तरह के अन्य कथन।"])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसका उद्देश्य बड़ी भाषा मॉडल (एलएलएम) का उपयोग करके कॉल में एक रिकॉर्डिंग प्रकटीकरण कथन की पहचान करना है जो संदर्भ को गतिशील रूप से समझता है, पूर्व निर्धारित वाक्यांशों की आवश्यकता को समाप्त करता है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिकॉर्डिंग प्रकटीकरण अनुपलब्ध"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित रूप से होने वाली कॉलें जहां एजेंट ग्राहक को बातचीत में चुप्पी की उम्मीद करने के लिए संकेत नहीं देता है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूकावट/ब्रेक"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल में रूकावट/ब्रेक क्षणों को निर्दिष्ट करें"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अपनी सदस्यता में परिवर्तन करने की आवश्यकता है? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") और हमें सहायता करने में खुशी होगी।"])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंटों, BPO, चैनलों और भाषाओं में 100% बातचीतों का स्वचालित रूप से विश्लेषण करें। AI सेवा में सुधार के लिए मुद्दों, जानकारी में अंतराल और शिक्षा के अवसरों का पता लगाता है।"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100% कवरेज"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट प्रतिक्रिया और शिक्षा"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट AI insights"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced रिपोर्टिंग और डैशबोर्ड"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट विश्लेषण और फ़िल्टरिंग"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑन-डिमांड अनुवाद"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट और लक्ष्य निर्धारण की समीक्षा करें"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा और प्रश्नोत्तरी"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk गुणवत्ता का आश्वासन (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेशेवर पर सब कुछ, प्लस"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (पुराना प्लान)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण विफलताओं की तत्काल अधिसूचना के लिए वेबहुक"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा वेयरहाउस एकीकरण"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce सेवा क्लाउड एकीकरण"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम एकीकरण विकास"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समर्पित ग्राहक सफलता प्रबंधक"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack के माध्यम से हमारे उत्पाद और इंजीनियरिंग टीमों तक सीधी पहुंच"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वृद्धि Suite में सब कुछ, प्लस"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% कवरेज के लिए AutoQA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा की समझ बनाने के लिए AI-संचालित बातचीत insights"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-संवर्धित सहायता डेस्क डेटा संवर्द्धन और फ़िल्टरिंग"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट: समीक्षा की जाने वाली इंटरेक्शन के लिए स्वचालित पहचान"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट विश्लेषण और फ़िल्टरिंग"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-संचालित प्रदर्शन रिपोर्टिंग और insights"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध पर बातचीत अनुवाद"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय ट्रैकिंग की समीक्षा करें"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पास दर विश्लेषण"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite में सब कुछ, प्लस"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटरप्राइज़ Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce और Genesys एकीकरण सहित एंटरप्राइज़ कार्यान्वयन पैकेज"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम एकीकरण विकास"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा वेयरहाउस एकीकरण"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण विफलताओं की तत्काल अधिसूचना के लिए वेबहुक"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समर्पित ग्राहक सफलता प्रबंधक"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारे उत्पाद और इंजीनियरिंग टीमों तक सीधी पहुंच"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारे रोडमैप पर प्रारंभिक पहुंच और प्रभाव"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वृद्धि Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित समीक्षा असाइनमेंट और लक्ष्य सेटिंग"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्षित शिक्षा और विश्लेषण"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीखने के प्रबंधन के लिए प्रश्नोत्तरी"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता भूमिका और अनुमति प्रबंधन"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संवेदनशील डेटा गोपनीयता"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced फ़िल्टरिंग विकल्प और बातचीत खोज"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकाधिक मूल्यांकन, समायोज्य भार और स्वतः-विफल विकल्पों के साथ अनुकूलन योग्य स्कोरकार्ड"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कहीं भी बातचीत की समीक्षा करने के लिए ब्राउज़र एक्सटेंशन"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण विश्लेषण"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंटों, टीमों, BPO की तुलना करने के लिए प्रदर्शन रिपोर्टिंग"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT सर्वेक्षण और ग्राहक प्रतिक्रिया विश्लेषण"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुविधाएं"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter पर सब कुछ, प्लस"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेशेवर (पुराना प्लान)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति बातचीत एकाधिक मूल्यांकन पैमाना"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack एकीकरण"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-संचालित इंटरेक्टिव बातचीत insights"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट की समीक्षा करें"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोचिंग के लिए पिन"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षा सत्र"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेंटिमेंट विश्लेषण और फ़िल्टरिंग"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत जटिलता के आधार पर फ़िल्टरिंग"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत insights"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सशर्त स्कोरकार्ड"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण विश्लेषण"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एडवांस्‍ड AI-संचालित CSAT विश्लेषिकी"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्रेडिंग कैलिब्रेशन/जांच करना"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कहीं भी बातचीत की समीक्षा करने के लिए ब्राउज़र एक्सटेंशन"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों की समीक्षा करने के लिए स्पॉटलाइट"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध पर बातचीत अनुवाद"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय ट्रैकिंग की समीक्षा करें"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम योजना"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टार्टर (पुराना प्लान)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असीमित बातचीत समीक्षा"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV निर्यात"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीयर-टू-पीयर और स्व-समीक्षा"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समायोज्य वजन और ऑटो-फेल विकल्पों के साथ स्कोरकार्ड"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मजबूत फ़िल्टरिंग विकल्प"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दैनिक, साप्ताहिक या मासिक समीक्षा लक्ष्य"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT सर्वेक्षण और बुनियादी विश्लेषण"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंटों, टीमों, BPO की तुलना करने के लिए रिपोर्ट"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता भूमिका और विशेषाधिकार प्रबंधन"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुविधाएं"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं अपनी Zendesk QA सदस्यता में बदलाव करना चाहता हूं।\n\nएक विकल्प को हाइलाइट या इंगित करें और उन सीटों की संख्या इंगित करें जिन्हें आप बदलना चाहते हैं:\nमैं [X सीटें जोड़ें] [X सीटें हटाएं] [Zendesk QA योजना में अपग्रेड करना चाहता हूं]\n\nकृपया अपनी कंपनी का नाम और अपने Zendesk QA खाते से जुड़े नाम के साथ-साथ इस परिवर्तन को संसाधित करने के लिए हमें जो भी अन्य विवरण पता होने चाहिए, उसे साझा करें।"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA सदस्यता परिवर्तन"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं Zendesk QA सदस्यता खरीदना चाह रहा हूं।\n\nकृपया अपनी कंपनी का नाम और अपने Zendesk QA खाते से जुड़ा नाम, साथ ही इस अनुरोध को संसाधित करने के लिए हमें जो भी अन्य विवरण पता होने चाहिए उसे साझा करें:\n\nकृपया कोई विशिष्ट प्रश्न भी साझा करें जिसे आप खरीदने से पहले संबोधित करना चाहते हैं:\n\nधन्यवाद!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA सदस्यता खरीद"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपकी सदस्यता ", _interpolate(_named("date")), " पर समाप्त होती है (", _interpolate(_named("days")), " बाएं)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपका ट्रायल ", _interpolate(_named("date")), "(", _interpolate(_named("days")), " बाएं) को समाप्त हो रहा है"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 दिन"]), _normalize([_interpolate(_named("n")), " दिन"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हर महीने की ", _interpolate(_named("dayOfMonth")), " तारीख को ", _interpolate(_named("card")), " को बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हर महीने की ", _interpolate(_named("dayOfMonth")), " तारीख को ", _interpolate(_named("card")), " को बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हर महीने की ", _interpolate(_named("dayOfMonth")), " तारीख को ", _interpolate(_named("card")), " को बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हर महीने की ", _interpolate(_named("dayOfMonth")), " तारीख को ", _interpolate(_named("card")), " को बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हर महीने की ", _interpolate(_named("dayOfMonth")), " तारीख को ", _interpolate(_named("card")), " को बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हर महीने की ", _interpolate(_named("dayOfMonth")), " तारीख को ", _interpolate(_named("card")), " को बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("billingDate")), " को ", _interpolate(_named("card")), " के लिए बिल (", _interpolate(_named("expires")), " को समाप्त हो रहा है) भेजा जाता है।"])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["में समाप्त हो रहा है"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["समय सीमा समाप्त"]), _normalize(["1 दिन में समाप्त हो जाता है"]), _normalize([_interpolate(_named("expiresIn")), " दिनों में समाप्त हो जाता है"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["प्रारंभिक मूल्य में ", _interpolate(_named("n")), " उपयोगकर्ता लाइसेंस शामिल हैं"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sum")), "/महीने में शुरू होता है"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति उपयोगकर्ता/माह"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति वर्ष"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति महीना"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रैमासिक"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेड्यूल किए गए निरस्तीकरण को हटा दें"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यदि आप Zendesk QA का उपयोग जारी रखना चाहते हैं, तो ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपकी सदस्यता ", _interpolate(_named("date")), " को रद्द कर दी गई थी।"])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपकी सदस्यता की समय-सीमा ", _interpolate(_named("date")), " को समाप्त होने वाली है।"])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["बिना किसी अतिरिक्त लागत के ", _interpolate(_named("days")), " दिन का ट्रायल!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस्तेमाल किया"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपके पास ", _interpolate(_named("n")), " उपयोगकर्ता हैं। सीटों की संख्या इससे कम नहीं हो सकती।"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सदस्यता ", _interpolate(_named("time")), " को फिर से शुरू होगी"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपकी वर्तमान Zendesk QA योजना (", _interpolate(_named("plan")), ") में AI-संचालित सुविधाएँ शामिल नहीं हैं। ध्वनि बुद्धिमत्ता सुविधाओं की पूरी शक्ति दिलाने के लिए, ", _interpolate(_named("link")), "।"])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अपनी सदस्यता रद्द करने के लिए, हमसे ", _interpolate(_named("link")), " संपर्क करें या हमारे साथ चैट करें।"])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुणवत्ता प्रबंधन योजना"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुणवत्ता प्रबंधन"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता अपडेट की गई"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई क्रेडिट कार्ड जोड़ें"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी योजनाएं"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलिंग"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलिंग जानकारी देखें"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलिंग अंतराल"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता रद्द करें"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलिंग अवधि के अंत में आपकी सदस्यता रद्द कर दी जाएगी।"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमसे संपर्क करें"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करने के लिए हमसे संपर्क करें"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान योजना"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम मूल्य निर्धारण"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डाउनग्रेड करें"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वार्षिक (15% बचाएं)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वार्षिक"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वार्षिक भुगतान करें"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता संपादित करें"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["योजनाओं को खोजें"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुफ्त ट्रायल"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैन्युअल रूप से इनवॉइस किया गया"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप अगले चरण में अंतिम आदेश सारांश की समीक्षा और पुष्टि कर सकते हैं।"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वाधिक लोकप्रिय"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके पास कोई सक्रिय भुगतान विधि नहीं है।"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रायल पर"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["योजना परिवर्तित करने के लिए अपनी वर्तमान सदस्यता फिर से शुरू करें"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता रोकें"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रैमासिक"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वार्षिक"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कीमत"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता पुन: सक्रिय करें"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता फिर से शुरू करें"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीटें"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी ट्रायल शुरू करें"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द कर दिया गया"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गैर-नवीनीकरण"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोका गया"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता स्थिति"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रायल"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपग्रेड करें"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता अपग्रेड करें"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया अपने ग्राहक सहायता अनुभव पर प्रतिक्रिया देने के लिए एक मिनट का समय लें। अग्रिम धन्यवाद - ", "{", "{", "agentName", "}", "}", " आपकी प्रतिक्रिया को महत्व देता है!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नमस्ते", "{", "{", "customerName", "}", "}", " 👋 !"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " का CSAT सर्वेक्षण"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप ", "{", "{", "agentName", "}", "}", " से प्राप्त सहायता का मूल्यांकन कैसे करेंगे?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["हम वास्तव में इसकी बहुत सराहना करते हैं। हम सभी के लिए ", _interpolate(_list(0)), "बेहतर बनाने के लिए आपकी प्रतिक्रिया का उपयोग करेंगे।"])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षणों के बारे में जानें"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रकार का चयन करें"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसे बाद में बदला जा सकता है।"])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकाधिक भाषाओं में स्वचालित CSAT सर्वेक्षणों के साथ ग्राहकों की संतुष्टि को मापें और सुधारें।"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सर्वेक्षण नहीं बनाया गया"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण बनाएँ"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण का नाम"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण बनाया गया है"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कीवर्ड बदलने का प्रयास करें या ", _interpolate(_named("contact_us")), " यदि आप इसे यहां सूचीबद्ध देखना चाहते हैं"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमें बताइए"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई भाषा नहीं मिली"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["नीचे दी गई सूची में से एक या अधिक भाषाओं का चयन करें। ", _interpolate(_named("contact_us")), " यदि आपको वह भाषा दिखाई नहीं देती है, जिसका आप उपयोग करना चाहते हैं, वो यहां सूचीबद्ध है।"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाएं जोड़ें"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमें बताइए"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा जोड़ें"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["पिछली बार ", _interpolate(_list(0)), " को प्रकाशित किया गया"])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["पिछली बार ", _interpolate(_list(0)), " को सहेजा गया"])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " प्रश्न"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिचय प्रश्न"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बायाँ स्केल लेबल"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल का विषय"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दाएं पैमाने का लेबल"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धन्यवाद!"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि किसी सर्वेक्षण में कोई अनुपलब्ध अनुवाद है, तो इसके बजाय मूल भाषा के पाठ दिखाए जाएंगे"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवाद लागू करें"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुपलब्ध अनुवाद"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["उन्हें लागू करने से पहले अपने सभी अनुवादों की समीक्षा करना सुनिश्चित करें। डाउनलोड की गई फ़ाइल में परिवर्तन करें, इसे सहेजें और यदि आवश्यक हो तो फिर से अपलोड करें। यदि कोई टोकन ", _interpolate(_named("token_example")), " टैग के रूप में प्रकट नहीं होता है, तो यह या तो गलत फ़ॉर्मेट में या किसी ऐसे फ़ील्ड में होता है जो टोकन की सहायता नहीं करता है।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवादों की समीक्षा करें"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवादित"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत को सारांशित करता है और टिप्पणियों के लिए सुझाव दिखाता है।"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI (अंग्रेजी में) सहायता करता है"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक बार प्रकार बनाए जाने के बाद आप उन्हें संपादित कर सकते हैं"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह प्रकार पहले से मौजूद है। किसी भिन्न नाम का चयन करें।"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम प्रकार को सर्वेक्षण प्रकार ड्रॉपडाउन में चयन योग्य विकल्प के रूप में सहेजें, और डैशबोर्ड पर सभी बनाए गए सर्वेक्षणों को समूहीकृत करें।"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शब्द टाइप करें और सहेजने के लिए एंटर दबाएं"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम प्रकार संपादित करें"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार का नाम रिक्त नहीं हो सकता"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार परिभाषित करें"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम प्रकार सहेजा नहीं गया था। इसे सहेजने के लिए एंटर दबाएं।"])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने वाले का ईमेल"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने वाले का नाम"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप इस कथन से किस हद तक सहमत हैं: \"", _interpolate(_named("agentName")), " ने मेरे मुद्दे को संभालना आसान बना दिया\"?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अब तक ", _interpolate(_named("brandName")), " से सहायता मिलना कितना आसान था?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल मिलाकर, आज आपकी समस्या को हल करना कितना आसान था?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारी टीम के साथ बातचीत करना कितना आसान था?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोपनीयता नीति URL (वैकल्पिक)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा की शर्तें URL (वैकल्पिक)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आप ", _interpolate(_named("agentName")), " से प्राप्त होने वाली सहायता को कैसे रेट करेंगे?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस अनुरोध के लिए हमारी सेवा कैसी थी?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप सहायता प्रतिनिधि से कितने संतुष्ट थे?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आज आपको ", _interpolate(_named("agentName")), " से जो सेवा मिली है, उससे आप कितने संतुष्ट हैं?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम प्रश्न"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अपने स्वयं के कस्टम ", _interpolate(_named("type")), " प्रश्न को परिभाषित करें ..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम प्रश्न टाइप करें"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL कस्टमाइज़ करें"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना ईमेल संदेश सेट करें"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी कस्टम प्रश्न प्रकार का नाम संपादित करने से एकत्रित डेटा को प्रभावित किए बिना, इसका उपयोग करने वाले सभी सर्वेक्षणों में उसे अपडेट कर दिया जाएगा।"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम प्रकार संपादित करें"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल का विषय"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिचय प्रश्न"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " प्रश्न"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया कस्टम प्रकार बनाएँ"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामग्री"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["निजीकरण टोकन"]), _normalize(["निजीकरण टोकन"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["वैयक्तिकरण टोकन को नीचे दिए गए फ़ील्ड में खींचें और छोड़ें।"]), _normalize(["वैयक्तिकरण टोकन को नीचे दिए गए फ़ील्ड में खींचें और छोड़ें।"])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["बातचीत ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान में केवल ईमेल के माध्यम से CSAT सर्वेक्षण भेजना संभव है। हम आपके लिए और अधिक अवसर लाने के लिए काम कर रहे हैं, जैसे सहायता डेस्क, CSAT सर्वेक्षण, SMS सर्वेक्षण आदि।"])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["कनेक्शन सिंक या नवीनीकृत करें"]), _normalize(["सिंक या कनेक्शन नवीनीकृत करें"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन ↗ सिंक या नवीनीकृत करें"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["कनेक्शन ठीक से सिंक नहीं किया गया है"]), _normalize(["कनेक्शन ठीक से सिंक नहीं किया गया है।"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संबंधित चेकबॉक्स ↗ को अनचेक करें"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी प्रमाणित करें ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन सक्रिय करने के लिए कम से कम एक सर्वेक्षण भेजने का नियम जोड़ें"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यह कनेक्शन वर्तमान में अक्षम किया गया है। ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यह कनेक्शन ग्राहक के संवेदनशील डेटा को गुप्त रखता है। सर्वेक्षण भेजना सक्षम करने के लिए,\nसेटिंग्स में ", _interpolate(_named("link")), "।"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["इस कनेक्शन को सर्वेक्षण भेजने के लिए अतिरिक्त अनुमतियों की आवश्यकता है।\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी प्रमाणित करें"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA को इंटरकॉम के लिए अधिक अनुमतियों की आवश्यकता होती है।"])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " और ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 घंटा"]), _normalize([_interpolate(_named("n")), " घंटे"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " मिनट"]), _normalize(["1 मिनट"]), _normalize([_interpolate(_named("n")), " मिनट"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["घंटा"]), _normalize(["घंटे"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सर्वेक्षण भेजने के नियमों को कॉन्फ़िगर करने के लिए ", _interpolate(_named("link")), "।"])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" दिन"]), _normalize(["दिन"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण लिंक की समय सीमा समाप्त हो गई है"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक की समय सीमा समाप्त होने वाली है"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षणों का उत्तर देने से रोकें यदि बहुत अधिक समय बीत चुका है"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " सर्वेक्षण ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिखने के बाद टैग को अल्पविराम या प्रेस एंटर से अलग करें। सुनिश्चित करें कि प्रत्येक को इंटरकॉम के रूप में ठीक से सूचीबद्ध किया गया है।"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन स्थितियों को निर्धारित करें जिनके आधार पर सर्वेक्षण भेजा जाता है।"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण भेजने के नियम"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण फ़ॉलो-अप भेजने के नियम"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["एक सर्वेक्षण ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " पहले से मौजूद है।\nया तो यहाँ या ", _interpolate(_named("link")), " में नियमों को संशोधित करके विरोध का हल करें"])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपने पहले ही ", _interpolate(_named("name")), " सर्वेक्षण के लिए मेल खाने वाले नियमों के साथ एक कनेक्शन सेट कर लिया है। या तो यहाँ या ", _interpolate(_named("link")), " में नियमों को संशोधित करके विरोध का हल करें"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अन्य नियम जोड़ें"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रांड जोड़ें"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति चुनें"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि करें"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मान खोजें"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग जोड़ें"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समान नियमों का मिलान करना"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मान चुनें"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल भेजा गया"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल फॉलो-अप भेजा गया"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एम्बेड किए गए स्निपेट और ईमेल फ़ॉलो अप"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड कॉपी करें"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने Zendesk सेटअप में स्निपेट कोड जोड़कर Zendesk QA सर्वेक्षणों को अपने ईमेल में संलग्न करें।"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंस्टालेशन गाइड"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk खोलें"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन करें"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्निपेट पूर्वावलोकन"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्निपेट कोड क्लिपबोर्ड पर कॉपी किया गया"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्निपेट कॉपी करना विफल रहा"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एम्बेड किया गया स्निपेट"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस सर्वेक्षण में सहेजे न गए परिवर्तन हैं। यह सुनिश्चित करने के लिए इसे फिर से सहेजें कि स्निपेट कोड नवीनतम अपडेट को दर्शाता है।"])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन जोड़ें"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई कनेक्शन नहीं जोड़ा गया"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने कनेक्शन के साथ Zendesk QA में ईमेल, लाइव चैट, फोन कॉल और बहुत कुछ खींचें"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण वितरण के बारे में जानें"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण भेजने के नियम"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण फ़ॉलो-अप भेजने के नियम"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रांड नियम जोड़ें"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियम जोड़ें"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणित करें"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + ईमेल फ़ॉलो अप"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने इंटरकॉम खाते को Zendesk QA CSAT सर्वेक्षण से कनेक्ट करें"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के माध्यम से डिलीवर करें"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण कौन और कब प्राप्त करता है"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियम संपादित करें"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्षम किया गया"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इन-ऐप सर्वेक्षण आपके ग्राहकों को चैट छोड़े बिना सर्वेक्षण पूरा करने में सक्षम बनाते हैं।"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटरकॉम लॉन्च करें"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति कनेक्शन वितरण नियम"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["एक सर्वेक्षण ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " पहले से मौजूद है।\nया तो यहाँ या ", _interpolate(_named("link")), " में कनेक्शन को अक्षम करके या नियमों को संशोधित करके विरोध को हल करें"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन स्थितियों को निर्धारित करें जिनके आधार पर सर्वेक्षण भेजा जाता है"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), " को ", _interpolate(_named("name")), " के द्वारा बनाया गया।"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("languageName")), " निकालें"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस भाषा को हटाने का मतलब है कि यह अब किसी भी उत्तरदाताओं को दिखाई नहीं देगी और सभी अनुवादित पाठ हटा दिए गए हैं।"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा निकालें"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस क्रिया को पूर्ववत् नहीं किया जा सकता है।"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैकल्पिक रूप से, आप भाषा को सर्वेक्षण से छिपाकर अक्षम कर सकते हैं। यह आपको उत्तरदाताओं को दिखाए बिना अपने सभी अनुवादों को रखने की अनुमति देगा।"])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), " को ", _interpolate(_named("name")), " द्वारा अंतिम बार संपादित किया गया"])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण भाषाओं के बारे में जानें"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अन्य भाषाओं को जोड़ने से पहले, सुनिश्चित करें कि आपने स्टाइलिंग और सामग्री चरणों में परिवर्तन करने को अंतिम रूप दिया है। ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा जोड़ें"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड का अनुवाद किया गया"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तरदाताओं के लिए दृश्यमान"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिफ़ॉल्ट"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवाद फ़ाइल डाउनलोड करें"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा निकालें"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाएं"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" अनुवाद फ़ाइल डाउनलोड करें"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवादों की समीक्षा करें"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अनुवाद पूर्ण। कोई बदलाव करने के लिए ", _interpolate(_named("review_link")), " या ", _interpolate(_named("download_link")), "।"])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" अनुवाद फ़ाइल डाउनलोड करें"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा करें कि क्या छूट रहा है"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कुछ सामग्री का अनुवाद किया जाना बाकी है और इसे अंग्रेजी से बदल दिया जाएगा। अनुपलब्ध अनुवाद जोड़ने के लिए ", _interpolate(_named("review_link")), " या ", _interpolate(_named("download_link")), "।"])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण सहेजें"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सर्वेक्षण में न सहेजे गए परिवर्तन हैं। नवीनतम परिवर्तनों के साथ अनुवाद फ़ाइल डाउनलोड करने के लिए ", _interpolate(_named("save")), "।"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवाद फ़ाइल अपलोड करें"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पसंदीदा मूल्यांकन पैमाने का चयन करें"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहकों को मूल्यांकन के लिए एक कारण चुनने के लिए एक ड्रॉपडाउन देता है"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमोजी"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बायां स्केल पाठ (वैकल्पिक)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नंबर"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण जोड़ें"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण खाली नहीं हो सकता"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह कारण पहले से मौजूद है"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने स्कोर के कारणों के लिए ड्रॉपडाउन दिखाएं"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी कारण को हटाना और उसे उसी नाम से फिर से जोड़ना या किसी कारण का नाम बदलना हमेशा एक नई प्रविष्टि बनाता है। परिणामस्वरूप, यह आपके डैशबोर्ड पर प्रदर्शित डेटा को प्रभावित करता है।"])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण हटाएँ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाया गया कारण डैशबोर्ड रिपोर्टिंग में बना रहता है लेकिन नए सर्वेक्षण उत्तरदाताओं को दिखाई नहीं देगा। यह संबंधित अनुवादों पर भी लागू होता है।"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कारण हटाएं"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["एक कारण हटाएं \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दायां स्केल पाठ (वैकल्पिक)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-पॉइंट स्केल पर, 1 और 2 को नकारात्मक स्कोर माना जाता है; 3 तटस्थ है; 4-5 सकारात्मक हैं"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्केल पाठ जोड़ें"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " स्केल"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन पैमाना"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA आपकी बातचीत को आयात कर रहा है। इस प्रक्रिया में 24 घंटे तक लग सकते हैं।"])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसका उपयोग पूरे सर्वेक्षण में वैयक्तिकरण टोकन के रूप में किया जाएगा।"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रांड का नाम"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बटन और लिंक"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने सर्वेक्षण का रूप अनुकूलित करें"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो पृष्ठभूमि"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, PNG और GIF फ़ॉर्मेट समर्थित हैं"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टाइलिंग"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो अपलोड करें"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शैली में साइन ऑफ करें"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा संदेश डालें जो लोगों को प्रतिक्रिया भेजने के बाद दिखाई देगा"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धन्यवाद संदेश"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धन्यवाद!"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत हेक्स रंग कोड"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत ईमेल पता"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस फ़ील्ड को खाली नहीं छोड़ा जा सकता"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत url"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिखाई नहीं दे रहा है:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दृश्यमान"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाए जाने की तारीख"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाएं"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषित सर्वेक्षण"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिनों में भेजे गए सर्वेक्षण"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण हटा दिया गया है"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण दोहराया गया है"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन ईमेल भेज दिया गया है"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रकाशित किया गया है"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण को अपडेट किया गया है"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण रोक दिया गया है"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोकें"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण रोकें"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन करें"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण पूर्वावलोकन भेजने के लिए ईमेल दर्ज करें"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन के लिए भाषा का चयन करें"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन ईमेल भेजें"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने ईमेल पर एक उदाहरण सर्वेक्षण भेजें। सर्वेक्षण पूर्वावलोकन से मूल्यांकन संग्रहीत नहीं किए जाएंगे।"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " सर्वेक्षण का पूर्वावलोकन करें"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वेब में ईमेल सर्वेक्षण पूर्वावलोकन"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित करें"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर शुरू करें"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ्ट के रूप में सहेजें"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्रांड का नाम"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टाइलिंग चरण में ब्रांड-विशिष्ट नाम जोड़ा जा सकता है"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक का नाम"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support प्रतिनिधि"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाया गया"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोका गया"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " का CSAT सर्वेक्षण"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न सहेजे गए बदलाव किए गए"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण को अपडेट करें और फिर से शुरू करें"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["अगर आपको यह ईमेल सही से नहीं दिख रहा है ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुझे जो सहायता मिली, उससे मैं खुश नहीं था, क्योंकि ..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA द्वारा संचालित"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण चुनें"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत पुनर्कथन"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण: ग्राहक को भुगतान विधि को अपडेट करने में समस्या थी। एजेंट ने एक आलेख साझा किया जो सहायता कर सकता है। इसने काम कर दिया।"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI द्वारा संचालित"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा की शर्तें"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी प्रतिक्रिया के लिए धन्यवाद"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके स्कोर का मुख्य कारण क्या है?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमें बताएं कि आपने यह मूल्यांकन क्यों दिया ..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च प्रयास"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम प्रयास"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण खोलने के लिए यहां क्लिक करें"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निजता"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका कस्टम प्रश्न यहां दिखाई देता है"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["आपने अपने ", _interpolate(_list(0)), " अनुभव को इतना स्कोर दिया"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया भेजें"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धन्यवाद!"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्‍यता समाप्‍त"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहुत असंतुष्ट"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहुत संतुष्ट"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्यान दें कि सर्वेक्षण की वर्तमान स्थिति और वितरण नियमों को सर्वेक्षण डुप्लिकेट में शामिल नहीं किया जाएगा"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["डुप्लिकेट सर्वेक्षण \"", _interpolate(_list(0)), "\""])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप एम्बेडेड ईमेल स्निपेट का उपयोग करते हैं, तो सर्वेक्षण को रोकने से पहले इसे Zendesk से निकालना सुनिश्चित करें"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण रोके जाने पर कोई प्रतिक्रियाएँ एकत्र नहीं की जाएंगी।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण रोकें"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुनिश्चित करें कि आप सर्वेक्षण को रोकने से पहले Zendesk से स्निपेट हटा दें।"])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण हटाएं"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप एम्बेडेड ईमेल स्निपेट का उपयोग करते हैं, तो सर्वेक्षण को हटाने से पहले इसे Zendesk से निकालना सुनिश्चित करें।"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह क्रिया सर्वेक्षण को स्थायी रूप से हटा देगी। सर्वेक्षण प्राप्त करने वाले उपयोगकर्ता अभी भी इसे भरने में सक्षम हैं और सर्वेक्षण से संबंधित सभी डेटा Zendesk QA में रहेंगे।."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सर्वेक्षण हटाएं ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुनिश्चित करें कि आप सर्वेक्षण को हटाने से पहले Zendesk से स्निपेट हटा दें।"])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके लागू किए गए परिवर्तन नए उत्तरदाताओं द्वारा देखे जाएंगे।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रकाशित करें"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रकाशित करने के बाद एम्बेड किए गए स्निपेट के नवीनतम संस्करण की प्रतिलिपि बनाना सुनिश्चित करें।"])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप एम्बेडेड ईमेल स्निपेट का उपयोग करते हैं, तो सर्वेक्षण फिर से शुरू करने के बाद इसे Zendesk पर कॉपी करना सुनिश्चित करें।"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण भेजना और प्रतिक्रियाएं एकत्र करना जारी रखें।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण को अपडेट करें और फिर से शुरू करें"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुनिश्चित करें कि आपने सर्वेक्षण फिर से शुरू करने के बाद Zendesk में एम्बेडेड स्निपेट जोड़ा है।"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण अपडेट करें"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण को अपडेट करने के बाद एम्बेडेड स्निपेट का नवीनतम संस्करण कॉपी करना सुनिश्चित करें।"])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई इस सर्वेक्षण को हटाना चाहते हैं? इस क्रिया को पूर्ववत् नहीं किया जा सकता है।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण हटाएं?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका ट्रायल समाप्त हो गया है, लेकिन आप एक पूर्ण खाते में अपग्रेड करके Zendesk QA का आनंद लेना जारी रख सकते हैं।"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका ट्रायल समाप्त हो गया है, लेकिन आप एक पूर्ण खाते में अपग्रेड करके Zendesk QA का आनंद लेना जारी रख सकते हैं। इसे सेट अप करने के लिए अपने स्थानीय व्यवस्थापक से बात करें।"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका ट्रायल समाप्त हो चुका है"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी अपग्रेड करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका मुफ़्त ट्रायल समाप्त हो गया है।"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA के पूर्ण संस्करण का उपयोग जारी रखने के लिए, अपना भुगतान विवरण दर्ज करें।"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA के पूर्ण संस्करण का उपयोग जारी रखने के लिए, आपके खाता व्यवस्थापक को भुगतान विवरण दर्ज करना होगा।"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान प्रबंधित करें"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक उपयोगकर्ता"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " को ", _interpolate(_named("authorName")), " द्वारा बनाया गया"])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " असाइनमेंट"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लगता है कि आप अभी भी इस पर काम कर रहे हैं। यदि आप परिवर्तनों को सहेजे बिना छोड़ देते हैं तो आप अपने द्वारा जोड़ी गई सभी जानकारी खो देते हैं।"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यह असाइनमेंट हर दूसरे ", _interpolate(_named("weekDay")), " को दोहराएगा"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["यह असाइनमेंट हर दूसरे ", _interpolate(_named("weekDay")), " को दोहराएगा"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["सभी समीक्षादाताओं को एक चक्र में शामिल किया गया"]), _normalize(["सभी समीक्षादाताओं को ", _interpolate(_named("nr")), " चक्रों में शामिल किया गया"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " बातचीत"]), _normalize([_interpolate(_named("n")), " बातचीत"]), _normalize([_interpolate(_named("n")), " बातचीत"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nr")), " एजेंट का"]), _normalize([_interpolate(_named("nr")), " एजेंट का"]), _normalize([_interpolate(_named("nr")), " एजेंट का"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["प्रतिभागी"]), _normalize(["प्रतिभागी"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " प्रति चक्र बातचीत"]), _normalize([_interpolate(_named("n")), " प्रति चक्र बातचीत"]), _normalize([_interpolate(_named("n")), " प्रति चक्र बातचीत"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " एक पंक्ति में प्रति समीक्षादाता"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षादाता"]), _normalize(["समीक्षादाता"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षाकर्ता"]), _normalize(["समीक्षाकर्ता"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान उपयोगकर्ता"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट्स को छोड़कर सभी कार्यस्थान उपयोगकर्ता"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["चक्र ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गैर-आवर्ती"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " असाइनमेंट"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट के बारे में जानें"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट बनाएँ"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह क्रिया असाइनमेंट को स्थायी रूप से हटा देगी और डैशबोर्ड से उसका डेटा निकाल देगी। इससे संबंधित सभी समीक्षाएं Zendesk QA में रहेंगी।"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["असाइनमेंट हटाएं ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट हटा दिया गया"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट डुप्लिकेट किया गया"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई असाइनमेंट नहीं जोड़ा गया है"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट बनाने में विफल"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट का नाम"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइकल रीफ्रेश किया जाता है और एक नई कार्य सूची बनाई जाती है। पहले से ही दी गई समीक्षाएं अभी भी लक्ष्य में गिनी जाती हैं।"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तत्काल"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित करें"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " पर अगले चक्र की शुरुआत में"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तनों को कब प्रकाशित करें?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस असाइनमेंट में किए गए परिवर्तन इस असाइनमेंट के साथ प्रकाशित किए जाएँगे"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट को सक्रिय के रूप में सेट करें?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट प्रारंभ दिवस पर आवर्ती"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला शुक्रवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा शुक्रवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा शुक्रवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा शुक्रवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम शुक्रवार"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला सोमवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा सोमवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा सोमवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा सोमवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम सोमवार"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला शनिवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा शनिवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा शनिवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा शनिवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम शनिवार"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला रविवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा रविवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा रविवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा रविवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम रविवार"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला गुरुवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा गुरुवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा गुरुवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा गुरुवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम गुरुवार"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला मंगलवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा मंगलवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा मंगलवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा मंगलवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम मंगलवार"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का पहला बुधवार"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का दूसरा बुधवार"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का तीसरा बुधवार"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का चौथा बुधवार"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने का अंतिम बुधवार"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम पुनरावृत्ति"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर महीने की पहली तारीख"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहला"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेकंड"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तीसरा"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चौथा"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोहराता है"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह का दिन"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खत्म करें"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू करें"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई परिणाम नहीं"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टाइम ज़ोन"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइकिल सेटिंग्स"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट का नाम"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुनियादी जानकारी"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक असाइनमेंट साइकिल की शुरुआत में, मूल्यांकन के लिए बातचीत की एक पूर्व निर्धारित सूची तैयार की जाती है।"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced सेटिंग"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा लक्ष्य पूरा न होने पर नई बातचीतें स्वचालित रूप से प्राप्त करें"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिशत (%) समीक्षा लक्ष्यों के साथ स्वचालित रूप से प्राप्त करना संभव नहीं है"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक पूर्ण संख्या होनी चाहिए, दशमलव नहीं"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों को बदलने की अनुमति दें (समीक्षाकर्ता की भूमिका और ऊपर)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों को बदलना"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा के लिए समीक्षाकर्ताओं को उनकी स्वयं की बातचीत असाइन करने की अनुमति दें"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाओं की न्यूनतम संख्या कम से कम 1 होनी चाहिए"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक समीक्षादाता के लिए न्यूनतम समीक्षाएं निर्दिष्ट करें"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिशत (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल समीक्षाएं"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक समीक्षाकर्ता को समीक्षाओं की संख्या निर्धारित करनी चाहिए"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता को सामूहिक रूप से बातचीत की एक निर्धारित संख्या की समीक्षा करनी चाहिए"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाओं की न्यूनतम संख्या कम से कम 1 होनी चाहिए"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा का लक्ष्य 1 से 100% के बीच होना चाहिए"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा लक्ष्य"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक समीक्षादाता के पास अपनी समीक्षा हो गई कुल बातचीतों की न्यूनतम संख्या होनी चाहिए"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता साझा पूल से बातचीत चुन सकते हैं"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ताओं को साझा पूल से बातचीत असाइन की जाती है"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट दृष्टिकोण"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी बॉट"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान प्रतिभागी"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान उपयोगकर्ता"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता को जोड़ें"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसकी समीक्षा की जाएगी?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता का चयन करें"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें कि किसकी समीक्षा करनी है"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहिष्करण की अनुमति केवल तभी दी जाती है, जब सभी कार्यस्थान उपयोगकर्ता या समूह का चयन किया जाता है"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता और समीक्षादाता"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["मौजूदा: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान डिफ़ॉल्ट"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किस प्रकार के उपयोगकर्ताओं को समीक्षादाता के रूप में चुना जाना चाहिए?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे सक्रिय एजेंट"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता को बहिष्कृत करें (वैकल्पिक)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहिष्कृत करने के लिए उपयोगकर्ताओं का चयन करें"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता को बहिष्कृत करें (वैकल्पिक)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बहिष्कृत करने के लिए उपयोगकर्ताओं का चयन करें"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट्स को छोड़कर सभी कार्यस्थान उपयोगकर्ता"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता को जोड़ें"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा कौन करेगा?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता का चयन करें"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान में स्व-समीक्षा की अनुमति नहीं है"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा की अनुमति दें"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान में स्व-समीक्षा की अनुमति नहीं है।"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["आज की बातचीत का मिलान"]), _normalize(["आज की बातचीत का मिलान"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लग रहा है जैसे असाइनमेंट आज से शुरू हुआ हो।"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भविष्य के आंकड़े अलग-अलग हो सकते हैं।"])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षादाता चयनित नहीं"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षाकर्ता चयनित नहीं"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षादाता"]), _normalize(["समीक्षादाता"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षाकर्ता"]), _normalize(["समीक्षाकर्ता"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन करें"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वतः प्राप्त करना: पर्याप्त बातचीतें न होने पर अनुमति है"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से शुक्रवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से सोमवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से शनिवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से रविवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से वीरवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से मंगलवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से बुधवार को ", _interpolate(_named("cycleLength"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " से रोज़ाना"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " को शुरू होता है और ", _interpolate(_named("endDateTime")), " को समाप्त होता है।"])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " तारीख की स्थितियां"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " बातचीत और सहायता डेस्क की स्थिति"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["कोई बातचीत और ", _interpolate(_named("count")), " सहायता डेस्क की स्थिति नहीं"]), _normalize(["कोई बातचीत और ", _interpolate(_named("count")), " सहायता डेस्क की स्थिति नहीं"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " बातचीत और कोई सहायता डेस्क की स्थिति नहीं"]), _normalize([_interpolate(_named("count")), " बातचीत और कोई सहायता डेस्क स्थिति नहीं"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["दृष्टिकोण: बातचीतों का चयन करने के लिए समीक्षाकर्ता"]), _normalize(["दृष्टिकोण: समीक्षाकर्ता को बातचीत सौंपी जाती है"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखने के लिए सारांश में हाइलाइट की गई त्रुटियों को ठीक करें"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य: समीक्षाकर्ता को सभी बातचीतों की समीक्षा करनी चाहिए"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["लक्ष्य: समीक्षाकर्ता को एक बातचीत की समीक्षा करनी चाहिए"]), _normalize(["लक्ष्य: समीक्षाकर्ता को ", _interpolate(_named("count")), " बातचीतों की समीक्षा करनी चाहिए"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["लक्ष्य: समीक्षाकर्ता को ", _interpolate(_named("count")), "% बातचीत की समीक्षा करनी चाहिए"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य: कोई समीक्षा लक्ष्य सेट नहीं"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य: प्रत्येक समीक्षादाता के पास अपनी सभी बातचीत की समीक्षा होनी चाहिए"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["लक्ष्य: प्रत्येक समीक्षादाता के पास ", _interpolate(_named("count")), " अपनी कुल बातचीत की समीक्षा होनी चाहिए"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["लक्ष्य: प्रत्येक समीक्षादाता के पास अपनी कुल बातचीत के ", _interpolate(_named("count")), "% की समीक्षा होनी चाहिए"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["लक्ष्य: प्रत्येक समीक्षाकर्ता को एक समीक्षा करनी चाहिए"]), _normalize(["लक्ष्य: प्रत्येक समीक्षक को ", _interpolate(_named("count")), " समीक्षाएं करनी चाहिए"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["प्रतिस्थापित करना: समीक्षाकर्ता बातचीतों को प्रतिस्थापित नहीं कर सकते"]), _normalize(["प्रतिस्थापित करना: समीक्षाकर्ता बातचीतों को प्रतिस्थापित कर सकते हैं"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्व-समीक्षा: समीक्षाकर्ता को स्वयं की बातचीत असाइन की जा सकती है"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई तारीख स्थितियां निर्धारित नहीं"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई असाइनमेंट नाम सेट नहीं"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सारांश"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थितियां"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateTime")), " से ", _interpolate(_named("weekday"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वि-साप्ताहिक"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर दिन"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मासिक"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कभी नहीं"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साप्ताहिक"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चक्र की लंबाई"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय के रूप में सेट करें"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ्ट के रूप में सहेजें"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ्ट के रूप में सहेजा गया असाइनमेंट"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट निष्क्रिय के रूप में सेट की गई"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय के रूप में सेट करें"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट बनाएँ"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट बनाई गई"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय के रूप में चिह्नित करें"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय के रूप में चिह्नित करें"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["असाइनमेंट के लंबित अपडेट अगले चक्र में प्रभावी होंगे, जो ", _interpolate(_list(0)), " शुरू होगा।"])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन प्रकाशित करें"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट अपडेट करें"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट अपडेट की गई"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि क्या एजेंट या बॉट ने बातचीत को समाप्त किया है।"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि क्या एजेंट ने बातचीत को विनम्रता से समाप्त किया"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि क्या बातचीत समाप्त की गई थी"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट और बॉट इंटरेक्शन दोनों में व्याकरण, वर्तनी और शैली की गलतियों का विश्लेषण करता है।"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट के व्याकरण, वर्तनी और शैली की गलतियों का विश्लेषण करता है"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट के व्याकरण, वर्तनी और शैली की गलतियों का विश्लेषण करता है"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तनी और व्याकरण"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट और बॉट अभिवादन के लिए बातचीत का विश्लेषण करता है।"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट अभिवादन वाक्यांशों के लिए पूरी बातचीत का विश्लेषण करता है"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशिष्ट अभिवादन के लिए पूरी बातचीत का विश्लेषण करता है"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि क्या एजेंट ग्राहकों की भावनाओं को समझता है और स्वीकार करता है"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन मामलों की पहचान करता है जहां एजेंट ने ग्राहक के सेंटिमेंट को प्रतिबिंबित किया या 'स्वीकृति' और 'पहचान' जैसे देखभाल करने वाले शब्दों का इस्तेमाल किया"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समानुभूति"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि एजेंट ग्राहक की क्वेरी या चिंता को समझता है या नहीं"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण करता है कि एजेंट ग्राहक की क्वेरी या चिंता को समझता है या नहीं"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझ"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शब्द जटिलता और वाक्य की लंबाई को देखते हुए विश्लेषण करता है कि किसी पाठ को कितनी आसानी से समझा जा सकता है"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शब्द जटिलता और वाक्य की लंबाई को देखते हुए विश्लेषण करता है कि किसी पाठ को कितनी आसानी से समझा जा सकता है"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस संदेश में 1 अत्यधिक लंबा वाक्य है, जिससे इसे पढ़ना चुनौतीपूर्ण हो जाता है"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["इस संदेश में ", _interpolate(_list(0)), " अत्यधिक लंबे वाक्य हैं , जिससे इसे पढ़ना चुनौतीपूर्ण हो जाता है"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पठनीयता"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफ़र किए गए समाधान के लिए संपूर्ण बातचीत का विश्लेषण करता है"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफ़र किए गए समाधान के लिए संपूर्ण बातचीत का विश्लेषण करता है"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाधान ऑफ़र किया गया"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट के स्वर का विश्लेषण करता है"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट के स्वर का विश्लेषण करता है"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वर"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट के संदेशों से किसी भी गलती का पता नहीं लगा सका"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्याकरण की ग़लतियाँ निर्धारित नहीं कर सका"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अभिवादन वाक्यांश नहीं मिला। यदि आवश्यक हो तो सेटिंग में अपने व्यवस्थापक के माध्यम से नए जोड़ें।"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने विनम्रता से बातचीत को समाप्त किया"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समाप्ति वाक्यांश नहीं मिला। यदि आवश्यक हो तो सेटिंग में अपने व्यवस्थापक के माध्यम से नए जोड़ें"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ग्राहक के सेंटिमेंट को प्रतिबिंबित किया या देखभाल करने वाले शब्दों का इस्तेमाल किया"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह पता नहीं लगा सका कि क्या एजेंट ने ग्राहक के सेंटिमेंट को प्रतिबिंबित किया या इस्तेमाल किए गए देखभाल शब्दों का इस्तेमाल किया"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने लंबे वाक्यों और जटिल शब्दों का इस्तेमाल किया, जिससे कुछ लोगों के लिए इसे समझना संभावित रूप से चुनौतीपूर्ण हो गया"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने जटिल शब्दों का इस्तेमाल किया, जिससे कुछ लोगों के लिए इसे समझना संभावित रूप से चुनौतीपूर्ण हो गया"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने लंबे वाक्यों का इस्तेमाल किया, जिससे कुछ लोगों के लिए इसे समझना संभावित रूप से चुनौतीपूर्ण हो गया"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ऐसी भाषा का इस्तेमाल किया जो समझने में आसान हो"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस श्रेणी को रेट करने के लिए पर्याप्त डेटा नहीं है"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ग्राहक के प्रश्न/अनुरोध को समझने के लिए संघर्ष किया"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ग्राहक के प्रश्न/अनुरोध को अच्छी तरह समझा"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह पता नहीं लगा सका कि एजेंट ग्राहक के प्रश्न/अनुरोध को समझ गया है या नहीं"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट का लहजा"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट का लहजा"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट का लहजा"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट का लहजा"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान एजेंट का लहजा"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट का लहजा निर्धारित नहीं कर सका"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत समाप्त नहीं हुई"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत समाप्त हो गई थी"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता नहीं चल सका कि बातचीत समाप्त हो गई है या नहीं"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट में ग्राहक और उनकी समस्याओं के प्रति सहानुभूति की कमी थी"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ग्राहक और उनकी समस्याओं के प्रति सहानुभूति दिखाई"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता नहीं लगा सका कि एजेंट ने ग्राहक और उनकी समस्याओं के प्रति सहानुभूति दिखाई या नहीं "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान ग्राहक का अभिवादन नहीं किया गया"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत के दौरान ग्राहक का अभिवादन किया गया"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य अभिवादन का पता नहीं चल सका"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ग्राहक को समाधान प्रदान नहीं किया"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंट ने ग्राहक को समाधान ऑफ़र किया"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह पता नहीं लगा सका कि एजेंट ने ग्राहक को समाधान की पेशकश की है या नहीं"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जटिल शब्द"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबे वाक्य"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्याकरण"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गलत वर्तनी"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षमाप्रार्थी"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शांति"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हँसमुख"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जिज्ञासु"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेशेवर"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायक"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शैली"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 समाप्ति"]), _normalize([_interpolate(_named("n")), " समाप्ति"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA केवल स्वीकृत समाप्ति के लिए जाँच करता है। यदि इस श्रेणी को महत्वपूर्ण के रूप में सेट किया गया है, तो स्वीकृत समाप्ति का उपयोग नहीं करने से समीक्षा विफल हो जाएगी।"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्तियां"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत समाप्तियां"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 छूट"]), _normalize([_interpolate(_named("n")), " छूट"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शब्द और वाक्यांश AutoQA वर्तनी या व्याकरण की गलतियों के रूप में अनदेखा करता है।"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तनी और व्याकरण छूट"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA छूट"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 अभिवादन"]), _normalize([_interpolate(_named("n")), " अभिवादन"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA केवल स्वीकृत अभिवादन की जाँच करता है। यदि इस श्रेणी को महत्वपूर्ण के रूप में सेट किया गया है, तो स्वीकृत अभिवादन का उपयोग नहीं करने पर समीक्षा विफल हो जाएगी।"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृत अभिवादन"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA श्रेणी"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक AutoQA श्रेणी का चयन करें"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित रूप से असाइन किए गए मूल कारण:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता → सेटिंग्स"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कुछ श्रेणियां MS Azure का लाभ उठाती हैं, जो कार्यक्षमता और समर्थित भाषाओं की एक विस्तारित श्रृंखला पेश करती हैं। अधिक जानकारी के लिए अपने ", _interpolate(_named("link")), " की समीक्षा करें या ऐसा करने के लिए किसी व्यवस्थापक से संपर्क करें।"])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["MS Azure का लाभ उठाकर श्रेणियों और समर्थित भाषाओं की एक विस्तारित सूची तक पहुँचें। ऐसा करने के लिए अपने ", _interpolate(_named("link")), " की समीक्षा करें या किसी व्यवस्थापक से संपर्क करें।"])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA का उपयोग करें"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA श्रेणियों को एक निश्चित मूल्यांकन पैमाने का उपयोग करके स्वचालित रूप से एक स्कोर सौंपा जाता है। आप बाद में मैन्युअल रूप से मूल्यांकन समायोजित कर सकते हैं। ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक AutoQA श्रेणी का चयन करें"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["स्वचालित रूप से असाइन किया गया स्कोर गलतियों की गंभीरता और उनकी संख्या पर निर्भर करता है। ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता को केवल प्रासंगिक मूल्यांकन श्रेणियां दिखाई जाती हैं। मूल्यांकन श्रेणियां उन स्थितियों के आधार पर दिखाई जाती हैं, जिन्हें आपने श्रेणी बनाते समय सेट किया है।"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैट"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ज्ञानप्राप्ति"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ोन"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कार्यस्थान \"", _interpolate(_named("workspace")), "\" के लिए मूल्यांकन श्रेणी प्रकार का चयन करें।"])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता को प्रत्येक बातचीत के लिए प्रासंगिक मूल्यांकन श्रेणियां देखने के लिए मैन्युअल रूप से सही स्कोरकार्ड का चयन करना होगा।"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई मूल्यांकन श्रेणी प्रकार बदलना चाहते हैं?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियां बदलें"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी प्रकार बदलें?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थितिगत मूल्यांकन श्रेणी"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिर मूल्यांकन श्रेणी"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी प्रकार"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी सेटिंग"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस क्रिया को पूर्ववत् नहीं किया जा सकता है।"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मूल्यांकन हटाएं"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैकल्पिक रूप से, आप किसी श्रेणी को संग्रहीत करके किसी भी डेटा को हटाए बिना अपने आंकड़े रखते हैं।"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["मैं समझता हूं कि यह इस श्रेणी के लिए ", _interpolate(_named("deleteAllRatings")), " दिया जाएगा ।"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जैसे टोन एकदम सही है"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी केवल तभी दिखाएं जब"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"हमेशा\" को अनदेखा किया जाएगा। इसे अन्य स्थितियों के साथ नहीं जोड़ा जा सकता है।"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमेशा"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति चुनें"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संतुष्टि स्कोर (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत प्रकार"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत चैनल"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक श्रेणी को एक नाम की आवश्यकता है"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी को स्कोरकार्ड में जोड़ें"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी बनाएं"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी बनाएं"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहीत करें"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी जोड़ी गई"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड पर एक संग्रहीत श्रेणी दिखाई नहीं देती है, लेकिन यह आपको किसी भी डेटा को हटाए बिना अपने आंकड़े रखने की अनुमति देती है।"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी को संग्रहीत करें"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " श्रेणी को संग्रहीत करें"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी संग्रहीत करें"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी हटाएँ"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस श्रेणी को हटाने के लिए \"श्रेणी हटाएँ\" टाइप करें"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी हटाएँ"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["श्रेणी हटाएँ ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी हटाई गई"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी डुप्लिकेट की गई"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी समूह जोड़ा गया"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी समूह हटाया गया"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी समूह क्रम बदल गया"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी समूह अपडेट किया गया"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी असंग्रहीत"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन श्रेणी अपडेट की गई"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब कोई एजेंट किसी महत्वपूर्ण श्रेणी में विफल रहता है, तो उस समीक्षा की सभी श्रेणियां स्वचालित रूप से विफल हो जाती हैं।"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण मूल्यांकन श्रेणी"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समूह में कोई श्रेणी नहीं"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई मूल्यांकन श्रेणी बनाएं"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी संपादित करें"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समूह के अंतर्गत सभी श्रेणियां हटाएं और डेटा की समीक्षा करें"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी समूह को हटाने से इससे जुड़ी सभी श्रेणियां असमूहीकृत हो जाएंगी।"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " श्रेणी समूह हटाएं"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह के अंतर्गत श्रेणियों को हटाने का विकल्प चुनने से इन श्रेणियों से संबंधित सभी समीक्षा डेटा खो जाएगा।"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह का नाम"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता मूल कारण के रूप में एक से अधिक कारणों का चयन कर सकते हैं"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता मूल कारण के रूप में एक से अधिक कारणों का चयन कर सकते हैं"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कई कारण"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जैसे लहजा"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया श्रेणी समूह जोड़ें"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया समूह"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"अन्य\" जोड़ें"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण जोड़ें"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह मूल कारण पहले से मौजूद है"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण जोड़ें"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन की व्याख्या करने के लिए मूल कारण जोड़ें"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन पैमाना"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहित"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस मुल्यांकन श्रेणी में स्कोरकार्ड जोड़ें"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असंग्रहित करें"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी अपडेट करें"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मूल्यांकन"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के लिए दिखाया गया है"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक मूल्यांकन"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकारात्मक और तटस्थ मूल्यांकन"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ, अपडेट करें"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वजन"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियां जो आपके लिए अधिक महत्वपूर्ण हैं, टिकट स्कोर गणना में अधिक वजनदार हो सकती हैं।"])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["वजन (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करने के बारे में जानें"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करने के बारे में जानें"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियमित कैलिब्रेशन/जांच सत्र आयोजित करने से समीक्षाकर्ता को अपनी मूल्यांकन तकनीकों को संरेखित रखने और यह सुनिश्चित करने की अनुमति मिलती है कि एजेंटों को समान स्तर की सुसंगत और निष्पक्ष प्रतिक्रिया प्राप्त हो।"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["योजनाएं देखें"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " एक ऐसी प्रक्रिया है जो आपकी टीम को एक ही पृष्ठ पर बने रहने में मदद करती है कि सभी ग्राहक इंटरेक्शन को कैसे संभाला और मूल्यांकन किया जाना चाहिए।"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेशेवर"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["कैलिब्रेशन/जांच करना हमारे ", _interpolate(_list(0)), " और ", _interpolate(_list(1)), " योजनाओं के लिए उपलब्ध है।"])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र में बातचीत जोड़े जाने पर पहले दी गई समीक्षाएं प्रदर्शित नहीं होती हैं।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिखाई नहीं दे रहा है"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले दी गई समीक्षाओं को कैलिब्रेशन समीक्षा माना जाता है। प्रति समीक्षाकर्ता केवल एक बातचीत समीक्षा सत्र में जोड़ी जाएगी। संदेश-विशिष्ट समीक्षाएं प्रदर्शित नहीं होती हैं।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दृश्यमान"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले दी गई समीक्षाओं की दृश्यता"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कैलिब्रेशन/जांच करना एक ऐसी प्रक्रिया है जो आपकी टीम को एक ही पृष्ठ पर बने रहने में सहायता करती है कि सभी ग्राहक इंटरेक्शन को कैसे संभाला और मूल्यांकन किया जाना चाहिए। ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं के पास कैलिब्रेशन/जांच सत्र के दौरान दी गई सभी समीक्षाओं तक असीमित पहुंच है।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमेशा सभी समीक्षाएं देखें"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लीड"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजने के बाद, उपयोगकर्ताओं को कैलिब्रेशन/जांच सत्र के दौरान अन्य समीक्षाकर्ताओं द्वारा दिए गए मूल्यांकन दिखाई देंगे।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजने के बाद सभी कैलिब्रेशन/जांच समीक्षाएँ देखें"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं के पास कैलिब्रेशन/जांच सत्र के दौरान दी गई सभी समीक्षाओं तक असीमित पहुंच है।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमेशा सभी समीक्षाएं देखें"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रबंधक"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजने के बाद, उपयोगकर्ताओं को कैलिब्रेशन/जांच सत्र के दौरान अन्य समीक्षाकर्ताओं द्वारा दिए गए मूल्यांकन दिखाई देंगे।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजने के बाद सभी कैलिब्रेशन/जांच समीक्षाएँ देखें"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजने के बाद, उपयोगकर्ताओं को कैलिब्रेशन/जांच सत्र के दौरान अन्य समीक्षाकर्ताओं द्वारा दिए गए मूल्यांकन दिखाई देंगे।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा भेजने के बाद सभी कैलिब्रेशन/जांच समीक्षाएँ देखें"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता केवल कैलिब्रेशन/जांच सत्र के दौरान दी गई अपनी स्वयं की समीक्षा देखेंगे।"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल स्वयं की समीक्षाएं देखें"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच सेटिंग्स अपडेट की गई"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच समीक्षा की दृश्यता"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी टीम की आंतरिक मीट्रिक प्रणाली के अनुसार अंतराल को भरकर डैशबोर्ड मैट्रिक के लिए सीमा सेट करें। डैशबोर्ड पर सीमा की समीक्षा करें।"])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन/जांच करना"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा अपडेट की गई"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ पल इंतज़ार करें।"])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["सुनिश्चित करें कि आपके पास आगे की सहायता के लिए व्यवस्थापक अनुमतियाँ और ", _interpolate(_named("retry")), ", या ", _interpolate(_named("contact")), " हैं।"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमसे संपर्क करें"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लगता है कि हम आपकी सहायता डेस्क के API तक पहुंच प्राप्त करने में सक्षम नहीं थे।"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोबारा कोशिश करें।"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हम आपकी सहायता डेस्क को जोड़ रहे हैं। आपको क्षण भर के लिए पुनर्निर्देशित किया जाएगा।"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " प्राधिकृत"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["एक नया सहायता डेस्क कनेक्शन जोड़ने के लिए अपने ", _interpolate(_list(0)), " पर पास वापस जाएं।"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लगता है कि आप एक या दो कदम चूक गए हैं।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समान ईमेल डोमेन वाला संगठन पहले से मौजूद है"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन पेज"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बीच..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब तक हम आपकी अनुमतियों की जांच करते हैं, तब तक प्रतीक्षा करें।"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बीच..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकीकरण"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान बनाएँ"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA का उपयोग जारी रखने के लिए, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोग के लिए लगभग तैयार"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं मिला"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता स्विच करें"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA का उपयोग जारी रखने के लिए, आपके प्रबंधक द्वारा आपको कार्यस्थान में असाइन किया जाएगा।"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["आपके खाते को हटाने से हमारे पास मौजूद सभी डेटा स्थायी रूप से हट जाएगा - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी दिए गए मूल्यांकन, बातचीत सामग्री, कार्यस्थान, आदि।"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack में जोड़ें"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिस्कनेक्ट करें"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकीकरण को डिस्कनेक्ट करने से Slack के माध्यम से सभी Zendesk QA सूचनाएं अक्षम हो जाएंगी"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack को डिस्कनेक्ट करें"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack एकीकरण को Zendesk QA से काट दिया गया है।"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी नए खाता उपयोगकर्ताओं के लिए डिफ़ॉल्ट सूचना सेटिंग्स निर्धारित करें।"])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी उपयोगकर्ताओं के लिए सेटिंग ओवरराइड करें"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप सभी खाता उपयोगकर्ताओं के लिए सूचना सेटिंग ओवरराइड करने वाले हैं"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता अपनी स्वयं की सूचना सेटिंग्स को परिभाषित करने में सक्षम नहीं होंगे।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचना सेटिंग्स अपडेट की गईं"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वेबहुक"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अलर्ट जब एक महत्वपूर्ण श्रेणी विफल हो जाती है"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT को प्रतिक्रिया मिलने पर अलर्ट"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रिगर"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेमो कार्यस्थान और डेटा बनाएँ"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेमो डेटा को फिर से बनाएं और डेमो कार्यस्थान रखें।"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेमो कार्यस्थान और डेटा निकालें"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेमो डेटा रीफ्रेश करें"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेटा रीफ़्रेश करें"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["खाता \"", _interpolate(_named("accountName")), "\" डेमो मोड प्रबंधित करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेमो मोड"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेज पर बने रहें"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेज छोड़ें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या इस पेज को छोड़कर सभी परिवर्तन खोना चाहते हैं?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने असाइनमेंट पू्र्ण कर ली है"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट बनाएँ"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट पूर्ण की गई"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई असाइनमेंट नहीं मिली"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान में आपके लिए समीक्षा करने के लिए कोई कार्य नहीं हैं।"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट समायोजित करें या अगले साइकल के शुरू होने की प्रतीक्षा करें।"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर समायोजित करने का प्रयास करें"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट संपादित करें"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट के लिए इस पेज पर नजर रखें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस साइकल के लिए कोई कार्य नहीं"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई बातचीत नहीं"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य को पूर्ण के रूप में चिह्नित करें"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने इस उपयोगकर्ता की समीक्षा की है।"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस असाइनमेंट में कोई स्व-समीक्षा नहीं"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत दृश्य के हेडर में कैलिब्रेशन आइकन पर क्लिक करके जोड़ें।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बातचीत नहीं जोड़ी गई"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियत तारीख"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट बनाएँ"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैलिब्रेशन सत्र बनाएँ"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके असाइनमेंट"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य असाइनमेंट"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सक्रिय असाइनमेंट नहीं"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई असाइनमेंट नहीं"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समीक्षादाता"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे अलावा सभी समीक्षादाता"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षादाता नहीं मिला"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता के लिए खोजें"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकाला गया"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्थितियां"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["करने वाले कार्य"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समीक्षाकर्ता"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे अलावा सभी समीक्षाकर्ता"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुझे असाइन किया गया"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षाकर्ता नहीं मिला"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता के लिए खोजें"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " दिन"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " दिन"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " दिन"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " दिन"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " दिन"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " दिन"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " घंटे"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " घंटे"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " घंटा"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " घंटे"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " घंटे"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " घंटे"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " मिनट"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " मिनट"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " मिनट"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " मिनट"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " मिनट"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " मिनट"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू नहीं"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर से असाइन करें"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान उपयोगकर्ता"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान समीक्षाकर्ता"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस साइकल के लिए नया लक्ष्य"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया समीक्षाकर्ता"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता का चयन करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत फिर से असाइन करें"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत फिर से असाइन करें"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीतों को स्थानांतरित किया जाना है"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान साइकल"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट संपादित करें"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षाकर्ता नहीं मिला"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज समीक्षाकर्ता"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुझे बातचीत पुनः असाइन करें"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य उपयोगकर्ता को बातचीत पुनः असाइन करें"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षिप्त विवरण"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " दिन"]), _normalize([_interpolate(_named("n")), " दिन"]), _normalize([_interpolate(_named("n")), " दिन"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " घंटे"]), _normalize([_interpolate(_named("n")), " घंटा"]), _normalize([_interpolate(_named("n")), " घंटे"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण किए गए साइकल छुपाएं"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण किए गए सत्र छिपाएं"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका लक्ष्य"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत को हटाने के लिए एक कारण जोड़ें"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझाएं कि आप इस बातचीत को क्यों हटा रहे हैं"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत हटाएँ"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप परिवर्तन रखना चाहते हैं?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस बातचीत को बदलने के लिए एक कारण जोड़ें"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव छोड़ें"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रखें"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन रखें"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिस्थापन नहीं मिला"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझाएं कि आप इस बातचीत को क्यों बदल रहे हैं"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालें"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप अभी भी इस बातचीत को असाइनमेंट से निकालना चाहते हैं? इससे लक्ष्य 1 से कम हो जाएगा।"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिस्थापन"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत बदलें"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट हटाएं"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट संपादित करें"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुझे फिर से असाइन करें"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य उपयोगकर्ता को पुनः असाइन करें"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुनः असाइन करें और समीक्षा करें"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत हटाएँ"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक समीक्षा छोड़ें"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम देखें"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक देखें"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["लक्ष्य: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " समीक्षा हो गई"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ पूर्ण की गईं"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण किए गए साइकल दिखाएं"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण किए गए साइकल दिखाएं"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचा हुआ समय"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत बदल दी गई"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य सूची टॉगल करें"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " बाद में"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " सेकंड"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " सेकंड"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "दि."])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "दि. ", _interpolate(_named("hours")), "घं."])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "दि‍न ", _interpolate(_named("hours")), "घंटे ", _interpolate(_named("minutes")), "मि‍नट"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "दि."])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " दिन"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "दि‍न ", _interpolate(_named("minutes")), "मि‍नट"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "घं"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "घं. ", _interpolate(_named("minutes")), "मि."])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "घं ", _interpolate(_named("minutes")), "मि. ", _interpolate(_named("seconds")), "से."])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "घं ", _interpolate(_named("seconds")), "से."])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "मि"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "मि. ", _interpolate(_named("seconds")), "से."])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "माह"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " से."])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "या"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तुरंत"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके पास अनुमतियों की कमी है। सहायता के लिए अपने व्यवस्थापक से संपर्क करें।"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप जिस पेज को ढूँढ रहे हैं वह मौजूद नहीं है।"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक अप्रत्याशित त्रुटि आ गई है। सहायता के लिए समर्थन से संपर्क करें।"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहुंच अस्वीकृत की गई। उस क्रिया को करने की अनुमति नहीं है।"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप जो पेज खोज रहे हैं, वह या तो है ही नहीं या आपके पास उसका ऐक्सेस नहीं है।"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप थोड़ा तेजी से जा रहे हैं और हमें पकड़ने में परेशानी हो रही है।"])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नहीं"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव सहेजे गए"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न सहेजे गए बदलाव किए गए"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " लागू किया"]), _normalize([_interpolate(_named("n")), " लागू किया"]), _normalize([_interpolate(_named("n")), " लागू किया"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्ट किया गया"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समय बनाया गया"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वारा बनाया गया"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गंभीर"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["है"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["पूर्व सदस्य"]), _normalize(["एक पूर्व सदस्य"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " चयनित"]), _normalize([_interpolate(_named("n")), " चयनित"]), _normalize([_interpolate(_named("n")), " चयनित"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछली बार अपडेट किया गया"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... और +1 अधिक"]), _normalize(["... और +", _interpolate(_named("n")), " अधिक"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 अधिक"]), _normalize(["+", _interpolate(_named("n")), " अधिक"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नहीं"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं है"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकाला गया"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " जवाब"]), _normalize([_interpolate(_named("n")), " जवाब"]), _normalize([_interpolate(_named("n")), " जवाब"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["शुक्रवार"]), _normalize(["शुक्रवार"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["सोमवार"]), _normalize(["सोमवार"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["शनिवार"]), _normalize(["शनिवार"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["रविवार"]), _normalize(["रविवार"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["गुरुवार"]), _normalize(["गुरुवार"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["मंगलवार"]), _normalize(["मंगलवार"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["बुधवार"]), _normalize(["बुधवार"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला दिन"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["व्यवस्थापक"]), _normalize(["एक व्यवस्थापक"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["खाता प्रबंधक"]), _normalize(["एक खाता प्रबंधक"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["उपयोगकर्ता"]), _normalize(["एक उपयोगकर्ता"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी किया गया"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और जानें"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका सत्र समाप्त हो गया है।"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन-इन"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस फ़ील्ड को खाली नहीं छोड़ा जा सकता"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आइटम नहीं मिला"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मेल खाने वाले लोग नहीं।"])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["मालिक"]), _normalize(["एक मालिक"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और जानें"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और जानें"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["और जानें।"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू करने से पहले, हमारी शर्तों को पढ़ें और सहमति दें और हम आगे बढ़ने के लिए तैयार होंगे।"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजा गया"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पक्का?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल मिलाकर की गई समीक्षाएँ"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट किया गया"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["एजेंट"]), _normalize(["एक एजेंट"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["लीड"]), _normalize(["एक लीड"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["प्रबंधक"]), _normalize(["एक प्रबंधक"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षक"]), _normalize(["एक समीक्षक"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनाम उपयोगकर्ता"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहीत करें"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनी"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीछे"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलें"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जल्दी"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्ट करें"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी करने के लिए क्लिक करें"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक कॉपी करें"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएँ"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खतरे का क्षेत्र"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएँ"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मना करें"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण (वैकल्पिक)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि की आवश्यकता है"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्षम करें"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न सहेजे गए परिवर्तनों को छोड़ना चाहते हैं?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्षम करें"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात करें"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवृत्ति"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लक्ष्य"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समझ गए"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित करें"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाएं"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कभी नहीं"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आगे बढ़ें"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ठीक है"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पास दर"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत सेटिंग"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम पढ़ें"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालें"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर से स्टोर करें"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिर से स्टोर करें"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षादाता"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा प्राप्त की गई/देखी गई"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान सदस्य द्वारा की गई समीक्षाएं"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिका"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव सहेजें"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाइनरी स्केल"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-पॉइंट स्केल"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-पॉइंट स्केल"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-पॉइंट स्केल"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवृत्ति का चयन करें"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम दिखाएं"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक दिखाएं"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छोड़ें"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पॉटलाइट"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटअप"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजें"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण प्रतिक्रिया"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न सहेजे गए बदलाव किए गए"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट करें"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वजन"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["आपको Zendesk QA में शामिल होने के ", _interpolate(_list(0)), " लिए आमंत्रित किया गया है।"])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["उपयोगकर्ता व्यवस्थापन केंद्र से स्वचालित रूप से कनेक्ट हो जाते हैं। ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को प्रबंधित करें"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता प्रबंधन के बारे में जानें"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कुछ उपयोगकर्ता आपके पहचान प्रदाता से स्वचालित रूप से प्रोविज़न और सिंक किए जाते हैं। ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "\" की खाता अनुमति बदल गई"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता अनुमतियों के बारे में जानें"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुझसे फिर से मत पूछो"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["उपयोगकर्ता पृष्ठ पर किए गए सभी परिवर्तन कार्यस्थानों को दर्शाते हैं।", _interpolate(_named("help2")), " ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसका मतलब है कि Zendesk QA के कुछ हिस्सों तक उपयोगकर्ता की पहुंच बदल सकती है"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता स्तर अनुमति बदलें"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " उपयोगकर्ता हटाया गया"]), _normalize([_interpolate(_named("n")), " हटाए गए उपयोगकर्ता"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["जब आप किसी उपयोगकर्ता को निकालते हैं, तो वे आपके खाते का एक्सेस खो देंगे। उनकी समीक्षा, टिप्पणियां और मूल्यांकन Zendesk QA में रहेंगी। ", _interpolate(_named("linebreak")), "किसी उपयोगकर्ता को निकालने से आपकी सदस्यता में सीटों की संख्या प्रभावित नहीं होगी।"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["इस उपयोगकर्ता को आपके द्वारा प्रबंधित किए जाने वाले सभी कार्यस्थानों से निकाल दिया जाएगा। ", _interpolate(_named("linebreak")), " उनकी समीक्षा, टिप्पणियां और मूल्यांकन बरकरार रहेंगे।"]), _normalize(["उन्हें आपके द्वारा प्रबंधित किए जाने वाले सभी कार्यस्थानों से निकाल दिया जाएगा। ", _interpolate(_named("linebreak")), " उनकी सभी समीक्षाएं, टिप्पणियां और मूल्यांकन बरकरार रहेंगे।"])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["निकालें ", _interpolate(_named("name"))]), _normalize(["उपयोगकर्ताओं को ", _interpolate(_named("name")), " निकालें"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब कुछ देख सकते हैं और सभी सेटिंग्स और बिलिंग प्रबंधित कर सकते हैं"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब कुछ देख सकते हैं और बिलिंग को छोड़कर व्यक्तिगत और कार्यस्थान सेटिंग्स प्रबंधित कर सकते हैं"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता अनुमतियां"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान-विशिष्ट अनुमतियाँ उपयोगकर्ता को उन कार्यस्थानों में डेटा देखने (और) संपादित करने की अनुमति देती हैं, जिनमें वे हैं।"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA के बारे में जानें"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके बॉट यहां दिखाई देते हैं क्योंकि उनके द्वारा भाग ली गई बातचीतें सिंक की गई हैें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई बॉट सिंक नहीं किया गया"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी बॉट को उपयोगकर्ता के रूप में चिह्नित करने से उन्हें साइन-इन का एक्सेस मिल जाएगा। उनका ऐतिहासिक बॉट डेटा डैशबोर्ड पर उपलब्ध रहेगा।"])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा कोई बॉट नहीं मिला"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी भिन्न कीवर्ड या वर्तनी का उपयोग करके फिर से खोजने का प्रयास करें।"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट्स खोजें"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट नाम से खोजें"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता के रूप में चिह्नित करें"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल व्यवस्थापक और खाता प्रबंधक ही बॉट्स को उपयोगकर्ताओं के रूप में चिह्नित कर सकते हैं"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट का नाम"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम चैट"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैन्युअल रूप से चिह्नित"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा योग्य"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट को ऑटो-विश्लेषण और किसी भी असाइनमेंट से हटा दिया जाता है। नया डेटा डैशबोर्ड में दिखाई नहीं देगा, जिससे सटीक रिपोर्टिंग और आसान बॉट प्रबंधन सुनिश्चित होगा।"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जनरेटिव बॉट"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्कफ़्लो बॉट"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूहों में जोड़ें"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समूह"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समूह नहीं"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूहों से निकालें"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह चुनें"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते की अनुमति"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान में जोड़ें"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full नाम"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम सक्रिय"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता स्तर की भूमिका"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक उपयोगकर्ता को एक खाता और/या कार्यस्थान स्तर की अनुमति असाइन की जाती है। व्यवस्थापक अनुमतियाँ अब खाता स्तर हैं, जबकि अन्य अनुमतियाँ कार्यस्थान स्तर पर हैं।"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान से निकालें"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान अनुमति"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह में प्रबंधित अनुमतियाँ"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण संपादित करें"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट के रूप में चिह्नित करें"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी उपयोगकर्ता को बॉट के रूप में चिह्नित करने से साइन-इन तक उनकी पहुंच समाप्त हो जाएगी। उनका ऐतिहासिक उपयोगकर्ता डेटा डैशबोर्ड पर उपलब्ध रहेगा।"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑटो-विश्लेषण और किसी भी असाइनमेंट से बॉट को हटा दें। नया डेटा डैशबोर्ड में दिखाई नहीं देगा, जिससे सटीक रिपोर्टिंग और आसान बॉट प्रबंधन सुनिश्चित होगा।"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट को समीक्षाओं से बाहर करें"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता को बॉट के रूप में चिह्नित किया गया"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल अनुमति प्रकार उपयोगकर्ता को बॉट के रूप में चिह्नित किया जा सकता है"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकाधिक खातों वाले उपयोगकर्ताओं को बॉट के रूप में चिह्नित नहीं किया जा सकता है"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता द्वारा खोजें"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता, बॉट और कार्यस्थान"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता और कार्यस्थान"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्‍यवस्‍थापक"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुमति नहीं है"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्ट किया गया"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैन्युअल रूप से जोड़ा गया"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित आमंत्रण"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM उपयोगकर्ता"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई की जरूरत है"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... और एक अधिक"]), _normalize(["... और ", _interpolate(_named("n")), " अधिक"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति को इसमें बदलें"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " लंबित आमंत्रण उपयोगकर्ता की भूमिका बदलने के लिए निकालें और पुनः आमंत्रित करें।"]), _normalize([_interpolate(_named("n")), " लंबित आमंत्रण उपयोगकर्ताओं की भूमिका बदलने के लिए निकालें और पुनः आमंत्रित करें"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " के लिए विवरण संपादित करें"]), _normalize([_interpolate(_named("n")), " चयनित उपयोगकर्ताओं के लिए विवरण संपादित करें"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " उपयोगकर्ता अपडेट किया गया।"]), _normalize([_interpolate(_named("n")), " उपयोगकर्ताओं को अपडेट किया गया।"])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["इस समूह में किसी अन्य कार्यस्थान से 1 उपयोगकर्ता है।"]), _normalize(["इस समूह में अन्य कार्यस्थान के ", _interpolate(_named("n")), " उपयोगकर्ता हैं।"])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह संग्रहीत किया गया"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहीत समूह"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह बनाएँ"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह बनाया गया"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्षकहीन समूह"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस समूह को स्थायी रूप से हटाना चाहते हैं?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह हटाएँ"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह हटाएँ"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह संपादित करें"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह लीड"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह की भूमिका"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह छिपा हुआ"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह समूह Zendesk QA में कहीं और दिखाई नहीं देगा"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल कार्यस्थान अनुमतियाँ प्रबंधित करें"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA में दिखाई नहीं देने वाला समूह"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लीड"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्षकहीन समूह"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य समूह का पहले से ही यह नाम है"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहीत समूह में पहले से ही यह नाम है"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी भूमिका केवल इस समूह के सदस्यों को प्रबंधित करने की अनुमति देती है। आगे के परिवर्तनों के लिए उच्च अनुमतियों वाले उपयोगकर्ता से संपर्क करें।"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएँ"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह लीड के रूप में निकालें"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह फिर से स्टोर किया गया"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह सहेजा गया"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह लीड के रूप में सेट करें"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह असंग्रहीत किया गया"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं सहेजे गए परिवर्तन"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने मौजूदा उपयोगकर्ताओं को इस समूह में जोड़ें"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम या ईमेल से खोजें"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य जोड़ें"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" समूह दृश्यमान"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["प्रत्येक सदस्य ", _interpolate(_named("workspace_role")), " है"])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूहों के बारे में जानें"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूहों की कुछ विशेषताएँ आपके पहचान प्रदाता से स्वचालित रूप से प्रोविज़न और सिंक्रनाइज़ की जाती हैं।"])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूहों के बारे में जानें"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह बनाएँ"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आसानी से अपनी टीम के सदस्यों को व्यवस्थित करें और अपनी संगठनात्मक संरचना की नकल करें।"])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम से कम 1 सदस्य जोड़ें"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समूह नाम जोड़ें"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस नाम का एक समूह पहले से मौजूद है"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह के सदस्य"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह का नाम"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी टीम के सदस्यों को व्यवस्थित करें और अपनी संगठनात्मक संरचना की नकल करें।"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["कोई समूह नहीं"]), _normalize(["1 समूह"]), _normalize([_interpolate(_named("count")), " समूह"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक नया समूह बनाएं या इसके बजाय एक संग्रहीत समूह को सक्रिय करें।"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सक्रिय समूह उपलब्ध नहीं है"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संग्रहीत समूह नहीं"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा कोई समूह नहीं मिला"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी भिन्न कीवर्ड या वर्तनी का उपयोग करके फिर से खोजने का प्रयास करें।"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समूह नहीं जोड़ा गया"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["इन में"]), _normalize(["में"]), _normalize(["में"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह के नाम से खोजें"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहित"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " संभव पुनः भेजें"])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " सीटें बची"]), _normalize([_interpolate(_named("n")), " सीट बची"]), _normalize([_interpolate(_named("n")), " सीटें बची"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी सीट की संख्या वर्तमान में सीमित है, क्योंकि आपकी सदस्यता रूकी हुई है। अधिक उपयोगकर्ताओं को जोड़ने के लिए इसे पुनः सक्रिय करें।"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप अपने खाते की सीटों की सीमा तक पहुँच गए हैं। आप किसी सक्रिय उपयोगकर्ता को निष्क्रिय करके या अधिक सीटें जोड़कर उपयोगकर्ताओं को बदल सकते हैं।"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान विवरण देखें"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " चयनित"]), _normalize([_interpolate(_named("n")), " चयनित"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " उपयोगकर्ता चयनित"]), _normalize([_interpolate(_named("n")), " उपयोगकर्ता चयनित"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " पंक्तियाँ"]), _normalize([_interpolate(_named("n")), " पंक्ति"]), _normalize([_interpolate(_named("n")), " पंक्तियाँ"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को उनके नाम या ईमेल पतों से बल्क में खोजें।"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अल्पविराम या नई लाइनों से अलग करके यहां डेटा चिपकाएं"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को बल्क में खोजें"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " के लिए कार्यस्थान अनुमतियाँ संपादित करें"]), _normalize([_interpolate(_named("n")), " चयनित सदस्यों के लिए कार्यस्थान अनुमतियाँ संपादित करें"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमतियां संपादित करें"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान अनुमति का चयन करें"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["कार्यस्थान से ", _interpolate(_named("name")), " निकालें"]), _normalize(["कार्यस्थान से ", _interpolate(_named("name")), " सदस्यों को निकालना"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " सदस्य को हटाया गया"]), _normalize([_interpolate(_named("n")), " सदस्य हटाए गए"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["सदस्य निकालें"]), _normalize(["सदस्यों को निकालें"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["इस उपयोगकर्ता को कार्यस्थान से निकाल दिया जाएगा।"]), _normalize(["इन उपयोगकर्ताओं को कार्यस्थान से निकाल दिया जाएगा।"])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "\" की कार्यस्थान अनुमति बदल गई है।"])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य जोड़ें"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत और संबंधित समीक्षाएँ देखें"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान में सब कुछ देखें, लेकिन केवल क्विज़, असाइनमेंट, तर्क-वितर्क और कैलिब्रेशन/जांच सत्र प्रबंधित करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान अनुमतियाँ"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान के भीतर सब कुछ देखें और प्रबंधित करें"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान सेटिंग्स को छोड़कर, कार्यस्थान में सब कुछ देखें"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को कनेक्ट करें"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा प्रबंधित कार्यस्थानों के सभी उपयोगकर्ता इस कार्यस्थान में जोड़े जा चुके हैं।"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी व्यवस्थापक से ऐसा करने के लिए कहें।"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए उपयोगकर्ताओं को सीधे स्वयं आमंत्रित करें या किसी व्यवस्थापक से ऐसा करने के लिए कहें।"])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " या ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित करें"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई उपयोगकर्ता नहीं"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " उपयोगकर्ता जोड़ा गया"]), _normalize([_interpolate(_named("n")), " उपयोगकर्ताओं को जोड़ा गया"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["सदस्यों को जोड़ें"]), _normalize(["सदस्य जोड़ें"]), _normalize([_interpolate(_named("n")), " सदस्यों को जोड़ें"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान अनुमति का चयन करें"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान के लिए उपयोगकर्ताओं का चयन करें"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " में सदस्यों को जोड़ें"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई लाइनों या अल्पविराम से अलग किए गए ईमेल यहां पेस्ट करें"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["मेल खाती ", _interpolate(_list(0)), "/", _interpolate(_list(1)), "ईमेल"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" पहले से जुड़े उपयोगकर्ताओं के साथ:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["मेल नहीं खा सकी ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " ईमेल"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तनी जाँचना या बाद में उन्हें अलग-अलग कनेक्ट करना"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" सहायता डेस्क उपयोगकर्ताओं के साथ:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " उपयोगकर्ता को कनेक्ट किया गया"]), _normalize([_interpolate(_named("n")), " उपयोगकर्ता कनेक्ट किए गए"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " उपयोगकर्ता आमंत्रित"]), _normalize([_interpolate(_named("n")), " आमंत्रित उपयोगकर्ता"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बल्क कनेक्ट"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक आमंत्रण"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण के लिए:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उनकी इंटरेक्शन की समीक्षा करने के लिए अपने सहायता डेस्क से कनेक्ट करें"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["उपयोगकर्ता कनेक्ट करें"]), _normalize(["उपयोगकर्ताओं को कनेक्ट करें"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अलग-अलग कनेक्ट करें"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप चाहते हैं कि आपके कनेक्टेड उपयोगकर्ता Zendesk QA तक पहुंचें, तो आप उन्हें यहां आमंत्रित कर सकते हैं।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्ट किए गए उपयोगकर्ताओं को आमंत्रित करें"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["उपयोगकर्ता को आमंत्रित करें"]), _normalize(["उपयोगकर्ताओं को आमंत्रित करें"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित करना छोड़ें"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को उनकी इंटरेक्शन की समीक्षा करने के लिए अपने सहायता डेस्क से कनेक्ट करने के लिए चुनें"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी सदस्यता प्रबंधित करें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क उपयोगकर्ताओं को कनेक्ट करें"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने कार्यस्थान को एक अद्वितीय नाम दें"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि सक्षम किया जाता है, तो सभी मौजूदा खाता कनेक्शन स्वचालित रूप से नए कार्यस्थान में जुड़ जाते हैं।"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान में सभी कनेक्शन जोड़ें"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान बनाएं"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान का नाम"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान बनाएं"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीधे खाते में जोड़े गए उपयोगकर्ताओं (कार्यस्थान असाइन किए बिना) को खाता-स्तरीय भूमिका असाइन की जाती है। आप उन्हें बाद में कार्यस्थानों में जोड़ सकते हैं।"])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित उपयोगकर्ताओं को खाता स्तर पर स्वचालित रूप से एक उपयोगकर्ता भूमिका असाइन की जाती है। आप उन्हें बाद में व्यवस्थापक भूमिका में अपग्रेड कर सकते हैं।"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ये सब काम करते हैं"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई लाइनों या अल्पविराम द्वारा अलग किए गए ईमेल यहां पेस्ट करें।"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " उपयोगकर्ता ईमेल द्वारा आमंत्रित"]), _normalize([_interpolate(_named("n")), " ईमेल द्वारा आमंत्रित उपयोगकर्ता"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान में जोड़े गए उपयोगकर्ताओं को खाता स्तर पर स्वचालित रूप से एक उपयोगकर्ता भूमिका असाइन की जाती है। आप उन्हें बाद में व्यवस्थापक भूमिका में अपग्रेड कर सकते हैं।"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य जोड़ें"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को कार्यस्थान में जोड़े बिना केवल खाते में आमंत्रित करें"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित उपयोगकर्ताओं को केवल एक खाता अनुमति असाइन की जाती है और किसी भी कार्यस्थान में नहीं जोड़ा जाता है।"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को सीधे कार्यस्थान में आमंत्रित करें और कार्यस्थान अनुमतियाँ असाइन करें"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान में उपयोगकर्ताओं को जोड़ना"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक आमंत्रण"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत रूप से आमंत्रित करें"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल खाते में आमंत्रित करें"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी कार्यस्थान में आमंत्रित करें"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["उपयोगकर्ताओं को आमंत्रित करें"]), _normalize(["उपयोगकर्ता को आमंत्रित करें"]), _normalize([_interpolate(_named("n")), "उपयोगकर्ताओं को आमंत्रित करें"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल के माध्यम से उपयोगकर्ताओं को सामूहिक आमंत्रण दें"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["उपयोगकर्ता हटाएं"]), _normalize(["उपयोगकर्ताओं को हटाएं"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अचयनित"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल के माध्यम से उपयोगकर्ताओं को आमंत्रित करें"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति का चयन करें"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रण फिर से भेजें"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्ट किए गए उपयोगकर्ता"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता आमंत्रण के लिए लंबित ईमेल"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM उपयोगकर्ता"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान का चयन करें"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI एजेंट"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्‍यवस्‍थापन केंद्र"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुणवत्ता का आश्वासन"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यबल प्रबंधन"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["पूर्ण ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["पूर्ण ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["पूर्ण ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["चक्र ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाकर्ता"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असाइनमेंट संपादित करें"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय बचाएं और समीक्षा करने के लिए मैन्युअल रूप से बातचीत को खोजने और आवंटित करने के बजाय स्वचालित समीक्षा असाइनमेंट सेट करें।"])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["देय ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र बातचीत दृश्य के साइड मेनू में 'कैलिब्रेशन' अनुभाग के तहत जोड़ा जा सकता है।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई सत्र नहीं जोड़ा गया"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेसलाइन"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सत्र नहीं"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत दृश्य के हेडर में कैलिब्रेशन आइकन पर क्लिक करके बातचीत को सत्र में जोड़ा जा सकता है।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई बातचीत नहीं जोड़ी गई"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कोई समीक्षा नहीं दी गई है। ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक कैलिब्रेशन समीक्षा छोड़ें"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल कारण"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पर जाएँ"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमांड पैलेट लोड करने में विफल।"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ नहीं मिला।"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID द्वारा मिला"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमांड पैलेट लोड हो रही है..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पेज, फ़िल्टर, बातचीत, सेटिंग्स..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बातचीत"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निजी फ़िल्टर"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सार्वजनिक फ़िल्टर"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " से"])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने API से"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), " ", _interpolate(_named("label")), " में से ", _interpolate(_named("rangeStart")), " से ", _interpolate(_named("rangeEnd")), " दिखाया जा रहा है"]), _normalize([_interpolate(_named("total")), " ", _interpolate(_named("label")), " में से ", _interpolate(_named("rangeStart")), " से ", _interpolate(_named("rangeEnd")), " दिखाया जा रहा है"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइडपैनल छुपाएं"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइडपैनल दिखाएं"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइडबार टॉगल करें"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क स्वीकार किए जाते हैं"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क खारिज कर दिए गए"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुले तर्क-वितर्क"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क आंशिक रूप से स्वीकार किया गया"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ पूर्ण की गईं"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षा प्राप्त की गई"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देखी गई समीक्षाएँ"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार किया गया"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोला गया"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से स्वीकार किया गया"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["कुल का ", _interpolate(_list(0))])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार किया गया"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार किया गया"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुला"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से स्वीकार किया गया"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार किया गया"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["समीक्षा"]), _normalize(["समीक्षाएँ"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उनकी समीक्षाओं की संख्या जो समीक्षादाता द्वारा देखी गई है"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उनकी समीक्षाओं की संख्या जो देखी गई है"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क करने वाले समीक्षाकर्ता"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तर्क-वितर्क करने वाले"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी के अनुसार तर्क-वितर्क"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय के साथ तर्क-वितर्क"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुला"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत समाधान समय"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत समाधान समय (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत समाधान समय (मिनट)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हल किया गया"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल तर्क-वितर्क"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग चुनें"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार किया गया"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया की भाषा"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई भाषा नहीं मिली"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी भाषाएं"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमानित ड्राइवर"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई ड्राइवर नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी ड्राइवर"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्डक्लाउड शब्द"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई शब्द नहीं मिले"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी शब्द"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उल्लेख"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नवीनतम"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे पुराना"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुला"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से स्वीकार किया गया"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ने ", _interpolate(_list(1)), " के साथ प्रतिक्रिया व्यक्त की"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार किया गया"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हल किया गया"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समीक्षाएँ"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्वचा का रंग चुनें"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्र"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सत्र नहीं मिला"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई श्रेणियाँ नहीं मिलीं"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणियाँ"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी का आकार"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आकार नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी आकार"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई फ़िल्टर नहीं मिला"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समूह नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी समूह"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई हैशटैग नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी हैशटैग"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई मान नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मान"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कस्टम फ़ील्ड नहीं मिली"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहायता डेस्क टैग"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सहायता डेस्क टैग नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सहायता डेस्क टैग"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम लोड हो रहे हैं..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नों के प्रकार"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रश्न प्रकार नहीं मिला"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया जोड़ें"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोरकार्ड"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्कोरकार्ड नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्कोरकार्ड"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्कोर नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्कोर"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्रोत नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी स्रोत"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रिया का कारण"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कारण नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कारण"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वेक्षण"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सर्वेक्षण नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सर्वेक्षण"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चैनल नहीं मिले"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी चैनल"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई उपयोगकर्ता नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी उपयोगकर्ता"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई कार्यस्थान नहीं मिला"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी कार्यस्थान"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी पर वापिस जाएं"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी हटा दी गई है या अभी तक प्रकाशित नहीं हुई है। लिंक को दोबारा जांचें या प्रश्नोत्तरी सूची पर वापिस नेविगेट करें।"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह प्रश्नोत्तरी उस कार्यक्षेत्र में साझा नहीं की गई है जिससे आप संबंधित हैं। किसी व्यवस्थापक से आपको उस कार्यस्थान में जोड़ने के लिए कहें जिसकी इस प्रश्नोत्तरी तक पहुँच है।"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी अनुपलब्ध है"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उन कार्यस्थानों को प्रश्नोत्तरी की पहुंच दी गई जिनसे आप संबंधित नहीं हैं। प्रश्नोत्तरी सहेजने और प्रकाशित करने में असमर्थ।"])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी व्यवस्थापक से प्रश्नोत्तरी को सहेजने और प्रकाशित करने के लिए कहें।"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको कम से कम एक उत्तर को सही के रूप में चिह्नित करना चाहिए।"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस फ़ील्ड को खाली नहीं छोड़ा जा सकता"])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप वर्तमान में प्रश्नोत्तरी का पूर्वावलोकन देख रहे हैं।"])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें कि कौन-से कार्यस्थान प्रश्नोत्तरी एक्सेस कर सकते हैं। कई कार्यस्थानों से संबंधित उपयोगकर्ता केवल एक बार प्रश्नोत्तरी का उत्तर दे सकते हैं। प्रश्नोत्तरी प्रकाशित होने के बाद \"सभी कार्यस्थान\" का चयन करने से नए बनाए गए कार्यस्थान शामिल नहीं होंगे।"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी पहुंच"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहीत करें"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन मोड से बाहर निकलें"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात करें"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन करें"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्न"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रियाएँ"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी लें"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी प्रकाशित करें"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी सेटिंग्स"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ्ट के रूप में सहेजें"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई क्विज़ बनाएँ"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असंग्रहित करें"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विकल्प"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विकल्प जोड़ें"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसे साझा करें"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अच्छा लग रहा है, है ना?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका उत्तर भेज दिया गया है"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी प्रश्नोत्तरी सहेज ली गई है"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी प्रश्नोत्तरी प्रकाशित हो चुकी है"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी प्रश्नोत्तरी हटा दी गई है"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्न जोड़ें"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी संग्रहीत करें"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संग्रहीत प्रश्नोत्तरी और इसके परिणाम अब प्रश्नोत्तरी की सूची में दिखाई नहीं देंगे। आप अभी भी एक संग्रहीत प्रश्नोत्तरी के परिणामों तक पहुंच सकते हैं और जब भी आवश्यकता हो इसे असंग्रहित कर सकते हैं।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी संग्रहीत करें"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित करें"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक बार प्रकाशित होने के बाद, प्रश्नोत्तरी को बदला या संपादित नहीं किया जा सकता है। सभी प्रकाशित प्रश्नोत्तरियां आपके खाते के प्रत्येक उपयोगकर्ता के लिए सुलभ होंगी।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी प्रकाशित करें?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई इस प्रश्नोत्तरी को हटाना चाहते हैं? इस क्रिया को पूर्ववत् नहीं किया जा सकता है।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी हटाएं?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक बार भेजने के बाद, आपके उत्तरों को बदला नहीं जा सकता है।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी सबमिट करनी है?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असंग्रहित प्रश्नोत्तरी"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी को असंग्रहित करने से यह प्रश्नोत्तरी संक्षिप्त विवरण में उपलब्ध हो जाता है। यह पिछले प्रतिभागियों को अपने परिणामों तक पहुंचने और नए प्रतिभागियों को प्रश्नोत्तरी लेने की अनुमति देगा।"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असंग्रहित प्रश्नोत्तरी"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट प्रश्न"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण (वैकल्पिक)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्न को नीचे ले जाएँ"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्न ऊपर ले जाएँ"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्न"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन में केवल उत्तर वाले प्रश्न प्रदर्शित किए जाते हैं।"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्न निकालें"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी भेजें"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्षकहीन प्रश्नोत्तरी"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी शीर्षक"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह प्रश्नोत्तरी और उसके परिणामों को स्थायी रूप से हटा देगा।"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैकल्पिक रूप से, आप अभी भी प्रश्नोत्तरी को संग्रहीत करके परिणामों तक पहुँच सकते हैं।"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर दिया गया"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी हटाएं"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह प्रश्नोत्तरी और उसके परिणामों को स्थायी रूप से हटा देगा।"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["प्रश्नोत्तरी हटाएं ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी टीम के आत्मविश्वास और कौशल को बढ़ावा देने, लगातार अनुभव प्रदान करने और टीम के नए सदस्यों को ऑनबोर्ड करने में सहायता करते हैं।"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप प्रश्नोत्तरी के नाम या लेखक के नाम से प्रश्नोत्तरी खोज सकते हैं।"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई प्रश्नोत्तरी नहीं बनाई गई है"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी की निर्यात सूची"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत स्कोर"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण करने का तारीख"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी/लेखक"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी / लेखक / कार्यस्थान"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित किया गया"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी, लेखक द्वारा खोजें"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहित"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्राफ़्ट"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी प्रश्नोत्तरियां"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी संग्रहीत की गई है।"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी को असंग्रहीत कर दिया गया है।"])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस प्रश्नोत्तरी को चयनित कार्यस्थानों में उपयोगकर्ताओं से कोई प्रतिक्रिया नहीं मिली।"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस प्रश्नोत्तरी को संग्रहीत होने से पहले कोई प्रतिक्रिया नहीं मिली।"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रतिक्रिया नहीं"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहां आपको प्रश्नोत्तरी के उत्तरों का ब्रेकडाउन दिखाई देगा।\nअभी तक किसी ने भी आपकी प्रश्नोत्तरी नहीं भरी है - इसे साझा करने से निश्चित रूप से सहायता मिलेगी।"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी तक कोई प्रतिक्रिया नहीं मिली"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिक्रियाएं:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["औसत स्कोर:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अलग-अलग"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षिप्त विवरण"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - कॉपी करें"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक कॉपी किया गया"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई परिणाम नहीं मिला"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " की प्रश्नोत्तरी"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप प्रतिभागी के नाम के लिए लीडरबोर्ड खोज सकते हैं।"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात लीडरबोर्ड"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्कोर"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिभागी द्वारा खोजें"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लीडरबोर्ड"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रश्नोत्तरी"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टॉगल लीडरबोर्ड"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसका मतलब है कि आप डैशबोर्ड पर इस हैशटैग के लिए फ़िल्टर नहीं कर पाएंगे और यह अब टिप्पणियों पर स्वतः पूर्ण नहीं होगा। यह क्रिया अपरिवर्तनीय है।"])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग, हैशटैग, हैशटैग, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग बनाएं"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग बनाएं"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग बनाएं"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यों को नए हैशटैग बनाने की अनुमति दें"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस्तेमाल किया"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग हटाएं"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["हैशटैग हटाएं ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग निर्माण लॉक कर दिया गया है"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग निर्माण अनलॉक कर दिया गया है"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["हैशटैग '", _interpolate(_list(0)), "' हटाया गया"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["हैशटैग '", _interpolate(_list(0)), "' का नाम बदलकर '", _interpolate(_list(1)), "' कर दिया गया"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 हैशटैग जोड़ा गया"]), _normalize([_interpolate(_named("count")), " हैशटैग जोड़े गए"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया नाम"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई हैशटैग नहीं बनाया गया"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम बदलें"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["हैशटैग \"", _interpolate(_named("tagRenamed")), "\" का नाम बदलें"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अल्पविराम या लाइन ब्रेक के साथ अलग हैशटैग"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैशटैग खोजें"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कोई चिंता नहीं। आप ऐसा कर सकते हैं ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल्कुल यहीं"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यस्थान से लिंक किया गया कनेक्शन"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन संलग्न करें"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस कार्यस्थान से एक कनेक्शन जोड़ें"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ कोई भी परिवर्तन करने के लिए अपने व्यवस्थापक से संपर्क करें"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहां कोई भी बदलाव करने के लिए Zendesk QA से संपर्क करें"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते में कोई कनेक्शन नहीं"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐसा लगता है कि आपने अभी तक ग्राहक सेवा प्लेटफ़ॉर्म के साथ एकीकृत नहीं किया है।"])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कनेक्शन हटाने से कार्यस्थान ", _interpolate(_named("workspaceName")), " से बातचीतें निकाल दी जाएँगी और समीक्षाओं और तर्क-वितर्क सहित उनसे संबंधित सभी डेटा हटा दिए जाएँगे।"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन हटाएँ"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["कनेक्शन हटाएँ ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी कनेक्शन का चयन करें"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["चुनी गई समयावधि के भीतर, कुछ सक्रिय AutoQA श्रेणियां आपके स्कोरकार्ड पर मौजूद नहीं थीं। हो सकता है कि इन श्रेणियों के लिए ", _interpolate(_named("date")), " को आपके अंतिम परिवर्तन से पहले की बातचीत का विश्लेषण न किया गया हो।"])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह वह जगह है जहां आपको दी गई सभी प्रतिक्रिया दिखाई देगी। अभी दिखाने के लिए कुछ नहीं है"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षा प्राप्त नहीं हुई।"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल टिप्पणी गतिविधि दिखाएं"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह वह जगह है जहां आपके द्वारा दी गई सभी प्रतिक्रिया दिखाई देगी। बातचीत दृश्य में आरंभ करें।"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई समीक्षा नहीं दी गई।"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई समीक्षाएँ"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त समीक्षाएँ"])}
  }
}