import dayjs, { type Dayjs } from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import weekday from 'dayjs/plugin/weekday'

import i18n, { getLocale } from '@/i18n'
import { getPluralizationKey } from '@/i18n/utils'
import { session } from '@/composables/useSession'

dayjs.extend(updateLocale)
dayjs.extend(localeData)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(weekday)

const localeList = dayjs.Ls

export const DATE_FORMAT_FULL_INTL: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  weekday: 'short',
}

export function weekStartsOnMonday() {
  return dayjs.localeData().firstDayOfWeek() === 1
}

export function setWeekStart(value: 'MONDAY' | 'SUNDAY') {
  dayjs.updateLocale('en', { weekStart: value === 'MONDAY' ? 1 : 0 })
}

export function getTimePast(d: string): string {
  const rawDate = d.substring(0, d.length > 24 ? d.length - 4 : d.length)
  const rawDateUTC = `${rawDate}${rawDate.endsWith('Z') ? '' : 'Z'}`
  const date = new Date(rawDateUTC)
  const timeDifference = dayjs(date).from(new Date())

  if (timeDifference.startsWith('1s')) return i18n.t('universal.duration.now')

  return timeDifference
}

export function formatDateIntl(date: string | Date, options?: Intl.DateTimeFormatOptions) {
  if (!date) return ''
  const dateToFormat = typeof date === 'string' ? new Date(date) : date
  const timeFormat = { hour12: session?.account?.timeFormat === 'TWELVE_HOUR' }
  const formattedOptions = options || { dateStyle: 'medium', timeStyle: 'short' }
  return Intl.DateTimeFormat(getLocale(), { ...formattedOptions, ...timeFormat }).format(dateToFormat)
}

export function formatDateByYearIntl(date: string | Date, format: Intl.DateTimeFormatOptions = DATE_FORMAT_FULL_INTL) {
  const dateToFormat = typeof date === 'string' ? new Date(date) : date

  const currentYear = new Date().getFullYear()
  const targetYear = new Date(dateToFormat).getFullYear()

  const timeFormat = { hour12: session?.account?.timeFormat === 'TWELVE_HOUR' }

  const formatOptions = (
    currentYear > targetYear ? { year: 'numeric', ...format } : format
  ) as Intl.DateTimeFormatOptions

  return Intl.DateTimeFormat(getLocale(), { ...formatOptions, ...timeFormat }).format(dateToFormat)
}

export function getMonthsPassed(start: Dayjs, end: Dayjs) {
  const yearsPassed = Number(end.format('YYYY')) - Number(start.format('YYYY'))
  const monthsPassed = Number(end.format('M')) - Number(start.format('M'))
  return yearsPassed * 12 + monthsPassed
}

export const maxFourDigitYearDate: Date = new Date(2050, 11, 31)

export function relativeTimeLater() {
  const config = {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'ss', r: 59, d: 's' },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
    ],
  }
  dayjs.extend(relativeTime, config)

  dayjs.updateLocale('en', {
    relativeTime: {
      ...localeList['en'].relativeTime,
      future: '%s later',
      past: '%s',
      s: '1s',
      ss: '%ds',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
    rounding: Math.floor,
  })
}

export function millisecondsToHMS(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const totalMinutes = Math.floor(totalSeconds / 60)
  const totalHours = Math.floor(totalMinutes / 60)

  return [totalHours || undefined, totalMinutes % 60, totalSeconds % 60]
    .filter((i) => i !== undefined)
    .map((i) => `0${i}`.slice(-2))
    .join(':')
}

export type TimeUnit = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second'

const relativeTimeUnits: Record<TimeUnit, number> = {
  year: 24 * 60 * 60 * 365,
  month: (24 * 60 * 60 * 365) / 12,
  day: 24 * 60 * 60,
  hour: 60 * 60,
  minute: 60,
  second: 1,
}

export type GetDurationTimeUnit = Extract<TimeUnit, 'day' | 'hour' | 'minute'>

export function getDuration(
  targetDate: string | Date,
  startDate: string | Date,
  acceptedTimeUnit: GetDurationTimeUnit[] = ['day', 'hour', 'minute'],
) {
  const target = typeof targetDate === 'string' ? new Date(targetDate) : targetDate
  const start = typeof startDate === 'string' ? new Date(startDate) : startDate
  const diffInSeconds = Math.floor((target.getTime() - start.getTime()) / 1000)

  const unit: GetDurationTimeUnit =
    (acceptedTimeUnit.find((u) => {
      return Math.abs(diffInSeconds) >= relativeTimeUnits[u as GetDurationTimeUnit]
    }) as GetDurationTimeUnit | undefined) ?? acceptedTimeUnit[acceptedTimeUnit.length - 1]

  return { duration: Math.floor(diffInSeconds / relativeTimeUnits[unit]), unit }
}

/**
 * Format the duration left between the target date and an optional start date.
 * The string will be formatted to use the highest relevant time unit.
 * E.g. it will use 'day' if the duration is more than 1 day.
 *
 * @param options.startDate the start date to calculate the duration from. Defaults to current date.
 * @param options.acceptedTimeUnit Array of accepted time units to use. Defaults to ['day', 'hour', 'minute']
 */
export function formatDurationLeft(
  targetDate: string | Date,
  options: { startDate?: string | Date; acceptedTimeUnit?: GetDurationTimeUnit[] } = {
    acceptedTimeUnit: ['day', 'hour', 'minute'],
  },
) {
  let start: Date
  if (options?.startDate) {
    start = typeof options?.startDate === 'string' ? new Date(options.startDate) : options.startDate
  } else {
    start = new Date()
  }

  const { duration, unit } = getDuration(targetDate, start, options?.acceptedTimeUnit)
  const positiveDuration = Math.max(0, duration)

  const minutePluralKeyMap = {
    zero: 'tasks.time_left_duration.minutes.zero',
    one: 'tasks.time_left_duration.minutes.one',
    two: 'tasks.time_left_duration.minutes.two',
    few: 'tasks.time_left_duration.minutes.few',
    many: 'tasks.time_left_duration.minutes.many',
    other: 'tasks.time_left_duration.minutes.other',
  }

  const hourPluralKeyMap = {
    zero: 'tasks.time_left_duration.hours.zero',
    one: 'tasks.time_left_duration.hours.one',
    two: 'tasks.time_left_duration.hours.two',
    few: 'tasks.time_left_duration.hours.few',
    many: 'tasks.time_left_duration.hours.many',
    other: 'tasks.time_left_duration.hours.other',
  }

  const dayPluralKeyMap = {
    zero: 'tasks.time_left_duration.days.zero',
    one: 'tasks.time_left_duration.days.one',
    two: 'tasks.time_left_duration.days.two',
    few: 'tasks.time_left_duration.days.few',
    many: 'tasks.time_left_duration.days.many',
    other: 'tasks.time_left_duration.days.other',
  }

  const unitMap: Record<GetDurationTimeUnit, Record<Intl.LDMLPluralRule, string>> = {
    day: dayPluralKeyMap,
    hour: hourPluralKeyMap,
    minute: minutePluralKeyMap,
  }

  const pluralKeyMap = unitMap[unit] || minutePluralKeyMap
  const key = getPluralizationKey(pluralKeyMap, duration) ?? 'tasks.time_left_duration.minutes.zero'

  return i18n.t(key, { duration: positiveDuration })
}

export function formatRelativeTimeIntl(date: string | Date, options?: Intl.RelativeTimeFormatOptions): string {
  if (!date) return ''

  const targetDate = typeof date === 'string' ? new Date(date) : date
  const now = new Date()
  const diffInSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000)
  const rtf = new Intl.RelativeTimeFormat(getLocale(), options || { numeric: 'auto', style: 'narrow' })

  const unit =
    Object.keys(relativeTimeUnits).find((u) => Math.abs(diffInSeconds) > relativeTimeUnits[u as TimeUnit]) || 'second'
  return rtf.format(
    Math.round(diffInSeconds / relativeTimeUnits[unit as TimeUnit]),
    unit as Intl.RelativeTimeFormatUnit,
  )
}

export function getWeekDayIntl(date: string | Date, locale = 'en-US') {
  const sourceDate = typeof date === 'string' ? new Date(date) : date
  const dtf = new Intl.DateTimeFormat(locale, { weekday: 'long' })
  return dtf.format(sourceDate)?.toLowerCase()
}
