import { TicketDistributionCategory, AgentDistributionCategory } from '@/modules/conversations/types'

import IntelligenceGlobe from '@/assets/intelligence-globe.svg'
import IntelligenceTarget from '@/assets/intelligence-target.svg'
import IntelligenceSentiment from '@/assets/intelligence-sentiment.svg'
import IntelligenceSend from '@/assets/intelligence-send.svg'
import IntelligenceThumb from '@/assets/intelligence-thumb.svg'

export default (category: TicketDistributionCategory | AgentDistributionCategory) => {
  if (category === TicketDistributionCategory.Language) return IntelligenceGlobe
  if (category === TicketDistributionCategory.Complexity) return IntelligenceTarget
  if (category === TicketDistributionCategory.Sentiment) return IntelligenceSentiment

  if (category === AgentDistributionCategory.Performance) return IntelligenceSend
  if (category === AgentDistributionCategory.Csat) return IntelligenceThumb
  if (category === AgentDistributionCategory.Sentiment) return IntelligenceSentiment

  return
}
