export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange din Zendesk-underdomän"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in på Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt företag har ett befintligt konto."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå med i ett företag"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill gå med i ett befintligt företag måste du be din admin att skicka en inbjudan till dig."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be din administratör att bjuda in dig"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag ändrade mig."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt registrering"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt företag har ett befintligt konto. Vill du gå med i ditt företagskonto eller skapa ett nytt?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera instruktioner"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ett nytt konto"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns ett befintligt konto"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan inte få åtkomst till ditt Zendesk QA-konto förrän dina utestående abonnemangsfakturor har betalats."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt abonnemang är pausat"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto hittades inte. Försök igen."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skaffa ett nytt lösenord."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Försök igen eller ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel kombination av e-postadress och lösenord."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begär en ny från din admin."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna inbjudningslänk är ogiltig eller så har den redan använts."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " inloggningsmetoden är avaktiverad på ditt konto."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna inbjudan är ogiltig eller redan använd. Begär en ny av din administratör."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ladda inbjudningsinformation."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att registrera. Det kan hända att en användare med denna e-postadress redan finns."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt konto har blockerats efter flera inloggningsförsök i följd. Kontrollera din inkorg efter ett e-postmeddelande med instruktioner om hur du tar bort blockeringen."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiera din e-postadress först"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett giltigt e-postkonto"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält är obligatoriskt"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält är obligatoriskt"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningskod"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka den magiska länken"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress på arbetet"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-postadress på arbetet"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/se/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtal om huvudtjänster"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord matchar inte kraven"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekretessmeddelande"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/se/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänta"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hej ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gå med i organisationen för ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-postadress har verifierats. Nu kan du logga in med ditt nya konto nedan."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsätt med Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig inbjudningslänk"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med hjälp av magisk länk"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tillbaka för att logga in"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsätt med Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsätt med Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-postadress har verifierats. Nu kan du logga in med ditt nya konto nedan:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in via en magisk länk"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in på Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt konto har tagits bort"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du inget konto?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig kod"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält är obligatoriskt"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett giltigt e-postkonto"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält är obligatoriskt"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningskod"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alla inloggningsalternativ"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " med traditionella metoder"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med hjälp av magisk länk"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fått ett e-postmeddelande som innehåller din inloggningskod."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fått ett e-postmeddelande som du kan följa för att logga in."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel inträffade när den magiska länken skickades. Försök igen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in via e-post"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa lösenord"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett tecken med liten bokstav"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett tecken med stor bokstav"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt lösenord måste innehålla"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet får inte vara längre än 128 tecken"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lätt att komma ihåg, svårt att gissa"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minst 8 tecken"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ditt lösenord"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du glömt lösenordet?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En liten bokstav"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En siffra"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stor bokstav"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill återställa ditt lösenord anger du din e-postadress så att vi kan skicka ett e-postmeddelande med instruktioner till dig."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett giltigt e-postkonto"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält är obligatoriskt"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ lösenord"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ett e-postmeddelande har skickats till ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om e-postmeddelandet inte visas i din inkorg eller skräppostmapp bör du kontrollera e-postadressen du angav."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tillbaka för att logga in"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera din e-post"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ lösenord"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), " och sedan får du ett e-postmeddelande om återställning av lösenord."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om ditt konto har ett befintligt lösenord"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om denna ", _interpolate(_named("bold")), ", så har vi skickat en länk för återställning av lösenord."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadressen är kopplad till ett konto"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kom du ihåg ditt lösenord?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel inträffade när återställningslänken skickades. Försök igen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ ditt lösenord"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig via den magiska länken"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Integritetspolicy)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["När du registrerar dig innebär det att du samtycker till ", _interpolate(_named("terms_of_service")), " och ", _interpolate(_named("privacy_policy")), " för Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjänstevillkor"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har ett konto?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig via en magisk länk"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig med din e-postadress på arbetet"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig med Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig för Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig med Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag har ett aktivt Zendesk-konto"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Registrera dig eller logga in för att slutföra din konfiguration av ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är det verkligen du? Kontrollera din inbox för en bekräftelse via e-post."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du är redo"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA fungerar bäst på datorer. Logga in där för att börja granska konversationer."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boka en demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan också boka en demo med oss och vi kommer att visa dig vad du kan göra i Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din närvaro är efterfrågad"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avbryt"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jag ändrade mig och vill ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " bjuder in dig till deras konto."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå med i kontot"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ett nytt konto"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök logga in igen"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Den reaktion du fick på din feedback: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna reaktioner"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerade på din kommentar"]), _normalize(["Flera personer har reagerat på din kommentar"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerade på ditt svar"]), _normalize(["Flera personer har reagerat på ditt svar"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerade på din granskning"]), _normalize(["Flera personer har reagerat på din granskning"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentar"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svara"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskning"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVISTER"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIVNA"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTTAGNA"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback om undersökning"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktioner"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startade"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markera kommentaren som läst?"]), _normalize(["Vill du markera alla kommentarer som lästa?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markera tvisten som läst?"]), _normalize(["Vill du markera alla tvister som lästa?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markera feedback om undersökning som läst?"]), _normalize(["Vill du markera all feedback om undersökning som läst?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markera granskning som läst?"]), _normalize(["Markera alla granskningar som lästa?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerad kommentar som läst"]), _normalize(["Markerade alla kommentarer som lästa"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerade tvist som läst"]), _normalize(["Markerade alla tvister som lästa"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerade feedback om undersökning som läst"]), _normalize(["Markerade all feedback om undersökning som läst"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerade granskning som läst"]), _normalize(["Markerade alla granskningar som lästa"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera alla som lästa"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som läst"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskningar"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När någon bestrider en granskning visas den här."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga tvister"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När du tilldelas till att lösa en tvist visas den här."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mottagna tvister"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När du bestrider en granskning visas den här."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga startade tvister"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback du har fått visas här. Inget att visa just nu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottagen feedback"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla reaktioner på dina granskningar och svar visas här. Inget att visa just nu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mottagna reaktioner"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna en konversation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback som du lämnar hamnar här. Börja med att lämna en granskning i konversationsvyn."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga lämnade granskningar"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback du har fått visas här. Inget att visa just nu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mottagna granskningar"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kommentarer hittades"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback som du lämnar som kommentarer visas här."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga lämnade kommentarer"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kommentarer hittades"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla kommentarer under en granskning som du äger, ", "@", "omnämnanden eller svar på dina kommentarer visas här."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mottagna kommentarer"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga godkända tvister"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga öppna tvister"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga avvisade tvister"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga lösta tvister"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök igen genom att välja ett annat alternativ i filtret ovan."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök igen genom att välja en annan användare från filtret ovan."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämnade kommentarer"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna kommentarer"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla tvister"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna tvister"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startade tvister"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagen feedback på undersökning"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämnade granskningar"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna granskningar"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla aktivitetslista"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " granskade"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " granskad"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " granskade"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startar om om ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " kvar"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurera ett uppdrag"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla uppdragslista"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det är inte möjligt att skapa en coachningssession mellan ", _interpolate(_list(0)), " och ", _interpolate(_list(1)), " eftersom de för närvarande inte finns på samma arbetsyta."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Coachningssessioner mellan ", _interpolate(_list(0)), " och ", _interpolate(_list(1)), " finns redan. Kontakta ", _interpolate(_list(0)), " för att få åtkomst till sessionen."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föreslagen"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En ny session mellan ", _interpolate(_list(0)), " och ", _interpolate(_list(1)), " har skapats."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj coach"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachad"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj coachad"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa session"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en session"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till session"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionens namn"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föreslagen"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förfallna"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommande"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa session"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner hittades"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig granskningstid"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpanel"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga data att visa för den valda perioden"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lade till nål i session. ", _interpolate(_named("buttonStart")), "Visa mig", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till i..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillagd"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått slutet av nålar från den valda perioden"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se hur nålar fungerar i denna ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artikel"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det verkar som att du inte har fastnålat något under ", _interpolate(_list(0)), ". Du kan använda nålar för att förbereda dig för 1:1 eller spara något till en annan gång."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["den coachade"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga nålar tillagda ännu"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Här är alla nålar som du har skapat under ", _interpolate(_named("name")), ". Nålar som läggs till i sessioner kommer att ", _interpolate(_named("bold")), " med den andra deltagaren."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delas omedelbart"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det verkar som att ", _interpolate(_list(0)), " inte har fått någon feedback för granskningen av konversation eller CSAT under den valda perioden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback tillgänglig"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått slutet på kommentarer från den valda perioden"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mätpunkter"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nålar"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nummer för ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt åtgärdsobjekt"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slutförd av ", _interpolate(_named("user")), " den ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["av ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv för att lägga till ett nytt åtgärdsobjekt"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vad kom man fram till i detta möte? Vilka är dina nästa steg?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärdsobjekt"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skapat av ", _interpolate(_named("name")), " den ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ta bort sessionen"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detta kommer att permanent ", _interpolate(_named("deleteSession")), ". Deltagare förlorar åtkomst till sessionen, inklusive dess innehåll och privata anteckningar."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Session skapad den ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast redigerad av ", _interpolate(_named("name")), " den ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalsämnen och anteckningar"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckning uppdaterad"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna session"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utvalda användare får åtkomst till sessionsinformation från och med denna tidpunkt."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj användare"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delad"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela session"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du kommer inte längre att vara en del av sessionen med ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kommer inte längre att vara en del av denna session."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " har nu åtkomst till denna session nu."]), _normalize([_interpolate(_named("n")), " nya användare har nu åtkomst till denna session"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort session"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort alla sessioner"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort session"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coachen"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anteckningar tillagda av ", _interpolate(_list(0)), " kommer att visas här."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till fastnålade objekt som diskussionsämnen från den högra sidopanelen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nålar"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningar som bara du kan se..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personliga anteckningar"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat anteckning uppdaterad"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dagar"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachad"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärdsobjekt"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste session"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pågår"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förfallna"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionsöversikt"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa alla"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag: Coachad"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla sessioner"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delade sessioner"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att byta ut ett filter för att se åtgärdsobjekt."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga åtgärdsobjekt"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärdsobjekt"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna och visade granskningar"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottaget · Visat"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förlopp efter sessioner"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som att agenterna inte har fått någon feedback."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera att du har rätt namn på användaren"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att hitta denna användare"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga data tillgängliga"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS under de senaste 30 dagarna"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detta kommer att permanent <b>ta bort alla sessioner</b> (2) med <b>", _interpolate(_named("name")), "</b>. Deltagare förlorar åtkomst till alla sessioner, inklusive innehåll och privata anteckningar."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått slutet på alla sessioner"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En coachningssession är en gruppaktivitet mellan en mentor och en coachad för att diskutera prestationer och möjligheter samt kortsiktiga och långsiktiga mål."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan söka efter sessioner både baserat på coachens namn och personen som coachas."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner hittades"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner skapade ännu"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort coachningshistorik"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort coachningshistorik "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste session"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla sessioner"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachad"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som slutförd"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som ångrad"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter namn"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt namn"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachning"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla sessionslista"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplanerad"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara på detta meddelande"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachning"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgången kalibreringssession. Inga ytterligare granskningar kan lämnas."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har redan lämnat en granskning om denna konversation."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det går inte längre att granska denna konversation eftersom sessionens förfallodatum har passerats."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna konversation har inga granskningar"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng panel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna en granskning om kalibrering"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETALJER"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖVERSIKT"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVSKRIFT"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Högtalare ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period av tystnad"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera högtalare"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska meddelande"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tillbaka för att spela upp meddelande"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Högtalare"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avskrift"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare av arbetsyta"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg först till anslutning för att skapa filter"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga bottar"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skapad klockan ", _interpolate(_named("time")), " av ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast uppdaterad klockan ", _interpolate(_named("time")), " av ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det verkar som att du inte har några filter. För att få åtkomst till denna sida måste du först skapa ett filter. Börja med ", _interpolate(_named("recommendedFilter")), " för att se dina egna konversationer. Skapa detta filter nedan för att fortsätta."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["under de senaste 30 dagarna"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkoret ”Skapat de senaste 30 dagarna” läggs till för filter som skapas utan datumintervall"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtret är inte tillgängligt i denna plan och kommer inte att tas med i beräkningen vid visning av resultat"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout uppstod vid hämtningsfilter. Försök igen."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om filtrering"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare = inloggad person"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Konversationer hittades: ", _interpolate(_named("count"))]), _normalize(["Konversation hittades: ", _interpolate(_named("count"))]), _normalize(["Konversationer hittades: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej tillgängligt med din nuvarande plan. Uppgradera om du vill använda denna funktion."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till villkor"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av AI för Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkor"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omvandla till uppdrag"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa filter"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa filter"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassade fält"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkor"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi laddar filteralternativ i enlighet med din specifika inställning. Det kan ta några minuter första gången de laddas."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett beskrivande namn på ditt filter"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filternamn"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett alternativ"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkor"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privata"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentliga"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera filter"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara ändringar"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga tillgängliga värden"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hittades inte"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett värde"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighet"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast aktiva sessioner ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa äldre sessioner ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förfallodatum"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera kalibreringssession"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa session"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kalibreringssession"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla granskningar av kalibreringar som ges under sessionen kommer att tas bort permanent."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort session"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort ", _interpolate(_named("sessionName")), " kalibreringssession"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner har skapats"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva sessioner"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner skapade"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva sessioner"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till filter"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ett privat filter"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ett offentligt filter"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter tillagt"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicera"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtret kommer att tas bort permanent."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort filter"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort filtret för ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla tvister"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister för mig"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister från mig"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna tvister"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga filter tillagda"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga privata filter skapade"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga offentliga filter skapade"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privata filter"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentliga filter"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vill du ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spara den"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta är vyn för ett temporärt filter."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstruerar ditt filter..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter uppdaterat"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insikter"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad kategori för automatisk QA"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast uppdaterad ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexa ord"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiska granskningar uppdateras dynamiskt baserat på ärendeändringar"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera i konversationen"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Långa meningar"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen automatisk poängsättning förutsagd"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att upptäcka några misstag"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa komplexa ord"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa långa meningar"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa konversation"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA kunde inte bedöma denna kategori på grund av otillräckligt sammanhang. Manuell granskning rekommenderas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upptäck automatiserade konversationsinsikter för att förenkla din granskningsprocess. Lyft fram viktiga konversationer och visa upp framstående positiva exempel för ditt team."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Samtal]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrigerad"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagen"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " accepterade tvisten"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tvist skickad till ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förklara varför du anser att den granskade är fel mottagare av denna feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en kommentar och ange en ny granskare"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en kommentar"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förklara varför du anser att dessa poäng är felaktiga (obligatoriskt)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en kommentar (obligatoriskt)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förklara varför du avvisar denna tvist"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en kommentar"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka tvist till"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka tvist till"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka tvist till"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ny granskare"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrida feedback"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrida feedback"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra granskad"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka tvist till"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvist"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrida denna granskning"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag håller inte med om poängen"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna granskning var inte avsedd för mig"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en användare"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " godkände delvis tvisten"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " avvisade tvisten"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkänd"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisad"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppen"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " startade en tvist: granskning"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " startade en tvist: granskad är fel"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En öppen tvist ska lösas innan du kan vidta andra åtgärder med granskningen."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptera"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj nya poäng"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort tvist"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du verkligen ignorera denna tvist?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort tvist"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvist"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid för tvistlösning"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisa"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välja nya rättvisa poäng (valfritt)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning av tvist"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad är fel"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänn tvist"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänn tvist"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrida feedback"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad är fel"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrida feedback"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisa tvist"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisa tvist"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa tvisten"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigerat"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " av ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["till ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskade"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt är klart"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldelad till:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna även en kommentar"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om villkorliga poängkort"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare tilldelad till:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj bara en"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att hitta några GIF med detta namn"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bli den första med att lämna en granskning."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt abonnemang är för närvarande pausat. Återaktivera den för att kunna ge feedback."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicerat av:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta visar hur mycket tid som har ägnats åt att granska konversationen"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en användare"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Rotorsak saknas för kategori: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedöm en kategori eller lägg till en kommentar"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimera"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandera kommentarsfält"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimera kommentarfält"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj formatering"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa formatering"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka granskning"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedöm alla kategorier"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedöm minst en kategori"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara denna konversation till senare"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara till senare"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa uppdrag"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersätt konversation i denna uppdragscykel"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska konversation"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning av baslinje"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kommentar"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar borttagen"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort denna kommentar?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarens författare"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna hashtag har tagits bort eller döpts om."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela det du tyckte om eller det som kunde ha varit bättre..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkorlig"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta tar bort alla kommentarer permanent."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kommentar"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Meddelande]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " har inte sett denna feedback ännu"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " har sett denna feedback"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare medlem"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryck på Enter för att skapa en ny hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassade hashtags är låsta av admin."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd detta för att bokmärka kundcase och filtrera bort senare"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som baslinje"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelandefeedback"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa konversation"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskningar utförda"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna en granskning nedan för att komma igång."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga andra granskningar kvar"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte bedömd"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående handläggare"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående konversation"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort som baslinje"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara på denna granskning"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj sidopanel för granskning"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta tar bort granskningen och alla tillagda kommentarer permanent."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort granskning"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort granskning"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning borttagen"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Granskning borttagen av ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt klart!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldela om denna uppgift till dig själv för att lämna en granskning"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har redan lämnat en granskning om denna användare."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du har lämnat en granskning för ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kategorier att visa för denna konversation."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskning är inte tillgänglig för denna arbetsyta."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara inte"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara denna granskning som ett utkast?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera detta utkast"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa sidopanelen för granskning"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underkänd"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd med mindre fel"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka in"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag räknar fortfarande på det..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna en kommentar"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska detta meddelande"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska dig själv"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska denna konversation"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Författare"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga användare att granska"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar avaktiverade"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen användare hittade"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagare"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare av arbetsyta"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj användare"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 granskning"]), _normalize([_interpolate(_named("count")), " granskningar"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj nya bedömningar"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din ansvarige av arbetsytan har inte tillåtit självgranskning."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryck på Enter för att tagga denna nål"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringar"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De senaste 30 dagarna"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stjärnmärkt"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en kalibreringssession"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insikter"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insikter ger dig en visuell representation av dina helpdesk-data. Använd de interaktiva diagrammen för att förstå var du ska fokusera dina QA-insatser på, avslöja blindspår och hitta rätt agenter att granska baserat på deras prestationer."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID för ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Webbadress för ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Översätt till ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till i"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej tilldelad"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visar för närvarande"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskar nu"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna konversation har tagits bort och är inte längre tillgänglig."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den konversation du letar efter finns inte i Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla nålar med denna konversation"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I session"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konversation"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du tar bort en konversation från en session tas alla omdömen som har getts om den bort."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort konversation från ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kalibreringssessioner skapade ännu."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna konversation har lagts till i sessionen"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna konversation har tagits bort från sessionen"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till i/ta bort från en kalibreringssession"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera länk till denna konversation"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen konversation att visa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärke"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens e-postadress"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens namn"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn på externt formulär"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid till första svar"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritet"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningskommentar"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nöjdhetspoäng"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid till första lösning"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprunglig"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta meddelande finns inte i denna konversation eller så har det tagits bort"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget ämne"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen konversation att visa"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen konversation hittades"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna ursprunglig konversation"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det går inte att länka till konversation – webbadress saknas"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagare"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga deltagare"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla konversation"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att läsa granskningar"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att granska"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation skapad via webbläsartillägg"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa undersökning om kundnöjdhet"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se konversation"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelande med en negativ ton"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelande med en positiv ton"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översätt till engelska"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa original"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konversation"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Radering av en konversation är permanent. Den tar också bort alla relaterade data, t.ex. undersökningar, utskrifter och granskningar från Zendesk QA, inklusive kontrollpaneler.", _interpolate(_named("lineBreak")), "Du har fortfarande åtkomst till denna konversation i Zendesk Support eller en annan helpdesk-programvara som du använder."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Radering av en konversation är permanent. Den tar också bort alla relaterad data, t.ex. undersökningar, utskrifter och granskningar från Zendesk QA, inklusive kontrollpaneler. Om konversationen tillhör flera arbetsytor kommer den att tas bort från alla dem.", _interpolate(_named("lineBreak")), "Du har fortfarande åtkomst till denna konversation i Zendesk Support eller en annan helpdesk-programvara som du använder."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konversation"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konversation från Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna konversation är ofullständig på grund av eskalering. Gå till ", _interpolate(_named("optionsUrl")), " och validera dina taggar. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna konversation är ofullständig på grund av eskalering. Tala med din administratör om hur du validerar dina taggar. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningar > Avancerade alternativ"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om taggar"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stängd"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppen"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntar"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löst"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Handläggare ändrad till ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej tilldelad"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Makro för ", _interpolate(_named("target")), " tillämpat av ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konversationen fick en bedömning ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Användare ", _interpolate(_named("target")), " bortredigerade konversationen"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status ändrad till ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Taggad med ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team ändrat till ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " pausade konversationen för ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " slutade snooza konversationen"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot har fastnat i en loop och upprepar ett meddelande i följd"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepning av bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepning av bot, där samma meddelande upprepas en eller flera gånger i följd"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matchande fraser hittades"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En genomsnittlig agent hanterar konversationen ", _interpolate(_named("percent")), " mer effektivt än boten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivisering av botkommunikation"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot hanterade konversationen ", _interpolate(_named("percent")), " mer effektivt än en genomsnittlig agent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivisering av botkommunikation"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalet saknar det nödvändiga uttalandet som informerar deltagarna om inspelningen, vilket krävs för efterlevnad och transparens"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upplysning om inspelning saknas"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ett Service Level Agreement (SLA) (SLA-avtal) har överträtts för ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA överträtt"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mätpunkt för SLA-regeln överskred dess måltid"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecken på kundförsämring. Kunden övervägde ett byte eller lovade att lämna."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk för kundbortfall identifierat"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversationen har perioder av tystnad som varar längre än det angivna tröskelvärdet på ", _interpolate(_list(0)), " s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instans"]), _normalize([_interpolate(_named("count")), " instanser"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s längsta"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period av tystnad"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden sökte assistans på högre nivå"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalering begärd"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden uttryckte sin enorma tacksamhet eller var mycket nöjd med den support de fick"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enastående service"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antingen kunden eller agenten efterfrågade uttryckligen en uppföljning"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förfrågan om uppföljning"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden visade sig vara missnöjd"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativt sentiment"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen var ovanlig eller atypisk och diskussionen gick fram och tillbaka för att nå en lösning"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvikande värde identifierat"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden visade nöjdhet"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivt sentiment"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämplig"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange som ej tillämplig"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast redigerad av ", _interpolate(_named("author")), " den ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej tillämpligt"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Av ", _interpolate(_named("author")), " den ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en orsak (valfritt)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga insikter detekterade"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till anslutning"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut din helpdesk till ditt konto och ha minst ett filter skapat för att se listan över konversationer."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer att visa"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa arbetsytans inställningar"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapad"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast uppdaterad"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källa"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ställ in"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Om en dag)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Denna månad)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Denna vecka)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta genom ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sökresultat för ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat av andra"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenterade"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyaste"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äldsta"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slumpmässiga"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligen uppdaterade"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligen svarade"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortera enligt"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oläst"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat av dig"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importprocessen kan ta en stund. Vi skickar ett e-postmeddelande till dig när den är klar."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importerar dina konversationer"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gräns: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till genom att klicka på kalibreringsikonen i konversationsvyns sidhuvud."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget att rapportera här. Försök igen med ett annat filter eller sorteringsalternativ."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga avvikande värden att visa."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta en relevant konversation och klicka på stjärnikonen i sidhuvudet för att bokmärka den här."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova att ändra filtren eller bjud in andra helpdesk-agenter att lägga till fler konversationer."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen gräns"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern anteckning"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi laddar konversationer just nu. Visst innehåll kan fortfarande saknas."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa användare"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen användare hittade"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar mottagna"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmar i arbetsyta"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående användare"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer tillagda"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer har lagts till"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oavslutade granskningar hamnar här. Läs igenom igen, revidera och skicka in den inom de närmaste 30 dagarna."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskningar som utkast"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga stjärnmärkta konversationer"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer att visa"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchning"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tillbaka till valt meddelande"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minst svar"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flest svar"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla konversationslista"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtal besvarades kl ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtal avslutades kl ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtal ringdes på ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat av dig"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det gick inte att läsa in ljudfilen. Se till att du är inloggad på ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar i undersökning om kundtillfredsställelse"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel när ljud laddades"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarens författare"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget samtal ägde rum"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyssna i helpdesk"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyssna på Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppspelningshastighet"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska samtal"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avskrift"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hög"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Låg"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medel"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte en användare av Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangordnar agenter automatiskt baserat på deras genomsnittliga CSAT per månad. Hitta konversationer som hanteras av agenter vars CSAT är lägre än deras kollegor med ett klick."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangordning efter CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medel"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hög"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Låg"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägsta"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Högsta"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte en användare av Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett ärende"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volym"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangordnar automatiskt agenter i prestationsgrupper baserat på hur många konversationer de hanterar per månad. Hitta konversationer av agenter med en lägre volym än deras kollegor med ett enda klick."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangordning efter volym"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte granskat"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsstatus"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Låg"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hög"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte en användare av Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangordnar agenter automatiskt baserat på det genomsnittliga sentimentet i konversationerna. Hitta interaktioner där agenter hanterar fler frustrerade kunder än deras kollegor med ett enda klick."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangordning efter sentiment"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte detekterad"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detekterad"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvikande värden"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer som kräver din uppmärksamhet. Vår maskininlärningsmodell identifierar komplexa eller atypiska interaktioner för vidare granskning."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvikande värden"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj konversationer för granskning där kunden antingen visade nöjdhet eller frustration. Använd menyn för att välja den typ av sentiment du letar efter i dina konversationer. Dra filterattributet överst för att se förhållandet mellan det valda sentimentet i alla dina interaktioner."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsstatus"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte granskat"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA detekterar språk i dina konversationer automatiskt. Använd listrutan för att välja konversationer på specifika språk för granskning. Dra filterattributet överst för att få en översikt över alla språk som används i din helpdesk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsinsikter"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan ", _interpolate(_named("paragraph_2_item")), " med insikter jämfört med vanliga konversationsfilter. Få en visuell översikt över alla data från dina helpdesks och utforska insikter som:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på hur man använder konversationsinsikter"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förstå den relativa betydelsen av specifika konversationsattribut i förhållande till andra"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta hög- och lågpresterande agenter med inbyggd rankning av agentprestationer"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " är ett kraftfullt interaktivt datautvinningsverktyg som hjälper dig att hitta de konversationer som mest behöver granskas."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["göra så mycket mer"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diagrammen för konversation och agent är ", _interpolate(_named("paragraph_3_item_1")), " – om du uppdaterar filter eller klickar på segmenterade diagramdelarna på något av dessa uppdateras konversationslistan automatiskt. ", _interpolate(_named("paragraph_3_item_2")), " för att utforska detaljerade uppdelningar eller förhållanden mellan de olika elementen."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sammankopplade"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordna om filterattributen"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endast konversationsinsikter visas ", _interpolate(_named("paragraph_4_item")), ". Vi tar automatiskt bort interaktioner utan verkligt granskningsvärde, t.ex. automatiskt genererade meddelanden, spam, botsvar eller meddelanden som inte har någon korrekt dialog med din supportrepresentant."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["korrekta och stängda konversationer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vad ser jag här?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ut av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " ut av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " ut av ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " av ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att hämta"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " N/A"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenter"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " av totalt)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda om"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insikter"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi är dock säkra på att den kommer att bli fantastisk när den väl kommer."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad undersökning"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback lämnades om undersökningen"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ännu inga tillgängliga poäng för feedback från undersökningen..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömning"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mottaget"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagg för feedback"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar för feedbackkommentar"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter för datumintervall tillämpat"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " svar"]), _normalize([_interpolate(_named("count")), " svar"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Undersök mer djupgående varför ", _interpolate(_list(0)), "-poängen gavs genom att ställa in orsaker under undersökningsinställningar."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Inga ", _interpolate(_list(0)), "-orsaker ännu"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om undersökningsorsaker"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer med taggar:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " av"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källa"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsaker"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarskategori"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " orsaker"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Förutsedda drivkrafter till ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationskälla"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-taggar"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Undersökningssvar (", _interpolate(_named("n")), ")"]), _normalize(["Undersökningssvar (", _interpolate(_named("n")), ")"]), _normalize(["Undersökningssvar (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget ämne"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversationer med ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " av användare"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarsfrekvens"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-poäng är summan av alla svar dividerat med summan av maximal poäng"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera mål"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort mål"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ställ in ett mål"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till mål"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kort"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väldigt lång"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lång"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medellång"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förvirrande"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej till hjälp"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dålig support"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bra support"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Överdrivet mycket känslor"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback till agent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klagomål"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otillräckligt svar"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem inte löst"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem löst"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativt sentiment"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dåligt resultat"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivt sentiment"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beröm"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dålig produkt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återbetalning"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snabb support"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Långsam support"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tilldelar automatiskt dina data om ", _interpolate(_list(0)), " i fördefinierade kategorier. Du kan förstå komplexa insikter och utforska orsaker utan att manuellt gå igenom den skriftliga feedbacken."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla poäng"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla undersökningar"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj svarsspråk"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarsstorlek"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj kommentarsstorlek"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftersom 25 % av kommentarerna endast består av ett ord är längre kommentarer ett måste att läsa. Få mer insiktsfull feedback utan att behöva göra något manuellt arbete, eftersom vi gör det automatiskt åt dig."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak till feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj orsak till feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga matchande poäng"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förutsedda drivkrafter"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj kommentarskategori"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord i taggmoln"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ord i taggmoln"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sedan senaste perioden"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konversationer"]), _normalize([_interpolate(_named("n")), " konversation"]), _normalize([_interpolate(_named("n")), " konversationer"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det går inte att beräkna andelen skickade undersökningar från alla konversationer på grund av brist på data."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar skickade"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningstratt"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " av ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Förutsedda drivkrafter till ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tilldelar automatiskt dina data om ", _interpolate(_list(0)), " i fördefinierade kategorier. Du kan förstå komplexa insikter och utforska orsaker utan att manuellt gå igenom den skriftliga feedbacken."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarsstorlek"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftersom 25 % av kommentarerna endast består av ett ord är längre kommentarer ett måste att läsa. Få mer insiktsfull feedback utan att behöva göra något manuellt arbete, eftersom vi gör det automatiskt åt dig."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningssvar"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (genomsnitt)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (genomsnitt)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnitt"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-trender efter"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källa"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsaker"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivkrafter"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Datum då undersökning om ", _interpolate(_list(0)), " skickades"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Datum då undersökning om ", _interpolate(_list(0)), " besvarades"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tilldela ", _interpolate(_list(0)), "-poäng till"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa data baserade på"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapandedatum för konversation"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ärendets ägare"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agent med flest svar"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (genomsnitt)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " över tid"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " och IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer som innehåller dessa ord:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggmoln"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT av användare"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-översikt"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningstratt"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningssvar"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-taggar"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT över tid"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggmoln"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen källa"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget språk"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga orsaker"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga taggar"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kommentarskategori"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbetsyta"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga data tillgängliga på grund av att inga tilldelningar har skapats eller inga cykler har startats. Kom tillbaka senare eller justera filtren."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utforska vad som är nytt"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " objekt"]), _normalize([_interpolate(_named("count")), " objekt"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " konversation"]), _normalize([_interpolate(_named("count")), " konversationer"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underkänd"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd med mindre fel"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd/Underkänd"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnitt"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnitt (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnitt (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bedömningar av ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig CSAT = [summan av feedbackpoäng ÷ (antal alla svar × högst möjliga poäng)] × 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-poäng för Zendesk QA normaliserar din feedback på en skala från 0–100 % och tar ett genomsnitt av de standardiserade poängen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (kundtillfredsställelsepoäng) kvantifierar hur nöjda dina kunder är med dina tjänster."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Varje granskning representerar en granskning av en person i en konversation. En konversation kan ha fler än en granskning (om två eller flera granskare granskar samma konversation)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Följ utvecklingen av granskarens poäng över tid."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Följ utvecklingen av agentens poäng över tid."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " granskningar"]), _normalize([_interpolate(_named("n")), " granskning"]), _normalize([_interpolate(_named("n")), " granskningar"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (internt kvalitetsresultat) är ett resultat av dina granskningar av konversationer."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = summan av granskningspoäng ÷ antal granskningar"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den beräknas som ett genomsnitt som tar hänsyn till alla granskningspoäng (vägt genomsnitt av kategoripoäng) som matchar de valda filtervillkoren."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ladda kort."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra dina filter och försök igen."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkännandefrekvensen anger resultatet av dina konversationsgranskningar."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Underkänd om granskningspoängen är ", _interpolate(_named("failScore")), " eller lägre"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Godkänd om granskningspoängen är ", _interpolate(_named("passScore")), " eller högre"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den bestäms av en fördefinierad baslinje som innebär att du:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frekvens för godkända = Summan av godkända granskningar ÷ Summan av totalt antal granskningar"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkännandefrekvensen anger en procentuell andel av hur många granskningar som godkänns utan att bli underkända i antingen kritiska eller icke-kritiska kategorier."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om granskaren t.ex. ger 3 granskningar denna dag är siffran som visas genomsnittet av de 3 poängen. Se hur varje cell beräknas ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om agenten t.ex. får 3 granskningar denna dag är siffran som visas genomsnittet av de 3 poängen. Se hur varje cell beräknas ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hänsyn tas till: kategorivikt, kritisk status (till skillnad från poäng över tid)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftersom kategorier kan markeras med n/a i granskningar kan antalet granskningar per kategori vara färre än det totala antalet granskningar från granskaren. Se hur varje cell beräknas"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hänsyn tas till: kategorivikt, kritisk status (till skillnad från poäng över tid)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftersom kategorier kan markeras med n/a i granskningar kan antalet granskningar per kategori vara färre än det totala antalet granskningar per agent. Se hur varje cell beräknas"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hänsyn tas till: kategorivikt, kritisk status (till skillnad från poäng över tid)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategorigrupper visas separat. Poängen för varje grupp beräknas som den genomsnittliga kategoripoängen för gruppen under samma period. Se hur varje cell beräknas ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obs!"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hänsyn tas till: kategorivikt, kritisk status (till skillnad från poäng över tid)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategorigrupper visas separat. Poängen för varje grupp beräknas som den genomsnittliga kategoripoängen för gruppen under samma period. Se hur varje cell beräknas ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Baslinje ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritiskt felfri"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kritiska felfria andelen anger en procentandel av hur många granskningar som godkänns utan att underkännas i några kritiska kategorier."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern kvalitetspoäng"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Övriga\" grundorsaker"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodisk förändring"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagligt byte"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvis förändring"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veckovis förändring"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig ändring"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng över tid"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa i antingen dagar eller veckor."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beräkning = totalt antal poäng för dag eller vecka dividerat med totalt antal lämnade granskningar"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa i antingen dagar eller veckor."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beräkning = totalt antal poäng för dag eller vecka dividerat med totalt antal mottagna granskningar"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömning enligt kategori"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa det kombinerade genomsnittet av alla kategoribedömningar som granskaren har lämnat under denna period."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa det kombinerade genomsnittet av alla kategoribedömningar som mottagits per agent under denna period."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundorsaker till låga bedömningar"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundorsaker till bedömningar"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoripoäng över tid"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysera hur kategorierna jämförs över tid."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa genomsnittspoängen som ges för varje kategori på en vald dag eller vecka."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysera hur kategorierna jämförs över tid."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa genomsnittspoängen som har erhållits för varje kategori på en vald dag eller vecka."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömning efter omdömen"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiverat"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritisk kategori"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningsskala"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnitt/totalt"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exkludera självgranskningar"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporterar alla kort"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Givna"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["konversation"]), _normalize(["konversationer"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["artikel"]), _normalize(["artiklar"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " granskningar"]), _normalize([_interpolate(_named("n")), " granskning"]), _normalize([_interpolate(_named("n")), " granskningar"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Visa ytterligare en"]), _normalize(["Visa ", _interpolate(_named("n")), " till"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 30 dagarna"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 7 dagarna"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste månaden"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste veckan"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna månad"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna vecka"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad period"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagar"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månader"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvartal"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veckor"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ladda kort. Ändra dina filter och försök igen."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga data att visa."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvis förändring"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpaneler"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer information."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina aktiva filter omfattar data över olika bedömningsskalor."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla kortnålar med dessa filter"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla kontrollpanelnålar med dessa filter"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig granskningstid"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skräddarsy"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kategorier"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriinsikter"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagram"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentar"]), _normalize(["Kommentarer"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matris"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare medlem"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern kvalitetspoäng"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (genomsnitt)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända granskningar"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla kort"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåla fast kontrollpanel"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningar"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Grundorsak"]), _normalize(["Grundorsaker"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gransknings-ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningstid"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurera grundorsaker"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundorsakerna låter dig veta varför konversationer får låga poäng"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga grundorsaker angivna för denna kategori"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng/totalt"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategorier"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategorigrupper"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorigrupp"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsyta"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skräddarsy ordning"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppera kategorier efter"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera alla"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skräddarsy"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exkludera självgranskningar"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkludera självgranskningar"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa endast självgranskningar"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rensa filter"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga hashtags hittades"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags med kommentarer"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj hashtags med kommentarer"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga taggar hittades"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-taggar"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter helpdesk-taggar"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga poängkort hittades"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj poängkort"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga matchande anslutningar"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationskälla"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj källa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fler filter"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga matchande arbetsytor"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antal givna"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla grupper"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga matchande grupper."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla grupper"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa kort"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa data baserade på"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapandedatum för granskning"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapandedatum för konversation"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa två decimaler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa inställningar"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla arbetsytor"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hela kontot"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytans namn"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga arbetsytor"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaggor"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat och dryck"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djur och natur"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föremål"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Människor och kropp"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resor och platser"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanligt använda"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sökresultat"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys och emoticon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symboler"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen emoji hittad"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna en konversation"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till uppgiftslistan"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " eller bläddra i din lista över ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du använder för närvarande ", _interpolate(_named("workspace")), " arbetsyta som inte är ansluten till denna domän. Klicka här för att ansluta den nu."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tillgängliga anslutningar"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan inte ge feedback om ", _interpolate(_named("host")), " just nu."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha din helpdesk som aktiv flik."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna domän kan inte granskas ännu. Meddela din administratör om den ska det."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till denna domän"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anslut med ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett ögonblick bara – gör dig redo"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att få webbadressen"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verktyg som redan är anslutna till organisationens Zendesk QA-konto."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga verktyg anslutna till organisationens Zendesk QA-konto."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gör att du kan granska webbplatser där webbadressen alltid är densamma genom att lägga till en unik hash i slutet innan du lämnar en granskning"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt konto"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningar"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina domäner"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäner som redan är anslutna till denna arbetsyta."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan granska alla sidor på följande domäner"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäner tillgängliga för granskning"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillägg"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unik webbadress"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ingen helpdesk ansluten ännu – gå till ", _interpolate(_named("link")), " för att lägga till en."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbläsartillägget Zendesk QA kan endast användas\ni planerna Professional och Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt arbetsyta"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett namn"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett värde"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassade fält"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värde"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det här är första gången vi har stött på denna diskussion. Ange några detaljer."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsreferens:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något att identifiera ärendet med senare"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återbetalning"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange både handläggaren och ett ämne"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska denna konversation"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej tilldelad"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontakta din chef eller skifta till en annan arbetsyta från ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar är avaktiverade för denna arbetsyta."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hej då"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsyta"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna webbadress kan inte granskas. Navigera till en konversation."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga först in till Zendesk QA via din SSO-leverantör och försök sedan igen."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppnar popup-fönster för inloggning."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med ditt Zendesk QA-konto"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänta..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiserar..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du inget konto ännu?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inte åtkomst till denna konversation. Om du ska ha det kontaktar du din chef."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen åtkomst"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera att din anslutning är aktiverad i Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera dina anslutningar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningsfel"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigera till en konversation på din helpdesk för att fortsätta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig webbadress"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återanslutning är nästan klar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett ögonblick"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att ladda om sidan för att komma tillbaka till där saker händer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel inträffade"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frigör dig från helpdeskens virtuella gränser och granska konversationer var du än befinner dig."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera ditt abonnemang om du vill använda tillägget"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera ditt abonnemang"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-anslutningen är inte länkad till denna arbetsyta"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din helpdesk känner inte igen ärendet eller så har Zendesk QA inte åtkomst till det"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att hitta konversation"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny aktivitet"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du fick lite feedback"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggning slutförd"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan använda appen nu"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedöm alla kategorier"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd detta för att bokmärka kundcase och filtrera bort senare"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback för denna konversation"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läs feedback"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppen i Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Här kommer all feedback och alla notifieringar att visas. Inget ännu…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mer i Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna granskningar"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsdetaljer"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller allt av"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är alla av"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller inte alla av"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte all av"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bättre än agentens"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sämre än agentens"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falskt"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är mer än"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller något av"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är något av"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller inga av"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte något av"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sant"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["överträtt"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommenterade"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommenterad av mig"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["har inte kommenterat"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte kommenterad av mig"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritiska för granskning"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senaste 30 dagarna"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inom"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["från"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senaste 14 dagarna"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senaste 24 h"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senaste 30 dagarna"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senaste 7 dagarna"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["förra månaden"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["förra veckan"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denna månad"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denna vecka"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till och med"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i går"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detekterade"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finns"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stjärnmärkta av mig"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte stjärnmärkta av mig"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inkluderar"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mer än"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["börjar från"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindre än"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fram tills"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte överträdda"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte komplexa"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte detekterad"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finns inte"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inkluderar inte"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte mottagna"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte visade"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positiva"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte granskade"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inte granskad av mig"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskade"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskade av mig"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mottagna"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritiska för granskning"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mer än"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["börjar från"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindre än"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fram tills"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innehåller inte"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visade"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min kommentar har svar"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nej"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egna kategorier"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egen spotlight"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter villkor"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mätpunkter"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagare"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning och feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa alla villkor"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jämför din bots konversationshantering med genomsnittliga agenter"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot har fastnat i en loop som upprepar samma meddelande i flera omgångar i följd"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal botsvar i konversationer"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En typ av bot som är involverad i konversationen"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bot involverad i konversationen"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversationsinnehåll innehåller frasen ", _interpolate(_list(0)), " i agenters meddelande(n)"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiera samtal automatiskt som saknar den obligatoriska upplysningen, t.ex. \"Detta samtal spelas in\" och liknande."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En person eller bot som är involverad i konversationen"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den agent eller bot som granskas i konversationen"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden sökte assistans på högre nivå"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivt och negativt sentiment detekterades i konversationen"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifierar den mest aktiva agenten i konversationer enligt AI-analys"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten avslutade konversationen på ett artigt sätt"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten förstår och uppmärksammar kundernas känslor"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar agentens grammatik-, stavnings- och stilfel"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hela konversationen för att se typiska hälsningsfraser"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten förstår kundens fråga eller problem"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hur enkelt det är att förstå en text, med tanke på ordkomplexitet och meningslängd"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hela konversationen för att få fram en erbjuden lösning"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar agentens ton under konversationen"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecken på kundförsämring. Kunden övervägde ett byte eller lovade att lämna."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp som konversationen tillhör."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelandets innehåll eller text"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar i konversationer"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten som tilldelats att granska konversationen"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den tid samtalskonversationen varade i sekunder"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationens innehåll eller text"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kommunikationskanal som används för konversationen (t.ex. e-post, chatt)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer lades till i konversationen"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då en kommentar lades till i konversationen"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivt och negativt sentiment detekterades i konversationen"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då kunden besvarade undersökningen om kundtillfredsställelse"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huruvida konversationsgranskningen är omtvistad"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kanal kunden använder för att inleda konversationen"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal kundsvar i konversationer"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumet för det sista meddelandet i konversationen"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen eller typen av användare som är involverad i konversationen"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tystnaden mellan agenten och kunden överskrider tröskelvärdet"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden sökte assistans på högre nivå"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden uttryckte sin enorma tacksamhet eller var mycket nöjd med den support de fick"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antingen kunden eller agenten efterfrågade uttryckligen en uppföljning"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texten eller posten för hela konversationen genomfördes via röst"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om konversationen är markerad som stjärnmärkt"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags som används i kommentarer för att kategorisera innehåll"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsspråket som detekteras av AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumet för det sista meddelandet i konversationen"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal privata anteckningar i konversationer"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittligt antal tecken per meddelande i konversationer"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal kundinriktade svar i konversationer"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En deltagare vars information är synlig för allmänheten"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittligt antal ord per meddelande i konversationer"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier som används för att bedöma eller utvärdera konversationen"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status för konversationens granskningsprocess"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En deltagare eller person som är involverad i konversationen"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En e-postadress som är associerad med konversationen"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om granskningen har visats"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskaren som bedömde konversationen"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då konversationen senast granskades"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den agent som granskas i konversationen"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla specifika kommentarer eller all specifik feedback från kunden avseende deras nöjdhet med konversationen"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationens kundtillfredsställelsepoäng"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundtillfredsställelsepoäng per meddelande i konversationen"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkortet som används för att granska konversationen"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissa SLA-avtal har överträtts"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den typ av källa eller anslutning som konversationen ursprungligen kommer från"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen var ovanlig eller atypisk och diskussionen gick fram och tillbaka för att nå en lösning"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningsfrågan relaterad till konversationsinnehållet"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titeln eller namnet på den undersökning som är associerad med konversationen"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten som tilldelats att granska konversationen"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärket eller den specifika produkt/tjänst som är associerad med konversationen"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kommunikationskanal som används för konversationen (t.ex. e-post, chatt)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då konversationen stängdes eller löstes"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då konversationen skapades första gången"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då konversationen skapades första gången"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då kunden besvarade undersökningen om kundtillfredsställelse"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kort beskrivning eller en sammanfattning av konversationen"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett externt referens-ID länkat till konversationen"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mapp eller specifika gruppering som konversationen är organiserad i"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det formulär eller den mall som används för att strukturera konversationen"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett externt referens-ID länkat till konversationen"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den brevlåda eller det e-postkonto som tog emot konversationen"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnumret som är associerat med konversationen"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den prioritetsnivå som tilldelats konversationen"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det totala antalet svar i konversationen"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationens tillstånd eller aktuella status"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationens aktuella status"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationens ämne eller tema"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar i konversationer"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar tilldelade för att kategorisera konversationen"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationens typ eller kategori"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då konversationen senast uppdaterades"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det datum då konversationen senast uppdaterades"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det medium eller den kanal där konversationen ägde rum"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innehållet i en avskrift av en konversation"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer som inte är berättigade till granskning genom AI-analys"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal deltagande agenter i konversationer"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om konversationen har visats"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Något meddelande"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivisering av botkommunikation"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepning av bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal botsvar"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ av bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalsriktning"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientmeddelande"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period av tystnad"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upplysning om inspelning saknas"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalering"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innehåll i samtalsavskrift"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stjärnmärkt"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej berättigad för granskning"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad av"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation via"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavning och grammatik"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelande"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mest aktiva agenten"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalslängd (sek)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsinnehåll"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelandekanal"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstagg"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenterat datum"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarsdatum för CSAT-undersökning"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvist"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundkanal"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal kundsvar"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste svarsdatum"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användartyp"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk för kundbortfall"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period av tystnad"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalering"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enastående service"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppföljning"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avskrift"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag för kommentarer"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvikande värde"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förståelse"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste svarsdatum"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal privata anteckningar i konversation"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittligt antal tecken"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittligt antal ord"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal offentliga svar"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlig deltagare"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömt datum"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömning och kommentar"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsstatus"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läsbarhet"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagare"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaterad e-postadress"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaterad"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarsantal"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning mottagen"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort för granskning"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning visad"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskat datum"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningskommentar"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per meddelande"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nöjdhetspoäng (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösning erbjuden"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källtyp"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ämne"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningsfråga"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningstitel"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärke för konversation"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärkesdomän"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationskanal"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stängningsdatum"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapandedatum"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapandedatum"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarsdatum för CSAT-undersökning"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum då CSAT-undersökningens skickades"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsbeskrivning"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externt ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsfält"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsmapp"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsformulär"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsgrupp"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout-ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externt ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsbrevlåda"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsprioritet"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal konversationssvar"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationstillstånd"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsstatus"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsämne"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tagg"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tagg"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationstyp"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterade"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterade"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avskriftsinnehåll"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal deltagande agenter"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visad status"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapacitet för sårbarhet"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsa för sårbarhet"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livshändelse för sårbarhet"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA-kategori"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett datumrelaterat villkor"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ett namn på ditt filter"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyll i ett filternamn"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggad person"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare medlemmar i arbetsyta"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmar i arbetsyta"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lägg till ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpa filter"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste ha minst 1 datumrelaterat filter"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj alternativ"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett alternativ"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din provperiod upphör om ", _interpolate(_named("n")), " dag. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din provperiod upphör <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>om ", _interpolate(_named("n")), " dag</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din provperiod upphör om ", _interpolate(_named("n")), " dagar. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din provperiod upphör <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>om ", _interpolate(_named("n")), " dagar</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din provperiod upphör i dag. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din provperiod upphör <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>i dag</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprätta ett abonnemang nu"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " dagar"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("days")), " kvar på din provperiod"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nått användargränsen för din plan. ", _interpolate(_named("link")), " för att ta reda på mer eller uppgradera din plan."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka här"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansluta din helpdesk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta gör det möjligt för oss att hämta in dina ärenden så att du kan granska dem."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut din helpdesk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du använder Zendesk QA med exempeldata. Om du vill ta bort exempeldata och importera dina egna konversationer måste du ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provdata"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera hjälpsam information nedan"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta en skärmbild på hela sidan för att ge sammanhang"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakta vårt kundsupportteam för att lösa detta problem:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel inträffade"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felreferens:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera hjälpsam information"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare, bottar och arbetsytor"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare och arbetsytor"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denna månad"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denna vecka"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " granskade ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " granskad ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " granskade ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vad är nytt?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tangentbordsgenvägar"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatta med oss"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ladda vår chatt (kan det hända att du använder en annonsblockerare?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachning"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpanel"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjälp"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startsida"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämnade granskningar"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna granskningar"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunskapsbas"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt konto"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgifter"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Appen uppdaterades. ", _interpolate(_named("buttonStart")), "Ladda om", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill börja använda Zendesk QA som en del av din arbetsyta:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jag vill bara berätta att du redan har fått en inbjudan om att använda Zendesk QA som en del av en arbetsyta. Om du vill kan du naturligtvis fortsätta, men du ska notera att detta skapar en ", _interpolate(_named("bold")), " åt dig."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["separat organisation"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja, låt mig fortsätta med ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ett nytt konto"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " ber dig att gå med i ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå med"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen internetanslutning"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ny nål"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snabbnavigering"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fokusera och expandera kommentarsredigeraren"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj eller visa sidopanel"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till nästa konversation"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna ursprunglig konversation"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till föregående konversation"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ge alla kategorier högsta möjliga betyg"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ge alla kategorier lägsta möjliga betyg"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanda konversationer (sorteras om slumpmässigt)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berätta för oss"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tangentbordsgenvägar"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla genvägsmodal"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nått teckengränsen på ", _interpolate(_named("charLimit"))])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Typen (", _interpolate(_named("type")), ") av bilden ", _interpolate(_named("name")), " är inte tillåtet"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Storleken på bilden ", _interpolate(_named("name")), " är för stor (5 MB tillåts)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ladda kommentarredigeraren"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Släpp för att ladda upp"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länk"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera mallar"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mallar tillagda"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till grundorsak"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övriga inklusive borttagna orsaker"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak till bedömning"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnamn"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternamn"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal anställda"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kontaktar dig för att hjälpa dig att konfigurera ditt konto"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Företagets namn"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Företagets kontaktnummer"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hur hörde du först talas om Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en kanal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett antal agenter"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsätt"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyll i alla obligatoriska fält"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste samtycka till att ha läst och godkänna våra villkor och vår Privacy Policy (Integritetspolicy)."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportdriven Slack-kanal"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklam"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händelse"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vän eller kollega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-marknadsplats eller kundansvarig"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värvningswebbplats"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sökmotor (Google, Bing osv.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociala medier"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berätta om dig själv och ditt företag."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontouppgifter"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag är redo."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolla runt, bekanta dig med appen, ta bort och lägg till saker."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto skapat. Vad är nästa steg?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du redo att föra in dina riktiga konversationer?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till dina egna data"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Börja utforska"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova dig fram med exempeldata"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För att ska kunna börja samla in dina ärenden för granskning."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut ditt helpdesk-konto"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag ändrade mig."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt registrering"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nål skapades. ", _interpolate(_named("buttonStart")), "Gå till nålar", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachningsnålar är tillgängliga i Professional-planen."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera plan"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alla planer"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara dina anteckningar för att förbereda dig på nästa 1:1 eller coachningssession."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nålar för coachning"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentera eller lägg till andra med ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna konversation har tagits bort"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en ny nål"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa nål"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera nål"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hur nålar fungerar"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inte behörighet att se denna konversation"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla kort"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla konversation"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåla fast kontrollpanel"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nål sparad"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla detta kort"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla denna konversation"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla denna kontrollpanel"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåla fast under en användare"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en användare"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast du kan se vad du nålar fast. Om du vill dela med andra ", "@", "omnämner du dem i kommentarerna."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " är inbjuden till denna konversation"]), _normalize([_interpolate(_named("agentsNames")), " är inbjudna till denna konversation"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 kommentar"]), _normalize([_interpolate(_named("n")), " kommentarer"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Du omnämnde ", _interpolate(_named("participants")), " men han eller hon är inte med i denna konversation."]), _normalize(["Du omnämnde ", _interpolate(_named("participants")), " men de är inte med i denna konversation."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " deltagare"]), _normalize([_interpolate(_named("n")), " deltagare"]), _normalize([_interpolate(_named("n")), " deltagare"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vill du ta bort ", _interpolate(_named("agentName")), " från att åtkomst till denna nål?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gör ingenting"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud inte in dem till denna konversation"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kommentar"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in dem"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in dem till denna konversation"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort användare"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tråd"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta kan inte ångras."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta tar också bort alla kommentarer under denna nål."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nya meddelanden"]), _normalize([_interpolate(_named("n")), " nytt meddelande"]), _normalize([_interpolate(_named("n")), " nya meddelanden"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast du kan se dina nålar. Om du vill dela nålar ", "@", " du någon annan i kommentarerna."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " nålade fast ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en kontrollpanel"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en konversation"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en anteckning"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera för att få coachningsnålar"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachningsnålar är tillgängliga i Professional-planen."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachningsnålar är tillgängliga i Professional-planen"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera för att få funktion"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbered dig på 1:1 eller spara något till en annan gång."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta tar bort nålen och alla tillagda kommentarer permanent."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort nål"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort nål"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappens namn"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla saker att återkomma till senare"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svara"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste först"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äldsta först"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara en intressant kontrollpaneltrend eller konversation som förberedelse för dina 1-1 eller anteckna det till en annan gång."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en nål genom att klicka på knappen ovan eller på nålikonen i konversations- eller kontrollpanelsvyn."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastnåla"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga nålar skapade"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Inga träffar för ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök använda andra nyckelord eller kontrollera om det finns stavfel."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en ny nål"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelanden"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personer"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter användare, nyckelord..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bokstavsordning"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligen skapad"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligen redigerad"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flest nålar"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mitt mål är att granska ", _interpolate(_named("n")), " konversation ", _interpolate(_named("schedule"))]), _normalize(["Mitt mål är att granska ", _interpolate(_named("n")), " konversationer ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål uppdaterat"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprätta mål"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ange ett personligt mål för antalet konversationer som du vill granska."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varje dag"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varje månad"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varje vecka"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsmål"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONLIGT"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallar"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läser in kanaler"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj kanal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela endast feedback om undersökning med kommentarer"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stärk dina kollegor genom att lyfta fram deras exceptionella servicekvalitet och utmärkta kundfeedback."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela positiv feedback om undersökning till Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kanaler hittades"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela feedback automatiskt med ovanstående poäng"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berömbot-integrationer"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få en påminnelse i början av veckan om du inte har gjort några granskningar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påminnelser"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veckovis påminnelse om att göra granskningar"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta emot notifieringar om nya granskningar, svar, omnämnanden och tvister i Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omnämnanden"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningar"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trådsvar"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrationer"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddela om nya granskningar, svar, omnämnanden och tvister."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister relaterade till mig"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omnämnanden i kommentarer"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nya mottagna granskningar"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar i trådar"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport med statistik"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta emot rapporter om lämnade och mottagna granskningar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varje dag"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["månadsvis"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veckovis"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varje dag"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Var 3:e timme"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Var 6:e timme"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omedelbart"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvis"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veckovis"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kontoadministratör har låst aviseringsinställningar."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj period"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personliga notifieringar"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktiv)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en ny inloggning på sociala medier till detta konto:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du tar bort en aktiv inloggningsmetod kommer du att loggas du ut. Du kan logga in igen med din alternativa e-postadress."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort inloggningsmetod"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google har lagts till som ett inloggningsalternativ"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningsalternativ"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack har lagts till som ett inloggningsalternativ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa lösenord"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metod"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ lösenord"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ett e-postmeddelande kommer att skickas till ", _interpolate(_named("email")), " för att återställa ditt lösenord."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primär e-postadress"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolla din inkorg för vårt bekräftelsemeddelande via e-post"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk länk"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill ändra detta ändrar du din ", _interpolate(_named("link")), " innan."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-postmeddelandet ", _interpolate(_named("email")), " kommer att associeras med detta lösenord."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil uppdaterad"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För bästa resultat laddar du upp en kvadratisk bild (256 x 256 eller mer)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primär e-postadress"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Början på veckan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personliga inställningar"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj minst ett alternativ"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallinnehåll"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallnamn"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa mall i"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till mallinnehåll"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ett mallnamn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa mall"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa mall"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kommentarsmallar för att snabba på granskning, fastnålning av objekt och coachning."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Används i"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort mall"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du ta bort mallen permanent?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera mall"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kommentarsmallar för att snabba på granskning, fastnålning av objekt och coachning."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mallar skapade"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarmall skapad"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarmall borttagen"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarmall sparad"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachning"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nålar"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar och tvister"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill få åtkomst tar du kontakt med ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kundsupport"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget konto hittades"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["För tillfället använder du ", _interpolate(_named("link")), " för att få åtkomst till Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tillbaka till Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontot inte migrerat"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot QA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mått"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistik"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpanel"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrering"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konversationer"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskning"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Givna"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mina kommentarer"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mina tvister"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mina granskningar"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktioner"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startade"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback om undersökning"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nålar"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachning"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personligt"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallar"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skapa lösenord"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inloggningsuppgifter"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-post"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-post"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logga in"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["återställ"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granska mål"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mallar"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["användare"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa mall"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggning"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallar"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnemang"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konto"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fakturering"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["företag"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationer"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalning"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenser"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inställningar"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnemang"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringar"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningar"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny kategori"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnemang"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motstridande meddelande hittades"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra eller ta bort ett av de motstridande meddelanden"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Använd ikonen ", _interpolate(_named("placeholder")), " för att utesluta känsliga data från Automatisk QA. I stället för att använda \"Ta hand om dig John\" kan du exempelvis använda \"Ta hand om dig ", _interpolate(_named("placeholder")), "\" för att representera alla namn."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Högst ", _interpolate(_named("limit")), " rader"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpa regel på"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått gränsen på 10 egna kategorier"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nått gränsen på ", _interpolate(_named("limit")), " egna kategorier"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till villkor"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till rad"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["och"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort villkor"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ifall agentmeddelande"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I annat fall poängsätt"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängsätt sedan"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kategori för poängkort"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd \"/\" för att lägga till en variabel"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla riktningar"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelande får inte vara tomt"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelandet får inte vara längre än 300 tecken"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla källor"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla kanaler"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla taggar"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla konversationer"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begränsa de områden där din regel gäller."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifika konversationer"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa Spotlight-insikt"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelande får inte vara tomt"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått gränsen på 10 egna insikter"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nått gränsen på ", _interpolate(_named("limit")), " egna insikter"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyll i insiktsnamn"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insiktsnamn"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kategori"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insiktstyp"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till inställningarna för arbetsyta"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte nu"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill börja använda denna bedömningskategori går du till inställningarna för poängkort på arbetsytan och lägger till den på ett valfritt poängkort."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori skapad"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängsätt avslutade konversationer automatiskt genom att identifiera specifika nyckelord eller fraser."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fungerar inte med röstutskrifter."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag förstår"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["High fem! ✋ Du har maximerat dina automatiska bedömningskategorier på ", _interpolate(_named("limit")), ". Du är kategoriseringens guide! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorigräns uppnådd"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att alla fält är ifyllda korrekt"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ge din bedömningskategori ett namn"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorinamn"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny bedömningskategori"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny bedömningskategori"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängsätt avslutade konversationer automatiskt genom att identifiera specifika nyckelord eller fraser. Fungerar inte med röstutskrifter."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hämta avslutade konversationer automatiskt genom att identifiera specifika nyckelord eller fraser. Fungerar endast med avskrifter."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängsätter konversationer automatiskt genom att identifiera specifika nyckelord eller fraser i både agent- och bot-meddelanden."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill använda denna bedömningskategori går du till inställningarna för poängkort på arbetsytan och lägger till det på ett poängkort."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedöm konversationer automatiskt baserat på specifika nyckelord eller fraser i meddelanden."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillgängligt för"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insiktskonfiguration"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna insikt visas i Spotlight baserat på definierade kriterier."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställning av poängkort"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna kategori kan användas på poängkort som är baserade på definierade kriterier."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chatt"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommer snart"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anpassad"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fördefinierat"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poängkort"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["röst"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara ändringar"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändringar sparade"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka på ", "{··}", " för att utesluta namn, företagsuppgifter och all annan känslig information under avslutande kontroller i Automatisk QA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till godkänd avslutning"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera godkänd avslutning"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd avslutning"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till undantag"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera undantag"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undantag"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till godkänd hälsning"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera godkänd hälsning"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänd hälsning"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klistra in ord åtskilda med radbrytningar"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryck på Enter efter varje ord för en ny post"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpligt språk"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I stället för att använda \"Ta hand om dig John\" kan du exempelvis använda \"Ta hand om dig ", "{··}", "\" för att representera alla namn."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platshållare för dynamiskt innehåll"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undantag"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 undantag tillagt"]), _normalize([_interpolate(_named("count")), " undantag tillagda"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Undantag redan tillagt"]), _normalize(["Undantag redan tillagt: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till undantag"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga undantag hittades"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga undantag tillagda"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undantag (skiftlägeskänsligt)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lägg till undantag"]), _normalize(["Lägg till 1 undantag"]), _normalize(["Lägg till ", _interpolate(_named("count")), " undantag"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter ett ord eller en fras"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undantag sparat"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["språk"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla språk"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter språk"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Avslutning redan tillagd"]), _normalize(["Avslutningar redan tillagda: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till avslutning"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga godkända avslutningar hittades"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga godkända avslutningar"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till avslutning"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter en avslutning"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning tillagd"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning sparad"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formulär har dubblettposter: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hälsning redan tillagt"]), _normalize(["Hälsningar redan tillagda: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till hälsning"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga godkända hälsningar hittades"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga godkända hälsningar"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till hälsning"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter en hälsning"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning tillagd"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning sparad"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till undantag"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ta bort undantag"]), _normalize(["Ta bort undantag"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vill du ta bort detta undantag permanent?"]), _normalize(["Vill du ta bort dessa undantag permanent?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera undantag"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ett eller flera undantag är längre än ", _interpolate(_list(0)), " tecken"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " tecken"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterad"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterad av"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabel"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiera dina egna godkännanden i linje med ditt varumärke och dina servicestandarder. När Automatisk QA läggs till upptäcker den endast godkända avslutningar. Utförda ändringar tillämpas till framtida konversationer."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått den högsta gränsen på 100 godkända avslutningar. Om du vill lägga till fler kan det vara dags att gå igenom dina befintliga avslutningar och förbättra dem."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gränsen för godkända avslutningar uppnådd"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända avslutningar"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord och fraser som du vill att Automatisk QA ska ignorera som stav- eller grammatikfel. Utförda ändringar tillämpas till framtida konversationer."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera-lista"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange dina egna hälsningsfraser som överensstämmer med ditt varumärke och dina servicestandarder. När Automatisk QA läggs till upptäcker de endast godkända hälsningar. Utförda ändringar tillämpas till framtida konversationer."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått den övre gränsen på 100 godkända hälsningar. Om du vill lägga till fler kan det vara dags att gå igenom och förbättra dina befintliga hälsningar igen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gränsen för godkända hälsningar uppnåddes"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända hälsningar"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte nu"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kategori"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du ta bort denna bedömningskategori permanent?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla kategorilista"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagen kategori kommer inte att visas på några nya konversationer och filter. Historiska data på kontrollpaneler och konversationer förblir intakta."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorin kommer att tas bort från alla poängkort där den har lagts till."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ta bort kategorin för poängkort"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori för poängkort borttagen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kategori för poängkort"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA-kategorier"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " som drivs av AI-modellen kommer att förbli på poängkortet men kommer inte längre att poängsättas automatiskt"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Att stänga av integration med LLM innebär inte att Automatisk QA-funktionen stängs av helt. Följande kommer att hända:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiska data"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " kommer att finnas kvar på kontrollpanelen för Automatisk QA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuellt tillagda grundorsaker"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " kommer att finnas kvar, men alla LLM-baserade grundorsaker kommer att tas bort"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng av LLM-baserad Automatisk QA"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng av"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA använder AI och stora språkmodeller (LLM) för att automatiskt poängsätta specifika kategorier. Dina uppgifter behandlas enligt ett strikt databehandlingsavtal (DPA) och används aldrig för att träna några modeller."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om generativ AI"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatisk QA använder Microsoft Azure OpenAI-tjänsten för ", _interpolate(_named("automatically_scoring_categories")), ". Dina uppgifter behandlas endast enligt strikt DPA för att ge Automatisk QA till dig och ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["för att bedöma vissa kategorier automatiskt"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["används aldrig för att träna några OpenAI-modeller"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-baserad Automatisk QA på"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-baserad Automatisk QA av"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-baserad Automatisk QA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiserad bedömning med AI i Automatisk QA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databehandling av MS Azure-baserade Automatisk QA-kategorier"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtycke från Microsoft Azure tillbakadragen"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtycke från Microsoft Azure lämnat. Konversationer kommer snart att få en automatisk bedömning."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan fortfarande använda Automatisk QA, men detta kommer att hända"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaktivering av MS Azure OpenAI-tjänsten kommer inte att stänga av Automatisk QA som en funktion."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan göra det i kategorivyn för poängkort."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA-kategorier som drivs av modellen kommer att stanna kvar på poängkortet men kommer inte att poängsättas automatiskt."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De grundorsaker du har lagt till manuellt kommer att finns kvar. Alla modellgenererade grundorsaker kommer att tas bort."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiska data ligger kvar på kontrollpanelen för Automatisk QA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaktivera databehandling via MS Azure OpenAI-tjänsten"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Större urval av Automatisk QA-kategorier."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruksklar support på flera språk."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snabbt införande av nya kategorier."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbättrad utvärderingsnoggrannhet. GPT-3.5 visar kontextuell medvetenhet på mänsklig nivå."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dra nytta av de senaste framstegen inom AI-teknik."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det här kommer du att få"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera databehandling via MS Azure OpenAI-tjänsten"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtycke lämnat"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget samtycke lämnat"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla nya användare tilldelas denna arbetsyta"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardarbetsyta"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12-timmars tid (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24-timmars tid (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsformat"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mån"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sön"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Företagsprofil uppdaterad"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Början på veckan"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egen integration"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID för åtkomstnyckel och hemlig nyckel finns vanligtvis under Åtkomsthantering > Användare"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID för AWS-åtkomstnyckel"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t.ex. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt ändring av hemlig åtkomstnyckel"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra värde för hemlig åtkomstnyckel"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningen gör det möjligt att endast definiera en region, vilken vanligtvis återfinns under Plats"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-region"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t.ex. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en hemlig nyckel"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig AWS-åtkomstnyckel"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t.ex. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan hitta all relevant information i Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hittas vanligtvis under inställningarna för Amazon Connect-instans och på sidan Datalagring"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagringsplats för samtalsinspelningar"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t.ex. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel inträffade när Amazon Connect lades till. Kontrollera om alla fält är ifyllda korrekt och försök igen."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagringsplatsen ska vara i formatet hink/mapp, åtskilda med ett snedstreck."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill lägga till en anslutning kontaktar du ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundsupport"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla dina Zendesk-ärenden kommer att synkroniseras automatiskt. Du kan välja att utesluta valda ärenden."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien och Stillahavsområdet (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien och Stillahavsområdet (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien och Stillahavsområdet (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien och Stillahavsområdet (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien och Stillahavsområdet (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (centrala)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverregion"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellanöstern (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj serverregion"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sydamerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Östra USA (Virgina)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Östra USA 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Västra USA (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning redan tillagd"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starta anslutning"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronisera endast konversationer som"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanal ett, kanal två"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna anslutnings unika ID är ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 månad"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 år"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 månader"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 månader"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiva konversationer kommer att tas bort efter den valda lagringsperioden. Inskickade granskningar förblir synliga i aktivitets- och kontrollvyerna."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagringsperiod"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj lagringsperiod"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observera att denna åtgärd är oåterkallelig."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag är medveten om att detta kommer att ta bort alla helpdesk-relaterade data för denna anslutning från Zendesk QA, inklusive konversationer, fält och taggar."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inskickade granskningar kommer inte att påverkas och förblir synliga i specifika vyer."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupp ett, grupp två"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkludera"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiv utveckling"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna integration finns fortfarande i ", _interpolate(_named("activeDevelopment")), ". Det kan hända att vissa detaljer saknas (t.ex. bilagor eller anpassade fält)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brevlåda ett, brevlåda två"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tagg ett, tagg två"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig kod för API-token"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter \"Namn på ärendefält\" på Freshdesk, under Inställningar → Ärendefält"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta \"Custom Field Names\" i Help Scout under Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta \"Namn på Klass-konversationer\" på Kustomer under Inställningar → Klasses → Konversation"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta \"Anpassat fältnamn\" i Live Agent under Inställningar → Anpassade fält"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitta \"Anpassat fältnamn\" på Wix under Inställningar → Anpassade fält"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separera fält-ID:n med kommatecken. Du hittar dessa ID:n i Zendesk, under Admin → Ärendefält."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut några av dina Helpdesk-användare för att granska dina första konversationer. Oroa dig inte, vi kommer inte att meddela dem om du inte bjuder in dem att bli medlemmar på arbetsytan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har integrerats"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till medlemmar"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kommer nu att börja hämta dina konversationer."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domän: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Helpdesk-identifierare: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att autentisera din anslutning"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt är klart. Detta är din nya anslutning."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissa anslutningar har inte uppdaterats eller också har de blivit inaktiva. Försök uppdatera dessa anslutningar eller ta bort dem från din lista."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID för API-klient"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig kod för API-klient"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skapa en ny API-token via Inställningar → Konto → API och SDK. Den webbadress för omdirigering som ska användas är ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto-ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj din helpdesk för att komma igång"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om ditt Intercom-konto är konfigurerat för att servas från EU eller Australien ska du uppdatera detta."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-postadress som du använder för att logga in på ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskera slutanvändarens namn, e-postadress och telefonnummer. Bankuppgifter är redan maskerade för alla helpdesk-anslutningar."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga integrationer ännu. Lägg till en nu."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du behöver dessa roller:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa anslutning"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en ny anslutning"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du avmarkerar detta kan du endast lägga till denna anslutning manuellt på specifika arbetsytor"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut till alla befintliga arbetsytor"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla arbetsytor"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-nyckel"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig kod för API-nyckel"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaler"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anslutningen ", _interpolate(_list(0)), " borttagen"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningsnamn"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapades den"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan hämta din API-token nedan."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-tröskelvärde"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort anslutningen ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv \"ta bort anslutning\" för att ta bort denna anslutning"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort anslutning"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera och övervaka dina anslutna datakällor."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera anslutning"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uteslut"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separera fältnamn med kommatecken"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att uppdatera"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ge din integration ett unikt namn"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj avancerade alternativ"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj fält med känsliga data"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagra aldrig webbadresser för bilagor"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera bilagor"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagra aldrig konversationsinnehåll (du kan hämta det senare på begäran från din Helpdesk)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera konversationsinnehåll"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagra aldrig konversationsämne (du kan hämta det senare på begäran från din Helpdesk)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera ämnesrad"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Din underdomän för ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-region"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matcha team-ID för inkorg"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeriskt ID för ditt Intercom-team"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pågår..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brevlådor"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera anslutning manuellt"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskera kunduppgifter"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga arbetsytor"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn på känsligt fält"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID för känsligt fält"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnya anslutning"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnya anslutning"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut till Salesforce-sandbox"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Upprätta en anslutning med ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa avancerade alternativ"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta endast in konversationer med en poäng som är lägre än eller lika med det inställda tröskelvärdet"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpa ett CSAT-gränsvärde"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronisera endast konversationer som..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skaffa senaste konversationer"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taggar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningar"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera anslutning"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning uppdaterad"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarnamn"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din helpdesk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill avbryta ditt abonnemang och ta bort ditt konto kontaktar du ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundsupport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konto"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagning av ett ärende i Zendesk Support (eller en annan helpdesk) påverkar inte associerade konversationsgranskningar. Du kan fortfarande visa och hantera dem."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Radering av ett ärende i Zendesk QA innebär också att associerade konversationsgranskningar tas bort.", _interpolate(_named("line_break")), "Att ta bort ett ärende i Zendesk Support (eller en annan helpdesk) påverkar inte associerade konversationsgranskningar. Du kan fortfarande visa och hantera dem."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera granskningar"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Att ta bort granskningar är permanent. Det påverkar också data på kontrollpaneler."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort alla granskningar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort alla"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla granskningar borttagna"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort alla"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När det finns granskningar förknippade med borttagna ärenden visas de här."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskningar ännu"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter ärende-ID"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ärende-ID: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagna konversationer"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Standardarbetsytor kan inte tas bort. Om du vill ta bort denna arbetsyta väljer du en annan förvald arbetsyta i ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinställningar"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna åtgärd kan inte ångras."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["radera och ta bort alla data"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jag förstår att detta kommer att ", _interpolate(_named("removeAllData")), " som är relaterade till arbetsytan, inklusive användare av arbetsyta och anslutningar."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort arbetsyta"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv \"ta bort arbetsyta\" för att ta bort denna arbetsyta"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort arbetsytan ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ta bort arbetsyta"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Var säker på att du vet vad du gör eller kontakta en yrkesverksam i förväg."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj om den förvalda granskade ska väljas baserat på den mest frekventa agenten eller den aktuella handläggaren."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mest aktiva agenten"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förvald granskad"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ge personer möjlighet att granska sina egna konversationer och gör det möjligt för agenter att utföra granskningar"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsyta har tagits bort"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsyta har uppdaterats"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprätta neutral bedömning genom att dölja granskningar som tidigare lämnats av andra granskare"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opartisk bedömning"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Färg"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna åtgärd kan inte ångras."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observera att detta kommer att radera och ta bort alla data som är relaterade till denna arbetsyta, inklusive användare av arbetsyta och anslutningar."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort arbetsyta"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tar bort arbetsyta..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk länk"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk länk"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det går inte att avaktivera din aktuella inloggningsmetod"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringsmetoder har uppdaterats"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåt vissa typer av autentiseringsmetoder för alla användare."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringar"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTO"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare och arbetsytor"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa och hantera arbetsytor och bjud in eller anslut användare."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanna kvar på sidan"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna sidan"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du lämna denna sida och förlora alla ändringar?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningar"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringar"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnemang"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som att inte finns några konversationer att importera. Börja med att ha några konversationer med din helpdesk och gå tillbaka hit för att analysera dem."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer hittades"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan inte ta bort denna systemkategori"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kategori"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Att ta bort kategorier är permanent. Den raderar alla kategoridata från rapporterna."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill återställa denna kategori måste du skapa den igen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kategori"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter kategori"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För att spara tid vid granskning fyller automatiska kategorier i poängkortet automatiskt med föreslagna bedömningar. Manuella kategorier fylls i från början av granskare."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste uppdatering"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiskt"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuellt"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavning och grammatik"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förståelse"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läsbarhet"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösning erbjuden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kategorier hittades"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga grundorsaker hittades"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga poängkort hittades"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova en annan sökning eller ändra filter"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla kategorier"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " poängkort"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " poängkort"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " poängkort"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla poängkort"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " poängkort"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusar"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusar"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusar"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla statusar"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusar"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla typer"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbetsytor"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbetsytor"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga arbetsytor hittades"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbetsytor"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla arbetsytor"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbetsytor"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vikt på ", _interpolate(_named("weight")), " bidrar ", _interpolate(_named("percentage")), " % till den totala poängen"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort poängkort"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagning av poängkort är permanent. Det kommer att radera alla poängkortsdata från rapporteringen."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill återställa detta poängkort måste du skapa det igen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort poängkort"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste uppdatering"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundorsaker"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bedömningsskala uppdaterad. ", _interpolate(_named("buttonStart")), "Börja granska", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA anger som standard en binär skalad, men du kan också välja en mer detaljerad skala:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill se olika alternativ för bedömningsskala, konfigurerade efter kategori, går du till dina ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inlevelse/ton"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkunskap"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genom att uppdatera bedömningsskalorna:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din tidigare bedömningsskala och motsvarande kategorier kommer att arkiveras (inte tas bort)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissa kontrollpaneldata kommer att påverkas eftersom det kan hända att din gamla och nya skala inte är jämförbara."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klona mina befintliga inställningar för bedömningskategorier till den nya skalan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill uppdatera bedömningsskalorna?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ändra visningsformatet för poängkort (siffror eller emojis) och göra kategorier obligatoriska eller valfria."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd emojis istället för siffror"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåt personer att hoppa över kategorier"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösning"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ av bedömningsskala"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kategorier för 1 arbetsyta har klonats"]), _normalize(["Kategorier för ", _interpolate(_named("workspacesAmount")), " arbetsytor har klonats"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar för arbetsyta"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa samtal automatiskt där agenten inte uppmanar kunden att de kan förvänta sig en tystnad i konversationen."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen har perioder av tystnad som varar längre än det angivna tröskelvärdet."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiera högsta tröskelvärde för period av tystnad för samtal"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period av tystnad"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagna insikter kommer inte att visas i några nya konversationer och filter. Historiska data på kontrollpaneler och konversationer förblir intakta."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ta bort Spotlight-insikt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-insikt borttagen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort Spotlight-insikt"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa konversationer som innehåller specifika ord eller fraser i utskrifter och meddelanden."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiera samtal automatiskt som saknar den obligatoriska upplysningen, t.ex. \"Detta samtal spelas in\" och liknande."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syftar till att identifiera en upplysning om inspelning i samtalet med hjälp av en LLM (Large Language Model) som dynamiskt förstår sammanhang, vilket eliminerar behovet av förinställda fraser."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upplysning om inspelning saknas"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa samtal automatiskt där agenten inte uppmanar kunden att de kan förvänta sig en tystnad i konversationen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period av tystnad"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange tillfällen med perioder av tystnad i ett samtal"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Behöver du göra ändringar i ditt abonnemang? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") så hjälper vi gärna till."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysera automatiskt 100 % av konversationerna över agenter, BPO:er, kanaler och språk. AI upptäcker problem, kunskapsbrister och coachningstillfällen för att förbättra servicen."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 % täckning med Automatisk QA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback från agenter och coachning"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-insikter i Spotlight"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerade rapporter och kontrollpaneler"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser och filtrering av sentiment"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand-översättningar"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska tilldelningar och upprättande av mål"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachning och quiz"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk kvalitetssäkring (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt i Professional, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (äldre)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks för omedelbar notifiering vid kritiska fel"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration för datalager"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud-integration"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad integrationsutveckling"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagerad ansvarig för kundframgång"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkt åtkomst till våra produkt- och teknikteam via Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt i Growth Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA för 100 % täckning"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-drivna konversationsinsikter för att förstå data bättre"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-förbättrad kvalitetshöjning och filtrering av Helpdesk-data"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Automatiserad detektering av interaktioner som måste granskas"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentanalyser och filtrering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-drivna resultatrapporter och insikter"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översättning av On-demand-konversation"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska tidkort"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analys av antal godkända"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt i AI Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementeringspaket för Enterprise inklusive integration med Salesforce och Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad integrationsutveckling"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration för datalager"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks för omedelbar notifiering vid kritiska fel"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagerad ansvarig för kundframgång"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkt åtkomst till våra produkt- och teknikteam"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidig åtkomst och inflytande över vår färdplan"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiska granskningsuppdrag och målsättning"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målinriktad coachning och statistik"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz för hantering av lärande"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantering av användarroller och behörigheter"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskering av känsliga data"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerade filtreringsalternativ och konversationssökning"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassningsbara poängkort med flera bedömningar, justerbara vikter och alternativ för automatisk felhantering"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbläsartillägg för att granska konversationer överallt"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser av grundorsak"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestationsrapporter för att jämföra agenter, team, BPO:er"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-undersökningar och analyser av feedback från kunder"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt i Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (äldre)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flera bedömningsskalor per konversation"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integration"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-drivna interaktiva konversationsinsikter"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska uppdrag"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nålar för coachning"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachningssession"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentanalyser och filtrering"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrering baserad på konversationskomplexitet"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationsinsikter"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkorliga poängkort"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser av grundorsak"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerad AI-driven CSAT-analys"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering av betygsättning"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbläsartillägg för att granska konversationer var som helst"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight för att upptäcka konversationer som måste granskas"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översättning av On-demand-konversation"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska tidkort"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad plan"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (äldre)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obegränsade konversationsgranskningar"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-export"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer-to-peer och självgranskningar"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort med justerbara vikter och alternativ för automatisk felhantering"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robusta filtreringsalternativ"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagliga, veckovisa eller månadsvisa granskningsmål"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-undersökningar och grundläggande analys"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpaneler"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter för att jämföra agenter, team, BPO:er"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantering av användarroller och privilegier"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag vill göra en ändring i mitt abonnemang på Zendesk QA.\n\nMarkera eller ange ett alternativ och ange antalet licenser du vill ändra:\nJag skulle vilja [lägga till X licenser] [ta bort X licenser] [uppgradera till planen för Zendesk QA]\n\nDela företagsnamnet och namnet som är associerat med ditt konto för Zendesk QA samt all annan information vi behöver känna till för att behandla denna ändring."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändring av abonnemang på Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag funderar på att köpa ett abonnemang på Zendesk QA.\nDela företagsnamnet och namnet som är associerat med ditt Zendesk QA-konto samt alla andra uppgifter vi behöver känna till för att behandla denna förfrågan:\n\nDela även eventuella specifika frågor som du vill ta upp innan du köper:\n\nTack!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köp av abonnemang på Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditt abonnemang upphör den ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " kvar)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din provperiod upphör den ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " kvar)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 dag"]), _normalize([_interpolate(_named("n")), " dagar"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") på den ", _interpolate(_named("dayOfMonth")), " varje månad."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") på den ", _interpolate(_named("dayOfMonth")), " varje månad."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") på den ", _interpolate(_named("dayOfMonth")), " varje månad."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") på den ", _interpolate(_named("dayOfMonth")), " varje månad."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") på den ", _interpolate(_named("dayOfMonth")), " varje månad."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") på den ", _interpolate(_named("dayOfMonth")), " varje månad."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureras till ", _interpolate(_named("card")), " (upphör att gälla den ", _interpolate(_named("expires")), ") den ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["går ut om"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Utgånget"]), _normalize(["Går ut om 1 dag"]), _normalize(["Går ut om ", _interpolate(_named("expiresIn")), " dagar"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startpriset inkluderar ", _interpolate(_named("n")), " användarlicenser"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["börjar från ", _interpolate(_named("sum")), "/månad"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["månad"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per användare/månad"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per år"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per månad"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kvartal"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ta bort den schemalagda uppsägningen"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill fortsätta använda Zendesk QA ska du ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditt abonnemang sades upp den ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditt abonnemang är schemalagt att gå ut den ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dagars provperiod utan extra kostnad!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["använda"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("n")), " användare. Antalet licenser får inte vara färre än detta."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnemang kommer att återupptas den ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["år"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din nuvarande plan för Zendesk QA (", _interpolate(_named("plan")), ") omfattar inte AI-drivna funktioner. För att utnyttja den fullständiga kraften i funktioner för röstintelligens", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill avbryta ditt abonnemang kontaktar du oss på ", _interpolate(_named("link")), " eller chattar med oss."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan för kvalitetshantering"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetshantering"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnemang uppdaterat"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ett kreditkort"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla planer"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturering"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se faktureringsinformation"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureringsintervall"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluta abonnemang"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt abonnemang kommer att avbrytas vid slutet av faktureringsperioden."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakta oss"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakta oss för att redigera"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad prissättning"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedgradera"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt (spara 15 %)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvist"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betala årligen"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvis"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera abonnemang"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utforska planer"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostnadsfri provperiod"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureras manuellt"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan granska och bekräfta den slutliga beställningsöversikten i nästa steg."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populärast"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ingen aktiv betalningsmetod."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I provperiod"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återuppta ditt nuvarande abonnemang om du vill ändra planen"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausa abonnemang"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvis"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvartalsvis"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återaktivera abonnemang"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återuppta prenumeration"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenser"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starta provperiod nu"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbruten"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnyas ej"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausat"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnemangsstatus"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provperiod"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera abonnemang"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvara en minut för att ge feedback om din upplevelse av kundsupport. Tack på förhand – ", "{", "{", "agentName", "}", "}", " uppskattar din feedback!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hej ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-undersökningen för ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hur skulle du bedöma supporten du fick från ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vi uppskattar det verkligen. Vi använder din feedback för att göra ", _interpolate(_list(0)), " bättre för alla."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om undersökningar"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj undersökningstypen"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta kan ändras senare."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mät och förbättra kundtillfredsställelsen med automatiska CSAT-undersökningar på flera språk."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga undersökningar skapade"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa undersökning"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningsnamn"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökning har skapats"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Försök med att ändra nyckelordet eller ", _interpolate(_named("contact_us")), " om du vill att den ska listas här"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["berätta för oss"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget språk hittades"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj ett eller flera språk från listan nedan. ", _interpolate(_named("contact_us")), " om du inte hittar det språk du vill använda i listan."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till språk"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berätta för oss"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till språk"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Senast publicerat ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Senast sparad ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " fråga"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduktionsfråga"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett på vänster skala"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postämne"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsaker"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett på höger skala"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om en undersökning saknar översättningar kommer grundspråkets text att visas istället"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpa översättningar"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översättningar saknas"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se till att granska alla dina översättningar innan du tillämpar dem. Gör ändringar i den nedladdade filen, spara den och ladda upp den igen vid behov. Om en token ", _interpolate(_named("token_example")), " inte visas som en tagg, har den antingen ett felaktigt format eller i ett fält som inte stöder token."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska översättningar"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översatt"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammanfattar konversation och visar förslag på kommentarer."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-assistent (på engelska) i Zendesk QA"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan redigera typer när de har skapats"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna typ finns redan. Välj ett annat namn."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara den anpassade typen som ett valbart alternativ i menyn Undersökningstyp och gruppera alla skapade undersökningar på kontrollpanelen."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv ord och tryck på Enter för att spara"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera anpassade typer"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typnamnet får inte vara tomt"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiera typ"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad typ sparades inte. Tryck på Enter för att spara den."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsändarens e-postadress"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsändarens namn"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I vilken grad håller du med om påståendet: \"", _interpolate(_named("agentName")), " gjorde det enkelt att hantera mitt problem\"?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hur enkelt har det varit att få hjälp av ", _interpolate(_named("brandName")), " hittills?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På det hela taget, hur lätt var det att lösa ditt problem idag?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hur lätt var det att interagera med vårt team?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbadress för Privacy Policy (Integritetspolicy) (valfritt)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbadress för tjänstevillkor (valfritt)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hur skulle du bedöma supporten du fick från ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hur var vår service för denna förfrågan?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hur nöjd var du med supportombudet?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hur nöjd är du med den tjänst du fick från ", _interpolate(_named("agentName")), " idag?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad fråga"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definiera din egen anpassade fråga om ", _interpolate(_named("type")), "..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv in anpassad fråga"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassa webbadresser"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ditt e-postmeddelande"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du redigerar namnet på en anpassad frågetyp uppdateras den i alla undersökningar som använder den, utan att påverka de insamlade uppgifterna."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera anpassade typer"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postämne"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduktionsfråga"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " fråga"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ny anpassad typ"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innehåll"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token för personanpassning"]), _normalize(["Token för personanpassning"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dra och släpp din token för personanpassning i fältet nedan."]), _normalize(["Dra och släpp dina token för personanpassning i fälten nedan."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konversation ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För närvarande är det endast möjligt att skicka ut CSAT-undersökningar via e-post. Vi arbetar med att ge dig fler möjligheter, t.ex. CSAT-undersökningar i helpdesk, SMS-undersökningar med mera."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synkronisera eller förnya anslutningen"]), _normalize(["Synkronisera eller förnya anslutningarna"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronisera eller förnya anslutningen ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Anslutning är inte korrekt synkroniserad"]), _normalize(["Anslutningar är inte korrekt synkroniserade."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avmarkera den relevanta kryssrutan ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentisera nu ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till minst en regel för utskick av undersökningar för att aktivera anslutningen"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna anslutning är avaktiverad just nu. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anslutningen maskerar känsliga klientuppgifter. Om du vill skicka ut undersökning,\n", _interpolate(_named("link")), " i inställningarna."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna anslutning kräver ytterligare behörigheter för att skicka ut undersökningar.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentisera nu"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA kräver mer behörighet för Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " och ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 timme"]), _normalize([_interpolate(_named("n")), " timmar"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minuter"]), _normalize(["1 minut"]), _normalize([_interpolate(_named("n")), " minuter"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["timme"]), _normalize(["timmar"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " för att konfigurera utskicksregler för undersökningar."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" dag"]), _normalize(["dagar"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningslänkar upphör att gälla"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länkar upphör att gälla om"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhindra att undersökningar besvaras om det har gått för lång tid"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["och"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " undersökning ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtskilj taggarna med kommatecken eller tryck på Enter när du har skrivit. Se till att var och en listas exakt som i Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange de villkor som ska gälla för att skicka ut undersökningen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler för att skicka ut undersökningar"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler för utskick av uppföljning på undersökning"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En undersökning ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " finns redan.\nLös konflikten genom att ändra reglerna, antingen här eller i ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har redan skapat en anslutning med matchningsregler för undersökningen ", _interpolate(_named("name")), ". Lös konflikten genom att ändra reglerna, antingen här eller i ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ytterligare en regel"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till varumärke"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj villkor"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter ett värde"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till tagg"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matchar samma regler"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett värde"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postmeddelande skickas om"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postuppföljning utskickad i"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbäddat fragment och e-postuppföljning"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera kod"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bifoga Zendesk QA-undersökningar i dina e-postmeddelanden genom att lägga till koden i din Zendesk-konfiguration."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsguide"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning av fragment"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodfragmentet kopierat till urklipp"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att kopiera fragment"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbäddat fragment"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna undersökning har osparade ändringar. Spara den igen för att se till att kodfragmentet återspeglar de senaste uppdateringarna."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till anslutning"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga anslutningar tillagda ännu"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg in e-postadress, live-chatt, telefonsamtal med mera i Zendesk QA med dina anslutningar"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om undersökningsleverans"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler för att skicka ut undersökningar"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler för utskick av uppföljning på undersökning"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till varumärkesregel"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till regel"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentisera"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + e-postuppföljning"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut ditt Intercom-konto till CSAT-undersökning i Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverera via"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vem får undersökningen och när"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera regler"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiverat"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar i appen gör det möjligt för dina kunder att besvara en undersökning utan att lämna chatten."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starta Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransregler per anslutning"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En undersökning ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " finns redan.\nLös konflikten genom att avaktivera anslutningen eller ändra reglerna, antingen här eller i ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverans"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange de villkor som ska gälla för att skicka ut undersökningen"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skapat av ", _interpolate(_named("name")), " den ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du tar bort detta språk innebär det att det inte längre är synligt för några svarande och alla översatta texter tas bort."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort språk"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna åtgärd kan inte ångras."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du avaktivera språket genom att dölja det från undersökningen. Detta gör det möjligt för dig att hålla alla dina översättningar på plats utan att visa dem för svarande."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senast redigerad av ", _interpolate(_named("name")), " den ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lär dig mer om undersökningsspråk"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Innan du lägger till andra språk måste du se till att du har slutfört dina ändringar i stegen för styling och innehåll. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till språk"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fält översatt"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig för svarande"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förvald"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner översättningsfil"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort språk"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ladda ner en översättningsfil"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granska översättningar"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Översättning slutförd. ", _interpolate(_named("review_link")), " eller ", _interpolate(_named("download_link")), " för att göra eventuella ändringar."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ladda ner en översättningsfil"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera vad som saknas"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visst innehåll har inte översatts och kommer att ersättas med engelska. ", _interpolate(_named("review_link")), " eller ", _interpolate(_named("download_link")), " om du vill lägga till översättningar som saknas."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara undersökningen"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Undersökningen innehåller osparade ändringar. ", _interpolate(_named("save")), " för att ladda ner översättningsfilen med de senaste ändringarna."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp översättningsfil"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj föredragen bedömningsskala"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ger kunder en listruta för att välja en orsak till bedömningen"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text i vänster skala (valfritt)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till orsak"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsaken kan inte vara tom"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna orsak finns redan"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa listruta för orsaker till dina poäng"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ny post skapas alltid om du tar bort en orsak och lägger till den igen med samma namn eller ändrar namnet på en orsak. På grund av detta påverkar det de data som visas på din kontrollpanel."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak till borttagning"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En borttagen orsak ligger kvar i kontrollpanelsrapporterna men kommer inte att vara synliga för nya svarande på undersökningar. Detta gäller också för relaterade översättningar."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort en orsak"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ta bort en orsak \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text i hög skala (valfritt)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På en 5-gradig skala anses 1 och 2 vara negativa poäng, 3 är neutral, 4-5 är positiva"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till text i skala"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " skala"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningsskala"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importerar dina konversationer. Detta kan ta upp till 24 timmar."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna kommer att användas som en token för personanpassning under hela undersökningen."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärkets namn"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knappar och länkar"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassa utseendet på din undersökning"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotypbakgrund"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaten JPG, PNG och GIF stöds"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotyp"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styling"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp logotyp"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut med stil"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett meddelande som personer kommer att se när de har skickat in sin feedback"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tackmeddelande"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felaktig hex-färgkod"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felaktig e-postadress"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält får inte vara tomt"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felaktig webbadress"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte synlig:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum skapat"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar skickade"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar skickade under de senaste 30 dagarna"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökning har tagits bort"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökning har duplicerats"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postmeddelande om förhandsvisning har skickats"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökning har publicerats"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökning har uppdaterats"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökning har pausats"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicera"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausa"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausa undersökning"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange e-postadressen som förhandsvisningen av undersökningen ska skickas till"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["din ", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj språk för förhandsvisning"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka ett e-postmeddelande med förhandsvisning"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka ett exempel på undersökning till din e-postadress. Bedömningarna från förhandsvisningen av undersökningen lagras inte."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Förhandsvisa undersökningen för ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning av e-postundersökningar på webben"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återuppta"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara som utkast"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärkets namn"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varumärkesspecifika namn kan läggas till i steget för styling"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundnamn"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportrepresentant"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagen"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausad"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-undersökningen för ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osparade ändringar gjordes"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera och återuppta undersökning"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du inte ser detta e-postmeddelande korrekt ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag var inte nöjd med det stöd jag fick eftersom..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj orsak"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammanfattning av konversation"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exempel: Kunden hade problem med att uppdatera betalningsmetoden. Agenten delade en artikel som kan vara till hjälp. Det fungerade."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA AI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjänstevillkor"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack för din feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilken är den främsta orsaken till dina poäng?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berätta varför du gav detta betyg..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hög ansträngning"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Låg ansträngning"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klickar du här för att öppna undersökningen"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integritet"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din anpassade fråga visas här"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du gav din upplevelse av ", _interpolate(_list(0)), " poängen"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka in feedback"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avprenumerera"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väldigt missnöjd"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mycket nöjd"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observera att aktuell undersökningsstatus och leveransregler inte kommer att inkluderas i undersökningsdubbletten"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplicera undersökning \"", _interpolate(_list(0)), "\""])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du använder det inbäddade e-postfragmentet måste du se till att ta bort det från Zendesk innan du pausar undersökningen"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga svar samlas in när undersökningen är pausad."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausa undersökning"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att du tar bort fragmentet från Zendesk innan du pausar undersökningen."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort undersökning"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du använder det inbäddade e-postfragmentet måste du se till att ta bort det från Zendesk innan du tar bort undersökningen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna åtgärd tar bort undersökningen permanent. Användare som fick undersökningen kan fortfarande fylla i den och alla uppgifter som är relaterade till undersökningen förblir i Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort undersökningen ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att du tar bort fragmentet från Zendesk innan du tar bort undersökningen."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina tillämpade ändringar kommer att ses av nya svarande."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera undersökning"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att du kopierar den senaste versionen av det inbäddade fragmentet när du har publicerat undersökningen."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du använder det inbäddade e-postfragmentet måste du kopiera det till Zendesk när du har återupptagit undersökningen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsätt att skicka ut undersökningen och samla in svar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera och återuppta undersökning"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att du lägger till det inbäddade fragmentet i Zendesk när du har återupptagit undersökningen."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera undersökning"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att du kopierar den senaste versionen av det inbäddade fragmentet när du har uppdaterat undersökningen."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill ta bort denna undersökning? Denna åtgärd kan inte ångras."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort undersökning?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din provperiod har gått ut, men du kan fortsätta att dra nytta av Zendesk QA genom att uppgradera till ett fullständigt konto."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din provperiod har gått ut, men du kan fortsätta att dra nytta av Zendesk QA genom att uppgradera till ett fullständigt konto. Tala med din lokala administratör för att konfigurera detta."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din provperiod har utgått"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgradera nu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kostnadsfria provperiod har upphört."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange dina betalningsuppgifter om du vill fortsätta använda den fullständiga versionen av Zendesk QA."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kontoadministratör behöver ange betalningsuppgifter om du vill fortsätta använda den fullständiga versionen av Zendesk QA."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera betalningar"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en användare"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skapad den ", _interpolate(_named("date")), " av ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " uppdrag"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som att du fortfarande arbetar med detta. Du förlorar all information som du har lagt till hittills om du lämnar utan att spara ändringarna."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detta uppdrag upprepas varannan ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detta uppdrag upprepas varje ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alla granskade omfattade i en cykel"]), _normalize(["Alla granskade omfattade i ", _interpolate(_named("nr")), " cykler"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konversationer"]), _normalize([_interpolate(_named("n")), " konversation"]), _normalize([_interpolate(_named("n")), " konversationer"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["av ", _interpolate(_named("nr")), " agent"]), _normalize(["av ", _interpolate(_named("nr")), " agent"]), _normalize(["av ", _interpolate(_named("nr")), " agenter"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["deltagare"]), _normalize(["deltagare"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konversationer per cykel"]), _normalize([_interpolate(_named("n")), " konversation per cykel"]), _normalize([_interpolate(_named("n")), " konversationer per cykel"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " per granskade i en rad"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["granskad"]), _normalize(["granskade"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["granskare"]), _normalize(["granskare"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskade"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare av arbetsyta"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare av arbetsyta utom agenter"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cykel ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ej återkommande"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " uppdrag"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskning"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om uppdrag"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa uppdrag"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna åtgärd tar bort uppdraget permanent och tar bort dess data från kontrollpanelen. Alla granskningar relaterade till den kommer att ligga kvar i Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort uppdraget ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag borttagen"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag duplicerat"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga uppdrag tillagda ännu"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att skapa uppdrag"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdragsnamn"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cykel uppdateras och en ny uppgiftslista skapas. Redan utförda granskningar räknas fortfarande mot målet."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omedelbart"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I början av nästa cykel den ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När ska man publicera ändringarna?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändringar som har gjorts i detta uppdrag kommer att publiceras med detta uppdrag"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ställ in uppdrag som aktivt?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återkommande på uppdragets startdag"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första fredagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra fredagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje fredagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde fredagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista fredagen i varje månad"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första måndagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra måndagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje måndagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde måndagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista måndagen i varje månad"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första lördagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra lördagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje lördagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde lördagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista lördagen i varje månad"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första söndagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra söndagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje söndagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde söndagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista söndagen i varje månad"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första torsdagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra torsdagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje torsdagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde torsdagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista torsdagen i varje månad"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första tisdagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra tisdagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje tisdagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde tisdagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista tisdagen i varje månad"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första onsdagen i varje månad"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra onsdagen i varje månad"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje onsdagen i varje månad"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde onsdagen i varje månad"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista onsdagen i varje månad"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassad återkommande frekvens"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:a varje månad"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjärde"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepas"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veckodag"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slut"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga träffar"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidszon"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cykelinställningar"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdragsnamn"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundläggande information"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I början av varje uppdragscykel genereras en fördefinierad lista med konversationer för utvärdering."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerade inställningar"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hämta nya konversationer automatiskt när granskningsmålet inte uppnås"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk hämtning är inte möjlig med procentuella ( %) granskningsmål"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måste vara ett heltal, inte en decimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåt ersättande konversationer (granskares roll och högre)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersätter konversationer"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåt granskare att vara tilldelade till sina egna konversationer för granskning"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskningar"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minsta antal granskningar måste vara minst 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange minsta möjliga granskningar för varje granskad"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konversationer"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["granskningar"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totalt antal granskningar"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varje granskare måste utföra ett angivet antal granskningar"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare måste granska ett visst antal konversationer tillsammans"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minsta antal granskningar måste vara minst 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsmålet måste vara mellan 1–100 %"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsmål"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varje granskad måste få ett minsta antal av deras totala antal konversationer granskade"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare kan välja konversationer från den delade poolen"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare tilldelas konversationer från den delade poolen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdragsmetod"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla bottar"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla deltagare på arbetsytan"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare av arbetsyta"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till granskare"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vem kommer att granskas?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj granskade"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj vem som ska granskas"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uteslutningar tillåts endast när alla användare av arbetsyta eller en grupp har valts"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare och granskade"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aktuell: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förvald arbetsyta"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilken typ av användare ska väljas som granskade?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mest aktiva agenten"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagare"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uteslut granskade (valfritt)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj användare som ska uteslutas"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uteslut granskare (valfritt)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj användare som ska uteslutas"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare av arbetsyta utom agenter"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till granskare"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vem kommer att göra granskningarna?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj granskare"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar tillåts inte på denna arbetsyta"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåt självgranskningar"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar tillåts inte på denna arbetsyta."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Matchande konversation i dag"]), _normalize(["Matchande konversationer i dag"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas som om uppdraget startade idag."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Framtida antal kan variera."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskade valda"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskare valda"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Granskad"]), _normalize(["Granskade"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Granskare"]), _normalize(["Granskare"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk hämtning: tillåts om inte tillräckligt med konversationer finns"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på fredagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på måndagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på lördagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på söndagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på torsdagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på tisdagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på onsdagar från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dagligen från kl ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startar ", _interpolate(_named("startDateTime")), " och slutar ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " datumvillkor"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " konversations- och helpdesk-villkor"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Inget konversations- och ", _interpolate(_named("count")), " helpdesk-villkor"]), _normalize(["Inga konversations- och ", _interpolate(_named("count")), " helpdesk-villkor"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " konversation och inget helpdesk-villkor"]), _normalize([_interpolate(_named("count")), " konversationer och inget helpdesk-villkor"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tillvägagångssätt: granskare ska välja konversationer"]), _normalize(["Tillvägagångssätt: granskare tilldelas konversationer"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärda felen som är markerade i översikten för att fortsätta"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: granskare måste granska alla konversationer"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mål: granskare måste granska en konversation"]), _normalize(["Mål: Granskare måste granska ", _interpolate(_named("count")), " konversationer"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: Granskare måste granska ", _interpolate(_named("count")), " % av konversationerna"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: inget granskningsmål inställt"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: varje granskad måste få alla sina konversationer granskade"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: varje granskad måste få ", _interpolate(_named("count")), " av deras totala konversationer granskade"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: varje granskad måste ha ", _interpolate(_named("count")), " % av deras totala konversationer granskade"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mål: varje granskare måste göra en granskning"]), _normalize(["Mål: varje granskare måste göra ", _interpolate(_named("count")), " granskningar"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ersätter: granskare kan inte ersätta konversationer"]), _normalize(["Ersätter: granskare kan ersätta konversationer"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Självgranskningar: granskare kan tilldelas egna konversationer"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga datumvillkor angivna"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget uppdragsnamn angivet"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkor"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " från ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varannan vecka"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varje dag"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månadsvis"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldrig"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veckovis"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cykellängd"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ställ in som aktiv"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara som utkast"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag sparat som utkast"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag inställt som inaktivt"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ställ in som inaktiv"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa uppdrag"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag skapat"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som aktiv"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som inaktiv"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De pågående uppdateringarna av uppdraget kommer att träda i kraft under nästa cykel, som börjar den ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera ändringar"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntar"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera uppdrag"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag uppdaterat"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten eller boten avslutade konversationen."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten avslutade konversationen på ett artigt sätt"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om konversationen avslutades"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar grammatik, stavning och stilfel vid interaktion med både agenter och bottar."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar agentens grammatik-, stavnings- och stilfel"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar agentens grammatik-, stavnings- och stilfel"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavning och grammatik"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar konversationen för agent- och bothälsningar."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hela konversationen för att se typiska hälsningsfraser"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hela konversationen för att se typiska hälsningar"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten förstår och uppmärksammar kundernas känslor"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifierar fall där agenten speglade kundens uppfattning eller använde omtänksamma ord som \"bekräfta\" och \"erkänna\""])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten förstår kundens fråga eller problem"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar om agenten förstår kundens fråga eller problem"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förståelse"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hur enkelt det är att förstå en text, med tanke på ordkomplexitet och meningslängd"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hur enkelt det är att förstå en text, med tanke på ordkomplexitet och meningslängd"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta meddelande innehåller 1 alltför lång mening, vilket gör det svårt att läsa"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Detta meddelande innehåller ", _interpolate(_list(0)), " alltför långa meningar, vilket gör den svår att läsa"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läsbarhet"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hela konversationen för att få fram en erbjuden lösning"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar hela konversationen för att få fram en erbjuden lösning"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösning erbjuden"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar agentens ton under konversationen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserar agentens ton under konversationen"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att upptäcka några misstag i agentens meddelanden"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att fastställa grammatikfel"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hälsningsfras identifierad. Lägg till nya vid behov via din admin i Inställningarna."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten avslutade konversationen artigt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen avslutningsfras identifierad. Lägg till nya vid behov via din admin i Inställningarna"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten återspeglade kundkänslan eller använde omsorgsfulla ord"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att identifiera om agenten återspeglade kundens åsikt eller använde omsorgsfulla ord"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten använde långa meningar och komplexa ord, vilket gör det potentiellt utmanande för vissa att förstå"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten använde komplexa ord, vilket gör det potentiellt utmanande för vissa att förstå"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten använde långa meningar, vilket gör det potentiellt utmanande för vissa att förstå"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten använde ett språk som är lätt att förstå"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte tillräckligt med data för att bedöma denna kategori"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten hade svårt att förstå kundens fråga/förfrågan"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten förstod kundens fråga/förfrågan väl"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att identifiera om agenten förstod kundens fråga/förfrågan"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens ton under konversationen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens ton under konversationen"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens ton under konversationen"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens ton under konversationen"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens ton under konversationen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att fastställa agentens ton"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen avslutades inte"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationen avslutades"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att identifiera om konversationen avslutades"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten saknade empati gentemot kunden och deras problem"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten visade empati gentemot kunden och deras problem"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att identifiera om agenten visade empati gentemot kunden och deras problem "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden hälsades inte välkommen under konversationen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden hälsades välkommen under konversationen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att identifiera en typisk hälsning"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten tillhandahöll ingen lösning till kunden"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten erbjöd lösning till kunden"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att identifiera om agenten erbjöd kunden en lösning"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexa ord"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Långa meningar"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammatik"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felstavning"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursäktande"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugn"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glad"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyfiken"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionell"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stödjande"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 avslutning"]), _normalize([_interpolate(_named("n")), " avslutningar"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA kontrollerar endast godkända avslutningar. Om denna kategori är inställd som kritisk kommer granskningen att underkännas om de godkända avslutningarna inte används."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända avslutningar"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända avslutningar"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 undantag"]), _normalize([_interpolate(_named("n")), " undantag"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord och fraser som Automatisk QA ignorerar som stav- eller grammatikfel."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undantag för stavning och grammatik"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undantag från Automatisk QA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hälsning"]), _normalize([_interpolate(_named("n")), " hälsningar"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA söker endast efter godkända hälsningar. Om denna kategori är inställd som kritisk kommer granskningen att underkännas om de godkända hälsningarna inte används."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända hälsningar"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända hälsningar"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA-kategori"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en Automatisk QA-kategori"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiskt tilldelade grundorsaker:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto → Inställningar"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vissa kategorier använder MS Azure, som erbjuder ett utökat urval av funktioner och språk som stöds. Granska dina ", _interpolate(_named("link")), " för mer information eller kontakta en admin för att göra det."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Få åtkomst till en utökad lista med kategorier och språk som stöds genom att utnyttja MS Azure. Granska dina ", _interpolate(_named("link")), " eller kontakta en administratör för att göra det."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd Automatisk QA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatisk QA-kategorier tilldelas en poäng automatiskt med hjälp av en fast bedömningsskala. Du kan justera bedömningen manuellt senare. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en Automatisk QA-kategori"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En automatiskt tilldelad poäng beror på misstagens allvar och antalet av dem. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundorsaker"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast relevanta bedömningskategorier visas för granskare. Bedömningskategorier visas baserat på de villkor du angav när du skapade en kategori."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chatt"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inskolning"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj typ av bedömningskategori för arbetsytan \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskaren måste välja rätt poängkort manuellt för att se relevanta bedömningskategorier för varje konversation."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill ändra typ av bedömningskategori?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra kategorier"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra typ av bedömningskategori?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villkorlig bedömningskategori"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisk bedömningskategori"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ av bedömningskategori"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar för bedömningskategori"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna åtgärd kan inte ångras."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ta bort alla bedömningar"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som alternativ kan du behålla din statistik utan att ta bort några uppgifter genom att arkivera en kategori."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jag förstår att detta kommer att ", _interpolate(_named("deleteAllRatings")), " som getts för denna kategori."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t.ex. tonen är perfekt"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa endast kategori om"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Alltid\" kommer att ignoreras. Det kan inte kombineras med andra villkor."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alltid"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-taggar"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj villkor"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nöjdhetspoäng (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källtyp"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationskanal"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kategori behöver ett namn"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kategorin på ett poängkort"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kategori"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kategori"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivera"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori tillagd"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkiverad kategori syns inte på poängkortet, men den ger dig möjlighet att spara din statistik utan att ta bort några data."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivera kategori"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arkivera ", _interpolate(_list(0)), " kategori"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori arkiverad"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort kategori"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv \"ta bort kategori\" för att ta bort denna kategori"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ta bort kategori"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ta bort kategorin ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori borttagen"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategorin duplicerad"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp för bedömningskategori tillagd"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp för bedömningskategori borttagen"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppordning för bedömningskategori ändrad"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp för bedömningskategori uppdaterad"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratingkategori oarkiverad"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningskategori uppdaterad"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När en agent underkänns i en kritisk kategori misslyckas alla kategorier i granskningen automatiskt."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritisk bedömningskategori"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicera"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kategorier i denna grupp"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ny bedömningskategori"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera kategori"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort alla kategorier och granska data under denna grupp"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagning av kategorigruppen kommer att lämna alla kategorier som är länkade till den inte är ogrupperade."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort ", _interpolate(_named("categoryName")), " kategorigrupp"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du väljer att ta bort kategorierna under gruppen kommer du att förlora alla granskningsdata som är kopplade med dessa kategorier."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppnamn"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare kan välja fler än en orsak som grundorsak"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare kan välja fler än en orsak som grundorsak"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flera orsaker"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["t.ex. ton"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en ny kategorigrupp"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny grupp"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till \"Övrigt\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till grundorsak"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna grundorsak finns redan"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till grundorsak"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till grundorsaker för att förklara bedömning"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övriga..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedömningsskala"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiverat"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till poängkort i denna bedömningskategori"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oarkivera"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera kategori"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla bedömningar"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas för"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativa granskningar"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativa och neutrala bedömningar"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, uppdatera"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vikt"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier som är viktigare för dig kan väga tyngre vid beräkningen av ärendepoäng."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vikt (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lär dig mer om kalibrering"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lär dig mer om kalibrering"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avancerat"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genom att hålla regelbundna kalibreringssessioner kan granskare hålla sin granskningsteknik anpassad och se till att agenter får samma nivå av konsekvent och opartisk feedback."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa planer"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " är en process som hjälper ditt team att hålla sig på samma sida när det gäller hur alla kundinteraktioner ska hanteras och utvärderas."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibrering är tillgängligt för våra planer ", _interpolate(_list(0)), " och ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare givna granskningar visas inte när en konversation läggs till i sessionen."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte synlig"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidigare gjorda granskningar betraktas som kalibreringsgranskningar. Endast en konversationsgranskning per granskare läggs till i sessionen. Meddelandespecifika granskningar visas inte."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synligt"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighet av tidigare lämnade granskningar"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrering är en process som hjälper ditt team att hålla sig på samma sida när det gäller hur alla kundinteraktioner ska hanteras och utvärderas. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarna har obegränsad åtkomst till alla granskningar som görs under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alltid alla granskningar"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter att användarna har skickat in en granskning får de se utvärderingar som andra granskare har gjort under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa alla kalibreringsgranskningar efter att du har skickat in en granskning"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarna har obegränsad åtkomst till alla granskningar som görs under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alltid alla granskningar"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter att användarna har skickat in en granskning får de se utvärderingar som andra granskare har gjort under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa alla kalibreringsgranskningar efter att du har skickat in en granskning"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter att användarna har skickat in en granskning får de se utvärderingar som andra granskare har gjort under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa alla kalibreringsgranskningar efter att du har skickat in en granskning"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarna får endast se sina egna granskningar som ges under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se endast egna granskningar"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringsinställningar uppdaterade"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighet för kalibreringsgranskningar"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange tröskelvärdet för mätvärden i kontrollpanelen genom att fylla i luckorna i enlighet med teamets interna mätvärdesystem. Granska tröskelvärdet på kontrollpanelen."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutningar"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmar"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tröskelvärde"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tröskelvärdet uppdaterat"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett ögonblick bara..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se till att du har administratörsbehörighet och ", _interpolate(_named("retry")), ", eller ", _interpolate(_named("contact")), " för att få ytterligare hjälp."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakta oss"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som att vi inte kunde få åtkomst till ditt API för helpdesk."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["försök igen"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kopplar dig till din helpdesk. Du kommer att omdirigeras en kort stund."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " behörig"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gå tillbaka till din ", _interpolate(_list(0)), " för att lägga till en ny helpdesk-anslutning."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som om du har missat ett eller två steg."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns redan en organisation med samma e-postdomän"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anslutningssida"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under tiden..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänta en stund medan vi kontrollerar dina behörigheter."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under tiden..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrering"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skapa en arbetsyta"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du vill fortsätta använda Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästan klar att användas"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbetsyta hittades"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt konto"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din chef behöver tilldela dig en arbetsyta om du vill fortsätta använda Zendesk QA."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du tar bort ditt konto kommer alla data vi har om dig att tas bort permanent – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alla angivna bedömningar, konversationsinnehåll, arbetsytor etc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till i Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppla från"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du frånkopplar integrationen kommer alla notifieringar i Zendesk QA via Slack att tas bort"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppla från Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integration har kopplats bort från Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiera standardinställningar för notifieringar för alla nya kontoanvändare."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åsidosätt inställningar för alla användare"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du håller på att åsidosätta notifieringsinställningar för alla kontoanvändare"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarna kommer inte att kunna definiera sina egna notifieringsinställningar."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringsinställningar uppdaterade"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varning när en kritisk kategori misslyckas"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varning när CSAT får ett svar"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggers"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbadress"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa demoarbetsyta och data"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återskapa demodata och behåll demoarbetsytan."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort demoarbetsyta och data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera demodata"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hantera demoläge i kontot \"", _interpolate(_named("accountName")), "\""])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demoläge"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanna kvar på sidan"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna sidan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du lämna denna sida och förlora alla ändringar?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har slutfört uppdrag"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa uppdrag"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag slutfört"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga uppdrag hittades"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns för närvarande inga uppgifter som du kan granska."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justera uppdraget eller vänta tills nästa cykel börjar."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att justera filtren"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera uppdrag"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håll ett öga på denna sida för uppdateringar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga uppgifter för denna cykel"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer att visa"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera uppgift som slutförd"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har granskat denna användare."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga självgranskningar i detta uppdrag"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till genom att klicka på kalibreringsikonen i konversationsvyns sidhuvud."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer tillagda"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förfallodatum"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa uppdrag"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa kalibreringssession"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina tilldelningar"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra tilldelningar"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva tilldelningar"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga tilldelningar"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla granskade"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla granskade utom jag"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskade hittade"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter granskad"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagen"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla statusar"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Att göra"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla granskare"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla granskare utom jag"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldelad mig"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskare hittades"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter granskare"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagar"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagar"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dag"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagar"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagar"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagar"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timmar"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timmar"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timme"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timmar"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timmar"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timmar"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuter"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuter"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minut"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuter"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuter"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuter"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återtilldela"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare av arbetsyta"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell granskare"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt mål för denna cykel"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny granskare"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj granskare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återtilldela konversationer"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återtilldela konversation"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer som ska flyttas"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell cykel"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera uppdrag"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga granskare hittades"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök granskare"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återtilldela konversationer till mig"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återtilldela konversationer till en annan användare"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dagar"]), _normalize([_interpolate(_named("n")), " dag"]), _normalize([_interpolate(_named("n")), " dagar"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " timmar"]), _normalize([_interpolate(_named("n")), " timme"]), _normalize([_interpolate(_named("n")), " timmar"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj slutförda cykler"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj slutförda sessioner"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt mål"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en orsak för att kunna ta bort denna konversation"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förklara varför du tar bort denna konversation"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konversation"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du behålla ändringarna?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en orsak för att kunna ersätta denna konversation"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorera ändringar"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behåll"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behåll ändringar"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersättning hittades inte"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förklara varför du ersätter denna konversation"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du fortfarande ta bort denna konversation från uppdraget? Detta kommer att minska målet med 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersätt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersätt konversation"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort utkast"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera utkast"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återtilldela till mig"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtilldela till annan användare"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtilldela och granska"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort konversation"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna en granskning"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa mindre"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa mer"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " granskad"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar slutförda"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa slutförda cykler"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa slutförda sessioner"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid kvar"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppgifter"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation ersatt"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla uppgiftslista"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " senare"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekund"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekunder"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dagar"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("minutes")), " m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " må"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " år"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just nu"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du saknar behörigheter. Kontakta din administratör för att få hjälp."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den sida du söker efter finns inte."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett oväntat fel inträffade. Kontakta support för att få hjälp."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtkomst nekad. Inte tillåten att utföra denna åtgärd."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den sida du söker efter finns inte eller så har du inte åtkomstbehörighet till den."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kör på lite för snabbt och vi har problem med att hinna ifatt."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändringar sparade"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osparade ändringar gjordes"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["och"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tillämpade"]), _normalize([_interpolate(_named("n")), " tillämpad"]), _normalize([_interpolate(_named("n")), " tillämpade"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansluten"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapades den"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapat av"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritiskt"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tidigare medlem"]), _normalize(["en tidigare medlem"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["här"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valda"]), _normalize([_interpolate(_named("n")), " vald"]), _normalize([_interpolate(_named("n")), " valda"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast uppdaterad"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... och +1 till"]), _normalize(["... och +", _interpolate(_named("n")), " till"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 till"]), _normalize(["+", _interpolate(_named("n")), " till"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ny"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["är inte"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagen"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["användare"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fredag"]), _normalize(["Fredagar"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Måndag"]), _normalize(["Måndagar"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lördag"]), _normalize(["Lördagar"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Söndag"]), _normalize(["Söndagar"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Torsdag"]), _normalize(["torsdagar"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tisdag"]), _normalize(["Tisdagar"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Onsdag"]), _normalize(["Onsdagar"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i går"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Admin"]), _normalize(["en admin"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kundansvarig"]), _normalize(["en kontoansvarig"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Användare"]), _normalize(["en användare"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopierad"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din session har utgått."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningar"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält kan inte vara tomt."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga objekt hittades."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga matchande personer."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ansvarig"]), _normalize(["en ansvarig"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innan vi börjar, läs och godkänn våra villkor så är vi redo att börja."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickat"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säker?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar gjorda överlag"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterad"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["en agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["en lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Chef"]), _normalize(["en chef"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Granskare"]), _normalize(["en granskare"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonym användare"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivera"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handläggare"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snart"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsätt"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att kopiera"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera länk"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riskzon"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neka"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning (valfritt)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräftelse krävs"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaktivera"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du ignorera osparade ändringar?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicera"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frekvens"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag förstår"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldrig"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieringar"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrigt"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal godkända"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personliga inställningar"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa mindre"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka igen"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskad"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna/sedda granskningar"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar utförda av medlem av arbetsyta"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara ändringar"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binär skala"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-gradig skala"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-gradig skala"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-gradig skala"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj frekvens"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa mindre"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa mer"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppa över"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källa"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steg"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka in"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback om undersökning"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsintervall"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osparade ändringar gjordes"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vikt"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du har blivit inbjuden av ", _interpolate(_list(0)), " för att gå med i Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Användare ansluts automatiskt från Administrationscentret. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera användare"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lär dig mer om användarhantering"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vissa användare distribueras och synkroniseras automatiskt från din identitetsleverantör. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kontobehörighet för \"", _interpolate(_list(0)), "\" ändras"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om användarbehörigheter"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fråga mig inte igen"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alla ändringar som har gjorts på användarsidan återspeglar arbetsytor. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta innebär att användarens åtkomst till vissa delar av Zendesk QA kan ändras"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra behörighet på kontonivå"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " användare borttagen"]), _normalize([_interpolate(_named("n")), " användare borttagna"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["När du tar bort en användare förlorar han eller hon åtkomst till ditt konto. Deras granskningar, kommentarer och bedömningar ligger kvar i Zendesk QA. ", _interpolate(_named("linebreak")), "Borttagning av en användare påverkar inte antalet licenser i ditt abonnemang."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Denna användare tas bort från alla arbetsytor som du hanterar. ", _interpolate(_named("linebreak")), " Deras granskningar, kommentarer och bedömningar förblir intakta."]), _normalize(["De kommer att tas bort från alla arbetsytor som du hanterar. ", _interpolate(_named("linebreak")), " Alla deras granskningar, kommentarer och bedömningar förblir intakta."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ta bort ", _interpolate(_named("name"))]), _normalize(["Ta bort ", _interpolate(_named("name")), " användare"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan se allt och hantera alla inställningar och fakturering"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan se allt och hantera personliga inställningar och inställningar för arbetsyta inställningar, utom fakturering"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontobehörigheter"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörigheter specifika för arbetsytor ger användare möjlighet att se (och) redigera data på arbetsytor de befinner sig på."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om Bot QA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina bottar visas här när konversationer som de har deltagit i synkroniseras"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga bottar synkroniserade"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du markerar en bot som en användare får den åtkomst till inloggning. Deras historiska botdata förblir tillgängliga på kontrollpaneler."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sådana bottar hittades"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök söka igen med ett annat nyckelord eller en annan stavning."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter bottar"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter botnamn"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som användare"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast admins och kontoansvariga kan markera botar som användare"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnamn"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste chatt"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuellt markerad"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningsbar"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boten tas bort från automatisk analys och eventuella tilldelningar. Nya data visas inte på kontrollpaneler, vilket säkerställer korrekta rapporter och enklare bothantering."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generativ bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänd"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsflödesbot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till i grupp(er)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla grupper"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grupp"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort från grupp(er)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj grupp"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontobehörighet"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till på arbetsyta/arbetsytor"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullständigt namn"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast aktiva"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll på kontonivå"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varje användare tilldelas en behörighet på konto- och/eller arbetsytnivå. Nu är administratörsbehörigheterna på kontonivå, medan andra behörigheter är på arbetsytnivån."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort från arbetsyta/arbetsytor"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källa"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörighet för arbetsyta"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörigheter hanteras i grupp"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera detaljer"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Att markera en användare som en bot kommer att ta bort deras åtkomst till inloggning. Deras historiska användaruppgifter förblir tillgängliga på kontrollpaneler."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort boten från automatisk analys och eventuella tilldelningar. Nya data visas inte på kontrollpaneler, vilket säkerställer korrekta rapporter och enklare bothantering."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uteslut bot från recensioner"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare markerad som bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast behörighetstypen Användare kan markeras som bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare med flera konton kan inte markeras som en bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter användare"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottar"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare, bottar och arbetsytor"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARBETSYTOR"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare och arbetsytor"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga behörigheter"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutna"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillagd manuellt"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntar"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntande inbjudan"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-användare"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd krävs"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...och en till"]), _normalize(["...och ", _interpolate(_named("n")), " till"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra behörighet till"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ta bort och bjud in igen för att ändra roll för ", _interpolate(_named("n")), " väntande inbjudan av användare."]), _normalize(["Ta bort och bjud in igen för att ändra roll för ", _interpolate(_named("n")), " väntande inbjudningar av användare"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Redigera uppgifter för ", _interpolate(_named("name"))]), _normalize(["Redigera uppgifter för ", _interpolate(_named("n")), " valda användare"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " användare uppdaterad."]), _normalize([_interpolate(_named("n")), " användare uppdaterade."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Denna grupp innehåller 1 användare från en annan arbetsyta."]), _normalize(["Denna grupp innehåller ", _interpolate(_named("n")), " användare från en annan arbetsyta."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grupp arkiverad"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiverad grupp"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa grupp"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen \"", _interpolate(_named("name")), "\" skapad"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namnlös grupp"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du ta bort denna grupp permanent?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort grupp"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort grupp"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera grupp"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppledare"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupproll"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen \"", _interpolate(_named("name")), "\" dold"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna grupp kommer inte att synas någon annanstans i Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera endast behörigheter för arbetsyta"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp är inte synlig i Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlem"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namnlös grupp"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En annan grupp har redan detta namn"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkiverad grupp har redan detta namn"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din roll tillåter endast hantering av medlemmar i denna grupp. Kontakta en användare med högre behörigheter om du vill ha ytterligare ändringar."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort som grupplead"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grupp återställd"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grupp sparad"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange som grupplead"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grupp oarkiverad"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osparade ändringar"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till dina befintliga användare i denna grupp"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter namn eller e-postadress"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till medlemmar"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grupp synlig"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varje medlem är ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om grupper"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissa attribut i grupper distribueras och synkroniseras automatiskt från din identitetsleverantör."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta reda på mer om grupper"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa grupp"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisera dina teammedlemmar på ett enkelt sätt och efterlikna din organisationsstruktur."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till minst 1 medlem"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ett gruppnamn"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns redan en grupp med detta namn"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppmedlemmar"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppnamn"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisera dina teammedlemmar och efterlikna din organisationsstruktur."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmar"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Inga grupper"]), _normalize(["1 grupp"]), _normalize([_interpolate(_named("count")), " grupper"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en ny grupp eller aktivera en arkiverad istället."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga aktiva grupper tillgängliga"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga arkiverade grupper"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sådana grupper hittades"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök söka igen med ett annat nyckelord eller stavning."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga grupper tillagda"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["i dessa"]), _normalize(["i"]), _normalize(["i"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter gruppnamn"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiverade"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kan skickas igen om ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licenser kvar"]), _normalize([_interpolate(_named("n")), " licens kvar"]), _normalize([_interpolate(_named("n")), " licenser kvar"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt licensantal är för närvarande begränsade eftersom ditt abonnemang är pausat. Aktivera det på nytt för att lägga till fler användare."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nått gränsen för dina kontolicenser. Du kan ersätta användare genom att avaktivera en aktiv användare eller lägga till fler licenser."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa betalningsuppgifter"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " vald"]), _normalize([_interpolate(_named("n")), " valda"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " användare vald"]), _normalize([_interpolate(_named("n")), " användare valda"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " rader"]), _normalize([_interpolate(_named("n")), " rad"]), _normalize([_interpolate(_named("n")), " rader"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök användare i bulk efter deras namn eller e-postadresser."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klistra in data åtskilda med kommatecken eller nya rader"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök användare i bulk"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Redigera behörigheter för arbetsyta för ", _interpolate(_named("name"))]), _normalize(["Redigera behörigheter för arbetsyta för ", _interpolate(_named("n")), " valda medlemmar"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera behörigheter"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj behörighet för arbetsyta"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ta bort ", _interpolate(_named("name")), " från arbetsyta"]), _normalize(["Ta bort ", _interpolate(_named("name")), " medlemmar från arbetsyta"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " medlem borttagen"]), _normalize([_interpolate(_named("n")), " medlemmar borttagna"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ta bort medlemmen"]), _normalize(["Ta bort medlemmar"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Denna användare tas bort från arbetsytan."]), _normalize(["Dessa användare tas bort från arbetsytan."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Behörigheter för arbetsyta för \"", _interpolate(_list(0)), "\" ändras."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till medlemmar"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se konversationer och relaterade granskningar"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se allt på arbetsytan, men hantera endast quiz, tilldelningar, tvister och kalibreringssessioner"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörigheter för arbetsyta"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se och hantera allt på arbetsytan"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se allt på arbetsytan, utom inställningar för arbetsytan"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anslut användare"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare från de arbetsytor du hanterar har lagts till på denna arbetsyta."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be en administratör att göra det."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud själv in nya användare direkt eller be en admin att göra det."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " eller ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga användare att visa"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " användare tillagd"]), _normalize([_interpolate(_named("n")), " användare tillagda"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lägg till medlemmar"]), _normalize(["Lägg till medlem"]), _normalize(["Lägg till ", _interpolate(_named("n")), " medlemmar"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj behörighet för arbetsyta"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj användare till arbetsytan"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lägg till medlemmar i ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klistra in e-postmeddelanden separerade med nya rader eller kommatecken"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Matchade ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-postmeddelanden"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" med redan anslutna användare:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det gick inte att matcha ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-postmeddelanden"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera stavningen eller anslut dem individuellt senare"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" med Helpdesk-användare:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " användare ansluten"]), _normalize([_interpolate(_named("n")), " användare anslutna"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " användare inbjuden"]), _normalize([_interpolate(_named("n")), " användare inbjudna"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massanslutning"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massinbjudan"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till exempel:\n\nKate", "@", "example.com, jan", "@", "example.com\n\nKate", "@", "example.com\nKate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut från din eller dina helpdesks för att granska deras interaktioner"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Anslut användare"]), _normalize(["Anslut användare"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresser"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut individuellt"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du vill att dina anslutna användare ska få åtkomst till Zendesk QA kan du bjuda in dem här."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in anslutna användare"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bjud in användare"]), _normalize(["Bjud in användare"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppa över inbjudan"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj användare att ansluta från din eller dina helpdesks för att granska deras interaktioner"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantera ditt abonnemang"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslut helpdesk-användare"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ge din arbetsyta ett unikt namn"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om detta är aktiverat läggs alla befintliga kontoanslutningar automatiskt till på denna arbetsyta."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till alla anslutningar på denna arbetsyta"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa arbetsyta"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytans namn"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa arbetsyta"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare som läggs till direkt på kontot (utan att tilldela en arbetsyta) tilldelas en roll på kontonivå. Du kan lägga till dem på arbetsytor senare."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbjudna användare tilldelas automatiskt en användarroll på kontonivån. Du kan uppgradera dem till en administratörsroll senare."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla dessa fungerar"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klistra in e-postmeddelanden separerade med nya rader eller kommatecken."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " användare inbjuden via e-post"]), _normalize([_interpolate(_named("n")), " användare inbjudna via e-post"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare som läggs till på en arbetsyta tilldelas automatiskt en användarroll på kontonivån. Du kan uppgradera dem till en administratörsroll senare."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till fler"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud bara in användare till kontot utan att lägga till dem på en arbetsyta"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbjudna användare tilldelas endast en kontobehörighet och läggs inte till i några arbetsytor."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in användare direkt till en arbetsyta och tilldela behörigheter för arbetsyta"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till användare på arbetsyta"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massinbjudan"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in individuellt"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud bara in till kontot"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in till en arbetsyta"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bjud in användare"]), _normalize(["Bjud in användare"]), _normalize(["Bjud in ", _interpolate(_named("n")), " användare"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massinbjud användare via e-post"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ta bort användare"]), _normalize(["Ta bort användare"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avmarkera"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bjud in användare via e-post"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga arbetsytor"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj behörighet"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka inbjudan igen"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansluten användare"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare med väntande e-postinbjudan"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-användare"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj arbetsyta"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-agenter"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrationscenter"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssäkring"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantering av arbetsstyrka"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Slutförda ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Slutförd ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Slutförda ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cykel ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskare"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera uppdrag"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara tid och ställ in automatiska granskningsuppdrag i stället för att söka efter och tilldela konversationer till granskning manuellt."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Förfaller ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner kan läggas till under avsnittet ”Kalibrering” på sidomenyn i konversationsvyn."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner tillagda ännu"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baslinje"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversationer kan läggas till i en session genom att klicka på ikonen för kalibrering i konversationsvyns sidhuvud."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga konversationer tillagda ännu"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inga granskningar har lämnats. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämna en granskning om kalibrering"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundorsak"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ladda kommandopalett."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingenting hittades."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hittades med ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar kommandopalett…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sidor, filter, konversationer, inställningar…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat filter"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentligt filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Från ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Från ditt API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Visar ", _interpolate(_named("rangeStart")), " till ", _interpolate(_named("rangeEnd")), " av ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Visar ", _interpolate(_named("rangeStart")), " till ", _interpolate(_named("rangeEnd")), " av ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj sidopanel"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa sidopanel"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla sidopanel"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister godkända"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister avvisade"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna tvister"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkända tvister"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar slutförda"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar mottagna"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar visade"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["godkända"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["öppnade"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delvis godkända"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " av totalt"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avvisade"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkända"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisade"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["granskning"]), _normalize(["granskningar"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antalet av deras granskningar som har setts av de granskade"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antalet av deras granskningar som har setts"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtvistade granskare"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvistlösare"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister efter kategori"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister över tid"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppen"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig lösningstid"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig lösningstid (t)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig lösningstid (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löst"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antal tvister"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkända"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarsspråk"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga språk hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla språk"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förutsedda drivkrafter"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga drivkrafter hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla drivkrafter"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord i taggmoln"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga ord hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla ord"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omnämnanden"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyaste"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äldsta"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkända"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " reagerade med ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisade"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösta"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granskningar"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj hudton"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner hittades"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kategorier hittades"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarsstorlek"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga storlekar hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla storlekar"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga filter hittades"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga grupper hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla grupper"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga hashtags hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga värden hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla värden"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga anpassade fält hittades"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-taggar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga helpdesk-taggar hittade"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla helpdesk-taggar"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar resultat…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frågetyper"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga frågetyper hittades"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till reaktion"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poängkort"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga poängkort hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla poängkort"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga poäng hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla poäng"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källor"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga källor hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla källor"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak till feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga orsaker hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla orsaker"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersökningar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga undersökningar hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla undersökningar"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kanaler hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla kanaler"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga användare hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla användare"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga arbetsytor hittades"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla arbetsytor"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till quiz"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzet har tagits bort eller har inte publicerats ännu. Dubbelkolla länken eller gå tillbaka till quizlistan."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta quiz har inte delats med en arbetsyta som du tillhör. Be en administratör att lägga till dig på en arbetsyta som detta quiz har åtkomst till."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz är ej tillgängligt"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtkomst till quizzet ges till arbetsyta/arbetsytor som du inte tillhör. Det går inte att spara och publicera quizzet."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be en administratör att spara och publicera quizzet."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du ska markera minst ett av svaren som korrekt."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält kan inte vara tomt."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du ser just nu en förhandsvisning av quizzet."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj vilka arbetsytor som kan få åtkomst till quizzet. Användare som tillhör flera arbetsytor kan endast besvara quizzet en gång. Att välja \"Alla arbetsytor\" kommer inte att omfatta nyligen skapade arbetsytor när quizzet har publicerats."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtkomst till quiz"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivera"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicera"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluta förhandsvisningsläget"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsvisning"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frågor"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta quizzet"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera quiz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar för quiz"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara som utkast"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa nytt quiz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oarkivera"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alternativ"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till alternativ"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela den"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser snyggt ut, eller hur?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt svar har skickats in"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt quiz har sparats"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt quiz har publicerats"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt quiz har tagits bort"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till fråga"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivera quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett arkiverat quiz och dess resultat kommer inte längre att visas i listan med quiz. Du kan fortfarande få tillgång till resultaten från ett arkiverat quiz och oarkivera det vid behov."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivera quiz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När ett quiz har publicerats kan det inte ändras eller redigeras. Alla publicerade quiz kommer att bli tillgängliga för alla användare på ditt konto."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicera quiz?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du verkligen ta bort detta quiz? Denna åtgärd kan inte ångras."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort quiz?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["När du har skickat in dina svar kan de inte ändras."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka in quiz?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oarkivera quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du slutar arkivera quizzet blir det tillgängligt i quizöversikten. Det kommer att göra det möjligt för tidigare deltagare att få åtkomst till sina resultat och för nya att göra guizzet."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oarkivera quiz"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubblettfråga"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning (valfritt)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flytta frågan nedåt"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flytta frågan uppåt"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fråga"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endast frågor med svar visas i förhandsvisningen."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort fråga"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka in quiz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namnlöst quiz"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzets titel"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta kommer att ta bort quizzet och dess resultat permanent."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du fortfarande få åtkomst till resultaten genom att arkivera quizzet."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvarat"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort quiz"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta kommer att ta bort quizzet och dess resultat permanent."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ta bort quiz ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicera"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz hjälper till att förbättra teamets självförtroende och färdigheter, leverera konsekventa upplevelser och introducera nya teammedlemmar."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan söka efter quiz efter quizzets namn eller författarens namn."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga quiz har skapats ännu"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera lista med quiz"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig poäng"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutförandedatum"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/författare"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Författare/Arbetsytor"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagare"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicerat"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbetsytor"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter quiz, författare"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiverat"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivt"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla quiz"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz har arkiverats."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz har oarkiverats."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta quiz fick inga svar från användare på den eller de valda arbetsytorna."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta quiz fick inga svar innan det arkiverades."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga svar"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Här ser du en uppdelning av svaren på quizzet.\nIngen har besvarat ditt quiz ännu – det skulle säkert till hjälp om du delade det."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga svar ännu"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genomsnittlig poäng:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enskild"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – Kopiera"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länk kopierad"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga resultat hittades"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quiz från ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan söka efter deltagarens namn på ledartavlan."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera ledartavla"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poäng"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter deltagare"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledartavla"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Växla till ledartavla"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta innebär att du inte kommer att kunna filtrera efter denna hashtag på kontrollpanelen och den kommer inte längre att fyllas i automatiskt i kommentarer. Denna åtgärd är oåterkallelig."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa hashtags"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa hashtags"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåt att medlemmar skapar nya hashtags"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ta bort hashtaggen ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapande av hashtag är låst"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möjligt att skapa hashtag"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["hashtag \"", _interpolate(_list(0)), "\" borttagen"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Namnet på hashtag \"", _interpolate(_list(0)), "\" byttes till \"", _interpolate(_list(1)), "\""])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag tillagd"]), _normalize([_interpolate(_named("count")), " hashtags tillagda"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt namn"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga hashtags skapade"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt namn"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Byt namn på hashtag \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtskilj hashtaggar med kommatecken eller radbrytningar"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter en hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inga problem. Du kan göra det ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direkt här"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning länkad till arbetsyta"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fäst anslutning"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fäst en anslutning till denna arbetsyta"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakta din administratör för att göra eventuella ändringar här"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakta Zendesk QA för att göra eventuella ändringar här"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga anslutningar under kontot"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som att du inte har integrerat med en kundserviceplattform ännu."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om du tar bort en anslutning kommer konversationer att tas bort från arbetsytan ", _interpolate(_named("workspaceName")), " och ta bort alla data som är relaterade till dem, inklusive granskningar och tvister."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort anslutning"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort anslutningen ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en anslutning"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Under den valda tidsperioden fanns vissa aktiva kategorier med automatisk QA inte på ditt poängkort. Det kan hända att konversationer före den senaste ändringen den ", _interpolate(_named("date")), " inte har analyserats för dessa kategorier."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det är här all feedback som du har fått kommer att visas. Inget att visa ännu."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga mottagna granskningar."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa endast kommentarsaktivitet"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det är här all feedback som du har gett kommer att visas. Kom igång i konversationsvyn."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga lämnade granskningar."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämnade granskningar"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottagna granskningar"])}
  }
}