import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rule_list = _resolveComponent("rule-list")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_survey_rules_modal = _resolveComponent("survey-rules-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.filters)
      ? (_openBlock(), _createBlock(_component_rule_list, {
          key: 0,
          conditions: _ctx.savedFilters[_ctx.deliveryMethod],
          filters: _ctx.filters
        }, null, 8 /* PROPS */, ["conditions", "filters"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["text-active-primary text-sm font-medium leading-[initial]", _ctx.$style.addRule]),
      "data-testid": "add-rule-btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenModal && _ctx.handleOpenModal(...args)))
    }, [
      (!_ctx.savedFilters[_ctx.deliveryMethod]?.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_plus_icon, { class: "icon-m" }),
            _createTextVNode(" " + _toDisplayString(_ctx.deliveryMethod === _ctx.DeliveryOptions.Chat
            ? _ctx.$t('settings.survey.edit.sections.delivery.add_brand_rule')
            : _ctx.$t('settings.survey.edit.sections.delivery.add_rule')), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('settings.survey.edit.sections.delivery.edit_rules')), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
    ], 2 /* CLASS */),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_survey_rules_modal, {
          key: 1,
          "model-value": _ctx.savedFilters[_ctx.deliveryMethod],
          "delivery-method": _ctx.deliveryMethod,
          "survey-id": _ctx.state.data.id,
          "connection-id": _ctx.connectionId,
          "source-type": _ctx.sourceType,
          filters: _ctx.filters,
          "show-modal": _ctx.showModal,
          onClose: _ctx.handleCloseModal,
          "onUpdate:modelValue": _ctx.handleUpdateFilters
        }, null, 8 /* PROPS */, ["model-value", "delivery-method", "survey-id", "connection-id", "source-type", "filters", "show-modal", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}