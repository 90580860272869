export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入您的 Zendesk 子網域"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入 Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貴公司已有一個帳戶。"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入公司"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要加入既有的公司，請要求管理員寄送邀請給您。"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請管理員邀請您"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我改變了主意。"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消註冊"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貴公司已有一個帳戶。請問您希望加入貴公司的帳戶，還是另外建立新帳戶？"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製說明"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立新帳戶"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已有帳戶存在"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必須支付所有尚未付款的訂閱發票，才能存取您的 Zendesk QA 帳戶。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱已暫停"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到帳戶。請再試一次。"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取得新密碼。"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["再試一次或", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件和密碼的組合不正確。"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請向管理員請求新的邀請。"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此邀請連結無效或已使用。"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的帳戶已停用此登入方法「", _interpolate(_named("method")), "」。"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此邀請無效或已使用，請向管理員請求新的邀請。"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入邀請資訊失敗。"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註冊失敗。可能已有使用者使用此電子郵件地址。"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有人連續多次嘗試登入您的帳戶，因此帳戶已封鎖。請檢查收件匣，確認是否收到提供解除封鎖說明的電子郵件。"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請先驗證您的電子郵件地址"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的電子郵件帳戶"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位為必填"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位為必填"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入代碼"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送魔術連結"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註冊"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作電子郵件地址"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的工作電子郵件地址"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.tw/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主服務協議"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼不符合要求"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權聲明"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.tw/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["嗨 ", _interpolate(_named("invitee_name")), "："])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["加入「", _interpolate(_named("organization")), "」組織"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的電子郵件地址已驗證。現在可使用以下新帳戶登入。"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 Google 繼續"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請連結無效"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用魔術連結登入"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回以登入"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 Slack 繼續"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過 Zendesk 繼續"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的電子郵件地址已驗證。現在可使用以下新帳戶登入："])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 Google 登入"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以魔術連結登入"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 Slack 登入"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入 Zendesk QA "])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的帳戶已刪除"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您沒有帳戶？"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的代碼"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位為必填"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的電子郵件帳戶"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位為必填"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入代碼"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視所有登入選項"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["以傳統方法", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用魔術連結登入"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已寄給您一封電子郵件，信中有登入代碼。"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已寄給您一封電子郵件，請依照信中步驟登入。"])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送魔術連結時發生錯誤。請再試一次。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以電子郵件地址登入"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立密碼"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一個小寫字母"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一個大寫字母"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密碼必須包含"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼長度上限為 128 個字元"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好記且難猜"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最少 8 個字元"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立您的密碼"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘記密碼？"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 個小寫字母"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 個數字"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 個大寫字母"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要重設密碼，請輸入您的電子郵件地址，我們將傳送一封附上說明的電子郵件給您。"])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的電子郵件帳戶"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位為必填"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設密碼"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已傳送一封電子郵件至 ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若電子郵件地址未顯示於您的收件匣或垃圾郵件資料夾，請檢查您輸入的電子郵件地址。"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回以登入"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢查您的電子郵件"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設密碼"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), "，則會收到重設密碼的電子郵件。"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果已設有帳戶密碼"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果", _interpolate(_named("bold")), "，則已寄給您密碼重設連結。"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件已和帳戶建立關聯"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知道密碼？"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送重設連結時發生錯誤。請再試一次。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設密碼"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用魔術連結註冊"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私政策"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["註冊即表示您同意 Zendesk QA 的", _interpolate(_named("terms_of_service")), "和", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務條款"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已有帳戶？"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以魔術連結註冊"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以工作電子郵件地址註冊"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 Slack 註冊"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註冊 Zendesk QA "])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 Google 註冊"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我有作用中的 Zendesk 帳戶"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["註冊或登入以完成「", _interpolate(_named("direct_install_name")), "」設定"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請問是本人嗎？請檢查您的收件匣內是否有電子郵件確認信。"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定全部完成"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 電腦版的運作效果最佳。在那邊登入即可開始審核對話。"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂示範"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["也可以預定一場示範，我們會為您介紹 Zendesk QA 的功能。"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敬請加入"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我改變了心意，想要", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("account")), "」邀請您加入其帳戶。"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入帳戶"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立新帳戶"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嘗試再次登入"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的意見所收到的回應：", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的回應"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["「", _interpolate(_named("agentName")), "」回應了您的評論"]), _normalize(["數個人回應了您的評論"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["「", _interpolate(_named("agentName")), "」回應了您的回覆"]), _normalize(["數個人回應了您的回覆"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["「", _interpolate(_named("agentName")), "」回應了您的審核"]), _normalize(["數個人回應了您的審核"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出項目"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收項目"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查意見"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始項目"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["是否要將評論標記為已讀？"]), _normalize(["是否要將所有評論標記為已讀？"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["是否要將異議標記為已讀？"]), _normalize(["是否要將所有異議標記為已讀？"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["是否要將調查意見標記為已讀？"]), _normalize(["是否要將所有調查意見標記為已讀？"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["是否要將審核標記為已讀？"]), _normalize(["是否要將所有審核標記為已讀？"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已將評論標記為已讀"]), _normalize(["已將所有評論標記為已讀"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已將異議標記為已讀"]), _normalize(["已將所有異議標記為已讀"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已將調查意見標記為已讀"]), _normalize(["已將所有調查意見標記為已讀"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已將審核標記為已讀"]), _normalize(["已將所有審核標記為已讀"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部標記為已讀"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為已讀"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["項審核"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有人對審核提出異議時，便會顯示在這裡。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無異議"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議分配給您解決時，就會顯示在這裡。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到任何異議"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您對審核提出異議時，就會顯示在這裡。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未開始任何異議"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有給您的意見都會顯示在這裡。目前沒有可顯示的內容。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到任何意見"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對您的審核和回覆提出的所有回應，都會顯示在這裡。目前沒有可顯示的內容。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有收到任何回應"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟對話"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您提出的所有意見都在這裡。首先請在「對話」視圖中提出一項審核。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未提出任何審核"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有給您的意見都會顯示在這裡。目前沒有可顯示的內容。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到審核"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到評論"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您以評論形式留下的所有意見都會顯示在這裡。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未提出任何評論"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到評論"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這裡會顯示您某項審核的所有評論、", "@", "提及，或是對您的評論的回覆。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有收到任何評論"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未接受任何異議"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有已開啟的異議"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有遭拒的異議"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有已解決的異議"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在上方的篩選功能選擇其他選項，再試一次。"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在上方的篩選功能選擇其他使用者，再試一次。"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提出的評論"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的評論"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有異議"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的異議"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開始的異議"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的調查意見"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提出的審核"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的審核"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換活動清單"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " 審核完畢"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " 審核完畢"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " 審核完畢"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["重新開始時間：", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["剩餘時間：", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定任務"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換任務清單"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["無法在「", _interpolate(_list(0)), "」和「", _interpolate(_list(1)), "」之間建立培訓階段，因為兩人目前不在同一個工作區。"])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」和「", _interpolate(_list(1)), "」之間已有培訓階段。請聯絡「", _interpolate(_list(0)), "」進入該階段。"])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建議"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已在「", _interpolate(_list(0)), "」和「", _interpolate(_list(1)), "」之間建立新階段。"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓者"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取培訓者"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受培訓者"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取受培訓者"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立階段"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立階段"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳情"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增階段"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段名稱"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建議"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將開始"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立階段"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到階段"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均審核時間"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儀表板"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取的時段無資料可顯示"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已新增釘選項目至階段。", _interpolate(_named("buttonStart")), "顯示", _interpolate(_named("buttonEnd")), "。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已顯示選取時段的所有釘選項目"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["透過此篇", _interpolate(_named("link")), "，瞭解釘選項目的運作方式。"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您似乎尚未對「", _interpolate(_list(0)), "」釘選任何項目。可運用釘選功能來準備一對一的內容，或是儲存項目供下次使用。"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受培訓者"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未新增釘選項目"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["這是您為「", _interpolate(_named("name")), "」建立的所有釘選項目。新增至此階段的釘選項目會與其他參與者", _interpolate(_named("bold")), "。"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即共用"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」在選取的時段內，似乎並未收到任何對話審核或客戶滿意度的相關意見。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無意見"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已顯示選取時段的所有評論"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量化分析"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選項目"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的數據"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建操作項目"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已由", _interpolate(_named("user")), "在 ", _interpolate(_named("date")), "完成"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["製作者：", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入即可新增操作項目"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這次會議成果如何？接下來要採取什麼行動？"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作項目"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("name")), " 於 ", _interpolate(_named("createdDate")), " 建立"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除階段"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["這會永久", _interpolate(_named("deleteSession")), "。參與者會失去對該階段的存取權限，包括內容和私人備註。"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["階段建立時間：", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最後由「", _interpolate(_named("name")), "」編輯於 ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["討論要點和備註"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新備註"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離開階段"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共用"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取的使用者從這時開始可存取階段詳情。"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取使用者"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已共用"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共用階段"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您將不再是「", _interpolate(_list(0)), "」和「", _interpolate(_list(1)), "」階段的成員。"])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」將不再是此階段的成員。"])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["「", _interpolate(_named("name")), "」現在可存取此階段。"]), _normalize(["有 ", _interpolate(_named("n")), " 位新使用者現在可存取此階段。"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除階段"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除所有階段"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除階段"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓者"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 新增的備註會顯示在這裡。"])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可從右側協作面板新增釘選項目做為討論要點"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選項目"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註只有您看得見..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人備註"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人備註"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 天"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓者"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受培訓者"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作項目"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後階段"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段概述"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視全部"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我：培訓者"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我：受培訓者"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有階段"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共用階段"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建議更改篩選條件，以檢視操作項目。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無操作項目"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作項目"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到並檢視審核"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到 · 已檢視"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段後的進展"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理似乎尚未收到任何意見。"])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請確認使用者名稱是否正確"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到該使用者"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可用資料"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天的 IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如此具有「<b>", _interpolate(_named("name")), "</b>」的<b>所有階段皆會刪除</b>（2 項）。參與者會失去對所有階段的存取權限，包括內容和私人備註。"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已顯示所有階段"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓階段是培訓者和受培訓者之間的小組活動，用於討論業績、機會、和長短期目標。"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可依據培訓者和受培訓者的姓名來搜尋階段。"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到階段"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未建立階段"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除培訓紀錄"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除培訓紀錄 "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後階段"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有階段"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓者"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受培訓者"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為已完成"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為復原"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按名稱搜尋"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新命名"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換階段清單"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未排程"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆此訊息"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準階段已逾時。無法提出其他審核。"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已對此對話提出審核。"])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法再審核此對話，因為已超過此階段的到期日。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話沒有任何審核"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉儀表板"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出校準審核"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳情"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摘要"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記錄副本"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["發話者 ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 秒"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無聲"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯發話者"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核訊息"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回播放中的訊息"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發話者"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記錄副本"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區使用者"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先新增連線，以建立篩選"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有 Bot"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("agentName")), "」建立於 ", _interpolate(_named("time"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最後由「", _interpolate(_named("agentName")), "」更新於 ", _interpolate(_named("time"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["似乎沒有任何篩選。若要存取此頁面，需先建立一項篩選。以「", _interpolate(_named("recommendedFilter")), "」做為開頭可檢視自己的對話。請在下方建立此篩選，即可繼續。"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天內"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「最近 30 天內建立」的條件會新增到所建立的篩選（未設日期範圍）"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此計劃未提供篩選，且顯示結果時不會套用篩選"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選選項擷取作業已逾時。請再試一次。"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解篩選"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人 = 登入的人員"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["找到的對話數量：", _interpolate(_named("count"))]), _normalize(["找到的對話數量：", _interpolate(_named("count"))]), _normalize(["找到的對話數量：", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前的計劃未提供，升級即可使用此功能。"])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增條件"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Zendesk QA 人工智慧提供"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轉為任務"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立篩選"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立篩選"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂欄位"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前正在根據指定的設定載入篩選選項，首次載入時可能需要幾分鐘。"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請為篩選取一個描述性的名稱"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選名稱"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取選項"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯篩選"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存變更"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可用值"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取值"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可見度"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅限作用中階段 ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示較早的階段 ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯校準階段"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立階段"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立校準階段"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該階段期間的所有校準審核都會永久刪除。"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除階段"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除「", _interpolate(_named("sessionName")), "」校準階段"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未建立任何階段"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無作用中階段"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立階段"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無作用中階段"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增篩選"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立私人篩選"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立公開篩選"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增篩選"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會永久刪除此篩選。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除篩選"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除「", _interpolate(_named("filterName")), "」篩選"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有異議"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["給我的異議"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我提出的異議"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟的異議"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增篩選"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立私人篩選"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立公開篩選"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人篩選"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開篩選"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["想要", _interpolate(_named("cta")), "？"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這是暫時性的篩選視圖。"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選建立中..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新篩選"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深入分析"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂自動 QA類別"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最後更新時間：", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複雜詞彙"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根據工單的變更，動態自動審核更新內容"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在對話中醒目顯示"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長句"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未預測到任何自動評分"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偵測到任何錯誤"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示複雜詞彙"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示長句"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一個對話"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於背景資訊不足，自動 QA 無法評價此類別。建議手動審核。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索自動化的對話深入分析，簡化審核流程。能夠為團隊將關鍵對話醒目顯示，並展示傑出的正面範例。"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[通話]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已修正"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("agentName")), "」已接受異議"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["異議已傳送給「", _interpolate(_named("agentName")), "」"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請解釋您為何認為這位受審核者並非接收此意見的對象"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增評論並設定新的受審核者"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增評論"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請解釋為何認為評分不公（必填）"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增評論（必填）"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請解釋您為何拒絕此異議"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增評論"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送異議給"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送異議給"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送異議給"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取新的受審核者"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議意見"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議意見"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更受審核者"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送異議給"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對此審核提出異議"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我不同意評分"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此審核對象並非是我"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取使用者"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("agentName")), "」部分接受異議"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("agentName")), "」拒絕異議"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒絕"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("userName")), "」提出異議：審核"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("userName")), "」提出異議：受審核者錯誤"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需解決已開啟的異議，才能對此審核採取其他操作。"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取新評分"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除異議"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否確定捨棄此異議？"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除異議"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議解決時間"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒絕"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取公平的新評分（選填）"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對審核提出異議"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者錯誤"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受異議"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受異議"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議意見"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者錯誤"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議意見"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒絕異議"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒絕異議"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視異議"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已編輯"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("author")), "」", _interpolate(_named("action")), "（", _interpolate(_named("date")), "）"])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["給「", _interpolate(_list(0)), "」"])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已審核"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部完成"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配給："])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請一併提出評論"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解條件式記分卡"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先前分配給："])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只需選取一個"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到任何該名稱的 GIF"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["率先提出審核。"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的訂閱目前已暫停。重新啟用即可提供意見。"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發表者："])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示審核此對話所花費的時間"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取使用者"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["類別所缺少的根本原因：「", _interpolate(_list(0)), "」"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡："])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["給予類別評級或新增評論"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增表情符號"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摺疊"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展開評論欄位"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折疊評論欄位"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏格式設定"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏儀表板"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示格式設定"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示儀表板"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["給予所有類別評級"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請至少給予一個類別評級"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存此對話以供之後使用"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存以供之後使用"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下個任務"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取代此任務週期中的對話"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核對話"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基準審核"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除評論"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除評論"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除此評論？"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論者"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此主題標籤已刪除或重新命名。"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享您喜歡的內容，或可以改善的內容..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件式"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這會永久刪除所有評論。"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除評論"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[訊息]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」尚未查看此意見"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」已看到此意見"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前成員"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋 GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按 Enter 即可新建主題標籤。"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂主題標籤已受管理員鎖定。"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用此功能將個案加入書籤，方便稍後篩選"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為基準"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訊息意見"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一個對話"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無意見"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未有完成的審核"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下方留下審核，即可開始。"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有其他審核"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無評級"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前一位受託人"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一個對話"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從基準移除"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆此審核"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏審核側欄"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這會永久刪除此審核和所有已新增的評論。"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除審核"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除審核"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除審核"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["審核已由 ", _interpolate(_list(0)), " 刪除"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全都完成了！"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新將此任務分配給自己，以便提出審核"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已對這位使用者提出一項審核。"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您已對「", _interpolate(_list(0)), "」提出審核"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話沒有可顯示的類別。"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此工作區無法使用自我審核。"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不要儲存"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否要將此審核儲存為草稿？"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捨棄此草稿"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捨棄"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示審核側欄"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不及格"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格但有輕微錯誤"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["還在計算中..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留下評論"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核此訊息"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核自己"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核此對話"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有需審核的使用者"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已停用自我審核"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到使用者"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區使用者"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取使用者"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 項審核"]), _normalize([_interpolate(_named("count")), " 項審核"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取新評級"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的工作區經理並未允許自我審核。"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按 Enter 即可標記此釘選項目"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已加星號"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立校準階段"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深入分析"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「深入分析」能以視覺化的方式呈現服務台的資料。可透過互動式圖表，瞭解 QA 時需要特別關注的地方、發現盲點所在，並且根據代理的表現，請合適的代理處理審核工作。"])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["翻譯為", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增位置"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未分配"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["當前正在檢視"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即審核"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話已移除，無法再使用。"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您所尋找的對話不存在於 Zendesk QA。"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話的所有釘選項目"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段中"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除對話"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若從階段移除對話，便會刪除所有對該對話的審核。"])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["從「", _interpolate(_named("sessionName")), "」移除對話"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未建立校準階段。"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話已新增至階段"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話已從階段移除"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["於校準階段新增/移除"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製此對話的連結"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的對話"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶電子郵件"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶 ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶名稱"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部表單名稱"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次回應時間"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優先等級"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查評論"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滿意度評分"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次解決時間"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原始"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此訊息不存在於此對話，或已遭刪除"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無標題"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的對話"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到對話"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟原始對話"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法連結至對話：缺少 URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無參與者"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選對話"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下即可閱讀審核內容"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一下即可審核"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以瀏覽器延伸功能建立的對話"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視客戶滿意度調查"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視對話"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帶有負面情緒的訊息"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帶有正面情緒的訊息"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻譯為英文"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示原文"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除對話"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除對話為永久性。此操作亦會從 Zendesk QA 刪除所有相關資料，例如調查、轉錄和審核內容，包括儀表板。", _interpolate(_named("lineBreak")), "仍可在 Zendesk Support 或其他使用的服務台軟體取得此對話。"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除對話是永久動作。刪除對話也會刪除所有相關資料，例如 Zendesk QA 內的問卷調查、記錄副本和評論，儀表板也包含在內。若該對話屬於多個工作區，則會從所有工作區內刪除。", _interpolate(_named("lineBreak")), "您仍可在 Zendesk Support 或其他您仍在使用的服務台軟體中存取該對話。"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除對話"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從 Zendesk QA 刪除對話"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此對話由於向上提報而未完成。前往", _interpolate(_named("optionsUrl")), "並驗證您的標籤。", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此對話由於向上提報而未完成。請聯絡管理員，瞭解如何驗證您的標籤。", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「連線」>「進階選項」"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解標籤"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已關閉"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待處理"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解決"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["受託人已變更為「", _interpolate(_named("target")), "」"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未分配"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("author")), "」已套用", _interpolate(_named("target")), "巨集"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["對話收到評級：", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["使用者「", _interpolate(_named("target")), "」已將對話轉為密文"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["狀態已變更為", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已加上「", _interpolate(_named("target")), "」標籤"])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["團隊已變更為「", _interpolate(_named("target")), "」"])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("author")), "」將對話延後 ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("author")), "」已取消延後對話"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 目前卡在循環中，因此不斷重複傳送相同訊息"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 重複"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 重複，即同一條訊息重複一次或連續重複多次"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到符合的片語"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由一般代理處理對話的效率，較 Bot 高出 ", _interpolate(_named("percent"))])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 通訊效率"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 Bot 處理對話的效率，較一般代理高出 ", _interpolate(_named("percent"))])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 通訊效率"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話缺少必要的聲明，未通知參與者將會錄音，而為遵循法規與公開透明，此舉不可或缺"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少錄音通知"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已違反 ", _interpolate(_list(0)), "的服務層級協定（SLA）。"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已違反 SLA"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA 規則的量化分析已超過其目標時間"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶流失的跡象，客戶有轉移的可能，或已確定會失去客戶。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["識別出的客戶流失風險"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["對話出現無聲狀態，持續時間超過所設定的 ", _interpolate(_list(0)), " 秒閾值"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 個實例"]), _normalize([_interpolate(_named("count")), " 實例"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最長 ", _interpolate(_list(0)), " 秒"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無聲"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶已尋求更高層級的協助"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已請求向上提報"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶對得到的支援服務，表示十分感謝或非常滿意"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優秀服務"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶或代理明確請求後續跟進"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後續跟進請求"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶表示不滿意"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面情緒"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出現異常或非典型的對話，雙方需要溝通多次尋求解決之道"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["偵測到極端值"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶表示滿意"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面情緒"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定為不適用"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最後由「", _interpolate(_named("author")), "」編輯於 ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("author")), " 建立於 ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增原因（選填）"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偵測到深入分析"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增連線"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將服務台連線至您的帳戶，並建立至少一個篩選，以檢視對話清單。"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的對話"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視工作區設定值"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立時間"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後更新時間"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（一天後）"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（本月）"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（本週）"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依 ID 尋找"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("searchQuery")), "」的搜尋結果"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由其他人審核"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已留評論"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最舊"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨機"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近更新"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近回覆"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未讀"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由您審核"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯入過程可能需要一些時間。完成時會寄送電子郵件給您。"])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 正在匯入對話"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["限制：", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下對話視圖標題中的校準圖示，即可新增。"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這裡沒有可報告的內容。請使用其他篩選或排序選項重試。"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的極端值。"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尋找相關對話並按一下標題中的星號，即可在這裡新增書籤。"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建議更改篩選條件，或邀請其他服務台代理，以新增更多對話。"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無限制"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內部備註"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們目前正在載入對話。可能仍會缺失某些內容。"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一個使用者"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到使用者"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的審核"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區成員"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一個使用者"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增對話"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增任何對話"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成的審核於此處顯示。請在 30 天之內重新閱讀、修改並提交。"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無審核草稿"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無加上星號的對話"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的對話"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無符合項目"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回已選取的訊息"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最少回覆"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多回覆"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換對話清單"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有使用者"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["通話接聽時間：", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["通話結束時間：", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["通話撥打時間：", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已審核"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由您審核"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["載入音訊檔案失敗。請確定您已登入「", _interpolate(_list(0)), "」。"])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度調查評論"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入音訊時出現錯誤"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論者"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未撥打電話"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在服務台聽候"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Aircall 聽候"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["播放速度"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核通話"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系統"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記錄副本"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非 Zendesk QA 使用者"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 會根據代理的每月平均客戶滿意度，自動為代理排名。如果代理的客戶滿意度低於同僚，按一下即可尋找這類代理所處理的對話。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依客戶滿意度排序"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非 Zendesk QA 使用者"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一份工單"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["處理量"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 會根據每月處理的對話數量，自動為代理排名，分到各個業績小組。如果代理的處理量低於同僚，按一下即可尋找此類代理所處理的對話。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依處理量排序"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已審核"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未審核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核狀態"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中性"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非 Zendesk QA 使用者"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 會根據對話中的平均情緒，自動為代理排名。如果代理所應對的不滿客戶多於同僚，按一下即可尋找此類互動。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依情緒排序"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偵測到"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已偵測到"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["極端值"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要留意的對話。我們的機器學習模型可辨識複雜或非典型的互動，以利進一步審核。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["極端值"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶在對話中表現出高興或不滿的情緒，可據此選擇要審核的對話。使用下拉式清單，即可選擇在對話中所要尋找的情緒類型。可將篩選屬性拖曳到頂端，檢視所選情緒在所有互動中的比率。"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核狀態"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未審核"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已審核"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 會自動偵測對話中的語言。可使用下拉式清單，選取特定語言的對話來審核。拖曳篩選屬性到頂端，便能取得服務台所有使用語言的概述。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話深入分析"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您可以透過「深入分析」", _interpolate(_named("paragraph_2_item")), "，而且比一般的對話篩選功能更為豐富實用。視覺化概述服務台的所有資料，還能進一步探討以下深入分析："])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解如何使用對話深入分析"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解特定對話屬性對比其他屬性的相對重要性"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過內建的代理業績排名功能，可找出表現最佳和較差的代理"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("paragraph_1_item")), "」是強大的互動式資料探勘工具，有助於找出最需要審核的對話。"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成更多工作"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「對話」和「代理」圖表採取", _interpolate(_named("paragraph_3_item_1")), "的設計：只要更新篩選條件，或是按一下某個圖表分區，就會自動更新對話清單。", _interpolate(_named("paragraph_3_item_2")), "，即可探索不同要素的細項或比率。"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相互連結"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新排列篩選屬性"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「對話深入分析」只會顯示", _interpolate(_named("paragraph_4_item")), "。系統會自動移除不具實質審核價值的互動，例如自動產生的訊息、垃圾廣告、Bot 回覆，或是並未與您的支援代表建立合適對話的訊息。"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清楚乾淨的已關閉對話"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我會在這裡看到什麼？"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " 的 ", _interpolate(_named("percentage"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["擷取失敗"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」不適用"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["（總數中的 ", _interpolate(_named("percentage")), "）"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新載入"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深入分析"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不過待準備好後，一定會很優秀。"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂調查"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未有調查意見留下"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前尚無調查意見的評分..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接收"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見標籤"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見評論標籤"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已套用日期範圍篩選"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 則回覆"]), _normalize([_interpolate(_named("count")), " 則回覆"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶費力度評分"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["可在調查設定值中設定原因，深入瞭解給予「", _interpolate(_list(0)), "」評分的原因。"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["尚無「", _interpolate(_list(0)), "」的原因"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解調查原因"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設有標籤的對話："])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」分類"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論類別"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("surveyType")), "」原因"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("surveyType")), "」的預測驅動因素"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話來源"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["調查回應（", _interpolate(_named("n")), "）"]), _normalize(["調查回應（", _interpolate(_named("n")), "）"]), _normalize(["調查回應（", _interpolate(_named("n")), "）"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無標題"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的對話"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」依使用者分類"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應比率"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」評分是所有回應的總和除以最高評分的總和"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯目標"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除目標"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定目標"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增目標"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很長"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中等"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令人困惑"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有幫助"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援不佳"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援良好"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全為表情符號"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理意見"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投訴"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短語"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題未解決"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題已解決"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面情緒"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成效不佳"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面情緒"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稱讚"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品不良"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援迅速"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援緩慢"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["謝謝"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA 會自動將「", _interpolate(_list(0)), "」資料分配為預先定義的類別。這樣即可瞭解複雜的深入分析並探討原因，而無需手動篩檢書面意見。"])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有評分"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有調查"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇回應語言"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論長度"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取評論長度"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於 25% 的評論只有一個詞彙，較長的評論更值得一讀。我們會幫您自動找出更加深入的分析意見，不用靠人工處理。"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見原因"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取意見原因"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有符合的評分"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預測驅動因素"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取評論類別"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字雲內文"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取文字雲內文"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["自上個時段起 ", _interpolate(_list(0))])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 個對話"]), _normalize([_interpolate(_named("n")), " 個對話"]), _normalize([_interpolate(_named("n")), " 個對話"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於缺少資料，而無法計算所有對話的調查傳送比率。"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送出的調查"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查漏斗圖"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("surveyType")), "」的預測驅動因素"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA 會自動將「", _interpolate(_list(0)), "」資料分配為預先定義的類別。這樣即可瞭解複雜的深入分析並探討原因，而無需手動篩檢書面意見。"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論長度"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於 25% 的評論只有一個詞彙，較長的評論更值得一讀。我們會幫您自動找出更加深入的分析意見，不用靠人工處理。"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查回應"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（平均）"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應數"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（平均）"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」趨勢分類"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應數"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驅動因素"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」調查傳送日期"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」調查回覆日期"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」評分分配對象"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料顯示條件"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話建立日期"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工單負責人"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆最多的代理"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（平均）"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（隨時間變化）"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應數"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」和「IQS」"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應數"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含這些詞彙的對話："])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字雲"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度依使用者分類"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度概述"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查漏斗圖"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查回應"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度時間趨勢"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字雲"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無管道"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無來源"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無語言"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無原因"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無標籤"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無評論類別"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無工作區"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可用資料，因為尚未建立任務或週期尚未開始。請稍後返回查看，或調整篩選條件。"])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索新內容"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 個項目"]), _normalize([_interpolate(_named("count")), " 個項目"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 個對話"]), _normalize([_interpolate(_named("count")), " 個對話"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不及格"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格但有輕微錯誤"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格/不及格"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均（小時）"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均（分鐘）"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("categoryName")), "」評級"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均客戶滿意度 = [意見評分總和 ÷（所有回應計數 × 最高可能評分）] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 客戶滿意度（CSAT）評分會以 0-100% 的量表，將您的意見評分正規劃化，並得出標準化評分的平均值。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度（CSAT）會將客戶對服務的滿意度量化呈現。"])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "每項審核僅代表單人對單一對話的審核。一個對話可以有多筆審核（若兩位以上審核者在審核相同的對話）。"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追蹤審核者評分隨時間的演變。"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追蹤代理評分隨時間的演變。"])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 個回應"]), _normalize([_interpolate(_named("n")), " 個回應"]), _normalize([_interpolate(_named("n")), " 個回應"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 項審核"]), _normalize([_interpolate(_named("n")), " 項審核"]), _normalize([_interpolate(_named("n")), " 項審核"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核："])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（內部品質評分）是對話審核結果。"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = 審核評分總和 ÷ 審核數"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計算得出的是平均值，所有符合所選篩選條件的審核評分（該類別評分的加權平均數）均計入在內。"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入卡片失敗。"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請變更篩選條件並重試。"])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格率呈現了對話審核的結果。"])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果審核評分低於 ", _interpolate(_named("failScore")), " 即失敗"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果審核評分高於 ", _interpolate(_named("passScore")), " 便及格"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這是依預先訂定的基準判斷："])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格率 = 通過的審核總數 ÷ 所有審核的總數"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所謂的及格率，代表重大或非重大類別在未有不及格的情況下，通過審核的百分比。"])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["例如，如果審核者在這天提出 3 項審核，則顯示的數字便是 3 筆評分的平均數。可在", _interpolate(_named("link")), "檢視每個儲存格的計算方式。"])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["例如，如果代理在這天收到 3 項審核，則顯示的數字便是 3 筆評分的平均數。可在", _interpolate(_named("link")), "檢視每個儲存格的計算方式。"])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不列入考量：類別權重、重大狀態（不同於「評分時間趨勢」）。"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於可在審核中將類別標記為「不適用」，因此每個類別的審核數量可能少於該名審核者的審核總數。可檢視每個儲存格的計算方式"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不列入考量：類別權重、重大狀態（不同於「評分時間趨勢」）。"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於可在審核中將類別標記為「不適用」，因此每個類別的審核數量可能少於每位代理的審核總數。可檢視每個儲存格的計算方式"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不列入考量：類別權重、重大狀態（不同於「評分時間趨勢」）。"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), "類別小組會單獨顯示。各小組的評分會依據該小組在同時段內的平均類別評分來計算。可在", _interpolate(_named("link")), "檢視每個儲存格的計算方式。"])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註："])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不列入考量：類別權重、重大狀態（不同於「評分時間趨勢」）。"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), "類別小組會單獨顯示。各小組的評分會依據該小組在同時段內的平均類別評分來計算。可在", _interpolate(_named("link")), "檢視每個儲存格的計算方式。"])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基準 ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無重大錯誤"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所謂的無重大錯誤率，代表任何重大類別在未有不及格的情況下，通過審核的百分比。"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內部品質評分"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「其他」根本原因"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概述"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期變更"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日變更"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月變更"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週變更"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年變更"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分時間趨勢"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依天數或週數檢視。"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計算 = 將天或週的合計評分除以所提出的審核總數"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依天數或週數檢視。"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計算 = 將天或週的合計評分除以收到的審核總數"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各類別評分"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視審核者在此期間提出的所有類別評級的合計平均數。"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視在此期間每位代理收到的所有類別評級的合計平均數。"])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低評級的根本原因"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級根本原因"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別評分時間趨勢"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析類別隨時間變化的對照情況。"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視所選的某天或某週對每個類別提出的平均評分。"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析類別隨時間變化的對照情況。"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視所選的某天或某週在每個類別收到的平均評分。"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各審核評分"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大類別"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級量表"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均數/總數"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除自我審核"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯出所有卡片"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出項目"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收項目"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["對話"]), _normalize(["對話"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["項目"]), _normalize(["項目"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " 個回應"]), _normalize([_interpolate(_named("n")), " 個回應"]), _normalize([_interpolate(_named("n")), " 個回應"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 項審核"]), _normalize([_interpolate(_named("n")), " 項審核"]), _normalize([_interpolate(_named("n")), " 項審核"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["再顯示一項"]), _normalize(["再顯示另外 ", _interpolate(_named("n")), " 項"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 7 天"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上個月"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上週"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本週"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂時段"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["季"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入卡片失敗。請變更篩選條件並重試。"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有資料可顯示。"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月變更"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概述"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儀表板"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多資訊。"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中的篩選包條件涵蓋了不同評級量表的資料。"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有符合這些篩選條件的卡片釘選項目"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有符合這些篩選條件的儀表板釘選項目"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均審核時間"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下載 CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無類別"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別深入分析"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖表"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["評論"]), _normalize(["評論"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矩陣"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前成員"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內部品質評分"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（平均）"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已通過審核"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選卡片"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選儀表板"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["根本原因"]), _normalize(["根本原因"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核 ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核時間"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定根本原因"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可透過根本原因來瞭解對話評分較低的原因"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此類別未設定根本原因"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分/總和"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別小組"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別小組"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂順序"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組類別分類"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全選"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除自我審核"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["納入自我審核"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅顯示自我審核"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除篩選"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到主題標籤"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論主題標籤"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取評論主題標籤"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到標籤"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋服務台標籤"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到記分卡"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取記分卡"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我審核"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無相符的連線"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話來源"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取來源"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多篩選"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有符合的工作區"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出總數"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有使用者"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有人"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有小組"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有符合的小組。"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有小組"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示卡片"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料顯示條件"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核建立日期"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話建立日期"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示兩位小數"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視設定值"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整個帳戶"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區名稱"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無工作區"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗幟"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食品和飲料"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動物和自然"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人物和身體部位"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅遊和地點"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常用"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋結果"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["笑臉和表情符號"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符號"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到表情符號"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟對話"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回任務清單"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), "或瀏覽您的", _interpolate(_named("link")), "清單"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您目前正在使用「", _interpolate(_named("workspace")), "」工作區，該工作區未連線到此網域。按一下這裡即可立即連線。"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用連線"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您目前不能對「", _interpolate(_named("host")), "」提供意見。"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可將您的服務台啟用中的分頁。"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚無法審核此網域。若應當可供審核，請通知您的管理員。"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增此網域"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["連線到「", _interpolate(_named("host")), "」"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請稍候，正在為您準備"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法取得 URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已經連線到組織 Zendesk QA 帳戶的工具。"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有任何工具連線到組織的 Zendesk QA 帳戶。"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若網站會維持相同的 URL，只要在結束審核前先在尾端加上不重複的雜湊，便能讓您審核網站"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換帳戶"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的網域"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已經連線到此工作區的網域。"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可審核以下網域的任意頁面"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網域可供審核"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["擴充功能"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["唯一 URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["尚未連線服務台，請至此處新增：", _interpolate(_named("link")), "。"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅能使用 Zendesk QA 瀏覽器延伸功能\nProfessional 和 Advanced 計劃。"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轉換工作區"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人："])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供名稱"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供值"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂欄位"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["值"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這是我們第一次遇到此對話，請提供一些詳情。"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話參考："])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以後可用於識別工單的內容"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請一併提供受託人與標題"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立中"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核此對話"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未分配"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請聯絡您的經理或在「", _interpolate(_named("settings")), "」切換成不同的工作區。"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已停用此工作區的自我審核。"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再見"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法審核此 URL。瀏覽至對話。"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請先透過 SSO 提供者登入 Zendesk QA，然後再試一次。"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以 SSO 登入"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在開啟彈出式登入視窗。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以您的 Zendesk QA 帳戶登入"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請稍候..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證中..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未擁有帳戶？"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註冊"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您無權存取此對話。如果理應擁有存取權限，請聯絡您的經理。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法存取"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請檢查是否已在 Zendesk QA 中啟用連線"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理連線"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線錯誤"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往服務台的任一對話，即可繼續操作"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無效 URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將重新連線完畢。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請稍後"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請嘗試重新載入頁面，讓一切重回正軌"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發生問題"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不必再受到服務台看不到的界限拘束，隨時隨地都能審核對話。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級訂閱即可使用延伸功能"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理訂閱"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台連線未連結至此工作區"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台無法識別此工單，或 Zendesk QA 不具存取權限"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到對話"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新活動"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有一些意見給您"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入成功"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在可開始使用 app"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["給予所有類別評級"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用此功能將個案加入書籤，方便稍後篩選"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入中..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有針對此對話的意見"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閱讀意見"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Zendesk QA 中開啟"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有意見和通知都會顯示在這裡。還沒有任何內容..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Zendesk QA 中檢視更多"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的審核"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話詳情"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含全部"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為全部"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含全部"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並非全部"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優於代理"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遜於代理"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超過"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含任何一個"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是任何一個"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含任何一個"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是任何一個"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已違反"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已留評論"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由我評論"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未評論"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並非由我評論"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大待審核項目"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含有"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["於"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 14 天"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 24 小時"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 7 天"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上個月"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上週"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本週"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直到"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已偵測到"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存在"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由我加星號"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並非由我加星號"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超過"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大於"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未滿"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小於"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未違反"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不複雜"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偵測到"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不存在"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未檢視"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未審核"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非由我審核"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已審核"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由我審核"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接收"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大待審核項目"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含有"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超過"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大於"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未滿"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小於"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已檢視"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的評論收到回覆"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂類別"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂焦點"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋條件"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資訊"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量化分析"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核和意見"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示所有條件"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將您的 Bot 對話處理方式，與代理的平均水準相比"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 目前卡在循環中，因此不斷連續重複傳送相同的訊息"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的 Bot 回覆數"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與對話的 Bot 類型"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與對話的 Bot"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["代理訊息的對話內容包含「", _interpolate(_list(0)), "」此句"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可自動識別出缺少強制通知聲明的通話，例如「此通話將會錄音」及類似內容。"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參加對話的人員或 Bot"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中做為審核對象的代理或 Bot"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶已尋求更高層級的協助"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在對話中偵測到正面和負面的情緒"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過人工智慧分析，找出對話中最活躍的代理"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理結束對話時是否禮貌"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理是否理解並認同客戶的感受"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理在文法、錯別字和風格上的錯誤"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整個對話，找出典型的問候短句"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理是否理解客戶的詢問或疑慮"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析文字的理解難易程度，包含用字複雜度和句子長度"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整個對話，找出所提供的解決方案"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理在整個對話中的語氣"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶流失的跡象，客戶有轉移的可能，或已確定會失去客戶。"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話所屬的小組。"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理訊息的內容或文字"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的代理回覆數"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配到對話審核的代理"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話對話持續的時間長度（秒）"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話內容或文字"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話使用的溝通管道（例如電子郵件、交談功能）"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增評論至對話"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話新增評論的日期"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在對話中偵測到正面和負面的情緒"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶回覆客戶滿意度調查的日期"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話審核是否存在異議"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶用於發起對話的管道"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的客戶回應數"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中最後一則訊息的日期"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參加對話的使用者角色或類型"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理與客戶之間的無聲時間超過閾值"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶已尋求更高層級的協助"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶對得到的支援服務，表示十分感謝或非常滿意"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶或代理明確請求後續跟進"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整場語音對話的文字或錄音"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話是否已標記為已加星號"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於分類內容的評論主題標籤"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智慧偵測到的對話語言"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中最後一則訊息的日期"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的私人備註數"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中每則訊息的平均字元數"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中面對客戶的回覆數"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資訊公開可見的參與者"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中每則訊息的平均字數"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於評價或評估對話的類別"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話審核流程的狀態"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參加對話的參與者或人員"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與對話相關聯的電子郵件地址"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核是否受過檢視"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負責評估對話的審核者"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後一次審核對話的日期"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中做為審核對象的代理"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶就對話滿意度所提供的任何特定評論或意見"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶對此對話的客戶滿意度評分"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中每則訊息的客戶滿意度評分"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於審核對話的記分卡"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已違反所提供的 SLA"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話產生來源或連線的類型"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出現異常或非典型的對話，雙方需要溝通多次尋求解決之道"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話內容的相關調查問題"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與對話相關聯的調查標題或名稱"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配到對話審核的代理"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與對話相關聯的品牌或特定產品/服務"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話使用的溝通管道（例如電子郵件、交談功能）"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話關閉或解決的日期"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話最初建立的日期"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話最初建立的日期"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶回覆客戶滿意度調查的日期"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話的簡短描述或摘要"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與對話連結的外部參考 ID"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於分類整理對話的資料夾或特定分組"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於建立對話架構的表單或範本"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與對話連結的外部參考 ID"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收對話時所用的信箱或電子郵件帳戶"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與對話建立關聯的電話號碼"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配給對話的優先等級"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的回覆總數"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話的情形或目前狀態"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話的目前狀態"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話的標題或主題"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的代理回覆數"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為了分類對話所分配的標籤"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話的類型或類別"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後更新對話的日期"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後更新對話的日期"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話發生的媒體或管道"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話的記錄副本內容"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經由人工智慧分析，列為不符合審核資格的對話"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中參與的代理人數"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話是否經過檢視"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任何訊息"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 通訊效率"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 重複"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 回覆數"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 類型"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話方向"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶端訊息"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無聲"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少錄音通知"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向上提報"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話記錄副本內容"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結尾"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已加星號"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不符審核資格"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話方式"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯別字和文法"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理訊息"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最活躍代理"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理回覆數"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話持續時間（秒）"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話內容"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訊息管道"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論標籤"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論日期"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度調查回覆日期"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶管道"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶回覆數"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後回覆日期"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者類型"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問候"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶流失風險"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無聲"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向上提報"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優秀服務"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後續跟進"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記錄副本"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論主題標籤"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["極端值"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後回覆日期"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話私人備註數"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均字元數"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均字數"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開回覆數"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開參與者"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級日期"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級和評論"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核狀態"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通順度"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相關電子郵件"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相關"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆數"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到審核"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已審核記分卡"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已檢視審核"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核日期"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查評論"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每則訊息的客戶滿意度"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滿意度評分（客戶滿意度）"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提供解決方案"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源類型"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標題"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查問題"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查標題"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話品牌"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌網域"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話管道"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結束日期"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立日期"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立日期"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度調查回覆日期"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度調查傳送日期"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話描述"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部 ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話欄位"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話資料夾"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話表單"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話小組"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部 ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話信箱"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話號碼"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話優先等級"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話回覆數"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話狀態"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話狀態"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話標題"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理回覆數"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話類型"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日期"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日期"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語氣"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記錄副本內容"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與的代理人數"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已檢視狀態"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能性風險"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康狀況風險"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社會事件風險"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 類別"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取一個日期相關條件"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為篩選新增名稱"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填寫篩選名稱"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入人員"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前工作區成員"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區成員"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["新增「", _interpolate(_named("filterName")), "」"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套用篩選"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需有 1 個日期相關的篩選"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取選項"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取選項"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用將在 ", _interpolate(_named("n")), " 天後到期。", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用將<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>在 ", _interpolate(_named("n")), " 天後到期</strong></a>。"])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用將在 ", _interpolate(_named("n")), " 天後到期。", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用將<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>在 ", _interpolate(_named("n")), " 天後到期</strong></a>。"])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用將在今天到期。", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的試用將在<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>今天</strong></a>到期。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即設定訂閱"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 天"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用剩下 ", _interpolate(_named("days"))])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已達到計劃的使用者人數上限。", _interpolate(_named("link")), "以瞭解更多或升級計劃。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請按一下這裡"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線服務台"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如此我們便能擷取您的工單，讓您處理審核工作。"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線服務台"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您目前是以樣本資料使用 Zendesk QA。若要刪除樣本資料並匯入自己的對話，請", _interpolate(_named("link")), "。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["樣本資料"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下方複製實用資訊"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["擷取整頁螢幕畫面以提供背景資訊"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們的客戶支援團隊解決此問題："])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發生問題"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯誤參考："])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製實用資訊"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者、Bot 和工作區"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者與工作區"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本週"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("period")), "已審核 ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("period")), "已審核 ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("period")), "已審核 ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新內容"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍵盤快速鍵"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與我們交談"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法載入我們的交談（是否使用了廣告封鎖程式？）"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳至..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社區"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儀表板"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幫助"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主頁"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提出的審核"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的審核"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知識庫"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換帳戶"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已更新應用程式。", _interpolate(_named("buttonStart")), "重新載入", _interpolate(_named("buttonEnd")), "。"])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果想在工作區中使用 Zendesk QA："])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["向您通知一下，您獲邀在工作區中使用 Zendesk QA。只要您願意，可繼續無妨。但請注意，如此便會為您建立一個", _interpolate(_named("bold")), "。"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單獨的組織"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["是，讓我繼續使用", _interpolate(_named("link")), "。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新帳戶"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您確定嗎？"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("inviter")), "」請您加入「", _interpolate(_named("account")), "」"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無網際網路連線"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增釘選項目"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速導覽"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鎖定並展開評論編輯器"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏或顯示協作面板"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瀏覽至下一個對話"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟原始對話"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瀏覽至上一個對話"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["給予所有類別可選最高評級"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["給予所有類別可選最低評級"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨機對話（隨機重新排序）"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告訴我們"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鍵盤快速鍵"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換快速鍵模式"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已達到 ", _interpolate(_named("charLimit")), "- 字元限制"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不允許影像「", _interpolate(_named("name")), "」的類型（", _interpolate(_named("type")), "）"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["影像「", _interpolate(_named("name")), "」大小過大（上限 5MB）"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論編輯器載入失敗"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放置即可上傳"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影像"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理範本"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增任何範本"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增根本原因"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他，包括已刪除的原因"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級原因"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國家"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["員工數"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話號碼"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們會與您聯絡，協助您設定帳戶"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名稱"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司聯絡號碼"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您如何得知 Zendesk QA？"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取管道"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取代理數量"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼續"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填寫所有必填欄位"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必須同意自己已閱畢並同意我們的條款和隱私政策。"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援型 Slack 頻道"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["廣告"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事件"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朋友或同事"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台商店或帳戶經理"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轉介網站"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋引擎（Google、Bing 等）"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社群媒體"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向我們介紹一下您自己和貴公司。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶詳情"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我準備好了。"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到處逛逛，熟悉一下 app，刪除和新增些內容。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶建立好了，接下來呢？"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["準備好匯入真正的對話了嗎？"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增自己的資料"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始探索"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可透過樣本資料試用"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們可以開始擷取工單，讓您處理審核工作。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線服務台帳戶"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我改變了主意。"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消註冊"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已建立釘選項目。", _interpolate(_named("buttonStart")), "前往釘選項目", _interpolate(_named("buttonEnd")), "。"])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional 計劃可使用培訓釘選項目。"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級計劃"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視所有計劃"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可儲存備註，用於下次的一對一活動或培訓階段。"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓用釘選項目"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留下評論或使用 ", "@", " 新增其他人"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此對話已刪除"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建釘選項目"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立釘選項目"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯釘選項目"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選項目的運作方式"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您沒有權限檢視此對話"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選卡片"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選對話"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選儀表板"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存釘選項目"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選此卡片"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選此對話"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選此儀表板"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選在使用者名下"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取使用者"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有您可以看到自己的釘選項目。若要和他人分享，可在評論中 ", "@", " 提及對方。"])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已邀請「", _interpolate(_named("agentsNames")), "」參加此對話"]), _normalize(["已邀請「", _interpolate(_named("agentsNames")), "」參加此對話"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 條評論"]), _normalize([_interpolate(_named("n")), " 條評論"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["您提及 ", _interpolate(_named("participants")), "，但對方不在此對話中。"]), _normalize(["您提及 ", _interpolate(_named("participants")), "，但對方不在此對話中。"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 位參與者"]), _normalize([_interpolate(_named("n")), " 位參與者"]), _normalize([_interpolate(_named("n")), " 位參與者"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您是否要移除「", _interpolate(_named("agentName")), "」對此釘選項目的存取權限？"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不進行任何操作"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不邀請對方參加此對話"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增評論"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請對方參加此對話"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除使用者"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可復原。"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這也會刪除此釘選項目下的所有評論。"])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 條新訊息"]), _normalize([_interpolate(_named("n")), " 條新訊息"]), _normalize([_interpolate(_named("n")), " 條新訊息"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有您才能檢視自己的釘選項目。若要共用釘選項目，可在評論中 ", "@", " 某人。"])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " 釘選了", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儀表板"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級即可取得培訓釘選項目"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional 計劃可使用培訓釘選項目。"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional 計劃可使用培訓釘選項目"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級即可取得功能"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用來準備一對一的內容，或是儲存項目供下次使用。"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這會永久刪除此釘選項目和所有已新增的評論。"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除釘選項目"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除釘選項目"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料夾名稱"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選內容以待稍後使用"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回覆"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排列最新的在前"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排列最舊的在前"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可將感興趣的儀表板趨勢或對話儲存起來，作為一對一內容的準備，或記起來供下次使用。"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下上方的按鈕，或是按一下「對話」或「儀表板」視圖中的釘選圖示，即可建立釘選項目。"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立任何釘選項目"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」無結果"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請嘗試使用不同的關鍵字，或檢查是否有錯字。"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建釘選項目"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訊息"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人員"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按使用者、關鍵字搜尋..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按字母順序"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近建立"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近編輯"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多人釘選"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["我的目標是", _interpolate(_named("schedule")), "審核 ", _interpolate(_named("n")), " 個對話"]), _normalize(["我的目標是", _interpolate(_named("schedule")), "審核 ", _interpolate(_named("n")), " 個對話"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新目標"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定目標"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可根據想要審核的對話數量，設定個人目標。"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核目標"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道載入中"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取管道"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅分享附有評論的調查意見"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可特別強調同事優異的服務品質和客戶的良好意見，同事提供支援。"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與 Slack 分享正面調查意見"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到管道"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動分享高於以下評分的意見"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誇獎 Bot 整合"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果尚未完成任何審核，會在一週開始時收到提醒。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提醒"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週審核提醒"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Slack 中接收新的審核、回覆、提及和異議通知。"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提及"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話回覆"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 整合"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知新的審核、回覆、提及和異議。"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與我相關的異議"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論中的提及"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剛收到的審核"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話中的回覆"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["具有統計資料的報告"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收所提出和所收到審核的報告。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報告"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每 3 小時"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每 6 小時"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的帳戶管理員已鎖定通知設定值。"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取時段"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人通知"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "（作用中）"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此帳戶新增社群登入功能："])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若移除目前使用中的登入方法，會將您登出。可使用替代電子郵件地址再次登入。"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除登入方法"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功新增 Google 為登入選項"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入選項"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功新增 Slack 為登入選項"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立密碼"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您確定嗎？"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方法"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設密碼"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["會寄送一封電子郵件至 ", _interpolate(_named("email")), "，可供重設密碼。"])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要電子郵件"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請檢查收件匣內是否有確認電子郵件"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔術連結"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要變更，請先變更您的", _interpolate(_named("link")), "。"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["電子郵件地址 ", _interpolate(_named("email")), " 會與此密碼建立關聯。"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新個人檔案"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人檔案圖片"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為取得最佳效果，請上傳正方形相片（256x256 像素或以上）"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要電子郵件"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一週開始"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人設定值"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取至少一個選項"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本內容"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本名稱"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在以下位置顯示範本"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增範本內容"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增範本名稱"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立範本"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立範本"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立評論範本，以加快審核、項目釘選和培訓的速度。"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用目標"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除範本"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久刪除此範本？"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯範本"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立評論範本，以加快審核、項目釘選和培訓的速度。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立任何範本"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已建立評論範本"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除評論範本"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存評論範本"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選項目"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核和異議"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要取得存取權限，請聯絡", _interpolate(_named("link")), "。"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶支援"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到帳戶"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目前請先使用 ", _interpolate(_named("link")), " 前往 Zendesk QA。"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回 Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶尚未移轉"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概述"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量化分析"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計資料"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儀表板"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出項目"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收項目"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的評論"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的異議"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的審核"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收項目"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始項目"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查意見"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釘選項目"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立密碼"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認證"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核目標"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立範本"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["範本"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳單結算"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["席位"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新類別"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到衝突的訊息"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更或移除一則衝突訊息"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["使用 ", _interpolate(_named("placeholder")), " 圖示即可將敏感資料排除在自動 QA 之外。舉例來說，可使用「", _interpolate(_named("placeholder")), "，請多保重」來呈現任何名稱，無需使用「約翰，請多保重」。"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最多 ", _interpolate(_named("limit")), " 列"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規則套用位置"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已達到 10 個自訂類別的上限"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已達到 ", _interpolate(_named("limit")), " 個自訂類別的上限"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增條件"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增列"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["且"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除條件"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若代理訊息"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否則評分為"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["則評分為"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立記分卡類別"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用「/」新增變數"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有方向"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理訊息不能為空"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訊息不得超過 300 個字元"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有來源"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有管道"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有標籤"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有對話"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["縮小規則套用範圍。"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定對話"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立焦點深入分析"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理訊息不能為空"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已達到 10 個自訂深入分析的上限"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已達到 ", _interpolate(_named("limit")), " 個自訂深入分析的上限"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填寫深入分析名稱"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深入分析名稱"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立類別"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深入分析類型"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中性"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往工作區設定值"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫時不要"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要開始使用此評級類別，請前往工作區記分卡設定值，將此評級類別新增至所選擇的記分卡。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已建立評級類別"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辨識特定關鍵字或片語，自動為已關閉的對話評分。"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用於語音通話的記錄副本。"])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["明白了"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["擊掌一下！✋ 您的自動化評級類別已達到 ", _interpolate(_named("limit")), " 的上限。您就是分類奇才！ 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已達到類別上限"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請確認所有欄位均已正確填寫"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為評級類別命名"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別名稱"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新評級類別"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新評級類別"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辨識特定關鍵字或片語，自動為已關閉的對話評分。不適用於語音通話的記錄副本。"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辨識特定關鍵字或片語，自動顯示已關閉的對話。僅適用於記錄副本。"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辨識代理與 Bot 訊息中的特定關鍵字或片語，自動為對話評分。"])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要使用此評級類別，請前往工作區記分卡設定值，將此評級類別新增至記分卡。"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根據訊息中的特定關鍵字或片語自動為對話評分。"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用對象"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深入分析設定"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此深入分析可根據所訂定的條件，顯示在焦點中。"])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡設定"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此類別可根據所訂定的條件，用於記分卡上。"])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交談"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將推出"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預先定義"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語音通話"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存變更"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存變更"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示："])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下 ", "{··}", " 圖示，即可在自動 QA 的結尾檢查期間，將姓名、公司詳情或任何其他敏感資訊排除在外。"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增經過批准的結尾"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯經過批准的結尾"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的結尾"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增免除"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯免除"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免除"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增經過批准的問候"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯經過批准的問候"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的問候"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在這裡貼上文字，以分行符號間隔"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在每個詞彙後按 Enter 以建立新條目"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用語言"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舉例來說，可使用「請多保重，", "{··}", "」來呈現任何名稱，而無需使用「請多保重，約翰」。"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動態內容預留位置"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免除"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已新增 1 項免除"]), _normalize(["已新增 ", _interpolate(_named("count")), " 項免除"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已新增免除"]), _normalize(["已新增免除：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增免除"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到免除"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增免除"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免除（區分大小寫）"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["新增免除"]), _normalize(["新增 1 項免除"]), _normalize(["新增 ", _interpolate(_named("count")), " 項免除"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋字詞或片語"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存免除"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有語言"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋語言"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已新增結尾"]), _normalize(["已新增結尾：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增結尾"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到經過批准的結尾"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有任何經過批准的結尾"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結尾"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增結尾"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋結尾"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增結尾"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存結尾"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["表單有重複項目：", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已新增問候"]), _normalize(["已新增問候：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增問候"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到經過批准的問候"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有經過批准的問候"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問候"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增問候"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋問候"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增問候"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存問候"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增免除"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["刪除免除"]), _normalize(["刪除免除"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["是否要永久刪除這項免除？"]), _normalize(["是否要永久刪除這幾項免除？"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯免除"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["一項或多項免除項目長度超過 ", _interpolate(_list(0)), " 個字元"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " 個字元"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日期"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新者"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變數"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可訂定符合品牌和服務標準的專屬結尾語。新增後，自動 QA 便只會偵測經過批准的結尾。所做變更會套用至往後的對話。"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的結尾已達到 100 筆的上限。若想要新增更多，不妨重新檢查並精簡現有的結尾。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的結尾已達到數量上限"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的結尾"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您希望自動 QA 能將其視為錯別字或文法錯誤，予以忽略的字詞或片語。所做變更會套用至往後的對話。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略清單"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可訂定符合品牌和服務標準的專屬問候。新增後，自動 QA 便只會偵測經過批准的問候。所做變更會套用至往後的對話。"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的問候已達到 100 筆的上限。若想要新增更多，不妨重新檢查並精簡現有的問候。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的問候已達到數量上限"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的問候"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫時不要"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除類別"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久刪除此評級類別？"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換類別清單"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除類別不會顯示在任何新對話與篩選中。儀表板和對話的歷史資料將維持不變。"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此類別將從所有新增類別的記分卡中移除。"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除記分卡類別失敗"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡類別已刪除"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除記分卡類別"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 類別"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由人工智慧模型支援的", _interpolate(_named("highlight")), "會保留在記分卡上，但不會再自動評分"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉對 LLM 的整合，並不會完全關閉自動 QA 功能，而會出現以下情況："])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歷史資料"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "會保留在自動 QA 儀表板上"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動新增根本原因"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "會保留，但將移除所有 LLM 型的根本原因"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉 LLM 型自動 QA"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 使用人工智慧和大型語言模型（LLM）自動為特定類別評分。您的資料會依據嚴格的資料處理協議（DPA）加以處理，絕不用於訓練任何模型。"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解生成式人工智慧"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動 QA 會將 Microsoft Azure OpenAI 服務用於", _interpolate(_named("automatically_scoring_categories")), "。您的資料會依據嚴格的資料處理協議妥善處理，且用途僅是為您提供自動 QA 功能，且", _interpolate(_named("never_used_for_training_models")), "。"])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動為某些類別評分"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絕不會用於訓練任何 OpenAI 模型"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 型自動 QA 已開啟"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 型自動 QA 已關閉"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 型自動 QA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在自動 QA 中以人工智慧自動評分"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure 自動 QA 類別的資料處理"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure 同意已撤銷"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已授予 Microsoft Azure 同意。對話很快就會獲得自動評級。"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您仍可使用自動 QA，但會發生以下情況"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停用 MS Azure OpenAI 服務，並不會關閉自動 QA 的功能。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可在記分卡類別視圖中執行此操作。"])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由該模型支援的自動 QA 類別會保留在記分卡上，但不會自動評分。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您手動新增的根本原因將會保留。所有模型產生的根本原因將會移除。"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歷史資料會保留在自動 QA 儀表板上。"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停用 MS Azure OpenAI 服務的資料處理功能"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更廣泛的自動 QA 類別。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即可用的多語系支援。"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速新增新類別。"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進一步強化評估的正確性，GPT-3.5 展現出真人級別的背景資訊感知能力。"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["享有最新發展的人工智慧技術。"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您會取得這些功能"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過 MS Azure OpenAI 服務實現資料處理能力"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已給予同意"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未給予同意"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有新使用者皆會分配至此工作區"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預設工作區"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 小時制（", _interpolate(_named("time")), "）"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 小時制（", _interpolate(_named("time")), "）"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間格式"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週一"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週日"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新公司個人檔案"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一週開始"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂整合"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存取密鑰 ID 和密鑰通常位於「Access management」>「Users」"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 存取密鑰 ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如 AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消變更存取密鑰"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更存取密鑰值"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此連線僅能訂定一個區域，通常位於「位置」"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 區域"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增密鑰"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 存取密鑰"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在 Amazon Connect 找到所有相關資訊。"])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常位於 Amazon Connect 執行個體設定值和資料儲存頁面"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話錄音儲存位置"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Amazon Connect 時出錯。請檢查是否已正確填妥所有欄位，然後再試一次。"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存位置的格式應為儲存貯體/資料夾，並以斜線分隔。"])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要新增連線，請聯絡 ", _interpolate(_named("link")), "。"])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶支援"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的所有 Zendesk 工單都將自動同步。您可以選擇排除已選取的工單。"])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞太（東京）"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞太（首爾）"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞太（大阪）"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞太（孟買）"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞太（雪梨）"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加拿大（中部）"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歐洲（法蘭克福）"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歐洲（蘇黎世）"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歐洲（愛爾蘭）"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歐洲（倫敦）"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伺服器地區"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中東（阿聯）"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取伺服器地區"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南美（聖保羅）"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美國東部（維吉尼亞）"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美國東部 2（俄亥俄）"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美國西部（奧勒岡）"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增連線"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始連線"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅同步對話可以"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道一、管道二"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此連線的唯一 ID 為 ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 個月"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 年"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 個月"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 個月"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選定的保留期過後，非作用中的對話將會刪除。已提交的審核在「活動」和「儀表板」視圖中仍會顯示。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留時段"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取保留時段"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請注意此操作不可復原。"])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我瞭解這會從 Zendesk QA 移除此連線的所有服務台相關資料，包括對話、欄位和標籤。"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提交的審核不會受到影響，且在特定的視圖中仍會顯示。"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一組、第二組"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主動開發"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此整合功能仍在", _interpolate(_named("activeDevelopment")), "。可能缺少某些詳情（如附件或自訂欄位）"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信箱一、信箱二"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤一、標籤二"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 金鑰"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 金鑰密鑰"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在「設定值」→「工單欄位」下找到 Freshdesk 的「工單欄位名稱」"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在「Settings」→「Custom fields」下找到 Help Scout 的「Custom Field Names」"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在「設定值」→「Klass 」→「對話」下找到 Kustomer 的「Klass 對話名稱」"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在「設定值」→「自訂欄位」下找到 Live Agent 的「自訂欄位名稱」"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在「設定值」→「自訂欄位」下找到 Wix 的「自訂欄位名稱」"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用半形逗號隔開欄位 ID。可在 Zendesk 的「管理員」→「工單欄位」下找到這些 ID。"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結幾位服務台使用者，即可開始審核第一批對話。別擔心，我們不會通知對方，除非您邀請他們成為工作區成員。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合成功"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增成員"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們現在會開始提取您的對話。"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["網域：", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["服務台識別碼：", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能驗證連線"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定完成。這是您的新連線。"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一些連線尚未更新，或已變成非作用中的狀態。請嘗試更新這些連線，或將連線從清單中移除。"])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 用戶端 ID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 用戶端密鑰"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要建立新 API 金鑰，請前往「設定值」→「帳戶」→「API 和 SDK」。所要使用的重新導向 URL 是 ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶 ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取服務台即可開始"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若您的 Intercom 帳戶設為從歐盟或澳洲提供服務，請更新此項。"])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["用於登入「", _interpolate(_list(0)), "」的電子郵件地址"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏一般使用者的名稱、電子郵件地址和電話號碼。所有服務台連線的銀行憑證均已隱藏。"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚無整合，可立即新增。"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要這些角色："])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立連線"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增連線"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消核取此項，便能手動將此連線新增至指定工作區"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線到所有現有工作區"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 鍵碼"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 鍵碼密鑰"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已刪除連線「", _interpolate(_list(0)), "」"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線名稱"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立時間"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在下方取得您的 API 金鑰。"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度閾值"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除連線「", _interpolate(_named("connectionName")), "」"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入「刪除連線」即可移除此連線"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除連線"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理並監控已連線的資料來源。"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯連線"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用半形逗號隔開欄位名稱"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新失敗"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為整合取一個不重複的名稱"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏進階選項"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏敏感資料欄位"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永不儲存附件 URL"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略附件"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永不儲存對話內容（稍後可依需求從服務台提取）"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略對話內容"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永不儲存對話標題（稍後可依需求從服務台提取）"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略標題"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非作用中"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」子網域"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 地區"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比對收件匣團隊 ID"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 團隊的數字 ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信箱"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動更新連線"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏客戶資料"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無工作區"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感欄位名稱"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感欄位 ID"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新連線"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新連線"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線至 Salesforce 沙箱"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["設定與「", _interpolate(_list(0)), "」的連線"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示進階選項"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅擷取評分低於或等於所設閾值的對話"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套用客戶滿意度閾值"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅同步滿足以下條件的對話..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取得最新對話"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新連線"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新連線"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者名稱"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要取消訂閱並刪除帳戶，請聯絡", _interpolate(_named("link")), "。"])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶支援"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除帳戶"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若刪除 Zendesk Support（或另一個服務台）中的工單，並不會影響相關的對話審核。您仍可檢視和管理這些對話。"])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若刪除 Zendesk QA 中的工單，也會刪除相關的對話審核內容。", _interpolate(_named("line_break")), "不過刪除 Zendesk Support（或另其他服務台）中的工單並不會影響相關的對話審核內容。仍可檢視和管理這些對話。"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理審核"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除審核為永久性操作。此操作也會影響儀表板上的資料。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除所有審核"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除所有"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除所有審核"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除所有"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有與已刪除工單關聯的審核時，您可在此處看見。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚無審核"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋工單 ID"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["工單 ID：", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除對話"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["無法刪除預設工作區。若要刪除此工作區，請在「", _interpolate(_named("link")), "」選擇其他預設工作區。"])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶設定值"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可復原。"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除並刪除所有資料"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我瞭解這會將此工作區相關的內容", _interpolate(_named("removeAllData")), "，包括工作區使用者和連線。"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除工作區"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入「刪除工作區」即可刪除此工作區"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除工作區「", _interpolate(_named("workspaceName")), "」"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除工作區"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請務必確定您要進行此操作，或請事先諮詢專業人士。"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇在選取預設受審核者時，是根據使用頻率最高的代理，還是根據當前受託人。"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最活躍代理"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預設受審核者"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許人員審核其自己的對話，並允許代理進行審核"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區已刪除"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區已更新"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏其他審核者先前提出的審核，讓評等保持中立"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不帶偏見的評等"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色彩"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可復原。"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請注意，這會移除並刪除此工作區相關的所有資料，包括工作區使用者和連線。"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除工作區"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區刪除中..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我審核"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔術連結"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔術連結"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能停用您目前的登入方式"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證方法已更新"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許所有使用者使用特定類型的驗證方法。"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者與工作區"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立和管理工作區，並且邀請或聯絡使用者。"])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留在頁面上"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離開頁面"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否要離開此頁面，並放棄所有變更？"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["似乎沒有可匯入的對話。從與您的服務台對話開始，再回到此處進行分析。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到對話"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您不能刪除此系統類別"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除類別"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會永久刪除類別，且清除報告中的所有類別資料。"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要還原此類別，必須重新建立。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除類別"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋類別"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動類別會依據建議評級自動填寫記分卡，以節省審核時間。手動類別需由審核者從頭開始填寫。"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後更新時間"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結尾"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯別字和文法"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問候"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通順度"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提供解決方案"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語氣"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到類別"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到根本原因"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到記分卡"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請搜尋其他內容，或變更篩選條件"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類別"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類別"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類別"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有類別"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類別"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種記分卡"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種記分卡"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種記分卡"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有記分卡"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種記分卡"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種狀態"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種狀態"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種狀態"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有狀態"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種狀態"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類型"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類型"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類型"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有類型"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 種類型"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 個工作區"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 個工作區"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工作區"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 個工作區"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 個工作區"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), " 的權重構成整體評分的 ", _interpolate(_named("percentage")), "%"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除記分卡"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會永久刪除記分卡，且清除報告中的所有記分卡資料。"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要還原此記分卡，必須重新建立。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除記分卡"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後更新時間"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非作用中"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已更新評級量表。", _interpolate(_named("buttonStart")), "開始審核", _interpolate(_named("buttonEnd")), "。"])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 預設為二元量表，但您亦可選擇更精細的量表："])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要檢視按類別設定的不同評級量表選項，請前往「", _interpolate(_named("link")), "」"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心/語氣"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品知識"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新評級量表："])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會封存（而非刪除）先前的評級量表和對應類別。"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於新舊量表可能無法相比較，某些儀表板資料會受到影響。"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製現有的評級類別設定到新量表上"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否確定要更新評級量表？"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可變更記分卡顯示格式（數字或表情符號），並將類別設為必填或選填。"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用表情符號而非數字"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許人員跳過類別"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決方案"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級量表類型"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已複製 1 個工作區的類別"]), _normalize(["已複製 ", _interpolate(_named("workspacesAmount")), " 個工作區的類別"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區設定值"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如為 ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若在通話中，代理並未提醒客戶對話將會出現無聲的間隔，便自動顯示此類通話。"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話無聲的時間超過設定的閾值。"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂定通話中的無聲上限閾值"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無聲"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除的深入分析不會顯示在任何新的對話與篩選中。儀表板和對話的歷史資料將維持不變。"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法刪除焦點深入分析"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點深入分析已刪除"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除焦點深入分析"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視在轉錄和訊息中包含特定文字或片語的對話。"])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可自動識別出缺少強制通知聲明的通話，例如「此通話將會錄音」及類似內容。"])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旨在運用可動態理解背景資訊的大型語言模型（LLM），辨識通話中的錄音通知聲明，無需預先設定片語短句。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少錄音通知"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若在通話中，代理並未提醒客戶對話將會出現無聲的間隔，便自動顯示此類通話。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無聲"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定通話中的無聲時刻"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["需要變更訂閱嗎？", _interpolate(_named("link")), "（", _interpolate(_named("email")), "），我們將竭誠為您服務。"])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動分析 100% 的跨代理、BPO、管道和語言的對話。人工智慧可偵測出問題、知識差距和培訓機會，用於改善服務。"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 覆蓋率 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理意見與培訓"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點人工智慧深入分析"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進階報告與儀表板"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒分析與篩選功能"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨選翻譯"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核分配和目標設定"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓和測驗"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 品質保證（QA）"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional 的所有功能，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced（舊版）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於立即通知重大不及格的 webhook"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料倉儲整合"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud 整合"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂整合開發"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專屬的客戶成功經理"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可透過 Slack 直接使用我們的產品和工程團隊"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite 的所有內容，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智慧 Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% 涵蓋範圍的自動 QA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過人工智慧驅動的對話深入分析，解讀資料"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以人工智慧增強服務台的資料擴充與篩選功能"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點：自動偵測必須審核的互動"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒分析與篩選功能"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以人工智慧為基礎的業績報告和深入分析"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨選對話翻譯"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核時間記錄"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格率分析"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智慧 Suite 的所有內容，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括 Salesforce 和 Genesys 整合的 Enterprise 實作套餐"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂整合開發"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料倉儲整合"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用於立即通知重大不及格的 webhook"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專屬的客戶成功經理"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可直接使用我們的產品和工程團隊"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搶先體驗並協助改善我們的藍圖"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動審核任務和目標設定值"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標性的培訓與分析"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["學習管理相關測驗"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者角色和權限管理"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感資料隱藏"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進階篩選選項和對話搜尋"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可自訂記分卡，提供多種評級、可調整的權重，以及自動設為不及格的選項"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可透過瀏覽器延伸功能，隨時隨地審核對話"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因分析"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過業績報告，比較代理、團隊、BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度調查和客戶意見分析"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter 上的所有功能，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional（舊版）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每個對話有多種評級量表"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 整合"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智慧驅動的互動對話深入分析"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核任務"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓用釘選項目"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓階段"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情緒分析與篩選功能"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根據對話複雜程度篩選"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話深入分析"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件式記分卡"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因分析"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以進階人工智慧為基礎的客戶滿意度分析"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分校準"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過瀏覽器延伸功能，可隨時隨地審核對話"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點功能可找出必須審核的對話"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨選對話翻譯"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核時間記錄"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂計劃"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter（舊版）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無限量審核對話"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 匯出"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同僚與自我審核"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡可調整權重，且有可自動設為不及格的選項"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強大的篩選選項"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日、每週或每月審核目標"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度調查和基本分析"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儀表板"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過報告來比較代理、團隊、BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者角色與權限管理"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我想變更我的 Zendesk QA 訂閱。\n\n醒目顯示或指定一個選項，並指定您想要變更的席位數量：\n我要 [新增 X 個席位] [移除 X 個席位] [升級至 Zendesk QA 計劃]\n\n請提供您的公司名稱、與 Zendesk QA 帳戶關聯的名稱，以及任何其他有助於我們處理此變更的詳情。"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 訂閱變更"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我要購買 Zendesk QA 訂閱。\n\n請提供您的公司名稱、與 Zendesk QA 帳戶關聯的名稱，以及任何其他有助於我們處理此請求的詳情。\n\n另請在購買前，提出任何您需要解決的特定問題：\n\n謝謝！"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購買 Zendesk QA 訂閱"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的訂閱結束日期為 ", _interpolate(_named("date")), "。（尚餘 ", _interpolate(_named("days")), "）"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的試用期將於 ", _interpolate(_named("date")), "（", _interpolate(_named("days")), "後）到期"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 天"]), _normalize([_interpolate(_named("n")), " 天"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("dayOfMonth")), " 日會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("dayOfMonth")), " 日會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("dayOfMonth")), " 日會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("dayOfMonth")), " 日會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("dayOfMonth")), " 日會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("dayOfMonth")), " 日會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["於 ", _interpolate(_named("billingDate")), " 會向「", _interpolate(_named("card")), "」收費（到期時間為 ", _interpolate(_named("expires")), "）。"])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結尾為"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已到期"]), _normalize(["於 1 天後到期"]), _normalize(["於 ", _interpolate(_named("expiresIn")), " 天後到期"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基本價格提供 ", _interpolate(_named("n")), " 份使用者授權"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sum")), "/月起"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每個使用者/月"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["季"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除排定的取消作業"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果要繼續使用 Zendesk QA，請", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["訂閱已於以下日期取消：", _interpolate(_named("date")), "。"])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["訂閱排定的到期日：", _interpolate(_named("date")), "。"])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["可試用 ", _interpolate(_named("days")), " 天，無需額外費用！"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您有 ", _interpolate(_named("n")), " 位使用者。席位數不能低於此數量。"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["訂閱將於以下時間恢復：", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您目前的 Zendesk QA 計劃（", _interpolate(_named("plan")), "）不包含以人工智慧為基礎的功能。", _interpolate(_named("link")), "，充分發揮語音智慧功能的威力。"])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要取消訂閱，請", _interpolate(_named("link")), "聯絡我們，或與我們交談。"])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品質管理計劃"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品質管理"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新訂閱"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增信用卡"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有計劃"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳單結算"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視帳單結算資訊"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳單結算間隔"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消訂閱"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱將在帳單結算週期結束時取消。"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們進行編輯"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前計劃"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂定價"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降級"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年（節省 15%）"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年付費"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯訂閱"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索計劃"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費試用"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動開立發票"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步可審核並確認最終的訂單摘要。"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最受歡迎的"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您沒有作用中的付款方式。"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["試用中"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢復目前的訂閱，才能變更計劃"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫停訂閱"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每季"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價格"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新啟動訂閱"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢復訂閱"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["席位"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即開始試用"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未續訂"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暫停"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱狀態"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["試用"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升級訂閱"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶費力度評分「CES」"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度「CSAT」"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請用一分鐘時間，針對您的客戶支援體驗提供意見。在此先向您致上誠摯謝意，「", "{", "{", "agentName", "}", "}", "」非常重視您的意見！"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嗨，", "{", "{", "customerName", "}", "}", " 👋！"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的客戶滿意度調查"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請問您對「", "{", "{", "agentName", "}", "}", "」所提供的支援服務評價如何？"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["非常感謝。我們會活用您的意見，讓「", _interpolate(_list(0)), "」更為適合每個人。"])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解調查"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取調查類型"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍後可變更。"])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運用多種語言的自動化客戶滿意度調查，衡量和提高客戶滿意度。"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立任何調查"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立調查"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查名稱"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已建立調查"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果希望此處能夠列出，可嘗試變更關鍵字，或", _interpolate(_named("contact_us"))])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告訴我們"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到語言"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請在下方的清單中選擇一種或多種語言。如果發現這裡並未列出您想使用的語言，請", _interpolate(_named("contact_us")), "。"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增語言"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告訴我們"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增語言"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最後發佈於 ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最後儲存於 ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("type")), "」問題"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介紹用問題"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左側量表標籤"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件主旨"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右量表標籤"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["謝謝"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若調查有未翻譯之處，則會以原始語言顯示文字"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套用翻譯"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少翻譯"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請務必審核所有翻譯後再套用。若有需要，可變更下載的檔案，儲存後重新上傳。若金鑰「", _interpolate(_named("token_example")), "」未顯示為標籤，則可能是格式錯誤，或位於不支援金鑰的欄位。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核翻譯"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已翻譯"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立對話摘要並顯示建議評論。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 人工智慧助理（英文）"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型建立後即可編輯"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此類型已存在。請選擇其他名稱。"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可將自訂類型儲存為「調查類型」下拉式清單中的選項，並將儀表板上所有已建立的調查分組。"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入詞彙後，按 Enter 即可儲存"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯自訂類型"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型名稱不能為空"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定義類型"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂類型未儲存。按 Enter 即可儲存。"])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄件者電子郵件"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄件者姓名"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請問您有多同意以下敘述：「『", _interpolate(_named("agentName")), "』讓我的問題得以輕鬆處理。」"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["到目前為止，向「", _interpolate(_named("brandName")), "」取得幫助的難易程度如何？"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總體而言，今天解決問題的難易度如何？"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與團隊互動的難易度如何？"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私政策 URL（選填）"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務條款 URL（選填）"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您會如何評價「", _interpolate(_named("agentName")), "」為您提供的支援服務？"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對於此請求，我們的服務怎麼樣？"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您對支援代表的滿意度如何？"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請問您對今天「", _interpolate(_named("agentName")), "」提供的服務有多滿意？"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂問題"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["定義自訂的「", _interpolate(_named("type")), "」問題..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入自訂問題"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂 URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定您的電子郵件訊息"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若編輯自訂問題類型的名稱，便會在所有使用此名稱的調查中更新，但不會影響已收集的資料。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯自訂類型"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件主旨"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介紹用問題"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("type")), "」問題"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶費力度評分"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建自訂類型"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內容"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["個人化標記"]), _normalize(["個人化標記"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["將個人化標記拖曳放置到下方欄位中。"]), _normalize(["將個人化標記拖曳放置到下方欄位中。"])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["對話「", _interpolate(_list(0)), "」"])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前僅可透過電子郵件傳送客戶滿意度調查。我們正致力於為您提供更多機會，例如服務台客戶滿意度調查、簡訊調查等。"])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["同步或更新連線"]), _normalize(["同步或更新連線"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步或更新連線 ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["連線未正確同步"]), _normalize(["連線未正確同步。"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消核取相關核取方塊 ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即驗證 ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至少一個調查傳送規則以啟用連線"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此連線目前已停用。", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此連線已隱藏敏感的用戶端資料。若要啟用調查傳送，\n在設定值中", _interpolate(_named("link")), "。"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此連線需要其他權限以傳送調查。\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即驗證"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 需要更多權限用於 Intercom。"])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["（", _interpolate(_named("hours")), " ", _interpolate(_named("minutes")), "）"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 小時"]), _normalize([_interpolate(_named("n")), " 小時"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 分鐘"]), _normalize(["1 分鐘"]), _normalize([_interpolate(_named("n")), " 分鐘"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["小時"]), _normalize(["小時"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), "，即可設定調查傳送規則。"])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" 天"]), _normalize(["天"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查連結到期時間"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結到期時間"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果經過太久時間，便不允許回答調查"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」調查 ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撰寫後請用半形逗號隔開標籤或按 Enter。請確定列出的每一項都與 Intercom 內完全一樣。"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定傳送調查的條件。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查傳送規則"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查後續跟進傳送規則"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), _interpolate(_named("rules")), "調查已存在。\n可在這裡或", _interpolate(_named("link")), "修改規則，便能解決衝突。"])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已對「", _interpolate(_named("name")), "」調查設定規則相符的連線。可在這裡或", _interpolate(_named("link")), "修改規則，便能解決衝突"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增另一條規則"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增品牌"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取條件"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋值"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增標籤"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符合相同規則的"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取值"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件寄出時間"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後續跟進電子郵件已寄出"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內嵌程式碼片段與電子郵件後續跟進"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製程式碼"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增程式碼片段至您的 Zendesk 設定值，即可在電子郵件中附加 Zendesk QA 調查。"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安裝指南"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["程式碼片段預覽"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["程式碼片段已複製到剪貼簿"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["程式碼片段複製失敗"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內嵌程式碼片段"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此調查有未儲存的變更，請再次儲存，確保程式碼片段反映最新的更新內容。"])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增連線"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未新增連線"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可透過連線，將電子郵件、即時交談、電話以及更多操作匯入 Zendesk QA "])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解調查傳遞方式"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查傳送規則"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查後續跟進傳送規則"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增品牌規則"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增規則"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + 電子郵件後續跟進"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將 Intercom 帳戶連線到 Zendesk QA 客戶滿意度調查"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳遞方式"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到調查的對象和時間"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯規則"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已啟用"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過 app 內調查，客戶無需離開交談即可完成調查。"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啟動 Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各連線的傳遞規則"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), _interpolate(_named("rules")), "調查已存在。\n可停用連線，也可以在這裡或", _interpolate(_named("link")), "修改規則，便能解決衝突"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳遞"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定傳送調查的條件"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("name")), " 於 ", _interpolate(_named("createdDate")), " 建立"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["移除", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若移除此語言，便不會再對任何回應者顯示，且所有翻譯文字也均會刪除。"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除語言"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可復原。"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者您可透過在調查中隱藏來停用語言。這使您可保留所有翻譯，而不向回應者顯示。"])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最後由「", _interpolate(_named("name")), "」編輯於 ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解調查語言"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["新增其他語言之前，請確定已在「樣式」和「內容」階段完成變更。", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增語言"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已翻譯的欄位"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向回應者顯示"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預設"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下載翻譯檔案"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除語言"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 下載翻譯檔案"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核翻譯"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["翻譯已完成。如需任何變更，可", _interpolate(_named("review_link")), "或", _interpolate(_named("download_link")), "。"])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 下載翻譯檔案"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核缺少的內容"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["某些內容尚未翻譯，會以英文取代。若要補上缺少的譯文，可", _interpolate(_named("review_link")), "或", _interpolate(_named("download_link")), "。"])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存調查"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["調查包含未儲存的變更。", _interpolate(_named("save")), "即可下載最新變更後的翻譯檔案。"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳翻譯檔案"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取偏好的評級量表"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為客戶提供下拉式清單，用於選擇評級原因"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表情符號"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左側量表文字（選填）"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["號碼"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增原因"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因不能留空"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此原因已存在"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示評分原因的下拉式清單"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若刪除原因後再以相同名稱新增，或將原因重新命名，都一律會建立新條目。因此會影響儀表板上顯示的資料。"])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除原因"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除原因會保留在儀表板報告中，但不會向新的調查回覆者顯示。這也適用於相關翻譯。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除原因"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["刪除原因「", _interpolate(_list(0)), "」"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右側量表文字（選填）"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["採用 5 分制量表，1 和 2 為負面，3 為中性，4-5 為正面"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增量表文字"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("type")), "」量表"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級量表"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 正在匯入對話。此過程最長可能需要 24 小時。"])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這會在整個調查中用作個人化標記。"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌名稱"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按鈕和連結"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客製化調查外觀"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標誌背景"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援 JPG、PNG 和 GIF 格式"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標誌"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["樣式"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳標誌"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展現與眾不同的結尾"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入使用者提交意見後會看到的訊息"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感謝訊息"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["謝謝"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十六進位色彩代碼不正確"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯誤的電子郵件地址"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位不能為空"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL 錯誤"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未顯示："])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示："])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立日期"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送出的調查"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天傳送的調查"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除調查"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已複製調查"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已傳送預覽電子郵件"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已發佈調查"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新調查"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暫停調查"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫停"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫停調查"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入電子郵件地址以傳送調查預覽"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取預覽語言"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送預覽電子郵件"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳送樣本調查到您的電子郵件地址。調查預覽的評級不會儲存。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["預覽「", _interpolate(_named("type")), "」調查"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網路上的電子郵件調查預覽"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼續"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存為草稿"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌名稱"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在「樣式」步驟中新增品牌專屬的名稱"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶名稱"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援 ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援代表"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暫停"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的客戶滿意度調查"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有未儲存的變更"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新並繼續調查"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果無法不能正確看到此電子郵件地址，", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我對獲得的支援服務不滿意，因為..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Zendesk QA 提供支援"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取原因"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話摘要"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：客戶在更新付費方式時遭遇問題。代理提供了一篇可能有所幫助的文章，而且奏效了。"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Zendesk QA 人工智慧提供支援"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務條款"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感謝提供意見！"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請問此評分的主要原因是？"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請說明您為什麼會給予此評級..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度費力"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低度費力"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下這裡開啟調查"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂問題會顯示在這裡"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您給予「", _interpolate(_list(0)), "」體驗的評分為"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交意見"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["謝謝"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消訂閱"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常不滿意"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常滿意"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請注意，目前的調查狀態和傳遞規則不會包含在調查副本中"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["複製調查「", _interpolate(_list(0)), "」"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若使用電子郵件內嵌程式碼片段，請先在 Zendesk 移除程式碼片段，再暫停調查"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查暫停時，不會收集任何回應。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫停調查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在暫停調查前，請務必先從 Zendesk 移除程式碼片段。"])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除調查"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若使用電子郵件內嵌程式碼片段，請先在 Zendesk 移除程式碼片段，再刪除調查。"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作會永久刪除調查。收到此調查的使用者仍可填寫，且所有與調查相關的資料都會留在 Zendesk QA 中。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除調查「", _interpolate(_named("surveyName")), "」"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["務必在刪除調查之前，先從 Zendesk 移除程式碼片段。"])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新的回覆者會看到您已套用的變更。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈調查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈調查後，請務必複製最新版本的內嵌程式碼片段。"])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若使用電子郵件內嵌程式碼片段，在繼續調查之前，請先將程式碼片段複製到 Zendesk。"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼續傳送調查並收集回應。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新並繼續調查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼續調查後，請務必將內嵌程式碼片段新增至 Zendesk。"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新調查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新調查後，請務必複製最新版本的內嵌程式碼片段。"])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否確定要刪除此調查？此操作不可復原。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除調查？"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的試用已過期，但您可升級為完整帳戶，繼續使用 Zendesk QA。"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的試用已過期，但您可升級為完整帳戶，繼續使用 Zendesk QA。請聯絡您的地區管理員進行設定。"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的試用已結束"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即升級"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費試用已結束。"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要繼續使用完整版的 Zendesk QA，請輸入付款詳情。"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要繼續使用完整版的 Zendesk QA，需請您的帳戶管理員輸入付款詳情。"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理付款"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("authorName")), "」建立於 ", _interpolate(_named("date"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "任務"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您似乎仍在處理此項目。若未儲存變更就離開，則會失去目前為止所新增的所有資訊。"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此任務會每隔週在", _interpolate(_named("weekDay")), "重複進行"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此任務會在每", _interpolate(_named("weekDay")), "重複進行"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 個週期內的所有受審核者"]), _normalize([_interpolate(_named("nr")), " 個週期內的所有受審核者"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 個對話"]), _normalize([_interpolate(_named("n")), " 個對話"]), _normalize([_interpolate(_named("n")), " 個對話"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["共 ", _interpolate(_named("nr")), " 位代理"]), _normalize(["共 ", _interpolate(_named("nr")), " 位代理"]), _normalize(["共 ", _interpolate(_named("nr")), " 位代理"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["參與者"]), _normalize(["參與者"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 每週期對話數"]), _normalize([_interpolate(_named("n")), " 每週期對話數"]), _normalize([_interpolate(_named("n")), " 每週期對話數"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " 連續每位受審核者"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["受審核者"]), _normalize(["受審核者"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["審核者"]), _normalize(["審核者"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區使用者"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除代理外的所有工作區使用者"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["週期 ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非經常性"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "任務"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核中"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解任務"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立任務"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作會永久刪除此任務，並從儀表板移除其資料。所有相關審核會留在 Zendesk QA 中。"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除任務「", _interpolate(_named("assignmentName")), "」"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除任務"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務已複製"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未新增任務"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立任務失敗"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務名稱"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週期已重新整理，新任務清單已建立。已提出的審核仍會計入目標。"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "於下個週期開始時"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何時發佈變更？"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對此任務所做的變更，會與此任務一同發佈"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否要將任務設為作用中？"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重複於任務開始當天日"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月的第一個週五"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週五"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週五"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週五"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最後一個週五"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一個週一"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週一"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週一"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週一"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最後一個週一"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一個週六"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週六"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週六"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週六"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最後一個週六"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一個週日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最後一個週日"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一個週四"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週四"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週四"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週四"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最後一個週四"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一個週二"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週二"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週二"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週二"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每個月最後一個週二"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一個週三"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二個週三"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三個週三"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四個週三"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每個月最後一個週三"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂週期"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月 1 日"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第三"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第四"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重複"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平日"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結束"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無結果"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時區"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週期設定值"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務名稱"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本資訊"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在每個任務週期開始時，會產生一個預先決定好的對話清單以供評估。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進階設定值"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未達到審核目標時，即自動擷取新對話"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法自動擷取百分比（%）審核目標"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須為整數，而非小數"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許取代對話（審核者與更高階角色）"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更換對話"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許為審核者分配到自己要進行審核的對話"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我審核"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["項審核"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小審核數必須至少為 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定每位受審核者的最小審核數"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["份對話"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百分比（%）"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["項審核"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["項審核（合計）"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每位審核者必須完成所設數量的審核"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者必須共同審核所設數量的對話"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小審核數必須至少為 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核目標必須在 1 至 100% 之間"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核目標"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每位受審核者合計的最小受審核對話數量"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者可在共用集區中選擇對話"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會從共用集區將對話分配給審核者"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務方法"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有 Bot"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區參與者"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區使用者"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增受審核者"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰是受審核的對象？"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取受審核者"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取要審核的人員"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需選取所有工作區使用者或小組，才允許使用排除項目"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者和受審核者"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["目前：", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區預設"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要選取何種類型的使用者作為受審核者？"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最活躍代理"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除受審核者（選填）"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取要排除在外的使用者"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除審核者（選填）"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取要排除在外的使用者"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除代理外的所有工作區使用者"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增審核者"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰會負責審核？"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取審核者"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此工作區不允許自我審核"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許自我審核"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此工作區不允許自我審核。"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["今天符合的對話"]), _normalize(["今天符合的對話"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示為任務如同從今天開始。"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以後的數字可能有所不同。"])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未選取審核者"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未選取審核者"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["受審核者"]), _normalize(["受審核者"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["審核者"]), _normalize(["審核者"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動擷取：對話不足時允許"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週五（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週一（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週六（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週日（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週四（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週二（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "的週三（始自 ", _interpolate(_named("startDateTime")), "）"])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " 起每天"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["開始於 ", _interpolate(_named("startDateTime")), "，結束於 ", _interpolate(_named("endDateTime")), "。"])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 個日期條件"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 個對話與服務台條件"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["無對話，有 ", _interpolate(_named("count")), " 個服務台條件"]), _normalize(["無對話，有 ", _interpolate(_named("count")), " 個服務台條件"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 個對話，無服務台條件"]), _normalize([_interpolate(_named("count")), " 個對話，無服務台條件"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["方法：由審核者選擇對話"]), _normalize(["方法：審核者會分配到對話"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正摘要中醒目顯示的錯誤即可繼續"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標：審核者必須審核所有對話"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目標：審核者必須審核一個對話"]), _normalize(["目標：審核者必須審核 ", _interpolate(_named("count")), " 個對話"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：審核者必須審核 ", _interpolate(_named("count")), "% 對話"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標：未設定審核目標"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標：每位受審核者所有的對話必須都審核完畢"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：每個受審核者的所有對話中，必須有 ", _interpolate(_named("count")), " 個經過審核"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：每個受審核者的所有對話中，必須有 ", _interpolate(_named("count")), "% 經過審核"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目標：每位審核者必須審核一次"]), _normalize(["目標：每位審核者必須審核 ", _interpolate(_named("count")), " 次"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["更換：審核者無法更換對話"]), _normalize(["取代：審核者可更換對話"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我審核：可分配審核者自己的對話"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未設定日期條件"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未設定工作任務名稱"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摘要"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " ", _interpolate(_named("dateTime")), " 起"])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每兩週"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永不"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週期長度"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定為作用中"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存為草稿"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作任務已儲存為草稿"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務已設為非作用中"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設為非作用中"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立任務"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已建立任務"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為作用中"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為非作用中"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["任務尚待處理的更新會在下個週期生效，開始於 ", _interpolate(_list(0)), "。"])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈變更"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非作用中"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解決"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新任務"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新任務"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理或 Bot 有否為對話作結。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理結束對話時是否禮貌"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析對話是否已收尾"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結尾"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理與 Bot 在互動中的語法、拼寫與風格錯誤。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理在文法、錯別字和風格上的錯誤"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理在文法、錯別字和風格上的錯誤"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯別字和文法"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理和 Bot 在對話中使用的問候。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整個對話，找出典型的問候短句"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整個對話，找出典型的問候"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問候"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理是否理解並認同客戶的感受"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辨識代理何時反映出客戶情緒，或使用諸如「理解」和「同意」等關懷的用語"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理是否理解客戶的詢問或疑慮"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理是否理解客戶的詢問或疑慮"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析文字的理解難易程度，包含用字複雜度和句子長度"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析文字的理解難易程度，包含用字複雜度和句子長度"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此訊息包含 1 個過長的句子，因此不易閱讀"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["此訊息包含 ", _interpolate(_list(0)), " 個過長的句子，因此不易閱讀"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通順度"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整個對話，找出所提供的解決方案"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整個對話，找出所提供的解決方案"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提供解決方案"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理在整個對話中的語氣"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析代理在整個對話中的語氣"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語氣"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法從代理訊息中偵測到任何錯誤"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法判斷文法錯誤"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偵測到問候片語。如有需要，可透過您的管理員在設定值中新增。"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理禮貌地結束對話"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偵測到結尾短句。如有需要，可透過您的管理員在「設定值」中新增"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理反映了客戶的情緒，或使用了關懷的用語"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法偵測代理是否代理反映了客戶的情緒，或使用了關懷的用語"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理使用的句子較長、用字複雜，對某些人來說可能難以理解"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理的用字複雜，對某些人來說可能難以理解"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理使用的句子較長，對某些人來說可能難以理解"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理的用語易於理解"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料不足，無法評價此類別"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理難以掌握客戶的問題/請求"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理相當理解客戶的問題/請求"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法偵測代理是否理解客戶的問題/請求"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理在對話中的語氣"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理在對話中的語氣"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理在對話中的語氣"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理在對話中的語氣"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理在對話中的語氣"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法確定代理的語氣"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話未結束"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話已結束"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法偵測對話是否已結束"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理對客戶及其問題缺乏同理心"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理對客戶及其遇到的問題表現出同理心"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法偵測代理是否對客戶及其問題表現出同理心 "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話期間未向客戶打招呼"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在對話期間向客戶打招呼"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法偵測一般問候"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理未向客戶提供解決方案"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理已向客戶提供解決方案"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無法偵測代理是否向客戶提供瞭解決方案"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複雜詞彙"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長句"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文法"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯別字"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["道歉"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冷靜"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快樂"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好奇"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專業"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支援"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["風格"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 種結尾"]), _normalize([_interpolate(_named("n")), " 種結尾"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 僅會檢查經過批准的結尾。當此類別設為重大時，如果未使用經過批准的結尾，將會無法通過審核。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的結尾"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的結尾"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 項免除"]), _normalize([_interpolate(_named("n")), " 項免除"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 視為錯別字或文法錯誤但忽略的字詞和句型。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["錯別字和文法免除項目"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 免除項目"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 種問候"]), _normalize([_interpolate(_named("n")), " 種問候"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 僅會檢查經過批准的問候。當此類別設為重大時，如果未使用經過批准的問候，將會無法通過審核。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的問候"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經過批准的問候"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA 類別"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取自動 QA 類別"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動分配根本原因："])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「帳戶」→「設定值」"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["某些類別會運用 MS Azure，提供更多功能與支援語言。前往", _interpolate(_named("link")), "確認詳情，即可瞭解更多資訊，也可以聯絡管理員。"])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["運用 MS Azure 可存取更多類別和支援語言。前往", _interpolate(_named("link")), "確認詳情，也可以聯絡管理員。"])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用自動 QA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動 QA 類別會使用固定的評級量表自動分配評分。可稍後手動調整評級。", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取自動 QA 類別"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["根據錯誤的嚴重程度及數量，自動分配的評分。", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅會向審核者顯示相關評級類別。評級類別將根據類別建立時所設的條件顯示。"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交談"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新手導引"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請選取工作區「", _interpolate(_named("workspace")), "」的評級類別類型。"])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者必須手動選取正確的記分卡，才能檢視每個對話的相關評級類別。"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否確定要變更評級類別的類型？"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更類別"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更評級類別的類型？"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件式評級類別"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["靜態評級類別"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別類型"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別設定值"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可復原。"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除所有評級"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者也可以將類別封存，如此會保留統計資料，但不會刪除任何資料。"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我瞭解這會對此類別", _interpolate(_named("deleteAllRatings")), "。"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：語氣十分適合"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別顯示條件"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會忽略「始終」。不可與其他條件合併。"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始終"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取條件"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滿意度評分（客戶滿意度）"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源類型"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話管道"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別需要名稱"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增類別至記分卡"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立類別"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立類別"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增評級類別"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存的類別不會在記分卡上顯示，但可透過此功能保留統計資料，而無需刪除任何資料。"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存類別"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["封存「", _interpolate(_list(0)), "」類別"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別已封存"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除類別"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入「刪除類別」即可刪除此類別"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除類別"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["刪除「", _interpolate(_list(0)), "」類別"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除評級類別"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別已複製"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增評級類別小組"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除評級類別小組"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已變更評級類別小組順序"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新評級類別小組"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級類別已取消封存"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新評級類別"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果代理未通過某個重大類別，該次審核的所有類別都會自動視為不及格。"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大評級類別"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此小組中無類別"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立新評級類別"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯類別"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除此小組下的所有類別並審核資料"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除類別小組後，所有與其連結的類別將變為未分組。"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除「", _interpolate(_named("categoryName")), "」類別小組"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若刪除此小組下的類別，會導致失去與這些類別關聯的所有審核資料。"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組名稱"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者可以選取超過一個的原因作為根本原因"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者可以選取超過一個的原因作為根本原因"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多個原因"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：語氣"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增新的類別小組"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新小組"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增「其他」"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增根本原因"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此根本原因已存在"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增根本原因"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增解釋評級的根本原因"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評級量表"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增記分卡至此評級類別"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消封存"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新類別"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有評級"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示用於"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面評級"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["負面和中性評級"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是，更新"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["權重"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對您而言較重要的類別，可在工單評分計算時使用更高的權重。"])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["權重（", _interpolate(_list(0)), "）"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解校準"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解校準"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進階"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期舉行校準階段，能讓審核者的評級方式保持一致，並確保代理收到相同標準且一致的中肯意見。"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視計劃"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」流程可針對所有客戶互動的處理和評估方式，幫助團隊處處保持一致。"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專業"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["校準可用於「", _interpolate(_list(0)), "」和「", _interpolate(_list(1)), "」計劃。"])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將對話新增至階段時，不會顯示先前給予的審核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不顯示"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["之前的審核將視為校準審核。每個審核者僅能將一條對話審核新增至階段。不顯示訊息的特定審核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先前提出審核的可見度"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「校準」流程可針對所有客戶互動的處理和評估方式，幫助團隊處處保持一致。", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者在校準階段可不受限制地存取所有審核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一律檢視所有審核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主管"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核後，使用者會在校準階段期間看到其他審核者的評估內容。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核後可檢視所有校準審核"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者在校準階段可不受限制地存取所有審核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一律檢視所有審核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經理"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核後，使用者會在校準階段期間看到其他審核者的評估內容。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核後可檢視所有校準審核"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核後，使用者會在校準階段期間看到其他審核者的評估內容。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交審核後可檢視所有校準審核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者在校準階段期間只能看到自己的審核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅檢視自己的審核"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新校準設定值"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準審核的可見度"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根據您的團隊內部量化分析系統，以填補空缺的方式來設定儀表板量化分析的閾值。審核儀表板上的閾值。"])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校準"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題標籤"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成員"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閾值"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新閾值"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請稍候..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請確定您擁有管理員權限，然後", _interpolate(_named("retry")), "，或", _interpolate(_named("contact")), "尋求進一步幫助。"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們似乎無法存取您服務台的 API。"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請再試一次"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在連接服務台連線。會立即將您重新導向。"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」已授權"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["請返回「", _interpolate(_list(0)), "」並新增服務台連線。"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您似乎錯過了一兩個步驟。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存在具有相同電子郵件網域的組織"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線頁面"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與此同時..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請稍候，我們正在檢查您的權限。"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與此同時..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合中"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立工作區"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若要繼續使用 Zendesk QA，請", _interpolate(_named("link")), "。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幾乎準備就緒"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工作區"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換帳戶"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若要繼續使用 Zendesk QA，需請您的經理將您分配到一個工作區。"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若刪除帳戶，即會永久刪除您的所有相關資料 - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有給予的評級、對話內容、工作區等。"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增到 Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中斷連線"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將整合中斷連線，即會停用所有來自 Slack 的 Zendesk QA 通知"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中斷 Slack 連線"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 整合已與 Zendesk QA 中斷連線。"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為所有新帳戶使用者訂定預設通知設定值。"])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆蓋所有使用者的設定值"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您即將覆寫所有帳戶使用者的通知設定值"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者會無法定義其自己的通知設定值。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新通知設定值"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大類別不及格時發出提醒"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在收到客戶滿意度回應時發出提醒"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["觸發程式"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立示範工作區與資料"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新建立示範資料，保留示範工作區。"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除示範工作區和資料"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新整理示範資料"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新整理資料"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["管理帳戶「", _interpolate(_named("accountName")), "」示範模式"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["示範模式"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留在頁面"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離開頁面"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否要離開此頁面，並放棄所有變更？"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已完成任務"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立任務"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務已完成"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到任務"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["當前可供審核的任務。"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請調整任務，或等待下個週期開始。"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請嘗試調整篩選"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯任務"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請留意此頁面上的最新消息"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此週期沒有任務"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的對話"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不適用"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將任務標記為已完成"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已審核此使用者。"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此任務中沒有自我審核"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下對話視圖標題中的校準圖示，即可新增。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增對話"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立任務"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立校準階段"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的任務"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他任務"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無作用中的任務"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有任務"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有受審核者"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除我以外的所有受審核者"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到受審核者"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋受審核者"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有狀態"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待辦事項"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有審核者"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除我外的所有審核者"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已分配給我"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到審核者"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋審核者"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小時"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小時"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小時"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小時"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小時"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小時"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分鐘"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分鐘"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分鐘"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分鐘"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分鐘"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分鐘"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無狀態"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區使用者"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["當前審核者"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此週期的新目標"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新審核者"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取審核者"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配對話"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配對話"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要移動的對話"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前週期"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯任務"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到審核者"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋審核者"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配對話給我"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將對話重新分配給其他使用者"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概述"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 天"]), _normalize([_interpolate(_named("n")), " 天"]), _normalize([_interpolate(_named("n")), " 天"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 小時"]), _normalize([_interpolate(_named("n")), " 小時"]), _normalize([_interpolate(_named("n")), " 小時"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏完成的週期"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏完成的階段"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的目標"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增原因以移除此對話"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請說明您為何要移除此對話"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除對話"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否要保留變更？"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增一個原因以更換此對話"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捨棄變更"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留變更"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到更換項目"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請說明您為何要更換此對話"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否仍要從任務中移除此對話？這會使目標減少 1。"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更換"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更換對話"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除草稿"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯草稿"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配給我"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配給其他使用者"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配並審核"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除對話"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出審核"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視較少"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視更多"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " 審核完畢"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核完成"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示完成的週期"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示完成的階段"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩餘時間"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更換對話"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換任務清單"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 後"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " 秒"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " 秒"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天 ", _interpolate(_named("hours")), " 小時"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天 ", _interpolate(_named("hours")), " 小時 ", _interpolate(_named("minutes")), " 分"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天 ", _interpolate(_named("minutes")), " 分"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小時"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小時 ", _interpolate(_named("minutes")), " 分"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小時 ", _interpolate(_named("minutes")), " 分 ", _interpolate(_named("seconds")), " 秒"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小時 ", _interpolate(_named("seconds")), " 秒"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " 分"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " 分 ", _interpolate(_named("seconds")), " 秒"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " 個月"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " 秒"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " 年"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剛才"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您缺少權限。聯絡您的管理員以取得協助。"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尋找的頁面不存在。"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發生意外錯誤。聯絡支援服務以取得幫助。"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒絕存取。不允許執行該操作。"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您正在尋找的頁面不存在或您無權存取。"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的進展有點太快了，我們無法跟上進度。"])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存變更"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有未儲存的變更"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已套用 ", _interpolate(_named("n")), " 項"]), _normalize(["已套用 ", _interpolate(_named("n")), " 項"]), _normalize(["已套用 ", _interpolate(_named("n")), " 項"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已連線"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立時間"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立者"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["前成員"]), _normalize(["前成員"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在這裡"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已選取 ", _interpolate(_named("n")), " 項"]), _normalize(["已選取 ", _interpolate(_named("n")), " 項"]), _normalize(["已選取 ", _interpolate(_named("n")), " 項"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後更新時間"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...還有 1 項"]), _normalize(["...還有 ", _interpolate(_named("n")), " 項"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["還有 1 項"]), _normalize(["還有 ", _interpolate(_named("n")), " 項"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 則回覆"]), _normalize([_interpolate(_named("n")), " 則回覆"]), _normalize([_interpolate(_named("n")), " 則回覆"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週五"]), _normalize(["週五"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週一"]), _normalize(["週一"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週六"]), _normalize(["週六"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週日"]), _normalize(["週日"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週四"]), _normalize(["週四"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週二"]), _normalize(["週二"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["週三"]), _normalize(["週三"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["管理員"]), _normalize(["管理員"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["帳戶經理"]), _normalize(["帳戶經理"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["使用者"]), _normalize(["使用者"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動 QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已複製"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解更多"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的階段已過期。"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位不能為空。"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到項目。"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有符合的人員。"])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["負責人"]), _normalize(["負責人"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解更多"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解更多"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解更多。"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始之前，請閱讀並同意我們的條款，然後便能開始。"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已傳送"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否確定？"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整體審核完成"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["代理"]), _normalize(["代理"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["主管"]), _normalize(["主管"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["經理"]), _normalize(["經理"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["審核者"]), _normalize(["審核者"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匿名使用者"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受託人"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶費力度評分"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍後"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼續"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下以複製"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製連結"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶滿意度"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["危險區域"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒絕"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述（選填）"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需確認"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停用"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捨棄未儲存變更？"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啟用"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯出"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻率"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["明白了"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永不"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格率"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人設定值"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人檔案"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閱讀較少"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新傳送"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["還原"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接收/已檢視審核"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區成員完成的審核"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存變更"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二進制量表"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 分制量表"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 分制量表"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 分制量表"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取頻率"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定值"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示較少"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示較多"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳過"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["焦點"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步驟"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查意見"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間段"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有未儲存的變更"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["權重"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」邀請您加入 Zendesk QA。"])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["使用者將從管理中心自動連線。", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理使用者"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解使用者管理"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["部分使用者是依您的身份識別提供者而自動佈建和同步。", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的帳戶權限為已變更"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解使用者權限"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不再詢問"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在使用者頁面上所做的一切變更，都會反映到工作區。", _interpolate(_named("help2")), "。", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這代表使用者對 Zendesk QA 某些部分的存取權限可能變更"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更帳戶層級權限"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已移除 ", _interpolate(_named("n")), " 位使用者"]), _normalize(["已移除 ", _interpolate(_named("n")), " 位使用者"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您移除使用者時，對方會失去對您帳戶的存取權限。其審核、評論和評級會留在 Zendesk QA 中。", _interpolate(_named("linebreak")), "移除使用者不會影響所訂閱的席位數。"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["將從您管理的所有工作區中移除此使用者。", _interpolate(_named("linebreak")), "其審核、評論和評級會保持不變。"]), _normalize(["會從您管理的所有工作區中移除。", _interpolate(_named("linebreak")), "其所有的審核、評論和評級會保持不變。"])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["移除「", _interpolate(_named("name")), "」"]), _normalize(["移除 ", _interpolate(_named("name")), " 位使用者"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可檢視所有內容，並管理所有設定值與帳單結算"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可檢視所有內容，並管理個人和工作區的設定值，但帳單結算功能除外"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶權限"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過工作區專屬的權限，使用者得以檢視和編輯所屬工作區的資料。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解 BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 Bot 會顯示在這裡，是因為 Bot 所參與的對話已同步處理"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有已同步的 Bot"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將 Bot 標記為使用者將授予其登入的權限。其歷史 Bot 資料仍會出現在儀表板上。"])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到此類 Bot"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請使用不同的關鍵字或拼寫再次搜尋。"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋 Bot"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按 Bot 名稱搜尋"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為使用者"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有管理員和帳戶管理者可以將 Bot 標記為使用者"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot 名稱"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後交談"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動標記"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可審核"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已從自動分析和所有任務中移除該 Bot。新資料不會顯示在儀表板上，如此能確保報告準確無誤，且能更輕鬆地管理 Bot。"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成式 Bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作流程 Bot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至小組"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有小組"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有小組"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從小組移除"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取小組"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶權限"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至工作區"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全名"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後作用中時間"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶層級角色"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每個使用者都會分配到一個帳戶及/或工作區級別的權限。管理員權限現為帳戶層級，其他權限為工作區層級。"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從工作區移除"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區權限"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組管理權限"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯詳情"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為 Bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若將使用者標記為 Bot，會移除該使用者的登入權限。其歷史使用者資料仍會出現在儀表板上。"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從自動分析和所有任務中移除 Bot。新資料不會顯示在儀表板上，如此能確保報告準確無誤，且能更輕鬆地管理 Bot。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從評論排除 Bot"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者已標記為 Bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅有「使用者」權限類型可標記為 Bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能將擁有多個帳戶的使用者標記為 Bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按使用者搜尋"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者、Bot 和工作區"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者與工作區"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理員"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無權限"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已連線"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已手動新增"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待處理"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待處理的邀請"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 使用者"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需採取行動"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...還有一項"]), _normalize(["...還有 ", _interpolate(_named("n")), " 項"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更權限為"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["移除並重新邀請對方，以變更 ", _interpolate(_named("n")), " 位待邀請使用者的角色。"]), _normalize(["移除並重新邀請對方，以變更 ", _interpolate(_named("n")), " 位待邀請使用者的角色。"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["編輯「", _interpolate(_named("name")), "」的詳情"]), _normalize(["為所選取的 ", _interpolate(_named("n")), " 位使用者編輯詳情"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已更新 ", _interpolate(_named("n")), " 位使用者。"]), _normalize(["已更新 ", _interpolate(_named("n")), " 位使用者。"])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["此小組有 1 位使用者來自另一個工作區。"]), _normalize(["此小組有 ", _interpolate(_named("n")), " 位使用者來自另一個工作區。"])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啟用"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已封存"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存小組"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立小組"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已建立"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未命名小組"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久刪除此小組？"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除小組"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除小組"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯小組"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組主管"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組角色"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已隱藏"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此小組不會顯示在 Zendesk QA 的任何其他地方"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅管理工作區權限"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不會在 Zendesk QA 中顯示小組"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主管"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成員"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未命名小組"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另一個小組已使用此名稱"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存的小組已使用此名稱"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的角色僅允許管理此小組的成員。若要進一步變更，請聯絡更高權限的使用者。"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消擔任小組主管"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已還原"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已儲存"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設為擔任小組主管"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已解除封存"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未儲存變更"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將您的現有使用者新增到此小組"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按名稱或電子郵件地址搜尋"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增成員"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」小組已顯示"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每個成員是", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解小組"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組的某些屬性為自動佈建，且是從身份識別提供者處同步而來。"])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞭解小組"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立小組"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輕鬆整理團隊成員，還可模擬組織架構。"])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至少 1 位成員"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增小組名稱"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用此名稱的小組已存在"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組成員"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組名稱"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可整理團隊成員，並且模擬組織架構。"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成員"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["無小組"]), _normalize(["有 1 個小組"]), _normalize(["有 ", _interpolate(_named("count")), " 個小組"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請建立新小組或啟用已封存的小組。"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可用的作用中小組"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有已封存的小組"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到此類小組"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請使用不同的關鍵字或拼寫再次搜尋。"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未新增小組"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["屬於"]), _normalize(["屬於"]), _normalize(["屬於"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按小組名稱搜尋"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["可重新傳送的時間：", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["還剩 ", _interpolate(_named("n")), " 個席位"]), _normalize(["還剩 ", _interpolate(_named("n")), " 個席位"]), _normalize(["還剩 ", _interpolate(_named("n")), " 個席位"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由於訂閱已暫停，目前席位數量有限。重新啟即可新增更多使用者。"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已達到帳戶席位的上限。若要取代使用者，可停用作用中的使用者，或是新增更多席位。"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視付款詳情"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已選取 ", _interpolate(_named("n")), " 位"]), _normalize(["已選取 ", _interpolate(_named("n")), " 位"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已選取 ", _interpolate(_named("n")), " 位使用者"]), _normalize(["已選取 ", _interpolate(_named("n")), " 位使用者"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 列"]), _normalize([_interpolate(_named("n")), " 列"]), _normalize([_interpolate(_named("n")), " 列"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按名稱或電子郵件地址，大量搜尋使用者。"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在這裡貼上資料，並以半形逗號或新行符號區隔"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大量搜尋使用者"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["編輯「", _interpolate(_named("name")), "」工作區權限"]), _normalize(["為所選取的 ", _interpolate(_named("n")), " 位成員編輯工作區權限"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯權限"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取工作區權限"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["從工作區移除「", _interpolate(_named("name")), "」"]), _normalize(["從工作區移除 ", _interpolate(_named("name")), " 位成員"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已移除 ", _interpolate(_named("n")), " 位成員"]), _normalize(["已移除 ", _interpolate(_named("n")), " 位成員"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["移除成員"]), _normalize(["移除成員"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["這位使用者會從工作區移除。"]), _normalize(["這些使用者會從工作區移除。"])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的工作區權限為已變更。"])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增成員"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視對話和相關審核"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在工作區中檢視所有內容，但僅限管理測驗、任務、異議和校準階段"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區權限"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在工作區中檢視和管理所有內容"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視工作區中的所有內容，工作區設定值除外"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結使用者"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您管理的工作區中的所有使用者均已新增至此工作區。"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請洽管理員處理。"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可直接邀請新使用者，或請管理員處理。"])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), "或", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可顯示的使用者"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" 已新增 ", _interpolate(_named("n")), " 位使用者"]), _normalize(["已新增 ", _interpolate(_named("n")), " 位使用者"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["新增成員"]), _normalize(["新增成員"]), _normalize(["新增 ", _interpolate(_named("n")), " 位成員"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取工作區權限"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取使用者至工作區"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["新增成員到「", _interpolate(_list(0)), "」"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在這裡貼上電子郵件地址，以新行或半形逗號區隔"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " 封電子郵件符合條件"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 連結的使用者數量："])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["無法配對 ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " 封電子郵件"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢查錯別字或稍後個別連線"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 服務台使用者數量："])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已連結 ", _interpolate(_named("n")), " 位使用者"]), _normalize(["已連結 ", _interpolate(_named("n")), " 位使用者"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已邀請 ", _interpolate(_named("n")), " 位使用者"]), _normalize(["已邀請 ", _interpolate(_named("n")), " 位使用者"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大量連線"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大量邀請"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：\n\nkate", "@", "example.com、john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從服務台連線以審核其互動"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["連結使用者"]), _normalize(["連結使用者"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個別連線"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若想讓已連結的使用者存取 Zendesk QA，可在這裡邀請對方。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請已連結的使用者"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["邀請使用者"]), _normalize(["邀請使用者"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳過邀請"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取要從服務台連線的使用者，以審核其互動"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理訂閱"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線服務台使用者"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為您的工作區取一個專屬名稱"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若啟用，所有現有帳戶連線都會自動新增至新的工作區。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增所有連線至此工作區"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立工作區"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區名稱"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立工作區"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接新增至帳戶（無需分配工作區）的使用者，都會分配到帳戶層級的角色。稍後可將他們新增至工作區。"])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲邀的使用者會自動分配到一個帳戶層級的使用者角色。稍後可升級為管理員角色。"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均可使用"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在這裡貼上電子郵件地址，以新行或半形逗號區隔。"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已透過電子郵件邀請 ", _interpolate(_named("n")), " 位使用者"]), _normalize(["已透過電子郵件邀請 ", _interpolate(_named("n")), " 位使用者"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至工作區的使用者會自動分配到一個帳戶層級的使用者角色。稍後可升級為管理員角色。"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增另一個"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅邀請使用者至帳戶，而不將其新增至工作區"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲邀的使用者僅會分配到一個帳戶權限，且並未新增至任何工作區。"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接邀請使用者至工作區，並分配工作區權限"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增使用者至工作區"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大量邀請"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個別邀請"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅邀請至此帳戶"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請加入工作區"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["邀請使用者"]), _normalize(["邀請使用者"]), _normalize(["邀請 ", _interpolate(_named("n")), " 位使用者"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過電子郵件大量邀請使用者"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["刪除使用者"]), _normalize(["刪除使用者"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消選取"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過電子郵件邀請使用者"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無工作區"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取權限"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新傳送邀請"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已連結的使用者"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待處理的電子郵件邀請使用者"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 使用者"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取工作區"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智慧代理"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理中心"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品質保證"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人力管理"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " 已完成"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " 已完成"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " 已完成"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["週期 ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核者"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯任務"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定任務自動審核功能，省下更多時間，不用手動尋找和分配待審核的對話。"])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " 到期"])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在對話視圖側邊功能表的「校準」區塊新增階段。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未新增階段"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基準"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無階段"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按一下對話視圖標題中的校準圖示，即可在階段中新增對話。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未新增對話"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["尚未提出審核。", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出校準審核"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳至"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入命令選項板失敗。"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到任何內容。"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依 ID 找到"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["載入命令選項板中..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頁面、篩選、對話、設定值..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對話"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人篩選"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開篩選"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["來自「", _interpolate(_list(0)), "」"])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來自您的 API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["顯示", _interpolate(_named("label")), "的 ", _interpolate(_named("rangeStart")), " 到 ", _interpolate(_named("rangeEnd")), " 項（共 ", _interpolate(_named("total")), " 項）"]), _normalize(["顯示", _interpolate(_named("label")), "的 ", _interpolate(_named("rangeStart")), " 到 ", _interpolate(_named("rangeEnd")), " 項（共 ", _interpolate(_named("total")), " 項）"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱藏協作面板"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示協作面板"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換側欄"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受異議"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒絕異議"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟異議"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受異議"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核完成"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的審核"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檢視過的審核"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["總數中的 ", _interpolate(_list(0))])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒絕"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒絕"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["審核"]), _normalize(["審核"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受審核者所檢視過的審核數"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受檢視過的審核數"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出異議的審核者"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出異議者"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各類別異議"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議時間趨勢"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解決時間"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解決時間（小時）"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解決時間（分鐘）"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解決"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議總數"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取主題標籤"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應語言"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到語言"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有語言"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預測驅動因素"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到驅動因素"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有驅動因素"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字雲內文"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到詞彙"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有詞彙"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提及"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最舊"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」使用 ", _interpolate(_list(1)), " 回應"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒絕"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解決"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取膚色"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["階段"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到階段"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到類別"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評論長度"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到長度"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有長度"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到篩選"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小組"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到小組"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有小組"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題標籤"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到主題標籤"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有主題標籤"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到值"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有值"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到自訂欄位"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務台標籤"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到服務台標籤"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有服務台標籤"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果載入中..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題類型"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到問題類型"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增回應"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記分卡"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到記分卡"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有記分卡"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到評分"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有評分"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來源"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到來源"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有來源"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見原因"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到原因"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有原因"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調查"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到調查"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有調查"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管道"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到管道"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有管道"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到使用者"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有使用者"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工作區"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作區"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回測驗"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗已刪除或尚未發佈。請再次確認連結，或返回測驗清單。"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此測驗尚未在您所屬的工作區共用。請找管理員將您新增到此測驗具有存取權限的工作區。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗無法使用"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已針對並非您所屬的工作區，將存取權限授予測驗。無法儲存和發佈測驗。"])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請要求管理員儲存並發佈測驗。"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需標記出一個正確答案。"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位不能為空。"])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您目前看到的是測驗預覽。"])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選取具有測驗存取權限的工作區。若使用者屬於多個工作區，也只能回答測驗一次。選取「所有工作區」，並不會包括測驗發佈後所新建的工作區。"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗存取權限"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結束預覽模式"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯出"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參加測驗"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈測驗"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗設定值"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存為草稿"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立新測驗"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消封存"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選項"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增選項"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看起來很不錯吧？"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提交您的答覆"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已儲存您的測驗"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已發佈您的測驗"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除您的測驗"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增問題"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存測驗"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存的測驗和其測驗結果，不會繼續顯示於測驗清單中。您仍然可存取已封存測驗的結果，需要時也可取消封存。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["封存測驗"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈後的測驗不可變更或編輯。您帳戶中的每個使用者都可以存取已發佈的所有測驗。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈測驗？"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否確定要刪除此測驗？此操作不可復原。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除測驗？"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一旦提交，就不能變更答案。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交測驗？"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消封存測驗"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將測驗取消封存，測驗便會出現在測驗概述中。如此之前的參與者便能存取測驗結果，新的參與者也可以參加測驗。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消封存測驗"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製問題"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述（選填）"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將問題下移"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將問題上移"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預覽中僅會顯示有答案的問題。"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除問題"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交測驗"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無標題測驗"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗標題"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這會永久刪除該測驗及其結果。"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者也可以封存測驗，如此仍可存取結果。"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已回覆"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除測驗"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這會永久刪除該測驗及其結果。"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["刪除「", _interpolate(_list(0)), "」測驗"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗有助於提升團隊自信心、強化技能、提供一致的體驗，並且帶領新的團隊成員熟悉工作內容。"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可依據測驗名稱或作者姓名來搜尋測驗。"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未建立任何測驗"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯出測驗清單"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均分數"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成日期"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗/作者"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗 / 作者 / 工作區"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈日期"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作區"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依據測驗或作者來搜尋"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已封存"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作用中"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有測驗"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗已封存。"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已將測驗取消封存。"])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此測驗未收到任何所選取工作區使用者的回應。"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此測驗在封存前未收到任何回應。"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無回應"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在這裡看到測驗答覆的細項。\n您的測驗尚未有人作答，不妨將測驗分享出去。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚無回應"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應："])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均分數："])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概述"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("quizName")), "」：複製"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已複製連結"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到結果"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」的測驗"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在排行榜搜尋參與者的姓名。"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯出排行榜"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按參與者搜尋"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排行榜"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["測驗"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換排行榜"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此主題標籤將不能在儀表板上篩選，也不會繼續在評論中自動完成。此操作不可還原。"])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題標籤, 主題標籤, 主題標籤..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立主題標籤"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立主題標籤"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立主題標籤"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許成員建立新主題標籤"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題標籤"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除主題標籤"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["刪除主題標籤「", _interpolate(_list(0)), "」"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題標籤建立功能已鎖定"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已將主題標籤建立功能解鎖"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["主題標籤「", _interpolate(_list(0)), "」已刪除"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["主題標籤「", _interpolate(_list(0)), "」已重新命名為「", _interpolate(_list(1)), "」"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已新增 1 個主題標籤"]), _normalize(["已新增 ", _interpolate(_named("count")), " 個主題標籤"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新名稱"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未建立主題標籤"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新命名"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["重新命名主題標籤「", _interpolate(_named("tagRenamed")), "」"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用半形逗號或分行符號隔開主題標籤"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋主題標籤"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["別擔心，您可以", _interpolate(_named("link_copy")), "進行。"])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就在這裡"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已連結至工作區的連線"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加連線"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加連線到此工作區"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連線"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請聯絡您的管理員，以在這裡進行變更"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請聯絡 Zendesk QA 以在這裡變更"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶下無連線"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您似乎尚未整合客戶服務平台。"])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["若刪除連線，則會從工作區「", _interpolate(_named("workspaceName")), "」移除對話，並刪除所有相關資料，包括審核和異議。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除連線"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["刪除連線「", _interpolate(_named("connectionName")), "」"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取連線"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在選定的時段內，某些作用中的自動 QA 類別未出現在您的記分卡中。若對話早於您在 ", _interpolate(_named("date")), " 最後所做的變更，這些類別可能尚未分析。"])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這裡會顯示所有給您的意見。尚無可顯示的內容。"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到任何審核。"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅顯示評論活動"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這裡會顯示您的所有意見。在「對話」視圖中開始。"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有提出任何審核。"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提出的審核"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到的審核"])}
  }
}