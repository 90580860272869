import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "root",
      class: _normalizeClass(_ctx.$style.root)
    }, null, 2 /* CLASS */),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["bg-gray-90 text-xs font-semibold text-white", _ctx.$style.tooltip])
    }, [
      _renderSlot(_ctx.$slots, "tooltip", {
        hovered: _ctx.data[_ctx.hoveredIdx]
      })
    ], 2 /* CLASS */), [
      [_vShow, _ctx.popper]
    ])
  ]))
}