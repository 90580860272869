import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_selection = _resolveComponent("workspace-selection")!
  const _component_ui_danger_button = _resolveComponent("ui-danger-button")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "element",
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    (!_ctx.allowLogout)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('extension.footer.workspace')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_workspace_selection, { "custom-url": _ctx.generateUrl }, null, 8 /* PROPS */, ["custom-url"]),
    (_ctx.allowLogout)
      ? (_openBlock(), _createBlock(_component_ui_danger_button, {
          key: 1,
          secondary: "",
          small: "",
          "data-track-id": "extension-logout",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('logout')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('extension.footer.log_out')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}