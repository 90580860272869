export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Zendesk subdomain"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company has an existing account."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a company"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To join an existing company, ask your admin to send you an invite."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask your admin to invite you"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I changed my mind."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel sign-up"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company has an existing account. Do you want to join your company's account or create a new one?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy instructions"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's an existing account"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't access your Zendesk QA account until your outstanding subscription invoices are paid."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription is on hold"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not found. Try again."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["get a new password."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Try again or ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email and password combination."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a new one from your admin."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invite link is invalid or has been used already."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " sign-in method is deactivated in your account."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invite is invalid or used. Request a new one from your admin."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load invite info."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-up failed. User with this email might already exist."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been blocked after multiple consecutive sign-in attempts. Check your inbox for an email with instructions on how to unblock it."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your email first"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email account"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in code"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the magic link"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work email"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your work email"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password does not match requirements"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Notice"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold up"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Join the ", _interpolate(_named("organization")), " organisation"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has been verified. You may now sign in with your new account below."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid invite link"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in using magic link"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to sign in"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has been verified. You may now sign in with your new account below:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in via magic link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been deleted"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid code"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email account"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in code"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all sign-in options"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " via traditional methods"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in using magic link"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've received an email which contains your sign-in code."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've received an email which you can follow to sign in."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error sending the magic link. Try again."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in via email"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create password"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One lowercase character"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One uppercase character"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password must contain"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum password length is 128 characters"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy to remember, hard to guess"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 characters minimum"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your password"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One lower case character"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One number"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One upper case character"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To reset your password, enter your email address and we'll send you an email with instructions."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email account"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An email has been sent to ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the email doesn't appear in your inbox or spam folder, please check the email you entered."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to sign in"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", then you'll be sent a password reset email."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your account has an existing password"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If that ", _interpolate(_named("bold")), ", then we've sent you a password reset link."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email is associated with an account"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remembered your password?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error sending the reset link. Try again."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up using magic link"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By signing up, you agree to Zendesk QA ", _interpolate(_named("terms_of_service")), " and ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have an account?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up via magic link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up with your work email"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up with Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up with Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have an active Zendesk account"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sign up or sign in to complete your ", _interpolate(_named("direct_install_name")), " setup"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it really you? Check your inbox for a confirmation email."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're all set up"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA works best on desktop. Sign in there to start reviewing conversations."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or, book a demo with us and we'll show you what you can do in Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your presence is requested"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I changed my mind and want to ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " invites you to join their account."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the account"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try signing in again"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The reaction you received for your feedback: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received reactions"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reacted to your comment"]), _normalize(["Several people reacted to your comment"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reacted to your reply"]), _normalize(["Several people reacted to your reply"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reacted to your review"]), _normalize(["Several people reacted to your review"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reply"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISPUTES"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIVEN"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEIVED"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey feedback"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactions"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mark comment as read?"]), _normalize(["Mark all comments as read?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mark dispute as read?"]), _normalize(["Mark all disputes as read?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mark survey feedback as read?"]), _normalize(["Mark all survey feedback as read?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mark review as read?"]), _normalize(["Mark all reviews as read?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marked comment as read"]), _normalize(["Marked all comments as read"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marked dispute as read"]), _normalize(["Marked all disputes as read"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marked survey feedback as read"]), _normalize(["Marked all survey feedback as read"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marked review as read"]), _normalize(["Marked all reviews as read"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as read"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When someone disputes a review, it appears here."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disputes"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you are assigned to resolve a dispute, it appears here."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No received disputes"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you dispute a review, it appears here."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No started disputes"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback given to you appears here. Nothing to show just yet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No received feedback"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reactions given to your reviews and replies appear here. Nothing to show just yet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No received reactions"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a conversation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback given by you ends up here. Start by leaving a review in the Conversations view."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No given reviews"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback given to you appears here. Nothing to show just yet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No received reviews"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comments found"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All feedback you leave as comments appear here."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No given comments"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comments found"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All comments under a review you own, ", "@", "mentions, or replies to your comments appear here."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No received comments"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accepted disputes"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open disputes"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No rejected disputes"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resolved disputes"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again by choosing another option from the filter above."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again by choosing another user from the filter above."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given comments"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received comments"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All disputes"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received disputes"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started disputes"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewee"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received survey feedback"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given reviews"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received reviews"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle activity list"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " reviewed"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " reviewed"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " reviewed"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Restarts in ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " left"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up an assignment"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle assignments list"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["It's not possible to create a coaching session between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), " as they are currently not in the same workspace."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Coaching sessions between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), " already exist. Contact ", _interpolate(_list(0)), " to get access to the session."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A new session between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), " has been created."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select coach"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachee"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select coachee"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create session"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a session"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add session"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session name"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create session"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions found"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average review time"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to show for the selected period"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Added pin to session. ", _interpolate(_named("buttonStart")), "Show me", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the end of pins from the selected period"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["See how pins work in this ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Looks like you haven’t pinned anything under ", _interpolate(_list(0)), ". You can use pins for preparing for 1:1’s or saving something for another time."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the person being coached"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pins added yet"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Here are all the pins you have created under ", _interpolate(_named("name")), ". Pins added to sessions will be ", _interpolate(_named("bold")), " with the other participant."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immediately shared"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Looks like ", _interpolate(_list(0)), " hasn't received any feedback for conversation review or CSAT during the selected period."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No feedback available"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve reached the end of comments from the selected period"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "'s numbers"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New action item"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Completed by ", _interpolate(_named("user")), " on ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["by ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to add a new action item"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What came out of this meeting? What are your next steps?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action items"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created by ", _interpolate(_named("name")), " on ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete the session"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This will permanently ", _interpolate(_named("deleteSession")), ". Participants lose access to the session, including its contents and private notes."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Session created on ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last edited by ", _interpolate(_named("name")), " on ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talking points and notes"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note updated"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave session"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected users gain access to session details from this point onwards."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select users"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share session"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You will no longer be a part of ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " session."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " will no longer be a part of this session."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " has access to this session now."]), _normalize([_interpolate(_named("n")), " new users have access to this session now"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete session"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all sessions"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete session"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the coach"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Notes added by ", _interpolate(_list(0)), " will appear here."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add pinned items as discussion points from the right-hand side panel"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes only you can see..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal notes"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private note updated"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 days"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachee"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action items"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last session"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions overview"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me: Coachee"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sessions"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared sessions"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try switching a filter in order to see action items."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No action items"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action items"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews received and seen"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received · Seen"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress after sessions"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems that the agents haven’t received any feedback."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify that you have the correct name of the user"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not find that user"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This will permanently <b>delete all sessions</b> (2) with <b>", _interpolate(_named("name")), "</b>. Participants lose access to all sessions, including the contents and private notes."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the end of all sessions"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A coaching session is a group activity between a mentor and a coachee to discuss performance, opportunities, short and long term goals."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can search sessions by both the coach's name and the person being coached."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions found"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions created yet"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete coaching history"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete coaching history "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last session"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sessions"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachee"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as done"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as undone"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle session list"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unscheduled"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to this message"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired calibration session. No additional reviews can be left."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've already left a review for this conversation."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewing this conversation is no longer possible as the due date of the session has passed."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has no reviews"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close panel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a calibration review"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUMMARY"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSCRIPT"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Speaker ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dead air"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit speaker"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review message"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to playing message"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speakers"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcript"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace users"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add connection first to create filters"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created at ", _interpolate(_named("time")), " by ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last updated on ", _interpolate(_named("time")), " by ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Looks like you have no filters. To access this page, you first need to create a filter. Start with ", _interpolate(_named("recommendedFilter")), " to see your own conversations. Create this filter below to continue."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["over the last 30 days"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Created at last 30 days\" condition will be added to filters created without a date range"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter is not available in this plan and will not be taken into account when displaying results"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching filter options timed out. Try again."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about filtering"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee = signed-in person"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Conversations found: ", _interpolate(_named("count"))]), _normalize(["Conversation found: ", _interpolate(_named("count"))]), _normalize(["Conversations found: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available in your current plan. Upgrade to use this feature."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add condition"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Zendesk QA AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn into assignment"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create filter"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create filter"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom fields"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're loading the filter options according to your specific setup. This may take a couple minutes on initial load."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a descriptive name to your filter"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter name"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit filter"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No values available"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a value"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only active sessions ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show older sessions ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit calibration session"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create session"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create calibration session"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All calibration reviews given during the session will be permanently deleted."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete session"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("sessionName")), " calibration session"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions have been created"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active sessions"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions created"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active sessions"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add filter"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a private filter"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a public filter"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter added"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The filter will be permanently deleted."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete filter"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("filterName")), " filter"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All disputes"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes for me"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes by me"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open disputes"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drafts"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filters added"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No private filters created"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No public filters created"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private filters"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public filters"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wish to ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save it"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a temporary filter's view."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructing your filter..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter updated"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom AutoQA category"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last updated ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex words"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-reviews update dynamically based on ticket changes"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight in conversation"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long sentences"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No auto score predicted"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect any mistakes"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show complex words"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show long sentences"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next conversation"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA couldn’t rate this category due to insufficient context. Manual review recommended."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover automated conversation insights to streamline your review process. Highlighting crucial conversations and showcasing standout positive examples for your team."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Call]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrected"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " accepted the dispute"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dispute sent to ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain why you think this reviewee is the wrong recipient for this feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment and set a new reviewee"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain why you believe these scores are unfairly rated (mandatory)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment (mandatory)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain why you are rejecting this dispute"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send dispute to"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send dispute to"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send dispute to"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new reviewee"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute feedback"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute feedback"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change reviewee"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send dispute to"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute this review"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't agree with the scores"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This review wasn't meant for me"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " partially accepted the dispute"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " rejected the dispute"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially accepted"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " started a dispute: review"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " started a dispute: wrong reviewee"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An open dispute should be resolved in order to take other actions on the review."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new scores"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete dispute"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to discard this dispute?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete dispute"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute resolution time"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new fair scores (optional)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute review"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong reviewee"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept dispute"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept dispute"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute feedback"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong reviewee"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute feedback"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject dispute"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject dispute"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the dispute"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " by ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["to ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All done"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a comment as well"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about conditional scorecards"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formerly assigned to:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just pick one"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't find any GIFs with that name"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be the first to leave a review."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription is currently on pause. Reactivate it to enable giving feedback."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted by:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This shows how much time was spent reviewing this conversation"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Missing root cause for category: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate a category or add a comment"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand comment field"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse comment field"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide formatting"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show formatting"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit review"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate all categories"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate at least one category"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save this conversation for later"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save for later"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next assignment"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace conversation in this assignment cycle"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review conversation"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baseline review"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete comment"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment deleted"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this comment?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment author"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This hashtag has been deleted or renamed."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share what you loved or what could have been better..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditional"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete all comments."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete comment"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Message]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " has not yet seen this feedback"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " has seen this feedback"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former member"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search GIFs..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Enter to create a new hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom hashtags are locked by admin."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this to bookmark cases and filter out later"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as baseline"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message feedback"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next conversation"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviews done"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a review below to get started."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other reviews left"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not rated"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous assignee"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous conversation"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove as baseline"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to this review"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide review sidebar"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the review and all added comments."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete review"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete review"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review deleted"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Review deleted by ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All done!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign this task to yourself in order to leave a review"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've already left a review for this user."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You've left a review for ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No categories to show for this conversation."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews are not available for this workspace."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't save"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save this review as a draft?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard this draft"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show review sidebar"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass with minors"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Still crunching the numbers..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a comment"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review this message"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review yourself"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review this conversation"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users to review"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews disabled"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace users"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select user"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 review"]), _normalize([_interpolate(_named("count")), " reviews"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new ratings"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your workspace manager has not allowed self-reviews."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Enter to tag this pin"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrations"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starred"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a calibration session"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drafts"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights give you a visual representation of your help desk data. Use the interactive charts to understand where to focus your QA efforts, uncover blindspots, and find the right agents to review based on their performance."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Translate to ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently viewing"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewing now"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been removed and is no longer available."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation you are looking for does not exist in Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All pins with this conversation"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In session"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove conversation"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing a conversation from a session will delete all reviews given to it."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove conversation from ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No calibration sessions created yet."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been added to the session"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been removed from the session"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to/remove from a calibration session"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link to this conversation"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversation to display"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer's email"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer's ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer's name"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External form name"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First response time"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey comment"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction score"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time to first resolution"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This message does not exist under this conversation or it has been deleted"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subject"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversation to display"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversation found"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open original conversation"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot link to conversation - missing URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No participants"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin conversation"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to read reviews"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to review"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation created via browser extension"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View customer satisfaction survey"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See conversation"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message with a negative sentiment"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message with a positive sentiment"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate to English"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show original"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete conversation"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deleting a conversation is permanent. It also deletes all related data, e.g. surveys, transcriptions, and reviews from Zendesk QA, including dashboards.", _interpolate(_named("lineBreak")), "You'll still be able to access this conversation in Zendesk Support or another help desk software you’re using."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deleting a conversation is permanent. It also deletes all related data, e.g. surveys, transcriptions, and reviews from Zendesk QA, including dashboards. If the conversation belongs to multiple workspaces, it will be deleted from all of them.", _interpolate(_named("lineBreak")), "You'll still be able to access this conversation in Zendesk Support or another help desk software you're using."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete conversation"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete conversation from Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This conversation is incomplete due to escalation. Go to ", _interpolate(_named("optionsUrl")), " and validate your tags. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This conversation is incomplete due to escalation. Speak to your administrator about validating your tags. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections > Advanced options"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about tags"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solved"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assignee changed to ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " macro applied by ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conversation received a rating ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User ", _interpolate(_named("target")), " redacted the conversation"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status changed to ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tagged with ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team changed to ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " snoozed the conversation for ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " un-snoozed the conversation"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot stuck in a loop, consecutively repeating a message"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot repetition"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot repetition, where the same message is repeated one or more times in succession"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching phrases found"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An average agent handles the conversation ", _interpolate(_named("percent")), " more efficiently than the bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot communication efficiency"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot handled the conversation ", _interpolate(_named("percent")), " more efficiently than an average agent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot communication efficiency"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation lacks the necessary statement informing participants of recording, which is required for compliance and transparency"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording disclosure missing"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A service-level agreement (SLA) has been breached for ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA breached"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric for the SLA rule exceeded it's target time"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signs of customer attrition. Customer was considering a switch or promised to part ways."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Churn risk identified"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversation has moments of dead air lasting longer than set threshold of ", _interpolate(_list(0)), "s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instance"]), _normalize([_interpolate(_named("count")), " instances"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s longest"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dead air"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer sought higher-level assistance"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalation requested"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer expressed extreme gratitude or was very content with the support received"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional service"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Either the customer or agent explicitly requested a follow-up"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up request"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer showed dissatisfaction"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative sentiment"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation was unusual or atypical and there was more back-and-forth to reach resolution"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlier detected"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer showed satisfaction"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive sentiment"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as not applicable"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last edited by ", _interpolate(_named("author")), " on ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not applicable"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By ", _interpolate(_named("author")), " on ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a reason (optional)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No insights detected"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add connection"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your help desk to your account and have at least one filter created to see the list of conversations."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations to display"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View workspace settings"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewee"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(In a day)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(This month)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(This week)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find by ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search results for ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed by others"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commented"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldest"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently updated"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently replied"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed by you"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The import process may take some time. We'll email you when it's complete."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA is importing your conversations"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limit: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by clicking on the calibration icon in the header of the conversation view."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing to report here. Try again using a different filter or sorting option."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No outliers to display."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a relevant conversation and click on the star icon in the header to bookmark it here."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try changing the filters or invite other help desk agents to add more conversations."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No limit"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal note"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're loading conversations right now. Some content might still be missing."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next user"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews received"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace members"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous user"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations added"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations have been added"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfinished reviews end up here. Re-read, revise and submit it in the next 30 days."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No drafted reviews"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No starred conversations"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations to display"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No match"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to selected message"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Least replies"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most replies"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle conversation list"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Call answered at ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Call ended at ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Call placed at ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed by you"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Failed to load audio file. Make sure you're signed in to ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction survey comment"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment author"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No call took place"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen in help desk"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen on Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playback speed"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review call"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcript"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a Zendesk QA User"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatically ranks agents based on their monthly average CSAT. Find conversations handled by agents whose CSAT is lower than their peers in one click."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking by CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a Zendesk QA User"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatically ranks agents into performance groups based on how many conversations they handle per month. Find conversations by agents with a lower volume than their peers in one click."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking by volume"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not reviewed"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review status"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a Zendesk QA User"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatically ranks agents based on the average sentiment in the conversations. Find interactions where agents deal with more frustrated customers than their peers in one click."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking by sentiment"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not detected"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outliers"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations that require your attention. Our machine learning model identifies complex or atypical interactions for further review."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outliers"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select conversations for reviewing where the customer displayed either delight or frustration. Use the drop-down to select the type of sentiment you're looking for in your conversations. Drag the filter attribute to the top to see the ratio of the chosen sentiment in all your interactions."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review status"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not reviewed"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatically detects languages in your conversations. Use the drop-down to select conversations in specific languages for reviewing. Drag the filter attribute to the top to get an overview of all the languages used in your help desk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation Insights"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can ", _interpolate(_named("paragraph_2_item")), " with Insights than with regular conversation filters. Get a visual overview of all the data from your help desks, and explore insights like:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about using conversation insights"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand the relative importance of specific conversation attributes in relation to others"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your top- and low-performing agents with built-in agent performance ranking"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " is a powerful interactive data mining tool helping you find the conversations that most need reviewing."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do so much more"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The Conversation and Agent charts are ", _interpolate(_named("paragraph_3_item_1")), " – updating filters or clicking on the segmented chart slices on either one will automatically update the conversation list. ", _interpolate(_named("paragraph_3_item_2")), " to explore detailed breakdowns or ratios of the different elements."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconnected"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rearrange the filter attributes"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conversation Insights displays only ", _interpolate(_named("paragraph_4_item")), ". We automatically remove interactions without real reviewing value, such as autogenerated messages, spam, bot replies, or messages that have no proper dialogue with your support rep."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clean and closed conversations"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do I see here?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " out of ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " out of ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " out of ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " of ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " N/A"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " of total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But we're sure it will be great once it arrives."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom survey"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No survey feedback left"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No survey feedback score available just yet..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["received"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback tag"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback comment tags"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date range filter applied"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " reply"]), _normalize([_interpolate(_named("count")), " replies"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dig deeper into why a ", _interpolate(_list(0)), " score was given by setting up reasons under survey settings."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No ", _interpolate(_list(0)), " reasons yet"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about survey reasons"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations with tags:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " by"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment category"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " reasons"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Predicted ", _interpolate(_named("surveyType")), " drivers"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation source"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tags"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Survey responses (", _interpolate(_named("n")), ")"]), _normalize(["Survey response (", _interpolate(_named("n")), ")"]), _normalize(["Survey responses (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subject"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversations with ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " by users"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response rate"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " score is a sum of all responses divided by the sum of maximum scores"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit target"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove target"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a target"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add target"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very long"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mid-length"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confusing"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not helpful"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad support"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good support"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotions-galore"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback for agent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crumbs"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue not solved"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue solved"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative sentiment"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad outcome"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive sentiment"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praise"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad product"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast support"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slow support"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA automatically allocates your ", _interpolate(_list(0)), " data into pre-defined categories. So you can understand complex insights and explore causes without manually sifting through the written feedback."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All scores"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All surveys"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select response language"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment size"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select comment size"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With 25% of comments consisting of only one word, longer comments are a must-read. Find more insightful feedback without putting in the manual work, as we do it automatically for you."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason of feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reason of feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching scores"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predicted drivers"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select comment category"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordcloud words"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select wordcloud words"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " since last period"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversations"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to calculate survey sent ratio from all conversations due to lack of data."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys sent"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey funnel"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Predicted ", _interpolate(_named("surveyType")), " drivers"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA automatically allocates your ", _interpolate(_list(0)), " data into pre-defined categories. So you can understand complex insights and explore causes without manually sifting through the written feedback."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment size"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With 25% of comments consisting of only one word, longer comments are a must-read. Find more insightful feedback without putting in the manual work, as we do it automatically for you."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey responses"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (average)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response count"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (average)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " trends by"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response count"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " survey sent date"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " survey reply date"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Assign ", _interpolate(_list(0)), " score to"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display data based on"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation creation date"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["owner of the ticket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agent with most replies"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (average)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " over time"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response count"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " and IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response count"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations that include these words:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word cloud"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT by users"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT overview"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey funnel"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey responses"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tags"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT over time"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word cloud"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No channel"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No source"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No language"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reasons"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tags"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comment category"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspace"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available because no assignments have been created or no cycles have been started. Check back later or adjust the filters."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore what's new"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " item"]), _normalize([_interpolate(_named("count")), " items"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversation"]), _normalize([_interpolate(_named("count")), " conversations"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass with minors"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass/Fail"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " ratings"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average CSAT = [sum of feedback scores ÷ (count of all responses × max possible score)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Zendesk QA CSAT score normalises your feedback score within a scale of 0-100% and takes an average of the standardised scores."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Customer Satisfaction Score) quantifies how happy your customers are with your services."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Each review represents the review by one person on one conversation. A conversation can have more than one review (if two or more reviewers review the same conversation)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track the evolution of reviewer's scores over time."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track the evolution of agents' scores over time."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " responses"]), _normalize([_interpolate(_named("n")), " response"]), _normalize([_interpolate(_named("n")), " responses"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " reviews"]), _normalize([_interpolate(_named("n")), " review"]), _normalize([_interpolate(_named("n")), " reviews"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Internal Quality Score) is the result of your conversation reviews."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = sum of review scores ÷ number of reviews"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's calculated as an average, taking into account all the review scores (weighted average of the category score) matching the selected filter conditions."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load card."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your filters to try again."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pass rate indicates the outcome of your conversation reviews."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fail if the review score is ", _interpolate(_named("failScore")), " or lower"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pass if the review score is ", _interpolate(_named("passScore")), " or higher"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is determined by a pre-defined baseline, meaning you:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass rate = Sum of reviews passed ÷ Sum of total reviews"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pass rate indicates a percentage of how many reviews pass without failing either critical or non-critical categories."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["e.g. If the reviewer gives 3 reviews on this day, the number displayed is the average of the 3 scores. See how each cell is calculated ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["e.g. If the agent receives 3 reviews on this day, the number displayed is the average of the 3 scores. See how each cell is calculated ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not taken into account: category weight, critical status (unlike Scores over time)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As categories can be marked n/a in reviews, the number of reviews per category may be lower than total reviews by the reviewer. See how each cell is calculated"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not taken into account: category weight, critical status (unlike Scores over time)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As categories can be marked n/a in reviews, the number of reviews per category may be lower than total reviews per agent. See how each cell is calculated"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not taken into account: category weight, critical status (unlike Scores over time)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Category groups are displayed separately. The score for each group is calculated by the average category score for that group during the same period. See how each cell is calculated ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not taken into account: category weight, critical status (unlike Scores over time)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Category groups are displayed separately. The score for each group is calculated by the average category score for that group during the same period. See how each cell is calculated ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Baseline ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical error free"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The critical error free rate indicates a percentage of how many reviews pass without failing any critical categories."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Quality Score"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Other' root causes"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic change"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily change"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly change"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly change"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly change"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores over time"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in either days or weeks."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation = total score for day or week divided by total reviews given"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in either days or weeks."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation = total score for day or week divided by total reviews received"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores by category"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the combined average of all category ratings given by the reviewer during this period."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the combined average of all category ratings received per agent during this period."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes for low ratings"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes for ratings"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category scores over time"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse how categories compare over time."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the average scores given for each category on a chosen day or week."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse how categories compare over time."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the average scores received for each category on a chosen day or week."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores by reviews"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average/Total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude self-reviews"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exports all cards"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["conversation"]), _normalize(["conversations"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["item"]), _normalize(["items"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " responses"]), _normalize([_interpolate(_named("n")), " response"]), _normalize([_interpolate(_named("n")), " responses"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " reviews"]), _normalize([_interpolate(_named("n")), " review"]), _normalize([_interpolate(_named("n")), " reviews"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Show one more"]), _normalize(["Show ", _interpolate(_named("n")), " more"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last week"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom period"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarters"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load card. Change your filters to try again."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to display."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly change"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your active filters include data across different rating scales."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All card pins with these filters"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All dashboard pins with these filters"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average review time"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No categories"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category insights"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comment"]), _normalize(["Comments"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former member"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Quality Score"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (average)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed reviews"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin card"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin dashboard"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Root cause"]), _normalize(["Root causes"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review time"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewee"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up root causes"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes let you know why conversations are getting low scores"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No root causes set for this category"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score / Total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating categories"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category groups"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category group"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise order"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group categories by"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude self-reviews"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include self-reviews"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only self-reviews"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hashtags found"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment hashtags"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select comment hashtags"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tags found"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tags"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search help desk tags"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scorecards found"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select scorecards"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching connections"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation source"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select source"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More filters"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching workspaces"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total times given"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching groups."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display cards"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display data based on"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review creation date"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation creation date"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show two decimal places"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View settings"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole account"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace name"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flags"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food and drink"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animals and nature"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People and body"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel and places"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently used"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys and emoticon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No emoji found"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open a conversation"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to task list"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " or browse your list of ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You're currently using the ", _interpolate(_named("workspace")), " workspace that is not connected to this domain. Click here to connect it now."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available connections"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can't give feedback on ", _interpolate(_named("host")), " just yet."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have your help desk as the active tab."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This domain can't be reviewed yet. If it should be, notify your admin."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this domain"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Connect with ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a moment - setting you up"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get the URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools that are already connected with organisation's Zendesk QA account."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tools connected with organisation's Zendesk QA account."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to review websites where the URL remains always the same, by appending a unique hash at the end before leaving a review"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch account"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your domains"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains that are already connected with this workspace."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can review any page on the following domains"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains available for review"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No help desk connected yet - head over to ", _interpolate(_named("link")), " to add one."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA browser extension can only be used\non Professional and Advanced plans."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch workspace"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a name"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a value"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom fields"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the first time we've come across this conversation. Provide some details."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation reference:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something to identify the ticket by later on"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide both the assignee and a subject"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review this conversation"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contact your manager or switch to a different workspace from the ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews are disabled for this workspace."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bye"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This URL can't be reviewed. Navigate to a conversation."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to Zendesk QA via your SSO provider first and then try again."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening sign-in popup."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with your Zendesk QA account"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticating..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not have an account yet?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have access to this conversation. If you should, contact your manager."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that your connection is activated in Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your connections"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection error"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to a conversation in your help desk to continue"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost done reconnecting."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One moment"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try reloading the page to get back into the flow of things"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free yourself from the virtual borders of your help desk and review conversations anywhere."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your subscription to use the Extension"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your subscription"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The help desk connection is not linked to this workspace"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your help desk does not recognise this ticket, or Zendesk QA does not have access to it"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not find conversation"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New activity"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got some feedback"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in successful"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use the app now"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate all categories"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this to bookmark cases and filter out later"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No feedback for this conversation"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read feedback"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where all feedback and notifications will appear. Nothing yet…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more in Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received reviews"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation details"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains all of"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is all of"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not contain all of"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not all of"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["better than agent’s"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worse than agent’s"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is more than"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains any of"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is any of"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not contain any of"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not any of"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["breached"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commented"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commented by me"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has not commented"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not commented by me"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critical for review"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last 30 days"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["within"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last 14 days"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last 24h"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last 30 days"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last 7 days"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last week"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this month"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this week"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detected"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exists"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starred by me"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not starred by me"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includes"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more than"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting from"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less than"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up until"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negative"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not breached"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not complex"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not detected"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not exist"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not include"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not received"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not viewed"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positive"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not reviewed"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not reviewed by me"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewed"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviewed by me"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["received"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critical for review"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more than"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting from"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less than"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up until"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not contain"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewed"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my comment has replies"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom categories"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom spotlight"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for condition"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review and feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all conditions"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compares your bot's conversation handling against average agents"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot is stuck in a loop, repeating the same message in consecutive turns"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bot replies in conversations"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A type of bot involved in the conversation"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bot involved in the conversation"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversation content contains the phrase ", _interpolate(_list(0)), " in agents' message(s)"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically identify calls lacking the mandatory disclosure statement, like 'This call will be recorded' and similar."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A person or bot involved in the conversation"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The agent or bot being reviewed in the conversation"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer sought higher-level assistance"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive and negative sentiment detected in the conversation"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifies the most active agent in conversations by AI analysis"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses if the agent wrapped up the conversation politely"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses whether the agent understands and acknowledges the customers' feelings"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses agent's grammar, spelling and style mistakes"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the entire conversation for typical greeting phrases"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses whether the agent understands the customer's query or concern"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses how easily a text can be understood, considering word complexity and sentence length"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the entire conversation for an offered solution"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses agent's tone throughout the conversation"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signs of customer attrition. Customer was considering a switch or promised to part ways."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group to which the conversation belongs."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content or text of the agent message"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of agent replies in conversations"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The agent assigned to review the conversation"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The length of time the call conversation lasted in seconds"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content or text of the conversation"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The communication channel used for the conversation (e.g. email, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments added to the conversation"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when a comment was added to the conversation"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive and negative sentiment detected in the conversation"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the customer replied to the customer satisfaction survey"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether the conversation review is disputed"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The channel used by the customer to initiate the conversation"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of customer responses in conversations"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date of the last message in the conversation"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role or type of user involved in the conversation"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silence between the agent and the customer exceeded the threshold"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer sought higher-level assistance"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer expressed extreme gratitude or was very content with the support received"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Either the customer or agent explicitly requested a follow-up"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text or record of the entire conversation conducted by voice"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether the conversation is marked as starred"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags used in comments to categorise content"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language of the conversation detected by AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date of the last message in the conversation"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of private notes in conversations"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average number of characters per message in conversations"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of customer-facing replies in conversations"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A participant whose information is publicly visible"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average number of words per message in conversations"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories used to rate or evaluate the conversation"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The status of the conversation's review process"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A participant or person involved in the conversation"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email associated with the conversation"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether the review has been viewed"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reviewer who assessed the conversation"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the conversation was last reviewed"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The agent being reviewed in the conversation"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any specific comments or feedback provided by the customer regarding their satisfaction with the conversation"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer's satisfaction score for the conversation"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction score per message in the conversation"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scorecard used for reviewing the conversation"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given SLAs have been breached"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The type of source or connection from which the conversation originated"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation was unusual or atypical and there was more back-and-forth to reach resolution"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The survey question related to the conversation content"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The title or name of the survey associated with the conversation"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The agent assigned to review the conversation"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The brand or specific product/service associated with the conversation"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The communication channel used for the conversation (e.g. email, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the conversation was closed or resolved"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the conversation was initially created"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the conversation was initially created"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the customer replied to the customer satisfaction survey"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief description or summary of the conversation"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An external reference ID linked to the conversation"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The folder or specific grouping in which the conversation is organised"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The form or template used for structuring the conversation"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An external reference ID linked to the conversation"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mailbox or email account through which the conversation was received"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone number associated with the conversation"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The priority level assigned to the conversation"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total number of replies in the conversation"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The state or current status of the conversation"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current status of the conversation"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The subject or topic of the conversation"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of agent replies in conversations"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags assigned to categorise the conversation"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The type or category of the conversation"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the conversation was last updated"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date when the conversation was last updated"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The medium or channel through which the conversation took place"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content of a transcription in a conversation"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations that are ineligible for review through AI analysis"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of participating agents in conversations"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether the conversation has been viewed"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any message"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot communication efficiency"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot repetition"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot reply count"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot type"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call direction"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client message"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dead air"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording disclosure missing"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalation"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call transcript content"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starred"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ineligible for review"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed by"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation via"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling and grammar"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent message"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most active agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent reply count"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call duration (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation content"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message channel"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment tag"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commented date"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT survey reply date"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathy"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer channel"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reply count"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reply date"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Churn risk"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dead air"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalation"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional service"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcript"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment hashtag"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlier"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehension"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reply date"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation private note count"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average character count"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average word count"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public reply count"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public participant"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rated date"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating and comment"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review status"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Readability"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related email"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply count"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review received"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed scorecard"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review viewed"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed date"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewee"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey comment"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per message"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction score (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution offered"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source type"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey question"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey title"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation brand"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand domain"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation channel"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed date"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT survey reply date"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT survey sent date"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation description"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation field"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation folder"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation form"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation group"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation mailbox"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation priority"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation reply count"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation state"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation status"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation subject"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent reply count"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tag"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tag"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation type"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated date"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated date"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcription content"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of participating agents"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed status"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vulnerability capability"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vulnerability health"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vulnerability life event"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA category"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date-related condition"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a name to your filter"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in a filter name"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed-in person"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former workspace members"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace members"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filter"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should have at least 1 date-related filter"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select options"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial ends in ", _interpolate(_named("n")), " day. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial ends <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>in ", _interpolate(_named("n")), " day</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial ends in ", _interpolate(_named("n")), " days. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial ends <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>in ", _interpolate(_named("n")), " days</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial ends today. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your trial ends <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>today</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up a subscription now"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " days"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("days")), " left in your trial"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the user limit for your plan. ", _interpolate(_named("link")), " to learn more or to upgrade your plan."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connect your help desk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will enable us to pull in your tickets for you to review."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your help desk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are using Zendesk QA with sample data. To delete sample data and import your own conversations, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample data"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy helpful information below"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a full-page screenshot to provide context"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our customer support team to resolve this issue:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error reference:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy helpful information"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users, bots, and workspaces"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users and Workspaces"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this month"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this week"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " reviewed ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " reviewed ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " reviewed ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's new?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyboard shortcuts"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat with us"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our chat could not be loaded (Are you maybe using an ad-blocker?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump to..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given reviews"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received reviews"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge base"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch account"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Application was updated. ", _interpolate(_named("buttonStart")), "Reload", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you'd like to start using Zendesk QA as part of your workspace:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Just letting you know that you've already received an invite to use Zendesk QA as part of a workspace. If you'd like, you may of course continue, but note that this will create a ", _interpolate(_named("bold")), " for you."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["separate organisation"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yes, let me continue with ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a new account"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " asks you to join ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No internet connection"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new pin"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick navigation"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus and expand the comment editor"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide or show sidepanel"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the next conversation"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open original conversation"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the previous conversation"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give all categories the highest possible rating"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give all categories the lowest possible rating"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuffle conversations (re-sorts randomly)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyboard shortcuts"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle shortcuts modal"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You've reached the ", _interpolate(_named("charLimit")), "-character limit"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Image ", _interpolate(_named("name")), " type (", _interpolate(_named("type")), ") is not allowed"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Image ", _interpolate(_named("name")), " size is too big (5MB allowed)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment editor failed to load"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop to upload"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage templates"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates added"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root cause"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other including deleted reasons"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for rating"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll reach out to help you set up your account"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company contact number"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you first hear about Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a channel"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a number of agents"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in all required fields"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to have read and consent to our terms and our privacy policy."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support driven Slack channel"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend or colleague"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk marketplace or account manager"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referring site"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search engine (Google, Bing, etc.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about yourself and your company."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account details"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm ready."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mosey around, get to know the app, delete and add things."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account created. What's next?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to bring in your real conversations?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your own data"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start exploring"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out with sample data"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So we can start pulling in your tickets for you to review."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your help desk account"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I changed my mind."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel sign-up"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin created. ", _interpolate(_named("buttonStart")), "Go to pins", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching pins is available on the Professional plan."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade plan"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all plans"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save your notes to prepare for the next 1:1 or coaching session."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins for Coaching"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment or add others with ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This conversation has been deleted"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new pin"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create pin"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit pin"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How pins work"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to see this conversation"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin card"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin conversation"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin dashboard"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin saved"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin this card"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin this conversation"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin this dashboard"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin under a user"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you can see what you pin. To share with others ", "@", "mention them in the comments."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " is now invited to this conversation"]), _normalize([_interpolate(_named("agentsNames")), " are now invited to this conversation"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 comment"]), _normalize([_interpolate(_named("n")), " comments"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["You mentioned ", _interpolate(_named("participants")), " but they're not in this conversation."]), _normalize(["You mentioned ", _interpolate(_named("participants")), " but they're not in this conversation."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " participants"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participants"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to remove ", _interpolate(_named("agentName")), " from accessing this pin?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do nothing"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not invite them to this conversation"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite them"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite them to this conversation"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove user"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This cannot be undone."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will also delete all the comments under this pin."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " new messages"]), _normalize([_interpolate(_named("n")), " new message"]), _normalize([_interpolate(_named("n")), " new messages"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you can see your pins. To share pins, ", "@", " someone in the comments."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " pinned ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a dashboard"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a conversation"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a note"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to get coaching pins"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching pins is available on the Professional plan."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching pins is available on the Professional plan"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to get feature"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare for your 1:1’s or save something for another time."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the pin and all added comments."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete pin"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete pin"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder name"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin things to come back to later"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest first"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldest first"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save an interesting dashboard trend or conversation in preparation for your 1-1s, or note it down for another time."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a pin by clicking on the button above or the pin icon in the Conversation or Dashboard view."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pins created"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No results for ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try using different keywords or checking for typos."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new pin"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by user, keyword..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetically"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently created"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently edited"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most pins"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["I aim to review ", _interpolate(_named("n")), " conversation ", _interpolate(_named("schedule"))]), _normalize(["I aim to review ", _interpolate(_named("n")), " conversations ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal updated"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set goal"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set a personal goal for the number of conversations you'd like to review."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["every day"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["every month"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["every week"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review goal"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading channels"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select channel"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only share survey feedback with comments"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empower your colleagues by highlighting their exceptional service quality and excellent customer feedback."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share positive survey feedback to Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No channels found"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically share feedback with scores above"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praisebot integrations"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive a reminder at the start of the week if you haven't done any reviews."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly reminder to do reviews"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications about new reviews, replies, mentions, and disputes in Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread replies"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack integrations"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify about new reviews, replies, mentions and disputes."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes related to me"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions in comments"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New received reviews"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies in threads"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report with statistics"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive reports about given and received reviews."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daily"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weekly"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 3 hours"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 6 hours"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantly"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account admin has locked notification settings."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select period"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal notifications"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (active)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new social sign-in to this account:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing an active sign-in method will sign you out. You can sign in again with your alternative email."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove sign-in method"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added Google as a sign-in option"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in options"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added Slack as a sign-in option"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create password"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An email to ", _interpolate(_named("email")), " will be sent to reset your password."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary email"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox for our confirmation email"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic link"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To change that, change your ", _interpolate(_named("link")), " beforehand."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The email ", _interpolate(_named("email")), " will be associated with this password."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile picture"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For best results upload a square photo (256x256 pixels or more)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary email"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of the week"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal settings"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one option"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template content"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show template in"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add template content"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a template name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create comment templates to speed up reviewing, pinning items, and coaching."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used in"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete template"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanently delete this template?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit template"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create comment templates to speed up reviewing, pinning items, and coaching."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates created"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment template created"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment template deleted"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment template saved"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews and disputes"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To get access, get in touch with ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer support"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account found"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For now, use ", _interpolate(_named("link")), " to access Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not migrated"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrics"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scores"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistics"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calibration"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversations"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my comments"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my disputes"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my reviews"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactions"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey feedback"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create password"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credentials"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review goal"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["templates"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seats"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscription"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentications"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflicting message found"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change or remove one of conflicting messages"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Use the ", _interpolate(_named("placeholder")), " icon to exclude sensitive data from AutoQA. For example, instead of \"Take care, John\", use \"Take care, ", _interpolate(_named("placeholder")), "\" to represent any name."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum ", _interpolate(_named("limit")), " rows"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply rule to"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve reached the 10 custom category limit"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You've reached the ", _interpolate(_named("limit")), " custom category limit"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add condition"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete condition"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If agent message"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otherwise score"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then score"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create scorecard category"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use '/' to add a variable"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All directions"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent message cannot be empty"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message can't be over 300 characters"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sources"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All channels"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All tags"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All conversations"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narrow down where your rule applies."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific conversations"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Spotlight insight"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent message cannot be empty"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve reached the 10 custom insight limit"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You've reached the ", _interpolate(_named("limit")), " custom insight limit"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in insight name"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight name"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight type"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to workspace settings"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not now"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start using this rating category, go to your workspace scorecard settings and add it onto a scorecard of your choice."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category created"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically score closed conversations by identifying specific keywords or phrases."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't work on voice transcripts."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["High five! ✋ You've maxed out your automated rating categories at ", _interpolate(_named("limit")), ". You're the wizard of categorisation! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category limit reached"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure all the fields are filled correctly"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your rating category a name"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category name"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New rating category"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New rating category"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically score closed conversations by identifying specific keywords or phrases. Doesn't work on voice transcripts."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically surface closed conversations by identifying specific keywords or phrases. Works on transcripts only."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically score conversations by identifying specific keywords or phrases in both agent and bot messages."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this rating category, go to your workspace scorecard settings and add it onto a scorecard."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score conversations automatically based on specific keywords or phrases in messages."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight setup"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This insight displays in Spotlight based on defined criteria."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard setup"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This category can be used on scorecards based on defined criteria."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coming soon"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pre-defined"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scorecard"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voice"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the ", "{··}", " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add approved closing"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit approved closing"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved closing"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exemptions"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit exemption"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemption"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add approved greeting"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit approved greeting"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved greeting"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste words here separated by line breaks"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Enter after each word for a new entry"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable language"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example, instead of \"Take care, John\" you can use \"Take care, ", "{··}", "\" to represent any name."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic content placeholder"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemptions"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 exemption added"]), _normalize([_interpolate(_named("count")), " exemptions added"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Exemption already added"]), _normalize(["Exemptions already added: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exemption"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No exemptions found"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No exemptions added"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemption (case-sensitive)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Add exemptions"]), _normalize(["Add 1 exemption"]), _normalize(["Add ", _interpolate(_named("count")), " exemptions"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a word or phrase"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemption saved"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All languages"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for language"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Closing already added"]), _normalize(["Closings already added: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add closing"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No approved closings found"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No approved closings"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add closing"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a closing"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing added"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing saved"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Form has duplicate entries: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Greeting already added"]), _normalize(["Greetings already added: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add greeting"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No approved greetings found"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No approved greetings"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add greeting"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a greeting"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting added"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting saved"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exemption"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Delete exemption"]), _normalize(["Delete exemptions"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Permanently delete this exemption?"]), _normalize(["Permanently delete these exemptions?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit exemption"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["One or more exemptions is over ", _interpolate(_list(0)), " characters long"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " characters"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated by"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define your own sign-offs that align with your brand and service standards. When added, AutoQA will only detect approved closings. Changes made apply to future conversations."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the top limit of 100 approved closings. If you want to add more, it might be time to revisit and refine your existing closings."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved closing limit reached"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved closings"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words and phrases you’d like AutoQA to ignore as spelling or grammar mistakes. Changes made apply to future conversations."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore list"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify your own greetings that align with your brand and service standards. When added, AutoQA will only detect approved greetings. Changes made apply to future conversations."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the top limit of 100 approved greetings. If you want to add more, it might be time to revisit and refine your existing greetings."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved greeting limit reached"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved greetings"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not now"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete category"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanently delete this rating category?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle categories list"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted category will not appear on any new conversations and filters. Historical data on dashboards and conversations remain intact."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category will be removed from all scorecards it has been added to."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete scorecard category"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard category deleted"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete scorecard category"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA categories"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " powered by the AI model will remain on the scorecard but will no longer be automatically scored"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning LLM integration off doesn't turn off the AutoQA feature completely. Here's what will happen:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical data"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " will remain on the AutoQA dashboard"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually added root causes"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " will stay, but all LLM-based root causes will be removed"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off LLM-based AutoQA"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA uses AI and large language models (LLMs) to automatically score specific categories. Your data is processed under a strict Data Processing Agreement (DPA) and is never used to train any models."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about generative AI"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA uses Microsoft Azure OpenAI Service for ", _interpolate(_named("automatically_scoring_categories")), ". Your data is processed under a strict DPA only to provide AutoQA to you and ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatically scoring some categories"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is never used to train any OpenAI models"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-based AutoQA turned on"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-based AutoQA turned off"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-based AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated scoring with AI in AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data processing of MS Azure based AutoQA categories"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure consent withdrawn"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure consent given. Conversations will receive automatic ratings soon."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can still use AutoQA, but here's what will happen"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabling MS Azure OpenAI service will not turn off AutoQA as a functionality."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can do that in the scorecard category view."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA categories powered by the model will stay on the scorecard but will not be automatically scored."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The root causes you have added manually will remain. All model-generated root causes will be removed."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical data stays on the AutoQA dashboard."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable data processing by MS Azure OpenAI service"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wider range of AutoQA categories."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-of-the-box multilingual support."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast paced addition of new categories."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved evaluation accuracy. GPT-3.5 shows human-level contextual awareness."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit from the latest advancements in AI technology."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here's what you'll get"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable data processing by MS Azure OpenAI service"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent given"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No consent given"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All new users are assigned to this workspace"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default workspace"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12-hour time (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24-hour time (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time format"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company profile updated"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of the week"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom integration"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access key ID and secret key are typically found under Access management > Users"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS access key ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel changing secret access key"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change secret access key value"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The connection allows to define only one region which is typically found under Location"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS region"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a secret key"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS secret access key"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find all relevant information in Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typically found under Amazon Connect instance settings and Data storage page"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage location of call recordings"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's been an error when adding Amazon Connect. Check if all the fields are filled correctly and try again."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage location should be in the format of bucket/folder separated by a slash."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To add a connection, contact ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Support"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your Zendesk tickets will be synced automatically. You can choose to exclude selected tickets."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (Central)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Zurich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Ireland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server region"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle East (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select server region"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South America (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US East (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US East 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US West (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection already added"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start connection"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only sync conversations that"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel one, channel two"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This connection's unique ID is ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 months"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 months"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive conversations will be deleted after the selected retention period. Submitted reviews remain visible in Activity and Dashboard views."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retention period"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select retention period"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that this action is irreversible."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand that this will remove all help desk-related data for this connection from Zendesk QA, including conversations, fields, and tags."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted reviews will not be affected and will remain visible in specific views."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group one, group two"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active development"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This integration is still in ", _interpolate(_named("activeDevelopment")), ". Some details might be missing (like attachments or custom fields)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailbox one, mailbox two"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag one, tag two"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API token secret"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find \"Ticket Field Names\" on Freshdesk under Settings → Ticket fields"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find \"Custom Field Names\" on Help Scout under Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find \"Klass Conversation Names\" on Kustomer under Settings → Klasses → Conversation"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find \"Custom Field Name\" on Live Agent under Settings → Custom fields"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find \"Custom Field Name\" on Wix under Settings → Custom fields"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate Field IDs with commas. You can find these IDs in Zendesk under Admin → Ticket fields."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect a few of your help desk users to review your first conversations. Don't worry, we won't notify them unless you invite them to become workspace members."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully integrated"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add members"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will now start pulling your conversations."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domain: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Help desk identifier: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not authenticate your connection"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All set. This is your new connection."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some connections have not been updated or have become inactive. Try to update these connections or remove them from your list."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API client ID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API client secret"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create a new API token from Settings → Account → API & SDKs. The Redirect URL to use is ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your help desk to get started"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your Intercom account is set up to be served from the EU or Australia, then update this."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Email you use to sign into ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mask out end-user's name, email, and phone number. Bank credentials are already masked for all help desk-connections."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No integrations yet. Add one now."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need these roles:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create connection"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new connection"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unchecking this allows you to manually add this connection to only specific workspaces"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to all existing workspaces"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key secret"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Connection ", _interpolate(_list(0)), " deleted"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection name"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can grab your API token down below."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT threshold"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete connection ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type \"delete connection\" to remove this connection"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete connection"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage and monitor your connected data sources."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit connection"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate field names with commas"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update failed"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a unique name to your integration"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide advanced options"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide sensitive data fields"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never store attachment URLs"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore attachments"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never store conversation content (you can later pull it in on demand from your help desk)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore conversation content"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never store conversation subject (you can later pull it in on demand from your help desk)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore subject line"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your ", _interpolate(_list(0)), " subdomain"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom region"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match inbox team ID"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeric ID of your Intercom team"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailboxes"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually update connection"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mask customer data"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensitive field name"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensitive field ID"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew connection"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew connection"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to Salesforce sandbox"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Setting up a connection with ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced options"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only pull in conversations with a score lower than or equal to the set threshold"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply a CSAT threshold"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync only conversations that..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get latest conversations"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update connection"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection updated"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your help desk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To cancel your subscription and delete your account, contact ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Support"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting a ticket in Zendesk Support (or another help desk) doesn't affect associated conversation reviews. You can still view and manage them."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deleting a ticket in Zendesk QA deletes associated conversation reviews, too.", _interpolate(_named("line_break")), "Deleting a ticket in Zendesk Support (or another help desk) doesn't affect associated conversation reviews. You can still view and manage them."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage reviews"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting reviews is permanent. It also affects data on dashboards."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all reviews"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reviews deleted"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When there are reviews associated with deleted tickets, you'll see them here."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviews yet"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for ticket ID"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ID: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted conversations"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Default workspaces can't be deleted. To delete this workspace, select another default workspace in ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove and delete all data"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I understand that this will ", _interpolate(_named("removeAllData")), " related to this workspace, including workspace users and connections."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete workspace"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type \"delete workspace\" to delete this workspace"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete workspace ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete workspace"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be sure of what you're doing or consult a professional beforehand."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select whether the default reviewee is selected based on the most frequent agent or the current assignee."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most active agent"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default reviewee"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow people to review their own conversations and enable agents to perform reviews"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace has been deleted"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace has been updated"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establish neutral grading by hiding reviews previously given by other reviewers"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbiased grading"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colour"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that this will remove and delete all data related to this workspace, including workspace users and connections."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete workspace"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting workspace..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic link"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic link"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot deactivate your current sign-in method"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication methods have been updated"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow certain types of authentication methods for all users."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentications"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users and workspaces"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and manage workspaces, and invite or connect users."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay on page"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave page"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave this page and lose all changes?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentications"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like there are no conversations to import. Start by having some conversations with your help desk, then return here to analyze them."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations found"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete this system category"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete category"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting categories is permanent. It will erase all category data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this category, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete category"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for category"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto categories automatically fill the scorecard with suggested ratings to save time on reviewing. Manual categories are filled by reviewers from scratch."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathy"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling and grammar"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehension"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Readability"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution offered"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No categories found"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No root causes found"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scorecards found"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try a different search or change filters"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categories"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categories"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categories"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All categories"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categories"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorecards"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorecards"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorecards"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All scorecards"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorecards"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuses"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuses"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuses"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All statuses"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuses"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All types"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces found"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight of ", _interpolate(_named("weight")), " contributes ", _interpolate(_named("percentage")), "% to the overall score"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete scorecard"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting scorecards is permanent. It will erase all scorecard data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this scorecard, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete scorecard"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rating scale updated. ", _interpolate(_named("buttonStart")), "Start reviewing", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA sets a binary scale by default, but you can also opt for a more granular scale:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you want to see different rating scale options, configured by category, go to your ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathy/Tone"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product knowledge"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By updating the rating scales:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your previous rating scale and the corresponding categories will be archived (not deleted)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some dashboard data will be affected since your old and new scale might not be comparable."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone my existing rating categories setup over to the new scale"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to update the rating scales?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can alter the scorecard display format (number or emojis) and make categories compulsory or optional."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use emojis instead of numbers"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow people to skip categories"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale type"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Categories for 1 workspace have been cloned"]), _normalize(["Categories for ", _interpolate(_named("workspacesAmount")), " workspaces have been cloned"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace settings"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically surface calls where the agent does not give prompts to customer to expect a silence in the conversation."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation has moments of dead air lasting longer than set threshold."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define maximum dead air threshold in calls"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dead air"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted insight will not appear on any new conversations and filters. Historical data on dashboards and conversations remain intact."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete Spotlight insight"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight insight deleted"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Spotlight insight"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View conversations containing specific words or phrases in transcripts and messages."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically identify calls lacking the mandatory disclosure statement, like 'This call will be recorded' and similar."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aims to identify a recording disclosure statement in the call using a Large Language Model (LLM) that dynamically understands context, eliminating the need for preset phrases."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording disclosure missing"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically surface calls where the agent does not give prompts to customer to expect a silence in the conversation."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dead air"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify dead air moments in a call"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Need to make changes to your subscription? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") and we’ll be happy to help."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically analyse 100% of conversations across agents, BPOs, channels, and languages. AI detects issues, knowledge gaps, and coaching opportunities to improve service."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100% coverage"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent feedback and coaching"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight AI insights"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced reporting and dashboards"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment analysis and filtering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand translations"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review assignments and goal setting"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching and quizzes"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Quality Assurance (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything on Professional, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks for immediate notification of critical fails"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data warehouse integration"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud integration"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom integration development"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated customer success manager"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct access to our product and engineering teams through Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything in Growth Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA for 100% coverage"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-driven Conversation Insights to make sense of data"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-enhanced help desk data enrichment and filtering"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Automated detection for must-review interactions"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment analysis and filtering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-powered performance reporting and insights"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand conversation translation"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review time tracking"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass rate analytics"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything in AI Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise implementation package including Salesforce and Genesys integration"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom integration development"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data warehouse integration"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks for immediate notification of critical fails"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated customer success manager"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct access to our product and engineering teams"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early access and influence on our roadmap"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic review assignments & goal setting"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targeted coaching & analytics"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes for learning management"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User role & permission management"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensitive data masking"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced filtering options and conversation search"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customisable scorecards with multiple ratings, adjustable weights, and auto-fail options"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser extension to review conversations anywhere"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause analysis"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance reporting for comparing agents, teams, BPOs"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT survey and customer feedback analytics"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything on Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple rating scales per conversation"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack integration"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-driven interactive Conversation Insights"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review assignments"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pins for coaching"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching sessions"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment analysis and filtering"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtering based on conversation complexity"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation insights"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditional scorecards"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause analysis"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced AI-powered CSAT analytics"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading calibration"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser extension to review conversation anywhere"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight to discover must-review conversations"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand conversation translation"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review time tracking"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom plan"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited conversation reviews"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV export"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer-to-peer and self-reviews"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards with adjustable weights and auto-fail options"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robust filtering options"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily, weekly, or monthly review goals"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT surveys and basic analytics"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports for comparing agents, teams, BPOs"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User role and privilege management"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am looking to make a change in my Zendesk QA subscription.\n\nHighlight or indicate one option and indicate the number of seats you would like to change:\nI would like to [add X seats] [remove X seats] [upgrade to the Zendesk QA plan]\n\nPlease share your company name and the name associated with your Zendesk QA account, as well as any other details we should know to process this change."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA subscription change"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am looking to purchase a Zendesk QA subscription.\n\nPlease share your company name and the name associated with your Zendesk QA account, as well as any other details we should know to process this request:\n\nPlease also share any specific questions you’d like addressed prior to purchasing:\n\nThank you!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA subscription purchase"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscription ends on ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " left)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial ends on ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " left)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 day"]), _normalize([_interpolate(_named("n")), " days"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("dayOfMonth")), "rd of every month."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("dayOfMonth")), "th of every month."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("dayOfMonth")), "st of every month."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("dayOfMonth")), "th of every month."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("dayOfMonth")), "nd of every month."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("dayOfMonth")), "th of every month."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billed to ", _interpolate(_named("card")), " (expires ", _interpolate(_named("expires")), ") on ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ending in"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Expired"]), _normalize(["Expires in 1 day"]), _normalize(["Expires in ", _interpolate(_named("expiresIn")), " days"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starting price includes ", _interpolate(_named("n")), " user licenses"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["starts at ", _interpolate(_named("sum")), "/month"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per user / month"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per year"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per month"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quarter"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove the scheduled cancellation"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you'd like to continue using Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscription was cancelled on ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscription is scheduled to expire on ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " day trial at no extra cost!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["used"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("n")), " users. Seat count can't be lower than that."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subscription will resume on ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your current Zendesk QA plan (", _interpolate(_named("plan")), ") does not include AI-powered features. To unleash the full power of voice intelligence features, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To cancel your subscription, contact us ", _interpolate(_named("link")), " or chat with us."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality management plan"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality management"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription updated"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a credit card"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All plans"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See billing information"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing interval"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel subscription"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription will be cancelled at the end of the billing period."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us to edit"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom pricing"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual (Save 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay annually"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit subscription"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore plans"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free trial"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoiced manually"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can review and confirm the final order summary in the next step."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most popular"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have an active payment method."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On trial"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume your current subscription in order to change the plan"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause subscription"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivate subscription"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume subscription"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seats"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start trial now"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-renewing"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription status"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade subscription"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please take one minute to provide feedback on your customer support experience. Thanks in advance — ", "{", "{", "agentName", "}", "}", " values your feedback!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "'s CSAT survey"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate the support you received from ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We really appreciate it. We'll use your feedback to make ", _interpolate(_list(0)), " better for everyone."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about surveys"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the survey type"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This can be changed later."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure and improve customer satisfaction with automated CSAT surveys in multiple languages."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No surveys created"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create survey"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey name"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey has been created"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Try changing the keyword or ", _interpolate(_named("contact_us")), " if you'd like to see it listed here"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["let us know"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No language found"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select one or more languages from the list below. ", _interpolate(_named("contact_us")), " if you don't see a language you'd like to use listed here."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add languages"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let us know"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add language"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Last published ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Last saved ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " question"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro question"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left scale label"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email subject"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right scale label"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a survey has any missing translations, base language’s texts will be shown instead"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply translations"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing translations"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make sure to review all your translations before applying them. Make changes in the downloaded file, save it, and re-upload if needed. If a token ", _interpolate(_named("token_example")), " does not appear as a tag, it's either in an incorrect format or in a field that does not support tokens."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review translations"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translated"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summarises conversation and shows suggestions for comments."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI assist (in English)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit types once they've been created"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This type already exists. Select a different name."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the custom type as a selectable option in the Survey type dropdown, and group all created surveys on the dashboard."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in word(s) and press Enter to save"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit custom types"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type name can't be empty"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define type"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom type was not saved. Press Enter to save it."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's email"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's name"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To what extent do you agree with the statement: “", _interpolate(_named("agentName")), " made it easy to handle my issue”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How easy was getting help from ", _interpolate(_named("brandName")), " so far?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall, how easy was it to solve your problem today?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How easy was it to interact with our team?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy URL (optional)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service URL (optional)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How would you rate the support you received from ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How was our service for this request?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How satisfied were you with the support representative?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How satisfied are you with the service you received from ", _interpolate(_named("agentName")), " today?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom question"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Define your own custom ", _interpolate(_named("type")), " question..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in custom question"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise URLs"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your email message"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing the name of a custom question type will update it in all surveys using it, without affecting the collected data."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit custom types"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email subject"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro question"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " question"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new custom type"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Personalisation token"]), _normalize(["Personalisation tokens"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Drag and drop the personalisation token into the field below."]), _normalize(["Drag and drop the personalisation tokens into the fields below."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversation ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently it's only possible to send out CSAT surveys via email. We are working to bring you more opportunities such as help desk CSAT surveys, SMS surveys and more."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sync or renew the connection"]), _normalize(["Sync or renew the connections"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync or renew the connection ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Connection is not synced correctly"]), _normalize(["Connections are not synced correctly."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uncheck the relevant checkbox ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticate now ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least one survey send out rule to activate the connection"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This connection is currently disabled. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This connection masks sensitive client data. To enable survey send-out,\n", _interpolate(_named("link")), " in the settings."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This connection requires additional permissions to send out surveys.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticate now"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA requires more permissions for Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " and ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hour"]), _normalize([_interpolate(_named("n")), " hours"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minutes"]), _normalize(["1 minute"]), _normalize([_interpolate(_named("n")), " minutes"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hour"]), _normalize(["hours"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " to configure survey sending rules."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" day"]), _normalize(["days"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey links expire"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links expire in"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevent answering surveys if too much time has passed"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " survey ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate the tags with commas or press enter after writing. Make sure each one is listed precisely as in Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the conditions based on which the survey is sent out."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey send out rules"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey follow-up send out rules"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A survey ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " already exists.\nResolve the conflict by modifying the rules either here or in the ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You've already set up a connection with matching rules for ", _interpolate(_named("name")), " survey. Resolve the conflict by modifying the rules either here or in the ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another rule"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add brand"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select condition"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a value"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matching the same rules"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a value"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent out in"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email follow-up sent out in"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded snippet and email follow up"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy code"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Zendesk QA surveys to your emails by adding the snippet code to your Zendesk setup."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation guide"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippet preview"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippet code copied to clipboard"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippet copying failed"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded snippet"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This survey has unsaved changes. Save it again to ensure the snippet code reflects the latest updates."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add connection"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connections added, yet"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull email, live chat, phone calls, and more into Zendesk QA with your connections"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about survey delivery"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey send out rules"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey follow-up send out rules"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add brand rule"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add rule"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticate"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + email follow up"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your Intercom account with Zendesk QA CSAT Survey"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver via"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who gets the survey and when"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit rules"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-app surveys enable your customers to complete a survey without leaving the chat."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery rules per connection"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A survey ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " already exists.\nResolve the conflict by disabling the connection or modifying the rules either here or in the ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the conditions based on which the survey is sent out"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created by ", _interpolate(_named("name")), " on ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing this language means that it will not be visible to any respondents anymore and all the translated texts are deleted."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove language"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatively, you can disable the language by hiding it from the survey. This will allow you to keep all your translations in place without showing it to respondents."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last edited by ", _interpolate(_named("name")), " on ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about survey languages"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before adding other languages, make sure you have finalised making changes in the Styling and Content steps. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add language"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields translated"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible to respondents"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download translation file"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove language"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" download a translation file"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review translations"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Translation complete. ", _interpolate(_named("review_link")), " or ", _interpolate(_named("download_link")), " to make any changes."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" download a translation file"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review what's missing"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some content has yet to be translated and will be replaced with English. ", _interpolate(_named("review_link")), " or ", _interpolate(_named("download_link")), " to add missing translations."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the survey"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The survey contains unsaved changes. ", _interpolate(_named("save")), " to download the translation file with the latest changes."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload translation file"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select preferred rating scale"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives customers a dropdown to select a reason for rating"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left scale text (optional)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reason"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason can't be empty"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This reason already exists"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show dropdown for reasons for your score"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting a reason and adding it again with the same name or renaming a reason always creates a new entry. As a result, it impacts the data displayed on your dashboard."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete reason"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A deleted reason remains in the dashboard reporting but will not be visible to new survey respondents. This also applies to the related translations."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a reason"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete a reason \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right scale text (optional)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On a 5-point scale, 1 and 2 are considered negative scores; 3 is neutral; 4-5 are positive"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add scale text"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " scale"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA is importing your conversations. This process might take up to 24 hours."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will be used as a personalisation token throughout the survey."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand name"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons and links"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise the look of your survey"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo background"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, PNG and GIF formats are supported"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styling"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off in style"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a message people will see after submitting their feedback"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you message"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect hex colour code"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email address"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect url"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not visible:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date created"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys sent"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys sent in the last 30 days"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey has been deleted"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey has been duplicated"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview email has been sent"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey has been published"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey has been updated"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey has been paused"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause survey"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email to send the survey preview"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language for preview"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a preview email"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an example survey to your email. The ratings from the survey preview will not be stored."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Preview the ", _interpolate(_named("type")), " survey"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email survey preview in the web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand name"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand-specific name can be added in the Styling step"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support rep"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "'s CSAT survey"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved changes made"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update and resume survey"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you are not seeing this email correctly ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wasn't happy with the support that I got, because..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reason"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation recap"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: Customer had problems updating the payment method. The agent shared an article which could help. It worked."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA AI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the main reason for your score?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us why you gave this rating..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High effort"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low effort"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here to open the survey"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your custom question appears here"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You gave your ", _interpolate(_list(0)), " experience a score of"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit feedback"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very dissatisfied"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very satisfied"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that the current survey status and delivery rules will not be included in the survey duplicate"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplicate survey “", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use the embedded email snippet, make sure to remove it from Zendesk before pausing the survey"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No responses are collected when the survey is paused."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause survey"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you remove the snippet from Zendesk before pausing the survey."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete survey"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use the embedded email snippet, make sure to remove it from Zendesk before deleting the survey."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will permanently delete the survey. Users who received the survey are still able to fill it in and all data related to the survey will remain in Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete survey ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you remove the snippet from Zendesk before deleting the survey."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your applied changes will be seen by new respondents."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish survey"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you copy the latest version of the embedded snippet after publishing the survey."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use the embedded email snippet, make sure to copy it to Zendesk after resuming the survey."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue sending out the survey and collecting responses."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update and resume survey"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you add the embedded snippet to Zendesk after resuming the survey."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update survey"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you copy the latest version of the embedded snippet after updating the survey."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this survey? This action cannot be undone."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete survey?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your trial has expired, but you can continue enjoying Zendesk QA by upgrading to a full account."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your trial has expired, but you can continue enjoying Zendesk QA by upgrading to a full account. Speak to your local administrator to set this up."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your trial has ended"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade now"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your free trial has ended."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To continue using the full version of Zendesk QA, enter your payment details."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To continue using the full version of Zendesk QA, your account administrator needs to enter payment details."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage payment"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a user"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created on ", _interpolate(_named("date")), " by ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " assignment"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you're still working on this. You lose all the info you've added this far if you leave without saving changes."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This assignment will repeat every other ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This assignment will repeat every ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["All reviewees covered in one cycle"]), _normalize(["All reviewees covered in ", _interpolate(_named("nr")), " cycles"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversations"]), _normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversations"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["of ", _interpolate(_named("nr")), " agent"]), _normalize(["of ", _interpolate(_named("nr")), " agent"]), _normalize(["of ", _interpolate(_named("nr")), " agents"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["participant"]), _normalize(["participants"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversations per cycle"]), _normalize([_interpolate(_named("n")), " conversation per cycle"]), _normalize([_interpolate(_named("n")), " conversations per cycle"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " per reviewee in a row"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["reviewee"]), _normalize(["reviewees"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["reviewer"]), _normalize(["reviewers"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewees"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspace users"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspace users except agents"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cycle ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non-recurring"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " assignment"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewing"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about assignments"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assignment"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will permanently delete the assignment and remove its data from the dashboard. All reviews related to it will remain in Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete assignment ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment deleted"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment duplicated"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assignments added yet"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create assignment"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment name"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle is refreshed and a new task list is created. Already given reviews still count towards the goal."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediately"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At the start of next cycle on ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When to publish changes?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes made to this assignment will be published with this assignment"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set assignment as active?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring on the assignment start day"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Friday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Friday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Friday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Friday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Friday of every month"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Monday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Monday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Monday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Monday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Monday of every month"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Saturday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Saturday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Saturday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Saturday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Saturday of every month"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Sunday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Sunday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Sunday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Sunday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Sunday of every month"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Thursday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Thursday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Thursday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Thursday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Thursday of every month"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Tuesday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Tuesday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Tuesday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Tuesday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Tuesday of every month"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Wednesday of every month"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Wednesday of every month"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Wednesday of every month"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth Wednesday of every month"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Wednesday of every month"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom recurrence"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st of every month"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekday"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle settings"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment name"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the start of each assignment cycle, a predetermined list of conversations is generated for evaluation."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced settings"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically fetch new conversations when review goal isn't met"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic fetching isn't possible with percentage (%) review goals"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a whole number, not a decimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow replacing conversations (Reviewer role and above)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacing conversations"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow reviewers to be assigned their own conversations for review"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum number of reviews must be at least 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify minimum reviews for each reviewee"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversations"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews in total"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each reviewer must do set number of reviews"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers must collectively review a set number of conversations"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum number of reviews must be at least 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review goal must be between 1 – 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review goal"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each reviewee must have minimum number of their total conversations reviewed"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers can choose conversations from the shared pool"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers are assigned conversations from the shared pool"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment approach"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All bots"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspace participants"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspace users"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reviewees"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who will be reviewed?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reviewees"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select who to review"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusions are only allowed when all workspace users or a group is selected"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers and reviewees"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Current: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace default"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which type of users should be selected as reviewees?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most active agent"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude reviewees (optional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select users to exclude"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude reviewers (optional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select users to exclude"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspace users except agents"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reviewers"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who will be doing the reviews?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reviewers"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews are not allowed in this workspace"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow self-reviews"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews are not allowed in this workspace."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Matching conversation today"]), _normalize(["Matching conversations today"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing as if the assignment started today."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future numbers may vary."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviewees selected"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviewers selected"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reviewee"]), _normalize(["Reviewees"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reviewer"]), _normalize(["Reviewers"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-fetching: allowed if not enough conversations"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Fridays from ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Mondays from ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Saturdays from ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Sundays from ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Thursdays from ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Tuesdays from ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " on Wednesdays from ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Daily from ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starts ", _interpolate(_named("startDateTime")), " and ends ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " date conditions"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " conversation and help desk conditions"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No conversation and ", _interpolate(_named("count")), " help desk condition"]), _normalize(["No conversation and ", _interpolate(_named("count")), " help desk conditions"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversation and no help desk condition"]), _normalize([_interpolate(_named("count")), " conversations and no help desk condition"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Approach: reviewers to choose conversations"]), _normalize(["Approach: reviewers are assigned conversations"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix the errors highlighted in the summary to continue"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal: reviewers must review all conversations"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Goal: reviewers must review one conversation"]), _normalize(["Goal: reviewers must review ", _interpolate(_named("count")), " conversations"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Goal: reviewers must review ", _interpolate(_named("count")), "% conversations"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal: no review goal set"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal: each reviewee must have all of their conversations reviewed"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Goal: each reviewee must have ", _interpolate(_named("count")), " of their total conversations reviewed"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Goal: each reviewee must have ", _interpolate(_named("count")), "% of their total conversations reviewed"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Goal: each reviewer must do one review"]), _normalize(["Goal: each reviewer must do ", _interpolate(_named("count")), " reviews"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Replacing: reviewers can not replace conversations"]), _normalize(["Replacing: reviewers can replace conversations"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-reviews: reviewers can be assigned own conversations"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date conditions set"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assignment name set"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " from ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bi-Weekly"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle length"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as Active"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment saved as Draft"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment set as Inactive"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as Inactive"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assignment"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment created"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The pending updates to the assignment will take effect in the next cycle, starting on ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish changes"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update assignment"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment updated"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses if the agent or bot wrapped up the conversation."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses if the agent wrapped up the conversation politely"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses whether the conversation was wrapped up"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses grammar, spelling, and style mistakes in both agent and bot interactions."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses agent's grammar, spelling and style mistakes"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses agent's grammar, spelling and style mistakes"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling and grammar"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the conversation for agent and bot greetings."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the entire conversation for typical greeting phrases"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the entire conversation for typical greetings"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greeting"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses whether the agent understands and acknowledges the customers' feelings"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifies cases where the agent mirrored customer sentiment or used caring words like 'acknowledge' and 'recognise'"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathy"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses whether the agent understands the customer's query or concern"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses whether the agent understands the customer's query or concern"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehension"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses how easily a text can be understood, considering word complexity and sentence length"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses how easily a text can be understood, considering word complexity and sentence length"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This message contains 1 overly long sentence, making it challenging to read"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This message contains ", _interpolate(_list(0)), " overly long sentences, making it challenging to read"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Readability"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the entire conversation for an offered solution"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses the entire conversation for an offered solution"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution offered"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses agent's tone throughout the conversation"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses agent's tone throughout the conversation"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect any mistakes from agent's messages"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not determine grammar mistakes"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No greeting phrase detected. Add new ones if necessary through your admin in Settings."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent wrapped up the conversation politely"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No closing phrase detected. Add new ones if necessary through your admin in Settings"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent mirrored the customer's sentiment or used caring words"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect if agent mirrored the customer's sentiment or used used caring words"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent used long sentences and complex words, making it potentially challenging for some to understand"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent used complex words, making it potentially challenging for some to understand"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent used long sentences, making it potentially challenging for some to understand"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent used language that is easy to understand"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough data to rate this category"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The agent struggled to grasp the customer's question/request"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The agent understood the customer's question/request well"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect whether the agent understood the customer's question/request"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent's tone during the conversation"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent's tone during the conversation"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent's tone during the conversation"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent's tone during the conversation"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent's tone during the conversation"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not determine the tone of the agent"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation was not wrapped up"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation was wrapped up"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect if conversation was wrapped up"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent lacked empathy towards the customer and their problems"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent showed empathy towards the customer and their problems"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect if agent showed empathy towards the customer and their problems "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer was not greeted during the conversation"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer was greeted during the conversation"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect typical greeting"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent did not provide a solution to the customer"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent offered solution to the customer"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not detect if agent offered a solution to the customer"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex words"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long sentences"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammar"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misspelling"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apologetic"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calm"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheerful"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curious"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportive"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 closing"]), _normalize([_interpolate(_named("n")), " closings"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA only checks for approved closings. If this category is set as critical, not using the approved closings will fail the review."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved closings"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved closings"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 exemption"]), _normalize([_interpolate(_named("n")), " exemptions"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words and phrases AutoQA ignores as spelling or grammar mistakes."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling and grammar exemptions"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA exemptions"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 greeting"]), _normalize([_interpolate(_named("n")), " greetings"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA only checks for approved greetings. If this category is set as critical, not using the approved greetings will fail the review."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved greetings"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved greetings"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA category"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an AutoQA category"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically assigned root causes:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account → Settings"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some categories leverage MS Azure, offering an extended range of functionality and supported languages. Review your ", _interpolate(_named("link")), " for more information, or contact an admin to do so."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Access an extended list of categories and supported languages by leveraging MS Azure. Review your ", _interpolate(_named("link")), " or contact an admin to do so."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA categories are assigned a score automatically, using a fixed rating scale. You can adjust the rating manually later. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an AutoQA category"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An automatically assigned score depends on the severity of mistakes and their number. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only relevant rating categories are shown to reviewers. Rating categories are shown based on the conditions you've set when creating a category."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onboarding"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select the rating category type for workspace \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers have to manually select the correct scorecard to see relevant rating categories for each conversation."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to change rating category type?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change categories"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change rating category type?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditional rating category"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static rating category"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category type"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category settings"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete all the ratings"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatively, you keep your statistics without deleting any data by archiving a category."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I understand that this will ", _interpolate(_named("deleteAllRatings")), " given for this category."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Tone is perfect"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show category only if"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Always\" will be ignored. It cannot be combined with other conditions."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tags"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select condition"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction score (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source type"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation channel"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A category needs a name"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the category to a scorecard"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category added"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An archived category is not visible on the scorecard, but it allows you to keep your statistics without deleting any data."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive category"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Archive ", _interpolate(_list(0)), " category"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category archived"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete category"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type \"delete category\" to delete this category"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete category"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete category ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category deleted"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category duplicated"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category group added"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category group deleted"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category group order changed"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category group updated"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category unarchived"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating category updated"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When an agent fails a critical category, all categories in that review fail automatically."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical rating category"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No categories in this group"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new rating category"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit category"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all categories and review data under this group"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting the category group will leave all the categories linked to it ungrouped."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("categoryName")), " category group"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing to delete the categories under the group will result in losing all the review data associated with these categories."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers can select more than one reason as a root cause"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers can select more than one reason as a root cause"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple reasons"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Tone"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new category group"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New group"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add \"Other\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root cause"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This root cause already exists"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root cause"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add scorecards to this rating category"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update category"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All ratings"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shown for"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative ratings"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative and neutral ratings"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, update"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories that are more important for you can have more weight in the ticket score calculation."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Weight (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about calibration"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about calibration"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding regular calibration sessions allows reviewers to keep their rating techniques aligned and ensure agents receive the same level of consistent and unbiased feedback."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View plans"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " is a process that helps your team stay on the same page on how all customer interactions should be handled and evaluated."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Calibration is available for our ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), " plans."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previously given reviews are not displayed when a conversation is added to the session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not visible"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previously given reviews are regarded as calibration reviews. Only one conversation review per reviewer will be added to the session. Message-specific reviews are not displayed."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility of previously given reviews"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Calibration is a process that helps your team stay on the same page on how all customer interactions should be handled and evaluated. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users have unlimited access to all reviews given during a calibration session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always see all reviews"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After submitting a review, users will see evaluations given by other reviewers during a calibration session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all calibration reviews after submitting a review"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users have unlimited access to all reviews given during a calibration session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always see all reviews"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After submitting a review, users will see evaluations given by other reviewers during a calibration session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all calibration reviews after submitting a review"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After submitting a review, users will see evaluations given by other reviewers during a calibration session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all calibration reviews after submitting a review"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users will only see their own reviews given during a calibration session."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See only own reviews"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration settings updated"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility of calibration reviews"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the threshold for dashboard metrics by filling in the gaps according to your teams internal metrics system. Review the threshold on the Dashboard."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold updated"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a moment..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make sure you have Admin permissions and ", _interpolate(_named("retry")), ", or ", _interpolate(_named("contact")), " for further help."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems we were not able to gain access to your help desk's API."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["try again"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're hooking up your help desk. You'll be redirected momentarily."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " authorised"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Head back to your ", _interpolate(_list(0)), " to add a new help desk connection."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems you've missed a step or two."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An organisation with the same email domain already exists"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connections page"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give us a moment while we check your permissions."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrating"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create a workspace"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To continue using Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost ready to use"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspace found"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch account"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To continue using Zendesk QA, your manager needs to assign you to a workspace."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deleting your account will permanently delete all the data we have on you - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all given ratings, conversation contents, workspaces, etc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnecting integration will disable all Zendesk QA notifications via Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack integration has been disconnected from Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define default notification settings for all new account users."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override settings for all users"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to override notifications settings for all account users"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users will not be able define their own notification settings."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings updated"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert when a critical category fails"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert when CSAT gets a response"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggers"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create demo workspace and data"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recreate demo data and keep the demo workspace."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove demo workspace and data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh demo data"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manage account \"", _interpolate(_named("accountName")), "\" demo mode"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo mode"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay on page"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave page"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave this page and lose all changes?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed the assignment"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assignment"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment completed"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assignments found"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no tasks for you to review."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust the assignment or wait for the next cycle to begin."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try adjusting the filters"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit assignment"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep an eye on this page for updates"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tasks for this cycle"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations to display"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark task as done"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve reviewed this user."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No self-reviews in this assignment"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by clicking on the calibration icon in the header of the conversation view."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations added"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assignment"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create calibration session"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your assignments"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other assignments"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active assignments"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assignments"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reviewees"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reviewees except me"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviewees found"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for reviewee"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All statuses"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reviewers"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reviewers except me"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to me"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviewers found"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for reviewer"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " days"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " days"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " day"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " days"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " days"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " days"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hours"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hours"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hour"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hours"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hours"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hours"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspace users"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current reviewer"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New goal for this cycle"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New reviewer"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reviewer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign conversations"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign conversation"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations to be moved"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current cycle"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit assignment"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviewers found"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search reviewer"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign conversations to me"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign conversations to other user"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " days"]), _normalize([_interpolate(_named("n")), " day"]), _normalize([_interpolate(_named("n")), " days"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " hours"]), _normalize([_interpolate(_named("n")), " hour"]), _normalize([_interpolate(_named("n")), " hours"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide completed cycles"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide completed sessions"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your goal"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a reason in order to remove this conversation"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain why you are removing this conversation"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove conversation"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to keep changes?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a reason in order to replace this conversation"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep changes"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replacement not found"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain why you are replacing this conversation"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still want to remove this conversation from the assignment? This will reduce the goal by 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace conversation"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete draft"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit draft"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign to me"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign to other user"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reassign and review"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove conversation"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a review"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View less"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Goal: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " reviewed"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews done"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show completed cycles"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show completed sessions"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation replaced"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle tasks list"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " later"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " second"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " seconds"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " days"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("minutes")), "m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("seconds")), "s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "mo"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "y"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just now"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You lack permissions. Contact your administrator for assistance."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for doesn't exist."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Contact support for assistance."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied. Not allowed to perform that action."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you're looking for either doesn't exist or you don't have access to it."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're going a little too fast and we're having trouble catching up."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved changes made"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " applied"]), _normalize([_interpolate(_named("n")), " applied"]), _normalize([_interpolate(_named("n")), " applied"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Former member"]), _normalize(["a Former member"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selected"]), _normalize([_interpolate(_named("n")), " selected"]), _normalize([_interpolate(_named("n")), " selected"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... and +1 more"]), _normalize(["... and +", _interpolate(_named("n")), " more"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 more"]), _normalize(["+", _interpolate(_named("n")), " more"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is not"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " replies"]), _normalize([_interpolate(_named("n")), " reply"]), _normalize([_interpolate(_named("n")), " replies"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Friday"]), _normalize(["Fridays"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Monday"]), _normalize(["Mondays"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Saturday"]), _normalize(["Saturdays"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sunday"]), _normalize(["Sundays"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Thursday"]), _normalize(["Thursdays"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tuesday"]), _normalize(["Tuesdays"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Wednesday"]), _normalize(["Wednesdays"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Admin"]), _normalize(["an Admin"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Account Manager"]), _normalize(["an Account Manager"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User"]), _normalize(["an User"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has expired."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-ins"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching people."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Owner"]), _normalize(["an Owner"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we start, read and consent to our terms and we’ll be good to go."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews done overall"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["an Agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["a Lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manager"]), _normalize(["a Manager"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reviewer"]), _normalize(["a Reviewer"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous user"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to copy"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danger zone"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (optional)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation required"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard unsaved changes?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass rate"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal settings"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read less"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewee"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews received/seen"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews done by workspace member"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binary scale"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-point scale"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-point scale"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-point scale"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select frequency"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey feedback"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time range"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved changes made"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You've been invited by ", _interpolate(_list(0)), " to join Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Users are automatically connected from the Admin Centre. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about user management"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some users are automatically provisioned and synced from your identity provider. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Account permission of “", _interpolate(_list(0)), "” changed"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about user permissions"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't ask me again"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All changes made on users page reflect to workspaces. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This means user's access to certain parts of Zendesk QA may change"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change account level permission"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " user removed"]), _normalize([_interpolate(_named("n")), " users removed"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you remove a user, they will lose access to your account. Their reviews, comments, and ratings will remain in Zendesk QA. ", _interpolate(_named("linebreak")), "Removing a user will not affect the number of seats in your subscription."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["This user will be removed from all workspaces you manage. ", _interpolate(_named("linebreak")), " Their reviews, comments, and ratings will remain intact."]), _normalize(["They will be removed from all workspaces you manage. ", _interpolate(_named("linebreak")), " All of their reviews, comments, and ratings will remain intact."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remove ", _interpolate(_named("name"))]), _normalize(["Remove ", _interpolate(_named("name")), " users"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see everything and manage all settings and billing"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see everything and manage personal and workspace settings, except billling"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account permissions"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace-specific permissions allow user to see (and) edit data within workspaces they're in."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bots appear here as conversations they've participated in are synced"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bots synced"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marking a bot as a user will grant them access to sign-in. Their historical bot data will remain available on dashboards."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such bots found"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try searching again using a different keyword or spelling."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for bots"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by bot name"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as user"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only admins and account managers can mark bots as users"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot name"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually marked"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewable"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bot is removed from auto-analysis and any assignments. New data won't appear in dashboards, ensuring accurate reporting and easier bot management."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generative bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow bot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to group(s)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No group"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from group(s)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select group"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account permission"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to workspace(s)"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last active"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account level role"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each user is assigned an Account and/or Workspace level permission. Admin permissions are now account level, whilst other permissions on the Workspace level."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from workspace(s)"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace permission"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions managed in group"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit details"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marking a user as a bot will remove their access to sign-in. Their historical user data will remain available on dashboards."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove the bot from auto-analysis and any assignments. New data won't appear in dashboards, ensuring accurate reporting and easier bot management."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude bot from reviews"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User marked as bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only permission type User can be marked as bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users with multiple accounts cannot be marked as a bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by user"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users, bots, and workspaces"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WORKSPACES"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users and workspaces"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permissions"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually added"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending invite"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM users"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action needed"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...and one more"]), _normalize(["...and ", _interpolate(_named("n")), " more"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change permission to"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remove and reinvite to change role of ", _interpolate(_named("n")), " pending invite user."]), _normalize(["Remove and reinvite to change role of ", _interpolate(_named("n")), " pending invite users"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edit details for ", _interpolate(_named("name"))]), _normalize(["Edit details for ", _interpolate(_named("n")), " selected users"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " user updated."]), _normalize([_interpolate(_named("n")), " users updated."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["This group contains 1 user from another workspace."]), _normalize(["This group contains ", _interpolate(_named("n")), " users from another workspace."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group archived"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived group"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create group"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group created"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled group"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanently delete this group?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group lead"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Role"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group hidden"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This group will not be visible anywhere else in Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only manage workspace permissions"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group not visible in Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled group"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another group already has this name"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An archived group already has this name"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your role only allows managing members of this group. Contact a user with higher permissions for further changes."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove as group lead"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group restored"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group saved"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as group lead"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group unarchived"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved changes"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your existing users to this group"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name or email"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add members"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" group visible"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Each member is ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about groups"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some attributes of groups are automatically provisioned and synced from your identity provider."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about Groups"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily organise your team members and mimic your organisational structure."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 member"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a group name"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group with this name already exists"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organise your team members and mimic your organisational structure."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No groups"]), _normalize(["1 group"]), _normalize([_interpolate(_named("count")), " groups"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new group or activate an archived one instead."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active groups available"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No archived groups"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such groups found"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try searching again using a different keyword or spelling."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups added"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["in these"]), _normalize(["in"]), _normalize(["in"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by group name"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Resend possible ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " seats left"]), _normalize([_interpolate(_named("n")), " seat left"]), _normalize([_interpolate(_named("n")), " seats left"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your seat count is currently limited, as your subscription is on pause. Reactivate it to add more users."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the limit of your account seats. You can replace users by deactivating an active user or adding more seats."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View payment details"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selected"]), _normalize([_interpolate(_named("n")), " selected"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " user selected"]), _normalize([_interpolate(_named("n")), " users selected"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " rows"]), _normalize([_interpolate(_named("n")), " row"]), _normalize([_interpolate(_named("n")), " rows"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search users in bulk by their names or email addresses."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste data here separated by commas or newlines"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk search users"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edit workspace permissions for ", _interpolate(_named("name"))]), _normalize(["Edit workspace permissions for ", _interpolate(_named("n")), " selected members"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit permissions"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workspace permission"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remove ", _interpolate(_named("name")), " from workspace"]), _normalize(["Remove ", _interpolate(_named("name")), " members from workspace"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " member removed"]), _normalize([_interpolate(_named("n")), " members removed"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Remove member"]), _normalize(["Remove members"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["This user will be removed from workspace."]), _normalize(["These users will be removed from workspace."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Workspace permission of “", _interpolate(_list(0)), "” changed."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add members"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See conversations and related reviews"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See everything in the workspace, but manage quizzes, assignments, disputes, and calibration sessions onlyr"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace permissions"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See and manage everything within the workspace"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See everything in the workspace, except for workspace settings"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connect users"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users from the workspaces you manage have been added to this workspace."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask an admin to do so."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite new users directly yourself or ask an admin to do so."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " or ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users to display"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " user added"]), _normalize([_interpolate(_named("n")), " users added"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Add members"]), _normalize(["Add member"]), _normalize(["Add ", _interpolate(_named("n")), " members"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workspace permission"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select users to the workspace"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Add members to ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste emails here separated by new lines or commas"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Matched ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " emails"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" with already connected users:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Could not match ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " emails"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check spelling or connect them later individually"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" with help desk users:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " user connected"]), _normalize([_interpolate(_named("n")), " users connected"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " user invited"]), _normalize([_interpolate(_named("n")), " users invited"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk connect"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk invite"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect from your help desk(s) to review their interactions"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Connect user"]), _normalize(["Connect users"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect individually"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want your connected users to access Zendesk QA, you can invite them here."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite connected users"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Invite user"]), _normalize(["Invite users"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip inviting"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select users to connect from your help desk(s) to review their interactions"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your subscription"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect help desk users"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your workspace a unique name"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If enabled, all existing account connections are automatically added to the new workspace."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all connections to this workspace"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create workspace"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace name"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create workspace"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users added directly to the account (without assigning a workspace) are assigned an Account-level role. You can add them to workspaces later."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited users are automatically assigned a User role on the Account level. You can upgrade them to an Admin role later."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These all work"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste emails here separated by newlines or commas."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " user invited by email"]), _normalize([_interpolate(_named("n")), " users invited by email"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users added to a workspace are automatically assigned a User role on the Account level. You can upgrade them to an Admin role later."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite users just to the account without adding them to a workspace"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited users are assigned only an Account permission and not added to any workspaces."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite users directly into a workspace and assign workspace permissions"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add users to workspace"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk invite"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite individually"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite just to the account"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite into a workspace"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Invite users"]), _normalize(["Invite user"]), _normalize(["Invite ", _interpolate(_named("n")), " users"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk invite users via email"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Delete user"]), _normalize(["Delete users"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselect"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite users via email"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select permission"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend invite"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected user"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending email invite user"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM user"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workspace"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI agents"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Centre"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality assurance"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce management"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Completed ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Completed ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Completed ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cycle ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit assignment"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time and set up automatic review assignments instead of manually finding and allocating conversations to review."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Due ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions can be added under 'Calibration' section in the side menu of the conversation view."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions added yet"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baseline"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations can be added to a session by clicking on the calibration icon in the header of the conversation view."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversations added yet"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No reviews have been given. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a calibration review"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump to"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load command palette."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing found."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found by ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading command palette…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pages, filters, conversations, settings…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private filter"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["From ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From your API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Showing ", _interpolate(_named("rangeStart")), " to ", _interpolate(_named("rangeEnd")), " of ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Showing ", _interpolate(_named("rangeStart")), " to ", _interpolate(_named("rangeEnd")), " of ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide sidepanel"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show sidepanel"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle sidebar"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes accepted"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes rejected"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open disputes"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes partially accepted"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews done"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews received"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews seen"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opened"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partially accepted"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " of total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rejected"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially accepted"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["review"]), _normalize(["reviews"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of their reviews which have been seen by reviewees"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of their reviews which have been seen"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputed reviewers"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputers"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes by category"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputes over time"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average resolution time"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average resolution time (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average resolution time (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total disputes"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response language"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No languages found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All languages"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predicted drivers"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No drivers found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All drivers"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordcloud words"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No words found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All words"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldest"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially accepted"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " reacted with ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select skin tone"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions found"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No categories found"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment size"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sizes found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sizes"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filters found"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hashtags found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No values found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All values"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No custom fields found"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk tags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No help desk tags found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All help desk tags"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading results…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question types"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No question types found"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reaction"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scorecards found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All scorecards"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scores found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All scores"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sources"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sources found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sources"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason of feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reasons found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reasons"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No surveys found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All surveys"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No channels found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All channels"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces found"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Quizzes"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quiz has been deleted or not yet published. Double-check the link or navigate back to quiz list."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This quiz has not been shared to a workspace you belong to. Ask an admin to add you to a workspace this quiz has access to."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz is unavailable"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz access given to workspace(s) you don't belong to. Unable to save and publish quiz."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask an admin to save and publish the quiz."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should mark at least one of the answers as the correct one."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're currently seeing a preview of the quiz."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select which workspaces can access the quiz. Users belonging to multiple workspaces can answer the quiz only once. Selecting \"All workspaces\" won't include newly created workspaces once the quiz has been published."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz access"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit preview mode"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take the quiz"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish quiz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz settings"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new quiz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share it"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks nice, doesn't it?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your answer has been submitted"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your quiz has been saved"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your quiz has been published"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your quiz has been removed"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add question"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An archived quiz and its results will no longer appear in the list of quizzes. You can still access the results of an archived quiz and unarchive it whenever needed."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive quiz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once published, a quiz cannot be changed or edited. All published quizzes will be accessible to every user on your account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish quiz?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this quiz? This action cannot be undone."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete quiz?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once submitted, your answers cannot be changed."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit quiz?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchiving the quiz makes it available in the quiz overview. It will allow previous participants to access their results and for new ones to take the quiz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive quiz"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate question"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (optional)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move question down"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move question up"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only questions with answers are displayed in the preview."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove question"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit quiz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled quiz"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz title"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the quiz and its results."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatively, you can still access the results by archiving the quiz."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete quiz"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the quiz and its results."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete quiz ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes help to boost team confidence and skills, deliver consistent experiences, and onboard new team members."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can search for quizzes by the quiz name or the author's name."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No quizzes created yet"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export list of quizzes"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg. score"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed date"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Author"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz / Author / Workspaces"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by quiz, author"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All quizzes"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz has been archived."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz has been unarchived."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This quiz received no responses from users in the selected workspace(s)."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This quiz received no responses before being archived."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No responses"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you'll see a breakdown of the quiz's answers.\nNo one has filled in your quiz yet - sharing it would surely help."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No responses yet"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average score:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Copy"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "'s quizzes"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can search the leaderboard for participant's name."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export leaderboard"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by participant"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle leaderboard"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This means you won't be able to filter for this hashtag on the dashboard and it won't autocomplete on comments anymore. This action is irreversible."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create hashtags"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create hashtags"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow members to create new hashtags"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag creation is locked"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag creation is unlocked"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag '", _interpolate(_list(0)), "' deleted"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag '", _interpolate(_list(0)), "' renamed to '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag added"]), _normalize([_interpolate(_named("count")), " hashtags added"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New name"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hashtags created"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rename hashtag \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate hashtags with commas or line breaks"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No worries. You can do so ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right here"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection linked to workspace"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach connection"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a connection to this workspace"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact your admin to make any changes here"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Zendesk QA to make any changes here"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connections under account"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems you haven't integrated with a customer service platform yet."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deleting a connection will remove conversations from workspace ", _interpolate(_named("workspaceName")), " and delete all data related to them, including reviews and disputes."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete connection"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete connection ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a connection"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Within the chosen time period, certain active AutoQA categories were not present on your scorecard. Conversations prior to your last change on ", _interpolate(_named("date")), ", may not have been analysed for these categories."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where all feedback given to you will appear. Nothing to show yet."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No received reviews."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only comment activity"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where all the feedback given by you will appear. Get started in the Conversations view."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No given reviews."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given reviews"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received reviews"])}
  }
}