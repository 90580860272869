import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_users_icon = _resolveComponent("users-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, _mergeProps({ "data-testid": "user-select" }, _ctx.$attrs, {
    "input-id": _ctx.inputId,
    mode: _ctx.multiple ? 'tags' : 'single',
    class: _ctx.$style.userPicker,
    "model-value": _ctx.modelValue,
    label: "name",
    placeholder: _ctx.placeholder || _ctx.$t('coaching.sessions.new_thread.user_placeholder'),
    options: _ctx.usersList,
    "no-results-text": _ctx.$t('universal.no_users_found'),
    multiline: _ctx.multiline,
    "is-loading": _ctx.isLoading,
    "close-on-select": !_ctx.multiple,
    "filter-results": false,
    object: "",
    onSearchChange: _ctx.getUsers,
    onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open'))),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }), {
    option: _withCtx(({ option }) => [
      (option.id === 'separator')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.separator)
          }, null, 2 /* CLASS */))
        : (option.id === null)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(_ctx.$style.more)
            }, _toDisplayString(option.name), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
    ]),
    icon: _withCtx(({ open }) => [
      open
        ? (_openBlock(), _createBlock(_component_search_icon, {
            key: 0,
            class: "icon-m"
          }))
        : (_openBlock(), _createBlock(_component_users_icon, {
            key: 1,
            class: "icon-m"
          }))
    ]),
    afterlist: _withCtx(() => [
      (_ctx.remainingUserCount > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('universal.n_more', { n: _ctx.$n(_ctx.remainingUserCount) }, _ctx.remainingUserCount)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["input-id", "mode", "class", "model-value", "placeholder", "options", "no-results-text", "multiline", "is-loading", "close-on-select", "onSearchChange"]))
}