import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.workspaceName)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.colorDot),
      style: _normalizeStyle({ backgroundColor: _ctx.workspace && _ctx.workspace.color })
    }, null, 6 /* CLASS, STYLE */),
    _createElementVNode("span", null, _toDisplayString(_ctx.workspace && _ctx.workspace.name), 1 /* TEXT */)
  ], 2 /* CLASS */))
}