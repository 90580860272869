import type { RouteRecordRaw } from 'vue-router'

import { setDirectInstallFlag } from '@/utils/direct-install'
import { getFirstParam } from '@/utils/routes'
import { hasZendeskProxy } from '@/config'
import { session } from '@/composables/useSession'

const isProxied = hasZendeskProxy || session.features.zendeskProxyDev

export const subscriptionRoute: RouteRecordRaw = {
  path: 'subscription',
  meta: { requiredAccountRole: ['ADMIN'], hideProxied: isProxied },
  children: [
    {
      path: '',
      name: 'settings.subscription',
      component: () => import('./views/account/SubscriptionIndexView.vue'),
    },
  ],
}

const routes: RouteRecordRaw[] = [
  {
    path: '/settings',
    name: 'settings',
    redirect: { name: 'profile.general' },
    meta: { includeChildrenOnlyInCommandPalette: true, requiresPayment: isProxied },
    component: () => import('./SettingsRoutes.vue'),
    children: [
      {
        path: 'connections',
        name: 'settings.connections',
        component: () => import('./views/account/ConnectionsView.vue'),
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'] },
        props: ({ query }) => {
          const { connection, code, newToken, connectionId, advancedOptions } = query
          return {
            code,
            connection,
            new: query.new,
            freshToken: !!newToken,
            connectionId,
            advancedOptions: advancedOptions === 'true',
          }
        },
      },
      {
        path: 'notifications',
        name: 'settings.notifications',
        component: () => import('./views/account/NotificationsView.vue'),
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'] },
        props: ({ query }) => ({ code: query.code }),
      },
      {
        path: 'company',
        name: 'settings.profile',
        component: () => import('./views/account/GeneralView.vue'),
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'] },
      },
      subscriptionRoute,
      {
        path: 'auto-qa/custom-categories/create',
        name: 'settings.auto_qa.create',
        component: () => import('./views/account/autoqa/AutoQaCustomTypeView.vue'),
        meta: {
          requiredAccountRole: ['ADMIN', 'MANAGER'],
          requiredFlag: 'autoQa',
        },
        props: () => ({ mode: 'CREATE' }),
      },
      {
        path: 'auto-qa/custom-categories/:id',
        name: 'settings.auto_qa.edit',
        component: () => import('./views/account/autoqa/AutoQaCustomTypeView.vue'),
        meta: {
          requiredAccountRole: ['ADMIN', 'MANAGER'],
          requiredFlag: 'autoQa',
        },
        props: () => ({ mode: 'EDIT' }),
      },
      {
        path: 'auto-qa/:categoryType?',
        name: 'settings.auto_qa',
        component: () => import('./views/account/AutoQaView.vue'),
        meta: {
          requiredAccountRole: ['ADMIN', 'MANAGER'],
          requiredFlag: 'autoQa',
        },
      },
      {
        path: 'spotlight/custom-spotlight/create',
        name: 'settings.spotlight.create',
        component: () => import('./views/account/autoqa/AutoQaCustomTypeView.vue'),
        meta: {
          requiredAccountRole: ['ADMIN', 'MANAGER'],
        },
        props: () => ({ mode: 'CREATE' }),
      },
      {
        path: 'spotlight/custom-spotlight/:id',
        name: 'settings.spotlight.edit',
        component: () => import('./views/account/autoqa/AutoQaCustomTypeView.vue'),
        meta: {
          requiredAccountRole: ['ADMIN', 'MANAGER'],
        },
        props: () => ({ mode: 'READ' }),
      },
      {
        path: 'spotlight/:categoryType?',
        name: 'settings.spotlight',
        component: () => import('./views/account/autoqa/AutoQaCustomTypeView.vue'),
        meta: {
          requiredAccountRole: ['ADMIN', 'MANAGER'],
          requiredFlag: {
            condition: 'OR',
            flags: ['callTranscriptions', 'callTranscriptionsBilling', 'talkTranscriptionsEnabled'],
          },
        },
        props: () => ({ mode: 'READ' }),
      },
      {
        path: 'survey',
        name: 'settings.survey',
        component: () => import('./views/account/SurveysView.vue'),
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'], requiredFlag: 'surveys' },
      },
      {
        path: 'authentications',
        name: 'settings.authentications',
        component: () => import('./views/account/AuthenticationsView.vue'),
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'], hideProxied: isProxied },
      },
      {
        path: 'scorecard',
        name: 'settings.scorecard',
        component: () => import('./views/account/ScorecardView.vue'),
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'] },
      },
      {
        path: 'reviews',
        name: 'settings.reviews',
        redirect: { name: 'settings.reviews.scorecards' },
        meta: { requiredAccountRole: ['ADMIN', 'MANAGER'], requiredFlag: 'advancedScorecardsDev' },
        children: [
          {
            path: 'scorecards',
            name: 'settings.reviews.scorecards',
            component: () => import('./views/account/reviews/ScorecardsView.vue'),
          },
          {
            path: 'scorecards/:id',
            name: 'settings.reviews.scorecards.edit',
            component: () => import('./views/account/reviews/CreateScorecardView.vue'),
          },
          {
            path: 'scorecards/new',
            name: 'settings.reviews.scorecards.new',
            component: () => import('./views/account/reviews/CreateScorecardView.vue'),
          },
          {
            path: 'categories',
            name: 'settings.reviews.categories',
            component: () => import('./views/account/reviews/CategoriesView.vue'),
          },
          {
            path: 'categories/new',
            name: 'settings.reviews.categories.new',
            component: () => import('./views/account/reviews/CategoriesView.vue'),
          },
          {
            path: 'root-causes',
            name: 'settings.reviews.rootCauses',
            component: () => import('./views/account/reviews/RootCausesView.vue'),
          },
          {
            path: 'root-causes/new',
            name: 'settings.reviews.rootCauses.new',
            component: () => import('./views/account/reviews/RootCausesView.vue'),
          },
        ],
      },
      {
        path: 'profile',
        children: [
          {
            path: 'general',
            name: 'profile.general',
            component: () => import('./views/profile/GeneralView.vue'),
          },
          {
            path: 'templates',
            name: 'profile.templates',
            component: () => import('./views/profile/CommentTemplatesView.vue'),
          },
          {
            path: 'templates/create',
            name: 'profile.templates.create',
            component: () => import('./views/profile/CommentTemplatesCreateView.vue'),
          },
          {
            path: 'templates/:id/edit',
            name: 'profile.templates.edit',
            component: () => import('./views/profile/CommentTemplatesCreateView.vue'),
            props: ({ params }) => ({
              id: params.id,
            }),
          },
          {
            path: 'logins',
            name: 'profile.logins',
            component: () => import('./views/profile/LoginView.vue'),
            meta: { hideProxied: isProxied },
          },
          {
            path: 'notifications',
            name: 'profile.notifications',
            component: () => import('./views/profile/NotificationsView.vue'),
          },
        ],
      },
    ],
  },
  /**
   * Helpdesk OAuth callback view
   */
  {
    path: '/integrate/:integration',
    component: () => import('./views/account/IntegrationCallbackView.vue'),
    props: ({ query, params }) => ({
      code: query.code,
      state: query.state,
      integrationKey: params.integration,
    }),
    meta: { skipAuth: 'optional' },
  },
  /**
   * Helpdesk OAuth redirect proxy for ZD provisioned accounts
   * Stores ZD subdomain to redirect back to ZQA
   */
  {
    path: '/integrate-zd/:subdomain/:integrationKey',
    name: 'integrate-zd',
    component: () => import('./views/account/IntegrationZDRedirectView.vue'),
    meta: { skipAuth: 'optional' },
  },
  /**
   * Direct connection install - from helpdesk marketplaces usually
   */
  {
    path: '/settings/connections/install/:source',
    component: () => import('./views/account/ConnectionInstallView.vue'),
    meta: { requiredAccountRole: ['ADMIN', 'MANAGER'], hideNav: true },
    beforeEnter(to, _from, next) {
      setDirectInstallFlag(getFirstParam(to.params.source))
      next()
    },
    props: ({ params }) => ({ source: params.source }),
  },
  /**
   * Trial ended view - payment is required to continue
   */
  {
    path: '/trial-ended',
    name: 'trial-ended',
    component: () => import('./views/TrialEndedView.vue'),
  },
  /**
   * Non-admin workspaceless users are redirected here
   */
  {
    path: '/not-configured',
    name: 'unconfigured-account',
    component: () => import('./views/UnconfiguredAccountView.vue'),
  },
  /**
   * No conversations found view - sufficient conversations are needed, demo tickets does not count
   */
  {
    path: '/no-conversations-found',
    name: 'no-conversations-found',
    component: () => import('./views/NoConversationsView.vue'),
  },
]

export default routes
