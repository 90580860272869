import { computed, ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { isEqual } from 'lodash-es'
import pMemoize from 'p-memoize'
import ExpiryMap from 'expiry-map'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { createOrUpdateDraft, deleteDraft, type Draft, getDrafts } from '@/modules/conversations/api/drafts'
import type { CommentTag } from '@/modules/shared/TicketContent/types/feedback'
import { bus } from '@/utils/bus'

const VERSION = 'v2'
export interface DraftRating {
  categoryId: number
  rating?: number
  cause?: string[]
}

export interface ReviewDraftContent {
  ratings: DraftRating[]
  comment: string
  userId?: number | string
  scorecardId?: string
  commentTags?: CommentTag[]
}

export default createSharedComposable(() => {
  const { connectionId, conversationId, messageId } = useTicketRouteParams()
  const drafts = ref<Draft[]>([])

  const _memGetDrafts = pMemoize(getDrafts, {
    cache: new ExpiryMap(1000),
    cacheKey: JSON.stringify,
  })

  const fetchDrafts = async () => {
    const res = await _memGetDrafts()
    drafts.value = res.drafts
  }

  const _isCurrentTicketDraft = (d: Draft) =>
    d.connectionId === connectionId.value?.toString() &&
    d.conversationId === conversationId.value &&
    ((!messageId.value && !d.messageId) || d.messageId === messageId.value)

  const currentDraftContent = computed<ReviewDraftContent | undefined>(() => {
    const draft = drafts.value.find(_isCurrentTicketDraft)
    return draft?.content && JSON.parse(draft.content)
  })

  const setDraft = async (content: ReviewDraftContent) => {
    if (!connectionId.value || !conversationId.value) return

    const draft = {
      connectionId: connectionId.value?.toString(),
      conversationId: conversationId.value,
      messageId: messageId.value || undefined,
      content: JSON.stringify(content),
      version: VERSION,
    }

    if (currentDraftContent.value && isEqual(currentDraftContent.value, JSON.parse(draft.content))) return

    await createOrUpdateDraft(draft)

    drafts.value = [...drafts.value.filter((d) => !_isCurrentTicketDraft(d)), draft]

    bus.$emit('change-review-draft', { isRemoved: false })
  }

  const _getDeleteDraftRequest = (discarded: boolean) => {
    return {
      connectionId: connectionId.value?.toString(),
      conversationId: conversationId.value,
      messageId: messageId.value || undefined,
      version: VERSION,
      discarded,
    }
  }

  let removing = false
  const removeDraft = async (discarded = true) => {
    if (removing || !connectionId.value || !conversationId.value) return

    if (!currentDraftContent.value) return

    removing = true
    await deleteDraft(_getDeleteDraftRequest(discarded))

    drafts.value = drafts.value.filter((d) => !_isCurrentTicketDraft(d))
    removing = false

    bus.$emit('change-review-draft', { isRemoved: true })
  }

  return {
    drafts,
    fetchDrafts,
    currentDraftContent,
    setDraft,
    removeDraft,
  }
})
