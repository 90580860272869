import { createSharedComposable } from '@vueuse/core'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import type { AdvancedScorecard_GetRootCausesResponse as GetRootCausesResponse } from '@zendesk/zqa-services/scorecards'
import { getRootCauses, createRootCause } from '../api'

export default createSharedComposable(() => {
  const queryClient = useQueryClient()

  const { data: rootCauses } = useQuery({
    queryKey: ['root-causes'],
    queryFn: () => getRootCauses(),
  })

  const { mutateAsync: add } = useMutation({
    mutationFn: createRootCause,
    onMutate: async (newRootCause) => {
      await queryClient.cancelQueries({ queryKey: ['root-causes'] })

      const previousRootCauses = queryClient.getQueryData(['root-causes'])

      // Optimistically update the cache with the new root cause
      queryClient.setQueryData(['root-causes'], (oldData: GetRootCausesResponse) => ({
        ...oldData,
        data: [
          ...oldData.data,
          {
            id: oldData.total + 1,
            cause: newRootCause.cause,
            categories: [],
            createdAt: '',
            updatedAt: '',
          },
        ],
        total: oldData.total + 1,
      }))

      return { previousRootCauses }
    },
    onError: (_err, _newRootCause, context) => {
      queryClient.setQueryData(['root-causes'], context.previousRootCauses)
    },
    onSettled: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['root-causes'] })
    },
  })
  return { rootCauses, addRootCause: add }
})
