import { SCALE_VALUES, type ScaleType } from '@/data/scales'
import { RequireReasonVisibility } from '@/types/category'
import { isNegativeRating, isNeutralRating, isRated } from '@/modules/shared/Review/utils/index'

interface Params {
  rating?: number
  scale: ScaleType
  requireReason: boolean
  cause?: string[]
  requireReasonVisibility: RequireReasonVisibility
}

export const rootCauseMissing = ({ rating, scale, requireReason, cause, requireReasonVisibility }: Params) => {
  if (!isRated({ rating }) || !requireReason || cause?.length) return false

  const activeScale = SCALE_VALUES[scale]

  switch (requireReasonVisibility) {
    case RequireReasonVisibility.All:
      return true
    case null:
    case RequireReasonVisibility.Negative: {
      return isNegativeRating(rating!, activeScale)
    }
    case RequireReasonVisibility.NegativeAndNeutral: {
      const negativeRating = isNegativeRating(rating!, activeScale)
      const neutralRating = isNeutralRating(rating!, activeScale)
      return negativeRating || neutralRating
    }
  }
}
