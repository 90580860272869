import { VueRenderer } from '@tiptap/vue-3'
import { type SuggestionKeyDownProps, type SuggestionProps } from '@tiptap/suggestion'
import tippy from 'tippy.js'
import i18n from '@/i18n'

import CommandsList from '../CommandsList.vue'

export default {
  items: ({ query }) => {
    return [
      {
        title: i18n.t('settings.categories.snippets.dynamic_content.label'),
        icon: '{··}',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent('<span data-id="placeholder" data-type="mention" data-label="{··}" class="snippet"></span>')
            .run()
        },
      },
    ]
      .filter((item) => item.title.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 10)
  },

  render: () => {
    let component
    let popup

    return {
      onStart: (props: SuggestionProps) => {
        component = new VueRenderer(CommandsList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          theme: 'light dropdown',
          arrow: false,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props: SuggestionProps) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }
        return component.ref?.onKeyDown(props)
      },

      onExit() {
        if (popup[0]) popup[0].destroy()
        component.destroy()
      },
    }
  },
}
