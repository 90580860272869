import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass([_ctx.$style.root, _ctx.hasSidebar && _ctx.$style.rootWithSidebar]),
    "data-testid": _ctx.chartId
  }, [
    (_ctx.width)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["text-xs font-semibold", _ctx.$style.tooltip])
          }, [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.arrow),
              "data-popper-arrow": ""
            }, null, 2 /* CLASS */), [
              [_vShow, _ctx.dotTooltip]
            ]),
            (_ctx.dotTooltip)
              ? _renderSlot(_ctx.$slots, "dot-tooltip", {
                  key: 0,
                  class: "text-sm font-semibold",
                  dot: _ctx.dotTooltip
                })
              : (_ctx.barTooltip)
                ? _renderSlot(_ctx.$slots, "bar-tooltip", {
                    key: 1,
                    bar: _ctx.barTooltip
                  })
                : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */), [
            [_vShow, (_ctx.$slots['dot-tooltip'] && _ctx.dotTooltip) || (_ctx.$slots['bar-tooltip'] && _ctx.barTooltip)]
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["text-gray-60 text-xs font-medium", _ctx.$style.axisNames])
    }, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('csat_dashboard.csat_timeline.left_axis', [_ctx.questionTypeLabel])), 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('csat_dashboard.csat_timeline.right_axis')), 1 /* TEXT */)
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.legend),
      style: _normalizeStyle({ top: _ctx.legendTop })
    }, [
      _renderSlot(_ctx.$slots, "legend")
    ], 6 /* CLASS, STYLE */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}