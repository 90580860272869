import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-sm" }
const _hoisted_3 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_funnel_graph = _resolveComponent("funnel-graph")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "csat-survey-funnel",
    title: _ctx.$t('csat_dashboard.funnel.title'),
    params: _ctx.filterParams,
    "api-method": _ctx.getCsatSurveyFunnel,
    "get-has-data": _ctx.getHasData
  }, {
    rawData: _withCtx(({ data: response }) => [
      _createVNode(_component_funnel_graph, {
        class: _normalizeClass(_ctx.$style.root),
        data: _ctx.formatResponse(response)
      }, {
        tooltip: _withCtx(({ hovered }) => [
          (_ctx.data[hovered] && hovered !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data[hovered].relative || 0) + "% (" + _toDisplayString(_ctx.$t('csat_dashboard.funnel.tickets_count', _ctx.data[hovered].value)) + ") ", 1 /* TEXT */),
                (_ctx.data[hovered].change !== null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('csat_dashboard.funnel.since_last_period', [_ctx.formatPeriodPercentage(_ctx.data[hovered].change)])), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]))
            : (_ctx.data[hovered])
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('csat_dashboard.funnel.tickets_count', _ctx.data[hovered].value)), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "data"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "params", "api-method", "get-has-data"]))
}