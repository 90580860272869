import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-gray-70 mb-1 text-xs font-medium"
}
const _hoisted_2 = ["id", "name", "value"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: "q-toggle__wrapper flex items-center text-sm",
    onInput: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("legend", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["q-toggle font-medium", [{ 'q-toggle--condensed': _ctx.condensed, 'q-toggle-dark': _ctx.dark, 'q-toggle--transparent': _ctx.transparent }]])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          _withDirectives(_createElementVNode("input", {
            id: `${_ctx.id}-${i}`,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
            class: "q-toggle__input sr-only",
            name: _ctx.name || _ctx.inputName,
            value: option.value,
            type: "radio"
          }, null, 8 /* PROPS */, _hoisted_2), [
            [_vModelRadio, _ctx.val]
          ]),
          _createElementVNode("label", {
            for: `${_ctx.id}-${i}`,
            class: _normalizeClass(["q-toggle__label q-toggle__label--left whitespace-nowrap leading-none", {
            'q-toggle-dark__label': _ctx.dark,
            'text-xs': _ctx.condensed,
            'text-sm': !_ctx.condensed,
          }])
          }, [
            (option.icon)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
                  key: 0,
                  class: "icon-m mr-1"
                }))
              : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(option.label) + " ", 1 /* TEXT */),
            ('count' in option)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["text-xs font-medium", _ctx.$style.count])
                }, [
                  _createElementVNode("span", null, _toDisplayString(option.count), 1 /* TEXT */)
                ], 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ], 10 /* CLASS, PROPS */, _hoisted_3)
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 32 /* NEED_HYDRATION */))
}