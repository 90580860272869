import { ListPinFoldersPayload_SortField as PinFolderSort } from '@zendesk/zqa-services/pins'

export const pinSortOptions: Record<string, PinFolderSort> = {
  'pins.sidebar.sort_option_alphabetically': PinFolderSort.ALPHABETICALLY,
  'pins.sidebar.sort_option_created': PinFolderSort.CREATED,
  'pins.sidebar.sort_option_edited': PinFolderSort.UPDATED,
  'pins.sidebar.sort_option_most_pins': PinFolderSort.PINS,
}

export const analyticsLabels: Record<PinFolderSort, string> = {
  [PinFolderSort.ALPHABETICALLY]: 'alphabetically',
  [PinFolderSort.CREATED]: 'created',
  [PinFolderSort.UPDATED]: 'edited',
  [PinFolderSort.PINS]: 'most_pins',
}
