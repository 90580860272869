import { type RouteRecordRaw } from 'vue-router'
import { MESSAGE_PATH, TICKET_PATH } from '@/composables/useTicketRouteParams'

const defineCsatRoute = (name: string): RouteRecordRaw => ({
  path: 'csat',
  name: `${name}.csat`,
  component: () => import('@/modules/shared/TicketContent/SurveySidebar.vue'),
})

const defineDisputeRoute = (name: string): RouteRecordRaw => ({
  path: 'disputes/:disputeId?',
  name: `${name}.dispute`,
  component: defineReviewSidebar,
  meta: { requiredFlag: 'disputes' },
})

const defineReviewSidebar = () => import('@/modules/shared/TicketContent/ReviewSidebar.vue')

export interface MessageRouteParams {
  messageId: string
  transcriptionId?: string
  transcriptionMessageId?: string
}

const defineMessageRoute = (name: string): RouteRecordRaw => ({
  path: MESSAGE_PATH,
  name: `${name}.message`,
  component: defineReviewSidebar,
})

const defineReviewRoute = (name: string, path: string): RouteRecordRaw => ({
  path: `${path}/${TICKET_PATH}`,
  name: `${name}.review`,
  component: defineReviewSidebar,
})

export const sidebarRoutes = (name: string, path: string): RouteRecordRaw[] => [
  defineCsatRoute(name),
  defineDisputeRoute(name),
  defineMessageRoute(name),
  defineReviewRoute(name, path),
]

export const assignmentsSidebarRoutes = (): RouteRecordRaw[] => [
  defineCsatRoute('assignments'),
  defineDisputeRoute('assignments'),
  defineMessageRoute('assignments'),
  {
    path: '/assignments/:assignmentId?',
    name: 'assignments.review',
    component: defineReviewSidebar,
  },
]

export const extensionSidebarRoutes = (): RouteRecordRaw[] => [
  defineCsatRoute('extension.conversations'),
  defineDisputeRoute('extension.conversations'),
  defineReviewRoute('extension.conversations', '/extension/conversations'),
]
