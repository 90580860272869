import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.breadSequence),
    "data-testid": "sunburst-breadcrumbs"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("span", {
        key: item.id,
        style: _normalizeStyle({ background: _ctx.colorGetter(item) }),
        class: _normalizeClass(["text-xs font-semibold leading-loose", {
        [_ctx.$style.crumb]: true,
        [_ctx.$style.firstCrumb]: i === 0,
        [_ctx.$style.lastCrumb]: i === _ctx.items.length - 1,
      }]),
        "data-track-id": "Visual filters breadcrumb clicked",
        onClick: ($event: any) => (_ctx.zoomOut(_ctx.items.length - 1 - i, item))
      }, [
        (i > 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.$style.arrowStart)
            }, null, 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        _createTextVNode(" " + _toDisplayString(_ctx.getName(item, i)) + " ", 1 /* TEXT */),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.arrowEnd),
          style: _normalizeStyle({ 'border-left-color': _ctx.colorGetter(item) })
        }, null, 6 /* CLASS, STYLE */)
      ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}