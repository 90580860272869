export default (interactedNode) => {
  const selectedSlices: any[] = []
  let node = interactedNode
  while (node) {
    selectedSlices.push({
      category: node.data.category,
      value: node.data.value,
      name: node.data.name,
      size: node.value,
    })
    node = node.parent
  }
  return selectedSlices.reverse()
}
