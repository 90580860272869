import { watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { AssignmentV2_GetUserAssignmentConversationsResponse_ConversationStatus as Status } from '@zendesk/zqa-services/assignments_v2'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/sessionStorage'
import useTasks from '@/composables/useTasks'
import { getAssignmentInfo } from '@/modules/tasks/api'
import useTicketRouteParams from '@/composables/useTicketRouteParams'

const STORAGE_KEYS = {
  REVIEWEE: 'assignmentFilterReviewee',
  REVIEWER: 'assignmentFilterReviewer',
  STATUS: 'assignmentFilterStatus',
}

export default createSharedComposable(() => {
  const { state, getAssignmentConversationsList, redirectToConversation, setActiveAssignment, getAllAssignments } =
    useTasks()
  const { assignmentId } = useTicketRouteParams()
  const route = useRoute()

  const isSelfReviewAllowed = computed(() => state.activeAssignment?.settings?.goal.config.allowSelfAssignment)
  const isEditView = computed(() => route.name.toString().startsWith('workspace.assignments'))

  const {
    data: assignmentInfo,
    isFetching: fetchingAssignmentInfo,
    refetch: refetchAssignmentInfo,
  } = useQuery({
    queryKey: ['assignment-info', assignmentId],
    queryFn: () => {
      if (!assignmentId.value || isEditView.value) return
      if (
        route.name.toString().startsWith('extension.tasks') &&
        !state.allAssignments.some((a) => a.id === assignmentId.value)
      ) {
        return {
          cycle: undefined,
          goal: 0,
          reviewCount: 0,
          reviewers: [],
          botsWithTasks: [],
          revieweesWithTasks: [],
          reviewersWithTasks: [],
        }
      }

      return getAssignmentInfo(assignmentId.value)
    },
    enabled: computed(() => !!assignmentId.value && !isEditView.value),
  })

  function setRevieweeFilter(id: string | number, isInitialLoad = false) {
    const isBot = typeof id === 'string'
    const defaultRevieweeId = isSelfReviewAllowed.value ? 0 : 1
    const { botsWithTasks = [], revieweesWithTasks = [] } = assignmentInfo.value || {}

    let revieweeId: number
    let botRevieweeId: string

    if (isBot) {
      if (botsWithTasks.includes(id as string)) {
        botRevieweeId = id as string
      }
    } else {
      revieweeId = (id as number) || defaultRevieweeId
      const isValidReviewee = revieweeId <= 1 || revieweesWithTasks.includes(revieweeId.toString())
      if (!isValidReviewee) {
        revieweeId = defaultRevieweeId
      }
    }

    state.listParams.revieweeId = revieweeId ?? null
    state.listParams.botRevieweeId = botRevieweeId ?? null

    if (!isInitialLoad) {
      saveToSessionStorage(STORAGE_KEYS.REVIEWEE, id)
      getAssignmentConversationsList(true)
    }
  }

  function setReviewerFilter(id: number, isInitialLoad = false) {
    if (!state.activeAssignment?.settings?.goal.config.assigned) return (state.listParams.reviewerId = null)

    let defaultId = 0
    if (state.activeAssignment?.isReviewer && state.activeAssignment?.settings?.goal.config.assigned) {
      defaultId = 2
    }

    const reviewerId = id ?? defaultId

    const isValidReviewer = reviewerId <= 2 || assignmentInfo.value?.reviewersWithTasks.includes(reviewerId.toString())

    state.listParams.reviewerId = isValidReviewer ? reviewerId : defaultId

    if (!isInitialLoad) {
      saveToSessionStorage(STORAGE_KEYS.REVIEWER, reviewerId)
      getAssignmentConversationsList(true)
    }
  }

  function setStatusFilter(value: Status, isInitialLoad = false) {
    state.listParams.status = value ?? Status.TODO

    if (!isInitialLoad) {
      saveToSessionStorage(STORAGE_KEYS.STATUS, value)
      getAssignmentConversationsList(true)
    }
  }

  function initializeFilters() {
    const filters = Object.fromEntries(
      Object.entries(STORAGE_KEYS).map(([key, storageKey]) => [key.toLowerCase(), getFromSessionStorage(storageKey)]),
    )

    setRevieweeFilter(filters.reviewee, true)
    setReviewerFilter(filters.reviewer, true)
    setStatusFilter(filters.status, true)
  }

  watch(
    () => assignmentInfo.value,
    async () => {
      if (!assignmentInfo.value) return
      if (!state.allAssignments?.length) {
        await getAllAssignments(true)
      } else {
        setActiveAssignment(assignmentId.value)
      }
      initializeFilters()
      await getAssignmentConversationsList(true)
      redirectToConversation()
    },
    { immediate: true },
  )

  return {
    assignmentInfo,
    fetchingAssignmentInfo,
    refetchAssignmentInfo,
    setRevieweeFilter,
    setReviewerFilter,
    setStatusFilter,
  }
})
