import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-gray-80 text-xl font-medium" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_select = _resolveComponent("filter-select")!
  const _component_session = _resolveComponent("session")!
  const _component_ticket_list_loader = _resolveComponent("ticket-list-loader")!
  const _component_check_square_icon = _resolveComponent("check-square-icon")!
  const _component_infinite_loading = _resolveComponent("infinite-loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container, "flex flex-col"])
  }, [
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createVNode(_component_filter_select, {
        modelValue: _ctx.roleOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.roleOption) = $event)),
        options: _ctx.filterOptions
      }, null, 8 /* PROPS */, ["modelValue", "options"])
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.list)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessions, (session) => {
        return (_openBlock(), _createBlock(_component_session, {
          key: session.id,
          session: session
        }, null, 8 /* PROPS */, ["session"]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createVNode(_component_infinite_loading, {
        identifier: _ctx.roleOption,
        onInfinite: _ctx.fetchActionItems
      }, {
        spinner: _withCtx(() => [
          _createVNode(_component_ticket_list_loader, {
            class: _normalizeClass(_ctx.$style.loader)
          }, null, 8 /* PROPS */, ["class"])
        ]),
        complete: _withCtx(() => [
          (!_ctx.sessions.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.emptyContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.emptyIcon)
                }, [
                  _createVNode(_component_check_square_icon)
                ], 2 /* CLASS */),
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('coaching.sessions.insights.action_items.empty.title')), 1 /* TEXT */),
                _createElementVNode("div", {
                  class: _normalizeClass(["text-gray-60 text-sm font-normal", _ctx.$style.emptyDescription])
                }, _toDisplayString(_ctx.$t('coaching.sessions.insights.action_items.empty.description')), 3 /* TEXT, CLASS */)
              ], 2 /* CLASS */))
            : (_openBlock(), _createElementBlock("span", _hoisted_2))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["identifier", "onInfinite"])
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}