import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-sm" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = { class: "text-gray-50" }
const _hoisted_5 = { class: "text-gray-80" }
const _hoisted_6 = { class: "text-gray-80 mb-2 text-base font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_map = _resolveComponent("tree-map")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_pin_dashboard_button = _resolveComponent("pin-dashboard-button")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "csat-tags-card",
    "api-method": _ctx.getCsatTags,
    title: _ctx.$t('csat_dashboard.tags.title'),
    "text-in-brackets": _ctx.tagsCount,
    params: _ctx.params,
    "data-rows-prop": "tags",
    onDataupdate: _ctx.setCount
  }, {
    rawData: _withCtx(({ data }) => [
      data
        ? (_openBlock(), _createBlock(_component_tree_map, {
            key: 0,
            data: _ctx.mapData(data)
          }, {
            tooltip: _withCtx(({ hovered }) => [
              hovered
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(hovered.name), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_3, [
                      _createTextVNode(_toDisplayString(hovered.subtext) + " ", 1 /* TEXT */),
                      _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(hovered.percentage) + "%)", 1 /* TEXT */)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", {
        class: _normalizeClass(["text-gray-60 text-center text-sm", _ctx.$style.count])
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('csat_dashboard.tags.conversations_count')) + " ", 1 /* TEXT */),
        _createElementVNode("strong", _hoisted_5, _toDisplayString(data.conversationsWithTags), 1 /* TEXT */)
      ], 2 /* CLASS */)
    ]),
    empty: _withCtx(() => [
      (_ctx.sourceType === 'csat')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.empty)
          }, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('csat_dashboard.tags.empty.title', [_ctx.questionTypeLabel])), 1 /* TEXT */),
            _createElementVNode("p", {
              class: _normalizeClass(["text-sm", _ctx.$style.subtitle])
            }, _toDisplayString(_ctx.$t('csat_dashboard.tags.empty.subtitle', [_ctx.questionTypeLabel])), 3 /* TEXT, CLASS */)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_q_toggle, {
        modelValue: _ctx.sourceType,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sourceType) = $event)),
          _ctx.analytics.csatDashboardTagsSourceTypeChanged
        ],
        condensed: "",
        class: _normalizeClass(["mr-3", _ctx.$style.toggle]),
        options: _ctx.sourceTypes
      }, null, 8 /* PROPS */, ["modelValue", "class", "options", "onUpdate:modelValue"]),
      _createVNode(_component_pin_dashboard_button, { "card-id": "csat-tags-card" })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["api-method", "title", "text-in-brackets", "params", "onDataupdate"]))
}