import { sortBy } from 'lodash-es'
import { getLanguageDisplayName } from '@/utils/languageNames'

const languagesList = [
  { name: getLanguageDisplayName({ locale: 'ar' }), id: 'ar' },
  { name: getLanguageDisplayName({ locale: 'az' }), id: 'az' },
  { name: getLanguageDisplayName({ locale: 'bg' }), id: 'bg' },
  { name: getLanguageDisplayName({ locale: 'zh' }), id: 'zh' },
  { name: getLanguageDisplayName({ locale: 'hr' }), id: 'hr' },
  { name: getLanguageDisplayName({ locale: 'cs' }), id: 'cs' },
  { name: getLanguageDisplayName({ locale: 'da' }), id: 'da' },
  { name: getLanguageDisplayName({ locale: 'nl' }), id: 'nl' },
  { name: getLanguageDisplayName({ locale: 'en' }), id: 'en' },
  { name: getLanguageDisplayName({ locale: 'et' }), id: 'et' },
  { name: getLanguageDisplayName({ locale: 'fi' }), id: 'fi' },
  { name: getLanguageDisplayName({ locale: 'fr' }), id: 'fr' },
  { name: getLanguageDisplayName({ locale: 'ka' }), id: 'ka' },
  { name: getLanguageDisplayName({ locale: 'de' }), id: 'de' },
  { name: getLanguageDisplayName({ locale: 'el' }), id: 'el' },
  { name: getLanguageDisplayName({ locale: 'he' }), id: 'he' },
  { name: getLanguageDisplayName({ locale: 'hu' }), id: 'hu' },
  { name: getLanguageDisplayName({ locale: 'id' }), id: 'id' },
  { name: getLanguageDisplayName({ locale: 'it' }), id: 'it' },
  { name: getLanguageDisplayName({ locale: 'ja' }), id: 'ja' },
  { name: getLanguageDisplayName({ locale: 'ko' }), id: 'ko' },
  { name: getLanguageDisplayName({ locale: 'lv' }), id: 'lv' },
  { name: getLanguageDisplayName({ locale: 'lt' }), id: 'lt' },
  { name: getLanguageDisplayName({ locale: 'no' }), id: 'no' },
  { name: getLanguageDisplayName({ locale: 'pl' }), id: 'pl' },
  { name: getLanguageDisplayName({ locale: 'pt' }), id: 'pt' },
  { name: getLanguageDisplayName({ locale: 'ro' }), id: 'ro' },
  { name: getLanguageDisplayName({ locale: 'ru' }), id: 'ru' },
  { name: getLanguageDisplayName({ locale: 'sk' }), id: 'sk' },
  { name: getLanguageDisplayName({ locale: 'sl' }), id: 'sl' },
  { name: getLanguageDisplayName({ locale: 'es' }), id: 'es' },
  { name: getLanguageDisplayName({ locale: 'sv' }), id: 'sv' },
  { name: getLanguageDisplayName({ locale: 'th' }), id: 'th' },
  { name: getLanguageDisplayName({ locale: 'tr' }), id: 'tr' },
  { name: getLanguageDisplayName({ locale: 'uk' }), id: 'uk' },
]

export default sortBy(languagesList, 'name')

export const supportedLanguagesObj = languagesList.reduce((o, curr) => ({ ...o, [curr.id]: { ...curr } }), {})

export const getTranslationById = (id: string) => supportedLanguagesObj[id].name
