import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-lg font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_image = _resolveComponent("question-image")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_q_radio = _resolveComponent("q-radio")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      "data-testid": "question-form-content",
      class: _normalizeClass(["text-sm", [_ctx.$style.content, !!_ctx.error && _ctx.$style.errorBorder]])
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1 /* TEXT */),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (url) => {
        return (_openBlock(), _createBlock(_component_question_image, {
          key: url,
          src: url
        }, null, 8 /* PROPS */, ["src"]))
      }), 128 /* KEYED_FRAGMENT */)),
      (_ctx.allowMultipleSelection)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 0,
            class: _normalizeClass(_ctx.$style.options)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (option) => {
              return (_openBlock(), _createElementBlock("li", {
                key: option.id,
                "data-testid": "answer",
                class: _normalizeClass(_ctx.$style.option)
              }, [
                _createVNode(_component_ui_checkbox, {
                  checked: option.selected,
                  label: option.label,
                  class: _normalizeClass(_ctx.$style.checkbox),
                  onChange: ($event: any) => (option.selected = $event)
                }, null, 8 /* PROPS */, ["checked", "label", "class", "onChange"])
              ], 2 /* CLASS */))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */))
        : (_openBlock(), _createBlock(_component_q_radio, {
            key: 1,
            "model-value": _ctx.selectedOptionId,
            "data-testid": "answer",
            options: _ctx.radioOptions,
            "onUpdate:modelValue": _ctx.handleRadioValueChange
          }, null, 8 /* PROPS */, ["model-value", "options", "onUpdate:modelValue"])),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["text-xs font-medium", _ctx.$style.messageError])
          }, _toDisplayString(_ctx.error), 3 /* TEXT, CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}