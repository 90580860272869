import timeTracking from './timeTracking'
import { IDLE_EVENTS } from './idleEvents'

let timedOut = false
let timeoutFn: number

const detect = () => {
  if (timedOut) {
    timedOut = false
    timeTracking.start()
  }

  clearTimeout(timeoutFn)
  timeoutFn = window.setTimeout(() => {
    timedOut = true

    timeTracking.pause({ limit: true })
  }, 30000)
}

const stop = () => {
  timedOut = false
  clearTimeout(timeoutFn)
}

const onVisibilityChange = () => {
  if (document.visibilityState !== 'visible') stop()
}

const bulkAddEventListeners = () => {
  IDLE_EVENTS.forEach((event) => window.addEventListener(event, detect))
  document.addEventListener('visibilitychange', onVisibilityChange)
}

const bulkRemoveEventListeners = () => {
  IDLE_EVENTS.forEach((event) => window.removeEventListener(event, detect))
  document.removeEventListener('visibilitychange', onVisibilityChange)

  timeTracking.pause()
  timeTracking.clearId()
  timeTracking.removeListeners()
}

export default {
  bulkAddEventListeners,
  bulkRemoveEventListeners,
  detect,
  stop,
}
