import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_coaching_page_nav = _resolveComponent("coaching-page-nav")!
  const _component_quiz_header = _resolveComponent("quiz-header")!
  const _component_ui_spinner = _resolveComponent("ui-spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_layout = _resolveComponent("page-layout")!

  return (_openBlock(), _createBlock(_component_page_layout, {
    "hide-left-sidebar": "",
    "hide-right-sidebar": "",
    view: _ctx.RootViews.Coaching
  }, {
    "page-nav": _withCtx(() => [
      _createVNode(_component_coaching_page_nav)
    ]),
    "page-header": _withCtx(() => [
      _createVNode(_component_quiz_header, {
        id: _ctx.id,
        quiz: _ctx.quiz,
        "edit-mode": _ctx.editMode,
        "preview-mode": _ctx.previewMode,
        onPreview: _ctx.handlePreview,
        onPublish: _ctx.handlePublishQuiz,
        onSaveDraft: _ctx.handleSaveQuizAsDraft,
        onDuplicate: _ctx.handleDuplicateQuiz,
        onRemove: _ctx.handleRemoveQuiz,
        onUnarchive: _ctx.handleUnarchiveQuiz,
        onExport: _ctx.handleExportQuiz,
        onArchive: _ctx.handleArchiveQuiz
      }, null, 8 /* PROPS */, ["id", "quiz", "edit-mode", "preview-mode", "onPreview", "onPublish", "onSaveDraft", "onDuplicate", "onRemove", "onUnarchive", "onExport", "onArchive"])
    ]),
    content: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ui_spinner, { key: 0 }))
        : (_openBlock(), _createBlock(_component_router_view, {
            key: 1,
            "quiz-id": _ctx.id,
            quiz: _ctx.quiz,
            "show-validation": _ctx.showValidation
          }, null, 8 /* PROPS */, ["quiz-id", "quiz", "show-validation"]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["view"]))
}