import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_session_row = _resolveComponent("session-row")!
  const _component_action_item = _resolveComponent("action-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_session_row, { session: _ctx.sessionData }, null, 8 /* PROPS */, ["session"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionItems, (actionItem, idx) => {
      return (_openBlock(), _createBlock(_component_action_item, {
        ref_for: true,
        ref: "editorRefs",
        key: actionItem.tempId || actionItem.id,
        idx: idx,
        action: actionItem,
        "tooltip-content": actionItem.completed ? _ctx.getActionCompletedTooltip(actionItem) : undefined,
        class: _normalizeClass(_ctx.$style.actionItem),
        onToggle: ($event: any) => (_ctx.toggleAction(actionItem, $event)),
        onUpdate: _ctx.updateAction,
        onEnter: ($event: any) => (_ctx.actionEnterHandler(idx)),
        onRemove: _ctx.removeAction
      }, null, 8 /* PROPS */, ["idx", "action", "tooltip-content", "class", "onToggle", "onUpdate", "onEnter", "onRemove"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}