import type { AdvancedScorecard_ScorecardRatingCategory as ScorecardRatingCategory } from '@zendesk/zqa-services/scorecards'

export interface CategoryFilter {
  type: CategoryFilterType
  allowedMultiple: boolean
  label: string
  conditions: CategoryFilterCondition[]
  values: CategoryFilterValue[]
}

export enum CategoryFilterType {
  AGENT_MESSAGE = 'AGENT_MESSAGE',
  SOURCE_TYPE = 'SOURCE_TYPE',
  TICKET_CHANNEL = 'TICKET_CHANNEL',
  TICKET_TAGS = 'TICKET_TAGS',
  CALL_DIRECTION = 'CALL_DIRECTION',
  DEADAIR = 'DEADAIR',
}

export interface CategoryFilterCondition {
  condition: CategoryFilterConditionCode
  labelCode: string
}

export enum CategoryFilterConditionCode {
  STRING_EQUALS = 'STRING_EQUALS',
  STRING_NOT_EQUALS = 'STRING_NOT_EQUALS',
  STRING_CONTAINS = 'STRING_CONTAINS',
  STRING_NOT_CONTAINS = 'STRING_NOT_CONTAINS',
  STRING_LIST_EQUALS = 'STRING_LIST_EQUALS',
  STRING_LIST_NOT_EQUALS = 'STRING_LIST_NOT_EQUALS',
  INTEGER_HIGHER = 'INTEGER_HIGHER',
}

export interface CategoryFilterValue {
  value: string
}

export interface AdvancedScorecard_ScorecardRatingCategory extends ScorecardRatingCategory {
  reviewCritical: boolean
  groupCritical: boolean
}

export enum ScorecardStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

export enum ScorecardSetupType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}
