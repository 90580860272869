import { ref, watch } from 'vue'
import { FormSections } from '../types'

export const sections = [
  FormSections.Styling,
  FormSections.ContentEmail,
  FormSections.ContentRatingScores,
  FormSections.ContentThankYou,
  FormSections.Delivery,
  FormSections.Languages,
]

export const activeSectionId = ref<string | undefined>(FormSections.Styling)

export const resetSectionId = () => (activeSectionId.value = FormSections.Styling)

export const previousSection = () => {
  const currentIdx = sections.findIndex((s) => s === activeSectionId.value)
  const nextIdx = (currentIdx - 1) % sections.length
  activeSectionId.value = sections[nextIdx]
}

export const nextSection = () => {
  const currentIdx = sections.findIndex((s) => s === activeSectionId.value)
  const nextIdx = (currentIdx + 1) % sections.length
  activeSectionId.value = sections[nextIdx]
}

watch(activeSectionId, (sectionId) => {
  if (sectionId === FormSections.Content) activeSectionId.value = FormSections.ContentEmail
})
