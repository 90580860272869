import { v4 as uuid } from 'uuid'
import { reactive, toRefs, computed, watch, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { isEqual } from 'lodash-es'

import ky from 'ky'
import { session } from '@/composables/useSession'
import i18n from '@/i18n'
import { useWorkspaceList } from '@/composables/useWorkspaceList'
import { getImageDownloadUrl } from '@/api/image-download-url'

import type { Quiz, Errors, QuizForm, QuizResponseOverview } from '../types'
import { getQuiz } from '../api'
import { getSelectableQuizForm, sortQuizByPosition } from '../utils'

interface QuizState {
  quiz: Quiz
  isLoading: boolean
}

const [managedWorkspaces] = useWorkspaceList({ managedOnly: true, loadOnMount: false })
const [workspaces, reload] = useWorkspaceList({ loadOnMount: false })
reload()

export const getQuizPreview = (state: QuizState): QuizForm => {
  const { fields = [] } = state.quiz
  const validatedFields = fields.filter(
    ({ id }) => !errors.value.questions[id] || !errors.value.questions[id].no_options,
  )
  const fieldsWithProperties = validatedFields.map((field) => {
    return {
      ...field,
      properties: [
        {
          key: 'allow_multiple_selection',
          value: field.options.filter(({ correct }) => correct).length === 1 ? 'false' : 'true',
        },
      ],
    }
  })

  const form = { ...state.quiz, fields: fieldsWithProperties }
  return getSelectableQuizForm(form)
}

const getDefaultState = (): QuizState => ({
  quiz: {
    id: uuid(),
    createdBy: session.user.id.toString(),
    name: '',
    description: '',
    status: 'DRAFT',
    publishedDate: '',
    workspaceIds: [],
    fields: [
      {
        id: uuid(),
        text: '',
        images: [],
        unsavedImages: [],
        type: 'MULTIPLE_CHOICE',
        position: '1',
        properties: [{ key: 'allow_multiple_selection', value: 'false' }],
        options: [],
      },
    ],
  },
  isLoading: true,
})

const getDefaultOverview = (): QuizResponseOverview => ({
  id: '',
  name: '',
  description: '',
  status: 'DRAFT',
  workspaceIds: [session.workspace.id.toString()],
  fields: [
    {
      id: uuid(),
      text: '',
      images: [],
      type: 'MULTIPLE_CHOICE',
      position: '1',
      options: [],
    },
  ],
  responseCount: '0',
  averageScorePercentage: '0',
})

export const state = reactive<QuizState>(getDefaultState())

export const overview = reactive<QuizResponseOverview>(getDefaultOverview())

export const errors = computed((): Errors => {
  const questionErrors = state.quiz.fields?.reduce((acc, question) => {
    const { id, options, text } = question
    acc[id] = {
      empty_title: !text,
      no_options: !options.length,
      no_correct_option: !!(options.length && !options.find(({ correct }) => correct)),
    }
    return acc
  }, {})

  return {
    no_quiz_title: !state.quiz.name,
    questions: questionErrors || {},
  }
})

async function loadForm(quizId: string | undefined, router, isTemplate?: boolean) {
  try {
    state.isLoading = true
    if (!quizId) {
      state.quiz = getDefaultState().quiz
      if (!state.quiz.workspaceIds.length) state.quiz.workspaceIds = managedWorkspaces.value.map(({ id }) => String(id))
    } else {
      const workspaceIds = workspaces.value.map(({ id }) => id)
      const quiz = await getQuiz(quizId, workspaceIds)
      if (quiz.fields) quiz.fields = quiz.fields.map((f) => ({ ...f, unsavedImages: [] }))

      if (!quiz.workspaceIds.length) quiz.workspaceIds = [session.workspace.id.toString()]
      state.quiz = sortQuizByPosition(quiz)
      if (isTemplate) {
        state.quiz.createdBy = session.user.id.toString()
        state.quiz.name = i18n.t('quizzes.title_cloned', { quizName: state.quiz.name })
        state.quiz.workspaceIds = managedWorkspaces.value.map(({ id }) => String(id))
        await cloneImages()
      }
    }
    state.isLoading = false
  } catch (e) {
    router.push({ name: 'quizzes.error' })
    state.isLoading = false
  }
}

const setQuiz = (quiz: Quiz) => {
  if (!quiz.workspaceIds.length) quiz.workspaceIds = [session.workspace.id.toString()]
  state.quiz = sortQuizByPosition(quiz)
}

const hasErrors = () =>
  errors.value.no_quiz_title ||
  Object.values(errors.value.questions).some((question) => Object.values(question).some((err) => err))

export const hasQuestionWithoutAnswers = () => {
  return Object.values(errors.value.questions).some((errors) => errors.no_options)
}

const cloneImages = async () => {
  await Promise.all(
    (state.quiz.fields || []).map(async (field) => {
      const images: string[] = []
      const unsavedImages: File[] = []

      await Promise.all(
        field.images.map(async (urlIn) => {
          const fileName = urlIn.split('/').pop()
          const { downloadUrl } = await getImageDownloadUrl(fileName)

          const response = await ky.get(downloadUrl)
          const blob = await response.blob()
          const file = new File([blob], uuid(), { type: blob.type })

          images.push(URL.createObjectURL(file))
          unsavedImages.push(file)
        }),
      )

      field.images = images
      field.unsavedImages = unsavedImages
    }),
  )
}

const cloneQuiz = async () => {
  state.quiz.id = uuid()
  state.quiz.status = 'DRAFT'
  state.quiz.createdBy = session.user.id.toString()
  state.quiz.name = i18n.t('quizzes.title_cloned', { quizName: state.quiz.name })
  state.quiz.workspaceIds = managedWorkspaces.value.map(({ id }) => String(id))

  await cloneImages()
}

export default (quizId: Ref<string | undefined>, isTemplate?: boolean) => {
  let loaded = false
  const router = useRouter()
  workspaces.value.length && loadForm(quizId.value, router, isTemplate)

  watch(quizId, (id) => loadForm(id, router))

  watch(workspaces, (list, oldList) => {
    if (loaded || !list.length || isEqual(list, oldList)) return
    loaded = true
    loadForm(quizId.value, router, isTemplate)
  })

  return { ...toRefs(state), hasErrors, cloneQuiz, setQuiz }
}
