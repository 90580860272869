import type { GetSurveysResponse_Type as TicketSurvey } from '@zendesk/zqa-services/tickets'
import type { NormalizedOptions } from 'ky'
import { api, errorHandlerHook } from '@/api'
import { getRequestHeaders } from '../../api'

export const getTicketSurveys = (externalId: string, connectionId: number) =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .get(`ticket/connection/${connectionId}/surveys`, {
      headers: getRequestHeaders(),
      searchParams: { externalId: encodeURIComponent(externalId) },
      hooks: {
        afterResponse: [
          (_request: Request, _options: NormalizedOptions, response: Response) => {
            if (response.status === 404) return new Response('{"types": []}')
            return response
          },
          errorHandlerHook,
        ],
      },
    })
    .json<{ types: TicketSurvey[] }>()
