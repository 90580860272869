export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自社のZendeskのサブドメインを入力します。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskにサインイン"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの会社には既存のアカウントがあります。"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社に参加"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存の会社に参加する場合は、管理者に招待状の送信を依頼してください。"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者に招待してもらってください"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気が変わりました。"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録をキャンセルする"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの会社には既存のアカウントがあります。あなたの会社のアカウントに参加しますか、それとも新しいアカウントを作成しますか？"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指示をコピーする"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいアカウントを作成する"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のアカウントがある"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未払いのサブスクリプション請求書の支払いが行われるまで、 Zendesk QAアカウントにアクセスできません。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のサブスクリプションは保留中です"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントが見つかりません。もう一度やり直してください。"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを取得してください。"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["もう一度やり直すか、", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスとパスワードの組み合わせが間違っています。"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者に新しい招待リンクをリクエストしてください。"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この招待リンクは、無効または使用済みです。"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), "のサインイン方法は、ご利用のアカウントでは無効になっています。"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この招待状は、無効または使用済みです。管理者に新しい招待状をリクエストしてください。"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状の情報を読み込めませんでした。"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録できませんでした。このメールアドレスを持つユーザーがすでに存在する可能性があります。"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のアカウントは、連続した複数回のサインインの試行後にブロックされました。ブロックを解除する手順については、受信トレイに届いているメールを参照してください。"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初にメールアドレスの確認を行ってください"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアカウントを入力してください"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドは入力必須です"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドは入力必須です"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインコード"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンクを送信"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント登録"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勤務先のメールアドレス"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勤務先のメールアドレス"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.co.jp/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メインサービス契約"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーに関する通知"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.co.jp/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お待ちください"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["こんにちは、", _interpolate(_named("invitee_name")), "さん"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("organization")), "組織に参加する"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスが確認されました。新しいアカウントを使用して以下でサインインできます。"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleで続行"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効な招待リンクです"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンクを使用してサインイン"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインに戻る"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackで続行"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskで続行"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスが確認されました。以下の新しいアカウントでサインインできます："])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleでサインインする"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンク経由でサインインする"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackでサインインする"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAにサインインする"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントが削除されました"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントをお持ちでない場合は"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なコードを入力してください"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドは入力必須です"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアカウントを入力してください"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドは入力必須です"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインコード"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのサインインオプションを表示"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), "従来のサインイン方式"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンクを使用してサインイン"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインコードを含むメールが届いています。"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信したメールに従ってサインインしてください。"])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンクの送信中にエラーが発生しました。もう一度やり直してください。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール経由でサインインする"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを作成する"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小文字を1文字以上"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大文字を1文字以上"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードには、以下を含める必要があります"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの最大文字数は128文字です"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["思い出しやすく、推測されにくいもの"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8文字以上"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを作成してください"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れた場合"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小文字1文字"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1つの番号"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大文字1文字"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットするには、メールアドレスを入力してください。折り返し、手順を記載したメールをお送りします。"])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアカウントを入力してください"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドは入力必須です"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットする"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "にメールを送信しました"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールが受信トレイまたはスパムフォルダに届かない場合は、入力したメールアドレスを確認してください。"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインに戻る"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを確認してください"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットする"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), "、パスワードリセット用メールが届きます。"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントに既存のパスワードがある場合"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["その", _interpolate(_named("bold")), "場合は、パスワードリセット用リンクを送信しています。"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスはアカウントに関連付けられています"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを思い出しましたか？"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセットリンクの送信中にエラーが発生しました。もう一度やり直してください。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットする"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンクを使用して登録"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この登録により、Zendesk QAの", _interpolate(_named("terms_of_service")), "および", _interpolate(_named("privacy_policy")), "に同意することになります"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントをお持ちですか？"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンク経由で登録する"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勤務先のメールアドレスで登録する"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackで登録する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAに登録する"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleアカウントで申し込む"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなZendeskアカウントがあります"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["登録またはサインインして、", _interpolate(_named("direct_install_name")), "の設定を完了してください"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認をお願いします受信トレイに届いている確認メールをチェックしてください。"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定が完了しました"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAは、デスクトップでの使用に最適です。サインインして会話のレビューを開始してください。"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デモを予約する"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または、デモをご予約ください。Zendesk QAでできることをお見せします。"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたを招待するリクエストが届いています"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["気が変わったので、 ", _interpolate(_named("cancel")), "したい"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), "が、自身のアカウントに参加するよう招待しています。"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントに参加する"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいアカウントを作成する"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインをやり直してみてください"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["あなたのフィードバックに対して届いたリアクション：", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたリアクション"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), "があなたのコメントに反応しました"]), _normalize(["複数のメンバーがあなたのコメントに反応しました"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), "があなたの返信に反応しました"]), _normalize(["複数のメンバーがあなたの返信に反応しました"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), "があなたのレビューに反応しました"]), _normalize(["複数のメンバーがあなたのレビューに反応しました"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付与済み"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取り済み"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのフィードバック"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リアクション"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["コメントを既読にしますか?"]), _normalize(["すべてのコメントを既読にしますか?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["異議を既読にしますか?"]), _normalize(["すべての異議を既読にしますか?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["アンケートのフィードバックを既読にしますか?"]), _normalize(["アンケートのフィードバックをすべて既読にしますか?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビューを既読にしますか？"]), _normalize(["すべてのレビューを既読にしますか？"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["コメントを既読にしました"]), _normalize(["すべてのコメントを既読にしました"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["異議を既読にしました"]), _normalize(["すべての異議を既読にしました"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["アンケートのフィードバックを既読にしました"]), _normalize(["アンケートのフィードバックをすべて既読にしました"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビューを既読にしました"]), _normalize(["すべてのレビューを既読にしました"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて開封済にマークする"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既読にする"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰かがレビューに異議を申し立てると、ここに表示されます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議はありません"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の解決があなたに割り当てられた場合は、ここに表示されます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けた異議はありません"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたがレビューに異議を申し立てた場合は、ここに表示されます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始済みの異議はありません"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたに届いたフィードバックは、すべてここに表示されます。表示するものがまだありません。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたフィードバックはありません"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのレビューや返信に対するすべてのリアクションは、ここに表示されます。表示するものがまだありません。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたリアクションはありません"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を開く"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したフィードバックはすべてここに表示されます。まず、[会話]ビューでレビューを投稿します。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューはありません"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたに届いたフィードバックは、すべてここに表示されます。表示するものがまだありません。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたレビューはありません"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントが見つかりません"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントとして残すフィードバックはすべてここに表示されます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントがありません"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントが見つかりません"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分がオーナーのレビューに含まれるすべてのコメント ", "@", "メンションやコメントへの返信がここに表示されます"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたコメントはありません"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けられた異議はありません"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解決の異議はありません"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否された異議はありません"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決済みの異議はありません"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上のフィルターから別のオプションを選択して、もう一度やり直してください。"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上のフィルターから別のユーザーを選択して、もう一度やり直してください。"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付与したコメント"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたコメント"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての異議"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けた異議"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始された異議"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートで受け取ったフィードバック"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付与したレビュー"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたレビュー"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティ"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティリストの切り替え"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "レビュー済み"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "レビュー済み"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "レビュー済み"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["再開まで残り", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["残り", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを設定する"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てリストを切り替える"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "と", _interpolate(_list(1)), "は現在同じワークスペース内にないため、これらの間にコーチングセッションを作成することはできません。"])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "と", _interpolate(_list(1)), "の間のコーチングセッションはすでに存在します。セッションへのアクセスを取得するには", _interpolate(_list(0)), "に連絡してください。"])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推奨"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "と", _interpolate(_list(1)), "の間に新規のセッションが作成されました。"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチ"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチを選択する"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング対象者"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング対象者を選択する"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを作成する"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを作成する"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを追加する"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年月日"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション名"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推奨"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限超過"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予定"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを作成する"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションが見つかりません"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均レビュー時間"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した期間に表示するデータがありません"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリ"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["セッションにピン留めを追加しました。", _interpolate(_named("buttonStart")), "表示する", _interpolate(_named("buttonEnd")), "。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加先"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加済み"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した期間中のピン留めの最後に達しました"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ピン留めの機能については、この", _interpolate(_named("link")), "を参照してください。"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記事"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "にはまだピン留めが設定されていないようです。ピン留めの機能は、1対1のセッションの準備や次の機会のために何かを保存しておく場合に使用できます。"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング対象者"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだピン留めが追加されていません"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "についてこれまでに作成したすべてのピン留めです。セッションに追加されたピン留めは、相手に", _interpolate(_named("bold")), "。"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐに共有"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["選択した期間中に", _interpolate(_list(0)), "に届いた会話レビューまたはCSATに関するフィードバックはないようです。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックがありません"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した期間中のコメントの最後に達しました"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メトリック"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留め"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "の番号"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規のアクション項目"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("user")), "が", _interpolate(_named("date")), "に完了済み"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["作成者：", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなた自身"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいアクション項目をタイプして追加します"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このミーティングから得られたものは何ですか？次のステップは何ですか？"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクションアイテム"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "が", _interpolate(_named("createdDate")), "に作成"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを削除"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["これにより、完全に", _interpolate(_named("deleteSession")), "。関係者は、コンテンツやプライベートメモを含め、このセッションにアクセスできなくなります。"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "に作成されたセッション"])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最終編集者：", _interpolate(_named("name")), "、編集日：", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["話の要点とメモ"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモを更新しました"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを終了"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したユーザーは、この時点からセッションの詳細にアクセスできるようになります。"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを選択する"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを共有する"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["これで", _interpolate(_list(0)), "と", _interpolate(_list(1)), "の間のセッションの関係者ではなくなります。"])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "はこのセッションの関係者ではなくなります。"])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " がこのセッションにアクセスできるようになりました。"]), _normalize([_interpolate(_named("n")), " 新しいユーザーがこのセッションにアクセスできるようになりました"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを削除する"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのセッションを削除する"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを削除する"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチ"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "が追加したメモが表示されます。"])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めしたアイテムを右側のサイドパネルからディスカッションポイントとして追加する"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留め"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分にしか表示されないメモ..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人的なメモ"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートメモが更新されました"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30日"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチ"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング対象者"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクションアイテム"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回のセッション"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限超過"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションの概要"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全て表示する"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分：コーチ"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分：コーチング対象者"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのセッション"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有セッション"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクション項目を表示するには、フィルターを切り替えてみてください。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクション項目がありません"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクションアイテム"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信および閲覧済みのレビュー"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信済み・閲覧済み"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション後の進捗"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントに届いているフィードバックがないようです。"])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの名前が正しいことを確認してください"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そのユーザーは見つかりませんでした"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当データはありません"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間のIQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["これにより、<b>", _interpolate(_named("name")), "</b>の<b>すべてのセッション(2)が完全に削除</b>されます。関係者は、コンテンツやプライベートメモを含め、すべてのセッションにアクセスできなくなります。"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのセッションの最後に達しました"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングセッションは、パフォーマンス、商談、短期的および長期的な目標について話し合うために指導者とコーチング対象者の間で行われるグループアクティビティです。"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションは、コーチの名前とコーチを受ける人の両方で検索できます。"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションが見つかりません"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだセッションが作成されていません"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングの履歴を削除する"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングの履歴を削除する "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回のセッション"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのセッション"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチ"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング対象者"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了としてマークする"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完了としてマークする"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前で検索する"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前を変更する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションリストを切り替える"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予定外"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメッセージに返信"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションの有効期限が切れています。レビューを追加することはできません。"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話に対するレビューはすでに投稿しています。"])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションの期限が過ぎているため、この会話のレビューは行えません。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話にレビューはありません"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パネルを閉じる"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションレビューを残す"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ログ"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["スピーカー", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェント"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマー"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "秒"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デッドエア"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スピーカーを編集する"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを確認する"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージの再生に戻る"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スピーカー"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ログ"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースユーザー"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを作成するには、まずコネクションを追加します"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットなし"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["作成日時：", _interpolate(_named("time")), "、作成者：", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最終更新日：", _interpolate(_named("time")), "、更新者：", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["フィルターが設定されていないようです。このページにアクセスするには、まずフィルターを作成する必要があります。手始めに", _interpolate(_named("recommendedFilter")), "を使用して、自分の会話を表示してみてください。操作を続けるには、以下でこのフィルターを作成します。"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「過去30日間に作成」の条件は、日付範囲なしで作成されたフィルターに追加されます"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターはこのプランでは利用できないため、結果を表示する際に考慮されません"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターオプションの取得がタイムアウトしました。もう一度やり直してください。"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルタリングについて"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者 = サインイン中のユーザー"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["見つかった会話：", _interpolate(_named("count")), "件"]), _normalize(["見つかった会話：", _interpolate(_named("count")), "件"]), _normalize(["見つかった会話：", _interpolate(_named("count")), "件"])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のプランでは利用できません。この機能を使用するにはアップグレードしてください。"])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を追加する"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成者: Zendesk QA AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクに変換する"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを作成する"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを作成する"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムフィールド"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定の設定に従ってフィルターオプションを読み込んでいます。初回読み込み時には、数分かかる場合があります。"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターにわかりやすい名前を付ける"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター名"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションを選択する"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベート"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリック"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを編集する"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存する"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値がありません"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見つかりませんでした"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値を選択する"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなセッションのみ ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古いセッションを表示 ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションを編集する"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを作成する"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションを作成する"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション中に行われたキャリブレーションレビューはすべて完全に削除されます。"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションを削除する"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), "のキャリブレーションセッションを削除する"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションが作成されていません"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなセッションがありません"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションが作成されていません"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなセッションがありません"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを追加する"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートフィルターを作成する"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリックフィルターを作成する"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターが追加されました"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターは完全に削除されます。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを削除する"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), "フィルターを削除する"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての異議"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分に対する異議"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分による異議"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解決の異議"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターが追加されていません"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートフィルターが作成されていません"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリックフィルターが作成されていません"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートフィルター"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリックフィルター"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cta")), "を希望しますか？"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存する"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これは一時的なフィルターのビューです。"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを作成しています..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターが更新されました"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイト"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムオートQAカテゴリ"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最終更新 ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複雑な単語"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動レビューはチケットの変更に基づいて動的に更新されます"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内をハイライトする"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長い文"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動スコアが予測されませんでした"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーを検出できませんでした"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複雑な単語を表示する"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長い文を表示する"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の会話"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテキストが不足しているため、AutoQAはこのカテゴリを評価できませんでした。手動でレビューすることをお勧めします。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライト"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動化された会話のインサイトを見つけて、レビュープロセスを効率化します。重要な会話を強調し、チームにとって優れた事例を紹介します。"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[コール]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正済み"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除済み"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "が異議を受け入れました"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["異議を", _interpolate(_named("agentName")), "に送信しました"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このレビュー対象者がこのフィードバックの受信者として不適切であると考える理由を説明してください"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを追加して新しいレビュー対象者を設定する"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを追加する"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらのスコアの評価が不当であると思う理由を説明してください（必須）"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを追加する（必須）"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この異議を却下する理由を説明してください"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを追加する"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の送信先"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の送信先"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の送信先"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレビュー対象者を選択"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議のフィードバック"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議のフィードバック"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者を変更する"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の送信先"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このレビューに異議を申し立てる"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアに同意できない"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このレビューは自分向けではありませんでした"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを選択する"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "が異議を部分的に受け入れました"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "が異議を拒否しました"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承諾した"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部承諾"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否した"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "が異議を開始：レビュー"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "が異議を開始：間違ったレビュー対象者"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解決の異議は、レビューに対して他のアクションを実行するために解決する必要があります。"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承諾"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいスコアを選択する"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を削除する"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この異議を破棄してもよろしいですか？"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を削除する"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の解決時間"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["却下"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい公平なスコアを選択する（オプション）"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューについて論ずる"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間違ったレビュー対象者"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を受け入れる"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を受け入れる"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議のフィードバック"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間違ったレビュー対象者"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議のフィードバック"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を拒否する"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を拒否する"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を表示する"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集済み"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), "、実行者：", _interpolate(_named("author")), "、日付：", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["宛先：", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー済み"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて完了"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当て先："])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントも残す"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付きスコアカードについて"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前の割り当て先："])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1つ選択してください"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その名前のGIFが見つかりませんでした"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初のレビューを残しましょう。"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のサブスクリプションは現在一時停止中です。フィードバックを提供できるようにするには、再度アクティブ化してください。"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投稿者："])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話のレビューにかかった時間を示しています"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを選択する"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["カテゴリの根本原因が見つかりません：", _interpolate(_list(0))])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード："])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを評価するか、コメントを追加してください"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絵文字を追加する"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行を折りたたむ"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントフィールドを展開する"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントフィールドを折りたたむ"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書式を非表示にする"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パネルを非表示にする"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書式を表示する"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パネルを表示する"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信する"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリを評価します"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少なくとも1つのカテゴリを評価します"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を保存して後で使用する"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後で使うために保存する"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の割り当てられたタスク"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この割り当てられたタスクのサイクルの会話を置き換える"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を確認する"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベースラインレビュー"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを削除する"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントが削除されました"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このコメントを削除しますか？"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントの作成者"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このハッシュタグは削除されているか、名前が変更されています。"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気に入った点、または改善できた点を教えてください..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントする"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件文"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、すべてのコメントが完全に削除されます。"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを削除する"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[メッセージ]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "はまだこのフィードバックを見ていません"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "がこのフィードバックを見ました"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧メンバー"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIFを検索する..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterキーを押して新しいハッシュタグを作成します。"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムハッシュタグは管理者によってロックされています。"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これを使用してケースをブックマークし、後からフィルターで除外する"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベースラインとしてマークする"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージのフィードバック"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の会話"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックはありません"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したレビューはありません"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始するには、以下にレビューを残してください。"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のレビューはありません"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未評価"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の担当者"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の会話"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベースラインとして削除する"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このレビューに返信する"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューサイドバーを非表示にする"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、レビューと追加されたすべてのコメントが完全に削除されます。"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを削除する"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを削除する"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューが削除されました"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["レビューが", _interpolate(_list(0)), "により削除されました"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了しました。"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを残すには、このタスクを自分に割り当て直してください"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このユーザーに対するレビューをすでに投稿しています。"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "に対するレビューを投稿しました"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話に表示するカテゴリはありません。"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューはこのワークスペースでは利用できません。"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存しない"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このレビューを下書きとして保存しますか?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この下書きを破棄する"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["破棄"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューのサイドバーを表示する"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格（少量のミス有り）"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ数値を計算中..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを残す"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメッセージを確認する"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分自身を確認する"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話をレビューする"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成者"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューするユーザーがいません"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューが無効になりました"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーが見つかりません"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースユーザー"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを選択する"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件のレビュー"]), _normalize([_interpolate(_named("count")), "件のレビュー"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい評価を選択する"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースのマネージャーが自己レビューを許可していません。"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterキーを押してこのピンにタグ付けする"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スター付き"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションを作成する"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイト"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイトは、ヘルプデスクのデータを視覚的に表現します。インタラクティブなグラフを使用して、QA作業に注力すべき箇所を理解し、盲点を明らかにし、パフォーマンスに基づいてレビューすべき適切なエージェントを見つけます。"])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), "のID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), "のURL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("language")), "に翻訳"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加先"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未割り当て"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在閲覧中"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在レビュー中"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話は削除され、利用できなくなっています。"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お探しの会話はZendesk QAには存在しません。"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話のすべてのピン留め"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション中"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を削除する"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションから会話を削除すると、その会話に付与されたすべてのレビューが削除されます。"])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), "から会話を削除する"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションはまだ作成されていません。"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話はセッションに追加されました"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話はセッションから削除されました"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションへ追加する/キャリブレーションセッションから削除する"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話へのリンクをコピーする"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する会話がありません"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランド"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーのメールアドレス"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーの名前"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部フォーム名"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT（初回応答時間）"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初回応答時間"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優先度"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのコメント"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満足度スコア"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR（最初の解決までの時間）"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初の解決までの時間"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメッセージはこの会話に存在しないか、削除されています"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件名がありません"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する会話がありません"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が見つかりません"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元の会話を開く"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話にリンクできません - URLがありません"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者がいません"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話をピン留めする"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックしてレビューを読む"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックして確認する"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブラウザの拡張機能経由で作成された会話"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客満足度アンケートを見る"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を表示する"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブなセンチメントのメッセージ"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジティブなセンチメントのメッセージ"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語に翻訳する"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原文を表示する"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を削除"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["会話を削除すると元に戻せません。また、アンケート、会話ログ、レビューなどのすべての関連データも、Zendesk QA（ダッシュボードも含む）から削除されます。", _interpolate(_named("lineBreak")), "この会話には、Zendesk Supportまたはご利用の別のヘルプデスクソフトウェアから引き続きアクセスできます。"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["会話を削除すると元に戻せません。また、アンケート、会話ログ、レビューなどの関連データもすべてZendesk QA（ダッシュボードも含む）から削除されます。会話が複数のワークスペースに属している場合、そのすべてから会話が削除されます。", _interpolate(_named("lineBreak")), "Zendesk Supportまたは使用している別のヘルプデスクソフトウェアからこの会話に引き続きアクセスできます。"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を削除"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAから会話を削除する"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この会話は、エスカレーションにより未完了となっています。", _interpolate(_named("optionsUrl")), "に移動してタグを検証してください。", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この会話は、エスカレーションにより未完了となっています。タグの検証については管理者に相談してください。", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[コネクション] > [詳細オプション]"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグについて"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新機能"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決済み"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["担当者が", _interpolate(_named("target")), "に変わりました"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未割り当て"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), "のマクロを", _interpolate(_named("author")), "が適用しました"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["会話に", _interpolate(_named("target")), "の評価が付きました"])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ユーザー", _interpolate(_named("target")), "が会話を墨消ししました"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ステータスが", _interpolate(_named("target")), "に変わりました"])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), "のタグが付きました"])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チームが", _interpolate(_named("target")), "に変わりました"])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), "がこの会話を", _interpolate(_named("target")), "の間スヌーズしました"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), "がこの会話のスヌーズを解除しました"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットがループ状態になり、同じメッセージを連続して繰り返します"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの繰り返し"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの繰り返し：同じメッセージが1回以上連続して繰り返されています"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致する語句が見つかりました"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["平均的なエージェントがボットに比べて", _interpolate(_named("percent")), "効率的に会話を処理しています"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットのコミュニケーション効率"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ボットが平均的なエージェントに比べて", _interpolate(_named("percent")), "効率的に会話を処理しています"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットのコミュニケーション効率"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に、録音について関係者に知らせるために必要なメッセージがありません。これはコンプライアンスと透明性を確保するために必須です。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["録音開示メッセージなし"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "のサービスレベルアグリーメント（SLA）違反がありました。"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA違反"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLAルールのメトリックが目標時間を超えました"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客離れの兆候。顧客は切り替えを検討中か、進路を変更する兆しがあります。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定された解約リスク"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "秒に設定されたしきい値以上のデッドエアが会話に含まれています"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件のインスタンス"]), _normalize([_interpolate(_named("count")), "件のインスタンス"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最長で", _interpolate(_list(0)), "秒"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デッドエア"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーがより高度なサポートを求めた"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エスカレーションがリクエストされました"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーは、受けたサポートに非常に感謝した、または非常に満足した"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優れたサービス"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーまたはエージェントが明示的にフォローアップをリクエストした"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローアップリクエスト"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが不満を示した"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブなセンチメント"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が普通ではない、または不規則で、解決に至るまでのやりとりが多かった"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外れ値が検出された"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが満足を示した"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジティブなセンチメント"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用可能"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用不可として設定"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最終編集者：", _interpolate(_named("author")), "、編集日：", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["設定者：", _interpolate(_named("author")), "、設定日：", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を追加する（オプション）"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイトが検出されませんでした"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを追加"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用のヘルプデスクをアカウントに接続し、会話のリストを表示するためのフィルターを少なくとも1つ作成します。"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する会話がありません"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース設定を表示"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終更新"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（1日）"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（今月）"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（今週）"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDで検索"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("searchQuery")), "の検索結果"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のユーザーがレビュー済み"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント済み"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい順"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古い順"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランダム"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新の新しい順"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信の新しい順"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並べ替え基準"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未読"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたがレビュー済み"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インポートプロセスには時間がかかる場合があります。完了したら、メールでお知らせします。"])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAが会話をインポートしています"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["上限：", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ビューのヘッダーにあるキャリブレーションアイコンをクリックして追加します。"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポートするものがありません。別のフィルターまたは並べ替えオプションを使用して、もう一度やり直してください。"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する外れ値はありません。"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する会話を見つけ、ヘッダーにある星印のアイコンをクリックして、ここにブックマークします。"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を追加するには、フィルターを変更するか、他のヘルプデスク担当者を招待します。"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマー"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制限なし"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社内メモ"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を読み込んでいます。一部のコンテンツがまだ表示されていない可能性があります。"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のユーザー"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーが見つかりません"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取ったレビュー"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースのメンバー"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前のユーザー"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が追加されていません"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が追加されていません"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成のレビューはここに表示されます。30日以内に読み直して修正し、送信してください。"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書きレビューはありません"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スター付きの会話はありません"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する会話がありません"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致するものがありません"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したメッセージに戻る"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信件数が最も少ない"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信件数が最も多い"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話リストを切り替える"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユーザー"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "にコールを受け付け"])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "にコールが終了"])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "にコールを発信"])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー済み"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたがレビュー済み"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["音声ファイルを読み込めませんでした。", _interpolate(_list(0)), "にサインインしていることを確認してください。"])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客満足度アンケートのコメント"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音声の読み込み中にエラーが発生しました"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントの作成者"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コールは行われませんでした"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクで聞く"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aircallで聞く"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再生速度"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コールを確認する"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["システム"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ログ"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAユーザーではない"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAは、月次平均CSATに基づいてエージェントを自動的にランク付けします。ワンクリックで、同僚よりもCSATが低いエージェントが対応した会話を見つけます。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATによるランキング"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAユーザーではない"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1チケット"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボリューム"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAは、月に処理する会話の数に基づいて、エージェントをパフォーマンスグループに自動的にランク付けします。同僚よりも会話量の少ないエージェントによる会話をワンクリックで見つけます。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボリューム別ランキング"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー済み"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未レビュー"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューのステータス"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAユーザーではない"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAは、会話の平均的なセンチメントに基づいて、エージェントを自動的にランク付けします。エージェントが同僚よりも多くの不満を持つカスタマーに対応するインタラクションをワンクリックで見つけます。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント別ランキング"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検出なし"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検出あり"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外れ値"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意が必要な会話です。Zendeskの機械学習モデルは、複雑なインタラクションや不規則なインタラクションを特定し、さらに詳しくレビューします。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外れ値"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジティブ"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが満足または不満を示した箇所をレビューする会話を選択します。ドロップダウンを使用して、確認したい会話のセンチメントタイプを選択します。フィルター属性を一番上にドラッグすると、すべてのインタラクションにおける選択したセンチメントの割合を確認できます。"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューのステータス"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未レビュー"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認済み"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAは会話内の言語を自動的に検出します。ドロップダウンを使用して、確認する特定の言語の会話を選択します。フィルター属性を一番上にドラッグすると、ヘルプデスクで使用されているすべての言語の概要が表示されます。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のインサイト"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["インサイトを使用すると、通常の会話フィルターよりも", _interpolate(_named("paragraph_2_item")), "できます。ご利用のヘルプデスクからのすべてのデータの概要を視覚的に把握し、次のようなインサイトを探索できます："])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のインサイト表示の使用について"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の特定の属性の重要度を他の属性と比較して理解する"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビルトインのエージェントパフォーマンスランキングを使って、パフォーマンスの高いエージェントと低いエージェントを見つけます"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), "は、最もレビューが必要な会話を見つけるのに役立つ強力なインタラクティブ・データマイニングツールです。"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["さらに行う"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「会話」グラフと「エージェント」グラフは、 ", _interpolate(_named("paragraph_3_item_1")), "– フィルターを更新するか、いずれかのセグメント化されたグラフのスライスをクリックすると、会話リストが自動的に更新されます。 ", _interpolate(_named("paragraph_3_item_2")), "して、 さまざまな要素の詳細な内訳や比率を調べることができます。"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相互接続"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター属性を並べ替える"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["会話のインサイトは", _interpolate(_named("paragraph_4_item")), "のみ表示します 。自動生成されたメッセージ、スパム、ボット返信、サポート担当者との適切な会話のないメッセージなど、実際のレビュー値を含まないインタラクションは自動的に削除されます。"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整理済みの会話と終了済みの会話"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここには何が表示されますか？"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " / ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取得できませんでした"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 該当なし"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェント"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(全体の", _interpolate(_named("percentage")), ")"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再読み込み"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイト"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しかし、評価スコアが届けばきっと役に立ちます。"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムアンケート"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのフィードバックが残っていません"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのフィードバックスコアはまだありません..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信しました"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックタグ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックコメントタグ"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付範囲フィルターを適用しました"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "返信"]), _normalize([_interpolate(_named("count")), "返信"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスク"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["アンケート設定で理由を設定することによって、", _interpolate(_list(0)), "に付けられたスコアの理由を詳しく調査できます。"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "の理由がまだ設定されていません"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの理由について"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ付きの会話："])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "、実行者："])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントのカテゴリ"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), "の理由"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["予測される", _interpolate(_named("surveyType")), "の要因"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ソース"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["アンケートの回答（", _interpolate(_named("n")), "）"]), _normalize(["アンケートの回答（", _interpolate(_named("n")), "）"]), _normalize(["アンケートの回答（", _interpolate(_named("n")), "）"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件名がありません"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "付きの会話"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ユーザー別", _interpolate(_list(0))])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答率"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "スコアは、すべての回答の合計を最大スコアの合計で割った値です"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲットを編集する"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲットを削除する"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標を設定する"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲット"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標を追加する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短い"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常に長い"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長い"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中程度の長さ"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["わかりにくい"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有用でない"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["良くないサポート"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["良いサポート"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["さまざまな感情"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントへのフィードバック"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苦情"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断片"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が解決されていません"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が解決されました"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブなセンチメント"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悪い結果"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジティブなセンチメント"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["称賛"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不良品"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迅速なサポート"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緩慢なサポート"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございます"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QAは、", _interpolate(_list(0)), "のデータを既定のカテゴリに自動で分類します。そのため、文字によるフィードバックを手動で選別することなく、複雑なインサイトを理解し、原因を調査することができます。"])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのスコア"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアンケート"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答の言語を選択する"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントサイズ"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントのサイズを選択する"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントの25%は1語のみで構成されていますが、それより長いコメントは必ず読まなければなりません。作業は自動的に行われるため、手動で行わなくても、より洞察に満ちたフィードバックを得ることができます。"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックの理由"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックの理由を選択する"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致するスコアがありません"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケート"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予測される要因"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントのカテゴリを選択する"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワードクラウドの単語"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワードクラウドの単語を選択する"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["前回の期間から", _interpolate(_list(0))])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件の会話"]), _normalize([_interpolate(_named("n")), "件の会話"]), _normalize([_interpolate(_named("n")), "件の会話"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データが不足しているため、すべての会話からアンケート送信率を計算できませんでした。"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信されたアンケート"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートファンネル"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["予測される", _interpolate(_named("surveyType")), "の要因"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QAは、", _interpolate(_list(0)), "のデータを既定のカテゴリに自動で分類します。そのため、文字によるフィードバックを手動で選別することなく、複雑なインサイトを理解し、原因を調査することができます。"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントサイズ"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントの25%は1語のみで構成されていますが、それより長いコメントは必ず読まなければなりません。作業は自動的に行われるため、手動で行わなくても、より洞察に満ちたフィードバックを得ることができます。"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの回答"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（平均）"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答数"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（平均）"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲット"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均値"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "の傾向"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答数"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドライバー"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "アンケート送信日"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "アンケート回答日"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "スコアの割り当て先"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データの表示基準："])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の作成日"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットのオーナー"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最も返信の多いエージェント"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（平均）"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "経時データ"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答数"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "およびIQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答数"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの語句を含む会話："])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワードクラウド"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー別CSAT"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATの概要"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートファンネル"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの回答"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATの推移"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワードクラウド"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルなし"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソースなし"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語なし"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由なし"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグなし"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントカテゴリなし"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースなし"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクが作成されていないか、サイクルが開始されていないため、利用可能なデータがありません。後で確認するか、フィルターを調整してください。"])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新の機能を試す"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "項目"]), _normalize([_interpolate(_named("count")), "項目"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "会話"]), _normalize([_interpolate(_named("count")), "会話"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格（少量のミス有り）"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格/不合格"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均値"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均（時間）"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均（分）"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), "の評価"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均CSAT = [フィードバックのスコア合計 ÷ (全回答数 × 満点のスコア)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA CSATスコアは、フィードバックスコアを0～100%のスケールで正規化し、標準化されたスコアの平均を取得します。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（顧客満足度スコア）は、カスタマーがあなたのサービスにどれだけ満足しているかを数値化します。"])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 各レビューは、1つの会話に対する1人のユーザーによるレビューを表します。1件の会話に複数のレビューが付く可能性があります（2人以上のレビュー担当者が同じ会話をレビューする場合）。"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者のスコアの推移を経時的に追跡します。"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのスコアの推移を経時的に追跡します。"])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件の回答"]), _normalize([_interpolate(_named("n")), "件の回答"]), _normalize([_interpolate(_named("n")), "件の回答"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件のレビュー"]), _normalize([_interpolate(_named("n")), "件のレビュー"]), _normalize([_interpolate(_named("n")), "件のレビュー"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー："])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（社内品質スコア）は、会話レビューの結果です。"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = レビュースコアの合計 ÷ レビュー数"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したフィルター条件に一致するすべてのレビュースコア（カテゴリスコアの加重平均）を考慮して、平均として計算されます。"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードを読み込めませんでした。"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを変更して、もう一度やり直してください。"])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格率は、会話レビューの結果を示します。"])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レビュースコアが", _interpolate(_named("failScore")), "以下の場合は不合格"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レビュースコアが", _interpolate(_named("passScore")), "以上の場合は合格"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事前に定義された基準値によって決まります。すなわち："])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格率 = 合格したレビューの合計 ÷ 総レビューの合計"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格率は、重要または非重要カテゴリで不合格になることなく合格したレビューの割合を示します。"])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["たとえば、この日にレビュー担当者が3件のレビューを行った場合、表示される数字は3件のスコアの平均値です。各セルの計算方法は", _interpolate(_named("link")), "で確認してください。"])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["たとえば、エージェントがこの日に3件のレビューを受け取った場合、表示される数字は3件のスコアの平均値です。各セルの計算方法は", _interpolate(_named("link")), "で確認してください。"])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考慮されない項目：カテゴリのウェイト、重大なステータス（スコアの推移とは異なります）。"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューでカテゴリが「n/a」とマークされる場合があるため、カテゴリごとのレビュー数は、そのレビュー担当者によるレビューの総数よりも少ない場合があります。各セルの計算方法を確認してください"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考慮されない項目：カテゴリのウェイト、重大なステータス（スコアの推移とは異なります）。"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューでカテゴリが「n/a」とマークされる場合があるため、カテゴリあたりのレビュー数は、エージェントあたりの総レビュー数よりも少ない場合があります。各セルの計算方法を確認してください"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考慮されない項目：カテゴリのウェイト、重大なステータス（スコアの推移とは異なります）。"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " カテゴリグループは個別に表示されます。各グループのスコアは、同じ期間におけるそのグループの平均カテゴリスコアによって計算されます。各セルの計算方法は", _interpolate(_named("link")), "で確認してください。"])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモ:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考慮されない項目：カテゴリのウェイト、重大なステータス（スコアの推移とは異なります）。"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " カテゴリグループは個別に表示されます。各グループのスコアは、同じ期間におけるそのグループの平均カテゴリスコアによって計算されます。各セルの計算方法は", _interpolate(_named("link")), "で確認してください。"])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ベースライン ", _interpolate(_named("count")), "％"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大なエラーなし"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大エラー回避率は、重大なカテゴリで不合格にならずに合格したレビューの割合を示します。"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社内品質スコア"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「その他」の根本的な原因"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期的な変更"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日次変更"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月次変更"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週次変更"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年次変更"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアの推移"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日単位または週単位で表示します。"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計算方法 = 1日または1週間の合計スコアを付与されたレビューの合計数で割った値"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日単位または週単位で表示します。"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計算方法 = 1日または1週間の合計スコアを受け取ったレビューの合計数で割った値"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ別スコア"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この期間中にレビュー担当者によって付与したすべてのカテゴリの評価の平均を表示します。"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この期間中に各エージェントが受け取ったすべてのカテゴリ評価の平均を表示します。"])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低評価の根本的な原因"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価の根本的な原因"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリスコアの推移"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間の経過に伴うカテゴリの比較を分析します。"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した日または週の各カテゴリの平均スコアを表示します。"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間の経過に伴うカテゴリの比較を分析します。"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した日または週にカテゴリごとに受け取った平均スコアを表示します。"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー別スコア"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済み"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要なカテゴリ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価のスケール"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均/合計"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューを除外する"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカードをエクスポート"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信済み"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取ったレビュー"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["会話"]), _normalize(["会話"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["項目"]), _normalize(["項目"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), "件の回答"]), _normalize([_interpolate(_named("n")), "件の回答"]), _normalize([_interpolate(_named("n")), "件の回答"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件のレビュー"]), _normalize([_interpolate(_named("n")), "件のレビュー"]), _normalize([_interpolate(_named("n")), "件のレビュー"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["さらに1件表示"]), _normalize(["さらに", _interpolate(_named("n")), "件表示"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去7日間"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先月"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去1週間"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今週"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム期間"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四半期"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードを読み込めませんでした。フィルターを変更して、もう一度やり直してください。"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示するデータがありません。"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月の推移"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細情報"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなフィルターには、さまざまな評価スケールのデータが含まれています。"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらのフィルターを適用したすべてのカードピン"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらのフィルターを適用したすべてのダッシュボードピン"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均レビュー時間"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマイズ"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVをダウンロードする"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリなし"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリのインサイト"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グラフ"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["コメント"]), _normalize(["コメント"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マトリックス"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年月日"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧メンバー"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社内品質スコア"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（平均）"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格したレビュー"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードをピン留めする"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボードをピン留めする"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["根本的な原因"]), _normalize(["根本的な原因"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認時間"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因を設定する"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因から、会話のスコアが低下している理由を把握できます"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このカテゴリには根本的な原因が設定されていません"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア/合計"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリ"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリグループ"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリグループ"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["順序をカスタマイズする"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリのグループ化基準："])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて選択する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマイズ"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューを除外する"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューを含める"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューのみを表示する"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをクリアする"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグが見つかりません"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントのハッシュタグ"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントのハッシュタグを選択する"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグが見つかりません"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグを検索する"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードが見つかりません"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードを選択する"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビュー"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致するコネクションがありません"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ソース"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソースを選択する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他のフィルター"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致するワークスペースがありません"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計時間"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユーザー"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全員"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのグループ"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致するグループがありません。"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのグループ"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードを表示する"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データの表示基準："])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー作成日"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の作成日"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小数点以下第2位まで表示する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビューの設定"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペース"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント全体"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース名"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースがありません"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティ"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食べ物・飲み物"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動物・自然"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もの"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人・からだ"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行・場所"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よく使用する絵文字"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顔文字"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標識・記号"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絵文字が見つかりません"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を開く"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクリストに戻る"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), "、または", _interpolate(_named("link")), "のリストを参照します"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["現在使用しているのは、このドメインに接続されていない", _interpolate(_named("workspace")), "ワークスペースです。ここをクリックして接続します。"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能なコネクション"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["今はまだ", _interpolate(_named("host")), "にフィードバックを返すことができません。"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用のヘルプデスクをアクティブなタブにします。"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このドメインはまだ確認できません。必要な場合は、管理者に連絡してください。"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このドメインを追加する"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("host")), "と接続する"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定までお待ちください"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLを取得できませんでした"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織のZendesk QAアカウントに接続済みのツール。"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織のZendesk QAアカウントに接続されているツールはありません。"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを残す前にURLの末尾に一意のハッシュを追加することで、URLが常に同じままWebサイトをレビューできます"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを切り替える"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドメイン"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにこのワークスペースに接続されているドメイン。"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のドメインの任意のページをレビューできます"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー可能なドメイン"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内線"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一意のURL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ヘルプデスクがまだ接続されていません。", _interpolate(_named("link")), "にアクセスして追加します。"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAブラウザ拡張機能は、\nProfessionalプランとAdvancedプランでのみ使用できます。"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを切り替える"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前を入力してください"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値を入力してください"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムフィールド"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話に遭遇したのは今回が初めてです。詳細を入力してください。"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の参照情報："])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後でチケットを識別するためのものです"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者と件名の両方を入力してください"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成中"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を確認する"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未割り当てになりました"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["マネージャーに連絡するか、", _interpolate(_named("settings")), "から別のワークスペースに切り替えてください。"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このワークスペースでは自己レビューが無効になっています。"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインアウト"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございました"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このURLを確認できません。会話に移動してください。"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初にSSOプロバイダ経由でZendesk QAにサインインしてから、もう一度やり直してください。"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSOでサインイン"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインポップアップを開いています。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAアカウントでサインイン"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お待ちください..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証中..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだアカウントをお持ちでない場合は"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント登録"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話にはアクセスできません。必要な場合は、マネージャーに連絡してください。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセス権なし"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAでコネクションがアクティブになっていることを確認してください"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを管理する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションエラー"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用のヘルプデスクで会話に移動して続行してください"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なURL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再接続の完了まであと少しです。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少しお待ちください"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページの再読み込みを試して、作業の流れに戻ってください"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクの仮想的な境界から解放され、どこにいても会話をレビューできます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションをアップグレードして拡張機能をご利用ください"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを管理する"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクのコネクションはこのワークスペースにリンクされていません"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクがこのチケットを認識しないか、Zendesk QAにアクセス権がありません"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が見つかりませんでした"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいアクティビティ"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックがありました"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインしました"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これでアプリを使用できます"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリを評価します"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これを使用してケースをブックマークし、後からフィルターで除外する"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話にフィードバックはありません"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックを読む"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAで開く"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここにすべてのフィードバックと通知が表示されます。まだ何もありません…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAについてもっと見る"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたレビュー"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティ"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の詳細"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のすべてを含む"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のすべて"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のいずれも含まない"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のいずれでもない"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントより優れている"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントより劣っている"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値より大きい"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のいずれかを含む"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のいずれか"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のいずれも含まない"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のいずれでもない"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["違反"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント済み"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分がコメント済み"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントなし"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分からのコメントなし"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューへの影響大"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次を含む"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の期間内"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の日付から"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去14日間"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去24時間"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去7日間"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先月"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先週"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今週"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の日付まで"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨日"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検出あり"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存在する"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分が付けたスターあり"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分が付けたスターなし"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値を含む"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値より大きい"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値以上"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値より小さい"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値以下"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["違反でない"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複雑でない"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検出なし"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存在しない"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次を含まない"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未受信"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未読"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジティブ"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未レビュー"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分によるレビューなし"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー済み"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分がレビュー済み"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信済み"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューへの影響大"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次を含む"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値より大きい"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値以上"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値より小さい"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の値以下"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次を含まない"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧済み"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分のコメントに返信あり"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なし"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あり"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムカテゴリ"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムスポットライト"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を検索"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年月日"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスク"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メトリック"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューとフィードバック"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての条件を表示する"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライト"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットによる会話の処理を、平均的なエージェントと比較します"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットがループ状態になり、同じメッセージを連続して繰り返します"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内のボットの返信数"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に参加しているボットのタイプ"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に参加しているボット"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["会話のコンテンツに、エージェントのメッセージの語句「", _interpolate(_list(0)), "」が含まれています"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「このコールは録音されます」などの必須の開示通知メッセージが含まれていないコールを自動的に特定します。"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に参加している人またはボット"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話でレビューされているエージェントまたはボット"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが高度なサポートを希望"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話で検出されたポジティブおよびネガティブのセンチメント"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI分析により、会話内で最もアクティブなエージェントを特定します"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが丁寧に会話を終えたかどうかを分析します"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの感情を理解および認識しているかどうかを分析します"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントの文法、スペル、スタイルのミスを分析します"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["典型的な挨拶のフレーズについて会話全体を分析します"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの質問や懸念事項を理解しているかどうかを分析します"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語の複雑さと文の長さを考慮して、テキストの理解のしやすさを分析します"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソリューションの提案について会話全体を分析します"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話全体を通したエージェントのトーンを分析します"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客離れの兆候。顧客は切り替えを検討中か、進路を変更する兆しがあります。"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の所属先のグループ。"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージの内容またはテキスト"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話におけるエージェントの返信数"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のレビューを割り当てられたエージェント"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コールの会話が続いた時間（秒単位）"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の内容またはテキスト"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に使用されたコミュニケーションチャネル（メール、チャットなど）"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に追加されたコメント"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話にコメントが追加された日付"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話においてポジティブおよびネガティブなセンチメントが検出されました"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが顧客満足度アンケートに回答した日付"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のレビューに異議があるかどうか"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが会話を開始するために使用したチャネル"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話におけるカスタマーの応答数"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の最後のメッセージの日付"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に参加しているユーザーのロールまたはタイプ"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントとカスタマーの間の無音時間がしきい値を超えた"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーがより高度なサポートを求めた"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーは、受けたサポートに非常に感謝した、または非常に満足した"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーまたはエージェントが明示的にフォローアップをリクエストした"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音声通話で行われた会話全体のテキストまたは記録"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話がスター付きとしてマークされているかどうか"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテンツをカテゴライズするためにコメント内で使用されるハッシュタグ"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIが検出した会話の言語"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の最後のメッセージの日付"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内のプライベートメモの数"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内のメッセージあたりの平均文字数"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内のカスタマー向け返信の数"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報が公開されている関係者"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内のメッセージあたりの平均単語数"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の評価に使用するカテゴリ"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のレビュープロセスのステータス"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の参加者または関係者"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に関連付けられたメール"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューが閲覧されたかどうか"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を評価したレビュー担当者"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が最後にレビューされた日付"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話でレビューされているエージェント"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の満足度に関してカスタマーから提供された具体的なコメントやフィードバック"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に対する顧客満足度スコア"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内のメッセージごとの顧客満足度スコア"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のレビューに使用されたスコアカード"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA違反が発生した場合"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が発生したソースまたはコネクションのタイプ"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が普通ではない、または不規則で、解決に至るまでのやりとりが多かった"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の内容に関連するアンケートの質問"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に関連付けられたアンケートのタイトルまたは名前"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のレビューを割り当てられたエージェント"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に関連付けられたブランドまたは特定の製品/サービス"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に使用されたコミュニケーションチャネル（メール、チャットなど）"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が終了または解決した日付"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が最初に作成された日付"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が最初に作成された日付"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーが顧客満足度アンケートに回答した日付"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の簡単な説明または概要"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話にリンクした外部参照ID"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が整理されるフォルダまたは特定のグループ"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の構築に使用したフォームまたはテンプレート"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話にリンクした外部参照ID"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を受信したメールボックスまたはメールアカウント"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に関連付けられた電話番号"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に割り当てられた優先度"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話内の返信の総数"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の状態または現在のステータス"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の現在のステータス"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の件名またはトピック"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話におけるエージェントの返信数"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を分類するために割り当てられたタグ"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のタイプまたはカテゴリ"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が最後に更新された日付"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が最後に更新された日付"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が行われたメディアまたはチャネル"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の会話ログの内容"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI分析によるレビューの対象外となる会話"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話に参加しているエージェントの数"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が閲覧されたかどうか"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任意のメッセージ"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットのコミュニケーション効率"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの繰り返し"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットの返信数"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットのタイプ"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コール方向"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントメッセージ"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デッドエア"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["録音開示メッセージなし"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エスカレーション"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コールの会話ログのコンテンツ"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージング"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スター付き"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象外"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー者"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の経由チャネル"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルと文法"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージ"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最もアクティブなエージェント"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントの返信数"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コール時間 (秒)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の内容"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージチャネル"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントタグ"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントした日"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATアンケートの回答日"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共感力"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーチャネル"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーの返信数"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終返信日"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザータイプ"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解約リスク"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デッドエア"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エスカレーション"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優れたサービス"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローアップ"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ログ"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントのハッシュタグ"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外れ値"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解力"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終返信日"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のプライベートメモの数"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均文字数"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均単語数"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリック返信数"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリック関係者"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価日"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価とコメント"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリ"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューのステータス"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読みやすさ"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連メール"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リレーション"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信数"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを受信"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー済みスコアカード"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧したレビュー"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューした日"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのコメント"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージあたりのCSAT"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満足度スコア（CSAT）"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されたソリューション"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソースタイプ"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件名"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの質問"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのタイトル"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のブランド"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドドメイン"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話チャネル"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATアンケートの回答日"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATアンケートの送信日"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の説明"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話フィールド"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話フォルダ"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話フォーム"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FreshdeskのID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話グループ"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプスカウトID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インターホンID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話メールボックス"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の優先度"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の返信数"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のステータス"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のステータス"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の件名"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントの返信数"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のタイプ"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZendeskのID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーン"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ログの内容"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加エージェント数"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧済みステータス"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脆弱性機能"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脆弱性の健全性"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脆弱性のライフイベント"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAカテゴリ"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付関連の条件を選択します"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターに名前を追加します"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター名を入力してください"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン中のユーザー"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元ワークスペースのメンバー"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースのメンバー"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), "を追加する"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを適用する"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付関連のフィルターが1つ以上必要です"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションを選択する"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションを選択する"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは残り", _interpolate(_named("n")), "日です。", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>残り", _interpolate(_named("n")), "日です</strong></a>。"])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは残り", _interpolate(_named("n")), "日です。", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>残り", _interpolate(_named("n")), "日です</strong></a>。"])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは本日終了します。", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のトライアルは<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>本日</strong></a>終了します。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐサブスクリプションを設定"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "日間"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは残り", _interpolate(_named("days")), "です"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["プランのユーザー数の上限に達しました。 ", _interpolate(_named("link")), "で詳細を確認するか、プランをアップグレードしてください。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここをクリック"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクに接続"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、チケットを取得して確認することができます。"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクに接続"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サンプルデータでZendesk QAを使用しています。サンプルデータを削除して自分の会話をインポートするには ", _interpolate(_named("link")), "をクリック。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(サンプルデータ)"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下で有益な情報をコピーします"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページ全体のスクリーンショットを撮ってコンテキストを提供します"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["弊社のカスタマーサポートチームに連絡して、この問題を解決してください："])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーの参照情報："])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役に立つ情報をコピーする"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー、ボット、ワークスペース"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーとワークスペース"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今週"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("period")), "、", _interpolate(_named("n")), "/", _interpolate(_named("total")), "レビュー済み"]), _normalize([_interpolate(_named("period")), "、", _interpolate(_named("n")), "/", _interpolate(_named("total")), "レビュー済み"]), _normalize([_interpolate(_named("period")), "、", _interpolate(_named("n")), "/", _interpolate(_named("total")), "レビュー済み"])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新機能"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインアウト"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーボードショートカット"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティ"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャットで連絡"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャットを読み込めませんでした（広告ブロッカーを使用していませんか？）"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジャンプ先..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプ"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームページ"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付与したレビュー"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたレビュー"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナレッジベース"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを切り替える"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アプリケーションが更新されました。", _interpolate(_named("buttonStart")), "再読み込み", _interpolate(_named("buttonEnd")), "。"])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの一部としてZendesk QAの使用を開始する場合は："])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ワークスペースの一部としてZendesk QAをご利用いただくための招待状がすでに届いていることをお知らせします。ご希望の場合は続行していただけますが、これにより", _interpolate(_named("bold")), "が作成されることにご注意ください。"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の組織"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["はい、", _interpolate(_named("link")), "で続行します。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいアカウント"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よろしいですか?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), "が", _interpolate(_named("account")), "に参加するように求めます"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し込む"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インターネット接続がありません"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいピンを追加"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイックナビゲーション"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントエディターにフォーカスして展開する"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイドパネルを表示/非表示にする"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の会話に移動する"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元の会話を開く"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の会話に移動する"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリに最高の評価を付ける"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリに最低の評価を付ける"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話をシャッフルする（ランダムに並べ替える）"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教えてください"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーボードショートカット"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショートカットモーダルを切り替える"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["文字数の上限である", _interpolate(_named("charLimit")), "文字に達しました"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["画像", _interpolate(_named("name")), "のタイプ（", _interpolate(_named("type")), "）は許可されていません"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["画像", _interpolate(_named("name")), "のサイズが大きすぎます（最大5MBまで）"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントエディタを読み込めませんでした"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドロップしてアップロードする"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクさせる"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクさせる"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキスト"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートを管理する"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートが追加されていません"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因を追加する"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除された理由を含むその他"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価の理由"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従業員数"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの設定をサポートするためにご連絡いたします"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の連絡先電話番号"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAを初めてお知りになったきっかけは何ですか？"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルを選択する"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントの数を選択する"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続行"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての必須フィールドに入力してください"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当社の利用規約およびプライバシーポリシーを読んで、その内容に同意する必要があります。"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポート主導型のSlackチャネル"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["広告"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友人または同僚"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクマーケットプレイスまたはアカウントマネージャー"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参照サイト"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索エンジン（Google、Bingなど）"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソーシャルメディア"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご自身や会社について教えてください。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント情報"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["準備ができました。"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索し、アプリの概要を理解して、削除や追加を行います。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントが作成されました。次は、"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実際の会話に参加する準備はできていますか？"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独自のデータを追加する"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索を開始する"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サンプルデータで試す"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["では、レビューするチケットの取得を開始します。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクアカウントに接続する"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気が変わりました。"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録をキャンセルする"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ピン留めを作成しました。", _interpolate(_named("buttonStart")), "ピン留めに移動", _interpolate(_named("buttonEnd")), "。"])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングピン留め機能はProfessionalプランで利用できます。"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランをアップグレードする"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのプランを見る"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモを保存して、次回の1対1のコーチングセッションに備えます。"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングのピン留め機能"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントするか、", "@", "で他のユーザーを追加する"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話は削除されました"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいピン留めを作成する"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めを作成する"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めを編集する"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めのしくみ"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を表示する権限がありません"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードをピン留めする"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話をピン留めする"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボードをピン留めする"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めを保存しました"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このカードをピン留めする"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話をピン留めする"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このダッシュボードをピン留めする"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの下にピン留めする"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを選択する"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めした内容は、自分だけが見ることができます。他のユーザーと共有するには、コメントで", "@", "メンションしてください。"])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), "がこの会話に招待されています"]), _normalize([_interpolate(_named("agentsNames")), "がこの会話に招待されています"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件のコメント"]), _normalize([_interpolate(_named("n")), "件のコメント"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["あなたが", _interpolate(_named("participants")), "にメンションしましたが、彼らはこの会話に参加していません"]), _normalize(["あなたが", _interpolate(_named("participants")), "にメンションしましたが、彼らはこの会話に参加していません"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "名の関係者"]), _normalize([_interpolate(_named("n")), "名の関係者"]), _normalize([_interpolate(_named("n")), "名の関係者"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "がこのピン留めにアクセスできないようにしますか？"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何もしない"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話に招待しない"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを追加する"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待する"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話に招待する"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを削除する"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スレッド"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作は取り消しできません。"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、このピン留めのすべてのコメントも削除されます。"])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件の新規メッセージ"]), _normalize([_interpolate(_named("n")), "件の新規メッセージ"]), _normalize([_interpolate(_named("n")), "件の新規メッセージ"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのピン留めを見ることができるのは自分だけです。ピン留めを共有するには、コメント内で誰かを", "@", "します。"])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "が", _interpolate(_named("pinType")), "をピン留めしました"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモ"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなた"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップグレードしてコーチングピン留め機能を入手"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングピン留め機能はProfessionalプランで利用できます。"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングピン留め機能はProfessionalプランで利用できます"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップグレードして機能を利用する"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1対1のセッションの準備や、別の機会に向けた保存の目的で利用できます。"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、ピン留めと追加されたすべてのコメントが完全に削除されます。"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めを削除"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めを削除"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォルダ名"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後で見たい項目をピン留めする"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいものから表示"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古いものから表示"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1対1のセッションの準備としてダッシュボードの興味深い傾向や会話を保存したり、別の機会に向けてメモに保存したりします。"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めを作成するには、上のボタンをクリックするか、会話ビューまたはダッシュボードビューでピンアイコンをクリックします。"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めする"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留めが作成されていません"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "の結果がありません"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のキーワードを使用するか、タイプミスがないか確認してください。"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいピン留めを作成する"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージ"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー、キーワードで検索..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルファベット順"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近作成したのも"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近編集したもの"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ほとんどのピン留め"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目標レビュー数：", _interpolate(_named("n")), "会話/", _interpolate(_named("schedule"))]), _normalize(["目標レビュー数：", _interpolate(_named("n")), "会話/", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標が更新されました"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標を設定する"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューしたい会話の数に個人的な目標を設定できます。"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎週"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標を確認する"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルを読み込んでいます"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルを選択する"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのコメント付きフィードバックのみ共有する"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス品質の素晴らしさと高評価のカスタマーフィードバックを強調して、同僚を支援します。"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの肯定的なフィードバックをSlackに共有する"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルが見つかりません"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアが上位の場合、自動的にフィードバックを共有します"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["称賛ボットインテグレーション"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを実行していない場合、週の初めにリマインダーが届きます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リマインダー"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎週行うレビューのリマインダー"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレビュー、返信、メンション、異議に関する通知をSlackで受信します。"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンション"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スレッドの返信"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackインテグレーション"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレビュー、返信、メンション、および異議について通知します。"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分に関連する異議"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント内のメンション"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しく受け付けたレビュー"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スレッドによる返信"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計を含むレポート"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分が行ったレビューまたは受けたレビューに関するレポートを受け取ります。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月次"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎週"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3時間おき"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6時間おき"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瞬時に"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフ"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎週"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント管理者が通知設定をロックしました。"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間を選択する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人通知"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "（アクティブ）"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアカウントに新しいソーシャルサインインを追加する："])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなサインイン方法を削除すると、サインアウトされます。別のメールアドレスでもう一度サインインできます。"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン方法を削除"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleをサインインオプションとして追加しました"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインオプション"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackをサインインオプションとして追加しました"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleを追加する"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackを追加する"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを作成する"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よろしいですか?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方法"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットする"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["パスワードをリセットするため、", _interpolate(_named("email")), "宛てのメールが送信されます。"])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メインのメールアドレス"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信トレイで確認メールをチェックしてください"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンク"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["これを変更するには、 事前に", _interpolate(_named("link")), "を変更する必要があります。"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["メール", _interpolate(_named("email")), "がこのパスワードに関連付けられます。"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールが更新されました"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール画像"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最良の結果を得るには、正方形の写真（256x256ピクセル以上）をアップロードしてください"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メインのメールアドレス"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週の最初の曜日"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人設定"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1つ以上のオプションを選択します"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートの内容"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート名"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートの表示先:"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートのコンテンツを追加してください"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート名を追加する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートを作成する"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートを作成する"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントテンプレートを作成して、レビュー、項目のピン留め、コーチングをスピードアップします。"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートを削除する"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このテンプレートを完全に削除しますか?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートを編集する"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントテンプレートを作成して、レビュー、項目のピン留め、コーチングをスピードアップします。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートが作成されていません"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントテンプレートが作成されました"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントテンプレートが削除されました"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントテンプレートを保存しました"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留め"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューと異議"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アクセスするには、", _interpolate(_named("link")), "までお問い合わせください。"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサポート"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントが見つかりません"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["現時点では、", _interpolate(_named("link")), "を使用してZendesk QAにアクセスしてください。"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskに戻る"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントが移行されていません"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケート"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メトリック"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信済み"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分のコメント"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分の異議"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分のレビュー"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティ"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リアクション"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのフィードバック"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティ"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピン留め"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを作成する"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資格情報"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセットする"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標を確認する"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートを作成する"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引先"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスク"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インテグレーション"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライセンス"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいカテゴリ"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライト"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケート"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["競合するメッセージが見つかりました"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["競合するメッセージの1つを変更または削除してください"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("placeholder")), "アイコンを使用して、機密データをオートQAから除外します。たとえば、「それではまた、John」の代わりに「それではまた、", _interpolate(_named("placeholder")), "」を使用して任意の名前を表します。"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最大", _interpolate(_named("limit")), "行"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルールの適用先"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムカテゴリの数が上限の10個に達しました"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["カスタムカテゴリの数が上限の", _interpolate(_named("limit")), "個に達しました"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を追加"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行を追加"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["かつ"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を削除"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージが"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それ以外の場合はスコア"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアを設定する"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードのカテゴリを作成"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「/」を使用して変数を追加します"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての方向"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージを空白にすることはできません"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージは300文字を超えることはできません"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テキスト"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのソース"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのチャネル"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのタグ"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての会話"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルールの適用先を絞り込みます。"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定の会話"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライトのインサイトを作成"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージを空白にすることはできません"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムインサイトの数が上限の10個に達しました"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["カスタムインサイトの数が上限の", _interpolate(_named("limit")), "個に達しました"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイト名を入力してください"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイト名"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを作成する"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイトタイプ"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジティブ"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの設定に移動する"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後で確認"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この評価カテゴリの使用を開始するには、ワークスペースのスコアカード設定に移動して、選択したスコアカードにカテゴリを追加します。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリが作成されました"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定のキーワードやフレーズを特定することで、終了した会話を自動的にスコアリングします。"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音声通話の会話ログでは機能しません。"])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ハイタッチ！ ✋ 自動評価カテゴリの上限", _interpolate(_named("limit")), "件に達しました 。あなたは分類の達人です! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリの上限に達しました"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのフィールドが正しく入力されていることを確認してください"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリに名前を付けます"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ名"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい評価カテゴリ"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい評価カテゴリ"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定のキーワードやフレーズを特定することで、終了した会話を自動的にスコアリングします。音声通話の会話ログでは機能しません。"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定のキーワードやフレーズを識別することで、終了した会話を自動的に表示します。会話ログのみで機能します。"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージとボットメッセージの両方に含まれる特定のキーワードまたはフレーズを識別することで、会話を自動的にスコアリングします。"])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この評価カテゴリを使用するには、ワークスペースのスコアカード設定に移動して、スコアカードにこのカテゴリを追加します。"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージ内の特定のキーワードやフレーズに基づいて会話を自動的にスコアリングします。"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用対象"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インサイトの設定"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このインサイトは、定義された条件に基づいてスポットライトに表示されます。"])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードの設定"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このカテゴリは、定義された基準に基づいてスコアカードで使用できます。"])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャット"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まもなく対応"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事前定義"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライト"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音声通話"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存する"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存しました"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヒント："])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["{··}", "アイコンをクリックして、オートQAのクロージングチェック対象から名前、会社の詳細情報、またはその他の機密情報を除外します。"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージングを追加する"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージングを編集する"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージング"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外を追加する"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外を編集する"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みの挨拶を追加する"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みの挨拶を編集する"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みの挨拶"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語を改行で区切って、ここに貼り付けます"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語を新しく入力するごとにEnterキーを押します"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用言語"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["たとえば、「それではまた、John」の代わりに「それではまた、", "{··}", "」を使用して任意の名前を表すことができます。"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動的コンテンツのプレースホルダ"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件の例外を追加済み"]), _normalize([_interpolate(_named("count")), "件の例外を追加済み"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["既に追加済みの例外です"]), _normalize(["既に追加済みの例外です：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外を追加する"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外が見つかりません"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外が追加されていません"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外（大文字と小文字を区別）"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["例外を追加する"]), _normalize(["1件の例外を追加する"]), _normalize([_interpolate(_named("count")), "件の例外を追加する"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語または語句を検索する"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外を保存しました"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての言語"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を検索する"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["既に追加済みのクロージングです"]), _normalize(["既に追加済みのクロージングです：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージングを追加する"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージングが見つかりません"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージングはありません"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージング"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージングを追加する"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージングを検索する"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージングが追加されました"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージングを保存しました"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["フォームに重複したエントリがあります：", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["既に追加済みの挨拶です"]), _normalize(["既に追加済みの挨拶です：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶を追加する"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認された挨拶が見つかりません"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認された挨拶はありません"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶を追加する"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶を検索する"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶が追加されました"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶を保存しました"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外を追加する"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["例外を削除する"]), _normalize(["例外を削除する"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["この例外を完全に削除しますか?"]), _normalize(["これらの例外を完全に削除しますか?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例外を編集する"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["複数の例外で、", _interpolate(_list(0)), "文字を超えています。"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), "文字"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新者"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変数"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドとサービスの基準に沿った独自の承認を定義します。追加すると、オートQAは承認済みのクロージングのみを検出します。加えられた変更内容は今後の会話に適用されます。"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みクロージングの上限数100件に達しました。さらに追加したい場合は、既存のクロージングを見直して改善する必要があります。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージング数の上限に達しました"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージング"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルや文法のミスとしてオートQAに無視させたい単語やフレーズです。加えられた変更内容は今後の会話に適用されます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無視リスト"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドとサービスの基準に沿った独自の挨拶を指定します。追加すると、オートQAは承認済みの挨拶のみを検出します。加えられた変更内容は今後の会話に適用されます。"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みの挨拶の上限数100件に達しました。挨拶をさらに追加したい場合は、既存の挨拶を見直して改善する必要があります。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶の承認数の上限に達しました"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認された挨拶"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後で確認"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを削除する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この評価カテゴリを完全に削除しますか?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリリストを切り替える"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されたカテゴリは、新しい会話やフィルターには表示されません。ダッシュボードと会話の履歴データはそのまま残ります。"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除したカテゴリは、その追加先であったすべてのスコアカードから削除されます。"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードのカテゴリを削除できませんでした"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードのカテゴリを削除しました"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードのカテゴリを削除"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAカテゴリ"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AIモデルを活用した", _interpolate(_named("highlight")), "はスコアカードに残りますが、 自動スコアリングは行われなくなります"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLMインテグレーションをオフにしても、 オートQA機能は完全にはオフになりません。次のような影響が生じます："])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歴データ"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "はオートQAダッシュボードに残ります"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動で追加した根本原因"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "は残りますが、LLMベースの根本原因はすべて削除されます"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLTベースのオートQAをオフにする"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフにする"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAは、AIと大規模言語モデル（LLM）を使用して特定のカテゴリを自動的にスコアリングします。お客様のデータは厳格なデータ処理契約（DPA）に基づいて処理され、モデルのトレーニングに使用されることはありません。"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成AIについて"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["オートQAは、", _interpolate(_named("automatically_scoring_categories")), "のにMicrosoft Azure OpenAIサービスを使用しています。お客様のデータは、オートQAを提供する目的でのみ厳格なDPAの下で処理され、", _interpolate(_named("never_used_for_training_models")), "。"])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部のカテゴリに自動的にスコアを付ける"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenAIモデルのトレーニングに使用されることはありません"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLMベースのオートQAがオンになりました"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLMベースのオートQAがオフになりました"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLMベースのオートQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAのAIを使用した自動スコアリング"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS AzureベースのオートQAカテゴリのデータ処理"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azureの同意を取り消しました"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azureの同意が得られました。会話はまもなく自動評価を受けます。"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き続きオートQAをご利用いただけますが、以下のような影響が生じます"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAIサービスを無効にしても、機能としてのオートQAは無効になりません。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードのカテゴリビューでこれを行うことができます。"])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モデルを利用したオートQAカテゴリはスコアカードに残りますが、自動的にスコア付けされることはありません。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動で追加した根本的な原因は残ります。モデルによって生成された根本的な原因はすべて削除されます。"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歴データはオートQAダッシュボードに残ります。"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAIサービスによるデータ処理を無効にする"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幅広いオートQAカテゴリ。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐに使える多言語サポート。"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいカテゴリの急速な追加。"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価の精度が向上しました。GPT-3.5は、人間レベルのコンテキスト認識を示します。"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最先端に進歩したAIテクノロジーを活用しましょう。"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容は次のとおりです。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAIサービスによるデータ処理を有効にする"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意が得られました"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意が得られませんでした"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての新規ユーザーはこのワークスペースに割り当てられます"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトのワークスペース"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12時間表示（", _interpolate(_named("time")), "）"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24時間表示（", _interpolate(_named("time")), "）"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間表記"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のプロフィールが更新されました"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週の最初の曜日"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムインテグレーション"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセスキーIDとシークレットキーは通常、「アクセス管理」 > 「ユーザー」で確認できます"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWSアクセスキーID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connectを追加"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットアクセスキーの変更をキャンセル"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットアクセスキー値を変更"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このコネクションではリージョンを1つだけ定義できます。リージョンは通常、「所在地」の下で確認できます"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWSリージョン"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットキーを追加"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWSシークレットアクセスキー"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連情報はすべてAmazon Connectで確認できます。"])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常は、Amazon Connectのインスタンス設定とデータストレージページで確認できます"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コール録音の保存場所"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connectの追加中にエラーが発生しました。すべてのフィールドが正しく入力されていることを確認し、もう一度やり直してください。"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存場所は、スラッシュで区切った バケット/フォルダ の形式にする必要があります。"])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["コネクションを追加する場合は、", _interpolate(_named("link")), "までご連絡ください。"])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサポート"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のZendeskチケットはすべて自動的に同期されます。選択したチケットを除外することもできます。"])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アジアパシフィック (東京)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アジアパシフィック (ソウル)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アジアパシフィック (大阪)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アジアパシフィック (ムンバイ)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アジアパシフィック (シドニー)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カナダ (中部)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧州 (フランクフルト)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧州 (チューリッヒ)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧州 (アイルランド)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧州 (ロンドン)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーのリージョン"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中東 (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーのリージョンを選択"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南米 (サンパウロ)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["米国東部（バージニア）"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["米国東部2（オハイオ）"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["米国西部 (オレゴン)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この接続はすでに追加されています"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを開始"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の条件に一致する会話のみ同期する"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル1、チャネル2"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このコネクションの一意のIDは", _interpolate(_named("tokenId")), "です"])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1か月"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1年"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3か月"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6か月"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブな会話は、選択した保持期間が過ぎると削除されます。送信されたレビューは、アクティビティビューとダッシュボードビューに表示されたままになります。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存期間"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存期間を選択する"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作は取り消しできませんのでご注意ください。"])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作により、会話、フィールド、タグなど、このコネクションのヘルプデスク関連のすべてのデータがZendesk QAから削除されることを確認しました。"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信済みのレビューは影響を受けず、特定のビューに表示されたままになります。"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ1、グループ2"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次を含む"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブな開発段階"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このインテグレーションは", _interpolate(_named("activeDevelopment")), "にあります。一部の詳細情報（添付ファイルやカスタムフィールド）が表示されない場合があります"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールボックス1、メールボックス2"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ1、タグ2"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIトークン"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIトークンシークレット"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdeskの「設定」→「チケットフィールド」で「チケットフィールド名」を見つけます"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scoutの「Settings」→「Custom fields」で「Custom Field Names」を見つけます"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomerの「設定」→「Klasses」→「会話」で「Klass会話名」を見つけます"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Agentの「設定」→「カスタムフィールド」で「カスタムフィールド名」を見つけます"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wixの「設定」→「カスタムフィールド」で「カスタムフィールド名」を見つけます"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィールドIDをカンマで区切ります。これらのIDは、Zendeskの「管理」→「チケットフィールド」で確認できます。"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用のヘルプデスクのユーザーを何人か接続して、最初の会話のレビューを行います。ワークスペースのメンバーになるように招待しない限り、これらのユーザーへの通知は行われませんのでご安心ください。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統合が完了しました"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーを追加する"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の取得を開始します。"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ドメイン：", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ヘルプデスクのID：", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを認証できませんでした"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["準備が完了しました。新しいコネクションは以下の通りです。"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部のコネクションが更新されていないか、非アクティブになっています。これらのコネクションを更新するか、リストから削除してみてください。"])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIクライアントID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIクライアントシークレット"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["新しいAPIトークンの作成は、「設定」→「アカウント」→「APIとSDK」から行います。使用するリダイレクトURLは", _interpolate(_named("redirectUrl")), "です"])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始するにはヘルプデスクを選択してください"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercomアカウントのサービス提供元がEUまたはオーストラリアに設定されている場合は、これを更新します。"])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "へのサインインに使用するメールアドレス"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エンドユーザーの名前、メールアドレス、電話番号を隠します。銀行の認証情報は、すべてのヘルプデスクのコネクションで隠すように既に設定されています。"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インテグレーションはまだありません。今すぐ追加してください。"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のロールが必要です："])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを作成する"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいコネクションを追加する"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このチェックボックスをオフにすると、このコネクションを特定のワークスペースにのみ手動で追加できます"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のすべてのワークスペースに接続する"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペース"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIキー"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIキーシークレット"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["コネクション", _interpolate(_list(0)), "を削除しました"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション名"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日時"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIトークンは以下で取得できます。"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATのしきい値"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["コネクション", _interpolate(_named("connectionName")), "を削除する"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このコネクションを削除するには、「コネクションを削除」と入力します"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを削除する"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続されたデータソースを管理および監視します。"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを編集する"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次を除外"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィールド名をカンマで区切る"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新に失敗しました"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インテグレーションに一意の名前を付けます"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細オプションを非表示にする"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機密データフィールドを非表示にする"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイルのURLを保存しない"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイルを無視する"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の内容を保存しない（後で必要に応じてヘルプデスクから取得できます）"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の内容を無視する"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の件名を保存しない（後で必要に応じてヘルプデスクから取得できます）"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件名を無視する"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブ"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["お使いの", _interpolate(_list(0)), "サブドメイン"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercomの地域"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信トレイのチームIDを一致させる"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercomチームの数値ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処理中..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールボックス"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを手動で更新する"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客データをマスクする"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースがありません"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機密フィールド名"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機密フィールドID"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを更新する"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを更新する"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforceサンドボックスに接続する"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "とのコネクションを設定する"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細オプションを表示する"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定したしきい値以下のスコアの会話のみを取り込みます"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATのしきい値を適用する"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の会話のみ同期する..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新の会話を取得する"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを更新する"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションが更新されました"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスク"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サブスクリプションを解約してアカウントを削除する場合は、", _interpolate(_named("link")), "までご連絡ください。"])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサポート"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを削除する"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Support（または別のヘルプデスク）でチケットを削除しても、関連する会話のレビューには影響しません。それらの会話は引き続きレビューおよび管理できます。"])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QAでチケットを削除すると、関連付けられた会話のレビューも削除されます。", _interpolate(_named("line_break")), "Zendesk Support（または別のヘルプデスク）でチケットを削除しても、関連する会話のレビューには影響しません。それらの会話は引き続き表示および管理ができます。"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを管理"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを削除すると元に戻せません。ダッシュボード上のデータにも影響します。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのレビューを削除"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて削除"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのレビューを削除しました"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて削除"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除済みのチケットに関連するレビューがある場合、ここに表示されます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだレビューがありません"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットIDを検索"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チケットID：", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除済みの会話"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["デフォルトのワークスペースは削除できません。このワークスペースを削除するには、", _interpolate(_named("link")), "で別のデフォルトワークスペースを選択します。"])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント設定"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作は取り消しできません。"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのデータを削除"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この操作により、ワークスペースユーザーやコネクションを含め、このワークスペースに関連する", _interpolate(_named("removeAllData")), "されることを確認しました。"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを削除する"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このワークスペースを削除するには、「ワークスペースを削除する」と入力します"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ワークスペース", _interpolate(_named("workspaceName")), "を削除する"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを削除する"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実行しようとしている操作に間違いがないことを確認するか、事前に専門家に相談してください。"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトのレビュー対象者を、最もアクティブなエージェントに基づいて選択するか、現在の担当者に基づいて選択するかを選択します。"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最もアクティブなエージェント"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトのレビュー対象者"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーに会話の自己レビューを許可し、エージェントがレビューを実行できるようにします"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースが削除されました"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースが更新されました"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のレビュー担当者が以前に投稿したレビューを非表示にして、中立的な評価を設定する"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公平な評価"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カラー"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作は取り消しできません。"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、ワークスペースのユーザーやコネクションなど、このワークスペースに関連するすべてのデータが削除されます。"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを削除する"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを削除しています..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビュー"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンク"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マジックリンク"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のサインイン方法を非アクティブにできません"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証方法が更新されました"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユーザーに特定のタイプの認証方法を許可します。"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーとワークスペース"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの作成と管理、ユーザーの招待または接続を行います。"])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動しない"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動する"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページから移動すると、すべての変更が失われますがよろしいでしょうか？"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インポートする会話がないようです。最初に、ご利用のヘルプデスクでいくつか会話をしてから、ここに戻って分析します。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が見つかりません"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このシステムカテゴリは削除できません"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを削除"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを削除すると元に戻せません。これにより、レポーティングからすべてのカテゴリデータが消去されます。"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このカテゴリを復元するには、再度作成する必要があります。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを削除"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを検索"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動カテゴリは、提案された評価を自動的にスコアカードに入力し、レビューにかかる時間を節約します。手動カテゴリは、レビュー担当者がゼロから入力します。"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終更新"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージング"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共感力"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルと文法"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解力"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読みやすさ"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されたソリューション"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーン"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリが見つかりません"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因が見つかりません"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードが見つかりません"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の検索を実行するか、フィルターを変更してみてください"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のカテゴリ"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のカテゴリ"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のカテゴリ"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリ"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のカテゴリ"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のスコアカード"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のスコアカード"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のスコアカード"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのスコアカード"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のスコアカード"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のステータス"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のステータス"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のステータス"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのステータス"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のステータス"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のタイプ"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のタイプ"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のタイプ"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのタイプ"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のタイプ"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のワークスペース"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のワークスペース"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースがありません"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のワークスペース"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペース"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件のワークスペース"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), "は総合スコアに", _interpolate(_named("percentage")), "%寄与します"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードを削除"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードを削除すると元に戻せません。これにより、レポーティングからすべてのスコアカードデータが消去されます。"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このスコアカードを復元するには、再度作成する必要があります。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードを削除"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終更新"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブ"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["評価スケールが更新されました。", _interpolate(_named("buttonStart")), "レビューを開始", _interpolate(_named("buttonEnd")), "。"])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAはデフォルトで2進数のスケールを設定しますが、より詳細なスケールを選択することもできます："])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["カテゴリ別に設定されたさまざまな評価スケールオプションを表示するには、", _interpolate(_named("link")), "に移動してください"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共感力/トーン"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品の知識"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価スケールの更新による："])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前の評価スケールと対応するカテゴリはアーカイブされます（削除されません）。"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新旧のスケールを比較できない可能性があるため、一部のダッシュボードデータが影響を受けます。"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存の評価カテゴリの設定を新しいスケールに複製する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価スケールを更新してもよろしいですか？"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードの表示形式（数字または絵文字）を変更したり、カテゴリを必須またはオプションにすることができます。"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字の代わりに絵文字を使用する"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーがカテゴリをスキップすることを許可する"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決策"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価スケールのタイプ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1個のワークスペースのカテゴリが複製されました"]), _normalize([_interpolate(_named("workspacesAmount")), "個のワークスペースのカテゴリが複製されました"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの設定"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("messageType")), "が"])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーに会話中に無音があることを伝えていない場合に、コールを自動的に表示します。"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定されたしきい値以上のデッドエアが会話に含まれています。"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コールでの最長デッドエアの閾値を定義します"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デッドエア"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されたインサイトは、新しい会話やフィルターには表示されません。ダッシュボードと会話の履歴データはそのまま残ります。"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライトインサイトを削除できませんでした"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライトインサイトを削除しました"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライトインサイトを削除"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ログやメッセージに特定の語句を含む会話を表示します。"])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「このコールは録音されます」などの必須の開示通知メッセージが含まれていないコールを自動的に特定します。"])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文脈を動的に理解する大規模言語モデル（LLM）を使用して通話内の録音開示通知メッセージを識別し、事前定義フレーズの必要を排除することを目指します。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["録音開示メッセージなし"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーに会話中に無音があることを伝えていない場合に、コールを自動的に表示します。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デッドエア"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コール中のデッドエアの瞬間を指定する"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サブスクリプションを変更する必要がありますか？ ", _interpolate(_named("link")), "（", _interpolate(_named("email")), "）私たちがお手伝いします。"])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェント、BPO、チャネル、言語間で会話を100%自動分析します。AIが問題、ナレッジベースの欠点、コーチングの機会を検出して、サービスを向上させます。"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQAを100%適用"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのフィードバックとコーチング"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライトのAIインサイト"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細なレポートとダッシュボード"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント分析とフィルタリング"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンデマンド翻訳"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てと目標設定の確認"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングとクイズ"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk品質保証（QA）"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionalのすべての機能に加えて、以下を利用可能"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced（旧バージョン）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大なエラーを即座に通知するWebhook"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データウェアハウスの統合"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloudインテグレーション"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムインテグレーション開発"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["専任のカスタマーサクセスマネージャー"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack経由でZendeskの製品チームとエンジニアリングチームに直接アクセスできます"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suiteのすべての機能に加えて、以下を利用可能"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100%の適用範囲のためのオートQA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データを理解するためのAI駆動型の会話のインサイト表示"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIを活用したヘルプデスクデータの充実化とフィルタリング"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライト:レビューが必要なインタラクションの自動検出"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント分析とフィルタリング"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIを活用したパフォーマンスレポーティングとインサイト"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンデマンドの会話翻訳"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムトラッキングを確認する"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格率分析"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suiteのすべての機能に加えて、以下を利用可能"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SalesforceおよびGenesysインテグレーションを含むエンタープライズ実装パッケージ"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムインテグレーション開発"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データウェアハウスの統合"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大なエラーを即座に通知するWebhook"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["専任のカスタマーサクセスマネージャー"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskの製品チームとエンジニアリングチームに直接アクセスできます"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["早期アクセスとロードマップへの影響"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューの割り当てと目標設定の自動化"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲットを絞ったコーチングと分析"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラーニング管理用クイズ"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーロールと権限の管理"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機密データのマスキング"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細なフィルタリングオプションと会話検索"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複数の評価、調整可能な重み付け、自動不合格オプションを備えたカスタマイズ可能なスコアカード"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どこでも会話を確認できるブラウザ拡張機能"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因の分析"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェント、チーム、BPOを比較するためのパフォーマンスレポーティング"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATアンケートとカスタマーフィードバック分析"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機能"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starterのすべての機能に加えて、以下を利用可能"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional（旧バージョン）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ごとの複数の評価スケール"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackインテグレーション"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIを活用したインタラクティブな会話のインサイト"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを確認する"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチング用のピン留め"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーチングセッション"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センチメント分析とフィルタリング"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の複雑さに基づくフィルタリング"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話のインサイト"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付きスコアカード"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因の分析"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIを活用した高度なCSAT分析"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価のキャリブレーション"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どこでも会話を確認できるブラウザ拡張機能"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー必須の会話を見つけるためのスポットライト"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンデマンドの会話翻訳"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムトラッキングを確認する"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムプラン"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter（旧バージョン）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無制限の会話レビュー"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVエクスポート"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同僚同士のレビューと自己レビュー"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調整可能な重み付けと自動不合格のオプションを備えたスコアカード"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["堅牢なフィルタリングオプション"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日、毎週、または毎月のレビュー目標"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATアンケートと基本的な分析"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェント、チーム、BPOを比較するためのレポート"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーロールと権限の管理"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機能"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAのサブスクリプションの変更を検討しています。\n\nいずれかのオプションを強調表示または提示し、変更するライセンス数を指定してください：\n[Xライセンス追加] [Xライセンス削除] [Zendesk QAプランにアップグレード] を希望します\n\n会社名とZendesk QAアカウントに関連付けられている名前、およびZendeskがこの変更を処理するために必要なその他の詳細情報を入力してください。"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAのサブスクリプションの変更"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAのサブスクリプションの購入を検討しています。\n\n会社名とZendesk QAアカウントに関連付けられている名前、およびZendeskがこのリクエストを処理するために必要なその他の詳細情報を入力してください：\n\nまた、購入前に解決したい具体的な質問があれば教えてください：\n\nよろしくお願いします。"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAのサブスクリプションの購入"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のサブスクリプションは", _interpolate(_named("date")), "に終了します（残り", _interpolate(_named("days")), "）"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のトライアルは", _interpolate(_named("date")), "に終了します（残り", _interpolate(_named("days")), "）"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1日"]), _normalize([_interpolate(_named("n")), "日"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：毎月", _interpolate(_named("dayOfMonth")), "日"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：毎月", _interpolate(_named("dayOfMonth")), "日"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：毎月", _interpolate(_named("dayOfMonth")), "日"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：毎月", _interpolate(_named("dayOfMonth")), "日"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：毎月", _interpolate(_named("dayOfMonth")), "日"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：毎月", _interpolate(_named("dayOfMonth")), "日"])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請求先のカード：", _interpolate(_named("card")), "（有効期限：", _interpolate(_named("expires")), "）、請求日：", _interpolate(_named("billingDate"))])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["末尾"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["期限切れ"]), _normalize(["残り1日で期限切れ"]), _normalize(["残り", _interpolate(_named("expiresIn")), "日で期限切れ"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最低料金には", _interpolate(_named("n")), "ユーザーライセンスが含まれます"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sum")), "/月から"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ユーザーの月額"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年額"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月額"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3か月"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解約予定を削除する"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QAを引き続きご利用になる場合は、", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のサブスクリプションは", _interpolate(_named("date")), "に解約されました。"])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のサブスクリプションは、", _interpolate(_named("date")), "に有効期限が切れる予定です。"])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["追加料金なしで", _interpolate(_named("days")), "日間トライアルをご利用いただけます！"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用済み"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お客様のユーザー数は", _interpolate(_named("n")), "です。ライセンス数は、ユーザー数以上でなければなりません。"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サブスクリプションは", _interpolate(_named("time")), "に再開予定です"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["現在ご利用のZendesk QAのプラン（", _interpolate(_named("plan")), "）には、AIを活用した機能は含まれていません。音声インテリジェンス機能を最大限に活用するには、", _interpolate(_named("link")), "。"])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サブスクリプションを解約される場合は、", _interpolate(_named("link")), "またはチャットでご連絡ください。"])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品質管理プラン"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品質管理"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションが更新されました"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカードを追加する"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのプラン"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求情報を確認する"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求間隔"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを解約する"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションは、請求期間の終了時に解約されます。"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集する際はお問い合わせください"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のプラン"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム価格"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウングレード"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年払い（15%割引）"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年払い"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月払い"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年払い"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月払い"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを編集する"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランを調べる"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料トライアル"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動で請求"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のステップで、最終的な注文の概要を確認します。"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一番の人気"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなお支払い方法がありません。"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トライアル中"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランを変更するには、現在のサブスクリプションを再開してください"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを一時停止する"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月払い"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四半期払い"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年払い"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを再度アクティブにする"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを再開する"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライセンス"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トライアルを申し込む"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルされました"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新なし"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一時停止中"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションのステータス"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トライアル"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップグレード"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションをアップグレードする"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少しお時間を頂戴して、カスタマーサポートのエクスペリエンスに関するフィードバックをご提供いただけませんでしょうか。ご協力に感謝いたします — ", "{", "{", "agentName", "}", "}", "がお客様のフィードバックを評価いたします。"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こんにちは、", "{", "{", "customerName", "}", "}", " 👋！"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "のCSATアンケート"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["{", "{", "agentName", "}", "}", "からお客様が受けたサポートを評価してください。"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["どうぞよろしくお願いいたします。いただいたフィードバックを活用して、すべての人にとって", _interpolate(_list(0)), "をより良いものにします。"])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートについて"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの種類を選択します"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これは後で変更できます。"])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複数の言語による自動CSATアンケートを使用して、顧客満足度を測定および改善します。"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが作成されていません"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを作成する"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケート名"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが作成されました"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このリストに表示させたい場合は、キーワードを変更してみるか、", _interpolate(_named("contact_us"))])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご連絡ください"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語が見つかりません"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["下のリストから言語を1つ以上選択してください。使用したい言語がこのリストに表示されていない場合は、", _interpolate(_named("contact_us")), "。"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を追加する"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご連絡ください"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を追加する"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最終公開：", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最終保存：", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "の質問"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["導入部の質問"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左スケールラベル"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールの件名"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右スケールラベル"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございました"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートに翻訳が見つからない場合は、代わりにベース言語のテキストが表示されます"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳を適用する"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳がありません"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["適用する前に、必ずすべての翻訳を確認してください。ダウンロードしたファイルに変更を加えて保存し、必要に応じて再アップロードしてください。トークン", _interpolate(_named("token_example")), "がタグとして表示されない場合、フォーマットが正しくないか、トークンをサポートしていないフィールドに含まれています。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳を確認する"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳済み"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を要約し、コメントの候補を表示します。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AIアシスト（英語）"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプは作成後に編集できます"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このタイプはすでに存在します。別の名前を選択してください。"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムタイプを「アンケートタイプ」ドロップダウンの選択可能オプションとして保存し、作成したすべてのアンケートをダッシュボード上でグループ化します。"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語（複数可）を入力し、Enterキーを押して保存します"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムタイプを編集する"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ名を空にすることはできません"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプを定義する"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムタイプは保存されませんでした。Enterキーを押して保存します。"])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信者のメールアドレス"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信者の名前"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["次の文にどの程度同意しますか？：「", _interpolate(_named("agentName")), "は問題の処理を容易にしてくれた」"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), "のサポートを利用するのは簡単でしたか？"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体的に、今日の問題は簡単に解決できましたか？"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当社のチームとやりとりするのは簡単でしたか？"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシーのURL（オプション）"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約のURL（オプション）"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["今回の", _interpolate(_named("agentName")), "のサポート対応の評価をお願いします。"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このリクエストに対する当社のサービスはいかがでしたか？"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポート担当者に対する満足度を教えてください。"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["今日", _interpolate(_named("agentName")), "から受けたサービスに対する満足度を教えてください。"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム質問"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["独自のカスタム", _interpolate(_named("type")), "質問を定義する ..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム質問を入力する"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLをカスタマイズする"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールメッセージを設定する"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム質問タイプの名前を編集すると、収集されたデータに影響を与えることなく、その名前を使用するすべてのアンケートで更新されます。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムタイプを編集する"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールの件名"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["導入部の質問"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "の質問"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいカスタムタイプを作成する"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテンツ"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["パーソナライゼーショントークン"]), _normalize(["パーソナライゼーショントークン"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["パーソナライゼーショントークンを下のフィールドにドラッグアンドドロップします。"]), _normalize(["パーソナライゼーショントークンを下のフィールドにドラッグアンドドロップします。"])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["会話", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、CSATアンケートの送信はメールでのみ可能です。ヘルプデスクCSATアンケート、SMSアンケートなど、より多くの手段をご利用いただけるように取り組んでいます。"])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["コネクションを同期または更新する"]), _normalize(["コネクションを同期または更新する"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを同期または更新する↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["コネクションが正しく同期されません"]), _normalize(["コネクションが正しく同期されません。"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連するチェックボックスのチェックを外す ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ認証 ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションをアクティブにするには、アンケート送信ルールを少なくとも1つ追加してください"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このコネクションは現在無効になっています。 ", _interpolate(_named("link")), "をクリック"])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このコネクションは、機密性の高いクライアントデータをマスクします。アンケートの送信を有効にするには、\n設定の", _interpolate(_named("link")), "をクリックします。"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このコネクションでアンケートを送信するには、追加の権限が必要です。\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ認証"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAには、Intercomに追加の権限が必要です。"])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["（", _interpolate(_named("hours")), _interpolate(_named("minutes")), "）"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1時間"]), _normalize([_interpolate(_named("n")), "時間"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "分"]), _normalize(["1分"]), _normalize([_interpolate(_named("n")), "分"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["時間"]), _normalize(["時間"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アンケートの送信ルールを設定するには、", _interpolate(_named("link"))])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" 日"]), _normalize(["日"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートリンクの有効期限"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクの有効期限まであと"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間が経過しすぎるとアンケートに回答できないようにする"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["および"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " アンケート ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグをカンマで区切るか、入力後にEnterキーを押します。それぞれがIntercomでのリストと正確に一致していることを確認してください。"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを送信するための条件を設定します。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの送信ルール"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローアップアンケートの送信ルール"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アンケート ", _interpolate(_named("name")), _interpolate(_named("rules")), " はすでに存在します。\nこの競合を解決するには、ここか", _interpolate(_named("link")), "でルールを修正してください。"])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "アンケートについて、ルールが一致するコネクションを既に設定済みです。この競合を解決するには、ここか", _interpolate(_named("link")), "でルールを修正してください"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のルールを追加する"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドを追加する"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を選択する"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値を検索する"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグを追加する"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じルールに一致"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値を選択する"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の時間の経過後にメールを送信"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の時間の経過後にフォローアップメールを送信"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埋め込みスニペットと補足メール"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コードをコピーする"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskの設定にスニペットコードを追加して、Zendesk QAのアンケートをメールに添付します。"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インストールガイド"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskを開く"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スニペットのプレビュー"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スニペットコードをクリップボードにコピーしました"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スニペットをコピーできませんでした"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埋め込み型のスニペット"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアンケートには保存されていない変更があります。もう一度保存して、スニペットコードに最新の更新が反映されるようにします。"])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを追加"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだコネクションが追加されていません"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを使って、メール、オンラインチャット、電話などをZendesk QAに取り込みます"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの配信について"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの送信ルール"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローアップアンケートの送信ルール"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランドルールを追加する"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルールを追加する"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger +メールのフォローアップ"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IntercomアカウントをZendesk QAのCSATアンケートに接続します"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配信手段"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの宛先と受信日"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルールを編集する"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アプリ内アンケートを使用すると、カスタマーはチャットを離れることなくアンケートに回答できます。"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercomを起動する"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションごとの配信ルール"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アンケート ", _interpolate(_named("name")), _interpolate(_named("rules")), " はすでに存在します。\nコネクションを無効にするか、ここまたは", _interpolate(_named("link")), "でルールを変更して競合を解決します。"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配信"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを送信するための条件を設定します"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "が", _interpolate(_named("createdDate")), "に作成"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("languageName")), "の削除"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この言語を削除すると、回答者にこの言語は表示されなくなり、翻訳済みのすべてのテキストは削除されます。"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を削除する"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作は取り消しできません。"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または、アンケートで言語を非表示にして無効にすることもできます。これにより、回答者に表示せずにすべての翻訳を所定の位置に保持することができます。"])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最終編集者：", _interpolate(_named("name")), "、編集日：", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの言語について"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["他の言語を追加する前に、「スタイル設定」と「コンテンツ」の手順で変更が完了していることを確認してください。 ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を追加する"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳されたフィールド"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答者に表示"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルト"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳ファイルをダウンロードする"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を削除する"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 翻訳ファイルをダウンロードする"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳を確認する"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["翻訳が完了しました。 ", _interpolate(_named("review_link")), " または ", _interpolate(_named("download_link")), "をクリックして変更を加えます。"])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 翻訳ファイルをダウンロードする"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足りないものを確認する"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["一部のコンテンツはまだ翻訳されていないため、英語に置き換えられます。 ", _interpolate(_named("review_link")), " または ", _interpolate(_named("download_link")), "をクリックして、不足している翻訳を追加します。"])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを保存する"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アンケートに保存されていない変更が含まれています。 ", _interpolate(_named("save")), "をクリックして、最新の変更を含む翻訳ファイルをダウンロードします。"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳ファイルをアップロードする"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優先する評価スケールを選択"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価の理由を選択するためのドロップダウンをカスタマーに表示します"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絵文字"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左スケールのテキスト（オプション）"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["番号"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を追加する"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を空白にすることはできません"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この理由はすでに存在します"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアの理由のドロップダウンを表示する"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を削除して同じ名前で再度追加したり、理由の名前を変更したりすると、常に新しいエントリが作成されます。その結果、ダッシュボードに表示されるデータに影響します。"])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を削除する"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除された理由はダッシュボードのレポートに残りますが、新しいアンケート回答者には表示されません。これは、関連する翻訳にも適用されます。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を削除する"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["理由「", _interpolate(_list(0)), "」を削除する"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右スケールのテキスト（オプション）"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5点満点で、1と2がマイナスのスコアと見なされます。3はニュートラル。4～5がプラスの値です"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スケールのテキストを追加する"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "スケール"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価のスケール"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QAが会話をインポートしています。このプロセスには最大で24時間かかる場合があります。"])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これは、アンケート全体を通してパーソナライゼーショントークンとして使用されます。"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランド名"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボタンとリンク"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの外観をカスタマイズする"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロゴの背景"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG、PNG、GIF形式に対応"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロゴ"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタイル設定"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロゴをアップロードする"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートに終了する"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックの送信後にメンバーに表示されるメッセージを入力してください"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お礼のメッセージ"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございました"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16進数のカラーコードが正しくありません"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスが正しくありません"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドを空白にすることはできません"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLが正しくありません"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非表示："])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示："])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信されたアンケート"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間に送信されたアンケート"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが削除されました"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが複製されました"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューメールが送信されました"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが公開されました"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが更新されました"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが一時停止されました"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一時停止"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを一時停止する"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのプレビューを送信するメールアドレスを入力してください"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューする言語を選択する"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューメールを送信する"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのサンプルを自分のメールアドレスに送信します。アンケートプレビューの評価は保存されません。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "アンケートをプレビューする"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webでのメールアンケートのプレビュー"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再開"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書きとして保存する"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランド名"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランド固有の名前は[スタイル設定]ステップで追加できます"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマー名"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポートID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポート担当者"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一時停止中"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "のCSATアンケート"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されていない変更"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを更新して再開する"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["このメールが正しく表示されない場合は、", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の理由から、受けたサポートに満足できませんでした"])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由を選択"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の要約"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：カスタマーが支払い方法を更新できませんでした。エージェントが、役に立つ記事を共有しました。上手くいきました。"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA AI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貴重なご意見をいただきありがとうございます。"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが付けたスコアの主な理由は何ですか？"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この評価を付けた理由を教えてください..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["労力が多い"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["労力が少ない"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここをクリックしてアンケートを開きます"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシー"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタム質問がここに表示されます"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["あなたは", _interpolate(_list(0)), "の経験に次のスコアを付けました"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックを送信する"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ありがとうございました"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信登録解除"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常に不満"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常に満足"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの現在のステータスおよび配信ルールはアンケートの複製には含まれませんのでご注意ください"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["アンケート「", _interpolate(_list(0)), "」を複製する"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埋め込まれたメールスニペットを使用している場合は、アンケートを一時停止する前に必ずZendeskから削除してください"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを一時停止すると、回答は収集されません。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを一時停止する"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを一時停止する前に、必ずZendeskからスニペットを削除してください。"])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを削除する"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埋め込まれたメールスニペットを使用している場合は、アンケートを削除する前に必ずZendeskから削除してください。"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアクションにより、アンケートが完全に削除されます。アンケートを受け取ったユーザーは引き続き回答することができ、アンケートに関連するすべてのデータはZendesk QAに残ります。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アンケート", _interpolate(_named("surveyName")), "を削除する"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを削除する前に、必ずZendeskからスニペットを削除してください。"])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用した変更は、新しい回答者にも表示されます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを公開する"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを公開したら、必ず最新バージョンの埋め込み型スニペットをコピーしてください。"])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埋め込み型メールスニペットを使用する場合は、アンケートを再開した後に、必ずそのスニペットをZendeskにコピーしてください。"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートの送信と回答の収集を続けます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを更新して再開する"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを再開したら、必ず埋め込み型スニペットをZendeskに追加してください。"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを更新する"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを更新したら、必ず最新バージョンの埋め込み型スニペットをコピーしてください。"])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアンケートを削除してもよろしいですか？この操作は取り消しできません。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートを削除しますか?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トライアル期間は終了しましたが、フルアカウントにアップグレードすることで、引き続きZendesk QAをご利用いただけます。"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トライアル期間は終了しましたが、フルアカウントにアップグレードすることで、引き続きZendesk QAをご利用いただけます。この設定については、ローカルの管理者に相談してください。"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トライアル期間が終了しました"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップグレード"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料トライアル期間が終了しました。"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全版のZendesk QAを引き続きご利用いただくには、お支払い情報を入力してください。"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全版のZendesk QAを引き続きご利用いただくには、アカウント管理者にお支払い情報を入力していただく必要があります。"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いを管理する"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), "が", _interpolate(_named("date")), "に作成"])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "の割り当て"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業が途中のようです。変更を保存せずに画面を閉じると、これまでに追加したすべての情報が失われます。"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この割り当ては、隔週", _interpolate(_named("weekDay")), "に繰り返されます"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この割り当ては、毎週", _interpolate(_named("weekDay")), "に繰り返されます"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["すべてのレビュー対象者を1サイクルでカバー"]), _normalize(["すべてのレビュー対象者を", _interpolate(_named("nr")), "サイクルでカバー"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件の会話"]), _normalize([_interpolate(_named("n")), "件の会話"]), _normalize([_interpolate(_named("n")), "件の会話"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nr")), "エージェント中"]), _normalize([_interpolate(_named("nr")), "エージェント中"]), _normalize([_interpolate(_named("nr")), "エージェント中"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["関係者"]), _normalize(["関係者"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1サイクルあたり", _interpolate(_named("n")), "会話"]), _normalize(["1サイクルあたり", _interpolate(_named("n")), "会話"]), _normalize(["1サイクルあたり", _interpolate(_named("n")), "会話"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レビュー対象者あたりの連続表示：", _interpolate(_named("reviewsCount")), "レビュー"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビュー対象者"]), _normalize(["レビュー対象者"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビュー担当者"]), _normalize(["レビュー担当者"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペースユーザー"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントを除くすべてのワークスペースユーザー"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サイクル ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非定期"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "の割り当て"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー中"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てについて"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを作成する"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作により、割り当てられたタスクが完全に削除され、そのデータがダッシュボードから削除されます。それに関連するすべてのレビューはZendesk QAに残ります。"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["割り当てられたタスク", _interpolate(_named("assignmentName")), "を削除する"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを削除しました"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクが重複しました"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ割り当てられたタスクが追加されていません"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを作成できませんでした"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクの名前"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイクルが更新され、新しいタスクリストが作成されます。すでに提供されているレビューは引き続き目標にカウントされます。"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即時"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["次の", _interpolate(_named("startDate")), "のサイクル開始時"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を公開するタイミング"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この割り当てに加えた変更は、この割り当てとともに公開されます"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクをアクティブにしますか?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクの開始日に繰り返し発生"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1金曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2金曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3金曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4金曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終金曜日"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1月曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2月曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3月曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4月曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終月曜日"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1土曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2土曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3土曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4土曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終土曜日"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1日曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2日曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3日曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4日曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終日曜日"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1木曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2木曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3木曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4木曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終木曜日"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1火曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2火曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3火曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4火曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終火曜日"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第1水曜日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第2水曜日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第3水曜日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月第4水曜日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月最終水曜日"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムの繰り返し設定"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月1日目"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初へ"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3番目"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4番目"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後へ"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繰り返し"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平日"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果はありません"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムゾーン"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイクル設定"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクの名前"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本情報"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各割り当てられたタスクのサイクルの開始時に、事前に定義された会話のリストが評価用に生成されます。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細設定"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー目標に達していない場合は新しい会話を自動的に取得する"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーセンテージ（%）のレビュー目標では自動取得は利用できません"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小数は無効です。整数を入力してください"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話の交換を許可する（レビュー担当者以上のロール）"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を置き換える"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者に自分の会話のレビューを割り当てられることを許可する"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビュー"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューの最小数は1以上でなければなりません"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各レビュー対象者の最小レビューを指定する"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーセント（%）"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計レビュー数"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各レビュー担当者は設定された回数のレビューを行う必要がある"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者は、設定された数の会話をまとめてレビューする必要がある"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューの最小数は1以上でなければなりません"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューの目標は1～100%の間でなければなりません"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューの目標"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各レビュー対象者は、最低合計数の会話をレビュー済みにする必要がある"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者が共有プールから会話を選択できるようにする"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者に共有プールの会話が割り当てられようにする"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクのアプローチ"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのボット"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースのすべての関係者"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペースユーザー"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者を追加する"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰がレビュー対象になりますか？"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者を選択する"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象を選択します"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースのすべてのユーザーまたは1つのグループが選択されている場合にのみ、除外が許可されます"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者とレビュー対象者"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["現在：", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースのデフォルト"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どのタイプのユーザーをレビュー対象者として選択すべきですか？"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最もアクティブなエージェント"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者を除外する（オプション）"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除外するユーザーを選択する"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者を除外する（オプション）"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除外するユーザーを選択する"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントを除くすべてのワークスペースユーザー"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者を追加してください"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰がレビューを行いますか？"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者を選択する"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このワークスペースでは自己レビューはできません"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビューを許可する"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このワークスペースでは自己レビューは許可されていません。"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["一致する本日の会話"]), _normalize(["一致する本日の会話"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクが今日開始されたかのように表示しています。"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["番号は今後変更される可能性があります。"])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者が選択されていません"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者が選択されていません"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビュー対象者"]), _normalize(["レビュー対象者"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビュー担当者"]), _normalize(["レビュー担当者"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動取得：会話数が足りない場合に許可"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "金曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "月曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "土曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "日曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "木曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "火曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), "水曜日、", _interpolate(_named("startDateTime")), "から開始"])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "から開始して毎日"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "から", _interpolate(_named("endDateTime")), "。"])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件の日付条件"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件の会話とヘルプデスク条件"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["会話なし、", _interpolate(_named("count")), "件のヘルプデスク条件"]), _normalize(["会話なし、", _interpolate(_named("count")), "件のヘルプデスク条件"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "件の会話、ヘルプデスク条件なし"]), _normalize([_interpolate(_named("count")), "件の会話、ヘルプデスク条件なし"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["アプローチ：レビュー担当者が会話を選択する"]), _normalize(["アプローチ：レビュー担当者に会話が割り当てられる"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続行するには、概要でハイライトされたエラーを修正してください"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標：レビュー担当者はすべての会話をレビューする必要がある"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目標：レビュー担当者は1件の会話をレビューする必要がある"]), _normalize(["目標：レビュー担当者は", _interpolate(_named("count")), "件の会話をレビューする必要がある"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：レビュー担当者は", _interpolate(_named("count")), "%の会話をレビューする必要がある"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標：レビュー目標が設定されていません"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標：各レビュー対象者は自分のすべての会話をレビューされる必要がある"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：各レビュー対象者は、 自分のすべての会話のうち", _interpolate(_named("count")), "件をレビューされる必要がある"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標：各レビュー対象者は、自分のすべての会話の", _interpolate(_named("count")), "%をレビューされる必要がある"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目標：各レビュー担当者は1件のレビューを行う必要がある"]), _normalize(["目標：各レビュー担当者は", _interpolate(_named("count")), "件のレビューを行う必要がある"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["交換：レビュー担当者は会話を交換できない"]), _normalize(["交換：レビュー担当者は会話を交換できる"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己レビュー：レビュー担当者は、自分の会話を割り当てられることができる"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付条件が設定されていません"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクの名前が設定されていません"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " ", _interpolate(_named("dateTime")), "から"])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隔週"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なし"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎週"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイクルの長さ"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブに設定"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書きとして保存"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てを下書きとして保存しました"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てが非アクティブに設定されました"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブに設定"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを作成する"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクが作成されました"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブにする"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブにする"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["割り当てられたタスクに対する保留中の更新は、", _interpolate(_list(0)), "から始まる次のサイクルで有効になります。"])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を公開する"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブ"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを更新する"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクが更新されました"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントまたはボットが会話を終了したかどうかを分析します。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが丁寧に会話を終えたかどうかを分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話がまとまったかどうかを分析します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージング"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントとボットの両方のインタラクションにおける文法、スペル、スタイルのミスを分析します。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントの文法、スペル、スタイルのミスを分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントの文法、スペル、スタイルのミスを分析します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルと文法"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージとボットのメッセージの会話を分析します。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["典型的な挨拶のフレーズについて会話全体を分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["典型的な挨拶について会話全体を分析します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの感情を理解および認識しているかどうかを分析します"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの感情を正確に映し出したケースや、「理解する」「認識する」といった思いやりのある言葉を使用したケースを特定します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共感力"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの質問や懸念事項を理解しているかどうかを分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの質問や懸念事項を理解しているかどうかを分析します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解力"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語の複雑さと文の長さを考慮して、テキストの理解のしやすさを分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語の複雑さと文の長さを考慮して、テキストの理解のしやすさを分析します"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメッセージには1個の長すぎる文が含まれているため、読みにくくなっています"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["このメッセージには", _interpolate(_list(0)), "個の長すぎる文が含まれているため、読みにくくなっています"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読みやすさ"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソリューションの提案について会話全体を分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソリューションの提案について会話全体を分析します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されたソリューション"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話全体を通したエージェントのトーンを分析します"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話全体を通したエージェントのトーンを分析します"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーン"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのメッセージからエラーを検出できませんでした"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文法の誤りを特定できなかった"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挨拶のフレーズは検出されませんでした。必要に応じて、管理者に依頼して[設定]で新しく追加してください。"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが丁寧に会話を終えた"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージングの語句が見つからなかった。必要に応じて、管理者に連絡し、[設定]で新しく追加します"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントは、顧客心理を汲み取り、思いやりのある言葉を使用した"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが顧客心理を汲み取っているかどうか、思いやりのある言葉を使用しているかどうかを判断できなかった"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが長い文や複雑な単語を使用したため、一部の人にとって理解するのが難しい可能性がある"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが複雑な単語を使用したため、一部の人にとって理解するのが難しい可能性がある"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが長い文を使用したため、一部の人にとって理解するのが難しい可能性がある"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントはわかりやすい言葉を使った"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このカテゴリを評価するのに十分なデータがない"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントはカスタマーの質問/リクエストを把握するのに苦労しました"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントはカスタマーの質問/リクエストをよく理解していました"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーの質問/リクエストを理解したかどうかを検出できませんでした"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中のエージェントのトーン"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中のエージェントのトーン"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中のエージェントのトーン"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中のエージェントのトーン"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中のエージェントのトーン"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントのトーンを特定できなかった"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が終了していない"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が終了した"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が終了したかどうかを判断できなかった"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントはカスタマーとその問題について共感を示していなかった"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントはカスタマーとその問題について共感を示した"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーとその問題について共感を示しているかどうかを判断できなかった"])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中にカスタマーに挨拶がなかった"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話中にカスタマーに挨拶がされた"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的な挨拶を検出できなかった"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーに解決策を提供しなかった"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーに解決策を提供した"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントがカスタマーにソリューションを提供したかどうか判断できなかった"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複雑な単語"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長い文"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文法"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルミス"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネガティブ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お詫び"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["穏やか"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["明るい"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好奇心旺盛"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフェッショナル"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["協力的"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタイル"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件のクロージング"]), _normalize([_interpolate(_named("n")), "件のクロージング"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAは、承認済みのクロージングのみを確認します。このカテゴリが「重大」に設定されている場合、承認済みのクロージングを使用しないと、レビューで不合格となります。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージング"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認済みのクロージング"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件の例外"]), _normalize([_interpolate(_named("n")), "件の除外"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルや文法のミスとしてオートQAが無視する単語やフレーズです。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルと文法の例外"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAの例外"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1件の挨拶"]), _normalize([_interpolate(_named("n")), "件の挨拶"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAは、承認済みの挨拶のみを確認します。このカテゴリが「重大」に設定されている場合、承認済みの挨拶を使用しないと、レビューで不合格となります。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認された挨拶"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認された挨拶"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAカテゴリ"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAカテゴリを選択"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動的に割り当てられた根本原因："])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「アカウント」→「設定」"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["一部のカテゴリでは、MS Azureを活用して、幅広い機能と言語のサポートを提供しています。詳しくは、お客様の", _interpolate(_named("link")), "を確認するか、管理者にお問い合わせください。"])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["MS Azureを活用して、カテゴリとサポート言語の拡張リストにアクセスします。お客様の", _interpolate(_named("link")), "を確認するか、管理者にお問い合わせください。"])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAを使用"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["オートQAカテゴリには、固定の評価スケールを使用して自動的にスコアが割り当てられます。評価は後で手動で調整できます。", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQAカテゴリを選択"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動的に割り当てられるスコアは、ミスの重大度とその数によって決まります。", _interpolate(_named("link")), "をクリック"])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する評価カテゴリのみがレビュー担当者に表示されます。評価カテゴリは、カテゴリの作成時に設定した条件に基づいて表示されます。"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャット"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンボーディング"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ワークスペース「", _interpolate(_named("workspace")), "」の評価カテゴリタイプを選択します。"])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者は、各会話に関連する評価カテゴリを表示するために、正しいスコアカードを手動で選択する必要があります。"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリタイプを変更してもよろしいですか？"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを変更する"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリタイプを変更しますか?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付き評価のカテゴリ"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静的評価のカテゴリ"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリのタイプ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリの設定"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作は取り消しできません。"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての評価を削除"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または、カテゴリをアーカイブして、データを削除せずに統計情報を保持することもできます。"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["この操作により、このカテゴリに付与された", _interpolate(_named("deleteAllRatings")), "されることを確認しました。"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例:：トーンが完璧である"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次の場合にのみカテゴリを表示"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「常時」は無視されます。他の条件と組み合わせることはできません。"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常時"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を選択する"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満足度スコア（CSAT）"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソースタイプ"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話チャネル"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリには名前が必要です"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードにカテゴリを追加する"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを作成する"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを作成する"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリが追加されました"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブされたカテゴリはスコアカードに表示されませんが、データを削除せずに統計情報を保持することができます。"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリをアーカイブする"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "カテゴリをアーカイブする"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリがアーカイブされました"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを削除する"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このカテゴリを削除するには、「カテゴリを削除する」と入力します"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを削除する"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["カテゴリ", _interpolate(_list(0)), "を削除する"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリが削除されました"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリが重複しています"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリグループが追加されました"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリグループが削除されました"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリのグループの順序を変更しました"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリグループが更新されました"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリのアーカイブを解除しました"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価カテゴリが更新されました"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントが重要なカテゴリで不合格になると、そのレビューのすべてのカテゴリが自動的に不合格になります。"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要な評価カテゴリ"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このグループにカテゴリはありません"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい評価カテゴリを作成する"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを編集する"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカテゴリを削除し、このグループのデータを確認する"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリグループを削除すると、そのグループにリンクされているすべてのカテゴリがグループ解除されたままになります。"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), "カテゴリグループを削除する"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ内のカテゴリを削除することを選択すると、これらのカテゴリに関連付けられているすべてのレビューデータが失われます。"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ名"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者は根本的な原因として複数の理由を選択できます"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者は根本的な原因として複数の理由を選択できます"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複数の理由"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例: トーン"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいカテゴリグループを追加する"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規グループ"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「その他」を追加する"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因を追加する"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この根本的な原因はすでに存在します"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因を追加する"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因を追加して評価を説明する"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価のスケール"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済み"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この評価カテゴリにスコアカードを追加する"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ解除"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを更新する"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての評価"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示対象:"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイナス評価"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイナスおよびニュートラルな評価"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい、更新してください"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量付け"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットスコアの計算では、重要度の高いカテゴリほど重み付けすることができます。"])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["重み付け(", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションについて"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションについて"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度な設定"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期的なキャリブレーションセッションを開催することで、レビュー担当者は評価手法を調整し、エージェントは同じレベルの一貫した公平なフィードバックを受け取ることができます。"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランの表示"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "はすべての顧客とのインタラクションをどのように処理し、評価すべきかについてチームの理解を深めるためのプロセスです。"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフェッショナル"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["キャリブレーションは、 ", _interpolate(_list(0)), "プランと", _interpolate(_list(1)), "プランで適用されます。"])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前に提供されたレビューは、会話がセッションに追加されたときに表示されません。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非表示"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前に提供されたレビューは、キャリブレーションレビューと見なされます。レビュー担当者1人につき1件の会話レビューのみがセッションに追加されます。メッセージ固有のレビューは表示されません。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示可能"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前に提供されたレビューの表示"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["キャリブレーションは、すべての顧客とのインタラクションを処理および評価する方法についてチームが同じ理解を維持するのに役立つプロセスです。 ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーは、キャリブレーションセッション中に行われたすべてのレビューに無制限にアクセスできます。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常にすべてのレビューを表示"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リード"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信したユーザーには、キャリブレーションセッション中に他のレビュー担当者が行った評価が表示されます。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信した後、すべてのキャリブレーションレビューを見る"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーは、キャリブレーションセッション中に行われたすべてのレビューに無制限にアクセスできます。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常にすべてのレビューを表示"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マネージャー"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信したユーザーには、キャリブレーションセッション中に他のレビュー担当者が行った評価が表示されます。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信した後、すべてのキャリブレーションレビューを見る"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信したユーザーには、キャリブレーションセッション中に他のレビュー担当者が行った評価が表示されます。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを送信した後、すべてのキャリブレーションレビューを見る"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーには、キャリブレーションセッション中に行われた自分のレビューのみが表示されます。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分のレビューのみを表示する"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション設定が更新されました"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションレビューの表示"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チームの内部メトリックシステムに従って差を埋めることで、ダッシュボードメトリックのしきい値を設定します。ダッシュボードでしきい値を確認してください。"])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスク"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーション"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグ"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しきい値"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しきい値が更新されました"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しばらくお待ちください..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["管理者権限があることを確認して", _interpolate(_named("retry")), "か、", _interpolate(_named("contact")), "に連絡してサポートを依頼してください。"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こちらまでお問い合わせください"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクのAPIにアクセスできなかったようです。"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再試行"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクを接続しています。すぐにリダイレクトされます。"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "が承認されました"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "に戻って新しいヘルプデスクのコネクションを追加してください。"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いくつかのステップを実行していないようです。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じメールドメインの組織が既に存在します"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションページ"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それまでの間..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限を確認しています。しばらくお待ちください。"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それまでの間..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統合"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを作成"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QAを引き続きご利用になる場合は、", _interpolate(_named("link")), "。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まもなく使用準備が完了します"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースが見つかりません"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを切り替える"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き続きZendesk QAを使用するには、マネージャーからワークスペースへの割り当てを受ける必要があります。"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アカウントを削除すると、お客様に関するすべてのデータが完全に削除されます - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての評価、会話の内容、ワークスペースなど"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackに追加"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切断"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インテグレーションを切断すると、Slack経由のすべてのZendesk QA通知が無効になります"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackを切断する"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SlackインテグレーションとZendesk QAの接続が切断されました。"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての新しいアカウントユーザーにデフォルトの通知設定を定義します。"])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユーザーの設定を上書きする"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアカウントユーザーの通知設定を上書きしようとしています"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーが独自の通知設定を定義することはできません。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知設定が更新されました"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大なカテゴリでエラーが発生した場合のアラート"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATが応答を受け取ったときのアラート"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリガ"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デモワークスペースとデータを作成"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デモデータを再作成し、デモワークスペースを維持します。"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デモワークスペースとデータを削除する"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デモデータを更新する"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データを更新する"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アカウント「", _interpolate(_named("accountName")), "」デモモードを管理する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デモモード"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/6059285322522-Zendesk%E3%81%AE%E7%94%9F%E6%88%90AI%E6%A9%9F%E8%83%BD%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043712839450-%E3%83%98%E3%83%AB%E3%83%97%E3%83%87%E3%82%B9%E3%82%AF%E3%82%B3%E3%83%8D%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E9%AB%98%E5%BA%A6%E3%81%AA%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E8%A8%AD%E5%AE%9A%E3%81%AE%E6%A7%8B%E6%88%90"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043747123354-%E3%82%AA%E3%83%BC%E3%83%88QA%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6Zendesk-QA%E3%81%A7%E3%82%AA%E3%83%BC%E3%83%88%E3%82%B9%E3%82%B3%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7418648293018-Zendesk-QA%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9FAI%E3%82%A8%E3%83%BC%E3%82%B8%E3%82%A7%E3%83%B3%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E8%A9%95%E4%BE%A1"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043724530842-Zendesk-QA%E3%81%A7%E3%82%AD%E3%83%A3%E3%83%AA%E3%83%96%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043759436954-%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%82%B9%E3%82%B3%E3%82%A2%E6%8E%A8%E7%A7%BB%E3%81%AE%E5%88%86%E6%9E%90%E6%96%B9%E6%B3%95"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043747231642-%E4%BC%9A%E8%A9%B1%E3%81%AE%E3%82%A4%E3%83%B3%E3%82%B5%E3%82%A4%E3%83%88%E3%83%93%E3%83%A5%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043747649690-%E8%A4%87%E6%95%B0%E3%81%AE%E3%82%B9%E3%82%B3%E3%82%A2%E3%82%AB%E3%83%BC%E3%83%89%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043701093786-Zendesk-QA%E3%81%AE%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%83%80%E3%83%83%E3%82%B7%E3%83%A5%E3%83%9C%E3%83%BC%E3%83%89%E3%81%AE%E8%A8%88%E7%AE%97"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043759449114-%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B%E4%BC%9A%E8%A9%B1%E3%82%92%E6%A4%9C%E7%B4%A2%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043760162074-%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%A8%E3%83%AF%E3%83%BC%E3%82%AF%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9%E3%81%AE%E7%AE%A1%E7%90%86"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043759543194-%E3%83%94%E3%83%B3%E7%95%99%E3%82%81%E3%81%97%E3%81%9F%E3%83%A1%E3%83%A2%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%82%A8%E3%83%BC%E3%82%B8%E3%82%A7%E3%83%B3%E3%83%88%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%81%E3%83%B3%E3%82%B0"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043747142938-Zendesk-QA%E3%81%A7%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043759586074-Zendesk-QA%E3%81%AE%E3%82%B9%E3%83%9D%E3%83%83%E3%83%88%E3%83%A9%E3%82%A4%E3%83%88%E3%82%A4%E3%83%B3%E3%82%B5%E3%82%A4%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E4%BC%9A%E8%A9%B1%E3%81%AE%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%AA%E3%83%B3%E3%82%B0"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/articles/7043759396250-Zendesk-QA%E3%81%A7%E3%81%AE%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E6%99%82%E9%96%93%E3%81%AE%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ja/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動しない"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページから移動すると、すべての変更が失われますがよろしいでしょうか？"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを完了しました"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを作成する"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクは完了"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクが見つかりません"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、あなたがレビューするタスクはありません。"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てを調整するか、次のサイクルの開始までお待ちください。"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを調整してみてください"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを編集する"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページで最新情報をご確認ください"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このサイクルのタスクはありません"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する会話がありません"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクを完了としてマーク"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このユーザーをレビューしました。"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この割り当てられたタスクには自己レビューがありません"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話ビューのヘッダーにあるキャリブレーションアイコンをクリックして追加します。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話が追加されていません"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを作成する"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションセッションを作成する"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分に割り当てられたタスク"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他の割り当て済みタスク"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブな割り当て済みタスクはありません"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクなし"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのレビュー対象者"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分を除くすべてのレビュー担当者"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者が見つかりません"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者を検索する"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除済み"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのステータス"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ToDo"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのレビュー担当者"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分を除くすべてのレビュアー"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分に割り当てられました"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者が見つかりません"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者を検索する"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "日"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "日"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "日"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "日"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "日"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "日"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "時間"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "時間"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "時間"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "時間"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "時間"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "時間"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "分"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "分"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "分"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "分"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "分"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "分"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当て直す"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペースユーザー"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のレビュー担当者"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このサイクルの新しい目標"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレビュー担当者"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者を選択する"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を再割り当てする"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を再割り当てする"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動させる会話"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のサイクル"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを編集する"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者が見つかりません"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索レビュー担当者"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を自分に再割り当てする"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を他のユーザーに再割り当てする"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "日"]), _normalize([_interpolate(_named("n")), "日"]), _normalize([_interpolate(_named("n")), "日"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "時間"]), _normalize([_interpolate(_named("n")), "時間"]), _normalize([_interpolate(_named("n")), "時間"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したサイクルを非表示にする"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したセッションを非表示にする"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を削除するには、理由を追加してください"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を削除する理由を説明してください"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を削除する"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保持しますか?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を置き換える理由を追加してください"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を破棄する"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保持する"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保持する"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代替品が見つかりません"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この会話を差し替える理由を説明してください"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクからこの会話を削除してもよろしいですか？これにより目標が1つ減ります。"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置換"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を置き換える"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書きを削除する"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書きを編集する"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私に再割り当てする"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のユーザーに再割り当てする"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再割り当てして確認する"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を削除する"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューを残す"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示を減らす"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示を増やす"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目標: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), "を確認する"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したレビュー"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したサイクルを表示する"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したセッションを表示する"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残り時間"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話を置き換えました"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクリストを切り替える"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "後"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "秒"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "秒"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "日"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "日 ", _interpolate(_named("hours")), "時間"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "日 ", _interpolate(_named("hours")), "時間 ", _interpolate(_named("minutes")), "分"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "日"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "日間"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "日 ", _interpolate(_named("minutes")), "分"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "時間"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "時間 ", _interpolate(_named("minutes")), "分"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "時間 ", _interpolate(_named("minutes")), "分 ", _interpolate(_named("seconds")), "秒"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "時間 ", _interpolate(_named("seconds")), "秒"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "分"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " 分 ", _interpolate(_named("seconds")), " 秒"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "月"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "秒"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "年"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["たった今"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限がありません。管理者にサポートを依頼してください。"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お探しのページは存在しません。"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予期しないエラーが発生しました。サポートにお問い合わせください。"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセスが拒否されました。そのアクションの実行は許可されていません。"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お探しのページは存在しないか、またはアクセスできる権限がありません。"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スピードが速すぎて、処理に問題が発生しました。"])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なし"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存しました"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されていない変更"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["および"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件適用済み"]), _normalize([_interpolate(_named("n")), "件適用済み"]), _normalize([_interpolate(_named("n")), "件適用済み"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続済み"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日時"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成者"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重大"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["旧メンバー"]), _normalize(["旧メンバー"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こちら"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件選択済み"]), _normalize([_interpolate(_named("n")), "件選択済み"]), _normalize([_interpolate(_named("n")), "件選択済み"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終更新"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...、他1件"]), _normalize(["...、他", _interpolate(_named("n")), "件"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["他1件"]), _normalize(["他", _interpolate(_named("n")), "件"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なし"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されました"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件の返信"]), _normalize([_interpolate(_named("n")), "件の返信"]), _normalize([_interpolate(_named("n")), "件の返信"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["金曜日"]), _normalize(["金曜日"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["月曜日"]), _normalize(["月曜日"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["土曜日"]), _normalize(["土曜日"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["日曜日"]), _normalize(["日曜日"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["木曜日"]), _normalize(["木曜日"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["火曜日"]), _normalize(["火曜日"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["水曜日"]), _normalize(["水曜日"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨日"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["管理者"]), _normalize(["管理者"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["アカウントマネージャー"]), _normalize(["アカウントマネージャー"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ユーザー"]), _normalize(["ユーザー"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オートQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーしました"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションの期限が切れました。"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドを空白にすることはできません。"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムが見つかりません。"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致するメンバーがいません。"])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["オーナー"]), _normalize(["オーナー"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細。"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用を開始する前に、当社の利用規約をご確認の上、内容に同意いただくことで、続行できます。"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信済み"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間違いありませんか？"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体の完了済みレビュー"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新しました"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["エージェント"]), _normalize(["エージェント"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["リーダー"]), _normalize(["リーダー"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["マネージャー"]), _normalize(["マネージャー"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビュー担当者"]), _normalize(["レビュー担当者"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匿名ユーザー"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担当者"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まもなく利用可能"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続行"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリックしてコピー"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクをコピー"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デンジャーゾーン"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否認"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明（オプション）"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認が必要です"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効にする"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されていない変更を破棄しますか？"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効にする"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクスポート"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻度"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスク"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なし"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合格率"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人設定"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前へ"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を隠す"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除する"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再送信"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["復元"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信/閲覧済みのレビュー"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースメンバーが行ったレビュー"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロール"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を保存する"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイナリスケール"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3点スケール"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4点スケール"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5点スケール"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頻度を選択する"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示を減らす"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示を増やす"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキップ"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポットライト"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステップ"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートのフィードバック"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケート"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されていない変更"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量付け"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "から、Zendesk QAに参加するよう招待されています。"])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ユーザーは管理センターから自動的に接続されます。", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを管理"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー管理について"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["一部のユーザーは、IDプロバイダーから自動的にプロビジョニングおよび同期されます。 ", _interpolate(_named("link")), "をクリック"])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」のアカウントの権限が変更されました"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの権限について"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後このメッセージを表示しない"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ユーザーページで行った変更はすべてワークスペースに反映されます。 ", _interpolate(_named("help2")), "。 ", _interpolate(_named("link")), "をクリック"])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これにより、Zendesk QAの特定の場所へのユーザーのアクセス権が変更される可能性があります"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントレベルの権限を変更する"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "ユーザーが削除されました"]), _normalize([_interpolate(_named("n")), "ユーザーが削除されました"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ユーザーを削除すると、そのユーザーはあなたのアカウントにアクセスできなくなります。そのユーザーのレビュー、コメント、評価はZendesk QAに残ります。", _interpolate(_named("linebreak")), "ユーザーを削除しても、サブスクリプションのライセンス数には影響しません。"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["このユーザーは、あなたが管理するすべてのワークスペースから削除されます。", _interpolate(_named("linebreak")), " そのユーザーのレビュー、コメント、評価はそのまま残ります。"]), _normalize(["あなたが管理するすべてのワークスペースから削除されます。", _interpolate(_named("linebreak")), " そのユーザーのレビュー、コメント、評価はすべてそのまま残ります。"])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), "を削除する"]), _normalize([_interpolate(_named("name")), "ユーザーを削除する"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてを表示し、すべての設定と請求を管理できます"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求以外のすべての情報を表示し、個人設定とワークスペースの設定を管理できます"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの権限"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース固有の権限により、ユーザーは自分が所属しているワークスペース内のデータを表示（および）編集できます。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットQAについて"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットが参加した会話が同期されると、ここに表示されます"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同期されたボットはありません"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットをユーザーとしてマークすると、ボットにサインインアクセス権が付与されます。ボットの履歴データはダッシュボード上で引き続き利用できます。"])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索に一致するボットは見つかりませんでした"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のキーワードまたはスペルを使用して、もう一度検索してください。"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットを検索"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット名で検索"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーとしてマーク"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットをユーザーとしてマークできるのは管理者とアカウントマネージャーのみです"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット名"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回のチャット"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動でマーク済み"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー可能"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットが自動分析および割り当てから削除されます。新しいデータがダッシュボードに表示されなくなり、正確なレポーティングと容易なボット管理が可能になります。"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成型ボット"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不明"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークフローボット"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループに追加する"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのグループ"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループなし"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループから削除"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループを選択する"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの権限"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースに追加する"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終アクティブ"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントレベルのロール"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各ユーザーには、アカウントレベルまたはワークスペースレベルの権限が割り当てられます。管理者権限はアカウントレベルであり、他の権限はワークスペースレベルです。"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースから削除する"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの権限"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループで管理されている権限"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を編集"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットとしてマーク"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーをボットとしてマークすると、そのユーザーのサインインアクセス権が削除されます。ユーザーの履歴データはダッシュボード上で引き続き利用できます。"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動分析および割り当てからボットを削除します。新しいデータがダッシュボードに表示されなくなり、正確なレポーティングと容易なボット管理が可能になります。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボットをレビューから除外"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーをボットとしてマークしました"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「ユーザー」の権限タイプのみボットとしてマークできます"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複数のアカウントを持つユーザーは、ボットとしてマークできません"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーで検索"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボット"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー、ボット、ワークスペース"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーとワークスペース"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限なし"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続済み"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動で追加されました"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の招待"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIMユーザー"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必ずご対応ください"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...、他1ユーザー"]), _normalize(["...、他", _interpolate(_named("n")), "ユーザー"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限を次に変更:"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["削除し、再度招待して、", _interpolate(_named("n")), "人の招待保留中のユーザーのロールを変更する"]), _normalize(["削除し、再度招待して、", _interpolate(_named("n")), "人の招待保留中のユーザーのロールを変更する"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), "の詳細を編集する"]), _normalize([_interpolate(_named("n")), "人の選ばれたユーザーの詳細を編集する"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "人のユーザーが更新されました。"]), _normalize([_interpolate(_named("n")), " 人のユーザーが更新されました。"])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["このグループには、別のワークスペースのユーザーが1人含まれています。"]), _normalize(["このグループには、別のワークスペースのユーザーが", _interpolate(_named("n")), "人含まれています。"])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブにする"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループがアーカイブされました"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済みグループ"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループを作成する"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループが作成されました"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトルなしのグループ"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このグループを完全に削除しますか？"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループを削除する"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループを削除する"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループを編集する"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループリード"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループロール"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループを非表示"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このグループはZendesk QA内の他の場所では表示されません"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの権限の管理のみ"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループがZendesk QAに表示されません"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リード"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトルなしのグループ"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のグループがすでにこの名前を持っています"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブされたグループはすでにこの名前を持っています"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのロールでは、このグループのメンバーの管理のみが許可されています。さらなる変更については、より高い権限を持つユーザーに問い合わせてください。"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループリードとして削除する"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループが復元されました"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループを保存しました"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループリードとして設定する"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループのアーカイブが解除されました"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存されていない変更"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のユーザーをこのグループに追加する"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前またはメールアドレスで検索する"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーを追加する"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("name")), "」グループが表示可能"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["各メンバーは", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループについて"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループの一部の属性は、IDプロバイダーから自動的にプロビジョニングおよび同期されます。"])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループについて"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループを作成する"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チームメンバーを簡潔に整理し、組織構造を再現します。"])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーを1人以上追加する"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ名を追加する"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この名前のグループはすでに存在します"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループメンバー"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ名"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チームメンバーを整理し、組織構造を再現します。"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["グループなし"]), _normalize(["1グループ"]), _normalize([_interpolate(_named("count")), "個のグループ"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいグループを作成するか、アーカイブしたグループをアクティブにしてください。"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブなグループがありません"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブされたグループがありません"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そのようなグループは見つかりませんでした"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のキーワードまたはスペルを使用して、もう一度検索してください。"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループが追加されていません"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["これらに"]), _normalize(["～に"]), _normalize(["～に"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ名で検索する"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済み"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "に再送信可能"])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["残り", _interpolate(_named("n")), "ライセンス"]), _normalize(["残り", _interpolate(_named("n")), "ライセンス"]), _normalize(["残り", _interpolate(_named("n")), "ライセンス"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のサブスクリプションは一時停止中のため、現在ライセンス数に制限があります。ユーザーを追加するには、再度アクティブ化してください。"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントのライセンス数の上限に達しました。アクティブなユーザーを非アクティブにするか、ライセンスを追加することで、ユーザーを置き換えることができます。"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いの詳細を表示する"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "件選択済み"]), _normalize([_interpolate(_named("n")), "件選択済み"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "ユーザー選択済み"]), _normalize([_interpolate(_named("n")), "ユーザー選択済み"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "行"]), _normalize([_interpolate(_named("n")), "行"]), _normalize([_interpolate(_named("n")), "行"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前またはメールアドレスでユーザーを一括検索します。"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここにデータをカンマまたは改行で区切って貼り付けます"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの一括検索"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), "のワークスペース権限を編集"]), _normalize([_interpolate(_named("n")), "件の選択済みメンバーのワークスペース権限を編集"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限を編集する"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの権限を選択する"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ワークスペースから", _interpolate(_named("name")), "を削除"]), _normalize(["ワークスペースから", _interpolate(_named("name")), "メンバーを削除"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "メンバーが削除されました"]), _normalize([_interpolate(_named("n")), "メンバーが削除されました"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["メンバーを削除する"]), _normalize(["メンバーを削除する"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["このユーザーはワークスペースから削除されます。"]), _normalize(["これらのユーザーはワークスペースから削除されます。"])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["「", _interpolate(_list(0)), "」のワークスペースの権限が変更されました。"])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーを追加する"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話と関連するレビューを表示する"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース内のすべての情報を表示しますが、管理できるのはクイズ、割り当てられたタスク、異議、キャリブレーションセッションのみです"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの権限"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース内のすべての情報を表示および管理する"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース内のすべての項目を表示する（ワークスペースの設定を除く）"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを接続する"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが管理しているワークスペースのすべてのユーザーがこのワークスペースに追加されました。"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者に依頼してください。"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいユーザーを直接招待するか、管理者に招待するよう依頼してください。"])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), "または", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示するユーザーがいません"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), "ユーザーが追加されました"]), _normalize([_interpolate(_named("n")), "ユーザーが追加されました"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["メンバーを追加する"]), _normalize(["メンバーを追加する"]), _normalize([_interpolate(_named("n")), "人のメンバーを追加する"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースの権限を選択する"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースに追加するユーザーを選択する"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["メンバーを", _interpolate(_list(0)), "に追加する"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを改行またはカンマで区切ってここに貼り付けます"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "件のメールアドレスが一致"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 接続済みのユーザーの場合:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "件のメールアドレスが一致しませんでした"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペルをチェックするか、後で個別に接続します"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ヘルプデスクユーザーの場合:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "ユーザーを接続しました"]), _normalize([_interpolate(_named("n")), "ユーザーを接続しました"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "ユーザーを招待しました"]), _normalize([_interpolate(_named("n")), "ユーザーを招待しました"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括接続"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括招待"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクから接続して、インタラクションを確認します"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ユーザーを接続する"]), _normalize(["ユーザーを接続する"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個別に接続する"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続中のユーザーがZendesk QAにアクセスできるようにしたい場合は、ここでユーザーを招待できます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続中のユーザーを招待する"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ユーザーを招待する"]), _normalize(["ユーザーを招待する"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待をスキップする"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続するユーザーをヘルプデスクから選択して、インタラクションを確認します"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションを管理する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクユーザーをつなぐ"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースに一意の名前を付けます"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効にすると、既存のすべてのアカウントコネクションが自動的に新しいワークスペースに追加されます。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのコネクションをこのワークスペースに追加する"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを作成する"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース名"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを作成する"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（ワークスペースを割り当てずに）アカウントに直接追加されたユーザーには、アカウントレベルのロールが割り当てられます。これらのユーザーは、後でワークスペースに追加できます。"])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待されたユーザーには、自動的にアカウントレベルの「ユーザー」ロールが割り当てられます。これらのユーザーは、後で「管理者」ロールにアップグレードできます。"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらはすべて機能します"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここにメールを改行またはカンマで区切って貼り付けます。"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "ユーザーをメールで招待しました"]), _normalize([_interpolate(_named("n")), "ユーザーをメールで招待しました"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースに追加されたユーザーには、自動的にアカウントレベルの「ユーザー」ロールが割り当てられます。これらのユーザーは、後で「管理者」ロールにアップグレードできます。"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のユーザーを追加する"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーをワークスペースに追加せずにアカウントのみに招待する"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待されたユーザーにはアカウント権限のみが割り当てられ、ワークスペースには追加されません。"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーをワークスペースに直接招待し、ワークスペースの権限を割り当てる"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースにユーザーを追加する"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括招待"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個別に招待する"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そのアカウントにのみ招待する"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースに招待する"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ユーザーを招待する"]), _normalize(["ユーザーを招待する"]), _normalize([_interpolate(_named("n")), "人のユーザーを招待する"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール経由でユーザーを一括招待する"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ユーザーを削除する"]), _normalize(["ユーザーを削除する"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択を解除"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール経由でユーザーを招待する"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースがありません"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限を選択する"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状を再送信する"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続中のユーザー"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中のメール招待ユーザー"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIMユーザー"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースを選択する"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIエージェント"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理センター"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品質保証"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークフォース マネジメント"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "完了"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "完了"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "完了"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サイクル", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー担当者"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割り当てられたタスクを編集する"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューする会話を手動で見つけて割り当てる代わりに、自動レビュー割り当てを設定して時間を節約します。"])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["期日：", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションは、会話ビューのサイドメニューの「キャリブレーション」セクションで追加できます。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだセッションが追加されていません"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベースライン"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションがありません"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話をセッションに追加するには、会話ビューのヘッダーにあるキャリブレーションアイコンをクリックします。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ会話が追加されていません"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レビューがありません。 ", _interpolate(_named("link")), "をクリック"])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャリブレーションレビューを残す"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本的な原因"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジャンプ先"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コマンドパレットを読み込めませんでした。"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何も見つかりません。"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDで検索"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コマンドパレットを読み込んでいます…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページ、フィルター、会話、設定…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会話"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートフィルター"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パブリックフィルター"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "から"])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIから"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["計", _interpolate(_named("total")), "件の", _interpolate(_named("label")), "のうち", _interpolate(_named("rangeStart")), "～", _interpolate(_named("rangeEnd")), "を表示中"]), _normalize(["計", _interpolate(_named("total")), "件の", _interpolate(_named("label")), "のうち", _interpolate(_named("rangeStart")), "～", _interpolate(_named("rangeEnd")), "を表示中"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイドパネルを非表示にする"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイドパネルを表示する"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイドバーを切り替える"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の受け付け"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の拒否"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解決の異議"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の一部を承諾した"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了したレビュー"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取ったレビュー"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧されたレビュー"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部承諾"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["全体の", _interpolate(_list(0))])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承諾した"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部承諾"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否した"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["レビュー"]), _normalize(["レビュー"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー対象者が閲覧したレビューの数"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧されたレビューの数"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議を申し立てられたレビュー担当者"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ別の異議"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の推移"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解決時間"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解決時間（時間）"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解決時間（分）"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決済み"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異議の合計"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグを選択する"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承諾"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答の言語"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語が見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての言語"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予測される要因"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要因が見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての要因"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワードクラウドの単語"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単語が見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての単語"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンション"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい順"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古い順"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部承諾"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "が", _interpolate(_list(1)), "でリアクションを返しました"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決済み"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肌の色を選択"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッション"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションが見つかりません"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリが見つかりません"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントサイズ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイズが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのサイズ"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターが見つかりません"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのグループ"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのハッシュタグ"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値が見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての値"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムフィールドが見つかりません"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプデスクタグが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのヘルプデスクタグ"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果を読み込み中..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問のタイプ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問のタイプが見つかりません"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リアクションを追加する"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカード"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアカードが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのスコアカード"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコアが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのスコア"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソースが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのソース"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックの理由"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由が見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての理由"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケート"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンケートが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアンケート"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネルが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのチャネル"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーが見つかりません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユーザー"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースがありません"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのワークスペース"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズに戻る"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズが削除されたか、まだ公開されていません。リンクを再確認するか、クイズリストに戻ってください。"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このクイズは、あなたが所属するワークスペースで共有されていません。管理者に依頼して、このクイズがアクセスできるワークスペースに追加してもらってください。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズは利用できません"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分が所属していないワークスペースにクイズへのアクセス権が付与されています。クイズの保存および公開はできません。"])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者にクイズの保存と公開を依頼してください。"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少なくとも1つの回答を正解としてマークする必要があります。"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフィールドを空白にすることはできません"])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、クイズのプレビューを表示しています。"])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズにアクセスできるワークスペースを選択します。複数のワークスペースに属するユーザーは、クイズに1回しか回答できません。「すべてのワークスペース」を選択した場合、クイズが公開されると、新しく作成されたワークスペースは含まれなくなります。"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズへのアクセス"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューモードを終了する"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクスポート"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問に答える"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを公開する"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズの設定"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書きとして保存する"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいクイズを作成する"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ解除"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションを追加する"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを共有します"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいですね。"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答が送信されました"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズが保存されました"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズが公開されました"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズが削除されました"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を追加する"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズをアーカイブする"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブされたクイズとその結果は、クイズのリストに表示されなくなります。アーカイブされたクイズの結果には引き続きアクセスでき、必要なときにいつでもアーカイブを展開できます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズをアーカイブする"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一度公開されたクイズを変更または編集することはできません。公開されたすべてのクイズには、アカウントのすべてのユーザーがアクセスできます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを公開しますか？"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このクイズを削除してもよろしいですか？この操作は取り消しできません。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを削除しますか?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一度送信した回答を変更することはできません。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを送信しますか？"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズのアーカイブを解除する"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズのアーカイブを解除すると、クイズの概要で利用できるようになります。これにより、以前の関係者は結果にアクセスでき、新しい関係者はクイズに参加できます。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズのアーカイブを解除する"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を複製する"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明（オプション）"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を下へ移動する"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を上に移動する"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答のある質問のみがプレビューに表示されます。"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を削除する"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを送信する"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無題のクイズ"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズのタイトル"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズとその結果が完全に削除されます。"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または、クイズをアーカイブして結果にアクセスすることもできます。"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答済み"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズを削除する"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズとその結果が完全に削除されます。"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["クイズ ", _interpolate(_list(0)), "を削除する"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズは、チームの自信とスキルを高め、一貫したエクスペリエンスを提供し、新しいチームメンバーをオンボーディングするのに役立ちます。"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズは、クイズの名前または作成者の名前で検索できます。"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだクイズが作成されていません"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズのリストをエクスポートする"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均スコア"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了日"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ/作成者"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ / 作成者 / ワークスペース"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開済み"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペース"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ、作成者で検索"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済み"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下書き"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのクイズ"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズがアーカイブされました。"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズのアーカイブを解除しました。"])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したワークスペースのユーザーからこのクイズへの回答はありませんでした。"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このクイズはアーカイブされるまで回答がありません。"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答がありません"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここには、クイズの回答の内訳が表示されます。\nまだクイズに誰も記入していません。クイズを共有しておくと役に立ちます。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ応答がありません"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答："])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均スコア："])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), "（コピー）"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクをコピーしました"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一致する結果がありません"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "のクイズ"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングチャートで関係者の名前を検索できます。"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングチャートをエクスポートする"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スコア"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係者で検索する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングチャート"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイズ"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングチャートの切り替え"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作により、ダッシュボードでこのハッシュタグをフィルタリングすることはできなくなり、このハッシュタグによるコメントのオートコンプリートも機能しなくなります。この操作は取り消しできません。"])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグ、ハッシュタグ、ハッシュタグ、..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグを作成する"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグを作成する"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグを作成する"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーが新しいハッシュタグを作成できるようにする"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグ"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用済み"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグを削除する"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ハッシュタグ", _interpolate(_list(0)), "を削除する"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグの作成はロックされています"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグの作成のロックが解除されました"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ハッシュタグ「", _interpolate(_list(0)), "」を削除済み"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ハッシュタグ「", _interpolate(_list(0)), "」の名前を「", _interpolate(_list(1)), "」に変更しました"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1個のハッシュタグが追加されました"]), _normalize([_interpolate(_named("count")), " 個のハッシュタグが追加されました"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい名前"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグが作成されていません"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前を変更する"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ハッシュタグ「", _interpolate(_named("tagRenamed")), "」の名前を変更する"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグはカンマまたは改行で区切ります"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハッシュタグを検索する"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ご安心ください。", _interpolate(_named("link_copy")), "で実行できます"])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここに表示"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワークスペースにリンクされているコネクション"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを添付する"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このワークスペースにコネクションを添付する"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクション"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここで変更を加えるには管理者にお問い合わせください"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここに変更を加えるには、Zendesk QAにお問い合わせください"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントにコネクションがありません"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだカスタマーサービスプラットフォームを統合されていないようです。"])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["コネクションを削除すると、ワークスペース", _interpolate(_named("workspaceName")), "から会話が削除され、レビューや異議を含め、それらに関連するすべてのデータが削除されます。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを削除する"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["コネクション", _interpolate(_named("connectionName")), "を削除する"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コネクションを選択する"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["選択した期間内に、スコアカード上に特定のアクティブなオートQAカテゴリは存在しませんでした。前回の", _interpolate(_named("date")), "の変更以前の会話は、これらのカテゴリについて分析されていない可能性があります。"])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたに届いたフィードバックはすべてここに表示されます。まだ表示するものがありません。"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取ったレビューはありません。"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントアクティビティのみを表示"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここには、提供されたすべてのフィードバックが表示されます。会話ビューで始めましょう。"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定されたレビューはありません。"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付与したレビュー"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け付けたレビュー"])}
  }
}