export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn ditt Zendesk-underdomene"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedriften din har en eksisterende konto."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bli med i en bedrift"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil bli med i et eksisterende selskap, ber du administratoren om å sende deg en invitasjon."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be administrator om å invitere deg"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ombestemte meg."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt registreringen"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedriften din har en eksisterende konto. Vil du bli med i bedriftens konto, eller opprette en ny?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier instruksjonene"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett en ny konto"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finnes en eksisterende konto"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du får ikke tilgang til Zendesk QA-kontoen din før utestående abonnementsfakturaer er betalt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementet ditt er på vent"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ikke kontoen. Prøv på nytt."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["få nytt passord."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøv på nytt eller ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil kombinasjon av e-postadresse og passord."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be administratoren om en ny."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne invitasjonslenken er ugyldig eller har blitt brukt allerede."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " påloggingsmetoden er deaktivert på kontoen din."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne invitasjonen er ugyldig eller brukt. Be administratoren om en ny."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke laste inn invitasjonsinformasjon."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere deg. Brukeren med denne e-postadressen finnes kanskje allerede."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen din er blitt blokkert etter flere påloggingsforsøk. Se etter en e-post med instruksjoner for hvordan du opphever blokkeringen."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreft e-postadressen din først"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgi en gyldig e-postkonto"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er obligatorisk"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er obligatorisk"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påloggingskode"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send den magiske lenken"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse, arbeid"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-postadresse, arbeid"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Hovedtjenesteavtale)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passordet samsvarer ikke med kravene"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merknad om personvern"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent litt"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hei ", _interpolate(_named("invitee_name")), "!"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bli med i ", _interpolate(_named("organization")), " organisasjonen"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadressen din er bekreftet. Du kan nå logge deg på med den nye kontoen nedenfor."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett med Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig invitasjonslenke"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på ved hjelp av den magiske lenken"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake for å logge på"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett med Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett med Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadressen din er bekreftet. Du kan nå logge deg på med den nye kontoen nedenfor:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på med Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på via en magisk lenke"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på med Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg deg inn på Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen din er slettet"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du ikke konto?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn en gyldig kode"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er obligatorisk"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgi en gyldig e-postkonto"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er obligatorisk"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påloggingskode"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle påloggingsalternativer"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " via tradisjonelle metoder"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på ved hjelp av den magiske lenken"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har mottatt en e-post som inneholder påloggingskoden din."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har mottatt en e-post som du kan følge for å logge på."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en feil under sending av den magiske lenken. Prøv på nytt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg deg på via e-post"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett passord"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En liten bokstav"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stor bokstav"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt passord må inneholde"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum passordlengde er 128 tegn"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lett å huske, vanskelig å gjette"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minst 8 tegn"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett passord"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du glemt passordet?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én liten bokstav"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett tall"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én stor bokstav"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil tilbakestille passordet, oppgir du e-postadressen din. Så sender vi deg en e-post med anvisninger."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgi en gyldig e-postkonto"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er obligatorisk"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passord"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det er sendt en e-post til ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis e-posten ikke vises i innboksen eller søppelpostmappen, må du sjekke e-postadressen du oppga."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake for å logge på"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjekk e-posten"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passord"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", så får du tilsendt en e-post om tilbakestilling av passord."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis kontoen din allerede har et passord"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis det er tilfelle ", _interpolate(_named("bold")), ", så har vi sendt deg en lenke for tilbakestilling av passord."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-postadressen er knyttet til en konto"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du husket passordet ditt?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en feil under sending av lenken for tilbakestilling. Prøv på nytt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passordet"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg ved hjelp av den magiske lenken"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Personvernerklæring)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ved å registrere deg godtar du Zendesk QA ", _interpolate(_named("terms_of_service")), " og ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruksvilkår"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du en konto?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg via en magisk lenke"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg med jobb-e-postadressen din"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg med Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg for Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg med Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har en aktiv Zendesk-konto"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Registrer deg, eller logg på for å fullføre ", _interpolate(_named("direct_install_name")), " konfigurasjonen"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er det virkelig deg? Se etter en e-post for bekreftelse i innboksen din."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt er klart"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA fungerer best på skrivebordet. Logg på der for å begynne å evaluere samtaler."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestill en demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eller bestill en demo med oss, så skal vi vise deg hva du kan gjøre i Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din tilstedeværelse er nødvendig"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avbryt"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg ombestemte meg og vil ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " inviterer deg til å bli med på kontoen deres."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bli med på kontoen"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett en ny konto"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å logge på igjen"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reaksjonen du fikk på tilbakemeldingen: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt respons"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerte på kommentaren din"]), _normalize(["Flere har reagert på kommentaren din"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerte på svaret ditt"]), _normalize(["Det var flere som reagerte på svaret ditt"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerte på evalueringen din"]), _normalize(["Flere personer reagerte på evalueringen din"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentar"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svar"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omtale"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESTRIDELSER"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GITT"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTTATT"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding på undersøkelsen"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaksjoner"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startet"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vil du merke kommentaren som lest?"]), _normalize(["Vil du merke alle kommentarer som lest?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vil du merke bestridelsen som lest?"]), _normalize(["Vil du merke alle bestridelser som lest?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vil du merke tilbakemelding på undersøkelsen som lest?"]), _normalize(["Vil du merke alle tilbakemeldinger på undersøkelsen som lest?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vil du merke evalueringen som lest?"]), _normalize(["Vil du merke alle evalueringene som lest?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merket kommentaren som lest"]), _normalize(["Merket alle kommentarer som lest"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merket bestridelsen som lest"]), _normalize(["Merket alle bestridelser som lest"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merket tilbakemelding på undersøkelsen som lest"]), _normalize(["Merket alle tilbakemeldinger på undersøkelsen som lest"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merket evalueringen som lest"]), _normalize(["Merket alle evalueringen som lest"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk alle som lest"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som lest"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evalueringer"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når noen bestrider en evaluering, vises dette her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bestridelser"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når du får ansvaret for å løse en bestridelse, vises dette her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottatte bestridelser"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når du bestrider en evaluering, vises dette her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen påbegynte bestridelser"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tilbakemeldinger du har fått, vises her. Ingenting å vise ennå."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottatte tilbakemeldinger"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle reaksjoner som er blitt gitt på evalueringene og svarene dine, vises her. Ingenting å vise ennå."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottatte reaksjoner"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne en samtale"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tilbakemeldinger du gir, ender her. Begynn med å legge igjen en evaluering i samtalevisningen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen gitte evalueringer"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tilbakemeldinger du har fått, vises her. Ingenting å vise ennå."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottatte evalueringer"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kommentarer"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tilbakemeldinger du legger inn som kommentarer, vises her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen gitte kommentarer"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kommentarer"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kommentarer under en evaluering du eier, ", "@", "omtaler eller svar på kommentarene dine vises her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottatte kommentarer"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen godkjente bestridelser"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen åpne bestridelser"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen avviste bestridelser"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen løste bestridelser"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv på nytt ved å velge et annet alternativ fra filteret ovenfor."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv på nytt ved å velge en annen bruker fra filteret over."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitte kommentarer"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte kommentarer"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bestridelser"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte bestridelser"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påbegynte bestridelser"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurdering"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person som evalueres"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte tilbakemeldinger på undersøkelser"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitte evalueringer"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte evalueringer"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå aktivitetsliste av og på"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " evaluert"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " evaluert"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " evaluert"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starter på nytt om ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " igjen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurer en tildeling"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå tildelingslisten av og på"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det er ikke mulig å opprette en opplæringsøkt mellom ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), " siden de ikke er i samme arbeidsområde akkurat nå."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Opplæringsøkter mellom ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), " finnes allerede. Kontakt ", _interpolate(_list(0)), " for å få tilgang til økten."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreslått"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En ny økt mellom ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), " er opprettet."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruktør"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg instruktør"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person under opplæring"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg person under opplæring"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økt"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett økt"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett en økt"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til økt"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på økten"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreslått"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har forfalt"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommende"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett økt"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen økter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig evalueringstid"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpanel"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen data å vise for den valgte perioden"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategori"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lagt til notat til økten ", _interpolate(_named("buttonStart")), "Vis meg", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagt til"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd slutten av nålene fra den valgte perioden"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se hvordan nålene fungerer i denne ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artikkel"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det ser ut til at du ikke har festet noe under ", _interpolate(_list(0)), ". Du kan bruke nåler til å forberede deg til 1:1-er eller lagre noe til et senere tidspunkt."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personen under opplæring"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen fester er lagt til ennå"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her er alle nålene du har opprettet under ", _interpolate(_named("name")), ". Nålene som er lagt til i øktene, blir ", _interpolate(_named("bold")), " med den andre deltakeren."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ble delt umiddelbart"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det ser ut som ", _interpolate(_list(0)), " har ikke mottatt tilbakemelding på samtaleevaluering eller CSAT i den valgte perioden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilbakemelding tilgjengelig"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er kommet til slutten av kommentarer fra den valgte perioden"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måleverdier"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fester"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s tall"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt handlingselement"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fullført av ", _interpolate(_named("user")), " på ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["innen ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deg"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv for å legge til et nytt gjøremål"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva kom ut av dette møtet? Hva er de neste trinnene dine?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingselementer"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opprettet av ", _interpolate(_named("name")), " den ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slette økten"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette vil permanent ", _interpolate(_named("deleteSession")), ". Deltakere mister tilgangen til økten, inkludert innholdet og private merknader."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Økten ble opprettet den ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sist redigert av ", _interpolate(_named("name")), " den ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snakkepunkter og notater"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merknad oppdatert"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forlat økten"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utvalgte brukere får tilgang til øktinformasjonen fra dette punktet og utover."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg brukere"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delt"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del økten"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du vil ikke lenger være en del av ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " -økten."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " vil ikke lenger være en del av denne økten."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " har tilgang til denne økten nå."]), _normalize([_interpolate(_named("n")), " nye brukere har tilgang til denne økten nå"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett økten"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett alle økter"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett økten"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruktøren"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Merknader lagt til av ", _interpolate(_list(0)), " vil vises her."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til festede elementer som diskusjonspunkter fra høyre sidepanel"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fester"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merknader bare du kan se..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige merknader"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat merknad ble oppdatert"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dager"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferdig"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruktør"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person under opplæring"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingselementer"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste økt"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferdig"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pågår"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har forfalt"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øktoversikt"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meg: Instruktør"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meg: Person under opplæring"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle økter"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delte økter"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å bytte et filter for å se handlingselementer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen handlingselementer"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingselementer"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer mottatt og sett"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt · Sett"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Framdrift etter økter"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ut til at kundekonsulentene ikke har fått noen tilbakemeldinger."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroller at du har riktig navn på brukeren"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke denne brukeren"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen data er tilgjengelige"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste 30 dager IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette vil permanent <b>slette alle økter</b> (2) med <b>", _interpolate(_named("name")), "</b>. Deltakere mister tilgang til alle økter, inkludert innholdet og private merknader."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd slutten av alle øktene"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En opplæringssøkt er en gruppeaktivitet mellom en mentor og en person under opplæring for å diskutere ytelse, muligheter, kortsiktige og langsiktige mål."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan søke etter økter etter navnet på både på instruktørens og personen som veiledes."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen økter"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke opprettet noen økter ennå"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett opplæringshistorikk"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett opplæringshistorikk "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste økt"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økt"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle økter"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruktør"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person under opplæring"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferdig"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som fullført"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som angret"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter navn"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi nytt navn"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæring"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå øktlisten av og på"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke planlagt"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar på denne meldingen"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæring"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utløpt kalibreringsøkt. Det kan ikke legges inn flere evalueringer."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede lagt igjen en evaluering av denne samtalen."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke lenger mulig å evaluere denne samtalen siden forfallsdatoen for økten er utløpt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtalen har ingen evalueringer"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukk panelet"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg igjen en kalibreringsevaluering"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETALJER"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAMMENDRAG"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSKRIPSJON"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Høyttaler ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dødtid"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger høyttaler"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer meldingen"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til å spille av melding"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høyttalere"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkripsjon"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere av arbeidsområdet"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en tilkobling først for å opprette filtre"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen boter"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opprettet den ", _interpolate(_named("time")), " av ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sist oppdatert den ", _interpolate(_named("time")), " av ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det ser ut til at du ikke har noen filtre. Du må først opprette et filter for å få tilgang til denne siden. Begynn med ", _interpolate(_named("recommendedFilter")), " for å se dine egne samtaler. Opprett dette filteret nedenfor for å fortsette."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i løpet av de siste 30 dagene"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelsen «Opprettet de siste 30 dagene» blir lagt til i filtre som opprettes uten noe datointervall"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter er ikke tilgjengelig i dette abonnementet og det blir ikke tatt hensyn til dette ved visning av resultater"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henting av filteralternativer ble tidsavbrutt. Prøv på nytt."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om filtrering"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig = pålogget person"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fant samtaler: ", _interpolate(_named("count"))]), _normalize(["Fant samtale: ", _interpolate(_named("count"))]), _normalize(["Fant samtaler: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tilgjengelig med ditt nåværende abonnement. Oppgrader for å bruke denne funksjonen."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til betingelse"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av Zendesk QA KI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelse"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjør om til tildeling"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett filter"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett filter"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinerte felt"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelser"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi laster inn filteralternativene i henhold til oppsettet ditt. Dette kan ta et par minutter ved første innlasting."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi et beskrivende navn til filteret"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filternavn"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg et alternativ"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelse"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlig"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger filter"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre endringer"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen verdier er tilgjengelige"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnes ikke"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en verdi"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig for"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bare aktive økter ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis eldre økter ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfallsdato"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kalibreringsøkten"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett økt"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kalibreringsøkt"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kalibreringsevalueringer som blir utført i løpet av økten, blir slettet for godt."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett økten"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett ", _interpolate(_named("sessionName")), " kalibreringsøkt"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke opprettet noen økter"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen aktive økter"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen økter ble opprettet"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen aktive økter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til et filter"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett et privat filter"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett et offentlig filter"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter lagt til"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag duplikat"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteret blir slettet for godt."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett filteret"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett ", _interpolate(_named("filterName")), " filter"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bestridelser"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser for meg"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser fra meg"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne bestridelser"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen filtre ble lagt til"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble ikke opprettet noen private filtre"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble ikke opprettet noen offentlige filtre"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private filtre"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlige filtre"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ønsker du å ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lagre den"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er visningen til et midlertidig filter."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstruerer filteret ditt..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteret er oppdatert"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsikt"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinert AutoQA-kategori"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sist oppdatert ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompliserte ord"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvevalueringer oppdateres dynamisk basert på saksendringer"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uthev i samtalen"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange setninger"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke forutsett noen automatisk poengsum"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke noen feil"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kompliserte ord"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis lange setninger"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste samtale"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA kunne ikke vurdere denne kategorien på grunn av utilstrekkelig kontekst. Manuell gjennomgang anbefales."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fokus"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremhev viktige samtaler og vis frem eksemplariske positive interaksjoner for teamet ditt."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Anrop]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettet"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " godtok bestridelsen"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestridelse ble sendt til ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar hvorfor du tror denne saksansvarlige er feil mottaker for denne tilbakemeldingen"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en kommentar og angi en ny saksansvarlig"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en kommentar"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar hvorfor du mener disse evalueringene er urettferdig vurdert (obligatorisk)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en kommentar (obligatorisk)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar hvorfor du avviser denne bestridelsen"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en kommentar"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send bestridelse til"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send bestridelse til"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send bestridelse til"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en ny person som evalueres"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse av evaluering"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse av evaluering"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre person som evalueres"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send bestridelse til"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid denne evalueringen"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er ikke enig i poengsummen"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne evalueringen var ikke myntet på meg"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en bruker"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " godtok delvis bestridelsen"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " avviste bestridelsen"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkjent"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvist"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpen"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " startet en bestridelse: evaluering"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " startet en bestridelse: feil person som evalueres"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En åpen bestridelse bør være løst før du kan foreta andre handlinger angående evalueringen."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godta"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg nye poengsummer"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett bestridelse"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil forkaste denne bestridelsen?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett bestridelse"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid til løsning av bestridelser"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvis"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg ny rettferdig poengsum (valgfritt)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid evalueringen"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil person som evalueres"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godta bestridelse"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godta bestridelse"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse av evaluering"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil person som evalueres"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse av evaluering"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvis bestridelse"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvis bestridelse"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis bestridelsen"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigert"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " av ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["til ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomgått"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helt ferdig"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelt til:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg igjen en kommentar også"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om betingede resultatkort"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere tildelt:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare å velge én"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke noen GIF-er med det navnet"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær den første til å legge inn en evaluering."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementet ditt er på pause for øyeblikket. Aktiver den på nytt for å gjøre det mulig å gi tilbakemelding."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrevet av:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette viser hvor mye tid som ble brukt på å evaluere denne samtalen"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en bruker"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mangler rotårsak for kategorien: «", _interpolate(_list(0)), "»"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder en kategori eller legg til en kommentar"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utvid kommentarfeltet"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå sammen kommentarfeltet"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul formatering"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis formatering"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send inn evaluering"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder alle kategorier"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder minst én kategori"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre denne samtalen til senere"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre til senere"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste tildeling"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstatt samtalen i denne tildelingssyklusen"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer samtalen"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunnleggende evaluering"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kommentaren"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentaren ble slettet"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne kommentaren?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfatter av kommentar"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne emneknaggen har fått slettet eller fått nytt navn."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del det du likte eller hva som kunne vært bedre..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betinget"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette fører til at alle kommentarer slettes for godt."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kommentaren"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Melding]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " har ikke sett denne tilbakemeldingen ennå"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " har sett denne tilbakemeldingen"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere medlem"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk i GIF-er..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trykk på Enter for å opprette en ny emneknagg."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinerte emneknagger er låst av administrator."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk dette til å legge til bokmerke for saker og filtrere ut senere"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som grunnlinje"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding på melding"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste samtale"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilbakemelding"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke foretatt noen evalueringer"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg igjen en evaluering nedenfor for å komme i gang."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen andre evalueringer igjen"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke evaluert"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere saksansvarlig"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige samtale"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern som grunnlinje"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar på denne evalueringen"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå gjennom"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul sidefeltet for evaluering"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette fører til at evalueringen og alle kommentarer slettes for godt."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett evalueringen"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett evalueringen"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringen ble slettet"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Evaluering slettet av ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferdig!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildel denne oppgaven til deg selv på nytt for å kunne legge inn en evaluering"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede lagt igjen en evaluering for denne brukeren."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du har lagt inn en evaluering for ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kategorier å vise for denne samtalen."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger er ikke tilgjengelig for dette arbeidsområdet."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke lagre"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du lagre denne evalueringen som en kladd?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkast denne kladden"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkast"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis sidefelt for evalueringer"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bestått"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått med mindre feil"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send inn"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behandler fremdeles tallene..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg igjen en kommentar"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer denne meldingen"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer deg selv"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se gjennom denne samtalen"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfatter"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brukere å evaluere"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger er deaktivert"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen bruker"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere av arbeidsområdet"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg bruker"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 omtale"]), _normalize([_interpolate(_named("count")), " omtaler"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg nye evalueringer"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområdeadministratoren din har ikke tillatt egenvurderinger."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trykk på Enter for å tagge dette festet"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringer"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste 30 dager"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stjernemerket"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett en kalibreringsøkt"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utkast"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsikt"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsikt gir deg en visuell representasjon av kundeservicedataene dine. Bruk de interaktive diagrammene for å forstå hvor du skal fokusere kvalitetssikringsinnsatsen, avdekke blindsoner og finne de riktige kundekonsulentene å gjennomgå basert på ytelsen deres."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oversett til ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tildelt"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ser nå på"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluerer nå"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtalen er fjernet og ikke lenger tilgjengelig."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtalen du ser etter finnes ikke i Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle fester med denne samtalen"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I økten"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern samtale"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner en samtale fra en økt, slettes alle evalueringer denne har fått."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fjern samtale fra ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke opprettet noen justeringsøkter ennå."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtalen er lagt til i økten"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtalen er blitt fjernet fra økten"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til/fjern fra en justeringsøkt"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier lenken til denne samtalen"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtale å vise"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkevare"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens e-postadresse"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens navn"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på eksternt skjema"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første responstid"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritet"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar til undersøkelsen"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfredshetsindeks"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagger"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid til første løsning"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne meldingen finnes ikke i denne samtalen, eller den er slettet"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke noe emne"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtale å vise"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen samtale"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne den opprinnelige samtalen"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke koble til samtale – mangler nettadresse"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen deltakere"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest samtalen"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk for å lese evalueringer"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk for å evaluere"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale opprettet via nettleserutvidelse"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis undersøkelse om kundetilfredshet"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se samtale"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding med negativt synspunkt"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding med positivt synspunkt"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversett til engelsk"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis opprinnelig"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett samtale"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sletting av en samtale er permanent. Den sletter også alle tilhørende data, f.eks. undersøkelser, transkripsjoner og vurderinger fra Zendesk QA, inkludert kontrollpaneler.", _interpolate(_named("lineBreak")), "Du får fremdeles tilgang til denne samtalen i Zendesk Support eller en annen programvare du bruker."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sletting av en samtale er permanent. Den sletter også alle relaterte data, f.eks. undersøkelser, transkripsjoner og vurderinger fra Zendesk QA, inkludert kontrollpaneler. Hvis samtalen tilhører flere arbeidsområder, blir den slettet fra alle disse.", _interpolate(_named("lineBreak")), "Du får fremdeles tilgang til denne samtalen i Zendesk Support eller en annen programvare for kundeservice som du bruker."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett samtale"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett samtale fra Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne samtalen er ufullstendig på grunn av opptrapping. Gå til ", _interpolate(_named("optionsUrl")), " og bekreft taggene dine. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne samtalen er ufullstendig på grunn av opptrapping. Snakk med administratoren om å validere taggene dine. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblinger > Avanserte alternativer"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om tagger"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stengt"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpen"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løst"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saksansvarlig endret til ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tildelt"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " makro anvendt av ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtale mottok en evaluering ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bruker ", _interpolate(_named("target")), " redigerte samtalen"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status endret til ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tagget med ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Teamet ble endret til ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " utsatte samtalen for ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " opphevet utsettelse av samtalen"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boten står fast i en loop og gjentar en melding fortløpende"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-gjentakelse"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-gjentakelse, der den samme meldingen gjentas én eller flere ganger etter hverandre"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant matchende setninger"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En gjennomsnittlig kundekonsulent håndterer samtalen ", _interpolate(_named("percent")), " mer effektivt enn boten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivitet for bot-kommunikasjon"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot håndterte samtalen ", _interpolate(_named("percent")), " mer effektivt enn en gjennomsnittlig kundekonsulent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivitet for bot-kommunikasjon"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale mangler den nødvendige erklæringen som informerer deltakerne om opptaket, noe som er påkrevd for å sikre samsvar og gjennomsiktighet"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opptakserklæring mangler"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En servicenivåavtale (SLA) har blitt overskredet for ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA overskredet"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregning for SLA-regelen overskred måltiden"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegn på kundeslitasje. Kunden vurderte å bytte eller avslutte kundeforholdet."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare for kundefrafall identifisert"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtalen har øyeblikk med dødtid som varer lenger enn den innstilte terskelen på ", _interpolate(_list(0)), "s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 forekomst"]), _normalize([_interpolate(_named("count")), " forekomster"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s lengste"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dødtid"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden søkte om hjelp på et overordnet nivå"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opptrapping nødvendig"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden uttrykte dyp takknemlighet eller var veldig fornøyd med støtten hen fikk"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksepsjonell service"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enten kunden eller kundekonsulenten ba uttrykkelig om en oppfølging"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forespørsel om oppfølging"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden var misfornøyd"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativt synspunkt"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen var uvanlig eller atypisk, og det ble mer fram og tilbake for å komme fram til en løsning"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesialtilfeller ble registrert"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden var fornøyd"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivt synspunkt"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett som ikke aktuelt"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sist redigert av ", _interpolate(_named("author")), " den ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke aktuelt"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Av ", _interpolate(_named("author")), " den ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en årsak (valgfritt)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen innsikt registrert"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en tilkobling"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble kundeservicetjenesten til kontoen din og få minst ett filter for å se listen over samtaler."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler å vise"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis innstillinger for arbeidsområde"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person som evalueres"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist oppdatert"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Om én dag)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Denne måneden)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Denne uken)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn etter ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Søkeresultater for ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert av andre"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenterte"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyeste"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eldste"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfeldig"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nylig oppdatert"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nylig besvarte"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter etter"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke lest"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert av deg"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importprosessen kan ta litt tid. Vi sender deg en e-post når den er fullført."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importerer samtalene dine"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Grense: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til ved å klikke på kalibreringsikonet i toppteksten i samtalevisningen."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingenting å melde her. Prøv på nytt med et annet filter eller sorteringsalternativ."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen spesialtilfeller å vise."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn en relevant samtale og klikk på stjerneikonet i toppteksten for å legge den til bokmerke her."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å endre filtrene, eller inviter andre kundestøttekonsulenter til å legge til flere samtaler."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grense"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern merknad"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi laster inn samtaler akkurat nå. Noe innhold kan fremdeles mangle."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste bruker"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen bruker"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte evalueringer"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer i arbeidsområdet"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige bruker"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler ble lagt til"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke lagt til noen samtaler"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ufullførte evalueringer havner her. Les den på nytt, endre den og send den inn i løpet av de neste 30 dagene."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen evalueringskladder"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen stjernemerkede samtaler"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler å vise"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samsvar"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til valgt melding"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minst svar"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flest svar"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunkt"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå samtaleliste av og på"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anropet besvart kl ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtalen ble avsluttet kl ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oppringning foretatt kl ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomgått"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert av deg"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kunne ikke laste inn lydfil. Pass på at du er logget på ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar om kundetilfredshet i undersøkelsen"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil under innlasting av lyd"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfatter av kommentar"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtale fant sted"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lytt i kundeservice"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lytt på Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avspillingshastighet"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer anropet"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkripsjon"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høy"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middels"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bruker av Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangerer kundekonsulentene automatisk basert på deres månedlige gjennomsnittlige CSAT. Finn samtaler som håndteres av kundekonsulenter hvis CSAT er lavere enn kollegenes, med ett klikk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangering etter CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middels"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høy"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laveste"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høyeste"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bruker av Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én sak"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volum"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangerer automatisk kundekonsulentene i ytelsesgrupper basert på hvor mange samtaler de håndterer per måned. Finn samtaler tilhørende kundekonsulenter som har lavere volum enn kollegene med ett klikk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangering etter volum"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke evaluert"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsstatus"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høy"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nøytral"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bruker av Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunkt"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangerer kundekonsulentene automatisk basert på gjennomsnittlig synspunkt i samtalene. Finn samhandlinger der kundekonsulenter håndterer flere frustrerte kunder enn kollegene med ett klikk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangering etter synspunkt"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke registrert"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrert"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesialtilfeller"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler som krever din oppmerksomhet. Maskinlæringsmodellen vår identifiserer kompliserte eller atypiske samhandlinger for videre evaluering."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesialtilfeller"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg samtaler for evaluering der kunden utviste enten glede eller frustrasjon. Bruk rullegardinlisten til å velge synspunkttypen du er på utkikk etter i samtalene. Dra filterattributtet til toppen for å se forholdet mellom det valgte synspunktet i alle samhandlingene dine."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunkt"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunkt"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsstatus"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke evaluert"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkobling"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA gjenkjenner automatisk språk i samtalene dine. Bruk rullegardinlisten til å velge samtaler på bestemte språk som skal evalueres. Dra filterattributtet til toppen for å få en oversikt over alle språkene som brukes i kundeservice."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleinnsikt"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan ", _interpolate(_named("paragraph_2_item")), " med Insights enn med vanlige samtalefiltre. Få en visuell oversikt over alle dataene fra kundeservicetjenestene dine, og oppdag innsikt som:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lær om bruk av samtaleinnsikt"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forstå den relative viktigheten av spesifikke samtaleattributter i forhold til andre"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn kundekonsulentene med høyest og dårlig ytelse med innebygd rangering av kundekonsulentytelse"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " er et kraftig interaktivt verktøy for datautvinning som hjelper deg med å finne samtalene som har mest behov for evaluering."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gjør så mye mer"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtale- og Kundekonsulent-diagrammene er ", _interpolate(_named("paragraph_3_item_1")), " – oppdaterer du filtrene eller klikker på de segmenterte diagramsegmentene på noen av disse, vil samtalelisten oppdateres automatisk. ", _interpolate(_named("paragraph_3_item_2")), " for å utforske detaljerte oppdelinger eller forholdstall for de forskjellige elementene."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sammenkoblet"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordne filterattributtene på nytt"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtaleinnsikt viser bra ", _interpolate(_named("paragraph_4_item")), ". Vi fjerner automatisk interaksjoner som ikke har noen reell verdi for evalueringen, som f.eks. automatisk genererte meldinger, søppelpost, bot-svar eller meldinger som ikke har noen ordentlig dialog med kundekonsulenten."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rene og lukkede samtaler"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er det jeg ser her?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " av ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " av ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke hente"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " I/A"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenter"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " av totalen)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last på nytt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsikt"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men vi er sikre på at den blir flott når den kommer."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset undersøkelse"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilbakemeldinger på undersøkelser"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke noen tilgjengelige tilbakemeldinger på undersøkelsen ennå..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurdering"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunn"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ble mottatt"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemeldingstag"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding kommentartagger"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datointervallfilter er brukt"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " svar"]), _normalize([_interpolate(_named("count")), " svar"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Finn ut mer om hvorfor en ", _interpolate(_list(0)), "-poengsum er gitt ved å konfigurere årsaker under innstillingene til undersøkelsene."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ingen ", _interpolate(_list(0)), " grunner ennå"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om årsaker til undersøkelsen"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler med tagger:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagger"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " av"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsaker"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagger"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarkategori"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " årsaker"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anslåtte ", _interpolate(_named("surveyType")), "-drivere"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekilde"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice-tagger"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Svar på undersøkelsen (", _interpolate(_named("n")), ")"]), _normalize(["Svar på undersøkelsen (", _interpolate(_named("n")), ")"]), _normalize(["Svar på undersøkelser (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke noe emne"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtaler med ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " av brukere"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsandel"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " poengsummen er summen av alle svarene delt på summen av maksimal poengsum"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger mål"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern mål"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett et mål"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til mål"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kort"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldig lang"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middels lengde"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forvirrende"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nyttig"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårlig kundestøtte"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bra kundestøtte"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følelser i massevis"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding om kundekonsulent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klage"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smuler"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem ikke løst"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem løst"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativt synspunkt"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårlig utfall"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivt synspunkt"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ros"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårlig produkt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundert"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rask kundestøtte"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsom kundestøtte"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tusen takk"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tildeler automatisk dine ", _interpolate(_list(0)), " data inn i forhåndsdefinerte kategorier. Dermed kan du forstå komplisert innsikt og utforske årsaker uten å måtte gå gjennom de skriftlige tilbakemeldingene."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle poengsummer"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle undersøkelser"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg responsspråk"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstørrelse"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kommentarstørrelse"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25 % av kommentarene består bare av ett ord, og derfor er det absolutt nødvendig å lese lengre kommentarer. Få mer kunnskap om tilbakemeldinger uten å måtte legge inn manuelt arbeid, siden vi gjør det automatisk for deg."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsak til tilbakemeldingene"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg årsak til tilbakemeldingen"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchende resultater"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelser"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslåtte drivere"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kommentarkategori"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordsky-ord"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg ordsky-ord"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " siden forrige periode"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " samtale"]), _normalize([_interpolate(_named("n")), " samtale"]), _normalize([_interpolate(_named("n")), " samtaler"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke beregne sendte undersøkelsesforhold fra alle samtaler på grunn av mangel på data."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendte undersøkelser"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsestrakt"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " av ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " av ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Antatt ", _interpolate(_named("surveyType")), " sjåfører"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tildeler automatisk dine ", _interpolate(_list(0)), " data inn i forhåndsdefinerte kategorier. Dermed kan du forstå komplisert innsikt og utforske årsaker uten å måtte gå gjennom de skriftlige tilbakemeldingene."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstørrelse"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25 % av kommentarene består bare av ett ord, og derfor er det absolutt nødvendig å lese lengre kommentarer. Få mer kunnskap om tilbakemeldinger uten å måtte legge inn manuelt arbeid, siden vi gjør det automatisk for deg."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsessvar"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (gjennomsnitt)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall svar"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (gjennomsnitt)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnitt"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " trender etter"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall svar"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsaker"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagger"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivere"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sendedato for undersøkelsen"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " dato for svar på undersøkelsen"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tildel ", _interpolate(_list(0)), " poengsum til"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis data basert på"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for opprettelse av samtalen"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eier av saken"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kundekonsulenten med flest svar"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (gjennomsnitt)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " over tid"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall svar"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " og IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall svar"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler som inneholder disse ordene:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordsky"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT etter brukere"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-oversikt"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsestrakt"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsessvar"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tagger"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT over tid"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordsky"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kilde"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke noe språk"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grunner"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tagger"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kommentarkategori"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke noe arbeidsområde"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen data tilgjengelig fordi det ikke er opprettet noen tildelinger eller ingen sykluser er startet. Kom tilbake senere eller juster filtrene."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utforsk hva som er nytt"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " element"]), _normalize([_interpolate(_named("count")), " elementer"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " samtale"]), _normalize([_interpolate(_named("count")), " samtaler"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bestått"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått med mindre feil"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestått/Ikke bestått"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnitt"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnitt (t)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnitt (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " evalueringer"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig CSAT = [summen av tilbakemeldinger ÷ (antall av alle svar × maks mulig poengsum)] × 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-poengsummen hos Zendesk QA normaliserer tilbakemeldingene innenfor en skala fra 0–100 % og tar et gjennomsnitt av de standardiserte vurderingene."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Customer Satisfaction Score) viser hvor fornøyde kundene er med tjenestene dine."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Hver evaluering representerer evalueringen skrevet av én person på én samtale. En samtale kan ha mer enn én evaluering (hvis to eller flere kvalitetssjekkere vurderer den samme samtalen)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følg utviklingen i kvalitetssjekkerens poengsum over tid."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følg utviklingen i kundekonsulentenes poengsummer over tid."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " evalueringer"]), _normalize([_interpolate(_named("n")), " evaluering"]), _normalize([_interpolate(_named("n")), " evalueringer"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (intern kvalitetsscore) er et resultat av samtaleevalueringene dine."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = summen av evalueringspoengsummene ÷ antall evalueringer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det beregnes som et gjennomsnitt, der det tas hensyn til alle evalueringspoengsummene (vektet gjennomsnitt av kategoripoengsum) som stemmer overens med de valgte filterbetingelsene."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke laste inn kortet."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bytt filtrene for å prøve på nytt."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståttsandelen indikerer resultatet av samtaleevalueringene."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ikke bestått hvis evalueringspoengsummen er ", _interpolate(_named("failScore")), " eller lavere"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestått hvis evalueringspoengsummen er ", _interpolate(_named("passScore")), " eller høyere"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den bestemmes av en forhåndsdefinert grunnlinje, som betyr at du:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståttandel = Sum av beståtte valueringer ÷ Sum av totale evalueringer"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståttandelen angir prosentandelen av hvor mange evalueringer som bestod uten at de mislykkes i verken kritiske eller ikke-kritiske kategorier."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis kvalitetssjekkeren f.eks. gir 3 evalueringer denne dagen, er tallet som vises, et gjennomsnitt av de 3 evalueringene. Se hvordan hver celle er beregnet ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis kundekonsulenten f.eks. gir 3 evalueringer denne dagen, er tallet som vises, et gjennomsnitt av de 3 evalueringene. Se hvordan hver celle er beregnet ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tatt hensyn til: kategorivekt, kritisk status (i motsetning til poengsum over tid)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siden kategorier kan merkes som ikke tilgjengelig i evalueringer, kan antallet evalueringer per kategori være lavere enn totalt antall evalueringer utført av brukeren. Se hvordan hver celle er beregnet"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tatt hensyn til: kategorivekt, kritisk status (i motsetning til poengsum over tid)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siden kategorier kan merkes som ikke tilgjengelig i evalueringene, kan antallet evalueringer per kategori være lavere enn totalt antall evalueringer per kundekonsulent. Se hvordan hver celle er beregnet"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tatt hensyn til: kategorivekt, kritisk status (i motsetning til poengsum over tid)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategorigrupper vises separat. Poengsummen for hver gruppe beregnes ut fra gjennomsnittlig kategoripoengsum for den gruppen i samme periode. Se hvordan hver celle er beregnet ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tatt hensyn til: kategorivekt, kritisk status (i motsetning til poengsum over tid)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategorigrupper vises separat. Poengsummen for hver gruppe beregnes ut fra gjennomsnittlig kategoripoengsum for den gruppen i samme periode. Se hvordan hver celle er beregnet ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grunnlinje ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri for kritiske feil"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosentandelen som er fri for kritiske feil angir prosentandelen av hvor mange evalueringer som består uten at den stryker i noen kritiske kategorier."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern kvalitetsscore"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Andre' underliggende årsaker"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversikt"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodisk endring"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daglig endring"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig endring"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukentlig endring"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig endring"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsummer over tid"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis i enten dager eller uker."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregning = total poengsum for dag eller uke delt på totalt antall gitte evalueringer"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis i enten dager eller uker."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregning = total poengsum for dag eller uke delt på totalt antall mottatte evalueringer"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum etter kategori"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis det kombinerte gjennomsnittet av alle kategorievalueringene som ble gitt av kvalitetssjekkeren i denne perioden."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis det kombinerte gjennomsnittet av alle kategorievalueringer som ble mottatt per kundekonsulent i denne perioden."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underliggende årsaker til dårlige evalueringer"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underliggende årsaker til evalueringer"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoripoengsum over tid"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser hvordan kategoriene sammenligner over tid."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis gjennomsnittlig poengsum gitt for hver kategori på en valgt dag eller uke."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser hvordan kategoriene sammenligner over tid."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis gjennomsnittlig poengsum for hver kategori på en valgt dag eller uke."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum etter evalueringer"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivert"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritisk kategori"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskala"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnitt/total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelat egenvurderinger"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporterer alle kort"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitt"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["samtale"]), _normalize(["samtaler"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["element"]), _normalize(["elementer"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " evalueringer"]), _normalize([_interpolate(_named("n")), " evaluering"]), _normalize([_interpolate(_named("n")), " evalueringer"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vis én til"]), _normalize(["Vis ", _interpolate(_named("n")), " til"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste 30 dager"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste 7 dager"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste måned"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste uke"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne måneden"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne uken"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinert periode"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dager"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måneder"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvartaler"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uker"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke laste inn kortet. Bytt filtrene for å prøve på nytt."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen data å vise."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig endring"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversikt"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpaneler"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer informasjon."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De aktive filtrene omfatter data på tvers av forskjellige evalueringsskalaer."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kortnåler med disse filtrene"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kontrollpanelnåler med disse filtrene"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig evalueringstid"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpass"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned CSV-fil"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kategorier"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriinnsikt"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagram"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentar"]), _normalize(["Kommentarer"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrise"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere medlem"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern kvalitetsscore"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (gjennomsnitt)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståtte evalueringer"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest kortet"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest kontrollpanelet"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderinger"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Underliggende årsak"]), _normalize(["Underliggende årsaker"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluerings-ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringstid"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person som evalueres"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekker"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurer underliggende årsaker"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underliggende årsaker forteller deg hvorfor samtaler får lav poengsum"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke angitt noen underliggende årsaker satt opp for denne kategorien"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum / Total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorier"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigrupper"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorigruppe"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområde"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpass rekkefølgen"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper kategorier etter"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk alle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpass"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelat egenvurderinger"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta med egenvurderinger"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kun egenvurderinger"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tøm filtre"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen emneknagger"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagg for kommentarer"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg emneknagger for kommentarer"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen tagger"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tagger"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk i helpdesk-tagger"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen resultatkort"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg resultatkort"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samsvarende tilkoblinger"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekilde"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kilde"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere filtre"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samsvarende arbeidsområder"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antall ganger gitt"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samsvarende grupper."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kort"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis data basert på"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettelsesdato for evalueringen"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for opprettelse av samtalen"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis to desimaler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis innstillingene"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visning"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbeidsområder"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hele kontoen"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på arbeidsområdet"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbeidsområder"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinert"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flagg"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat og drikke"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dyr og natur"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekter"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennesker og kropp"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reise og steder"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofte brukt"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søkeresultater"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiley og uttrykksikon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symboler"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen emoji"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne en samtale"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til oppgavelisten"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " eller bla gjennom listen din med ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bruker for øyeblikket ", _interpolate(_named("workspace")), " arbeidsområde som ikke er koblet til dette domenet. Klikk her for å koble den til nå."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilgjengelige tilkoblinger"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan ikke gi tilbakemelding på ", _interpolate(_named("host")), " ennå."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha kundeservice som aktiv fane."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette domenet kan ikke evalueres ennå. I så fall må du gi beskjed til administratoren."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til dette domenet"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Koble til med ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et øyeblikk - gjør klart for deg"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikk ikke nettadressen"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verktøy som allerede er tilkoblet organisasjonens Zendesk QA-konto."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen verktøy koblet til organisasjonens Zendesk QA-konto."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lar deg evaluere nettsteder der nettadressen alltid forblir den samme, ved å tilføye en unik emneknagg på slutten før du legger inn en evaluering"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bytt konto"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblinger"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenene dine"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domener som allerede er tilkoblet dette arbeidsområdet."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan evaluere en hvilken som helst side på følgende domener"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domener som er tilgjengelige for evaluering"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internnummer"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unik nettadresse"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ingen kundestøtte er tilkoblet ennå. Gå til ", _interpolate(_named("link")), " for å legge til en."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettleserutvidelsen av Zendesk QA kan bare brukes\npå Professional- og Advanced-abonnementet."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bytt arbeidsområde"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgi et navn"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgi en verdi"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinerte felt"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdi"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er første gang vi støter på denne samtalen. Gi noen detaljer."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalereferanse:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe å identifisere saken med senere"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundert"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgi både saksansvarlig og et emne"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppretter"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se gjennom denne samtalen"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tildelt"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta kontakt med lederen din, eller bytt til et annet arbeidsområde enn ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurdering er deaktivert for dette arbeidsområdet."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg av"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha det!"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområde"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne nettadressen kan ikke evalueres. Naviger til en samtale."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg deg inn på Zendesk QA via SSO-leverandøren først. Prøv deretter på nytt."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg på med SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpner popup-vindu for pålogging."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg deg på med Zendesk QA-kontoen din"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiserer..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du ikke en konto ennå?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer deg"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke tilgang til denne samtalen. Hvis du vil, tar du kontakt med lederen din."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgang"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjekk at forbindelsen din er aktivert i Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer tilkoblingene dine"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblingsfeil"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naviger til en samtale i kundeservicetjenesten for å fortsette"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesten ferdig med å koble til."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et øyeblikk"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å laste inn siden på nytt for å komme inn i flyten av ting igjen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe gikk galt"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri deg fra de virtuelle grensene til kundestøttetjenesten og evaluere samtaler hvor som helst."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader abonnementet ditt for å bruke utvidelsen"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer abonnementet ditt"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeserviceforbindelsen er ikke tilknyttet dette arbeidsområdet"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservicen gjenkjenner ikke denne saken, eller Zendesk QA har ikke tilgang til den"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ikke samtalen"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny aktivitet"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fått tilbakemelding"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påloggingen fullført"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan bruke appen nå"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder alle kategorier"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk dette til å legge til bokmerke for saker og filtrere ut senere"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilbakemelding for denne samtalen"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les tilbakemeldingene"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne i Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er her alle tilbakemeldinger og varslinger vil vises. Ingenting ennå…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mer i Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte evalueringer"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluering"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaledetaljer"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder alt av"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er alle av"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder ikke alle av"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke alle av"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedre enn kundekonsulentens"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verre enn kundekonsulentens"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usant"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er mer enn"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder hvilke som helst av"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er noen av"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder ikke noen av"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke noen av"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sant"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ble brutt"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommenterte"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentert av meg"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["har ikke kommentert"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke kommentert av meg"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritisk for evaluering"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siste 30 dager"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innen"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siste 14 dager"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siste 24 timer"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siste 30 dager"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siste 7 dager"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forrige måned"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forrige uke"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne måneden"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne uken"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["til"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i går"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrert"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finnes"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stjernemerket av meg"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke stjernemerket av meg"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omfatter"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mer enn"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starter fra"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindre enn"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frem til"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke overskredet"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke komplisert"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke registrert"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finnes ikke"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omfatter ikke"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke mottatt"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke vist"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positiv"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke evaluert"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke evaluert av meg"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluert"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluert av meg"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mottatt"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritisk for evaluering"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mer enn"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starter fra"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindre enn"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frem til"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inneholder ikke"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vist"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentaren min har svar"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nei"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinerte kategorier"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset søkelys"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på betingelse"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasjon"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måleverdier"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluering og tilbakemelding"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle betingelser"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fokus"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammenligner botens samtalehåndtering med gjennomsnittlige kundekonsulenter"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boten blir sittende fast i en loop og gjentar den samme meldingen i påfølgende omganger"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall bot-svar i samtaler"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En type bot som er involvert i samtalen"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bot involvert i samtalen"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtaleinnholdet inneholder uttrykket ", _interpolate(_list(0)), " i kundekonsulentens melding(er)"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiser automatisk samtaler som mangler den obligatoriske informasjonserklæringen, som «Denne samtalen blir tatt opp» og lignende."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En person eller bot som er involvert i samtalen"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten eller boten som vurderes i samtalen"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden ba om hjelp på et høyere nivå"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive og negative synspunkter avdekket i samtalen"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiserer den mest aktive kundekonsulenten i samtaler ved hjelp av KI-analyse"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten avsluttet samtalen på en høflig måte"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten forstår og anerkjenner kundenes opplevelser"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer kundekonsulentens grammatikk-, stave- og stilfeil"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for å se etter typiske hilsesetninger"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten forstår kundens forespørsel eller bekymring"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hvor lett en tekst kan forstås, med tanke på ordkompleksitet og setningslengde"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for en tilbudt løsning"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer kundekonsulentens tone gjennom hele samtalen"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegn på kundeslitasje. Kunden vurderte å bytte eller avslutte kundeforholdet."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe som samtalen tilhører."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innholdet i eller teksten i kundekonsulentmeldingen"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundekonsulentsvar i samtaler"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten som er tildelt evaluering av samtalen"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiden samtalen varte i sekunder"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innholdet i eller teksten i samtalen"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikasjonskanalen som ble brukt til samtalen (f.eks. e-post, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer lagt til i samtalen"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen da en kommentar ble lagt til i samtalen"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive og negative synspunkter avdekket i samtalen"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen da kunden svarte på undersøkelsen om kundetilfredshet"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvorvidt samtaleevalueringen er omstridt"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen kunden brukte til å starte samtalen"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundesvar i samtaler"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen for den siste meldingen i samtalen"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen eller typen bruker som er involvert i samtalen"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stillheten mellom kundekonsulenten og kunden overskred terskelen"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden søkte om hjelp på et overordnet nivå"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden uttrykte dyp takknemlighet eller var veldig fornøyd med støtten hen fikk"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enten kunden eller kundekonsulenten ba uttrykkelig om en oppfølging"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teksten eller oppføringen for hele samtalen utført med tale"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvorvidt samtalen er merket med stjerne"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagger som brukes i kommentarer for å kategorisere innhold"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språket i samtalen, oppdaget av KI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen for den siste meldingen i samtalen"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall private merknader i samtaler"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig antall tegn per melding i samtaler"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall svar rettet mot kunder i samtaler"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En deltaker hvis informasjon er offentlig synlig"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig antall ord per melding i samtaler"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier som brukes til å vurdere eller evaluere samtalen"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for samtalens evalueringsprosess"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En deltaker eller person som er involvert i samtalen"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En e-post forbundet med samtalen"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvorvidt evalueringen har blitt sett"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkeren som vurderte samtalen"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen samtalen sist ble evaluert"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten som evalueres i samtalen"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventuelle spesifikke kommentarer eller tilbakemeldinger fra kunden angående deres tilfredshet med samtalen"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundetilfredshetsindeks for samtalen"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundetilfredshetsindeks per melding i samtalen"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkortet som ble brukt til å evaluere samtalen"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitte SLA-er er overskredet"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typen kilde eller tilkobling som samtalen kom fra"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen var uvanlig eller atypisk, og det ble mer fram og tilbake for å komme fram til en løsning"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsesspørsmålet var knyttet til samtaleinnholdet"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tittelen eller navnet på undersøkelsen som er forbundet med samtalen"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten som er tildelt evaluering av samtalen"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkevaren eller det spesifikke produktet/tjenesten som er forbundet med samtalen"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikasjonskanalen som ble brukt til samtalen (f.eks. e-post, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen da samtalen ble avsluttet eller løst"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen da samtalen ble opprettet første gang"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen da samtalen ble opprettet første gang"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen da kunden svarte på undersøkelsen om kundetilfredshet"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kort beskrivelse eller sammendrag av samtalen"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ekstern referanse-ID koblet til samtalen"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappen eller den spesifikke gruppen der samtalen er organisert"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjemaet eller malen som brukes til å strukturere samtalen"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ekstern referanse-ID koblet til samtalen"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postkassen eller e-postkontoen som samtalen ble mottatt gjennom"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummeret som er forbundet med samtalen"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritetsnivået som er tildelt til samtalen"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antall svar i samtalen"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status eller gjeldende status for samtalen"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjeldende status for samtalen"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emnet eller temaet for samtalen"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundekonsulentsvar i samtaler"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagger som er tildelt for å kategorisere samtalen"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typen eller kategorien for samtalen"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen samtalen sist ble oppdatert"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen samtalen sist ble oppdatert"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediet eller kanalen som samtalen skjedde gjennom"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innholdet i en transkripsjon i en samtale"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler som ikke er kvalifisert for gjennomgang via KI-analyse"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundekonsulenter som deltar i samtaler"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvorvidt samtalen er blitt vist"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken som helst melding"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivitet for bot-kommunikasjon"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-gjentakelse"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall Bot-svar"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-type"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppringningsretning"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientmelding"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dødtid"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opptakserklæring mangler"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opptrapping"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innhold i samtaletranskripsjon"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluttes"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stjernemerket"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke kvalifisert for evaluering"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert av"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale via"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettskriving og grammatikk"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentmelding"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mest aktive kundekonsulenten"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundekonsulentsvar"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalevarighet (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleinnhold"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingskanal"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentartag"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentert dato"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunkt"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for svar på CSAT-undersøkelse"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelse"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekanal"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundesvar"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for siste svar"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukertype"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsen"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare for kundefrafall"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dødtid"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opptrapping"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksepsjonell service"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppfølging"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkripsjon"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagg for kommentar"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesialtilfelle"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forståelse"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for siste svar"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall private merknader for samtale"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig antall tegn"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig antall ord"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall offentlig svar"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlig deltaker"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurdert dato"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurdering og kommenter"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategori"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsstatus"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesevennlighet"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltaker"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatert e-post"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilhørende relasjoner"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall svar"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt evaluering"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert resultatkort"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringen ble sett"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluert dato"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person som evalueres"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekker"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar til undersøkelsen"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per melding"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfredshetsindeks (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbudt løsning"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kildetype"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emne"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsesspørsmål"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tittel på undersøkelsen"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalemerke"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkevaredomene"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekanal"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluttet dato"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettelsesdato"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettelsesdato"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for svar på CSAT-undersøkelse"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utsendingsdato for CSAT-undersøkelse"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalebeskrivelse"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstern ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalefelt"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalemappe"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleskjema"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalegruppe"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout-ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstern ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porttelefon-ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalepostkasse"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleprioritet"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall samtalersvar"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalestatus"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalestatus"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleemne"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundekonsulentsvar"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tag"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tag"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaletype"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdatert dato"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdatert dato"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innhold i transkripsjon"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall kundekonsulenter som deltar"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visningsstatus"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sårbarhetsmulighet"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sårbarhetshelse"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sårbar livshendelse"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategori"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en datorelatert betingelse"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til et navn i filteret ditt"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn et filternavn"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pålogget person"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere medlemmer av arbeidsområdet"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer i arbeidsområdet"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Legg til ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk filter"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må ha minst 1 datorelatert filter"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg alternativer"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg et alternativ"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode ender om ", _interpolate(_named("n")), " dag. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode ender <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>om ", _interpolate(_named("n")), " dag</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode ender om ", _interpolate(_named("n")), " dager. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode ender <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>om ", _interpolate(_named("n")), " dager</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøveperioden din utløper i dag. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din prøveperiode ender <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>i dag</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett opp et abonnement nå"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " dager"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("days")), " igjen av prøveperioden"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nådd brukergrensen for abonnementet ditt. ", _interpolate(_named("link")), " for å lese mer eller oppgradere abonnementet ditt."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk her"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koble til kundeservice"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette gjør at vi kan hente inn sakene dine som kan evalueres."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til kundeservice"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bruker Zendesk QA med eksempeldata. Hvis du vil slette eksempeldata og importere dine egne samtaler, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksempeldata"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier nyttig informasjon nedenfor"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta et helsides skjermbilde for å vise kontekst"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta kontakt med kundeserviceteamet vårt for å løse dette problemet:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe gikk galt"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feilreferanse:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier nyttig informasjon"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere, boter og arbeidsområder"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere og arbeidsområder"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne måneden"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne uken"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluert ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluert ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluert ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er nytt?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg av"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurtigtaster"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat med oss"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatten vår kunne ikke lastes inn (kanskje du har blokkert annonser?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæring"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopp til..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettsamfunn"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpanel"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjelp"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjemmeside"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitte evalueringer"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte evalueringer"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunnskapsbase"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bytt konto"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgaver"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applikasjonen ble oppdatert. ", _interpolate(_named("buttonStart")), "Last på nytt", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjør følgende hvis du vil begynne å bruke Zendesk QA som en del av arbeidsområdet ditt:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg vil bare fortelle deg at du allerede har mottatt en invitasjon til å bruke Zendesk QA som en del av et arbeidsområde. Hvis du vil, kan du selvfølgelig fortsette, men vær oppmerksom på at dette oppretter en ", _interpolate(_named("bold")), " for deg."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egen organisasjon"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja, jeg fortsetter med ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en ny konto"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " ber deg om å bli med ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bli med"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen Internett-forbindelse"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til ett nytt feste"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurtignavigering"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fokuser og utvid kommentarredigereren"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul eller vis sidepanel"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til neste samtale"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne den opprinnelige samtalen"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til forrige samtale"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi alle kategorier høyest mulig vurdering"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi alle kategorier lavest mulig evaluering"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stokk samtaler (sorterer tilfeldig)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortell oss"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurtigtaster"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå på snarveimodus"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nådd ", _interpolate(_named("charLimit")), "-tegns grensen"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bilde ", _interpolate(_named("name")), " type (", _interpolate(_named("type")), ") er ikke tillatt"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bilde ", _interpolate(_named("name")), " størrelsen er for stor (5 MB tillatt)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarredigereren kunne ikke lastes inn"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slipp for å laste opp"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilde"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenke"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenke"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer maler"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen maler ble lagt til"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til underliggende årsak"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet, inkludert slettede årsaker"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsak til evaluering"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall ansatte"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi tar kontakt for å hjelpe deg med å konfigurere kontoen din"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmanavn"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktnummer for bedriften"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan fikk du først høre om Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en kanal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg et antall kundekonsulenter"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyll ut alle obligatoriske feltene"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må godta å ha lest og samtykke til vilkårene våre og retningslinjene for personvern."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Støttedrevet Slack-kanal"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklame"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendelse"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venn eller kollega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice markedsplass eller kontoadministrator"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henvisende nettsted"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søkemotor (Google, Bing osv.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosiale medier"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortell om deg selv og bedriften din."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinformasjon"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er klar."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk rundt, bli kjent med appen, slett og legg til ting."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto opprettet. Hva er det neste?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du klar til å få inn de ekte samtalene?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til egne data"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begynn å utforske"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv det selv med eksempeldata"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kan nå begynne å hente inn sakene dine som du kan evaluere."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til kundeservicekontoen din"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ombestemte meg."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt registreringen"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Notat ble opprettet ", _interpolate(_named("buttonStart")), "Gå til notater", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæringsnotater er tilgjengelig med Professional-abonnementet."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader abonnementet"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle planer"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre merknadene dine for å forberede seg til neste 1:1 eller opplæringsøkt."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåler for opplæring"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenter eller legg til andre med ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtalen er slettet"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett et nytt feste"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett feste"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger feste"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan festene fungerer"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke tillatelse til å se denne samtalen"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest kortet"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest samtalen"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest kontrollpanelet"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste lagret"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest dette kortet"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest denne samtalen"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest dette kontrollpanelet"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest under en bruker"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en bruker"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare du som kan se festene dine. Hvis du vil dele fester, ", "@", " noen i kommentarene."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " er nå invitert til denne samtalen"]), _normalize([_interpolate(_named("agentsNames")), " er nå invitert til denne samtalen"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 kommentar"]), _normalize([_interpolate(_named("n")), " kommentarer"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Du nevnte ", _interpolate(_named("participants")), ", men de er ikke i denne samtalen."]), _normalize(["Du nevnte ", _interpolate(_named("participants")), ", men de er ikke i denne samtalen."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " deltakere"]), _normalize([_interpolate(_named("n")), " deltaker"]), _normalize([_interpolate(_named("n")), " deltakere"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vil du fjerne ", _interpolate(_named("agentName")), " fra å få tilgang til denne nålen?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke gjør noe"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke inviter dem til denne samtalen"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til kommentar"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter dem"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter dem til denne samtalen"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bruker"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tråd"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan du ikke angre."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil også slette alle kommentarene under denne nålen."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nye meldinger"]), _normalize([_interpolate(_named("n")), " ny melding"]), _normalize([_interpolate(_named("n")), " nye meldinger"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare du som kan se festene dine. Hvis du vil dele fester, ", "@", " noen i kommentarene."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " festet ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et kontrollpanel"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en samtale"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en merknad"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader for å få opplæringsnåler"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæringsnotater er tilgjengelig med Professional-abonnementet."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæringsnåler er tilgjengelig med Professional-abonnementet"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader for å få funksjonen"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbered deg på 1:1 eller lagre noe til et annet tidspunkt."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil permanent slette nålen og alle kommentarer som er lagt til."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett feste"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett feste"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappenavn"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest ting og kom tilbake til dem senere"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyeste først"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eldste først"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre en interessant kontrollpaneltrend eller samtale som forberedelse til dine 1-1, eller noter det til en annen gang."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fest noe ved å klikke på knappen ovenfor eller på ikonet i samtale- eller kontrollpanelvisningen."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen fester er opprettet"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ingen resultater for ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å bruke andre nøkkelord eller se etter skrivefeil."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett et nytt feste"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldinger"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personer"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på bruker, nøkkelord..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetisk"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nylig opprettet"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nylig redigert"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flest nåler"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Jeg har som mål å evaulere ", _interpolate(_named("n")), " samtale ", _interpolate(_named("schedule"))]), _normalize(["Jeg har som mål å evaluere ", _interpolate(_named("n")), " samtaler ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål ble oppdatert"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett mål"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan angi et personlig mål for antallet samtaler du ønsker å evaluere."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hver dag"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hver måned"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hver uke"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsmål"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONLIGE"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlogging"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maler"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn kanaler"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kanal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bare del tilbakemeldinger på undersøkelsen med kommentarer"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styrke kollegene dine ved å fremheve deres eksepsjonelle servicekvalitet og utmerkede tilbakemeldinger fra kundene."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi Slack positive tilbakemeldinger om undersøkelsen"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen kanaler"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del automatisk tilbakemeldinger med poengsummen over"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praisebot-integrasjoner"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motta en påminnelse i begynnelsen av uken hvis du ikke har foretatt noen evalueringer."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påminnelser"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukentlig påminnelse om å foreta evalueringer"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motta varslinger om nye evalueringer, svar, omtaler og bestridelser i Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtaler"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderinger"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trådsvar"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrasjoner"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsle om nye evalueringer, svar, omtaler og bestridelser."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser knyttet til meg"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtaler i kommentarene"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nye mottatte evalueringer"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar i tråder"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport med statistikk"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motta rapporter om gitte og mottatte evalueringer."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daglig"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["månedlig"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukentlig"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daglig"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver 3. time"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver 6. time"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straks"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukentlig"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoadministratoren din har låste varslingsinnstillinger."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg periode"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige varsler"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktiv)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til et nytt sosialt brukernavn på denne kontoen:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner en aktiv påloggingsmetode, blir du logget av. Du kan logge på igjen med en alternative e-postadresse."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern påloggingsmetoden"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La til Google som påloggingsalternativ"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påloggingsalternativer"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La til Slack som påloggingsalternativ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlogging"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett passord"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passord"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En e-post til ", _interpolate(_named("email")), " blir sendt for å nullstille passordet ditt."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primær e-postadresse"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjekk innboksen din etter bekreftelses-eposten"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk lenke"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For å endre det, endrer du ", _interpolate(_named("link")), " på forhånd."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-posten ", _interpolate(_named("email")), " blir knyttet til dette passordet."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilen ble oppdatert"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbilde"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For best resultat, last opp et firkantet bilde (256x256 piksler eller mer)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primær e-post"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start på uken"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige innstillinger"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg minst ett alternativ"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malinnhold"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på mal"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis mal i"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til malinnhold"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til et malnavn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett mal"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett mal"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kommentarmaler for å sette fart i evalueringen, feste elementer og opplæring."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukt i"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett mal"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne malen for godt?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger mal"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kommentarmaler for å sette fart i evalueringen, feste elementer og opplæring."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen maler opprettet"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarmal ble opprettet"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarmalen ble slettet"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarmalen ble lagret"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæring"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fester"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer og bestridelser"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontakt ", _interpolate(_named("link")), " for å få tilgang."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kundestøtte"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen konto"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bruk ", _interpolate(_named("link")), " for nå for å få tilgang til Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen ble ikke overført"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversikt"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelser"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måleverdier"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poengsum"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistikk"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpanel"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrering"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samtaler"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtre"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluering"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitt"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentarene mine"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mine bestridelser"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mine evalueringer"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaksjoner"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startet"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding på undersøkelsen"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fester"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæring"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maler"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opprette passord"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukernavn/passord"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-post"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-post"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pålogging"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilbakestille"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluer mål"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maler"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruker"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett mal"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukernavn"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maler"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konto"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fakturering"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedrift"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasjoner"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisasjon"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaling"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lisenser"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innstillinger"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjenninger"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblinger"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny kategori"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fokus"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelser"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant motstridende melding"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre eller fjern én av de motstridende meldingene"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bruk ", _interpolate(_named("placeholder")), "-ikonet for å utelate sensitive data fra AutoQA. I stedet for «Ta vare på deg selv, Kari» kan du for eksempel bruke «Ta vare på deg selv, ", _interpolate(_named("placeholder")), "» for å representere et hvilket som helst navn."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimum ", _interpolate(_named("limit")), " rader"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk regel på"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd grensen på 10 egendefinerte kategorier"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nådd grensen på ", _interpolate(_named("limit")), " egendefinerte kategorier"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til betingelse"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til rad"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett betingelse"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis kundekonsulentens melding"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annen poengsum"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn deretter resultatet"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett resultatkortkategori"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk «/» for å legge til en variabel"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle retninger"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentmeldingen kan ikke være tom"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen kan ikke ha mer enn 300 tegn"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kilder"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kanaler"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tagger"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle samtaler"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avgrense der regelen din gjelder."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konkrete samtaler"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag Spotlight-innsikt"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentmeldingen kan ikke være tom"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd grensen på 10 tilpasset innsikt"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nådd grensen på ", _interpolate(_named("limit")), " egendefinert innsikt"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn navnet på innsikten"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsikt-navn"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kategori"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsiktstype"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nøytral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til innstillingene for arbeidsområde"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nå"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil begynne å bruke denne vurderingskategorien, går du til innstillingene for resultatkort for arbeidsområdet og legger det til på ønsket resultatkort."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien ble opprettet"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjør automatisk en vurdering av avsluttede samtaler ved å identifisere spesifikke nøkkelord eller uttrykk."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fungerer ikke med taletranskripsjoner."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjønner"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gi meg fem! ✋ Du har maksimert antallet automatiserte evalueringskategorier for ", _interpolate(_named("limit")), ". Du er veiviseren for kategoriseringen! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorigrensen er nådd"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass på at feltene er riktig utfylt"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi vurderingskategorien et navn"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på kategori"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny vurderingskategori"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny vurderingskategori"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjør automatisk en vurdering av avsluttede samtaler ved å identifisere spesifikke nøkkelord eller uttrykk. Fungerer ikke med taletranskripsjoner."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjør automatisk en vurdering av avsluttede samtaler ved å identifisere spesifikke nøkkelord eller uttrykk. Fungerer kun på transkripsjoner."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett poengsum for samtaler automatisk ved å identifisere spesifikke nøkkelord eller uttrykk i både kundekonsulent- og bot-meldinger."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil bruke denne vurderingskategorien, går du til innstillingene for resultatkort for arbeidsområdet og legger den til på et resultatkort."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett poengsum på samtaler automatisk basert på spesifikke nøkkelord eller uttrykk i meldinger."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgjengelig i"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsiktkonfigurering"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne innsikten vises i Spotlight basert på definerte kriterier."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppsett av resultatkort"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne kategorien kan brukes på resultatkort basert på definerte kriterier."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommer snart"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egendefinert"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forhåndsdefinert"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resutlatkort"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i fokus"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tale"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre endringer"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endringer ble lagret"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk på ", "{··}", "-ikonet for å utelate navn, bedriftsdetaljer eller annen sensitiv informasjon under AutoQAs avsluttende kontroller."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til godkjent avslutning"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger godkjent avslutning"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjent avslutning"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til unntak"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger unntak"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntak"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til godkjent hilsen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger godkjent hilsen"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente hilsener"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lim inn ord her atskilt med linjeskift"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trykk på Enter etter hvert ord for å få en ny oppføring"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjeldende språk"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I stedet for «Ta vare på deg selv, hilsen Kari» kan du for eksempel bruke «Ta vare på deg selv, hilsen ", "{··}", "» for å representere et hvilket som helst navn."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plassholder for dynamisk innhold"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntak"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 unntak lagt til"]), _normalize([_interpolate(_named("count")), " unntak lagt til"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Unntaket er allerede lagt til"]), _normalize(["Unntak er allerede er lagt til: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til unntak"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen unntak"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen unntak er lagt til"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntak (skiller mellom store og små bokstaver)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Legg til unntak"]), _normalize(["Legg til 1 unntak"]), _normalize(["Legg til ", _interpolate(_named("count")), " unntak"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter et ord eller en setning"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntaket lagret"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["språk"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle språk"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter språk"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Avslutning allerede lagt til"]), _normalize(["Avslutninger allerede lagt til: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til avslutning"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen godkjente avslutninger"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen godkjente avslutninger"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluttes"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til avslutning"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter en avslutning"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning lagt til"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning lagret"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Skjemaet har dupliserte oppføringer: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hilsenen allerede lagt til"]), _normalize(["Hilsener allerede lagt til: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til hilsen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen godkjente hilsener"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen godkjente hilsener"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsen"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til hilsen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter en hilsen"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsen lagt til"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsenen ble lagret"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til unntak"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Slett unntak"]), _normalize(["Slett unntak"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vil du slette dette unntaket for godt?"]), _normalize(["Vil du slette disse unntakene for godt?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger unntak"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ett eller flere unntak er over ", _interpolate(_list(0)), " tegn langt"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " tegn"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdatert"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdatert av"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabel"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer dine egne avslutninger som stemmer overens med merkevaren og servicestandardene dine. Når AutoQA legges til, vil den bare registrere godkjente avslutninger. Endringene som er foretatt, gjelder framtidige samtaler."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd den øvre grensen på 100 godkjente avslutninger. Hvis du vil legge til flere, er det kanskje på tide å se nærmere på og begrense de eksisterende avslutningene."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grensen for godkjent avslutninger er nådd"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente avslutninger"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord og uttrykk du vil at AutoQA skal ignorere som stave- eller grammatikkfeil. Endringene som er foretatt, gjelder framtidige samtaler."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer listen"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg dine egne hilsener som passer med merkevaren din og servicestandardene dine. Når AutoQA legges til, vil den bare registrere godkjente hilsener. Endringene som er foretatt, gjelder framtidige samtaler."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd toppgrensen på 100 godkjente hilsener. Hvis du vil legge til flere, er det kanskje på tide å se nærmere på og begrense de eksisterende hilsenene."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grensen for godkjent hilsener er nådd"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente hilsener"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nå"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kategori"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne vurderingskategorien for godt?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå kategorilisten av og på"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet kategori vises ikke på noen nye samtaler og filtre. Historiske data om kontrollpaneler og samtaler forblir intakte."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien blir fjernet fra alle resultatkort den er lagt til."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke slette resultatkortkategorien"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkortkategorien ble slettet"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett resultatkortkategori"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA kategorier"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " drevet av KI-modellen blir værende på resultatkortet, men scores ikke lenger automatisk"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-funksjonen slås ikke helt av hvis LLM-integrasjonen slås av. Her er hva som vil skje:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske data"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " blir liggende på kontrollpanelet for AutoQA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underliggende årsaker lagt til manuelt"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " blir værende, men alle LLM-baserte underliggende årsaker blir fjernet"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå av LLM-basert AutoQA"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå av"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA bruker KI og store språkmodeller (LLM-er) for å automatisk score bestemte kategorier. Dataene dine behandles i henhold til en streng databehandlersavtale (DPA) og brukes aldri til å trene modeller."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler om generativ KI"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA bruker Microsoft Azure OpenAI Service for ", _interpolate(_named("automatically_scoring_categories")), ". Dataene dine behandles under en streng DPA kun for å levere AutoQA til deg og ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatisk tildeling av score i noen kategorier"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brukes aldri til å trene OpenAI-modeller"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basert AutoQA er slått på"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basert AutoQA er slått av"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-basert AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisert scoring med KI i AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databehandling av MS Azure-baserte AutoQA-kategorier"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure-samtykke trukket tilbake"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er gitt samtykke til Microsoft Azure. Samtaler får automatiske vurderinger snart."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan fremdeles bruke AutoQA, men her er hva som vil skje"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Å deaktivere MS Azure OpenAI-tjenesten vil ikke slå av AutoQA som en funksjon."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan gjøre dette i kategorivisningen for resultatkort."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategorier drevet av modellen blir værende på resultatkortet, men gis ikke automatisk score."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De underliggende årsakene du har lagt til manuelt, blir værende. Alle modellgenererte underliggende årsaker blir fjernet."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske data blir værende på kontrollpanelet for AutoQA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver databehandling via MS Azure OpenAI-tjeneste"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Større utvalg av AutoQA-kategorier."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruksklar støtte for flere språk."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raskt tilføyelse av nye kategorier."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbedret evalueringsnøyaktighet. GPT-3.5 har kontekstbevissthet på menneskelig nivå."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dra nytte av det nyeste innen KI-teknologi."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her er hva du får"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver databehandling via MS Azure OpenAI-tjeneste"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke er gitt"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke gitt samtykke"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle nye brukere tildeles dette arbeidsområdet"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard arbeidsområde"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 timers tidsangivelse (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 timers tidsangivelse (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsformat"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søn"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedriftsprofilen er oppdatert"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start på uken"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset integrasjon"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgangsnøkkel-ID og hemmelig nøkkel kan du vanligvis finne under Tilgangsadministrasjon > Brukere"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgangsnøkkel-ID for AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt endring av hemmelig tilgangsnøkkel"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre verdien på hemmelig tilgangsnøkkel"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblingen gjør det kun mulig å definere én region, som vanligvis finnes under Sted"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-region"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en hemmelig nøkkel"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemmelig tilgangsnøkkel for AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du finner all relevant informasjon i Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnes vanligvis under innstillingene for forekomster i Amazon Connect og på siden Datalagring"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagringssted for samtaleopptak"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en feil når Amazon Connect ble lagt til. Kontroller at alle feltene er riktig utfylt, og prøv på nytt."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagringsstedet må ha formatet bøtte/mappe, atskilt med en skråstrek."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil legge til en tilkobling, kontakter du ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundestøtte"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Zendesk-sakene dine blir synkronisert automatisk. Du kan velge å utelate utvalgte saker."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia/Stillehavsregionen (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia/Stillehavsregionen (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia/Stillehavsregionen (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia/Stillehavsregionen (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia/Stillehavsregionen (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (sentralt)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverregion"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midtøsten (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg serverregion"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sør-Amerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østre USA (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østre USA 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestre USA (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koblingen er allerede lagt til"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start forbindelse"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser kun samtaler som"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanal én, kanal to"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkobling"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Den unike ID-en for denne tilkoblingen er ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 måned"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 år"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 måneder"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 måneder"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive samtaler blir slettet etter den valgte oppbevaringsperioden. Innsendte evalueringer forblir synlige i Aktivitets- og Kontrollpanel-visningene."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppbevaringsperiode"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg oppbevaringsperiode"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær oppmerksom på at denne handlingen ikke kan angres."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg forstår at dette fjerner all kundeservice-relatert data for denne tilkoblingen fra Zendesk QA, inkludert samtaler, felt og tagger."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsendte evalueringer blir ikke påvirket og vil fortsatt være synlige i bestemte visninger."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gruppe én, gruppe to"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta med"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiv utvikling"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne integrasjonen er fremdeles i ", _interpolate(_named("activeDevelopment")), ". Noen detaljer kan mangle (som vedlegg eller egendefinerte felt)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["postkasse én, postkasse to"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tagg én, tagg to"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token hemmelig"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn «Navn på saksfelt» på Freshdesk under Innstillinger → Saksfelter"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn «Custom Field Names» på Help Scout under Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn «Klass-samtalenavn» på Kustomer under Innstillinger → Klasses → Samtale"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn «Egendefinert feltnavn» på Live Agent under Innstillinger → Egendefinerte felter"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn «Egendefinert feltnavn» på Wix under Innstillinger → Egendefinerte felter"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill felt-ID-ene fra hverandre med komma. Du finner disse ID-ene i Zendesk under Admin → Saksfelter."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til noen av kundeservicebrukerne for å gå gjennom de første samtalene. Ta det rolig, vi varsler ikke dem med mindre du inviterer dem til å bli medlemmer av arbeidsområdet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrert"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til medlemmer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi vil nå begynne å hente samtalene dine."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domene: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kundeservice-ID: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke godkjenne tilkoblingen din"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt er klart. Dette er den nye tilkoblingen din."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noen tilkoblinger har ikke blitt oppdatert eller er blitt inaktive. Prøv å oppdatere disse tilkoblingene eller fjerne dem fra listen din."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-klient-ID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API kundehemmelighet"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opprett et nytt API-token fra Innstillinger → Konto → API og SDK-er. Omdirigerings-URL-en som skal brukes, er ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto-ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kundeservice for å komme i gang"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis porttelefonkontoen din er konfigurert for å betjenes fra EU eller Australia, må du oppdatere denne."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-postadresse du bruker til å logge på ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul sluttbrukerens navn, e-postadresse og telefonnummer. Banklegitimasjonen er allerede skjult for alle kundeservicetilkoblinger."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen integrasjoner ennå. Legg til en nå."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du trenger disse rollene:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett forbindelse"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en ny forbindelse"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner avmerkingen av dette, kan du manuelt legge til denne tilkoblingen bare til bestemte arbeidsområder"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til alle eksisterende arbeidsområder"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbeidsområder"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-nøkkel"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-nøkkelvhemmelig"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaler"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tilkobling ", _interpolate(_list(0)), " er slettet"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblingsnavn"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet den"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan hente API-tokenet ditt nedenfor."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-terskel"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett tilkobling ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv «slett tilkobling» for å fjerne denne tilkoblingen"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett tilkobling"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer og overvåk de tilkoblede datakildene."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger tilkobling"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelat"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill feltnavnene fra hverandre med komma"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke oppdatere"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi et unikt navn til integrasjonen"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul avanserte alternativer"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul sensitive datafelter"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppbevar aldri nettadresser for vedlegg"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer vedlegg"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleinnhold skal aldri lagres (du kan hente det inn senere ved behov fra kundeservice)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer samtaleinnholdet"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleemne skal aldri lagres (du kan hente det inn senere ved behov fra kundeservice)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer emnelinjen"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ditt ", _interpolate(_list(0)), " underdomene"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-region"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match team-ID for innboks"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerisk ID for porttelefonteamet ditt"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pågår..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postkasser"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater tilkoblingen manuelt"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masker kundedata"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbeidsområder"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følsomt feltnavn"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID til sensitivt felt"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornye forbindelsen"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornye forbindelsen"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til Salesforce-sandkasse"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Setter opp en forbindelse med ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis avanserte alternativer"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bare hent inn samtaler med en poengsum lavere enn eller lik den valgte terskelen"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk en CSAT-terskel"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser bare samtaler som..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få de nyeste samtalene"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagger"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblinger"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater tilkoblingen"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblingen er oppdatert"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukernavn"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kundeservice"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For å avbryte abonnementet ditt og slette kontoen din, kontakt ", _interpolate(_named("link")), "- ."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundestøtte"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett konto"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slette en sak i Zendesk Support (eller en annen kundestøtte) påvirker ikke tilknyttede samtalegjennomganger. Du kan fremdeles vise og administrere dem."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du sletter en sak i Zendesk QA , slettes også tilknyttede samtalegjennomganger.", _interpolate(_named("line_break")), "Å slette en sak i Zendesk Support (eller en annen kundeservice) påvirker ikke tilknyttede samtalegjennomganger. Du kan fremdeles vise og administrere dem."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer vurderinger"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletting av vurderinger er permanent. Det påvirker også data på kontrollpaneler."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett alle vurderinger"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett alle"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vurderinger ble slettet"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett alle"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når det er vurderinger knyttet til slettede saker, ser du dem her."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen vurderinger enda"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter saks-ID"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saks-ID: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettede samtaler"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Standard arbeidsområder kan ikke slettes. Hvis du vil slette dette arbeidsområdet, velger du et annet standard arbeidsområde i ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinnstillinger"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan du ikke angre."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjerne og slette alle data"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg forstår at dette vil ", _interpolate(_named("removeAllData")), " relatert til dette arbeidsområdet, deriblant brukere av arbeidsområdet og tilkoblinger."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett arbeidsområde"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv «slett arbeidsområde» for å slette dette arbeidsområdet"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett arbeidsområde ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slett arbeidsområde"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær sikker på hva du gjør, eller ta kontakt med en profesjonell på forhånd."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg om standard person som evalueres skal velges basert på den vanligste kundekonsulenten eller på aktuelle saksansvarlig."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mest aktive kundekonsulenten"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard person som evalueres"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La folk evaluere sine egne samtaler, og la kundekonsulenter foreta evalueringer"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområdet har blitt slettet"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområdet er oppdatert"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etabler nøytral vurdering ved å skjule evalueringer som tidligere er gitt av andre kvalitetssjekkere"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektiv vurdering"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farge"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan du ikke angre."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær oppmerksom på at dette vil fjerne og slette all data relatert til dette arbeidsområdet, inkludert arbeidsområdebrukere og tilkoblinger."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett arbeidsområde"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletter arbeidsområde ..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk lenke"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk lenke"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke deaktivere gjeldende påloggingsmetode"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjenningsmetodene er oppdatert"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillat visse typer godkjenningsmetoder for alle brukere."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjenninger"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTO"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere og arbeidsområder"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett og administrer arbeidsområder, og inviter eller koble til brukere."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bli på siden"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forlat siden"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du forlate denne siden og miste alle endringene?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblinger"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjenninger"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ikke ut til at det er noen samtaler å importere. Begynn med å ha noen samtaler med kundeservice, kom deretter hit for å analysere dem."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen samtaler"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke slette denne systemkategorien"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kategori"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletting av kategorier er permanent. Det vil slette alle kategoridata fra rapporteringen."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil gjenopprette denne kategorien, må du opprette den på nytt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kategori"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter kategori"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiske kategorier fyller automatisk resultatkortet med foreslåtte vurderinger for å spare tid ved gjennomgang. Manuelle kategorier fylles ut av kvalitetssjekkere fra bunnen av."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist oppdatert"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelt"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettskriving og grammatikk"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsen"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forståelse"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesevennlighet"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbudt løsning"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kategorier"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen underliggende årsaker"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen resultatkort"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv et annet søk eller endre filtre"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kategorier"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultatkort"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultatkort"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultatkort"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle resultatkort"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultatkort"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuser"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuser"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuser"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle statuser"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuser"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle typer"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbeidsområder"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbeidsområder"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen arbeidsområder"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbeidsområder"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbeidsområder"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbeidsområder"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vekt av ", _interpolate(_named("weight")), " bidrar ", _interpolate(_named("percentage")), " % til sammenlagt score"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett resultatkort"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletting av resultatkort er permanent. Det vil sletter alle resultatkortdata fra rapporteringen."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For å gjenopprette dette resultatkortet, må du opprette det på nytt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett resultatkort"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist oppdatert"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladd"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underliggende årsaker"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vurderingsskalaen er oppdatert. ", _interpolate(_named("buttonStart")), "Start evalueringen", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA angir en binær skala som standard, men du kan også velge en mer detaljert skala:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil se forskjellige alternativer for vurderingsskala, konfigurert etter kategori, går du til ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlevelse/tone"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkunnskap"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved å oppdatere vurderingsskalaen:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den tidligere vurderingsskalaen og de tilhørende kategoriene blir arkivert (ikke slettet)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noen kontrollpaneldata blir påvirket siden den gamle og den nye skalaen din kanskje ikke er sammenlignbare."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klone mitt eksisterende konfigurasjon for vurderingskategorier til den nye skalaen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil oppdatere vurderingsskalaen?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan endre visningsformatet for resultatkortet (tall eller emojier) og gjøre kategorier obligatoriske eller valgfrie."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk emojier i stedet for tall"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La folk hoppe over kategorier"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løsning"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskalatype"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kategoriene for 1 arbeidsområde er blitt klonet"]), _normalize(["Kategorier for ", _interpolate(_named("workspacesAmount")), " arbeidsområder er blitt klonet"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger for arbeidsområde"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["hvis ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få automatisk fram samtaler der kundekonsulenten ikke oppfordrer kunden til å forvente taushet i samtalen."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen har øyeblikk med dødtid som varer lenger enn den valgte terskelen."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer maksimal terskel for dødtid i samtaler"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dødtid"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet innsikt vises ikke på noen nye samtaler og filtre. Historiske data om kontrollpaneler og samtaler forblir intakte."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke slette Spotlightinnsikt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-innsikt ble slettet"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett Spotlight-innsikt"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis samtaler som inneholder bestemte ord eller uttrykk i transkripsjoner og meldinger."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiser automatisk samtaler som mangler den obligatoriske informasjonserklæringen, som «Denne samtalen blir tatt opp» og lignende."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tar sikte på å identifisere en erklæring om opptak i samtalen ved hjelp av en Large Language Model (LLM) som dynamisk forstår kontekst, og eliminerer behovet for forhåndsinnstilte setninger."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opptakserklæring mangler"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få automatisk fram samtaler der kundekonsulenten ikke oppfordrer kunden til å forvente taushet i samtalen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dødtid"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesifiser øyeblikk der det ikke skjer noe samtale"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trenger du å foreta endringer i abonnementet ditt? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), "), så hjelper vi gjerne."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser automatisk 100 % av samtalene på tvers av kundekonsulenter, BPO-er, kanaler og språk. KI registrerer problemer, kunnskapsmangler og opplæringsmuligheter for å forbedre tjenesten."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100 % dekning"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding og opplæring av kundekonsulenter"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-innsikt i fokus"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avansert rapportering og kontrollpaneler"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunktanalyse og filtrering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behovsbetingede oversettelser"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomgang av tildelinger og mål"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæring og quizer"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk kvalitetskontroll (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt på Professional, pluss"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avansert (tidligere)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks for umiddelbar varsling om kritiske feil"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasjon av datavarehus"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasjon med Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset integrasjonsutvikling"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedikert kundesuksessansvarlig"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte tilgang til produkt- og teknikerteamene våre gjennom Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt i Growth Suite, pluss"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA for 100 % dekning"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-basert samtaleinnsikt for å gi mening ut av data"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-utvidet helpdesk-databerikelse og filtrering"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fokus: Automatisert gjenkjenning for samhandlinger som må evalueres"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunktanalyse og filtrering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-basert ytelsesrapportering og innsikt"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behovsbetinget samtaleoversettelse"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer tidssporing"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse over beståttandel"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt i AI Suite, pluss"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementeringspakke for storbedrift med integrering av Salesforce og Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset integrasjonsutvikling"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasjon av datavarehus"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks for umiddelbar varsling om kritiske feil"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedikert kundesuksessansvarlig"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte tilgang til produkt- og teknikerteamene våre"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidlig tilgang til og innflytelse på veikartet vårt"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiske evalueringstildelinger og målsetting"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målrettet opplæring og analyser"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer for administrasjon av læring"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrering av brukerroller og -tillatelser"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjerming av sensitive data"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanserte filtreringsalternativer og samtalesøk"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasningsbare resultatkort med flere evalueringer, justerbar vekt og alternativer for automatisk feil"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettleserutvidelse for å evaluere samtaler hvor som helst"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse av underliggende årsak"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ytelsesrapportering for å sammenligne kundekonsulenter, team, BPOer"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-undersøkelse og analyse av kundetilbakemeldinger"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funksjoner"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt i Starter, pluss"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (tidligere)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere vurderingsskalaer per samtale"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrasjon"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-basert interaktiv samtaleinnsikt"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer tildelingene"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festet for opplæring"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplæringsøkt"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synspunktanalyse og filtrering"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrering basert på samtalens kompleksitet"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleinnsikt"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingede resultatkort"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse av underliggende årsak"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avansert KI-basert CSAT-analyse"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskalibrering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettleserutvidelse for å gå gjennom samtalen hvor som helst"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett i fokus for å finne samtaler som må evalueres"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behovsbetinget samtaleoversettelse"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer tidssporing"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset abonnement"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (tidligere)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubegrensede samtaleevalueriger"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-eksport"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fagfelle og egenvurdering"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort med justerbar vekt og alternativer for automatisk feil"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robuste filtreringsalternativer"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daglige, ukentlige eller månedlige evalueringsmål"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-undersøkelser og grunnleggende analyser"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollpaneler"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter for å sammenligne kundekonsulenter, team, BPO-er"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrering av brukerroller og -rettigheter"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funksjoner"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg vurderer å gjøre en endring i mitt Zendesk QA-abonnement.\n\nUthev eller angi ett alternativ og angi antallet lisenser du vil endre:\nJeg vil [legge til X-lisenser] [fjerne X-lisenser] [oppgradere til abonnementet for Zendesk QA]\n\nOppgi bedriftsnavnet ditt og navnet som er forbundet med Zendesk QA-kontoen, samt all annen informasjon vi burde vite for å behandle denne endringen."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endring i Zendesk QA-abonnement"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har tenkt å kjøpe et Zendesk QA-abonnement.\n\nOppgi bedriftsnavnet ditt og navnet som er knyttet til Zendesk QA-kontoen, samt all annen informasjon vi burde vite for å behandle denne forespørselen:\n\nDel eventuelle spesifikke spørsmål du vil besvare før du kjøper:\n\nTakk!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjøp av Zendesk QA-abonnement"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnementet ditt utløper den ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " igjen)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøveperioden din utløper den ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " igjen)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 dag"]), _normalize([_interpolate(_named("n")), " dager"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("dayOfMonth")), ". i hver måned."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("dayOfMonth")), ". i hver måned."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("dayOfMonth")), ". i hver måned."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("dayOfMonth")), ". hver måned."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakturert til ", _interpolate(_named("card")), " (utløper ", _interpolate(_named("expires")), ") den ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["som slutter på"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Utløpt"]), _normalize(["Utløper om 1 dag"]), _normalize(["Utløper om ", _interpolate(_named("expiresIn")), " dager"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startprisen inkluderer ", _interpolate(_named("n")), " brukerlisenser"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["starter den ", _interpolate(_named("sum")), "/måned"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måned"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per bruker / måned"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per år"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per måned"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kvartal"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjerne planlagt avbestilling"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil fortsette å bruke Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnementet ditt ble sagt opp den ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnementet ditt skal etter planen utløpe den ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " -dagers prøveperiode uten ekstra kostnad!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brukt"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("n")), " brukere. Lisensantallet kan ikke være mindre enn det."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnementet gjenopptas den ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["år"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditt nåværende Zendesk QA-abonnement (", _interpolate(_named("plan")), ") omfatter ikke KI-baserte funksjoner. Hvis du vil ta i bruk alle funksjonene med talefunksjonalitet, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta kontakt med oss for å avbryte abonnementet ditt ", _interpolate(_named("link")), " eller chat med oss."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan for kvalitetsstyring"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetsstyring"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementet er oppdatert"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til kredittkort"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle planer"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturering"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se faktureringsinformasjon"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureringsintervall"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt abonnementet"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementet ditt blir avbrutt når faktureringsperioden er over."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta kontakt med oss"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta kontakt med oss for å redigere"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nåværende plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinerte priser"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedgrader"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig (Spar 15 %)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal årlig"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger abonnementet"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utforsk planer"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis prøveperiode"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturert manuelt"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan evaluere og bekrefte det endelige bestillingssammendraget i neste trinn."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mest populær"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke noen aktiv betalingsmetode."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I prøveperiode"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenoppta det gjeldende abonnementet for å endre abonnementet"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett abonnementet på pause"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvartalsvis"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver abonnementet på nytt"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenoppta abonnementet"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisenser"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start prøveperioden nå"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbrutt"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornyes ikke"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midlertidig stanset"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsstatus"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøveperioden"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader abonnement"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinert"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk ett minutt på å gi tilbakemelding om kundestøtteopplevelsen din. På forhånd takk – ", "{", "{", "agentName", "}", "}", " setter pris på tilbakemeldingene dine!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hei, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s CSAT-undersøkelse"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan vil du vurdere kundestøtten du fikk fra ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det setter vi veldig pris på. Vi vil bruke tilbakemeldingene dine til å gjøre ", _interpolate(_list(0)), " bedre for alle."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om undersøkelser"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg undersøkelsestype"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan endres senere."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål og forbedre kundetilfredsheten med automatiserte CSAT-undersøkelser på flere språk."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble ikke opprettet noen undersøkelser"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett undersøkelse"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på undersøkelse"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsen er opprettet"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøv å endre nøkkelordet eller ", _interpolate(_named("contact_us")), " hvis om du vil at det skal stå oppført her"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi oss beskjed"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke noe språk"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg ett eller flere språk fra listen nedenfor. ", _interpolate(_named("contact_us")), " hvis du ikke ser et språk du vil bruke oppført her."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til språk"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi oss beskjed"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til språk"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sist publisert ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sist lagret ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " spørsmål"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduksjonsspørsmål"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venstre skalaetikett"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emne i e-post"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsaker"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høyre skalaetikett"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk!"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis en undersøkelse mangler oversettelser, vises basisspråkets tekster i stedet"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk oversettelser"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversettelser mangler"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sørg for å evaluere alle oversettelsene dine før du tar dem i bruk. Gjør endringer i den nedlastede filen, lagre den og last opp på nytt om nødvendig. Hvis et token ", _interpolate(_named("token_example")), " vises ikke som en tag, har den enten feil format eller er i et felt som ikke støtter token."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer oversettelsene"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversatt"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppsummerer samtalen og viser forslag til kommentarer."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-assistent for Zendesk QA (på engelsk)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan redigere typer etter at de er opprettet"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne typen finnes allerede. Velg et annet navn."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre den egendefinerte typen som et valgbart alternativ i rullegardinmenyen Undersøkelsestype, og grupper alle opprettede undersøkelser i kontrollpanelet."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn ord og trykk på Enter for å lagre"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger egendefinerte typer"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typenavn kan ikke stå tomt"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer type"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egendefinert type ble ikke lagret. Trykk på Enter for å lagre den."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsenderens e‑postadresse"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsenderens navn"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I hvor stor grad er du enig i påstanden: «", _interpolate(_named("agentName")), " gjorde det enkelt å håndtere problemet mitt”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvor enkelt var det å få hjelp fra ", _interpolate(_named("brandName")), " så langt?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt, hvor enkelt var det å løse problemet ditt i dag?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor enkelt var det å samhandle med teamet vårt?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettadresse for retningslinjer for personvern (valgfritt)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettadresse for bruksvilkår (valgfritt)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvordan vil du vurdere kundestøtten du fikk fra ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan var servicen vår for denne forespørselen?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor fornøyd var du med kundestøtterepresentanten?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvor fornøyd er du med servicen du fikk fra ", _interpolate(_named("agentName")), " i dag?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset spørsmål"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definer ditt egne tilpassede ", _interpolate(_named("type")), " spørsmål..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn egendefinert spørsmål"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpass nettadresser"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett opp e-postmeldingen din"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du redigerer navnet på en egendefinert spørsmålstype, blir det oppdatert i alle undersøkelser som bruker den, uten at det påvirker dataene som samles inn."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger egendefinerte typer"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emne i e-post"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduksjonsspørsmål"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " spørsmål"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett ny egendefinert type"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innhold"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tilpasningstoken"]), _normalize(["Tilpasningstokener"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dra og slipp personaliseringstokenet i feltet nedenfor."]), _normalize(["Dra personaliseringstokenene og slipp dem i feltene nedenfor."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtale ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For øyeblikket er det kun mulig å sende ut CSAT-undersøkelser via e-post. Vi jobber med å gi deg flere muligheter, for eksempel CSAT-undersøkelser for kundestøtte, SMS-undersøkelser og annet."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synkroniser eller forny tilkoblingen"]), _normalize(["Synkroniser eller forny tilkoblingene"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser eller forny tilkoblingen ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tilkoblingen er ikke synkronisert på riktig måte"]), _normalize(["Tilkoblingene er ikke synkronisert på riktig måte."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjern avmerkingen i den relevante avmerkingsboksen ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få godkjenning nå ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til minst én utsendingsregel for undersøkelser for å aktivere forbindelsen"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne forbindelsen er deaktivert nå. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne koblingen skjuler sensitive kundedata. Hvis du vil aktivere utsending av undersøkelser,\n", _interpolate(_named("link")), " i innstillingene."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tilkoblingen krever flere tillatelser for å kunne sende ut undersøkelser.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få godkjenning nå"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA krever flere tillatelser for porttelefon."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " og ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 time"]), _normalize([_interpolate(_named("n")), " timer"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minutter"]), _normalize(["1 minutt"]), _normalize([_interpolate(_named("n")), " minutter"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["time"]), _normalize(["timer"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " for å konfigurere senderegler for undersøkelser."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" dag"]), _normalize(["dager"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenker til undersøkelser utløper"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenkene utløper om"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unngå svar på undersøkelser hvis det har gått for lang tid"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " undersøkelse ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill taggene fra hverandre med komma, eller trykk på Enter etter at du har skrevet. Pass på at hver enkelt er oppført presis som i porttelefonen."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi betingelsene basert på hvilken undersøkelsen som sendes ut."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler for utsending av undersøkelse"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler for utsending av undersøkelsesopppfølging"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En undersøkelse ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " finnes allerede.\nLøs konflikten ved å endre reglene, enten her eller i ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har allerede konfigurert en forbindelse med matchingsregler for ", _interpolate(_named("name")), " undersøkelse. Løs konflikten ved å endre reglene, enten her eller i ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en annen regel"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til merkevare"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg betingelse"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreft"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter en verdi"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matcher de samme reglene"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en verdi"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post sendes etter"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postoppfølging ble sendt ut i"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innebygd snutt og e-postoppfølging"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier kode"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg ved Zendesk QA-undersøkelser til e-posten din ved å legge til koden i Zendesk-oppsettet."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installasjonsveiledning"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning av snutt"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snuttkode ble kopiert til utklippstavlen"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke kopiere snutt"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innebygd snutt"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne undersøkelsen har ulagrede endringer. Lagre den på nytt for å være sikker på at snuttkoden gjenspeiler de siste oppdateringene."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en tilkobling"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilkoblinger er lagt til ennå"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hent e-post, live chat, telefonsamtaler og annet til Zendesk QA med tilkoblingene dine"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om levering av undersøkelser"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler for utsending av undersøkelse"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler for utsending av undersøkelsesopppfølging"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til merkeregel"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til regel"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få godkjenning"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + e-postoppfølging"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til porttelefonkontoen din til CSAT-undersøkelsen Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levere via"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem som får undersøkelsen og når"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger regler"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivert"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med undersøkelser i appen kan kundene dine fullføre en undersøkelse uten å måtte forlate chatten."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsregler per tilkobling"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En undersøkelse ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " finnes allerede.\nLøs konflikten ved å deaktivere koblingen eller endre reglene, enten her eller i ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveranse"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi betingelsene basert på hvilken undersøkelsen som sendes ut"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opprettet av ", _interpolate(_named("name")), " den ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fjern ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner dette språket, betyr det at det ikke blir synlig for noen respondenter lenger, og all oversatt tekst blir slettet."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern språk"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan du ikke angre."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du deaktivere språket ved å skjule det for undersøkelsen. Dette gjør at du kan holde alle oversettelsene dine på plass uten å vise dem til respondentene."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sist redigert av ", _interpolate(_named("name")), " den ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om undersøkelsesspråk"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Før du legger til andre språk, må du kontrollere at du har fullført endringer i trinnene Styling og Innhold. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til språk"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversatte felter"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig for respondenter"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned oversettelsesfil"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern språk"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" laste ned en oversettelsesfil"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer oversettelsene"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oversettelsen er fullført. ", _interpolate(_named("review_link")), " eller ", _interpolate(_named("download_link")), " for å foreta endringer."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" laste ned en oversettelsesfil"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer hva som mangler"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noe av innholdet er ennå ikke oversatt, og vil bli erstattet med engelsk. ", _interpolate(_named("review_link")), " eller ", _interpolate(_named("download_link")), " for å legge til manglende oversettelser."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre undersøkelsen"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Undersøkelsen inneholder ulagrede endringer. ", _interpolate(_named("save")), " for å laste ned oversettelsesfilen med de siste endringene."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp oversettelsesfil"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg foretrukket vurderingsskala"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gir kundene en rullegardinmeny for å velge en årsak for vurderingen"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojier"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst i venstre skala (valgfritt)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til årsak"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsaken kan ikke stå tom"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne årsaken finnes allerede"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis rullegardinmenyen for å se årsakene til poengsummen din"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du sletter en årsak og legger den til på nytt med samme navn, eller gir et nytt navn til en årsak, opprettes det alltid en ny oppføring. Som et resultat av dette påvirker det dataene som vises på kontrollpanelet ditt."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett grunn"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En slettet årsak blir liggende i rapporteringen på kontrollpanelet, men vil ikke være synlig for nye undersøkelsesrespondenter. Dette gjelder også for de tilhørende oversettelsene."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett en årsak"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slett en årsak \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst i høyre skala (valgfritt)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På en 5-punkts skala regnes 1 og 2 som negativ vurdering. 3 er nøytral; 4-5 er positive"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til skalatekst"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " skala"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskala"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importerer samtalene dine. Denne prosessen kan ta opptil 24 timer."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil bli brukt som et tilpasningstoken under hele undersøkelsen."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkevarenavn"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knapper og lenker"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpass utseendet på undersøkelsen din"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logobakgrunn"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, PNG og GIF-formatene støttes"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styling"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg av med stil"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn en melding folk vil se etter å ha sendt inn tilbakemelding"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk-melding"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk!"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil heksadesimal fargekode"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil e-postadresse"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet kan ikke stå tomt"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feil URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke synlig:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettelsesdato"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendte undersøkelser"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelser sendt i løpet av de siste 30 dagene"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsen er slettet"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsen er blitt duplisert"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post med forhåndsvisning er sendt"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsen er publisert"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsen er oppdatert"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelsen er blitt satt på pause"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag duplikat"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stans midlertidig"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stans undersøkelsen midlertidig"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn e-postadressen hvor forhåndsvisningen av undersøkelsen skal sendes"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["din", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg språk for forhåndsvisningen"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send en e-post med forhåndsvisning"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send en eksempelundersøkelse til e-posten din. Vurderingene fra forhåndsvisningen av undersøkelsen blir ikke lagret."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Forhåndsvis ", _interpolate(_named("type")), " undersøkelse"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning av e-postundersøkelse på nett"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiser"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenoppta"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre som kladd"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkevarenavn"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkevarespesifikt navn kan legges til i Styling-trinnet"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenavn"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-representant"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladd"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midlertidig stanset"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s CSAT-undersøkelse"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble foretatt endringer som ikke er lagret"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater og gjenoppta undersøkelsen"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du ikke ser denne e-posten riktig ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg var ikke fornøyd med kundestøtten jeg fikk, fordi..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levert av Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg årsak"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleoppsummering"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksempel: Kunden hadde problemer med å oppdatere betalingsmåten. Kundekonsulenten delte en artikkel som kan være til hjelp. Det virket."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levert av Zendesk QA KI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruksvilkår"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tusen takk for tilbakemeldingen!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva er den viktigste årsaken til poengsummen din?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortell oss hvorfor du ga denne vurderingen..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høy innsats"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav innsats"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klikk her for å åpne undersøkelsen"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det egendefinerte spørsmålet vises her"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du ga din ", _interpolate(_list(0)), " opplevelse en poengsum på"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send inn tilbakemelding"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk!"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld deg av"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldig misfornøyd"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldig fornøyd"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær oppmerksom på at gjeldende undersøkelsesstatus og leveringsregler ikke blir inkludert i undersøkelsesduplikatet"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dupliser undersøkelsen “", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruker den innebygde e-postsnutten, må du huske å fjerne den fra Zendesk før du setter undersøkelsen på pause"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det samles ikke inn svar når undersøkelsen er satt på pause."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stans undersøkelsen midlertidig"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass på at du fjerner snutten fra Zendesk før du setter undersøkelsen på pause."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett undersøkelsen"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruker den innebygde e-postsnutten, må du passe på å fjerne den fra Zendesk før du sletter undersøkelsen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handlingen sletter undersøkelsen for godt. Brukere som mottok undersøkelsen, kan fremdeles fylle den ut, og all data knyttet til undersøkelsen blir værende i Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett undersøkelsen ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass på at du fjerner snutten fra Zendesk før du sletter undersøkelsen."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endringene du har valgt, blir sett av nye respondenter."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentliggjør undersøkelse"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass på at du kopierer den nyeste versjonen av den innebygde kodesnutten etter at du har publisert undersøkelsen."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruker den innebygde e-postsnutten, må du passe på å kopiere den til Zendesk etter at du har gjenopptatt undersøkelsen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett å sende ut undersøkelsen og samle inn svar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater og gjenoppta undersøkelsen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass på at du legger til den innebygde snutten i Zendesk etter at du har gjenopptatt undersøkelsen."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater undersøkelsen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass på at du kopierer den nyeste versjonen av den innebygde snutten etter at du har oppdatert undersøkelsen."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil slette denne undersøkelsen? Dette kan du ikke angre."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette undersøkelsen?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøveperioden din har utløpt, men du kan fortsette å benytte deg av Zendesk QA ved å oppgradere til en fullverdig konto."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøveperioden din har utløpt, men du kan fortsette å benytte deg av Zendesk QA ved å oppgradere til en fullverdig konto. Snakk med din lokale administrator for å konfigurere dette."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøveperioden er avsluttet"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgrader nå"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den gratis prøveperioden er avsluttet."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil fortsette å bruke fullversjonen av Zendesk QA, må du legge inn betalingsopplysningene."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoadministrator må legge inn betalingsopplysningene for å fortsette å bruke fullversjonen av Zendesk QA."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer betaling"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en bruker"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opprettet den ", _interpolate(_named("date")), " av ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tildeling"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ut til at du fremdeles jobber med dette. Du mister all informasjonen du har lagt til så langt hvis du avslutter uten å lagre endringer."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tildelingen gjentas hver andre ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tildelingen gjentas hver ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alle personer som evalueres som er dekket i én syklus"]), _normalize(["Alle personer som evalueres som er dekket i ", _interpolate(_named("nr")), " sykluser"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " samtaler"]), _normalize([_interpolate(_named("n")), " samtale"]), _normalize([_interpolate(_named("n")), " samtaler"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["av ", _interpolate(_named("nr")), " kundekonsulent"]), _normalize(["av ", _interpolate(_named("nr")), " kundekonsulent"]), _normalize(["av ", _interpolate(_named("nr")), " kundekonsulenter"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["deltaker"]), _normalize(["deltakere"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " samtaler per syklus"]), _normalize([_interpolate(_named("n")), " samtale per syklus"]), _normalize([_interpolate(_named("n")), " samtaler per syklus"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " per person som evalueres i en rad"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["person som evalueres"]), _normalize(["personer som evalueres"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["kvalitetssjekker"]), _normalize(["kvalitetssjekkere"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personer som evalueres"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere av arbeidsområdet"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere av arbeidsområdet unntatt kundekonsulenter"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Syklus ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke gjentakende"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tildeling"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluerer"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om tildelinger"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett tildeling"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handlingen sletter for godt tildelingen og fjerner dataene fra kontrollpanelet. Alle evalueringer knyttet til den blir værende i Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett tildelingen ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingen er slettet"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingen er duplisert"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tildelinger er lagt til ennå"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke opprette tildelingen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på tildeling"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syklusen oppdateres og en ny oppgaveliste opprettes. Evalueringer som allerede er gitt, teller fremdeles med hensyn til målet."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiddelbart"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiser"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ved starten av neste syklus den ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når skal endringene publiseres?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endringer som er gjort i denne tildelingen, blir publisert sammen med denne tildelingen"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du angi tildelingen som aktiv?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjentakende på tildelingens startdag"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første fredag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre fredag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje fredag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde fredag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste fredag i hver måned"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første mandag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre mandag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje mandag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde mandag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste mandag i hver måned"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første lørdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre lørdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje lørdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde lørdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste lørdag i hver måned"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første søndag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre søndag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje søndag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde søndag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste søndag i hver måned"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første torsdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre torsdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje torsdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde torsdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste torsdag i hver måned"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første tirsdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre tirsdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje tirsdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde tirsdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste tirsdag i hver måned"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første onsdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre onsdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje onsdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde onsdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste onsdag i hver måned"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset gjentakelse"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["den 1. i hver måned"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjentakelser"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukedag"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutt"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen resultater"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidssone"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syklusinnstillinger"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på tildeling"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunnleggende informasjon"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På starten av hver tildelingssyklus genereres en forhåndsbestemt liste med samtaler for evaluering."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanserte innstillinger"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innhent nye samtaler automatisk når vurderingsmålet ikke er nådd"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk innhenting er ikke mulig med prosentandel (%) vurderingsmål"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Må være et helt tall, ikke en desimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillat erstatning av samtaler (kvalitetssjekker og høyere)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstatte samtale"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La kvalitetssjekkere få tildelt sine egne samtaler for vurdering"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evalueringer"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumsantallet må være minst 1 evaluering"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi minimum antall evalueringer for hver kvalitetssjekker"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samtaler"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prosent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evalueringer"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evalueringer totalt"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver kvalitetssjekker må foreta et bestemt antall evalueringer"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere må evaluere et bestemt antall samtaler i fellesskap"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumsantallet må være minst 1 evaluering"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsmålet må være mellom 1 – 100 %"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringsmål"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver person som evalueres må få minimum antall av samtalene sine evaluert"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere kan velge samtaler fra den delte gruppen"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere tildeles samtaler fra den delte gruppen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingsmetode"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle boter"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbeidsområdedeltakere"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere av arbeidsområdet"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til personer som evalueres"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem blir evaluert?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg personer som evalueres"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg hvem som skal evalueres"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntak er bare tillatt når alle arbeidsområdebrukere eller en gruppe er valgt"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere og personer som evalueres"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gjeldende: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard arbeidsområde"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken type brukere skal velges som personer som evalueres?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mest aktive kundekonsulenten"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltaker"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelat personer som evalueres (valgfritt)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg brukere som skal utelates"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelat kvalitetssjekkere (valgfritt)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg brukere som skal utelates"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere av arbeidsområdet unntatt kundekonsulenter"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til kvalitetssjekkere"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem skal foreta evalueringene?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kvalitetssjekkere"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurdering er ikke tillatt i dette arbeidsområdet"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillat egenvurderinger"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger er ikke tillatt i dette arbeidsområdet."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Matchende samtale i dag"]), _normalize(["Matchende samtaler i dag"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viser som om tildelingen startet i dag."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremtidige tall kan variere."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke valgt noen person som evalueres"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke valgt noen kvalitetssjekkere"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Person som evalueres"]), _normalize(["Personer som evalueres"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kvalitetssjekker"]), _normalize(["Kvalitetssjekkere"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk innhenting: Tillatt hvis det ikke er nok samtaler"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på fredager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på mandager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på lørdager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på søndager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på torsdager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på tirsdager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på onsdager fra ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Daglig fra ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starter ", _interpolate(_named("startDateTime")), " og slutter ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " datobetingelser"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " samtale- og helpdeskbetingelser"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ingen samtale og ", _interpolate(_named("count")), " kundeservicebetingelse"]), _normalize(["Ingen samtale og ", _interpolate(_named("count")), " kundeservicebetingelser"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " samtale og ingen kundeservicebetingelse"]), _normalize([_interpolate(_named("count")), " samtaler og ingen kundeservicebetingelse"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tilnærming: Kvalitetssjekkere velger samtaler"]), _normalize(["Tilnærming: Kvalitetssjekkere tildeles samtaler"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rett opp feilene som er uthevet i sammendraget for å fortsette"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: Kvalitetssjekkere må evaluere alle samtaler"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mål: Lesere må evaluere én samtale"]), _normalize(["Mål: Leserne må evaluere ", _interpolate(_named("count")), " samtaler"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: Kvalitetssjekkere må evaluere ", _interpolate(_named("count")), "% samtaler"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: Det er ikke satt noe mål for evalueringen"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: Hver person som evalueres må få alle sine samtaler evaluert"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: Hver person som evalueres må ha ", _interpolate(_named("count")), " av deres totale antall samtaler evaluert"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: Hver person som evalueres må ha ", _interpolate(_named("count")), " % av deres totale antall samtaler evaluert"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mål: Hver kvalitetssjekker må foreta én evaluering"]), _normalize(["Mål: Hver kvalitetssjekker må gjøre ", _interpolate(_named("count")), " evalueringer"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Erstatte: Kvalitetssjekkere kan ikke erstatte samtaler"]), _normalize(["Erstatter: Kvalitetssjekkere kan erstatte samtaler"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egenvurderinger: Kvalitetssjekkere kan tildeles egne samtaler"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke angitt noen datobetingelser"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke angitt noe tildelingsnavn"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammendrag"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelser"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " fra ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ganger i uken"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daglig"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldri"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukentlig"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sykluslengde"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett som aktiv"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre som kladd"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingen ble lagret som kladd"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingen er angitt som Inaktiv"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sett som Inaktiv"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett tildeling"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildeling opprettet"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som aktiv"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som inaktiv"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De ventende oppdateringene i tildelingen trer i kraft i neste syklus, som starter den ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiser endringer"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladd"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater tildeling"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildeling oppdatert"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten eller boten avsluttet samtalen."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten avsluttet samtalen på en høflig måte"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om samtalen ble avsluttet"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutning"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer grammatikk-, stave- og stilfeil i samhandlinger med både kundekonsulenter og bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer kundekonsulentens grammatikk-, stave- og stilfeil"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer kundekonsulentens grammatikk-, stave- og stilfeil"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettskriving og grammatikk"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer samtalen for kundekonsulent- og bot-hilsener."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for å se etter typiske hilsesetninger"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for å se etter typiske hilsener"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsen"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten forstår og anerkjenner kundenes opplevelser"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiserer tilfeller der kundekonsulenten speilet kundens synspunkter eller brukte omsorgsfulle ord som «forstår» og «anerkjenne»"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten forstår kundens forespørsel eller bekymring"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om kundekonsulenten forstår kundens forespørsel eller bekymring"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forståelse"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hvor lett en tekst kan forstås, med tanke på ordkompleksitet og setningslengde"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hvor lett en tekst kan forstås, med tanke på ordkompleksitet og setningslengde"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne meldingen inneholder 1 altfor lang setning, noe som gjør den vanskelig å lese"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Denne meldingen inneholder ", _interpolate(_list(0)), " for lange setninger, som gjør den vanskelig å lese"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesevennlighet"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for en tilbudt løsning"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for en tilbudt løsning"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbudt løsning"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer kundekonsulentens tone gjennom hele samtalen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer kundekonsulentens tone gjennom hele samtalen"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke noen feil i kundekonsulentens meldinger"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke fastslå grammatikkfeil"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen hilse-setning. Legg til nye om nødvendig via administrator i innstillingene."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten avsluttet samtalen på en høflig måte"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen avsluttende setning. Legg til nye om nødvendig via administrator i innstillingene"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten gjenspeilte kundens synspunkter eller brukte omsorgsfulle ord"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke avdekke om kundekonsulenten speilet kundens oppfatning eller brukte forståelsesfulle ord"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten brukte lange setninger og kompliserte ord, noe som gjorde det potensielt vanskelig å forstå for noen"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten brukte komplekse ord, noe som gjorde det potensielt vanskelig å forstå for noen"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten brukte lange setninger, noe som gjorde det potensielt vanskelig å forstå for noen"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten brukte et språk som er lett å forstå"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nok data til å vurdere denne kategorien"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten hadde problemer med å forstå kundens spørsmål/forespørsel"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten forstod kundens spørsmål/forespørsel godt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke avdekke om kundekonsulenten forstod kundens spørsmål/forespørsel"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentens tone under samtalen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentens tone under samtalen"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentens tone under samtalen"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentens tone under samtalen"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulentens tone under samtalen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klarte ikke å bestemme tonen til kundekonsulenten"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen ble ikke avsluttet"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen ble avsluttet"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke ut om samtalen ble avbrutt"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulent manglet empati overfor kunden og hens problemer"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulent viste empati overfor kunden og deres problemer"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke avdekke om kundekonsulenten viste empati med kunden og hens problemer "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble ikke hilst på kunden i løpet samtalen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten hilste på kunden under samtalen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ikke en typisk hilsen"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten tilbød ikke noen løsning til kunden"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekonsulenten tilbød en løsning til kunden"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke avdekke om kundekonsulenten tilbød en løsning til kunden"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompliserte ord"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange setninger"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammatikk"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavefeil"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklagende"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolig"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munter"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nysgjerrig"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonell"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Støttende"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 avslutning"]), _normalize([_interpolate(_named("n")), " avslutninger"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA ser bare etter godkjente avslutninger. Hvis denne kategorien er angitt som kritisk, vil det å ikke bruke godkjente avslutninger føre til at evalueringer ikke blir godkjent."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente avslutninger"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente avslutninger"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 unntak"]), _normalize([_interpolate(_named("n")), " unntak"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord og uttrykk AutoQA ignorerer som stave- eller grammatikkfeil."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntak for stavemåte og grammatikk"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unntak fra AutoQA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hilsen"]), _normalize([_interpolate(_named("n")), " hilsener"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA ser bare etter godkjente hilsener. Hvis denne kategorien er angitt som kritisk, vil ikke bruk av de godkjente hilsenene føre til at evalueringen ikke ble godkjent."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente hilsener"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente hilsener"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategori"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en AutoQA-kategori"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk tildelte underliggende årsaker:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto → Innstillinger"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noen kategorier benytter MS Azure, og har et utvidet utvalg av funksjoner og språk som støttes. Se over ", _interpolate(_named("link")), " hvis du vil ha mer informasjon, eller ta kontakt med en administrator for å gjøre det."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Få tilgang til en utvidet liste med kategorier og språk som støttes, ved å benytte MS Azure. Evaluer din ", _interpolate(_named("link")), " eller ta kontakt med en administrator for å gjøre dette."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA-kategorier tildeles automatisk en poengsum ved hjelp av en fast vurderingsskala. Du kan justere vurderingen manuelt senere. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en AutoQA-kategori"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En automatisk tildelt poengsum avhenger av hvor alvorlig feilene er og antallet. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underliggende årsaker"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare relevante vurderingskategorier som vises til kvalitetssjekkerne. Vurderingskategorier vises basert på betingelsene du satte da du opprettet en kategori."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innføringsprogram"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg vurderingskategoritypen for arbeidsområdet «", _interpolate(_named("workspace")), "»."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere må velge riktig resultatkort manuelt for å se relevante vurderingskategorier for hver samtale."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil endre vurderingskategoritype?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre kategorier"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre vurderingskategoritype?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betinget vurderingskategori"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisk vurderingskategori"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategoritype"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger for vurderingskategori"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan du ikke angre."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slette alle vurderingene"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du beholde statistikken uten å slette data ved å arkivere en kategori."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg forstår at dette vil ", _interpolate(_named("deleteAllRatings")), " gitt for denne kategorien."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. er tonen perfekt"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis bare kategori hvis"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Alltid» blir ignorert. Den kan ikke kombineres med andre betingelser."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alltid"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tagger"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg betingelse"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfredshetsindeks (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kildetype"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekanal"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kategori trenger et navn"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til kategorien på et resultatkort"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kategori"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kategori"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiver"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien er lagt til"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkivert kategori er ikke synlig på resultatkortet, men den lar deg beholde statistikken uten å slette data."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivkategori"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arkiver kategorien ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien ble arkivert"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett kategori"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv «slett kategori» for å slette denne kategorien"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slette kategori"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slett kategori ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien ble slettet"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien er duplisert"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigruppen ble lagt til"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigruppen ble slettet"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekkefølgen for vurderingskategorigruppen er endret"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigruppen er oppdatert"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien ble fjernet fra arkivet"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien er oppdatert"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når en kundekonsulent ikke består en kritisk kategori, mislykkes alle kategoriene i denne evalueringen automatisk."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritisk vurderingskategori"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag duplikat"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kategorier i denne gruppen"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett ny vurderingskategori"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kategori"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett alle kategorier og se gjennom data under denne gruppen"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletter du kategorigruppen, blir alle kategoriene som er knyttet til den ikke gruppert."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett ", _interpolate(_named("categoryName")), " kategorigruppen"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du velger å slette kategoriene under gruppen, vil du miste alle evalueringsdataene som er tilknyttet disse kategoriene."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenavn"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere kan velge mer enn én årsak som underliggende årsak"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere kan velge mer enn én årsak som underliggende årsak"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere årsaker"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. tone"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en ny kategorigruppe"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny gruppe"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til «Annet»"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til underliggende årsak"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den underliggende årsaken finnes allerede"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til underliggende årsak"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til underliggende årsaker for å forklare vurderingen"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskala"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivert"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til resultatkort i denne vurderingskategorien"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra arkiv"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater kategori"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vurderinger"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vist for"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative evalueringer"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative og nøytrale vurderinger"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, oppdater"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vekt"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier som er viktigere for deg, kan ha mer vekt i beregningen av sakens poengsum."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vekt (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om kalibrering"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om kalibrering"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avansert"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelmessige kalibreringssøkter gjør det mulig for kvalitetssjekkere å holde samsvar med vurderingsteknikkene og sikre at kundekonsulentene oppnår samme nivå av konsekvente og saklige tilbakemeldinger."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis abonnement"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " er en prosess som gjør det lettere for teamet å holde seg orientert om hvordan alle kundeinteraksjoner skal håndteres og evalueres."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profesjonell"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibrering er tilgjengelig for våre ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), " planer."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere gitte evalueringer vises ikke når en samtale legges til i økten."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke synlig"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere gitte evalueringer regnes som kalibreringsevalueringer. Det blir kun lagt til én samtaleevaluering per kvalitetssjekker i økten. Meldingsspesifikke evalueringer vises ikke."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighet for tidligere evalueringer"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrering er en prosess som gjør det lettere for teamet å holde seg orientert om hvordan alle kundesamhandlinger skal håndteres og evalueres. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerne har ubegrenset tilgang til alle evalueringer som gis under en kalibreringsøkt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alltid alle evalueringene"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salgsemne"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etter at de har sendt inn en evaluering, vil brukerne se evalueringene som ble gjort av andre kvalitetssjekkere i løpet av en kalibreringsøkt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kalibreringsevalueringene etter å ha sendt inn en evaluering"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerne har ubegrenset tilgang til alle evalueringer som gis under en kalibreringsøkt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alltid alle evalueringene"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leder"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etter at de har sendt inn en evaluering, vil brukerne se evalueringene som ble gjort av andre kvalitetssjekkere i løpet av en kalibreringsøkt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kalibreringsevalueringene etter å ha sendt inn en evaluering"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etter at de har sendt inn en evaluering, vil brukerne se evalueringene som ble gjort av andre kvalitetssjekkere i løpet av en kalibreringsøkt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kalibreringsevalueringene etter å ha sendt inn en evaluering"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekker"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerne vil bare se sine egne evalueringer gitt under en kalibreringsøkt."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se kun egne evalueringer"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringsinnstillingene ble oppdatert"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighet av kalibreringsgjennomgang"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi terskelen for kontrollpanelets måleverdier ved å fylle hullene i henhold til teamets interne målesystem. Evaluer terskelen på kontrollpanelet."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelinger"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblinger"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagger"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terskel"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terskelen ble oppdatert"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et øyeblikk..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pass på at du har administratortillatelser og ", _interpolate(_named("retry")), ", eller ", _interpolate(_named("contact")), " hvis du vil ha mer hjelp."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ta kontakt med oss"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ut til at vi ikke fikk tilgang til brukerstøttegrensesnittet til kundeservice."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prøv på nytt"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kobler til kundestøttetjenesten din. Du blir omdirigert om et øyeblikk."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorisert"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gå tilbake til din ", _interpolate(_list(0)), " for å legge til en ny kundeservicetilkobling."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ut til at du har gått glipp av et trinn eller to."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finnes allerede en organisasjon med samme e-postdomene"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilkoblingsside"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I mellomtiden..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi oss et øyeblikk mens vi sjekker tillatelsene dine."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I mellomtiden..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrerer"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opprette et arbeidsområde"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil fortsette å bruke Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesten klar til bruk"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ikke noe arbeidsområde"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bytt konto"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil fortsette å bruke Zendesk QA, må lederen din tildele deg et arbeidsområde."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du sletter kontoen din, slettes alle dataene vi har om deg permanent ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle gitte evalueringer, samtaleinnhold, arbeidsområder osv."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til i Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble fra"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du kobler fra integrasjonen, deaktiveres alle Zendesk QA-varslinger via Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble fra Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrasjonen er koblet fra Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer standard varslingsinnstillinger for alle nye kontobrukere."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overstyr innstillinger for alle brukere"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er i ferd med å overstyre varslingsinnstillingene for alle kontobrukere"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerne vil ikke kunne definere sine egne varslingsinnstillinger."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslingsinnstillingene ble oppdatert"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsle når en kritisk kategori svikter"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsel når CSAT får et svar"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utløsere"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprette demoarbeidsområde og -data"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenopprett demodata og behold demoarbeidsområdet."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern demoarbeidsområde og data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater demodata"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Administrer konto \"", _interpolate(_named("accountName")), "\" demomodus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demomodus"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bli på siden"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forlat siden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du forlate denne siden og miste alle endringene?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fullført tildelingen"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett tildeling"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingen er fullført"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen tildelinger"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er for øyeblikket ingen oppgaver du kan evaluere."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juster tildelingen eller vent til neste syklus starter."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å justere filtrene"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger tildeling"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold et øye med denne siden for oppdateringer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen oppgaver for denne syklusen"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler å vise"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk oppgaven som fullført"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har evaluert denne brukeren."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen egenvurderinger i denne tildelingen"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til ved å klikke på kalibreringsikonet i toppteksten i samtalevisningen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler ble lagt til"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfallsdato"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett tildeling"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett kalibreringsøkt"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelingene dine"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre tildelinger"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen aktive tildelinger"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tildelinger"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle personer som evalueres"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle personer som evalueres unntatt meg"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen personer som evalueres"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter person som evalueres"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferdig"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladd"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernet"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle statuser"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skal gjøres"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kvalitetssjekkere"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kvalitetssjekkere unntatt meg"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelt til meg"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kvalitetssjekkere"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter kvalitetssjekker"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dager"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dager"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dag"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dager"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dager"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dager"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " time"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutt"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I/A"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildel på nytt"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere av arbeidsområdet"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjeldende kvalitetssjekker"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt mål for denne syklusen"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny kvalitetssjekker"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kvalitetssjekker"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildel samtalene på nytt"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildel samtalen på nytt"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler som skal flyttes"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjeldende syklus"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger tildeling"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kvalitetssjekkere"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter kvalitetssjekkere"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilordne samtaler til meg på nytt"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilordne samtaler til en annen bruker"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekker"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekkere"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversikt"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dager"]), _normalize([_interpolate(_named("n")), " dag"]), _normalize([_interpolate(_named("n")), " dager"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " timer"]), _normalize([_interpolate(_named("n")), " time"]), _normalize([_interpolate(_named("n")), " timer"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul fullførte sykluser"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul fullførte økter"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målet ditt"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en årsak for å fjerne denne samtalen"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar hvorfor du fjerner denne samtalen"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern samtale"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du beholde endringene?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en årsak for å erstatte denne samtalen"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkast endringer"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold endringene"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen erstatning"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar hvorfor du erstatter denne samtalen"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du fremdeles fjerne denne samtalen fra tildelingen? Dette reduserer målet med 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstatt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstatt samtale"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett utkast"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger utkast"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilordne meg igjen"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildel på nytt til en annen bruker"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildel på nytt og evaluer"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern samtale"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg igjen en evaluering"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis mindre"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis mer"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " evaluert"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utførte evalueringer"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis fullførte sykluser"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis fullførte økter"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid igjen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppgaver"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale erstattet"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå oppgaveliste av og på"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " senere"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekund"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekunder"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " t"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " t ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dager"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("minutes")), " m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t ", _interpolate(_named("minutes")), " m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t ", _interpolate(_named("minutes")), " md ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " min ", _interpolate(_named("seconds")), " sek"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " mnd."])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " å"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkurat nå"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du mangler tillatelser. Ta kontakt med administrator for å få hjelp."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siden du leter etter finnes ikke."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en uventet feil. Ta kontakt med kundestøtte for å få hjelp."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgang. Har ikke lov til å utføre denne handlingen."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siden du leter etter, eksisterer ikke, eller du har ikke tilgang til den."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du går fram litt for fort, og vi har problemer med å ta deg igjen."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endringer ble lagret"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble foretatt endringer som ikke er lagret"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " brukt"]), _normalize([_interpolate(_named("n")), " brukt"]), _normalize([_interpolate(_named("n")), " brukt"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblet"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet den"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet av"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritisk"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferdig"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tidligere medlem"]), _normalize(["et tidligere medlem"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valgt"]), _normalize([_interpolate(_named("n")), " valgt"]), _normalize([_interpolate(_named("n")), " valgt"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist oppdatert"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... og +1 til"]), _normalize(["... og +", _interpolate(_named("n")), " til"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 til"]), _normalize(["+", _interpolate(_named("n")), " til"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ny"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernet"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruker"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fredag"]), _normalize(["fredager"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mandag"]), _normalize(["mandager"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lørdag"]), _normalize(["lørdager"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Søndag"]), _normalize(["søndager"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Torsdag"]), _normalize(["torsdager"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tirsdag"]), _normalize(["tirsdager"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Onsdag"]), _normalize(["onsdager"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i går"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Admin"]), _normalize(["en administrator"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontoadministrator"]), _normalize(["en kontoadministrator"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bruker"]), _normalize(["en bruker"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mer"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økten har utløpt."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pålogginger"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet kan ikke stå tomt."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen elementer."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samsvarende personer."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eier"]), _normalize(["en eier"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mer"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mer"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mer."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Før vi setter i gang må du lese og godta vilkårene våre, så er vi klare."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendt"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluering gjort totalt"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdatert"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kundekonsulent"]), _normalize(["en kundekonsulent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Salgsemne"]), _normalize(["et salgsemne"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Leder"]), _normalize(["en leder"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kvalitetssjekker"]), _normalize(["en kvalitetssjekker"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonym bruker"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiver"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saksansvarlig"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukk"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snart"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk for å kopiere"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier lenken"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faresone"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslå"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse (valgfritt)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjenning er påkrevd"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du forkaste endringer som ikke er lagret?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag duplikat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frekvens"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjønner"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldri"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varslinger"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståttandel"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige innstillinger"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mindre"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send på nytt"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenopprett"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person som evalueres"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte/sette evalueringer"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekker"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer utført av medlem av arbeidsområdet"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre endringer"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binær skala"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-punkts skala"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-punkts skala"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-punkts skala"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg frekvens"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innstillinger"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis færre"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis mer"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopp over"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fokus"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinn"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send inn"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding på undersøkelsen"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelser"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsintervall"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble foretatt endringer som ikke er lagret"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vekt"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du er invitert av ", _interpolate(_list(0)), " for å bli med i Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brukerne kobles til automatisk fra administrasjonssenteret. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer brukere"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lær om brukeradministrasjon"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noen brukere klargjøres og synkroniseres automatisk fra identitetsleverandøren din. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kontotillatelse for «", _interpolate(_list(0)), "» endret"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om brukertillatelser"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke spør meg igjen"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle endringer som gjøres på brukersiden, gjelder arbeidsområder. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette betyr at brukernes tilgang til visse deler av Zendesk QA kan endres"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre tillatelse på kontonivå"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruker er fjernet"]), _normalize([_interpolate(_named("n")), " brukere er fjernet"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når du fjerner en bruker, mister vedkommende tilgangen til kontoen din. Evalueringene, kommentarene og vurderingene deres forblir i Zendesk QA. ", _interpolate(_named("linebreak")), "Hvis du fjerner en bruker, påvirker det ikke antallet lisenser i abonnementet ditt."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Denne brukeren blir fjernet fra alle arbeidsområder du administrerer. ", _interpolate(_named("linebreak")), " Evalueringene, kommentarene og vurderingene deres forblir intakte."]), _normalize(["De blir fjernet fra alle arbeidsområder du administrerer. ", _interpolate(_named("linebreak")), " Alle deres evalueringer, kommentarer og vurderinger forblir intakte."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fjern ", _interpolate(_named("name"))]), _normalize(["Fjern ", _interpolate(_named("name")), " brukere"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan se alt og administrere alle innstillinger og fakturering"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan se alt og administrere person- og arbeidsområdeinnstillinger, unntatt fakturering"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotillatelser"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med arbeidsområdespesifikke tillatelser kan brukere se (og) redigere data innenfor arbeidsområder de befinner seg i."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botene dine vises her etter hvert som samtaler de har deltatt i, synkroniseres"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen boter ble synkronisert"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du merker en bot som bruker, får de tilgang til pålogging. Deres historiske bot-data forblir tilgjengelige på kontrollpaneler."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen slike boter"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å søke på nytt med et annet nøkkelord eller stavemåte."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter boter"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter bot-navn"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som bruker"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare administratorer og kontoadministratorer som kan merke boter som brukere"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-navn"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelt merket"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan evalueres"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boten fjernes fra automatisk analyse og eventuelle tildelinger. Nye data vises ikke i kontrollpaneler, noe som sikrer nøyaktige rapportering og enklere styring av bot."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generativ bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukjent"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsflyt-bot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til i gruppe(r)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen gruppe"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra gruppe(r)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg gruppe"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotillatelse"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til arbeidsområde(r)"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullt navn"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste aktivitet"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontonivårolle"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver bruker tildeles en tillatelse på konto- og/eller arbeidsområdenivå. Administratortillatelsene er nå på kontonivå, mens andre tillatelser på Arbeidsområde-nivå."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra arbeidsområde(r)"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområdetillatelse"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillatelser administreres i gruppen"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger detaljer"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk som bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du merker en bruker som bot, mister brukeren tilgangen til pålogging. Deres historiske brukerdata forblir tilgjengelige på kontrollpaneler."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern boten fra automatisk analyse og eventuelle tildelinger. Nye data vises ikke i kontrollpaneler, noe som sikrer nøyaktige rapportering og enklere styring av bot."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelat bot fra evalueringer"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker er merket som bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare tillatelsestypen Bruker som kan merkes som bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere med flere kontoer kan ikke merkes som bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på bruker"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boter"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere, boter og arbeidsområder"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARBEIDSOMRÅDER"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere og arbeidsområder"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tillatelser"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblet"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagt til manuelt"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventende invitasjon"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-brukere"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling kreves"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...og én til"]), _normalize(["...og ", _interpolate(_named("n")), " til"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre tillatelse til"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fjerne og invitere på nytt for å endre rollen for ", _interpolate(_named("n")), " ventende brukerinvitasjon."]), _normalize(["Fjern og inviter på nytt for å endre rolle for ", _interpolate(_named("n")), " ventende brukerinvitasjoner"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rediger detaljer for ", _interpolate(_named("name"))]), _normalize(["Rediger detaljer for ", _interpolate(_named("n")), " valgte brukere"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruker oppdatert."]), _normalize([_interpolate(_named("n")), " brukere oppdatert."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Denne gruppen inneholder 1 bruker fra et annet arbeidsområde."]), _normalize(["Denne gruppen inneholder ", _interpolate(_named("n")), " brukere fra et annet arbeidsområde."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" gruppen ble arkivert"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivert gruppe"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett gruppe"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Gruppen \"", _interpolate(_named("name")), "\" ble opprettet"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke navngitt gruppe"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne gruppen for godt?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett gruppe"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett gruppe"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger gruppe"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppesalgsemne"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupperolle"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" gruppe skjult"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne gruppen vil ikke være synlig noen andre steder i Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bare administrere arbeidsområdetillatelser"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen er ikke synlig i Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salgsemne"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlem"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke navngitt gruppe"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En annen gruppe har allerede dette navnet"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkivert gruppe har allerede dette navnet"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen din kan bare administrere medlemmer av denne gruppen. Ta kontakt med en bruker med høyere tillatelser for ytterligere endringer."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern som gruppesalgsemne"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen \"", _interpolate(_named("name")), "\" ble gjenopprettet"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" gruppe lagret"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi som gruppesalgsemne"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" -gruppen ble fjernet fra arkivet"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulagrede endringer"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til eksisterende brukere i denne gruppen"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter navn eller e-postadresse"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til medlemmer"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" gruppe synlig"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvert medlem er ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om grupper"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noen attributter til grupper klargjøres og synkroniseres automatisk fra identitetsleverandøren din."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les om grupper"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett gruppe"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er enkelt å organisere teammedlemmene dine og etterligne organisasjonsstrukturen."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til minst 1 medlem"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til et gruppenavn"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finnes allerede en gruppe med dette navnet"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppemedlemmer"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenavn"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiser teammedlemmene dine og etterligne organisasjonsstrukturen."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ingen grupper"]), _normalize(["1 gruppe"]), _normalize([_interpolate(_named("count")), " grupper"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett en ny gruppe eller aktiver en arkivert i stedet."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgjengelige aktive grupper"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arkiverte grupper"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen slike grupper"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv å søke på nytt med et annet nøkkelord eller stavemåte."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grupper ble lagt til"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["i disse"]), _normalize(["i"]), _normalize(["i"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på gruppenavn"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivert"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ny sending mulig ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " lisenser igjen"]), _normalize([_interpolate(_named("n")), " lisens igjen"]), _normalize([_interpolate(_named("n")), " lisenser igjen"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisensantallet er for øyeblikket begrenset, siden abonnementet ditt er på pause. Aktiver den på nytt for å legge til flere brukere."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nådd grensen for kontolisenser. Du kan erstatte brukere ved å deaktivere en aktiv bruker eller legge til flere lisenser."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis betalingsopplysningene"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valgt"]), _normalize([_interpolate(_named("n")), " valgt"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruker valgt"]), _normalize([_interpolate(_named("n")), " brukere valgt"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " rader"]), _normalize([_interpolate(_named("n")), " rad"]), _normalize([_interpolate(_named("n")), " rader"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk samtidig etter brukere etter navn eller e-postadresse."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lim inn data atskilt med komma eller linjeskift her"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massesøk i brukere"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rediger arbeidsområdetillatelser for ", _interpolate(_named("name"))]), _normalize(["Rediger arbeidsområdetillatelser for ", _interpolate(_named("n")), " utvalgte medlemmer"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger tillatelser"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg arbeidsområdetillatelse"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fjern ", _interpolate(_named("name")), " fra arbeidsområdet"]), _normalize(["Fjern ", _interpolate(_named("name")), " medlemmer fra arbeidsområdet"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " medlem fjernet"]), _normalize([_interpolate(_named("n")), " medlemmer fjernet"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fjern medlem"]), _normalize(["Fjerne medlemmer"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Denne brukeren blir fjernet fra arbeidsområdet."]), _normalize(["Disse brukerne blir fjernet fra arbeidsområdet."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arbeidsområdetillatelse for «", _interpolate(_list(0)), "» endret."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til medlemmer"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se samtaler og relaterte evalueringer"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alt i arbeidsområdet, men administrer kun quizer, oppgaver, bestridelser og kalibreringsøkter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområdetillatelser"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se og administrer alt på arbeidsområdet"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alt i arbeidsområdet, unntatt arbeidsområdeinnstillingene"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koble til brukere"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere fra arbeidsområdene du administrerer, er lagt til i dette arbeidsområdet."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be en administrator om å gjøre det."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter nye brukere direkte selv, eller be en administrator om å gjøre det."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " eller ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brukere å vise"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " bruker lagt til"]), _normalize([_interpolate(_named("n")), " brukere lagt til"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Legg til medlemmer"]), _normalize(["Legg til medlem"]), _normalize(["Legg til ", _interpolate(_named("n")), " medlemmer"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg arbeidsområdetillatelse"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg brukere til arbeidsområdet"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Legg til medlemmer til ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lim inn e-poster atskilt med nye linjer eller komma her"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Matchet ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-poster"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" med allerede tilkoblede brukere:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kan ikke matche ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-poster"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroller stavemåten eller koble dem til enkeltvis senere"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" med kundeservicebrukere:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruker tilkoblet"]), _normalize([_interpolate(_named("n")), " brukere tilkoblet"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruker invitert"]), _normalize([_interpolate(_named("n")), " brukere invitert"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massetilkobling"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseinvitasjon"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksempel:\n\nkate", "@", "eksempel.com, john", "@", "eksempel.com\n\nkate", "@", "eksempel.com\nkate", "@", "eksempel.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til fra din kundeservice for å se gjennom samhandlingene deres"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Koble til bruker"]), _normalize(["Koble til brukere"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-poster"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til individuelt"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil at de tilkoblede brukerne skal få tilgang til Zendesk QA, kan du invitere dem her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter tilkoblede brukere"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Inviter bruker"]), _normalize(["Inviter brukere"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopp over invitasjonen"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg brukere å koble til fra din kundeservice for å gjennomgå samhandlingene deres"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer abonnementet ditt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til kundeservicebrukere"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi arbeidsområdet et unikt navn"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis dette er aktivert, blir alle eksisterende kontotilkoblinger automatisk lagt til det nye arbeidsområdet."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til alle tilkoblinger til dette arbeidsområdet"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett arbeidsområde"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på arbeidsområdet"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett arbeidsområde"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere som legges til direkte på kontoen (uten å tildele et arbeidsområde), tildeles en rolle på kontonivå. Du kan legge dem til i arbeidsområder senere."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviterte brukere tildeles automatisk en brukerrolle på kontonivå. Du kan oppgradere dem til en administratorrolle senere."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle disse fungerer"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lim inn e-poster atskilt med linjeskift eller komma her."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruker invitert på e-post"]), _normalize([_interpolate(_named("n")), " brukere er invitert på e-post"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere som legges til i et arbeidsområde, tildeles automatisk en brukerrolle på kontonivå. Du kan oppgradere dem til en administratorrolle senere."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en til"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter brukere kun til kontoen uten å legge dem til i et arbeidsområde"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviterte brukere tildeles bare en kontotillatelse og legges ikke til noen arbeidsområder."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter brukere direkte til et arbeidsområde og tildel arbeidsområdetillatelser"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til brukere til arbeidsområdet"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseinvitasjon"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter individuelt"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter bare til kontoen"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter til et arbeidsområde"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Inviter brukere"]), _normalize(["Inviter bruker"]), _normalize(["Inviter ", _interpolate(_named("n")), " brukere"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseinviter brukere via e-post"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Slett bruker"]), _normalize(["Slett brukere"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern valget"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter brukere via e-post"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbeidsområder"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg tillatelse"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitasjon på nytt"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkoblet bruker"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventende e-post med brukerinvitasjon"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-bruker"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg arbeidsområde"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-konsulenter"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrasjonssenter"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetskontroll"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce Management"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fullført ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Fullført ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Fullført ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Syklus ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssjekker"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger tildeling"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spar tid og konfigurer automatiske evalueringstildelinger i stedet for å manuelt finne og tildele samtaler som skal evalueres."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Forfaller ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter kan legges til under Kalibrering i sidemenyen til samtalevisningen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen økter er lagt til ennå"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grunnlinje"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen økter"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler kan legges til en økt ved å klikke på kalibreringsikonet i toppteksten i samtalevisningen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler er lagt til ennå"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det er ikke gitt noen evalueringer. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg igjen en kalibreringsevaluering"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotårsak"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopp til"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke laste inn kommandopaletten."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingenting."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant etter ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster kommandopalett…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sider, filtre, samtaler, innstillinger…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat filter"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlig filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fra ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fra din API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Viser ", _interpolate(_named("rangeStart")), " til ", _interpolate(_named("rangeEnd")), " av ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Viser ", _interpolate(_named("rangeStart")), " til ", _interpolate(_named("rangeEnd")), " av ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul sidepanel"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis sidepanel"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver/deaktiver sidefelt"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkjente bestridelser"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviste bestridelser"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpne bestridelser"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkjente bestridelser"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utførte evalueringer"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte evalueringer"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sette evalueringer"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["godkjent"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["åpnet"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delvis godkjent"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " av totalen"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avvist"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpen"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkjent"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvist"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["evaluering"]), _normalize(["evalueringer"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antallet vurderinger som har blitt sett av personene som evalueres"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall av deres evalueringer som er sett"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omstridte kvalitetssjekkere"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelsesoppretter"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser sortert etter kategori"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestridelser over tid"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpen"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig løsningstid"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig løsningstid (t)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig løsningstid (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løst"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antall bestridelser"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg emneknagg"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatt"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarspråk"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen språk"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle språk"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslåtte drivere"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen drivere"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle drivere"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordsky-ord"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen ord"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle ord"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtaler"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyeste"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eldste"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åpen"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvis godkjent"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " reagerte med ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvist"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løst"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalueringer"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgt hudfarge"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen økter"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kategorier"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstørrelse"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen størrelser"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle størrelser"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen filtre"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen grupper"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagger"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen emneknagger"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle emneknagger"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen verdier"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verdier"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen egendefinerte felter"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeservice-tagger"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen kundeservice-tagger"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kundeservice-tagger"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster inn resultater…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørsmålstyper"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen spørsmålstyper"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til reaksjon"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatkort"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen resultatkort"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle resultatkort"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen poengsummer"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle poengsummer"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilder"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen kilder"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kilder"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsak til tilbakemeldingene"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen årsaker"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grunner"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøkelser"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen undersøkelser"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle undersøkelser"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen kanaler"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kanaler"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finner ingen brukere"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brukere"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen arbeidsområder"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbeidsområder"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til Quizer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizen er slettet eller ikke publisert enda. Dobbeltsjekk lenken eller gå tilbake til quizlisten."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne quizen ble ikke delt med noe arbeidsområde du tilhører. Be en administrator om å legge deg til et arbeidsområde som denne quizen har tilgang til."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz ikke tilgjengelig"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiztilgang er gitt til arbeidsområde(r) du ikke tilhører. Kan ikke lagre og publisere quizen."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be en administrator om å lagre og publisere quizen."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bør merke minst ett av svarene som riktig."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet kan ikke stå tomt."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du ser nå en forhåndsvisning av quizen."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg hvilke arbeidsområder som har tilgang til quizene. Brukere som tilhører flere arbeidsområder, kan kun svare på quizen én gang. Hvis du velger «Alle arbeidsområder», blir ikke nyopprettede arbeidsområder tatt med etter at quizen er publisert."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgang til quiz"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiver"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag duplikat"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå ut av forhåndsvisningsmodus"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørsmål"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta quizen"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiser quiz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizinnstillinger"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre som kladd"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett ny quiz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra arkiv"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alternativer"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til alternativ"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del den"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser bra ut, ikke sant?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svaret ditt er sendt inn"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizen din er lagret"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizen din er publisert"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizen din er fjernet"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til spørsmål"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiver quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkivert quiz og resultatene vises ikke lenger i quizlisten. Du kan fremdeles få tilgang til resultatene fra en arkivert test og ta den ut av arkivet når det er nødvendig."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiver quiz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiser"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når en quiz er publisert, kan den ikke endres eller redigeres. Alle publiserte quizer vil være tilgjengelige for alle brukere på kontoen din."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du publisere quizen?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil slette denne quizen? Dette kan du ikke angre."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette quizen?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når svarene er sendt inn, kan de ikke endres."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du sende inn quizen?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern quiz fra arkiv"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner quizen fra arkivet, blir den tilgjengelig i quizoversikten. Den gir tidligere deltakere tilgang til resultatene og gjør det mulig for nye å ta quizen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern quiz fra arkiv"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliser spørsmålet"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse (valgfritt)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flytt spørsmålet ned"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flytt spørsmålet opp"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørsmål"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er bare spørsmål med svar som vises i forhåndsvisningen."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern spørsmålet"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send inn quiz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz uten tittel"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiztittel"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil permanent slette quizen og dens resultater."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du fremdeles få tilgang til resultatene ved å arkivere quizen."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvart"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett quizen"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil permanent slette quizen og dens resultater."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slett quizen ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lag duplikat"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer er med på å øke teamets selvtillit og ferdigheter, levere konsistente opplevelser og inkludere nye teammedlemmer."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan søke opp quizer etter quizens navn eller forfatterens navn."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke opprettet noen quiz ennå"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter quizliste"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gj.sn. poengsum"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullført dato"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/forfatter"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz / Forfatter / Arbeidsområder"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltakere"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisert"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeidsområder"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på quiz, forfatter"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivert"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladd"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle quizene"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizen har blitt arkivert."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizen har blitt fjernet fra arkivet."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne quizen mottok ingen svar fra brukere i det/de valgte arbeidsområdet/ene."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne quizen fikk ingen svar før den ble arkivert."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen svar"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ser du en oversikt over svarene på testene.\nIngen har fylt ut quizen din ennå - det vil sikkert hjelpe å dele den."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen svar ennå"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig poengsum:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuell"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversikt"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Kopier"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenken ble kopiert"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fant ingen resultater"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s quizer"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan søke på ledertavlen etter deltakernavnet."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter ledertavle"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poengsum"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk på deltaker"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledertavle"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizer"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå av/på ledertavle"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette betyr at du ikke kan filtrere for denne emneknaggen på kontrollpanelet, og den vil ikke lenger fullføres automatisk for kommentarer. Denne handlingen kan ikke angres."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagg, emneknagg, emneknagg, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett emneknagger"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett emneknagger"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett emneknagger"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La medlemmene opprette nye emneknagger"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emneknagg"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukt"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett emneknagg"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slett emneknagg ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppretting av emneknagg er låst"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppretting av emneknagg er låst opp"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Emneknagg '", _interpolate(_list(0)), "' ble slettet"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Emneknagg '", _interpolate(_list(0)), "' fikk det nye navnet '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 emneknagg lagt til"]), _normalize([_interpolate(_named("count")), " emneknagger lagt til"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt navn"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ble ikke opprettet noen emneknagg"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi nytt navn"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gi emneknaggen nytt navn:", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill emneknaggene fra hverandre med komma eller linjeskift"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter en emneknagg"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ingen grunn til bekymring. Du kan gjøre slik ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akkurat her"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkobling knyttet til arbeidsområdet"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til tilkoblingen"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koble til en tilkobling til dette arbeidsområdet"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilkobling"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta kontakt med administrator for å foreta eventuelle endringer her"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta kontakt med Zendesk QA for å foreta eventuelle endringer her"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilkoblinger under kontoen"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ut til at du ikke har integrert med noen kundeserviceplattform ennå."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du sletter en tilkobling, fjernes samtaler fra arbeidsområdet ", _interpolate(_named("workspaceName")), " og sletter alle data forbundet med dem, inkludert evalueringer og bestridelser."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett tilkobling"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slett tilkobling ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en tilkobling"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I løpet av den valgte tidsperioden var det visse aktive AutoQA-kategorier som ikke fantes på resultatkortet ditt. Samtaler før siste endring den ", _interpolate(_named("date")), ", har kanskje ikke blitt analysert for disse kategoriene."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er her alle tilbakemeldinger du får, vises. Ingenting å vise ennå."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen mottatte evalueringer."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kun kommentaraktivitet"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er her alle tilbakemeldingene du har gitt, vises. Kom i gang i samtalevisning."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen gitte evalueringer."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitte evalueringer"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mottatte evalueringer"])}
  }
}