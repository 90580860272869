export interface SeparatorOption {
  id: 'separator'
  label: string
  value: 'separator'
}

export function getSeparatorOption(label: string): SeparatorOption {
  return {
    id: 'separator',
    label,
    value: 'separator',
  }
}
