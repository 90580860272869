import type { BotsResponse, Bot } from '@zendesk/zqa-services/users'
import { api } from '@/api'

export const getBots = (workspaceIds: number[]) =>
  api
    .get('bots', {
      headers: {
        'X-Klaus-Workspace': workspaceIds.join(),
      },
    })
    .json<BotsResponse>()

export const editBot = (botId: string, changes: Partial<Bot>) =>
  api.put(`bots/${botId}`, { json: { ...changes } }).json<Bot>()

export const setBotAsUser = (botId: string, workspaceIds: number[]) =>
  api.post(`bots/${botId}/set-as-user`, { json: { workspaceIds } }).json()
