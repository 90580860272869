import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_cell = _resolveComponent("number-cell")!

  return (_openBlock(), _createBlock(_component_number_cell, {
    "bg-color": _ctx.getBackground(),
    color: typeof _ctx.value !== 'number' ? 'gray-50' : 'gray-80',
    bold: _ctx.bold,
    value: typeof _ctx.value === 'number' ? `${_ctx.value} / ${_ctx.goal}` : _ctx.$t('dashboard.goal.value_na')
  }, null, 8 /* PROPS */, ["bg-color", "color", "bold", "value"]))
}