import qs from 'query-string'
import type { FilterResponse } from '@zendesk/zqa-services/survey-dashboard'
import type { CSVURLResponse } from '@zendesk/zqa-services/dashboard'

import { api } from '@/api'
import { apiCallGenerator, type CoreParams } from '../api'
import type {
  ConversationList,
  SurveyReplies,
  CsatFilterParams,
  CsatTags,
  Overview,
  Funnel,
  WorkspacesTimeline,
  WordCloud,
  CsatByUsers,
  CsatTimelineBreakdown,
  CsatValueList,
  CsatSurveys,
} from './types'

export const createApiCall = apiCallGenerator<CsatFilterParams>('dashboard/csat')

export let getConversationListController: AbortController
export const getConversationList = (params: any) => {
  getConversationListController = new AbortController()
  return createApiCall<ConversationList>('conversation-list')(params, getConversationListController.signal)
}

export const getOverview = createApiCall<Overview>('overview')

export const getCsatSurveyFunnel = createApiCall<Funnel>('survey-funnel')

export const getCsatTimeline = createApiCall<WorkspacesTimeline>('timeline')

export const getCsatDashboardTimelineBreakdown = (filterParams: CsatFilterParams) => {
  const { breakdownSource, ...params } = filterParams
  return createApiCall<CsatTimelineBreakdown>(`timeline/${breakdownSource}`)(params)
}

export const getCsatBreakdown = (filterParams: CsatFilterParams) => {
  const { breakdownSource, ...params } = filterParams
  return createApiCall<CsatTimelineBreakdown>(`breakdown/${breakdownSource}`)(params)
}

export const getCsatTags = createApiCall<CsatTags>('tags')

export const getCsatPredictedTags = createApiCall<CsatValueList>('predicted-tags')

export const getCsatPredictedDimensions = createApiCall<CsatValueList>('predicted-dimensions')

export const getCsatWordCloud = createApiCall<WordCloud>('word-cloud')

export const getCsatWordCloudValues = ({
  workspaceIds,
  searchString,
  questionTypeId,
  signal,
}: CoreParams & { searchString?: string; questionTypeId?: string } & { signal?: AbortSignal }) =>
  api
    .get('dashboard/csat/word-cloud-words', {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ searchString, questionTypeId, limit: 800 }),
      signal,
    })
    .json<{ values: string[]; total: number }>()

export const getCsatReasons = ({
  workspaceIds,
  searchString,
  surveyIds,
  questionTypeId,
  signal,
}: CoreParams & { searchString?: string; surveyIds: string[]; questionTypeId: string } & { signal?: AbortSignal }) =>
  api
    .get('dashboard/csat/reasons', {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ searchString, surveyIds, questionTypeId, limit: 10 }),
      signal,
    })
    .json<{ values: string[]; total: number }>()

export const getCsatByUsers = createApiCall<CsatByUsers>('per-agent')

export const getSurveyReplies = createApiCall<SurveyReplies>('comment-list')

export const getCsatDashboardScores = createApiCall<{ scores: { label: string; normalisedScore: string }[] }>('scores')

export const getCsatSurveys = () => api.get('surveys-published').json<CsatSurveys>()

export const exportTimeout = 12 * 60 * 1000

export const getAllExportsCsv = createApiCall<CSVURLResponse>('export.csv', {
  timeout: exportTimeout,
})

export const getCsatLanguages = ({
  workspaceIds,
  surveyIds,
  questionTypeId,
}: {
  workspaceIds: number[]
  surveyIds: string[]
  questionTypeId: string
}) =>
  api
    .get('dashboard/csat/languages', {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ surveyIds, questionTypeId }),
    })
    .json<FilterResponse>()
