import heart from '../assets/klausmoji/heart-eyes.png?url'
import star from '../assets/klausmoji/star-struck.png?url'
import sunglasses from '../assets/klausmoji/deal-with-klaus.png?url'
import unicorn from '../assets/klausmoji/unicat.png?url'
import rainbow from '../assets/klausmoji/rainbowpuke.png?url'
import facepalm from '../assets/klausmoji/facepaw.png?url'
import preach from '../assets/klausmoji/klaus-preach.png?url'
import thumbsup from '../assets/klausmoji/paws-up.png?url'
import hooray from '../assets/klausmoji/hooray.png?url'
import frown from '../assets/klausmoji/klaus-frowning.png?url'
import klaus from '../assets/klausmoji/klaus.png?url'
import lmto from '../assets/klausmoji/lmto.png?url'
import panic from '../assets/klausmoji/mild-panic.png?url'
import pumpkin from '../assets/klausmoji/klaus-pumpkin.png?url'
import tombstone from '../assets/klausmoji/klaus-tombstone.png?url'
import zombie from '../assets/klausmoji/klaus-zombie.png?url'
import ghost from '../assets/klausmoji/klaus-ghost.png?url'
import skull from '../assets/klausmoji/klaus-skull.png?url'
import wizard from '../assets/klausmoji/klaus-wizard.png?url'
import count from '../assets/klausmoji/klaus-count.png?url'
import mummy from '../assets/klausmoji/klaus-mummy.png?url'

export const klausmojis = [
  {
    short_names: ['heartklaus', 'klaus_heart_eyes'],
    emoticons: [],
    keywords: ['klaus', 'heart', 'eyes', 'cat'],
    imageUrl: heart,
  },
  {
    short_names: ['starklaus', 'klaus_star_eyes'],
    emoticons: [],
    keywords: ['klaus', 'star', 'eyes', 'cat'],
    imageUrl: star,
  },
  {
    short_names: ['deal-with-klaus'],
    emoticons: [],
    keywords: ['klaus', 'sunglasses', 'dealwithit', 'deal-with-klaus', 'dealwithklaus', 'cat'],
    imageUrl: sunglasses,
  },
  {
    short_names: ['uniklaus'],
    emoticons: [],
    keywords: ['klaus', 'unicorn', 'unicat', 'cat'],
    imageUrl: unicorn,
  },
  {
    short_names: ['pukeklaus'],
    emoticons: [],
    keywords: ['klaus', 'puke', 'rainbow', 'cat'],
    imageUrl: rainbow,
  },
  {
    short_names: ['facepaw'],
    emoticons: [],
    keywords: ['klaus', 'facepalm', 'face palm', 'cat'],
    imageUrl: facepalm,
  },
  {
    short_names: ['paws_up'],
    emoticons: [],
    keywords: ['klaus', 'pawsup', 'thumbsup', 'paw', 'cat'],
    imageUrl: thumbsup,
  },
  {
    short_names: ['raised_paws'],
    emoticons: [],
    keywords: ['klaus', 'raised', 'hands', 'paws', 'preach', 'klauspreach', 'klaus_preach', 'cat'],
    imageUrl: preach,
  },
  {
    short_names: ['hooray'],
    emoticons: [],
    keywords: ['klaus', 'hooray', 'klaus_joy', 'celebration', 'celebrate', 'cat'],
    imageUrl: hooray,
  },
  {
    short_names: ['klausfrown'],
    emoticons: [],
    keywords: ['klaus', 'frown', 'frowning', 'cat'],
    imageUrl: frown,
  },
  {
    short_names: ['klaus'],
    emoticons: [],
    keywords: ['klaus', 'cat'],
    imageUrl: klaus,
  },
  {
    short_names: ['lmto'],
    emoticons: [],
    keywords: ['klaus', 'laugh', 'laughing', 'cat'],
    imageUrl: lmto,
  },
  {
    short_names: ['mild-panic'],
    emoticons: [],
    keywords: ['klaus', 'panic', 'mildklaus', 'cat'],
    imageUrl: panic,
  },
  {
    short_names: ['klaus-pumpkin'],
    emoticons: [],
    keywords: ['klaus', 'pumpkin', 'halloween', 'orange'],
    imageUrl: pumpkin,
  },
  {
    short_names: ['klaus-tombstone'],
    emoticons: [],
    keywords: ['klaus', 'tombstone', 'halloween'],
    imageUrl: tombstone,
  },
  {
    short_names: ['klaus-zombie'],
    emoticons: [],
    keywords: ['klaus', 'zombie', 'halloween', 'cat'],
    imageUrl: zombie,
  },
  {
    short_names: ['klaus-ghost'],
    emoticons: [],
    keywords: ['klaus', 'ghost', 'boo', 'halloween', 'cat'],
    imageUrl: ghost,
  },
  {
    short_names: ['klaus-skull'],
    emoticons: [],
    keywords: ['klaus', 'skull', 'halloween', 'cat'],
    imageUrl: skull,
  },
  {
    short_names: ['klaus-wizard'],
    emoticons: [],
    keywords: ['wizard', 'halloween', 'purple', 'cat'],
    imageUrl: wizard,
  },
  {
    short_names: ['klaus-count'],
    emoticons: [],
    keywords: ['klaus', 'halloween', 'dracula', 'vampire', 'cat'],
    imageUrl: count,
  },
  {
    short_names: ['klaus-mummy'],
    emoticons: [],
    keywords: ['klaus', 'halloween', 'ow', 'hurt', 'cat'],
    imageUrl: mummy,
  },
]
