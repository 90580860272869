import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { type CalibrationSession } from '@/api/calibration'

export default createSharedComposable(() => {
  const showModal = ref(false)
  const edit = ref<CalibrationSession>()

  const editCalibrationSession = (session: CalibrationSession) => {
    edit.value = session
    showModal.value = true
  }
  const closeModal = () => {
    showModal.value = false
    edit.value = undefined
  }

  return {
    showModal,
    edit,
    closeModal,
    editCalibrationSession,
  }
})
