import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full text-sm" }
const _hoisted_3 = { class: "text-sm font-semibold" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "root",
      class: _normalizeClass(_ctx.$style.root)
    }, null, 2 /* CLASS */),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["text-xs font-medium", _ctx.$style.tooltip])
    }, [
      (_ctx.tooltipData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('disputes_dashboard.legend.disputes')), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.tooltipData.date), 1 /* TEXT */),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tooltipData.disputes, (dispute) => {
              return (_openBlock(), _createElementBlock("div", {
                key: dispute.id,
                class: _normalizeClass(_ctx.$style.tooltipRow)
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.rect),
                  style: _normalizeStyle({ background: dispute.color })
                }, null, 6 /* CLASS, STYLE */),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.tooltipScore)
                }, [
                  _createElementVNode("span", null, _toDisplayString(dispute.value) + " " + _toDisplayString(_ctx.$t(dispute.labelKey, dispute.value).toLowerCase()), 1 /* TEXT */),
                  (dispute.percentageValue !== null)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('disputes_dashboard.legend.percent_of_resolved', [`${dispute.percentageValue}%`])), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */)
              ], 2 /* CLASS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */), [
      [_vShow, _ctx.tooltipData]
    ])
  ]))
}