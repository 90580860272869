import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pin_icon = _resolveComponent("pin-icon")!
  const _component_tippy = _resolveComponent("tippy")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    trigger: _ctx.hasPins ? 'mouseenter focus' : 'manual',
    interactive: "",
    theme: "light pin-theme"
  }, {
    trigger: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        type: "button",
        class: "hover:bg-gray-20 text-gray-60 flex h-8 w-8 items-center justify-center rounded",
        "data-testid": "pin-dashboard",
        small: "",
        secondary: "",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
      }, [
        _createVNode(_component_pin_icon, {
          class: _normalizeClass(["icon-m", { [_ctx.$style.hasPinsButton]: _ctx.hasPins }])
        }, null, 8 /* PROPS */, ["class"])
      ])), [
        [_directive_tippy, { content: !_ctx.hasPins && _ctx.pinIt, delay: _ctx.delay }]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.buttons])
      }, [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([_ctx.$style.button]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
        }, _toDisplayString(_ctx.pinIt), 3 /* TEXT, CLASS */),
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([_ctx.$style.button]),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showSimilarPins && _ctx.showSimilarPins(...args)))
        }, _toDisplayString(_ctx.allPins), 3 /* TEXT, CLASS */)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["trigger"]))
}