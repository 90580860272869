import { computed, reactive, ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { debounce, cloneDeep } from 'lodash-es'

import {
  type CustomCategoryTemplateCondition,
  TemplateFilterCondition_TemplateFilterConditionCode as TemplateFilterConditionCode,
  SpotlightSignal,
  TemplateFilter_TemplateFilterTypeCode as TemplateFilterType,
} from '@zendesk/zqa-services/autoqa'
import i18n from '@/i18n'
import { type DeconstructedOption } from '@/modules/conversations/utils/filters'
import {
  addCustomConditionMessagePhrase,
  mapErrors,
  removeCustomConditionMessagePhrase,
  updateConditions,
} from '@/modules/settings/components/autoqa/utils'

import {
  type CustomSpotlightTemplate,
  type CustomRatingCategoryFormErrors as SpotlightFormErrors,
} from '@/modules/settings/components/autoqa/types'

const getEmptyErrors = (): SpotlightFormErrors => ({
  name: null,
  filter: '',
  ratingFilters: {},
})

export default createSharedComposable(() => {
  const getEmptyForm = (): CustomSpotlightTemplate => ({
    id: '',
    name: '',
    conditions: [],
    searchablePhrases: [
      {
        type: TemplateFilterType.AGENT_MESSAGE,
        condition: TemplateFilterConditionCode.STRING_CONTAINS,
        values: [{ value: '' }],
      },
    ],
    signal: SpotlightSignal.NEUTRAL,
  })

  const form = reactive(getEmptyForm())
  const initialForm = ref<CustomSpotlightTemplate>()
  const errors = ref(getEmptyErrors())

  const deadAirSpotlightSettingValue = ref('')
  const spotlightSettingValueInMs = computed(() =>
    (deadAirSpotlightSettingValue.value ? parseFloat(deadAirSpotlightSettingValue.value) * 1000 : '').toString(),
  )

  const getSpotlightConversationFilterValue = (filterId: string) =>
    form.conditions?.find((c) => c.type === filterId)?.values.map((v) => v.value) || []

  // modifiers
  const updateSpotlightConditions = (selectedFilters: DeconstructedOption[]) => {
    if (!selectedFilters.length) return (form.conditions = [])

    const conditions = updateConditions(selectedFilters)

    form.conditions = conditions
  }

  const updateMessageType = (type: TemplateFilterType) => {
    form.searchablePhrases = form.searchablePhrases.map((c) => {
      return {
        ...c,
        type,
      }
    })
  }

  const updateMessageConditionType = (
    condition: CustomCategoryTemplateCondition,
    selected: TemplateFilterConditionCode,
    index: number,
  ) => {
    const otherConditions = form.searchablePhrases.filter((c) => c.condition !== condition.condition)
    const newCondition = { ...condition, condition: selected }

    if (index === 0) return (form.searchablePhrases = [newCondition, ...otherConditions])
    else form.searchablePhrases = [...otherConditions, index === 1 && newCondition]
  }

  const updateMessagePhrase = debounce(
    (selected: CustomCategoryTemplateCondition, phrase: string, phraseId: number) => {
      const phrases = form.searchablePhrases
      selected.values.splice(phraseId, 1, { value: phrase as string })

      form.searchablePhrases = phrases.map((c) => {
        if (c.condition === selected.condition) return selected
        return c
      })

      if (errors.value.ratingFilters?.[selected.type]) {
        validateSpotlightMessagePhrases()
      }
    },
    300,
  )

  const addMessageCondition = (filterId: TemplateFilterType) => {
    const condition = {
      type: filterId,
      condition: TemplateFilterConditionCode.STRING_NOT_CONTAINS,
      values: [{ value: '' }],
    }

    form.searchablePhrases = [...form.searchablePhrases, condition]
  }

  const removeMessageCondition = (condition: CustomCategoryTemplateCondition) => {
    const allConditions = form.searchablePhrases
    allConditions.splice(1, 1)
    form.searchablePhrases = allConditions

    if (errors.value.ratingFilters?.[condition.type]) {
      validateSpotlightMessagePhrases()
    }
  }

  const addMessagePhrase = (selectedCondition: CustomCategoryTemplateCondition, conditionIndex: number) => {
    const otherPhrases = form.searchablePhrases.filter((c) => c.condition !== selectedCondition.condition)

    const newCondition = addCustomConditionMessagePhrase(selectedCondition)

    if (conditionIndex === 0) form.searchablePhrases = [newCondition, ...otherPhrases]
    else form.searchablePhrases = [...otherPhrases, conditionIndex === 1 && newCondition]
  }

  const removeMessagePhrase = (
    selectedCondition: CustomCategoryTemplateCondition,
    conditionIndex: number,
    phraseIndex: number,
  ) => {
    const otherConditions = form.searchablePhrases.filter((c) => c.condition !== selectedCondition.condition)

    const updatedCondition = removeCustomConditionMessagePhrase(selectedCondition, phraseIndex)

    if (conditionIndex === 0) form.searchablePhrases = [updatedCondition, ...otherConditions]
    else form.searchablePhrases = [...otherConditions, conditionIndex === 1 && updatedCondition]

    if (errors.value.ratingFilters?.[selectedCondition.type]) {
      validateSpotlightMessagePhrases()
    }
  }

  // validators
  const validateSpotlightName = () => {
    errors.value.name = form.name ? null : i18n.t('settings.categories.create.spotlight_name.errors.empty')
  }

  const validateSpotlightValue = () => {
    errors.value.filter =
      form.searchablePhrases[0].values.every((x) => x.value) || deadAirSpotlightSettingValue.value
        ? null
        : i18n.t('settings.categories.create.spotlight_filter.errors.empty')
  }

  const validateSpotlightMessagePhrases = () => {
    const mapped = mapErrors(form.searchablePhrases)

    errors.value = { ...errors.value, ...mapped }
  }

  const validateSpotlight = () => {
    validateSpotlightName()
    validateSpotlightValue()
    validateSpotlightMessagePhrases()
  }

  const hasSpotlightErrors = computed(
    () => !!errors.value.name || !!errors.value.filter || !!Object.keys(errors.value.ratingFilters).length,
  )

  // cleanup
  const fillForm = (spotlight: CustomSpotlightTemplate) => {
    form.id = spotlight.id
    form.name = spotlight.name
    form.signal = spotlight.signal
    form.searchablePhrases = cloneDeep(spotlight.searchablePhrases)
    form.conditions = cloneDeep(spotlight.conditions)

    resetErrors()
  }

  const resetErrors = () => {
    errors.value = getEmptyErrors()
  }

  const resetInitialForm = () => {
    initialForm.value = cloneDeep(form)
  }

  const resetForm = () => {
    Object.assign(form, getEmptyForm())
    resetErrors()
  }

  return {
    form,
    initialForm,
    errors,
    deadAirSpotlightSettingValue,
    spotlightSettingValueInMs,

    getEmptyForm,
    resetInitialForm,
    resetForm,
    fillForm,
    // get
    getSpotlightConversationFilterValue,

    // update
    updateSpotlightConditions,
    updateMessageType,
    updateMessageConditionType,
    updateMessagePhrase,

    addMessageCondition,
    removeMessageCondition,
    addMessagePhrase,
    removeMessagePhrase,

    // validate
    validateSpotlightName,
    validateSpotlight,
    hasSpotlightErrors,
  }
})
