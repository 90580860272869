import type { PinComment } from '@zendesk/zqa-services/pins'

export interface ConvertedComment {
  id: string
  ownerId?: number
  ownerName?: string
  ownerPicture?: string
  created: string
  updated: string
  comment: string
  highlighted: boolean
  firstCommentIdInThread: string
  lastCommentIdInThread: string
}

export default (comments: PinComment[]): ConvertedComment[] => {
  return comments.flatMap((comment) => {
    const newComments = [comment, ...comment.replies]
    return newComments.map((comment) => ({
      id: comment.id,
      ownerId: comment.user && Number(comment.user.id),
      ownerName: comment.user?.name,
      ownerPicture: comment.user?.avatar,
      created: comment.createdAt || '',
      updated: comment.updatedAt || '',
      comment: comment.body,
      highlighted: false,
      firstCommentIdInThread: newComments[0].id,
      lastCommentIdInThread: newComments[newComments.length - 1].id,
    }))
  })
}
