import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_form_field = _resolveComponent("ui-form-field")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_ui_pill_toggle = _resolveComponent("ui-pill-toggle")!
  const _component_view_settings_wrapper = _resolveComponent("view-settings-wrapper")!

  return (_openBlock(), _createBlock(_component_view_settings_wrapper, {
    onOpen: _ctx.analytics.csatDashboardViewSettingsOpened
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ui_form_field, {
        label: _ctx.$t('csat_dashboard.view_settings.owner_type', [_ctx.questionTypeLabel])
      }, null, 8 /* PROPS */, ["label"]),
      _createVNode(_component_q_radio, {
        "model-value": _ctx.viewSettings.csatOwnerSetting,
        options: [
        { label: _ctx.$t('csat_dashboard.view_settings.owner_type_assignee'), value: _ctx.CsatOwnerSetting.ticketAssignee },
        {
          label: _ctx.$t('csat_dashboard.view_settings.owner_type_most_replies'),
          value: _ctx.CsatOwnerSetting.agentWithMostReplies,
        },
      ],
        "onUpdate:modelValue": _ctx.changeCsatOwnerSetting
      }, null, 8 /* PROPS */, ["model-value", "options", "onUpdate:modelValue"]),
      _createVNode(_component_ui_form_field, {
        label: _ctx.$t('csat_dashboard.view_settings.date_type'),
        class: "mt-6"
      }, null, 8 /* PROPS */, ["label"]),
      _createVNode(_component_q_radio, {
        "model-value": _ctx.viewSettings.displayDateSetting,
        options: [
        {
          label: _ctx.$t('csat_dashboard.view_settings.date_type_reply', [_ctx.questionTypeLabel]),
          value: _ctx.DisplayDateSetting.csatReply,
        },
        { label: _ctx.$t('csat_dashboard.view_settings.date_type_creation'), value: _ctx.DisplayDateSetting.ticketCreated },
        {
          label: _ctx.$t('csat_dashboard.view_settings.date_type_csat_sent', [_ctx.questionTypeLabel]),
          value: _ctx.DisplayDateSetting.csatSent,
        },
      ],
        "onUpdate:modelValue": _ctx.changeDisplayDateSetting
      }, null, 8 /* PROPS */, ["model-value", "options", "onUpdate:modelValue"]),
      _createVNode(_component_ui_form_field, {
        label: _ctx.$t('dashboard.view_settings.cards'),
        class: "mt-6"
      }, null, 8 /* PROPS */, ["label"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.toggles)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
          return (_openBlock(), _createBlock(_component_ui_pill_toggle, {
            key: card.id,
            class: _normalizeClass(_ctx.$style.toggle),
            "model-value": _ctx.viewSettings.cardsVisible[card.id],
            label: card.label,
            small: "",
            "onUpdate:modelValue": ($event: any) => (_ctx.handleSettingsUpdate(card.id, $event))
          }, null, 8 /* PROPS */, ["class", "model-value", "label", "onUpdate:modelValue"]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onOpen"]))
}