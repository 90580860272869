import { computed, ref } from 'vue'

import i18n from '@/i18n'
import { toast } from '@/components/toast'
import { session } from '@/composables/useSession'
import analytics from '@/utils/analytics'
import type { Session, CoachingUser } from '../types'
import { updatePrivateNote, updateSession } from '../api'

interface Props {
  session: Session
  coach?: CoachingUser | null
}

const privateNotePlaceholder = i18n.t('coaching.sessions.thread.private_notes.placeholder')

type Emit = ((event: 'update', session: Partial<Session>) => void) & ((event: 'delete', id: string) => void)

export default function (props: Props, { emit }: { emit: Emit }) {
  const editingNote = ref(false)
  const editingPrivateNote = ref(false)
  const noteEditor = ref()
  const privateNoteEditor = ref()
  const note = ref(props.session.notes)
  const privateNote = ref(props.session.privateNote)

  const notePlaceholder = computed(() =>
    i18n.t('coaching.sessions.thread.note_placeholder', [
      props.coach ? props.coach.name : i18n.t('coaching.sessions.thread.note_author_placeholder'),
    ]),
  )

  async function saveNote() {
    await noteEditor.value?.saveImagesToBucket()
    editingNote.value = false
    const res = await updateSession(props.session.id, {
      notes: note.value,
      title: props.session.title,
      scheduledAt: props.session.scheduledAt,
    })
    toast({ status: 'success', message: i18n.t('coaching.sessions.thread.notes.updated') })
    emit('update', { ...res.session, notes: note.value })
    analytics.coachingEditTalkingPoints()
  }

  function editNote() {
    if (props.coach?.id !== session.user.id.toString()) return
    editingNote.value = true
    setTimeout(() => noteEditor.value?.focus(), 0)
  }

  function resetNote() {
    editingNote.value = false
    note.value = props.session.notes || ''
  }

  async function savePrivateNote() {
    await privateNoteEditor.value?.saveImagesToBucket()
    editingPrivateNote.value = false
    await updatePrivateNote(props.session.id, privateNote.value)
    toast({ status: 'success', message: i18n.t('coaching.sessions.thread.private_notes.updated') })
    emit('update', { privateNote: privateNote.value })
    analytics.coachingEditPrivateNote()
  }

  function editPrivateNote() {
    editingPrivateNote.value = true
    setTimeout(() => privateNoteEditor.value.focus(), 0)
  }

  function resetPrivateNote() {
    editingPrivateNote.value = false
    privateNote.value = props.session.privateNote || ''
  }

  return {
    note,
    noteEditor,
    notePlaceholder,
    editingNote,
    saveNote,
    editNote,
    resetNote,

    privateNote,
    privateNoteEditor,
    privateNotePlaceholder,
    defaultPrivateNote: `<p>${privateNotePlaceholder}</p>`,
    editingPrivateNote,
    savePrivateNote,
    editPrivateNote,
    resetPrivateNote,
  }
}
