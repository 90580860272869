import type { HelpdeskCustomFieldFilter } from '@/modules/dashboard/types'

export function customFieldsFilterMapToList(values: Record<string, HelpdeskCustomFieldFilter>) {
  return values ? Object.values(values).flatMap((val) => val) : []
}

export function customFieldFilterListToMap(values: HelpdeskCustomFieldFilter[]) {
  return values
    ? values.reduce<Record<string, HelpdeskCustomFieldFilter>>((acc, cur) => {
        return {
          ...acc,
          [cur.externalId]: cur,
        }
      }, {})
    : {}
}
