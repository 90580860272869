import { watch, ref, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import {
  AssignmentV2_Settings_Goal_TYPE,
  AssignmentV2_Settings_Goal_Config_UNIT,
  AssignmentV2_Settings_RevieweeClass as RevieweeClass,
} from '@zendesk/zqa-services/assignments_v2'
import type {
  TicketSearchFilterRequest_AutoQAFilterOptionRequest,
  TicketSearchFilterRequest_FilterOptionRequest,
  TicketSearchFilterRequest_SpotlightFilterOptionRequest,
  TicketSearchFilterRequest_CustomCategoryFilterOptionRequest,
} from '@zendesk/zqa-services/tickets'
import type { BotsResponse } from '@zendesk/zqa-services/users'
import i18n from '@/i18n'
import type { UserGroup } from '@/modules/user-management/groups/types'
import { type WorkspaceUser } from '@/modules/user-management/types'
import { getWorkspaceUsers } from '@/modules/user-management/api'
import { getUserGroupsWithMembers } from '@/modules/user-management/groups/api'
import {
  type BotParticipant,
  type AssignmentV2Form,
  type AssignmentV2_Assignment,
  type AssignmentV2_Assignment_Participants,
} from '@/modules/workspace/views/assignments/types/assignments'
import { useWorkspaceList } from '@/composables/useWorkspaceList'
import { session } from '@/composables/useSession'
import { getBots } from '@/modules/user-management/bots/api'
import { type DeconstructedOption, constructFilterOptions } from '@/modules/conversations/utils/filters'
import { generateDefaultAssignmentFormV2 } from './defaultAssignmentsForm'
import { toSubmittableAssignmentFormV2 as toSubmittableAssignmentForm } from './utils/form'
import { ALL_BOTS_ID, ALL_USERS_ID, Steps } from './constants'
import { getDateConditions } from './utils/cycleV2'

export default createSharedComposable(() => {
  const route = useRoute()
  const [workspaces] = useWorkspaceList()

  const form = ref<Partial<AssignmentV2Form | null>>(null)
  const initialForm = ref<Partial<AssignmentV2_Assignment>>()
  const newFilterOptions = ref<DeconstructedOption[]>([])
  const activeStepId = ref<Steps>(Steps.General)

  const allReviewers = computed(() =>
    workspaceUsers.value?.filter((u) => {
      if (!u.workspaceRole && !u.accountRole) return false
      return u.workspaceRole ? u.workspaceRole !== 'AGENT' : u.accountRole != 'USER'
    }),
  )
  const allReviewees = computed(() => [
    ...workspaceUsers.value,
    ...workspaceBots.value.filter(({ reviewable }) => reviewable),
  ])
  const botRevieweesSelected = computed(() => form.value.settings.revieweeClass === RevieweeClass.BOT)
  const workspace = computed(() => workspaces.value?.find((ws) => ws.id === Number(route.params.id)))
  const workspaceHasSelfReviews = computed(() => workspace.value?.settings.selfReviews)
  const assignmentSelfReviewsDisabled = computed(() => session.features.botQa2Dev && botRevieweesSelected.value)
  const validator = computed(() => validateAssignmentForm(form.value))
  const reviewers = computed(() =>
    getParticipants({
      type: 'reviewer',
      participants: form.value?.participants,
      allAvailableParticipants: allReviewers.value,
    }),
  )
  const reviewerIds = computed(() => reviewers.value.map((r) => String(r.id)))
  const reviewees = computed(() =>
    getParticipants({
      type: 'reviewee',
      participants: form.value?.participants,
      allAvailableParticipants: allReviewees.value,
      botReviewees: botRevieweesSelected.value,
    }),
  )
  const revieweeIds = computed(() => reviewees.value.map((r) => String(r.id)))
  const reviewersCount = computed(() => reviewers.value?.length)
  const revieweesCount = computed(() => reviewees.value?.length)

  onBeforeMount(() => (form.value = generateDefaultAssignmentFormV2(workspaceHasSelfReviews.value)))

  const { data: workspaceBots } = useQuery({
    queryKey: ['assignment-workspace-bots', workspace],
    queryFn: () => getBots(workspace.value ? [workspace.value.id] : []),
    select: (data) => data.bots.sort((a, b) => a.name.localeCompare(b.name)).map((bot) => ({ ...bot, isBot: true })),
    enabled: session.features.botQa,
    placeholderData: (): BotsResponse => ({ bots: [] }),
  })

  const { data: workspaceUsers } = useQuery({
    queryKey: ['assignment-workspace-users', workspace],
    queryFn: () => getWorkspaceUsers(workspace.value?.id),
    select: (data) => data.users.sort((a, b) => a.name.localeCompare(b.name)),
    placeholderData: () => ({ users: [] as WorkspaceUser[], readonly: true }),
  })

  const { data: reviewerGroups } = useQuery({
    queryKey: ['assignment-reviewer-groups', workspace],
    queryFn: () => getUserGroupsWithMembers({ workspaceId: workspace.value.id, reviewersOnly: true }),
    select: (data) => data.groups,
    placeholderData: () => ({ groups: [] as UserGroup[] }),
  })

  const { data: revieweeGroups } = useQuery({
    queryKey: ['assignment-reviewee-groups', workspace],
    queryFn: () => getUserGroupsWithMembers({ workspaceId: workspace.value.id }),
    select: (data) => data.groups,
    placeholderData: () => ({ groups: [] as UserGroup[] }),
  })

  const dateConditions = computed(() => getDateConditions(form.value.filter))

  function validateAssignmentForm(form: Partial<AssignmentV2_Assignment>): Record<number | string, boolean> {
    const rules = {
      general: !!form.name,
      participants: !!validateParticipants(),
      conditions: !!dateConditions.value?.length,
      goal: validGoal.value,
      minimum: validMinimum.value,
    }

    return {
      ...rules,
      all: Object.values(rules).every(Boolean),
    }
  }

  function validateParticipants() {
    if (!workspaceHasSelfReviews.value) {
      return reviewers.value.length && everyReviewerHasSomeoneElseToReview(reviewerIds.value, revieweeIds.value)
    }

    return reviewers.value.length && reviewees.value.length
  }

  const goalMax = ref(100)
  const specifyMinimumReviews = ref(false)

  const goalIntegerError = computed(() => {
    if (
      form.value.settings.goal.config.unit === AssignmentV2_Settings_Goal_Config_UNIT.NUMBER &&
      !Number.isInteger(Number(form.value.settings.goal.config.value))
    ) {
      return i18n.t('settings.workspaces.assignments_v_2.steps.goal.integer_error')
    }

    return false
  })

  const validGoal = computed(
    () =>
      form.value.settings.goal.config.value > 0 &&
      form.value.settings.goal.config.value <= goalMax.value &&
      !goalIntegerError.value,
  )
  const validMinimum = computed(
    () =>
      (specifyMinimumReviews.value && form.value.settings.goal.config.revieweeMinimum > 0) ||
      !specifyMinimumReviews.value,
  )

  const formSubmitted = ref(false)

  const hideMinimumReviews = computed(() => {
    return (
      form.value.settings.goal.type === AssignmentV2_Settings_Goal_TYPE.REVIEWEE &&
      form.value.settings.goal.config.unit === AssignmentV2_Settings_Goal_Config_UNIT.NUMBER
    )
  })

  watch(
    () => newFilterOptions.value,
    () => {
      form.value.filter.options = constructFilterOptions(
        newFilterOptions.value.filter((o) => !o.option.isAutoQa && !o.option.isSpotlight && !o.option.isCustomCategory),
      ) as TicketSearchFilterRequest_FilterOptionRequest[]
      form.value.filter.autoQaOptions = constructFilterOptions(
        newFilterOptions.value.filter((o) => o.option.isAutoQa),
      ) as TicketSearchFilterRequest_AutoQAFilterOptionRequest[]
      form.value.filter.spotlightOptions = constructFilterOptions(
        newFilterOptions.value.filter((o) => o.option.isSpotlight),
      ) as TicketSearchFilterRequest_SpotlightFilterOptionRequest[]
      form.value.filter.customCategoryOptions = constructFilterOptions(
        newFilterOptions.value.filter((o) => o.option.isCustomCategory),
        true,
      ) as TicketSearchFilterRequest_CustomCategoryFilterOptionRequest[]
    },
  )

  return {
    form,
    initialForm,
    newFilterOptions,
    workspaceHasSelfReviews,
    assignmentSelfReviewsDisabled,
    reviewerGroups: computed(() => reviewerGroups.value),
    revieweeGroups: computed(() => revieweeGroups.value),
    allReviewers: computed(() => allReviewers.value),
    allReviewees: computed(() => allReviewees.value),
    revieweeUsers: workspaceUsers,
    workspaceBots,
    reviewers,
    reviewees,
    reviewersCount,
    revieweesCount,
    goalIntegerError,
    validator,
    toSubmittableForm: toSubmittableAssignmentForm,
    dateConditions,
    goalMax,
    hideMinimumReviews,
    specifyMinimumReviews,
    formSubmitted,
    activeStepId,
    botRevieweesSelected,
  }
})

function everyReviewerHasSomeoneElseToReview(reviewerIds: string[], revieweeIds: string[]) {
  return reviewerIds.every((reviewerId) => revieweeIds.some((revieweeId) => revieweeId !== reviewerId))
}

interface ParticipantIdsProps {
  type: 'reviewer' | 'reviewee'
  participants: AssignmentV2_Assignment_Participants
  allAvailableParticipants: (WorkspaceUser | BotParticipant)[]
  botReviewees?: boolean
}

function getParticipants({ type, participants, allAvailableParticipants, botReviewees }: ParticipantIdsProps) {
  if (!participants) return []

  const includedUsers = (type === 'reviewer' ? participants.reviewers : participants.reviewees) || []
  const includedGroups = (type === 'reviewer' ? participants.reviewerGroups : participants.revieweeGroups) || []
  const exludedUsers = (type === 'reviewer' ? participants.excludeReviewers : participants.excludeReviewees) || []
  const excludedGroups =
    (type === 'reviewer' ? participants.excludeReviewerGroups : participants.excludeRevieweeGroups) || []
  const includedBots = (type === 'reviewee' ? participants.botReviewees : []) || []
  const excludedBots = (type === 'reviewee' ? participants.excludeBotReviewees : []) || []

  const allAvailableUsers = allAvailableParticipants.filter((p) => !('isBot' in p) || !p.isBot)
  const allAvailableBots = allAvailableParticipants.filter((p) => 'isBot' in p && p.isBot)

  const allUsersIncluded = includedUsers.some((u) => u.id === ALL_USERS_ID)
  const allBotsIncluded = includedUsers.some((u) => u.id === ALL_BOTS_ID)

  const userParticipants = allUsersIncluded ? allAvailableUsers : includedUsers
  const botParticipants = allBotsIncluded ? allAvailableBots : includedBots
  const groupParticipants = !botReviewees ? includedGroups.flatMap((g) => g.members) : []

  const includedParticipants = [
    ...(!botReviewees ? userParticipants : []),
    ...(botReviewees ? botParticipants : []),
    ...(!botReviewees ? groupParticipants : []),
  ]
  const includedParticipantsLegacy = [
    ...(includedUsers.some((u) => u.id === ALL_USERS_ID) ? allAvailableParticipants : includedUsers),
    ...includedBots,
    ...includedGroups.flatMap((g) => g.members),
  ]
  const includedParticipantsMap = session.features.botQa2Dev
    ? new Map(includedParticipants.map((p) => [p.id, p]))
    : new Map(includedParticipantsLegacy.map((p) => [p.id, p]))

  const excludedParticipantIds = [
    ...exludedUsers.map((u) => u.id),
    ...excludedBots.map((b) => b.id),
    ...excludedGroups.flatMap((g) => g.members).map((m) => m.id),
  ]

  return [...includedParticipantsMap.values()].filter(({ id }) => !excludedParticipantIds.includes(id))
}
