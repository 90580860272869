import config from '@/config'

interface TicketLinkParams {
  accountId?: string | number
  workspaceId?: string | number
  conversationId: string
  connectionId?: string | number
  messageId?: string
  calibrationSessionId?: string
  searchMode?: boolean
}

export function getTicketLink({
  accountId,
  workspaceId,
  conversationId,
  connectionId,
  messageId = '',
  calibrationSessionId = '',
  searchMode = false,
}: TicketLinkParams) {
  const url = new URL(window.location.origin)

  url.pathname = `${config.baseUrl}/conversations`

  if (accountId) url.searchParams.append('account', accountId.toString())
  if (workspaceId) url.searchParams.append('workspace', workspaceId.toString())

  if (searchMode) {
    url.searchParams.append('externalTicketId', encodeURIComponent(conversationId))
    return url.toString()
  }

  if (calibrationSessionId) {
    url.pathname = `${config.baseUrl}/tasks/calibrations/${calibrationSessionId}`
  }

  url.pathname += `/${connectionId}/${encodeURIComponent(conversationId)}`

  if (messageId) {
    url.pathname += `/messages/${encodeURIComponent(messageId)}`
  }

  if (!calibrationSessionId) url.searchParams.append('singleTicket', 'true')

  return url.toString()
}
