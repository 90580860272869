<template>
  <ol class="flex flex-col gap-y-2" :class="$style.stepper">
    <slot />
  </ol>
</template>

<style lang="scss" module>
.stepper {
  counter-reset: stepper;
}
</style>
