import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.commentCell, { [_ctx.$style.blank]: !_ctx.comment, [_ctx.$style.fluid]: _ctx.fullWidth }]),
    dir: "auto"
  }, [
    _renderSlot(_ctx.$slots, "default", {
      comment: _ctx.textComment || '-'
    }, () => [
      _createTextVNode(_toDisplayString(_ctx.textComment || '-'), 1 /* TEXT */)
    ])
  ], 2 /* CLASS */)), [
    [_directive_tippy, {
      content: `<div dir='auto'>${_ctx.cleanComment}</div>`,
      onShow: _ctx.onShow,
      allowHTML: true,
      theme: 'comment-cell',
    }]
  ])
}