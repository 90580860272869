import { uniq } from 'lodash-es'
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'
import { searchTicketTags } from '@/modules/dashboard/api'

type Props = ComputedRef<{
  workspaceIds: number[]
  searchQuery: string
  selectedTags: string[]
}>

export function useTicketTags(props: Props) {
  const workspaceIds = computed(() => props.value.workspaceIds)
  const searchQuery = computed(() => props.value.searchQuery)
  const selectedTags = computed(() => props.value.selectedTags)

  const enabled = computed(() => workspaceIds.value.length > 0)

  const { data: ticketTagData, isPending: isLoading } = useQuery({
    queryKey: ['ticket-tags', workspaceIds, searchQuery],
    queryFn: ({ signal }) => searchTicketTags({ workspaceIds: workspaceIds.value, tag: searchQuery.value, signal }),
    select: (data) => ({
      tags: uniq(selectedTags.value.concat(data.tags)).map((tag) => ({ id: tag, name: tag })),
      total: data.total,
    }),
    enabled,
    placeholderData: keepPreviousData,
  })

  return { ticketTagData, isLoading }
}
