import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_coaching_page_nav = _resolveComponent("coaching-page-nav")!
  const _component_answer_header = _resolveComponent("answer-header")!
  const _component_ui_spinner = _resolveComponent("ui-spinner")!
  const _component_quiz_title = _resolveComponent("quiz-title")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_layout = _resolveComponent("page-layout")!

  return (_openBlock(), _createBlock(_component_page_layout, {
    "hide-left-sidebar": "",
    "hide-right-sidebar": "",
    view: _ctx.RootViews.Coaching
  }, {
    "page-nav": _withCtx(() => [
      _createVNode(_component_coaching_page_nav)
    ]),
    "page-header": _withCtx(() => [
      _createVNode(_component_answer_header, { title: _ctx.title }, null, 8 /* PROPS */, ["title"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("main", {
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.scrollableContent)
        }, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ui_spinner, {
                key: 0,
                class: _normalizeClass(_ctx.$style.spinner)
              }, null, 8 /* PROPS */, ["class"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style.form)
              }, [
                _createVNode(_component_quiz_title, {
                  title: _ctx.title,
                  description: _ctx.description,
                  "read-only": "",
                  "hide-access": ""
                }, null, 8 /* PROPS */, ["title", "description"]),
                (_openBlock(), _createBlock(_component_router_view, {
                  id: _ctx.id,
                  key: _ctx.$route.fullPath,
                  "response-id": _ctx.responseId,
                  "form-data": _ctx.quizForm,
                  onSubmit: _ctx.handleSubmitRequest
                }, null, 8 /* PROPS */, ["id", "response-id", "form-data", "onSubmit"]))
              ], 2 /* CLASS */))
        ], 2 /* CLASS */)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["view"]))
}