export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast dit Zendesk-underdomæne"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind på Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit firma har en eksisterende konto."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig et firma"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil tilmelde dig et eksisterende firma, skal du bede din administrator om at sende dig en invitation."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed din administrator om at invitere dig"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ændrede mening."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller tilmelding"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit firma har en eksisterende konto. Vil du tilmelde dig dit firmas konto eller oprette en ny?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér instruktioner"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en ny konto"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der findes en eksisterende konto"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke få adgang til din Zendesk QA-konto, før dine udestående abonnementsfakturaer er betalt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit abonnement er på standby"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen blev ikke fundet. Prøv igen."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["få en ny adgangskode."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøv igen eller ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert kombination af e-mailadresse og adgangskode."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmod din administrator om et nyt."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette invitationslink er ugyldigt eller er allerede blevet brugt."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " login-metoden er deaktiveret på din konto."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne invitation er ugyldig eller er benyttet. Anmod din administrator om et nyt."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke indlæse invitationsoplysningerne."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmelding mislykkedes. En bruger med denne e-mailadresse findes måske allerede."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din konto er blevet blokeret efter flere på hinanden følgende loginforsøg. Se efter en e-mail i din indbakke med instruktioner i, hvordan du fjerner blokeringen."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft først din e-mailadresse"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig e-mailkonto"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt er obligatorisk"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt er obligatorisk"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loginkode"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send det magiske link"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsmailadresse"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din arbejdsmailadresse"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/dk/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Aftale om hovedydelser)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskoden matcher ikke kravene"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklæring om beskyttelse af personlige oplysninger"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/dk/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent lidt"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hej ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilmeld dig organisationen ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-mailadresse er blevet bekræftet. Du kan nu logge ind med din nye konto herunder."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt med Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldigt invitationslink"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med det magiske link"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vend tilbage for at logge ind"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt med Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt med Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-mailadresse er blevet bekræftet. Du kan nu logge ind med din nye konto nedenfor:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind via det magiske link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind på Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din konto er blevet slettet"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du ikke en konto?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig kode"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt er obligatorisk"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig e-mailkonto"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt er obligatorisk"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loginkode"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle login-indstillinger"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " via traditionelle metoder"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med det magiske link"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har modtaget en e-mail, som indeholder din loginkode."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har modtaget en e-mail, som du kan følge for at logge ind."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under afsendelse af det magiske link. Prøv igen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind via e-mail"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret adgangskode"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tegn med små bogstaver"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tegn med store bogstaver"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny adgangskode skal indeholde"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskodens maksimale længde er 128 tegn"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem at huske, svær at gætte"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindst 8 tegn"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret din adgangskode"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du glemt adgangskoden?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tegn med småt"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tal"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tegn med stort"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at nulstille din adgangskode skal du indtaste din e-mailadresse, hvorefter vi sender dig en e-mail med instruktioner."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig e-mailkonto"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt er obligatorisk"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil adgangskode"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der er blevet sendt en e-mail til ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du ikke kan se e-mailen i din indbakke eller spam-mappe, bedes du kontrollere den indtastede e-mailadresse."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vend tilbage for at logge ind"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek din e-mail"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil adgangskode"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", vil du modtage en e-mail til nulstilling af adgangskoden."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis din konto har en eksisterende adgangskode"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis den ", _interpolate(_named("bold")), ", har vi sendt dig et link til nulstilling af adgangskoden."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailadresse er knyttet til en konto"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du husket din adgangskode?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under afsendelse af linket til nulstilling. Prøv igen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil din adgangskode"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig via det magiske link"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Privatlivspolitik)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når du tilmelder dig, accepterer du Zendesk QA ", _interpolate(_named("terms_of_service")), " og ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicebetingelser"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du en konto?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig via det magiske link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig med din arbejdsmail"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig med Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig med Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har en aktiv Zendesk-konto"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilmeld dig eller log ind for at fuldføre din ", _interpolate(_named("direct_install_name")), " konfiguration"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er det virkelig dig? Kontrollér din indbakke for en bekræftelsesmail."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Så er du parat"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA fungerer bedst på desktops. Log ind for at begynde at bedømme samtaler."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book en demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan også booke en demo med os, så vi kan vise dig, hvad du kan i Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er anmodet om din tilmelding"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annullere"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg ændrede mening og vil gerne ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " inviterer dig til at tilmelde dig deres konto."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig kontoen"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en ny konto"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at logge ind igen"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Den reaktion du fik på din feedback: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne reaktioner"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerede på din kommentar"]), _normalize(["Flere personer har reageret på din kommentar"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerede på dit svar"]), _normalize(["Flere personer har reageret på dit svar"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagerede på din bedømmelse"]), _normalize(["Flere personer har reageret på din bedømmelse"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentar"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["svar"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelse"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVISTER"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFGIVNE"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODTAGNE"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback på undersøgelsen"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktioner"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påbegyndte"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markér kommentar som læst?"]), _normalize(["Markér alle kommentarer som læst?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markér tvist som læst?"]), _normalize(["Markér alle tvister som læst?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markér feedback fra undersøgelsen som læst?"]), _normalize(["Markér al feedback på undersøgelsen som læst?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markér bedømmelsen som læst?"]), _normalize(["Markér alle bedømmelser som læst?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerede kommentar som læst"]), _normalize(["Markerede alle kommentarer som læst"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerede tvist som læst"]), _normalize(["Markerede alle tvister som læst"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerede feedback fra undersøgelsen som læst"]), _normalize(["Markerede al feedback fra undersøgelsen som læst"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Markerede bedømmelse som læst"]), _normalize(["Markerede alle bedømmelser som læst"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér alle som læst"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som læst"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelser"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når nogen bestrider en bedømmelse, vises den her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tvister"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når du får til opgave at løse en tvist, vises den her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tvister modtaget"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når du bestrider en bedømmelse, vises den her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen påbegyndte tvister"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al feedback, du har fået, vises her. Endnu intet at vise."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback modtaget"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle reaktioner på dine bedømmelser og svar vises her. Endnu intet at vise."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen reaktioner modtaget"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbn en samtale"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al feedback, du har givet, havner her. Start med at skrive en bedømmelse i Samtalevisningen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen afgivne bedømmelser"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al feedback, du har fået, vises her. Endnu intet at vise."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bedømmelser modtaget"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kommentarer"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al feedback, du efterlader som kommentarer, vises her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen afgivne kommentarer"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kommentarer"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kommentarer under en bedømmelse, som du ejer, ", "@", "omtaler eller svar på dine kommentarer vises her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kommentarer modtaget"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen accepterede tvister"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen åbne tvister"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen afviste tvister"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen løste tvister"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv igen ved at vælge en anden indstilling fra ovenstående filter."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv igen ved at vælge en anden bruger fra ovenstående filter."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivne kommentarer"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne kommentarer"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tvister"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne tvister"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opstartede tvister"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der bliver bedømt"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtaget feedback fra undersøgelsen"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivne bedømmelser"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne bedømmelser"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift aktivitetsliste"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " bedømt"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " bedømt"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " bedømt"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Genstarter om ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " tilbage"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurer en opgave"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift opgaveliste"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det er ikke muligt at oprette en coaching-session imellem ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), ", da de i øjeblikket ikke befinder sig i det samme arbejdsområde."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Coaching-sessioner imellem ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), " findes allerede. Kontakt ", _interpolate(_list(0)), " for at få adgang til sessionen."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreslået"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Der er blevet oprettet en ny session imellem ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg coach"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der modtager coaching"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg den person, der modtager coaching"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret session"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en session"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj session"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på session"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreslået"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overskredet"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommende"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret session"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen sessioner"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig bedømmelsestid"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolpanel"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen data at vise for den valgte periode"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategori"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Føjede note til session. ", _interpolate(_named("buttonStart")), "Vis", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføjet"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er nået til de sidste noter for den valgte periode"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se, hvordan noter fungerer i denne ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artikel"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det ser ud til, at du ikke har fastgjort noget under ", _interpolate(_list(0)), ". Du kan bruge noterne til at forberede 1:1 sessioner eller gemme noget til et senere tidspunkt."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person der modtager coaching"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke tilføjet nogen noter"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her er alle de noter, som du har oprettet under ", _interpolate(_named("name")), ". Noter, som føjes til sessioner, bliver ", _interpolate(_named("bold")), " med den anden deltager."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["straks delt"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det ser ud til, at ", _interpolate(_list(0)), " ikke har modtaget feedback på sin samtalebedømmelse eller CSAT i den valgte periode."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgængelig feedback"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er nået til de sidste kommentarer for den valgte periode"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrikker"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["tal for ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt handlingspunkt"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fuldført af ", _interpolate(_named("user")), " d. ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["af ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dig"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast for at tilføje et nyt handlingspunkt"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad kom der ud af dette møde? Hvad er dine næste skridt?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingspunkter"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oprettet af ", _interpolate(_named("name")), " d. ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slette sessionen"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette vil permanent ", _interpolate(_named("deleteSession")), ". Deltagere mister adgang til sessionen, herunder dens indhold og private notater."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Session oprettet d. ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senest redigeret af ", _interpolate(_named("name")), " d. ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleemner og notater"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notat blev opdateret"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forlad session"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgte brugere får adgang til sessionsdetaljer fra dette tidspunkt og frem."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg brugere"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delt"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del session"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du vil ikke længere være en del af sessionen ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " vil ikke længere være en del af denne session."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " har nu adgang til denne session."]), _normalize([_interpolate(_named("n")), " nye brugere har nu adgang til denne session"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet session"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle sessioner"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet session"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coachen"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Notater tilføjet af ", _interpolate(_list(0)), " vil blive vist her."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj fastgjorte noter som samtalepunkter fra det højre sidepanel"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notater, som kun du kan se..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige notater"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat notat blev opdateret"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dage"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der modtager coaching"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingspunkter"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste session"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igangværende"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overskredet"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt over sessioner"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mig: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mig: Person der modtager coaching"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sessioner"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delte sessioner"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at ændre et filter for at se handlingspunkter."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen handlingspunkter"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingspunkter"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser modtaget og set"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtaget · Set"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status efter sessioner"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ud til, at agenterne ikke har modtaget feedback."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft, at du har brugerens korrekte navn"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke finde denne bruger"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen tilgængelige data"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste 30 dages IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette vil permanent <b>slette alle sessioner</b> (2) med <b>", _interpolate(_named("name")), "</b>. Deltagere mister adgang til alle sessioner, herunder indholdet og private notater."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er nået til slutningen af alle sessioner"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En coaching-session er en gruppeaktivitet mellem en mentor og en person, der modtager coaching, for at snakke om performance, muligheder, kort- og langsigtede mål."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan søge efter sessioner på både coachens navn og den person, der coaches."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen sessioner"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke oprettet nogen sessioner"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet coaching-historik"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet coaching-historik "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste session"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sessioner"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der modtager coaching"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som afsluttet"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som ikke afsluttet"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via navn"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omdøb"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift sessionsliste"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke-planlagt"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvar denne meddelelse"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløbet kalibreringssession. Der kan ikke skrives flere bedømmelser."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede skrevet en bedømmelse af denne samtale."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke længere muligt at bedømme denne samtale, eftersom forfaldsdatoen for sessionen er overskredet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtale har ingen bedømmelser"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luk panelet"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv en kalibreringsbedømmelse"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETALJER"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OVERSIGT"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFSKRIFT"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Højttaler ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilsigtet stilhed"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger højttaler"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm meddelelsen"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vend tilbage til afspilning af meddelelse"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højttalere"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afskrift"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere af arbejdsområde"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj først en tilknytning for at kunne oprette filtre"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oprettet d. ", _interpolate(_named("time")), " af ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senest opdateret d. ", _interpolate(_named("time")), " af ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det ser ud til, at du ikke har nogen filtre. For at få adgang til denne side, skal du først oprette et filter. Start med ", _interpolate(_named("recommendedFilter")), " for at se dine egne samtaler. Opret dette filter nedenfor for at fortsætte."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i løbet af de seneste 30 dage"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelsen \"Oprettet i mindst 30 dage\" vil blive føjet til filtre, der oprettes uden et datointerval"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteret er ikke tilgængeligt for dette abonnement og vil ikke blive taget i betragtning ved visning af resultater"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout under hentning af filterindstillinger. Prøv igen."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om filtrering"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig = person, som er logget ind"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fundne samtaler: ", _interpolate(_named("count"))]), _normalize(["Fundet samtale: ", _interpolate(_named("count"))]), _normalize(["Fundne samtaler: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke tilgængelig med dit aktuelle abonnement. Opgrader for at bruge denne funktion."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj betingelse"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Af AI for Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelse"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omdan til opgave"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret filter"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret filter"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefinerede felter"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelser"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi indlæser filterindstillingerne i henhold til din specifikke opsætning. Ved den første indlæsning, kan dette tage et par minutter."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv dit filter et beskrivende navn"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filternavn"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foretag et valg"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelse"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentligt"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger filter"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem ændringer"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgængelige værdier"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke fundet"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en værdi"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighed"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun aktive sessioner ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis ældre sessioner ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfaldsdato"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kalibreringssession"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret session"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kalibreringssession"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kalibreringsbedømmelser, der blev givet i løbet af sessionen, vil blive slettet permanent."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet session"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet ", _interpolate(_named("sessionName")), " kalibreringssession"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke blevet oprettet nogen sessioner"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen aktive sessioner"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen sessioner"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen aktive sessioner"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj filter"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret et privat filter"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret et offentligt filter"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter tilføjet"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteret vil blive slettet permanent."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet filter"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet ", _interpolate(_named("filterName")), " filter"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tvister"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister til mig"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister fra mig"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbne tvister"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladder"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke tilføjet nogen filtre"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen private filtre"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen offentlige filtre"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private filtre"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlige filtre"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ønsker du at ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemme den"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er en midlertidig filtervisning."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbygger dit filter ..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter opdateret"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsigter"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret Auto-QA-kategori"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senest opdateret ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplekse ord"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiske bedømmelser opdateres dynamisk baseret på ticket-ændringer"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremhæv i samtale"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange sætninger"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen prognoser for automatisk score"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere nogen fejl"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis komplekse ord"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis lange sætninger"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste samtale"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA kunne ikke vurdere denne kategori pga. utilstrækkelig kontekst. Manuel bedømmelse anbefales."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find automatiseret samtaleindsigt for at effektivisere din bedømmelsesproces. Fremhæv vigtige samtaler, og præsenter ekstraordinære positive eksempler for dit team."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Opkald]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettet"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " accepterede tvisten"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tvist sendt til ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar, hvorfor du mener, at denne bedømte person er den forkerte modtager af denne feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en kommentar og angiv en ny person at bedømme"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en kommentar"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar, hvorfor du mener, at disse scorer er vurderet forkert (obligatorisk)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en kommentar (obligatorisk)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar, hvorfor du afviser denne tvist"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en kommentar"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send tvist til"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send tvist til"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send tvist til"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en ny person at bedømme"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid feedback"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid feedback"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift person der skal bedømmes"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send tvist til"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvist"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid denne bedømmelse"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er ikke enig i scorerne"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne bedømmelse var ikke tiltænkt mig"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en bruger"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " accepterede delvist tvisten"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " afviste tvisten"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteret"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvist accepteret"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvist"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åben"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " startede en tvist: bedømmelse"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " startede en tvist: forkert person der bliver bedømt"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En åben tvist skal løses, så du kan iværksætte andre foranstaltninger i forbindelse med bedømmelsen."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptér"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg nye scorer"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet tvist"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil kassere denne tvist?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet tvist"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvist"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt for løsning af tvist"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg nye retfærdige scorer (valgfrit)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid bedømmelse"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert person der bliver bedømt"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptér tvist"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptér tvist"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid feedback"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert person der bliver bedømt"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrid feedback"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis tvist"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis tvist"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se tvisten"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigeret"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " af ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["til ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelt til:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv også gerne en kommentar"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om betingede resultattavler"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere tildelt til:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal bare vælge en"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke finde nogen GIF-filer med dette navn"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær den første til at bedømme."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit abonnement er i øjeblikket på pause. Genaktiver det for at få mulighed for at give feedback."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsendt af:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette viser, hvor meget tid der blev brugt på at bedømme denne samtale"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en bruger"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mangler hovedårsag til kategori: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder en kategori eller tilføj en kommentar"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udvid kommentarfeltet"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul kommentarfeltet"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul formatering"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis formatering"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend bedømmelse"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder alle kategorier"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder mindst én kategori"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem denne samtale til senere"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem til senere"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste opgave"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstat samtale i denne opgavecyklus"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm samtalen"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse af referencepunkt"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kommentar"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar slettet"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet denne kommentar?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarens forfatter"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette hashtag er blevet slettet eller omdøbt."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortæl hvad du kunne lide, eller hvad der kunne have været bedre..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentér"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betinget"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette sletter alle kommentarer permanent."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kommentar"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Meddelelse]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " har endnu ikke set denne feedback"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " har set denne feedback"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere medlem"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg i GIF-filer..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryk på Enter for at oprette et nyt hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefinerede hashtags låses af administratoren."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug denne til at sætte bogmærke i sager og filtrere fra senere"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som referencepunkt"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback på meddelelsen"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste samtale"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke foretaget nogen bedømmelser"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv en bedømmelse nedenfor for at komme i gang."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen flere bedømmelser"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke vurderet"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere ansvarlig"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere samtale"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern som referencepunkt"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvar denne bedømmelse"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul sidepanelet for bedømmelser"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette sletter bedømmelsen og alle tilføjede kommentarer permanent."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet bedømmelse"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet bedømmelse"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelsen blev slettet"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bedømmelse slettet af ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel denne opgave til dig selv, så du kan skrive en bedømmelse"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede skrevet en bedømmelse af denne bruger."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du har skrevet en bedømmelse af ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen kategorier at vise for denne samtale."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelser er ikke tilgængelige for dette arbejdsområde."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem ikke"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du gemme denne bedømmelse som en kladde?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassér denne kladde"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassér"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis sidepanelet for bedømmelser"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dumpet"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestået"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestået med småfejl"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi arbejder stadig på sagen..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efterlad en kommentar"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm denne meddelelse"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm dig selv"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm denne samtale"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfatter"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brugere at bedømme"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulighed for selvbedømmelse er deaktiveret"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen brugere"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagere"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere af arbejdsområde"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg bruger"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 bedømmelse"]), _normalize([_interpolate(_named("count")), " bedømmelser"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg nye vurderinger"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefen for dit arbejdsområde har ikke tilladt selvbedømmelser."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryk på Enter for at tagge denne note"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringer"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste 30 dage"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stjernemarkerede"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en kalibreringssession"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladder"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsigter"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsigter giver dig en visuel gengivelse af dine helpdesk-data. Brug de interaktive diagrammer til at forstå, hvor du skal fokusere din QA-indsats, hvordan du afdækker blinde vinkler og finder de rette agenter at bedømme baseret på deres performance."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " Id"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL-ADRESSE"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oversæt til ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke-tildelt"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuel visning"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer nu"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtale er blevet fjernet og er ikke længere tilgængelig."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtale, som du leder efter, findes ikke i Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle noter til denne samtale"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I session"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern samtale"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner en samtale fra en session, slettes alle bedømmelser, den har fået."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fjern samtale fra ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke oprettet nogen kalibreringssessioner."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtale er blevet føjet til sessionen"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtale er blevet fjernet fra sessionen"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til/fjern fra en kalibreringssession"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér link til denne samtale"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtale at vise"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens e-mailadresse"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde-id"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundens navn"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på ekstern formular"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første responstid"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritet"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar til undersøgelse"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfredshedsscore"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid til første løsning"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprindelig"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne meddelelse findes ikke under denne samtale, eller den er blevet slettet"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intet emne"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtale at vise"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen samtale"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbn den oprindelige samtale"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke linke til samtale – URL-adresse mangler"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagere"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen deltagere"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør samtale"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik for at læse bedømmelser"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik for at bedømme"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale oprettet via browserudvidelse"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se kundetilfredshedsundersøgelse"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se samtale"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelse med negativ stemning"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelse med positiv stemning"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversæt til engelsk"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis den oprindelige"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet samtale"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sletning af en samtale er permanent. Den sletter også alle relaterede data, f.eks. undersøgelser, afskrifter og anmeldelser fra Zendesk QA, herunder kontrolpaneler.", _interpolate(_named("lineBreak")), "Du vil stadig kunne få adgang til denne samtale i Zendesk Support eller en anden helpdesk-software, som du benytter."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sletning af en samtale er permanent. Den sletter også alle relaterede data, f.eks. undersøgelser, afskrifter og anmeldelser fra Zendesk QA, herunder kontrolpaneler. Hvis samtalen hører til flere arbejdsområder, vil den blive slettet fra dem alle.", _interpolate(_named("lineBreak")), "Du vil stadig kunne få adgang til denne samtale i Zendesk Support eller via anden helpdesk-software, som du benytter."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet samtale"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet samtale fra Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne samtale er ufuldstændig som følge af optrapning. Gå til ", _interpolate(_named("optionsUrl")), " og valider dine tags. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne samtale er ufuldstændig som følge af optrapning. Tal med din administrator om validering af dine tags. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbindelser > Avancerede indstillinger"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om tags"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukkede"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbne"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventer"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løst"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ansvarlig er ændret til ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke-tildelt"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " makro anvendt af ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtale modtog en vurdering ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brugeren ", _interpolate(_named("target")), " bortredigerede samtalen"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status ændret til ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tagget med ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team er ændret til ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " udsatte samtalen for ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " annullerede udsættelsen af samtalen"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botten sidder fast i et loop, der bliver ved med at gentage en meddelelse"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-gentagelse"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-gentagelse, hvor den samme meddelelse gentages en eller flere gange i træk"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev fundet matchende sætninger"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En gennemsnitlig agent håndterer samtalen ", _interpolate(_named("percent")), " mere effektivt end botten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-kommunikationens effektivitet"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Botten klarede samtalen ", _interpolate(_named("percent")), " mere effektivt end en gennemsnitlig agent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-kommunikationens effektivitet"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale mangler den nødvendige erklæring, der informerer deltagerne om optagelsen, som er nødvendig af hensyn til overholdelse og gennemsigtighed"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om optagelse mangler"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En Service Level Agreement (SLA) (Serviceniveauaftale) er blevet overtrådt for ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA er overtrådt"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrik for SLA-regel overskred den allokerede tid"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegn på kundeafgang. Kunden overvejede at skifte eller lovede at afslutte samarbejdet."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frafaldsrisiko identificeret"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En samtale har øjeblikke med utilsigtet stilhed, der varer længere end den angivne tærskel på ", _interpolate(_list(0)), " s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 forekomst"]), _normalize([_interpolate(_named("count")), " forekomster"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s er det længste"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilsigtet stilhed"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden søgte assistance fra person på højere niveau"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev anmodet om optrapning"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden udtrykte stor taknemmelighed eller var meget tilfreds med den modtagne support"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enestående service"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enten kunden eller agenten anmodede udtrykkeligt om en opfølgning"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmodning om opfølgning"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden var utilfreds"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ stemning"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen var usædvanlig eller atypisk, og der blev diskuteret frem og tilbage for at nå frem til en løsning"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvigelse registreret"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden var tilfreds"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv stemning"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv som ikke relevant"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senest redigeret af ", _interpolate(_named("author")), " d. ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Af ", _interpolate(_named("author")), " d. ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en årsag (valgfrit)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen indsigt registreret"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj tilknytning"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knyt din helpdesk til din konto, og få oprettet mindst ét filter, så du kan se listen over samtaler."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler at vise"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se indstillinger for arbejdsområde"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der bliver bedømt"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senest opdateret"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstil"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Om en dag)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Denne måned)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Denne uge)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find via id"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Søgeresultater for ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt af andre"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenterede"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyeste"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ældste"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfældige"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligt opdateret"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvaret for nylig"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortér efter"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulæst"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt af dig"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importprocessen kan tage noget tid. Vi sender dig en e-mail, når den er færdig."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importerer dine samtaler"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limit: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ved at klikke på kalibreringsikonet i samtalevisningens overskrift."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intet at berette her. Prøv igen ved brug af et andet filter eller en anden sorteringsindstilling."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen afvigelser at vise."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find en relevant samtale og klik på stjerneikonet i overskriften for at sætte et bogmærke her."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at ændre filtrene eller inviter andre helpdesk-agenter til at tilføje flere samtaler."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grænse"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internt notat"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi indlæser i øjeblikket samtaler. Noget indhold mangler måske stadig."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste bruger"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen brugere"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne bedømmelser"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer af arbejdsområde"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere bruger"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke tilføjet nogen samtaler"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke blevet tilføjet nogen samtaler"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ufærdige bedømmelser ender her. Læs den igen, revidér og indsend den i løbet af de næste 30 dage."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen udkast til bedømmelser"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen stjernemarkerede samtaler"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler at vise"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen resultater"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vend tilbage til den valgte meddelelse"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færrest svar"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flest svar"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemning"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift samtaleliste"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opkald besvaret kl. ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opkald sluttede kl. ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opkald foretaget kl. ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt af dig"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kunne ikke indlæse lydfil. Sørg for, at du er logget ind på ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar til kundetilfredshedsundersøgelse"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl under indlæsning af lyd"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarens forfatter"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen opkald"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyt i helpdesk"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyt på Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afspilningshastighed"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm opkald"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afskrift"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høj"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bruger af Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangordner automatisk agenter baseret på deres månedlige gennemsnitlige CSAT. Med ét klik kan du finde samtaler, der blev håndteret af agenter, hvis CSAT er lavere end deres kollegaers."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangordning efter CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høj"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laveste"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højeste"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bruger af Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lydstyrke"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangordner automatisk agenter i performance-grupper baseret på hvor mange samtaler, de håndterer pr. måned. Med ét klik kan du finde samtaler sorteret efter agenter med en mindre mængde end deres kollegaer."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangordning efter mængde"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bedømt"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for bedømmelse"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lav"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høj"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bruger af Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemning"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangordner automatisk agenter baseret på den gennemsnitlige stemning i samtalerne. Med ét klik kan du finde interaktioner, hvor agenter har at gøre med flere frustrerede kunder end deres kollegaer."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangordning efter stemning"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke registreret"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreret"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvigelser"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler der kræver din opmærksomhed. Vores maskinlæringsmodel identificerer komplekse eller atypiske interaktioner til nærmere bedømmelse."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvigelser"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg at bedømme samtaler, hvor kunden udviste enten begejstring eller frustration. Brug rullemenuen til at vælge den type stemning, som du leder efter i dine samtaler. Træk filtreringsattributten til toppen for at se forholdet mellem den valgte stemning i alle dine interaktioner."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemning"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemning"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for bedømmelse"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bedømt"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytning"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA registrerer automatisk sproget i dine samtaler. Brug rullemenuen til at vælge samtaler på specifikke sprog til bedømmelse. Træk filtreringsattributten til toppen for at få en oversigt over alle de sprog, der bruges i din helpdesk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleindsigt"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan ", _interpolate(_named("paragraph_2_item")), " med Indsigter end med almindelige samtalefiltre. Få et visuelt overblik over alle dataene fra dine helpdesks, og gå på opdagelse i indsigter som:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om brug af samtaleindsigt"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forstå den relative betydning af specifikke samtaleattributter i forhold til andre"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find de agenter med den bedste og den ringeste performance med indbygget rangordning af agentperformance"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " er et effektivt interaktivt dataanalyseværktøj, der hjælper dig med at finde de samtaler, der har mest brug for bedømmelse."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gøre så meget mere"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtale- og agent-diagrammerne er ", _interpolate(_named("paragraph_3_item_1")), " – opdatering af filtre eller et klik på et segmenteret diagramudsnit fra et af diagrammerne vil automatisk opdatere samtalelisten. ", _interpolate(_named("paragraph_3_item_2")), " for at undersøge detaljerede opdelinger eller forhold mellem de forskellige elementer."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indbyrdes forbundne"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omarranger filterattributterne"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samtaleindsigt viser kun ", _interpolate(_named("paragraph_4_item")), ". Vi fjerner automatisk udvekslinger uden reel bedømmelsesværdi, såsom automatisk genererede meddelelser, spam, bot-svar eller meddelelser, som ikke har en decideret dialog med din supportrepræsentant."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rene og lukkede samtaler"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad er det jeg ser her?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ud af ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " ud af ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " ud af ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " af ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke hente"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " IKKE RELEVANT"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenter"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " af total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæs igen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsigter"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men vi er sikre på, at den vil være fantastisk, når den ankommer."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugertilpasset undersøgelse"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback på undersøgelsen"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der findes endnu ingen feedback-score på undersøgelsen..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurdering"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modtaget"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback-tag"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag ti feedback-kommentar"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datointervalfilter anvendt"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " svar"]), _normalize([_interpolate(_named("count")), " svar"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Grav dybere ned i, hvorfor en ", _interpolate(_list(0)), "-score blev givet ved at angive årsager under undersøgelsesindstillinger."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Endnu ingen ", _interpolate(_list(0)), "-årsager"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om årsager til undersøgelsen"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler med tags:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " af"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsager"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarkategori"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " årsager"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Forventede faktorer for ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekilde"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tags"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Undersøgelsessvar (", _interpolate(_named("n")), ")"]), _normalize(["Undersøgelsessvar (", _interpolate(_named("n")), ")"]), _normalize(["Undersøgelsessvar (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intet emne"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtaler med ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " af brugere"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvarelsesprocent"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-scoren er summen af alle svar divideret med summen af maksimale scorer"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger mål"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern mål"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv et mål"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj mål"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kort"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meget lang"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellem længde"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forvirrende"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nyttig"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårlig support"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["God support"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meget følelsesladet"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback til agent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klage"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Små bidder"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem ikke løst"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem løst"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ stemning"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårligt resultat"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv stemning"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ros"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårligt produkt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godtgørelse"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurtig support"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsom support"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tildeler automatisk dine ", _interpolate(_list(0)), "-data i foruddefinerede kategorier. Så kan du forstå komplekse indsigter og undersøge årsager uden at skulle lede den skriftlige feedback igennem."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle scorer"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle undersøgelser"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg svarsprog"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstørrelse"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg kommentarstørrelse"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 25 % af alle kommentarer kun består af ét ord, skal længere kommentarer læses. Få mere indsigtsfuld feedback uden brug af manuel arbejdskraft, da vi automatisk gør det for dig."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag til feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg årsag til feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorer"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchende scorer"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forventede faktorer"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg kommentarkategori"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord fra ordskyen"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg ord fra ordskyen"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " siden seneste periode"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " samtale"]), _normalize([_interpolate(_named("n")), " samtale"]), _normalize([_interpolate(_named("n")), " samtaler"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke beregne antallet af sendte undersøgelser fra alle samtaler pga. manglende data."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser sendt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsestragt"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " af ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " af ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " af ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Forventede faktorer for ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA tildeler automatisk dine ", _interpolate(_list(0)), "-data i foruddefinerede kategorier. Så kan du forstå komplekse indsigter og undersøge årsager uden at skulle lede den skriftlige feedback igennem."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstørrelse"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 25 % af alle kommentarer kun består af ét ord, skal længere kommentarer læses. Få mere indsigtsfuld feedback uden brug af manuel arbejdskraft, da vi automatisk gør det for dig."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsessvar"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (gennemsnit)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal besvarelser"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (gennemsnit)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnit"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-trends efter"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal besvarelser"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsager"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktorer"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Afsendelsesdato for ", _interpolate(_list(0)), "-undersøgelse"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Svardato for ", _interpolate(_list(0)), "-undersøgelse"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tildel ", _interpolate(_list(0)), "-score til"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis data baseret på"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato for samtale"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ejer af ticket´en"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agent med flest svar"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (gennemsnit)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " over tid"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal besvarelser"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " og IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal besvarelser"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler, som indeholder disse ord:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordsky"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT efter brugere"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-oversigt"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsestragt"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsessvar"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tags"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT over tid"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordsky"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kilde"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intet sprog"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen årsag"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tags"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kommentarkategori"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intet arbejdsområde"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgængelige data, fordi der ikke er blevet oprettet nogen tildelinger eller der ikke blev startet cyklusser. Vend tilbage senere eller juster filtrene."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se, hvad der er nyt"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " element"]), _normalize([_interpolate(_named("count")), " elementer"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " samtale"]), _normalize([_interpolate(_named("count")), " samtaler"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dumpet"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestået"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestået med småfejl"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestået/dumpet"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnit"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnit (t)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnit (min.)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " vurderinger"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig CSAT = [sum af feedbackscorer ÷ (antal af alle svar × maks. mulig score)] × 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-scoren i Zendesk QA normaliserer din feedback-score inden for skalaen 0-100 % og beregner et gennemsnit af de standardiserede scorer."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (kundetilfredshedsscore) kvantificerer hvor glade dine kunder er for dine serviceydelser."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Hver bedømmelse repræsenterer en bedømmelse fra én person af én samtale. En samtale kan have mere end én bedømmer (hvis to eller flere korrekturlæsere bedømmer den samme samtale)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følg udviklingen i bedømmernes scorer over tid."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følg udviklingen af agenters scorer over tid."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bedømmelser"]), _normalize([_interpolate(_named("n")), " bedømmelse"]), _normalize([_interpolate(_named("n")), " bedømmelser"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Internal Quality Score) er resultatet af bedømmelserne af dine samtaler."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = summen af bedømmelser ÷ antal bedømmelser"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den beregnes som et gennemsnit, idet der tages højde for alle bedømmelser (det vægtede gennemsnit af kategoriernes scorer), som matcher de valgte filterbetingelser."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortet kunne ikke indlæses."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juster dine filtre for at prøve igen."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståelsesprocenten angiver resultatet af dine samtalebedømmelser."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dumper, hvis vurderingsscoren er ", _interpolate(_named("failScore")), " eller derunder"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Består, hvis vurderingsscoren er ", _interpolate(_named("passScore")), " eller derover"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den bestemmes ud fra et foruddefineret referencepunkt, hvilket betyder, at du:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståelsesprocent = Summen af beståede bedømmelser ÷ Summen af samlede bedømmelser"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståelsesprocenten angiver en procentdel af, hvor mange bedømmelser, der er bestået, uden at dumpe i hverken vigtige eller ikke-vigtige kategorier."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis f.eks. bedømmeren denne dag skriver 3 bedømmelser, er det viste tal gennemsnittet af de 3 scorer. Se, hvordan hver celle beregnes ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["f.eks. hvis agenten denne dag modtager 3 bedømmelser, er det viste tal gennemsnittet af de 3 scorer. Se, hvordan hver celle beregnes ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følgende tages Ikke i betragtning: kategorivægtning, status for vigtighed (i modsætning til scorer over tid)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da kategorier kan markeres som ikke relevante i bedømmelser, kan antallet af bedømmelser pr. kategori være mindre end det samlede antal bedømmelser fra bedømmeren. Se, hvordan hver celle beregnes"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følgende tages Ikke i betragtning: kategorivægtning, status for vigtighed (i modsætning til scorer over tid)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da kategorier kan markeres som ikke relevante i bedømmelser, kan antallet af bedømmelser pr. kategori være mindre end det samlede antal bedømmelser pr. agent. Se, hvordan hver celle beregnes"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følgende tages Ikke i betragtning: kategorivægtning, status for vigtighed (i modsætning til scorer over tid)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategorigrupper vises separat. Scoren for hver gruppe beregnes ud fra den gennemsnitlige kategoriscore for den pågældende gruppe i den samme periode. Se, hvordan hver celle beregnes ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følgende tages Ikke i betragtning: kategorivægtning, status for vigtighed (i modsætning til scorer over tid)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategorigrupper vises separat. Scoren for hver gruppe beregnes ud fra den gennemsnitlige kategoriscore for den pågældende gruppe i den samme periode. Se, hvordan hver celle beregnes ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Referencepunkt ", _interpolate(_named("count")), " %"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen alvorlige fejl"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procentsatsen for ingen alvorlige fejl angiver en procentdel af, hvor mange bedømmelser der er bestået, uden at dumpe i de vigtige kategorier."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern kvalitetsscore (IQS)"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Andre\" hovedårsager"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodisk ændring"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daglig ændring"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig ændring"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugentlig ændring"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig ændring"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorer over tid"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visning i enten dage eller uger."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregning = den samlede score for dag eller uge divideret med det samlede antal afgivne bedømmelser"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visning i enten dage eller uger."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregning = samlede score for dag eller uge divideret med det samlede antal modtagne bedømmelser"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorer efter kategori"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis det samlede gennemsnit af alle kategorivurderinger, som er givet af bedømmeren i denne periode."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis det samlede gennemsnit af alle kategorivurderinger, som blev modtaget pr. agent i denne periode."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedårsager til dårlige vurderinger"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedårsager til vurderinger"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriscorer over tid"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysér hvordan kategorierne sammenligner sig over tid."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se de gennemsnitsscorer, der er givet for hver kategori på en valgt dag eller uge."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysér hvordan kategorierne sammenligner sig over tid."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se de gennemsnitsscorer, der er modtaget for hver kategori på en valgt dag eller uge."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorer efter bedømmelser"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiveret"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigtig kategori"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskala"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnit/total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udeluk selvbedømmelser"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporterer alle kort"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivne"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["samtale"]), _normalize(["samtaler"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["element"]), _normalize(["elementer"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bedømmelser"]), _normalize([_interpolate(_named("n")), " bedømmelse"]), _normalize([_interpolate(_named("n")), " bedømmelser"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vis yderligere en"]), _normalize(["Vis yderligere ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste 30 dage"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste 7 dage"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste måned"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste uge"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne måned"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne uge"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret periode"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dage"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måneder"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvartaler"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uger"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortet kunne ikke indlæses. Juster dine filtre for at prøve igen."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen data at vise."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig ændring"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolpaneler"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere oplysninger."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine aktive filtre omfatter data på tværs af forskellige vurderingsskalaer."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle korts noter med disse filtre"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kontrolpanelers noter med disse filtre"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig bedømmelsestid"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpas"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kategorier"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriser indsigter"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graf"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentar"]), _normalize(["Kommentarer"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere medlem"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern kvalitetsscore (IQS)"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (gennemsnit)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståede bedømmelser"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør kort"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør kontrolpanel"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderinger"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hovedårsag"]), _normalize(["Hovedårsager"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer-id"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelsestid"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der bliver bedømt"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurer hovedårsager"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedårsagerne fortæller dig, hvorfor samtaler får en lav score"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke angivet nogen hovedårsager for denne kategori"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score/total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorier"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper af vurderingskategorier"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorigruppe"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområde"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpas rækkefølge"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppér kategorier efter"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg alle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpas"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udeluk selvbedømmelser"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medtag selvbedømmelser"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kun selvbedømmelser"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryd filtre"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen hashtags"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar-hashtags"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg kommentar-hashtags"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen tags"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tags"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg i helpdesk-tags"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen resultattavler"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavler"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg resultattavle"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelser"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchende forbindelser"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekilde"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg kilde"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere filtre"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchende arbejdsområder"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivet antal gange i alt"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhver"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchende grupper."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kort"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis data baseret på"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato for bedømmelse"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato for samtale"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis to decimaler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis indstillinger"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbejdsområder"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hele kontoen"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på arbejdsområde"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbejdsområder"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flag"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mad og drikke"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dyr og natur"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekter"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennesker og krop"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejse og steder"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofte anvendte"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgeresultater"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys og humørikon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symboler"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen emoji"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgning"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbn en samtale"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til opgavelisten"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " eller gennemse din liste med ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bruger i øjeblikket arbejdsområdet ", _interpolate(_named("workspace")), ", som ikke er knyttet til dette domæne. Klik her for at tilknytte det nu."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilgængelige tilknytninger"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du kan endnu ikke give feedback omkring ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har din helpdesk som den aktive fane."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette domæne kan endnu ikke bedømmes. Hvis det er tilfældet, bedes du underrette din administrator."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj dette domæne"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knyt til ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et øjeblik – vi opretter dig"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke hente URL-adressen"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værktøjer, som allerede er knyttet til organisationens Zendesk QA-konto."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen værktøjer knyttet til organisationens Zendesk QA-konto."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver dig mulighed for at bedømme websites, hvor URL-adressen altid forbliver den samme, ved at tilføje et unik hash til sidst, før der skrives en bedømmelse"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift konto"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytninger"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine domæner"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domæner, der allerede er knyttet til dette arbejdsområde."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan gennemgå enhver af følgende domæners sider"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domæner der kan bedømmes"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udvidelse"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unik URL-adresse"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der er endnu ingen helpdesk tilsluttet – gå til ", _interpolate(_named("link")), " for at tilføje en."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserudvidelsen Zendesk QA kan kun bruges\ni abonnementerne Professional og Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift arbejdsområde"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv et navn"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv en værdi"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefinerede felter"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værdi"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er første gang, vi støder på denne samtale. Giv lidt flere oplysninger."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalereference:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noget der kan identificere en ticket senere"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godtgørelse"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv både den ansvarlige og et emne"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opretter"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm denne samtale"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke-tildelt"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontakt din chef eller skift til et andet arbejdsområde end ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelse er deaktiveret for dette arbejdsområde."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ud"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På gensyn"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområde"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne URL-adresse kan ikke bedømmes. Naviger til en samtale."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log først ind på Zendesk QA via din SSO-udbyder og prøv derefter igen."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbner pop op-vindue for login."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med din Zendesk QA-konto"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent et øjeblik..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkender ..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du ikke en konto endnu?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke adgang til denne samtale. Hvis du burde have, bedes du kontakte din chef."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen adgang"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollér at din tilknytning er aktiveret i Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer dine tilknytninger"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytningsfejl"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naviger til en samtale i din helpdesk for at fortsætte"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig URL-adresse"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genoprettelse af forbindelse er næsten færdig."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et øjeblik"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at indlæse siden igen for at komme i gang igen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noget gik galt"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gør dig fri af din helpdesks virtuelle grænser og bedøm samtaler hvor som helst."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgrader dit abonnement for at bruge udvidelsen"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer dit abonnement"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tilknytningen er ikke knyttet til dette arbejdsområde"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din helpdesk genkender ikke denne ticket, eller Zendesk QA har ikke adgang til den"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke finde samtalen"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny aktivitet"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fået feedback"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du blev logget ind"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan nu bruge appen"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurder alle kategorier"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug denne til at sætte bogmærke i sager og filtrere fra senere"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen feedback på denne samtale"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs feedback"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbn i Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er her al feedback og alle notifikationer bliver vist. Intet endnu…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mere i Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne bedømmelser"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om samtale"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeholder alle"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er alle"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeholder ikke alle"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke alle"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedre end agenters"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["værre end agenters"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falsk"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er mere end"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeholder nogen af"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er enhver"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeholder ikke nogen af"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke nogen af"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sand"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overtrådt"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommenterede"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommenteret af mig"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["har ikke kommenteret"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke kommenteret af mig"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vigtigt til gennemsyn"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeholder"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste 30 dage"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inden for"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste 14 dage"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste 24 timer"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste 30 dage"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste 7 dage"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste måned"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seneste uge"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne måned"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne uge"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indtil"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i går"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registreret"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["findes"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stjernemarkeret af mig"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke stjernemarkeret af mig"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inkluderer"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mere end"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["begyndende fra"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindre end"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indtil"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke overtrådt"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke kompleks"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke registreret"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eksisterer ikke"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inkluderer ikke"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke modtaget"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke set"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positiv"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke bedømt"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke bedømt af mig"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømt"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømt af mig"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modtaget"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vigtigt til gennemsyn"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeholder"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mere end"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["begyndende fra"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindre end"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indtil"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indeholder ikke"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["set"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min kommentar har fået svar"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nej"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefinerede kategorier"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret spotlight"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter betingelse"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrikker"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagere"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse og feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis alle betingelser"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammenligner din bots håndtering af samtaler med en gennemsnitlig agents"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botten sidder fast i et loop, og den samme meddelelse bliver ved med at blive gentaget"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal bot-svar i samtaler"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En type bot, som er involveret i samtalen"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bot involveret i samtalen"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtaleindhold indeholder sætningen ", _interpolate(_list(0)), " i agentens meddelelse(r)"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificer automatisk opkald, der mangler den obligatoriske erklæring, såsom ”Dette opkald vil blive optaget” og lignende."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En person eller bot, som er involveret i samtalen"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den agent eller bot, der bliver bedømt i samtalen"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde søgte assistance på et højere niveau"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev registreret positive og negative stemninger i samtalen"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificerer den mest aktive agent i samtaler ved hjælp af AI-analyse"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om agenten afsluttede samtalen på en høflig måde"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om agenten forstår og anerkender kundernes følelser"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer agenters grammatik-, stave- og stilfejl"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for typiske hilsesætninger"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, om agenten forstår kundens forespørgsel eller bekymring"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, hvor nemt en tekst kan forstås, under hensyntagen til ordenes kompleksitet og sætningslængde"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for en tilbudt løsning"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer agentens tone under hele samtalen"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegn på kundeafgang. Kunden overvejede at skifte eller lovede at afslutte samarbejdet."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe, som samtalen tilhører."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indholdet eller teksten i agentmeddelelsen"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar i samtaler"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den agent, som har fået til opgave at bedømme samtalen"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det tidsrum, som opkaldet varede i sekunder"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalens indhold eller tekst"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kommunikationskanal, der bruges til samtalen (f.eks. e-mail, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer føjet til samtalen"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den dato, hvor en kommentar blev føjet til samtalen"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev registreret positive og negative stemninger i samtalen"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den dato, hvor kunden svarede på kundetilfredshedsundersøgelsen"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvorvidt samtalebedømmelsen er blevet bestridt"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kanal, som kunden brugte til at indlede samtalen"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal kundesvar i samtaler"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen for den sidste meddelelse i samtalen"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen eller typen af bruger, som er involveret i samtalen"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioden med stilhed mellem agenten og kunden overskred grænsen"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden søgte assistance fra person på højere niveau"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden udtrykte stor taknemmelighed eller var meget tilfreds med den modtagne support"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enten kunden eller agenten anmodede udtrykkeligt om en opfølgning"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teksten eller optagelse af hele samtalen, som foregik via personlig samtale"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om samtalen er stjernemarkeret"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags, der bruges i kommentarer til at kategorisere indhold"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalens sprog registreret af AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen for den sidste meddelelse i samtalen"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal private notater i samtaler"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlige antal tegn pr. meddelelse i samtaler"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal kundeorienterede svar i samtaler"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En deltager, hvis oplysninger er offentligt synlige"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitligt antal ord pr. meddelelse i samtaler"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier, der bruges til at vurdere eller evaluere samtalen"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for samtalens bedømmelsesproces"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En deltager eller person, som er involveret i samtalen"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En e-mail, der er knyttet til samtalen"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om bedømmelsen er blevet set"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den bedømmer, der vurderede samtalen"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen, hvor samtalen sidst blev bedømt"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den agent, der bliver bedømt i samtalen"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventuelle specifikke kommentarer eller feedback fra kunden angående tilfredshed med samtalen"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundetilfredshedsscore for samtalen"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundetilfredshedsscore pr. meddelelse i samtalen"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den resultattavle, der bruges til at bedømme samtalen"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De givne SLA'er er blevet overtrådt"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den type kilde eller forbindelse, som samtalen stammer fra"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen var usædvanlig eller atypisk, og der blev diskuteret frem og tilbage for at nå frem til en løsning"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsesspørgsmål havde relation til samtalens indhold"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titlen eller navnet på den undersøgelse, der er forbundet med samtalen"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den agent, som har fået til opgave at bedømme samtalen"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det brand eller specifikke produkt/service, der er forbundet med samtalen"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den kommunikationskanal, der bruges til samtalen (f.eks. e-mail, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen, hvor samtalen blev lukket eller løst"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen, hvor samtalen først blev oprettet"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen, hvor samtalen først blev oprettet"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den dato, hvor kunden svarede på kundetilfredshedsundersøgelsen"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kort beskrivelse af eller oversigt over samtalen"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et eksternt reference-id, der er knyttet til samtalen"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den mappe eller specifikke gruppering, som samtalen er organiseret i"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den formular eller skabelon, der bruges til at strukturere samtalen"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et eksternt reference-id, der er knyttet til samtalen"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den postkasse eller e-mailkonto, som samtalen blev modtaget igennem"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det telefonnummer, der er knyttet til samtalen"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det prioritetsniveau, som samtalen er blevet tildelt"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det samlede antal svar i samtalen"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilstanden eller aktuelle status for samtalen"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den aktuelle status for samtalen"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalens emne eller temaer"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar i samtaler"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags tildelt for at kategorisere samtalen"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalens type eller kategori"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen, hvor samtalen senest blev opdateret"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoen, hvor samtalen senest blev opdateret"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det medie eller den kanal, som samtalen fandt sted igennem"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indholdet af en afskrift i en samtale"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler, som ikke er egnet til bedømmelse via AI-analyse"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal deltagende agenter i samtaler"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om samtalen er blevet set"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhver meddelelse"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-kommunikationens effektivitet"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-gentagelse"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar fra bot"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-type"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retning for opkald"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientmeddelelse"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilsigtet stilhed"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om optagelse mangler"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optrapning"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afskriftsindhold for opkald"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutningshilsen"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stjernemarkerede"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke berettiget til bedømmelse"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt af"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale via"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavning og grammatik"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelelse"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mest aktive agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varighed for opkald (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalens indhold"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelseskanal"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentartag"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for kommentar"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemning"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svardato for CSAT-undersøgelse"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvist"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekanal"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal kundesvar"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste svardato"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugertype"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomsthilsen"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frafaldsrisiko"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilsigtet stilhed"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optrapning"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enestående service"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfølgning"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afskrift"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar-hashtag"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvigelse"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forståelse"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste svardato"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal private notater i samtale"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitligt antal tegn"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitligt antal ord"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal offentlige svar"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlig deltager"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for vurdering"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurdering og kommentar"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategori"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for bedømmelse"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læsbarhed"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltager"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relateret e-mail"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relateret"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse modtaget"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømt resultattavle"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelse set"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for bedømmelse"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der bliver bedømt"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar til undersøgelse"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT pr. meddelelse"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfredshedsscore (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løsning tilbudt"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kildetype"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emne"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsesspørgsmål"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsestitel"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale-brand"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand-domæne"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekanal"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for lukning"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svardato for CSAT-undersøgelse"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsendelsesdato for CSAT-undersøgelse"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalebeskrivelse"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksternt id"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalefelt"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalemappe"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleform"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-id"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalegruppe"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout-id"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksternt id"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-id"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalens postkasse"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleprioritet"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal svar på samtaler"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaletilstand"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalestatus"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleemne"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal agentsvar"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tag"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tag"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaletype"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdateringsdato"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdateringsdato"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-id"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afskriftsindhold"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal deltagende agenter"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visningsstatus"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sårbarhed i forhold til kapacitet"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sårbarhed i forhold til helbred"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sårbarhed i forhold til livsbegivenhed"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA-kategori"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en datorelateret betingelse"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj et navn til dit filter"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfyld et filternavn"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person, som er logget ind"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere medlemmer af arbejdsområdet"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer af arbejdsområde"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilføj ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend filter"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal have mindst 1 datorelateret filter"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg indstillinger"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foretag et valg"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode slutter om ", _interpolate(_named("n")), " dag. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode slutter <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>om ", _interpolate(_named("n")), " dag </strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode slutter om ", _interpolate(_named("n")), " dage. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode slutter <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>om ", _interpolate(_named("n")), " dage</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode slutter i dag. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din prøveperiode <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>i dag</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret et abonnement nu"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " dage"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("days")), " tilbage af din prøveperiode"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nået dit abonnements brugergrænse. ", _interpolate(_named("link")), " for at læse mere eller opgradere din plan."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik her"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilslut din helpdesk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil gøre det muligt for os at trække dine tickets ind, så du kan bedømme dem."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilslut din helpdesk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du bruger Zendesk QA med eksempeldata. ", _interpolate(_named("link")), ", hvis du vil slette prøvedata og importere dine egne samtaler."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksempeldata"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér nyttige oplysninger nedenfor"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag et helsides screenshot for at give kontekst"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt vores kundesupportteam for at løse dette problem:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noget gik galt"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlreference:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér nyttige oplysninger"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere, bots og arbejdsområder"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere og arbejdsområder"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne måned"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denne uge"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " bedømt ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " bedømt ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " bedømt ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad er det nye?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ud"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastaturgenveje"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat med os"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vores chat kunne ikke indlæses (bruger du måske en reklameblokker?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolpanel"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjælp"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startside"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivne bedømmelser"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne bedømmelser"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidensbase"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift konto"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applikationen blev opdateret. ", _interpolate(_named("buttonStart")), "Genindlæs", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil i gang med at bruge Zendesk QA som en del af dit arbejdsområde:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vi vil bare fortælle dig, at du allerede har modtaget en invitation til at bruge Zendesk QA som en del af et arbejdsområde. Hvis du vil, kan du naturligvis fortsætte, men bemærk, at dette vil oprette en ", _interpolate(_named("bold")), " for dig."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["separat organisation"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja, lad mig fortsætte med ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en ny konto"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " beder dig om at tilmelde dig ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen internetforbindelse"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny note"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurtig navigation"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fokusér og udvid kommentareditoren"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul eller vis sidepanel"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naviger til den næste samtale"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbn den oprindelige samtale"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naviger til den forrige samtale"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv alle kategorier den højest mulige vurdering"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv alle kategorier den lavest mulige vurdering"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bland samtaler (sortér tilfældigt)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv os feedback"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastaturgenveje"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå genvejsmodal til/fra"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nået tegnbegrænsningen på ", _interpolate(_named("charLimit"))])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billedet ", _interpolate(_named("name")), " af typen (", _interpolate(_named("type")), ") er ikke tilladt"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Billedstørrelsen for ", _interpolate(_named("name")), " er for stor (5 MB tilladt)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentareditor kunne ikke indlæses"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slip for at uploade"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billede"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer skabeloner"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke tilføjet nogen skabeloner"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj hovedårsagen"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet, der inkluderer slettede årsager"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag til vurdering"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternavn"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal medarbejdere"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi vil kontakte dig for at hjælpe dig med at konfigurere din konto"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmanavn"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktnummer til firma"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan hørte du første gang om Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en kanal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg et antal agenter"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfyld alle obligatoriske felter"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal acceptere at have læst og givet dit samtykke til vores betingelser og vores privatlivspolitik."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportdrevet Slack-kanal"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklame"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hændelse"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ven eller kollega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-markedsplads eller kontoadministrator"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henvisende website"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgemaskine (Google, Bing osv.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociale medier"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortæl os om dig selv og dit firma."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontooplysninger"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er klar."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kig rundt, lær appen at kende, slet og tilføj ting."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen er oprettet. Hvad skal der ske nu?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du klar til at komme i gang med dine rigtige samtaler?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj dine egne data"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå på opdagelse"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv den med eksempeldata"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Så vi kan begynde at trække dine tickets ind til bedømmelse."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyt din helpdesk-konto"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ændrede mening."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller tilmelding"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noten blev oprettet. ", _interpolate(_named("buttonStart")), "Gå til noter", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-noter er tilgængelige med abonnementet Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgrader abonnement"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle abonnementer"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem dine notater som forberedelse til den næste 1:1 session eller coaching-session."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter til coaching"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenter eller tilføj andre med ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne samtale er blevet slettet"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en ny note"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret note"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger note"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sådan fungerer noter"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke tilladelse til at se denne samtale"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør kort"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør samtale"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør kontrolpanel"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noten blev gemt"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør dette kort"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør denne samtale"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør dette kontrolpanel"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør under en bruger"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en bruger"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er kun dig, der kan se det, du fastgør. Til deling med andre ", "@", "nævn dem i kommentarerne."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " er nu inviteret til denne samtale"]), _normalize([_interpolate(_named("agentsNames")), " er nu inviteret til denne samtale"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 kommentar"]), _normalize([_interpolate(_named("n")), " kommentarer"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Du nævnte ", _interpolate(_named("participants")), " men vedkommende er ikke med i denne samtale."]), _normalize(["Du nævnte ", _interpolate(_named("participants")), " men vedkommende er ikke med i denne samtale."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " deltagere"]), _normalize([_interpolate(_named("n")), " deltager"]), _normalize([_interpolate(_named("n")), " deltagere"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vil du fjerne ", _interpolate(_named("agentName")), " fra adgangen til denne note?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gør ingenting"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter ikke vedkommende til denne samtale"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj kommentar"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter vedkommende"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter vedkommende til denne samtale"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bruger"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tråd"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan ikke fortrydes."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette sletter også alle kommentarer under denne note."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nye meddelelser"]), _normalize([_interpolate(_named("n")), " ny meddelelse"]), _normalize([_interpolate(_named("n")), " nye meddelelser"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er kun dig, som kan se dine noter. Hvis du vil dele noter, ", "@", " nogen i kommentarerne."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " fastgjort ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et kontrolpanel"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en samtale"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et notat"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dig"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgrader for at kunne skrive coaching-noter"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-noter er tilgængelige med abonnementet Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-noter er tilgængelige med abonnementet Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgrader for at få funktion"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forberede dig på din 1:1 session eller gem noget til et andet tidspunkt."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette sletter noten og alle tilføjede kommentarer permanent."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet note"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet note"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappenavn"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør ting, som du kan vende tilbage til senere"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyeste først"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ældste først"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem en interessant tendens eller samtale i kontrolpanelet som forberedelse til dine 1-1 sessioner, eller noter dem til en anden gang."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en note ved at klikke på knappen ovenfor eller fastgør ikonet i visningen af samtalen eller kontrolpanelet."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen noter"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ingen resultater for ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at bruge andre nøgleord eller kontrollér for stavefejl."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en ny note"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelser"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personer"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via bruger, nøgleord ..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetisk"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet for nylig"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigeret for nylig"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flest noter"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Jeg sigter mod at bedømme ", _interpolate(_named("n")), " samtale ", _interpolate(_named("schedule"))]), _normalize(["Jeg sigter mod at bedømme ", _interpolate(_named("n")), " samtaler ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål opdateret"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sæt mål"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan angive et personligt mål for antallet af samtaler, som du vil bedømme."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hver dag"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hver måned"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hver uge"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelsesmål"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONLIGT"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabeloner"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser kanaler"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg kanal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del kun feedback fra undersøgelser med kommentarer"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styrk dine kollegaer ved at fremhæve kvaliteten af deres enestående service og fremragende feedback fra kunder."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del positiv feedback fra undersøgelser til Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kanaler"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del automatisk feedback med ovenstående scorer"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationer med Ros-bot"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag en påmindelse i starten af ugen, hvis du ikke har foretaget nogen bedømmelser."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påmindelser"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugentlig påmindelse om at foretage bedømmelser"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag notifikationer om nye bedømmelser, svar, omtaler og tvister i Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtaler"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderinger"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar i tråde"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrationer"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underret om nye bedømmelser, svar, omtaler og tvister."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister, der vedrører mig"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtaler i kommentarer"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har modtaget nye bedømmelser"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar i tråde"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport med statistik"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag rapporter om afgivne og modtagne bedømmelser."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dagligt"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["månedligt"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ugentligt"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagligt"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver 3. time"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver 6. time"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjeblikkeligt"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fra"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugentligt"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kontoadministrator har låst indstillingerne for notifikationer."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg periode"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige notifikationer"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktiv)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj et nyt socialt login til denne konto:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner en aktiv login-metode, bliver du logget ud. Du kan logge ind igen med din alternative e-mailadresse."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern login-metode"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google er blevet tilføjet som login-mulighed"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login-muligheder"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack er blevet tilføjet som login-mulighed"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret adgangskode"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil adgangskode"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der vil blive sendt en e-mail til ", _interpolate(_named("email")), " for at nulstille din adgangskode."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primære e-mailadresse"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollér din indbakke for vores bekræftelsesmail"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk link"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For at ændre det skal du på forhånd ændre din ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-mailadressen ", _interpolate(_named("email")), " vil blive knyttet til denne adgangskode."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil er opdateret"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbillede"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload et firkantet billede (256x256 pixels eller derover) for at få det bedste resultat"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primær e-mailadresse"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start på ugen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige indstillinger"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foretag mindst ét valg"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabelonindhold"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabelonnavn"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis skabelon i"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj skabelonindhold"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et skabelonnavn"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret skabelon"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret skabelon"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kommentarskabeloner for at fremskynde bedømmelser, fastgøre elementer og coaching."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruges i"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet skabelon"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne skabelon permanent?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger skabelon"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kommentarskabeloner for at fremskynde bedømmelser, fastgøre elementer og coaching."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen skabeloner"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarskabelonen blev oprettet"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarskabelonen blev slettet"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarskabelonen blev gemt"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser og tvister"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontakt ", _interpolate(_named("link")), " for at få adgang."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kundesupport"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen konto"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benyt indtil videre ", _interpolate(_named("link")), " for at få adgang til Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vend tilbage til Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen blev ikke migreret"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-QA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrikker"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scorer"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistikker"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolpanel"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrering"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samtaler"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtre"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelse"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivne"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mine kommentarer"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mine tvister"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mine bedømmelser"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktioner"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påbegyndte"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback på undersøgelsen"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitet"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personligt"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabeloner"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opret adgangskode"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["legitimationsoplysninger"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailadresse"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailadresse"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nulstil"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelsesmål"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skabeloner"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruger"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret skabelon"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabeloner"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konto"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fakturering"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["firma"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationer"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaling"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenser"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indstillinger"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelser"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytninger"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny kategori"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev fundet modstridende meddelelser"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udskift eller fjern en af de modstridende meddelelser"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brug ikonet, ", _interpolate(_named("placeholder")), ", for at udelukke følsomme data fra Auto-QA. I stedet for ”Hav det godt. Hilsen John” kan du f.eks. bruge ”Hav det godt. ", _interpolate(_named("placeholder")), "\", der repræsenterer et navn."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalt ", _interpolate(_named("limit")), " rækker"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend regel på"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået grænsen på 10 brugerdefinerede kategorier"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nået grænsen på ", _interpolate(_named("limit")), " brugerdefinerede kategorier"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj betingelse"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj række"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet betingelse"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis agentmeddelelse"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellers score"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Så score"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kategori for resultattavle"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug '/' for at tilføje en variabel"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle viderestillinger"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelelse skal udfyldes"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelsen må ikke være på over 300 tegn"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kilder"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kanaler"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tags"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle samtaler"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begræns områder, hvor din regel gælder."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifikke samtaler"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret Spotlight-indsigt"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentmeddelelse skal udfyldes"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået grænsen på 10 brugerdefinerede indsigter"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har nået grænsen på ", _interpolate(_named("limit")), " brugerdefinerede indsigter"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfyld navn på indsigt"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på indsigt"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kategori"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsigtstype"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiv"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til indstillinger for arbejdsområde"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nu"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at komme i gang med at bruge denne vurderingskategori skal du gå til dit arbejdsområdes indstillinger for resultattavler og føje den til en resultattavle efter eget valg."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien blev oprettet"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv automatisk point til lukkede samtaler ved at identificere specifikke nøgleord eller vendinger."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fungerer ikke på afskrifter af tale."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Highfive! ✋ Du har nået dine automatiserede vurderingskategorier til ", _interpolate(_named("limit")), ". Du er kategoriseringens mester! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grænsen for kategorier er nået"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at alle felter er udfyldt korrekt"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv din vurderingskategori et navn"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorinavn"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny vurderingskategori"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny vurderingskategori"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv automatisk point til lukkede samtaler ved at identificere specifikke nøgleord eller vendinger. Fungerer ikke på afskrifter af tale."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få automatisk vist lukkede samtaler ved at identificere specifikke nøgleord eller vendinger. Fungerer kun på afskrifter."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv automatisk point til samtaler ved at identificere specifikke nøgleord eller vendinger i både agent- og bot-meddelelser."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil bruge denne vurderingskategori, skal du gå til dit arbejdsområdes indstillinger for resultattavler og føje den til en resultattavle."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv automatisk samtaler scorer baseret på specifikke nøgleord eller sætninger i meddelelserne."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgængelig for"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration af indsigt"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne indsigt vises i Spotlight baseret på definerede kriterier."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration af resultattavle"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne kategori kan bruges på resultattavler baseret på definerede kriterier."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommer snart"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brugerdefineret"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foruddefinerede"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultattavle"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tale"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem ændringer"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændringerne blev gemt"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik på ikonet ", "{··}", " for at udelukke navne, firmaoplysninger eller andre følsomme oplysninger under Auto-QA kontrol af afslutningshilsener."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj godkendt afslutningshilsen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger godkendt afslutningshilsen"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendt afslutningshilsen"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj undtagelser"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger undtagelse"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undtagelse"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj godkendt velkomsthilsen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger godkendt velkomsthilsen"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendt velkomsthilsen"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsæt ord her adskilt af linjeskift"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryk på Enter efter hvert ord for at få en ny indtastning"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gældende sprog"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I stedet for ”Hav det godt. Hilsen John” kan du f.eks. bruge ”Hav det godt. Hilsen ", "{··}", "” der repræsenterer et navn."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pladsholder for dynamisk indhold"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undtagelser"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 undtagelse tilføjet"]), _normalize([_interpolate(_named("count")), " undtagelser tilføjet"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Undtagelse, der allerede er tilføjet"]), _normalize(["Undtagelser, der allerede er tilføjet: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj undtagelse"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen undtagelser"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke tilføjet nogen undtagelser"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undtagelse (skelner mellem store og små bogstaver)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tilføj undtagelser"]), _normalize(["Tilføj 1 undtagelse"]), _normalize(["Tilføj ", _interpolate(_named("count")), " undtagelser"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter et ord eller en sætning"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undtagelsen blev gemt"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sprog"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sprog"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter sprog"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Afslutningshilsen, der allerede er tilføjet"]), _normalize(["Afslutningshilsener, der allerede er tilføjet: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj afslutningshilsen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen godkendte afslutningshilsener"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen godkendte afslutningshilsener"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutningshilsen"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj afslutningshilsen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter en afslutningshilsen"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutningshilsen tilføjet"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutningshilsen gemt"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formularen har identiske fortegnelser: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Velkomsthilsen, der allerede er tilføjet"]), _normalize(["Velkomsthilsener, der allerede er tilføjet: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj velkomsthilsen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen godkendte velkomsthilsener"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen godkendte velkomsthilsener"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomsthilsen"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj velkomsthilsen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter en velkomsthilsen"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomsthilsen tilføjet"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomsthilsen blev gemt"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj undtagelse"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Slet undtagelse"]), _normalize(["Slet undtagelser"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vil du slette denne undtagelse permanent?"]), _normalize(["Vil du slette disse undtagelser permanent?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger undtagelse"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En eller flere undtagelser er på over ", _interpolate(_list(0)), " tegn"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " tegn"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdateret"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdateret af"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabel"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer dine egne afslutninger, der stemmer overens med dit brand og dine servicestandarder. Når man tilføjer Auto-QA, registrerer den kun godkendte afslutningshilsener. Ændringerne gælder for fremtidige samtaler."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået den øvre grænse på 100 godkendte afslutningshilsener. Hvis du vil tilføje flere, er det måske på tide at revidere og finjustere dine eksisterende afslutningshilsener."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grænsen for godkendte afslutningshilsener er nået"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendte afslutningshilsener"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord og vendinger, som du gerne vil have Auto-QA til at ignorere som stave- eller grammatikfejl. Ændringerne gælder for fremtidige samtaler."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer liste"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv dine egne velkomsthilsener, der stemmer overens med dit brand og dine servicestandarder. Når Auto-QA tilføjes, registrerer den kun godkendte velkomsthilsener. Ændringerne gælder for fremtidige samtaler."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået den øvre grænse på 100 godkendte velkomsthilsener. Hvis du vil tilføje flere, er det måske på tide at revidere og finjustere dine eksisterende velkomsthilsener."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grænsen for godkendte velkomsthilsener er nået"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendte velkomsthilsener"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke nu"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kategori"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne vurderingskategori permanent?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift kategoriliste"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettede kategorier vises ikke i nye samtaler og filtre. Historiske data i kontrolpaneler og samtaler forbliver intakte."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien vil blive fjernet fra alle resultattavler, som den er blevet føjet til."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det lykkedes ikke at slette kategorien for resultattavlen"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien for resultattavlen blev slettet"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kategorien for resultattavlen"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA-kategorier"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), ", der er drevet af AI-modellen, forbliver på resultattavlen, men vil ikke længere automatisk blive rangordnet"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivering af LLM-integration vil ikke deaktivere Auto-QA-funktionen fuldstændigt. Dette vil der komme til at ske:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske data"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " forbliver på Auto-QA-kontrolpanelet"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelt tilføjede hovedårsager"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " forbliver, men alle grundlæggende LLM-baserede årsager vil blive fjernet"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver LLM-baseret Auto-QA"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivér"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA bruger AI og store sprogmodeller (LLM´er) til automatisk at rangordne specifikke kategorier. Dine data behandles under en streng databehandleraftale (DPA) og bruges aldrig til at oplære nogen modeller."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om Generativ AI"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Auto-QA bruger Microsoft Azure OpenAI Service til ", _interpolate(_named("automatically_scoring_categories")), ". Dine data behandles under en streng databeskyttelseslovgivning (DPA) for kun at levere Auto-QA til dig og ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatisk pointtildeling til visse kategorier"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruges aldrig til træning af OpenAI-modeller"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-baseret Auto-QA er aktiveret"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-baseret Auto-QA er deaktiveret"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-baseret Auto-QA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiserede resultater med AI i Auto-QA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databehandling af MS Azure-baserede Auto-QA-kategorier"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykket til Microsoft Azure er trukket tilbage"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er givet samtykke til Microsoft Azure. Samtaler vil snart modtage automatiske vurderinger."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan stadig bruge Auto-QA, men her kan du se, hvad der vil ske"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivering af MS Azure OpenAI-service vil ikke deaktivere Auto-QA som en funktion."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det kan du gøre i resultattavlens kategorivisning."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA-kategorier, der drives af modellen, forbliver på resultattavlen, men vil ikke automatisk få tildelt point."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De grundlæggende årsager, som du tilføjede manuelt, vil bestå. Alle model-genererede hovedårsager vil blive fjernet."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske data forbliver på Auto-QA-kontrolpanelet."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver databehandling med MS Azure OpenAI-service"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bredere udvalg af Auto-QA-kategorier."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flersproget support der er klar til brug."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurtig tilføjelse af nye kategorier."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbedret evalueringsnøjagtighed. GPT-3.5 viser kontekstafhængig forståelse på menneskeligt niveau."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag fordel af de seneste fremskridt inden for AI-teknologi."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er, hvad du får"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivér databehandling af MS Azure OpenAI-service"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke givet"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke givet samtykke"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle nye brugere tildeles dette arbejdsområde"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardarbejdsområde"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tidssystem med 12-timer (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tidssystem med 24-timer (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsformat"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søn"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmaprofilen blev opdateret"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start på ugen"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret integration"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangsnøgle-id og hemmelig nøgle findes typisk under Adgangsstyring > Brugere"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangsnøgle-id til AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller ændring af hemmelig adgangsnøgle"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift værdi for hemmelig adgangsnøgle"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytningen tillader kun at definere ét område, som typisk findes under Lokalitet"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-region"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en hemmelig nøgle"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemmelig adgangsnøgle til AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan finde alle relevante oplysninger i Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Findes typisk under indstillinger for forekomst og datalagringsside i Amazon Connect"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerplacering for opkaldsoptagelser"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl under tilføjelse af Amazon Connect. Kontrollér om alle felter er udfyldt korrekt og prøv igen."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerplacering skal have formatet kurv/mappe adskilt af en skråstreg."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil tilføje en tilknytning, skal du kontakte ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundesupport"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle dine Zendesk-tickets vil automatisk blive synkroniseret. Du kan vælge at udelukke valgte tickets."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien og Stillehavsområdet (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien og Stillehavsområdet (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien og Stillehavsområdet (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien og Stillehavsområdet (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asien og Stillehavsområdet (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (Central)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverområde"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellemøsten (De Forenede Arabiske Emirater)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg serverområde"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sydamerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østlige USA (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østlige 2 USA (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestlige USA (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytningen er allerede tilføjet"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start tilknytning"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser kun samtaler, som"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanal et, kanal to"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytning"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tilknytnings unikke id er ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 måned"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 år"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 måneder"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 måneder"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive samtaler vil blive slettet efter den valgte opbevaringsperiode. Indsendte bedømmelser forbliver synlige i Aktivitets- og Kontrolpanelvisninger."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevaringsperiode"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg opbevaringsperiode"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk, at denne handling ikke kan fortrydes."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg forstår, at dette vil fjerne alle helpdesk-relaterede data for denne tilknytning fra Zendesk QA, herunder samtaler, felter og tags."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsendte bedømmelser vil ikke blive påvirket og vil forblive synlige i specifikke visninger."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gruppe et, gruppe to"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkluder"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiv udvikling"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne integration er stadig i ", _interpolate(_named("activeDevelopment")), ". Nogle oplysninger mangler måske (såsom vedhæftede filer eller brugerdefinerede felter)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["postkasse et, postkasse to"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag et, tag to"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-tokens hemmelighed"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ”Navne på ticket-felter” på Freshdesk under Indstillinger → Ticket-felter"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ”Custom Field Names” på Help Scout under Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ”Klass for samtalenavne” på Kustomer under Indstillinger → Klasses → Samtale"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ”Navn på brugerdefineret felt” på Live Agent under Indstillinger → Brugerdefinerede felter"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ”Custom Field Name” på Wix under Settings → Custom fields"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adskil felt-id'er med kommaer. Du kan finde disse id’er i Zendesk under Administrator → Ticket-felter."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbind nogle få af dine helpdesk-brugere for at bedømme dine første samtaler. Bare rolig, vi underretter dem ikke, medmindre du inviterer dem til at blive medlemmer af arbejdsområdet."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blev integreret"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj medlemmer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi vil nu begynde at trække dine samtaler."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domæne: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Helpdesk-identifikator: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke godkende din tilknytning"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdigt arbejde. Dette er din nye tilknytning."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visse tilknytninger er ikke blevet opdateret eller er blevet inaktive. Prøv at opdatere disse tilknytninger eller fjern dem fra din liste."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-klient-id"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-klients hemmelighed"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opret et nyt API-token fra Indstillinger → Konto → API og SDK'er. Den URL-adresse til omdirigering, der skal bruges, er ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto-id"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg din helpdesk for at komme i gang"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis din Intercom-konto er konfigureret til at blive betjent fra EU eller Australien, skal den opdateres."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-mailadresse, som du bruger til at logge ind på ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul slutbrugerens navn, e-mailadresse og telefonnummer. Banklegitimationsoplysningerne er allerede maskeret for alle helpdesk-tilknytninger."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endnu ingen integrationer. Tilføj én nu."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal bruge disse roller:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret tilknytning"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny tilknytning"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner afkrydsningen fra dette felt, kan du manuelt føje denne forbindelse til visse specifikke arbejdsområder"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret forbindelse til alle eksisterende arbejdsområder"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbejdsområder"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-nøgle"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-nøgles hemmelighed"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaler"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tilknytningen ", _interpolate(_list(0)), " blev slettet"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytningens navn"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsestidspunkt"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan få fat i din API-token nedenfor."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-tærskel"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet tilknytningen ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv ”slet tilknytning” for at fjerne denne tilknytning"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet tilknytning"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer og overvåg dine tilknyttede datakilder."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger tilknytning"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udeluk"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adskil feltnavne med kommaer"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdatering mislykkedes"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv din integration et unikt navn"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul avancerede indstillinger"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul følsomme datafelter"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevar aldrig vedhæftede URL-adresser"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer vedhæftede filer"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevar aldrig samtaleindhold (om ønsket, kan du senere hente det fra din helpdesk)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer samtaleindhold"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevar aldrig samtaleemne (om ønsket, kan du senere hente det fra din helpdesk)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer emnelinje"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Underdomæne for ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-område"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match team-id med indbakke"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerisk id for dit intercom-team"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I gang..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postkasser"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater tilknytningen manuelt"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul kundedata"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbejdsområder"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på følsomt felt"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id for følsomt felt"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forny tilknytning"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forny tilknytning"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etabler forbindelse til Salesforce sandbox"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konfiguration af en forbindelse med ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis avancerede indstillinger"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag kun samtaler med en score, som er mindre end eller lig med den angivne tærskel"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend en CSAT-tærskel"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser kun samtaler, der ..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hent de seneste samtaler"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytninger"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater tilknytning"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytningen blev opdateret"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugernavn"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din helpdesk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For at opsige dit abonnement og slette din konto skal du kontakte ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundesupport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet konto"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning af en ticket i Zendesk Support (eller en anden helpdesk) påvirker ikke tilknyttede gennemgange af samtaler. Du kan stadig se og administrere dem."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sletning af en ticket i Zendesk QA sletter også tilknyttede samtalebedømmelser.", _interpolate(_named("line_break")), "Sletning af en ticket i Zendesk Support (eller en anden helpdesk) påvirker ikke tilknyttede samtalebedømmelser. Du kan stadig se og administrere dem."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer bedømmelser"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning af bedømmelser er permanent. Det påvirker også kontrolpanelers data."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle bedømmelser"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bedømmelser blev slettet"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når der er knyttet bedømmelser til slettede tickets, kan du se dem her."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endnu ingen bedømmelser"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter ticket-id"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket-id: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettede samtaler"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Standardarbejdsområder kan ikke slettes. Hvis du vil slette dette arbejdsområde, skal du vælge et andet standardarbejdsområde i ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindstillinger"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling kan ikke fortrydes."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjerne og slette alle data"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg forstår, at dette vil ", _interpolate(_named("removeAllData")), " med relation til dette arbejdsområde, herunder brugere af arbejdsområder og tilknytninger."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet arbejdsområde"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv ”slet arbejdsområde” for at slette dette arbejdsområde"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet arbejdsområdet ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slet arbejdsområde"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær sikker på, hvad du laver, eller kontakt en professionel på forhånd."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg, om personer der bliver bedømt, som standard skal vælges baseret på den agent, der hyppigst forekommer, eller den aktuelt ansvarlige."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mest aktive agent"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardperson der bliver bedømt"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lad personer gennemgå deres egne samtaler og giv agenter mulighed for at give bedømmelser"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområdet er blevet slettet"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområdet er blevet opdateret"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en neutral karaktergivning ved at skjule bedømmelser, som tidligere er givet af andre bedømmere"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektiv karaktergivning"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farve"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling kan ikke fortrydes."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk, at dette vil fjerne og slette alle data, der er relateret til dette arbejdsområde, herunder brugere af arbejdsområder og tilknytninger."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet arbejdsområde"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletter arbejdsområde ..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelser"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk link"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisk link"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke deaktivere din aktuelle login-metode"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelsesmetoder er blevet opdateret"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillad visse typer af godkendelsesmetoder for alle brugere."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelser"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTO"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere og arbejdsområder"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret og administrer arbejdsområder, og inviter eller tilknyt brugere."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bliv på siden"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forlad siden"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du forlade denne side og miste alle ændringer?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytninger"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelser"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ud til, at der ikke er nogen samtaler at importere. Start med at have nogle samtaler med din helpdesk, og vend derefter tilbage hertil for at analysere dem."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen samtaler"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke slette denne systemkategori"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kategori"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning af kategorier er permanent. Den sletter alle kategoridata fra rapporteringen."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil genoprette denne kategori, skal du oprette den på ny."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kategori"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter kategori"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavler"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiske kategorier udfylder automatisk resultattavlen med foreslåede vurderinger for at spare tid på bedømmelser. Manuelle kategorier udfyldes fra bunden af bedømmere."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senest opdateret"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutningshilsen"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavning og grammatik"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomsthilsen"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forståelse"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læsbarhed"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løsning tilbudt"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kategorier"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen hovedårsager"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen resultattavler"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv en anden søgning eller brug nogle andre filtre"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kategorier"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorier"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultattavler"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultattavler"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultattavler"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle resultattavler"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " resultattavler"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusser"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusser"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusser"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle statusser"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusser"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle typer"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typer"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbejdsområder"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbejdsområder"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen arbejdsområder"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbejdsområder"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbejdsområder"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arbejdsområder"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En vægt på ", _interpolate(_named("weight")), " bidrager med ", _interpolate(_named("percentage")), " % til den samlede score"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet resultattavle"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning af resultattavler permanent. Den sletter alle resultattavlens data fra rapporteringen."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil genoprette denne resultattavle, skal du oprette den på ny."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet resultattavle"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senest opdateret"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladde"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedårsager"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavler"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vurderingsskalaen blev opdateret. ", _interpolate(_named("buttonStart")), "Begynd bedømmelse", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA angiver som standard en binær skala, men du kan også vælge en mere detaljeret skala:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du ønsker at se forskellige valgmuligheder for vurderingsskalaer, konfigureret efter kategori, skal du gå til dine ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati/Tone"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkendskab"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved at opdatere vurderingsskalaerne:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din tidligere vurderingsskala og de tilsvarende kategorier vil blive arkiveret (ikke slettet)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visse kontrolpaneldata vil blive påvirket, eftersom din gamle og nye skala måske ikke er sammenlignelige."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klon mine eksisterende konfiguration af vurderingskategorier til den nye skala"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil opdatere vurderingsskalaerne?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ændre visningsformatet for resultattavlen (tal eller emojis) og gøre kategorier obligatoriske eller valgfrie."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug emojis i stedet for tal"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillad at personer springer kategorier over"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løsning"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskalatype"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kategorier for 1 arbejdsområde er blevet klonet"]), _normalize(["Kategorier for ", _interpolate(_named("workspacesAmount")), " arbejdsområder er blevet klonet"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger for arbejdsområde"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis automatisk opkald, hvor agenten ikke giver kunden besked om at forvente stilhed i samtalen."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen har øjeblikke med utilsigtet stilhed, som varer længere end den angivne tærskel."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer den maksimale tærskel for utilsigtet stilhed i opkald"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilsigtet stilhed"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet indsigt bliver ikke vist i nye samtaler og filtre. Historiske data i kontrolpaneler og samtaler forbliver intakte."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke slette Spotlight-indsigt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-indsigt blev slettet"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet Spotlight-indsigt"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se samtaler, der indeholder specifikke ord eller vendinger i afskrifter og meddelelser."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificer automatisk opkald, der mangler den obligatoriske erklæring, såsom ”Dette opkald vil blive optaget” og lignende."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigter mod at identificere en erklæring i opkaldet om at opkaldet bliver optaget ved brug af en Large Language Model (LLM), der dynamisk forstår konteksten, hvilket eliminerer behovet for forudindstillede sætninger."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om optagelse mangler"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis automatisk opkald, hvor agenten ikke giver kunden besked om at forvente stilhed i samtalen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilsigtet stilhed"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv øjeblikke med utilsigtet stilhed i et opkald"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Har du brug for at foretage ændringer i dit abonnement? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), "), og vi står klar til at hjælpe."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysér automatisk 100 % af samtalerne på tværs af agenter, BPO'er, kanaler og sprog. AI registrerer problemer, videnshuller og coaching-muligheder for at forbedre servicen."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA 100 % dækning"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentfeedback og coaching"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-indsigt i Spotlight"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanceret rapportering og kontrolpaneler"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemningsanalyse og -filtrering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand oversættelser"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm opgaver og målsætning"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching og quizzer"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk kvalitetssikring (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt fra Professional plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks til øjeblikkelig notifikation ved alvorlige fejl"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration af datalager"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration af Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udvikling af brugerdefineret integration"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedikeret kundesucceschef"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte adgang til vores produkt- og teknikteams via Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt fra Growth Suite plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA for 100 % dækning"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-drevne samtaleindsigter, der kan få data til at give mening"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-forbedret databerigelse og filtrering af helpdesk"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Automatiseret registrering af interaktioner, som skal bedømmes"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemningsanalyse og -filtrering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-dreven rapportering af og indsigt i performance"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand oversættelse af samtale"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemgå bedømmelsestid"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser af beståelsesprocent"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt i AI Suite plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementeringspakke til virksomheder inklusiv integration af Salesforce og Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udvikling af brugerdefineret integration"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration af datalager"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks til øjeblikkelig notifikation ved alvorlige fejl"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedikeret kundesucceschef"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte adgang til vores produkt- og teknikteams"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidlig adgang til og indflydelse på vores køreplan"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk bedømmelse af opgaver og målsætning"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målrettet coaching og analyse"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer til læringsstyring"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration af brugerroller og tilladelser"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følsomme data skjules"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede filtreringsindstillinger og samtalesøgning"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefinerbare resultattavler med flere forskellige vurderinger, korrigerbar vægtning og indstillinger for automatisk dumpning"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserudvidelse til at bedømme samtaler hvor som helst"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse af hovedårsager"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportering af performance til sammenligning af agenter, teams, udlicitering af forretningsprocesser (BPO´er)"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-undersøgelse og analyser af feedback fra kunder"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt fra Starter plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere forskellige vurderingsskalaer pr. samtale"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integration"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-drevet interaktiv samtaleindsigt"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedøm opgaver"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter til coaching"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching-session"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stemningsanalyse og -filtrering"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrering baseret på samtalens kompleksitet"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaleindsigt"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingede resultattavler"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse af hovedårsager"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede AI-drevne CSAT-analyser"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering af karaktergivning"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserudvidelse til bedømmelse af samtalen hvor som helst"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight for at finde samtaler, der skal bedømmes"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand oversættelse af samtale"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemgå bedømmelsestid"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret abonnement"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubegrænset antal samtalebedømmelser"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-eksport"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer-to-peer og selvbedømmelser"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle med korrigerbar vægtning og indstillinger for automatisk dumpning"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robuste filtreringsmuligheder"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daglige, ugentlige eller månedlige bedømmelsesmål"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-undersøgelser og grundlæggende analyser"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolpaneler"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter til sammenligning af agenter, teams, BPO'er"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration af brugerroller og rettigheder"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ønsker at foretage en ændring i mit Zendesk QA-abonnement.\n\nFremhæv eller angiv en mulighed og angiv det antal licenser, du ønsker at ændre:\nJeg vil gerne [tilføje X licenser] [fjerne X licenser] [opgradere til et Zendesk QA-abonnement]\n\nDel dit firmanavn og det navn, der er knyttet til din Zendesk QA-konto samt andre oplysninger, som vi bør kende for at kunne behandle denne ændring."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændring af Zendesk QA-abonnement"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg planlægger at købe et Zendesk QA-abonnement.\n\nDel dit firmanavn og det navn, der er knyttet til din Zendesk QA-konto samt andre oplysninger, som vi bør kende for at behandle denne anmodning:\n\nDel også specifikke spørgsmål, som du ønsker besvaret, før du køber:\n\nTak!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb af et Zendesk QA-abonnement"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit abonnement udløber d. ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " tilbage)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din prøveperiode slutter d. ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " tilbage)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 dag"]), _normalize([_interpolate(_named("n")), " dage"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("dayOfMonth")), ". hver måned."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("dayOfMonth")), ". hver måned."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faktureret til ", _interpolate(_named("card")), " (udløber ", _interpolate(_named("expires")), ") d. ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["som slutter den"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Udløbet"]), _normalize(["Udløber om 1 dag"]), _normalize(["Udløber om ", _interpolate(_named("expiresIn")), " dage"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startprisen omfatter ", _interpolate(_named("n")), " brugerlicenser"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["starter med ", _interpolate(_named("sum")), "/månedligt"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måned"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pr. bruger/månedligt"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pr. år"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pr. måned"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kvartal"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjern den planlagte annullering"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " hvis du vil fortsætte med at bruge Zendesk QA"])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit abonnement blev opsagt d. ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit abonnement er planlagt til at udløbe d. ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dages prøveperiode uden ekstra omkostninger!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brugt"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("n")), " brugere. Antal licenser må ikke være lavere end det."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnementet vil blive genoptaget d. ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["år"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit nuværende abonnement på Zendesk QA (", _interpolate(_named("plan")), ") omfatter ikke AI-drevne funktioner. ", _interpolate(_named("link")), ", vil give dig mulighed for fuldt ud at udnytte styrken ved taleintelligensfunktioner."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontakt os ", _interpolate(_named("link")), " for at opsige dit abonnement eller chat med os."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement for kvalitetsstyring"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetsstyring"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementet er opdateret"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et kreditkort"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle abonnementer"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturering"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se faktureringsoplysninger"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureringsinterval"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsig abonnement"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit abonnement vil blive opsagt, når faktureringsperioden udløber."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt os"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt os for at redigere"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelt abonnement"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret prisfastsættelse"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedgrader"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt (spar 15 %)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal årligt"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger abonnement"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find abonnementer"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis prøveversion"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureres manuelt"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan gennemse og bekræfte den endelige ordreoversigt i det næste trin."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mest populære"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke en aktiv betalingsmetode."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På prøve"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genoptag dit aktuelle abonnement for at ændre abonnementet"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sæt abonnement på pause"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvartårligt"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genaktiver abonnement"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genoptag abonnement"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenser"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start prøveperiode nu"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulleret"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornys ikke"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På pause"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsstatus"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøveperiode"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgradér"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgradér abonnement"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug et minut på at give feedback om din oplevelse med kundesupport. På forhånd tak – ", "{", "{", "agentName", "}", "}", " værdsætter din feedback!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hej ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-undersøgelse for ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan vil du vurdere den support, som du modtog fra ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Det sætter vi stor pris på. Vi vil bruge din feedback til at gøre ", _interpolate(_list(0)), " bedre for alle."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om undersøgelser"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg undersøgelsestype"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette kan ændres senere."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål og forbedr kundetilfredsheden med automatiserede CSAT-undersøgelser på flere sprog."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen undersøgelser"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret undersøgelse"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på undersøgelse"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelse er blevet oprettet"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøv at ændre nøgleordet eller ", _interpolate(_named("contact_us")), " hvis du ønsker at se den opført her"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giv os besked"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet noget sprog"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vælg et eller flere sprog fra listen nedenfor. ", _interpolate(_named("contact_us")), " hvis du ikke kan finde et sprog på denne liste, som du ønsker at bruge."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj sprog"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv os besked"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj sprog"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Senest udgivet ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Senest gemt ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-spørgsmål"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduktionsspørgsmål"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket for venstre side af skalaen"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailemne"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsager"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket for højre side af skalaen"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis en undersøgelse har manglende oversættelser, vises udgangssprogets tekster i stedet for"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend oversættelser"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manglende oversættelser"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sørg for at gennemgå alle dine oversættelser, før du anvender dem. Foretag ændringer i den downloadede fil, gem den og upload om nødvendigt igen. Hvis et token ", _interpolate(_named("token_example")), " ikke vises som et tag, skyldes det enten et forkert format eller at feltet ikke understøtter tokens."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemgå oversættelser"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversat"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsummerer samtalen og viser forslag til kommentarer."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-assistance i Zendesk QA (på engelsk)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan redigere typer, når de er blevet oprettet"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne type findes allerede. Vælg et andet navn."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem den brugerdefinerede type som en valgbar indstilling i rullemenuen Undersøgelsestype, og grupper alle oprettede undersøgelser i kontrolpanelet."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast et eller flere ord, og tryk på Enter for at gemme"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger brugerdefinerede typer"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på type skal udfyldes"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer type"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret type blev ikke gemt. Tryk på Enter for at gemme den."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsenders e-mailadresse"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsenders navn"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I hvor høj grad er du enig i udsagnet: ”", _interpolate(_named("agentName")), " gjorde det nemt at håndtere mit problem”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvor nemt har det indtil videre været at få hjælp fra ", _interpolate(_named("brandName")), "?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor nemt har det samlet set været at få løst dit problem i dag?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor nemt har det været at interagere med vores team?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-adresse til Privacy Policy (Privatlivspolitik) (valgfrit)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-adresse til servicebetingelser (valgfrit)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvordan vil du vurdere den support, som du modtog fra ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan var vores service i forhold til denne anmodning?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor tilfreds var du med supportrepræsentanten?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvor tilfreds er du med den service, du modtog fra ", _interpolate(_named("agentName")), " i dag?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret spørgsmål"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definer dit eget brugerdefinerede ", _interpolate(_named("type")), "-spørgsmål..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast brugerdefineret spørgsmål"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpas URL-adresser"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv din e-mailmeddelelse"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigering af navnet på en brugerdefineret spørgsmålstype vil opdatere den i alle undersøgelser, som bruger den, uden at det påvirker de indsamlede data."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger brugerdefinerede typer"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailemne"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduktionsspørgsmål"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-spørgsmål"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret ny brugerdefineret type"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indhold"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Personaliseringstoken"]), _normalize(["Personaliseringstokens"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Træk og slip personaliseringstokenet i feltet nedenfor."]), _normalize(["Træk og slip personaliseringstokens i felterne nedenfor."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Samtale ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I øjeblikket er det kun muligt at udsende CSAT-undersøgelser via e-mail. Vi arbejder på at give dig flere muligheder, såsom CSAT-helpdesk-undersøgelser, SMS-undersøgelser med mere."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synkroniser eller forny tilknytningen"]), _normalize(["Synkroniser eller forny tilknytningerne"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser eller forny tilknytningen ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tilknytningen er ikke synkroniseret korrekt"]), _normalize(["Tilknytningerne er ikke synkroniseret korrekt."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fjerne markeringen i det relevante afkrydsningsfelt ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkend nu ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj mindst én regel for udsendelse af undersøgelser for at aktivere tilknytningen"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tilknytning er i øjeblikket deaktiveret. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tilknytning skjuler følsomme klientdata. For at aktivere udsendelse af undersøgelser\nskal du ", _interpolate(_named("link")), " i indstillingerne."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne tilknytning kræver yderligere tilladelser for at kunne udsende undersøgelser.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkend nu"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA kræver flere tilladelser til Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " og ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 time"]), _normalize([_interpolate(_named("n")), " timer"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minutter"]), _normalize(["1 minut"]), _normalize([_interpolate(_named("n")), " minutter"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["time"]), _normalize(["timer"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " for at konfigurere regler for afsendelse af undersøgelser."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" dag"]), _normalize(["dage"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links til undersøgelsen udløber"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links udløber om"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undgå at undersøgelser bliver besvaret, hvis der er gået for lang tid"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " undersøgelse ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adskil tags med kommaer, eller tryk på Enter efter skrivning. Sørg for, at hver enkelt er angivet nøjagtigt som i Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv de betingelser, som undersøgelsen sendes ud på baggrund af."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler for udsendelse af undersøgelse"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfølgningsregler for udsendelse af undersøgelse"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En undersøgelse ved navn ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " findes allerede.\nLøs konflikten ved at ændre reglerne enten her eller i ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har allerede konfigureret en forbindelse med matchende regler for ", _interpolate(_named("name")), " undersøgelse. Løs konflikten ved at ændre reglerne enten her eller i ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en anden regel"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj brand"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg betingelse"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter en værdi"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matcher de samme regler"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en værdi"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail sendt ud via"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfølgning på mail sendt ud via"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integreret snippet og e-mailopfølgning"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér kode"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedhæft Zendesk QA-undersøgelser til dine e-mails ved at føje snippet-koden til din Zendesk-konfiguration."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsvejledning"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbn Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisning"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisning af snippet"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippet-kode kopieret til udklipsholder"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiering af snippet mislykkedes"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integreret snippet"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne undersøgelse har ændringer, der ikke er gemt. Gem den igen for at sikre, at snippet-koden afspejler de seneste opdateringer."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj tilknytning"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke tilføjet nogen tilknytninger"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Træk e-mail, live chat, telefonopkald med mere til Zendesk QA sammen med dine tilknytninger"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om levering af undersøgelser"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler for udsendelse af undersøgelse"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfølgningsregler for udsendelse af undersøgelse"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj brandregel"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj regel"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkend"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opfølgning på Messenger + e-mail"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbind din intercom-konto med CSAT-undersøgelser i Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveres via"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem modtager undersøgelsen og hvornår"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger regler"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveret"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser i appen giver dine kunder mulighed for at besvare en undersøgelse uden at skulle forlade chatten."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsregler pr. tilknytning"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En undersøgelse ved navn ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " findes allerede.\nLøs konflikten ved at deaktivere tilknytningen eller ved at ændre reglerne enten her eller i ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv de betingelser, som undersøgelsen sendes ud på baggrund af"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oprettet af ", _interpolate(_named("name")), " d. ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fjern ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du fjerner dette sprog, betyder det, at det ikke længere vil være synligt for nogen respondenter, og alle de oversatte tekster bliver slettet."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern sprog"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling kan ikke fortrydes."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du deaktivere sproget ved at skjule det fra undersøgelsen. Dette giver dig mulighed for at bevare alle dine oversættelser uden at vise dem til respondenterne."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Senest redigeret af ", _interpolate(_named("name")), " d. ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om undersøgelsessprog"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Før du tilføjer andre sprog, skal du sørge for, at du er færdig med at foretage ændringer i trinene for design og indhold. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj sprog"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversatte felter"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig for respondenter"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download oversættelsesfil"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern sprog"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" download en oversættelsesfil"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemgå oversættelser"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oversættelsen er fuldført. ", _interpolate(_named("review_link")), " eller ", _interpolate(_named("download_link")), " for at foretage ændringer."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" download en oversættelsesfil"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemse, hvad der mangler"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Noget indhold er endnu ikke oversat og her vil blive brugt engelsk. ", _interpolate(_named("review_link")), " eller ", _interpolate(_named("download_link")), " for at tilføje manglende oversættelser."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem undersøgelsen"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Undersøgelsen indeholder ændringer, der ikke er gemt. ", _interpolate(_named("save")), " for at downloade oversættelsesfilen med de seneste ændringer."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload oversættelsesfil"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en foretrukken vurderingsskala"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver kunder en rullemenu, så de kan vælge årsagen til vurderingen"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst i venstre side af skalaen (valgfrit)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tal"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj årsag"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag skal udfyldes"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne årsag findes allerede"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis rullemenuen for årsager til din score"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning af en årsag, der dernæst tilføjes igen med det samme navn eller omdøbning af en årsag, opretter altid en ny post. Som følge heraf vil det påvirke de data, der vises på dit kontrolpanel."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet årsag"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En slettet årsag forbliver i kontrolpanelets rapportering, men vil ikke være synlig for nye undersøgelsesrespondenter. Dette gælder også for de dertilhørende oversættelser."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet en årsag"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slet en årsag ”", _interpolate(_list(0)), "”"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst i højre side af skalaen (valgfrit)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På en 5-trins skala betragtes 1 og 2 som negative scorer, 3 er neutral, 4-5 er positive"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj skalatekst"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " skala"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskala"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importerer dine samtaler. Denne proces kan tage op til 24 timer."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette vil blive brugt som et personaliseringstoken under hele undersøgelsen."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på brand"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knapper og links"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpas din undersøgelses udseende"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baggrund for logo"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaterne JPG, PNG og GIF understøttes"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslut med stil"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en besked, som folk vil lægge mærke til, efter de har indsendt deres feedback"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takkemeddelelse"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert hex-farvekode"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert e-mailadresse"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt skal udfyldes"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert URL-adresse"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke synlig:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlig:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendte undersøgelser"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser sendt inden for de seneste 30 dage"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelse er blevet slettet"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelse er blevet duplikeret"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisningsmail er blevet sendt"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelse er blevet udgivet"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelse er blevet opdateret"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelsen er blevet sat på pause"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold pause i undersøgelsen"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisning"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast e-mailadressen for at sende prøvevisningen af undersøgelsen"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["din", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg sprog til prøvevisning"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send en prøvevisningsmail"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send et eksempel på en undersøgelse til din e-mail. Vurderingerne fra prøvevisningen af undersøgelsen vil ikke blive gemt."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prøvevisning af ", _interpolate(_named("type")), "-undersøgelsen"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisning af en e-mailundersøgelse på nettet"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgiv"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genoptag"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem som kladde"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på brand"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand-specifikt navn kan tilføjes i designtrinnet"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenavn"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-id"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportrepræsentant"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladde"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På pause"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-undersøgelse for ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er foretaget ændringer, der ikke er gemt"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater og genoptag undersøgelse"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du ikke ser denne e-mail korrekt ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg var ikke tilfreds med den support, jeg fik, fordi..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drevet af Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg årsag"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume af samtale"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksempel: Kunden havde problemer med at opdatere betalingsmetoden. Agenten delte en artikel, som kunne hjælpe. Det virkede."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drevet af AI for Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicebetingelser"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak for din feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad er den primære årsag til din score?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortæl os, hvorfor du gav denne vurdering..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stor indsats"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lille indsats"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klik her for at åbne undersøgelsen"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskyttelse af personlige oplysninger"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit brugerdefinerede spørgsmål vises her"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du gav din ", _interpolate(_list(0)), "-oplevelse en score på"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend feedback"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmeld"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meget utilfreds"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meget tilfreds"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk, at den aktuelle undersøgelsesstatus og leveringsregler ikke vil blive inkluderet i undersøgelsens dublet"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplikér undersøgelse ”", _interpolate(_list(0)), "“"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruger det integrerede e-mail-snippet, skal du sørge for at fjerne det fra Zendesk, før undersøgelsen sættes på pause"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der indsamles ingen svar, når undersøgelsen er sat på pause."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold pause i undersøgelsen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for at fjerne snippet fra Zendesk, før undersøgelsen sættes på pause."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet undersøgelse"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruger det integrerede e-mail-snippet, skal du sørge for at fjerne det fra Zendesk, før du sletter undersøgelsen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling vil slette undersøgelsen permanent. Brugere, der modtog undersøgelsen, kan stadig udfylde den, og alle data, der vedrører undersøgelsen, forbliver i Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet undersøgelse ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for at fjerne snippet fra Zendesk, før du sletter undersøgelsen."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine anvendte ændringer vil blive set af nye respondenter."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgiv undersøgelse"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for at kopiere den seneste version af det integrerede kode-snippet efter du har udgivet undersøgelsen."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruger det integrerede e-mail-snippet, skal du sørge for at kopiere det til Zendesk, efter du har genoptaget undersøgelsen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt med at udsende undersøgelsen og indsamle svar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater og genoptag undersøgelse"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for at tilføje det integrerede snippet til Zendesk, efter du har genoptaget undersøgelsen."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater undersøgelse"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for at kopiere den seneste version af det integrerede snippet, efter du har opdateret undersøgelsen."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil slette denne undersøgelse? Denne handling kan ikke fortrydes."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette undersøgelsen?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din prøveperiode er udløbet, men du kan fortsat få glæde af Zendesk QA ved at opgradere til en konto med fuld adgang."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din prøveperiode er udløbet, men du kan fortsat få glæde af Zendesk QA ved at opgradere til en konto med fuld adgang. Tal med din lokale administrator for at konfigurere dette."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din prøveperiode er udløbet"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgrader nu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din gratis prøveperiode er udløbet."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil fortsætte med at bruge den fulde version af Zendesk QA, skal du indtaste dine betalingsoplysninger."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil fortsætte med at bruge den fulde version af Zendesk QA, skal din kontoadministrator indtaste betalingsoplysninger."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer betaling"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en bruger"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oprettet d. ", _interpolate(_named("date")), " af ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " opgave"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ud til, at du stadig arbejder på dette. Du mister alle de oplysninger, som du indtil videre har tilføjet, hvis du afslutter uden at gemme ændringer."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne opgave gentages hver anden ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denne opgave gentages hver ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alle personer der bliver bedømt samlet i en cyklus"]), _normalize(["Alle personer der bliver bedømt samlet i ", _interpolate(_named("nr")), " cyklusser"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " samtaler"]), _normalize([_interpolate(_named("n")), " samtale"]), _normalize([_interpolate(_named("n")), " samtaler"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["af ", _interpolate(_named("nr")), " agent"]), _normalize(["af ", _interpolate(_named("nr")), " agent"]), _normalize(["af ", _interpolate(_named("nr")), " agenter"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["deltager"]), _normalize(["deltagere"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " samtaler pr. cyklus"]), _normalize([_interpolate(_named("n")), " samtale pr. cyklus"]), _normalize([_interpolate(_named("n")), " samtaler pr. cyklus"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " pr. person der bliver bedømt i træk"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["person der bliver bedømt"]), _normalize(["personer der bliver bedømt"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["bedømmer"]), _normalize(["bedømmere"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personer, der bliver bedømt"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere af arbejdsområdet"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere af arbejdsområdet undtagen agenter"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cyklus ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "-", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ikke tilbagevendende"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " opgave"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemgår"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om opgaver"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret opgave"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling sletter opgaven permanent og fjerner dens data fra kontrolpanelet. Alle bedømmelser, der er relateret til den, forbliver i Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet opgave ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave er slettet"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave er duplikeret"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke tilføjet nogen opgaver"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke oprette opgave"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på opgave"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyklus blev opdateret, og der oprettes en ny opgaveliste. Allerede afgivne bedømmelser tæller stadig med i målet."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjeblikkeligt"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgiv"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I starten af næste cyklus d. ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvornår skal ændringerne udgives?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændringer i denne opgave vil blive offentliggjort sammen med denne opgave"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du angive opgave som aktiv?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbagevendende på opgavens startdag"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første fredag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden fredag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje fredag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde fredag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste fredag i hver måned"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første mandag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden mandag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje mandag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde mandag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste mandag i hver måned"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første lørdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden lørdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje lørdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde lørdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste lørdag i hver måned"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første søndag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden søndag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje søndag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde søndag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste søndag i hver måned"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første torsdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden torsdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje torsdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde torsdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste torsdag i hver måned"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første tirsdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden tirsdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje tirsdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde tirsdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste tirsdag i hver måned"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første onsdag i hver måned"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden onsdag i hver måned"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje onsdag i hver måned"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde onsdag i hver måned"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste onsdag i hver måned"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugerdefineret gentagelse"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D. 1. i hver måned"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tredje"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjerde"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentagelser"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hverdag"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslut"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen resultater"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidszone"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyklusindstillinger"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på opgave"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlæggende oplysninger"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I starten af hver opgavecyklus genereres en forudbestemt liste med samtaler til evaluering."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede indstillinger"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hent nye samtaler automatisk, når mål for bedømmelser ikke opnås"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk hentning er ikke mulig, når mål for bedømmelser har en procentdel på (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skal være et helt tal, ikke en decimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillad erstatning af samtaler (rolle som bedømmer og derover)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstatter samtaler"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillad at bedømmere får tildelt deres egne samtaler til bedømmelse"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelser"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelser"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumsantallet af bedømmelser skal være mindst 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv et mindste antal bedømmelser for hver person der skal bedømmes"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samtaler"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelser"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedømmelser i alt"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver bedømmer skal udføre det angivne antal bedømmelser"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere skal gennemgå et bestemt antal samtaler i fællesskab"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumsantallet af bedømmelser skal være mindst 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelsesmål skal være imellem 1 og 100 %"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelsesmål"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver person, der bliver bedømt, skal have et mindsteantal af deres samlede samtaler bedømt"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere kan vælge samtaler fra den delte pulje"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere tildeles samtaler fra den delte pulje"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgang til opgaven"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bots"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle deltagere i arbejdsområdet"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere af arbejdsområdet"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj personer der bliver bedømt"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem bliver bedømt?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg personer der skal bedømmes"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg, hvem der skal bedømmes"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undtagelser er kun tilladte, når alle brugere af arbejdsområdet eller en gruppe er valgt"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere og personer der bliver bedømt"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aktuelle: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard for arbejdsområde"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilken type brugere skal vælges som personer der skal bedømmes?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mest aktive agent"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltager"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udeluk personer, der bliver bedømt (valgfrit)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg brugere, der skal udelukkes"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udeluk bedømmere (valgfrit)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg brugere, der skal udelukkes"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere af arbejdsområdet undtagen agenter"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj bedømmere"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvem skal foretage bedømmelser?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg bedømmere"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelse er ikke tilladt i dette arbejdsområde"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillad selvbedømmelser"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelser er ikke tilladt i dette arbejdsområde."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Matchende samtale i dag"]), _normalize(["Matchende samtaler i dag"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vises, som om opgaven begyndte i dag."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremtidige tal kan variere."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke valgt nogen personer at bedømme"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke valgt nogen bedømmere"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Person der bliver bedømt"]), _normalize(["Personer der bliver bedømt"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bedømmer"]), _normalize(["Bedømmere"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisning"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk hentning: tilladt, hvis der ikke er samtaler nok"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på fredage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på mandage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på lørdage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på søndage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " torsdage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på tirsdage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " på onsdage fra d. ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dagligt fra ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starter ", _interpolate(_named("startDateTime")), " og slutter ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " datobetingelser"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " samtale og helpdesk-betingelser"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ingen samtale og ", _interpolate(_named("count")), " helpdesk-betingelse"]), _normalize(["Ingen samtale og ", _interpolate(_named("count")), " helpdesk-betingelser"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " samtaler og ingen helpdesk-betingelse"]), _normalize([_interpolate(_named("count")), " samtaler og ingen helpdesk-betingelse"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fremgangsmåde: bedømmere vælger samtaler"]), _normalize(["Fremgangsmåde: bedømmere tildeles samtaler"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ret de fejl, der er fremhævet i oversigten, for at fortsætte"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: bedømmere skal gennemgå alle samtaler"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mål: bedømmere skal gennemgå én samtale"]), _normalize(["Mål: bedømmere skal gennemgå ", _interpolate(_named("count")), " samtaler"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: bedømmere skal gennemgå ", _interpolate(_named("count")), " % samtaler"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: Der er ikke angivet bedømmelsesmål"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål: Hver enkelt person, der bliver bedømt, skal have alle deres samtaler bedømt"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: hver person, der bliver bedømt, skal have ", _interpolate(_named("count")), " af deres samlede antal samtaler bedømt"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: hver person, der bliver bedømt, skal have ", _interpolate(_named("count")), " % af deres samlede antal samtaler bedømt"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mål: hver bedømmer skal foretage én bedømmelse"]), _normalize(["Mål: hver bedømmer skal foretage ", _interpolate(_named("count")), " bedømmelser"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Erstatning: bedømmere kan ikke erstatte samtaler"]), _normalize(["Erstatning: bedømmere kan erstatte samtaler"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvbedømmelser: bedømmere kan få tildelt deres egne samtaler"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke angivet nogen datobetingelser"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke angivet noget navn for opgave"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betingelser"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " fra ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver anden uge"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagligt"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldrig"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugentligt"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cykluslængde"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv som Aktiv"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem som kladde"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave blev gemt som kladde"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave angivet som Inaktiv"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv som Inaktiv"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret opgave"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave oprettet"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som aktiv"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som inaktiv"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De afventende opdateringer af opgaven træder i kraft i næste cyklus, der starter d. ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgiv ændringer"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladde"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventer"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater opgave"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave blev opdateret"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, om agenten eller botten afsluttede samtalen."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om agenten afsluttede samtalen på en høflig måde"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, om samtalen blev afsluttet"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutningshilsen"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer grammatik-, stave- og stilfejl i både agent- og bot-interaktioner."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer agenters grammatik-, stave- og stilfejl"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer agenters grammatik-, stave- og stilfejl"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavning og grammatik"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer samtalen for agent- og bot-hilsener."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for typiske hilsesætninger"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for typiske velkomsthilsener"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomsthilsen"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer om agenten forstår og anerkender kundernes følelser"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificerer tilfælde, hvor agenten spejlede kundernes stemning eller brugte omsorgsfulde ord som ”anerkend” og ”genkend”"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, om agenten forstår kundens forespørgsel eller bekymring"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, om agenten forstår kundens forespørgsel eller bekymring"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forståelse"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, hvor nemt en tekst kan forstås, under hensyntagen til ordenes kompleksitet og sætningslængde"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer, hvor nemt en tekst kan forstås, under hensyntagen til ordenes kompleksitet og sætningslængde"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne meddelelse indeholder 1 alt for lang sætning, hvilket gør den udfordrende at læse"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Denne meddelelse indeholder ", _interpolate(_list(0)), " alt for lange sætninger, hvilket gør dem udfordrende at læse"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læsbarhed"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for en tilbudt løsning"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer hele samtalen for en tilbudt løsning"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løsning tilbudt"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer agentens tone under hele samtalen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyserer agentens tone under hele samtalen"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tone"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere nogen fejl i agentens meddelelser"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke fastslå grammatiske fejl"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke registreret nogen velkomsthilsen. Tilføj om nødvendigt nye i Indstillinger, via din administrator."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent afsluttede samtalen høfligt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke registreret nogen afsluttende sætning. Tilføj om nødvendigt nye i Indstillinger, via din administrator"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent spejlede kundens stemning eller brugte omsorgsfulde ord"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere om agenten spejlede kundens stemning eller brugte omsorgsfulde ord"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten brugte lange sætninger og komplekse ord, hvilket gør det potentielt udfordrende for nogle mennesker at forstå"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten brugte komplekse ord, hvilket gør det potentielt udfordrende for nogle mennesker at forstå"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent brugte lange sætninger, hvilket gør det potentielt udfordrende for nogle mennesker at forstå"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten brugte et sprog, som er nemt at forstå"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke nok data til at vurdere denne kategori"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten havde svært ved at forstå kundens spørgsmål/anmodning"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten kunne sagtens forstå kundens spørgsmål/anmodning"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere, om agenten forstod kundens spørgsmål/anmodning"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens tone under samtalen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens tone under samtalen"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens tone under samtalen"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens tone under samtalen"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens tone under samtalen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentens tone kunne ikke fastslås"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen blev ikke afsluttet"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalen blev afsluttet"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere om samtalen blev afsluttet"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten manglede empati over for kunden og dennes problemer"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent udviste empati over for kunden og dennes problemer"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere om agenten udviste empati over for kunden og dennes problemer "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden blev ikke hilst velkommen under samtalen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden blev hilst velkommen under samtalen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere en typisk velkomsthilsen"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten tilbød ikke kunden en løsning"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent tilbød kunden en løsning"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke registrere, om agenten tilbød kunden en løsning"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplekse ord"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange sætninger"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammatik"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavefejl"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undskyldende"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolig"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munter"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nysgerrig"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionel"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Støttende"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 afslutningshilsen"]), _normalize([_interpolate(_named("n")), " afslutningshilsen"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA kontrollerer kun for godkendte afslutningshilsener. Hvis denne kategori er angivet som vigtig, vil manglende brug af de godkendte afslutningshilsener blive bedømt som dumpet."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendte afslutningshilsener"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendte afslutningshilsener"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 undtagelse"]), _normalize([_interpolate(_named("n")), " undtagelser"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord og vendinger, som Auto-QA ignorerer som stave- eller grammatikfejl."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undtagelser for stave- og grammatik"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA-undtagelser"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hilsen"]), _normalize([_interpolate(_named("n")), " hilsener"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA kontrollerer kun for godkendte velkomsthilsener. Hvis denne kategori er angivet som vigtig, vil manglende brug af de godkendte velkomsthilsener blive bedømt som dumpet."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendte velkomsthilsener"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendte velkomsthilsener"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA-kategori"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en Auto-QA-kategori"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk tildelte hovedårsager:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto → Indstillinger"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visse kategorier benytter sig af MS Azure, der tilbyder et udvidet udvalg af funktioner og understøttede sprog. Gennemgå din ", _interpolate(_named("link")), " for at få yderligere oplysninger, eller kontakt en administrator med henblik på dette."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Få adgang til en udvidet liste over kategorier og understøttede sprog ved at benytte MS Azure. Gennemgå din ", _interpolate(_named("link")), " eller kontakt en administrator med henblik på dette."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug Auto-QA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Auto-QA-kategorier tildeles automatisk en score ved brug af en fast vurderingsskala. Du kan justere vurderingen manuelt senere. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en Auto-QA-kategori"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatisk tildelte point afhænger af fejlenes alvor og deres antal. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedårsager"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun relevante vurderingskategorier vises for bedømmere. Vurderingskategorier vises baseret på de betingelser, du angav, da du oprettede en kategori."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onboarding"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vælg vurderingskategoritypen for arbejdsområdet ”", _interpolate(_named("workspace")), "”."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere skal manuelt vælge den korrekte resultattavle for at se relevante vurderingskategorier for hver samtale."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil ændre vurderingskategoritypen?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift kategorier"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift vurderingskategoritype?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betinget vurderingskategori"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisk vurderingskategori"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategoritype"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger for vurderingskategori"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling kan ikke fortrydes."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slette alle vurderinger"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du bevare din statistik ved at arkivere en kategori uden at slette data."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg forstår, at dette vil ", _interpolate(_named("deleteAllRatings")), ", der er angivet for denne kategori."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. Tonen er perfekt"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kun kategori hvis"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["”Altid” vil blive ignoreret. Den kan ikke kombineres med andre forhold."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altid"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tags"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg betingelse"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilfredshedsscore (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kildetype"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtalekanal"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kategori skal have et navn"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj kategorien til en resultattavle"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kategori"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kategori"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien blev tilføjet"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkiveret kategori er ikke synlig på resultattavlen, men den giver dig mulighed for at føre din statistik uden at skulle slette data."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivkategori"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arkiver ", _interpolate(_list(0)), "-kategori"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien blev arkiveret"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kategori"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ”slet kategori” for at slette denne kategori"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slet kategori"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slet kategori ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien blev slettet"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien er duplikeret"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigruppen blev tilføjet"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigruppen blev slettet"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupperækkefølgen på vurderingskategorien er ændret"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorigruppen blev opdateret"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategori blev fjernet fra arkiv"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingskategorien blev opdateret"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis en agent dumper en vigtig kategori, vil alle denne kategoris bedømmelser automatisk dumpe."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigtig vurderingskategori"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kategorier i denne gruppe"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret ny vurderingskategori"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kategori"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle kategorier og gennemse data i denne gruppe"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning af kategorigruppen vil efterlade alle de kategorier, der er knyttet til den, uden en gruppe."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet ", _interpolate(_named("categoryName")), " kategorigruppe"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vælger at slette kategorierne under gruppen, mister du alle de bedømmelsesdata, som er knyttet til disse kategorier."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenavn"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere kan vælge mere end én årsag som hovedårsag"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere kan vælge mere end én årsag som hovedårsag"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere årsager"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f.eks. Tone"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ny kategorigruppe"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny gruppe"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ”Andet”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj hovedårsagen"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne hovedårsag findes allerede"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj hovedårsagen"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj hovedårsager for at forklare vurdering"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderingsskala"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiveret"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavler"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavler"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj resultattavler til denne vurderingskategori"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern arkivering"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater kategori"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vurderinger"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vises for"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative vurderinger"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative og neutrale vurderinger"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, opdater"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vægtning"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier, som er vigtigere for dig, kan få større vægt i beregningen af en ticket-score."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vægtning (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om kalibrering"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om kalibrering"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avanceret"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afholdelse af regelmæssige kalibreringssessioner giver bedømmere mulighed for at holde deres vurderingsteknikker afstemt og sikre, at agenter modtager det samme niveau af ensartet og objektiv feedback."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se abonnementer"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " er en proces, der hjælper dit team med at holde øje med, hvordan alle kundeinteraktioner skal håndteres og evalueres."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professionel"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibrering er tilgængelig for vores abonnementer ", _interpolate(_list(0)), " og ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere afgivne bedømmelser vises ikke, når der føjes en samtale til sessionen."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke synlig"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere afgivne bedømmelser betragtes som kalibreringsbedømmelser. Der vil kun blive føjet én samtalebedømmelse pr. bedømmer til sessionen. Meddelelsesspecifikke bedømmelser vises ikke."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synligt"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere afgivne bedømmelsers synlighed"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrering er en proces, der hjælper dit team med at holde øje med, hvordan alle kundeinteraktioner skal håndteres og evalueres. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere har ubegrænset adgang til alle bedømmelser, der gives under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se altid alle bedømmelser"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeemne"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter indsendelse af en bedømmelse kan brugere se evalueringer, som er givet af andre bedømmere under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kalibreringsbedømmelser, efter du har indsendt en bedømmelse"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere har ubegrænset adgang til alle bedømmelser, der gives under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se altid alle bedømmelser"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter indsendelse af en bedømmelse kan brugere se evalueringer, som er givet af andre bedømmere under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kalibreringsbedømmelser, efter du har indsendt en bedømmelse"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter indsendelse af en bedømmelse kan brugere se evalueringer, som er givet af andre bedømmere under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle kalibreringsbedømmelser, efter du har indsendt en bedømmelse"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere vil kun se deres egne bedømmelser, der er givet under en kalibreringssession."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se kun egne bedømmelser"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringsindstillingerne blev opdateret"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibreringsbedømmelsernes synlighed"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv tærsklen for kontrolpanelets metrik ved at udfylde hullerne i henhold til dit teams interne metriksystem. Gennemse tærsklen i kontrolpanelet."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrering"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytninger"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelt"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tærskel"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tærskel blev opdateret"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et øjeblik..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sørg for, at du har administratortilladelser og ", _interpolate(_named("retry")), ", eller ", _interpolate(_named("contact")), " for yderligere hjælp."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt os"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ud til, at vi ikke kunne få adgang til din helpdesks API."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prøv igen"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi tilslutter din helpdesk. Du vil blive omdirigeret om et øjeblik."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " godkendt"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vend tilbage til din ", _interpolate(_list(0)), " for at tilføje en ny helpdesk-tilknytning."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ud til, at du har overset et eller to trin."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der findes allerede en organisation med det samme e-maildomæne"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["side med tilknytninger"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I mellemtiden ..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent et øjeblik, mens vi kontrollerer dine tilladelser."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I mellemtiden ..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrerer"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opret et arbejdsområde"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du vil fortsætte med at bruge Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næsten klar til brug"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet noget arbejdsområde"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift konto"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du vil fortsætte med at bruge Zendesk QA, skal din chef tildele dig et arbejdsområde."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis du sletter din konto, slettes alle de data, vi har om dig, permanent – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle vurderinger, samtaleindhold, arbejdsområder osv. der er blevet givet"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbryd"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbrydelse af integration vil deaktivere alle Zendesk QA-notifikationer via Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbryd Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integration er blevet fjernet fra Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definer standardindstillinger for notifikationer for alle nye kontobrugere."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilsidesæt indstillinger for alle brugere"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ved at tilsidesætte notifikationsindstillinger for alle kontobrugere"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere vil ikke kunne definere deres egne notifikationsindstillinger."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillingerne for notifikationer blev opdateret"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advarsel, når man dumper en vigtig kategori"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advarsel, når CSAT får et svar"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggere"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-adresse"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret et demoarbejdsområde og data"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genopret demodata, og behold demoarbejdsområdet."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern demoarbejdsområde og tilknyttede data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater demodata"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Administrer demotilstand for kontoen ”", _interpolate(_named("accountName")), "”"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demotilstand"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bliv på siden"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forlad siden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du forlade denne side og miste alle ændringer?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fuldført opgaven"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret opgave"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave er fuldført"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen opgaver"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I øjeblikket er der ingen opgaver, som du kan bedømme."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korriger opgaven eller vent til den næste cyklus begynder."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at justere filtrene"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger opgave"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold øje med denne side for opdateringer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen opgaver i denne cyklus"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen samtaler at vise"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér opgave som fuldført"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har bedømt denne bruger."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen selvbedømmelser i denne opgave"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ved at klikke på kalibreringsikonet i samtalevisningens overskrift."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke tilføjet nogen samtaler"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfaldsdato"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret opgave"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret kalibreringssession"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine opgaver"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre opgaver"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen aktive opgaver"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen opgaver"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle personer der bliver bedømt"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle personer, der bliver bedømt undtagen mig"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet personer, der er blevet bedømt"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter person der er blevet bedømt"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladde"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernet"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle statusser"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgave"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bedømmere"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bedømmere undtagen mig"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelt mig"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen bedømmere"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter bedømmer"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dage"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dage"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dag"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dage"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dage"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dage"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " time"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " timer"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minut"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutter"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke relevant"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere af arbejdsområdet"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende bedømmer"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt mål for denne cyklus"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny bedømmer"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg bedømmer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel samtaler"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel samtale"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtaler, som skal flyttes"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuel cyklus"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger opgave"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen bedømmere"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter bedømmer"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel samtaler til mig"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omfordel samtaler til en anden bruger"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmere"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dage"]), _normalize([_interpolate(_named("n")), " dag"]), _normalize([_interpolate(_named("n")), " dage"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " timer"]), _normalize([_interpolate(_named("n")), " time"]), _normalize([_interpolate(_named("n")), " timer"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul fuldførte cyklusser"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul fuldførte sessioner"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit mål"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en årsag for at fjerne denne samtale"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar, hvorfor du fjerner denne samtale"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern samtale"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du beholde ændringerne?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en årsag for at erstatte denne samtale"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassér ændringer"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold ændringer"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstatningen blev ikke fundet"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklar, hvorfor du erstatter denne samtale"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du stadig fjerne denne samtale fra opgaven? Dette vil reducere målet med 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstat"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstat samtale"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet kladde"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kladde"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel til mig"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel til anden bruger"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentildel og bedøm"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern samtale"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv en bedømmelse"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mindre"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mere"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mål: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " bedømt"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udførte bedømmelser"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis fuldførte cyklusser"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis fuldførte sessioner"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterende tid"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgaver"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale erstattet"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift opgaveliste"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " senere"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekund"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekunder"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " t"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " t ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dage"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("minutes")), " m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t ", _interpolate(_named("minutes")), " m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t ", _interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " t ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " md"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " år"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lige nu"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du mangler tilladelser. Kontakt din administrator for at få hjælp."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den side, du leder efter, findes ikke."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en uventet fejl. Kontakt support for at få hjælp."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgang nægtet. Har ikke tilladelse til at udføre denne handling."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den side, som du leder efter, findes ikke, eller du har ikke adgang til den."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det går lidt for hurtigt og vi har problemer med følge med."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændringerne blev gemt"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er foretaget ændringer, der ikke er gemt"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " anvendt"]), _normalize([_interpolate(_named("n")), " anvendt"]), _normalize([_interpolate(_named("n")), " anvendte"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyttet"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsestidspunkt"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet af"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigtig"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tidligere medlem"]), _normalize(["et tidligere medlem"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valgt"]), _normalize([_interpolate(_named("n")), " valgt"]), _normalize([_interpolate(_named("n")), " valgt"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senest opdateret"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... og yderligere 1"]), _normalize(["... og yderligere ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["yderligere 1"]), _normalize(["yderligere ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ny"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er ikke"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernet"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"]), _normalize([_interpolate(_named("n")), " svar"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dag"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruger"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fredag"]), _normalize(["fredage"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mandag"]), _normalize(["mandage"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lørdag"]), _normalize(["lørdage"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Søndag"]), _normalize(["søndage"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Torsdag"]), _normalize(["torsdage"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tirsdag"]), _normalize(["tirsdage"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Onsdag"]), _normalize(["onsdage"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i går"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrator"]), _normalize(["en administrator"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontoadministrator"]), _normalize(["en kontoadministrator"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bruger"]), _normalize(["en bruger"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieret"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs mere"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din session er udløbet."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlogninger"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt skal udfyldes."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen elementer."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matchende personer."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ejer"]), _normalize(["en ejer"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs mere"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs mere"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs mere."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Før vi går i gang, skal du læse og acceptere vores betingelser, hvorefter vi er færdige."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendt"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser udført i alt"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdateret"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["en agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kundeemne"]), _normalize(["et kundeemne"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Chef"]), _normalize(["en chef"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bedømmer"]), _normalize(["en bedømmer"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonym bruger"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansvarlig"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luk"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snart"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyt"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik for at kopiere"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér link"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farezone"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse (valgfri)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelse er påkrævet"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivér"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du kassere ændringer, der ikke er gemt?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivér"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportér"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyppighed"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldrig"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beståelsesprocent"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlige indstillinger"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs mindre"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send igen"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gendan"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person der bliver bedømt"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser modtaget/set"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser udført af medlem af arbejdsområdet"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem ændringer"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binær skala"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-trins skala"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-trins skala"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-trins skala"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavle"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgning"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg frekvens"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mindre"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mere"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring over"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trin"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback på undersøgelsen"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsinterval"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er foretaget ændringer, der ikke er gemt"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdatér"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vægtning"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du er blevet inviteret af ", _interpolate(_list(0)), " til at tilmelde dig Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brugere tilsluttes automatisk fra Administrationscenteret. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer brugere"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om brugeradministration"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visse brugere klargøres og synkroniseres automatisk fra din identitetsudbyder. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kontotilladelse for ”", _interpolate(_list(0)), "” er ændret"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om brugertilladelser"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørg mig ikke igen"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle ændringer på brugersiden afspejler arbejdsområder. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det betyder, at brugeres adgang til visse dele af Zendesk QA kan blive ændret"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift tilladelse på kontoniveau"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruger fjernet"]), _normalize([_interpolate(_named("n")), " brugere fjernet"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når du fjerner en bruger, vil denne miste adgang til din konto. Deres bedømmelser, kommentarer og vurderinger forbliver i Zendesk QA. ", _interpolate(_named("linebreak")), "Fjernelse af en bruger vil ikke påvirke antallet af licenser i dit abonnement."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Denne bruger vil blive fjernet fra alle arbejdsområder, som du administrerer. ", _interpolate(_named("linebreak")), " Deres bedømmelser, kommentarer og vurderinger forbliver som de er."]), _normalize(["De vil blive fjernet fra alle arbejdsområder, som du administrerer. ", _interpolate(_named("linebreak")), " Alle deres bedømmelser, kommentarer og vurderinger forbliver som de er."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fjern ", _interpolate(_named("name"))]), _normalize(["Fjern ", _interpolate(_named("name")), " brugere"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan se alt og administrere alle indstillinger og fakturering"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan se alt og administrere indstillinger for personlige og arbejdsområder, undtagen fakturering"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotilladelser"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområdespecifikke tilladelser giver brugere mulighed for at se (og) redigere data i de arbejdsområder, de befinder sig i."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om Bot-QA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine bots vises her, når samtaler, som de har deltaget i, synkroniseres"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen synkroniserede bots"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markering af en bot som bruger vil give den adgang til at logge ind. Dens historiske bot-data vil forblive tilgængelige i kontrolpanelerne."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen sådanne bots blev fundet"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at søge igen ved at bruge et andet nøgleord eller en anden stavemåde."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter bots"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via botnavn"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som bruger"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun administratorer og kontoadministratorer kan markere en bot som bruger"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-navn"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelt markeret"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan bedømmes"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botten fjernes fra automatisk analyse og eventuelle tildelinger. Nye data bliver ikke vist i kontrolpaneler, hvilket sikrer nøjagtig rapportering og nemmere administration af botter."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generativ bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukendt"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot for arbejdsgange"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til gruppe(r)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen gruppe"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra gruppe(r)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg gruppe"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotilladelse"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj til arbejdsområde(r)"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulde navn"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senest aktiv"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle på kontoniveau"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver bruger tildeles en tilladelse på konto- og/eller arbejdsområdeniveau. Administratortilladelser er nu på kontoniveau, mens andre tilladelser på arbejdsområdeniveau."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra arbejdsområde(r)"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilladelse til arbejdsområde"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilladelser administreres i gruppe"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger detaljer"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markering af en bruger som bot vil fjerne brugerens adgang til at logge ind. Deres historiske brugerdata vil forblive tilgængelige på kontrolpanelerne."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern botten fra automatisk analyse og eventuelle tildelinger. Nye data bliver ikke vist i kontrolpaneler, hvilket sikrer nøjagtig rapportering og nemmere administration af botter."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udeluk bot fra bedømmelser"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger markeret som bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun tilladelsestypen Bruger kan markeres som bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere med flere konti kan ikke markeres som bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter bruger"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere, bots og arbejdsområder"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARBEJDSOMRÅDER"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere og arbejdsområder"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilladelser"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyttet"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelt tilføjet"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventer"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventende invitation"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-brugere"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling er nødvendig"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...og yderligere en"]), _normalize(["...og yderligere ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift tilladelse til"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fjern og inviter igen for at ændre rolle for ", _interpolate(_named("n")), " afventende inviteret bruger."]), _normalize(["Fjern og inviter igen for at ændre rolle for ", _interpolate(_named("n")), " afventende inviterede brugere"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rediger detaljer for ", _interpolate(_named("name"))]), _normalize(["Rediger detaljer for ", _interpolate(_named("n")), " valgte brugere"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruger opdateret."]), _normalize([_interpolate(_named("n")), " brugere opdateret."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Denne gruppe indeholder 1 bruger fra et andet arbejdsområde."]), _normalize(["Denne gruppe indeholder ", _interpolate(_named("n")), " brugere fra et andet arbejdsområde."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivér"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” er arkiveret"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiveret gruppe"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret gruppe"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” blev oprettet"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavngivet gruppe"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette denne gruppe permanent?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet gruppe"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet gruppe"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger gruppe"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppekundeemner"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupperolle"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” er skjult"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne gruppe vil ikke være synlig andre steder i Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer kun tilladelser til arbejdsområde"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe er ikke synlig i Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeemne"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlem"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavngivet gruppe"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En anden gruppe har allerede dette navn"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkiveret gruppe har allerede dette navn"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din rolle tillader kun administration af medlemmer af denne gruppe. Kontakt en bruger med et højere tilladelsesniveau for yderligere ændringer."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern som gruppekundeemne"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” blev genoprettet"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” blev gemt"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv som gruppekundeemne"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” blev fjernet fra arkiv"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændringer, der ikke er gemt"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj dine eksisterende brugere til denne gruppe"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via navn eller e-mailadresse"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj medlemmer"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen ”", _interpolate(_named("name")), "” er synlig"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvert medlem er ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om grupper"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visse attributter for grupper klargøres og synkroniseres automatisk fra din identitetsudbyder."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få flere oplysninger om grupper"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret gruppe"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiser nemt dine teammedlemmer og efterlign din organisationsstruktur."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj mindst 1 medlem"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et gruppenavn"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der findes allerede en gruppe med dette navn"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppemedlemmer"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenavn"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiser dine teammedlemmer og efterlign din organisationsstruktur."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ingen grupper"]), _normalize(["1 gruppe"]), _normalize([_interpolate(_named("count")), " grupper"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en ny gruppe eller aktivér en arkiveret i stedet for."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgængelige aktive grupper"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arkiverede grupper"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet sådanne grupper"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at søge igen ved at bruge et andet nøgleord eller en anden stavemåde."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grupper tilføjet"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["i disse"]), _normalize(["i"]), _normalize(["i"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via gruppenavn"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiveret"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Send om muligt ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licenser tilbage"]), _normalize([_interpolate(_named("n")), " licens tilbage"]), _normalize([_interpolate(_named("n")), " licenser tilbage"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit antal licenser er i øjeblikket begrænset, da dit abonnement er på pause. Genaktiver det for at tilføje flere brugere."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået grænsen for licenser på din konto. Du kan erstatte brugere ved at deaktivere en aktiv bruger eller tilføje flere licenser."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se betalingsoplysninger"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valgt"]), _normalize([_interpolate(_named("n")), " valgt"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruger valgt"]), _normalize([_interpolate(_named("n")), " brugere er valgt"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " rækker"]), _normalize([_interpolate(_named("n")), " række"]), _normalize([_interpolate(_named("n")), " rækker"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massesøg efter brugere via deres navne eller e-mailadresser."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsæt data, adskilt af kommaer eller linjeskift, her"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgning"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massesøg efter brugere"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rediger tilladelser til arbejdsområde for ", _interpolate(_named("name"))]), _normalize(["Rediger tilladelser til arbejdsområde for ", _interpolate(_named("n")), " valgte medlemmer"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigeringstilladelser"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg tilladelse til arbejdsområde"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fjern ", _interpolate(_named("name")), " fra arbejdsområde"]), _normalize(["Fjern ", _interpolate(_named("name")), " medlemmer fra arbejdsområdet"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " medlem fjernet"]), _normalize([_interpolate(_named("n")), " medlemmer fjernet"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fjern medlem"]), _normalize(["Fjern medlemmer"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Denne bruger vil blive fjernet fra arbejdsområdet."]), _normalize(["Disse brugere vil blive fjernet fra arbejdsområdet."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tilladelse til arbejdsområde ”", _interpolate(_list(0)), "” ændret."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj medlemmer"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se samtaler og relaterede bedømmelser"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alt i arbejdsområdet, men administrer kun quizzer, opgaver, tvister og kalibreringssessioner"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilladelser til arbejdsområde"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se og administrer alt i arbejdsområdet"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alt i arbejdsområdet, undtagen indstillingerne for arbejdsområdet"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilslut brugere"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere fra de arbejdsområder, som du administrerer, er blevet føjet til dette arbejdsområde."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed en administrator om at gøre dette."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter selv nye brugere direkte, eller bed en administrator om at gøre det."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " eller ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brugere at vise"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " bruger tilføjet"]), _normalize([_interpolate(_named("n")), " brugere tilføjet"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tilføj medlemmer"]), _normalize(["Tilføj medlem"]), _normalize(["Tilføj ", _interpolate(_named("n")), " medlemmer"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg tilladelse til arbejdsområde"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg brugere til arbejdsområde"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Føj medlemmer til ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsæt e-mails, adskilt af nye linjer eller kommaer, her"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Match ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mails"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" med allerede tilknyttede brugere:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kunne ikke matche ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mails"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfør stavekontrol eller tilknyt dem enkeltvis senere"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" med helpdesk-brugere:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tilknyttet bruger"]), _normalize([_interpolate(_named("n")), " tilknyttede brugere"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " inviteret bruger"]), _normalize([_interpolate(_named("n")), " inviterede brugere"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massetilknytning"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseinvitation"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For eksempel:\n\nkate", "@", "eksempel.com, jan", "@", "eksempel.com\n\nkate", "@", "eksempel.com\nkate", "@", "eksempel.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyt fra din/dine helpdesk(s) for at gennemgå deres interaktioner"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tilknyt bruger"]), _normalize(["Tilknyt brugere"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyt individuelt"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du ønsker, at dine tilknyttede brugere skal have adgang til Zendesk QA, kan du invitere dem her."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter tilknyttede brugere"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Inviter bruger"]), _normalize(["Inviter brugere"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring invitationen over"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg brugere, der skal tilknyttes fra din/dine helpdesk(s), for at gennemgå deres interaktioner"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrer dit abonnement"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyt helpdesk-brugere"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv dit arbejdsområde et unikt navn"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis denne funktion er aktiveret, føjes alle eksisterende kontotilknytninger automatisk til det nye arbejdsområde."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj alle tilknytninger til dette arbejdsområde"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret arbejdsområde"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på arbejdsområde"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret arbejdsområde"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere, som føjes direkte til kontoen (uden at få tildelt et arbejdsområde), tildeles en rolle på kontoniveau. Du kan føje dem til arbejdsområder senere."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviterede brugere tildeles automatisk en brugerrolle på kontoniveau. Du kan opgradere dem til administratorrolle senere."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disse virker alle"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsæt e-mails, adskilt af linjer eller kommaer, her."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bruger inviteret via e-mail"]), _normalize([_interpolate(_named("n")), " brugere inviteret via e-mail"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere, som føjes til et arbejdsområde, tildeles automatisk en brugerrolle på kontoniveau. Du kan opgradere dem til administratorrolle senere."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj én til"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter brugere til kontoen uden at føje dem til et arbejdsområde"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviterede brugere tildeles kun en kontotilladelse og føjes ikke til nogen arbejdsområder."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter brugere direkte til et arbejdsområde og tildel tilladelser til arbejdsområde"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj brugere til arbejdsområdet"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseinvitation"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter individuelt"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter kun til kontoen"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter til et arbejdsområde"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Inviter brugere"]), _normalize(["Inviter bruger"]), _normalize(["Inviter ", _interpolate(_named("n")), " brugere"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseinvitation af brugere via e-mail"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Slet bruger"]), _normalize(["Slet brugere"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fravælg"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter brugere via e-mail"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen arbejdsområder"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg tilladelse"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitationen igen"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknyttet bruger"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventende e-mail til invitation af bruger"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-bruger"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg arbejdsområde"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-agenter"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrationscenter"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalitetssikring"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce management"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fuldført ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Fuldført ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Fuldført ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleNr")), ". cyklus"])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "-", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmer"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger opgave"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spar tid og konfigurer automatisk opgaver til bedømmelse i stedet for manuelt at finde og tildele samtaler til bedømmelse."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Forfalder d. ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner kan tilføjes under sektionen ”Kalibrering” i samtalevisningens sidemenu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke tilføjet nogen sessioner"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referencepunkt"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen sessioner"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan føje samtaler til en session ved at klikke på kalibreringsikonet i samtalevisningens overskrift."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke tilføjet nogen samtaler"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der er ikke blevet givet nogen bedømmelser. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv en kalibreringsbedømmelse"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedårsag"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke indlæse kommandopalet."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet noget."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundet af id"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser kommandopalet…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sider, filtre, samtaler, indstillinger..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtale"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat filter"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentligt filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fra ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fra din API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Viser ", _interpolate(_named("rangeStart")), " til ", _interpolate(_named("rangeEnd")), " af ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Viser ", _interpolate(_named("rangeStart")), " til ", _interpolate(_named("rangeEnd")), " af ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul sidepanel"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis sidepanel"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå sidepanel til/fra"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepterede tvister"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afviste tvister"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbne tvister"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvist accepterede tvister"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udførte bedømmelser"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne bedømmelser"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sete bedømmelser"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepterede"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["åbnede"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delvist accepterede"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " af total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afvist"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepterede"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbne"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvist accepteret"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afviste"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["bedømmelse"]), _normalize(["bedømmelser"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antallet af deres bedømmelser, der er blevet set af de personer, der bliver er blevet bedømt"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal af deres bedømmelser, der er blevet set"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omstridte bedømmere"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputanter"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister efter kategori"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister igennem tiden"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åben"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig løsningstid"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig løsningstid (t)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig løsningstid (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løst"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvister i alt"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlede"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepterede"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarsprog"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen sprog"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sprog"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forventede faktorer"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen faktorer"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle faktorer"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord fra ordskyen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen ord"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle ord"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omtaler"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyeste"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ældste"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åbne"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delvist accepteret"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " reagerede med ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afviste"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løste"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgning"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg hudfarve"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen sessioner"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kategorier"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarer"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarstørrelse"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen størrelser"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle størrelser"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen filtre"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen grupper"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle grupper"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen hashtags"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen værdier"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle værdier"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen brugerdefinerede felter"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk-tags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen helpdesk-tags"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle helpdesk-tags"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæser resultater…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørgsmålstyper"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen spørgsmålstyper"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj reaktion"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultattavler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen resultattavler"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle resultattavler"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorer"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen scorer"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle scorer"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilder"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kilder"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kilder"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag til feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen årsager"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle årsager"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undersøgelser"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen undersøgelser"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle undersøgelser"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen kanaler"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kanaler"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen brugere"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen arbejdsområder"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle arbejdsområder"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til Quizzer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzen er blevet slettet eller er endnu ikke udgivet. Dobbelttjek linket eller naviger tilbage til quizlisten."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne quiz er ikke blevet delt med et arbejdsområde, som du tilhører. Bed en administrator om at føje dig til et arbejdsområde, som denne quiz har adgang til."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzen er ikke tilgængelig"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er givet adgang quizzer i arbejdsområde(r), som du ikke tilhører. Kan ikke gemme og udgive quiz."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed en administrator om at gemme og udgive quizzen."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindst ét af svarene skal markeres som det rigtige."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette felt skal udfyldes."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette er en prøvevisning af quizzen."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg hvilke arbejdsområder, der kan få adgang til quizzen. Brugere, der tilhører flere arbejdsområder, kan kun besvare denne quiz én gang. Valg af ”Alle arbejdsområder” omfatter ikke arbejdsområder, der blev oprettet, efter quizzen blev udgivet."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgang til quiz"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslut prøvevisningstilstand"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportér"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøvevisning"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørgsmål"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvar quizzen"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgiv quiz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizindstillinger"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem som kladde"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret ny quiz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern arkivering"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valg"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj valg"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del den"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser godt ud, ikke?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit svar er blevet indsendt"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din quiz er blevet gemt"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din quiz er blevet udgivet"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din quiz er blevet fjernet"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj et spørgsmål"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En arkiveret quiz og dens resultater vil ikke længere blive vist på listen over quizzer. Du kan stadig få adgang til resultaterne af en arkiveret quiz og annullere den, hvis det er nødvendigt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkivér quiz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgiv"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når først en quiz er udgivet, kan den ikke ændres eller redigeres. Alle udgivne quizzer vil være tilgængelige for alle brugere på din konto."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du udgive quiz?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil slette denne quiz? Denne handling kan ikke fortrydes."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette quizzen?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når først dine svar er indsendt, kan de ikke ændres."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du indsende quizzen?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern arkiveringen af quizzen"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Når quizzen fjernes fra arkivet, bliver den tilgængelig i quizoversigten. Det giver tidligere deltagere adgang til deres resultater, og mulighed for at nye kan besvare quizzen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern arkiveringen af quizzen"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér spørgsmål"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse (valgfri)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt spørgsmålet ned"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt spørgsmålet op"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørgsmål"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun spørgsmål med svar vises i prøvevisningen."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern spørgsmål"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend quiz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz uden titel"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel på quiz"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette sletter quizzen og dens resultater permanent."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativt kan du arkivere quizzen og dermed stadig få adgang til resultaterne."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besvaret"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet quiz"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette sletter quizzen og dens resultater permanent."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slet quiz ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikér"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer hjælper med at styrke teamets selvtillid og færdigheder, så de kan levere ensartede oplevelser og integrere nye teammedlemmer."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan søge efter quizzer ved hjælp af quizzens navn eller forfatterens navn."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ikke oprettet nogen quizzer"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportér liste med quizzer"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gns. score"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldført dato"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Forfatter"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Forfatter/Arbejdsområder"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltagere"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgivet"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbejdsområder"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via quiz, forfatter"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkiveret"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kladde"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle quizzer"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz er blevet arkiveret."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz er blevet fjernet fra arkivet."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne quiz modtog ingen svar fra brugere i de(t) valgte arbejdsområde(r)."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne quiz modtog ingen svar, før den blev arkiveret."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen svar"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kan du se en opdeling af quizsvarene.\nDer er endnu ingen, der har besvaret din quiz – det ville helt sikkert hjælpe at dele den."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endnu ingen svar"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig score:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - kopiér"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linket blev kopieret"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen resultater"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quizzer for ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan søge efter deltagerens navn på performancelisten."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportér performanceliste"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg via deltager"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performanceliste"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzer"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå performanceliste til/fra"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det betyder, at du ikke vil kunne filtrere for dette hashtag i kontrolpanelet, og at det ikke længere vil autofuldføre kommentarer. Denne handling kan ikke fortrydes."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret hashtags"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret hashtags"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillad medlemmer at oprette nye hashtags"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugt"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Slet hashtagget ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af hashtag er låst"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af hashtag er låst op"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtagget ”", _interpolate(_list(0)), "” blev slettet"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtagget ”", _interpolate(_list(0)), "” omdøbt til ”", _interpolate(_list(1)), "“"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag tilføjet"]), _normalize([_interpolate(_named("count")), " hashtags tilføjet"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt navn"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke oprettet nogen hashtags"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omdøb"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Omdøb hashtag ”", _interpolate(_named("tagRenamed")), "“"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adskil hashtags med kommaer eller linjeskift"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter et hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bare tag det roligt. Det kan du sagtens ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lige her"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytning forbundet med arbejdsområde"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør tilknytning"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastgør en tilknytning til dette arbejdsområde"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilknytning"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt din administrator for at foretage eventuelle ændringer her"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt Zendesk QA for at foretage eventuelle ændringer her"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilknytninger under konto"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det ser ud til, at du endnu ikke har integreret med en kundeserviceplatform."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sletning af en tilknytning vil fjerne samtaler fra arbejdsområdet ", _interpolate(_named("workspaceName")), " og slette alle data, som er relateret til dem, herunder bedømmelser og tvister."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet tilknytning"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slet tilknytningen ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en tilknytning"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inden for den valgte tidsperiode var visse aktive Auto-QA-kategorier ikke til stede på din resultattavle. Samtaler før din seneste ændring d. ", _interpolate(_named("date")), ", er måske ikke blevet analyseret for disse kategorier."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her vil al den feedback, du har fået, blive vist. Endnu intet at vise."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen modtagne bedømmelser."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis kun kommentaraktivitet"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her vil al den feedback, du har givet, blive vist. Kom godt i gang med Samtalevisningen."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen afgivne bedømmelser."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgivne bedømmelser"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtagne bedømmelser"])}
  }
}