import type {
  GetDraftsResponse as GetDraftsResponseFromService,
  DeleteDraftRequest,
  Draft as DraftFromService,
} from '@zendesk/zqa-services/drafts'
import qs from 'query-string'
import { api } from '@/api'
import { session } from '@/composables/useSession'

// TODO: Clean this userId confusion up once BE has cleaned up this.
// I confirmed with BE that this doesn't need to be filled for PUT/POST requests and it's used in some GET request response.
// Although at the moment it always seems to return userId: "0" in the response.
export type Draft = Omit<DraftFromService, 'userId'>

interface GetDraftsResponse extends Omit<GetDraftsResponseFromService, 'drafts'> {
  drafts: Draft[]
}

export const transformOldDraft = (d: Draft): Draft => {
  // support for old draft ids which are in the form of externalTicketId.externalMessageId
  // remove this logic after
  // https://linear.app/klausapp/issue/K-730/automatic-deletion-of-old-drafts
  // has been live 30 days
  if (!d.version) {
    return {
      ...d,
      conversationId: d.conversationId.split('.')[0],
      messageId: d.conversationId.split('.')[1],
    }
  }

  return d
}

export const getDrafts = (): Promise<GetDraftsResponse> => {
  return api
    .get('drafts', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json<GetDraftsResponse>()
    .then(({ drafts }) => ({ drafts: drafts.map(transformOldDraft) }))
}

export const createOrUpdateDraft = (json: Draft) =>
  api
    .post('drafts', {
      json,
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

export const deleteDraft = (searchParams: DeleteDraftRequest) =>
  api
    .delete('drafts', {
      searchParams: qs.stringify(searchParams),
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()
