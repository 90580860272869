import { computed, ref } from 'vue'
import {
  type BaseFilter,
  getPrivateFilters,
  getPublicFilters,
  setPrivateFilterPosition,
  setPublicFilterPosition,
} from '@/modules/conversations/api/filters'
import { hasAccountRole, hasWorkspaceRole } from '@/utils/roleUtils'
import i18n from '@/i18n'
import { track } from '@/utils/analytics'

const PUBLIC_FILTERS_EXPANDED_STORAGE_KEY = 'klausPublicFiltersExpanded.v1'
const PRIVATE_FILTERS_EXPANDED_STORAGE_KEY = 'klausPrivateFiltersExpanded.v1'

export enum FilterType {
  Starred = 'starred',
  Drafts = 'drafts',
  Search = 'search',
  Calibration = 'calibration',
  Insights = 'insights',
}

const state = ref<{
  filtersInitialized: boolean
  activeFilterId: number | string | null
  activeFilterName: string
  publicFilters: BaseFilter[]
  privateFilters: BaseFilter[]
  publicFiltersExpanded: boolean
  privateFiltersExpanded: boolean
}>({
  filtersInitialized: false,
  activeFilterId: null,
  activeFilterName: '',
  publicFilters: [],
  privateFilters: [],
  publicFiltersExpanded: localStorage[PUBLIC_FILTERS_EXPANDED_STORAGE_KEY]
    ? localStorage[PUBLIC_FILTERS_EXPANDED_STORAGE_KEY] === 'true'
    : true,
  privateFiltersExpanded: localStorage[PRIVATE_FILTERS_EXPANDED_STORAGE_KEY]
    ? localStorage[PRIVATE_FILTERS_EXPANDED_STORAGE_KEY] === 'true'
    : true,
})

const filtersInitialized = computed(() => state.value.filtersInitialized)
const activeFilterId = computed(() => state.value.activeFilterId)
const activeFilterName = computed(() => state.value.activeFilterName)
const publicFilters = computed(() => state.value.publicFilters)
const privateFilters = computed(() => state.value.privateFilters)
const allFilters = computed(() => [...state.value.publicFilters, ...state.value.privateFilters])
const publicFiltersExpanded = computed(() => state.value.publicFiltersExpanded)
const publicFiltersActive = computed(() =>
  state.value.publicFilters.map((filter) => filter.filterId).includes(+state.value.activeFilterId),
)
const privateFiltersExpanded = computed(() => state.value.privateFiltersExpanded)
const privateFiltersActive = computed(() =>
  state.value.privateFilters.map((filter) => filter.filterId).includes(+state.value.activeFilterId),
)
const searchModeActive = computed(() => activeFilterId.value?.toString().startsWith(FilterType.Search))

const pathName = computed(() => {
  return activeFilterId.value?.toString().startsWith('calibration.') ? 'conversations.calibration' : 'conversations'
})

const setActiveFilterId = (id: number | string | null) => {
  if (id) updateActiveFilterName(id)

  state.value.activeFilterId = id
}

const setActiveFilterName = (name: string) => {
  state.value.activeFilterName = name
}

const expandPublicFilters = (expanded = true) => {
  state.value.publicFiltersExpanded = expanded
  localStorage[PUBLIC_FILTERS_EXPANDED_STORAGE_KEY] = expanded
  track(`Filters ${expanded} ? 'expanded' : 'collapsed'`, { isPublic: true })
}
const expandPrivateFilters = (expanded = true) => {
  state.value.privateFiltersExpanded = expanded
  localStorage[PRIVATE_FILTERS_EXPANDED_STORAGE_KEY] = expanded
  track(`Filters ${expanded} ? 'expanded' : 'collapsed'`, { isPublic: false })
}

export const setPrivateFilters = (newFilters: BaseFilter[]) => (state.value.privateFilters = newFilters)
export const setPublicFilters = (newFilters: BaseFilter[]) => (state.value.publicFilters = newFilters)

const getFilters = async (defaultToFirstFilter = false) => {
  if (hasAccountRole('ADMIN', 'MANAGER') || hasWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER')) {
    setPublicFilters(await getPublicFilters().then(({ data }) => data))
  }
  setPrivateFilters(await getPrivateFilters().then(({ data }) => data))
  state.value.filtersInitialized = true

  updateActiveFilterName()

  if (!activeFilterId.value || !activeFilterName.value || defaultToFirstFilter) selectDefaultFilter()
}

export const selectDefaultFilter = () => {
  setActiveFilterId((publicFilters.value[0] || privateFilters.value[0] || {}).filterId)
}

export const handlePublicFiltersNewOrder = ({ newIndex, oldIndex }) => {
  const filter = publicFilters.value[newIndex]

  if (newIndex !== oldIndex) {
    setPublicFilterPosition({
      id: filter.filterId,
      position: newIndex,
    })
  }

  track('Filter dragged', { isPublic: true })
}

export const handlePrivateFiltersNewOrder = ({ newIndex, oldIndex }) => {
  const filter = privateFilters.value[newIndex]

  if (newIndex !== oldIndex) {
    setPrivateFilterPosition({
      id: filter.filterId,
      position: newIndex,
    })
  }

  track('Filter dragged', { isPublic: false })
}

const getFilterName = (id: string | number): string => {
  if (typeof id === 'string') {
    if (id === FilterType.Insights) return i18n.t('conversations.filters.visual_filters')
    if (id === FilterType.Starred) return i18n.t('conversations.sub_nav.starred')
    if (id === FilterType.Drafts) return i18n.t('conversations.filters.drafts')
    if (id.startsWith(FilterType.Search)) return i18n.t('universal.search')

    return id
  }

  return allFilters.value.find((f) => f.filterId === id)?.name || ''
}

const updateActiveFilterName = (id?: number | string) => {
  if (!id) id = activeFilterId.value

  setActiveFilterName(getFilterName(id))
}

export {
  filtersInitialized,
  activeFilterId,
  activeFilterName,
  publicFilters,
  privateFilters,
  allFilters,
  publicFiltersExpanded,
  publicFiltersActive,
  privateFiltersExpanded,
  privateFiltersActive,
  searchModeActive,
  pathName,
  setActiveFilterId,
  updateActiveFilterName,
  expandPublicFilters,
  expandPrivateFilters,
  getFilters,
}
