import { type AssignmentV2_UserAvatar } from '@zendesk/zqa-services/assignments_v2'
import { type UserGroupMember, type UserGroup } from '@zendesk/zqa-services/user-groups'
import { type WorkspaceUser } from '@/modules/user-management/types'
import { type BotParticipant } from '../types/assignments'

export function isBot(
  item: WorkspaceUser | AssignmentV2_UserAvatar | BotParticipant | UserGroupMember | UserGroup,
): item is BotParticipant {
  return 'isBot' in item && item.isBot
}
