import { computed, ref, type VNodeRef } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { capitalize, snakeCase } from 'lodash-es'
import { createPopper } from '@popperjs/core'
import type { Instance } from '@popperjs/core/lib/types'
import type { ReadabilityIssue } from '@zendesk/zqa-services/autoqa'
import { session } from '@/composables/useSession'
import useMessageHighlighting from '@/composables/useMessageHighlighting'
import useReviewSidebar from '@/composables/useReviewSidebar'
import i18n from '@/i18n'
import { translationExists } from '@/i18n/utils'

export default createSharedComposable(() => {
  const { state: sidebarState } = useReviewSidebar()
  const { highlightingMap } = useMessageHighlighting()

  const popper = ref<Instance | null>(null)
  const generateGetBoundingClientRect =
    (x = 0, y = 0) =>
    () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    })

  const virtualReferenceElement = { getBoundingClientRect: generateGetBoundingClientRect() }
  const _tooltipContainerRef = ref<VNodeRef>()
  const mistakeIndex = ref<string>()
  const messageId = ref<string>()
  const isShown = ref(false)

  const setTooltipContainerRef = (el) => {
    // Need this so the template ref is set like :ref="(el) => (tooltipContainerRef = el)"
    _tooltipContainerRef.value = el
  }

  const translateRootCauses = (mistakeName: string) => {
    const key = `settings.workspaces.rating_categories.edit.auto_root_cause.${snakeCase(mistakeName)}`
    if (translationExists(key)) return i18n.t(key)

    return capitalize(mistakeName)
  }

  const tooltipTitle = computed(() =>
    highlightingMap.showGrammarMistakes
      ? translateRootCauses(
          sidebarState.autoQaReviews?.metadataPerMessage[messageId.value]?.mistakes[mistakeIndex.value]?.category,
        )
      : mistakeIndex.value && i18n.t('settings.workspaces.rating_categories.edit.auto_categories.readability.name'),
  )

  const tooltipHelp = computed(() =>
    highlightingMap.showGrammarMistakes
      ? sidebarState.autoQaReviews?.metadataPerMessage[messageId.value]?.mistakes[mistakeIndex.value]?.message
      : translateRootCauses(readabilityType.value && readabilityType.value[mistakeIndex.value]?.type),
  )

  const readabilityType = computed(() => {
    let issues: ReadabilityIssue[] = undefined
    if (highlightingMap.showReadabilityComplexWords) {
      issues = sidebarState.autoQaReviews?.metadataPerMessage[messageId.value]?.readabilityIssues.filter(
        (i: ReadabilityIssue) => i.type === 'complex_words',
      )
    } else if (highlightingMap.showReadabilityLongSentences) {
      issues = sidebarState.autoQaReviews?.metadataPerMessage[messageId.value]?.readabilityIssues.filter(
        (i: ReadabilityIssue) => i.type === 'long_sentences',
      )
    }
    return issues
  })

  const handleMouseOver = () => {
    if (!_tooltipContainerRef.value) return

    popper.value = createPopper(virtualReferenceElement as any, _tooltipContainerRef.value as any, {
      placement: 'top',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    })
  }

  const handleMouseMove = (e: MouseEvent, id: string) => {
    const target = e.target as HTMLElement
    mistakeIndex.value = target?.dataset.mistakeId
    messageId.value = id

    if (!mistakeIndex.value) return

    isShown.value = true

    const rect = target.getBoundingClientRect()
    virtualReferenceElement.getBoundingClientRect = generateGetBoundingClientRect(
      rect.x + (rect.right - rect.x) / 2,
      rect.y,
    )
    popper.value?.update()
  }

  const handleMouseLeave = () => {
    popper.value = null
    mistakeIndex.value = undefined
    messageId.value = undefined
    isShown.value = false
  }

  const highlightingEnabled = computed(
    () =>
      session.features.autoQa &&
      (highlightingMap.showGrammarMistakes ||
        highlightingMap.showReadabilityComplexWords ||
        highlightingMap.showReadabilityLongSentences),
  )

  return {
    translateRootCauses,
    isShown,
    highlightingEnabled,
    tooltipTitle,
    tooltipHelp,
    setTooltipContainerRef,
    createHandlers: (messageId: string | number) => ({
      mouseenter: highlightingEnabled.value ? handleMouseOver : null,
      mousemove: highlightingEnabled.value ? (e) => handleMouseMove(e, messageId.toString()) : null,
      mouseleave: highlightingEnabled.value ? handleMouseLeave : null,
    }),
  }
})
