export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți subdomeniul Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare în Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compania dumneavoastră are un cont existent."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alăturați-vă unei companii"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a vă alătura unei companii existente, cereți administratorului dumneavoastră să vă trimită o invitație."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereți o invitație din partea administratorului"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M-am răzgândit."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare autentificare"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compania dumneavoastră are un cont existent. Doriți să vă conectați la contul companiei dumneavoastră sau să creați unul nou?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiere instrucțiuni"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un cont nou"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Există un cont existent"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu vă puteți accesa contul Zendesk QA până când facturile restante ale abonamentului nu sunt plătite."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul dumneavoastră este în pauză"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contul nu a fost găsit. Încercați din nou."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setarea unei parole noi."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Încercați din nou sau ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinație greșită de adresă de e-mail și parolă."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitați una nouă de la administratorul dumneavoastră."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest link de invitație nu este valabil sau a fost deja utilizat."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Metoda de autentificare ", _interpolate(_named("method")), " este dezactivată în contul dumneavoastră."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această invitație nu este validă sau este folosită. Solicitați una nouă de la administratorul dumneavoastră."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcarea informațiilor despre invitație a eșuat."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificarea a eșuat. Este posibil să existe deja un utilizator cu acest e-mail."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contul dumneavoastră a fost blocat după mai multe încercări consecutive de autentificare. Verificați-vă secțiunea de Inbox pentru a afla dacă ați primit un e-mail cu instrucțiuni despre cum să îl deblocați."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați-vă mai întâi e-mailul"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un cont cu adresă de e-mail validă"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp este obligatoriu"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp este obligatoriu"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetare"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți link-ul magic"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înregistrare"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail serviciu"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailul dumneavoastră de serviciu"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Acord de servicii principale)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola nu corespunde cerințelor"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica de confidențialitate"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Așteptați"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bună, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alăturați-vă organizației ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailul dumneavoastră a fost verificat. Acum vă puteți autentifica cu noul cont de mai jos."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuați cu Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link invitație nevalid"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă cu linkul magic"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenire la autentificare"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuați cu Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuați cu Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailul dumneavoastră a fost verificat. Acum vă puteți autentifica cu noul cont de mai jos:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu linkul magic"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă în Zendesk QA "])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contul dumneavoastră a fost șters"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți un cont?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un cod valid"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp este obligatoriu"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un cont cu adresă de e-mail validă"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp este obligatoriu"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descoperiți toate opțiunile de autentificare"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " prin metode tradiționale"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă cu linkul magic"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați primit un e-mail care conține codul de autentificare."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați primit un e-mail pe care îl puteți urma pentru a vă autentifica."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S-a produs o eroare la trimiterea linkului magic. Încercați din nou."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare prin e-mail"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare parolă"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O literă minusculă"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O majusculă"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noua parolă trebuie să conțină"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lungimea maximă a parolei este de 128 de caractere"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ușor de reținut, greu de ghicit"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minim 8 caractere"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați parola dumneavoastră"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V-ați uitat parola?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O literă minusculă"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un număr"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O literă majusculă"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a vă reseta parola, introduceți adresa dumneavoastră de e-mail și vă vom trimite un e-mail cu instrucțiuni."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un cont cu adresă de e-mail validă"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp este obligatoriu"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetare parolă"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un e-mail a fost trimis la ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă e-mailul nu apare în secțiunea Inbox sau Spam, vă rugăm să verificați adresa de e-mail pe care ați introdus-o."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenire la autentificare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați-vă e-mailul"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetare parolă"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", vi se va trimite un e-mail de resetare a parolei."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă contul dumneavoastră are deja o parolă"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dacă ", _interpolate(_named("bold")), ", atunci veți primi un link de resetare a parolei."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailul este asociat cu un cont"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V-ați amintit parola?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S-a produs o eroare la trimiterea linkului de resetare. Încercați din nou."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetați-vă parola"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă cu link-ul magic"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Politica de confidențialitate)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prin autentificare, sunteți de acord cu Zendesk QA ", _interpolate(_named("terms_of_service")), " și ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termenii de furnizare a serviciului"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveți deja un cont?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu link magic"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu e-mail serviciu"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare cu Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare în Zendesk QA "])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare cu Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am un cont Zendesk activ"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Înscrieți-vă sau autentificați-vă pentru a finaliza configurarea ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți chiar dumneavoastră? Verificați-vă secțiunea de Inbox pentru un e-mail de confirmare."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totul este gata"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA funcționează cel mai bine pe desktop. Autentificați-vă pentru a începe să evaluați conversațiile."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programați o demonstrație"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau programați o demonstrație cu noi și vă vom arăta ce puteți face în Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezența dumneavoastră este solicitată"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anulare"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["M-am răzgândit și vreau să ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " vă invită să vă alăturați contului lor."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înscrieți-vă în cont"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un cont nou"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să vă autentificați din nou"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reacția pe care ați primit-o pentru feedbackul dumneavoastră: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacții primite"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " a reacționat la comentariul dumneavoastră"]), _normalize(["Mai multe persoane au reacționat la comentariul dumneavoastră"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " a reacționat la răspunsul dumneavoastră"]), _normalize(["Mai multe persoane au reacționat la răspunsul dumneavoastră"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " a reacționat la evaluarea dumneavoastră"]), _normalize(["Mai multe persoane au reacționat la recenzia dumneavoastră"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentariu"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["răspuns"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluare"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISPUTE"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAT"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIMIT"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sondaj"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacții"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Început"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcați comentariul ca citit?"]), _normalize(["Marcați toate comentariile ca citite?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcați disputa ca citită?"]), _normalize(["Marcați toate disputele ca citite?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcați feedbackul sondajului ca citit?"]), _normalize(["Marcați tot feedbackul pentru sondaj ca citit?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcați evaluarea ca citită?"]), _normalize(["Marcați toate evaluările ca fiind citite?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentariu marcat ca citit"]), _normalize(["Toate comentariile au fost marcate ca citite"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["A marcat disputa ca citită"]), _normalize(["A marcat toate disputele ca citite"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["A marcat feedbackul din sondaj ca citit"]), _normalize(["A marcat tot feedbackul din sondaj ca citit"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["A marcat evaluarea ca citită"]), _normalize(["A marcat toate evaluările ca citite"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcare toate ca citite"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcare ca citit"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluări"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atunci când cineva creează o dispută pentru o evaluare, aceasta apare aici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există dispute"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atunci când vi se alocă rezolvarea unei dispute, acesta apare aici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au primit dispute"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atunci când disputați o evaluare, aceasta apare aici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există dispute începute"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot feedbackul acordat apare aici. Încă nu avem nimic de afișat."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun feedback primit"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aici apar toate reacțiile date la evaluările și răspunsurile dumneavoastră. Încă nu avem nimic de afișat."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio reacție primită"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschideți o conversație"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot feedback-ul oferit de dumneavoastră ajunge aici. Începeți prin a lăsa o evaluare în vizualizarea Conversații."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există evaluări date"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot feedbackul acordat apare aici. Încă nu avem nimic de afișat."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există evaluări primite"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit comentarii"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot feedbackul pe care îl lăsați în comentarii apare aici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există comentarii"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit comentarii"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate comentariile sub o evaluare pe care o dețineți, ", "@", "mențiunile sau răspunsurile la comentariile dumneavoastră apar aici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au primit comentarii"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există dispute acceptate"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există dispute deschise"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există dispute respinse"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există dispute rezolvate"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați din nou alegând o altă opțiune din filtrul de mai sus."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați din nou, alegând un alt utilizator din filtrul de mai sus."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii date"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii primite"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate disputele"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute primite"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute începute"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedbackul primit în urma sondajului"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări date"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare listă de activități"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " evaluat"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " evaluat"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " evaluate"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repornește în ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " rămas"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurați o alocare"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare listă de alocări"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nu este posibilă crearea unei sesiuni de instruire între ", _interpolate(_list(0)), " și ", _interpolate(_list(1)), ", deoarece în prezent nu se află în același spațiu de lucru."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Există deja sesiuni de instruire între ", _interpolate(_list(0)), " și ", _interpolate(_list(1)), ". Contactați ", _interpolate(_list(0)), " pentru a obține acces la sesiune."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugestie"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A fost creată o nouă sesiune între ", _interpolate(_list(0)), " și ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare instructor"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană instruită"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare persoană instruită"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiune"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare sesiune"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați o sesiune"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalii"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare sesiune"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume sesiune"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugestie"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întârziată"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterioară"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viitoare"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare sesiune"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit sesiuni"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timpul mediu de evaluare"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablou de bord"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu se afișează date pentru perioada selectată"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Element fixat adăugat la sesiune. ", _interpolate(_named("buttonStart")), "Arătă-mi", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare la..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugate"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați ajuns la sfârșitul elementelor fixate din perioada selectată"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vedeți cum funcționează elementele fixate în acest ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["articol"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se pare că nu ați fixat nimic pentru ", _interpolate(_list(0)), ". Puteți folosi elemente fixate pentru a vă pregăti pentru întâlnirile unu la unu sau pentru a păstra ceva pentru o dată ulterioară."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persoana instruită"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun element fixat adăugat"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aici sunt toate elementele fixate pe care le-ați creat pentru ", _interpolate(_named("name")), ". Elementele fixate adăugate la sesiuni vor fi ", _interpolate(_named("bold")), " cu celălalt participant."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partajată imediat"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se pare că ", _interpolate(_list(0)), " nu a primit niciun feedback de la evaluarea conversației sau CSAT în perioada selectată."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există feedback disponibil"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați ajuns la sfârșitul comentariilor din perioada selectată"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analize metrice"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente fixate"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Numerele lui ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element de acțiune nou"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Finalizat de ", _interpolate(_named("user")), " pe ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["de către ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dumneavoastră"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastați pentru a adăuga un nou element de acțiune"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce a reieșit din această întâlnire? Care sunt următorii pași?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente de acțiuni"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creat de ", _interpolate(_named("name")), " la ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ștergeți sesiunea"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Acest lucru va fi permanent ", _interpolate(_named("deleteSession")), ". Participanții pierd accesul la sesiune, inclusiv la conținutul acesteia și la notele private."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sesiune creată pe ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editat ultima dată de ", _interpolate(_named("name")), " pe ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puncte de discuție și note"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notă actualizată"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Părăsire sesiune"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajare"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii selectați au acces la detaliile sesiunii începând din acest moment."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare utilizatori"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajat"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajare sesiune"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nu veți mai face parte din sesiunea ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nu va mai face parte din această sesiune."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " are acces la această sesiune acum."]), _normalize([_interpolate(_named("n")), " utilizatori noi au acces acum la această sesiune"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere sesiune"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți toate sesiunile"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere sesiune"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instructor"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Notele adăugate de ", _interpolate(_list(0)), " vor apărea aici."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați elemente fixate ca puncte de discuție din panoul din dreapta"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente fixate"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note pe care numai dumneavoastră le puteți vedea..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note personale"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notă privată actualizată"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 de zile"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminat"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană instruită"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente de acțiuni"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima sesiune"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminat"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În curs de desfășurare"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întârziate"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală a sesiunilor"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare toate"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu: Instructor"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu: Persoană instruită"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate sesiunile"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni partajate"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să schimbați un filtru pentru a vedea elementele de acțiune."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun element de acțiune"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente de acțiuni"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite și vizualizate"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primită · Văzută"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresul după sesiuni"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că agenții nu au primit niciun feedback."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați că aveți numele corect al utilizatorului"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut găsi acel utilizator"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt disponibile date"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS din ultimele 30 de zile"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Acest lucru va <b>șterge definitiv toate sesiunile</b> (2) cu <b>", _interpolate(_named("name")), "</b>. Participanții pierd accesul la toate sesiunile, inclusiv la conținut și la notele private."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați ajuns la sfârșitul tuturor sesiunilor"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O sesiune de instruire este o activitate de grup între un mentor și o persoană instruită pentru a discuta despre performanță, oportunități, obiective pe termen scurt și lung."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți căuta sesiuni atât după numele instructorului, cât și după cel al persoanei instruite."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit sesiuni"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există sesiuni create"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere istoric instruire"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere istoric instruire "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima sesiune"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiune"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate sesiunile"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană instruită"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminat"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcată ca terminată"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcată ca neterminată"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după nume"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redenumire"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare listă sesiune"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplanificat"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspundeți la acest mesaj"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiune de calibrare expirată. Nu pot fi lăsate evaluări suplimentare."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați lăsat deja o evaluare pentru această conversație."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluarea acestei conversații nu mai este posibilă deoarece data scadentă a sesiunii a trecut."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această conversație nu are evaluări"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere panou"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți o evaluare de calibrare"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETALII"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REZUMAT"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSCRIERE"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vorbitor ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goluri de conversație"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare vorbitor"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de evaluare"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reveniți la redarea mesajului"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbitori"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcriere"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii spațiului de lucru"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați mai întâi conexiunea pentru a crea filtre"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără boți"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creat la ", _interpolate(_named("time")), " de către ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultima actualizare pe ", _interpolate(_named("time")), " de către ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se pare că nu aveți filtre. Pentru a accesa această pagină, trebuie mai întâi să creați un filtru. Începeți cu ", _interpolate(_named("recommendedFilter")), " pentru a vă vizualiza propriile conversații. Creați acest filtru mai jos pentru a continua."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în ultimele 30 de zile"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiția „Creat în ultimele 30 de zile” va fi adăugată la filtrele create fără un interval de date"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrul nu este disponibil în acest abonament și nu va fi luat în considerare la afișarea rezultatelor"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obținerea opțiunilor de filtrare a eșuat. Încercați din nou."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre filtrare"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat = persoana autentificată"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Conversații găsite: ", _interpolate(_named("count"))]), _normalize(["Conversație găsită: ", _interpolate(_named("count"))]), _normalize(["Conversații găsite: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil în abonamentul dumneavoastră actual. Treceți la un abonament superior pentru a utiliza această funcție."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare condiție"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IA Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiție"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformare în alocare"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare filtru"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare filtru"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmpuri personalizate"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiții"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcăm opțiunile de filtrare în funcție de configurarea dumneavoastră specifică. Acest lucru poate dura câteva minute la încărcarea inițială."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dați un nume descriptiv filtrului dumneavoastră."])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume filtru"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o opțiune"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiție"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare filtru"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare modificări"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există valori disponibile"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă…"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o valoare"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibilitate"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar sesiunile active ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare sesiuni mai vechi ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data scadentă"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare sesiune de calibrare"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare sesiune"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare sesiune de calibrare"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate evaluările de calibrare acordate în timpul sesiunii vor fi șterse definitiv."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere sesiune"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergeți ", _interpolate(_named("sessionName")), " sesiune de calibrare"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create sesiuni"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există sesiuni active"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici o sesiune creată"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există sesiuni active"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare filtru"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un filtru privat"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un filtru public"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru adăugat"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrul va fi șters definitiv."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere filtru"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergeți filtrul ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate disputele"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute pentru mine"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute din partea mea"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute deschise"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schițe"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au adăugat filtre"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create filtre private"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create filtre publice"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre private"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre publice"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doriți să ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salvați"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta este o vizualizare temporară a filtrului."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrul dumneavoastră se creează..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru actualizat"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie personalizată asigurarea calității automată"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultima actualizare ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuvinte complexe"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluările automate se actualizează în mod dinamic pe baza modificărilor de tichete"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidențiere în conversație"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propoziții lungi"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun punctaj automat prognozat"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta nicio greșeală"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare cuvinte complexe"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare propoziții lungi"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Următoarea conversație"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată nu a putut evalua această categorie din cauza contextului insuficient. Se recomandă evaluarea manuală."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente importante"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descoperiți informații despre conversații automatizate pentru a vă simplifica procesul de evaluare. Evidențierea conversațiilor cruciale și prezentarea unor exemple pozitive deosebite pentru echipa dumneavoastră."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Apel]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corectat"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șters"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a acceptat disputa"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dispută trimisă la ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicați de ce credeți că această persoană evaluată nu este destinatarul potrivit pentru acest feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un comentariu și stabiliți un nouă persoană evaluată"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un comentariu"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicați de ce credeți că aceste punctaje sunt evaluate în mod incorect (obligatoriu)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un comentariu (obligatoriu)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicați de ce respingeți această dispută"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un comentariu"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere dispută către"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere dispută către"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere dispută către"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o nouă persoană evaluată"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback pentru dispută"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback pentru dispută"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare persoană evaluată"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere dispută către"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispută"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputați această evaluare"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt de acord cu punctajele"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această evaluare nu îmi era destinată"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați un utilizator"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a acceptat parțial disputa"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a respins disputa"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptat"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parțial acceptată"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respinsă"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschise"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " a inițiat o dispută: evaluare"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " a inițiat o dispută: persoană evaluată greșită"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O dispută deschisă ar trebui să fie soluționată pentru a putea întreprinde alte acțiuni privind evaluarea."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptare"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați noile punctaje"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere dispută"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să eliminați această dispută?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere dispută"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispută"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timpul de rezolvare a disputei"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respingere"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați noi punctaje corecte (opțional)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputați evaluarea"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată greșită"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptați disputa"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptați disputa"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback pentru dispută"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată greșită"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback pentru dispută"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respingeți disputa"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respingeți disputa"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați disputa"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editat"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " de către ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["cu ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am terminat"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocat către:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lăsați un comentariu"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre fișele de evaluare condiționale"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocat anterior către:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți doar unul"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu am putut găsi niciun GIF cu acest nume"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiți primul care lasă o evaluare."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul dumneavoastră este întrerupt în prezent. Reactivați-l pentru a permite acordarea de feedback."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postat de:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru arată cât de mult timp a fost petrecut pentru a evalua această conversație"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați un utilizator"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lipsește cauza principală pentru categoria: „", _interpolate(_list(0)), "”"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați o categorie sau adăugați un comentariu"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare emoticon"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrângere"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extindere câmp de comentarii"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrângere câmp de comentarii"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere formatare"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere panou"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare formatare"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare panou"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere evaluare"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați toate categoriile"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați cel puțin o categorie"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvează această conversație pentru mai târziu"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare pentru mai târziu"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Următoarea alocare"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înlocuiți conversația în acest ciclu de alocare"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare conversație"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare nivel de referință"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere comentariu"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu șters"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți acest comentariul?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor comentariu"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest hashtag a fost șters sau redenumit."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Împărtășiți ce v-a plăcut sau ce ar fi putut fi mai bun..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentați"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condițional"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru va șterge definitiv toate comentariile."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere comentariu"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Mesaj]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nu a văzut încă acest feedback"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a văzut acest feedback"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fost membru"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați GIF-uri..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apăsați Enter pentru a crea un nou hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-urile personalizate sunt blocate de către administrator."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizați acest lucru pentru a marca cazuri și a le filtra mai târziu"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcați ca nivel de referință"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj feedback"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Următoarea conversație"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici un feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au efectuat evaluări"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lăsați o evaluare mai jos pentru a începe."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au mai rămas alte comentarii"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neevaluat"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat anterior"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația anterioară"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare ca nivel de referință"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspuns"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspundeți la această evaluare"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere bară laterală de evaluare"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru va șterge definitiv evaluarea și toate comentariile adăugate."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere evaluare"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere evaluare"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare ștearsă"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Evaluare ștearsă de ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați terminat!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocați-vă această sarcină pentru a lăsa o evaluare"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați lăsat deja o evaluare pentru acest utilizator."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ați lăsat o evaluare pentru ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există categorii de afișat pentru această conversație."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluările nu sunt disponibile pentru acest spațiu de lucru."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu salvați"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvați această evaluare ca schiță?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminați această schiță"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare bară laterală de evaluare"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A eșuat"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omitere"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admis cu erori minime"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încă se mai calculează cifrele..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lăsați un comentariu"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați acest mesaj"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați-vă"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați această conversație"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există utilizatori de evaluat"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluări dezactivate"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit niciun utilizator"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participanți"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii spațiului de lucru"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă…"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare utilizator"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 evaluare"]), _normalize([_interpolate(_named("count")), " evaluări"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare evaluări noi"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managerul spațiului dumneavoastră de lucru nu a permis auto-evaluările."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apăsați Enter pentru a eticheta acest element fixat"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrări"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimele 30 de zile"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcate ca fiind importante"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați o sesiune de calibrare"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schițe"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații vă oferă o reprezentare vizuală a datelor din biroul de asistență. Folosiți diagramele interactive pentru a înțelege unde să vă concentrați eforturile de asigurare a calității, pentru a descoperi elementele care necesită îmbunătățire și pentru a găsi agenții potriviți pe care să îi evaluați în funcție de performanța lor."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " IDENTIFICATOR"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " Adresă URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traduceți în ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare la"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nealocat"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare curentă"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați acum"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această conversație a fost eliminată și nu mai este disponibilă."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această conversație pe care o căutați nu există în Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate elementele fixate cu această conversație"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În sesiune"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare conversație"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminarea unei conversații dintr-o sesiune va șterge toate evaluările acordate acesteia."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eliminați conversația din ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create încă sesiuni de calibrare."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această conversație a fost adăugată la sesiune"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această conversație a fost eliminată din sesiune"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugarea la/eliminarea dintr-o sesiune de calibrare"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiați linkul către această conversație"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio conversație de afișat"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcă"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailul clientului"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator client"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume client"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume formular extern"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPPR"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp de primire a primului răspuns"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritate"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu sondaj"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj satisfacție"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp pentru prima rezolvare"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă…"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest mesaj nu există în cadrul acestei conversații sau a fost șters"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun subiect"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio conversație de afișat"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit nicio conversație"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschidere conversație originală"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu se poate crea un link către conversație - URL lipsă"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participanți"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără participanți"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixare conversație"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți clic pentru a citi evaluări"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți clic pentru a evalua"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversație creată prin intermediul unei extensii de browser"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați sondajul de satisfacție a clienților"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare conversație"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj cu un sentiment negativ"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj cu un sentiment pozitiv"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradu în engleză"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare conținut original"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere conversație"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergerea unei conversații este permanentă. De asemenea, șterge toate datele aferente, de exemplu sondaje, transcrieri și evaluări din Zendesk QA, inclusiv tablourile de bord.", _interpolate(_named("lineBreak")), "Veți putea accesa în continuare această conversație în Zendesk Support sau în alt software de birou de asistență pe care îl utilizați."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergerea unei conversații este permanentă. De asemenea, șterge toate datele conexe, de exemplu, sondaje, transcrieri și evaluări din Zendesk QA, inclusiv tablourile de bord. Dacă conversația aparține mai multor spații de lucru, aceasta va fi ștearsă din toate.", _interpolate(_named("lineBreak")), "Veți putea accesa în continuare această conversație în Zendesk Support sau în alt software de birou de asistență pe care îl utilizați."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere conversație"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți conversația din Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această conversație este incompletă din cauza prioritizării. Mergeți la ", _interpolate(_named("optionsUrl")), " și validați-vă etichetele. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această conversație este incompletă din cauza prioritizării. Discutați cu administratorul despre validarea etichetelor. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiuni > Opțiuni avansate"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre etichete"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închisă"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nou"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschis"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În așteptare"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvat"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Responsabilul desemnat a fost modificat în ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nealocat"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macromanda ", _interpolate(_named("target")), " a fost aplicată de ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conversația a primit o evaluare de ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilizatorul ", _interpolate(_named("target")), " a redactat conversația"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stare modificată în ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Etichetat cu ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Echipa s-a schimbat în ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " a amânat conversația pentru ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " a anulat amânarea conversației"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot blocat într-o buclă, repetând consecutiv un mesaj"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetare bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetare bot, unde același mesaj este repetat o dată sau de mai multe ori succesiv"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraze găsite care corespund"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un agent gestionează conversația ", _interpolate(_named("percent")), " mai eficient decât botul"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiența comunicării cu botul"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Botul a gestionat conversația ", _interpolate(_named("percent")), " mai eficient decât un agent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiența comunicării cu botul"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația nu conține declarația necesară de informare a participanților cu privire la înregistrare, care este necesară pentru conformitate și transparență."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarația privind înregistrarea lipsește"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Un acord privind nivelul serviciului (SLA) a fost încălcat pentru ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA încălcat"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza metrică pentru regula SLA a depășit timpul țintă"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semne de pierdere a clienților. Clientul se gândea la o schimbare sau promitea că va renunța la serviciu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risc de dezabonare identificat"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversația are goluri, care durează mai mult decât pragul stabilit de ", _interpolate(_list(0)), " s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instanță"]), _normalize([_interpolate(_named("count")), " instanțe"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cel mult ", _interpolate(_list(0)), " s"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goluri de conversație"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul a solicitat asistență la un nivel superior"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escaladare obligatorie"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul și-a exprimat recunoștința extremă sau a fost foarte mulțumit de asistența primită"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicii excepționale"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fie clientul, fie agentul a solicitat în mod explicit o continuare a comunicării"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitare de continuare a comunicării"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul s-a arătat nemulțumit"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment negativ"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația a fost neobișnuită sau atipică și au existat mai multe schimburi de replici pentru a ajunge la o rezolvare"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru de excludere detectat"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul a arătat satisfacție"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment pozitiv"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicabil"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setați ca neaplicabil"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editat ultima dată de ", _interpolate(_named("author")), " pe ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este aplicabil"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De către ", _interpolate(_named("author")), " pe ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un motiv (opțional)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost detectate informații"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare conexiune"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați-vă biroul de asistență la cont și creați cel puțin un filtru pentru a vedea lista de conversații."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există conversații de afișat"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare setări spațiu de lucru"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima actualizare"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setare"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Peste o zi)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Luna aceasta)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Săptămâna aceasta)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după identificator"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rezultatele căutării pentru ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat de alții"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentate"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai recente"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai vechi"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleatorii"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizate recent"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru care s-au acordat recent răspunsuri"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortare după"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necitit"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat de dumneavoastră"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesul de importare poate dura ceva timp. Vă vom trimite un e-mail când va fi gata."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA vă importă conversațiile"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limită: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați făcând clic pe pictograma de calibrare din antetul vizualizării conversației."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimic de raportat. Încercați din nou să utilizați un alt filtru sau o altă opțiune de sortare."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există filtre de excludere care să fie afișate."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți o conversație relevantă și faceți clic pe pictograma cu stea din antet pentru a o marca aici."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să schimbați filtrele sau invitați alți agenți de la biroul de asistență să adauge mai multe conversații."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără limită"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notă internă"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcăm conversațiile chiar acum. Este posibil ca o parte din conținut să lipsească în continuare."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Următorul utilizator"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit niciun utilizator"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membrii spațiului de lucru"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator anterior"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au adăugat conversații"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost adăugate conversații"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluările neterminate ajung aici. Recitiți-o, revizuiți-o și trimiteți-o în următoarele 30 de zile."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există evaluări în stadiu de schiță"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există conversații marcate ca fiind importante"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există conversații de afișat"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio potrivire"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reveniți la mesajul selectat"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai puține răspunsuri"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai multe răspunsuri"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare listă conversație"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apel acceptat la ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apelul s-a încheiat la ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apel plasat la ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat de dumneavoastră"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Încărcarea fișierului audio a eșuat. Asigurați-vă că sunteți autentificat la ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu privind sondajul de satisfacție a clienților"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare la încărcare audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor comentariu"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a avut loc niciun apel"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascultați în biroul de asistență"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascultați pe Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viteza de redare"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare apel"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcriere"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridicat"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scăzut"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediu"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este un utilizator Zendesk QA "])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA clasifică automat agenții pe baza mediei lunare a CSAT. Găsiți conversațiile gestionate de agenții al căror CSAT este mai mic decât cel al colegilor lor, cu un singur clic."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificare după CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medie"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridicată"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scăzută"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cea mai scăzută"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cea mai ridicată"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este un utilizator Zendesk QA "])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un tichet"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volum"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA clasifică automat agenții în grupuri de performanță pe baza numărului de conversații pe care le gestionează pe lună. Găsiți conversații ale agenților cu un volum mai mic decât al colegilor lor, cu un singur clic."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificare în funcție de volum"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost evaluat"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare evaluare"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scăzut"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridicat"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutru"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este un utilizator Zendesk QA "])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA clasifică automat agenții pe baza mediei sentimentului din conversații. Găsiți interacțiunile în care agenții se confruntă cu mai mulți clienți frustrați decât colegii lor, cu un singur clic."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificare în funcție de sentiment"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedetectat"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectată"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre de excludere"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații care necesită atenția dumneavoastră. Modelul nostru de învățare automatizată identifică interacțiunile complexe sau atipice pentru a fi evaluate ulterior."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre de excludere"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitiv"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați conversațiile în care clientul a manifestat fie încântare, fie frustrare. Utilizați lista derulantă pentru a selecta tipul de sentiment pe care îl căutați în conversațiile dumneavoastră. Glisați atributul filtrului în partea de sus pentru a vedea proporția sentimentului ales în toate interacțiunile dumneavoastră."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare evaluare"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost evaluat"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiune"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA detectează automat limbile din conversațiile dumneavoastră. Utilizați meniul derulant pentru a selecta conversațiile în anumite limbi și pentru a le evalua. Glisați atributul de filtrare în partea de sus pentru a obține o prezentare generală a tuturor limbilor utilizate în biroul dumneavoastră de asistență."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații conversație"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puteți ", _interpolate(_named("paragraph_2_item")), " cu informații decât cu filtrele obișnuite de conversație. Obțineți o prezentare generală vizuală a tuturor datelor din birourile dumneavoastră de asistență și explorați informații precum:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați cum să utilizați informații conversație"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înțelegeți importanța relativă a anumitor atribute ale conversației în raport cu altele"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți agenții cu cele mai bune și cele mai slabe performanțe cu ajutorul clasificării integrate a performanțelor agenților"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " este un instrument interactiv puternic de extragere a datelor, care vă ajută să găsiți conversațiile, care au nevoie de o evaluare."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["face mult mai mult"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diagramele Conversație și Agent sunt ", _interpolate(_named("paragraph_3_item_1")), " - actualizarea filtrelor sau un clic pe elementele segmentate din oricare dintre ele va actualiza automat lista de conversații. ", _interpolate(_named("paragraph_3_item_2")), " pentru a explora defalcări detaliate sau rapoarte ale diferitelor elemente."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconectate"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rearanjare atribute filtru"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Informații conversație afișează doar ", _interpolate(_named("paragraph_4_item")), ". Eliminăm automat interacțiunile fără valoare reală de evaluare, precum mesajele generate automat, spam, răspunsurile boților sau mesajele care nu au un dialog adecvat cu reprezentantul de asistență."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversații rezolvate și încheiate"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce văd aici?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " din ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " din ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " din ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " din ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obținerea a eșuat"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " NU ESTE DISPONIBIL"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenți"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " din total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reîncărcare"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar suntem siguri că va fi grozav odată ce va fi disponibil."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaj personalizat"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a rămas niciun feedback de sondaj"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încă nu este disponibil niciun punctaj de feedback din sondaj..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motiv"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primit"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetă feedback"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete pentru comentarii feedback"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se aplică un filtru de interval de date"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " răspuns"]), _normalize([_interpolate(_named("count")), " răspunsuri"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birou de asistență"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aflați mai în detaliu motivul pentru care a fost acordat un punctaj ", _interpolate(_list(0)), " prin stabilirea motivelor în setările sondajului."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nu există încă motive ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre motivele sondajului"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații cu etichete:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " de către"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbi"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motive"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie comentariu"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " motive"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tipare ", _interpolate(_named("surveyType")), " prognozate"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă conversație"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete birou de asistență"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Răspunsuri la sondaj (", _interpolate(_named("n")), ")"]), _normalize(["Răspuns la sondaj (", _interpolate(_named("n")), ")"]), _normalize(["Răspunsuri la sondaj (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun subiect"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversații cu ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " de către utilizatori"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rată de răspuns"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Punctajul ", _interpolate(_list(0)), " este o sumă a tuturor răspunsurilor împărțită la suma punctajelor maxime"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare țintă"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare țintă"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabiliți o țintă"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țintă"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare țintă"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scurt"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foarte lung"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediu"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confuz"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inutil"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență slabă"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență bună"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar emoji"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cont"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback pentru agent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plângere"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foarte scurt"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema nu este rezolvată"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemă rezolvată"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment negativ"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultat negativ"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment pozitiv"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laudă"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produs slab"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rambursare"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență rapidă"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență lentă"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă mulțumesc"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA alocă automat datele ", _interpolate(_list(0)), " în categorii predefinite. Astfel, puteți să înțelegeți informații complexe și să explorați cauzele fără a trece manual prin feedbackul scris."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate punctajele"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate sondajele"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați limba de răspuns"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiune comentariu"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare dimensiune comentariu"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Având în vedere că 25% dintre comentarii constau într-un singur cuvânt, comentariile mai lungi trebuie citite. Găsiți mai multe feedbackuri mai pătrunzătoare fără a depune muncă manuală, deoarece noi o facem automat pentru dumneavoastră."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motiv feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare motiv feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaje"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există punctaje care să corespundă"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipare prognozate"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați categoria de comentarii"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuvinte reprezentate vizual"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați cuvintele reprezentate vizual"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " de la ultima perioadă"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversații"]), _normalize([_interpolate(_named("n")), " conversație"]), _normalize([_interpolate(_named("n")), " conversații"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportul de sondaje trimise din toate conversațiile nu a putut fi calculat din cauza lipsei de date."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje trimise"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pâlnie sondaj"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " din ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " din ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " din ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tipare ", _interpolate(_named("surveyType")), " prognozate"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA alocă automat datele ", _interpolate(_list(0)), " în categorii predefinite. Astfel, puteți să înțelegeți informații complexe și să explorați cauzele fără a trece manual prin feedbackul scris."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiune comentariu"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Având în vedere că 25% dintre comentarii constau într-un singur cuvânt, comentariile mai lungi trebuie citite. Găsiți mai multe feedbackuri mai pătrunzătoare fără a depune muncă manuală, deoarece noi o facem automat pentru dumneavoastră."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oră"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri la sondaj"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (medie)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de răspunsuri"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (medie)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țintă"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediu"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tendințe ", _interpolate(_list(0)), " de către"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de răspunsuri"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbi"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motive"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipare"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data trimiterii sondajului ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data răspunsului la sondaj ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Alocați punctajul ", _interpolate(_list(0)), " către"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare date pe baza"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creării conversației"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proprietar tichet"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agentul cu cele mai multe răspunsuri"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (medie)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " în timp"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de răspunsuri"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " și IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de răspunsuri"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații care includ aceste cuvinte:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mănunchi de cuvinte"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT de către utilizatori"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pâlnie sondaj"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri la sondaj"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete birou de asistență"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT în timp"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mănunchi de cuvinte"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici un canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără sursă"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără limbă"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun motiv"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără etichete"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără categorie comentariu"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără spațiu de lucru"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există date disponibile, deoarece nu au fost create alocări sau nu au fost pornite cicluri. Reveniți mai târziu sau reglați filtrele."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorați noutățile"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " element"]), _normalize([_interpolate(_named("count")), " elemente"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversație"]), _normalize([_interpolate(_named("count")), " conversații"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A eșuat"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reușit"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admis cu erori minime"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reușit/respins"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediu"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medie (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medie (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Evaluări ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media CSAT = [suma punctajelor de feedback ÷ (numărul tuturor răspunsurilor × punctajul maxim posibil)] × 100%."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctajul CSAT al Zendesk QA normalizează punctajul de feedback pe o scară de 0-100% și ia o medie a punctajelor standardizate."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Customer Satisfaction Score - Punctajul de satisfacție a clienților) cuantifică gradul de satisfacție a clienților dumneavoastră față de serviciile dumneavoastră."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Fiecare evaluare reprezintă evaluarea unei persoane pentru o conversație. O conversație poate avea mai mult de o evaluare (dacă doi sau mai mulți evaluatori evaluează aceeași conversație)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urmăriți evoluția în timp a punctajelor acordate de evaluatori."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urmăriți evoluția punctajelor agenților de-a lungul timpului."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " răspunsuri"]), _normalize([_interpolate(_named("n")), " răspuns"]), _normalize([_interpolate(_named("n")), " răspunsuri"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " evaluări"]), _normalize([_interpolate(_named("n")), " evaluare"]), _normalize([_interpolate(_named("n")), " evaluări"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzii:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (punctaj de calitate internă) este rezultatul evaluărilor conversației dumneavoastră."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = suma punctajelor evaluării ÷ numărul de evaluări"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este calculată ca o medie, luând în considerare toate punctajele evaluării (media ponderată a punctajului categoriei), care corespund condițiilor de filtrare selectate."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcarea cardului a eșuat."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați filtrele pentru a încerca din nou."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata de reușită indică rezultatul evaluărilor conversației dumneavoastră."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Respins dacă punctajul de evaluare este ", _interpolate(_named("failScore")), " sau mai mic"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reușit dacă punctajul de evaluare este ", _interpolate(_named("passScore")), " sau mai mare"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta este determinat de un nivel de referință predefinit, ceea ce înseamnă că dumneavoastră:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata de reușită = Suma evaluărilor reușite ÷ Suma evaluărilor totale"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata de reușită indică un procentaj al numărului de evaluări reușite fără a fi respinse la categoriile critice sau necritice."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De exemplu, dacă evaluatorul a acordat trei evaluări în această zi, numărul afișat este media celor trei punctaje. Vedeți cum se calculează fiecare celulă ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De exemplu, dacă agentul primește trei evaluări în această zi, numărul afișat este media celor trei punctaje. Vedeți cum se calculează fiecare celulă ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt luate în considerare: ponderea categoriei, starea critică (spre deosebire de punctaje în timp)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deoarece categoriile pot fi marcate cu „nu este disponibil” în evaluări, numărul de evaluări pentru fiecare categorie poate fi mai mic decât numărul total de evaluări ale evaluatorului. Vedeți cum este calculată fiecare celulă"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt luate în considerare: ponderea categoriei, starea critică (spre deosebire de punctaje în timp)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deoarece categoriile pot fi marcate cu „nu este disponibil” în evaluări, numărul de evaluări pentru fiecare categorie poate fi mai mic decât numărul total de evaluări pentru fiecare agent. Vedeți cum este calculată fiecare celulă"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt luate în considerare: ponderea categoriei, starea critică (spre deosebire de punctaje în timp)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Grupurile de categorii sunt afișate separat. Punctajul pentru fiecare grup este calculat prin punctajul mediu al categoriei respective în aceeași perioadă. Vedeți cum se calculează fiecare celulă ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notă:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt luate în considerare: ponderea categoriei, starea critică (spre deosebire de punctaje în timp)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Grupurile de categorii sunt afișate separat. Punctajul pentru fiecare grup este calculat prin punctajul mediu al categoriei respective în aceeași perioadă. Vedeți cum se calculează fiecare celulă ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nivel de referință ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără erori critice"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata fără erori critice indică un procentaj al numărului de evaluări, care sunt admise fără să fie afectate de nicio categorie critică."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj de calitate internă"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Alte” cauze principale"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbare periodică"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbare zilnică"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbare lunară"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbare săptămânală"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbare anuală"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaje în timp"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați fie în zile, fie în săptămâni."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul = punctajul total pentru ziua sau săptămâna respectivă împărțit la numărul total de evaluări acordate"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați fie în zile, fie în săptămâni."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul = scorul total pentru ziua sau săptămâna respectivă împărțit la numărul total de evaluări primite"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaje pe categorii"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați media combinată a tuturor evaluărilor categoriilor acordate de evaluator în această perioadă."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați media combinată a tuturor evaluărilor categoriei primite pentru fiecare agent în această perioadă."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauzele principale ale evaluărilor scăzute"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauzele principale ale evaluărilor"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctajele pe categorii în timp"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizați modul în care categoriile se compară în timp."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați punctajele medii acordate pentru fiecare categorie într-o anumită zi sau săptămână."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizați modul în care categoriile se compară în timp."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați punctajele medii primite pentru fiecare categorie într-o anumită zi sau săptămână."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaje în funcție de evaluări"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivate"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria critică"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala de evaluare"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medie/Total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excludere auto-evaluări"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportă toate fișele"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primit"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["conversație"]), _normalize(["conversații"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["element"]), _normalize(["elemente"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " răspunsuri"]), _normalize([_interpolate(_named("n")), " răspuns"]), _normalize([_interpolate(_named("n")), " răspunsuri"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " evaluări"]), _normalize([_interpolate(_named("n")), " evaluare"]), _normalize([_interpolate(_named("n")), " evaluări"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Arată încă unul"]), _normalize(["Arată încă ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimele 30 de zile"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimele 7 zile"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima lună"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima săptămână"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luna aceasta"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Săptămâna aceasta"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă personalizată"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zile"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luni"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestre"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Săptămâni"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ani"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcarea cardului a eșuat. Modificați filtrele pentru a încerca din nou."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există date de afișat."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbare lunară"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablouri de bord"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai multe informații."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrele dumneavoastră active includ date pe diferite scale de evaluare."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate elementele de fixare ale cardului cu aceste filtre"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate elementele de fixare ale tabloului de bord cu aceste filtre"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timpul mediu de evaluare"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizare"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcare CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără categorii"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie informații"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafic"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentariu"]), _normalize(["Comentarii"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fost membru"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj de calitate internă"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (medie)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări reușite"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixare fișă"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixare tablou de bord"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cauza principală"]), _normalize(["Cauze principale"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator evaluare"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp de evaluare"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabilirea cauzelor principale"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauzele principale vă permit să știți de ce conversațiile primesc punctaje scăzute"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt stabilite cauze principale pentru această categorie"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj / Total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii de evaluare"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupe de categorii de evaluare"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup de categorii"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiu de lucru"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizare comandă"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupați categoriile în funcție de"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare toate"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizare"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excludere auto-evaluări"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includere auto-evaluări"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare doar auto-evaluări"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golire filtre"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit hashtag-uri"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-uri comentariu"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare hashtag-uri comentariu"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit nicio etichetă"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete birou de asistență"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare etichete pentru biroul de asistență"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit fișe de evaluare"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare fișe de evaluare"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluări"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există conexiuni care să corespundă"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă conversație"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați sursa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai multe filtre"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există spații de lucru care să corespundă"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total timp acordat"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oricine"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate grupurile"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există grupuri care să corespundă."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate grupurile"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare fișe"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare date pe baza"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creării evaluării"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creării conversației"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați două zecimale"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați setările"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate spațiile de lucru"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întregul cont"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume spațiu de lucru"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există spații de lucru"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizat"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steaguri"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mâncare și băutură"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animale și natură"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiecte"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoane și corp"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Călătorii și locuri"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizate frecvent"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultate căutare"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fețe zâmbitoare și emoticon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simboluri"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost găsit nici un emoticon"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschideți o conversație"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi la lista de sarcini"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " sau răsfoiți lista dumneavoastră de ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["În prezent, utilizați spațiul de lucru ", _interpolate(_named("workspace")), ", care nu este conectat la acest domeniu. Faceți clic aici pentru a-l conecta acum."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conexiuni disponibile"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu puteți oferi feedback pe ", _interpolate(_named("host")), " încă."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveți biroul de asistență ca filă activă."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest domeniu nu poate fi evaluat încă. Dacă este cazul, anunțați administratorul."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați acest domeniu"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conectare cu ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar un moment până finalizăm totul pentru dumneavoastră"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut obține adresa URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumente care sunt deja conectate cu contul Zendesk QA al organizației."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există instrumente conectate cu contul Zendesk QA al organizației."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă permite să evaluați site-uri web în care adresa URL rămâne mereu aceeași, adăugând o valoare hash unică la sfârșit înainte de a lăsa o evaluare"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare cont"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiuni"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeniile dumneavoastră"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenii care sunt deja conectate la acest spațiu de lucru."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți evalua orice pagină din următoarele domenii"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenii disponibile pentru evaluare"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensie"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă URL unică"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Încă nu este conectat niciun birou de asistență - accesați ", _interpolate(_named("link")), " pentru a adăuga unul."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensia de browser Zendesk QA poate fi utilizată doar\npentru abonamentele Professional și Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare spațiu de lucru"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un nume"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți o valoare"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmpuri personalizate"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoare"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este prima dată când întâlnim această conversație. Furnizați câteva detalii."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referință conversație:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceva pentru a identifica tichetul mai târziu"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rambursare"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți atât responsabilul desemnat, cât și subiectul"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se creează"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați această conversație"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nealocat"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contactați-vă managerul sau treceți la un alt spațiu de lucru de pe ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluările sunt dezactivate pentru acest spațiu de lucru."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectare"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La revedere"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiu de lucru"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această adresă URL nu poate fi evaluată. Navigați către o conversație."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă mai întâi la Zendesk QA prin intermediul furnizorului dumneavoastră SSO și apoi încercați din nou."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschiderea ferestrei pop-up de autentificare."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă cu contul dumneavoastră Zendesk QA "])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Așteptați..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În curs de autentificare..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți încă un cont?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înregistrare"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți acces la această conversație. Dacă este cazul, contactați-vă managerul."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără acces"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați dacă conexiunea dumneavoastră este activată în Zendesk QA "])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați-vă conexiunile"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare de conexiune"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigați la o conversație în biroul de asistență pentru a continua"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă URL nevalidă"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconectare aproape finalizată."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un moment"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să reîncărcați pagina pentru a reveni la fluxul de lucruri"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o problemă"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliberați-vă de granițele virtuale ale biroului dumneavoastră de asistență și analizați conversațiile de oriunde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treceți la un abonament superior pentru a utiliza extensia"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați-vă abonamentul"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiunea la biroul de asistență nu este legată de acest spațiu de lucru"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biroul dumneavoastră de asistență nu recunoaște acest tichet sau Zendesk QA nu are acces la el"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația nu a putut fi găsită"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate nouă"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați primit feedback"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare reușită"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți utiliza aplicația acum"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați toate categoriile"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizați acest lucru pentru a marca cazuri și a le filtra mai târziu"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă…"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici un feedback pentru această conversație"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citiți feedbackul"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschis în Zendesk QA "])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aici vor apărea toate feedbackurile și notificările. Nimic încă..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați mai multe în Zendesk QA "])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalii conversație"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conține toate"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este tot din"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu conține toate"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este tot din"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai bun decât agentul"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai rău decât agentul"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fals"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este mai mare decât"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conține oricare dintre"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este oricare dintre"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu conține niciunul dintre"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este oricare dintre"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adevărat"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["încălcată"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentate"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentariul meu"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu a comentat"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentariul nu îmi aparține"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critic pentru evaluare"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conține"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimele 30 de zile"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în cel mult"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în ultimele 14 zile"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimele 24 de ore"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimele 30 de zile"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimele 7 zile"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultima lună"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultima săptămână"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luna aceasta"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["săptămâna aceasta"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["până la"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["astăzi"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detectate"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["există"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marcat ca fiind important de mine"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu a fost marcat ca fiind important de mine"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["include"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai mare decât"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["începând de la"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai mic decât"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["până la"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu a fost încălcat"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este complex"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nedetectat"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu există"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu include"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu s-a primit"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nevizualizat"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozitiv"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu a fost evaluat"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu a fost evaluat de mine"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluat"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluat de mine"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primit"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critic pentru evaluare"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conține"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai mare decât"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["începând de la"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai mic decât"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["până la"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu conține"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a fost vizualizată"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentariul meu are răspunsuri"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii personalizate"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element important personalizat"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare condiție"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversație"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birou de asistență"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analize metrice"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participanți"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare și feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați toate condițiile"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente importante"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compară gestionarea conversațiilor de către botul dumneavoastră cu agenții obișnuiți"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botul este blocat într-o buclă, repetând același mesaj în ture consecutive"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale botului în conversații"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un tip de bot implicat în conversație"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un bot implicat în conversație"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conținutul conversației conține fraza ", _interpolate(_list(0)), " în mesajul (mesajele) agenților"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificarea automată a apelurilor cărora le lipsește declarația obligatorie de informare, precum „Acest apel va fi înregistrat” și altele similare."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O persoană sau un bot implicat în conversație"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul sau botul care este evaluat în conversație"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul a solicitat asistență de nivel superior"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentul pozitiv și negativ detectat în conversație"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifică cel mai activ agent în conversații prin analiza IA"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul a încheiat conversația în mod politicos"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul înțelege și recunoaște sentimentele clienților"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează greșelile de gramatică, ortografie și stil ale agentului"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează întreaga conversație pentru mesaje tipice de întâmpinare"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul înțelege întrebarea sau problema clientului"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează cât de ușor poate fi înțeles un text, luând în considerare complexitatea cuvintelor și lungimea propozițiilor"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează întreaga conversație pentru o soluție oferită"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează tonul agentului pe parcursul conversației"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semne de pierdere a clienților. Clientul se gândea la o schimbare sau promitea că va renunța la serviciu."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupul din care face parte conversația."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținutul sau textul mesajului agentului"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale agentului în conversații"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul alocat să evalueze conversația"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata în secunde a conversației telefonice"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținutul sau textul conversației"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canalul de comunicare utilizat pentru conversație (de exemplu, e-mail, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii adăugate la conversație"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care un comentariu a fost adăugat la conversație"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentul pozitiv și negativ detectat în conversație"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care clientul a răspuns la sondajul de satisfacție a clienților"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă evaluarea conversației este disputată"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canalul utilizat de client pentru a iniția conversația"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale clienților în conversații"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultimului mesaj din conversație"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolul sau tipul de utilizator implicat în conversație"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liniștea dintre agent și client a depășit pragul admis"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul a solicitat asistență la un nivel superior"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul și-a exprimat recunoștința extremă sau a fost foarte mulțumit de asistența primită"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fie clientul, fie agentul a solicitat în mod explicit o continuare a comunicării"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textul sau înregistrarea întregii conversații vocale"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă conversația este marcată ca fiind importantă"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-uri utilizate în comentarii pentru a clasifica conținutul"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limba de conversație detectată de IA"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultimului mesaj din conversație"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de note private în conversații"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul mediu de caractere per mesaj în conversații"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri adresate clienților în conversații"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un participant ale cărui informații sunt vizibile public"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul mediu de cuvinte per mesaj în conversații"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii utilizate pentru a evalua conversația"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starea procesului de evaluare a conversației"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un participant sau o persoană implicată în conversație"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail asociat cu conversația"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă recenzia a fost vizualizată"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorul care a evaluat conversația"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care conversația a fost evaluată ultima dată"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul care este evaluat în cadrul conversației"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orice comentarii sau feedback specific furnizat de client cu privire la satisfacția sa în legătură cu conversația."])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctajul de satisfacție a clientului pentru conversație"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctajul de satisfacție a clientului per mesaj în conversație"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișa de evaluare utilizată pentru evaluarea conversației"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Având în vedere că SLA-urile au fost încălcate"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul de sursă sau de conexiune de la care provine conversația"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația a fost neobișnuită sau atipică și au existat mai multe schimburi de replici pentru a ajunge la o rezolvare"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebarea din sondaj referitoare la conținutul conversației"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titlul sau numele sondajului asociat conversației"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul alocat să evalueze conversația"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca sau produsul/serviciul specific asociat cu conversația"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canalul de comunicare utilizat pentru conversație (de exemplu, e-mail, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care conversația a fost închisă sau rezolvată"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care conversația a fost creată inițial"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care conversația a fost creată inițial"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care clientul a răspuns la sondajul de satisfacție a clienților"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O scurtă descriere sau un rezumat al conversației"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un identificator de referință extern legat de conversație"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folderul sau gruparea specifică în care este organizată conversația"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularul sau șablonul utilizat pentru structurarea conversației"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un identificator de referință extern legat de conversație"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căsuța poștală sau contul de e-mail prin care a fost primită conversația"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de telefon asociat conversației"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivelul de prioritate alocat conversației"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul total de răspunsuri din conversație"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadiul sau starea actuală a conversației"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starea actuală a conversației"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiectul sau tema conversației"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale agentului în conversații"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete alocate pentru a clasifica conversația"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul sau categoria conversației"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care conversația a fost actualizată ultima dată"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data la care conversația a fost actualizată ultima dată"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediul sau canalul prin care a avut loc conversația"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținutul unei transcrieri într-o conversație"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații care nu sunt eligibile pentru a fi examinate prin analiza IA"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de agenți participanți la conversații"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă conversația a fost vizualizată"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orice mesaj"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiența comunicării cu botul"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetare bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale botului"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcție apel"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul clientului"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goluri de conversație"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarația privind înregistrarea lipsește"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritizare"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținut transcriere apel"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcate ca fiind importante"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neeligibil pentru evaluare"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluat de"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversație prin"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografie și gramatică"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul agentului"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel mai activ agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale agentului"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durată apel (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținutul conversației"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal mesaj"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetă comentariu"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data comentariului"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de răspuns la sondajul CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispută"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canalul clientului"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale clientului"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultimului răspuns"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip de utilizator"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riscul de dezabonare"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goluri de conversație"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritizare"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicii excepționale"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuarea comunicării"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcriere"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag comentariu"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru de excludere"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înțelegere"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultimului răspuns"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de note private în conversație"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul mediu de caractere"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul mediu de cuvinte"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri publice"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant public"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data evaluată"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare și comentarii"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare evaluare"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizibilitate"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail asociat"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asociat"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de răspunsuri"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare primită"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare revizuită"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare vizualizată"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data evaluată"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu sondaj"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per mesaj"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctajul de satisfacție (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluția oferită"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul de sursă"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiect"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebare din sondaj"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titlul sondajului"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcă conversație"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeniu marcă"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de conversație"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data închiderii"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creării"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creării"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de răspuns la sondajul CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data trimiterii sondajului CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrierea conversației"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator extern"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmp de conversație"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder conversație"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular conversație"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup conversație"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajutor identificator Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator extern"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corespondență conversație"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de telefon"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritatea conversației"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri la conversație"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadiu conversație"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare conversație"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiect de conversație"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de răspunsuri ale agentului"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetă pentru biroul de asistență"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetă pentru biroul de asistență"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul de conversație"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data actualizării"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data actualizării"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținut transcriere"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de agenți participanți"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare vizualizată"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitatea de vulnerabilitate"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vulnerabilitate sănătate"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vulnerabilitate eveniment de viață"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria asigurarea calității automată"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o condiție legată de dată"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un nume filtrului dumneavoastră"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completați un nume de filtru"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană autentificată"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foști membri ai spațiului de lucru"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membrii spațiului de lucru"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adăugare ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicare filtru"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar trebui să aveți cel puțin 1 filtru legat de date"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare opțiuni"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o opțiune"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioada dumneavoastră de probă se termină în ", _interpolate(_named("n")), " zi. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioada dumneavoastră de probă se termină <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>în ", _interpolate(_named("n")), " zi</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioada dumneavoastră de probă se încheie în ", _interpolate(_named("n")), " zile. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioada dumneavoastră de probă se încheie <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>în ", _interpolate(_named("n")), " zile</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioada dumneavoastră de probă se încheie astăzi. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioada dumneavoastră de probă se încheie <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>astăzi</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurați un abonament acum"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " zile"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Au mai rămas ", _interpolate(_named("days")), " zile din perioada dumneavoastră de probă"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ați atins limita de utilizatori pentru abonamentul dumneavoastră. ", _interpolate(_named("link")), " pentru a afla mai multe sau pentru a trece la un abonament superior."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți clic aici"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectați biroul de asistență"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru ne va permite să vă aducem tichetele pentru evaluare."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați biroul de asistență"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilizați Zendesk QA cu date mostră. Pentru a șterge datele mostră și a importa propriile conversații, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date mostră"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiați informațiile utile de mai jos"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți o captură de ecran pentru întreaga pagină pentru a oferi context"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactați echipa noastră de asistență pentru clienți pentru a rezolva această problemă:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o problemă"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referință eroare:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiere informații utile"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori, boți și spații de lucru"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori și spații de lucru"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["astăzi"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luna aceasta"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["săptămâna aceasta"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluate ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluat ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluate ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce este nou?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectare"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzi rapide de pe tastatură"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversați pe chat cu noi"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat-ul nostru nu a putut fi încărcat (poate folosiți un ad-blocker?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salt la..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunitate"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablou de bord"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajutor"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina de pornire"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări date"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bază de cunoștințe"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare cont"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarcini"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aplicația a fost actualizată. ", _interpolate(_named("buttonStart")), "Reîncărcare", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă doriți să începeți să utilizați Zendesk QA ca parte a spațiului dumneavoastră de lucru:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vă anunțam că ați primit deja o invitație pentru a utiliza Zendesk QA ca parte a unui spațiu de lucru. Dacă doriți, puteți continua, desigur, dar rețineți că acest lucru va crea o ", _interpolate(_named("bold")), " pentru dumneavoastră."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizație separată"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Da, lăsați-mă să continui cu ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un cont nou"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " vă cere să vă alăturați ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alăturați-vă"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio conexiune la internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un element fixat nou"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigare rapidă"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focalizați și extindeți editorul de comentarii"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere sau afișare panou lateral"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigați la următoarea conversație"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschidere conversație originală"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigați la conversația anterioară"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordați tuturor categoriilor cea mai mare evaluare posibilă"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordați tuturor categoriilor cea mai mică evaluare posibilă"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare aleatorie conversații (resortare aleatorie)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuneți-ne"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzi rapide de pe tastatură"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutarea comenzilor rapide modale"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ați ajuns la limita de caractere ", _interpolate(_named("charLimit"))])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tipul de imagine ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") nu este acceptat"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dimensiunea imaginii ", _interpolate(_named("name")), " este prea mare (5 MB permis)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcarea editorului de comentarii a eșuat"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați pentru a încărca"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagine"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionare șabloane"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost adăugate șabloane"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare cauză principală"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele, inclusiv motive șterse"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivul evaluării"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țară"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenume"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de angajați"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de telefon"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă vom contacta pentru a vă ajuta să vă configurați contul"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume companie"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de contact al companiei"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum ați auzit prima dată de Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați un canal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați un număr de agenți"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuare"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completați toate câmpurile obligatorii"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să fiți de acord cu citirea și acceptarea termenilor noștri și a Privacy Policy (Politica de confidențialitate)."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal Slack bazat pe asistență"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicitate"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eveniment"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prieten sau coleg"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin birou de asistență sau manager cont"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site de referință"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de căutare (Google, Bing etc.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medii de socializare"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorbiți-ne despre dumneavoastră și despre compania dumneavoastră."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalii cont"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunt gata."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descoperiți, familiarizați-vă cu aplicația, ștergeți și adăugați lucruri."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cont creat. Ce urmează?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți gata să aduceți conversațiile reale?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați propriile date"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începeți explorarea"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați-o cu date mostră"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astfel, putem începe să vă aducem tichetele pentru a le evalua."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați-vă contul biroului de asistență"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M-am răzgândit."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare autentificare"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Element fixat creat. ", _interpolate(_named("buttonStart")), "Mergeți la elementele fixate", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixarea instruirii este disponibilă în cadrul abonamentului Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecere la abonament superior"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedeți toate abonamentele"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Păstrați-vă notițele pentru a vă pregăti pentru următoarea sesiune unu la unu sau de instruire."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente fixate pentru instruire"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentați sau adăugați alte persoane cu ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această conversație a fost ștearsă"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un nou element fixat"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare element fixat"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare element fixat"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum funcționează elementele fixate"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți permisiunea de a vizualiza această conversație."])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixare fișă"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixare conversație"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixare tablou de bord"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element fixat salvat"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați această fișă"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați această conversație"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați acest tablou de bord"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați sub un utilizator"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați un utilizator"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numai dumneavoastră puteți vizualiza ce ați fixat. Pentru a le partaja cu alte persoane ", "@", "menționați-le în comentarii."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " este acum invitat la această conversație"]), _normalize([_interpolate(_named("agentsNames")), " sunt acum invitați la această conversație"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 comentariu"]), _normalize([_interpolate(_named("n")), " comentarii"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ați menționat ", _interpolate(_named("participants")), ", dar nu face parte din această conversație."]), _normalize(["Ați menționat ", _interpolate(_named("participants")), ", dar nu fac parte din această conversație."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " participanți"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participanți"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doriți să eliminați ", _interpolate(_named("agentName")), " de la accesarea acestui element fixat?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu faceți nimic"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu-i invitați la această conversație"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare comentariu "])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați-i"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați-i la această conversație"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare utilizator"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șir"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune nu poate fi anulată."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru va șterge, de asemenea, toate comentariile de sub acest element fixat."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " mesaje noi"]), _normalize([_interpolate(_named("n")), " mesaj nou"]), _normalize([_interpolate(_named("n")), " mesaje noi"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar dumneavoastră puteți vizualiza elementele fixate. Pentru a partaja elemente fixate, ", "@", " cineva în comentarii."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a fixat ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un tablou de bord"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o conversație"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o notă"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dumneavoastră"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecere la abonament superior pentru a obține fixarea instruirii"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixarea instruirii este disponibilă în cadrul abonamentului Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixarea instruirii este disponibilă în cadrul abonamentului Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecere la abonament superior pentru a obține caracteristica"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregătiți-vă pentru întâlnirile unu la unu sau păstrați pentru o dată ulterioară."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru va șterge definitiv elementul fixat și toate comentariile adăugate."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere element fixat"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere element fixat"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume folder"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați lucruri la care să reveniți mai târziu"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspuns"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începând cu cele mai noi"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începând cu cele mai vechi"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Păstrați o tendință sau o conversație interesantă din tabloul de bord pentru a vă pregăti pentru întâlnirile unu la unu sau notați-o pentru altă dată."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un element fixat făcând clic pe butonul de mai sus sau pe pictograma de element fixat din vizualizarea Conversație sau Tablou de bord."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixați"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu sunt create elemente fixate"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Niciun rezultat pentru ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să utilizați cuvinte cheie diferite sau să verificați dacă există greșeli de ortografie."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nou"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un nou element fixat"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaje"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoane"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după utilizator, cuvânt cheie..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În ordine alfabetică"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat recent"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editat recent"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai multe elemente fixate"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Îmi propun să evaluez ", _interpolate(_named("n")), " conversație ", _interpolate(_named("schedule"))]), _normalize(["Îmi propun să evaluez ", _interpolate(_named("n")), " conversații ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv actualizat"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurare obiectiv"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți stabili un obiectiv personal pentru numărul de conversații pe care doriți să le evaluați."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în fiecare zi"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în fiecare lună"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în fiecare săptămână"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv evaluare"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șabloane"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcare canale"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare canal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajați doar feedback-ul sondajului cu comentarii"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajutați-vă colegii prin evidențierea calității excepționale a serviciilor lor și a feedback-ului excelent al clienților."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajați feedbackul pozitiv al sondajului în Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit canale"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajați automat feedbackul cu punctajele de mai sus"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrări bot de laudă"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți o reamintire la începutul săptămânii dacă nu ați făcut nicio evaluare."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reamintiri"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reamintire săptămânală pentru a face evaluări"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți notificări despre evaluări, răspunsuri, menționări și dispute noi în Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menţionări"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri în șir"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrări Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificare cu privire la noile evaluări, răspunsuri, mențiuni și dispute."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute legate de mine"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menționări în comentarii"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noi evaluări primite"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri în șir"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raport cu statistici"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți rapoarte despre evaluările acordate și primite."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapoarte"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zilnic"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lunar"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["săptămânal"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zilnic"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fiecare 3 ore"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fiecare 6 ore"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantaneu"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunar"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprit"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Săptămânal"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratorul contului dumneavoastră a blocat setările de notificare."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare perioadă"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări personale"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (activă)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați o nouă autentificare socială la acest cont:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare unei metode active de autentificarea vă va deconecta. Vă puteți autentifica din nou cu adresa de e-mail alternativă."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare metodă de autentificare"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați adăugat cu succes Google ca opțiune de autentificare"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opțiuni de autentificare"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați adăugat cu succes Slack ca opțiune de autentificare"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare parolă"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodă"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetare parolă"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se va trimite un e-mail la ", _interpolate(_named("email")), " pentru a vă reseta parola."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail principal"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați secțiunea Inbox pentru e-mailul nostru de confirmare"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link magic"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru a schimba acest lucru, modificați-vă ", _interpolate(_named("link")), " în prealabil."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adresa de e-mail ", _interpolate(_named("email")), " va fi asociată cu această parolă."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil actualizat"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagine profil"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru cele mai bune rezultate, încărcați o fotografie pătrată (256x256 pixeli sau mai mult)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail principal"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începutul săptămânii"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări personale"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați cel puțin o opțiune"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținut șablon"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume șablon"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare șablon în"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare conținut șablon"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare nume de șablon"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare șablon"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare șablon"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați șabloane de comentarii pentru a accelera evaluarea, fixarea elementelor și instruirea."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizate în"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere șablon"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți definitiv acest șablon?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare șablon"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați șabloane de comentarii pentru a accelera evaluarea, fixarea elementelor și instruirea."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create șabloane"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șablon de comentariu creat"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șablon de comentariu șters"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șablon de comentariu salvat"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente fixate"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări și dispute"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru a obține acces, luați legătura cu ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistenţă pentru clienţi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost găsit niciun cont"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru moment, utilizați ", _interpolate(_named("link")), " pentru a accesa Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi la Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contul nu a fost migrat"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA bot"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analize metrice"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punctaje"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistici"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablou de bord"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calibrare"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversații"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtre"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluare"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primit"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentariile mele"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disputele mele"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluările mele"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacții"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primit"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Început"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sondaj"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente fixate"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șabloane"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creare parolă"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acreditări"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autentificare"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resetare"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obiectiv evaluare"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["șabloane"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilizator"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare șablon"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șabloane"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cont"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cont"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturare"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["companie"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birou de asistență"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrări"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizație"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plată"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posturi"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setări"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonament"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificări"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiuni"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie nouă"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente importante"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj contradictoriu găsit"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificarea sau eliminarea unuia dintre mesajele contradictorii"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilizați pictograma ", _interpolate(_named("placeholder")), "pentru a exclude datele sensibile din asigurarea calității automată. De exemplu, în loc de „Ai grijă John”, puteți folosi „Ai grijă ", _interpolate(_named("placeholder")), "” pentru a reprezenta orice nume."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max. ", _interpolate(_named("limit")), " rânduri"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicați regula pentru"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați atins limita de 10 categorii personalizate"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ați atins limita de ", _interpolate(_named("limit")), " categorii personalizate"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare condiție"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare rând"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["și"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere condiție"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă mesajul agentului"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În caz contrar, punctajul"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoi punctajul"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare categorie pentru fișă de evaluare"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizați „/” pentru a adăuga o variabilă"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate direcțiile"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul agentului nu poate fi gol"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul nu poate avea mai mult de 300 de caractere"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate sursele"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate canalele"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate etichetele"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate conversațiile"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrângeți domeniul în care se aplică regula dumneavoastră."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații specifice"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare informații despre Elemente importante"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul agentului nu poate fi gol"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați atins limita de 10 informații personalizate"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ați atins limita de ", _interpolate(_named("limit")), " informații personalizate"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completați numele informațiilor"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume informații"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare categorie"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip de perspectivă"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutru"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitiv"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mergeți la setările spațiului de lucru"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amânare"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a începe să utilizați această categorie de evaluare, accesați setările fișei de evaluare a spațiului de lucru și adăugați-o pe o fișă de evaluare la alegere."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare creată"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctați automat conversațiile închise prin identificarea unor cuvinte cheie sau fraze specifice."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu funcționează pentru transcrieri vocale."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am înțeles"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bateți palma! ✋ Ați atins maximul categoriilor de evaluare automatizată la ", _interpolate(_named("limit")), ". Sunteți un vrăjitor al clasificării! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limita categoriei a fost atinsă"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că toate câmpurile sunt completate corect"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denumiți categoria de evaluare"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume categorie"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare nouă"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare nouă"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctați automat conversațiile închise prin identificarea unor cuvinte cheie sau fraze specifice. Nu funcționează pentru transcrieri vocale."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați automat conversațiile închise prin identificarea cuvintelor cheie sau frazelor specifice. Funcționează doar pentru transcrieri."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctați automat conversațiile identificând anumite cuvinte cheie sau expresii atât în mesajele agentului, cât și în cele ale botului."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a utiliza această categorie de evaluare, accesați setările fișei de evaluare a spațiului de lucru și adăugați-o la o fișă de evaluare."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați automat conversațiile pe baza anumitor cuvinte cheie sau expresii din mesaje."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibil pentru"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurare informații"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această perspectivă se afișează în Elemente importante pe baza criteriilor definite."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurare fișă de evaluare"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această categorie poate fi utilizată în fișele de evaluare pe baza unor criterii definite."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["în curând"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personalizat"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predefinite"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fișă de evaluare"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elemente importante"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voce"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare modificări"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificări salvate"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfat:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți clic pe pictograma ", "{··}", " pentru a exclude numele, detaliile companiei sau orice alte informații sensibile în timpul verificărilor de închidere ale asigurării calității automate."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare închidere aprobată"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare închidere aprobată"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere aprobată"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare excepții"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare excepție"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepție"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare mesaj de întâmpinare aprobat"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare mesaj de întâmpinare aprobat"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare aprobat"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipiți aici cuvinte separate prin linii de pauză"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apăsați Enter după fiecare cuvânt pentru o nouă intrare"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limba aplicabilă"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De exemplu, în loc de „Ai grijă John”, puteți folosi „Ai grijă ", "{··}", "” pentru a reprezenta orice nume."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituent conținut dinamic"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepții"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 excepție adăugată"]), _normalize([_interpolate(_named("count")), " excepții adăugate"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Excepție deja adăugată"]), _normalize(["Excepții deja adăugate: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare excepție"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit excepții"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio excepție adăugată"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepție (sensibil la litere majuscule și minuscule)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adăugare excepții"]), _normalize(["Adăugare excepție"]), _normalize(["Adăugare ", _interpolate(_named("count")), " excepții"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați un cuvânt sau o frază"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepție salvată"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limbă"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate limbile"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați limba"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Închidere deja adăugată"]), _normalize(["Închideri deja adăugate: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare închidere"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit închideri aprobate"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există închideri aprobate"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare închidere"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați o închidere"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere adăugată"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere salvată"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formularul are intrări duplicate: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mesaj de întâmpinare deja adăugat"]), _normalize(["Mesaje de întâmpinare deja adăugate: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare mesaj de întâmpinare"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost găsite mesaje de întâmpinare aprobate"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există mesaje de întâmpinare aprobate"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare mesaj de întâmpinare"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați un mesaj de întâmpinare"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare adăugat"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare salvat"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare excepție"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ștergere excepție"]), _normalize(["Ștergere excepții"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ștergeți definitiv această excepție?"]), _normalize(["Ștergeți definitiv aceste excepții?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare excepție"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Una sau mai multe excepții are o lungime de peste ", _interpolate(_list(0)), " caractere"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " caractere"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fost actualizat"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizat de"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabilă"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiți-vă propriile aprobări care să se alinieze cu standardele de marcă și de serviciu. Atunci când este adăugată, asigurarea calității automată va detecta doar închiderile aprobate. Modificările efectuate se aplică conversațiilor viitoare."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați atins limita maximă de 100 de închideri aprobate. Dacă doriți să adăugați mai multe, ar putea fi timpul să evaluați și să rafinați închiderile existente."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limita de închidere aprobată a fost atinsă"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închideri aprobate"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuvinte și fraze pe care doriți ca asigurarea calității automată să le ignore ca fiind greșeli de ortografie sau gramatică. Modificările efectuate se aplică conversațiilor viitoare."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorare listă"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specificați propriile mesaje de întâmpinare, care se aliniază cu marca și standardele serviciului. Atunci când este adăugată, asigurarea calității automată va detecta numai mesajele de întâmpinare aprobate. Modificările efectuate se aplică conversațiilor viitoare."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați atins limita maximă de 100 de mesaje de întâmpinare aprobate. Dacă doriți să adăugați mai multe, ar putea fi momentul să accesați și să vă revizuiți mesajele de întâmpinare existente."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limita de mesaje de întâmpinare aprobată a fost atinsă"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaje de întâmpinare aprobate"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amânare"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere categorie"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți permanent această categorie de evaluare?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare listă de categorii"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria ștersă nu va apărea pentru nicio conversație și filtre noi. Datele istorice din tablourile de bord și conversații rămân intacte."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria va fi eliminată din toate fișele de evaluare la care a fost adăugată."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea categoriei pentru fișa de evaluare a eșuat"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria pentru fișa de evaluare a fost ștearsă"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere categorie pentru fișa de evaluare"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoriile privind asigurarea calității automată"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " pe bază de model IA vor rămâne pe fișa de evaluare, dar nu vor fi punctate automat"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivarea integrării LLM nu dezactivează complet funcția de asigurarea calității automată. Iată ce se va întâmpla:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date istorice"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " va rămâne pe tabloul de bord al asigurării calității automate"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauze principale adăugate manual"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " va rămâne, dar toate cauzele principale bazate pe LLM vor fi eliminate"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivarea asigurării calității automate bazate pe LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprire"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată utilizează IA și modele mari de limbi (LLM) pentru a puncta automat anumite categorii. Datele dumneavoastră sunt prelucrate în baza unui acord strict de prelucrare a datelor (DPA) și nu sunt folosite niciodată pentru a instrui niciun model."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre IA generativă"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asigurarea calității automată utilizează serviciul Microsoft Azure OpenAI pentru ", _interpolate(_named("automatically_scoring_categories")), ". Datele dumneavoastră sunt procesate în conformitate cu un DPA (acord de prelucrare a datelor) strict doar pentru a vă oferi asigurarea calității automată și ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clasificarea automată a unor categorii"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este folosit niciodată pentru a instrui modelele OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată bazată pe LLM este activată"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată bazată pe LLM este dezactivată"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată bazată pe LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificare automată cu IA în asigurarea calității automată"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelucrarea datelor din categoriile asigurările calității automate bazate pe MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consimțământul pentru Microsoft Azure a fost retras"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consimțământul pentru Microsoft Azure a fost acordat. Conversațiile vor primi în curând evaluări automate."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți utiliza în continuare asigurarea calității automată, dar iată ce se va întâmpla"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivarea serviciului MS Azure OpenAI nu va dezactiva asigurarea calității automată ca funcționalitate."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți face acest lucru în vizualizarea categoriilor fișelor de evaluare."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoriile privind asigurarea calității automată pe bază de model vor rămâne pe fișa de evaluare, dar nu vor fi punctate automat."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauzele principale pe care le-ați adăugat manual vor rămâne. Toate cauzele principale generate de model vor fi eliminate."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datele istorice rămân pe tabloul de bord al asigurării calității automate."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivați prelucrarea datelor de către serviciul MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O gamă mai largă de categorii asigurarea calității automată."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență multilingvă gata de utilizare."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare rapidă de noi categorii."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îmbunătățirea acurateței evaluării. GPT-3.5 prezintă o conștientizare contextuală la nivel uman."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiați de cele mai recente progrese ale tehnologiei IA."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iată ce veți primi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activați prelucrarea datelor de către serviciul MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consimțământ acordat"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consimțământul nu a fost acordat"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii noi sunt alocați acestui spațiu de lucru"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiu de lucru implicit"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Durată de 12 de ore (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Durată de 24 de ore (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format oră"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lu"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilul companiei a fost actualizat"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începutul săptămânii"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrare personalizată"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatorul cheii de acces și cheia secretă se găsesc de obicei în Gestionarea accesului > Utilizatori"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator cheie de acces AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de exemplu, AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare modificare cheie de acces secretă"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare valoare cheii de acces secretă"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiunea permite definirea unei singure regiuni, care se găsește de obicei în secțiunea Locație"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regiunea AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de exemplu, eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați o cheie secretă"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheie de acces secretă AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de exemplu, wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți găsi toate informațiile relevante în Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se găsește de obicei în setările instanței Amazon Connect și în pagina Stocare date"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locul de stocare a înregistrărilor apelurilor"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de exemplu, amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o eroare la adăugarea Amazon Connect. Verificați dacă toate câmpurile sunt completate corect și încercați din nou."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locația de stocare trebuie să fie în formatul interval/folder separat de o bară oblică."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru a adăuga o conexiune, contactați ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistența pentru clienți"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate tichetele dumneavoastră Zendesk vor fi sincronizate automat. Puteți alege să excludeți tichetele selectate."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Seul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacific (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (partea centrală)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zurich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irlanda)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Londra)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regiunea serverului"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientul Mijlociu (EAU)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați regiunea serverului"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["America de Sud (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coasta de Est SUA (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coasta de Est SUA 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coasta de Vest SUA (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiune deja adăugată"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începeți conexiunea"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizați numai conversațiile care"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canalul unu, canalul doi"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiune"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Identificatorul unic al acestei conexiuni este ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 lună"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 luni"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 luni"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversațiile inactive vor fi șterse după perioada de păstrare selectată. Evaluările trimise rămân vizibile în vizualizările Activitate și Tablou de bord."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioada de păstrare"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați perioada de păstrare"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rețineți că această acțiune este ireversibilă."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înțeleg că acest lucru va elimina toate datele legate de biroul de asistență pentru această conexiune din Zendesk QA, inclusiv conversațiile, câmpurile și etichetele."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluările trimise nu vor fi afectate și vor rămâne vizibile în vizualizările specifice."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupul unu, grupul doi"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includeți"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dezvoltare activă"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această integrare se află încă în ", _interpolate(_named("activeDevelopment")), ". Este posibil ca unele detalii să lipsească (precum fișierele atașate sau câmpurile personalizate)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corespondența unu, corespondența doi"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eticheta unu, eticheta doi"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simbol API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret simbol API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți „Nume câmp tichet” în Freshdesk, în Setări → Câmpuri tichete"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți „Custom Field Names” în Help Scout, în Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți „Nume de conversație Klass” pe Kustomer în Setări → Klasses → Conversație"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți „Nume câmp personalizat” pe Live Agent în Setări → Câmpuri personalizate"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți „Custom Field Name” pe Wix în Settings → Custom fields"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separați identificatorii de câmp cu virgule. Puteți găsi acești identificatori în Zendesk, în Administrator → Câmpuri tichet."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați câțiva dintre utilizatorii biroului de asistență pentru a vă evalua primele conversații. Nu vă faceți griji, nu îi vom notifica decât dacă îi invitați să devină membri ai spațiului de lucru."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrat cu succes"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare membrii"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acum vom începe să vă extragem conversațiile."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domeniu: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identificator birou de asistență: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiunea dumneavoastră nu a putut fi autentificată"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totul este pregătit. Aceasta este noua dumneavoastră conexiune."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unele conexiuni nu au fost actualizate sau au devenit inactive. Încercați să actualizați aceste conexiuni sau să le eliminați din listă."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator client API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret client API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creați un nou simbol API din Setări → Cont → API & SDK. Adresa URL de redirecționare care trebuie utilizată este ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator cont"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați biroul dumneavoastră de asistență pentru a începe"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă contul dumneavoastră Intercom este configurat pentru a fi deservit din UE sau Australia, atunci actualizați acest lucru."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-mailul pe care îl utilizați pentru a vă autentifica în ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascați numele, adresa de e-mail și numărul de telefon al utilizatorului final. Acreditările bancare sunt deja mascate pentru toate conexiunile la biroul de asistență."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există încă integrări. Adăugați una acum."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aveți nevoie de aceste roluri:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare conexiune"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați o nouă conexiune"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debifarea acestei opțiuni vă permite să adăugați manual această conexiune doar la anumite spații de lucru"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați-vă la toate spațiile de lucru existente"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate spațiile de lucru"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheie API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret cheie API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conexiune ", _interpolate(_list(0)), " ștearsă"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume conexiune"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat(ă) la"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți obține simbolul API mai jos."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prag CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergere conexiune ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastați „ștergere conexiune” pentru a elimina această conexiune"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere conexiune"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați și monitorizați sursele de date conectate."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare conexiune"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclud"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separați numele câmpurilor cu virgule"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizarea a eșuat"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dați un nume unic integrării dumneavoastră"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere opțiuni avansate"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere câmpuri de date sensibile"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu stocați niciodată adresele URL ale fișierelor atașate"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorare fișiere atașate"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu stocați niciodată conținutul conversației (îl puteți extrage ulterior la cerere de la biroul de asistență)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorare conținut conversație"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu stocați niciodată subiectul conversației (îl puteți extrage ulterior la cerere de la biroul de asistență)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorare linie de subiect"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivă"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Subdomeniul dumneavoastră ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regiunea Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corespondență identificator echipă inbox"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator numeric al echipei Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În curs de desfășurare..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corespondențe"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare manuală a conexiunii"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascarea datelor clienților"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există spații de lucru"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume câmp sensibil"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator câmp sensibil"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reînnoire conexiune"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reînnoire conexiune"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați-vă la modul sandbox Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Stabilirea unei conexiuni cu ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare opțiuni avansate"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obțineți doar conversațiile cu un punctaj mai mic sau egal cu pragul stabilit"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicați un prag CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizați numai conversațiile care..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obțineți cele mai recente conversații"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiuni"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare conexiune"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiune actualizată"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume de utilizator"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biroul dumneavoastră de asistență"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru a vă anula abonamentul și șterge contul, contactați ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistența pentru clienți"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere cont"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea unui tichet în Zendesk Support (sau în alt birou de asistență) nu afectează evaluarea conversațiilor asociate. Le puteți vizualiza și gestiona în continuare."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergerea unui tichet în Zendesk QA șterge și evaluările conversațiilor asociate.", _interpolate(_named("line_break")), "Ștergerea unui tichet în Zendesk Support (sau într-un alt birou de asistență) nu afectează evaluările conversațiilor asociate. Le puteți vizualiza și gestiona în continuare."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionare evaluări"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea evaluărilor este permanentă. De asemenea, afectează datele de pe tablourile de bord."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți toate evaluările"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere toate"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate evaluările au fost șterse"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere toate"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atunci când există evaluări asociate cu tichete șterse, le veți vedea aici."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încă nu există evaluări"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați identificatorul tichetului"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Identificator tichet: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații șterse"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spațiile de lucru implicite nu pot fi șterse. Pentru a șterge acest spațiu de lucru, selectați un alt spațiu de lucru implicit în ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări cont"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune nu poate fi anulată."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminarea și ștergerea tuturor datelor"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Înțeleg că acest lucru va ", _interpolate(_named("removeAllData")), " asociate acestui spațiu de lucru, inclusiv utilizatorii și conexiunile spațiului de lucru."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere spațiu de lucru"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastați „ștergere spațiu de lucru” pentru a șterge acest spațiu de lucru"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergere spațiu de lucru ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ștergere spațiu de lucru"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiți sigur de ceea ce faceți sau consultați în prealabil un profesionist."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați dacă persoana evaluată implicit este aleasă pe baza agentului cu cea mai frecventă activitate sau a responsabilului desemnat actual."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel mai activ agent"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoana evaluată implicit"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiteți persoanelor să își evalueze propriile conversații și permiteți agenților să efectueze evaluări"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiul de lucru a fost șters"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiul de lucru a fost actualizat"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabilirea unei clasificări neutre prin ascunderea evaluărilor acordate anterior de alți evaluatori"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificare imparțială"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culoare"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune nu poate fi anulată."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rețineți că acest lucru va elimina și șterge toate datele legate de acest spațiu de lucru, inclusiv utilizatorii și conexiunile."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere spațiu de lucru"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea spațiului de lucru..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluări"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link magic"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO Okta"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link magic"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu puteți dezactiva metoda de autentificare curentă"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodele de autentificare au fost actualizate"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiteți anumite tipuri de metode de autentificare pentru toți utilizatorii."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificări"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONT"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori și spații de lucru"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați și gestionați spații de lucru și invitați sau conectați utilizatori."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rămâneți pe pagină"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Părăsiți pagina"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Părăsiți această pagină și pierdeți toate modificările?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiuni"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificări"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că nu există conversații de importat. Începeți prin a avea câteva conversații cu biroul de asistență, apoi reveniți aici pentru a le analiza."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit conversații"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu puteți șterge această categorie de sistem"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere categorie"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea categoriilor este permanentă. Aceasta va șterge toate datele categoriei din raportare."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a restabili această categorie, va trebui să o creați din nou."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere categorie"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare pentru categorie"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoriile automate completează automat fișa de evaluare cu evaluări sugerate pentru a economisi timp. Categoriile manuale sunt completate de evaluatori de la zero."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima actualizare"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automat"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografie și gramatică"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înțelegere"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizibilitate"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluția oferită"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit categorii"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit cauze principale"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit fișe de evaluare"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați o căutare diferită sau schimbați filtrele"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorii"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorii"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorii"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate categoriile"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorii"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fișe de evaluare"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fișe de evaluare"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fișe de evaluare"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate fișele de evaluare"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fișe de evaluare"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stări"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stări"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stări"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate stările"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stări"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipuri"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipuri"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipuri"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate tipurile"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipuri"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spații de lucru"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spații de lucru"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit spații de lucru"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spații de lucru"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate spațiile de lucru"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spații de lucru"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponderea ", _interpolate(_named("weight")), " contribuie cu ", _interpolate(_named("percentage")), "% la punctajul global"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere fișă de evaluare"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea fișelor de evaluare este permanentă. Aceasta va șterge toate datele din fișa de evaluare din raportare."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a restabili această fișă de evaluare, va trebui să o creați din nou."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere fișă de evaluare"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima actualizare"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activă"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiță"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivă"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauze principale"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scala de evaluare a fost actualizată. ", _interpolate(_named("buttonStart")), "Începeți evaluarea", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA stabilește în mod implicit o scală binară, dar puteți opta și pentru o scară mai granulară:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dacă doriți să vizualizați diferite opțiuni ale scalei de evaluare, configurate în funcție de categorie, accesați ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie/Ton"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cunoașterea produsului"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prin actualizarea scalelor de evaluare:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala dumneavoastră de evaluare anterioară și categoriile corespunzătoare vor fi arhivate (nu șterse)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unele date din tabloul de bord vor fi afectate, deoarece este posibil ca scala veche și cea nouă să nu fie comparabile."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clonarea configurației categoriilor mele de evaluare existente pe noua scală"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să actualizați scalele de evaluare?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți modifica formatul de afișare a fișei de evaluare (numere sau emoticoane) și puteți face categoriile obligatorii sau opționale."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizați emoticoane în loc de numere"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiteți persoanelor să sară peste categorii"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluție"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip de scală de evaluare"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Categoriile pentru un spațiu de lucru au fost clonate"]), _normalize(["Categoriile pentru ", _interpolate(_named("workspacesAmount")), " spații de lucru au fost clonate"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări privind spațiul de lucru"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dacă ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișarea automată a apelurilor în cazul în care agentul nu îi indică clientului să se aștepte la o tăcere în timpul conversației."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația are goluri care durează mai mult decât pragul stabilit."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiți pragul maxim pentru golurile de conversație din apeluri"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goluri de conversație"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informațiile șterse nu vor apărea în conversațiile și filtrele noi. Datele istorice din tablourile de bord și conversații rămân intacte."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea informațiilor din elementele importante a eșuat"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informațiile din elementele importante au fost șterse"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere informații din elementele importante"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați conversațiile care conțin anumite cuvinte sau expresii în transcrieri și mesaje."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificarea automată a apelurilor cărora le lipsește declarația obligatorie de informare, precum „Acest apel va fi înregistrat” și altele similare."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are ca scop identificarea unei declarații de informare privind înregistrarea apelului cu ajutorul unui model de limbaj mare (LLM), care înțelege în mod dinamic contextul, eliminând necesitatea unor fraze prestabilite."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarația privind înregistrarea lipsește"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișarea automată a apelurilor în cazul în care agentul nu îi indică clientului să se aștepte la o tăcere în timpul conversației."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goluri de conversație"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specificați golurile de conversație dintr-un apel"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trebuie să faceți modificări la abonamentul dumneavoastră? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") și vom fi bucuroși să vă ajutăm."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizați automat 100% conversațiile agenților, BPO, canale și limbi. IA detectează problemele, lacunele de cunoștințe și oportunitățile de instruire pentru a îmbunătăți serviciile."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acoperire 100% pentru asigurarea calității automată"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback agent și instruire"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații elemente importante IA"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportare avansată și tablouri de bord"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza și filtrarea sentimentelor"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduceri la cerere"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați alocările și stabilirea obiectivelor"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire și teste"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității Zendesk (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totul pe Professional, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (moștenire)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-uri pentru notificarea imediată a eșecurilor critice"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrarea depozitului de date"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrarea Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezvoltare integrare personalizată"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager dedicat succesului clienților"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acces direct la echipele noastre de produs și de inginerie prin Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ceea ce conține Growth Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IA Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată pentru o acoperire de 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații conversație bazate pe IA pentru a da sens datelor"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îmbogățirea și filtrarea datelor din biroul de asistență îmbunătățite cu ajutorul IA"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente importante: Detectarea automată a interacțiunilor care trebuie evaluate"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza și filtrarea sentimentelor"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapoarte și informații despre performanță bazate pe IA"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducerea conversațiilor la cerere"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitorizare durată evaluare"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiză a ratei de reușită"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ceea ce conține IA Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pachet de implementare Enterprise, inclusiv integrarea Salesforce și Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezvoltare integrare personalizată"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrarea depozitului de date"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-uri pentru notificarea imediată a eșecurilor critice"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager dedicat succesului clienților"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acces direct la echipele noastre de produs și de inginerie"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acces timpuriu și influență asupra parcursului nostru tehnologic"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare automată a evaluării și stabilirea obiectivelor"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruire și analize specifice"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste pentru managementul învățării"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionarea rolurilor și permisiunilor utilizatorului"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascarea datelor sensibile"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opțiuni avansate de filtrare și căutare de conversații"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare personalizabile cu evaluări multiple, ponderi reglabile și opțiuni de eșec automat"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensie de browser pentru a evalua conversațiile de oriunde"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza cauzelor principale"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapoarte de performanță pentru compararea agenților, echipelor, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaj CSAT și analiză feedback client"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caracteristici"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ceea ce conține Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (moștenire)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale de evaluare multiple per conversație"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrare Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații conversație interactive bazate pe IA"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare alocări"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente fixate pentru instruire"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni de instruire"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza și filtrarea sentimentelor"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrare pe baza complexității conversației"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații conversație"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare condiționale"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza cauzelor principale"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiză CSAT cu ajutorul IA avansată"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare clasificare"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensie de browser pentru a evalua conversația oriunde"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcare elemente importante pentru a descoperi conversațiile care trebuie evaluate"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducerea conversațiilor la cerere"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare cronometru"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament personalizat"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (moștenire)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări nelimitate pentru conversații"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări reciproce și auto-evaluări"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare cu ponderi reglabile și opțiuni de eșec automat"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opțiuni de filtrare robuste"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiective de evaluare zilnică, săptămânală sau lunară"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje CSAT și analize de bază"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablouri de bord"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapoarte pentru compararea agenților, echipelor, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionarea rolurilor și privilegiilor utilizatorului"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caracteristici"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caut să fac o schimbare în abonamentul meu Zendesk QA.\n\nEvidențiați sau indicați o opțiune și indicați numărul de posturi pe care doriți să le modificați:\nAș dori să [adaug X posturi] [elimin X posturi] [trec la abonamentul superior Zendesk QA ]\n\nVă rugăm să împărtășiți numele companiei și numele asociat contului dvs. Zendesk QA, precum și orice alte detalii pe care ar trebui să le cunoaștem pentru a procesa această modificare."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificarea abonamentului Zendesk QA "])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doresc să achiziționez un abonament Zendesk QA.\n\nVă rugăm să împărtășiți numele companiei și numele asociat contului Zendesk QA, precum și orice alte detalii pe care ar trebui să le cunoaștem pentru a procesa această solicitare:\n\nDe asemenea, vă rugăm să împărtășiți orice întrebări specifice pe care doriți să le adresați înainte de cumpărare:\n\nVă mulțumesc!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achiziționarea abonamentului Zendesk QA "])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonamentul dumneavoastră se încheie pe ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " rămase)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioada dumneavoastră de probă se încheie pe ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " rămase)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 zi"]), _normalize([_interpolate(_named("n")), " zile"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe data de ", _interpolate(_named("dayOfMonth")), "a fiecărei luni."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe data de ", _interpolate(_named("dayOfMonth")), " a fiecărei luni."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe data de ", _interpolate(_named("dayOfMonth")), " a fiecărei luni."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe data de ", _interpolate(_named("dayOfMonth")), " a fiecărei luni."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe data de ", _interpolate(_named("dayOfMonth")), " a fiecărei luni."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe data de ", _interpolate(_named("dayOfMonth")), " a fiecărei luni."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturat pentru ", _interpolate(_named("card")), " (expiră la ", _interpolate(_named("expires")), ") pe ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["care se termină în"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Expirat"]), _normalize(["Expiră în 1 zi"]), _normalize(["Expiră în ", _interpolate(_named("expiresIn")), " zile"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prețul de pornire include ", _interpolate(_named("n")), " licențe de utilizator"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["începe de la ", _interpolate(_named("sum")), "/lună"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lună"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per utilizator/lună"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe an"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe lună"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trimestru"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminare anulare planificată"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dacă doriți să continuați să utilizați Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonamentul dumneavoastră a fost anulat pe ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonamentul dumneavoastră este planificat să expire pe ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perioadă de probă de ", _interpolate(_named("days")), " zi, fără costuri suplimentare!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilizate"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aveți ", _interpolate(_named("n")), " utilizatori. Numărul de posturi nu poate fi mai mic decât valoarea aceasta."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonamentul va fi reluat pe ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Actualul abonament Zendesk QA (", _interpolate(_named("plan")), ") nu include caracteristici bazate pe IA. Pentru a profita de întreaga putere a caracteristicilor de inteligență vocală, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru a vă anula abonamentul, contactați-ne ", _interpolate(_named("link")), " sau conversați pe chat cu noi."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament de management al calității"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionare calitate"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul a fost actualizat"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un card de credit"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate abonamentele"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturare"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați informațiile de facturare"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval de facturare"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare abonament"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul dumneavoastră va fi anulat la sfârșitul perioadei de facturare."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactați-ne"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactați-ne pentru a edita"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament curent"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețuri personalizate"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenire la versiune inferioară"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual (economisiți 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunar"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plata anuală"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunar"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare abonament"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorați abonamentele"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă de probă gratuită"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturat manual"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți evalua și confirma rezumatul final al comenzii în etapa următoare."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel mai popular"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți o metodă de plată activă."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În perioada de probă"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reluați abonamentul actual pentru a-l modifica"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrerupere abonament"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunar"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrial"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preț"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivare abonament"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reluare abonament"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posturi"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începeți perioada de probă acum"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulată"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu se va reînnoi"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În pauză"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare abonament"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă de probă"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecere la abonament superior"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecere la abonament superior"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizat"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să acordați un minut pentru a oferi un feedback despre experiența dumneavoastră privind asistența pentru clienți. Vă mulțumim anticipat - ", "{", "{", "agentName", "}", "}", " apreciază feedbackul dumneavoastră!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bună ziua ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondajul CSAT al lui", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum ați evalua asistența pe care o primiți de la ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Apreciem foarte mult acest lucru. Vom folosi feedback-ul dumneavoastră pentru a face ", _interpolate(_list(0)), " mai bun pentru toată lumea."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre sondaje"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați tipul de sondaj"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru poate fi modificat ulterior."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Măsurați și îmbunătățiți satisfacția clienților cu ajutorul sondajelor CSAT automate în mai multe limbi."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create sondaje"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare sondaj"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume sondaj"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fost creat un sondaj"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Încercați să schimbați cuvântul cheie sau ", _interpolate(_named("contact_us")), " dacă doriți să îl vedeți listat aici"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anunțați-ne"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici o limbă găsită"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selectați una sau mai multe limbi din lista de mai jos. ", _interpolate(_named("contact_us")), " dacă nu vedeți o limbă pe care doriți să o utilizați în această listă."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare limbi"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anunțați-ne"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare limbă"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ultima publicare ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ultima salvare ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " întrebare"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebare introductivă"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetă scală stânga"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiect e-mail"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motive"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetă scală dreapta"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă mulțumim"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În cazul în care un sondaj are traduceri lipsă, vor fi afișate în schimb textele din limba de bază"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicare traduceri"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducerile lipsesc"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asigurați-vă că evaluați toate traducerile înainte de a le aplica. Efectuați modificări în fișierul descărcat, salvați-l și reîncărcați-l, dacă este necesar. Dacă un simbol ", _interpolate(_named("token_example")), " nu apare ca etichetă, înseamnă că este într-un format incorect sau într-un câmp care nu acceptă simbolurile."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați traducerile"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradus"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezumă conversația și prezintă sugestii pentru comentarii."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență IA Zendesk QA (în engleză)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți edita tipurile după ce au fost create"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest tip există deja. Selectați un alt nume."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvați tipul personalizat ca opțiune selectabilă în meniul derulant Tip de sondaj și grupați toate sondajele create în tabloul de bord."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți cuvântul (cuvintele) și apăsați Enter pentru a salva"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare tipuri personalizate"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele tipului nu poate fi gol"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiți tipul"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul personalizat nu a fost salvat. Apăsați Enter pentru a-l salva."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de e-mail a expeditorului"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele expeditorului"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["În ce măsură sunteți de acord cu afirmația: „", _interpolate(_named("agentName")), " a facilitat gestionarea problemei mele”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cât de ușor a fost să obțineți ajutor de la ", _interpolate(_named("brandName")), " până acum?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În general, cât de ușor a fost să vă rezolvați problema astăzi?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cât de ușor a fost să interacționați cu echipa noastră?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa URL a Privacy Policy (Politica de confidențialitate) (opțional)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă URL termeni de furnizare a serviciului (opțional)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cum ați aprecia calitatea asistenței primite de la ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum a fost serviciul nostru pentru această solicitare?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cât de mulțumit ați fost de reprezentantul de asistență?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cât de mulțumit sunteți de serviciile pe care le-ați primit astăzi de la ", _interpolate(_named("agentName")), "?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebare personalizată"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definiți-vă propria întrebare personalizată ", _interpolate(_named("type")), "..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți întrebarea personalizată"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizați adresele URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setați mesajul de e-mail"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editarea numelui unui tip de întrebare personalizată îl va actualiza în toate sondajele care îl utilizează, fără a afecta datele colectate."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare tipuri personalizate"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiect e-mail"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebare introductivă"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " întrebare"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un nou tip personalizat"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținut"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Simbol de personalizare"]), _normalize(["Simboluri de personalizare"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Glisați și fixați simbolul de personalizare în câmpul de mai jos."]), _normalize(["Glisați și fixați simbolurile de personalizare în câmpurile de mai jos."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversație ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În prezent, este posibil să trimiteți sondaje CSAT doar prin e-mail. Lucrăm pentru a vă oferi mai multe oportunități, precum sondaje CSAT pentru biroul de asistență, sondaje prin SMS și multe altele."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sincronizarea sau reînnoirea conexiunii"]), _normalize(["Sincronizarea sau reînnoirea conexiunilor"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizarea sau reînnoirea conexiunii ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conexiunea nu este sincronizată corect"]), _normalize(["Conexiunile nu sunt sincronizate corect."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debifați caseta de selectare corespunzătoare ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă acum ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați cel puțin o regulă de trimitere a sondajului pentru a activa conexiunea"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această conexiune este în prezent dezactivată. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această conexiune maschează datele sensibile ale clientului. Pentru a activa trimiterea de sondaje,\n", _interpolate(_named("link")), " în setări."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această conexiune necesită permisiuni suplimentare pentru a trimite sondaje.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă acum"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA necesită mai multe permisiuni pentru Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " și ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 oră"]), _normalize([_interpolate(_named("n")), " ore"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minute"]), _normalize(["1 minut"]), _normalize([_interpolate(_named("n")), " minute"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["oră"]), _normalize(["ore"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " pentru a configura regulile de trimitere a sondajelor."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" zi"]), _normalize(["zile"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkurile pentru sondaj expiră"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkurile expiră în"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Împiedicați răspunsurile la sondaje dacă a trecut prea mult timp"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["și"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sondaj ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separați etichetele cu virgule sau apăsați enter după ce le-ați introdus. Asigurați-vă că fiecare dintre ele este listată exact ca în Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabiliți condițiile pe baza cărora se trimite sondajul."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguli de trimitere a sondajului"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguli de trimitere a continuării comunicării pentru sondaj"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Există deja un sondaj ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nRezolvați conflictul modificând regulile fie aici, fie în ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ați configurat deja o conexiune cu reguli care corespund pentru sondajul ", _interpolate(_named("name")), ". Rezolvați conflictul prin modificarea regulilor fie aici, fie în ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați o altă regulă"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare marcă"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare condiție"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați o valoare"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare etichetă"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["care corespund acelorași reguli"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o valoare"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail trimis în"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de continuare a comunicării trimis în"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragment încorporat și continuare a comunicării prin e-mail"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiere cod"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atașați sondajele Zendesk QA la e-mailurile dumneavoastră, adăugând codul de fragmente la configurația Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghid de instalare"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschideți Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previzualizare"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previzualizare fragment"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul fragmentului a fost copiat în clipboard"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copierea fragmentului a eșuat"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragment încorporat"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest sondaj are modificări nesalvate. Salvați-l din nou pentru a vă asigura că codul fragmentului reflectă cele mai recente actualizări."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare conexiune"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au adăugat conexiuni, încă"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți e-mailuri, chat în timp real, apeluri telefonice și multe altele în Zendesk QA cu ajutorul conexiunilor dumneavoastră"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre livrarea sondajelor"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguli de trimitere a sondajului"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguli de trimitere a continuării comunicării pentru sondaj"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare regulă de marcă"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare regulă"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + continuare a comunicării pe e-mail"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați-vă contul Intercom cu Zendesk QA sondaj CSAT"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrare prin"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cine primește sondajul și când"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare reguli"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activat"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondajele din aplicație le permit clienților dumneavoastră să completeze un sondaj fără a părăsi chat-ul."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lansare Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguli de livrare per conexiune"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Există deja un sondaj ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nRezolvați conflictul prin dezactivarea conexiunii sau prin modificarea regulilor fie aici, fie în ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrare"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabiliți condițiile pe baza cărora se trimite sondajul"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creată de ", _interpolate(_named("name")), " la ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eliminare ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminarea acestei limbi înseamnă că nu va mai fi vizibilă pentru niciun respondent și că toate textele traduse vor fi șterse."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare limbă"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune nu poate fi anulată."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativ, puteți dezactiva limba, ascunzând-o din sondaj. Acest lucru vă va permite să păstrați toate traducerile fără a le afișa pentru respondenți."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editată ultima dată de ", _interpolate(_named("name")), " pe ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre limbile sondajului"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Înainte de a adăuga alte limbi, asigurați-vă că ați finalizat modificările efectuate în etapele de Stilizare și Conținut. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare limbă"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmpuri traduse"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibil pentru respondenți"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implicit"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcați fișierul de traducere"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare limbă"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbi"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" descărcați un fișier de traducere"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați traducerile"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traducerea este finalizată. ", _interpolate(_named("review_link")), " sau ", _interpolate(_named("download_link")), " pentru a efectua modificări."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" descărcați un fișier de traducere"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluați ceea ce lipsește"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O parte din conținut nu a fost încă tradus și va fi înlocuit cu limba engleză. ", _interpolate(_named("review_link")), " sau ", _interpolate(_named("download_link")), " pentru a adăuga traducerile lipsă."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare sondaj"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sondajul conține modificări nesalvate. ", _interpolate(_named("save")), " pentru a descărca fișierul de traducere cu cele mai recente modificări."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați fișierul de traducere"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați scala de evaluare preferată"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferă clienților o listă derulantă pentru a selecta un motiv pentru evaluare"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoticoane"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text scală stânga (opțional)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numere"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare motiv"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivul nu poate fi gol"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest motiv există deja"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați lista derulantă pentru motivele punctajului dumneavoastră"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea unui motiv și adăugarea lui din nou cu același nume sau redenumirea unui motiv creează întotdeauna o nouă intrare. Ca urmare, aceasta are un impact asupra datelor afișate pe tabloul de bord."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere motiv"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un motiv șters rămâne în tabloul de bord de raportare, dar nu va fi vizibil pentru noii respondenți la sondaj. Acest lucru este valabil și pentru traducerile asociate."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți un motiv"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ștergeți un motiv „", _interpolate(_list(0)), "”"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text scală dreapta (opțional)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pe o scală de 5 puncte, 1 și 2 sunt considerate punctaje negative; 3 este neutru; 4-5 sunt pozitive."])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare text scală"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scală ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala de evaluare"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA vă importă conversațiile. Acest proces poate dura până la 24 de ore."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta va fi utilizat ca simbol de personalizare pe parcursul sondajului."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume marcă"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butoane și linkuri"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizați aspectul sondajului dumneavoastră"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundal siglă"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunt acceptate formatele JPG, PNG și GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siglă"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilizare"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcare siglă"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încheiați cu stil"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un mesaj pe care oamenii îl vor vedea după ce își trimit feedback-ul"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de mulțumire"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă mulțumim"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de culoare hexazecimal incorect"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de e-mail este incorectă"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp nu poate fi lăsat necompletat"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă URL incorectă"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este vizibil:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibil:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creării"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbi"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje trimise"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje trimise în ultimele 30 de zile"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondajul a fost șters"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondajul a fost duplicat"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fost trimis un e-mail de previzualizare"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondajul a fost publicat"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondajul a fost actualizat"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondajul a fost întrerupt"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pauză"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrerupere sondaj"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previzualizare"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți adresa de e-mail pentru a trimite previzualizarea sondajului"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nume", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați limba pentru previzualizare"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți un e-mail de previzualizare"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți un exemplu de sondaj pe adresa dumneavoastră de e-mail. Evaluările din previzualizarea sondajului nu vor fi stocate."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Previzualizați sondajul ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previzualizare sondaj prin e-mail în web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicare"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reluare"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare ca schiță"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume marcă"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele specific mărcii poate fi adăugat în etapa de stilizare"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume client"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator asistență"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprezentant de asistență"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șters"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiță"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În pauză"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondajul CSAT al lui", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificări nesalvate efectuate"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizarea și reluarea sondajului"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dacă nu vedeți corect acest e-mail ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu am fost mulțumit(ă) de sprijinul pe care l-am primit, pentru că..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susținut de Zendesk QA "])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați motivul"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recapitulare conversație"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplu: Clientul a întâmpinat probleme cu actualizarea metodei de plată. Agentul a partajat un articol, care ar putea fi de ajutor. A funcționat."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susținut de Zendesk QA IA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termenii de furnizare a serviciului"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulțumim pentru feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Care este motivul principal al punctajului dumneavoastră?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuneți-ne de ce ați acordat această evaluare..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efort ridicat"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efort scăzut"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clic aici pentru a deschide sondajul"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidențialitate"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebarea dumneavoastră personalizată apare aici"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ați acordat experienței dumneavoastră ", _interpolate(_list(0)), " un punctaj de"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere feedback"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă mulțumim"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezabonare"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foarte nemulțumit"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foarte mulțumit"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rețineți că starea actuală a sondajului și regulile de livrare nu vor fi incluse în duplicatul sondajului"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondaj duplicat „", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă folosiți fragmentul de e-mail încorporat, asigurați-vă că îl eliminați din Zendesk înainte de a întrerupe sondajul."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu se colectează niciun răspuns atunci când sondajul este întrerupt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrerupere sondaj"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că ați eliminat fragmentul din Zendesk înainte de a întrerupe sondajul."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere sondaj"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă folosiți fragmentul de e-mail încorporat, asigurați-vă că îl eliminați din Zendesk înainte de a șterge sondajul."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune va șterge definitiv sondajul. Utilizatorii care au primit sondajul pot în continuare să îl completeze și toate datele legate de sondaj vor rămâne în Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergere sondaj ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că ați eliminat fragmentul din Zendesk înainte de a șterge sondajul."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificările pe care le-ați aplicat vor fi vizualizate de noii respondenți."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicare sondaj"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că ați copiat cea mai recentă versiune a fragmentului încorporat după publicarea sondajului."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă folosiți fragmentul de e-mail încorporat, asigurați-vă că îl copiați în Zendesk după reluarea sondajului."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuați să trimiteți sondajul și să colectați răspunsurile."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizarea și reluarea sondajului"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că adăugați fragmentul încorporat în Zendesk după reluarea sondajului."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare sondaj"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurați-vă că ați copiat cea mai recentă versiune a fragmentului încorporat după actualizarea sondajului."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți acest sondaj? Această acțiune nu poate fi anulată."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți sondajul?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioada de probă a expirat, dar vă puteți bucura în continuare de Zendesk QA, făcând actualizare la un cont complet."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioada de probă a expirat, dar vă puteți bucura în continuare de Zendesk QA, făcând actualizare la un cont complet. Discutați cu administratorul local pentru a configura acest lucru."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioada dumneavoastră de probă s-a încheiat"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treceți la un abonament superior"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioada de probă gratuită s-a încheiat."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a continua să utilizați versiunea completă a Zendesk QA, introduceți detaliile de plată."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a continua să utilizați versiunea completă a Zendesk QA, administratorul contului dumneavoastră trebuie să introducă detaliile de plată."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionare plată"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un utilizator"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creată pe ", _interpolate(_named("date")), " de către ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Alocare ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că încă mai lucrați la asta. Dacă plecați fără să salvați modificările, pierdeți toate informațiile adăugate până acum."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această alocare se va repeta o dată la două ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Această alocare se va repeta la fiecare ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Toate persoanele evaluate în cadrul unui ciclu"]), _normalize(["Toate persoanele evaluate în cadrul ciclurilor ", _interpolate(_named("nr"))])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversații"]), _normalize([_interpolate(_named("n")), " conversație"]), _normalize([_interpolate(_named("n")), " conversații"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["de ", _interpolate(_named("nr")), " agenți"]), _normalize(["de ", _interpolate(_named("nr")), " agent"]), _normalize(["de ", _interpolate(_named("nr")), " agenți"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["participant"]), _normalize(["participanți"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversații per ciclu"]), _normalize([_interpolate(_named("n")), " conversație per ciclu"]), _normalize([_interpolate(_named("n")), " conversații per ciclu"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " pentru fiecare persoană evaluată dintr-un rând"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["persoană evaluată"]), _normalize(["persoane evaluate"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["evaluator"]), _normalize(["evaluatori"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoane evaluate"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatori"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii spațiului de lucru"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii spațiului de lucru, cu excepția agenților"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclu ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu se repetă"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Alocare ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluare"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre alocări"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare alocare"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune va șterge definitiv alocarea și va elimina datele sale din tabloul de bord. Toate evaluările legate de aceasta vor rămâne în Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergere alocare ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare ștearsă"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare duplicată"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost adăugate alocări încă"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocarea nu a putut fi creată"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denumire alocare"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclul este reîmprospătat și se creează o nouă listă de sarcini. Evaluările deja acordate se iau în considerare pentru atingerea obiectivului."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imediat"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicare"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La începutul următorului ciclu pe ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Când se publică modificările?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificările aduse la această alocare vor fi publicate odată cu aceasta"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setați alocarea ca fiind activă?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurentă în ziua de începere a alocării"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de vineri a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de vineri a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de vineri a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de vineri a fiecare luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de vineri a fiecărei luni"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de luni a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de luni a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de luni a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de luni a fiecărei luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de luni a fiecărei luni"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de sâmbătă a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de sâmbătă a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de sâmbătă a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de sâmbătă a fiecărei luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de sâmbătă a fiecărei luni"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de duminică a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de duminică a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de duminică a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de duminică a fiecărei luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de duminică a fiecărei luni"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de joi a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de joi a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de joi a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de joi a fiecărei luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de joi a fiecărei luni"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de marți a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de marți a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de marți a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de marți a fiecărei luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de marți a fiecărei luni"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima zi de miercuri a fiecărei luni"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A doua zi de miercuri a fiecărei luni"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treia zi de miercuri a fiecărei luni"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A patra zi de miercuri a fiecărei luni"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima zi de miercuri a fiecărei luni"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurență personalizată"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zi"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de 1 a fiecărei luni"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundă"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al treilea"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al patrulea"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se repetă"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În cursul săptămânii"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfârșit"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Început"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun rezultat"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fus orar"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări ciclu"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denumire alocare"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații de bază"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La începutul fiecărui ciclu de alocare, se generează o listă prestabilită de conversații pentru evaluare."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări avansate"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preluați automat conversații noi atunci când obiectivul de evaluare nu este atins"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preluarea automată nu este posibilă pentru obiectivele de evaluare procentuale (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să fie un număr întreg, nu o zecimală"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiteți înlocuirea conversațiilor (rolul de evaluator și altele superioare)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înlocuirea conversațiilor"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiteți evaluatorilor să li se aloce propriile conversații pentru evaluare"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluări"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluări"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul minim de evaluări trebuie să fie de cel puțin 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precizați evaluările minime pentru fiecare persoană evaluată"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversații"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procente (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluări"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluări în total"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiecare evaluator trebuie să efectueze un număr stabilit de evaluări"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorii trebuie să evalueze împreună un număr stabilit de conversații"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul minim de evaluări trebuie să fie de cel puțin 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectivul de evaluare trebuie să fie între 1 și 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv evaluare"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiecărei persoane evaluate trebuie să i se evalueze un număr minim de conversații din totalul conversațiilor sale"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorii pot alege conversații din fondul comun"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorilor li se alocă conversații din fondul comun"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abordarea de alocare"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți boții"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți participanții la spațiul de lucru"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii spațiului de lucru"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare persoane evaluate"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cine va fi evaluat?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați persoanele evaluate"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați pe cine să evaluați"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluderile sunt permise numai atunci când sunt selectați toți utilizatorii spațiului de lucru sau un grup"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatori și persoane evaluate"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Curent: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiul de lucru implicit"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce tip de utilizatori ar trebui să fie selectați în calitate de persoane evaluate?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel mai activ agent"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excludere persoane evaluate (opțional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați utilizatorii care urmează să fie excluși"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excludere evaluatori (opțional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați utilizatorii care urmează să fie excluși"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii spațiului de lucru, cu excepția agenților"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare evaluatori"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cine va efectua evaluările?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați evaluatorii"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluările nu sunt permise în acest spațiu de lucru"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitere auto-evaluări"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluările nu sunt permise în acest spațiu de lucru."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conversație corelată astăzi"]), _normalize(["Conversații corelate astăzi"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arătând ca și când alocarea a început astăzi."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cifrele viitoare pot varia."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost selectate persoane evaluate"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost selectați evaluatori"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Persoană evaluată"]), _normalize(["Persoane evaluate"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Evaluator"]), _normalize(["Evaluatori"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previzualizare"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preluarea automată este permisă dacă nu există suficiente conversații"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de vineri de la ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de luni de la ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de sâmbătă de la ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de duminică de la ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de joi de la ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de marți de la ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " în zilele de miercuri de la ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zilnic de la ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Începe la ", _interpolate(_named("startDateTime")), " și se încheie la ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condiții dată"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condiții de conversație și birou de asistență"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nici o conversație și ", _interpolate(_named("count")), " condiţie pentru biroul de asistență"]), _normalize(["Nici o conversație și ", _interpolate(_named("count")), " condiții pentru biroul de asistență"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversație și nici o condiție pentru biroul de asistență"]), _normalize([_interpolate(_named("count")), " conversații și nu există condiții pentru biroul de asistență"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Abordare: evaluatorii aleg conversațiile"]), _normalize(["Abordare: evaluatorilor li se alocă conversații"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediați erorile evidențiate în rezumat pentru a continua"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv: evaluatorii trebuie să evalueze toate conversațiile"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Obiectiv: evaluatorii trebuie să evalueze o conversație"]), _normalize(["Obiectiv: evaluatorii trebuie să evalueze ", _interpolate(_named("count")), " conversații"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obiectiv: evaluatorii trebuie să evalueze ", _interpolate(_named("count")), "% conversații"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv: nu a fost stabilit niciun obiectiv de evaluare"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv: fiecare persoană evaluată trebuie să aibă toate conversațiile sale evaluate"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obiectiv: fiecare persoană evaluată trebuie să aibă ", _interpolate(_named("count")), " din totalul conversațiilor sale evaluate"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obiectiv: fiecare persoană evaluată trebuie să aibă ", _interpolate(_named("count")), "% din totalul conversațiilor sale evaluate"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Obiectiv: fiecare evaluator trebuie să facă o evaluare"]), _normalize(["Obiectiv: fiecare evaluator trebuie să facă ", _interpolate(_named("count")), " evaluări"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Înlocuire: evaluatorii nu pot înlocui conversațiile"]), _normalize(["Înlocuire: evaluatorii pot înlocui conversațiile"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-evaluări: evaluatorilor li se pot aloca propriile conversații"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au stabilit condiții de dată"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este setată nicio denumire pentru alocare"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezumat"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiții"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " de la ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De două ori pe săptămână"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zilnic"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunar"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciodată"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Săptămânal"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durată ciclu"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setare ca activ"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare ca schiță"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare salvată ca schiță"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare setată ca inactivă"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setați ca inactivă"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare alocare"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare creată"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcați ca activă"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcați ca inactivă"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Actualizările în așteptare ale alocării vor intra în vigoare în următorul ciclu, începând cu ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicare modificări"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiță"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivă"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În așteptare"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare alocare"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare actualizată"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul sau botul a încheiat conversația."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul a încheiat conversația în mod politicos"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă conversația a fost încheiată"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează greșelile gramaticale, ortografice și de stil atât în interacțiunile cu agentul, cât și cu botul."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează greșelile de gramatică, ortografie și stil ale agentului"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează greșelile de gramatică, ortografie și stil ale agentului"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografie și gramatică"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează conversația pentru mesajele de întâmpinare ale agentului și botului."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează întreaga conversație pentru mesaje tipice de întâmpinare"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează întreaga conversație pentru mesaje de întâmpinare tipice"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj de întâmpinare"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul înțelege și recunoaște sentimentele clienților"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifică cazurile în care agentul a reflectat sentimentul clientului sau a folosit cuvinte de grijă precum „conștientizare” și „recunoaștere”."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul înțelege întrebarea sau problema clientului"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează dacă agentul înțelege întrebarea sau problema clientului"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înțelegere"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează cât de ușor poate fi înțeles un text, luând în considerare complexitatea cuvintelor și lungimea propozițiilor"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează cât de ușor poate fi înțeles un text, luând în considerare complexitatea cuvintelor și lungimea propozițiilor"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest mesaj conține o propoziție prea lungă, ceea ce îngreunează lectura"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Acest mesaj conține ", _interpolate(_list(0)), " propoziții prea lungi, ceea ce îngreunează lectura"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizibilitate"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează întreaga conversație pentru o soluție oferită"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează întreaga conversație pentru o soluție oferită"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluția oferită"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează tonul agentului pe parcursul conversației"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizează tonul agentului pe parcursul conversației"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta nicio greșeală din mesajele agentului"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au putut determina greșeli gramaticale"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost detectată niciun mesaj de întâmpinare. Adăugați altele noi, dacă este necesar, prin intermediul administratorului dumneavoastră din setări."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a încheiat conversația politicos"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost detectată nicio frază de închidere. Adăugați altele noi, dacă este necesar, prin intermediul administratorului dumneavoastră din setări"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a reflectat sentimentul clientului sau a folosit cuvinte prietenoase"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta dacă agentul a reflectat sentimentul clientului sau a folosit cuvinte prietenoase"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a folosit propoziții lungi și cuvinte complexe, ceea ce a făcut ca înțelegerea sa să fie dificilă pentru unele persoane"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a folosit cuvinte complexe, ceea ce face ca înțelegerea să fie dificilă pentru unele persoane"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a folosit propoziții lungi, îngreunând înțelegerea mesajului de către unele persoane"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a folosit un limbaj ușor de înțeles"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există suficiente date pentru a evalua această categorie"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul s-a străduit să înțeleagă întrebarea/solicitarea clientului"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a înțeles corect întrebarea/solicitarea clientului"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta dacă agentul a înțeles întrebarea/solicitarea clientului"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonul agentului în timpul conversației"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonul agentului în timpul conversației"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonul agentului în timpul conversației"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonul agentului în timpul conversației"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonul agentului în timpul conversației"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut determina tonul agentului"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația nu a fost încheiată"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația a fost încheiată"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta dacă conversația a fost încheiată"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul nu a avut empatie față de client și față de problemele acestuia"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a dat dovadă de empatie față de client și problemele acestuia"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta dacă agentul a manifestat empatie față de client și problemele sale "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul nu a fost salutat în timpul conversației"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientul a fost salutat în timpul conversației"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta mesajul de întâmpinare tipic"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul nu a oferit o soluție clientului"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentul a oferit o soluție clientului"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a putut detecta dacă agentul a oferit o soluție clientului"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuvinte complexe"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propoziții lungi"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramatică"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scriere greșită"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apologetic"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calm"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vesel"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curios"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesional"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susținător"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 închidere"]), _normalize([_interpolate(_named("n")), " închideri"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată verifică numai închiderile aprobate. În cazul în care această categorie este setată ca fiind critică, dacă nu se utilizează închiderile aprobate, evaluarea va eșua."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închideri aprobate"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închideri aprobate"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 excepție"]), _normalize([_interpolate(_named("n")), " excepții"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuvinte și fraze pe care asigurarea calității automată le ignoră ca fiind greșeli de ortografie sau gramatică."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepții pentru ortografie și gramatică"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepții asigurarea calității automată"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 mesaj de întâmpinare"]), _normalize([_interpolate(_named("n")), " mesaje de întâmpinare"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată verifică doar mesajele de întâmpinare aprobate. Dacă această categorie este setată ca fiind critică și nu se utilizează mesajele de întâmpinare aprobate, evaluarea va eșua."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaje de întâmpinare aprobate"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaje de întâmpinare aprobate"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria asigurarea calității automată"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o categorie de asigurarea calității automată"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauze principale alocate automat:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cont → Setări"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unele categorii utilizează MS Azure, oferind o gamă extinsă de funcționalități și limbi acceptate. Pentru mai multe informații, consultați ", _interpolate(_named("link")), " sau contactați un administrator."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accesați o listă extinsă de categorii și limbi acceptate prin utilizarea MS Azure. Evaluați ", _interpolate(_named("link")), " sau contactați un administrator pentru a face acest lucru."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizați asigurarea calității automată"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Categoriilor de asigurare a calității automate li se alocă automat un punctaj, folosind o scală de evaluare fixă. Puteți ajusta manual evaluarea mai târziu. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o categorie de asigurarea calității automată"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un punctaj alocat automat depinde de gravitatea greșelilor și de numărul acestora. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauze principale"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar categoriile de evaluare relevante sunt afișate evaluatorilor. Categoriile de evaluare sunt afișate pe baza condițiilor pe care le-ați stabilit atunci când creați o categorie."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruire"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selectați tipul de categorie de evaluare pentru spațiul de lucru „", _interpolate(_named("workspace")), "”."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorii trebuie să selecteze manual fișa de evaluare corectă pentru a vedea categoriile de evaluare relevante pentru fiecare conversație."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să schimbați tipul de categorie de evaluare?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare categorii"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați tipul de categorie de evaluare?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de evaluare condițională"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de evaluare statică"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip categorie de evaluare"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări pentru categoria de evaluare"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această acțiune nu poate fi anulată."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ștergeți toate evaluările"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativ, puteți păstra statisticile fără a șterge date, arhivând o categorie."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Înțeleg că acest lucru va fi ", _interpolate(_named("deleteAllRatings")), " acordat pentru această categorie."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De exemplu - Tonul este perfect"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați categoria doar dacă"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Întotdeauna” va fi ignorat. Nu poate fi combinată cu alte condiții."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întotdeauna"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete birou de asistență"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare condiție"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctajul de satisfacție (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipul de sursă"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de conversație"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O categorie are nevoie de un nume"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați categoria la o fișă de evaluare"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare categorie"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare categorie"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivare"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare adăugată"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O categorie arhivată nu este vizibilă pe fișa de evaluare, dar vă permite să păstrați statisticile fără a șterge datele."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivați categoria"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arhivați categoria ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare arhivată"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere categorie"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastați „ștergere categorie” pentru a șterge această categorie"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ștergere categorie"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ștergere categorie ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de evaluare a fost ștearsă"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de evaluare a fost duplicată"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupul de categorii de evaluare a fost adăugat"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupul de categorii de evaluare a fost șters"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbarea ordinii grupurilor de categorii de evaluare"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupul de categorii de evaluare a fost actualizat"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare nearhivată"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de evaluare a fost actualizată"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atunci când un agent nu reușește să treacă o categorie critică, toate categoriile din evaluarea respectivă eșuează automat."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie de evaluare critică"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există categorii în acest grup"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crearea unei noi categorii de evaluare"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare categorie"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți toate categoriile și datele de evaluare din acest grup"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergerea grupului de categorii va lăsa toate categoriile asociate acestuia fără grup."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergeți grupul de categorii ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă alegeți să ștergeți categoriile din cadrul grupului, se vor pierde toate datele de evaluare asociate cu aceste categorii."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume grup"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorii pot selecta mai mult de un motiv ca fiind cauza principală"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatorii pot selecta mai mult de un motiv ca fiind cauza principală"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motive multiple"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De exemplu - Tonul"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un nou grup de categorii"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup nou"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare „Altele”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare cauză principală"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această cauză principală există deja"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare cauză principală"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați cauzele principale pentru a explica evaluarea"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala de evaluare"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivat"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați fișe de evaluare la această categorie de evaluare"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezarhivare"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare categorie"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate evaluările"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișat pentru"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări negative"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări negative și neutre"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da, actualizează"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondere"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoriile care sunt mai importante pentru dumneavoastră pot avea o pondere mai mare în calculul punctajului pentru tichet."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pondere (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre calibrare"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre calibrare"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfășurarea unor sesiuni de calibrare periodice permite evaluatorilor să își mențină tehnicile de evaluare aliniate și să se asigure că agenții primesc același nivel de feedback consistent și imparțial."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare abonamente"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " este un proces care vă ajută echipa să rămână pe aceeași lungime de undă în ceea ce privește modul în care trebuie tratate și evaluate toate interacțiunile cu clienții."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Calibrarea este disponibilă pentru abonamentele ", _interpolate(_list(0)), " și ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluările efectuate anterior nu sunt afișate atunci când se adaugă o conversație la sesiune."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este vizibil"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluările efectuate anterior sunt considerate ca fiind evaluări de calibrare. La sesiune se va adăuga doar o singură evaluare de conversație pentru fiecare evaluator. Nu se afișează evaluările specifice mesajului."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibil"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibilitatea evaluărilor efectuate anterior"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Calibrarea este un proces care ajută echipa dumneavoastră să rămână pe aceeași lungime de undă în ceea ce privește modul în care trebuie tratate și evaluate toate interacțiunile cu clienții. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii au acces nelimitat la toate evaluările acordate în timpul unei sesiuni de calibrare."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați întotdeauna toate evaluările"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact potențial"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["După trimiterea unei evaluări, utilizatorii vor vedea evaluările făcute de alți evaluatori în timpul unei sesiuni de calibrare."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați toate recenziile despre calibrare după ce ați trimis o evaluare"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii au acces nelimitat la toate evaluările acordate în timpul unei sesiuni de calibrare."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați întotdeauna toate evaluările"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["După trimiterea unei evaluări, utilizatorii vor vedea evaluările făcute de alți evaluatori în timpul unei sesiuni de calibrare."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați toate recenziile despre calibrare după ce ați trimis o evaluare"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["După trimiterea unei evaluări, utilizatorii vor vedea evaluările făcute de alți evaluatori în timpul unei sesiuni de calibrare."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați toate recenziile despre calibrare după ce ați trimis o evaluare"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii vor vedea doar propriile evaluări acordate în timpul unei sesiuni de calibrare."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați doar evaluările proprii"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări de calibrare actualizate"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizibilitatea evaluărilor de calibrare"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabiliți pragul pentru analizele metrice din tabloul de bord prin completarea lacunelor în funcție de sistemul intern de analize metrice al echipei dumneavoastră. Evaluați pragul din tabloul de bord."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocări"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiuni"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-uri"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prag"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prag actualizat"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar o clipă..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asigurați-vă că aveți permisiuni de administrator și ", _interpolate(_named("retry")), " sau ", _interpolate(_named("contact")), " pentru ajutor suplimentar."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contactați-ne"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că nu am reușit să obținem acces la API-ul biroului dumneavoastră de asistență."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["încercați din nou"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă conectăm biroul de asistență. Veți fi redirecționat în scurt timp."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorizat"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reveniți la ", _interpolate(_list(0)), " pentru a adăuga o nouă conexiune la biroul de asistență."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că ați ratat un pas sau doi."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Există deja o organizație cu același domeniu de e-mail"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagina de conexiuni"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Între timp..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordați-ne un moment până când vă verificăm permisiunile."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Între timp..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrarea"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creați un spațiu de lucru"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pentru a continua să utilizați Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aproape gata de utilizare"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit niciun spațiu de lucru"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare cont"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a continua să utilizați Zendesk QA, managerul dumneavoastră trebuie să vă aloce un spațiu de lucru."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergerea contului dumneavoastră va șterge definitiv toate datele pe care le avem despre dumneavoastră - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toate evaluările acordate, conținutul conversațiilor, spațiile de lucru etc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare la Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectare"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectarea integrării va dezactiva toate notificările Zendesk QA prin Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectare Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrarea Slack a fost deconectată de la Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiți setările de notificare implicite pentru toți utilizatorii de conturi noi."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprascrieți setările pentru toți utilizatorii"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți pe cale să suprascrieți setările de notificare pentru toți utilizatorii contului"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii nu vor putea să își definească propriile setări de notificare."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setările privind notificările au fost actualizate"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-uri"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertă atunci când o categorie critică eșuează"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertă atunci când CSAT primește un răspuns"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declanșatori"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați spațiul de lucru și datele demonstrative"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recreați datele demonstrative și păstrați spațiul de lucru demonstrativ."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminați spațiul de lucru și datele demonstrative"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reîmprospătare date demonstrative"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reîmprospătare date"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gestionați contul mod demonstrativ „", _interpolate(_named("accountName")), "”"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul demonstrativ"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rămâneți pe pagină"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Părăsiți pagina"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Părăsiți această pagină și pierdeți toate modificările?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați finalizat alocarea"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare alocare"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocare finalizată"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost găsite alocări"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În prezent, nu există sarcini pe care să le evaluați."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustați alocarea sau așteptați să înceapă următorul ciclu."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să reglați filtrele"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare alocare"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiți cu ochii pe această pagină pentru actualizări"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există sarcini pentru acest ciclu"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există conversații de afișat"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcare sarcină ca terminată"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați evaluat acest utilizator."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există auto-evaluări în această alocare"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați făcând clic pe pictograma de calibrare din antetul vizualizării conversației."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au adăugat conversații"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data scadentă"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare alocare"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare sesiune de calibrare"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocările dumneavoastră"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte alocări"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există alocări active"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există alocări"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate persoanele evaluate"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate persoanele evaluate, cu excepția mea"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit persoane evaluate"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare pentru persoană evaluată"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminat"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiță"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminat"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate stările"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De făcut"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți evaluatorii"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți evaluatorii, cu excepția mea"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alocată mie"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit evaluatori"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați un evaluator"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " zile"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " zile"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " zi"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " zile"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " zile"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " zile"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " oră"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minut"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu este disponibil"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocare"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii spațiului de lucru"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator curent"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv nou pentru acest ciclu"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator nou"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare evaluator"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocare conversații"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocare conversație"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversații care trebuie mutate"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclul curent"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare alocare"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit evaluatori"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare evaluator"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocă-mi conversațiile"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocare conversații către alt utilizator"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatori"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " zile"]), _normalize([_interpolate(_named("n")), " zi"]), _normalize([_interpolate(_named("n")), " zile"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ore"]), _normalize([_interpolate(_named("n")), " oră"]), _normalize([_interpolate(_named("n")), " ore"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere cicluri finalizate"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere sesiuni finalizate"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectivul dumneavoastră"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un motiv pentru a elimina această conversație"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicați de ce eliminați această conversație"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare conversație"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doriți să păstrați modificările?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un motiv pentru a înlocui această conversație"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminați modificările"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Păstrare"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Păstrați modificările"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înlocuirea nu a fost găsită"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explicați de ce înlocuiți această conversație"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai doriți să eliminați această conversație din alocare? Acest lucru va reduce obiectivul cu 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înlocuire"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înlocuire conversație"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere schiță"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare schiță"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocă-mi"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocare către alt utilizator"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realocare și evaluare"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare conversație"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lăsați o recenzie"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați mai puțin"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați mai mult"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obiectiv: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " evaluat"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări terminate"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare cicluri finalizate"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare sesiuni finalizate"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp rămas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarcini"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversație înlocuită"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare listă de sarcini"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " mai târziu"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " secundă"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " secunde"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " z"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " z ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "z ", _interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " z"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " zile"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "z ", _interpolate(_named("minutes")), "m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "lună"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " y"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiar acum"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă lipsesc permisiunile. Contactați administratorul dumneavoastră pentru asistență."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina pe care o căutați nu există."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o eroare neașteptată. Contactați serviciul de asistență."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acces refuzat. Efectuarea acestei acțiuni nu este permisă."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina pe care o căutați nu există sau nu aveți acces la aceasta."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evoluați rapid și avem probleme în a ține pasul cu dumneavoastră."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimic"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificări salvate"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificări nesalvate efectuate"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["și"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " aplicate"]), _normalize([_interpolate(_named("n")), " aplicată"]), _normalize([_interpolate(_named("n")), " aplicate"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectat"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat(ă) la"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat de"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critic"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminat"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fost membru"]), _normalize(["un fost membru"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aici"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selectate"]), _normalize([_interpolate(_named("n")), " selectată"]), _normalize([_interpolate(_named("n")), " selectate"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima actualizare"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... și + încă 1"]), _normalize(["... și + încă ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+ încă 1"]), _normalize(["+ încă ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nou"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimic"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu este"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sau"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminat"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " răspunsuri"]), _normalize([_interpolate(_named("n")), " răspuns"]), _normalize([_interpolate(_named("n")), " răspunsuri"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["astăzi"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilizator"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vineri"]), _normalize(["Zile de vineri"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Luni"]), _normalize(["Zile de luni"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sâmbătă"]), _normalize(["Zile de sâmbătă"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Duminică"]), _normalize(["Duminici"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Joi"]), _normalize(["Zile de joi"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marți"]), _normalize(["Zile de marți"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Miercuri"]), _normalize(["Zile de miercuri"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrator"]), _normalize(["un administrator"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manager de cont"]), _normalize(["un manager de cont"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utilizator"]), _normalize(["un utilizator"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității automată"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiat"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați mai mult"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiunea dumneavoastră a expirat."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificări"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp nu poate fi gol."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit elemente."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există persoane care să corespundă."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Proprietar"]), _normalize(["un proprietar"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați mai mult"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați mai mult"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați mai mult."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înainte de a începe, citiți și acceptați condițiile noastre și vom putea începe."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimis"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigur?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări efectuate în general"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fost actualizat"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["un agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contact potențial"]), _normalize(["un contact potențial"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manager"]), _normalize(["un manager"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Evaluator"]), _normalize(["un evaluator"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator anonim"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivare"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabil desemnat"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închidere"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În curând"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuare"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți clic pentru a copia"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiere link"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona de pericol"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuzare"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere (opțional)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare obligatorie"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezactivare"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminați modificările nesalvate?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activare"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportare"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frecvență"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectiv"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am înțeles"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birou de asistență"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbi"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciodată"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înainte"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificări"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altele"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata de reușită"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări personale"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citiți mai puțin"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrimitere"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restabilire"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoană evaluată"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite/vizualizate"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări efectuate de un membru al spațiului de lucru"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare modificări"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala binară"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala de 3 puncte"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala de 4 puncte"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala de 5 puncte"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișă de evaluare"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare frecvență"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare mai puțin"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare mai mult"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omitere"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente importante"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sondaj"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval de timp"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificări nesalvate efectuate"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizare"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondere"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ați fost invitat de ", _interpolate(_list(0)), " să vă alăturați Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilizatorii sunt conectați automat din centrul de administrare. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionare utilizatori"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre gestionarea utilizatorilor"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unii utilizatori sunt puși la dispoziție și sincronizați automat de la furnizorul de identitate. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Permisiunea contului „", _interpolate(_list(0)), "” a fost modificată"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre permisiunile utilizatorilor"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu mă întreba din nou"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toate modificările efectuate pe pagina utilizatorilor se reflectă în spațiile de lucru. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru înseamnă că accesul utilizatorului la anumite părți din Zendesk QA se poate modifica"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificarea permisiunii la nivel de cont"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilizator eliminat"]), _normalize([_interpolate(_named("n")), " utilizatori eliminați"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atunci când eliminați un utilizator, acesta va pierde accesul la contul dumneavoastră. Evaluările, comentariile și clasificările acestora vor rămâne în Zendesk QA. ", _interpolate(_named("linebreak")), "Eliminarea unui utilizator nu va afecta numărul de posturi din abonament."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Acest utilizator va fi eliminat din toate spațiile de lucru pe care le gestionați. ", _interpolate(_named("linebreak")), " Evaluările, comentariile și clasificările acestora vor rămâne intacte."]), _normalize(["Aceștia vor fi eliminați din toate spațiile de lucru pe care le gestionați. ", _interpolate(_named("linebreak")), " Toate evaluările, comentariile și clasificările lor vor rămâne intacte."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Eliminați ", _interpolate(_named("name"))]), _normalize(["Eliminați ", _interpolate(_named("name")), " utilizatori"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poate vizualiza totul și poate gestiona toate setările și facturarea"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poate vizualiza totul și gestiona setările personale și ale spațiului de lucru, cu excepția facturării"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiuni de cont"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiunile specifice spațiului de lucru permit utilizatorului să vadă (și) să editeze datele din spațiile de lucru în care se află."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre QA bot"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boții dumneavoastră apar aici pe măsură ce conversațiile la care au participat sunt sincronizate"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există boți sincronizați"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcarea unui bot ca utilizator îi va acorda acces la autentificare. Datele lor istorice privind boții vor rămâne disponibile pe tablourile de bord."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit astfel de boți"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să căutați din nou folosind un alt cuvânt cheie sau o altă formulare."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați boți"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după numele botului"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcare ca utilizator"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar administratorii și managerii de cont pot marca boții ca utilizatori"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele botului"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimul chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcat manual"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluabil"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botul este eliminat din autoanaliză și din orice alocări. Datele noi nu vor apărea în tablourile de bord, asigurând o raportare precisă și o gestionare mai ușoară a boților."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot generativ"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necunoscut"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot flux de lucru"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare în grup (grupuri)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate grupurile"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun grup"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare din grup (grupuri)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare grup"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiune cont"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați la spațiul (spațiile) de lucru"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume complet"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimul activ"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol la nivel de cont"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiecărui utilizator i se alocă o permisiune la nivel de cont și/sau spațiu de lucru. Permisiunile de administrator sunt acum la nivel de cont, în timp ce celelalte permisiuni sunt la nivel de spațiu de lucru."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare din spațiul (spațiile) de lucru"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sursă"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiune spațiu de lucru"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiuni gestionate în grup"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare detalii"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcare ca bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcarea unui utilizator ca bot îi va elimina accesul la autentificare. Datele lor istorice despre utilizator vor rămâne disponibile pe tablourile de bord."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminați botul din autoanaliză și de la orice alocări. Datele noi nu vor apărea în tablourile de bord, asigurând o raportare precisă și o gestionare mai ușoară a boților."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excludeți botul din evaluări"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator marcat ca bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doar tipul de permisiune Utilizator poate fi marcat ca bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorii cu conturi multiple nu pot fi marcați ca bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după utilizator"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boți"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori, boți și spații de lucru"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPAȚII DE LUCRU"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori și spații de lucru"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există permisiuni"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectat"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugat manual"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În așteptare"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație în așteptare"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiuni necesare"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...și încă una"]), _normalize(["...și încă ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare permisiune în"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Eliminați și reinvitați pentru a modifica rolul ", _interpolate(_named("n")), " utilizator în așteptare."]), _normalize(["Eliminați și reinvitați pentru a modifica rolul celor ", _interpolate(_named("n")), " utilizatori în așteptare"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Editare detalii pentru ", _interpolate(_named("name"))]), _normalize(["Editare detalii pentru ", _interpolate(_named("n")), " utilizatori selectați"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilizator actualizat."]), _normalize([_interpolate(_named("n")), " utilizatori actualizați."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Acest grup conține 1 utilizator dintr-un alt spațiu de lucru."]), _normalize(["Acest grup conține ", _interpolate(_named("n")), " utilizatori din alt spațiu de lucru."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activare"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” a fost arhivat"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup arhivat"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare grup"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” a fost creat"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup fără titlu"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți definitiv acest grup?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere grup"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere grup"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare grup"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact potențial grup"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolul grupului"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” a fost ascuns"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest grup nu va fi vizibil nicăieri altundeva în Zendesk QA "])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați doar permisiunile spațiului de lucru"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupul nu este vizibil în Zendesk QA "])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact potențial"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membru"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup fără titlu"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un alt grup are deja acest nume"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un grup arhivat are deja acest nume"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolul dumneavoastră permite doar gestionarea membrilor acestui grup. Contactați un utilizator cu permisiuni mai mari pentru modificări suplimentare."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare drept contact potențial grup"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” a fost restabilit"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” a fost salvat"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabilire drept contact potențial de grup"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” nu a fost arhivat"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificări nesalvate"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați utilizatorii existenți la acest grup"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați după nume sau adresă de e-mail"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare membrii"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupul „", _interpolate(_named("name")), "” este vizibil"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fiecare membru este ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre grupuri"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unele atribute ale grupurilor sunt furnizate și sincronizate automat de la furnizorul de identitate."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aflați despre grupuri"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare grup"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizați-vă cu ușurință membrii echipei și imitați structura dumneavoastră organizațională."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați cel puțin 1 membru"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un nume de grup"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Există deja un grup cu acest nume"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri grup"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume grup"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizați-vă membrii echipei și imitați-vă structura organizațională."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fără grupuri"]), _normalize(["1 grup"]), _normalize([_interpolate(_named("count")), " grupuri"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În schimb, creați un grup nou sau activați un grup arhivat."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există grupuri active disponibile"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există grupuri arhivate"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit astfel de grupuri"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să căutați din nou folosind un alt cuvânt cheie sau o altă formulare."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost adăugate grupuri"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["în aceste"]), _normalize(["în"]), _normalize(["în"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după numele grupului"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivat"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Retrimitere posibilă în ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " posturi rămase"]), _normalize([_interpolate(_named("n")), " post rămas"]), _normalize([_interpolate(_named("n")), " posturi rămase"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de posturi este limitat în prezent, deoarece abonamentul dumneavoastră este întrerupt. Reactivați-l pentru a adăuga mai mulți utilizatori."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați atins limita posturilor din contul dumneavoastră. Puteți înlocui utilizatorii prin dezactivarea unui utilizator activ sau prin adăugarea de posturi suplimentare."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați detaliile de plată"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selectat"]), _normalize([_interpolate(_named("n")), " selectate"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilizator selectat"]), _normalize([_interpolate(_named("n")), " utilizatori selectați"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " rânduri"]), _normalize([_interpolate(_named("n")), " rând"]), _normalize([_interpolate(_named("n")), " rânduri"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați utilizatori în bloc după numele sau adresele de e-mail ale acestora."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipiți aici datele separate prin virgule sau linii noi"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare în bloc a utilizatorilor"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Editarea permisiunilor spațiului de lucru pentru ", _interpolate(_named("name"))]), _normalize(["Editarea permisiunilor spațiului de lucru pentru ", _interpolate(_named("n")), " membrii selectați"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiuni de editare"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați permisiunea pentru spațiul de lucru"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Eliminați ", _interpolate(_named("name")), " din spațiul de lucru"]), _normalize(["Eliminați ", _interpolate(_named("name")), " membrii din spațiul de lucru"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " membru eliminat"]), _normalize([_interpolate(_named("n")), " membri eliminați"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eliminare membru"]), _normalize(["Eliminare membri"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Acest utilizator va fi eliminat din spațiul de lucru."]), _normalize(["Acești utilizatori vor fi eliminați din spațiul de lucru."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Permisiunea spațiului de lucru „", _interpolate(_list(0)), "” a fost modificată."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare membrii"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați conversațiile și evaluările asociate"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați totul în spațiul de lucru, dar gestionați doar testele, temele, disputele și sesiunile de calibrare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisiuni pentru spațiul de lucru"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați și gestionați totul în cadrul spațiului de lucru"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați totul în spațiul de lucru, cu excepția setărilor spațiului de lucru"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectare utilizatori"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii din spațiile de lucru pe care le gestionați au fost adăugați la acest spațiu de lucru."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereți unui administrator să facă acest lucru."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați direct noii utilizatori sau cereți unui administrator să facă acest lucru."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " sau ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există utilizatori de afișat"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " utilizator adăugat"]), _normalize([_interpolate(_named("n")), " utilizatori adăugați"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adăugare membri"]), _normalize(["Adăugare membru"]), _normalize(["Adăugare ", _interpolate(_named("n")), " membri"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați permisiunea pentru spațiul de lucru"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați utilizatorii pentru spațiul de lucru"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Adăugați membri la ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipiți e-mailurile aici, separate prin linii noi sau virgule"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mailuri corelate"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cu utilizatorii deja conectați:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nu s-au putut corela ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mailuri"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați ortografia sau conectați-le mai târziu în mod individual"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cu utilizatorii biroului de asistență:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilizator conectat"]), _normalize([_interpolate(_named("n")), " utilizatori conectați"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilizator invitat"]), _normalize([_interpolate(_named("n")), " utilizatori invitați"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare în bloc"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație în bloc"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De exemplu:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați-vă de la biroul (birourile) de asistență pentru a le evalua interacțiunile"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conectare utilizator"]), _normalize(["Conectare utilizatori"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailuri"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectare individuală"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă doriți ca utilizatorii conectați să acceseze Zendesk QA, îi puteți invita aici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați utilizatorii conectați"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Invitare utilizator"]), _normalize(["Invitare utilizatori"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omitere invitație"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați utilizatorii care urmează să se conecteze de la biroul (birourile) de asistență pentru a analiza interacțiunile acestora"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați-vă abonamentul"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectați utilizatorii biroului de asistență"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți un nume unic pentru spațiul de lucru"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă este activată, toate conexiunile de cont existente sunt adăugate automat la noul spațiu de lucru."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați toate conexiunile la acest spațiu de lucru"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare spațiu de lucru"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume spațiu de lucru"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare spațiu de lucru"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorilor adăugați direct la cont (fără a le fi alocat un spațiu de lucru) li se alocă un rol la nivel de cont. Îi puteți adăuga ulterior la spațiile de lucru."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorilor invitați li se alocă automat un rol de utilizator la nivel de cont. Puteți să le acordați ulterior un rol de administrator."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate acestea funcționează"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipiți aici e-mailurile separate prin linii noi sau virgule."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilizator invitat prin e-mail"]), _normalize([_interpolate(_named("n")), " utilizatori invitați prin e-mail"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorilor adăugați la un spațiu de lucru li se alocă automat un rol de utilizator la nivel de cont. Puteți să le acordați ulterior un rol de administrator."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare altele"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați utilizatorii doar în cont, fără a-i adăuga la un spațiu de lucru"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorilor invitați li se alocă doar o permisiune pentru cont și nu sunt adăugați la niciun spațiu de lucru."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați utilizatorii direct într-un spațiu de lucru și alocați-le permisiuni pentru spațiul de lucru"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați utilizatori la spațiul de lucru"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație în bloc"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitație individuală"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați doar în cont"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați într-un spațiu de lucru"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Invitați utilizatori"]), _normalize(["Invitați un utilizator"]), _normalize(["Invitați ", _interpolate(_named("n")), " utilizatori"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați în bloc utilizatorii prin e-mail"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ștergere utilizator"]), _normalize(["Ștergere utilizatori"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselectare"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitați utilizatorii prin e-mail"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există spații de lucru"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare permisiune"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți din nou invitația"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator conectat"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator invitat prin e-mail în așteptare"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare spațiu de lucru"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenți IA"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centru de administrare"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigurarea calității"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce Management"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " finalizată"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " finalizată"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " finalizate"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclu ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare alocare"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economisiți timp și configurați alocări automate de evaluări în loc să găsiți și să alocați manual conversațiile pentru evaluare."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentariu"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scadentă ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiunile pot fi adăugate în secțiunea „Calibrare” din meniul lateral al vizualizării conversației."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au adăugat încă sesiuni"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nivel de referință"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există sesiuni"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversațiile pot fi adăugate la o sesiune făcând clic pe pictograma de calibrare din antetul vizualizării conversației."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au adăugat încă conversații"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu au fost acordate evaluări. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți o evaluare de calibrare"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cauza principală"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salt la"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcarea paletei de comenzi a eșuat."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-a găsit nimic."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsit după identificator"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă paleta de comenzi..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagini, filtre, conversații, setări..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversație"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru privat"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru public"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De la ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din API-ul dumneavoastră"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Se afișează de la ", _interpolate(_named("rangeStart")), " la ", _interpolate(_named("rangeEnd")), " din ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Afișare de la ", _interpolate(_named("rangeStart")), " la ", _interpolate(_named("rangeEnd")), " din ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascundere panou lateral"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișare panou lateral"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare bară laterală"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute acceptate"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute respinse"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute deschise"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute acceptate parțial"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări terminate"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări vizualizate"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acceptate"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deschise"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parțial acceptate"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " din total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respinse"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptate"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschise"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parțial acceptate"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respinse"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["evaluare"]), _normalize(["evaluări"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de evaluări ale acestora care au fost vizualizate de către persoanele evaluate"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de evaluări ale acestora care au fost vizualizate"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluatori disputați"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoane care inițiază disputa"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute pe categorii"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute în timp"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschise"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp mediu pentru rezolvare"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp mediu pentru rezolvare (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timp mediu pentru rezolvare (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvat"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total dispute"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectare hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptate"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limba de răspuns"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nici o limbă găsită"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate limbile"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipare prognozate"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit tipare"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate tiparele"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuvinte reprezentate vizual"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit cuvinte"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate cuvintele"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menţionări"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai recente"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele mai vechi"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschise"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parțial acceptate"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a reacționat cu ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respinse"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvate"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați nuanța tenului"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiuni"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit sesiuni"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorii"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit categorii"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarii"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiune comentariu"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit dimensiuni"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate dimensiunile"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit filtre"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuri"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit grupuri"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate grupurile"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag-uri"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit hashtag-uri"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate hashtag-urile"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit valori"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate valorile"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit câmpuri personalizate"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichete birou de asistență"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost găsite etichete pentru biroul de asistență"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate etichetele biroului de asistență"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă rezultatele…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipuri de întrebări"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit tipuri de întrebări"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare reacție"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișe de evaluare"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit fișe de evaluare"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate fișele de evaluare"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaje"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit punctaje"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate punctajele"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surse"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit surse"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate sursele"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motiv feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit motive"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate motivele"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaje"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit sondaje"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate sondajele"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit canale"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate canalele"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatori"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit utilizatori"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toți utilizatorii"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit spații de lucru"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate spațiile de lucru"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi la Teste"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul a fost șters sau nu a fost încă publicat. Verificați de două ori link-ul sau navigați înapoi la lista testului."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest test nu a fost partajat într-un spațiu de lucru din care faceți parte. Cereți unui administrator să vă adauge la un spațiu de lucru la care acest test are acces."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul nu este disponibil"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesul la test acordat spațiului (spațiilor) de lucru din care nu faceți parte. Nu se poate salva și publica testul."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereți unui administrator să salveze și să publice testul."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să marcați cel puțin unul dintre răspunsuri ca fiind cel corect."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest câmp nu poate fi gol."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În prezent, vedeți o previzualizare a testului."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați ce spații de lucru pot accesa testul. Utilizatorii care aparțin mai multor spații de lucru pot răspunde la test o singură dată. Selectarea opțiunii „Toate spațiile de lucru” nu va include spațiile de lucru nou create după publicarea testului."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acces la test"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivare"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieșiți din modul de previzualizare"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportare"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previzualizare"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebări"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completați testul"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicare test"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări pentru test"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare ca schiță"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare test nou"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezarhivare"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opțiuni"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare opțiune"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partajați"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arată bine, nu-i așa?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsul dumneavoastră a fost trimis"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul dumneavoastră a fost salvat"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul dumneavoastră a fost publicat"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul dumneavoastră a fost eliminat"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugare întrebare"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivare test"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un test arhivat și rezultatele acestuia nu vor mai apărea în lista de teste. Puteți accesa în continuare rezultatele unui test arhivat și îl puteți dezarhiva ori de câte ori este nevoie."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivare test"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicare"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odată publicat, un test nu mai poate fi modificat sau editat. Toate testele publicate vor fi accesibile tuturor utilizatorilor din contul dumneavoastră."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicați testul?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți acest test? Această acțiune nu poate fi anulată."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți testul?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odată trimise, răspunsurile dumneavoastră nu mai pot fi modificate."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimiteți testul?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezarhivare test"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezarhivarea testului îl face disponibil în prezentarea generală a testului. Aceasta va permite participanților anteriori să își acceseze rezultatele și celor noi să participe la test."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezarhivare test"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare întrebare"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere (opțional)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutați întrebarea în jos"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutați întrebarea în sus"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întrebare"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În previzualizare sunt afișate numai întrebările cu răspuns."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare întrebare"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimitere test"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test fără titlu"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titlu test"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru va șterge definitiv testul și rezultatele acestuia."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativ, puteți accesa în continuare rezultatele prin arhivarea testului."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cu răspuns"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere test"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru va șterge definitiv testul și rezultatele acestuia."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ștergere test ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicare"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testele ajută la creșterea încrederii și a abilităților echipei, la furnizarea de experiențe coerente și la integrarea noilor membri ai echipei."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți căuta testele după numele testului sau după numele autorului."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun test creat încă"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportare listă de teste"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj mediu"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data finalizării"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test/Autor"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test / Autor / Spații de lucru"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participanți"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicat"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spații de lucru"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după test, autor"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhivat"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiță"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate testele"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul a fost arhivat."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testul a fost dezarhivat."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest test nu a primit niciun răspuns din partea utilizatorilor din spațiul (spațiile) de lucru selectat(e)."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest test nu a primit niciun răspuns înainte de a fi arhivat."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există răspunsuri"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aici veți vedea o defalcare a răspunsurilor la acest test.\nNimeni nu a completat încă testul dumneavoastră - partajarea acestuia ar fi cu siguranță de ajutor."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun răspuns încă"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspunsuri:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj mediu:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Copiere"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copiat"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu s-au găsit rezultate"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Testele lui ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți căuta în clasament după numele participantului."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportare clasament"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctaj"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare după participant"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasament"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comutare clasament"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest lucru înseamnă că nu veți mai putea filtra pentru acest hashtag în tabloul de bord și nu va mai fi completat automat în comentarii. Această acțiune este ireversibilă."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați hashtag-uri"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați hashtag-uri"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați hashtag-uri"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiteți membrilor să creeze noi hashtag-uri"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizat"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ștergere hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crearea de hashtag este blocată"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crearea de hashtag este deblocată"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag „", _interpolate(_list(0)), "” șters"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag-ul „", _interpolate(_list(0)), "” a fost redenumit în „", _interpolate(_list(1)), "”"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag adăugat"]), _normalize([_interpolate(_named("count")), " hashtag-uri adăugate"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denumire nouă"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost create hashtag-uri"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redenumire"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Redenumire hashtag „", _interpolate(_named("tagRenamed")), "”"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separați hashtag-urile cu virgule sau sfârșit de linie"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați un hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu vă faceți griji. Puteți face acest lucru ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chiar aici."])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiune legată de spațiul de lucru"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atașare conexiune"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atașați o conexiune la acest spațiu de lucru"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiune"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactați administratorul dumneavoastră pentru a face orice modificări aici"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactați Zendesk QA pentru a face orice modificare aici"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există conexiuni în cont"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că nu v-ați integrat încă cu o platformă de serviciu pentru clienți."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergerea unei conexiuni va elimina conversațiile din spațiul de lucru ", _interpolate(_named("workspaceName")), " și va șterge toate datele legate de acestea, inclusiv evaluările și disputele."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergere conexiune"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ștergere conexiune ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați o conexiune"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["În perioada de timp aleasă, anumite categorii de asigurare a calității automate activă nu au fost prezente pe fișa dumneavoastră de evaluare. Este posibil ca conversațiile dinaintea ultimei modificări de pe ", _interpolate(_named("date")), " să nu fi fost analizate pentru aceste categorii."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aici vor apărea toate feedbackurile, care vă sunt acordate. Nimic de afișat, încă."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există evaluări primite."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați doar activitatea comentariilor"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aici vor apărea toate feedbackurile pe care le-ați acordat. Începeți în vizualizarea Conversații."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există evaluări date."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări date"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluări primite"])}
  }
}