import qs from 'query-string'

interface Props<Defaults, Params> {
  defaults: Defaults
  params: Params
  storageKey: string
  whitelist?: string[]
  serializer?: (defaults: Defaults, params: Params, whitelist?: string[]) => string
}

export const cleanParams = <Defaults extends Record<string, any>, Params extends Record<string, any>>(
  defaults: Defaults,
  params: Params,
  whitelist: string[] = [],
) => {
  return Object.keys(params)
    .filter((key) => {
      const differsFromDefault = typeof defaults[key] === 'undefined' || params[key] !== defaults[key]
      const inWhitelist = whitelist.length === 0 || whitelist.includes(key)
      return differsFromDefault && inWhitelist
    })
    .reduce((obj, key) => ({ ...obj, [key]: params[key] }), {})
}
export const defaultSerializer = <Defaults extends object, Params extends object>(
  defaults: Defaults,
  params: Params,
  whitelist: string[] = [],
) => {
  const cleanedParams = cleanParams(defaults, params, whitelist)
  return qs.stringify(cleanedParams, { arrayFormat: 'comma' as const, skipNull: true })
}

export const storeParams = <Defaults extends object, Params extends object>({
  defaults,
  params,
  storageKey,
  whitelist,
  serializer,
}: Props<Defaults, Params>): void => {
  const serializerFn = serializer || defaultSerializer

  localStorage[storageKey] = serializerFn(defaults, params, whitelist)
}

const options = { parseNumbers: true, parseBooleans: true, arrayFormat: 'comma' as const }

export const getStoredParams = (storageKey: string) => qs.parse(localStorage[storageKey], options) as any

export const getUrlParams = () => qs.parse(window.location.search, options) as any

export const getPersistentParams = (storageKey: string) => {
  const urlParams = getUrlParams()
  const storedParams = getStoredParams(storageKey)
  return { ...storedParams, ...urlParams }
}
