import { capitalize } from 'lodash-es'
import { CsatStatisticsRequest_BreakdownSource as BreakdownSource } from '@zendesk/zqa-services/survey-dashboard'
import i18n from '@/i18n'
import { translationExists } from '@/i18n/utils'

export const getPredictedLabel = (label: string, emptyFallback?: string) => {
  const dimensionKey = `csat_dashboard.filter_bar.predicted_dimensions.${label}`
  const categoryKey = `csat_dashboard.filter_bar.predicted_tags.${label}`
  if (translationExists(dimensionKey)) return i18n.t(dimensionKey)
  if (translationExists(categoryKey)) return i18n.t(categoryKey)
  return label || emptyFallback || i18n.t('csat_dashboard.filter_bar.result_none')
}

export const prettifySource = (name = '') => capitalize(name.replace('_', ' '))

export const missingValueMap = {
  [BreakdownSource.channel]: 'csat_dashboard.missing_value.channel',
  [BreakdownSource.source]: 'csat_dashboard.missing_value.conversation_source',
  [BreakdownSource.helpdeskTag]: 'csat_dashboard.missing_value.helpdesk_tags',
  [BreakdownSource.csatReason]: 'csat_dashboard.missing_value.csat_reasons',
  [BreakdownSource.predictedTag]: 'csat_dashboard.missing_value.predicted_tags',
  [BreakdownSource.csatLanguage]: 'csat_dashboard.missing_value.csat_language',
}
