import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_line = _resolveComponent("custom-line")!

  return (_ctx.chartData)
    ? (_openBlock(), _createBlock(_component_custom_line, {
        key: 0,
        data: _ctx.chartData,
        options: _ctx.options
      }, null, 8 /* PROPS */, ["data", "options"]))
    : _createCommentVNode("v-if", true)
}