export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τον υποτομέα σας στο Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος στο Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εταιρεία σας έχει ήδη έναν λογαριασμό."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενταχθείτε σε μια εταιρεία"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να ενταχθείτε σε μια υπάρχουσα εταιρεία, ζητήστε από τον διαχειριστή σας να σας στείλει μια πρόσκληση."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζητήστε από τον διαχειριστή σας να σας προσκαλέσει"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλαξα γνώμη."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση εγγραφής"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εταιρεία σας έχει ήδη έναν λογαριασμό. Θέλετε να ενταχθείτε στον λογαριασμό της εταιρείας σας ή να δημιουργήσετε έναν νέο;"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή οδηγιών"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε νέο λογαριασμό"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπάρχει ήδη ένας λογαριασμός"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να αποκτήσετε πρόσβαση στον λογαριασμό σας στο Zendesk QA μέχρι να πληρωθούν τα εκκρεμή τιμολόγια για τη συνδρομή."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνδρομή σας είναι σε αναστολή"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λογαριασμός δεν βρέθηκε. Δοκιμάστε ξανά."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δημιουργήστε νέο κωδικό πρόσβασης."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προσπαθήστε ξανά ή ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάθος συνδυασμός email και κωδικού πρόσβασης."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζητήστε μια νέα από τον διαχειριστή σας."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτός ο σύνδεσμος πρόσκλησης είναι άκυρος ή έχει ήδη χρησιμοποιηθεί."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η μέθοδος εισόδου ", _interpolate(_named("method")), " είναι απενεργοποιημένη στον λογαριασμό σας."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η πρόσκληση δεν είναι έγκυρη ή έχει χρησιμοποιηθεί. Ζητήστε μια νέα από τον διαχειριστή σας."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρτωση των πληροφοριών πρόσκλησης απέτυχε."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εγγραφή απέτυχε. Μπορεί να υπάρχει ήδη χρήστης με αυτό το email."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λογαριασμός σας έχει μπλοκαριστεί μετά από πολλές συνεχόμενες προσπάθειες εισόδου. Ελέγξτε τα εισερχόμενά σας για ένα email με οδηγίες για το πώς να τον ξεμπλοκάρετε."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαληθεύστε πρώτα το email σας"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο λογαριασμό email"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο απαιτείται"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο απαιτείται"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός εισόδου"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή του μαγικού συνδέσμου"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφή"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email εργασίας"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το email εργασίας σας"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Σύμβαση κύριων υπηρεσιών)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός πρόσβασης δεν ταιριάζει με τις απαιτήσεις"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δήλωση απορρήτου"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιμένετε"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Γεια σας, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ενταχθείτε στον οργανισμό ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το email σας επαληθεύτηκε. Μπορείτε τώρα να πραγματοποιήσετε είσοδο με τον νέο σας λογαριασμό παρακάτω."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε με Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη έγκυρος σύνδεσμος πρόσκλησης"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδεθείτε χρησιμοποιώντας μαγικό σύνδεσμο"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στην είσοδο"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε με Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε με Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το email σας επαληθεύτηκε. Μπορείτε τώρα να πραγματοποιήσετε είσοδο με τον νέο σας λογαριασμό παρακάτω:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος με Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος μέσω μαγικού συνδέσμου"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος με Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος στο Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λογαριασμός σας διαγράφηκε"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε λογαριασμό;"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο κωδικό"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο απαιτείται"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο λογαριασμό email"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο απαιτείται"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός εισόδου"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε όλες τις επιλογές εισόδου"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " μέσω παραδοσιακών μεθόδων"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδεθείτε χρησιμοποιώντας μαγικό σύνδεσμο"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε λάβει ένα email που περιέχει τον κωδικό εισόδου σας."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε λάβει ένα email το οποίο μπορείτε να ακολουθήσετε για να πραγματοποιήσετε είσοδο."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα κατά την αποστολή του μαγικού συνδέσμου. Δοκιμάστε ξανά."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος μέσω email"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία κωδικού πρόσβασης"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναν πεζό χαρακτήρα"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναν κεφαλαίο χαρακτήρα"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο νέος κωδικός πρόσβασης πρέπει να περιέχει"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μέγιστο μήκος του κωδικού πρόσβασης είναι 128 χαρακτήρες"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εύκολο να τον θυμάστε, δύσκολο να τον μαντέψει κάποιος"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 χαρακτήρες τουλάχιστον"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε τον κωδικό πρόσβασής σας"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεχάσατε τον κωδικό πρόσβασης;"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας πεζός χαρακτήρας"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας αριθμός"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας κεφαλαίος χαρακτήρας"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να αλλάξετε τον κωδικό πρόσβασής σας, εισαγάγετε τη διεύθυνση email σας και θα σας στείλουμε ένα email με οδηγίες."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο λογαριασμό email"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο απαιτείται"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά κωδικού πρόσβασης"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχει σταλεί email στη διεύθυνση ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν το email δεν εμφανίζεται στα εισερχόμενά σας ή στον φάκελο ανεπιθύμητης αλληλογραφίας, ελέγξτε το email που εισαγάγατε."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή για είσοδο"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τα email σας"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά κωδικού πρόσβασης"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", τότε θα σας σταλεί ένα email επαναφοράς κωδικού πρόσβασης."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν ο λογαριασμός σας έχει ήδη έναν κωδικό πρόσβασης"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εάν αυτό ", _interpolate(_named("bold")), ", τότε σας έχουμε στείλει έναν σύνδεσμο επαναφοράς κωδικού πρόσβασης."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["το email σχετίζεται με έναν λογαριασμό"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θυμηθήκατε τον κωδικό πρόσβασής σας;"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα κατά την αποστολή του συνδέσμου επαναφοράς. Δοκιμάστε ξανά."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφέρετε τον κωδικό πρόσβασής σας"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε χρησιμοποιώντας τον μαγικό σύνδεσμο"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Πολιτική απορρήτου)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Με την εγγραφή σας, αποδέχεστε τους ", _interpolate(_named("terms_of_service")), " και την ", _interpolate(_named("privacy_policy")), " του Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όρους χρήσης"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε λογαριασμό;"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφή μέσω μαγικού συνδέσμου"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε με το email εργασίας σας"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε με το Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε στο Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε με το Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχω ενεργό λογαριασμό Zendesk"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εγγραφείτε ή πραγματοποιήστε είσοδο για να ολοκληρώσετε τη ρύθμιση του ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε πράγματι εσείς; Ελέγξτε τα εισερχόμενά σας για το email επιβεβαίωσης."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε έτοιμοι"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA λειτουργεί καλύτερα σε υπολογιστή. Πραγματοποιήστε είσοδο εκεί για να αρχίσετε να ελέγχετε συζητήσεις."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείστε μια επίδειξη"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλακτικά, κλείστε μια επίδειξη μαζί μας και θα σας δείξουμε τι μπορείτε να κάνετε στο Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζητείται η παρουσία σας"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ακυρώσω"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Άλλαξα γνώμη και θέλω να ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("account")), " σάς προσκαλεί να ενταχθείτε στον λογαριασμό του."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενταχθείτε στον λογαριασμό"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε νέο λογαριασμό"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε να πραγματοποιήσετε είσοδο ξανά"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η αντίδραση που λάβατε για τις παρατηρήσεις σας: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθείσες αντιδράσεις"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ο χρήστης ", _interpolate(_named("agentName")), " αντέδρασε στο σχόλιό σας"]), _normalize(["Πολλά άτομα αντέδρασαν στο σχόλιό σας"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ο χρήστης ", _interpolate(_named("agentName")), " αντέδρασε στην απάντησή σας"]), _normalize(["Πολλά άτομα αντέδρασαν στην απάντησή σας"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ο χρήστης ", _interpolate(_named("agentName")), " αντέδρασε στον έλεγχό σας"]), _normalize(["Πολλά άτομα αντέδρασαν στον έλεγχό σας"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σχόλιο"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["απάντηση"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έλεγχος"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΔΙΕΝΕΞΕΙΣ"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΔΟΘΗΚΑΝ"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΛΗΦΘΗΚΑΝ"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις έρευνας"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιδράσεις"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεκίνησαν"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Επισήμανση σχολίου ως αναγνωσμένου;"]), _normalize(["Επισήμανση όλων των σχολίων ως αναγνωσμένων;"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Επισήμανση διένεξης ως αναγνωσμένης;"]), _normalize(["Επισήμανση όλων των διενέξεων ως αναγνωσμένων;"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Επισήμανση παρατηρήσεων έρευνας ως αναγνωσμένων;"]), _normalize(["Επισήμανση όλων των παρατηρήσεων έρευνας ως αναγνωσμένων;"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Επισήμανση ελέγχου ως αναγνωσμένου;"]), _normalize(["Επισήμανση όλων των ελέγχων ως αναγνωσμένων;"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Το σχόλιο επισημάνθηκε ως αναγνωσμένο"]), _normalize(["Όλα τα σχόλια επισημάνθηκαν ως αναγνωσμένα"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Η διένεξη επισημάνθηκε ως αναγνωσμένη"]), _normalize(["Όλες οι διενέξεις επισημάνθηκαν ως αναγνωσμένες"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Τα σχόλια έρευνας επισημάνθηκαν ως αναγνωσμένα"]), _normalize(["Όλα τα σχόλια έρευνας επισημάνθηκαν ως αναγνωσμένα"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ο έλεγχος επισημάνθηκε ως αναγνωσμένος"]), _normalize(["Όλοι οι έλεγχοι επισημάνθηκαν ως αναγνωσμένοι"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση όλων ως αναγνωσμένων"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως αναγνωσμένου"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έλεγχοι"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν κάποιος υποβάλλει διένεξη για έναν έλεγχο, εμφανίζεται εδώ."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διενέξεις"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν σας ανατίθεται η επίλυση μιας διένεξης, εμφανίζεται εδώ."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ληφθεί διενέξεις"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν υποβάλλετε διένεξη για έναν έλεγχο, εμφανίζεται εδώ."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ξεκινήσει διενέξεις"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι παρατηρήσεις που δίνονται σε εσάς εμφανίζονται εδώ. Δεν υπάρχει τίποτα για εμφάνιση ακόμη."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ληφθεί παρατηρήσεις"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι αντιδράσεις που δίνονται στους ελέγχους και τις απαντήσεις σας εμφανίζονται εδώ. Δεν υπάρχει τίποτα για εμφάνιση ακόμη."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ληφθεί αντιδράσεις"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοίξτε μια συζήτηση"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι παρατηρήσεις που δίνονται από εσάς καταλήγουν εδώ. Ξεκινήστε υποβάλλοντας έναν έλεγχο στην προβολή \"Συζητήσεις\"."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δοθεί έλεγχοι"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι παρατηρήσεις που δίνονται σε εσάς εμφανίζονται εδώ. Δεν υπάρχει τίποτα για εμφάνιση ακόμη."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ληφθεί έλεγχοι"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν σχόλια"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι παρατηρήσεις που υποβάλλετε ως σχόλια εμφανίζονται εδώ."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δοθεί σχόλια"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν σχόλια"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα σχόλια κάτω από έναν έλεγχο του οποίου είστε κάτοχος, οι επισημάνσεις με ", "@", " ή οι απαντήσεις στα σχόλιά σας εμφανίζονται εδώ."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ληφθεί σχόλια"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν αποδεκτές διενέξεις"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ανοιχτές διενέξεις"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν απορριφθεί διενέξεις"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν επιλυθεί διενέξεις"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε ξανά ορίζοντας μια άλλη επιλογή από το παραπάνω φίλτρο."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε ξανά επιλέγοντας έναν άλλο χρήστη από το παραπάνω φίλτρο."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοθέντα σχόλια"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντα σχόλια"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι διενέξεις"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθείσες διενέξεις"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις που ξεκίνησαν"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγχόμενος"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθείσες παρατηρήσεις έρευνας"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοθέντες έλεγχοι"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντες έλεγχοι"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριότητα"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή λίστας δραστηριοτήτων"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ελέγχθηκαν"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ελέγχθηκε"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ελέγχθηκαν"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανεκκίνηση σε ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " απομένουν"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίστε μια ανάθεση"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή λίστας αναθέσεων"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δεν είναι δυνατή η δημιουργία συνεδρίας καθοδήγησης μεταξύ των ", _interpolate(_list(0)), " και ", _interpolate(_list(1)), ", καθώς δεν βρίσκονται στον ίδιο χώρο εργασίας αυτήν τη στιγμή."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Υπάρχουν ήδη συνεδρίες καθοδήγησης μεταξύ των ", _interpolate(_list(0)), " και ", _interpolate(_list(1)), ". Επικοινωνήστε με τον χρήστη ", _interpolate(_list(0)), " για να αποκτήσετε πρόσβαση στη συνεδρία."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτείνεται"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δημιουργήθηκε μια νέα συνεδρία μεταξύ των χρηστών ", _interpolate(_list(0)), " και ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαιδευτής"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε εκπαιδευτή"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαιδευόμενος"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε εκπαιδευόμενο"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρία"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία συνεδρίας"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια συνεδρία"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη συνεδρίας"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα συνεδρίας"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτείνονται"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπρόθεσμες"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παλαιότερες"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επερχόμενες"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία συνεδρίας"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν συνεδρίες"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρίες"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος χρόνος ελέγχου"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας ελέγχου"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν δεδομένα για εμφάνιση για την επιλεγμένη περίοδο"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία αξιολόγησης"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προστέθηκε καρφίτσα στη συνεδρία. ", _interpolate(_named("buttonStart")), "Εμφάνιση", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σε..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προστέθηκαν"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε φτάσει στο τέλος των καρφιτσών από την επιλεγμένη περίοδο"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δείτε πώς λειτουργούν οι καρφίτσες σε αυτό το ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["άρθρο"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Φαίνεται ότι δεν έχετε καρφιτσώσει κάτι κάτω από τον χρήστη ", _interpolate(_list(0)), ". Μπορείτε να χρησιμοποιείτε καρφίτσες για να προετοιμαστείτε για συνεδρίες 1:1 ή για να αποθηκεύσετε κάτι για κάποια άλλη στιγμή."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ο εκπαιδευόμενος"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί καρφίτσες ακόμη"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εδώ είναι όλες οι καρφίτσες που έχετε δημιουργήσει κάτω από τον χρήστη ", _interpolate(_named("name")), ". Οι καρφίτσες που προστίθενται σε συνεδρίες θα ", _interpolate(_named("bold")), " στον άλλο συμμετέχοντα."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κοινοποιούνται αμέσως"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Φαίνεται ότι ο χρήστης ", _interpolate(_list(0)), " δεν έχει λάβει παρατηρήσεις για έλεγχο συζητήσεων ή CSAT κατά τη διάρκεια της επιλεγμένης περιόδου."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διαθέσιμες παρατηρήσεις"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε φτάσει στο τέλος των σχολίων από την επιλεγμένη περίοδο"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετρικές"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσες"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Οι αριθμοί του χρήστη ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέο στοιχείο ενέργειας"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ολοκληρώθηκε από ", _interpolate(_named("user")), " στις ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["από ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εσάς"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε για να προσθέσετε ένα νέο στοιχείο ενεργειών"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι βγήκε από αυτήν τη συνάντηση; Ποια είναι τα επόμενα βήματά σας;"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία ενεργειών"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δημιουργήθηκε από ", _interpolate(_named("name")), " στις ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαγραφεί η συνεδρία"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Με αυτήν την ενέργεια θα ", _interpolate(_named("deleteSession")), " οριστικά. Οι συμμετέχοντες χάνουν την πρόσβαση στη συνεδρία, συμπεριλαμβανομένων των περιεχομένων και των προσωπικών σημειώσεων."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Συνεδρία που δημιουργήθηκε στις ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τελευταία επεξεργασία από τον χρήστη ", _interpolate(_named("name")), " στις ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέματα συζήτησης και σημειώσεις"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σημείωση ενημερώθηκε"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποχώρηση από τη συνεδρία"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποίηση"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι επιλεγμένοι χρήστες αποκτούν πρόσβαση στις λεπτομέρειες της συνεδρίας από αυτό το σημείο και μετά."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χρήστες"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποιημένες"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποίηση συνεδρίας"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δεν θα είστε πλέον μέρος της συνεδρίας των ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_list(0)), " δεν θα είναι πλέον μέρος αυτής της συνεδρίας."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ο χρήστης ", _interpolate(_named("name")), " έχει πρόσβαση σε αυτήν τη συνεδρία τώρα."]), _normalize([_interpolate(_named("n")), " νέοι χρήστες έχουν πρόσβαση σε αυτήν τη συνεδρία τώρα"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συνεδρίας"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων των συνεδριών"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συνεδρίας"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ο εκπαιδευτής"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Οι σημειώσεις που προστίθενται από τον χρήστη ", _interpolate(_list(0)), " θα εμφανίζονται εδώ."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε καρφιτσωμένα στοιχεία ως θέματα συζήτησης από το δεξί πλαίσιο"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσες"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημειώσεις που μόνο εσείς μπορείτε να δείτε..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικές σημειώσεις"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ιδιωτική σημείωση ενημερώθηκε"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ημέρες"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώθηκε"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαιδευτής"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαιδευόμενος"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία ενεργειών"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία συνεδρία"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώθηκε"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εξέλιξη"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπρόθεσμη"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση συνεδριών"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή όλων"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγώ: Εκπαιδευτής"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγώ: Εκπαιδευόμενος"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι συνεδρίες"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποιημένες συνεδρίες"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε να αλλάξετε ένα φίλτρο για να δείτε τα στοιχεία ενεργειών."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανένα στοιχείο ενεργειών"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία ενεργειών"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που ελήφθησαν και προβλήθηκαν"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντες · Προβληθέντες"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόοδος μετά τις συνεδρίες"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρίες"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ότι οι εκπρόσωποι δεν έχουν λάβει παρατηρήσεις."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι έχετε το σωστό όνομα του χρήστη"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε αυτός ο χρήστης"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διαθέσιμα δεδομένα"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS τελευταίων 30 ημερών"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Με αυτήν την ενέργεια θα <b>διαγραφούν όλες οι συνεδρίες</b> (2) με <b>", _interpolate(_named("name")), "</b> οριστικά. Οι συμμετέχοντες χάνουν την πρόσβαση σε όλες τις συνεδρίες, συμπεριλαμβανομένων των περιεχομένων και των προσωπικών σημειώσεων."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε φτάσει στο τέλος όλων των συνεδριών"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια συνεδρία καθοδήγησης είναι μια ομαδική δραστηριότητα μεταξύ ενός μέντορα και ενός εκπαιδευόμενου για να συζητήσουν για την απόδοση, τις ευκαιρίες, τους βραχυπρόθεσμους και μακροπρόθεσμους στόχους."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να αναζητάτε συνεδρίες με βάση τόσο το όνομα του εκπαιδευτή όσο και το άτομο που εκπαιδεύεται."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν συνεδρίες"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί συνεδρίες ακόμη"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή ιστορικού καθοδήγησης"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή ιστορικού καθοδήγησης "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία συνεδρία"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρία"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρίες"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι συνεδρίες"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαιδευτής"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπαιδευόμενος"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώθηκε"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως ολοκληρωμένης"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως μη ολοκληρωμένης"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση το όνομα"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετονομασία"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθοδήγηση"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή λίστας συνεδριών"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη προγραμματισμένη"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απάντηση σε αυτό το μήνυμα"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθοδήγηση"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνεδρία ευθυγράμμισης έχει λήξει. Δεν μπορούν να υποβληθούν πρόσθετοι έλεγχοι."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε υποβάλει ήδη έναν έλεγχο για αυτήν τη συζήτηση."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο έλεγχος αυτής της συζήτησης δεν είναι πλέον δυνατός, καθώς η προθεσμία της συνεδρίας έχει περάσει."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συζήτηση δεν έχει ελέγχους"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείσιμο πλαισίου"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε έναν έλεγχο ευθυγράμμισης"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΛΕΠΤΟΜΕΡΕΙΕΣ"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΥΝΟΨΗ"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΝΤΙΓΡΑΦΟ"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ομιλητής ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπρόσωπος"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πελάτης"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " δ"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["παύση"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία ομιλητή"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος μηνύματος"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στην αναπαραγωγή μηνύματος"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομιλητές"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντίγραφο"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες χώρου εργασίας"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε πρώτα σύνδεση για να δημιουργήσετε φίλτρα"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ρομπότ"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δημιουργήθηκε στις ", _interpolate(_named("time")), " από τον χρήστη ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τελευταία ενημέρωση στις ", _interpolate(_named("time")), " από τον χρήστη ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Φαίνεται ότι δεν έχετε φίλτρα. Για να αποκτήσετε πρόσβαση σε αυτήν τη σελίδα, πρέπει πρώτα να δημιουργήσετε ένα φίλτρο. Ξεκινήστε με το φίλτρο ", _interpolate(_named("recommendedFilter")), " για να δείτε τις δικές σας συζητήσεις. Δημιουργήστε αυτό το φίλτρο παρακάτω για να συνεχίσετε."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τις τελευταίες 30 ημέρες"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνθήκη \"Δημιουργήθηκε τις τελευταίες 30 ημέρες\" θα προστεθεί στα φίλτρα που δημιουργήθηκαν χωρίς εύρος ημερομηνιών"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το φίλτρο δεν είναι διαθέσιμο σε αυτό το πρόγραμμα και δεν θα ληφθεί υπόψη κατά την εμφάνιση των αποτελεσμάτων"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το χρονικό όριο για τη λήψη των επιλογών φίλτρου έληξε. Δοκιμάστε ξανά."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για το φιλτράρισμα"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος = άτομο που έχει πραγματοποιήσει είσοδο"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Συζητήσεις που βρέθηκαν: ", _interpolate(_named("count"))]), _normalize(["Συζητήσεις που βρέθηκαν: ", _interpolate(_named("count"))]), _normalize(["Συζητήσεις που βρέθηκαν: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν διατίθεται στο τρέχον πρόγραμμά σας. Κάντε αναβάθμιση για να χρησιμοποιήσετε αυτήν τη δυνατότητα."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη συνθήκης"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Από το Zendesk QA AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνθήκη"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετατροπή σε ανάθεση"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία φίλτρου"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία φίλτρου"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένα πεδία"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνθήκες"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορτώνουμε τις επιλογές φίλτρου σύμφωνα με τη συγκεκριμένη ρύθμισή σας. Μπορεί να διαρκέσει μερικά λεπτά κατά την αρχική φόρτωση."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε ένα περιγραφικό όνομα στο φίλτρο σας"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα φίλτρου"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε μια επιλογή"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνθήκη"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιωτικό"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημόσιο"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία φίλτρου"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση αλλαγών"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διαθέσιμες τιμές"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνεται φόρτωση..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια τιμή"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατότητα"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο ενεργές συνεδρίες ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση παλαιότερων συνεδριών ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προθεσμία"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία συνεδρίας ευθυγράμμισης"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία συνεδρίας"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία συνεδρίας ευθυγράμμισης"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι έλεγχοι ευθυγράμμισης που δόθηκαν κατά τη διάρκεια της συνεδρίας θα διαγραφούν οριστικά."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συνεδρίας"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή συνεδρίας ευθυγράμμισης ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί συνεδρίες"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ενεργές συνεδρίες"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί συνεδρίες"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ενεργές συνεδρίες"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη φίλτρου"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε ένα ιδιωτικό φίλτρο"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε ένα δημόσιο φίλτρο"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το φίλτρο προστέθηκε"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το φίλτρο θα διαγραφεί οριστικά."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή φίλτρου"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή φίλτρου ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι διενέξεις"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις για εμένα"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις από εμένα"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές διενέξεις"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρα"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί φίλτρα"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί ιδιωτικά φίλτρα"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί δημόσια φίλτρα"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρα"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιωτικά φίλτρα"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημόσια φίλτρα"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Θέλετε να ", _interpolate(_named("cta")), ";"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["το αποθηκεύσετε"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή είναι μια προσωρινή προβολή του φίλτρου."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το φίλτρο σας κατασκευάζεται..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το φίλτρο ενημερώθηκε"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη κατηγορία Αυτόματου QA"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τελευταία ενημέρωση ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολύπλοκες λέξεις"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτόματοι έλεγχοι ενημερώνονται δυναμικά με βάση τις αλλαγές των δελτίων"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση στη συζήτηση"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεγάλες προτάσεις"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν προβλέφθηκε αυτόματη βαθμολογία"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατός ο εντοπισμός λαθών"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση πολύπλοκων λέξεων"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση μεγάλων προτάσεων"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενη συζήτηση"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο Αυτόματος QA δεν μπόρεσε να αξιολογήσει αυτήν την κατηγορία λόγω ανεπαρκούς πλαισίου. Συνιστάται μη αυτόματος έλεγχος."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημαντικά"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανακαλύψτε αυτοματοποιημένες πληροφορίες συζήτησης για να βελτιώσετε τη διαδικασία ελέγχου σας. Επισημάνετε κρίσιμες συζητήσεις και αναδείξτε θετικά παραδείγματα που ξεχωρίζουν για την ομάδα εργασίας σας."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Κλήση]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διορθώθηκε"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγράφηκε"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("agentName")), " αποδέχθηκε τη διένεξη"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η διένεξη στάλθηκε στον χρήστη ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξηγήστε γιατί πιστεύετε ότι αυτός ο ελεγχόμενος είναι ο λάθος παραλήπτης για αυτές τις παρατηρήσεις"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα σχόλιο και ορίστε έναν νέο ελεγχόμενο"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα σχόλιο"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξηγήστε γιατί πιστεύετε ότι αυτές οι βαθμολογίες είναι άδικες (υποχρεωτικό)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα σχόλιο (υποχρεωτικό)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξηγήστε γιατί απορρίπτετε αυτήν τη διένεξη"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα σχόλιο"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή διένεξης σε"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή διένεξης σε"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή διένεξης σε"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε νέο ελεγχόμενο"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις διένεξης"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις διένεξης"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή ελεγχόμενου"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή διένεξης σε"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διένεξη"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή διένεξης για αυτόν τον έλεγχο"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν συμφωνώ με τις βαθμολογίες"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτός ο έλεγχος δεν προοριζόταν για μένα"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε έναν χρήστη"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("agentName")), " αποδέχθηκε εν μέρει τη διένεξη"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("agentName")), " απέρριψε τη διένεξη"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδεκτές"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εν μέρει αποδεκτές"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απορριφθείσες"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("userName")), " ξεκίνησε μια διένεξη: έλεγχος"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("userName")), " ξεκίνησε μια διένεξη: λάθος ελεγχόμενος"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια ανοιχτή διένεξη θα πρέπει να επιλυθεί για να εκτελεστούν άλλες ενέργειες στον έλεγχο."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδοχή"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε νέες βαθμολογίες"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή διένεξης"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι ότι θέλετε να απορρίψετε αυτήν τη διένεξη;"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή διένεξης"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διένεξη"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρόνος επίλυσης διένεξης"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε νέες δίκαιες βαθμολογίες (προαιρετικά)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή διένεξης για τον έλεγχο"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάθος ελεγχόμενος"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδοχή διένεξης"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδοχή διένεξης"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις διένεξης"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάθος ελεγχόμενος"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις διένεξης"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη διένεξης"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη διένεξης"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή της διένεξης"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγινε επεξεργασία"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " από τον χρήστη ", _interpolate(_named("author")), " στις ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["προς ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελειώσαμε"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανατέθηκε σε:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε επίσης ένα σχόλιο"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τις κάρτες βαθμολογίας υπό όρους"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγουμένως ανατέθηκε σε:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απλώς διαλέξτε ένα"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε GIF με αυτό το όνομα"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε πρώτοι έναν έλεγχο."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνδρομή σας είναι σε παύση αυτήν τη στιγμή. Ενεργοποιήστε την ξανά για να μπορείτε να δώσετε παρατηρήσεις."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναρτήθηκε από:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό δείχνει πόσος χρόνος δαπανήθηκε για τον έλεγχο αυτής της συζήτησης"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε έναν χρήστη"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Λείπει η βασική αιτία για την κατηγορία: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήστε μια κατηγορία ή προσθέστε ένα σχόλιο"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύμπτυξη"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάπτυξη πεδίου σχολίων"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύμπτυξη πεδίου σχολίων"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη μορφοποίησης"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη πλαισίου"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση μορφοποίησης"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση πλαισίου"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή ελέγχου"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήστε όλες τις κατηγορίες"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήστε τουλάχιστον μία κατηγορία"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθηκεύστε αυτήν τη συζήτηση για αργότερα"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση για αργότερα"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενη ανάθεση"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντικαταστήστε τη συζήτηση σε αυτόν τον κύκλο ανάθεσης"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος συζήτησης"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος αναφοράς"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή σχολίου"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το σχόλιο διαγράφηκε"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή αυτού του σχολίου;"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντάκτης σχολίου"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το hashtag έχει διαγραφεί ή μετονομαστεί."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πείτε μας τι σας άρεσε ή τι θα μπορούσε να είναι καλύτερο..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχολιασμός"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπό όρους"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αυτήν την ενέργεια θα διαγραφούν οριστικά όλα τα σχόλια."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή σχολίου"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Μήνυμα]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_list(0)), " δεν έχει δει ακόμη αυτές τις παρατηρήσεις"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_list(0)), " έχει δει αυτές τις παρατηρήσεις"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώην μέλος"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πατήστε Enter για να δημιουργήσετε ένα νέο hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα εξατομικευμένα hashtag είναι κλειδωμένα από τον διαχειριστή."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιήστε το για να προσθέσετε σελιδοδείκτη σε υποθέσεις και να τις φιλτράρετε αργότερα"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως βάσης αναφοράς"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις μηνύματος"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενη συζήτηση"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν παρατηρήσεις"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν γίνει έλεγχοι"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε έναν έλεγχο παρακάτω για να ξεκινήσετε."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν απομένουν άλλοι έλεγχοι"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχει αξιολογηθεί"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενος εντολοδόχος"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενη συζήτηση"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάργηση βάσης αναφοράς"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απάντηση"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απάντηση σε αυτόν τον έλεγχο"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη πλευρικής εργαλειοθήκης ελέγχου"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αυτήν την ενέργεια θα διαγραφεί οριστικά ο έλεγχος και όλα τα σχόλια που έχουν προστεθεί."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή ελέγχου"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή ελέγχου"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο έλεγχος διαγράφηκε"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ο έλεγχος διαγράφηκε από τον χρήστη ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελειώσαμε!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέστε εκ νέου αυτήν την εργασία στον εαυτό σας για να υποβάλετε έναν έλεγχο"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε υποβάλει ήδη έναν έλεγχο για αυτόν τον χρήστη."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Έχετε υποβάλει έναν έλεγχο για τον χρήστη ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν κατηγορίες για εμφάνιση για αυτήν τη συζήτηση."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτοαξιολογήσεις δεν είναι διαθέσιμες για αυτόν τον χώρο εργασίας."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να μην γίνει αποθήκευση"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση αυτού του ελέγχου ως πρόχειρο;"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη αυτού του πρόχειρου"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση πλευρικής εργαλειοθήκης ελέγχου"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτυχία"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχία"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχία με δευτερεύοντα σφάλματα"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνεται υπολογισμός ακόμη..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε ένα σχόλιο"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος αυτού του μηνύματος"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τον εαυτό σας"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε αυτήν τη συζήτηση"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντάκτης"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν χρήστες για έλεγχο"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτοαξιολογήσεις απενεργοποιήθηκαν"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε χρήστης"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετέχοντες"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες χώρου εργασίας"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνεται φόρτωση..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χρήστη"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 έλεγχος"]), _normalize([_interpolate(_named("count")), " έλεγχοι"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε νέες αξιολογήσεις"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο υπεύθυνος του χώρου εργασίας σας δεν έχει επιτρέψει τις αυτοαξιολογήσεις."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πατήστε Enter για να προσθέσετε ετικέτα σε αυτήν την καρφίτσα"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγραμμίσεις"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταίες 30 ημέρες"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αστέρι"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια συνεδρία ευθυγράμμισης"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρα"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι πληροφορίες σάς παρέχουν μια οπτική αναπαράσταση των δεδομένων της υπηρεσίας βοήθειας. Χρησιμοποιήστε τα διαδραστικά γραφήματα για να κατανοήσετε πού πρέπει να εστιάσετε τις προσπάθειές σας όσον αφορά το QA, να εντοπίσετε τα τυφλά σημεία και να βρείτε τους κατάλληλους εκπροσώπους για έλεγχο με βάση την απόδοσή τους."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αναγνωριστικό ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Μετάφραση σε ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σε"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χωρίς αντιστοίχιση"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει τώρα"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχει τώρα"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συζήτηση έχει αφαιρεθεί και δεν είναι πλέον διαθέσιμη."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συζήτηση που αναζητάτε δεν υπάρχει στο Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι καρφίτσες με αυτήν τη συζήτηση"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε συνεδρία"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση συζήτησης"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την αφαίρεση μιας συζήτησης από μια συνεδρία θα διαγραφούν όλοι οι έλεγχοι που έχουν δοθεί σε αυτήν."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αφαίρεση συζήτησης από τη συνεδρία ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί συνεδρίες ευθυγράμμισης ακόμη."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συζήτηση προστέθηκε στη συνεδρία"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συζήτηση αφαιρέθηκε από τη συνεδρία"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σε / Αφαίρεση από μια συνεδρία ευθυγράμμισης"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή συνδέσμου αυτής της συζήτησης"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει συζήτηση για εμφάνιση"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επωνυμία"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email πελάτη"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό πελάτη"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα πελάτη"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα εξωτερικής φόρμας"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΧΠΑ"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρόνος πρώτης απόκρισης"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδα"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτεραιότητα"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο έρευνας"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία ικανοποίησης"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΧΓΠΕ"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρόνος για πρώτη επίλυση"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχικό"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνεται φόρτωση..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το μήνυμα δεν υπάρχει σε αυτήν τη συζήτηση ή έχει διαγραφεί"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει θέμα"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει συζήτηση για εμφάνιση"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε συζήτηση"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άνοιγμα αρχικής συζήτησης"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι δυνατή η σύνδεση με τη συζήτηση – λείπει το URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετέχοντες"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συμμετέχοντες"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα συζήτησης"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ για να διαβάσετε ελέγχους"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ για έλεγχο"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζήτηση που δημιουργήθηκε μέσω επέκτασης προγράμματος περιήγησης"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή έρευνας ικανοποίησης πελατών"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή συζήτησης"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνυμα με αρνητικό συναίσθημα"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνυμα με θετικό συναίσθημα"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάφραση στα Αγγλικά"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση πρωτότυπου"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συζήτησης"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η διαγραφή μιας συζήτησης είναι οριστική. Διαγράφονται επίσης όλα τα σχετικά δεδομένα, π.χ. έρευνες, μεταγραφές και έλεγχοι από το Zendesk QA, συμπεριλαμβανομένων των πινάκων ελέγχου.", _interpolate(_named("lineBreak")), "Θα εξακολουθείτε να έχετε πρόσβαση σε αυτήν τη συζήτηση στο Zendesk Support ή σε άλλο λογισμικό υπηρεσίας βοήθειας που χρησιμοποιείτε."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η διαγραφή μιας συζήτησης είναι οριστική. Επίσης, διαγράφει όλα τα σχετικά δεδομένα, π.χ. έρευνες, μεταγραφές και κριτικές από Zendesk QA, συμπεριλαμβανομένων των πινάκων εργαλείων. Εάν η συζήτηση ανήκει σε πολλούς χώρους εργασίας, θα διαγραφεί από όλους.", _interpolate(_named("lineBreak")), "Θα εξακολουθείτε να έχετε πρόσβαση σε αυτήν τη συζήτηση στο Zendesk Support ή σε άλλο λογισμικό υπηρεσίας βοήθειας που χρησιμοποιείτε."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συζήτησης"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συζήτησης από το Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η συζήτηση δεν είναι ολοκληρωμένη λόγω κλιμάκωσης. Μεταβείτε στο μενού ", _interpolate(_named("optionsUrl")), " και επικυρώστε τις ετικέτες σας. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η συζήτηση δεν είναι ολοκληρωμένη λόγω κλιμάκωσης. Μιλήστε με τον διαχειριστή σας σχετικά με την επικύρωση των ετικετών σας. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσεις > Προηγμένες επιλογές"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε σχετικά με τις ετικέτες"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλειστές"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέες"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εκκρεμότητα"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλύθηκαν"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο εντολοδόχος άλλαξε στον χρήστη ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χωρίς αντιστοίχιση"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εφαρμόστηκε η μακροεντολή ", _interpolate(_named("target")), " από τον χρήστη ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η συζήτηση έλαβε αξιολόγηση ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("target")), " απέκρυψε τη συζήτηση"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η κατάσταση άλλαξε σε ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προστέθηκε ετικέτα ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα εργασίας άλλαξε σε ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("author")), " ανέβαλε τη συζήτηση για ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("author")), " κατάργησε την αναβολή της συζήτησης"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ρομπότ έχει κολλήσει σε βρόχο, επαναλαμβάνοντας διαδοχικά ένα μήνυμα"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επανάληψη ρομπότ"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επανάληψη ρομπότ, όπου το ίδιο μήνυμα επαναλαμβάνεται μία ή περισσότερες φορές διαδοχικά"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρέθηκαν φράσεις που ταιριάζουν"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ένας μέσος εκπρόσωπος χειρίζεται τη συζήτηση ", _interpolate(_named("percent")), " πιο αποτελεσματικά από το ρομπότ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτελεσματικότητα επικοινωνίας ρομπότ"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το ρομπότ χειρίστηκε τη συζήτηση ", _interpolate(_named("percent")), " πιο αποτελεσματικά από έναν μέσο εκπρόσωπο"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτελεσματικότητα επικοινωνίας ρομπότ"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση δεν περιλαμβάνει την απαραίτητη δήλωση που ενημερώνει τους συμμετέχοντες για την καταγραφή, η οποία απαιτείται για λόγους συμμόρφωσης και διαφάνειας"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπει η γνωστοποίηση καταγραφής"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Έχει παραβιαστεί μια σύμβαση επιπέδου υπηρεσιών (SLA (ΣΕΥ)) για ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παραβίαση SLA (ΣΕΥ)"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η μετρική για τον κανόνα SLA υπερέβη τον χρονικό στόχο"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδείξεις απώλειας πελάτη. Ο πελάτης εξέταζε το ενδεχόμενο αλλαγής ή δήλωσε ότι θα αποχωρήσει."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστηκε κίνδυνος αποχώρησης"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η συζήτηση έχει στιγμές παύσης που διαρκούν περισσότερο από το καθορισμένο όριο των ", _interpolate(_list(0)), " δ"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 παρουσία"]), _normalize([_interpolate(_named("count")), " παρουσίες"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " δ μέγιστη"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης αναζήτησε βοήθεια υψηλότερου επιπέδου"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζητήθηκε κλιμάκωση"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης εξέφρασε εξαιρετική ευγνωμοσύνη ή ήταν πολύ ικανοποιημένος με την υποστήριξη που έλαβε"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαιρετική εξυπηρέτηση"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είτε ο πελάτης είτε ο εκπρόσωπος ζήτησε ρητά συμπληρωματική επικοινωνία"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αίτημα συμπληρωματικής επικοινωνίας"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης έδειξε δυσαρέσκεια"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικό συναίσθημα"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση ήταν ασυνήθιστη ή μη τυπική και χρειάστηκε περισσότερος χρόνος για την επίτευξη επίλυσης"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστηκε ακραία τιμή"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης έδειξε ικανοποίηση"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θετικό συναίσθημα"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμοστέο"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός ως μη εφαρμοστέου"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τελευταία επεξεργασία από ", _interpolate(_named("author")), " στις ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη εφαρμοστέο"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Από ", _interpolate(_named("author")), " στις ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε έναν λόγο (προαιρετικά)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν εντοπίστηκαν πληροφορίες"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σύνδεσης"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέστε την υπηρεσία βοήθειας με τον λογαριασμό σας και δημιουργήστε τουλάχιστον ένα φίλτρο για να δείτε τη λίστα των συζητήσεων."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συζητήσεις για εμφάνιση"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή ρυθμίσεων χώρου εργασίας"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγχόμενος"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία ενημέρωση"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Σε μια ημέρα)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Αυτόν τον μήνα)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Αυτήν την εβδομάδα)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εύρεση με βάση το αναγνωριστικό"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αποτελέσματα αναζήτησης για ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε από άλλους"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχολιάστηκαν"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νεότερες"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παλαιότερες"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τυχαία"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσφατη ενημέρωση"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσφατη απάντηση"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταξινόμηση κατά"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη αναγνωσμένες"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε από εσάς"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαδικασία εισαγωγής μπορεί να διαρκέσει λίγη ώρα. Θα σας στείλουμε email όταν ολοκληρωθεί."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA εισάγει τις συζητήσεις σας"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Όριο: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε κάνοντας κλικ στο εικονίδιο ευθυγράμμισης στην κεφαλίδα της προβολής συζήτησης."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει τίποτα για αναφορά εδώ. Δοκιμάστε ξανά χρησιμοποιώντας διαφορετικό φίλτρο ή επιλογή ταξινόμησης."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ακραίες τιμές για εμφάνιση."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε μια σχετική συζήτηση και κάντε κλικ στο εικονίδιο αστεριού στην κεφαλίδα για να της προσθέσετε σελιδοδείκτη εδώ."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε να αλλάξετε τα φίλτρα ή προσκαλέστε άλλους εκπροσώπους της υπηρεσίας βοήθειας να προσθέσουν περισσότερες συζητήσεις."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πελάτης"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χωρίς όριο"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εσωτερικό σημείωμα"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορτώνουμε τις συζητήσεις αυτήν τη στιγμή. Μπορεί να λείπει ακόμη κάποιο περιεχόμενο."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενος χρήστης"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε χρήστης"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που ελήφθησαν"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλη χώρου εργασίας"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενος χρήστης"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί συζητήσεις"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί συζητήσεις"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ημιτελείς έλεγχοι καταλήγουν εδώ. Ξαναδιαβάστε, αναθεωρήστε και υποβάλετέ τον μέσα στις επόμενες 30 ημέρες."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν έλεγχοι ως πρόχειρα"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συζητήσεις με αστέρι"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συζητήσεις για εμφάνιση"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε αποτέλεσμα"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στο επιλεγμένο μήνυμα"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λιγότερες απαντήσεις"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περισσότερες απαντήσεις"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναίσθημα"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή λίστας συζητήσεων"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΡΟΜΠΟΤ"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η κλήση απαντήθηκε στις ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η κλήση έληξε στις ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η κλήση πραγματοποιήθηκε στις ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε από εσάς"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η φόρτωση του αρχείου ήχου απέτυχε. Βεβαιωθείτε ότι έχετε εισέλθει στο ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο έρευνας ικανοποίησης πελατών"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα φόρτωσης ήχου"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντάκτης σχολίου"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν πραγματοποιήθηκε καμία κλήση"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακρόαση στην υπηρεσία βοήθειας"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακρόαση στο Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταχύτητα αναπαραγωγής"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος κλήσης"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύστημα"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντίγραφο"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υψηλή"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαμηλή"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέτρια"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι χρήστης του Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA κατατάσσει αυτόματα τους εκπροσώπους με βάση το μηνιαίο μέσο CSAT τους. Βρείτε συζητήσεις που χειρίστηκαν εκπρόσωποι των οποίων το CSAT είναι χαμηλότερο από των ομολόγων τους με ένα κλικ."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάταξη με βάση το CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέτρια"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υψηλή"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαμηλή"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαμηλότερη"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υψηλότερη"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι χρήστης του Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα δελτίο"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όγκος"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA κατατάσσει αυτόματα τους εκπροσώπους σε ομάδες απόδοσης με βάση τον αριθμό των συζητήσεων που χειρίζονται ανά μήνα. Βρείτε συζητήσεις από εκπροσώπους με χαμηλότερο όγκο συγκριτικά με τους ομολόγους τους με ένα κλικ."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάταξη με βάση τον όγκο"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ελέγχθηκε"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση ελέγχου"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαμηλό"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υψηλό"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ουδέτερο"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι χρήστης του Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναίσθημα"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA κατατάσσει αυτόματα τους εκπροσώπους με βάση το μέσο συναίσθημα στις συζητήσεις. Βρείτε αλληλεπιδράσεις όπου οι εκπρόσωποι αντιμετωπίζουν πιο απογοητευμένους πελάτες από τους ομολόγους τους με ένα κλικ."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάταξη με βάση το συναίσθημα"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν εντοπίστηκε"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστηκε"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακραίες τιμές"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις που απαιτούν την προσοχή σας. Το μοντέλο μηχανικής μάθησής μας εντοπίζει σύνθετες ή άτυπες αλληλεπιδράσεις για περαιτέρω έλεγχο."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακραίες τιμές"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικό"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θετικό"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε συζητήσεις για έλεγχο όπου ο πελάτης έδειξε είτε ικανοποίηση είτε απογοήτευση. Χρησιμοποιήστε το αναπτυσσόμενο μενού για να επιλέξετε τον τύπο συναισθήματος που αναζητάτε στις συζητήσεις σας. Σύρετε το χαρακτηριστικό φίλτρου στην κορυφή για να δείτε το ποσοστό του επιλεγμένου συναισθήματος σε όλες τις αλληλεπιδράσεις σας."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναίσθημα"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναίσθημα"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση ελέγχου"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ελέγχθηκε"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA ανιχνεύει αυτόματα τις γλώσσες στις συζητήσεις σας. Χρησιμοποιήστε το αναπτυσσόμενο μενού για να επιλέξετε συζητήσεις σε συγκεκριμένες γλώσσες για έλεγχο. Σύρετε το χαρακτηριστικό φίλτρου στην κορυφή, για να δείτε μια επισκόπηση όλων των γλωσσών που χρησιμοποιούνται στην υπηρεσία βοήθειας."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες συζήτησης"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Μπορείτε να ", _interpolate(_named("paragraph_2_item")), " με τις Πληροφορίες συγκριτικά με τα κανονικά φίλτρα συζητήσεων. Δείτε μια οπτική επισκόπηση όλων των δεδομένων από τις υπηρεσίες βοήθειας και εξερευνήστε πληροφορίες όπως:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τη χρήση των Πληροφοριών συζήτησης"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατανοήστε τη σχετική σημασία συγκεκριμένων χαρακτηριστικών συζήτησης σε σχέση με άλλα"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε τους εκπροσώπους με την υψηλότερη και τη χαμηλότερη απόδοση με την ενσωματωμένη κατάταξη απόδοσης εκπροσώπων"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Οι ", _interpolate(_named("paragraph_1_item")), " είναι ένα ισχυρό διαδραστικό εργαλείο εξόρυξης δεδομένων που σας βοηθά να βρίσκετε τις συζητήσεις που χρειάζονται έλεγχο περισσότερο."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάνετε πολύ περισσότερα"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τα γραφήματα \"Συζητήσεις\" και \"Εκπρόσωποι\" είναι ", _interpolate(_named("paragraph_3_item_1")), " – με ενημέρωση των φίλτρων ή με κλικ στα διαχωρισμένα τμήματα ενός από τα δύο γραφήματα θα ενημερωθεί αυτόματα η λίστα συζητήσεων. ", _interpolate(_named("paragraph_3_item_2")), " για να εξερευνήσετε λεπτομερείς αναλύσεις ή ποσοστά των διαφόρων στοιχείων."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διασυνδεδεμένα"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναδιατάξτε τα χαρακτηριστικά φίλτρου"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Οι Πληροφορίες συζήτησης εμφανίζουν μόνο ", _interpolate(_named("paragraph_4_item")), ". Αφαιρούμε αυτόματα αλληλεπιδράσεις χωρίς πραγματική αξία ελέγχου, όπως μηνύματα που δημιουργούνται αυτόματα, ανεπιθύμητα μηνύματα, απαντήσεις από ρομπότ, ή μηνύματα που δεν περιέχουν πραγματικό διάλογο με τον εκπρόσωπο υποστήριξης."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καθαρές και κλειστές συζητήσεις"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι βλέπω εδώ;"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " από ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " από ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " από ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " της κατηγορίας ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η λήψη απέτυχε"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Δ/Ι"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπρόσωποι"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " του συνόλου)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφόρτωση"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είμαστε σίγουροι όμως ότι θα είναι εξαιρετική μόλις προκύψει."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη έρευνα"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν υποβληθεί παρατηρήσεις έρευνας"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει διαθέσιμη βαθμολογία παρατηρήσεων έρευνας ακόμη..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολόγηση"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ελήφθη"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτα παρατηρήσεων"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες σχολίων παρατηρήσεων"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμόστηκε φίλτρο εύρους ημερομηνιών"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " απάντηση"]), _normalize([_interpolate(_named("count")), " απαντήσεις"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσία βοήθειας"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Εξετάστε σε βάθος γιατί δόθηκε μια βαθμολογία ", _interpolate(_list(0)), " ρυθμίζοντας λόγους από τις ρυθμίσεις της έρευνας."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δεν υπάρχουν λόγοι ", _interpolate(_list(0)), " ακόμη"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τους λόγους της έρευνας"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις με ετικέτες:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " κατά"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσες"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγοι"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία σχολίων"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Λόγοι ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προβλεπόμενοι παράγοντες ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση συζήτησης"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες υπηρεσίας βοήθειας"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Απαντήσεις έρευνας (", _interpolate(_named("n")), ")"]), _normalize(["Απάντηση έρευνας (", _interpolate(_named("n")), ")"]), _normalize(["Απαντήσεις έρευνας (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει θέμα"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Συζητήσεις με ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " κατά χρήστες"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσοστό απαντήσεων"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η βαθμολογία ", _interpolate(_list(0)), " είναι το άθροισμα όλων των απαντήσεων διαιρούμενο με το άθροισμα των μέγιστων βαθμολογιών"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στόχου"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση στόχου"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε έναν στόχο"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε στόχο"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύντομο"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολύ μεγάλο"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεγάλο"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεσαίου μεγέθους"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυσνόητο σχόλιο"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βοήθησε"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κακή υποστήριξη"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλή υποστήριξη"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπερβολικό συναίσθημα"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λογαριασμός"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις για τον εκπρόσωπο"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταγγελία"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύντομο και αταξινόμητο"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το πρόβλημα δεν επιλύθηκε"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το πρόβλημα επιλύθηκε"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικό συναίσθημα"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικό αποτέλεσμα"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θετικό συναίσθημα"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έπαινος"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κακό προϊόν"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή χρημάτων"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γρήγορη υποστήριξη"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αργή υποστήριξη"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστώ"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το Zendesk QA κατανέμει αυτόματα τα δεδομένα ", _interpolate(_list(0)), " σας σε προκαθορισμένες κατηγορίες. Έτσι, μπορείτε να κατανοείτε σύνθετες πληροφορίες και να διερευνάτε τις αιτίες χωρίς να εξετάζετε μη αυτόματα όλες τις γραπτές παρατηρήσεις."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι βαθμολογίες"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι έρευνες"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε γλώσσα απάντησης"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέγεθος σχολίου"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μέγεθος σχολίου"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθώς το 25% των σχολίων αποτελείται από μία μόνο λέξη, τα μεγαλύτερα σχόλια είναι απαραίτητο να διαβάζονται. Βρείτε πιο ουσιαστικές παρατηρήσεις χωρίς κόπο, καθώς το κάνουμε αυτόματα για εσάς."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος των παρατηρήσεων"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τον λόγο των παρατηρήσεων"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογίες"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν βαθμολογίες που να ταιριάζουν"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβλεπόμενοι παράγοντες"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε κατηγορία σχολίων"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λέξεις του cloud"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε λέξεις του cloud"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " από την τελευταία περίοδο"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " συζήτηση"]), _normalize([_interpolate(_named("n")), " συζήτηση"]), _normalize([_interpolate(_named("n")), " συζητήσεις"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι δυνατός ο υπολογισμός του ποσοστού απεσταλμένων ερευνών από όλες τις συζητήσεις λόγω έλλειψης δεδομένων."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απεσταλμένες έρευνες"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χοάνη έρευνας"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " από ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " από ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " από ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προβλεπόμενοι παράγοντες ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το Zendesk QA κατανέμει αυτόματα τα δεδομένα ", _interpolate(_list(0)), " σας σε προκαθορισμένες κατηγορίες. Έτσι, μπορείτε να κατανοείτε σύνθετες πληροφορίες και να διερευνάτε τις αιτίες χωρίς να εξετάζετε μη αυτόματα όλες τις γραπτές παρατηρήσεις."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέγεθος σχολίου"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθώς το 25% των σχολίων αποτελείται από μία μόνο λέξη, τα μεγαλύτερα σχόλια είναι απαραίτητο να διαβάζονται. Βρείτε πιο ουσιαστικές παρατηρήσεις χωρίς κόπο, καθώς το κάνουμε αυτόματα για εσάς."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις έρευνας"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (μέσος όρος)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (μέσος όρος)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " τάσεις κατά"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσες"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγοι"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράγοντες"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ημερομηνία αποστολής έρευνας ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ημερομηνία απάντησης έρευνας ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Εκχώρηση βαθμολογίας ", _interpolate(_list(0)), " σε"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση δεδομένων με βάση"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας συζήτησης"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάτοχο του δελτίου"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εκπρόσωπο με τις περισσότερες απαντήσεις"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (μέσος όρος)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " στην πάροδο του χρόνου"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " και IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις που περιλαμβάνουν αυτές τις λέξεις:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud με λέξεις"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT κατά χρήστες"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χοάνη έρευνας"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις έρευνας"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες υπηρεσίας βοήθειας"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT στην πάροδο του χρόνου"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud με λέξεις"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει κανάλι"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει προέλευση"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει γλώσσα"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν λόγοι"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ετικέτες"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει κατηγορία σχολίων"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει χώρος εργασίας"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διαθέσιμα δεδομένα επειδή δεν έχουν δημιουργηθεί αναθέσεις ή δεν έχουν ξεκινήσει κύκλοι. Ελέγξτε ξανά αργότερα ή προσαρμόστε τα φίλτρα."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξερευνήστε τι νέο υπάρχει"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " στοιχείο"]), _normalize([_interpolate(_named("count")), " στοιχεία"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " συζήτηση"]), _normalize([_interpolate(_named("count")), " συζητήσεις"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτυχία"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχία"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχία με δευτερεύοντα σφάλματα"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχία/Αποτυχία"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος (ώρες)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος (λεπτά)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αξιολογήσεις κατηγορίας ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος CSAT = [άθροισμα βαθμολογιών παρατηρήσεων ÷ (αριθμός όλων των απαντήσεων × μέγιστη δυνατή βαθμολογία)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η βαθμολογία CSAT του Zendesk QA κανονικοποιεί τη βαθμολογία των παρατηρήσεών σας σε μια κλίμακα 0-100% και λαμβάνει τον μέσο όρο των τυποποιημένων βαθμολογιών."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το CSAT (Βαθμολογία Ικανοποίησης Πελατών) ποσοτικοποιεί το πόσο ευχαριστημένοι είναι οι πελάτες σας με τις υπηρεσίες σας."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Κάθε έλεγχος αντιπροσωπεύει τον έλεγχο από ένα άτομο σε μία συζήτηση. Μια συζήτηση μπορεί να έχει περισσότερους από έναν ελέγχους (εάν δύο ή περισσότεροι ελεγκτές ελέγξουν την ίδια συζήτηση)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολουθήστε την εξέλιξη των βαθμολογιών του ελεγκτή στην πάροδο του χρόνου."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολουθήστε την εξέλιξη της βαθμολογίας των εκπροσώπων στην πάροδο του χρόνου."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " απαντήσεις"]), _normalize([_interpolate(_named("n")), " απάντηση"]), _normalize([_interpolate(_named("n")), " απαντήσεις"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " έλεγχοι"]), _normalize([_interpolate(_named("n")), " έλεγχος"]), _normalize([_interpolate(_named("n")), " έλεγχοι"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το IQS (εσωτερική βαθμολογία ποιότητας) είναι το αποτέλεσμα των ελέγχων συζητήσεών σας."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = άθροισμα βαθμολογιών ελέγχων ÷ αριθμό ελέγχων"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπολογίζεται ως μέσος όρος, λαμβάνοντας υπόψη όλες τις βαθμολογίες ελέγχων (σταθμισμένος μέσος όρος της βαθμολογίας κατηγορίας) που αντιστοιχούν στις επιλεγμένες συνθήκες φίλτρου."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρτωση της κάρτας απέτυχε."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλάξτε τα φίλτρα σας για να δοκιμάσετε ξανά."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ποσοστό επιτυχίας δείχνει το αποτέλεσμα των ελέγχων συζητήσεών σας."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αποτυχία εάν η βαθμολογία του ελέγχου είναι ", _interpolate(_named("failScore")), " ή χαμηλότερη"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επιτυχία εάν η βαθμολογία του ελέγχου είναι ", _interpolate(_named("passScore")), " ή υψηλότερη"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίζεται από μια προκαθορισμένη βάση αναφοράς, δηλαδή:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσοστό επιτυχίας = άθροισμα επιτυχών ελέγχων ÷ άθροισμα συνολικών ελέγχων"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ποσοστό επιτυχίας δείχνει πόσοι έλεγχοι είναι επιτυχείς χωρίς αποτυχία σε καμία κρίσιμη ή μη κρίσιμη κατηγορία."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["π.χ. Εάν ο ελεγκτής δώσει 3 ελέγχους αυτήν την ημέρα, ο αριθμός που εμφανίζεται είναι ο μέσος όρος των 3 βαθμολογιών. Δείτε πώς υπολογίζεται κάθε κελί ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["π.χ. Εάν ο εκπρόσωπος λάβει 3 ελέγχους αυτήν την ημέρα, ο αριθμός που εμφανίζεται είναι ο μέσος όρος των 3 βαθμολογιών. Δείτε πώς υπολογίζεται κάθε κελί ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν λαμβάνονται υπόψη: βάρος κατηγορίας, κρίσιμη κατάσταση (σε αντίθεση με τις \"Βαθμολογίες στην πάροδο του χρόνου\")."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθώς οι κατηγορίες μπορούν να επισημανθούν ως \"Δ/Ι\" στους ελέγχους, ο αριθμός των ελέγχων ανά κατηγορία μπορεί να είναι μικρότερος από το σύνολο των ελέγχων από τον ελεγκτή. Δείτε πώς υπολογίζεται κάθε κελί"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν λαμβάνονται υπόψη: βάρος κατηγορίας, κρίσιμη κατάσταση (σε αντίθεση με τις \"Βαθμολογίες στην πάροδο του χρόνου\")."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθώς οι κατηγορίες μπορούν να επισημανθούν ως \"Δ/Ι\" στους ελέγχους, ο αριθμός των ελέγχων ανά κατηγορία μπορεί να είναι μικρότερος από το σύνολο των ελέγχων ανά εκπρόσωπο. Δείτε πώς υπολογίζεται κάθε κελί"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν λαμβάνονται υπόψη: βάρος κατηγορίας, κρίσιμη κατάσταση (σε αντίθεση με τις \"Βαθμολογίες στην πάροδο του χρόνου\")."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Οι ομάδες κατηγοριών εμφανίζονται ξεχωριστά. Η βαθμολογία για κάθε ομάδα υπολογίζεται με βάση τη μέση βαθμολογία της κατηγορίας για τη συγκεκριμένη ομάδα κατά την ίδια περίοδο. Δείτε πώς υπολογίζεται κάθε κελί ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημείωση:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν λαμβάνονται υπόψη: βάρος κατηγορίας, κρίσιμη κατάσταση (σε αντίθεση με τις \"Βαθμολογίες στην πάροδο του χρόνου\")."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Οι ομάδες κατηγοριών εμφανίζονται ξεχωριστά. Η βαθμολογία για κάθε ομάδα υπολογίζεται με βάση τη μέση βαθμολογία της κατηγορίας για τη συγκεκριμένη ομάδα κατά την ίδια περίοδο. Δείτε πώς υπολογίζεται κάθε κελί ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "% αναφοράς"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χωρίς κρίσιμα σφάλματα"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ποσοστό χωρίς κρίσιμα σφάλματα δείχνει πόσοι έλεγχοι είναι επιτυχείς χωρίς αποτυχία σε καμία κρίσιμη κατηγορία."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εσωτερική βαθμολογία ποιότητας"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Άλλες\" βασικές αιτίες"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοδική μεταβολή"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερήσια μεταβολή"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία μεταβολή"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εβδομαδιαία μεταβολή"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια μεταβολή"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογίες στην πάροδο του χρόνου"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τη σε ημέρες ή σε εβδομάδες."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπολογισμός = συνολική βαθμολογία για ημέρα ή εβδομάδα διαιρούμενη με το σύνολο των ελέγχων που δόθηκαν"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τη σε ημέρες ή σε εβδομάδες."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπολογισμός = συνολική βαθμολογία για ημέρα ή εβδομάδα διαιρούμενη με το σύνολο των ελέγχων που ελήφθησαν"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογίες κατά κατηγορία"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τον συνδυαστικό μέσο όρο όλων των αξιολογήσεων κατηγορίας που έδωσε ο ελεγκτής κατά τη διάρκεια αυτής της περιόδου."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τον συνδυαστικό μέσο όρο όλων των αξιολογήσεων κατηγορίας που ελήφθησαν ανά εκπρόσωπο κατά τη διάρκεια αυτής της περιόδου."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασικές αιτίες για τις χαμηλές αξιολογήσεις"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασικές αιτίες για τις αξιολογήσεις"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογίες κατηγορίας στην πάροδο του χρόνου"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύστε πώς συγκρίνονται οι κατηγορίες στην πάροδο του χρόνου."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τις μέσες βαθμολογίες που δόθηκαν για κάθε κατηγορία μια επιλεγμένη ημέρα ή εβδομάδα."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύστε πώς συγκρίνονται οι κατηγορίες στην πάροδο του χρόνου."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τις μέσες βαθμολογίες που ελήφθησαν για κάθε κατηγορία μια επιλεγμένη ημέρα ή εβδομάδα."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογίες κατά ελέγχους"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθετήθηκε"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κρίσιμη κατηγορία"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλίμακα αξιολόγησης"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος / Σύνολο"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση αυτοαξιολογήσεων"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξάγει όλες τις καρτέλες"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοθέντα"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντα"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["συζήτηση"]), _normalize(["συζητήσεις"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["στοιχείο"]), _normalize(["στοιχεία"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " απαντήσεις"]), _normalize([_interpolate(_named("n")), " απάντηση"]), _normalize([_interpolate(_named("n")), " απαντήσεις"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " έλεγχοι"]), _normalize([_interpolate(_named("n")), " έλεγχος"]), _normalize([_interpolate(_named("n")), " έλεγχοι"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Εμφάνιση ενός ακόμη"]), _normalize(["Εμφάνιση ", _interpolate(_named("n")), " ακόμη"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταίες 30 ημέρες"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταίες 7 ημέρες"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενος μήνας"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενη εβδομάδα"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόν τον μήνα"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτήν την εβδομάδα"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη περίοδος"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημέρες"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνες"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίμηνα"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εβδομάδες"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έτη"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρτωση της κάρτας απέτυχε. Αλλάξτε τα φίλτρα σας για να δοκιμάσετε ξανά."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν δεδομένα για εμφάνιση."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία μεταβολή"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακες ελέγχου"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περισσότερες πληροφορίες."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα ενεργά φίλτρα σας περιλαμβάνουν δεδομένα από διαφορετικές κλίμακες αξιολόγησης."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι καρφίτσες καρτών με αυτά τα φίλτρα"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι καρφίτσες πίνακα ελέγχου με αυτά τα φίλτρα"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος χρόνος ελέγχου"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομίκευση"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν κατηγορίες"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες κατηγορίας"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γράφημα"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Σχόλιο"]), _normalize(["Σχόλια"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώην μέλος"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εσωτερική βαθμολογία ποιότητας"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (μέσος όρος)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχείς έλεγχοι"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα κάρτας"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα πίνακα ελέγχου"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήσεις"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Βασική αιτία"]), _normalize(["Βασικές αιτίες"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό ελέγχου"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρόνος ελέγχου"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγχόμενος"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτής"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίστε βασικές αιτίες"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι βασικές αιτίες εξηγούν γιατί οι συζητήσεις λαμβάνουν χαμηλές βαθμολογίες"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν οριστεί βασικές αιτίες για αυτήν την κατηγορία"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία / Σύνολο"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες αξιολόγησης"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες κατηγοριών αξιολόγησης"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδα κατηγοριών"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρος εργασίας"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομίκευση σειράς"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομαδοποίηση κατηγοριών κατά"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογή όλων"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομίκευση"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση αυτοαξιολογήσεων"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπερίληψη αυτοαξιολογήσεων"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση μόνο αυτοαξιολογήσεων"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκκαθάριση φίλτρων"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν hashtag"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag σχολίου"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε hashtag σχολίου"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν ετικέτες"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες υπηρεσίας βοήθειας"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε ετικέτες υπηρεσίας βοήθειας"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κάρτες βαθμολογίας"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε κάρτες βαθμολογίας"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτοαξιολογήσεις"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συνδέσεις που να ταιριάζουν"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση συζήτησης"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε προέλευση"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περισσότερα φίλτρα"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν χώροι εργασίας που να ταιριάζουν"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικοί χρόνοι που δόθηκαν"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι ομάδες"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ομάδες που να ταιριάζουν."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι ομάδες"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση καρτών"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση δεδομένων με βάση"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας ελέγχου"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας συζήτησης"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση δύο δεκαδικών ψηφίων"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις προβολής"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χώροι εργασίας"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολόκληρος λογαριασμός"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα χώρου εργασίας"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν χώροι εργασίας"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριότητα"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένο"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημαίες"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαγητό και ποτό"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζώα και φύση"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντικείμενα"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άτομα και σώμα"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταξίδια και μέρη"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιούνται συχνά"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτελέσματα αναζήτησης"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiley και emoticon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύμβολα"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν emoji"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοίξτε μια συζήτηση"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στη λίστα εργασιών"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " ή περιηγηθείτε στη λίστα των ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτήν τη στιγμή χρησιμοποιείτε τον χώρο εργασίας ", _interpolate(_named("workspace")), " που δεν είναι συνδεδεμένος με αυτόν τον τομέα. Κάντε κλικ εδώ για να τον συνδέσετε τώρα."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαθέσιμων συνδέσεων"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δεν μπορείτε να υποβάλετε παρατηρήσεις για το ", _interpolate(_named("host")), " ακόμη."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε την υπηρεσία βοήθειας ως την ενεργή καρτέλα."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι δυνατός ο έλεγχος αυτού του τομέα ακόμη. Αν πρέπει να ελεγχθεί, ενημερώστε τον διαχειριστή σας."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε αυτόν τον τομέα"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Συνδεθείτε με το ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια στιγμή, γίνεται ρύθμιση"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η λήψη του URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργαλεία που είναι ήδη συνδεδεμένα με τον λογαριασμό Zendesk QA του οργανισμού."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν εργαλεία συνδεδεμένα με τον λογαριασμό Zendesk QA του οργανισμού."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σας επιτρέπει να ελέγχετε ιστότοπους όπου το URL παραμένει πάντα το ίδιο, προσαρτώντας ένα μοναδικό hash στο τέλος πριν υποβάλετε έναν έλεγχο"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή λογαριασμού"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσεις"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι τομείς σας"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τομείς που είναι ήδη συνδεδεμένοι με αυτόν τον χώρο εργασίας."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να ελέγξετε οποιαδήποτε σελίδα στους ακόλουθους τομείς"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαθέσιμοι τομείς για έλεγχο"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επέκταση"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μοναδικό URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δεν έχει συνδεθεί υπηρεσία βοήθειας ακόμη – μεταβείτε στο ", _interpolate(_named("link")), " για να προσθέσετε μία."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επέκταση προγράμματος περιήγησης του Zendesk QA μπορεί να χρησιμοποιηθεί μόνο\nστα προγράμματα Professional και Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή χώρου εργασίας"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε ένα όνομα"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε μια τιμή"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένα πεδία"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμή"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είναι η πρώτη φορά που συναντάμε αυτήν τη συζήτηση. Δώστε μερικές λεπτομέρειες."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά συζήτησης:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάτι για να αναγνωρίζετε το δελτίο αργότερα"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή χρημάτων"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταχωρήστε τον εντολοδόχο και ένα θέμα"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνεται δημιουργία"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε αυτήν τη συζήτηση"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χωρίς αντιστοίχιση"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επικοινωνήστε με τον υπεύθυνό σας ή επιλέξτε διαφορετικό χώρο εργασίας από τις ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτοαξιολογήσεις είναι απενεργοποιημένες για αυτόν τον χώρο εργασίας."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έξοδος"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντίο"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρος εργασίας"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είναι δυνατός ο έλεγχος αυτού του URL. Πλοηγηθείτε σε μια συζήτηση."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματοποιήστε είσοδο πρώτα στο Zendesk QA μέσω του παρόχου SSO και στη συνέχεια δοκιμάστε ξανά."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος με SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άνοιγμα αναδυόμενου παράθυρου εισόδου."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματοποιήστε είσοδο με τον λογαριασμό σας στο Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιμένετε..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος ταυτότητας..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε λογαριασμό ακόμη;"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφή"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε πρόσβαση σε αυτήν τη συζήτηση. Εάν έπρεπε να έχετε, επικοινωνήστε με τον υπεύθυνό σας."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει πρόσβαση"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε ότι η σύνδεσή σας είναι ενεργοποιημένη στο Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχειριστείτε τις συνδέσεις σας"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα σύνδεσης"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να συνεχίσετε, πλοηγηθείτε σε μια συζήτηση στην υπηρεσία βοήθειας"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη έγκυρο URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επανασύνδεση σχεδόν τελείωσε."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια στιγμή"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε να επαναφορτώσετε τη σελίδα για να επανέλθετε στη ροή των πραγμάτων"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε κάποιο πρόβλημα"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απελευθερωθείτε από τα εικονικά όρια της υπηρεσίας βοήθειας και ελέγξτε συζητήσεις οπουδήποτε."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβαθμίστε τη συνδρομή σας για να χρησιμοποιήσετε την επέκταση"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχειριστείτε τη συνδρομή σας"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σύνδεση υπηρεσίας βοήθειας δεν συνδέεται με αυτόν τον χώρο εργασίας"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η υπηρεσία βοήθειας δεν αναγνωρίζει το συγκεκριμένο δελτίο ή το Zendesk QA δεν έχει πρόσβαση σε αυτό"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση δεν βρέθηκε"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα δραστηριότητα"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε κάποιες παρατηρήσεις"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχής είσοδος"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να χρησιμοποιήσετε την εφαρμογή τώρα"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήστε όλες τις κατηγορίες"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιήστε το για να προσθέσετε σελιδοδείκτη σε υποθέσεις και να τις φιλτράρετε αργότερα"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνεται φόρτωση..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν παρατηρήσεις για αυτήν τη συζήτηση"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαβάστε παρατηρήσεις"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άνοιγμα στο Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εδώ θα εμφανίζονται όλες οι παρατηρήσεις και ειδοποιήσεις. Τίποτα ακόμη…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε περισσότερα στο Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντες έλεγχοι"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριότητα"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες συζήτησης"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["περιέχει όλα τα"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι όλα τα"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν περιέχει όλα τα"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι όλα τα"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καλύτερη από του εκπροσώπου"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χειρότερη από του εκπροσώπου"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ψευδές"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι μεγαλύτερο από"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["περιέχει οποιοδήποτε από τα"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι οποιοδήποτε από τα"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν περιέχει κανένα από τα"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι κανένα από τα"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αληθές"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["παραβιάστηκε"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σχολίασε"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σχολιάστηκε από εμένα"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν έχει σχολιάσει"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν σχολιάστηκε από εμένα"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κρίσιμο για έλεγχο"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["περιέχει"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τελευταίες 30 ημέρες"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εντός"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τελευταίες 14 ημέρες"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τελευταίο 24ωρο"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τελευταίες 30 ημέρες"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τελευταίες 7 ημέρες"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προηγούμενος μήνας"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προηγούμενη εβδομάδα"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αυτόν τον μήνα"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αυτήν την εβδομάδα"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έως"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σήμερα"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χθες"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εντοπίστηκε"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["υπάρχει"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επισημάνθηκε με αστέρι από εμένα"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν επισημάνθηκε με αστέρι από εμένα"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["περιλαμβάνει"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μεγαλύτερο από"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μικρότερο από"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έως"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αρνητικό"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν παραβιάστηκε"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όχι πολύπλοκο"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν εντοπίστηκε"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν υπάρχει"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν περιλαμβάνει"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν ελήφθη"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν προβλήθηκε"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["θετικό"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν ελέγχθηκε"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν ελέγχθηκε από εμένα"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ελέγχθηκε"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ελέγχθηκε από εμένα"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ελήφθη"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κρίσιμο για έλεγχο"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["περιέχει"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μεγαλύτερο από"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μικρότερο από"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έως"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν περιέχει"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προβλήθηκε"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["το σχόλιό μου έχει απαντήσεις"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όχι"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ναι"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσαρμοσμένες κατηγορίες"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσαρμοσμένα \"Σημαντικά\""])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση για συνθήκη"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζήτηση"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσία βοήθειας"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετρικές"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετέχοντες"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος και παρατηρήσεις"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση όλων των συνθηκών"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημαντικά"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγκρίνει τον χειρισμό συζητήσεων του ρομπότ σας με μέσους εκπροσώπους"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ρομπότ έχει κολλήσει σε βρόχο, επαναλαμβάνοντας το ίδιο μήνυμα διαδοχικά"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων ρομπότ σε συζητήσεις"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας τύπος ρομπότ που εμπλέκεται στη συζήτηση"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα ρομπότ που εμπλέκεται στη συζήτηση"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το περιεχόμενο της συζήτησης περιέχει τη φράση ", _interpolate(_list(0)), " στα μηνύματα των εκπροσώπων"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστε αυτόματα τις κλήσεις που δεν περιλαμβάνουν την υποχρεωτική δήλωση γνωστοποίησης, όπως \"Αυτή η κλήση θα καταγραφεί\" και παρόμοιες φράσεις."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα άτομο ή ένα ρομπότ που συμμετέχει στη συζήτηση"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος ή το ρομπότ που ελέγχεται στη συζήτηση"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης αναζήτησε βοήθεια υψηλότερου επιπέδου"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θετικό και αρνητικό συναίσθημα που εντοπίστηκε στη συζήτηση"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίζει τον πιο ενεργό εκπρόσωπο στις συζητήσεις μέσω ανάλυσης τεχνητής νοημοσύνης"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει αν ο εκπρόσωπος ολοκλήρωσε τη συζήτηση ευγενικά"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει αν ο εκπρόσωπος κατανοεί και αναγνωρίζει τα συναισθήματα των πελατών"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τα λάθη γραμματικής, ορθογραφίας και ύφους του εκπροσώπου"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει ολόκληρη τη συζήτηση για τυπικές φράσεις χαιρετισμού"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει εάν ο εκπρόσωπος κατανοεί το ερώτημα ή τον προβληματισμό του πελάτη"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει πόσο εύκολα μπορεί να γίνει κατανοητό ένα κείμενο, λαμβάνοντας υπόψη την πολυπλοκότητα των λέξεων και το μήκος των προτάσεων"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει ολόκληρη τη συζήτηση για μια προτεινόμενη λύση"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τον τόνο του εκπροσώπου στη διάρκεια της συζήτησης"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδείξεις απώλειας πελάτη. Ο πελάτης εξέταζε το ενδεχόμενο αλλαγής ή δήλωσε ότι θα αποχωρήσει."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ομάδα στην οποία ανήκει η συζήτηση."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το περιεχόμενο ή το κείμενο του μηνύματος του εκπροσώπου"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων εκπροσώπων στις συζητήσεις"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος στον οποίο έχει ανατεθεί ο έλεγχος της συζήτησης"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διάρκεια της συζήτησης μέσω κλήσης σε δευτερόλεπτα"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το περιεχόμενο ή το κείμενο της συζήτησης"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κανάλι επικοινωνίας που χρησιμοποιήθηκε για τη συζήτηση (π.χ. email, συνομιλία)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια που προστέθηκαν στη συζήτηση"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία κατά την οποία προστέθηκε ένα σχόλιο στη συζήτηση"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θετικό και αρνητικό συναίσθημα που εντοπίστηκε στη συζήτηση"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία κατά την οποία ο πελάτης απάντησε στην έρευνα ικανοποίησης πελατών"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν έχει υποβληθεί διένεξη για τον έλεγχο της συζήτησης"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κανάλι που χρησιμοποίησε ο πελάτης για να ξεκινήσει τη συζήτηση"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων πελατών στις συζητήσεις"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία του τελευταίου μηνύματος στη συζήτηση"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ρόλος ή ο τύπος του χρήστη που συμμετέχει στη συζήτηση"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σιωπή μεταξύ του εκπροσώπου και του πελάτη ξεπέρασε το όριο"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης αναζήτησε βοήθεια υψηλότερου επιπέδου"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης εξέφρασε εξαιρετική ευγνωμοσύνη ή ήταν πολύ ικανοποιημένος με την υποστήριξη που έλαβε"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είτε ο πελάτης είτε ο εκπρόσωπος ζήτησε ρητά συμπληρωματική επικοινωνία"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κείμενο ή η καταγραφή ολόκληρης της συζήτησης που διεξάγεται μέσω φωνής"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν η συζήτηση έχει επισημανθεί με αστέρι"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag που χρησιμοποιούνται στα σχόλια για την κατηγοριοποίηση του περιεχομένου"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η γλώσσα της συζήτησης που εντοπίζεται από την τεχνητή νοημοσύνη"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία του τελευταίου μηνύματος στη συζήτηση"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός ιδιωτικών σημειώσεων στις συζητήσεις"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος αριθμός χαρακτήρων ανά μήνυμα στις συζητήσεις"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων που απευθύνονται σε πελάτες στις συζητήσεις"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας συμμετέχων του οποίου οι πληροφορίες είναι δημόσια ορατές"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος αριθμός λέξεων ανά μήνυμα στις συζητήσεις"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες που χρησιμοποιούνται για την αξιολόγηση της συζήτησης"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατάσταση της διαδικασίας ελέγχου της συζήτησης"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας συμμετέχων ή ένα άτομο που εμπλέκεται στη συζήτηση"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα email που σχετίζεται με τη συζήτηση"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν ο έλεγχος έχει προβληθεί"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ελεγκτής που αξιολόγησε τη συζήτηση"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία του τελευταίου ελέγχου της συζήτησης"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος που ελέγχεται στη συζήτηση"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τυχόν συγκεκριμένα σχόλια ή παρατηρήσεις που παρέχονται από τον πελάτη σχετικά με την ικανοποίησή του από τη συζήτηση"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η βαθμολογία ικανοποίησης του πελάτη για τη συζήτηση"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία ικανοποίησης πελατών ανά μήνυμα στη συζήτηση"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κάρτα βαθμολογίας που χρησιμοποιείται για τον έλεγχο της συζήτησης"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι SLA (ΣΕΥ) που δόθηκαν παραβιάστηκαν"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το είδος της προέλευσης ή της σύνδεσης από την οποία προήλθε η συζήτηση"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση ήταν ασυνήθιστη ή μη τυπική και χρειάστηκε περισσότερος χρόνος για την επίτευξη επίλυσης"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ερώτηση έρευνας που σχετίζεται με το περιεχόμενο της συζήτησης"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τίτλος ή το όνομα της έρευνας που σχετίζεται με τη συζήτηση"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος στον οποίο έχει ανατεθεί ο έλεγχος της συζήτησης"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επωνυμία ή το συγκεκριμένο προϊόν/υπηρεσία που σχετίζεται με τη συζήτηση"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κανάλι επικοινωνίας που χρησιμοποιήθηκε για τη συζήτηση (π.χ. email, συνομιλία)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία κατά την οποία η συζήτηση έκλεισε ή επιλύθηκε"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία κατά την οποία δημιουργήθηκε αρχικά η συζήτηση"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία κατά την οποία δημιουργήθηκε αρχικά η συζήτηση"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία κατά την οποία ο πελάτης απάντησε στην έρευνα ικανοποίησης πελατών"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια σύντομη περιγραφή ή σύνοψη της συζήτησης"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα εξωτερικό αναγνωριστικό αναφοράς που συνδέεται με τη συζήτηση"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο φάκελος ή η συγκεκριμένη ομαδοποίηση στην οποία έχει τοποθετηθεί η συζήτηση"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρμα ή το πρότυπο που χρησιμοποιείται για τη διάρθρωση της συζήτησης"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα εξωτερικό αναγνωριστικό αναφοράς που συνδέεται με τη συζήτηση"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το γραμματοκιβώτιο ή ο λογαριασμός email μέσω του οποίου ελήφθη η συζήτηση"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο αριθμός τηλεφώνου που σχετίζεται με τη συζήτηση"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το επίπεδο προτεραιότητας που έχει εκχωρηθεί στη συζήτηση"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο συνολικός αριθμός απαντήσεων στη συζήτηση"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο χαρακτηρισμός ή η τρέχουσα κατάσταση της συζήτησης"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η τρέχουσα κατάσταση της συζήτησης"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το θέμα της συζήτησης"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων εκπροσώπων στις συζητήσεις"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ετικέτες που έχουν εκχωρηθεί για την κατηγοριοποίηση της συζήτησης"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τύπος ή η κατηγορία της συζήτησης"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία της τελευταίας ενημέρωσης της συζήτησης"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ημερομηνία της τελευταίας ενημέρωσης της συζήτησης"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μέσο ή το κανάλι μέσω του οποίου πραγματοποιήθηκε η συζήτηση"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το περιεχόμενο ενός αντίγραφου σε μια συζήτηση"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις που δεν πληρούν τις προϋποθέσεις για έλεγχο μέσω ανάλυσης τεχνητής νοημοσύνης"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός συμμετεχόντων εκπροσώπων στις συζητήσεις"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν η συζήτηση έχει προβληθεί"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οποιοδήποτε μήνυμα"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτελεσματικότητα επικοινωνίας ρομπότ"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επανάληψη ρομπότ"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων ρομπότ"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος ρομπότ"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατεύθυνση κλήσης"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνυμα πελάτη"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπει η γνωστοποίηση καταγραφής"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (ΣΕΥ)"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλιμάκωση"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιεχόμενο αντίγραφου κλήσης"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποφώνηση"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αστέρι"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη επιλέξιμη για έλεγχο"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγχθηκε από"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζήτηση μέσω"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορθογραφία και γραμματική"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνυμα εκπροσώπου"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πιο ενεργός εκπρόσωπος"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων εκπροσώπων"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διάρκεια κλήσης (δευτ.)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιεχόμενο συζήτησης"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι μηνύματος"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτα σχολίου"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία σχολιασμού"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναίσθημα"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία απάντησης της έρευνας CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διένεξη"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσυναίσθηση"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι πελάτη"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων πελατών"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία τελευταίας απάντησης"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος χρήστη"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαιρετισμός"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κίνδυνος αποχώρησης"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλιμάκωση"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαιρετική εξυπηρέτηση"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρωματική επικοινωνία"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντίγραφο"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag σχολίου"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακραία τιμή"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατανόηση"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία τελευταίας απάντησης"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός ιδιωτικών σημειώσεων συζήτησης"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος αριθμός χαρακτήρων"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος αριθμός λέξεων"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός δημόσιων απαντήσεων"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημόσιος συμμετέχων"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία αξιολόγησης"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολόγηση και σχόλιο"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία αξιολόγησης"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση ελέγχου"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωσιμότητα"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετέχων"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετικό email"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετικός"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο έλεγχος ελήφθη"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγμένη κάρτα βαθμολογίας"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο έλεγχος προβλήθηκε"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ελέγχου"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγχόμενος"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτής"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο έρευνας"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT ανά μήνυμα"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία ικανοποίησης (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτεινόμενη λύση"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος προέλευσης"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέμα"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ερώτηση έρευνας"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τίτλος έρευνας"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επωνυμία συζήτησης"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τομέας επωνυμίας"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι συζήτησης"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία κλεισίματος"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία απάντησης της έρευνας CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία αποστολής της έρευνας CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή συζήτησης"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξωτερικό αναγνωριστικό"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πεδίο συζήτησης"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φάκελος συζήτησης"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρμα συζήτησης"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδα συζητήσεων"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξωτερικό αναγνωριστικό"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραμματοκιβώτιο συζήτησης"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός τηλεφώνου"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτεραιότητα συζήτησης"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων συζήτησης"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρακτηρισμός συζήτησης"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση συζήτησης"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέμα συζήτησης"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός απαντήσεων εκπροσώπων"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτα υπηρεσίας βοήθειας"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτα υπηρεσίας βοήθειας"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος συζήτησης"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ενημέρωσης"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ενημέρωσης"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τόνος"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιεχόμενο αντίγραφου"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός συμμετεχόντων εκπροσώπων"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση προβολής"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυνατότητα ευαλωτότητας"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υγεία ευαλωτότητας"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γεγονός ζωής ευαλωτότητας"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία Αυτόματου QA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια συνθήκη που σχετίζεται με ημερομηνία"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα όνομα στο φίλτρο σας"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρώστε ένα όνομα φίλτρου"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άτομο που έχει πραγματοποιήσει είσοδο"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώην μέλη χώρου εργασίας"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλη χώρου εργασίας"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προσθήκη ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή φίλτρου"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα πρέπει να έχετε τουλάχιστον 1 φίλτρο που σχετίζεται με ημερομηνία"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε επιλογές"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε μια επιλογή"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει σε ", _interpolate(_named("n")), " ημέρα. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>σε ", _interpolate(_named("n")), " ημέρα</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει σε ", _interpolate(_named("n")), " ημέρες. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>σε ", _interpolate(_named("n")), " ημέρες</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει σήμερα. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>σήμερα</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίστε μια συνδρομή τώρα"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " ημέρες"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Σας απομένουν ", _interpolate(_named("days")), " ημέρες στη δοκιμαστική σας έκδοση"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο χρηστών για το πρόγραμμά σας. ", _interpolate(_named("link")), " για να μάθετε περισσότερα ή να αναβαθμίσετε το πρόγραμμά σας."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ εδώ"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συνδέστε την υπηρεσία βοήθειας"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό θα μας επιτρέψει να ανακτήσουμε τα δελτία σας για να τα ελέγξετε."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέστε την υπηρεσία βοήθειας"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρησιμοποιείτε το Zendesk QA με δείγμα δεδομένων. Για να διαγράψετε το δείγμα δεδομένων και να εισαγάγετε τις δικές σας συζητήσεις, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείγμα δεδομένων"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγράψτε χρήσιμες πληροφορίες παρακάτω"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τραβήξτε ένα στιγμιότυπο οθόνης πλήρους σελίδας για να παρέχετε το πλαίσιο"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνήστε με την ομάδα υποστήριξης πελατών μας για να επιλύσετε αυτό το πρόβλημα:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε κάποιο πρόβλημα"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά σφάλματος:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή χρήσιμων πληροφοριών"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες, ρομπότ και χώροι εργασίας"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες και χώροι εργασίας"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σήμερα"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αυτόν τον μήνα"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αυτήν την εβδομάδα"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ελέγχθηκε ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ελέγχθηκαν ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ελέγχθηκαν ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι νέο υπάρχει;"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έξοδος"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντομεύσεις πληκτρολογίου"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριότητα"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνομιλήστε μαζί μας"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρτωση της συνομιλίας μας δεν ήταν δυνατή (Μήπως χρησιμοποιείτε κάποιο ad-blocker;)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθοδήγηση"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάβαση σε..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινότητα"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας ελέγχου"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βοήθεια"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχική σελίδα"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοθέντες έλεγχοι"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντες έλεγχοι"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γνωσιακή βάση"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή λογαριασμού"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργασίες"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η εφαρμογή ενημερώθηκε. ", _interpolate(_named("buttonStart")), "Επαναφόρτωση", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αν θέλετε να αρχίσετε να χρησιμοποιείτε το Zendesk QA ως μέρος του χώρου εργασίας σας:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Απλώς σας ενημερώνουμε ότι έχετε ήδη λάβει πρόσκληση για να χρησιμοποιήσετε το Zendesk QA ως μέρος ενός χώρου εργασίας. Αν θέλετε, μπορείτε φυσικά να συνεχίσετε, αλλά σημειώστε ότι αυτό θα δημιουργήσει έναν ", _interpolate(_named("bold")), " για εσάς."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ξεχωριστό οργανισμό"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ναι, επιτρέψτε μου να συνεχίσω με ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έναν νέο λογαριασμό"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι;"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_named("inviter")), " σάς ζητάει να ενταχθείτε στον λογαριασμό ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένταξη"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει σύνδεση στο Internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη νέας καρφίτσας"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γρήγορη πλοήγηση"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εστίαση και ανάπτυξη του εργαλείου επεξεργασίας σχολίων"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη ή εμφάνιση πλευρικού πλαισίου"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πλοήγηση στην επόμενη συζήτηση"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άνοιγμα αρχικής συζήτησης"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πλοήγηση στην προηγούμενη συζήτηση"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκχώρηση σε όλες τις κατηγορίες της υψηλότερης δυνατής αξιολόγησης"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκχώρηση σε όλες τις κατηγορίες της χαμηλότερης δυνατής αξιολόγησης"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τυχαία σειρά συζητήσεων"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πείτε μας"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντομεύσεις πληκτρολογίου"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή παραθύρου συντομεύσεων"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο των ", _interpolate(_named("charLimit")), " χαρακτήρων"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο τύπος εικόνας ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") δεν επιτρέπεται"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το μέγεθος της εικόνας ", _interpolate(_named("name")), " είναι πολύ μεγάλο (επιτρέπονται έως 5 MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρτωση του εργαλείου επεξεργασίας σχολίων απέτυχε"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθέστε για αποστολή"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εικόνα"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεσμος"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεσμος"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κείμενο"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση προτύπων"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί πρότυπα"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη βασικής αιτίας"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενο"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο, συμπεριλαμβανομένων των διαγραμμένων λόγων"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος αξιολόγησης"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επώνυμο"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός εργαζομένων"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός τηλεφώνου"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα επικοινωνήσουμε μαζί σας για να σας βοηθήσουμε να ρυθμίσετε τον λογαριασμό σας"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα εταιρείας"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός επικοινωνίας της εταιρείας"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώς ακούσατε για πρώτη φορά για το Zendesk QA;"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ένα κανάλι"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε έναν αριθμό εκπροσώπων"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρώστε όλα τα απαιτούμενα πεδία"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να βεβαιώσετε ότι έχετε διαβάσει και αποδέχεστε τους όρους μας και την πολιτική απορρήτου μας."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι Slack με υποστήριξη"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαφήμιση"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκδήλωση"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλος ή συνάδελφος"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace υπηρεσίας βοήθειας ή υπεύθυνος λογαριασμού"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστότοπος παραπομπής"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηχανή αναζήτησης (Google, Bing κ.λπ.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσα κοινωνικής δικτύωσης"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πείτε μας για τον εαυτό σας και την εταιρεία σας."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία λογαριασμού"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είμαι έτοιμος/-η."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιπλανηθείτε, γνωρίστε την εφαρμογή, διαγράψτε και προσθέστε διάφορα πράγματα."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λογαριασμός δημιουργήθηκε. Ποιο είναι το επόμενο βήμα;"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε έτοιμοι να φέρετε τις πραγματικές σας συζητήσεις;"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε τα δικά σας δεδομένα"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη εξερεύνησης"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε το με δείγμα δεδομένων"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έτσι, μπορούμε να αρχίσουμε να ανακτούμε τα δελτία σας για να τα ελέγξετε."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέστε τον λογαριασμό σας στην υπηρεσία βοήθειας"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλαξα γνώμη."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση εγγραφής"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η καρφίτσα δημιουργήθηκε. ", _interpolate(_named("buttonStart")), "Μετάβαση στις καρφίτσες", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι καρφίτσες καθοδήγησης είναι διαθέσιμες στο πρόγραμμα Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβάθμιση προγράμματος"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε όλα τα προγράμματα"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθηκεύστε τις σημειώσεις σας για να προετοιμαστείτε για την επόμενη συνεδρία 1:1 ή συνεδρία καθοδήγησης."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσες για καθοδήγηση"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχολιάστε ή προσθέστε άλλα άτομα με ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συζήτηση έχει διαγραφεί"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία νέας καρφίτσας"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία καρφίτσας"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία καρφίτσας"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώς λειτουργούν οι καρφίτσες"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε δικαίωμα προβολής αυτής της συζήτησης"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα κάρτας"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα συζήτησης"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα πίνακα ελέγχου"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η καρφίτσα αποθηκεύτηκε"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφιτσώστε αυτήν την κάρτα"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφιτσώστε αυτήν τη συζήτηση"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφιτσώστε αυτόν τον πίνακα ελέγχου"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσα κάτω από έναν χρήστη"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε έναν χρήστη"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Μόνο εσείς μπορείτε να δείτε τι καρφιτσώνετε. Για κοινοποίηση σε άλλους χρήστες, επισημάνετέ τους με το ", "@", " στα σχόλια."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ο χρήστης ", _interpolate(_named("agentsNames")), " έχει προσκληθεί τώρα σε αυτήν τη συζήτηση"]), _normalize(["Οι χρήστες ", _interpolate(_named("agentsNames")), " έχουν προσκληθεί τώρα σε αυτήν τη συζήτηση"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 σχόλιο"]), _normalize([_interpolate(_named("n")), " σχόλια"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Επισημάνατε τον χρήστη ", _interpolate(_named("participants")), ", αλλά δεν συμμετέχει σε αυτήν τη συζήτηση."]), _normalize(["Επισημάνατε τους χρήστες ", _interpolate(_named("participants")), ", αλλά δεν συμμετέχουν σε αυτήν τη συζήτηση."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " συμμετέχοντες"]), _normalize([_interpolate(_named("n")), " συμμετέχων"]), _normalize([_interpolate(_named("n")), " συμμετέχοντες"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Θέλετε να καταργήσετε την πρόσβαση του χρήστη ", _interpolate(_named("agentName")), " σε αυτήν την καρφίτσα;"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καμία ενέργεια"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μην τους προσκαλέσετε σε αυτήν τη συζήτηση"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε σχόλιο"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσκαλέστε τους"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσκαλέστε τους σε αυτήν τη συζήτηση"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση χρήστη"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νήμα"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αυτήν την ενέργεια θα διαγραφούν επίσης όλα τα σχόλια κάτω από αυτήν την καρφίτσα."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " νέα μηνύματα"]), _normalize([_interpolate(_named("n")), " νέο μήνυμα"]), _normalize([_interpolate(_named("n")), " νέα μηνύματα"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο εσείς μπορείτε να δείτε τις καρφίτσες σας. Για να κοινοποιήσετε καρφίτσες, επισημάνετε με το ", "@", " κάποιον στα σχόλια."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Καρφιτσώθηκε ", _interpolate(_named("pinType")), " από ", _interpolate(_named("agentName"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ένας πίνακας ελέγχου"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μια συζήτηση"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μια σημείωση"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εσάς"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε αναβάθμιση για να αποκτήσετε καρφίτσες καθοδήγησης"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι καρφίτσες καθοδήγησης είναι διαθέσιμες στο πρόγραμμα Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι καρφίτσες καθοδήγησης είναι διαθέσιμες στο πρόγραμμα Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβάθμιση για να αποκτήσετε τη δυνατότητα"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προετοιμαστείτε για συνεδρίες 1:1 ή αποθηκεύστε κάτι για κάποια άλλη στιγμή."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αυτήν την ενέργεια θα διαγραφεί οριστικά η καρφίτσα και όλα τα σχόλια που έχουν προστεθεί."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή καρφίτσας"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή καρφίτσας"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα φακέλου"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφιτσώστε διάφορα στοιχεία για να επιστρέψετε αργότερα"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απάντηση"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτα τα νεότερα"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτα τα παλαιότερα"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθηκεύστε μια ενδιαφέρουσα τάση του πίνακα ελέγχου ή συζήτηση στο πλαίσιο της προετοιμασίας των συνεδριών 1-1, ή σημειώστε τη για κάποια άλλη στιγμή."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια καρφίτσα κάνοντας κλικ στο παραπάνω κουμπί ή στο εικονίδιο καρφίτσας στην προβολή \"Συζήτηση\" ή \"Πίνακας ελέγχου\"."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσωμα"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί καρφίτσες"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δεν βρέθηκαν αποτελέσματα για ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε να χρησιμοποιήσετε διαφορετικές λέξεις-κλειδιά ή ελέγξτε για ορθογραφικά λάθη."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία νέας καρφίτσας"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνύματα"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άτομα"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση τον χρήστη, τη λέξη-κλειδί..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλφαβητικά"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσφατη δημιουργία"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσφατη επεξεργασία"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περισσότερες καρφίτσες"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Σκοπεύω να ελέγξω ", _interpolate(_named("n")), " συζήτηση ", _interpolate(_named("schedule"))]), _normalize(["Σκοπεύω να ελέγξω ", _interpolate(_named("n")), " συζητήσεις ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο στόχος ενημερώθηκε"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός στόχου"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να ορίσετε έναν προσωπικό στόχο για τον αριθμό των συζητήσεων που θα θέλατε να ελέγχετε."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάθε ημέρα"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάθε μήνα"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάθε εβδομάδα"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος ελέγχων"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΠΡΟΣΩΠΙΚΕΣ"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικά"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρότυπα"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση καναλιών"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε κανάλι"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποίηση παρατηρήσεων έρευνας μόνο με σχόλια"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδυναμώστε τους συναδέλφους σας τονίζοντας την εξαιρετική ποιότητα εξυπηρέτησής τους και τις εξαιρετικές παρατηρήσεις των πελατών."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποίηση θετικών παρατηρήσεων έρευνας στο Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κανάλια"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματη κοινοποίηση παρατηρήσεων με βαθμολογίες πάνω από"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποιήσεις Praisebot"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε μια υπενθύμιση στην αρχή της εβδομάδας αν δεν έχετε κάνει κανέναν έλεγχο."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπενθυμίσεις"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εβδομαδιαία υπενθύμιση για πραγματοποίηση ελέγχων"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε ειδοποιήσεις για νέους ελέγχους, απαντήσεις, επισημάνσεις και διενέξεις στο Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισημάνσεις"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήσεις"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις νημάτων"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποιήσεις Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιηθείτε για νέους ελέγχους, απαντήσεις, επισημάνσεις και διενέξεις."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις που σχετίζονται με εμένα"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισημάνσεις σε σχόλια"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέοι ληφθέντες έλεγχοι"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις σε νήματα"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά με στατιστικά στοιχεία"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε αναφορές σχετικά με τους ελέγχους που έχουν δοθεί και ληφθεί."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορές"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καθημερινά"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μηνιαία"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εβδομαδιαία"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθημερινά"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάθε 3 ώρες"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάθε 6 ώρες"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αμέσως"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποίηση"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εβδομαδιαία"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο διαχειριστής του λογαριασμού σας έχει κλειδώσει τις ρυθμίσεις ειδοποιήσεων."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε περίοδο"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικές ειδοποιήσεις"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (ενεργό)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε μια νέα μέθοδο εισόδου μέσω κοινωνικής δικτύωσης σε αυτόν τον λογαριασμό:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την αφαίρεση μιας ενεργής μεθόδου εισόδου θα πραγματοποιήσετε έξοδο. Μπορείτε να πραγματοποιήσετε είσοδο ξανά με το εναλλακτικό email σας."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση μεθόδου εισόδου"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προστέθηκε επιτυχώς το Google ως επιλογή εισόδου"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές εισόδου"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προστέθηκε επιτυχώς το Slack ως επιλογή εισόδου"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη του Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη του Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία κωδικού πρόσβασης"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι;"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέθοδος"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά κωδικού πρόσβασης"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Θα σταλεί ένα email στη διεύθυνση ", _interpolate(_named("email")), " για να επαναφέρετε τον κωδικό πρόσβασής σας."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κύριο email"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τα εισερχόμενά σας για το email επιβεβαίωσης"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαγικός σύνδεσμος"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Για να το αλλάξετε, αλλάξτε πρώτα το ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το email ", _interpolate(_named("email")), " θα συσχετιστεί με αυτόν τον κωδικό πρόσβασης."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το προφίλ ενημερώθηκε"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εικόνα προφίλ"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για καλύτερα αποτελέσματα, αποστείλετε μια τετράγωνη φωτογραφία (256 x 256 pixel ή περισσότερα)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κύριο email"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχή της εβδομάδας"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικές ρυθμίσεις"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε τουλάχιστον μία επιλογή"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιεχόμενο προτύπου"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα προτύπου"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση προτύπου σε"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε περιεχόμενο προτύπου"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα όνομα προτύπου"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία προτύπου"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία προτύπου"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε πρότυπα σχολίων για να επιταχύνετε τον έλεγχο, το καρφίτσωμα στοιχείων και την καθοδήγηση."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιείται σε"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή προτύπου"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οριστική διαγραφή αυτού του προτύπου;"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία προτύπου"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε πρότυπα σχολίων για να επιταχύνετε τον έλεγχο, το καρφίτσωμα στοιχείων και την καθοδήγηση."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί πρότυπα"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το πρότυπο σχολίων δημιουργήθηκε"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το πρότυπο σχολίων διαγράφηκε"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το πρότυπο σχολίων αποθηκεύτηκε"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθοδήγηση"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσες"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι και διενέξεις"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Για να αποκτήσετε πρόσβαση, επικοινωνήστε με την ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["υποστήριξη πελατών"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε λογαριασμός"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προς το παρόν, χρησιμοποιήστε το ", _interpolate(_named("link")), "για πρόσβαση στο Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στο Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λογαριασμός δεν μετεγκαταστάθηκε"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μετρικές"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["βαθμολογίες"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στατιστικά στοιχεία"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας ελέγχου"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ευθυγράμμιση"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συζητήσεις"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["φίλτρα"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έλεγχος"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοθέντα"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντα"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τα σχόλιά μου"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["οι διενέξεις μου"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["οι έλεγχοί μου"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριότητα"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιδράσεις"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντα"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεκίνησαν"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις έρευνας"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριότητα"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσες"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρίες"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθοδήγηση"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικές"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρότυπα"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δημιουργία κωδικού πρόσβασης"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαπιστευτήρια"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σύνδεση"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επαναφορά"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στόχος ελέγχων"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πρότυπα"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χρήστης"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία προτύπου"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικά"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρότυπα"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λογαριασμός"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομή"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["λογαριασμός"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τιμολόγηση"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εταιρεία"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσία βοήθειας"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποιήσεις"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["οργανισμός"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πληρωμή"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["άδειες"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ρυθμίσεις"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συνδρομή"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι ταυτότητας"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσεις"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικά"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα κατηγορία"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημαντικά"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομή"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρέθηκε μήνυμα σε διένεξη"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή ή κατάργηση ενός από τα μηνύματα που βρίσκονται σε διένεξη"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρησιμοποιήστε το εικονίδιο ", _interpolate(_named("placeholder")), " για να εξαιρέσετε ευαίσθητα δεδομένα από τον Αυτόματο QA. Για παράδειγμα, αντί της φράσης \"Καλή συνέχεια, Γιάννης\" χρησιμοποιήστε το \"Καλή συνέχεια, ", _interpolate(_named("placeholder")), "\", που αντιστοιχεί σε οποιοδήποτε όνομα."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το πολύ ", _interpolate(_named("limit")), " γραμμές"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή κανόνα σε"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο των 10 προσαρμοσμένων κατηγοριών"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο των ", _interpolate(_named("limit")), " εξατομικευμένων κατηγοριών"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη συνθήκης"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη γραμμής"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["και"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή συνθήκης"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν το μήνυμα εκπροσώπου"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαφορετικά βαθμολόγηση με"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τότε βαθμολόγηση με"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία κατηγορίας κάρτας βαθμολογίας"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιήστε το \"/\" για να προσθέσετε μια μεταβλητή"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι κατευθύνσεις"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μήνυμα εκπροσώπου δεν μπορεί να είναι κενό"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μήνυμα δεν μπορεί να υπερβαίνει τους 300 χαρακτήρες"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κείμενο"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι προελεύσεις"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα κανάλια"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι ετικέτες"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι συζητήσεις"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιορίστε το πεδίο εφαρμογής του κανόνα σας."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγκεκριμένες συζητήσεις"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία πληροφορίας \"Σημαντικά\""])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μήνυμα εκπροσώπου δεν μπορεί να είναι κενό"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο των 10 προσαρμοσμένων πληροφοριών"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο των ", _interpolate(_named("limit")), " εξατομικευμένων πληροφοριών"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρώστε το όνομα της πληροφορίας"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα πληροφορίας"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία κατηγορίας"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος πληροφορίας"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητική"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ουδέτερη"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θετική"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάβαση στις ρυθμίσεις χώρου εργασίας"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όχι τώρα"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να αρχίσετε να χρησιμοποιείτε αυτήν την κατηγορία αξιολόγησης, μεταβείτε στις ρυθμίσεις καρτών βαθμολογίας του χώρου εργασίας σας και προσθέστε τη σε μια κάρτα βαθμολογίας της επιλογής σας."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία αξιολόγησης δημιουργήθηκε"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογήστε αυτόματα κλειστές συζητήσεις με τον εντοπισμό συγκεκριμένων λέξεων-κλειδιών ή φράσεων."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν λειτουργεί σε αντίγραφα φωνής."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κόλλα πέντε! ✋ Έχετε συμπληρώσει το όριο των ", _interpolate(_named("limit")), " κατηγοριών αυτοματοποιημένης αξιολόγησης. Είστε ο μάγος της κατηγοριοποίησης! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όριο κατηγοριών συμπληρώθηκε"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε ένα όνομα στην κατηγορία αξιολόγησής σας"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα κατηγορίας"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα κατηγορία αξιολόγησης"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα κατηγορία αξιολόγησης"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογήστε αυτόματα κλειστές συζητήσεις με τον εντοπισμό συγκεκριμένων λέξεων-κλειδιών ή φράσεων. Δεν λειτουργεί σε αντίγραφα φωνής."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματη εμφάνιση κλειστών συζητήσεων προσδιορίζοντας συγκεκριμένες λέξεις-κλειδιά ή φράσεις. Λειτουργεί μόνο σε αντίγραφα."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογήστε αυτόματα τις συζητήσεις εντοπίζοντας συγκεκριμένες λέξεις-κλειδιά ή φράσεις στα μηνύματα τόσο του εκπροσώπου όσο και του ρομπότ."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να χρησιμοποιήσετε αυτήν την κατηγορία αξιολόγησης, μεταβείτε στις ρυθμίσεις καρτών βαθμολογίας του χώρου εργασίας σας και προσθέστε τη σε μια κάρτα βαθμολογίας."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογήστε τις συνομιλίες αυτόματα με βάση συγκεκριμένες λέξεις-κλειδιά ή φράσεις στα μηνύματα."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαθέσιμο για"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρύθμιση πληροφορίας"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η πληροφορία εμφανίζεται στα Σημαντικά με βάση καθορισμένα κριτήρια."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρύθμιση κάρτας βαθμολογίας"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η κατηγορία μπορεί να χρησιμοποιηθεί σε κάρτες βαθμολογίας με βάση καθορισμένα κριτήρια."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ρομπότ"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συνομιλία"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαθέσιμο σύντομα"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξατομικευμένο"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προκαθορισμένο"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάρτα βαθμολογίας"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σημαντικά"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["φωνή"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση αλλαγών"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αλλαγές αποθηκεύτηκαν"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμβουλή:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ στο εικονίδιο ", "{··}", " για να εξαιρέσετε τα ονόματα, τα στοιχεία της εταιρείας ή οποιαδήποτε άλλη ευαίσθητη πληροφορία κατά τους ελέγχους αποφώνησης του Αυτόματου QA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη εγκεκριμένης αποφώνησης"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία εγκεκριμένης αποφώνησης"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένη αποφώνηση"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη εξαιρέσεων"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία εξαίρεσης"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη εγκεκριμένου χαιρετισμού"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία εγκεκριμένου χαιρετισμού"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένος χαιρετισμός"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικολλήστε λέξεις εδώ διαχωρισμένες με αλλαγές γραμμής"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πατήστε Enter μετά από κάθε λέξη για μια νέα καταχώρηση"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα εφαρμογής"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για παράδειγμα, αντί της φράσης \"Καλή συνέχεια, Γιάννης\" μπορείτε να χρησιμοποιήσετε το \"Καλή συνέχεια, ", "{··}", "\", που αντιστοιχεί σε οποιοδήποτε όνομα."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύμβολο κράτησης θέσης δυναμικού περιεχομένου"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαιρέσεις"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Προστέθηκε 1 εξαίρεση"]), _normalize(["Προστέθηκαν ", _interpolate(_named("count")), " εξαιρέσεις"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Η εξαίρεση έχει προστεθεί ήδη"]), _normalize(["Οι εξαιρέσεις έχουν προστεθεί ήδη: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη εξαίρεσης"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν εξαιρέσεις"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί εξαιρέσεις"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση (διάκριση πεζών-κεφαλαίων)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Προσθήκη εξαιρέσεων"]), _normalize(["Προσθήκη 1 εξαίρεσης"]), _normalize(["Προσθήκη ", _interpolate(_named("count")), " εξαιρέσεων"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε μια λέξη ή φράση"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εξαίρεση αποθηκεύτηκε"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γλώσσα"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι γλώσσες"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε μια γλώσσα"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Η αποφώνηση έχει προστεθεί ήδη"]), _normalize(["Οι αποφωνήσεις έχουν προστεθεί ήδη: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη αποφώνησης"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν εγκεκριμένες αποφωνήσεις"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν εγκεκριμένες αποφωνήσεις"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποφώνηση"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη αποφώνησης"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε μια αποφώνηση"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αποφώνηση προστέθηκε"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αποφώνηση αποθηκεύτηκε"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η φόρμα έχει διπλότυπες καταχωρήσεις: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ο χαιρετισμός έχει προστεθεί ήδη"]), _normalize(["Οι χαιρετισμοί έχουν προστεθεί ήδη: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη χαιρετισμού"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν εγκεκριμένοι χαιρετισμοί"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν εγκεκριμένοι χαιρετισμοί"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαιρετισμός"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη χαιρετισμού"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε έναν χαιρετισμό"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο χαιρετισμός προστέθηκε"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο χαιρετισμός αποθηκεύτηκε"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη εξαίρεσης"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Διαγραφή εξαίρεσης"]), _normalize(["Διαγραφή εξαιρέσεων"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Οριστική διαγραφή αυτής της εξαίρεσης;"]), _normalize(["Οριστική διαγραφή αυτών των εξαιρέσεων;"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία εξαίρεσης"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Μία ή περισσότερες εξαιρέσεις υπερβαίνουν τους ", _interpolate(_list(0)), " χαρακτήρες"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " χαρακτήρες"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημερώθηκε από"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταβλητή"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίστε τις δικές σας αποφωνήσεις που ευθυγραμμίζονται με την επωνυμία και τα πρότυπα εξυπηρέτησής σας. Όταν προστεθούν, ο Αυτόματος QA θα εντοπίζει μόνο τις εγκεκριμένες αποφωνήσεις. Οι αλλαγές που γίνονται ισχύουν για μελλοντικές συζητήσεις."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε συμπληρώσει το ανώτατο όριο των 100 εγκεκριμένων αποφωνήσεων. Αν θέλετε να προσθέσετε περισσότερες, ίσως ήρθε η ώρα να επανεξετάσετε και να βελτιώσετε τις υπάρχουσες αποφωνήσεις σας."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όριο εγκεκριμένων αποφωνήσεων συμπληρώθηκε"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένες αποφωνήσεις"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λέξεις και φράσεις που θέλετε να παραβλέπει ο Αυτόματος QA ως ορθογραφικά ή γραμματικά λάθη. Οι αλλαγές που γίνονται ισχύουν για μελλοντικές συζητήσεις."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λίστα παράβλεψης"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίστε τους δικούς σας χαιρετισμούς που ευθυγραμμίζονται με την επωνυμία και τα πρότυπα εξυπηρέτησής σας. Όταν προστεθούν, ο Αυτόματος QA θα εντοπίζει μόνο τους εγκεκριμένους χαιρετισμούς. Οι αλλαγές που γίνονται ισχύουν για μελλοντικές συζητήσεις."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε συμπληρώσει το ανώτατο όριο των 100 εγκεκριμένων χαιρετισμών. Αν θέλετε να προσθέσετε περισσότερους, ίσως ήρθε η ώρα να επανεξετάσετε και να βελτιώσετε τους υπάρχοντες χαιρετισμούς σας."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όριο εγκεκριμένων χαιρετισμών συμπληρώθηκε"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένοι χαιρετισμοί"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όχι τώρα"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κατηγορίας"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οριστική διαγραφή αυτής της κατηγορίας αξιολόγησης;"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή λίστας κατηγοριών"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραμμένη κατηγορία δεν θα εμφανίζεται σε νέες συζητήσεις και φίλτρα. Τα δεδομένα ιστορικού στους πίνακες ελέγχου και τις συζητήσεις παραμένουν ανέπαφα."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία θα αφαιρεθεί από όλους τους πίνακες στοχοθεσίας στους οποίους έχει προστεθεί."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτυχία διαγραφής κατηγορίας πίνακα στοχοθεσίας"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία πίνακα στοχοθεσίας διαγράφηκε"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κατηγορίας πίνακα στοχοθεσίας"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κατηγορίες Αυτόματου QA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Οι ", _interpolate(_named("highlight")), " που υποστηρίζονται από το μοντέλο τεχνητής νοημοσύνης θα παραμείνουν στην κάρτα βαθμολογίας, αλλά δεν θα βαθμολογούνται πλέον αυτόματα"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η απενεργοποίηση της ενοποίησης LLM δεν απενεργοποιεί εντελώς τη λειτουργία Αυτόματος QA. Να τι θα συμβεί:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεδομένα ιστορικού"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τα ", _interpolate(_named("highlight")), " θα παραμείνουν στον πίνακα ελέγχου του Αυτόματου QA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["βασικές αιτίες που προστέθηκαν αυτόματα"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Οι ", _interpolate(_named("highlight")), " θα παραμείνουν, αλλά όλες οι βασικές αιτίες που βασίζονται σε LLM θα αφαιρεθούν"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποίηση Αυτόματου QA που βασίζεται σε LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποίηση"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο Αυτόματος QA χρησιμοποιεί τεχνητή νοημοσύνη και μεγάλα γλωσσικά μοντέλα (LLM) για την αυτόματη βαθμολόγηση συγκεκριμένων κατηγοριών. Τα δεδομένα σας υποβάλλονται σε επεξεργασία βάσει μιας αυστηρής Συμφωνίας επεξεργασίας δεδομένων (DPA) και δεν χρησιμοποιούνται ποτέ για την εκπαίδευση μοντέλων."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τη γενετική τεχνητή νοημοσύνη"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ο Αυτόματος QA χρησιμοποιεί την υπηρεσία Microsoft Azure OpenAI για την ", _interpolate(_named("automatically_scoring_categories")), ". Η επεξεργασία των δεδομένων σας διέπεται από αυστηρή DPA και πραγματοποιείται μόνο για την παροχή του Αυτόματου QA σε εσάς, ενώ τα δεδομένα σας ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αυτόματη βαθμολόγηση ορισμένων κατηγοριών"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν χρησιμοποιούνται ποτέ για την εκπαίδευση μοντέλων OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο Αυτόματος QA που βασίζεται σε LLM ενεργοποιήθηκε"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο Αυτόματος QA που βασίζεται σε LLM απενεργοποιήθηκε"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA που βασίζεται σε LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτοματοποιημένη βαθμολόγηση με τεχνητή νοημοσύνη στον Αυτόματο QA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία δεδομένων των κατηγοριών Αυτόματου QA με βάση το MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συγκατάθεση σχετικά με το Microsoft Azure ανακλήθηκε"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δόθηκε συγκατάθεση σχετικά με το Microsoft Azure. Οι συζητήσεις θα λάβουν σύντομα αυτόματες αξιολογήσεις."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξακολουθείτε να μπορείτε να χρησιμοποιήσετε τον Αυτόματο QA, αλλά θα συμβούν τα εξής"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η απενεργοποίηση της υπηρεσίας MS Azure OpenAI δεν θα απενεργοποιήσει τον Αυτόματο QA ως λειτουργία."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να το κάνετε αυτό στην προβολή κατηγοριών της κάρτας βαθμολογίας."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι κατηγορίες Αυτόματου QA που παρέχονται από το μοντέλο θα παραμείνουν στην κάρτα βαθμολογίας, αλλά δεν θα βαθμολογούνται αυτόματα."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι βασικές αιτίες που έχετε προσθέσει μη αυτόματα θα διατηρηθούν. Όλες οι βασικές αιτίες που δημιουργήθηκαν από το μοντέλο θα αφαιρεθούν."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα δεδομένα ιστορικού παραμένουν στον πίνακα ελέγχου του Αυτόματου QA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποίηση επεξεργασίας δεδομένων από την υπηρεσία MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευρύτερο φάσμα κατηγοριών Αυτόματου QA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσωματωμένη υποστήριξη πολλών γλωσσών."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γρήγορη προσθήκη νέων κατηγοριών."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βελτιωμένη ακρίβεια αξιολόγησης. Το GPT-3.5 παρουσιάζει επίγνωση του πλαισίου σε ανθρώπινο επίπεδο."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επωφεληθείτε από τις τελευταίες εξελίξεις στην τεχνολογία τεχνητής νοημοσύνης."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τι θα εξασφαλίσετε"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποίηση επεξεργασίας δεδομένων από την υπηρεσία MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δόθηκε συγκατάθεση"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν δόθηκε συγκατάθεση"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι νέοι χρήστες ανατίθενται σε αυτόν τον χώρο εργασίας"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπιλεγμένος χώρος εργασίας"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρόνος σε 12-ωρο σύστημα (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρόνος σε 24-ωρο σύστημα (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μορφοποίηση ώρας"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δευ"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κυρ"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το προφίλ της εταιρείας ενημερώθηκε"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχή της εβδομάδας"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη ενοποίηση"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αναγνωριστικό κλειδιού πρόσβασης και το μυστικό κλειδί συνήθως βρίσκονται στην ενότητα \"Διαχείριση πρόσβασης\" > \"Χρήστες\""])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό κλειδιού πρόσβασης AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση αλλαγής μυστικού κλειδιού πρόσβασης"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή τιμής μυστικού κλειδιού πρόσβασης"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σύνδεση επιτρέπει τον καθορισμό μόνο μίας περιοχής, που συνήθως βρίσκεται στην ενότητα \"Τοποθεσία\""])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα μυστικό κλειδί"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μυστικό κλειδί πρόσβασης AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να βρείτε όλες τις σχετικές πληροφορίες στο Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνήθως βρίσκεται στις ρυθμίσεις παρουσίας Amazon Connect και στη σελίδα \"Αποθήκευση δεδομένων\""])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τοποθεσία αποθήκευσης ηχογραφήσεων κλήσεων"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα κατά την προσθήκη του Amazon Connect. Ελέγξτε αν έχουν συμπληρωθεί σωστά όλα τα πεδία και προσπαθήστε ξανά."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η τοποθεσία αποθήκευσης θα πρέπει να έχει τη μορφή \"κάδος/φάκελος\" τα οποία να διαχωρίζονται με κάθετο."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Για να προσθέσετε μια σύνδεση, επικοινωνήστε με την ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποστήριξη πελατών"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα δελτία σας στο Zendesk θα συγχρονιστούν αυτόματα. Μπορείτε να επιλέξετε την εξαίρεση επιλεγμένων δελτίων."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασία-Ειρηνικός (Τόκιο)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασία-Ειρηνικός (Σεούλ)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασία-Ειρηνικός (Οσάκα)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασία-Ειρηνικός (Μουμπάι)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασία-Ειρηνικός (Σίδνεϊ)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καναδάς (Κεντρικός)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευρώπη (Φρανκφούρτη)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευρώπη (Ζυρίχη)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευρώπη (Ιρλανδία)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευρώπη (Λονδίνο)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή διακομιστή"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέση Ανατολή (ΗΑΕ)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε περιοχή διακομιστή"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νότια Αμερική (Σάο Πάολο)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανατολικές ΗΠΑ (Βιρτζίνια)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανατολικές ΗΠΑ 2 (Οχάιο)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυτικές ΗΠΑ (Όρεγκον)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σύνδεση έχει ήδη προστεθεί"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη σύνδεσης"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγχρονισμός μόνο συζητήσεων που"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κανάλι ένα, κανάλι δύο"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το μοναδικό αναγνωριστικό αυτής της σύνδεσης είναι ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 μήνας"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 έτος"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 μήνες"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 μήνες"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ανενεργές συζητήσεις θα διαγραφούν μετά την επιλεγμένη περίοδο διατήρησης. Οι έλεγχοι που έχουν υποβληθεί παραμένουν ορατοί στις προβολές \"Δραστηριότητα\" και \"Πίνακας ελέγχου\"."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος διατήρησης"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε περίοδο διατήρησης"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημειώστε ότι αυτή η ενέργεια είναι μη αναστρέψιμη."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατανοώ ότι με αυτήν την ενέργεια θα αφαιρεθούν όλα τα δεδομένα που σχετίζονται με την υπηρεσία βοήθειας για αυτήν τη σύνδεση από το Zendesk QA, συμπεριλαμβανομένων των συζητήσεων, των πεδίων και των ετικετών."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι έλεγχοι που έχουν υποβληθεί δεν θα επηρεαστούν και θα παραμείνουν ορατοί σε συγκεκριμένες προβολές."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ομάδα ένα, ομάδα δύο"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιλαμβάνουν"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ενεργής ανάπτυξης"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η ενοποίηση βρίσκεται ακόμη σε φάση ", _interpolate(_named("activeDevelopment")), ". Μπορεί να λείπουν ορισμένες λεπτομέρειες (όπως συνημμένα ή εξατομικευμένα πεδία)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γραμματοκιβώτιο ένα, γραμματοκιβώτιο δύο"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ετικέτα ένα, ετικέτα δύο"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύμβολο API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μυστικό συμβόλου API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε τα \"Ονόματα πεδίων δελτίων\" στο Freshdesk στις Ρυθμίσεις → Πεδία δελτίων"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε τα \"Custom Field Names\" στο Help Scout στη διαδρομή Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε τα \"Ονόματα συζητήσεων Klass\" στο Kustomer στις Ρυθμίσεις → Klasses → Συζήτηση"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε το \"Όνομα εξατομικευμένου πεδίου\" στο Live Agent στις Ρυθμίσεις → Εξατομικευμένα πεδία"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε το \"Όνομα εξατομικευμένου πεδίου\" στο Wix στις Ρυθμίσεις → Εξατομικευμένα πεδία"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχωρίστε τα αναγνωριστικά πεδίων με κόμματα. Μπορείτε να βρείτε αυτά τα αναγνωριστικά στο Zendesk στη Διαχείριση → Πεδία δελτίων."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέστε μερικούς από τους χρήστες της υπηρεσίας βοήθειας για να ελέγξετε τις πρώτες σας συζητήσεις. Μην ανησυχείτε, δεν θα τους ειδοποιήσουμε, εκτός αν τους προσκαλέσετε να γίνουν μέλη του χώρου εργασίας."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχής ενοποίηση"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη μελών"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τώρα θα αρχίσουμε να ανακτούμε τις συζητήσεις σας."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Τομέας: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Αναγνωριστικό υπηρεσίας βοήθειας: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατός ο έλεγχος ταυτότητας της σύνδεσής σας"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε έτοιμοι. Αυτή είναι η νέα σας σύνδεση."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμένες συνδέσεις δεν έχουν ενημερωθεί ή έχουν καταστεί ανενεργές. Δοκιμάστε να ενημερώσετε αυτές τις συνδέσεις ή να τις αφαιρέσετε από τη λίστα σας."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό πελάτη API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μυστικό πελάτη API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δημιουργήστε ένα νέο σύμβολο API από τις Ρυθμίσεις → Λογαριασμός → API και SDK. Το URL ανακατεύθυνσης που πρέπει να χρησιμοποιηθεί είναι ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό λογαριασμού"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε την υπηρεσία βοήθειάς σας για να ξεκινήσετε"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν ο λογαριασμός σας στο Intercom έχει ρυθμιστεί να εξυπηρετείται από την ΕΕ ή την Αυστραλία, τότε ενημερώστε αυτήν τη ρύθμιση."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το email που χρησιμοποιείτε για να πραγματοποιήσετε είσοδο στο ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλύψτε το όνομα, το email και τον αριθμό τηλεφώνου του τελικού χρήστη. Τα τραπεζικά διαπιστευτήρια είναι ήδη καλυμμένα για όλες τις συνδέσεις της υπηρεσίας βοήθειας."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ενοποιήσεις ακόμη. Προσθέστε μία τώρα."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρειάζεστε αυτούς τους ρόλους:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργή"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία σύνδεσης"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε μια νέα σύνδεση"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατάργηση αυτής της επιλογής σάς επιτρέπει να προσθέσετε μη αυτόματα αυτήν τη σύνδεση μόνο σε συγκεκριμένους χώρους εργασίας"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση με όλους τους υπάρχοντες χώρους εργασίας"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χώροι εργασίας"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλειδί API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μυστικό κλειδιού API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλια"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η σύνδεση ", _interpolate(_list(0)), " διαγράφηκε"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα σύνδεσης"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήθηκε στις"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να λάβετε το σύμβολο API σας παρακάτω."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όριο CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή σύνδεσης ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε \"διαγραφή σύνδεσης\" για να αφαιρέσετε αυτήν τη σύνδεση"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή σύνδεσης"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχειριστείτε και παρακολουθήστε τις συνδεδεμένες πηγές δεδομένων σας."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία σύνδεσης"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχωρίστε τα ονόματα των πεδίων με κόμματα"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ενημέρωση απέτυχε"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε ένα μοναδικό όνομα στην ενοποίησή σας"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη προηγμένων επιλογών"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη πεδίων ευαίσθητων δεδομένων"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να μην αποθηκεύονται ποτέ URL συνημμένων"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράβλεψη συνημμένων"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να μην αποθηκεύεται ποτέ το περιεχόμενο των συζητήσεων (μπορείτε να το ανακτήσετε αργότερα κατ' απαίτηση από την υπηρεσία βοήθειας)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράβλεψη περιεχομένου συζητήσεων"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να μην αποθηκεύεται ποτέ το θέμα των συζητήσεων (μπορείτε να το ανακτήσετε αργότερα κατ' απαίτηση από την υπηρεσία βοήθειας)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράβλεψη γραμμής θέματος"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανενεργή"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ο υποτομέας του ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιστοίχιση αναγνωριστικού ομάδας εργασίας εισερχομένων"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμητικό αναγνωριστικό της ομάδας εργασίας σας στο Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εξέλιξη..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραμματοκιβώτια"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη αυτόματη ενημέρωση σύνδεσης"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάλυψη δεδομένων πελατών"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν χώροι εργασίας"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα ευαίσθητου πεδίου"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό ευαίσθητου πεδίου"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανανέωση σύνδεσης"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανανέωση σύνδεσης"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση στο περιβάλλον δοκιμής Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ρύθμιση σύνδεσης με ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση προηγμένων επιλογών"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανακτήστε μόνο συζητήσεις με βαθμολογία χαμηλότερη ή ίση με το καθορισμένο όριο"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμόστε ένα όριο CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγχρονισμός μόνο συζητήσεων που..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε τις τελευταίες συζητήσεις"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσεις"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση σύνδεσης"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σύνδεση ενημερώθηκε"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα χρήστη"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η υπηρεσία βοήθειάς σας"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Για να ακυρώσετε τη συνδρομή σας και να διαγράψετε τον λογαριασμό σας, επικοινωνήστε με την ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποστήριξη πελατών"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή λογαριασμού"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραφή ενός δελτίου στο Zendesk Support (ή σε άλλη υπηρεσία βοήθειας) δεν επηρεάζει τους σχετικούς ελέγχους συζητήσεων. Μπορείτε ακόμη να τους προβάλετε και να τους διαχειριστείτε."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Με τη διαγραφή ενός δελτίου στο Zendesk QA διαγράφονται και οι σχετικοί έλεγχοι συζητήσεων.", _interpolate(_named("line_break")), "Η διαγραφή ενός δελτίου στο Zendesk Support (ή σε άλλη υπηρεσία βοήθειας) δεν επηρεάζει τους σχετικούς ελέγχους συζητήσεων. Μπορείτε ακόμη να τους προβάλετε και να τους διαχειριστείτε."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση ελέγχων"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραφή ελέγχων είναι μόνιμη. Επηρεάζει επίσης τα δεδομένα στους πίνακες ελέγχου."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων των ελέγχων"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι έλεγχοι διαγράφηκαν"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν υπάρχουν έλεγχοι που σχετίζονται με διαγραμμένα δελτία, θα τους βλέπετε εδώ."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν έλεγχοι ακόμη"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση αναγνωριστικού δελτίου"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αναγνωριστικό δελτίου: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραμμένες συζητήσεις"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δεν είναι δυνατή η διαγραφή των προεπιλεγμένων χώρων εργασίας. Για να διαγράψετε αυτόν τον χώρο εργασίας, επιλέξτε έναν άλλο προεπιλεγμένο χώρο εργασίας στις ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις λογαριασμού"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αφαιρεθούν και διαγραφούν όλα τα δεδομένα"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κατανοώ ότι με αυτήν την ενέργεια θα ", _interpolate(_named("removeAllData")), " που σχετίζονται με αυτόν τον χώρο εργασίας, συμπεριλαμβανομένων των χρηστών και των συνδέσεων του χώρου εργασίας."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή χώρου εργασίας"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε \"διαγραφή χώρου εργασίας\" για να διαγράψετε αυτόν τον χώρο εργασίας"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή χώρου εργασίας ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαγραφή χώρου εργασίας"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αν δεν είστε σίγουροι για αυτό που κάνετε, συμβουλευτείτε προηγουμένως έναν επαγγελματία."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε αν ο προεπιλεγμένος ελεγχόμενος επιλέγεται με βάση τον πιο συχνό εκπρόσωπο ή τον τρέχοντα εντολοδόχο."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πιο ενεργός εκπρόσωπος"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπιλεγμένος ελεγχόμενος"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε τη δυνατότητα στους χρήστες να ελέγχουν τις δικές τους συζητήσεις και επιτρέψτε στους εκπροσώπους να πραγματοποιούν ελέγχους"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο χώρος εργασίας διαγράφηκε"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο χώρος εργασίας ενημερώθηκε"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξασφαλίστε ουδέτερη βαθμολόγηση με απόκρυψη των ελέγχων που έχουν δοθεί προηγουμένως από άλλους ελεγκτές"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αμερόληπτη βαθμολόγηση"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρώμα"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημειώστε ότι με αυτήν την ενέργεια θα αφαιρεθούν και διαγραφούν όλα τα δεδομένα που σχετίζονται με αυτόν τον χώρο εργασίας, συμπεριλαμβανομένων των χρηστών και των συνδέσεων του χώρου εργασίας."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή χώρου εργασίας"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή χώρου εργασίας..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτοαξιολογήσεις"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαγικός σύνδεσμος"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαγικός σύνδεσμος"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να απενεργοποιήσετε την τρέχουσα μέθοδο εισόδου"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι μέθοδοι ελέγχου ταυτότητας ενημερώθηκαν"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτρέψτε ορισμένους τύπους μεθόδων ελέγχου ταυτότητας για όλους τους χρήστες."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι ταυτότητας"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΛΟΓΑΡΙΑΣΜΟΣ"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες και χώροι εργασίας"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε και διαχειριστείτε χώρους εργασίας, και προσκαλέστε ή συνδέστε χρήστες."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παραμονή στη σελίδα"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποχώρηση από τη σελίδα"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέλετε να αποχωρήσετε από αυτήν τη σελίδα και να χάσετε όλες τις αλλαγές;"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσεις"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι ταυτότητας"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομή"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ότι δεν υπάρχουν συζητήσεις προς εισαγωγή. Ξεκινήστε κάνοντας κάποιες συζητήσεις με την υπηρεσία βοήθειας και, στη συνέχεια, επιστρέψτε εδώ για να τις αναλύσετε."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν συζητήσεις"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να διαγράψετε αυτήν την κατηγορία συστήματος"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κατηγορίας"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραφή κατηγοριών είναι οριστική. Θα διαγράψει όλα τα δεδομένα κατηγορίας από τη δημιουργία αναφορών."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να ανακτήσετε αυτή την κατηγορία, θα πρέπει να τη δημιουργήσετε ξανά."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κατηγορίας"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση κατηγορίας"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτόματες κατηγορίες συμπληρώνουν αυτόματα την κάρτα βαθμολογίας με προτεινόμενες αξιολογήσεις για την εξοικονόμηση χρόνου κατά τον έλεγχο. Οι μη αυτόματες κατηγορίες συμπληρώνονται από τους ελεγκτές από την αρχή."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία ενημέρωση"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματη"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη αυτόματη"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποφώνηση"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσυναίσθηση"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορθογραφία και γραμματική"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαιρετισμός"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατανόηση"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωσιμότητα"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτεινόμενη λύση"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τόνος"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κατηγορίες"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν βασικές αιτίες"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κάρτες βαθμολογίας"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε μια διαφορετική αναζήτηση ή αλλάξτε τα φίλτρα"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κατηγορίες"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κατηγορίες"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κατηγορίες"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι κατηγορίες"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κατηγορίες"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κάρτες βαθμολογίας"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κάρτες βαθμολογίας"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κάρτες βαθμολογίας"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι κάρτες βαθμολογίας"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " κάρτες βαθμολογίας"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " καταστάσεις"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " καταστάσεις"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " καταστάσεις"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι καταστάσεις"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " καταστάσεις"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " τύποι"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " τύποι"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " τύποι"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι τύποι"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " τύποι"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " χώροι εργασίας"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " χώροι εργασίας"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν χώροι εργασίας"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " χώροι εργασίας"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χώροι εργασίας"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " χώροι εργασίας"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η βαρύτητα του ", _interpolate(_named("weight")), " συνεισφέρει ", _interpolate(_named("percentage")), "% στη συνολική βαθμολογία"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κάρτας βαθμολογίας"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραφή των καρτών βαθμολογίας είναι οριστική. Θα διαγράψει όλα τα δεδομένα της κάρτας βαθμολογίας από τη δημιουργία αναφορών."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να ανακτήσετε αυτή την κάρτα βαθμολογίας, θα πρέπει να τη δημιουργήσετε ξανά."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κάρτας βαθμολογίας"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία ενημέρωση"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργή"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρη"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανενεργή"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασικές αιτίες"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η κλίμακα αξιολόγησης ενημερώθηκε. ", _interpolate(_named("buttonStart")), "Έναρξη ελέγχου", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA ορίζει μια δυαδική κλίμακα από προεπιλογή, αλλά μπορείτε επίσης να επιλέξετε μια πιο αναλυτική κλίμακα:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αν θέλετε να βλέπετε διαφορετικές επιλογές κλίμακας αξιολόγησης, διαμορφωμένες κατά κατηγορία, μεταβείτε στις ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσυναίσθηση/Τόνος"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γνώση του προϊόντος"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την ενημέρωση των κλιμάκων αξιολόγησης:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η προηγούμενη κλίμακα αξιολόγησης και οι αντίστοιχες κατηγορίες θα αρχειοθετηθούν (δεν θα διαγραφούν)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμένα δεδομένα του πίνακα ελέγχου θα επηρεαστούν, καθώς η παλιά και η νέα κλίμακα μπορεί να μην είναι συγκρίσιμες."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλωνοποίηση της υφιστάμενης ρύθμισης των κατηγοριών αξιολόγησης στη νέα κλίμακα"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι ότι θέλετε να ενημερώσετε τις κλίμακες αξιολόγησης;"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να αλλάξετε τη μορφή εμφάνισης της κάρτας βαθμολογίας (αριθμοί ή emoji) και να κάνετε τις κατηγορίες υποχρεωτικές ή προαιρετικές."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήση emoji αντί για αριθμούς"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να επιτρέπεται στους χρήστες να παραλείπουν κατηγορίες"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λύση"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος κλίμακας αξιολόγησης"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Οι κατηγορίες για 1 χώρο εργασίας κλωνοποιήθηκαν"]), _normalize(["Οι κατηγορίες για ", _interpolate(_named("workspacesAmount")), " χώρους εργασίας κλωνοποιήθηκαν"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις χώρου εργασίας"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εάν ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστε αυτόματα τις κλήσεις όπου ο εκπρόσωπος δεν δίνει προτροπές στον πελάτη για να περιμένει μια σιωπή στη συζήτηση."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση έχει στιγμές παύσης που διαρκούν περισσότερο από το καθορισμένο όριο."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίστε το μέγιστο όριο παύσεων σε κλήσεις"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραμμένη πληροφορία δεν θα εμφανίζεται σε νέες συζητήσεις και φίλτρα. Τα δεδομένα ιστορικού στους πίνακες ελέγχου και τις συζητήσεις παραμένουν ανέπαφα."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραφή της πληροφορίας \"Σημαντικά\" απέτυχε"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πληροφορία \"Σημαντικά\" διαγράφηκε"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή πληροφορίας \"Σημαντικά\""])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή συνομιλιών που περιέχουν συγκεκριμένες λέξεις ή φράσεις σε μεταγραφές και μηνύματα."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστε αυτόματα τις κλήσεις που δεν περιλαμβάνουν τη δήλωση γνωστοποίησης, όπως \"Αυτή η κλήση θα καταγραφεί\" και παρόμοιες φράσεις."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος είναι ο εντοπισμός μιας δήλωσης κοινοποίησης καταγραφής στην κλήση με τη χρήση ενός Large Language Model (LLM) που κατανοεί δυναμικά το πλαίσιο, καταργώντας την ανάγκη για προκαθορισμένες φράσεις."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπει η γνωστοποίηση καταγραφής"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίστε αυτόματα τις κλήσεις όπου ο εκπρόσωπος δεν δίνει προτροπές στον πελάτη για να περιμένει μια σιωπή στη συζήτηση."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίστε τις στιγμές παύσης σε μια κλήση"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Θέλετε να κάνετε αλλαγές στη συνδρομή σας; ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") και θα χαρούμε να σας βοηθήσουμε."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύστε αυτόματα το 100% των συζητήσεων για το σύνολο των εκπροσώπων, των BPO, των καναλιών και των γλωσσών. Η τεχνητή νοημοσύνη εντοπίζει προβλήματα, κενά γνώσης και ευκαιρίες καθοδήγησης για τη βελτίωση της εξυπηρέτησης."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% κάλυψη Αυτόματου QA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις και καθοδήγηση εκπροσώπων"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες τεχνητής νοημοσύνης \"Σημαντικά\""])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνθετη δημιουργία αναφορών και πίνακες ελέγχου"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάλυση και φιλτράρισμα συναισθήματος"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφράσεις κατ' απαίτηση"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις ελέγχων και καθορισμός στόχων"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθοδήγηση και κουίζ"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιοτικός έλεγχος Zendesk (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα όσα περιλαμβάνει το Professional, συν"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (παλαιά έκδοση)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook για άμεση ειδοποίηση σχετικά με κρίσιμες αποτυχίες"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποίηση αποθήκης δεδομένων"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποίηση του Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάπτυξη εξατομικευμένης ενοποίησης"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκλειστικός υπεύθυνος επιτυχίας πελατών"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άμεση πρόσβαση στις ομάδες προϊόντος και engineering μας μέσω του Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα όσα περιλαμβάνει το Growth Suite, συν"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite Τεχνητής Νοημοσύνης"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA για 100% κάλυψη"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες συζήτησης με τεχνητή νοημοσύνη για την ερμηνεία των δεδομένων"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμπλουτισμός και φιλτράρισμα δεδομένων υπηρεσίας βοήθειας με τεχνητή νοημοσύνη"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημαντικά: Αυτοματοποιημένος εντοπισμός για αλληλεπιδράσεις που πρέπει να ελεγχθούν"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάλυση και φιλτράρισμα συναισθήματος"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορές και πληροφορίες σχετικά με την απόδοση με τεχνητή νοημοσύνη"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάφραση συζήτησης κατ' απαίτηση"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολούθηση χρόνου ελέγχων"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλυτικά στοιχεία ποσοστού επιτυχίας"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα όσα περιλαμβάνει το AI Suite, συν"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πακέτο εταιρικής υλοποίησης, συμπεριλαμβανομένης της ενοποίησης του Salesforce και του Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάπτυξη εξατομικευμένης ενοποίησης"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποίηση αποθήκης δεδομένων"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook για άμεση ειδοποίηση σχετικά με κρίσιμες αποτυχίες"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκλειστικός υπεύθυνος επιτυχίας πελατών"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άμεση πρόσβαση στις ομάδες προϊόντος και engineering μας"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώιμη πρόσβαση και επίδραση στον οδικό χάρτη μας"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματη ανάθεση ελέγχων και καθορισμός στόχων"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοχευμένη καθοδήγηση και αναλυτικά στοιχεία"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ για διαχείριση της μάθησης"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση ρόλων και δικαιωμάτων χρηστών"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάλυψη ευαίσθητων δεδομένων"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγμένες επιλογές φιλτραρίσματος και αναζήτηση συζητήσεων"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικεύσιμες κάρτες βαθμολογίας με πολλαπλές αξιολογήσεις, προσαρμόσιμα βάρη και επιλογές αυτόματης αποτυχίας"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επέκταση προγράμματος περιήγησης για έλεγχο συζητήσεων οπουδήποτε"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάλυση βασικών αιτιών"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορές απόδοσης για σύγκριση εκπροσώπων, ομάδων εργασίας και BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνα CSAT και αναλυτικά στοιχεία παρατηρήσεων πελατών"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυνατότητες"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα όσα περιλαμβάνει το Starter, συν"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (παλαιά έκδοση)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολλές κλίμακες αξιολόγησης ανά συζήτηση"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποίηση Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαδραστικές Πληροφορίες συζήτησης με τεχνητή νοημοσύνη"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις ελέγχων"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καρφίτσες για καθοδήγηση"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρίες καθοδήγησης"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάλυση και φιλτράρισμα συναισθήματος"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φιλτράρισμα με βάση την πολυπλοκότητα της συζήτησης"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες συζήτησης"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας υπό όρους"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάλυση βασικών αιτιών"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγμένα αναλυτικά στοιχεία CSAT με τεχνητή νοημοσύνη"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση βαθμολόγησης"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επέκταση προγράμματος περιήγησης για έλεγχο συζητήσεων οπουδήποτε"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λειτουργία \"Σημαντικά\" για τον εντοπισμό συζητήσεων που πρέπει να ελεγχθούν"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάφραση συζήτησης κατ' απαίτηση"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολούθηση χρόνου ελέγχων"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένο πρόγραμμα"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (παλαιά έκδοση)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απεριόριστοι έλεγχοι συζητήσεων"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαγωγή CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήσεις από ομολόγους και αυτοαξιολογήσεις"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας με προσαρμόσιμα βάρη και επιλογές αυτόματης αποτυχίας"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτελεσματικές επιλογές φιλτραρίσματος"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερήσιοι, εβδομαδιαίοι ή μηνιαίοι στόχοι ελέγχων"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες CSAT και βασικά στοιχεία ανάλυσης"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακες ελέγχου"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορές για σύγκριση εκπροσώπων, ομάδων εργασίας και BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση ρόλων και προνομίων χρηστών"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυνατότητες"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέλω να κάνω μια αλλαγή στη συνδρομή μου στο Zendesk QA.\n\nΕπισημάνετε ή υποδείξτε μία επιλογή και υποδείξτε τον αριθμό των αδειών που θέλετε να αλλάξετε:\nΘα ήθελα να [προσθέσω X άδειες] [αφαιρέσω X άδειες] [κάνω αναβάθμιση στο πρόγραμμα Zendesk QA]\n\nΠείτε μας το όνομα της εταιρείας σας και το όνομα που σχετίζεται με τον λογαριασμό σας στο Zendesk QA, καθώς και τυχόν άλλες λεπτομέρειες που πρέπει να γνωρίζουμε για να διεκπεραιώσουμε αυτήν την αλλαγή."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή συνδρομής Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέλω να αγοράσω μια συνδρομή Zendesk QA.\n\nΠείτε μας το όνομα της εταιρείας σας και το όνομα που σχετίζεται με τον λογαριασμό σας στο Zendesk QA, καθώς και τυχόν άλλες λεπτομέρειες που πρέπει να γνωρίζουμε για να διεκπεραιώσουμε αυτό το αίτημα:\n\nΓράψτε επίσης τυχόν συγκεκριμένες ερωτήσεις που θέλετε να απαντηθούν πριν από την αγορά:\n\nΕυχαριστώ!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά συνδρομής Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η συνδρομή σας λήγει στις ", _interpolate(_named("date")), " (απομένουν ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας λήγει στις ", _interpolate(_named("date")), " (απομένουν ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ημέρα"]), _normalize([_interpolate(_named("n")), " ημέρες"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("dayOfMonth")), " κάθε μήνα."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("dayOfMonth")), " κάθε μήνα."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("dayOfMonth")), " κάθε μήνα."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("dayOfMonth")), " κάθε μήνα."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("dayOfMonth")), " κάθε μήνα."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("dayOfMonth")), " κάθε μήνα."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Χρεώνεται στην κάρτα ", _interpolate(_named("card")), " (λήγει τον ", _interpolate(_named("expires")), ") στις ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["που λήγει σε"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Έληξε"]), _normalize(["Λήγει σε 1 ημέρα"]), _normalize(["Λήγει σε ", _interpolate(_named("expiresIn")), " ημέρες"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η τιμή εκκίνησης περιλαμβάνει ", _interpolate(_named("n")), " άδειες χρήσης"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["από ", _interpolate(_named("sum")), "/μήνα"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μήνα"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανά χρήστη / μήνα"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανά έτος"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανά μήνα"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τρίμηνο"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καταργήστε την προγραμματισμένη ακύρωση"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αν θέλετε να συνεχίσετε να χρησιμοποιείτε το Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η συνδρομή σας ακυρώθηκε στις ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η συνδρομή σας έχει προγραμματιστεί να λήξει στις ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δοκιμαστική έκδοση ", _interpolate(_named("days")), " ημερών χωρίς επιπλέον κόστος!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χρησιμοποιήθηκαν"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχετε ", _interpolate(_named("n")), " χρήστες. Ο αριθμός των αδειών δεν μπορεί να είναι μικρότερος από αυτό."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η συνδρομή θα συνεχιστεί στις ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έτος"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Το πρόγραμμα Zendesk QA (", _interpolate(_named("plan")), ") που διαθέτετε αυτήν τη στιγμή δεν περιλαμβάνει δυνατότητες τεχνητής νοημοσύνης. Για να εξασφαλίστε την πλήρη ισχύ των δυνατοτήτων φωνητικής νοημοσύνης, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Για να ακυρώσετε τη συνδρομή σας, επικοινωνήστε μαζί μας ", _interpolate(_named("link")), " ή συνομιλήστε μαζί μας."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόγραμμα διαχείρισης ποιότητας"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση ποιότητας"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνδρομή ενημερώθηκε"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε μια πιστωτική κάρτα"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα προγράμματα"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμολόγηση"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε πληροφορίες τιμολόγησης"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διάστημα τιμολόγησης"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση συνδρομής"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνδρομή σας θα ακυρωθεί στο τέλος της περιόδου τιμολόγησης."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνία"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνήστε μαζί μας για επεξεργασία"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχον πρόγραμμα"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη τιμολόγηση"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάθμιση"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια (εξοικονόμηση 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια πληρωμή"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία συνδρομής"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξερευνήστε τα προγράμματα"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δωρεάν δοκιμαστική έκδοση"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμολογείται μη αυτόματα"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να ελέγξετε και να επιβεβαιώσετε την τελική σύνοψη της παραγγελίας στο επόμενο βήμα."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πιο δημοφιλές"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε ενεργή μέθοδο πληρωμής."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπό δοκιμή"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε την τρέχουσα συνδρομή σας για να αλλάξετε το πρόγραμμα"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση συνδρομής"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τριμηνιαία"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμή"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επανενεργοποίηση συνδρομής"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχιση συνδρομής"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άδειες"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη δοκιμαστικής έκδοσης τώρα"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργή"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακυρώθηκε"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ανανεώνεται"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε παύση"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση συνδρομής"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμαστική έκδοση"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβάθμιση"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβάθμιση συνδρομής"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένο"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε αφιερώστε ένα λεπτό για να δώσετε τις παρατηρήσεις σας σχετικά με την εμπειρία σας όσον αφορά την υποστήριξη πελατών. Ευχαριστούμε εκ των προτέρων – Ο εκπρόσωπος ", "{", "{", "agentName", "}", "}", " εκτιμά τις παρατηρήσεις σας!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γεια σας, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Έρευνα CSAT του χρήστη ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώς θα αξιολογούσατε την υποστήριξη που λάβατε από τον εκπρόσωπο ", "{", "{", "agentName", "}", "}", ";"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το εκτιμούμε βαθύτατα. Θα χρησιμοποιήσουμε τις παρατηρήσεις σας για να κάνουμε το ", _interpolate(_list(0)), " καλύτερο για όλους."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τις έρευνες"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τον τύπο της έρευνας"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό μπορεί να αλλάξει αργότερα."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετρήστε και βελτιώστε την ικανοποίηση πελατών με αυτοματοποιημένες έρευνες CSAT σε πολλές γλώσσες."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί έρευνες"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία έρευνας"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα έρευνας"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η έρευνα δημιουργήθηκε"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δοκιμάστε να αλλάξετε τη λέξη-κλειδί ή το ", _interpolate(_named("contact_us")), " αν θέλετε να τη δείτε να εμφανίζεται εδώ"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ενημερώστε μας"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε γλώσσα"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επιλέξτε μία ή περισσότερες γλώσσες από την παρακάτω λίστα. ", _interpolate(_named("contact_us")), " εάν δεν βλέπετε εδώ μια γλώσσα που θέλετε να χρησιμοποιήσετε."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη γλωσσών"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημερώστε μας"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη γλώσσας"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Τελευταία δημοσίευση ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Τελευταία αποθήκευση ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ερώτηση ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγική ερώτηση"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριστερή ετικέτα κλίμακας"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέμα email"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγοι"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεξιά ετικέτα κλίμακας"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστούμε"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν σε μια έρευνα λείπουν μεταφράσεις, θα εμφανίζονται τα κείμενα της βασικής γλώσσας"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή μεταφράσεων"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπουν μεταφράσεις"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Βεβαιωθείτε ότι έχετε ελέγξει όλες τις μεταφράσεις σας πριν τις εφαρμόσετε. Κάντε αλλαγές στο αρχείο που λάβατε, αποθηκεύστε το και αποστείλετέ το ξανά, αν χρειάζεται. Εάν ένα σύμβολο ", _interpolate(_named("token_example")), " δεν εμφανίζεται ως ετικέτα, είτε έχει λανθασμένη μορφή είτε βρίσκεται σε ένα πεδίο που δεν υποστηρίζει σύμβολα."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος μεταφράσεων"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφρασμένο"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνοψίζει τη συζήτηση και εμφανίζει προτάσεις για σχόλια."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI assist (στα Αγγλικά)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να επεξεργαστείτε τους τύπους αφού δημιουργηθούν"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτός ο τύπος υπάρχει ήδη. Επιλέξτε ένα διαφορετικό όνομα."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθηκεύστε τον εξατομικευμένο τύπο ως επιλέξιμη επιλογή στο αναπτυσσόμενο μενού \"Τύπος έρευνας\" και ομαδοποιήστε όλες τις έρευνες που δημιουργήθηκαν στον πίνακα ελέγχου."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε λέξεις και πατήστε Enter για αποθήκευση"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία εξατομικευμένων τύπων"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όνομα τύπου δεν μπορεί να είναι κενό"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορισμός τύπου"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εξατομικευμένος τύπος δεν αποθηκεύτηκε. Πατήστε Enter για να τον αποθηκεύσετε."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email αποστολέα"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα αποστολέα"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Σε ποιον βαθμό συμφωνείτε με τη δήλωση: \"Ο εκπρόσωπος ", _interpolate(_named("agentName")), " διευκόλυνε τον χειρισμό του προβλήματός μου\";"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Πόσο εύκολο ήταν να λάβετε βοήθεια από το ", _interpolate(_named("brandName")), " μέχρι στιγμής;"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικά, πόσο εύκολο ήταν να λύσετε το πρόβλημά σας σήμερα;"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πόσο εύκολο ήταν να αλληλεπιδράσετε με την ομάδα μας;"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Πολιτικής απορρήτου (προαιρετικά)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL όρων χρήσης (προαιρετικά)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Πώς θα αξιολογούσατε την υποστήριξη που λάβατε από τον εκπρόσωπο ", _interpolate(_named("agentName")), ";"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώς ήταν η εξυπηρέτησή μας για αυτό το αίτημα;"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πόσο ικανοποιημένοι μείνατε από τον εκπρόσωπο υποστήριξης;"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Πόσο ικανοποιημένοι είστε από την εξυπηρέτηση που λάβατε από τον εκπρόσωπο ", _interpolate(_named("agentName")), " σήμερα;"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη ερώτηση"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Καθορίστε τη δική σας εξατομικευμένη ερώτηση ", _interpolate(_named("type")), "..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε την εξατομικευμένη ερώτηση"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομίκευση των URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε το μήνυμα email σας"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την επεξεργασία του ονόματος ενός εξατομικευμένου τύπου ερώτησης θα ενημερωθεί σε όλες τις έρευνες που το χρησιμοποιούν, χωρίς να επηρεαστούν τα δεδομένα που συλλέγονται."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία εξατομικευμένων τύπων"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέμα email"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγική ερώτηση"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ερώτηση ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία νέου εξατομικευμένου τύπου"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιεχόμενο"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Σύμβολο εξατομίκευσης"]), _normalize(["Σύμβολα εξατομίκευσης"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Σύρετε και αποθέστε το σύμβολο εξατομίκευσης στο παρακάτω πεδίο."]), _normalize(["Σύρετε και αποθέστε τα σύμβολα εξατομίκευσης στα παρακάτω πεδία."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Συζήτηση ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προς το παρόν είναι δυνατή μόνο η αποστολή ερευνών CSAT μέσω email. Εργαζόμαστε για να σας προσφέρουμε περισσότερες ευκαιρίες, όπως έρευνες CSAT υπηρεσίας βοήθειας, έρευνες SMS και άλλα."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Συγχρονίστε ή ανανεώστε τη σύνδεση"]), _normalize(["Συγχρονίστε ή ανανεώστε τις συνδέσεις"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγχρονίστε ή ανανεώστε τη σύνδεση ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Η σύνδεση δεν έχει συγχρονιστεί σωστά"]), _normalize(["Οι συνδέσεις δεν έχουν συγχρονιστεί σωστά."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καταργήστε την επιλογή του σχετικού πλαισίου ελέγχου ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος ταυτότητας τώρα ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε τουλάχιστον έναν κανόνα αποστολής έρευνας για να ενεργοποιήσετε τη σύνδεση"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η σύνδεση είναι απενεργοποιημένη αυτήν τη στιγμή. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η σύνδεση καλύπτει τα ευαίσθητα δεδομένα πελατών. Για να ενεργοποιήσετε την αποστολή ερευνών,\n", _interpolate(_named("link")), " στις ρυθμίσεις."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η σύνδεση απαιτεί πρόσθετα δικαιώματα για την αποστολή ερευνών.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος ταυτότητας τώρα"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA απαιτεί περισσότερα δικαιώματα για το Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " και ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ώρα"]), _normalize([_interpolate(_named("n")), " ώρες"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " λεπτά"]), _normalize(["1 λεπτό"]), _normalize([_interpolate(_named("n")), " λεπτά"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ώρα"]), _normalize(["ώρες"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " για να διαμορφώσετε τους κανόνες αποστολής ερευνών."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" ημέρα"]), _normalize(["ημέρες"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι σύνδεσμοι ερευνών λήγουν"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι σύνδεσμοι λήγουν σε"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτρέψτε την απάντηση σε έρευνες εάν έχει περάσει πολύς χρόνος"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["και"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["έρευνα ", _interpolate(_named("name"))])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχωρίστε τις ετικέτες με κόμματα ή πατήστε Enter μετά από καθεμία. Βεβαιωθείτε ότι κάθε ετικέτα αναφέρεται ακριβώς όπως στο Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε τις συνθήκες με βάση τις οποίες αποστέλλεται η έρευνα."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανόνες αποστολής έρευνας"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανόνες αποστολής συμπληρωματικού email έρευνας"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Υπάρχει ήδη μια έρευνα ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nΕπιλύστε τη σύγκρουση τροποποιώντας τους κανόνες είτε εδώ είτε στην ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Έχετε δημιουργήσει ήδη μια σύνδεση με αντίστοιχους κανόνες για την έρευνα ", _interpolate(_named("name")), ". Επιλύστε τη σύγκρουση τροποποιώντας τους κανόνες είτε εδώ είτε στην ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε άλλο κανόνα"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε επωνυμία"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε συνθήκη"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε μια τιμή"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη ετικέτας"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["που αντιστοιχεί στους ίδιους κανόνες"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια τιμή"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή email σε"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή συμπληρωματικού email σε"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσωματωμένο απόσπασμα κώδικα και συμπληρωματικό email"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή κώδικα"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισυνάψτε έρευνες του Zendesk QA στα email σας προσθέτοντας το απόσπασμα κώδικα στη ρύθμιση του Zendesk σας."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οδηγός εγκατάστασης"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άνοιγμα του Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπισκόπηση"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπισκόπηση αποσπάσματος κώδικα"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το απόσπασμα κώδικα αντιγράφηκε στο πρόχειρο"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αντιγραφή του αποσπάσματος κώδικα απέτυχε"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσωματωμένο απόσπασμα κώδικα"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η έρευνα έχει μη αποθηκευμένες αλλαγές. Αποθηκεύστε την ξανά για να διασφαλίσετε ότι το απόσπασμα κώδικα αντικατοπτρίζει τις τελευταίες ενημερώσεις."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σύνδεσης"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί συνδέσεις, ακόμη"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανακτήστε email, ζωντανή συνομιλία, τηλεφωνικές κλήσεις και άλλα στο Zendesk QA με τις συνδέσεις σας"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για την παράδοση ερευνών"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανόνες αποστολής έρευνας"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανόνες αποστολής συμπληρωματικού email έρευνας"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη κανόνα επωνυμίας"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη κανόνα"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος ταυτότητας"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρωματική επικοινωνία μέσω Messenger + email"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέστε τον λογαριασμό σας στο Intercom με την έρευνα CSAT του Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράδοση μέσω"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιος λαμβάνει την έρευνα και πότε"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία κανόνων"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιημένο"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι έρευνες εντός της εφαρμογής επιτρέπουν στους πελάτες σας να ολοκληρώσουν μια έρευνα χωρίς να αποχωρήσουν από τη συνομιλία."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκκίνηση του Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανόνες παράδοσης ανά σύνδεση"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Υπάρχει ήδη μια έρευνα ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nΕπιλύστε τη σύγκρουση απενεργοποιώντας τη σύνδεση ή τροποποιώντας τους κανόνες είτε εδώ είτε στην ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράδοση"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε τις συνθήκες με βάση τις οποίες αποστέλλεται η έρευνα"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δημιουργήθηκε από ", _interpolate(_named("name")), " στις ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αφαίρεση γλώσσας \"", _interpolate(_named("languageName")), "\""])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αφαίρεση αυτής της γλώσσας σημαίνει ότι δεν θα είναι πλέον ορατή σε κανέναν ερωτώμενο και ότι όλα τα μεταφρασμένα κείμενα διαγράφονται."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση γλώσσας"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλακτικά, μπορείτε να απενεργοποιήσετε τη γλώσσα αποκρύπτοντάς την από την έρευνα. Αυτό θα σας επιτρέψει να διατηρήσετε όλες τις μεταφράσεις σας στη θέση τους χωρίς να τις εμφανίζετε στους ερωτώμενους."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Τελευταία επεξεργασία από ", _interpolate(_named("name")), " στις ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τις γλώσσες έρευνας"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Πριν προσθέσετε άλλες γλώσσες, βεβαιωθείτε ότι έχετε οριστικοποιήσει τις αλλαγές στα βήματα \"Διαμόρφωση στυλ\" και \"Περιεχόμενο\". ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη γλώσσας"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφρασμένα πεδία"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατή στους ερωτώμενους"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπιλεγμένη"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη αρχείου μετάφρασης"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση γλώσσας"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσες"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" κάντε λήψη ενός αρχείου μετάφρασης"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τις μεταφράσεις"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η μετάφραση ολοκληρώθηκε. ", _interpolate(_named("review_link")), " ή ", _interpolate(_named("download_link")), " για να κάνετε αλλαγές."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" κάντε λήψη ενός αρχείου μετάφρασης"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τι λείπει"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ορισμένο περιεχόμενο δεν έχει μεταφραστεί ακόμη και θα αντικατασταθεί με Αγγλικά. ", _interpolate(_named("review_link")), " ή ", _interpolate(_named("download_link")), " για να προσθέσετε τις μεταφράσεις που λείπουν."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθηκεύστε την έρευνα"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η έρευνα περιέχει μη αποθηκευμένες αλλαγές. ", _interpolate(_named("save")), " για να κάνετε λήψη του αρχείου μετάφρασης με τις τελευταίες αλλαγές."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή αρχείου μετάφρασης"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε την προτιμώμενη κλίμακα αξιολόγησης"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δίνει στους πελάτες ένα αναπτυσσόμενο μενού για να επιλέξουν έναν λόγο για την αξιολόγηση"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριστερό κείμενο κλίμακας (προαιρετικά)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμοί"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε λόγο"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λόγος δεν μπορεί να είναι κενός"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτός ο λόγος υπάρχει ήδη"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση αναπτυσσόμενου μενού για τους λόγους της βαθμολογίας σας"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαγραφή ενός λόγου και η εκ νέου προσθήκη του με το ίδιο όνομα ή η μετονομασία ενός λόγου δημιουργεί πάντα μια νέα καταχώρηση. Ως αποτέλεσμα, επηρεάζει τα δεδομένα που εμφανίζονται στον πίνακα ελέγχου σας."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή λόγου"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας διαγραμμένος λόγος παραμένει στις αναφορές του πίνακα ελέγχου, αλλά δεν θα είναι ορατός στους νέους ερωτώμενους της έρευνας. Αυτό ισχύει και για τις σχετικές μεταφράσεις."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγράψτε έναν λόγο"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Διαγράψτε έναν λόγο \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεξί κείμενο κλίμακας (προαιρετικά)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε μια κλίμακα 5 σημείων, το 1 και το 2 θεωρούνται αρνητικές βαθμολογίες, το 3 είναι ουδέτερο, τα 4-5 είναι θετικά"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε κείμενο κλίμακας"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κλίμακα ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλίμακα αξιολόγησης"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Zendesk QA εισάγει τις συζητήσεις σας. Αυτή η διαδικασία μπορεί να διαρκέσει έως 24 ώρες."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό θα χρησιμοποιηθεί ως σύμβολο εξατομίκευσης στη διάρκεια της έρευνας."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα επωνυμίας"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουμπιά και σύνδεσμοι"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικεύστε την εμφάνιση της έρευνάς σας"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόντο λογότυπου"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποστηρίζονται οι μορφές JPG, PNG και GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λογότυπο"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαμόρφωση στυλ"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή λογότυπου"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείστε με στυλ"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε ένα μήνυμα που θα βλέπουν οι χρήστες μετά την υποβολή των παρατηρήσεών τους"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστήριο μήνυμα"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστούμε"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λανθασμένος δεκαεξαδικός κωδικός χρώματος"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λανθασμένη διεύθυνση email"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο δεν μπορεί να είναι κενό"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λανθασμένο URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη ορατό:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατό:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσες"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απεσταλμένες έρευνες"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες που στάλθηκαν τις τελευταίες 30 ημέρες"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η έρευνα διαγράφηκε"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήθηκε αντίγραφο της έρευνας"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το email προεπισκόπησης στάλθηκε"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η έρευνα δημοσιεύθηκε"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η έρευνα ενημερώθηκε"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η έρευνα είναι σε παύση"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση έρευνας"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπισκόπηση"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε το email όπου θα σταλεί η προεπισκόπηση της έρευνας"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε γλώσσα για προεπισκόπηση"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή email προεπισκόπησης"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλτε ένα παράδειγμα έρευνας στο email σας. Οι αξιολογήσεις από την προεπισκόπηση της έρευνας δεν θα αποθηκευτούν."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προεπισκόπηση της έρευνας ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπισκόπηση έρευνας μέσω email στον ιστό"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχιση"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση ως πρόχειρο"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα επωνυμίας"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όνομα της συγκεκριμένης επωνυμίας μπορεί να προστεθεί στο βήμα \"Διαμόρφωση στυλ\""])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα πελάτη"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωριστικό υποστήριξης"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπρόσωπος υποστήριξης"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργή"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγράφηκε"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρο"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε παύση"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Έρευνα CSAT του χρήστη ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγιναν μη αποθηκευμένες αλλαγές"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση και συνέχιση έρευνας"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εάν δεν βλέπετε σωστά αυτό το email, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είμαι ευχαριστημένος με την υποστήριξη που έλαβα, επειδή..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την υποστήριξη του Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε λόγο"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανακεφαλαίωση συζήτησης"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράδειγμα: Ο πελάτης είχε προβλήματα με την ενημέρωση της μεθόδου πληρωμής. Ο εκπρόσωπος κοινοποίησε ένα άρθρο που θα μπορούσε να βοηθήσει. Λειτούργησε."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την υποστήριξη της Τεχνητής νοημοσύνης του Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όροι χρήσης"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σας ευχαριστούμε για τις παρατηρήσεις σας!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιος είναι ο κύριος λόγος για τη βαθμολογία σας;"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πείτε μας γιατί δώσατε αυτήν την αξιολόγηση..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεγάλη προσπάθεια"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μικρή προσπάθεια"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάντε κλικ εδώ για να ανοίξετε την έρευνα"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρρητο"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εξατομικευμένη ερώτησή σας εμφανίζεται εδώ"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Βαθμολογήσατε την εμπειρία σας στο ", _interpolate(_list(0)), " με"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή παρατηρήσεων"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστούμε"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάργηση εγγραφής"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολύ δυσαρεστημένος/-η"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολύ ικανοποιημένος/-η"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημειώστε ότι η τρέχουσα κατάσταση της έρευνας και οι κανόνες παράδοσης δεν θα συμπεριληφθούν στο αντίγραφο της έρευνας"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δημιουργία αντιγράφου της έρευνας \"", _interpolate(_list(0)), "\""])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν χρησιμοποιείτε το ενσωματωμένο απόσπασμα κώδικα email, φροντίστε να το αφαιρέσετε από το Zendesk πριν κάνετε παύση της έρευνας"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν συλλέγονται απαντήσεις όταν η έρευνα είναι σε παύση."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση έρευνας"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φροντίστε να αφαιρέσετε το απόσπασμα κώδικα από το Zendesk πριν κάνετε παύση της έρευνας."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή έρευνας"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν χρησιμοποιείτε το ενσωματωμένο απόσπασμα κώδικα email, φροντίστε να το αφαιρέσετε από το Zendesk πριν διαγράψετε την έρευνα."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια θα διαγράψει οριστικά την έρευνα. Οι χρήστες που έλαβαν την έρευνα εξακολουθούν να μπορούν να τη συμπληρώσουν, και όλα τα δεδομένα που σχετίζονται με την έρευνα θα παραμείνουν στο Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή έρευνας ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φροντίστε να αφαιρέσετε το απόσπασμα κώδικα από το Zendesk πριν διαγράψετε την έρευνα."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι νέοι ερωτώμενοι θα βλέπουν τις αλλαγές που εφαρμόζετε."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση έρευνας"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φροντίστε να αντιγράψετε την τελευταία έκδοση του ενσωματωμένου αποσπάσματος κώδικα μετά τη δημοσίευση της έρευνας."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν χρησιμοποιείτε το ενσωματωμένο απόσπασμα κώδικα email, φροντίστε να το αντιγράψετε στο Zendesk μετά τη συνέχιση της έρευνας."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε την αποστολή της έρευνας και τη συλλογή απαντήσεων."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση και συνέχιση έρευνας"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι έχετε προσθέσει το ενσωματωμένο απόσπασμα κώδικα στο Zendesk μετά τη συνέχιση της έρευνας."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση έρευνας"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φροντίστε να αντιγράψετε την τελευταία έκδοση του ενσωματωμένου αποσπάσματος κώδικα μετά την ενημέρωση της έρευνας."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν την έρευνα; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή έρευνας;"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δοκιμαστική σας περίοδος έχει λήξει, αλλά μπορείτε να συνεχίσετε να απολαμβάνετε το Zendesk QA κάνοντας αναβάθμιση σε πλήρη λογαριασμό."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δοκιμαστική σας περίοδος έχει λήξει, αλλά μπορείτε να συνεχίσετε να απολαμβάνετε το Zendesk QA κάνοντας αναβάθμιση σε πλήρη λογαριασμό. Για να το ρυθμίσετε, μιλήστε με τον τοπικό σας διαχειριστή."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δοκιμαστική έκδοσή σας έληξε"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβάθμιση τώρα"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δωρεάν δοκιμαστική έκδοσή σας έχει λήξει."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να συνεχίσετε να χρησιμοποιείτε την πλήρη έκδοση του Zendesk QA, εισαγάγετε τα στοιχεία πληρωμής σας."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να συνεχίσετε να χρησιμοποιείτε την πλήρη έκδοση του Zendesk QA, ο διαχειριστής του λογαριασμού σας πρέπει να εισαγάγει τα στοιχεία πληρωμής."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση πληρωμής"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έναν χρήστη"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δημιουργήθηκε στις ", _interpolate(_named("date")), " από ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ανάθεση"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ότι δεν έχετε ολοκληρώσει την επεξεργασία. Αν αποχωρήσετε χωρίς να αποθηκεύσετε τις αλλαγές, θα χάσετε όλες τις πληροφορίες που έχετε προσθέσει έως τώρα."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η ανάθεση θα επαναλαμβάνεται κάθε δεύτερη ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αυτή η ανάθεση θα επαναλαμβάνεται κάθε ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Όλοι οι ελεγχόμενοι καλύπτονται σε έναν κύκλο"]), _normalize(["Όλοι οι ελεγχόμενοι καλύπτονται σε ", _interpolate(_named("nr")), " κύκλους"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " συζητήσεις"]), _normalize([_interpolate(_named("n")), " συζήτηση"]), _normalize([_interpolate(_named("n")), " συζητήσεις"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nr")), " εκπροσώπων"]), _normalize([_interpolate(_named("nr")), " εκπροσώπου"]), _normalize([_interpolate(_named("nr")), " εκπροσώπων"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["συμμετέχων"]), _normalize(["συμμετέχοντες"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " συζητήσεις ανά κύκλο"]), _normalize([_interpolate(_named("n")), " συζήτηση ανά κύκλο"]), _normalize([_interpolate(_named("n")), " συζητήσεις ανά κύκλο"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " ανά ελεγχόμενο στη σειρά"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ελεγχόμενος"]), _normalize(["ελεγχόμενοι"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ελεγκτής"]), _normalize(["ελεγκτές"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγχόμενοι"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτές"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες του χώρου εργασίας"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες του χώρου εργασίας εκτός από τους εκπροσώπους"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κύκλος ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μη επαναλαμβανόμενη"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ανάθεση"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τις αναθέσεις"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ανάθεσης"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια θα διαγράψει οριστικά την ανάθεση και θα αφαιρέσει τα δεδομένα της από τον πίνακα ελέγχου. Όλοι οι έλεγχοι που σχετίζονται με αυτήν θα παραμείνουν στο Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή ανάθεσης ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ανάθεση διαγράφηκε"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήθηκε αντίγραφο της ανάθεσης"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί αναθέσεις ακόμη"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δημιουργία ανάθεσης απέτυχε"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα ανάθεσης"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κύκλος ανανεώνεται και δημιουργείται μια νέα λίστα εργασιών. Οι έλεγχοι που έχουν δοθεί ήδη υπολογίζονται για την επίτευξη του στόχου."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αμέσως"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κατά την έναρξη του επόμενου κύκλου στις ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πότε να δημοσιεύονται οι αλλαγές;"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αλλαγές που γίνονται σε αυτήν την ανάθεση θα δημοσιεύονται με αυτήν την ανάθεση"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός ανάθεσης ως ενεργής;"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλαμβάνεται την ημέρα έναρξης της ανάθεσης"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη Παρασκευή κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη Παρασκευή κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη Παρασκευή κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη Παρασκευή κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία Παρασκευή κάθε μήνα"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη Δευτέρα κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη Δευτέρα κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη Δευτέρα κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη Δευτέρα κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία Δευτέρα κάθε μήνα"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτο Σάββατο κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερο Σάββατο κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτο Σάββατο κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτο Σάββατο κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταίο Σάββατο κάθε μήνα"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη Κυριακή κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη Κυριακή κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη Κυριακή κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη Κυριακή κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία Κυριακή κάθε μήνα"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη Πέμπτη κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη Πέμπτη κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη Πέμπτη κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη Πέμπτη κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία Πέμπτη κάθε μήνα"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη Τρίτη κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη Τρίτη κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη Τρίτη κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη Τρίτη κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία Τρίτη κάθε μήνα"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη Τετάρτη κάθε μήνα"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη Τετάρτη κάθε μήνα"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη Τετάρτη κάθε μήνα"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη Τετάρτη κάθε μήνα"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία Τετάρτη κάθε μήνα"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξατομικευμένη επανάληψη"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημέρα"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1η κάθε μήνα"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεύτερη"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέταρτη"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλαμβάνεται"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθημερινή"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν αποτελέσματα"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζώνη ώρας"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις κύκλου"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα ανάθεσης"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασικές πληροφορίες"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στην αρχή κάθε κύκλου ανάθεσης, δημιουργείται μια προκαθορισμένη λίστα συζητήσεων για αξιολόγηση."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικά"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνθετες ρυθμίσεις"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματη λήψη νέων συζητήσεων όταν δεν επιτυγχάνεται ο στόχος ελέγχων"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αυτόματη λήψη δεν είναι δυνατή με στόχους ελέγχων ποσοστού (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να είναι ακέραιος αριθμός, όχι δεκαδικός"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να επιτρέπεται η αντικατάσταση συζητήσεων (ρόλος Ελεγκτή και άνω)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντικατάσταση συζητήσεων"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να επιτρέπεται η ανάθεση στους ελεγκτές των δικών τους συζητήσεων για έλεγχο"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτοαξιολογήσεις"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έλεγχοι"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ελάχιστος αριθμός ελέγχων πρέπει να είναι τουλάχιστον 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίστε τους ελάχιστους ελέγχους για κάθε ελεγχόμενο"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συζητήσεις"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τοις εκατό (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έλεγχοι"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["έλεγχοι συνολικά"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάθε ελεγκτής πρέπει να κάνει τον καθορισμένο αριθμό ελέγχων"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ελεγκτές πρέπει να ελέγξουν συλλογικά έναν καθορισμένο αριθμό συζητήσεων"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ελάχιστος αριθμός ελέγχων πρέπει να είναι τουλάχιστον 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο στόχος ελέγχων πρέπει να είναι μεταξύ 1 – 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος ελέγχων"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για κάθε ελεγχόμενο πρέπει να ελεγχθεί ένας ελάχιστος αριθμός των συνολικών συζητήσεών του"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ελεγκτές μπορούν να επιλέγουν συζητήσεις από τον κοινό χώρο συγκέντρωσης"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στους ελεγκτές ανατίθενται συζητήσεις από τον κοινό χώρο συγκέντρωσης"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσέγγιση ανάθεσης"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα ρομπότ"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι συμμετέχοντες του χώρου εργασίας"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες του χώρου εργασίας"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ελεγχόμενους"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιοι θα ελέγχονται;"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ελεγχόμενους"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ποιος θα ελεγχθεί"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτρέπονται εξαιρέσεις μόνο όταν έχουν επιλεγεί όλοι οι χρήστες του χώρου εργασίας ή μια ομάδα"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτές και ελεγχόμενοι"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Τρέχων: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπιλογή χώρου εργασίας"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιος τύπος χρηστών θα πρέπει να επιλεγεί ως ελεγχόμενοι;"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πιο ενεργός εκπρόσωπος"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετέχων"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση ελεγχομένων (προαιρετικά)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χρήστες για εξαίρεση"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση ελεγκτών (προαιρετικά)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χρήστες για εξαίρεση"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες του χώρου εργασίας εκτός από τους εκπροσώπους"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ελεγκτές"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιος θα κάνει τους ελέγχους;"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ελεγκτές"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτοαξιολογήσεις δεν επιτρέπονται σε αυτόν τον χώρο εργασίας"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να επιτρέπονται αυτοαξιολογήσεις"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αυτοαξιολογήσεις δεν επιτρέπονται σε αυτόν τον χώρο εργασίας."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Συνομιλία που ταιριάζει σήμερα"]), _normalize(["Συνομιλίες που ταιριάζουν σήμερα"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφανίζεται σαν να ξεκίνησε σήμερα η ανάθεση."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι μελλοντικοί αριθμοί ενδέχεται να διαφέρουν."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν επιλεγεί ελεγχόμενοι"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν επιλεγεί ελεγκτές"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ελεγχόμενος"]), _normalize(["Ελεγχόμενοι"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ελεγκτής"]), _normalize(["Ελεγκτές"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπισκόπηση"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματη λήψη: να επιτρέπεται αν δεν υπάρχουν αρκετές συζητήσεις"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " τις Παρασκευές από ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " τις Δευτέρες από ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " τα Σάββατα από ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " τις Κυριακές από ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " κάθε Πέμπτη από ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " κάθε Τρίτη από ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " τις Τετάρτες από ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Καθημερινά από τις ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αρχίζει στις ", _interpolate(_named("startDateTime")), " και λήγει στις ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " συνθήκες ημερομηνίας"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " συζήτηση και συνθήκες υπηρεσίας βοήθειας"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Καμία συζήτηση και ", _interpolate(_named("count")), " συνθήκη υπηρεσίας βοήθειας"]), _normalize(["Καμία συζήτηση και ", _interpolate(_named("count")), " συνθήκες υπηρεσίας βοήθειας"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " συζήτηση και καμία συνθήκη υπηρεσίας βοήθειας"]), _normalize([_interpolate(_named("count")), " συζητήσεις και καμία συνθήκη υπηρεσίας βοήθειας"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Προσέγγιση: οι ελεγκτές επιλέγουν τις συζητήσεις"]), _normalize(["Προσέγγιση: οι συζητήσεις ανατίθενται στους ελεγκτές"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διορθώστε τα σφάλματα που επισημαίνονται στη σύνοψη για να συνεχίσετε"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος: οι ελεγκτές πρέπει να ελέγξουν όλες τις συζητήσεις"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Στόχος: οι ελεγκτές πρέπει να ελέγξουν μία συζήτηση"]), _normalize(["Στόχος: οι ελεγκτές πρέπει να ελέγξουν ", _interpolate(_named("count")), " συζητήσεις"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Στόχος: οι ελεγκτές πρέπει να ελέγξουν το ", _interpolate(_named("count")), "% των συζητήσεων"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος: δεν έχει οριστεί στόχος ελέγχων"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος: πρέπει να ελεγχθούν όλες οι συζητήσεις κάθε ελεγχόμενου"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Στόχος: πρέπει να ελεγχθούν ", _interpolate(_named("count")), " από τις συνολικές συζητήσεις κάθε ελεγχόμενου"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Στόχος: πρέπει να ελεγχθεί το ", _interpolate(_named("count")), "% των συνολικών συζητήσεων κάθε ελεγχόμενου"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Στόχος: κάθε ελεγκτής πρέπει να κάνει έναν έλεγχο"]), _normalize(["Στόχος: κάθε ελεγκτής πρέπει να κάνει ", _interpolate(_named("count")), " ελέγχους"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Αντικατάσταση: οι ελεγκτές δεν μπορούν να αντικαθιστούν συζητήσεις"]), _normalize(["Αντικατάσταση: οι ελεγκτές μπορούν να αντικαθιστούν συζητήσεις"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτοαξιολογήσεις: μπορούν να ανατεθούν στους ελεγκτές δικές τους συζητήσεις"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν οριστεί συνθήκες ημερομηνίας"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχει οριστεί όνομα ανάθεσης"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνοψη"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνθήκες"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " από ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανά δεκαπενθήμερο"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθημερινά"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποτέ"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εβδομαδιαία"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διάρκεια κύκλου"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός ως ενεργής"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση ως πρόχειρο"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ανάθεση αποθηκεύτηκε ως πρόχειρο"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ανάθεση ορίστηκε ως ανενεργή"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός ως ανενεργής"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ανάθεσης"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ανάθεση δημιουργήθηκε"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως ενεργής"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως ανενεργής"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Οι εκκρεμείς ενημερώσεις της ανάθεσης θα τεθούν σε ισχύ στον επόμενο κύκλο, που θα ξεκινήσει στις ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση αλλαγών"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργή"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρο"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανενεργή"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εκκρεμότητα"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση ανάθεσης"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ανάθεση ενημερώθηκε"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει αν ο εκπρόσωπος ή το ρομπότ ολοκλήρωσε τη συζήτηση."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει αν ο εκπρόσωπος ολοκλήρωσε τη συζήτηση ευγενικά"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει αν η συζήτηση ολοκληρώθηκε"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποφώνηση"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει λάθη γραμματικής, ορθογραφίας και στυλ στις αλληλεπιδράσεις τόσο του εκπροσώπου όσο και του ρομπότ."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τα λάθη γραμματικής, ορθογραφίας και ύφους του εκπροσώπου"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τα λάθη γραμματικής, ορθογραφίας και ύφους του εκπροσώπου"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορθογραφία και γραμματική"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τη συζήτηση για χαιρετισμούς του εκπροσώπου και του ρομπότ."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει ολόκληρη τη συζήτηση για τυπικές φράσεις χαιρετισμού"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει ολόκληρη τη συζήτηση για τυπικούς χαιρετισμούς"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαιρετισμός"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει αν ο εκπρόσωπος κατανοεί και αναγνωρίζει τα συναισθήματα των πελατών"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντοπίζει περιπτώσεις όπου ο εκπρόσωπος είχε συναίσθημα αντίστοιχο με του πελάτη ή χρησιμοποίησε λέξεις που εκφράζουν κατανόηση, όπως \"καταλαβαίνω\" και \"αναγνωρίζω\""])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενσυναίσθηση"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει εάν ο εκπρόσωπος κατανοεί το ερώτημα ή τον προβληματισμό του πελάτη"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει εάν ο εκπρόσωπος κατανοεί το ερώτημα ή τον προβληματισμό του πελάτη"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατανόηση"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει πόσο εύκολα μπορεί να γίνει κατανοητό ένα κείμενο, λαμβάνοντας υπόψη την πολυπλοκότητα των λέξεων και το μήκος των προτάσεων"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει πόσο εύκολα μπορεί να γίνει κατανοητό ένα κείμενο, λαμβάνοντας υπόψη την πολυπλοκότητα των λέξεων και το μήκος των προτάσεων"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το μήνυμα περιέχει 1 υπερβολικά μεγάλη πρόταση, που καθιστά δύσκολη την ανάγνωσή του"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Αυτό το μήνυμα περιέχει ", _interpolate(_list(0)), " υπερβολικά μεγάλες προτάσεις, που καθιστούν δύσκολη την ανάγνωσή του"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωσιμότητα"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει ολόκληρη τη συζήτηση για μια προτεινόμενη λύση"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει ολόκληρη τη συζήτηση για μια προτεινόμενη λύση"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτεινόμενη λύση"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τον τόνο του εκπροσώπου στη διάρκεια της συζήτησης"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναλύει τον τόνο του εκπροσώπου στη διάρκεια της συζήτησης"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τόνος"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατός ο εντοπισμός λαθών στα μηνύματα του εκπροσώπου"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατός ο προσδιορισμός γραμματικών λαθών"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν εντοπίστηκε φράση χαιρετισμού. Προσθέστε νέες αν χρειάζεται μέσω του διαχειριστή σας στις Ρυθμίσεις."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος ολοκλήρωσε τη συζήτηση ευγενικά"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν εντοπίστηκε φράση αποφώνησης. Προσθέστε νέες αν χρειάζεται μέσω του διαχειριστή σας στις Ρυθμίσεις"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος είχε συναίσθημα αντίστοιχο με του πελάτη ή χρησιμοποίησε λέξεις που εκφράζουν κατανόηση"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατό να εντοπιστεί αν ο εκπρόσωπος είχε συναίσθημα αντίστοιχο με του πελάτη ή αν χρησιμοποίησε λέξεις που εκφράζουν φροντίδα"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος χρησιμοποιούσε μεγάλες προτάσεις και πολύπλοκες λέξεις, με αποτέλεσμα κάποια άτομα να δυσκολεύονται πιθανώς στην κατανόηση"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος χρησιμοποιούσε πολύπλοκες λέξεις, με αποτέλεσμα κάποια άτομα να δυσκολεύονται πιθανώς στην κατανόηση"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος χρησιμοποιούσε μεγάλες προτάσεις, με αποτέλεσμα κάποια άτομα να δυσκολεύονται πιθανώς στην κατανόηση"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος χρησιμοποίησε γλώσσα που είναι εύκολα κατανοητή"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν αρκετά δεδομένα για την αξιολόγηση αυτής της κατηγορίας"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος δυσκολεύτηκε να κατανοήσει την ερώτηση / το αίτημα του πελάτη"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος κατανόησε καλά την ερώτηση / το αίτημα του πελάτη"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατό να εντοπιστεί αν ο εκπρόσωπος κατανόησε την ερώτηση / το αίτημα του πελάτη"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τόνος του εκπροσώπου κατά τη διάρκεια της συζήτησης"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τόνος του εκπροσώπου κατά τη διάρκεια της συζήτησης"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τόνος του εκπροσώπου κατά τη διάρκεια της συζήτησης"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τόνος του εκπροσώπου κατά τη διάρκεια της συζήτησης"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τόνος του εκπροσώπου κατά τη διάρκεια της συζήτησης"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατός ο προσδιορισμός του τόνου του εκπροσώπου"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση δεν ολοκληρώθηκε"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση ολοκληρώθηκε"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατό να εντοπιστεί αν η συζήτηση ολοκληρώθηκε"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος δεν είχε ενσυναίσθηση ως προς τον πελάτη και τα προβλήματά του"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος έδειξε ενσυναίσθηση ως προς τον πελάτη και τα προβλήματά του"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατό να εντοπιστεί αν ο εκπρόσωπος έδειξε ενσυναίσθηση ως προς τον πελάτη και τα προβλήματά του "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης δεν χαιρετήθηκε κατά τη διάρκεια της συζήτησης"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο πελάτης χαιρετήθηκε κατά τη διάρκεια της συζήτησης"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατός ο εντοπισμός τυπικού χαιρετισμού"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος δεν έδωσε λύση στον πελάτη"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο εκπρόσωπος πρότεινε λύση στον πελάτη"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατό να εντοπιστεί αν ο εκπρόσωπος πρότεινε μια λύση στον πελάτη"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολύπλοκες λέξεις"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεγάλες προτάσεις"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραμματική"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορθογραφικά λάθη"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικός"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απολογητικός"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ήρεμος"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρούμενος"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίεργος"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελματικός"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποστηρικτικός"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στυλ"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 αποφώνηση"]), _normalize([_interpolate(_named("n")), " αποφωνήσεις"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο Αυτόματος QA πραγματοποιεί έλεγχο μόνο για εγκεκριμένες αποφωνήσεις. Εάν αυτή η κατηγορία έχει οριστεί ως κρίσιμη, η μη χρήση των εγκεκριμένων αποφωνήσεων θα έχει ως αποτέλεσμα τη αποτυχία του ελέγχου."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένες αποφωνήσεις"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένες αποφωνήσεις"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 εξαίρεση"]), _normalize([_interpolate(_named("n")), " εξαιρέσεις"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λέξεις και φράσεις που παραβλέπει ο Αυτόματος QA ως ορθογραφικά ή γραμματικά λάθη."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαιρέσεις ορθογραφίας και γραμματικής"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαιρέσεις Αυτόματου QA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 χαιρετισμός"]), _normalize([_interpolate(_named("n")), " χαιρετισμοί"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο Αυτόματος QA πραγματοποιεί έλεγχο μόνο για εγκεκριμένους χαιρετισμούς. Εάν αυτή η κατηγορία έχει οριστεί ως κρίσιμη, η μη χρήση των εγκεκριμένων χαιρετισμών θα έχει ως αποτέλεσμα τη αποτυχία του ελέγχου."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένοι χαιρετισμοί"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκεκριμένοι χαιρετισμοί"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία Αυτόματου QA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια κατηγορία Αυτόματου QA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασικές αιτίες που ανατέθηκαν αυτόματα:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λογαριασμός → Ρυθμίσεις"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ορισμένες κατηγορίες αξιοποιούν το MS Azure, προσφέροντας ένα εκτεταμένο φάσμα λειτουργικότητας και υποστηριζόμενων γλωσσών. Επιλέξτε ", _interpolate(_named("link")), " για περισσότερες πληροφορίες ή επικοινωνήστε με έναν διαχειριστή για να το κάνετε."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Αποκτήστε πρόσβαση σε μια διευρυμένη λίστα κατηγοριών και υποστηριζόμενων γλωσσών αξιοποιώντας το MS Azure. Επιλέξτε ", _interpolate(_named("link")), " ή επικοινωνήστε με έναν διαχειριστή για να το κάνετε."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήση Αυτόματου QA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Στις κατηγορίες του Αυτόματου QA εκχωρείται μια βαθμολογία αυτόματα, χρησιμοποιώντας μια σταθερή κλίμακα αξιολόγησης. Μπορείτε να προσαρμόσετε την αξιολόγηση μη αυτόματα αργότερα. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια κατηγορία Αυτόματου QA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η βαθμολογία που εκχωρείται αυτόματα εξαρτάται από τη σοβαρότητα των λαθών και τον αριθμό τους. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασικές αιτίες"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο οι σχετικές κατηγορίες αξιολόγησης εμφανίζονται στους ελεγκτές. Οι κατηγορίες αξιολόγησης εμφανίζονται με βάση τις συνθήκες που ορίσατε κατά τη δημιουργία μιας κατηγορίας."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συνομιλία"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ένταξη"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["τηλέφωνο"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επιλέξτε τον τύπο κατηγορίας αξιολόγησης για τον χώρο εργασίας \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ελεγκτές πρέπει να επιλέξουν μη αυτόματα τη σωστή κάρτα βαθμολογίας για να δουν τις σχετικές κατηγορίες αξιολόγησης για κάθε συζήτηση."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι ότι θέλετε να αλλάξετε τον τύπο της κατηγορίας αξιολόγησης;"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή κατηγοριών"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή τύπου κατηγορίας αξιολόγησης;"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία αξιολόγησης υπό όρους"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στατική κατηγορία αξιολόγησης"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος κατηγορίας αξιολόγησης"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις κατηγορίας αξιολόγησης"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαγραφούν όλες οι αξιολογήσεις"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλακτικά, μπορείτε να διατηρήσετε τα στατιστικά στοιχεία σας χωρίς να διαγράψετε δεδομένα αρχειοθετώντας μια κατηγορία."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κατανοώ ότι με αυτήν την ενέργεια θα ", _interpolate(_named("deleteAllRatings")), " που έχουν δοθεί για αυτήν την κατηγορία."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ. Ο τόνος είναι τέλειος"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση κατηγορίας μόνο αν"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το \"Πάντα\" θα παραβλεφθεί. Δεν μπορεί να συνδυαστεί με άλλες συνθήκες."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πάντα"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες υπηρεσίας βοήθειας"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε συνθήκη"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία ικανοποίησης (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος προέλευσης"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλι συζήτησης"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια κατηγορία χρειάζεται ένα όνομα"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε την κατηγορία σε μια κάρτα βαθμολογίας"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία κατηγορίας"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία κατηγορίας"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθέτηση"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία αξιολόγησης προστέθηκε"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια αρχειοθετημένη κατηγορία δεν είναι ορατή στην κάρτα βαθμολογίας, αλλά σας επιτρέπει να διατηρήσετε τα στατιστικά στοιχεία σας χωρίς να διαγράψετε δεδομένα."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθέτηση κατηγορίας"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Αρχειοθέτηση κατηγορίας ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία αξιολόγησης αρχειοθετήθηκε"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κατηγορίας"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε \"διαγραφή κατηγορίας\" για να διαγράψετε αυτήν την κατηγορία"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαγραφή κατηγορίας"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Διαγραφή κατηγορίας ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία αξιολόγησης διαγράφηκε"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήθηκε αντίγραφο της κατηγορίας αξιολόγησης"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ομάδα κατηγοριών αξιολόγησης προστέθηκε"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ομάδα κατηγοριών αξιολόγησης διαγράφηκε"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σειρά της ομάδας κατηγοριών αξιολόγησης άλλαξε"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ομάδα κατηγοριών αξιολόγησης ενημερώθηκε"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία αξιολόγησης αφαιρέθηκε από το αρχείο"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η κατηγορία αξιολόγησης ενημερώθηκε"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν ένας εκπρόσωπος αποτυγχάνει σε μια κρίσιμη κατηγορία, όλες οι κατηγορίες σε αυτόν τον έλεγχο αποτυγχάνουν αυτόματα."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κρίσιμη κατηγορία αξιολόγησης"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν κατηγορίες σε αυτήν την ομάδα"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία νέας κατηγορίας αξιολόγησης"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία κατηγορίας"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων των κατηγοριών και των δεδομένων ελέγχου σε αυτήν την ομάδα"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με τη διαγραφή της ομάδας κατηγοριών όλες οι κατηγορίες που συνδέονται με αυτήν θα είναι μη ομαδοποιημένες."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή ομάδας κατηγοριών ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αν επιλέξετε να διαγράψετε τις κατηγορίες σε αυτήν την ομάδα, θα χάσετε όλα τα δεδομένα ελέγχων που σχετίζονται με αυτές τις κατηγορίες."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα ομάδας"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ελεγκτές μπορούν να επιλέξουν περισσότερους από έναν λόγους ως βασική αιτία"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ελεγκτές μπορούν να επιλέξουν περισσότερους από έναν λόγους ως βασική αιτία"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολλοί λόγοι"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["π.χ. Τόνος"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη νέας ομάδας κατηγοριών"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα ομάδα"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη \"Άλλου\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη βασικής αιτίας"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η βασική αιτία υπάρχει ήδη"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη βασικής αιτίας"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε βασικές αιτίες για να εξηγήσετε την αξιολόγηση"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλίμακα αξιολόγησης"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργές"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθετημένες"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε κάρτες βαθμολογίας σε αυτήν την κατηγορία αξιολόγησης"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση από το αρχείο"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση κατηγορίας"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες τις αξιολογήσεις"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφανίζεται για"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικές αξιολογήσεις"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρνητικές και ουδέτερες αξιολογήσεις"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ναι, να ενημερωθεί"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βάρος"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι κατηγορίες που είναι πιο σημαντικές για εσάς μπορούν να έχουν μεγαλύτερη βαρύτητα στον υπολογισμό της βαθμολογίας δελτίου."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Βάρος (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για την ευθυγράμμιση"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για την ευθυγράμμιση"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πραγματοποίηση τακτικών συνεδριών ευθυγράμμισης επιτρέπει στους ελεγκτές να διατηρούν ευθυγραμμισμένες τις τεχνικές αξιολόγησής τους και να διασφαλίζουν ότι οι εκπρόσωποι λαμβάνουν το ίδιο επίπεδο συνεπών και αμερόληπτων παρατηρήσεων."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή προγραμμάτων"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η ", _interpolate(_list(0)), " είναι μια διαδικασία που βοηθά την ομάδα εργασίας σας να είναι συντονισμένη όσον αφορά τον τρόπο χειρισμού και αξιολόγησης όλων των αλληλεπιδράσεων με τους πελάτες."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Η ευθυγράμμιση είναι διαθέσιμη για τα προγράμματα ", _interpolate(_list(0)), " και ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι έλεγχοι που δόθηκαν παλαιότερα δεν εμφανίζονται όταν προστίθεται μια συζήτηση στη συνεδρία."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη ορατοί"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι έλεγχοι που δόθηκαν παλαιότερα θεωρούνται έλεγχοι ευθυγράμμισης. Μόνο ένας έλεγχος συζήτησης ανά ελεγκτή θα προστίθεται στη συνεδρία. Οι έλεγχοι που αφορούν συγκεκριμένο μήνυμα δεν εμφανίζονται."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατοί"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατότητα των ελέγχων που δόθηκαν παλαιότερα"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ευθυγράμμιση είναι μια διαδικασία που βοηθά την ομάδα εργασίας σας να είναι συντονισμένη όσον αφορά τον τρόπο χειρισμού και αξιολόγησης όλων των αλληλεπιδράσεων με τους πελάτες. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι χρήστες έχουν απεριόριστη πρόσβαση σε όλους τους ελέγχους που δίνονται κατά τη διάρκεια μιας συνεδρίας ευθυγράμμισης."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει πάντα όλους τους ελέγχους"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικεφαλής"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετά την υποβολή ενός ελέγχου, οι χρήστες θα βλέπουν τις αξιολογήσεις που δόθηκαν από άλλους ελεγκτές κατά τη διάρκεια μιας συνεδρίας ευθυγράμμισης."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει όλους τους ελέγχους ευθυγράμμισης μετά την υποβολή ενός ελέγχου"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι χρήστες έχουν απεριόριστη πρόσβαση σε όλους τους ελέγχους που δίνονται κατά τη διάρκεια μιας συνεδρίας ευθυγράμμισης."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει πάντα όλους τους ελέγχους"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπεύθυνος"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετά την υποβολή ενός ελέγχου, οι χρήστες θα βλέπουν τις αξιολογήσεις που δόθηκαν από άλλους ελεγκτές κατά τη διάρκεια μιας συνεδρίας ευθυγράμμισης."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει όλους τους ελέγχους ευθυγράμμισης μετά την υποβολή ενός ελέγχου"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετά την υποβολή ενός ελέγχου, οι χρήστες θα βλέπουν τις αξιολογήσεις που δόθηκαν από άλλους ελεγκτές κατά τη διάρκεια μιας συνεδρίας ευθυγράμμισης."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει όλους τους ελέγχους ευθυγράμμισης μετά την υποβολή ενός ελέγχου"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτής"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι χρήστες θα βλέπουν μόνο τους δικούς τους ελέγχους που δόθηκαν κατά τη διάρκεια μιας συνεδρίας ευθυγράμμισης."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει μόνο τους δικούς του ελέγχους"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ρυθμίσεις ευθυγράμμισης ενημερώθηκαν"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατότητα των ελέγχων ευθυγράμμισης"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε το όριο για τις μετρικές του πίνακα ελέγχου συμπληρώνοντας τα κενά σύμφωνα με το εσωτερικό σύστημα μετρικών της ομάδας εργασίας σας. Ελέγξτε το όριο στον πίνακα ελέγχου."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναθέσεις"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγράμμιση"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέσεις"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικά"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλη"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όριο"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όριο ενημερώθηκε"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια στιγμή..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Βεβαιωθείτε ότι έχετε δικαιώματα Διαχειριστή και ", _interpolate(_named("retry")), ", ή ", _interpolate(_named("contact")), " για περαιτέρω βοήθεια."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επικοινωνήστε μαζί μας"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ότι δεν μπορέσαμε να αποκτήσουμε πρόσβαση στο API της υπηρεσίας βοήθειας."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δοκιμάστε ξανά"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδέουμε την υπηρεσία βοήθειας. Θα ανακατευθυνθείτε αμέσως."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το ", _interpolate(_list(0)), " εξουσιοδοτήθηκε"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Επιστρέψτε στη ", _interpolate(_list(0)), " για να προσθέσετε μια νέα σύνδεση υπηρεσίας βοήθειας."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ότι έχετε χάσει ένα-δυο βήματα."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπάρχει ήδη ένας οργανισμός με τον ίδιο τομέα email"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σελίδα συνδέσεων"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στο μεταξύ..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε μας ένα λεπτό για να ελέγξουμε τα δικαιώματά σας."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στο μεταξύ..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενοποίηση"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δημιουργήστε έναν χώρο εργασίας"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Για να συνεχίσετε να χρησιμοποιείτε το Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχεδόν έτοιμος για χρήση"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε χώρος εργασίας"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή λογαριασμού"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να συνεχίσετε να χρησιμοποιείτε το Zendesk QA, ο υπεύθυνός σας πρέπει να σας εκχωρήσει σε έναν χώρο εργασίας."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Με τη διαγραφή του λογαριασμού σας θα διαγραφούν οριστικά όλα τα δεδομένα που έχουμε για εσάς – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όλες οι αξιολογήσεις που έχουν δοθεί, τα περιεχόμενα συζητήσεων, οι χώροι εργασίας κ.λπ."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη στο Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποσύνδεση"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την αποσύνδεση της ενοποίησης θα απενεργοποιηθούν όλες οι ειδοποιήσεις του Zendesk QA μέσω του Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποσύνδεση Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ενοποίηση του Slack αποσυνδέθηκε από το Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθορίστε τις προεπιλεγμένες ρυθμίσεις ειδοποιήσεων για όλους τους νέους χρήστες του λογαριασμού."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράκαμψη ρυθμίσεων για όλους τους χρήστες"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόκειται να παρακάμψετε τις ρυθμίσεις ειδοποιήσεων για όλους τους χρήστες του λογαριασμού"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι χρήστες δεν θα μπορούν να καθορίσουν τις δικές τους ρυθμίσεις ειδοποιήσεων."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ρυθμίσεις ειδοποιήσεων ενημερώθηκαν"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποίηση όταν αποτυγχάνει μια κρίσιμη κατηγορία"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποίηση όταν το CSAT λαμβάνει απάντηση"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναύσματα"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία χώρου εργασίας και δεδομένων επίδειξης"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναδημιουργήστε τα δεδομένα επίδειξης και διατηρήστε τον χώρο εργασίας επίδειξης."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση χώρου εργασίας και δεδομένων επίδειξης"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανανέωση δεδομένων επίδειξης"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανανέωση δεδομένων"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαχείριση λειτουργίας επίδειξης λογαριασμού \"", _interpolate(_named("accountName")), "\""])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λειτουργία επίδειξης"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παραμονή στη σελίδα"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποχώρηση από τη σελίδα"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέλετε να αποχωρήσετε από αυτήν τη σελίδα και να χάσετε όλες τις αλλαγές;"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε ολοκληρώσει την ανάθεση"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ανάθεσης"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ανάθεση ολοκληρώθηκε"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν αναθέσεις"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτήν τη στιγμή δεν υπάρχουν εργασίες για να ελέγξετε."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσαρμόστε την ανάθεση ή περιμένετε να ξεκινήσει ο επόμενος κύκλος."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε να προσαρμόσετε τα φίλτρα"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία ανάθεσης"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολουθήστε αυτήν τη σελίδα για ενημερώσεις"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν εργασίες για αυτόν τον κύκλο"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συζητήσεις για εμφάνιση"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση εργασίας ως ολοκληρωμένης"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε ελέγξει αυτόν τον χρήστη."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν αυτοαξιολογήσεις σε αυτήν την ανάθεση"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε κάνοντας κλικ στο εικονίδιο ευθυγράμμισης στην κεφαλίδα της προβολής συζήτησης."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί συζητήσεις"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προθεσμία"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ανάθεσης"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία συνεδρίας ευθυγράμμισης"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αναθέσεις σας"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλες αναθέσεις"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ενεργές αναθέσεις"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν αναθέσεις"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι ελεγχόμενοι"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι ελεγχόμενοι εκτός από εμένα"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν ελεγχόμενοι"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε ελεγχόμενο"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώθηκε"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρο"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαιρέθηκε"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι καταστάσεις"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκκρεμής εργασία"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι ελεγκτές"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι ελεγκτές εκτός από εμένα"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανατέθηκαν σε εμένα"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν ελεγκτές"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε ελεγκτή"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ημέρες"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ημέρες"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ημέρα"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ημέρες"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ημέρες"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ημέρες"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ώρες"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ώρες"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ώρα"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ώρες"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ώρες"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ώρες"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " λεπτά"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " λεπτά"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " λεπτό"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " λεπτά"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " λεπτά"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " λεπτά"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Ι"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες του χώρου εργασίας"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχων ελεγκτής"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέος στόχος για αυτόν τον κύκλο"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέος ελεγκτής"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ελεγκτή"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση συζητήσεων"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση συζήτησης"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζητήσεις προς μετακίνηση"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχων κύκλος"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία ανάθεσης"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν ελεγκτές"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε ελεγκτή"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση συζητήσεων σε εμένα"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση συζητήσεων σε άλλο χρήστη"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτής"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτές"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ημέρες"]), _normalize([_interpolate(_named("n")), " ημέρα"]), _normalize([_interpolate(_named("n")), " ημέρες"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ώρες"]), _normalize([_interpolate(_named("n")), " ώρα"]), _normalize([_interpolate(_named("n")), " ώρες"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη ολοκληρωμένων κύκλων"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη ολοκληρωμένων συνεδριών"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο στόχος σας"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε έναν λόγο για να αφαιρέσετε αυτήν τη συζήτηση"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξηγήστε γιατί αφαιρείτε αυτήν τη συζήτηση"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση συζήτησης"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέλετε να διατηρήσετε τις αλλαγές;"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε έναν λόγο για να αντικαταστήσετε αυτήν τη συζήτηση"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη αλλαγών"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διατήρηση"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διατήρηση αλλαγών"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε συζήτηση αντικατάστασης"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξηγήστε γιατί αντικαθιστάτε αυτήν τη συζήτηση"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θέλετε ακόμη να αφαιρέσετε αυτήν τη συζήτηση από την ανάθεση; Αυτό θα μειώσει τον στόχο κατά 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντικατάσταση"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντικατάσταση συζήτησης"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή πρόχειρου"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία πρόχειρου"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση σε εμένα"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση σε άλλο χρήστη"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκ νέου ανάθεση και έλεγχος"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση συζήτησης"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε έναν έλεγχο"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή λιγότερων"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή περισσότερων"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Στόχος: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " ελέγχθηκαν"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που έγιναν"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση ολοκληρωμένων κύκλων"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση ολοκληρωμένων συνεδριών"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρόνος που απομένει"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργασίες"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συζήτηση αντικαταστάθηκε"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή λίστας εργασιών"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " αργότερα"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " δευτερόλεπτο"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " δευτερόλεπτα"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " η"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " η ", _interpolate(_named("hours")), " ω"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " η ", _interpolate(_named("hours")), " ω ", _interpolate(_named("minutes")), " λ"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " η"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " ημέρες"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " η ", _interpolate(_named("minutes")), " λ"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ω"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ω ", _interpolate(_named("minutes")), " λ"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ω ", _interpolate(_named("minutes")), " λ ", _interpolate(_named("seconds")), " δ"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ω ", _interpolate(_named("seconds")), " δ"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " λ"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " λ ", _interpolate(_named("seconds")), " δ"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " μ"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " δ"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ε"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόλις τώρα"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε δικαιώματα. Επικοινωνήστε με τον διαχειριστή σας για βοήθεια."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σελίδα που αναζητάτε δεν υπάρχει."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε ένα μη αναμενόμενο σφάλμα. Επικοινωνήστε με την υποστήριξη για βοήθεια."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν επιτράπηκε η πρόσβαση. Δεν επιτρέπεται η εκτέλεση αυτής της ενέργειας."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σελίδα που αναζητάτε δεν υπάρχει ή δεν έχετε πρόσβαση σε αυτήν."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πηγαίνετε πολύ γρήγορα και δυσκολευόμαστε να σας προλάβουμε."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καμία"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αλλαγές αποθηκεύτηκαν"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγιναν μη αποθηκευμένες αλλαγές"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["και"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " εφαρμόστηκαν"]), _normalize([_interpolate(_named("n")), " εφαρμόστηκε"]), _normalize([_interpolate(_named("n")), " εφαρμόστηκαν"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδεδεμένο"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήθηκε στις"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήθηκε από"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κρίσιμη"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώθηκε"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["είναι"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Πρώην μέλος"]), _normalize(["ένα Πρώην μέλος"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εδώ"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " επιλέχθηκαν"]), _normalize([_interpolate(_named("n")), " επιλέχθηκε"]), _normalize([_interpolate(_named("n")), " επιλέχθηκαν"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία ενημέρωση"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... και +1 ακόμη"]), _normalize(["... και +", _interpolate(_named("n")), " ακόμη"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 ακόμη"]), _normalize(["+", _interpolate(_named("n")), " ακόμη"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["νέο"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καμία"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δεν είναι"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ή"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαιρέθηκε"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " απαντήσεις"]), _normalize([_interpolate(_named("n")), " απάντηση"]), _normalize([_interpolate(_named("n")), " απαντήσεις"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σήμερα"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χρήστης"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Παρασκευή"]), _normalize(["Παρασκευές"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Δευτέρα"]), _normalize(["Δευτέρες"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Σάββατο"]), _normalize(["Σάββατα"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Κυριακή"]), _normalize(["Κυριακές"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Πέμπτη"]), _normalize(["Πέμπτες"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Τρίτη"]), _normalize(["Τρίτες"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Τετάρτη"]), _normalize(["Τετάρτες"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χθες"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Διαχειριστής"]), _normalize(["ένας Διαχειριστής"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Υπεύθυνος λογαριασμού"]), _normalize(["ένας Υπεύθυνος λογαριασμού"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Χρήστης"]), _normalize(["ένας Χρήστης"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτόματος QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγράφηκε"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε περισσότερα"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνεδρία σας έχει λήξει."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδοι"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο δεν μπορεί να είναι κενό."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν στοιχεία."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν άτομα που να ταιριάζουν."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Κάτοχος"]), _normalize(["ένας Κάτοχος"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε περισσότερα"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε περισσότερα"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε περισσότερα."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πριν ξεκινήσουμε, διαβάστε και αποδεχτείτε τους όρους μας, και είμαστε έτοιμοι."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στάλθηκε"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι;"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που έγιναν συνολικά"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημερώθηκε"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Εκπρόσωπος"]), _normalize(["ένας Εκπρόσωπος"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Επικεφαλής"]), _normalize(["ένας Επικεφαλής"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Υπεύθυνος"]), _normalize(["ένας Υπεύθυνος"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ελεγκτής"]), _normalize(["ένας Ελεγκτής"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανώνυμος χρήστης"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθέτηση"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολοδόχος"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενο"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείσιμο"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύντομα"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ για αντιγραφή"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή συνδέσμου"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσοχή!"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή (προαιρετικά)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαιτείται επιβεβαίωση"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποίηση"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη μη αποθηκευμένων αλλαγών;"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποίηση"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαγωγή"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συχνότητα"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στόχος"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσία βοήθειας"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσες"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποτέ"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενο"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλο"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσοστό επιτυχίας"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικές ρυθμίσεις"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγούμενο"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προφίλ"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαβάστε λιγότερα"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επανάληψη αποστολής"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάκτηση"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγχόμενος"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που ελήφθησαν/προβλήθηκαν"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτής"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που έγιναν από μέλος του χώρου εργασίας"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρόλος"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση αλλαγών"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυαδική κλίμακα"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλίμακα 3 σημείων"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλίμακα 4 σημείων"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλίμακα 5 σημείων"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα βαθμολογίας"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε συχνότητα"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση λιγότερων"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση περισσότερων"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράλειψη"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημαντικά"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βήμα"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις έρευνας"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρονικό εύρος"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγιναν μη αποθηκευμένες αλλαγές"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βάρος"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Έχετε προσκληθεί από τον χρήστη ", _interpolate(_list(0)), " να ενταχθείτε στο Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Οι χρήστες συνδέονται αυτόματα από την Κεντρική διαχείριση. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση χρηστών"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τη διαχείριση χρηστών"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ορισμένοι χρήστες παρέχονται και συγχρονίζονται αυτόματα από τον πάροχο ταυτοτήτων σας. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το δικαίωμα λογαριασμού \"", _interpolate(_list(0)), "\" άλλαξε"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τα δικαιώματα χρηστών"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να μην ερωτηθώ ξανά"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Όλες οι αλλαγές που πραγματοποιούνται στη σελίδα χρηστών αντικατοπτρίζονται στους χώρους εργασίας. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό σημαίνει ότι η πρόσβαση του χρήστη σε ορισμένα τμήματα του Zendesk QA μπορεί να αλλάξει"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή δικαιώματος επιπέδου λογαριασμού"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " χρήστης αφαιρέθηκε"]), _normalize([_interpolate(_named("n")), " χρήστες αφαιρέθηκαν"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Όταν αφαιρείτε έναν χρήστη, χάνει την πρόσβασή του στον λογαριασμό σας. Οι έλεγχοι, τα σχόλια και οι αξιολογήσεις του θα παραμείνουν στο Zendesk QA. ", _interpolate(_named("linebreak")), "Η αφαίρεση ενός χρήστη δεν θα επηρεάσει τον αριθμό των αδειών στη συνδρομή σας."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Αυτός ο χρήστης θα αφαιρεθεί από όλους τους χώρους εργασίας που διαχειρίζεστε. ", _interpolate(_named("linebreak")), " Οι έλεγχοι, τα σχόλια και οι αξιολογήσεις του θα παραμείνουν ανέπαφα."]), _normalize(["Θα αφαιρεθούν από όλους τους χώρους εργασίας που διαχειρίζεστε. ", _interpolate(_named("linebreak")), " Όλοι οι έλεγχοι, τα σχόλια και οι αξιολογήσεις τους θα παραμείνουν ανέπαφα."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Αφαίρεση του χρήστη ", _interpolate(_named("name"))]), _normalize(["Αφαίρεση των χρηστών ", _interpolate(_named("name"))])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορεί να δει τα πάντα και να διαχειριστεί όλες τις ρυθμίσεις και την τιμολόγηση"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να δει τα πάντα και να διαχειριστεί τις προσωπικές ρυθμίσεις και τις ρυθμίσεις χώρου εργασίας, εκτός από την τιμολόγηση"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δικαιώματα λογαριασμού"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα δικαιώματα που αφορούν συγκεκριμένο χώρο εργασίας επιτρέπουν στον χρήστη να βλέπει και να επεξεργάζεται δεδομένα εντός του χώρου εργασίας στον οποίο βρίσκεται."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για το BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα ρομπότ σας εμφανίζονται εδώ καθώς συγχρονίζονται οι συζητήσεις στις οποίες έχουν συμμετάσχει"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν συγχρονιστεί ρομπότ"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επισήμανση ενός ρομπότ ως χρήστη θα του εκχωρήσει πρόσβαση για είσοδο. Τα δεδομένα ιστορικού του ρομπότ θα παραμείνουν διαθέσιμα στους πίνακες ελέγχου."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν τέτοια ρομπότ"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε ξανά την αναζήτηση χρησιμοποιώντας διαφορετική λέξη-κλειδί ή ορθογραφία."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση για ρομπότ"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση το όνομα ρομπότ"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως χρήστη"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο οι διαχειριστές και οι υπεύθυνοι λογαριασμών μπορούν να επισημαίνουν τα ρομπότ ως χρήστες"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα ρομπότ"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία συνομιλία"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισημάνθηκε μη αυτόματα"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δυνατότητα ελέγχου"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όχι"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ρομπότ αφαιρείται από την αυτόματη ανάλυση και από τυχόν αναθέσεις. Τα νέα δεδομένα δεν θα εμφανίζονται στους πίνακες ελέγχου, εξασφαλίζοντας ακριβείς αναφορές και ευκολότερη διαχείριση των ρομπότ."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ναι"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενετικό ρομπότ"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άγνωστο"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ ροής εργασιών"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σε ομάδες"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι ομάδες"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει ομάδα"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση από ομάδες"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ομάδα"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δικαίωμα λογαριασμού"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη σε χώρους εργασίας"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονοματεπώνυμο"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία δραστηριότητα"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρόλος σε επίπεδο λογαριασμού"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε κάθε χρήστη εκχωρείται ένα δικαίωμα σε επίπεδο λογαριασμού ή/και χώρου εργασίας. Τα δικαιώματα Διαχειριστή είναι πλέον σε επίπεδο λογαριασμού, ενώ τα υπόλοιπα δικαιώματα σε επίπεδο χώρου εργασίας."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση από χώρους εργασίας"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δικαίωμα χώρου εργασίας"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση δικαιωμάτων σε ομάδα"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία λεπτομερειών"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισήμανση ως ρομπότ"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επισήμανση ενός χρήστη ως ρομπότ θα αφαιρέσει την πρόσβασή του στην είσοδο. Τα δεδομένα χρήστη ιστορικού θα παραμείνουν διαθέσιμα στους πίνακες ελέγχου."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαιρέστε το ρομπότ από την αυτόματη ανάλυση και από τυχόν αναθέσεις. Τα νέα δεδομένα δεν θα εμφανίζονται στους πίνακες ελέγχου, εξασφαλίζοντας ακριβείς αναφορές και ευκολότερη διαχείριση των ρομπότ."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαίρεση του ρομπότ από ελέγχους"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο χρήστης επισημάνθηκε ως ρομπότ"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο ο τύπος δικαιώματος \"Χρήστης\" μπορεί να επισημανθεί ως ρομπότ"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι χρήστες με πολλαπλούς λογαριασμούς δεν μπορούν να επισημανθούν ως ρομπότ"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση ανά χρήστη"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρομπότ"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες, ρομπότ και χώροι εργασίας"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΧΩΡΟΙ ΕΡΓΑΣΙΑΣ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες και χώροι εργασίας"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχειριστής"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν δικαιώματα"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοί"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδεδεμένοι"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προστέθηκε μη αυτόματα"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εκκρεμότητα"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση σε εκκρεμότητα"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαιτείται ενέργεια"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...και ένας ακόμη"]), _normalize(["...και ", _interpolate(_named("n")), " ακόμη"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή δικαιώματος σε"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Αφαιρέστε και προσκαλέστε ξανά για να αλλάξετε τον ρόλο ", _interpolate(_named("n")), " χρήστη με πρόσκληση σε εκκρεμότητα."]), _normalize(["Αφαιρέστε και προσκαλέστε ξανά για να αλλάξετε τον ρόλο ", _interpolate(_named("n")), " χρηστών με πρόσκληση σε εκκρεμότητα"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Επεξεργασία λεπτομερειών για τον χρήστη ", _interpolate(_named("name"))]), _normalize(["Επεξεργασία λεπτομερειών για ", _interpolate(_named("n")), " επιλεγμένους χρήστες"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " χρήστης ενημερώθηκε"]), _normalize([_interpolate(_named("n")), " χρήστες ενημερώθηκαν."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Αυτή η ομάδα περιέχει 1 χρήστη από άλλο χώρο εργασίας."]), _normalize(["Αυτή η ομάδα περιέχει ", _interpolate(_named("n")), " χρήστες από άλλο χώρο εργασίας."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποίηση"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" αρχειοθετήθηκε"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθετημένη ομάδα"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ομάδας"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" δημιουργήθηκε"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδα χωρίς τίτλο"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οριστική διαγραφή αυτής της ομάδας;"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή ομάδας"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή ομάδας"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία ομάδας"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικεφαλής ομάδας"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρόλος ομάδας"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" έγινε κρυφή"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η ομάδα δεν θα είναι ορατή πουθενά αλλού στο Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση μόνο δικαιωμάτων χώρου εργασίας"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ομάδα δεν είναι ορατή στο Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικεφαλής"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλος"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδα χωρίς τίτλο"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια άλλη ομάδα έχει ήδη αυτό το όνομα"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια αρχειοθετημένη ομάδα έχει ήδη αυτό το όνομα"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ρόλος σας επιτρέπει μόνο τη διαχείριση μελών αυτής της ομάδας. Επικοινωνήστε με έναν χρήστη με υψηλότερα δικαιώματα για περαιτέρω αλλαγές."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάργηση επικεφαλής ομάδας"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" ανακτήθηκε"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" αποθηκεύτηκε"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμός ως επικεφαλής ομάδας"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" αφαιρέθηκε από το αρχείο"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη αποθηκευμένες αλλαγές"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε τους υπάρχοντες χρήστες σας σε αυτήν την ομάδα"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση το όνομα ή το email"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη μελών"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Η ομάδα \"", _interpolate(_named("name")), "\" έγινε ορατή"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κάθε μέλος είναι ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τις ομάδες"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορισμένα χαρακτηριστικά των ομάδων παρέχονται και συγχρονίζονται αυτόματα από τον πάροχο ταυτοτήτων σας."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάθετε για τις ομάδες"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ομάδας"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οργανώστε εύκολα τα μέλη της ομάδας εργασίας σας και μιμηθείτε την οργανωτική δομή σας."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε τουλάχιστον 1 μέλος"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα όνομα ομάδας"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπάρχει ήδη μια ομάδα με αυτό το όνομα"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλη ομάδας"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα ομάδας"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οργανώστε τα μέλη της ομάδας εργασίας σας και μιμηθείτε την οργανωτική δομή σας."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέλη"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Καμία ομάδα"]), _normalize(["1 ομάδα"]), _normalize([_interpolate(_named("count")), " ομάδες"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια νέα ομάδα ή ενεργοποιήστε μια αρχειοθετημένη ομάδα."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διαθέσιμες ενεργές ομάδες"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν αρχειοθετημένες ομάδες"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν τέτοιες ομάδες"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε ξανά την αναζήτηση χρησιμοποιώντας μια διαφορετική λέξη-κλειδί ή ορθογραφία."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί ομάδες"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["σε"]), _normalize(["σε"]), _normalize(["σε"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση το όνομα ομάδας"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργές"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθετημένες"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Πιθανή επανάληψη αποστολής ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Απομένουν ", _interpolate(_named("n")), " άδειες"]), _normalize(["Απομένει ", _interpolate(_named("n")), " άδεια"]), _normalize(["Απομένουν ", _interpolate(_named("n")), " άδειες"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο αριθμός αδειών σας είναι περιορισμένος αυτήν τη στιγμή, καθώς η συνδρομή σας είναι σε παύση. Ενεργοποιήστε την ξανά για να προσθέσετε περισσότερους χρήστες."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε συμπληρώσει το όριο των αδειών του λογαριασμού σας. Μπορείτε να αντικαταστήσετε χρήστες απενεργοποιώντας έναν ενεργό χρήστη ή προσθέτοντας περισσότερες άδειες."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή στοιχείων πληρωμής"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " επιλέχθηκε"]), _normalize([_interpolate(_named("n")), " επιλέχθηκαν"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " χρήστης επιλέχθηκε"]), _normalize([_interpolate(_named("n")), " χρήστες επιλέχθηκαν"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " γραμμές"]), _normalize([_interpolate(_named("n")), " γραμμή"]), _normalize([_interpolate(_named("n")), " γραμμές"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε χρήστες μαζικά με βάση τα ονόματα ή τις διευθύνσεις email τους."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικολλήστε δεδομένα εδώ διαχωρισμένα με κόμματα ή αλλαγές γραμμής"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαζική αναζήτηση χρηστών"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Επεξεργασία δικαιωμάτων χώρου εργασίας για τον χρήστη ", _interpolate(_named("name"))]), _normalize(["Επεξεργασία δικαιωμάτων χώρου εργασίας για ", _interpolate(_named("n")), " επιλεγμένα μέλη"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία δικαιωμάτων"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε δικαίωμα χώρου εργασίας"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Αφαίρεση του χρήστη ", _interpolate(_named("name")), " από τον χώρο εργασίας"]), _normalize(["Αφαίρεση των μελών ", _interpolate(_named("name")), " από τον χώρο εργασίας"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " μέλος αφαιρέθηκε"]), _normalize([_interpolate(_named("n")), " μέλη αφαιρέθηκαν"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Αφαίρεση μέλους"]), _normalize(["Αφαίρεση μελών"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Αυτός ο χρήστης θα αφαιρεθεί από τον χώρο εργασίας."]), _normalize(["Αυτοί οι χρήστες θα αφαιρεθούν από τον χώρο εργασίας."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το δικαίωμα χώρου εργασίας \"", _interpolate(_list(0)), "\" άλλαξε."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη μελών"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει συζητήσεις και σχετικούς ελέγχους"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει τα πάντα στον χώρο εργασίας, αλλά διαχειρίζεται μόνο κουίζ, αναθέσεις, διενέξεις και συνεδρίες ευθυγράμμισης"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δικαιώματα χώρου εργασίας"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει και διαχειρίζεται τα πάντα εντός του χώρου εργασίας"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βλέπει τα πάντα στον χώρο εργασίας, εκτός από τις ρυθμίσεις χώρου εργασίας"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συνδέστε χρήστες"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες από τους χώρους εργασίας που διαχειρίζεστε έχουν προστεθεί σε αυτόν τον χώρο εργασίας."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζητήστε από έναν διαχειριστή να το κάνει."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσκαλέστε νέους χρήστες απευθείας εσείς οι ίδιοι ή ζητήστε από έναν διαχειριστή να το κάνει."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " ή ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσκαλέστε"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν χρήστες για εμφάνιση"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" Προστέθηκε ", _interpolate(_named("n")), " χρήστης"]), _normalize(["Προστέθηκαν ", _interpolate(_named("n")), " χρήστες"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Προσθήκη μελών"]), _normalize(["Προσθήκη μέλους"]), _normalize(["Προσθήκη ", _interpolate(_named("n")), " μελών"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε δικαίωμα χώρου εργασίας"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χρήστες για τον χώρο εργασίας"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Προσθήκη μελών σε ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικολλήστε email εδώ διαχωρισμένα με αλλαγές γραμμής ή κόμματα"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Αντιστοιχίστηκαν ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " email"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" με ήδη συνδεδεμένους χρήστες:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Δεν ήταν δυνατή η αντιστοίχιση ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " email"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε την ορθογραφία ή συνδέστε τα αργότερα μεμονωμένα"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" με χρήστες της υπηρεσίας βοήθειας:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " χρήστης συνδέθηκε"]), _normalize([_interpolate(_named("n")), " χρήστες συνδέθηκαν"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " χρήστης προσκλήθηκε"]), _normalize([_interpolate(_named("n")), " χρήστες προσκλήθηκαν"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαζική σύνδεση"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαζική πρόσκληση"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για παράδειγμα:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματοποιήστε σύνδεση από τις υπηρεσίες βοήθειας, για να ελέγξετε τις αλληλεπιδράσεις τους"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Σύνδεση χρήστη"]), _normalize(["Σύνδεση χρηστών"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση μεμονωμένα"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αν θέλετε οι συνδεδεμένοι χρήστες σας να έχουν πρόσβαση στο Zendesk QA, μπορείτε να τους προσκαλέσετε εδώ."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση συνδεδεμένων χρηστών"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Πρόσκληση χρήστη"]), _normalize(["Πρόσκληση χρηστών"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράλειψη πρόσκλησης"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χρήστες για σύνδεση από τις υπηρεσίες βοήθειας, για να ελέγξετε τις αλληλεπιδράσεις τους"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχειριστείτε τη συνδρομή σας"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση χρηστών υπηρεσίας βοήθειας"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε στον χώρο εργασίας σας ένα μοναδικό όνομα"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν είναι ενεργοποιημένο, όλες οι υπάρχουσες συνδέσεις λογαριασμού προστίθενται αυτόματα στον νέο χώρο εργασίας."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη όλων των συνδέσεων σε αυτόν τον χώρο εργασίας"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία χώρου εργασίας"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα χώρου εργασίας"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία χώρου εργασίας"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στους χρήστες που προστίθενται απευθείας στον λογαριασμό (χωρίς να τους εκχωρείται χώρος εργασίας) εκχωρείται ένας ρόλος σε επίπεδο λογαριασμού. Μπορείτε να τους προσθέσετε σε χώρους εργασίας αργότερα."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στους προσκεκλημένους χρήστες εκχωρείται αυτόματα ένας ρόλος χρήστη στο επίπεδο λογαριασμού. Μπορείτε να τους αναβαθμίσετε σε ρόλο Διαχειριστή αργότερα."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιήστε όποιον από τους παρακάτω τρόπους."])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικολλήστε email εδώ διαχωρισμένα με αλλαγές γραμμής ή κόμματα."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " χρήστης προσκλήθηκε μέσω email"]), _normalize([_interpolate(_named("n")), " χρήστες προσκλήθηκε μέσω email"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στους χρήστες που προστίθενται σε έναν χώρο εργασίας εκχωρείται αυτόματα ένας ρόλος χρήστη στο επίπεδο λογαριασμού. Μπορείτε να τους αναβαθμίσετε σε ρόλο Διαχειριστή αργότερα."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη και άλλου"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσκαλέστε χρήστες μόνο στον λογαριασμό χωρίς να τους προσθέσετε σε χώρο εργασίας"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στους προσκεκλημένους χρήστες εκχωρείται μόνο ένα δικαίωμα λογαριασμού και δεν προστίθενται σε χώρους εργασίας."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσκαλέστε χρήστες απευθείας σε έναν χώρο εργασίας και εκχωρήστε δικαιώματα χώρου εργασίας"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε χρήστες στον χώρο εργασίας"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαζική πρόσκληση"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση μεμονωμένα"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση μόνο στον λογαριασμό"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση σε χώρο εργασίας"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Πρόσκληση χρηστών"]), _normalize(["Πρόσκληση χρήστη"]), _normalize(["Πρόσκληση ", _interpolate(_named("n")), " χρηστών"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαζική πρόσκληση χρηστών μέσω email"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Διαγραφή χρήστη"]), _normalize(["Διαγραφή χρηστών"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποεπιλογή"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσκληση χρηστών μέσω email"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν χώροι εργασίας"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε δικαίωμα"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επανάληψη αποστολής πρόσκλησης"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδεδεμένος χρήστης"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης με εκκρεμή πρόσκληση email"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε χώρο εργασίας"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπρόσωποι τεχνητής νοημοσύνης"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κεντρική διαχείριση"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιοτικός έλεγχος"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση εργατικού δυναμικού"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ολοκληρώθηκαν ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Ολοκληρώθηκε ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Ολοκληρώθηκαν ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Κύκλος ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτής"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία ανάθεσης"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξοικονομήστε χρόνο και ρυθμίστε αυτόματες αναθέσεις ελέγχων αντί να βρίσκετε και να κατανέμετε μη αυτόματα συζητήσεις για έλεγχο."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Προθεσμία ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι συνεδρίες μπορούν να προστεθούν από την ενότητα \"Ευθυγράμμιση\" στο πλευρικό μενού της προβολής συζήτησης."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί συνεδρίες ακόμη"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["βάση αναφοράς"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συνεδρίες"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορούν να προστεθούν συζητήσεις σε μια συνεδρία κάνοντας κλικ στο εικονίδιο ευθυγράμμισης στην κεφαλίδα της προβολής συζήτησης."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν προστεθεί συζητήσεις ακόμη"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δεν έχουν δοθεί έλεγχοι. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε έναν έλεγχο ευθυγράμμισης"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βασική αιτία"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετάβαση σε"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρτωση της παλέτας εντολών απέτυχε."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε τίποτα."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρέθηκαν με βάση το αναγνωριστικό"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση παλέτας εντολών…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σελίδες, φίλτρα, συζητήσεις, ρυθμίσεις…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συζήτηση"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιωτικό φίλτρο"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημόσιο φίλτρο"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Από ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Από το API σας"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Εμφανίζονται ", _interpolate(_named("rangeStart")), " έως ", _interpolate(_named("rangeEnd")), " από ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Εμφανίζονται ", _interpolate(_named("rangeStart")), " έως ", _interpolate(_named("rangeEnd")), " από ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόκρυψη πλευρικού πλαισίου"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση πλευρικού πλαισίου"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή πλευρικής εργαλειοθήκης"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδεκτές διενέξεις"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απορριφθείσες διενέξεις"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές διενέξεις"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εν μέρει αποδεκτές διενέξεις"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που έγιναν"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που ελήφθησαν"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι που προβλήθηκαν"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αποδεκτές"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανοιχτές"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εν μέρει αποδεκτές"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " του συνόλου"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["απορριφθείσες"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδεκτές"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εν μέρει αποδεκτές"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απορριφθείσες"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["έλεγχος"]), _normalize(["έλεγχοι"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός των ελέγχων τους που έχουν δει οι ελεγχόμενοι"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός των ελέγχων τους που έχουν προβληθεί"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελεγκτές για τους οποίους έχει υποβληθεί διένεξη"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άτομα που έχουν υποβάλει διένεξη"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις κατά κατηγορία"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διενέξεις στην πάροδο του χρόνου"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος χρόνος επίλυσης"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος χρόνος επίλυσης (ώρες)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος χρόνος επίλυσης (λεπτά)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλύθηκαν"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικές διενέξεις"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδεκτές"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα απάντησης"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν γλώσσες"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι γλώσσες"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβλεπόμενοι παράγοντες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν παράγοντες"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι παράγοντες"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λέξεις του cloud"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν λέξεις"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι λέξεις"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισημάνσεις"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νεότεροι"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παλαιότεροι"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανοιχτές"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εν μέρει αποδεκτές"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ο χρήστης ", _interpolate(_list(0)), " αντέδρασε με ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απορριφθείσες"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλύθηκαν"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχοι"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τόνο δέρματος"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεδρίες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν συνεδρίες"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κατηγορίες"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλια"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέγεθος σχολίου"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν μεγέθη"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα μεγέθη"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν φίλτρα"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομάδες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν ομάδες"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι ομάδες"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν hashtag"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα hashtag"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν τιμές"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι τιμές"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν εξατομικευμένα πεδία"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες υπηρεσίας βοήθειας"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν ετικέτες της υπηρεσίας βοήθειας"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι ετικέτες της υπηρεσίας βοήθειας"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση αποτελεσμάτων…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύποι ερωτήσεων"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν τύποι ερωτήσεων"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη αντίδρασης"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτες βαθμολογίας"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κάρτες βαθμολογίας"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι κάρτες βαθμολογίας"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογίες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν βαθμολογίες"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι βαθμολογίες"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προελεύσεις"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν προελεύσεις"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι προελεύσεις"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος των παρατηρήσεων"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν λόγοι"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι λόγοι"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έρευνες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν έρευνες"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλες οι έρευνες"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανάλια"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν κανάλια"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα κανάλια"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστες"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν χρήστες"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χρήστες"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν χώροι εργασίας"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλοι οι χώροι εργασίας"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στα κουίζ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ έχει διαγραφεί ή δεν έχει δημοσιευτεί ακόμη. Ελέγξτε ξανά τον σύνδεσμο ή πλοηγηθείτε πίσω στη λίστα των κουίζ."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το κουίζ δεν έχει κοινοποιηθεί σε χώρο εργασίας στον οποίο ανήκετε. Ζητήστε από έναν διαχειριστή να σας προσθέσει σε έναν χώρο εργασίας στον οποίο έχει πρόσβαση αυτό το κουίζ."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ δεν είναι διαθέσιμο"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πρόσβαση στο κουίζ έχει δοθεί σε χώρους εργασίας στους οποίους δεν ανήκετε. Δεν είναι δυνατή η αποθήκευση και η δημοσίευση του κουίζ."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζητήστε από έναν διαχειριστή να αποθηκεύσει και να δημοσιεύσει το κουίζ."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα πρέπει να επισημάνετε τουλάχιστον μία από τις απαντήσεις ως τη σωστή."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο δεν μπορεί να είναι κενό."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτήν τη στιγμή βλέπετε μια προεπισκόπηση του κουίζ."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ποιοι χώροι εργασίας θα έχουν πρόσβαση στο κουίζ. Οι χρήστες που ανήκουν σε πολλούς χώρους εργασίας μπορούν να απαντήσουν στο κουίζ μόνο μία φορά. Η επιλογή \"Όλοι οι χώροι εργασίας\" δεν θα περιλαμβάνει τους νέους χώρους εργασίας μετά τη δημοσίευση του κουίζ."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσβαση στο κουίζ"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθέτηση"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έξοδος από τη λειτουργία προεπισκόπησης"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαγωγή"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προεπισκόπηση"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ερωτήσεις"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρώστε το κουίζ"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση κουίζ"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις κουίζ"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση ως πρόχειρο"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία νέου κουίζ"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση από το αρχείο"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επιλογές"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη επιλογής"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινοποιήστε το"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ωραίο, έτσι δεν είναι;"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η απάντησή σας υποβλήθηκε"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ σας αποθηκεύτηκε"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ σας δημοσιεύτηκε"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ σας αφαιρέθηκε"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη ερώτησης"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθέτηση κουίζ"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα αρχειοθετημένο κουίζ και τα αποτελέσματά του δεν θα εμφανίζονται πλέον στη λίστα των κουίζ. Εξακολουθείτε να έχετε πρόσβαση στα αποτελέσματα ενός αρχειοθετημένου κουίζ, και μπορείτε να το αφαιρέσετε από το αρχείο όποτε χρειαστεί."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθέτηση κουίζ"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν δημοσιευτεί ένα κουίζ, δεν είναι δυνατή η αλλαγή ή η επεξεργασία του. Όλα τα δημοσιευμένα κουίζ θα είναι προσβάσιμα σε κάθε χρήστη του λογαριασμού σας."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση κουίζ;"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το κουίζ; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κουίζ;"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφού υποβληθούν, οι απαντήσεις σας δεν μπορούν να αλλάξουν."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή κουίζ;"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση κουίζ από το αρχείο"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αφαίρεση του κουίζ από το αρχείο το καθιστά διαθέσιμο στην επισκόπηση κουίζ. Θα επιτρέψει στους προηγούμενους συμμετέχοντες να έχουν πρόσβαση στα αποτελέσματά τους, και στους νέους να συμπληρώσουν το κουίζ."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση κουίζ από το αρχείο"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου της ερώτησης"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή (προαιρετικά)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετακίνηση ερώτησης προς τα κάτω"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετακίνηση ερώτησης προς τα πάνω"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ερώτηση"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο ερωτήσεις με απαντήσεις εμφανίζονται στην προεπισκόπηση."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση ερώτησης"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή κουίζ"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ χωρίς τίτλο"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τίτλος κουίζ"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αυτήν την ενέργεια θα διαγραφεί οριστικά το κουίζ και τα αποτελέσματά του."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλακτικά, μπορείτε να έχετε πρόσβαση στα αποτελέσματα αρχειοθετώντας το κουίζ."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήθηκε"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή κουίζ"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με αυτήν την ενέργεια θα διαγραφεί οριστικά το κουίζ και τα αποτελέσματά του."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Διαγραφή κουίζ ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία αντιγράφου"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα κουίζ συμβάλλουν στην ενίσχυση της αυτοπεποίθησης και των δεξιοτήτων της ομάδας εργασίας, στην παροχή σταθερών εμπειριών και στην ένταξη νέων μελών της ομάδας εργασίας."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να αναζητήσετε κουίζ με βάση το όνομα του κουίζ ή το όνομα του συντάκτη."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί κουίζ ακόμη"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαγωγή λίστας κουίζ"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέση βαθμολογία"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ολοκλήρωσης"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ/Συντάκτης"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ / Συντάκτης / Χώροι εργασίας"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετέχοντες"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσίευση"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώροι εργασίας"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση το κουίζ, τον συντάκτη"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχειοθετημένο"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόχειρο"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργό"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα τα κουίζ"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ αρχειοθετήθηκε."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κουίζ αφαιρέθηκε από το αρχείο."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το κουίζ δεν έλαβε απαντήσεις από τους χρήστες στους επιλεγμένους χώρους εργασίας."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το κουίζ δεν έλαβε απαντήσεις πριν αρχειοθετηθεί."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν απαντήσεις"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εδώ θα δείτε μια ανάλυση των απαντήσεων του κουίζ.\nΚανείς δεν έχει συμπληρώσει ακόμη το κουίζ σας – θα βοηθούσε σίγουρα η κοινοποίησή του."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν απαντήσεις ακόμη"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαντήσεις:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέση βαθμολογία:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεμονωμένες"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – Αντίγραφο"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο σύνδεσμος αντιγράφηκε"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν αποτελέσματα"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Κουίζ του χρήστη ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να αναζητήσετε στον πίνακα κατάταξης το όνομα του συμμετέχοντα."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαγωγή πίνακα κατάταξης"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήστης"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βαθμολογία"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση με βάση τον συμμετέχοντα"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας κατάταξης"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κουίζ"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εναλλαγή πίνακα κατάταξης"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό σημαίνει ότι δεν θα μπορείτε να φιλτράρετε για αυτό το hashtag στον πίνακα ελέγχου και δεν θα συμπληρώνεται αυτόματα στα σχόλια πλέον. Αυτή η ενέργεια είναι μη αναστρέψιμη."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία hashtag"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία hashtag"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία hashtag"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να επιτρέπεται στα μέλη να δημιουργούν νέα hashtag"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε χρήση"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Διαγραφή hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δημιουργία hashtag είναι κλειδωμένη"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η δημιουργία hashtag είναι ξεκλείδωτη"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το hashtag '", _interpolate(_list(0)), "' διαγράφηκε"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Το hashtag '", _interpolate(_list(0)), "' μετονομάστηκε σε '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Προστέθηκε 1 hashtag"]), _normalize(["Προστέθηκαν ", _interpolate(_named("count")), " hashtag"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέο όνομα"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δημιουργηθεί hashtag"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετονομασία"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Μετονομασία hashtag \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχωρίστε τα hashtag με κόμματα ή αλλαγές γραμμής"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε ένα hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Μην ανησυχείτε. Μπορείτε να το κάνετε ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ακριβώς εδώ"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η σύνδεση συνδέθηκε με τον χώρο εργασίας"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσάρτηση σύνδεσης"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσαρτήστε μια σύνδεση σε αυτόν τον χώρο εργασίας"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνήστε με τον διαχειριστή σας για να κάνετε αλλαγές εδώ"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνήστε με το Zendesk QA για να κάνετε αλλαγές εδώ"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συνδέσεις στον λογαριασμό"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φαίνεται ότι δεν έχετε ενσωματώσει ακόμη μια πλατφόρμα εξυπηρέτησης πελατών."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Με τη διαγραφή μιας σύνδεσης θα αφαιρεθούν οι συζητήσεις από τον χώρο εργασίας ", _interpolate(_named("workspaceName")), " και θα διαγραφούν όλα τα δεδομένα που σχετίζονται με αυτές, συμπεριλαμβανομένων των ελέγχων και των διενέξεων."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή σύνδεσης"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Διαγραφή σύνδεσης ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια σύνδεση"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Εντός της επιλεγμένης χρονικής περιόδου, ορισμένες ενεργές κατηγορίες Αυτόματου QA δεν υπήρχαν στην κάρτα βαθμολογίας σας. Οι συζητήσεις πριν από την τελευταία σας αλλαγή στις ", _interpolate(_named("date")), " ενδέχεται να μην έχουν αναλυθεί για αυτές τις κατηγορίες."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εδώ θα εμφανίζονται όλες οι παρατηρήσεις που δίνονται σε εσάς. Δεν υπάρχει τίποτα για εμφάνιση ακόμη."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν ληφθεί έλεγχοι."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση μόνο της δραστηριότητας σχολίων"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εδώ θα εμφανίζονται όλες οι παρατηρήσεις που δίνονται από εσάς. Ξεκινήστε από την προβολή \"Συζητήσεις\"."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν δοθεί έλεγχοι."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοθέντες έλεγχοι"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ληφθέντες έλεγχοι"])}
  }
}