import { computed, reactive, ref } from 'vue'
import type { NavigationGuardNext } from 'vue-router'
import { cloneDeep, debounce, isEmpty } from 'lodash-es'
import { createSharedComposable } from '@vueuse/core'
import {
  TemplateFilter_TemplateFilterTypeCode as TemplateFilterType,
  type TemplateFilter,
  type CustomCategoryTemplateCondition,
  AddCustomCategoryTemplateRequest_Scale,
  TemplateFilterCondition_TemplateFilterConditionCode as TemplateFilterConditionCode,
} from '@zendesk/zqa-services/autoqa'
import type {
  AdvancedScorecard_AccountCategory as AccountCategory,
  AdvancedScorecard_CreateCategoryRequest as CreateCategoryRequest,
  AdvancedScorecard_UpdateCategoryRequest as UpdateCategoryRequest,
} from '@zendesk/zqa-services/scorecards'
import {
  updateConditions,
  mapErrors,
  addCustomConditionMessagePhrase,
  removeCustomConditionMessagePhrase,
} from '@/modules/settings/components/autoqa/utils'
import { type CustomRatingCategoryFormErrors as FormErrors } from '@/modules/settings/components/autoqa/types'

import i18n from '@/i18n'
import { type FilterItemEmit } from '@/components/filters/types'
import alertDialog from '@/components/alertDialog'
import type { DeconstructedOption } from '@/modules/conversations/utils/filters'
import { ScorecardSetupType } from '../types'

const getEmptyErrors = (): FormErrors => ({
  name: null,
  ratingFilters: {},
})

export default createSharedComposable(() => {
  const getEmptyForm = (): UpdateCategoryRequest => ({
    id: undefined,
    name: '',
    type: ScorecardSetupType.MANUAL,
    description: '',
    customCategoryTemplate: {
      id: undefined,
      name: '',
      scale: AddCustomCategoryTemplateRequest_Scale.SCALE_2,
      conditions: [],
      ratings: [
        {
          id: undefined,
          ratingScaleNumericScore: '1',
          conditions: [
            {
              type: TemplateFilterType.AGENT_MESSAGE,
              condition: TemplateFilterConditionCode.STRING_CONTAINS,
              values: [{ value: '' }],
            },
          ],
          isOtherwise: false,
        },
        {
          id: undefined,
          ratingScaleNumericScore: '42',
          conditions: [],
          isOtherwise: true,
        },
      ],
    },
  })

  const form = reactive(getEmptyForm())
  const initialForm = ref<Partial<CreateCategoryRequest>>({})

  const resetInitialForm = () => {
    initialForm.value = cloneDeep(form)
  }

  const errors = ref(getEmptyErrors())

  const validateName = () => {
    errors.value.name = form.name ? null : i18n.t('settings.categories.create.name.errors.empty')
  }

  const validateAgentMessageFilters = () => {
    const rating = getRating(false)

    if (!rating) return

    const messageConditions = rating.conditions?.filter((c) => c.type === TemplateFilterType.AGENT_MESSAGE)

    errors.value = { ...errors.value, ...mapErrors(messageConditions) }
  }

  const validate = () => {
    validateName()
    validateAgentMessageFilters()
  }

  const getRating = (isOtherwise: boolean) =>
    form.customCategoryTemplate?.ratings.find((r) => r.isOtherwise === isOtherwise)

  const getRatingConditions = () => {
    const rating = getRating(false)
    return rating?.conditions
  }

  const getRatingCondition = (filterId: string) => {
    const rating = getRating(false)
    return rating.conditions.find((c) => c.type === filterId)
  }

  const getSpecificRatingCondition = (filterCondition: string) => {
    const rating = getRating(false)
    return rating.conditions.find((c) => c.condition === filterCondition)
  }

  const getSelectedCondition = (filter: TemplateFilter) => {
    const ratingCondition = getRatingCondition(filter.type)
    return ratingCondition?.condition || filter.conditions[0].condition
  }

  const getSelectedValue = (filterId: string) => {
    const ratingCondition = getRatingCondition(filterId)
    return ratingCondition?.values.map((v) => v.value) || []
  }

  const getRatingScore = (isOtherwise: boolean) => {
    const rating = getRating(isOtherwise)
    return Number(rating?.ratingScaleNumericScore)
  }

  const getConversationFilterValue = (filterId: string) =>
    form.customCategoryTemplate.conditions.find((c) => c.type === filterId)?.values.map((v) => v.value) || []

  const addRatingCondition = (filterId: TemplateFilterType) => {
    const rating = getRating(false)
    const condition = {
      type: filterId,
      condition: TemplateFilterConditionCode.STRING_NOT_CONTAINS,
      values: [{ value: '' }],
    }

    rating.conditions.push(condition)
  }

  const deleteRatingCondition = (condition: CustomCategoryTemplateCondition) => {
    const rating = getRating(false)

    rating.conditions.splice(1, 1)

    if (errors.value.ratingFilters?.[condition.type]) {
      validateAgentMessageFilters()
    }
  }

  const addPhrase = (selectedCondition: CustomCategoryTemplateCondition, conditionIndex: number) => {
    const rating = getRating(false)
    const otherConditions = getRatingConditions().filter((c) => c.condition !== selectedCondition.condition)
    const newCondition = addCustomConditionMessagePhrase(selectedCondition)

    if (conditionIndex === 0) rating.conditions = [newCondition, ...otherConditions]
    else rating.conditions = [...otherConditions, newCondition]
  }

  const removePhrase = (
    selectedCondition: CustomCategoryTemplateCondition,
    conditionIndex: number,
    phraseIndex: number,
  ) => {
    const rating = getRating(false)
    const otherConditions = rating.conditions.filter((c) => c.condition !== selectedCondition.condition)
    const updatedCondition = removeCustomConditionMessagePhrase(selectedCondition, phraseIndex)

    if (conditionIndex === 0) rating.conditions = [updatedCondition, ...otherConditions]
    else rating.conditions = [...otherConditions, updatedCondition]

    if (errors.value.ratingFilters?.[selectedCondition.type]) {
      validateAgentMessageFilters()
    }
  }

  const updateRating = (rating: number, isOtherwise: boolean) => {
    const oppositeRatingMap = {
      0: 1,
      1: 0,
    }

    form.customCategoryTemplate.ratings = form.customCategoryTemplate.ratings.map((r) => {
      const isUpdatedRating = r.isOtherwise === isOtherwise
      const shouldFlipRating = !isUpdatedRating && r.ratingScaleNumericScore === String(rating)

      if (isUpdatedRating) {
        return { ...r, ratingScaleNumericScore: String(rating) }
      }

      if (shouldFlipRating) {
        return { ...r, ratingScaleNumericScore: String(oppositeRatingMap[rating]) }
      }

      return r
    })
  }

  const updateCustomFilterCondition = (
    condition: CustomCategoryTemplateCondition,
    selected: TemplateFilterConditionCode,
  ) => {
    const rating = form.customCategoryTemplate.ratings.find((r) => r.isOtherwise === false)

    const selectedTemplate = rating.conditions.find(
      (c: CustomCategoryTemplateCondition) => c.condition === condition.condition,
    )
    selectedTemplate.condition = selected
  }

  const updateCustomFilter = debounce(
    (condition: CustomCategoryTemplateCondition, phrase: string, phraseId: number) => {
      const rating = form.customCategoryTemplate.ratings.find((r) => r.isOtherwise === false)

      const selected = rating.conditions.find(
        (c: CustomCategoryTemplateCondition) => c.condition === condition.condition,
      )
      selected.values[phraseId].value = phrase as string

      if (errors.value.ratingFilters?.[condition.type]) {
        validateAgentMessageFilters()
      }
    },
    300,
  )

  const updateFilter = (filterId: TemplateFilterType, { selectedCondition, value }: FilterItemEmit) => {
    const valueArray = Array.isArray(value) ? value : [value]

    const rating = form.customCategoryTemplate.ratings.find((r) => r.isOtherwise === false)
    const otherConditions = rating.conditions.filter((c: CustomCategoryTemplateCondition) => c.type !== filterId)
    const condition = {
      type: filterId,
      condition: TemplateFilterConditionCode[selectedCondition.name],
      values: (valueArray as string[]).map((v) => ({ value: v })),
    }

    rating.conditions = [...otherConditions, condition]
    validateAgentMessageFilters()
  }

  const updateCondition = (selectedFilters: DeconstructedOption[]) => {
    if (!selectedFilters.length) return (form.customCategoryTemplate.conditions = [])
    const conditions = updateConditions(selectedFilters)

    form.customCategoryTemplate.conditions = conditions
  }

  const setSetupType = (setupType: ScorecardSetupType) => {
    const isManual = setupType === ScorecardSetupType.MANUAL

    if (isManual) {
      form.customCategoryTemplate = undefined
      return
    }

    const { customCategoryTemplate } = getEmptyForm()

    form.customCategoryTemplate = {
      ...customCategoryTemplate,
      name: form.name,
    }
  }

  const resetErrors = () => {
    errors.value = getEmptyErrors()
  }

  const fillForm = (category: AccountCategory) => {
    form.id = category.id
    form.name = category.name
    form.description = category.description

    const isAuto = !isEmpty(category.customCategoryTemplate)
    const isSystem = !!category.autoQaCategory

    form.type = isAuto || isSystem ? ScorecardSetupType.AUTO : ScorecardSetupType.MANUAL

    if (isAuto) {
      const { id, scale, conditions, ratings } = category.customCategoryTemplate
      form.customCategoryTemplate = {
        id,
        name: category.name,
        scale,
        conditions: cloneDeep(conditions),
        ratings: cloneDeep(ratings),
      }
    }

    resetErrors()
  }

  const resetForm = () => {
    Object.assign(form, getEmptyForm())
    resetErrors()
  }

  const hasFilterErrors = computed(
    () => form.type !== ScorecardSetupType.MANUAL && !!Object.keys(errors.value.ratingFilters).length,
  )

  const hasErrors = computed(() => !!errors.value.name || hasFilterErrors.value)

  const handleRouteChange = async (next: NavigationGuardNext, pendingChanges: boolean) => {
    if (!pendingChanges) return next()

    const { isConfirmed } = await alertDialog('', {
      title: i18n.t('settings.navigation.changes_in_progress.message'),
      confirmButtonText: i18n.t('settings.navigation.changes_in_progress.confirm'),
      cancelButtonText: i18n.t('settings.navigation.changes_in_progress.cancel'),
    })

    if (isConfirmed) {
      resetInitialForm()
      return next()
    }
  }

  return {
    getEmptyForm,
    form,
    initialForm,
    resetInitialForm,
    errors,
    hasErrors,
    hasFilterErrors,
    validate,
    validateName,
    getRating,
    getRatingCondition,
    getSpecificRatingCondition,
    getRatingScore,
    getRatingConditions,
    getSelectedCondition,
    getSelectedValue,
    getConversationFilterValue,
    addRatingCondition,
    deleteRatingCondition,
    addPhrase,
    removePhrase,
    fillForm,
    resetForm,
    updateRating,
    updateFilter,
    updateCustomFilter,
    updateCustomFilterCondition,
    updateCondition,
    handleRouteChange,
    setSetupType,
  }
})
