import { scaleOrdinal } from 'd3-scale'
import { AgentDistributionCategory } from '@/modules/conversations/types'
import { sortMap } from './sortSubCategories'

const notZQAUserColor = 'gray-60'

export default {
  [AgentDistributionCategory.Performance]: {
    color: 'green',
    scale: scaleOrdinal(sortMap[AgentDistributionCategory.Performance], ['70', '60', '50', '40', '30', '20', '10']),
    forcedSliceColors: {
      '': notZQAUserColor,
    },
  },
  [AgentDistributionCategory.Csat]: {
    color: 'teal',
    scale: scaleOrdinal(
      sortMap[AgentDistributionCategory.Csat].filter((c) => c !== 'Low satisfaction score'),
      ['60', '50', '40', '30', '20', '10'],
    ),
    forcedSliceColors: {
      'Low satisfaction score': 'red-70',
      '': notZQAUserColor,
    },
  },
  [AgentDistributionCategory.Sentiment]: {
    color: 'yellow',
    scale: scaleOrdinal(
      sortMap[AgentDistributionCategory.Sentiment].filter((c) => c !== 'High negative sentiment'),
      ['60', '50', '40', '30', '20', '10'],
    ),
    forcedSliceColors: {
      'High negative sentiment': 'red-60',
      '': notZQAUserColor,
    },
  },
  [AgentDistributionCategory.Reviewed]: {
    color: 'blue',
    scale: scaleOrdinal(['30', '20', '10']),
    forcedSliceColors: {
      false: 'red-50',
    },
  },
}
