import type { ApiResponse } from '@/components/pickers/TagPicker.vue'

export const GROUP = 'USER_GROUP'
export const BOT = 'BOT'

export enum FilterValueType {
  Loading = 'loading',
  NoValues = 'noValues',
  SearchableTags = 'searchableTags',
  CustomField = 'customField',
  Generic = 'generic',
  Date = 'date',
  Text = 'text',
  MultirowText = 'multirow-text',
  Integer = 'integer',
}

export interface DropdownSelectedValue {
  label: string
  value: string
  name: string
  type?: string
  isCustom: boolean
  fieldId: string
}

interface Option extends DropdownSelectedValue {
  emojiSrc?: string
}

export type FilterItemValue = string | string[] | Date | DropdownSelectedValue[]

export interface Condition {
  name?: string
  label?: string
  valueRequired?: boolean
}

export interface ValueComponentProps {
  // FilterValueType.SearchableTags
  apiMethod?: () => (query: string) => Promise<ApiResponse>
  // FilterValueType.Generic
  labelKey?: keyof Option
  groups?: { name?: string; options: Option[] }[]
  noResultsText?: string
}

export interface FilterItemEmit {
  selectedCondition: Condition
  value: FilterItemValue
}

export interface FilterOption {
  name: string
  label: string
  description: string
  intelligenceIcon?: boolean
  botIcon?: boolean
  isSpotlight?: boolean
}

export interface AddedFilter {
  id: string
  label: string
  name: string
  showAddSimilarButton?: boolean
  description?: string
  value?: FilterItemValue
  selectedCondition: Condition
  conditions?: Condition[]
  valueType: FilterValueType
  valueComponentProps?: ValueComponentProps
  intelligenceIcon?: boolean
  botIcon?: boolean
  disabled: boolean
  addedFilter?: boolean
}
