import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_shadow = _resolveComponent("side-shadow")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: _normalizeClass(_ctx.$style.wrap)
  }, [
    _createElementVNode("div", {
      ref: "scrollContainer",
      class: _normalizeClass(_ctx.$style.scrollContainer),
      style: _normalizeStyle({ width: _ctx.width, height: _ctx.height }),
      onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ], 512 /* NEED_PATCH */),
      _createVNode(_component_side_shadow, {
        position: "top",
        active: _ctx.shadow.top
      }, null, 8 /* PROPS */, ["active"]),
      _createVNode(_component_side_shadow, {
        position: "right",
        active: _ctx.shadow.right
      }, null, 8 /* PROPS */, ["active"]),
      _createVNode(_component_side_shadow, {
        position: "bottom",
        active: _ctx.shadow.bottom
      }, null, 8 /* PROPS */, ["active"]),
      _createVNode(_component_side_shadow, {
        position: "left",
        active: _ctx.shadow.left
      }, null, 8 /* PROPS */, ["active"])
    ], 38 /* CLASS, STYLE, NEED_HYDRATION */)
  ], 2 /* CLASS */))
}