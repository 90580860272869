import { getLocale } from '@/i18n'
import { Locales, DevLocales, ProdLocales, type Locale, type DevLocale, type ProdLocale } from '@/i18n/types'
import { getLanguageDisplayName } from '@/utils/languageNames'
import { session } from '@/composables/useSession'

export const getLanguageLabel = (locale: string) => {
  if (locale === Locales.EnPseudo) return 'Pseudo English'
  if (locale === Locales.Latin) return 'Latin (Keys-only)'

  const selectedLanguageName = getLanguageDisplayName({ locale })
  if (locale === getLocale()) return selectedLanguageName

  const nativeLanguageName = getLanguageDisplayName({ locale, translateTo: locale })
  return `${selectedLanguageName} - ${nativeLanguageName}`
}

export const getAvailableLanguages = () => {
  const prodLocales = Object.values(ProdLocales).map((l: ProdLocale) => ({ label: getLanguageLabel(l), value: l }))
  const langs: { label: string; value: Locale }[] = [...prodLocales]

  if (session.features.zendeskTestLanguages) {
    const devLocales = Object.values(DevLocales).map((l: DevLocale) => ({ label: getLanguageLabel(l), value: l }))
    langs.push(...devLocales)
  }

  return langs.sort((a, b) => a.label.localeCompare(b.label))
}
