export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba su subdominio de Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión en Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su empresa ya tiene una cuenta."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse a una compañía"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para unirse a una compañía existente, pídale al administrador que le envíe una invitación."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicite al administrador que lo invite"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambié de parecer."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar registro"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su empresa ya tiene una cuenta. ¿Desea unirse a la cuenta de su empresa o crear una nueva?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar instrucciones"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta nueva"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya hay una cuenta"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede acceder a su cuenta de Zendesk QA hasta que se paguen las facturas de suscripción pendientes."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su suscripción está en pausa"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró la cuenta. Vuelva a intentarlo."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obtenga una nueva contraseña."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuelva a intentarlo u ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinación incorrecta de correo electrónico y contraseña."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicite una nueva a su administrador."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este vínculo de invitación no es válido o ya se ha utilizado."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El método de inicio de sesión ", _interpolate(_named("method")), " está desactivado en su cuenta."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta invitación no es válida o está en uso. Solicite una nueva a su administrador."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la información de la invitación."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló el registro. Es posible que el usuario con este correo electrónico ya exista."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuenta se bloqueó después de varios intentos de inicio de sesión consecutivos. Busque en su bandeja de entrada un correo electrónico con instrucciones sobre cómo desbloquearla."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique su correo electrónico primero"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una cuenta de correo electrónico válida"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de inicio de sesión"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar el vínculo mágico"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico de trabajo"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su correo electrónico de trabajo"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.es/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrato de servicios principales"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña no cumple los requisitos"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de privacidad"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.es/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hola, ", _interpolate(_named("invitee_name")), ":"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Únase a la organización ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su correo electrónico se verificó. Ahora puede iniciar sesión con su nueva cuenta a continuación."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculo de invitación no válido"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión usando el vínculo mágico"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver para iniciar sesión"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su correo electrónico se verificó. Ahora puede iniciar sesión con su nueva cuenta a continuación:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con un vínculo mágico"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión en Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuenta se ha borrado"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tiene una cuenta?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un código válido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una cuenta de correo electrónico válida"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de inicio de sesión"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las opciones de inicio de sesión"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " a través de métodos tradicionales"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión usando el vínculo mágico"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha recibido un correo electrónico que contiene su código de inicio de sesión."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha recibido un correo electrónico que puede seguir para iniciar sesión."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al enviar el vínculo mágico. Vuelva a intentarlo."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión por correo electrónico"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear contraseña"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carácter en minúscula"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carácter en mayúscula"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nueva contraseña debe contener"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longitud máxima de la contraseña es 128 caracteres"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil de recordar, difícil de adivinar"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo de 8 caracteres"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una contraseña"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidó su contraseña?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carácter en minúscula"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un número"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carácter en mayúscula"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restablecer su contraseña, ingrese su dirección de correo electrónico y le enviaremos un correo electrónico con instrucciones."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una cuenta de correo electrónico válida"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se ha enviado un correo electrónico a ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el correo electrónico no aparece en la bandeja de entrada o en la carpeta de correo no deseado, verifique el correo electrónico que proporcionó."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver para iniciar sesión"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise su correo electrónico"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", luego se le enviará un correo electrónico de restablecimiento de contraseña."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si su cuenta ya tiene una contraseña"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si eso ", _interpolate(_named("bold")), ", le enviamos un vínculo para restablecer su contraseña."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el correo electrónico está asociado con una cuenta"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Recordó su contraseña?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al enviar el vínculo de restablecimiento. Vuelva a intentarlo."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablezca su contraseña"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese usando el vínculo mágico"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Al registrarse, acepta los ", _interpolate(_named("terms_of_service")), " y ", _interpolate(_named("privacy_policy")), " de Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de servicio"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene una cuenta?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse con un vínculo mágico"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese con su correo electrónico de trabajo"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse con Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse en Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese con Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengo una cuenta activa de Zendesk"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Regístrese o inicie sesión para completar su configuración de ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿De verdad es usted? Busque en su bandeja de entrada un correo electrónico de confirmación."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya está listo"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA funciona mejor en equipos de escritorio. Inicie sesión ahí para comenzar a evaluar conversaciones."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve una demostración"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O bien, reserve una demostración con nosotros y le mostraremos lo que se puede hacer en Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se solicita su presencia"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["He cambiado de parecer y quiero ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " le invita a unirse a su cuenta."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse a la cuenta"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta nueva"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente volver a iniciar sesión"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La reacción que recibió por sus comentarios: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacciones recibidas"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reaccionó a su comentario"]), _normalize(["Varias personas reaccionaron a su comentario"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reaccionó a su respuesta"]), _normalize(["Varias personas reaccionaron a su respuesta"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reaccionó a su evaluación"]), _normalize(["Varias personas reaccionaron a su evaluación"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentario"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respuesta"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisión"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISPUTAS"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DADOS"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECIBIDOS"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la encuesta"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacciones"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzó"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["¿Marcar el comentario como leído?"]), _normalize(["¿Marcar todos los comentarios como leídos?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["¿Marcar la disputa como leída?"]), _normalize(["¿Marcar todas las disputas como leídas?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["¿Marcar los comentarios de la encuesta como leídos?"]), _normalize(["¿Marcar todos los comentarios de la encuesta como leídos?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["¿Marcar la evaluación como leída?"]), _normalize(["¿Marcar todas las evaluaciones como leídas?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentario marcado como leído"]), _normalize(["Todos los comentarios se marcaron como leídos"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Disputa marcada como leída"]), _normalize(["Todas las disputas se marcaron como leídas"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentarios de la encuesta marcados como leídos"]), _normalize(["Todos los comentarios de la encuesta marcados como leídos"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Evaluación marcada como leída"]), _normalize(["Evaluaciones marcadas como leídas"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar todas como leídas"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como leído"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluaciones"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si alguien cuestiona una evaluación, esta aparece aquí."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disputas"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando se le asigne una disputa para resolver, aparecerá aquí."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han recibido disputas"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando impugna una evaluación, esta aparece aquí."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disputas iniciadas"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los comentarios que se le envíen aparecen aquí. Aún no hay nada que mostrar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han recibido comentarios"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las reacciones a las evaluaciones y respuestas aparecen aquí. Aún no hay nada que mostrar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han recibido reacciones"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir una conversación"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los comentarios proporcionados por usted se ven aquí. Para comenzar, haga una evaluación en la vista Conversaciones."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han proporcionado evaluaciones"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los comentarios que se le envíen aparecen aquí. Aún no hay nada que mostrar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han recibido evaluaciones"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado comentarios"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los comentarios que realice aparecerán aquí."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay comentarios"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado comentarios"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los comentarios que está evaluando y ", "@", "las menciones o las respuestas a sus comentarios aparecen aquí."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han recibido comentarios"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disputas aceptadas"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disputas abiertas"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disputas rechazadas"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay disputas resueltas"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelva a intentarlo eligiendo otra opción en el filtro de arriba."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelva a intentarlo eligiendo a otro usuario en el filtro de arriba."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios dados"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios recibidos"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las disputas"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas recibidas"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas iniciadas"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios recibidos sobre la encuesta"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones dadas"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de actividades"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " evaluada(s)"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " evaluada(s)"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " evaluada(s)"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se reinicia en ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quedan ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar una asignación"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de asignaciones"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No es posible crear una sesión de capacitación entre ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), ", ya que no se encuentran en el mismo espacio de trabajo."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ya existen sesiones de capacitación entre ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), ". Contacte a ", _interpolate(_list(0)), " para obtener acceso a la sesión."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se creó una nueva sesión entre ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar instructor"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona que recibe la capacitación"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar persona que recibe la capacitación"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear sesión"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una sesión"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar sesión"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la sesión"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vencidas"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximas"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear sesión"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron sesiones"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo promedio de evaluación"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para mostrar para el período seleccionado"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Marcador agregado a la sesión. ", _interpolate(_named("buttonStart")), "Mostrarme", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a…"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregado"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcanzó el final de los marcadores del periodo seleccionado"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vea cómo funcionan los marcadores en este ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artículo"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Al parecer, no marcó nada en ", _interpolate(_list(0)), ". Puede usar marcadores para prepararse para un encuentro individual o para guardar algo para otro momento."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la persona que recibe la capacitación"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se han agregado marcadores"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Estos son todos los marcadores que ha creado en ", _interpolate(_named("name")), ". Los marcadores que se agreguen a las sesiones ", _interpolate(_named("bold")), " con el otro participante."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compartido de inmediato"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Al parecer, ", _interpolate(_list(0)), " no ha recibido comentarios para la evaluación de la conversación o CSAT durante el periodo seleccionado."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay comentarios disponibles"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha llegado al final de los comentarios del período seleccionado"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métricas"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcadores"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Los números de ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo elemento de acción"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Completado por ", _interpolate(_named("user")), " el ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["por ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usted"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba para agregar un nuevo elemento de acción"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué resultó de esta reunión? ¿Cuáles son los próximos pasos?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de acción"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creado por ", _interpolate(_named("name")), " el ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["borrar la sesión"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esto ", _interpolate(_named("deleteSession")), " de manera permanente. Los participantes pierden el acceso a la sesión, incluidos su contenido y sus notas privadas."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sesión creada el ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última edición de ", _interpolate(_named("name")), " el ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de conversación y notas"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se actualizó la nota"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonar sesión"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios seleccionados obtienen acceso a los detalles de la sesión a partir de este momento."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar usuarios"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartidas"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir sesión"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ya no será parte de la sesión de ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " dejará de formar parte de esta sesión."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " tiene acceso a esta sesión ahora."]), _normalize([_interpolate(_named("n")), " nuevos usuarios tienen acceso a esta sesión ahora"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar sesión"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todas las sesiones"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar sesión"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el instructor"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Las notas agregadas por ", _interpolate(_list(0)), " aparecerán aquí."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue elementos mostrados como puntos de discusión desde el panel de la derecha"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcadores"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas que solo usted puede ver…"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas personales"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota privada actualizada"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 días"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona que recibe la capacitación"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de acción"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última sesión"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En curso"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vencidas"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general sobre las sesiones"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo: Instructor"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo: Persona que recibe la capacitación"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las sesiones"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones compartidas"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente cambiar un filtro para ver los elementos de acción."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay elementos de acción"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de acción"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas y vistas"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibidas · Vistas"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso después de las sesiones"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al parecer, los agentes no han recibido comentarios."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique que tenga el nombre correcto del usuario"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo encontrar al usuario"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin datos disponibles"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS de los últimos 30 días"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esto <b>borrará todas las sesiones</b> (2) con <b>", _interpolate(_named("name")), "</b> de manera permanente. Los participantes pierden el acceso a todas las sesiones, incluidos el contenido y las notas privadas."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha llegado al final de todas las sesiones"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una sesión de capacitación es una actividad de grupo entre un mentor y un instructor para hablar sobre el rendimiento, las oportunidades y los objetivos a corto y largo plazo."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede buscar sesiones por el nombre del instructor y por la persona a la que está capacitando."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron sesiones"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se ha creado ninguna sesión"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar historial de capacitación"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar historial de capacitación "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última sesión"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las sesiones"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona que recibe la capacitación"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como terminado"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como no terminado"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de nombre"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de sesiones"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No programado"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder a este mensaje"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión de calibración vencida. No se pueden dejar más evaluaciones."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya dejó una evaluación para esta conversación."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya no es posible evaluar esta conversación porque la fecha de vencimiento de la sesión ya pasó."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversación no tiene evaluaciones"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar panel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar una evaluación de calibración"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETALLES"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESUMEN"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSCRIPCIÓN"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Orador ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aire muerto"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar orador"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar mensaje"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a reproducir el mensaje"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altavoces"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcripción"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios del espacio de trabajo"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue la conexión primero para crear filtros"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creado a las ", _interpolate(_named("time")), " por ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última actualización el ", _interpolate(_named("time")), " por ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Al parecer, no tiene filtros. Para acceder a esta página, primero debe crear un filtro. Comience con ", _interpolate(_named("recommendedFilter")), " para ver sus propias conversaciones. Cree este filtro a continuación para continuar."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en los últimos 30 días"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agregará la condición \"Creado en los últimos 30 días\" a los filtros creados sin un intervalo de fechas"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El filtro no está disponible en este plan y no se tendrá en cuenta al mostrar los resultados"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agotó el tiempo de espera para la obtención de las opciones de filtro. Vuelva a intentarlo."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre el filtrado"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado = persona que ha iniciado sesión"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Conversaciones encontradas: ", _interpolate(_named("count"))]), _normalize(["Conversación encontrada: ", _interpolate(_named("count"))]), _normalize(["Conversaciones encontradas: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está disponible en su plan actual. Actualice para usar esta función."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar condición"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IA de Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condición"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir en asignación"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear filtro"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear filtro"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos personalizados"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos cargando las opciones de filtros según su configuración específica. La carga inicial puede tardar un par de minutos."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigne un nombre descriptivo a su filtro"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del filtro"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condición"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privado"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar filtro"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay valores disponibles"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No encontrado"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un valor"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo sesiones activas ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar sesiones más antiguas ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de vencimiento"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar sesión de calibración"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear sesión"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear sesión de calibración"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las evaluaciones de calibración realizadas durante la sesión se borrarán de manera permanente."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar sesión"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar ", _interpolate(_named("sessionName")), " sesión de calibración"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha creado ninguna sesión"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay sesiones activas"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se crearon sesiones"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay sesiones activas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar filtro"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un filtro privado"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un filtro público"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro agregado"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El filtro se borrará de manera permanente."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar filtro"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar ", _interpolate(_named("filterName")), " filtro"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las disputas"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas para mí"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas mías"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas abiertas"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borradores"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron filtros"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se crearon filtros privados"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se crearon filtros públicos"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros privados"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros públicos"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es una vista de filtro temporal."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creando el filtro…"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro actualizado"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información valiosa"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de AutoQA personalizada"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última actualización ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras complejas"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las autoevaluaciones se actualizan de manera dinámica en función de los cambios del ticket"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resaltar en la conversación"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oraciones largas"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pronostica ninguna puntuación automática"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar ningún error"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar palabras complejas"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar oraciones largas"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima conversación"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA no pudo calificar esta categoría por falta de contexto. Se recomienda la revisión manual."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacado"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra información automatizada de las conversaciones para simplificar el proceso de evaluación. Resalte conversaciones cruciales y muestre ejemplos positivos destacados para su equipo."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Llamada]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregido"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " aceptó la disputa"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Disputa enviada a ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por qué cree que esta persona evaluada no es la destinataria correcta de sus comentarios"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un comentario y configurar una nueva persona evaluada"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un comentario"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por qué cree que estas puntuaciones están calificadas de manera incorrecta (obligatorio)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue un comentario (obligatorio)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por qué rechaza esta disputa"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un comentario"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar disputa a"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar disputa a"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar disputa a"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar nueva persona evaluada"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la disputa"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la disputa"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar persona evaluada"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar disputa a"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputa"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputar esta evaluación"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No estoy de acuerdo con las puntuaciones"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta evaluación no era para mí"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un usuario"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " aceptó parcialmente la disputa"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " rechazó la disputa"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptada"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente aceptada"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazado"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " inició una disputa: evaluar"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " inició una disputa: persona evaluada equivocada"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las disputas abiertas deben resolverse para poder realizar otras acciones en la evaluación."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar nuevas puntuaciones"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar disputa"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea descartar esta disputa?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar disputa"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputa"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de resolución de la disputa"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione nuevas puntuaciones justas (opcional)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar disputa"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada equivocada"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar disputa"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar disputa"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la disputa"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada equivocada"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la disputa"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar disputa"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar disputa"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la disputa"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " por ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["a ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisados"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignado a:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deje un comentario también"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre las tarjetas de puntuación condicionales"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteriormente asignado a:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija uno"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo encontrar ningún GIF con ese nombre"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sea el primero en dejar una evaluación."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su suscripción está actualmente en pausa. Vuelva a activarla para poder ofrecer comentarios."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado por:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto muestra cuánto tiempo se dedicó a evaluar esta conversación"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un usuario"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Falta la causa raíz para la categoría: “", _interpolate(_list(0)), "”"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Califique una categoría o agregue un comentario"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar…"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraer"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ampliar el campo de comentarios"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraer el campo de comentarios"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar formato"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar formato"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar evaluación"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Califique todas las categorías"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Califique por lo menos una categoría"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar esta conversación para después"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar para después"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima asignación"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reemplazar conversación en este ciclo de asignación"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar conversación"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación de referencia"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar comentario"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario borrado"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea borrar este comentario?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor del comentario"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este hashtag se ha borrado o se ha cambiado de nombre."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparta lo que le gustó y lo que podría haber sido mejor…"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentar"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condicional"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto borrará todos los comentarios de manera permanente."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar comentario"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Mensaje]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " aún no ha visto este comentario"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ha visto este comentario"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exmiembro"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar GIF…"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presione Intro para crear un nuevo hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los hashtags personalizados están bloqueados por el administrador."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilícelo para marcar casos como favoritos y filtrarlos más adelante"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como referencia"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre los mensajes"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima conversación"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay comentarios"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay evaluaciones hechas"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deje un comentario a continuación para comenzar."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No quedan más evaluaciones"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin calificar"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado anterior"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversación anterior"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar como referencia"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder a esta evaluación"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar barra lateral de evaluaciones"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto borrará la evaluación y todos los comentarios agregados de manera permanente."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar evaluación"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar evaluación"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación borrada"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Evaluación borrada por ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo."])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasígnese esta tarea a usted mismo para poder dejar una evaluación"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya dejó una evaluación para este usuario."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dejó una evaluación para ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay categorías para mostrar para esta conversación."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las autoevaluaciones no están disponibles para este espacio de trabajo."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No guardar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Guardar esta evaluación como borrador?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar este borrador"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar barra lateral de evaluaciones"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desaprobar"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobar"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobar con errores menores"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún calculando los números…"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar un comentario"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar este mensaje"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar usted mismo"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar esta conversación"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay usuarios para evaluar"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluaciones desactivadas"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún usuario"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios del espacio de trabajo"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar usuario"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 evaluación"]), _normalize([_interpolate(_named("count")), " evaluaciones"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar nuevas calificaciones"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El administrador del espacio de trabajo no ha permitido las autoevaluaciones."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presione Intro para etiquetar este marcador"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibraciones"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 30 días"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacadas"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una sesión de calibración"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borradores"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información valiosa"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información valiosa ofrece una representación visual de sus datos de help desk. Utilice los gráficos interactivos para comprender dónde enfocar sus esfuerzos de control de calidad, descubrir puntos ocultos y encontrar a los agentes correctos para revisar en función de su desempeño."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID de ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL de ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traducir a ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin asignar"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrado actualmente"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluando ahora"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversación se eliminó y ya no está disponible."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación que está buscando no existe en Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los marcadores que tiene esta conversación"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En sesión"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar conversación"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si borra una conversación de una sesión, se borrarán todas las evaluaciones que se le hayan asignado."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eliminar conversación de ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se han creado sesiones de calibración."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversación se ha agregado a la sesión"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversación se ha eliminado de la sesión"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar/eliminar de una sesión de calibración"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar el vínculo a esta conversación"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conversación para mostrar"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico del cliente"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del cliente"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del cliente"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del formulario externo"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de primera contestación"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridad"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario de la encuesta"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de satisfacción"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo hasta la primera resolución"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mensaje no existe en esta conversación o se ha borrado"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin asunto"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conversación para mostrar"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguna conversación"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir la conversación original"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede vincular a la conversación; falta una URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay participantes"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar conversación"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para leer las evaluaciones"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para evaluar"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversación creada mediante la extensión del navegador"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver encuesta de satisfacción del cliente"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver conversación"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje con tono negativo"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje con tono positivo"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducir al inglés"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar original"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar conversación"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La eliminación de una conversación es permanente. También borra todos los datos relacionados, por ejemplo, encuestas, transcripciones y revisiones del control de Zendesk QA, incluidos los paneles.", _interpolate(_named("lineBreak")), "Todavía podrá acceder a esta conversación en Zendesk Support o en otro software de help desk que esté usando."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La eliminación de una conversación es permanente. También borra todos los datos relacionados, por ejemplo, encuestas, transcripciones y revisiones del Zendesk QA, incluidos los paneles. Si la conversación pertenece a varios espacios de trabajo, se borrará de todos ellos.", _interpolate(_named("lineBreak")), " Aún podrá acceder a esta conversación en Zendesk Support o en otro software de help desk que esté usando."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar conversación"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar conversación del control de Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conversación está incompleta debido a una derivación. Vaya a ", _interpolate(_named("optionsUrl")), " y valide sus etiquetas. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conversación está incompleta debido a una derivación. Hable con el administrador sobre la validación de etiquetas. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiones > Opciones avanzadas"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre las etiquetas"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrado"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resuelto"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El agente asignado cambió a ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin asignar"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macro ", _interpolate(_named("target")), " aplicada por ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La conversación recibió una calificación de ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El usuario ", _interpolate(_named("target")), " suprimió la conversación"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Estado cambió a ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Etiquetado con ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Equipo cambiado a ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " pospuso la conversación para ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " activó la conversación"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot atascado en un bucle, repitiendo un mensaje consecutivamente"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetición de bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetición del bot, donde el mismo mensaje se repite una o más veces seguidas"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se encontraron frases coincidentes"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un agente promedio maneja la conversación ", _interpolate(_named("percent")), " más eficientemente que el bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiencia de la comunicación del bot"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El bot manejó la conversación ", _interpolate(_named("percent")), " más eficientemente que un agente promedio"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiencia de la comunicación del bot"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación carece de la declaración necesaria que informa a los participantes de la grabación, lo cual es necesario para el cumplimiento y la transparencia"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta la divulgación de la grabación"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se ha incumplido un contrato de nivel de servicio (SLA) para ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA incumplido"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La métrica de la regla de SLA superó el tiempo objetivo"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signos de abandono de clientes. El cliente estaba considerando cambiar o prometió irse."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riesgo de abandono identificado"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La conversación tiene momentos de inactividad que duran más que el umbral establecido de ", _interpolate(_list(0)), "s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instancia"]), _normalize([_interpolate(_named("count")), " instancias"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s más largo"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aire muerto"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente solicitó asistencia de alto nivel"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se solicitó una derivación"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente expresó sumo agradecimiento o estaba muy contento con el soporte recibido"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio excepcional"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente o el agente solicitó explícitamente un seguimiento"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de seguimiento (Follow-up request)"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente mostró insatisfacción"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono negativo"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación fue inusual o atípica y hubo idas y vueltas para llegar a una resolución"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se detectó un valor atípico"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente mostró satisfacción"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono positivo"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicable"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer como no aplicable"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última edición por ", _interpolate(_named("author")), " el ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplicable"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por ", _interpolate(_named("author")), " el ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una razón (opcional)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se detectó información valiosa"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar conexión"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte su help desk con su cuenta y cree al menos un filtro para ver la lista de conversaciones."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conversaciones para mostrar"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la configuración del espacio de trabajo"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(En un día)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Este mes)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Esta semana)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resultados de la búsqueda de ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadas por otros"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentadas"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más recientes"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más antiguas"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleatorias"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizadas recientemente"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respondidas recientemente"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin leer"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadas por usted"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El proceso de importación puede tardar un poco. Le enviaremos un correo electrónico cuando esté listo."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA está importando sus conversaciones"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Límite: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en el icono de calibrado en el encabezado de la vista de conversación para agregar."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay nada que informar aquí. Vuelva a intentarlo con otro filtro u opción de clasificación."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay valores atípicos para mostrar."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque una conversación relevante y haga clic en el icono de estrella en el encabezado para marcarla aquí."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente cambiar los filtros o invite a otros agentes de help desk a agregar más conversaciones."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin límite"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota interna"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos cargando conversaciones en este momento. Es posible que aún falte algo de contenido."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo usuario"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún usuario"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrantes del espacio de trabajo"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario anterior"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron conversaciones"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha agregado ninguna conversación"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las evaluaciones sin terminar se muestran aquí. Vuelva a leer, revisar y enviar en los próximos 30 días."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay evaluaciones en borrador"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conversaciones destacadas"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conversaciones para mostrar"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin coincidencia"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al mensaje seleccionado"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos respuestas"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más respuestas"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de conversaciones"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La llamada se respondió a las ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La llamada terminó a las ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La llamada se realizó a las ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisados"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadas por usted"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No se pudo cargar el archivo de audio. Asegúrese de haber iniciado sesión en ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario de la encuesta de satisfacción del cliente"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al cargar el audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor del comentario"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se realizó ninguna llamada"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escuchar en help desk"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escuchar en Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de reproducción"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar llamada"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcripción"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplicable"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es un usuario de Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA clasifica automáticamente a los agentes en función de su CSAT promedio mensual. Busque las conversaciones manejadas por agentes cuyo CSAT sea más bajo que el de sus pares con un solo clic."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación por CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy baja"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy alta"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es un usuario de Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA clasifica automáticamente a los agentes en grupos de desempeño según la cantidad de conversaciones que manejan por mes. Busque las conversaciones de los agentes con un volumen más bajo que sus pares con un solo clic."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación por volumen"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisados"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No revisados"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la evaluación"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplicable"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutro"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es un usuario de Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA clasifica automáticamente a los agentes en función del tono promedio expresado en las conversaciones. Busque interacciones en las que los agentes atienden a más clientes frustrados que sus pares con un solo clic."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación por tono"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No detectado"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectadas"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores atípicos"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones que requieren su atención. Nuestro modelo de aprendizaje automático identifica las interacciones complejas o atípicas para una evaluación más detallada."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valores atípicos"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivo"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione las conversaciones para evaluar en las que el cliente haya mostrado deleite o frustración. Utilice el menú desplegable para seleccionar el tipo de tono que busca en sus conversaciones. Arrastre el atributo de filtro hacia la parte superior para ver la proporción del tono elegido en todas sus interacciones."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la evaluación"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No evaluado"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisados"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA detecta automáticamente los idiomas en las conversaciones. Utilice el menú desplegable para seleccionar las conversaciones en idiomas específicos para evaluar. Arrastre el atributo de filtro hacia la parte superior para obtener una descripción general de todos los idiomas que se usan en el help desk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de las conversaciones"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puede ", _interpolate(_named("paragraph_2_item")), " con Información valiosa que con los filtros de conversación normales. Obtenga una visión general de todos los datos de sus help desks y explore información valiosa como:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre el uso de información de las conversaciones"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprenda la importancia relativa de los atributos específicos de una conversación en relación con los demás"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque los agentes con mejor o peor desempeño con la clasificación integrada de desempeño de los agentes"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " es una potente herramienta interactiva de minado de datos que ayuda a encontrar las conversaciones que más necesitan evaluación."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hacer mucho más"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Los gráficos Conversación y Agente se muestran ", _interpolate(_named("paragraph_3_item_1")), ". Si actualiza los filtros o hace clic en los segmentos del gráfico en cualquiera de ellos, se actualizará automáticamente la lista de conversaciones. ", _interpolate(_named("paragraph_3_item_2")), " para explorar desgloses detallados o proporciones de los distintos elementos."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconectados"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorganizar los atributos de los filtros"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La información de las conversaciones muestra solo ", _interpolate(_named("paragraph_4_item")), ". Eliminamos automáticamente las interacciones que no tengan un valor real de evaluación, como los mensajes generados automáticamente, el spam, las respuestas de bot o los mensajes que no tengan un diálogo adecuado con el representante de soporte."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversaciones limpias y cerradas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué veo aquí?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " de ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo obtener"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplicable"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " NO APLICA"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentes"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " del total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a cargar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información valiosa"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pero estamos seguros de que será genial una vez que llegue."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuesta personalizada"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No quedan comentarios sobre la encuesta"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay ninguna puntuación disponible para comentarios de la encuesta…"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificación"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibido"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta del comentario"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas de comentarios"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se aplicó el filtro de intervalo de fechas"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " respuesta"]), _normalize([_interpolate(_named("count")), " respuestas"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Analice más a fondo por qué se asignó la puntuación de ", _interpolate(_list(0)), " mediante la configuración de motivos en la configuración de la encuesta."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aún no hay razones de ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre los motivos de la encuesta"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones con etiquetas:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " por"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razones"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de comentario"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " razones"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " conductores previstos"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen de la conversación"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas de help desk"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Respuestas a la encuesta (", _interpolate(_named("n")), ")"]), _normalize(["Respuesta a la encuesta (", _interpolate(_named("n")), ")"]), _normalize(["Respuestas a la encuesta (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin asunto"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversaciones con ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " de usuarios"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de respuesta"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La puntuación de ", _interpolate(_list(0)), " es la suma de todas las respuestas dividida por la suma de las puntuaciones máximas"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar objetivo"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar objetivo"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer un objetivo"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destino"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar destino"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corto"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy largo"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largo"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediano"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confuso"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es útil"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mal soporte"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buen soporte"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muchas emociones"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios para el agente"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queja"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migas"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema no resuelto"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema resuelto"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono negativo"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mal resultado"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono positivo"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elogio"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mal producto"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte rápido"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte lento"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplicable"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA asigna automáticamente sus datos de ", _interpolate(_list(0)), " en categorías predefinidas. De esta manera, puede comprender información valiosa y explorar las causas sin tener que examinar manualmente los comentarios escritos."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las puntuaciones"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las encuestas"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar idioma de respuesta"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño del comentario"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tamaño del comentario"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado que el 25 % de los comentarios constan de una sola palabra, los comentarios más largos son de lectura obligatoria. Encuentre comentarios más valiosos sin esfuerzo; nosotros los buscamos automáticamente por usted."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón del comentario"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar la razón de los comentarios"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay puntuaciones que coincidan"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductores previstos"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar categoría de comentario"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras de la nube de palabras"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar palabras de wordcloud"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " desde el último periodo"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversación"]), _normalize([_interpolate(_named("n")), " conversación"]), _normalize([_interpolate(_named("n")), " conversaciones"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo calcular el índice de encuestas enviadas de todas las conversaciones debido a la falta de datos."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas enviadas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embudo de encuesta"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " de ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " conductores previstos"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA asigna automáticamente sus datos de ", _interpolate(_list(0)), " en categorías predefinidas. De esta manera, puede comprender información valiosa y explorar las causas sin tener que examinar manualmente los comentarios escritos."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño del comentario"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado que el 25 % de los comentarios constan de una sola palabra, los comentarios más largos son de lectura obligatoria. Encuentre comentarios más valiosos sin esfuerzo; nosotros los buscamos automáticamente por usted."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas a la encuesta"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (promedio)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (promedio)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destino"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tendencias de ", _interpolate(_list(0)), " por"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razones"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductores"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fecha de envío de la encuesta de ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fecha de respuesta a la encuesta de ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Asignar puntuación de ", _interpolate(_list(0)), " para"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar datos en función de"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación de la conversación"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dueño del ticket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agente con más respuestas"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (promedio)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a lo largo del tiempo"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " e IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones que incluyen estas palabras:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nube de palabras"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT por usuarios"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general sobre CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embudo de encuesta"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas a la encuesta"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas de help desk"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT a lo largo del tiempo"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nube de palabras"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin origen"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin idioma"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin razones"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin etiquetas"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin categoría de comentarios"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin espacio de trabajo"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos disponibles porque no se han creado asignaciones o no se han iniciado ciclos. Vuelva más tarde o ajuste los filtros."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore las novedades"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " elemento"]), _normalize([_interpolate(_named("count")), " elementos"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversación"]), _normalize([_interpolate(_named("count")), " conversaciones"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desaprobar"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobar"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobar con errores menores"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobar/Desaprobar"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Calificaciones de ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT promedio = [suma de las puntuaciones de los comentarios (recuento de todas las respuestas x puntuación máxima posible)] x 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La puntuación de la CSAT de Zendesk QA estandariza la puntuación de los comentarios en una escala del 0 al 100% y toma un promedio de las puntuaciones estandarizadas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (puntuación de satisfacción del cliente) cuantifica cuán satisfechos están sus clientes con sus servicios."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Cada evaluación representa la evaluación realizada por una persona en una conversación. Una conversación puede tener más de una evaluación (si dos o más evaluadores evalúan la misma conversación)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga el seguimiento de la evolución de las puntuaciones de los evaluadores a lo largo del tiempo."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga el seguimiento de la evolución de las puntuaciones de los agentes a lo largo del tiempo."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " respuestas"]), _normalize([_interpolate(_named("n")), " respuesta"]), _normalize([_interpolate(_named("n")), " respuestas"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " evaluaciones"]), _normalize([_interpolate(_named("n")), " evaluación"]), _normalize([_interpolate(_named("n")), " evaluaciones"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IQS (Internal Quality Score (puntuación de calidad interna)) es el resultado de la evaluación de sus conversaciones."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = suma de las puntuaciones de las evaluaciones divididas en varias evaluaciones"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se calcula como un promedio, teniendo en cuenta todas las puntuaciones de evaluación (el promedio ponderado de la puntuación de la categoría) que coincidan con las condiciones del filtro seleccionado."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la tarjeta."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambie sus filtros para volver a intentar."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tasa de aprobación indica el resultado de sus evaluaciones de conversaciones."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Desaprueba si la puntuación de la evaluación es ", _interpolate(_named("failScore")), " o inferior"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aprueba si la puntuación de la evaluación es ", _interpolate(_named("passScore")), " o superior"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está determinado por una línea de base predefinida, lo que significa que usted:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de aprobación = suma de evaluaciones aprobadas ÷ suma de evaluaciones totales"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El índice de aprobación indica el porcentaje de cuántas evaluaciones se aprueban sin fallar en categorías críticas o no críticas."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["p. ej., si el evaluador da 3 evaluaciones ese día, el número que se muestra es el promedio de las 3 puntuaciones. Vea cómo se calcula cada celda ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["p. ej., si el agente recibe 3 evaluaciones ese día, el número que se muestra es el promedio de las 3 puntuaciones. Vea cómo se calcula cada celda ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se tienen en cuenta: ponderación de la categoría, estado crítico (a diferencia de las puntuaciones a lo largo del tiempo)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debido a que las categorías se pueden marcar como n/d en las evaluaciones, el número de evaluaciones por categoría puede ser inferior al total de evaluaciones del evaluador. Vea cómo se calcula cada celda"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se tienen en cuenta: ponderación de la categoría, estado crítico (a diferencia de las puntuaciones a lo largo del tiempo)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debido a que las categorías se pueden marcar como n/d en las evaluaciones, el número de evaluaciones por categoría puede ser inferior al total de evaluaciones por agente. Vea cómo se calcula cada celda"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se tienen en cuenta: ponderación de la categoría, estado crítico (a diferencia de las puntuaciones a lo largo del tiempo)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Los grupos de categorías se muestran por separado. La puntuación de cada grupo se calcula mediante la puntuación de categoría promedio para ese grupo durante el mismo período. Vea cómo se calcula cada celda ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se tienen en cuenta: ponderación de la categoría, estado crítico (a diferencia de las puntuaciones a lo largo del tiempo)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Los grupos de categorías se muestran por separado. La puntuación de cada grupo se calcula mediante la puntuación de categoría promedio para ese grupo durante el mismo período. Vea cómo se calcula cada celda ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Línea de base ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libre de errores críticos"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El índice libre de errores críticos indica el porcentaje de cuántas evaluaciones se aprueban sin fallar ninguna categoría crítica."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Quality Score (puntuación de calidad interna)"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Otras” causas de fondo"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio periódico"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio diario"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio mensual"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio semanal"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio anual"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones a lo largo del tiempo"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en días o semanas."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo = puntuación total del día o semana dividido por el total de evaluaciones recibidas"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en días o semanas."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo = puntuación total del día o semana dividido por el total de evaluaciones recibidas"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones por categoría"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea el promedio combinado de todas las calificaciones de categoría proporcionadas por el evaluador durante este período."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea el promedio combinado de todas las calificaciones de categoría recibidas por el agente durante este período."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas de fondo de las calificaciones bajas"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas de fondo de las calificaciones"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones de categoría a lo largo del tiempo"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analice cómo se comparan las categorías a medida que va pasando el tiempo."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea las puntuaciones promedio otorgadas para cada categoría en un día o una semana seleccionados."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analice cómo se comparan las categorías a medida que va pasando el tiempo."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea las puntuaciones promedio recibidas para cada categoría en un día o una semana seleccionados."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones por evaluaciones"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivados"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría crítica"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de calificación"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio/Total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir autoevaluaciones"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporta todas las tarjetas"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["conversación"]), _normalize(["conversaciones"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["elemento"]), _normalize(["elementos"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " respuestas"]), _normalize([_interpolate(_named("n")), " respuesta"]), _normalize([_interpolate(_named("n")), " respuestas"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " evaluaciones"]), _normalize([_interpolate(_named("n")), " evaluación"]), _normalize([_interpolate(_named("n")), " evaluaciones"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mostrar uno más"]), _normalize(["Mostrar ", _interpolate(_named("n")), " más"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 30 días"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 7 días"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último mes"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última semana"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mes"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período personalizado"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meses"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestres"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanas"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Años"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la tarjeta. Cambie sus filtros para volver a intentar."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para mostrar."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio mensual"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneles"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus filtros activos incluyen datos en distintas escalas de calificación."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los marcadores de la tarjeta con estos filtros"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los marcadores del panel con estos filtros"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo promedio de evaluación"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin categorías"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información valiosa por categoría"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentario"]), _normalize(["Comentarios"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matriz"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exmiembro"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Quality Score (puntuación de calidad interna)"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (promedio)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones aprobadas"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar tarjeta"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar panel"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificaciones"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Causa de fondo"]), _normalize(["Causas de fondo"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la evaluación"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de evaluación"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar las causas de fondo"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las causas de fondo le permiten saber por qué las conversaciones tienen puntuaciones bajas"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna causa de fondo establecida para esta categoría"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación/Total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías de calificación"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de categorías de calificación"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de categoría"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio de trabajo"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar pedido"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías de grupos por"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar todo"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir autoevaluaciones"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluir autoevaluaciones"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar solo autoevaluaciones"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar filtros"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron hashtags"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags del comentario"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar hashtags del comentario"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado etiquetas"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas de help desk"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar etiquetas de help desk"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tarjetas de puntuación"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tarjetas de puntuación"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluaciones"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conexiones que coincidan"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen de la conversación"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar origen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más filtros"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay espacios de trabajo que coincidan"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de veces dado"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los grupos"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay grupos que coincidan."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los grupos"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar tarjetas"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar datos en función de"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación de la evaluación"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación de la conversación"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar dos decimales"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver configuración"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los espacios de trabajo"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toda la cuenta"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del espacio de trabajo"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin espacios de trabajo"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizadas"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banderas"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comida y bebida"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animales y naturaleza"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetos"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas y cuerpo"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajes y lugares"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más usados"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la búsqueda"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys y emoticonos"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolos"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún emoji"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir una conversación"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la lista de tareas"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " o explore su lista de ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Actualmente, está usando el espacio de trabajo ", _interpolate(_named("workspace")), " que no está conectado a este dominio. Haga clic aquí para conectarlo ahora."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conexiones disponibles"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No puede dar comentarios sobre ", _interpolate(_named("host")), " todavía."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga help desk como la pestaña activa."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este dominio aún no se puede evaluar. Si es necesario, notifique a su administrador."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar este dominio"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conectarse con ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere un momento. Estamos configurando todo."])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo obtener la URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas que ya están conectadas con la cuenta de Zendesk QA de la organización."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay herramientas conectadas con la cuenta de Zendesk QA de la organización."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le permite evaluar sitios web donde la URL es siempre la misma, al agregar un hash único al final antes de dejar una evaluación"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de cuenta"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiones"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus dominios"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominios que ya están conectados con este espacio de trabajo."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede revisar cualquier página en los siguientes dominios"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominios disponibles para evaluación"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensión"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL única"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aún no hay un help desk conectado. Vaya a ", _interpolate(_named("link")), " para agregar uno."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La extensión del navegador Zendesk QA solo se puede usar\nen los planes Professional y Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar espacio de trabajo"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcione un nombre"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcione un valor"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos personalizados"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es la primera vez que nos encontramos con esta conversación. Proporcione algunos detalles."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia a la conversación:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo para identificar el ticket más adelante"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcione el agente asignado y un asunto"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creando"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar esta conversación"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin asignar"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contacte a su gerente o cámbiese a un espacio de trabajo diferente al del ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las autoevaluaciones están desactivadas para este espacio de trabajo."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta luego."])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio de trabajo"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta URL no se puede evaluar. Vaya a una conversación."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicie sesión en Zendesk QA a través de su proveedor de SSO y luego vuelva a intentar."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abriendo una ventana emergente de inicio de sesión."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicie sesión con su cuenta de Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticando…"])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Aún no tiene una cuenta?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene acceso a esta conversación. Si es necesario, contacte a su gerente."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienen acceso"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique que su conexión esté activada en Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre sus conexiones"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de conexión"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a una conversación en su help desk para continuar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL no válido"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya casi termina la reconexión."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un momento"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente volver a cargar la página para que todo funcione"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libérese de las fronteras virtuales de su help desk y evalúe conversaciones en cualquier lugar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualice su suscripción para usar la extensión"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre su suscripción"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conexión de help desk no está vinculada a este espacio de trabajo"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su help desk no reconoce este ticket, o Zendesk QA no tiene acceso a él"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo encontrar la conversación"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva actividad"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene comentarios"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión correcto"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya puede usar la aplicación"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Califique todas las categorías"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilícelo para marcar casos como favoritos y filtrarlos más adelante"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay comentarios para esta conversación"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer comentarios"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir en Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí es donde aparecerán todos los comentarios y notificaciones. Aún no hay nada…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más en Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la conversación"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contiene todos"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es todo"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no contiene todo"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es todo"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mejor que la del agente"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peor que la del agente"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falso"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es más de"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contiene cualquiera de"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es cualquiera de"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no contiene ninguno de"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es ninguno de"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incumplidas"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentadas"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentadas por mí"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no ha comentado"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no comentadas por mí"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["críticos para la evaluación"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 30 días"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 14 días"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimas 24 horas"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 30 días"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 7 días"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes pasado"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semana pasada"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este mes"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esta semana"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoy"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayer"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detectado"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["existe"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destacadas por mí"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no destacadas por mi"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incluye"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más de"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partir del"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menor que"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativo"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no incumplidos"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no complejos"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no detectados"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no existe"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no incluye"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no recibidos"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no visto"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positivo"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no evaluadas"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No evaluadas por mí"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluados"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluadas por mí"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibido"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["críticos para la evaluación"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más de"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partir del"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menor que"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["does not contain"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visto"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi comentario tiene respuestas"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sí"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías personalizadas"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacado personalizado"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar condición"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversación"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métricas"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación y comentarios"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todas las condiciones"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacado"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compara el manejo de conversaciones de su bot con el de agentes promedio"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El bot está atascado en un bucle y repite el mismo mensaje en turnos consecutivos"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respuestas del bot en las conversaciones"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un tipo de bot involucrado en la conversación"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un bot involucrado en la conversación"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El contenido de la conversación contiene la frase ", _interpolate(_list(0)), " en los mensajes de los agentes"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifique automáticamente las llamadas que carecen de la declaración de divulgación obligatoria, como \"Esta llamada será grabada\" y similares."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una persona o un bot involucrado en la conversación"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente o bot que se está evaluando en la conversación"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente buscó asistencia de alto nivel"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonos positivo y negativo detectados en la conversación"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica al agente más activo en las conversaciones mediante el análisis de IA"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente terminó la conversación amablemente"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente comprende y reconoce los sentimientos de los clientes"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza los errores de gramática, ortografía y estilo del agente"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza toda la conversación en busca de frases de saludo de bienvenida típicas"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente entiende la consulta o preocupación del cliente"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza la facilidad con la que se puede entender un texto, teniendo en cuenta la complejidad de las palabras y la longitud de las oraciones"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza toda la conversación en busca de una solución ofrecida"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza el tono del agente en toda la conversación"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signos de abandono de clientes. El cliente estaba considerando cambiar o prometió irse."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo al que pertenece la conversación."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contenido o el texto del mensaje del agente"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas del agente en las conversaciones"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente asignado para evaluar la conversación"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad de tiempo que duró la conversación de la llamada en segundos"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contenido o el texto de la conversación"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El canal de comunicación utilizado para la conversación (p. ej., correo electrónico, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios agregados a la conversación"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha en que se agregó un comentario a la conversación"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonos positivo y negativo detectados en la conversación"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha en que el cliente respondió a la encuesta de satisfacción del cliente"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la evaluación de la conversación está en disputa"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El canal utilizado por el cliente para iniciar la conversación"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas de los clientes en las conversaciones"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha del último mensaje de la conversación"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rol o el tipo de usuario involucrado en la conversación"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El silencio entre el agente y el cliente superó el umbral"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente solicitó asistencia de alto nivel"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente expresó sumo agradecimiento o estaba muy contento con el soporte recibido"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente o el agente solicitó explícitamente un seguimiento"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El texto o registro de toda la conversación realizada por voz"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la conversación se marcó con una estrella"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags utilizados en los comentarios para categorizar el contenido"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma de la conversación detectado por la IA"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha del último mensaje de la conversación"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de notas privadas en las conversaciones"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad promedio de caracteres por mensaje en las conversaciones"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas que reciben los clientes en las conversaciones"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un participante cuya información se puede ver públicamente"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad promedio de palabras por mensaje en las conversaciones"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías que se usan para calificar o evaluar la conversación"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estado del proceso de evaluación de la conversación"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un participante o una persona involucrada en la conversación"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un correo electrónico asociado con la conversación"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la evaluación se ha visualizado"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El evaluador que revisó la conversación"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de la última evaluación de la conversación"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente al que se está revisando en la conversación"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier comentario específico proporcionado por el cliente con respecto a su satisfacción con la conversación"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La puntuación de satisfacción del cliente con la conversación"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La puntuación de satisfacción del cliente por mensaje en la conversación"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tarjeta de puntuación utilizada para revisar la conversación"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han incumplido los SLA proporcionados"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de origen o conexión donde se originó la conversación"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación fue inusual o atípica y hubo idas y vueltas para llegar a una resolución"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pregunta de la encuesta relacionada con el contenido de la conversación"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El título o el nombre de la encuesta asociada con la conversación"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente asignado para evaluar la conversación"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marca o el producto/servicio específico asociado con la conversación"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El canal de comunicación utilizado para la conversación (p. ej., correo electrónico, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha en que se cerró o se resolvió la conversación"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de creación inicial de la conversación"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de creación inicial de la conversación"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha en que el cliente respondió a la encuesta de satisfacción del cliente"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una breve descripción o un resumen de la conversación"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una ID de referencia externa vinculada a la conversación"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carpeta o el grupo específico en el que está organizada la conversación"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formulario o la plantilla utilizados para estructurar la conversación"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una ID de referencia externa vinculada a la conversación"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El buzón o la cuenta de correo electrónico a través del cual se recibió la conversación"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de teléfono asociado con la conversación"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nivel de prioridad asignado a la conversación"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número total de respuestas en la conversación"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estado o el estado actual de la conversación"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estado actual de la conversación"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El asunto o el tema de la conversación"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas del agente en las conversaciones"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas asignadas para categorizar la conversación"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo o la categoría de la conversación"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de la última actualización de la conversación"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de la última actualización de la conversación"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El medio o el canal a través del cual tuvo lugar la conversación"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contenido de una transcripción en una conversación"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones que no se pueden evaluar mediante el análisis de la IA"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de agentes que participan en las conversaciones"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la conversación se ha visualizado"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier mensaje"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiencia de la comunicación del bot"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetición de bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respuestas del bot"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de llamada"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje del cliente"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aire muerto"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta la divulgación de la grabación"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derivación"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido de la transcripción de llamadas"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacadas"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reúne los requisitos para evaluación"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluado por"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversación por"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografía y gramática"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje del agente"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente más activo"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas del agente"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la(s) llamada(s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido de la conversación"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal del mensaje"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta del comentario"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del comentario"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de respuesta a la encuesta de CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputa"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatía"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal del cliente"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas del cliente"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de última respuesta"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de usuario"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de bienvenida"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riesgo de abandono"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aire muerto"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derivación"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio excepcional"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcripción"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag del comentario"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor atípico"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprensión"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de última respuesta"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de notas privadas de la conversación"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad promedio de caracteres"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad promedio de palabras"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas públicas"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participante público"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de calificación"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificación y comentario"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la revisión"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legibilidad"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participante"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico relacionado"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relacionados"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación recibida"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación revisada"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación visualizada"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de evaluación"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario de la encuesta"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT por mensaje"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de satisfacción (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solución ofrecida"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de origen"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta de la encuesta"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título de encuesta"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca de la conversación"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio de la marca"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de conversación"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cierre"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de respuesta a la encuesta de CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de envío de la encuesta de CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la conversación"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externa"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo de la conversación"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpeta de la conversación"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de la conversación"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de la conversación"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externa"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buzón de la conversación"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridad de la conversación"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas de conversaciones"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la conversación"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la conversación"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto de la conversación"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas del agente"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta del help desk"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta del help desk"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de conversación"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de actualización"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de actualización"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido de la transcripción"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de agentes participantes"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado visto"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidad de vulnerabilidad"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la vulnerabilidad"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento vital de vulnerabilidad"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de AutoQA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una condición relacionada con la fecha"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue un nombre a su filtro"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre de filtro"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona que ha iniciado sesión"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exmiembros del espacio de trabajo"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrantes del espacio de trabajo"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agregar ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar filtros"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe tener por lo menos 1 filtro relacionado con fechas"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar opciones"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una opción"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su periodo de prueba finaliza en ", _interpolate(_named("n")), " día. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su período de prueba finaliza <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>en ", _interpolate(_named("n")), " día</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su período de prueba finaliza en ", _interpolate(_named("n")), " días. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su período de prueba finaliza <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>en ", _interpolate(_named("n")), " días</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su período de prueba finaliza hoy. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su período de prueba finaliza <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>hoy</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure una suscripción ahora"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " días"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le quedan ", _interpolate(_named("days")), " a su período de prueba"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha alcanzado el límite de usuarios para su plan. ", _interpolate(_named("link")), " para obtener más información o para actualizar su plan."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic aquí"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conecte su help desk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto nos permitirá incluir sus tickets para que los evalúe."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte su help desk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Está usando Zendesk QA con datos de muestra. Para borrar datos de muestra e importar sus propias conversaciones, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de muestra"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie la información útil a continuación"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tome una captura de pantalla de toda la página para proporcionar contexto"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comuníquese con el equipo de atención al cliente para resolver este problema:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia al error:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie información útil"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios, bots y espacios de trabajo"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios y espacios de trabajo"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoy"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este mes"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esta semana"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluada(s) ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluada(s) ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " evaluada(s) ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué novedades hay?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos abreviados del teclado"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatee con nosotros"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro chat no se pudo cargar (¿está usando un bloqueador de anuncios?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a…"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunidad"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página principal"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones dadas"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de conocimientos"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de cuenta"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tareas"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La aplicación se actualizó. ", _interpolate(_named("buttonStart")), "Volver a cargar", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desea comenzar a usar Zendesk QA como parte de su espacio de trabajo:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le informamos que recibió una invitación para usar Zendesk QA como parte de un espacio de trabajo. Si lo desea, puede continuar, por supuesto, pero tenga en cuenta que esto creará un ", _interpolate(_named("bold")), " para usted."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organización separada"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sí, continuar con ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una cuenta nueva"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " le pide que se una a ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conexión a Internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nuevo marcador"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegación rápida"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfoque y amplíe el editor de comentarios"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oculte o muestre el panel lateral"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a la siguiente conversación"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abra la conversación original"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a la conversación anterior"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé a todas las categorías la calificación más alta posible"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé a todas las categorías la calificación más baja posible"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordene las conversaciones (de manera aleatoria)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuéntenos"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos abreviados del teclado"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar métodos abreviados"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha alcanzado el límite de caracteres de ", _interpolate(_named("charLimit"))])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El tipo de imagen ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") no se permite"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El tamaño de la imagen ", _interpolate(_named("name")), " es demasiado grande (se permiten 5 MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El editor de comentarios no se pudo cargar"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suelte para cargar"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar plantillas"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron plantillas"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar causa de fondo"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro, incluidas las razones borradas"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón de la calificación"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer nombre"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de empleados"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos comunicaremos con usted para ayudarle a configurar su cuenta"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la compañía"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contacto de la compañía"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo se enteró de Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un canal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una cantidad de agentes"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llene todos los campos obligatorios"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe aceptar haber leído y aceptar nuestros términos y nuestra política de privacidad."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de Slack impulsado por soporte"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicidad"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigo o colega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador de cuenta o Marketplace de help desk"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio recomendante"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de búsqueda (Google, Bing, etc.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociales"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuéntenos de usted y de su empresa."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la cuenta"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy listo."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mire, conozca la aplicación, borre y agregue cosas."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta creada. ¿Qué sigue?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está listo para tener conversaciones reales?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue sus propios datos"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar a explorar"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruébelo con datos de muestra"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entonces, podemos comenzar a obtener sus tickets para que los evalúe."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte su cuenta de help desk"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambié de parecer."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar registro"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Marcador creado. ", _interpolate(_named("buttonStart")), "Ir a los marcadores", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los marcadores de capacitación están disponibles en el plan Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar plan"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los planes"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde sus notas para prepararse para la próxima sesión individual o de capacitación."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcadores para capacitación"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comente o agregue a otras personas con ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversación se ha borrado"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un marcador nuevo"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear marcador"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar marcador"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo funcionan los marcadores"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene permiso para ver esta conversación"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar tarjeta"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar conversación"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar panel"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se guardó el marcador"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar esta tarjeta"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar esta conversación"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar este panel"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar debajo de un usuario"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un usuario"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo usted puede ver lo que marca. Para compartir con los demás, ", "@", "menciónelos en los comentarios."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " está invitado a esta conversación"]), _normalize([_interpolate(_named("agentsNames")), " están invitados a esta conversación"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 comentario"]), _normalize([_interpolate(_named("n")), " comentarios"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usted mencionó a ", _interpolate(_named("participants")), ", pero no están en esta conversación."]), _normalize(["Usted mencionó a ", _interpolate(_named("participants")), ", pero no están en esta conversación."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " participantes"]), _normalize([_interpolate(_named("n")), " participante"]), _normalize([_interpolate(_named("n")), " participantes"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea eliminar el acceso de ", _interpolate(_named("agentName")), " a este marcador?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hacer nada"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invitar a esta conversación"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar comentario"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a esta conversación"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar usuario"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilo"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto no se puede deshacer."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto también borrará todos los comentarios bajo este marcador."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nuevos mensajes"]), _normalize([_interpolate(_named("n")), " nuevo mensaje"]), _normalize([_interpolate(_named("n")), " nuevos mensajes"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo usted puede ver los marcadores. Para compartir marcadores, ", "@", " a alguien en los comentarios."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " marcó ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un panel"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una conversación"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una nota"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualice para obtener marcadores de capacitación"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los marcador de capacitación están disponibles en el plan Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los marcadores de capacitación están disponibles en el plan Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualice para obtener la función"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepárese para sus sesiones individuales o guarde algo para otro momento."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto borrará de manera permanente el marcador y todos los comentarios agregados."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar marcador"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar marcador"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de carpeta"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque cosas para verlas más tarde"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más nuevo primero"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más antiguo primero"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde una tendencia o conversación interesantes del panel como preparación para los informes individuales, o anótelos para otro momento."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear un marcador, haga clic en el botón de arriba o en el icono de marcador en la vista de conversación o de panel."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han creado marcadores"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No hay resultados para ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente usar otras palabras clave o verifique si hay errores de escritura."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un marcador nuevo"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por usuario, palabra clave…"])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabéticamente"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creados recientemente"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editados recientemente"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mayoría de los marcadores"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Quiero evaluar ", _interpolate(_named("n")), " conversación ", _interpolate(_named("schedule"))]), _normalize(["Quiero evaluar ", _interpolate(_named("n")), " conversaciones ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo actualizado"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer objetivo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede establecer un objetivo personal para la cantidad de conversaciones que desea evaluar."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos los días"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cada mes"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todas las semanas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo de la evaluación"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando canales"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar canal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo compartir comentarios de la encuesta"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dé poder a sus colegas al destacar la excepcional calidad de su servicio y los excelentes comentarios de los clientes."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparta comentarios positivos de encuestas en Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron canales"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparta comentarios automáticamente con puntuaciones de más de"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integraciones del bot de elogios"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba un recordatorio al comienzo de la semana si no ha hecho ninguna evaluación."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordatorios"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordatorio semanal para hacer evaluaciones"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba notificaciones sobre nuevas evaluaciones, respuestas, menciones y disputas en Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menciones"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificaciones"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas al hilo"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integraciones de Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifique sobre nuevas evaluaciones, respuestas, menciones y disputas."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas relacionadas conmigo"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menciones en los comentarios"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevas evaluaciones recibidas"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas en hilos"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe con estadísticas"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba informes sobre las evaluaciones recibidas y dadas."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diariamente"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensual"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semanalmente"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada día"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada 3 horas"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada 6 horas"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al instante"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivada"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada semana"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El administrador de su cuenta ha bloqueado la configuración de notificaciones."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar período"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones personales"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (activo)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue un nuevo inicio de sesión social a esta cuenta:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si elimina un método de inicio de sesión activo, se cerrará su sesión. Puede volver a iniciar sesión con su correo electrónico alternativo."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar método de inicio de sesión"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google se agregó correctamente como una opción de inicio de sesión"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de inicio de sesión"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack se agregó correctamente como una opción de inicio de sesión"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear contraseña"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se enviará un correo electrónico a ", _interpolate(_named("email")), " para restablecer su contraseña."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico principal"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque en su bandeja de entrada nuestro correo electrónico de confirmación"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculo mágico"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para cambiarlo, cambie su ", _interpolate(_named("link")), " de antemano."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El correo electrónico ", _interpolate(_named("email")), " se asociará con esta contraseña."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El perfil fue actualizado"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen del perfil"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obtener mejores resultados, cargue una foto cuadrada (de 256 x 256 píxeles o más)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico principal"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de la semana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración personal"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione al menos una opción"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido de la plantilla"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar plantilla en"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar contenido de plantilla"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nombre de plantilla"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear plantilla"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear plantilla"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree plantillas de comentarios para agilizar la evaluación, mostrar elementos y ofrecer capacitación."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usado en"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar plantilla"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Borrar esta plantilla de manera permanente?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar plantilla"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree plantillas de comentarios para agilizar la evaluación, mostrar elementos y ofrecer capacitación."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se crearon plantillas"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se creó la plantilla de comentarios"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla de comentarios borrada"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se guardó la plantilla de comentarios"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcadores"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones y disputas"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para obtener acceso, comuníquese con ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atención al cliente"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado ninguna cuenta"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por ahora, use ", _interpolate(_named("link")), " para acceder a Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no migrada"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["métricas"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puntuaciones"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estadísticas"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calibración"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversaciones"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtros"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisión"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mis comentarios"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mis disputas"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mis evaluaciones"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacciones"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzó"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la encuesta"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcadores"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crear contraseña"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credenciales"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inicio de sesión"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restablecer"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisar objetivo"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plantillas"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear plantilla"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturación"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compañía"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integraciones"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organización"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licencias"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuración"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suscripción"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticaciones"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiones"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva categoría"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacado"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se encontró un mensaje en conflicto"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar o eliminar uno de los mensajes en conflicto"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilice el icono ", _interpolate(_named("placeholder")), " para excluir datos confidenciales de AutoQA. Por ejemplo, en lugar de “Cuídate. Juan”, puede usar “Cuídate. ", _interpolate(_named("placeholder")), "” para representar cualquier nombre."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Máximo ", _interpolate(_named("limit")), " filas"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar regla a"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el límite de 10 categorías personalizadas"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha alcanzado el límite de ", _interpolate(_named("limit")), " categorías personalizadas"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar condición"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar fila"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar condición"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el mensaje del agente"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De lo contrario, puntuar"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entonces, puntuar"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear categoría de tarjeta de puntuación"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar \"/\" para agregar una variable"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las direcciones"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mensaje del agente no puede estar vacío"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mensaje no puede tener más de 300 caracteres"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los orígenes"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los canales"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las etiquetas"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las conversaciones"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delimite dónde se aplica la regla."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones específicas"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear información valiosa de Destacado"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mensaje del agente no puede estar vacío"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el límite de 10 informaciones valiosas personalizadas"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha alcanzado el límite de ", _interpolate(_named("limit")), " informaciones valiosas personalizadas"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete el nombre de la información valiosa"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la información valiosa"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear categoría"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de información valiosa"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutro"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivo"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la configuración del espacio de trabajo"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora no"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para comenzar a usar esta categoría de calificación, vaya a la configuración de la tarjeta de puntuación de su espacio de trabajo y agréguela a la tarjeta de puntuación que desee."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación creada"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Califique automáticamente las conversaciones cerradas mediante la identificación de palabras clave o frases específicas."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No funciona en las transcripciones de voz."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendido"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¡Choque esos cinco! ✋ Llegó al límite de sus categorías de calificación automatizadas en ", _interpolate(_named("limit")), ". ¡Es el mago de las categorizaciones! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alcanzó el límite de categorías"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de que todos los campos estén rellenados correctamente"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigne un nombre a su categoría de calificación"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la categoría"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva categoría de calificación"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva categoría de calificación"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Califique automáticamente las conversaciones cerradas mediante la identificación de palabras clave o frases específicas. No funciona en las transcripciones de voz."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea las conversaciones cerradas automáticamente mediante la identificación de palabras clave o frases específicas. Solo funciona con transcripciones."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntúe automáticamente las conversaciones mediante la identificación de palabras clave o frases específicas en los mensajes del agente y del bot."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usar esta categoría de calificación, vaya a la configuración de la tarjeta de puntuación del espacio de trabajo y agréguela a una tarjeta de puntuación."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntúe las conversaciones automáticamente en función de palabras clave o frases específicas en los mensajes."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible para"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la información valiosa"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta información valiosa se muestra en Destacado según los criterios definidos."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la tarjeta de puntuación"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta categoría se puede usar en las tarjetas de puntuación en función de criterios definidos."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muy pronto"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personalizado"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predefinido"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarjeta de puntuación"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destacado"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voz"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se guardaron los cambios"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerencia:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en el icono ", "{··}", " para excluir nombres, detalles de empresas o cualquier otra información confidencial durante las verificaciones de cierre de AutoQA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar cierre aprobado"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar cierre aprobado"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre aprobado"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar exenciones"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar exención"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exención"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un saludo de bienvenida aprobado"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar saludo de bienvenida aprobado"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludo de bienvenida aprobado"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegue palabras aquí separadas por saltos de línea"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presione Intro después de cada palabra para una nueva entrada"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma aplicable"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo, en lugar de “Cuídate. Juan”, puede usar “Cuídate. ", "{··}", "” para representar cualquier nombre."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador de posición de contenido dinámico"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exenciones"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Se agregó una exención"]), _normalize(["Se agregaron ", _interpolate(_named("count")), " exenciones"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Exención ya agregada"]), _normalize(["Exenciones ya agregadas: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar exención"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron exenciones"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron exenciones"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exención (distingue entre mayúsculas y minúsculas)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Agregar exenciones"]), _normalize(["Agregar 1 exención"]), _normalize(["Agregar ", _interpolate(_named("count")), " exenciones"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar una palabra o frase"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exención guardada"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los idiomas"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar idioma"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cierre ya agregado"]), _normalize(["Cierres ya agregados: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar cierre"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron cierres aprobados"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cierres aprobados"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar cierre"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un cierre"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre agregado"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre guardado"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El formulario tiene entradas duplicadas: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Saludo de bienvenida ya agregado"]), _normalize(["Saludos de bienvenida ya agregados: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar saludo"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron saludos de bienvenida aprobados"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay saludos de bienvenida aprobados"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de bienvenida"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar saludo"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un saludo de bienvenida"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludo de bienvenida agregado"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludo de bienvenida guardado"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar exención"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Borrar exención"]), _normalize(["Borrar exenciones"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["¿Borrar esta exención de manera permanente?"]), _normalize(["¿Borrar estas exenciones de manera permanente?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar exención"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Una o varias exenciones superan los ", _interpolate(_list(0)), " caracteres de largo"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " caracteres"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado por"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina sus propias firmas que se ajusten a su marca y sus estándares de servicio. Al agregarlos, AutoQA solo detectará los cierres aprobados. Los cambios realizados se aplican a las conversaciones futuras."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcanzó el límite máximo de 100 solicitudes de cierre aprobadas. Si desea agregar más, podría ser el momento de revisar y perfeccionar los criterios de cierre existentes."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alcanzó el límite de cierres aprobados"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierres aprobados"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras y frases que desea que AutoQA ignore por ser errores de ortografía o gramática. Los cambios realizados se aplican a las conversaciones futuras."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de ignorados"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especifique sus propios saludos de bienvenida que se ajusten a su marca y estándares de servicio. Al agregarlos, AutoQA solo detectará los saludos de bienvenida aprobados. Los cambios realizados se aplican a las conversaciones futuras."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcanzó el límite máximo de 100 saludos de bienvenida aprobados. Si desea agregar más, podría ser el momento de revisar y perfeccionar los saludos de bienvenida existentes."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alcanzó el límite de saludos de bienvenida aprobados"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludos de bienvenida aprobados"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora no"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar categoría"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Borrar esta categoría de calificación de manera permanente?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de categorías"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La categoría eliminada no aparecerá en ninguna conversación ni filtro nuevos. Los datos históricos de los paneles y las conversaciones permanecen intactos."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La categoría se eliminará de todas las tarjetas de puntuación a las que se haya agregado."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo borrar la categoría de la tarjeta de puntuación"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se borró la categoría de la tarjeta de puntuación"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar categoría de la tarjeta de puntuación"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías de AutoQA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " impulsado por el modelo de IA permanecerá en el cuadro de mando, pero ya no recibirá una puntuación automática"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se desactiva la integración de LLM, no se desactivará por completo la función AutoQA . Esto es lo que sucede:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos históricos"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " permanecerá en el panel de AutoQA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas raíz agregadas manualmente"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " permanecerá, pero se eliminarán todas las causas raíz basadas en LLM"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar AutoQA basado en LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshabilitar"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA usa IA y modelos de lenguaje extenso (LLM) para calificar automáticamente categorías específicas. Sus datos se procesan bajo un estricto Acuerdo de procesamiento de datos (DPA) y nunca se usan para entrenar a ningún modelo."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre la IA generativa"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA utiliza el servicio OpenAI de Microsoft Azure para ", _interpolate(_named("automatically_scoring_categories")), ". Sus datos se procesan bajo un estricto DPA solo para proporcionarle AutoQA, ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puntuación automática de algunas categorías"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nunca se usa para entrenar ningún modelo de OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA basado en LLM activado"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA basado en LLM desactivado"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA basado en LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación automatizada con IA en AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento de datos de las categorías de AutoQA basadas en MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento de Microsoft Azure retirado"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se dio el consentimiento de Microsoft Azure. Las conversaciones recibirán calificaciones automáticas pronto."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía puede usar AutoQA, pero esto es lo que sucederá"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se desactiva el servicio MS Azure OpenAI, no se desactivará AutoQA como funcionalidad."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede hacerlo en la vista de categorías de la tarjeta de puntuación."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las categorías de AutoQA impulsadas por el modelo permanecerán en la tarjeta de puntuación, pero no recibirán una puntuación de manera automática."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las causas de fondo que haya agregado manualmente se conservarán. Se eliminarán todas las causas de fondo generadas por el modelo."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos históricos permanecen en el panel de AutoQA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar el procesamiento de datos del servicio MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una gama más amplia de categorías de AutoQA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte multilingüe listo para usar."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adición rápida de nuevas categorías."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión de evaluación mejorada. GPT-3.5 muestra conciencia contextual a nivel humano."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aproveche los últimos avances en tecnología de IA."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto es lo que obtendrá"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar el procesamiento de datos del servicio MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento dado"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento no dado"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios nuevos se asignan a este espacio de trabajo"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio de trabajo predeterminado"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Formato de 12 horas (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Formato de 24 horas (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de hora"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil de la compañía actualizado"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de la semana"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integración personalizada"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ID de la clave de acceso y la clave secreta se encuentran normalmente en Administración de acceso > Usuarios"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de clave de acceso de AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p. ej., AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar cambio de clave de acceso secreta"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar el valor de la clave de acceso secreta"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conexión permite definir solo una región que normalmente se encuentra en Ubicación"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región de AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p. ej., eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una clave secreta"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave de acceso secreta de AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p. ej., wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toda la información pertinente se encuentra en Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalmente se encuentra en la configuración de la instancia de Amazon Connect y en la página Almacenamiento de datos"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación de almacenamiento de las grabaciones de llamadas"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p. ej., amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al agregar Amazon Connect. Verifique si todos los campos se han rellenado correctamente y vuelva a intentarlo."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ubicación de almacenamiento debe tener el formato de depósito/carpeta separado por una barra oblicua."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para agregar una conexión, póngase en contacto con ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención al cliente"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los tickets de Zendesk se sincronizarán automáticamente. Puede optar por excluir los tickets seleccionados."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacífico (Tokio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacífico (Seúl)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacífico (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacífico (Bombay)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacífico (Sídney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadá (Centro)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Fráncfort)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zúrich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irlanda)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Londres)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región del servidor"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oriente Medio (EAU)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar región del servidor"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudamérica (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EE. UU. Este (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EE. UU. Este 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EE. UU. Oeste (Oregón)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conexión ya está agregada"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar conexión"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar solo las conversaciones que"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canal uno, canal dos"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La ID única de esta conexión es ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mes"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 año"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 meses"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 meses"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las conversaciones inactivas se borrarán una vez transcurrido el período de retención seleccionado. Las evaluaciones enviadas siguen estando visibles en las vistas de actividad y de panel."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de retención"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar período de retención"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que esta acción es irreversible."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendo que esto eliminará todos los datos relacionados con el help desk para esta conexión de Zendesk QA, incluidas las conversaciones, los campos y las etiquetas."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las evaluaciones enviadas no se verán afectadas y seguirán estando visibles en vistas específicas."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupo uno, grupo dos"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluir"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desarrollo activo"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta integración aún está en ", _interpolate(_named("activeDevelopment")), ". Es posible que falten algunos detalles (como archivos adjuntos o campos personalizados)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buzón uno, buzón dos"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etiqueta uno, etiqueta dos"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token de API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secreto de token de API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque “Nombres de campos de ticket” en Freshdesk en Configuración → Campos de ticket"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque “Custom Field Names” en Help Scout en Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque “Nombres de conversaciones de Klass” en Kustomer, en Configuración → Klasses → Conversación"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque “Nombre de campo personalizado” en Live Agent en Configuración → Campos personalizados"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque “Nombre de campo personalizado” en Wix en Configuración → Campos personalizados"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe las ID de campo con comas. Estas ID se encuentran en Zendesk en Administrador → Campos de ticket."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte a algunos de los usuarios de su help desk para que evalúen sus primeras conversaciones. No se preocupe, no les notificaremos a menos que usted los invite a convertirse en integrantes del espacio de trabajo."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se integró correctamente"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar miembros"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora comenzaremos a extraer sus conversaciones."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dominio: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identificador de help desk: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo autenticar su conexión"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo listo. Esta es su nueva conexión."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunas conexiones no se han actualizado o se han vuelto inactivas. Intente actualizar estas conexiones o eliminarlas de la lista."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del cliente de API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secreto de cliente de API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cree un nuevo token de API desde Configuración → Cuenta → API y SDK. La URL de redireccionamiento que se debe usar es ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la cuenta"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione su help desk para comenzar"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si su cuenta de Intercom está configurada para ser atendida desde la UE o Australia, actualice esto."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El correo electrónico que se usa para iniciar sesión en ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oculte el nombre, correo electrónico y número de teléfono del usuario final. Las credenciales bancarias ya están enmascaradas para todas las conexiones de help desk."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay integraciones. Agregue una ahora."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesita estos roles:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear conexión"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una nueva conexión"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desmarca esta opción, podrá agregar manualmente esta conexión solo a determinados espacios de trabajo"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectarse a todos los espacios de trabajo existentes"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los espacios de trabajo"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secreto de clave de API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canales"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conexión ", _interpolate(_list(0)), " borrada"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la conexión"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede obtener su token de API abajo."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral de CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar conexión ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba “borrar conexión” para eliminar esta conexión"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar conexión"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre y monitoree sus fuentes de datos conectadas."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar conexión"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluye"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los nombres de los campos se deben separar con comas"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo actualizar"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asigne un nombre único a su integración"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar opciones avanzadas"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar campos de datos confidenciales"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca almacenar URL de archivos adjuntos"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar archivos adjuntos"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca almacene el contenido de la conversación (puede obtenerlo después a pedido desde su help desk)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar el contenido de la conversación"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca almacenar el asunto de la conversación (más adelante puede acceder a pedido desde help desk)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar la línea del asunto"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Su subdominio de ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región de Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidir con la ID del equipo de la bandeja de entrada"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID numérica de su equipo de Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En curso..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buzones"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar la conexión manualmente"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enmascarar los datos del cliente"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin espacios de trabajo"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de campo confidencial"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de campo confidencial"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovar conexión"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovar conexión"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectarse al sandbox de Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Establecer una conexión con ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar opciones avanzadas"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo extraer las conversaciones con una puntuación inferior o igual al umbral establecido"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar un umbral de satisfacción del cliente (CSAT)"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar solo las conversaciones que..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener las últimas conversaciones"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiones"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar conexión"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión actualizada"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su help desk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para cancelar su suscripción y borrar su cuenta, comuníquese con ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención al cliente"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar cuenta"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar un ticket en Zendesk Support (u otro help desk) no afecta las revisiones de conversaciones asociadas. Todavía puede verlos y administrarlos."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si se borra un ticket en el control de Zendesk QA , también se borran las revisiones de conversaciones asociadas.", _interpolate(_named("line_break")), "Borrar un ticket en Zendesk Support (u otro help desk) no afecta las revisiones de conversaciones asociadas. Todavía puede verlos y administrarlos."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar evaluaciones"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La eliminación de evaluaciones es permanente. También afecta los datos de los paneles."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todas las evaluaciones"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todos"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se borraron todas las evaluaciones"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todos"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si hay evaluaciones asociadas con tickets borrados, las verá aquí."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay evaluaciones"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar ID de ticket"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID del ticket: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones borradas"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Los espacios de trabajo predeterminados no se pueden borrar. Para borrar este espacio de trabajo, seleccione otro espacio de trabajo predeterminado en ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la cuenta"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción no se puede deshacer."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar y borrar todos los datos"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comprendo que esto va a ", _interpolate(_named("removeAllData")), " en relación con este espacio de trabajo, incluidos los usuarios y las conexiones del espacio de trabajo."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar espacio de trabajo"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba “borrar espacio de trabajo” para borrar este espacio de trabajo"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar espacio de trabajo ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["borrar espacio de trabajo"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de estar seguro de lo que está haciendo o consulte a un profesional de antemano."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione si la persona evaluada predeterminada se selecciona en función del agente más frecuente o del agente asignado actualmente."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente más activo"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada predeterminada"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permita que las personas evalúen sus propias conversaciones y que los agentes realicen evaluaciones"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El espacio de trabajo se ha borrado"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El espacio de trabajo se ha actualizado"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establezca una calificación neutra al ocultar las evaluaciones proporcionadas anteriormente por otros evaluadores"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificación imparcial"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción no se puede deshacer."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que esto eliminará y borrará todos los datos relacionados con este espacio de trabajo, incluidos los usuarios y las conexiones del espacio de trabajo."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar espacio de trabajo"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrando espacio de trabajo..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluaciones"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculo mágico"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO de Okta"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculo mágico"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede desactivar el método de inicio de sesión actual"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han actualizado los métodos de autenticación"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permita ciertos tipos de métodos de autenticación para todos los usuarios."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticaciones"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUENTA"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios y espacios de trabajo"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree y administre espacios de trabajo, e invite o conecte a usuarios."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanecer en la página"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonar página"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Salir de esta página y perder todos los cambios?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiones"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticaciones"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que no hay conversaciones para importar. Comience por tener algunas conversaciones con su mesa de ayuda. Luego, regrese aquí para analizarlas."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron conversaciones"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede borrar esta categoría del sistema"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar categoría"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La eliminación de categorías es permanente. Todos los datos de categoría de los informes se borrarán."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar esta categoría, tendrá que volver a crearla."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar categoría"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar categoría"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las categorías automáticas llenan automáticamente la tarjeta de puntuación con puntuaciones sugeridas para ahorrar tiempo en la evaluación. Las categorías manuales las llenan los evaluadores desde cero."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automática"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatía"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografía y gramática"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de bienvenida"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprensión"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legibilidad"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solución ofrecida"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron categorías"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron causas raíz"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tarjetas de puntuación"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruebe con otra búsqueda o cambie los filtros"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorías"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorías"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorías"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las categorías"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorías"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tarjetas de puntuación"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tarjetas de puntuación"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tarjetas de puntuación"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las tarjetas de puntuación"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tarjetas de puntuación"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " estados"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " estados"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " estados"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los estados"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " estados"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los tipos"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espacios de trabajo"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espacios de trabajo"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron espacios de trabajo"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espacios de trabajo"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los espacios de trabajo"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espacios de trabajo"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El peso de ", _interpolate(_named("weight")), " contribuye al ", _interpolate(_named("percentage")), "% de la puntuación general"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar tarjeta de puntuación"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La eliminación de las tarjetas de puntuación es permanente. Borrará todos los datos de la tarjeta de puntuación de los informes."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar esta tarjeta de puntuación, tendrá que volver a crearla."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar tarjeta de puntuación"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas de fondo"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Escala de calificación actualizada. ", _interpolate(_named("buttonStart")), "Comenzar a evaluar", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA establece una escala binaria de manera predeterminada, pero también puede optar por una escala más granular:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si desea ver distintas opciones de escala de calificación, configuradas por categoría, vaya a su ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatía/Tono"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conocimiento del producto"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al actualizar las escalas de calificación:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su escala de calificación anterior y las categorías correspondientes se archivarán (no se borrarán)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunos datos del panel se verán afectados, ya que la escala antigua y la nueva podrían no ser comparables."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clonar la configuración de mis categorías de calificación existentes en la nueva escala"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea actualizar las escalas de calificación?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede modificar el formato de visualización de la tarjeta de puntuación (número o emojis) y hacer que las categorías sean obligatorias u opcionales."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar emojis en lugar de números"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que las personas omitan categorías"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solución"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de escala de calificación"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Se han clonado las categorías de 1 espacio de trabajo"]), _normalize(["Se han clonado las categorías de ", _interpolate(_named("workspacesAmount")), " espacios de trabajo"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del espacio de trabajo"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea automáticamente las llamadas en las que el agente no da indicaciones al cliente de que espere un silencio en la conversación."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación tiene momentos de estancamiento que duran más que el umbral establecido."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir el umbral de aire muerto máximo en las llamadas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aire muerto"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información que se borre no aparecerá en ninguna conversación ni filtro nuevos. Los datos históricos de los paneles y las conversaciones permanecen intactos."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo borrar la información valiosa de Spotlight"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se borró la información valiosa de Spotlight"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar información valiosa de Spotlight"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea las conversaciones que contienen palabras o frases específicas en las transcripciones y los mensajes."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifique automáticamente las llamadas que carecen de la declaración de divulgación obligatoria, como \"Esta llamada será grabada\" y similares."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene como objetivo identificar una declaración de divulgación de grabación en la llamada usando un modelo de lenguaje grande (LLM) que comprende dinámicamente el contexto, eliminando la necesidad de frases preestablecidas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta la divulgación de la grabación"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea automáticamente las llamadas en las que el agente no da indicaciones al cliente de que espere un silencio en la conversación."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aire muerto"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificar momentos inactivos en una llamada"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Necesita hacer cambios en su suscripción? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") y estaremos encantados de ayudarle."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analice automáticamente el 100 % de las conversaciones entre agentes, BPO, canales e idiomas. La IA detecta problemas, brechas de conocimiento y oportunidades de capacitación para mejorar el servicio."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100 % de cobertura"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios y capacitación de los agentes"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacar información valiosa de la IA"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes y paneles avanzados"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis y filtrado de tono"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducciones bajo demanda"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar las asignaciones y el establecimiento de objetivos"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación y cuestionarios"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de calidad de Zendesk (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo en el plan Professional, más"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzado (heredado)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks para notificación inmediata de desaprobaciones críticas"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integración del almacén de datos"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integración con Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de integraciones personalizadas"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador exclusivo del éxito del cliente"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso directo a nuestros equipos de productos e ingeniería mediante Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo en el plan Growth Suite, más"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IA Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA para una cobertura del 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información valiosa de las conversaciones impulsada por IA para entender los datos"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enriquecimiento y filtrado de datos del help desk mejorados"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacado: Detección automatizada de interacciones imprescindibles"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis y filtrado de tono"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes e información valiosa sobre el desempeño generados por IA"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducción de conversaciones a pedido"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento del tiempo de evaluación"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de índices de aprobación"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo en IA Suite, más"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete de implementación empresarial que incluye integración con Salesforce y Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de integraciones personalizadas"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integración del almacén de datos"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks para notificación inmediata de desaprobaciones críticas"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador exclusivo del éxito del cliente"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso directo a nuestros equipos de productos e ingeniería"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso anticipado e influencia en nuestra hoja de ruta"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación automática de asignaciones y establecimiento de objetivos"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitación y análisis específicos"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios para la administración del aprendizaje"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración de roles y permisos de usuario"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enmascaramiento de datos confidenciales"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de filtrado avanzadas y búsqueda de conversaciones"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación personalizables con varias calificaciones, ponderaciones ajustables y opciones de desaprobación propia"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensión del navegador para evaluar conversaciones en cualquier lugar"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de causa de fondo"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes de desempeño para comparar agentes, equipos y BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuesta de CSAT y análisis de comentarios de los clientes"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funciones"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo en el plan Starter, más"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (heredado)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varias escalas de calificación por conversación"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integración de Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de las conversaciones interactiva impulsada por IA"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar asignaciones"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcadores para capacitación"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones de capacitación"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis y filtrado de tono"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrado en función de la complejidad de la conversación"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de las conversaciones"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación condicionales"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de causa de fondo"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de CSAT avanzados con tecnología de IA"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración de la calificación"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensión del navegador para evaluar la conversación en cualquier lugar"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque para descubrir las conversaciones imprescindibles"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducción de conversaciones a pedido"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento del tiempo de evaluación"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan personalizado"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (heredado)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones de conversaciones ilimitadas"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportación a CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluaciones y evaluaciones entre pares"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación con ponderaciones ajustables y opciones de desaprobación propia"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de filtrado robustas"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos de evaluación diaria, semanal o mensual"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas de CSAT y análisis básico"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneles"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes para comparar agentes, equipos y BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración de roles y privilegios de usuario"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funciones"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero hacer un cambio en mi suscripción a Zendesk QA.\n\nResalte o indique una opción e indique la cantidad de licencias que desea cambiar:\nMe gustaría [agregar X licencias] [eliminar X licencias] [actualizar al plan de Zendesk QA]\n\nComparta el nombre de su compañía y el nombre asociado con su cuenta de Zendesk QA, así como cualquier otro detalle que debamos saber para procesar este cambio."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de suscripción a Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero comprar una suscripción a Zendesk QA.\n\nIndique el nombre de su compañía y el nombre asociado con su cuenta de Zendesk QA, así como cualquier otro detalle que debamos saber para procesar esta solicitud:\n\nComparta también cualquier pregunta específica que desee que se aborde antes de la compra:\n\n¡Gracias!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra de suscripción a Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su suscripción finaliza el ", _interpolate(_named("date")), " (quedan ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El período de prueba finaliza el ", _interpolate(_named("date")), " (quedan ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 día"]), _normalize([_interpolate(_named("n")), " días"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (expira en ", _interpolate(_named("expires")), ") el ", _interpolate(_named("dayOfMonth")), " de cada mes."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (expira en ", _interpolate(_named("expires")), ") el ", _interpolate(_named("dayOfMonth")), " de cada mes."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (expira en ", _interpolate(_named("expires")), ") el ", _interpolate(_named("dayOfMonth")), "de cada mes."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (expira en ", _interpolate(_named("expires")), ") el ", _interpolate(_named("dayOfMonth")), " de cada mes."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (expira en ", _interpolate(_named("expires")), ") el ", _interpolate(_named("dayOfMonth")), " de cada mes."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (expira en ", _interpolate(_named("expires")), ") el ", _interpolate(_named("dayOfMonth")), " de cada mes."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturado a ", _interpolate(_named("card")), " (vence el ", _interpolate(_named("expires")), ") el ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termina en"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vencido"]), _normalize(["Vence en 1 día"]), _normalize(["Vence en ", _interpolate(_named("expiresIn")), " días"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El precio inicial incluye ", _interpolate(_named("n")), " licencias de usuario"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["desde ", _interpolate(_named("sum")), " por mes"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por usuario al mes"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al año"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por mes"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trimestre"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar la cancelación programada"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si desea seguir usando Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su suscripción se canceló el ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su suscripción vencerá el ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " días de prueba sin costo adicional"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usadas"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiene ", _interpolate(_named("n")), " usuarios. La cantidad de licencias no puede ser inferior."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La suscripción se reanudará el ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["año"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su plan actual de Zendesk QA (", _interpolate(_named("plan")), ") no incluye funciones impulsadas por IA. Para aprovechar todo el potencial de las funciones de inteligencia de voz, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para cancelar su suscripción, contáctenos ", _interpolate(_named("link")), " o chatee con nosotros."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de administración de la calidad"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración de la calidad"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suscripción fue actualizada"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una tarjeta de crédito"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los planes"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver información de facturación"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de facturación"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar suscripción"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su suscripción se cancelará al final del período de facturación."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos para editar"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan actual"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios personalizados"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajar de categoría"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual (ahorre el 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar anualmente"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar suscripción"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar los planes"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba gratuita"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se factura manualmente"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede revisar y confirmar el resumen final del pedido en el próximo paso."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más popular"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene un método de pago activo."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con versión de prueba"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reanude su suscripción actual para cambiar el plan"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar suscripción"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestral"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivar suscripción"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reanudar suscripción"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencias"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar la prueba ahora"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelados"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No renovable"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En pausa"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la suscripción"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar suscripción"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizadas"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tómese un minuto para proporcionar comentarios sobre su experiencia de atención al cliente. Gracias de antemano. ", "{", "{", "agentName", "}", "}", " valora sus comentarios."])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Encuesta de CSAT de ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo calificaría el soporte que recibió de ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Realmente se lo agradecemos. Usaremos sus comentarios para mejorar ", _interpolate(_list(0)), " para todos."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre las encuestas"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el tipo de encuesta"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede cambiar esto más adelante."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mida y mejore la satisfacción del cliente con encuestas de CSAT automatizadas en varios idiomas."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se crearon encuestas"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear encuesta"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de la encuesta"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha creado la encuesta"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pruebe cambiar la palabra clave o ", _interpolate(_named("contact_us")), " si desea verlo en la lista aquí"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["háganoslo saber"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún idioma"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleccione uno o más idiomas de la lista a continuación. ", _interpolate(_named("contact_us")), " si no ve en la lista un idioma que desea usar."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar idiomas"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Háganoslo saber"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar idioma"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Última publicación ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se guardó por última vez el ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " pregunta"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta de introducción"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rótulo de escala izquierda"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto del correo electrónico"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razones"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rótulo de escala derecha"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si a una encuesta le falta alguna traducción, se mostrarán los textos del idioma base en su lugar"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar traducciones"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltan traducciones"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asegúrese de revisar todas las traducciones antes de aplicarlas. Haga los cambios necesarios en el archivo descargado, guárdelo y vuelva a cargarlo. Si un token ", _interpolate(_named("token_example")), " no aparece como una etiqueta, puede que tenga un formato incorrecto o que esté en un campo que no admita tokens."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar traducciones"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducido"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume la conversación y muestra sugerencias de comentarios."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistencia de IA de Zendesk QA (en inglés)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede editar los tipos una vez que se han creado"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este tipo ya existe. Seleccione otro nombre."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde el tipo personalizado como una opción que se puede seleccionar en el menú desplegable Tipo de encuesta y agrupe todas las encuestas creadas en el panel."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la(s) palabra(s) y presione Intro para guardar"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tipos personalizados"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del tipo no puede estar vacío"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir tipo"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se guardó el tipo personalizado. Presione Intro para guardarlo."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico del remitente"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del remitente"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿En qué medida está de acuerdo con la afirmación: “", _interpolate(_named("agentName")), " facilitó la solución de mi problema”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Qué tan sencillo fue obtener ayuda de ", _interpolate(_named("brandName")), " hasta ahora?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En general, ¿qué tan fácil fue resolver su problema hoy?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Fue fácil interactuar con nuestro equipo?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de la política de privacidad (opcional)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de los Términos de servicio (opcional)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Cómo calificaría el soporte que recibió de ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo fue nuestro servicio para esta solicitud?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es su satisfacción con el representante de soporte?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Cuál es su grado de satisfacción con el servicio recibido de parte de ", _interpolate(_named("agentName")), " hoy?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta personalizada"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Defina su propia pregunta ", _interpolate(_named("type")), " personalizada..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba una pregunta personalizada"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure su mensaje de correo electrónico"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si edita el nombre de un tipo de pregunta personalizado, se actualizará en todas las encuestas que lo usen, sin afectar los datos recopilados."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tipos personalizados"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto del correo electrónico"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta de introducción"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " pregunta"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un nuevo tipo personalizado"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token de personalización"]), _normalize(["Tokens de personalización"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Arrastre y suelte el token de personalización en el campo a continuación."]), _normalize(["Arrastre y suelte los tokens de personalización en los campos a continuación."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversación ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente solo es posible enviar encuestas de CSAT por correo electrónico. Estamos trabajando para ofrecerle más oportunidades, como encuestas de CSAT del help desk, encuestas por SMS y más."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sincronizar o renovar la conexión"]), _normalize(["Sincronizar o renovar las conexiones"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar o renovar la conexión ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["La conexión no se sincronizó correctamente"]), _normalize(["Las conexiones no se sincronizan correctamente."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quitar la marca de la casilla de verificación correspondiente ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticar ahora ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue por lo menos una regla de envío de encuestas para activar la conexión"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conexión está desactivada actualmente. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conexión enmascara los datos confidenciales del cliente. Para activar el envío de encuestas,\n", _interpolate(_named("link")), " en la configuración."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conexión requiere permisos adicionales para enviar encuestas.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticar ahora"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA necesita más permisos para Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " y ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hora"]), _normalize([_interpolate(_named("n")), " horas"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minutos"]), _normalize(["1 minuto"]), _normalize([_interpolate(_named("n")), " minutos"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hora"]), _normalize(["horas"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " para configurar las reglas de envío de encuestas."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" día"]), _normalize(["días"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los vínculos a la encuesta caducan"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los vínculos vencen en"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evite responder encuestas si ha pasado demasiado tiempo"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " encuesta ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe las etiquetas con comas o presione Intro después de escribir. Asegúrese de que cada una de ellas aparezca exactamente como en Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina las condiciones en función de las cuales se enviará la encuesta."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de envío de la encuesta"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de envío de seguimiento de encuesta"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ya existe una encuesta ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nResuelva el conflicto modificando las reglas aquí o en ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ya configuró una conexión con reglas coincidentes para la encuesta ", _interpolate(_named("name")), ". Resuelva el conflicto modificando las reglas aquí o en ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar otra regla"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar marca"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar condición"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un valor"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar etiqueta"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aplicar las mismas reglas"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un valor"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico enviado en"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento por correo electrónico enviado en"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragmento incrustado y seguimiento por correo electrónico"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar código"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjunte las encuestas de Zendesk QA a sus mensajes de correo electrónico agregando el fragmento de código a la configuración de su cuenta de Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía de instalación"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de Zendesk abierta"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa del fragmento"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El fragmento de código se copió al portapapeles"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al copiar el fragmento"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragmento incrustado"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta encuesta tiene cambios sin guardar. Guárdela nuevamente para asegurarse de que el código refleje las últimas actualizaciones."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar conexión"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron conexiones todavía"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorpore correo electrónico, chat en vivo, llamadas telefónicas y más a Zendesk QA con sus conexiones"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre el envío de encuestas"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de envío de la encuesta"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de envío de seguimiento de encuesta"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar regla de marca"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar regla"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticar"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + seguimiento por correo electrónico"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte su cuenta de Intercom con la encuesta de CSAT de Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar por"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quién recibe la encuesta y cuándo"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar reglas"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activada"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las encuestas dentro de la aplicación permiten que los clientes completen una encuesta sin abandonar el chat."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de entrega por conexión"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ya existe una encuesta ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), ".\nResuelva el conflicto al desactivar la conexión o modificar las reglas aquí o en ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establezca las condiciones en función de las cuales se enviará la encuesta"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creado por ", _interpolate(_named("name")), " el ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eliminar ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se elimina este idioma, ya no estará visible para los encuestados y se borrarán todos los textos traducidos."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar idioma"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción no se puede deshacer."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como alternativa, puede desactivar el idioma ocultándolo de la encuesta. Esto le permitirá mantener todas sus traducciones en su lugar sin mostrárselas a los encuestados."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última edición por ", _interpolate(_named("name")), " el ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre los idiomas de las encuestas"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Antes de agregar otros idiomas, asegúrese de que ha terminado de hacer los cambios en los pasos de Estilo y Contenido. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar idioma"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos traducidos"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible para los encuestados"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predeterminada"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar archivo de traducción"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar idioma"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" descargar un archivo de traducción"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluar traducciones"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traducción completada. ", _interpolate(_named("review_link")), " o ", _interpolate(_named("download_link")), " para hacer cambios."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" descargar un archivo de traducción"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evalúe lo que falta"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Parte del contenido aún no se ha traducido y se mostrará en inglés. ", _interpolate(_named("review_link")), " o ", _interpolate(_named("download_link")), " para agregar las traducciones que faltan."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar la encuesta"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La encuesta contiene cambios sin guardar. ", _interpolate(_named("save")), " para descargar el archivo de traducción con los cambios más recientes."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar archivo de traducción"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione la escala de calificación preferida"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrece a los clientes un menú desplegable para seleccionar un motivo para calificar"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto de escala izquierda (opcional)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Números"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar razón"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La razón no puede estar vacía"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta razón ya existe"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menú desplegable para explicar las razones de su puntuación"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se borra una razón y se vuelve a agregar con el mismo nombre o se le cambia el nombre, siempre se crea una nueva entrada. Como resultado, los datos que se muestran en el panel se ven afectados."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar razón"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La razón borrada permanece en los informes del panel, pero no será visible para los nuevos encuestados. Esto también se aplica a las traducciones relacionadas."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar una razón"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Borrar una razón “", _interpolate(_list(0)), "”"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto de escala derecha (opcional)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En una escala de 5 puntos, 1 y 2 se consideran puntuaciones negativas; 3, neutro; y 4 y 5, positivas"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar texto de escala"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " escala"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de calificación"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA está importando sus conversaciones. Este proceso puede tardar hasta 24 horas."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto se usará como un token de personalización en toda la encuesta."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de marca"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botones y vínculos"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalice el aspecto de su encuesta"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondo del logotipo"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se admiten los formatos JPG, PNG y GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estilo"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar logotipo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre sesión con estilo"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un mensaje que las personas verán después de enviar sus comentarios"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de agradecimiento"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de color hexadecimal incorrecto"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico incorrecta"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo no puede estar vacío"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorrecta"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No visible:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas enviadas"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas enviadas en los últimos 30 días"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La encuesta se ha borrado"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La encuesta se ha duplicado"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha enviado una vista previa del correo electrónico"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La encuesta se ha publicado"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La encuesta se ha actualizado"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La encuesta está en pausa"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar encuesta"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el correo electrónico para enviar la vista previa de la encuesta"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["su", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el idioma de la vista previa"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar un correo electrónico de vista previa"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíe una encuesta de ejemplo a su correo electrónico. Las calificaciones de la vista previa de la encuesta no se almacenarán."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Previsualizar la encuesta ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa de la encuesta por correo electrónico en la web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reanudar"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar como borrador"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de marca"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se puede agregar un nombre específico de la marca en el paso Estilo"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del cliente"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de soporte"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante de soporte"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En pausa"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Encuesta de CSAT de ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hicieron cambios sin guardar"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar y reanudar la encuesta"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si no ve este correo electrónico correctamente, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No estoy conforme con el soporte que recibí porque…"])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnología de Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar motivo"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de la conversación"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo: El cliente tuvo problemas al actualizar el método de pago. El agente compartió un artículo que podría ser útil. Funcionó."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con tecnología de IA de Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de servicio"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le agradecemos estos comentarios."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es la razón principal de su puntuación?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díganos por qué dio esta calificación..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mucho esfuerzo"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poco esfuerzo"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["haga clic aquí para abrir la encuesta"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pregunta personalizada aparece aquí"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le dio a su experiencia con ", _interpolate(_list(0)), " una puntuación de"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar comentarios"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar suscripción"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy insatisfecho"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy satisfecho"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que el estado actual de la encuesta y las reglas de entrega no se incluirán en el duplicado de la encuesta"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Encuesta duplicada “", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usa el fragmento de correo electrónico incrustado, asegúrese de eliminarlo de Zendesk antes de pausar la encuesta"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se recopilan respuestas cuando la encuesta está en pausa."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar encuesta"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de eliminar el fragmento de Zendesk antes de pausar la encuesta."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar encuesta"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usa el fragmento de correo electrónico incrustado, asegúrese de eliminarlo de Zendesk antes de borrar la encuesta."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción borrará la encuesta de manera permanente. Los usuarios que recibieron la encuesta aún pueden completarla y todos los datos relacionados permanecerán en Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar encuesta ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de eliminar el fragmento de Zendesk antes de borrar la encuesta."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los nuevos encuestados verán los cambios aplicados."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar encuesta"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de copiar la última versión del fragmento incrustado después de publicar la encuesta."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usa el fragmento de correo electrónico incrustado, asegúrese de copiarlo a Zendesk después de reanudar la encuesta."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga enviando la encuesta y recopilando respuestas."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar y reanudar la encuesta"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de agregar el fragmento incrustado a Zendesk después de reanudar la encuesta."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar encuesta"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de copiar la última versión del fragmento incrustado después de actualizar la encuesta."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea borrar esta encuesta? Esta acción no se puede deshacer."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Borrar encuesta?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su versión de prueba ha vencido, pero puede seguir disfrutando de Zendesk QA si actualiza a una cuenta completa."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su versión de prueba ha vencido, pero puede seguir disfrutando de Zendesk QA si actualiza a una cuenta completa. Hable con el administrador local para configurarlo."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El periodo de prueba ha finalizado"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar ahora"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su prueba gratuita ha finalizado."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para seguir usando la versión completa de Zendesk QA, ingrese sus detalles de pago."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para seguir usando la versión completa de Zendesk QA, el administrador de su cuenta debe ingresar los detalles de pago."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar pago"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un usuario"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creado el ", _interpolate(_named("date")), " por ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Asignación ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al parecer, todavía está trabajando en esto. Perderá toda la información que haya agregado hasta aquí si sale sin guardar los cambios."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta asignación se repetirá cada dos ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta asignación se repetirá cada ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Todas las personas evaluadas están incluidas en un ciclo"]), _normalize(["Todas las personas evaluadas están incluidas en ", _interpolate(_named("nr")), " ciclos"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversaciones"]), _normalize([_interpolate(_named("n")), " conversación"]), _normalize([_interpolate(_named("n")), " conversaciones"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["de ", _interpolate(_named("nr")), " agente"]), _normalize(["de ", _interpolate(_named("nr")), " agente"]), _normalize(["de ", _interpolate(_named("nr")), " agentes"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["participante"]), _normalize(["participantes"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversaciones por ciclo"]), _normalize([_interpolate(_named("n")), " conversación por ciclo"]), _normalize([_interpolate(_named("n")), " conversaciones por ciclo"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " por persona revisada consecutiva"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["persona evaluada"]), _normalize(["personas evaluadas"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["evaluador"]), _normalize(["evaluadores"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas evaluadas"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadores"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios del espacio de trabajo"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios del espacio de trabajo excepto los agentes"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclo ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no recurrente"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Asignación ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluando"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre las asignaciones"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear asignación"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción borrará la asignación y eliminará sus datos del panel de manera permanente. Todas las evaluaciones relacionadas permanecerán en Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar asignación ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación borrada"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación duplicada"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se han agregado asignaciones"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo crear la asignación"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la asignación"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ciclo se actualiza y se crea una nueva lista de tareas. Las evaluaciones que ya se han recibido cuentan para alcanzar el objetivo."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De inmediato"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Al comienzo del próximo ciclo el ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuándo publicar los cambios?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios realizados en esta asignación se publicarán con esta asignación"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea configurar la asignación como activa?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurrente el día de inicio de la asignación"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer viernes de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo viernes de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer viernes de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuarto viernes de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último viernes de cada mes"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer lunes de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo lunes de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer lunes de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuarto lunes de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último lunes de cada mes"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer sábado de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo sábado de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer sábado de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuarto sábado de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último sábado de cada mes"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer domingo de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo domingo de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer domingo de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuarto domingo de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último domingo de cada mes"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El primer jueves de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El segundo jueves de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer jueves de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuarto jueves de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último jueves de cada mes"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El primer martes de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El segundo martes de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer martes de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuarto martes de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último martes de cada mes"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer miércoles de cada mes"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo miércoles de cada mes"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercer miércoles de cada mes"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuarto miércoles de cada mes"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último miércoles de cada mes"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurrencia personalizada"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El día 1 de cada mes"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primera"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercero"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuarto"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se repite"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día de semana"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalización"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay resultados"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona horaria"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del ciclo"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la asignación"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información básica"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al comienzo de cada ciclo de asignación, se genera una lista predeterminada de conversaciones para la evaluación."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración avanzada"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener nuevas conversaciones automáticamente cuando el objetivo de evaluación no se alcance"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La obtención automática no es posible con los objetivos de evaluación de porcentaje (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser un número entero, no un decimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir reemplazo de conversaciones (rol de evaluador y superiores)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reemplazar conversaciones"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir la asignación de evaluadores a sus propias conversaciones para evaluación"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluaciones"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluaciones"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad mínima de evaluaciones debe ser 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especifique el mínimo de evaluaciones para cada persona evaluada"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversaciones"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por ciento (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluaciones"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evaluaciones en total"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada evaluador debe realizar una cantidad determinada de evaluaciones"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los evaluadores deben evaluar colectivamente una cantidad determinada de conversaciones"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad mínima de evaluaciones debe ser 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El objetivo de evaluación debe estar entre 1 y 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo de la evaluación"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada persona evaluada debe tener una cantidad mínima de conversaciones evaluadas"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los evaluadores pueden elegir conversaciones del grupo compartido"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A los evaluadores se les asignan conversaciones del grupo compartido"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfoque de asignación"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los bots"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los participantes del espacio de trabajo"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios del espacio de trabajo"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar personas evaluadas"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿A quién se evaluará?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar personas evaluadas"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar a quién evaluar"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las exclusiones solo se permiten cuando se seleccionan todos los usuarios del espacio de trabajo o un grupo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadores y personas evaluadas"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Actual: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio de trabajo predeterminado"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué tipo de usuarios se deben seleccionar como personas evaluadas?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente más activo"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participante"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir personas evaluadas (opcional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar usuarios para excluir"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir evaluadores (opcional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar usuarios para excluir"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios del espacio de trabajo excepto los agentes"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar evaluadores"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quién hará las evaluaciones?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar evaluadores"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permiten autoevaluaciones en este espacio de trabajo"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir autoevaluaciones"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permiten autoevaluaciones en este espacio de trabajo."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conversación coincidente hoy"]), _normalize(["Conversaciones coincidentes hoy"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrando como si la asignación hubiera comenzado hoy."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los números futuros pueden variar."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han seleccionado personas evaluadas"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han seleccionado evaluadores"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Persona evaluada"]), _normalize(["Personas evaluadas"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Evaluador"]), _normalize(["Evaluadores"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtención automática: permitida si no hay suficientes conversaciones"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los viernes, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los lunes, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los sábados, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los domingos, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los jueves, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los martes, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", los miércoles, desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diariamente desde el ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comienza el ", _interpolate(_named("startDateTime")), " y termina el ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condiciones de fecha"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condiciones de la conversación y del help desk"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No hay conversación y ", _interpolate(_named("count")), " condición de help desk"]), _normalize(["No hay conversación y ", _interpolate(_named("count")), " condiciones de help desk"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversación y no hay condición de help desk"]), _normalize([_interpolate(_named("count")), " conversaciones y sin condición de help desk"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enfoque: los evaluadores eligen las conversaciones"]), _normalize(["Enfoque: a los evaluadores se les asignan conversaciones"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrija los errores resaltados en el resumen para continuar"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo: los evaluadores deben evaluar todas las conversaciones"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Objetivo: los evaluadores deben evaluar una conversación"]), _normalize(["Objetivo: los evaluadores deben evaluar ", _interpolate(_named("count")), " conversaciones"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objetivo: los evaluadores deben evaluar el ", _interpolate(_named("count")), "% de las conversaciones"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo: no se ha definido ningún objetivo de evaluación"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo: se debe evaluar el total de conversaciones de cada persona evaluada"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objetivo: se deben evaluar ", _interpolate(_named("count")), " conversaciones del total de conversaciones de cada persona evaluada"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objetivo: se debe evaluar el ", _interpolate(_named("count")), "% del total de conversaciones de cada persona evaluada"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Objetivo: cada revisor debe hacer una evaluación"]), _normalize(["Objetivo: cada revisor debe hacer ", _interpolate(_named("count")), " evaluaciones"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reemplazo: los evaluadores no pueden reemplazar conversaciones"]), _normalize(["Reemplazar: los evaluadores pueden reemplazar conversaciones"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluaciones: a los evaluadores se les pueden asignar sus propias conversaciones"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han establecido condiciones de fecha"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha establecido un nombre para la asignación"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " de ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada dos semanas"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada día"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada semana"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del ciclo"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer como activo"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar como borrador"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La asignación se guardó como borrador"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La asignación se configuró como inactiva"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer como inactivo"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear asignación"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación creada"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como activo"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como inactivo"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Las actualizaciones pendientes a la asignación entrarán en vigencia en el próximo ciclo, a partir del ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar cambios"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar asignación"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación actualizada"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente o el bot concluyó la conversación."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente terminó la conversación amablemente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si la conversación se concluyó"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza los errores de gramática, ortografía y estilo en las interacciones de agentes y bots."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza los errores de gramática, ortografía y estilo del agente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza los errores de gramática, ortografía y estilo del agente"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografía y gramática"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza la conversación en busca de mensajes de agente y bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza toda la conversación en busca de frases de saludo de bienvenida típicas"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza toda la conversación para ver saludos de bienvenida típicos"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de bienvenida"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente comprende y reconoce los sentimientos de los clientes"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica los casos en los que el agente reflejó la percepción del cliente o usó palabras comprensivas como “comprender” y “reconocer”"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatía"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente entiende la consulta o preocupación del cliente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza si el agente entiende la consulta o preocupación del cliente"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprensión"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza la facilidad con la que se puede entender un texto, teniendo en cuenta la complejidad de las palabras y la longitud de las oraciones"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza la facilidad con la que se puede entender un texto, teniendo en cuenta la complejidad de las palabras y la longitud de las oraciones"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mensaje contiene 1 oración demasiado larga, lo que dificulta su lectura"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Este mensaje contiene ", _interpolate(_list(0)), " oraciones demasiado largas, lo que dificulta su lectura"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legibilidad"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza toda la conversación en busca de una solución ofrecida"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza toda la conversación en busca de una solución ofrecida"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solución ofrecida"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza el tono del agente en toda la conversación"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza el tono del agente en toda la conversación"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar ningún error en los mensajes del agente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fue posible determinar errores gramaticales"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se detectó ninguna frase de saludo de bienvenida. Agregue nuevos si es necesario desde el administrador en Configuración."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente concluyó la conversación de manera amable"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se detectó ninguna frase de cierre. Agregue nuevos si es necesario desde el administrador en Configuración"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente reflejó la percepción del cliente o usó palabras cariñosas"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar si el agente reflejó la percepción del cliente o usó palabras comprensivas"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente usó oraciones largas y palabras complejas, lo que hace que para algunos sea potencialmente difícil de entender"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente usó palabras complejas, lo que hace que para algunos sea potencialmente difícil de entender"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente usó oraciones largas, lo que hace que para algunos sea potencialmente difícil de entender"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente usó un lenguaje fácil de entender"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay suficientes datos para calificar esta categoría"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente tuvo dificultades para comprender la pregunta o solicitud del cliente"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente comprendió la pregunta o solicitud del cliente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar si el agente comprendió la pregunta o solicitud del cliente"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono del agente durante la conversación"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono del agente durante la conversación"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono del agente durante la conversación"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono del agente durante la conversación"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono del agente durante la conversación"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fue posible determinar el tono del agente"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación no terminó"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversación terminó"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fue posible detectar si la conversación finalizó"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente no tuvo empatía hacia el cliente y sus problemas"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente mostró empatía hacia el cliente y sus problemas"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar si el agente mostró empatía hacia el cliente y sus problemas "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se saludó al cliente durante la conversación"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se saludó al cliente durante la conversación"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar el saludo de bienvenida típico"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente no proporcionó una solución al cliente"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El agente ofreció una solución al cliente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo detectar si el agente ofreció una solución al cliente"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras complejas"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oraciones largas"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramática"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta de ortografía"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disculpa"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calma"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegre"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curioso"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solidario"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estilo"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 cierre"]), _normalize([_interpolate(_named("n")), " cierres"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA solo verifica los cierres aprobados. Si esta categoría se configura como crítica, no se aprobará la evaluación si no se usan los cierres aprobados."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierres aprobados"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierres aprobados"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 exención"]), _normalize([_interpolate(_named("n")), " exenciones"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA ignora las palabras y frases como errores de ortografía o gramática."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exenciones de ortografía y gramática"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exenciones de AutoQA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 saludo de bienvenida"]), _normalize([_interpolate(_named("n")), " saludos de bienvenida"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA solo verifica los saludos de bienvenida aprobados. Si esta categoría se configura como crítica, no se aprobará la evaluación si no se usan los saludos de bienvenida aprobados."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludos de bienvenida aprobados"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludos de bienvenida aprobados"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de AutoQA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una categoría de AutoQA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas raíz asignadas automáticamente:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta → Configuración"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Algunas categorías utilizan MS Azure para ofrecer una amplia gama de funcionalidades e idiomas admitidos. Revise su ", _interpolate(_named("link")), " para obtener más información, o comuníquese con un administrador para que lo haga."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Acceda a una lista ampliada de categorías e idiomas compatibles aprovechando MS Azure. Revise su ", _interpolate(_named("link")), " o comuníquese con un administrador para que lo haga."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A las categorías de AutoQA se les asigna una puntuación automáticamente, usando una escala de calificación fija. Puede ajustar la calificación manualmente más adelante. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una categoría de AutoQA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La puntuación asignada automáticamente depende de la gravedad de los errores y su número. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas de fondo"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los evaluadores solo ven las categorías de calificación pertinentes. Las categorías de calificación se muestran en función de las condiciones que se establecieron al crear una categoría."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chatear"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integración"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teléfono"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleccione el tipo de categoría de calificación para el espacio de trabajo “", _interpolate(_named("workspace")), "”."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los evaluadores deben seleccionar manualmente la tarjeta de puntuación correcta para ver las categorías de calificación pertinentes para cada conversación."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea cambiar el tipo de categoría de calificación?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de categoría"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cambiar el tipo de categoría de calificación?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación condicional"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación estática"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de categoría de calificación"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la categoría de calificación"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción no se puede deshacer."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["borrar todas las calificaciones"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como alternativa, puede guardar sus estadísticas sin borrar ningún dato al archivar una categoría."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comprendo que esto ", _interpolate(_named("deleteAllRatings")), " proporcionados para esta categoría."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p. ej., el tono es perfecto"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar categoría solo si"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Siempre” se ignorará. No se puede combinar con otras condiciones."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siempre"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas de help desk"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar condición"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de satisfacción (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de origen"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de conversación"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una categoría necesita un nombre"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar la categoría a una tarjeta de puntuación"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear categoría"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear categoría"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación agregada"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una categoría archivada no es visible en la tarjeta de puntuación, pero le permite guardar sus estadísticas sin borrar ningún dato."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar categoría"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Archivar ", _interpolate(_list(0)), " categoría"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación archivada"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar categoría"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba “borrar categoría” para eliminar esta categoría"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["borrar categoría"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Borrar categoría ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación borrada"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación duplicada"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de categoría de calificación agregado"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de categoría de calificación borrado"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden del grupo de la categoría de calificación modificado"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de categoría de calificación actualizado"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación cancelada"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación actualizada"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando un agente no aprueba una categoría crítica, todas las categorías de esa evaluación se desaprueban de manera automática."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de calificación crítica"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay categorías en este grupo"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva categoría de calificación"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar categoría"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todas las categorías y revisar los datos de este grupo"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se borra el grupo de categorías, se dejarán sin agrupar todas las categorías vinculadas."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar ", _interpolate(_named("categoryName")), " grupo de categoría"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si elige borrar las categorías del grupo, se perderán todos los datos de evaluación asociados con esas categorías."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del grupo"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los evaluadores pueden seleccionar más de una razón como causa de fondo"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los evaluadores pueden seleccionar más de una razón como causa de fondo"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varias razones"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p. ej., Tono"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nuevo grupo de categorías"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo nuevo"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar “Otro”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar causa de fondo"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta causa de fondo ya existe"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar causa de fondo"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar causas de fondo para explicar la calificación"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de calificación"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivados"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar tarjetas de puntuación a esta categoría de calificación"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarchivar"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar categoría"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las calificaciones"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se muestra para"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificaciones negativas"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificaciones negativas y neutras"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, actualizar"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponderación"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las categorías que son más importantes para usted pueden tener una mayor ponderación en el cálculo de la puntuación del ticket."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ponderación (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre la calibración"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre la calibración"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La realización de sesiones de calibración regulares permite a los evaluadores mantener alineadas sus técnicas de calificación y garantizar que los agentes reciban el mismo nivel de comentarios sistemáticos y sin sesgos."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver planes"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " es un proceso que ayuda a su equipo a mantenerse al tanto de cómo deben manejarse y evaluarse todas las interacciones con los clientes."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profesional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La calibración está disponible para nuestros planes ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las evaluaciones proporcionadas anteriormente no se muestran cuando se agrega una conversación a la sesión."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No visible"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las evaluaciones realizadas anteriormente se consideran evaluaciones de calibración. Solo se agregará una evaluación de conversación por evaluador a la sesión. No se muestran las evaluaciones específicas de los mensajes."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad de evaluaciones proporcionadas anteriormente"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La calibración es un proceso que ayuda a su equipo a mantenerse al tanto de cómo deben manejarse y evaluarse todas las interacciones con los clientes. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios tienen acceso ilimitado a todas las evaluaciones realizadas durante una sesión de calibración."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver siempre todas las evaluaciones"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de enviar una evaluación, los usuarios verán las evaluaciones realizadas por otros evaluadores durante una sesión de calibración."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las evaluaciones de calibración después de enviar una evaluación"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios tienen acceso ilimitado a todas las evaluaciones realizadas durante una sesión de calibración."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver siempre todas las evaluaciones"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de enviar una evaluación, los usuarios verán las evaluaciones realizadas por otros evaluadores durante una sesión de calibración."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las evaluaciones de calibración después de enviar una evaluación"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de enviar una evaluación, los usuarios verán las evaluaciones realizadas por otros evaluadores durante una sesión de calibración."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las evaluaciones de calibración después de enviar una evaluación"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios solo verán sus propias evaluaciones durante una sesión de calibración."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver solo sus propias evaluaciones"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de calibración se actualizó"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad de las evaluaciones de calibración"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure el umbral para las métricas del panel completando las brechas de acuerdo con el sistema de métricas interno de su equipo. Revise el umbral en el panel."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiones"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral actualizado"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un momento…"])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asegúrese de tener permisos de administrador y ", _interpolate(_named("retry")), ", o ", _interpolate(_named("contact")), " si desea más ayuda."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contáctenos"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al parecer, no pudimos obtener acceso a la API de su help desk."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volver a intentar"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos conectando su help desk. Se le redirigirá en un momento."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " se autorizó"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Regrese a su ", _interpolate(_list(0)), " para agregar una nueva conexión al help desk."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al parecer, ha omitido uno o dos pasos."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una organización con el mismo dominio de correo electrónico"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["página de conexiones"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mientras tanto..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denos un momento mientras verificamos sus permisos."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mientras tanto..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrando"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crear un espacio de trabajo"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para seguir usandoZendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casi listo para usar"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún espacio de trabajo"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de cuenta"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para seguir usando Zendesk QA, su administrador debe asignarle un espacio de trabajo."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si borra su cuenta, se borrarán permanentemente todos los datos que tenemos sobre usted, ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todas las calificaciones proporcionadas, el contenido de las conversaciones, los espacios de trabajo, etc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se desconecta la integración, se desactivarán todas las notificaciones de Zendesk QA mediante Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La integración de Slack se ha desconectado de Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina la configuración de notificaciones predeterminada para todos los usuarios de cuentas nuevas."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anular la configuración de todos los usuarios"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de anular la configuración de notificaciones para todos los usuarios de la cuenta"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios no podrán definir su propia configuración de notificaciones."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de las notificaciones se actualizó"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertar cuando se desapruebe una categoría crítica"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertar cuando CSAT reciba una respuesta"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparadores"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree espacio de trabajo y datos de demostración"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recree los datos de la demostración y conserve el espacio de trabajo de la demostración."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar el espacio de trabajo y los datos de la demostración"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar los datos de la demostración"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar datos"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Administrar cuenta “", _interpolate(_named("accountName")), "” en modo de demostración"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de demostración"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/6059285322522-Acerca-de-las-funciones-de-IA-generativa-en-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043712839450-Configurar-opciones-de-seguridad-avanzadas-para-las-conexiones-del-help-desk"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043747123354-Configuraci%C3%B3n-de-la-puntuaci%C3%B3n-autom%C3%A1tica-en-Zendesk-QA-usando-AutoQA"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7418648293018-Evaluaci%C3%B3n-del-rendimiento-de-los-agentes-de-IA-con-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043724530842-Configuraci%C3%B3n-de-la-calibraci%C3%B3n-en-Zendesk-QA"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043759436954-An%C3%A1lisis-de-las-puntuaciones-de-las-categor%C3%ADas-a-lo-largo-del-tiempo"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043747231642-Uso-de-la-vista-Informaci%C3%B3n-de-las-conversaciones"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043747649690-Crear-varias-tarjetas-de-puntuaci%C3%B3n"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043701093786-Introducci%C3%B3n-a-los-c%C3%A1lculos-del-panel-Evaluaciones-de-Zendesk-QA"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043759449114-Buscar-conversaciones-para-evaluaci%C3%B3n-usando-filtros-personalizados"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043760162074-Administraci%C3%B3n-de-usuarios-y-espacios-de-trabajo"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043759543194-Uso-de-notas-ancladas-para-instruir-a-los-agentes"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043747142938-Administraci%C3%B3n-de-grupos-en-Zendesk-QA"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043759586074-Uso-de-informaci%C3%B3n-valiosa-de-Destacado-de-Zendesk-QA-para-filtrar-conversaciones"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/articles/7043759396250-Seguimiento-del-tiempo-de-evaluaci%C3%B3n-en-el-control-de-Zendesk-QA"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/es/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanecer en la página"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonar página"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Salir de esta página y perder todos los cambios?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha completado la asignación"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear asignación"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación completada"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron asignaciones"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna tarea para evaluar."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuste la asignación o espere a que comience el próximo ciclo."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruebe ajustar los filtros"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar asignación"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esté atento a las actualizaciones en esta página"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tareas para este ciclo"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conversaciones para mostrar"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar tarea como terminada"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha evaluado a este usuario."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay autoevaluaciones en esta asignación"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic en el icono de calibrado en el encabezado de la vista de conversación para agregar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron conversaciones"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de vencimiento"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear asignación"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear sesión de calibración"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus asignaciones"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras asignaciones"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay asignaciones activas"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay asignaciones"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las personas evaluadas"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las personas evaluadas excepto yo"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron personas evaluadas"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar persona evaluada"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los estados"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para hacer"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los evaluadores"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los evaluadores excepto yo"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignadas a mí"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron evaluadores"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar evaluador"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " días"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " días"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " día"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " días"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " días"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " días"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hora"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuto"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO APLICA"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios del espacio de trabajo"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador actual"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo objetivo para este ciclo"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo evaluador"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar evaluador"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar conversaciones"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar conversación"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversaciones para mover"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclo actual"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar asignación"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron evaluadores"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar evaluador"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignarme conversaciones"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar conversaciones a otro usuario"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadores"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " días"]), _normalize([_interpolate(_named("n")), " día"]), _normalize([_interpolate(_named("n")), " días"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " horas"]), _normalize([_interpolate(_named("n")), " hora"]), _normalize([_interpolate(_named("n")), " horas"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar ciclos completados"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar sesiones completadas"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su objetivo"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue una razón para eliminar esta conversación"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por qué elimina esta conversación"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar conversación"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea conservar los cambios?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue una razón para reemplazar esta conversación"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar cambios"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantener"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conservar cambios"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró el reemplazo"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por qué reemplaza esta conversación"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Aún desea eliminar esta conversación de la asignación? Esto reducirá el objetivo en 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reemplazar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reemplazar conversación"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar borrador"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar borrador"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar a mí"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar a otro usuario"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasignar y evaluar"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar conversación"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar una evaluación"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objetivo: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " evaluados"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones hechas"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar ciclos completados"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar sesiones completadas"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo restante"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tareas"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversación reemplazada"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de tareas"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " más tarde"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " segundo"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " segundos"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " días"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("minutes")), "m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "min"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("seconds")), "s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " meses"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " años"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace un momento"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene permisos. Comuníquese con el administrador para obtener ayuda."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La página que está buscando no existe."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error inesperado. Comuníquese con soporte para obtener ayuda."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso denegado. No tiene permiso para realizar esa acción."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La página que está buscando no existe o no tiene acceso a ella."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está yendo demasiado rápido y nosotros no podemos ponernos al día."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se guardaron los cambios"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hicieron cambios sin guardar"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " aplicado(s)"]), _normalize([_interpolate(_named("n")), " aplicado(s)"]), _normalize([_interpolate(_named("n")), " aplicado(s)"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado por"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crítico"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Exmiembro"]), _normalize(["un exmiembro"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " seleccionado(s)"]), _normalize([_interpolate(_named("n")), " seleccionado(s)"]), _normalize([_interpolate(_named("n")), " seleccionado(s)"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["… y 1 más"]), _normalize(["… y ", _interpolate(_named("n")), " más"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 más"]), _normalize([_interpolate(_named("n")), " más"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuevas"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o bien"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " respuestas"]), _normalize([_interpolate(_named("n")), " respuesta"]), _normalize([_interpolate(_named("n")), " respuestas"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoy"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Viernes"]), _normalize(["Viernes"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lunes"]), _normalize(["Lunes"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sábado"]), _normalize(["Sábados"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Domingo"]), _normalize(["Domingos"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jueves"]), _normalize(["Jueves"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Martes"]), _normalize(["Martes"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Miércoles"]), _normalize(["Miércoles"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayer"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrador"]), _normalize(["un administrador"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrador de cuenta"]), _normalize(["un administrador de cuenta"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuario"]), _normalize(["un usuario"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sesión ha vencido."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicios de sesión"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo no puede estar vacío."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún elemento."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay personas que coincidan."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dueño"]), _normalize(["un dueño"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de comenzar, lea y acepte nuestros términos."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones hechas en general"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agente"]), _normalize(["un agente"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["un lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gerente"]), _normalize(["un gerente"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Evaluador"]), _normalize(["un evaluador"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario anónimo"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente asignado"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para copiar"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar vínculo"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona de peligro"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aceptar"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción (opcional)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere confirmación"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea descartar los cambios sin guardar?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frecuencia"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendido"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACEPTAR"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de aprobados"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración personal"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer menos"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a enviar"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona evaluada"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas/vistas"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones realizadas por un integrante del espacio de trabajo"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala binaria"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de 3 puntos"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de 4 puntos"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de 5 puntos"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de puntuación"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar frecuencia"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menos"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar más"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omitir"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacado"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paso"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la encuesta"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de tiempo"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hicieron cambios sin guardar"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponderación"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Recibió una invitación de ", _interpolate(_list(0)), " para unirse a Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Los usuarios se conectan automáticamente desde el Centro de administración. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar usuarios"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre la administración de usuarios"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Algunos usuarios se aprovisionan y se sincronizan automáticamente desde su proveedor de identidad. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El permiso de la cuenta de “", _interpolate(_list(0)), "” se modificó"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre los permisos de usuario"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No volver a preguntarme"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Todos los cambios realizados en la página de usuarios se reflejan en los espacios de trabajo. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto significa que el acceso del usuario a ciertas partes de Zendesk QA puede cambiar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar los permisos de la cuenta"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuario eliminado"]), _normalize([_interpolate(_named("n")), " usuarios eliminados"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si elimina a un usuario, este perderá el acceso a su cuenta. Sus evaluaciones, comentarios y calificaciones permanecerán en Zendesk QA. ", _interpolate(_named("linebreak")), "La eliminación de un usuario no afecta la cantidad de licencias de la suscripción."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Este usuario se eliminará de todos los espacios de trabajo que administre. ", _interpolate(_named("linebreak")), " Sus evaluaciones, comentarios y calificaciones permanecerán intactas."]), _normalize(["Se eliminarán de todos los espacios de trabajo que administre. ", _interpolate(_named("linebreak")), " Todas sus evaluaciones, comentarios y calificaciones permanecerán intactas."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Eliminar ", _interpolate(_named("name"))]), _normalize(["Eliminar ", _interpolate(_named("name")), " usuarios"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede ver todo y administrar la configuración y la facturación"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede ver todo y administrar la configuración personal y del espacio de trabajo, excepto la facturación"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos de cuenta"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los permisos específicos del espacio de trabajo permiten al usuario ver (y) editar datos dentro de los espacios de trabajo en los que se encuentran."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los bots aparecen aquí a medida que se sincronizan las conversaciones en las que han participado"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay bots sincronizados"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se marca a un bot como usuario, se le otorgará acceso para iniciar sesión. Sus datos históricos de bot seguirán estando disponibles en los paneles."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tales bots"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente volver a buscar usando una palabra clave u ortografía diferente."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar bots"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre de bot"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como usuario"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo los administradores y los gerentes de cuentas pueden marcar bots como usuarios"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del bot"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcado manualmente"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluable"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El bot se elimina del análisis automático y de las asignaciones. Los nuevos datos no aparecerán en los paneles, lo que garantiza informes precisos y una administración más sencilla de los bots."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot generativo"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot de flujo de trabajo"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a grupo(s)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los grupos"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún grupo"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar de grupo(s)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar grupo"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiso de la cuenta"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a espacio(s) de trabajo"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo por última vez"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol a nivel de cuenta"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cada usuario se le asigna un permiso de nivel de cuenta o espacio de trabajo. Los permisos de administrador ahora se otorgan a nivel de cuenta, mientras que los otros permisos se otorgan a nivel de espacio de trabajo."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar de espacio(s) de trabajo"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiso del espacio de trabajo"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos administrados en grupo"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalles"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se marca a un usuario como bot, se le quitará el acceso para iniciar sesión. Sus datos históricos de usuario seguirán estando disponibles en los paneles."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimine el bot del análisis automático y de las asignaciones. Los nuevos datos no aparecerán en los paneles, lo que garantiza informes precisos y una administración más sencilla de los bots."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir bot de las revisiones"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario marcado como bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo el tipo de permiso Usuario puede marcarse como bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios con varias cuentas no se pueden marcar como bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por usuario"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios, bots y espacios de trabajo"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPACIOS DE TRABAJO"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios y espacios de trabajo"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienen permisos"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregado manualmente"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación pendiente"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios de SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere una acción"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["… y uno más"]), _normalize(["… y ", _interpolate(_named("n")), " más"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar permiso a"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Elimine y vuelva a invitar para cambiar el rol de ", _interpolate(_named("n")), " usuario por invitación pendiente."]), _normalize(["Elimine y vuelva a invitar para cambiar el rol de ", _interpolate(_named("n")), " usuarios por invitación pendientes"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Editar detalles de ", _interpolate(_named("name"))]), _normalize(["Editar detalles de ", _interpolate(_named("n")), " usuarios seleccionados"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuario actualizado."]), _normalize([_interpolate(_named("n")), " usuarios actualizados."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Este grupo contiene 1 usuario de otro espacio de trabajo."]), _normalize(["Este grupo contiene ", _interpolate(_named("n")), " usuarios de otro espacio de trabajo."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo “", _interpolate(_named("name")), "” archivado"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo archivado"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear grupo"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se creó el grupo “", _interpolate(_named("name")), "”"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo sin título"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Borrar este grupo de manera permanente?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar grupo"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar grupo"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar grupo"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead de grupo"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol de grupo"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo “", _interpolate(_named("name")), "” oculto"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este grupo no será visible en ningún otro lugar de Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo administrar los permisos del espacio de trabajo"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo no visible en Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembro"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo sin título"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro grupo ya tiene este nombre"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un grupo archivado ya tiene este nombre"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su rol solo permite administrar a los miembros de este grupo. Contacte a un usuario con permisos más altos si desea realizar más cambios."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar como lead de grupo"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo “", _interpolate(_named("name")), "” restaurado"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo “", _interpolate(_named("name")), "” guardado"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer como lead de grupo"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo “", _interpolate(_named("name")), "” desarchivado"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios sin guardar"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue a sus usuarios existentes a este grupo"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre o correo electrónico"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar miembros"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo “", _interpolate(_named("name")), "” visible"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cada miembro es ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre los grupos"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algunos atributos de grupos se aprovisionan y se sincronizan automáticamente desde su proveedor de identidad."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información sobre los grupos"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear grupo"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organice fácilmente a los integrantes de su equipo e imite la estructura de su organización."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue por lo menos 1 miembro"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue un nombre de grupo"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe un grupo con este nombre"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrantes del grupo"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del grupo"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organice a los integrantes de su equipo e imite la estructura de su organización."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sin grupos"]), _normalize(["1 grupo"]), _normalize([_interpolate(_named("count")), " grupos"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree un grupo nuevo o active uno archivado."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay grupos activos disponibles"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay grupos archivados"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguno de esos grupos"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente volver a buscar usando una palabra clave u ortografía diferente."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregó ningún grupo"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["en estos"]), _normalize(["en"]), _normalize(["en"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre de grupo"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivados"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es posible reenviar ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licencias restantes"]), _normalize([_interpolate(_named("n")), " licencia restante"]), _normalize([_interpolate(_named("n")), " licencias restantes"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad de licencias es limitada actualmente, ya que su suscripción está en pausa. Vuelva a activarla para agregar más usuarios."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el límite de licencias de su cuenta. Para reemplazar usuarios, puede desactivar a un usuario activo o agregar más licencias."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver los detalles de pago"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " seleccionado(s)"]), _normalize([_interpolate(_named("n")), " seleccionado(s)"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuario seleccionado"]), _normalize([_interpolate(_named("n")), " usuarios seleccionados"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " filas"]), _normalize([_interpolate(_named("n")), " fila"]), _normalize([_interpolate(_named("n")), " filas"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque usuarios de forma masiva por sus nombres o direcciones de correo electrónico."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegue los datos aquí separados por comas o nuevas líneas"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer búsquedas masivas de usuarios"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Editar los permisos del espacio de trabajo para ", _interpolate(_named("name"))]), _normalize(["Editar los permisos del espacio de trabajo para ", _interpolate(_named("n")), " miembros seleccionados"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos de edición"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar permiso de espacio de trabajo"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Eliminar ", _interpolate(_named("name")), " del espacio de trabajo"]), _normalize(["Eliminar ", _interpolate(_named("name")), " miembros del espacio de trabajo"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " integrante eliminado"]), _normalize([_interpolate(_named("n")), " integrantes eliminados"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eliminar miembro"]), _normalize(["Eliminar miembros"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Este usuario se eliminará del espacio de trabajo."]), _normalize(["Estos usuarios se eliminarán del espacio de trabajo."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El permiso del espacio de trabajo de “", _interpolate(_list(0)), "” se modificó."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar miembros"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea conversaciones y evaluaciones relacionadas"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea todo lo que hay en el espacio de trabajo, pero administre cuestionarios, asignaciones, disputas y sesiones de calibración únicamente"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos del espacio de trabajo"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea y administre todo dentro del espacio de trabajo"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vea todo en el espacio de trabajo, excepto la configuración del espacio de trabajo"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectar usuarios"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios de los espacios de trabajo que administra se han agregado a este espacio."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicítele a un administrador que lo haga."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite a los nuevos usuarios directamente usted mismo o pídale a un administrador que lo haga."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " o ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay usuarios para mostrar"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " usuario agregado"]), _normalize([_interpolate(_named("n")), " usuarios agregados"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Agregar miembros"]), _normalize(["Agregar miembro"]), _normalize(["Agregar ", _interpolate(_named("n")), " miembros"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar permiso de espacio de trabajo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar usuarios para el espacio de trabajo"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Agregar miembros a ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegue los correos electrónicos aquí separados por líneas o comas"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " correos electrónicos coinciden"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" con usuarios que ya están conectados:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No fue posible hacer coincidir ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " correos electrónicos"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique la ortografía o conéctelos más tarde de manera individual"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" con los usuarios de help desk:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuario conectado"]), _normalize([_interpolate(_named("n")), " usuarios conectados"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuario invitado"]), _normalize([_interpolate(_named("n")), " usuarios invitados"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión masiva"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación masiva"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo:\n\nkate", "@", "ejemplo.com, john", "@", "ejemplo.com\n\nkate", "@", "ejemplo.com\nkate", "@", "ejemplo.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conéctese desde su help desk para evaluar sus interacciones"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conectar usuario"]), _normalize(["Conectar usuarios"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes de correo electrónico"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar de manera individual"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desea que sus usuarios conectados tengan acceso a Zendesk QA, puede invitarlos aquí."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a usuarios conectados"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Invitar usuario"]), _normalize(["Invitar usuarios"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omitir invitaciones"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione a los usuarios de su help desk para que se conecten y revisen sus interacciones"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre su suscripción"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar usuarios de help desk"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dele un nombre único a su espacio de trabajo"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se activa, todas las conexiones de cuentas existentes se agregan automáticamente al nuevo espacio de trabajo."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar todas las conexiones a este espacio de trabajo"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear espacio de trabajo"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del espacio de trabajo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear espacio de trabajo"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A los usuarios que se agregan directamente a la cuenta (sin asignarles un espacio de trabajo) se les asigna un rol de nivel de cuenta. Puede agregarlos a los espacios de trabajo más adelante."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A los usuarios invitados se les asigna automáticamente un rol de usuario a nivel de cuenta. Puede actualizarlos a un rol de administrador más adelante."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo esto funciona"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegue los correos electrónicos aquí separados por líneas nuevas o comas."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuario invitado por correo electrónico"]), _normalize([_interpolate(_named("n")), " usuarios invitados por correo electrónico"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A los usuarios agregados a un espacio de trabajo se les asigna automáticamente un rol de usuario en el nivel de la cuenta. Puede actualizarlos a un rol de administrador más adelante."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar otro"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a los usuarios solo a la cuenta sin agregarlos a un espacio de trabajo"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A los usuarios invitados solo se les asigna un permiso de cuenta y no se agregan a ningún espacio de trabajo."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a los usuarios directamente a un espacio de trabajo y asignar permisos al espacio de trabajo"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar usuarios al espacio de trabajo"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación masiva"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar de manera individual"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar solo a la cuenta"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a un espacio de trabajo"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Invitar usuarios"]), _normalize(["Invitar usuario"]), _normalize(["Invitar ", _interpolate(_named("n")), " usuarios"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a los usuarios por correo electrónico de manera masiva"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Borrar usuario"]), _normalize(["Borrar usuarios"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseleccionar"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar a los usuarios por correo electrónico"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin espacios de trabajo"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar permiso"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a enviar la invitación"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario conectado"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario de invitación por correo electrónico pendiente"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario de SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar espacio de trabajo"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentes IA"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de administración"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de calidad"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de personal"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Completada ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Completada ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Completada ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclo ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluador"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar asignación"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorre tiempo y configure asignaciones de evaluación automáticas en lugar de buscar y asignar manualmente las conversaciones para evaluar."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vence ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es posible agregar sesiones en la sección \"Calibración\" en el menú lateral de la vista de conversación."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se ha agregado ninguna sesión"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["línea de base"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay sesiones"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pueden agregar conversaciones a una sesión haciendo clic en el icono de calibración en el encabezado de la vista de conversaciones."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se han agregado conversaciones"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se han hecho evaluaciones. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar una evaluación de calibración"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causa de fondo"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la paleta de comandos."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró nada."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrado por ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando paleta de comandos…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["páginas, filtros, conversaciones, configuración…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversación"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro privado"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro público"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Del ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde su API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mostrando ", _interpolate(_named("rangeStart")), " a ", _interpolate(_named("rangeEnd")), " de ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Mostrando ", _interpolate(_named("rangeStart")), " a ", _interpolate(_named("rangeEnd")), " de ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar panel lateral"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar panel lateral"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar barra lateral"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas aceptadas"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas rechazadas"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas abiertas"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas aceptadas parcialmente"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones hechas"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones vistas"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptadas"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abierto"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parcialmente aceptadas"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " del total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazada"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptada"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente aceptadas"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazada"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["evaluación"]), _normalize(["evaluaciones"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de evaluaciones que vieron las personas evaluadas"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de evaluaciones que se han visto"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluadores en disputa"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personas que disputan"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas por categoría"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disputas a lo largo del tiempo"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de resolución promedio"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de resolución promedio (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de resolución promedio (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resuelto"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de disputas"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptada"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma de respuesta"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron idiomas"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los idiomas"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductores previstos"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron conductores"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los conductores"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras de la nube de palabras"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron palabras"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las palabras"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menciones"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más recientes"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más antiguas"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente aceptadas"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ha reaccionado con ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazado"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resuelto"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el tono de piel"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron sesiones"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron categorías"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño del comentario"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tamaños"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los tamaños"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron filtros"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún grupo"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los grupos"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron hashtags"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron valores"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los valores"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron campos personalizados"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas de help desk"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron etiquetas de help desk"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las etiquetas de help desk"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando resultados…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de pregunta"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tipos de pregunta"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar reacción"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas de puntuación"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tarjetas de puntuación"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las tarjetas de puntuación"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron puntuaciones"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las puntuaciones"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orígenes"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron orígenes"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los orígenes"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón del comentario"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron razones"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las razones"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuestas"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron encuestas"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las encuestas"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canales"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron canales"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los canales"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron usuarios"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron espacios de trabajo"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los espacios de trabajo"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a cuestionarios"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuestionario se ha borrado o aún no se ha publicado. Vuelva a verificar el vínculo o regrese a la lista de cuestionarios."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este cuestionario no se ha compartido con un espacio de trabajo al que pertenece. Solicite a un administrador que lo agregue a un espacio de trabajo al que tenga acceso este cuestionario."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuestionario no está disponible"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se dio acceso a los cuestionarios de espacios de trabajo a los que no pertenece. No se pudo guardar ni publicar el cuestionario."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pida a un administrador que guarde y publique el cuestionario."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe marcar por lo menos una de las respuestas como correcta."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo no puede estar vacío."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está viendo una vista previa del cuestionario."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione los espacios de trabajo que pueden acceder al cuestionario. Los usuarios que pertenecen a varios espacios de trabajo pueden responder el cuestionario solo una vez. Si selecciona “Todos los espacios de trabajo”, no se incluirán los espacios de trabajo recién creados una vez que se haya publicado el cuestionario."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso a cuestionario"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir del modo de vista previa"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llene el cuestionario"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar cuestionario"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del cuestionario"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar como borrador"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo cuestionario"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarchivar"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opciones"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar opción"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartirlo"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ve bien, ¿no?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su respuesta se ha enviado"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuestionario se ha guardado"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuestionario se ha publicado"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuestionario se ha eliminado"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue una pregunta"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar cuestionario"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cuestionarios archivados y sus resultados ya no aparecerán en la lista de cuestionarios. Aún puede acceder a los resultados de los cuestionarios archivados y desarchivarlos cuando sea necesario."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar cuestionario"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez publicados, los cuestionarios no se pueden cambiar ni editar. Todos los usuarios de su cuenta podrán acceder a todos los cuestionarios publicados."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Publicar cuestionario?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea borrar este cuestionario? Esta acción no se puede deshacer."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Borrar cuestionario?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez enviadas, sus respuestas no se pueden cambiar."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Enviar cuestionario?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarchivar cuestionario"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se elimina el archivo del cuestionario, estará disponible en la información general del cuestionario. Permitirá a los participantes anteriores acceder a sus resultados y a los nuevos responder el cuestionario."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarchivar cuestionario"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta duplicada"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción (opcional)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover pregunta hacia abajo"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover pregunta hacia arriba"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la vista previa solo se muestran las preguntas con respuestas."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar pregunta"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar cuestionario"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionario sin título"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título del cuestionario"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto borrará el cuestionario y sus resultados de manera permanente."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como alternativa, también puede archivar el cuestionario para acceder a los resultados."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respondidas"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar cuestionario"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto borrará el cuestionario y sus resultados de manera permanente."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Borrar cuestionario ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cuestionarios ayudan a impulsar la confianza y las habilidades del equipo, ofrecer experiencias coherentes e integrar a los nuevos integrantes del equipo."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede buscar cuestionarios por nombre del cuestionario o nombre del autor."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se han creado cuestionarios"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar lista de cuestionarios"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación promedio"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionario/Autor"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionario/Autor/Espacios de trabajo"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicadas"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios de trabajo"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por cuestionario, autor"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivado"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los cuestionarios"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuestionario se ha archivado."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se eliminó el archivo del cuestionario."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este cuestionario no recibió respuestas de los usuarios en los espacios de trabajo seleccionados."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este cuestionario no recibió ninguna respuesta antes de ser archivado."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay respuestas"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí verá un desglose de las respuestas del cuestionario.\nNadie ha completado su cuestionario todavía; sin duda sería útil compartirlo."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay respuestas"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación promedio:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Copiar"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se copió el vínculo"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron resultados"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionario"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cuestionarios de ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede buscar el nombre del participante en la tabla de clasificación."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar tabla de clasificación"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionario"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por participante"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla de marcadores"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar la tabla de clasificación"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto significa que ya no podrá filtrar este hashtag en el panel y que ya no se completará automáticamente en los comentarios. Esta acción no se puede revertir."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear hashtags"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear hashtags"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que los miembros creen nuevos hashtags"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usó"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Borrar hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La creación de hashtags está bloqueada"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La creación de hashtags está desbloqueada"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag “", _interpolate(_list(0)), "” borrado"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se cambió el nombre del hashtag “", _interpolate(_list(0)), "” a “", _interpolate(_list(1)), "”"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag agregado"]), _normalize([_interpolate(_named("count")), " hashtags agregados"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo nombre"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se crearon hashtags"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de nombre"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cambiar nombre de hashtag “", _interpolate(_named("tagRenamed")), "”"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe los hashtags con comas o saltos de línea"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se preocupe. Puede hacerlo ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión vinculada al espacio de trabajo"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar conexión"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar una conexión a este espacio de trabajo"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte a su administrador para hacer cambios aquí"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte a Zendesk QA para hacer cambios aquí"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conexiones en la cuenta"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al parecer, aún no se ha integrado con una plataforma de servicio de atención al cliente."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si se borra una conexión, se eliminarán las conversaciones del espacio de trabajo ", _interpolate(_named("workspaceName")), " y todos los datos relacionados con ellos, incluidas las evaluaciones y las disputas."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar conexión"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borrar conexión ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar una conexión"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dentro del periodo seleccionado, ciertas categorías de AutoQA activas no estaban presentes en su tarjeta de puntuación. Las conversaciones anteriores al último cambio del ", _interpolate(_named("date")), " pueden no haber sido analizados para estas categorías."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí aparecerán todos los comentarios que se le den. No hay nada que mostrar todavía."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han recibido evaluaciones."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar solo la actividad de comentarios"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí aparecerán todos los comentarios proporcionados por usted. Comience en la vista Conversaciones."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han proporcionado evaluaciones."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones dadas"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones recibidas"])}
  }
}