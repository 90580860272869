import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-80 mb-4 text-3xl font-medium" }
const _hoisted_2 = { class: "text-gray-90 text-sm font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crosshair_icon = _resolveComponent("crosshair-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.emptyContainer)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.iconContainer)
    }, [
      _createVNode(_component_crosshair_icon)
    ], 2 /* CLASS */),
    _createElementVNode("h3", _hoisted_1, [
      _renderSlot(_ctx.$slots, "title")
    ]),
    _createElementVNode("p", _hoisted_2, [
      _renderSlot(_ctx.$slots, "description")
    ])
  ], 2 /* CLASS */))
}