import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "text-gray-70 font-medium" }
const _hoisted_5 = { class: "text-gray-70 font-medium" }
const _hoisted_6 = { class: "text-gray-70 font-medium" }
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "text-gray-70 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_left_icon = _resolveComponent("chevron-left-icon")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-sm", [_ctx.$style.container, _ctx.simple && _ctx.$style.simple]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.item)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.pageSwitcher)
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["font-medium", _ctx.$style.page]),
          type: "button",
          disabled: _ctx.page === 1,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage('-1')))
        }, [
          _createVNode(_component_chevron_left_icon, { size: "16" })
        ], 10 /* CLASS, PROPS */, _hoisted_1),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageOptions, (p) => {
            return (_openBlock(), _createElementBlock("button", {
              key: p,
              type: "button",
              class: _normalizeClass([
              _ctx.$style.page,
              {
                [_ctx.$style.activePage]: _ctx.page === p,
                'font-medium': _ctx.page !== p,
                'font-semibold': _ctx.page === p,
              },
            ]),
              onClick: ($event: any) => (_ctx.setPage(p))
            }, _toDisplayString(typeof p === 'number' ? _ctx.formatNumber(p) : '···'), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("button", {
          class: _normalizeClass(["font-medium", _ctx.$style.page]),
          type: "button",
          disabled: _ctx.page === _ctx.pageCount,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage('+1')))
        }, [
          _createVNode(_component_chevron_right_icon, { size: "16" })
        ], 10 /* CLASS, PROPS */, _hoisted_3)
      ], 2 /* CLASS */)
    ], 2 /* CLASS */),
    (!_ctx.simple)
      ? (_openBlock(), _createBlock(_component_i18n_t, {
          key: 0,
          keypath: "components.pagination.wrapper",
          tag: "div",
          class: _normalizeClass(_ctx.$style.pageDisplay),
          plural: _ctx.fromAmount
        }, {
          rangeStart: _withCtx(() => [
            _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.fromAmount), 1 /* TEXT */)
          ]),
          rangeEnd: _withCtx(() => [
            _createElementVNode("strong", _hoisted_5, _toDisplayString(_ctx.toAmount), 1 /* TEXT */)
          ]),
          label: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              keypath: _ctx.itemTypeKeyPath,
              tag: "span",
              plural: _ctx.total
            }, {
              count: _withCtx(() => [
                _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.total), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["keypath", "plural"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "plural"]))
      : _createCommentVNode("v-if", true),
    (!_ctx.simple)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.item)
        }, [
          (_ctx.filteredPageSizeOptions.length > 0)
            ? (_openBlock(), _createElementBlock("select", {
                key: 0,
                class: _normalizeClass(["text-gray-60 text-sm font-medium", _ctx.$style.pageSizeSwitcher]),
                value: _ctx.closestPageSize,
                onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handlePageSizeChange && _ctx.handlePageSizeChange(...args))),
                onChange: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPageSizeOptions, (o) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: o,
                    value: o
                  }, [
                    _createVNode(_component_i18n_t, {
                      keypath: _ctx.itemTypeI18nKey,
                      tag: "span",
                      plural: o
                    }, {
                      count: _withCtx(() => [
                        _createElementVNode("strong", _hoisted_9, _toDisplayString(o), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["keypath", "plural"])
                  ], 8 /* PROPS */, _hoisted_8))
                }), 128 /* KEYED_FRAGMENT */))
              ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_7))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}