const useScrollToCard = () => {
  let mountTime: number

  const scrollToCard = function () {
    if (!mountTime) mountTime = new Date().getTime()

    const query = new URLSearchParams(window.location.search)
    const cardId = query.get('cardId')
    const stopTrying = new Date().getTime() - mountTime > 5000

    if (!cardId || stopTrying) return

    const cardEl = document.querySelector(`[data-testid="${cardId}`)
    if (cardEl) {
      setTimeout(() => {
        cardEl?.scrollIntoView({ block: 'end' })
        document.querySelector('.main')?.scrollBy(0, 50)
      }, 500)
      return
    }

    requestAnimationFrame(scrollToCard)
  }

  return { scrollToCard }
}

export default useScrollToCard
