export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre sous-domaine Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter à Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entreprise a déjà un compte."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre une entreprise"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rejoindre une entreprise existante, demandez à votre administrateur de vous envoyer une invitation."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander à votre administrateur de vous inviter"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai changé d'avis."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'inscription"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entreprise a déjà un compte. Voulez-vous rejoindre le compte de votre entreprise ou en créer un nouveau?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier les instructions"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau compte"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compte existe déjà"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas accéder à votre compte AQ Zendesk tant que vous n’avez pas payé vos factures d’abonnement impayées."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement est en pause"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte introuvable. Réessayez."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obtenez un nouveau mot de passe."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Réessayez ou ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinaison adresse courriel/mot de passe incorrecte."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez-en une nouvelle à votre administrateur."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce lien d’invitation n’est pas valide ou a déjà été utilisé."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La méthode de connexion ", _interpolate(_named("method")), " est désactivée dans votre compte."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette invitation n'est pas valide ou est utilisée. Demandez-en une nouvelle à votre administrateur."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de chargement des informations d'invitation."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'inscription. Il est possible que l'utilisateur avec cette adresse courriel existe déjà."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été bloqué après plusieurs tentatives de connexion consécutives. Recherchez un courriel contenant des instructions sur la façon de le débloquer dans votre boîte de réception."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez d'abord votre adresse courriel"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un compte de messagerie valide"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de connexion"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le lien magique"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel professionnelle"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse courriel professionnelle"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.fr/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat Cadre de Service"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne correspond pas aux exigences"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.fr/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ", _interpolate(_named("invitee_name")), ","])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rejoignez l'organisation ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse courriel a été vérifiée. Vous pouvez maintenant vous connecter avec votre nouveau compte ci-dessous."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien d’invitation non valide"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec un lien magique"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir pour vous connecter"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse courriel a été vérifiée. Vous pouvez maintenant vous connecter avec votre nouveau compte ci-dessous :"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion via un lien magique"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion avec Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à l’AQ Zendesk "])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été supprimé"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un code valide"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un compte de messagerie valide"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de connexion"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les options de connexion"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " par les méthodes traditionnelles"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec un lien magique"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez reçu un courriel qui contient votre code de connexion."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez reçu un courriel que vous pouvez suivre pour vous connecter."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'envoi du lien magique. Réessayez."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion par courriel"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un caractère minuscule"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un caractère majuscule"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longueur maximale du mot de passe est de 128 caractères"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facile à mémoriser, difficile à deviner"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 caractères minimum"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer votre mot de passe"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un caractère minuscule"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chiffre"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un caractère majuscule"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour réinitialiser votre mot de passe, saisissez votre adresse courriel et nous vous enverrons un courriel contenant les instructions."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un compte de messagerie valide"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un courriel a été envoyé à ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le courriel n’apparaît pas dans votre boîte de réception ou votre dossier de pourriel, vérifiez l’adresse courriel que vous avez saisie."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir pour vous connecter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre messagerie électronique"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", vous recevrez un courriel de réinitialisation du mot de passe."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si votre compte a déjà un mot de passe"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si ", _interpolate(_named("bold")), ", nous vous avons envoyé un lien de réinitialisation du mot de passe."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l’adresse courriel est associée à un compte"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous souvenez de votre mot de passe?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'envoi du lien de réinitialisation. Réessayez."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser votre mot de passe"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter en utilisant un lien magique"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En vous inscrivant, vous acceptez les ", _interpolate(_named("terms_of_service")), " et la ", _interpolate(_named("privacy_policy")), " d’AQ Zendesk "])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un compte?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription via un lien magique"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription avec votre adresse courriel professionnelle"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription avec Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription à l’AQ Zendesk "])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription avec Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai un compte Zendesk actif"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inscrivez-vous ou connectez-vous pour terminer votre configuration ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce vraiment vous? Recherchez un courriel de confirmation dans votre boîte de réception."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes prêt"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’AQ Zendesk fonctionne mieux sur les ordinateurs de bureau. Connectez-vous ici pour commencer à évaluer des conversations."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez une démo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou réservez une démo auprès de nous et nous vous montrerons ce que vous pouvez faire avec AQ Zendesk."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre présence est demandée"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuler"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J'ai changé d'avis et je souhaite ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " vous invite à rejoindre son compte."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre le compte"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau compte"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer de se connecter"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La réaction que vous avez reçue pour vos commentaires : ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactions reçues"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " a réagi à votre commentaire"]), _normalize(["Plusieurs personnes ont réagi à votre commentaire"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " a réagi à votre réponse"]), _normalize(["Plusieurs personnes ont réagi à votre réponse"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " a réagi à votre évaluation"]), _normalize(["Plusieurs personnes ont réagi à votre évaluation"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaire"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réponse"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluation"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIFFÉRENDS"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DONNÉ(ES)"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REÇU(ES)"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le sondage"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactions"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initié(es)"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marquer le commentaire comme lu?"]), _normalize(["Marquer tous les commentaires comme lus?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marquer le différend comme lu?"]), _normalize(["Marquer tous les différends comme lus?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marquer le commentaire sur le sondage comme lu?"]), _normalize(["Marquer tous les commentaires sur le sondage comme lus?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marquer l'évaluation comme lue?"]), _normalize(["Marquer toutes les évaluations comme lues?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Commentaire marqué comme lu"]), _normalize(["Tous les commentaires marqués comme lus"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Différend marqué comme lu"]), _normalize(["Tous les différends marqués comme lus"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Commentaires sur le sondage marqués comme lus"]), _normalize(["Tous les commentaires sur le sondage marqués comme lus"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Évaluation marquée comme lue"]), _normalize(["Toutes les évaluations marquées comme lues"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les marquer toutes comme lues"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme lu"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluations"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'un utilisateur conteste une évaluation, cela s'affiche ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand vous êtes affecté à la résolution d'un différend, cela s'affiche ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend reçu"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous contestez une évaluation, cela s'affiche ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend initié"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires qui vous sont donnés s'affichent ici. Rien à afficher pour l'instant."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire reçu"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les réactions données à vos évaluations et réponses s'affichent ici. Rien à afficher pour l'instant."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune réaction reçue"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir une conversation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires que vous avez donnés se retrouvent ici. Commencez par laisser une évaluation dans la vue Conversations."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune évaluation donnée"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires qui vous sont donnés s'affichent ici. Rien à afficher pour l'instant."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune évaluation reçue"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire trouvé"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires que vous laissez s'affichent ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire donné"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire trouvé"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires sous une évaluation qui vous appartient, les ", "@", "mentions ou les réponses à vos commentaires s'affichent ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire reçu"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend accepté"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend ouvert"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend rejeté"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun différend résolu"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayez en choisissant une autre option dans le filtre ci-dessus."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayez en choisissant un autre utilisateur dans le filtre ci-dessus."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires donnés"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires reçus"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les différends"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends reçus"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends initiés"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne évaluée"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le sondage reçus"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations données"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la liste d’activités"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " évaluée"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " évaluée"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " évaluées"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommence dans ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il reste ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir une affectation"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la liste des affectations"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il n'est pas possible de créer une séance de coaching entre ", _interpolate(_list(0)), " et ", _interpolate(_list(1)), " car ils ne se trouvent pas dans le même espace de travail."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Des séances de coaching entre ", _interpolate(_list(0)), " et ", _interpolate(_list(1)), " existent déjà. Contactez ", _interpolate(_list(0)), " pour accéder à la séance."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggéré"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Une nouvelle séance entre ", _interpolate(_list(0)), " et ", _interpolate(_list(1)), " a été créée."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraîneur"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un entraîneur"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne entraînée"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une personne coachée"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une séance"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la séance"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggéré"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En retard"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienne"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À venir"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance trouvée"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai d’évaluation moyen"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée à afficher pour la période sélectionnée"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Épingle ajoutée à la séance. ", _interpolate(_named("buttonStart")), "Montrez-moi", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la fin des épingles de la période sélectionnée"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Découvrez comment fonctionnent les épingles dans cet ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il semble que vous n'avez rien épinglé sous ", _interpolate(_list(0)), ". Vous pouvez utiliser les épingles pour vous préparer aux séances individuelles ou les enregistrer pour une autre fois."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la personne coachée"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune épingle ajoutée pour l'instant"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voici toutes les épingles que vous avez créées sous ", _interpolate(_named("name")), ". Les épingles ajoutées aux séances seront ", _interpolate(_named("bold")), " avec l'autre participant."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immédiatement partagée(s)"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["On dirait que ", _interpolate(_list(0)), " n'a reçu aucun commentaire pour l’évaluation de la conversation ou du CSAT pendant la période sélectionnée."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire disponible"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la fin des commentaires de la période sélectionnée"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingles"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les chiffres de ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élément d’action"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terminé par ", _interpolate(_named("user")), " le ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["par ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez pour ajouter un nouvel élément d'action"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu’est-il ressorti de cette réunion? Quelles sont les prochaines étapes?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments d’action"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Création par ", _interpolate(_named("name")), " le ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimera la séance"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cela ", _interpolate(_named("deleteSession")), " de façon permanente. Les participants perdent l'accès à la séance, notamment à son contenu et aux notes privées."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Séance créée le ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière modification par ", _interpolate(_named("name")), " le ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points de discussion et notes"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note mise à jour"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la séance"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs sélectionnés ont désormais accès aux détails de la séance."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez des utilisateurs"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagé"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager la séance"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous ne ferez plus partie de la séance ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ne fera plus partie de cette séance."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " a accès à cette séance maintenant."]), _normalize([_interpolate(_named("n")), " nouveaux utilisateurs ont accès à cette séance maintenant"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la séance"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer toutes les séances"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la séance"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'entraîneur"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les notes ajoutées par ", _interpolate(_list(0)), " s'afficheront ici."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des éléments épinglés comme points de discussion à partir du panneau sur la droite"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingles"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les notes que vous êtes le seul à pouvoir voir..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes personnelles"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note privée mise à jour"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 jours"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraîneur"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne entraînée"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments d’action"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière séance"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En retard"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des séances"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout afficher"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moi : Entraîneur"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moi : Personne entraînée"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les séances"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances partagées"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez de changer de filtre pour voir les éléments d’action."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élément d’action"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments d’action"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues et consultées"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu · Vu"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression après les séances"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que les agents n'ont reçu aucun commentaire."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez que le nom de l'utilisateur est correct"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de trouver cet utilisateur"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée disponible"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI pour les 30 derniers jours"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cela <b>supprimera définitivement toutes les séances</b> (2) avec <b>", _interpolate(_named("name")), "</b>. Les participants perdent l'accès à toutes les séances, y compris le contenu et les notes privées."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la fin de toutes les séances"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une séance de coaching est une activité de groupe entre un mentor et une personne coachée pour discuter des performances, des opportunités et des objectifs à court et long terme."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez rechercher des séances à la fois en fonction du nom du coach et de la personne coachée."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance trouvée"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance créée pour l’instant"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'historique de coaching"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'historique de coaching "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière séance"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les séances"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraîneur"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne entraînée"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme Terminée"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme non effectué(es)"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encadrement"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la liste des séances"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non planifié(es)"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre à ce message"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encadrement"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance d’étalonnage expirée. Aucune évaluation supplémentaire ne peut être laissée."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà laissé une évaluation pour cette conversation."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'est plus possible d’évaluer cette conversation car la date d'échéance de la séance est passée."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette conversation n'a aucune évaluation"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le panneau"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser une évaluation sur l'étalonnage"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÉTAILS"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSUMÉ"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSCRIPTION"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Intervenant ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps mort"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'intervenant"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer le message"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la lecture du message"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervenants"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcription"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs de l'espace de travail"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez d’abord une connexion pour créer des filtres"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun assistant"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Création à ", _interpolate(_named("time")), " par ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière mise à jour le ", _interpolate(_named("time")), " par ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On dirait que vous n'avez pas de filtres. Pour accéder à cette page, vous devez d'abord créer un filtre. Commencez par ", _interpolate(_named("recommendedFilter")), " pour afficher vos propres conversations. Créez ce filtre ci-dessous pour continuer."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au cours des 30 derniers jours"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La condition Créé au cours des 30 derniers jours sera ajoutée aux filtres créés sans plage de dates"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le filtre n'est pas disponible dans ce forfait et ne sera pas pris en compte lors de l'affichage des résultats"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La récupération des options de filtre a expiré. Réessayez."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur le filtrage"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné = personne connectée"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Conversation trouvée : ", _interpolate(_named("count"))]), _normalize(["Conversation trouvée : ", _interpolate(_named("count"))]), _normalize(["Conversations trouvées : ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible avec votre forfait actuel. Effectuez une mise à niveau pour profiter de cette fonctionnalité."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une condition"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par l’IA d’AQ Zendesk "])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir en affectation"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un filtre"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un filtre"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs personnalisés"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes en train de charger les options de filtres en fonction de votre configuration spécifique. Cela peut prendre quelques minutes lors du chargement initial."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un nom descriptif à votre filtre"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du filtre"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une option"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le filtre"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune valeur disponible"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introuvable."])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une valeur"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances actives uniquement ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les séances plus anciennes ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’échéance"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la séance d'étalonnage"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance d'étalonnage"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les évaluations d'étalonnage données pendant la séance seront définitivement supprimées."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la séance"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la séance d'étalonnage ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance n'a été créée"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance active"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance créée"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance active"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un filtre"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un filtre privé"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un filtre public"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre ajouté"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le filtre sera supprimé définitivement."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le filtre"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer le filtre ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les différends"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends me concernant"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends de ma part"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends ouverts"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillons"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre ajouté"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre privé créé"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre public créé"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres privés"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres publics"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous souhaitez ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l’enregistrer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'une vue de filtre temporaire."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de votre filtre..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre mis à jour"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’AQ automatique personnalisée"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière mise à jour ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots complexes"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluations automatiques sont mises à jour de façon dynamique en fonction des modifications de ticket"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre en évidence dans la conversation"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrases longues"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune note automatique prévue"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune erreur n'a pu être détectée"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les mots complexes"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les phrases longues"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation suivante"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique n'a pas pu noter cette catégorie en raison d'un contexte insuffisant. Évaluation manuelle conseillée."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez des informations clés des conversations automatisées pour simplifier votre processus d'évaluation. Mettez en évidence les conversations cruciales et présentez des exemples positifs remarquables à votre équipe."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Appel]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrigé(es)"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimée"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a accepté le différend"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Différend envoyé à ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expliquez pourquoi vous pensez que la personne évaluée n'est pas le bon destinataire pour ces commentaires"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commentaire et définir une nouvelle personne évaluée"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commentaire"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expliquez pourquoi vous pensez que ces notes reçoivent une note injuste (obligatoire)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un commentaire (obligatoire)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expliquez pourquoi vous rejetez ce différend"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commentaire"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le différend à"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le différend à"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le différend à"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une nouvelle personne évaluée"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contester les commentaires"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contester les commentaires"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la personne évaluée"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le différend à"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différend"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contester cette évaluation"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne suis pas d'accord avec les notes"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette évaluation ne m'était pas destinée"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un utilisateur"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a partiellement accepté le différend"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a refusé le différend"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptés"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement acceptés"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusés"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " a initié un différend : évaluation"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " a initié un différend : mauvaise personne évaluée"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un différend ouvert doit être résolu afin de prendre d'autres mesures concernant l'évaluation."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner de nouvelles notes"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le différend"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce différend?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le différend"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différend"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai de résolution des différends"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner de nouvelles notes équitables (facultatif)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contester l’évaluation"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise personne à évaluer"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter le différend"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter le différend"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contester les commentaires"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise personne à évaluer"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contester les commentaires"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter le différend"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter le différend"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le différend"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifié"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " par ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["avec ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué(e)"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération terminée"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectée à :"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser également un commentaire"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur les fiches d’évaluation conditionnelles"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anciennement affecté à :"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez-en un(e) seul(e)"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n’avons trouvé aucun fichier GIF portant ce nom"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez le premier à laisser une évaluation."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement est actuellement en pause. Réactivez-le pour permettre l'envoi de commentaires."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié par :"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela montre le temps qui a été consacré à l'évaluation de cette conversation"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un utilisateur"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cause profonde manquante pour la catégorie : « ", _interpolate(_list(0)), " »"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d’évaluation :"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez une catégorie ou ajoutez un commentaire"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des émojis"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer le champ de commentaire"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduire le champ de commentaire"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le formatage"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher le volet"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le formatage"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le volet"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l’évaluation"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez toutes les catégories"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez au moins une catégorie"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer cette conversation pour plus tard"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer pour plus tard"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation suivante"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer la conversation dans ce cycle d'affectation"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer la conversation"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation de référence"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le commentaire"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire supprimé"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ce commentaire?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur du commentaire"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce hashtag a été supprimé ou renommé."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez ce que vous avez aimé ou ce qui aurait pu être mieux..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditionnel"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action aura pour effet de supprimer définitivement tous les commentaires."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le commentaire"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Message]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " n'a pas encore vu ces commentaires"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a vu ces commentaires"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien membre"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez des GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour créer un nouveau hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les hashtags personnalisés sont verrouillés par l'administrateur."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction permet d’ajouter les requêtes aux favoris et de les filtrer plus tard"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme référence"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le message"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation suivante"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune évaluation effectuée"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez une évaluation ci-dessous pour commencer."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune autre évaluation restante"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non noté"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné précédent"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation précédente"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer comme référence"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondez à cette évaluation"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer la barre latérale des évaluations"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'évaluation et tous les commentaires ajoutés seront définitivement supprimés."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'évaluation"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'évaluation"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation supprimée"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Évaluation supprimée par ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est tout!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter cette tâche à vous-même afin de laisser une évaluation"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà laissé une évaluation pour cet utilisateur."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous avez laissé une évaluation pour ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catégorie à afficher pour cette conversation."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les auto-évaluations ne sont pas disponibles pour cet espace de travail."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas enregistrer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer cette évaluation comme brouillon?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ce brouillon"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la barre latérale de l'évaluation"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite avec des erreurs mineures"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas encore fait les calculs..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser un commentaire"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer ce message"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous évaluer vous-même"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer cette conversation"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur à évaluer"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-évaluations désactivées"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur trouvé"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs de l'espace de travail"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un utilisateur"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 évaluation"]), _normalize([_interpolate(_named("count")), " évaluations"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner de nouvelles évaluations"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le responsable de votre espace de travail n'a pas autorisé les auto-évaluations."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour étiqueter cette épingle"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnages"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 derniers jours"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments avec une étoile"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance d'étalonnage"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillons"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights vous offre une représentation visuelle des données de votre service d’assistance. Servez-vous des graphiques interactifs pour comprendre où concentrer vos efforts en matière d'assurance qualité, découvrir les zones d’ombre et identifier les bons agents à évaluer en fonction de leurs performances."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traduire en ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non affecté"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de visualisation"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation en cours"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette conversation a été supprimée et n'est plus disponible."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette conversation que vous recherchez n'existe pas dans l’AQ Zendesk."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les épingles avec cette conversation"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de séance"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la conversation"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous supprimez une conversation d'une séance, toutes les évaluations qui lui ont été données seront supprimées."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la conversation de ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance d'étalonnage créée pour l’instant."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette conversation a été ajoutée à la séance"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette conversation a été supprimée de la séance"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à/supprimer d'une séance d'étalonnage"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien dans cette conversation"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation à afficher"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel du client"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du client"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du formulaire externe"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier temps de réponse"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur le sondage"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de satisfaction"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai avant la première résolution"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'origine"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce message n'existe pas dans cette conversation ou a été supprimé"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun sujet"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation à afficher"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation trouvée"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir la conversation initiale"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de lier la conversation - URL manquante"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun participant"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler la conversation"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour lire les évaluations"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour évaluer"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation créée via une extension de navigateur"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter le sondage de satisfaction de la clientèle"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la conversation"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message avec un sentiment négatif"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message avec un sentiment positif"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduire en anglais"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l’original"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la conversation"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La suppression d’une conversation est définitive. Cela supprime aussi toutes les données associées, par exemple les enquêtes, les transcriptions et les évaluations de Zendesk QA, y compris les tableaux de bord.", _interpolate(_named("lineBreak")), "Vous pourrez toujours accéder à cette conversation dans Zendesk Support ou dans un autre logiciel de service d’assistance que vous utilisez."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La suppression d’une conversation est définitive. Cela supprime aussi toutes les données associées (par ex. les enquêtes, les transcriptions et les évaluations de Zendesk QA), y compris les tableaux de bord. Si la conversation appartient à plusieurs espaces de travail, elle sera supprimée de tous ces espaces de travail.", _interpolate(_named("lineBreak")), "Vous pourrez toujours accéder à cette conversation dans Zendesk Support ou un autre logiciel de service d’assistance que vous utilisez."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la conversation"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la conversation de Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette conversation est incomplète à cause d’un transfert. Accédez à ", _interpolate(_named("optionsUrl")), " et validez vos étiquettes. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette conversation est incomplète à cause d’un transfert. Contactez votre administrateur pour valider vos étiquettes. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions > Options avancées"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus au sujet des étiquettes"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clos"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolu"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assigné modifié : désormais ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non affecté"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macro ", _interpolate(_named("target")), " appliquée par ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La conversation a reçu une évaluation ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’utilisateur ", _interpolate(_named("target")), " a supprimé la conversation"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Statut modifié : désormais ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Étiqueté avec ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Équipe modifiée : désormais ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " a suspendu momentanément la conversation pendant ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " a annulé la suspension momentanée de la conversation"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant bloqué dans une boucle, répétant sans cesse un message"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétition de l’assistant"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétition de l’assistant, où le même message est répété une ou plusieurs fois de suite"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expressions correspondantes trouvées"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un agent moyen gère la conversation ", _interpolate(_named("percent")), " plus efficacement que l’assistant"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficacité de communication de l’assistant"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’assistant a traité la conversation ", _interpolate(_named("percent")), " plus efficacement qu’un agent moyen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficacité de communication de l’assistant"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversation ne contient pas la déclaration nécessaire informant les participants de l’enregistrement, ce qui est requis pour la conformité et la transparence"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgation de l’enregistrement manquante"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Un accord sur les niveaux de service (ANS) n’a pas été respecté pour ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANS non respecté"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mesure de la règle ANS a dépassé son objectif temporel"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signes d'attrition de la clientèle. Le client envisageait un changement ou a promis de se désengager."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque d'attrition identifié"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La conversation comporte des moments de temps mort qui durent plus longtemps que le seuil défini de ", _interpolate(_list(0)), " s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instance"]), _normalize([_interpolate(_named("count")), " instances"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s le plus long"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps mort"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a demandé une assistance de niveau supérieur"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert demandé"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a exprimé une extrême gratitude ou était très satisfait de l'assistance reçue"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service exceptionnel"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client ou l'agent a explicitement demandé un suivi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de suivi"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a montré son mécontentement"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment négatif"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversation était inhabituelle ou atypique, et il y avait plus d'échanges pour résoudre le problème"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas particulier détecté"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client s'est montré satisfait"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment positif"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir comme non applicable"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière modification par ", _interpolate(_named("author")), " le ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicable"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Par ", _interpolate(_named("author")), " le ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une raison (facultatif)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune information détectée"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une connexion"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre service d'assistance à votre compte et créez au moins un filtre pour afficher la liste des conversations."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation à afficher"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les paramètres de l'espace de travail"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne évaluée"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensemble"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Dans un jour)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Ce mois-ci)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Cette semaine-ci)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Résultats de recherche pour ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluée par d'autres personnes"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentées"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le(s) plus récent(es)"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le(s) plus ancien(nes)"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aléatoire"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mises à jour récemment"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses récentes"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lues"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluée par vous"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le processus d'importation peut prendre un certain temps. Nous vous enverrons un courriel une fois le processus terminé."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’AQ Zendesk  est en train d'importer vos conversations"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limite : ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez en cliquant sur l'icône d'étalonnage dans l'en-tête de la vue de conversation."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rien à signaler ici. Réessayez en utilisant une autre option de filtre ou de tri."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun cas particulier à afficher."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez une conversation pertinente et cliquez sur l'icône en forme d’étoile dans l'en-tête pour l'ajouter à vos favoris."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez de modifier les filtres ou invitez d'autres agents du service d'assistance à ajouter d'autres conversations."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de limite"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note interne"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conversations sont en cours de chargement. Il est possible que du contenu soit encore manquant."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur suivant"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur trouvé"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de l'espace de travail"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur précédent"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation ajoutée"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation n'a été ajoutée"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluations inachevées s'affichent ici. Relisez-les, modifiez-les et renvoyez-les dans un délai de 30 jours."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune évaluation en brouillon"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation avec une étoile"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation à afficher"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune correspondance"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au message sélectionné"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le moins de réponses"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plus de réponses"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la liste des conversations"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Appel pris à ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Appel terminé à ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Appel passé à ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué(e)"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluée par vous"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Échec de chargement du fichier audio. Vérifiez que vous êtes connecté à ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur le sondage de satisfaction de la clientèle"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de chargement de l'audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur du commentaire"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun appel n'a eu lieu"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écouter dans le service d’assistance"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écouter sur Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de lecture"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer l'appel"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcription"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevée"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas un utilisateur d’AQ Zendesk "])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ Zendesk classe automatiquement les agents en fonction de leur CSAT moyen mensuel. Trouvez en un clic les conversations traitées par des agents dont le CSAT est inférieur à celui de leurs pairs."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement par CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevée"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plus basse"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plus élevée"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas un utilisateur d’AQ Zendesk "])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ Zendesk  classe automatiquement les agents dans des groupes de performances d'après le nombre de conversations qu'ils traitent par mois. Trouvez en un clic les conversations des agents dont le volume est inférieur à celui de leurs pairs."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement par volume"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué(e)"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas évalué"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de révision"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevée"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutre"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas un utilisateur d’AQ Zendesk "])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ Zendesk classe automatiquement les agents en fonction du sentiment moyen ressenti lors des conversations. Trouvez en un clic les interactions dans lesquelles les agents sont confrontés à plus de clients frustrés que leurs pairs."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement par sentiment"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non détectée"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détectée"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas particuliers"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations qui nécessitent votre attention. Notre modèle d’apprentissage automatique identifie les interactions complexes ou inhabituelles pour une évaluation plus approfondie."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas particuliers"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négatif"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positif"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les conversations à évaluer pour lesquelles le client a montré de la satisfaction ou de la frustration. Utilisez le menu déroulant pour sélectionner le type de sentiment que vous recherchez dans vos conversations. Faites glisser l'attribut de filtre vers le haut pour voir la proportion du sentiment choisi dans toutes vos interactions."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de révision"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas évalué"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué(e)"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ Zendesk détecte automatiquement les langues dans vos conversations. Utilisez les menus déroulants pour sélectionner des conversations dans des langues spécifiques à des fins d’évaluation. Faites glisser le filtre d'attribut vers le haut pour accéder à un aperçu de toutes les langues utilisées dans votre service d'assistance."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations clés des conversations"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez ", _interpolate(_named("paragraph_2_item")), " de choses avec Insights qu'avec les filtres de conversation standards. Bénéficiez d'un aperçu visuel de toutes les données provenant de votre service d'assistance, et explorez des informations telles que :"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur l'utilisation des informations clés des conversations"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre l'importance relative d'attributs de conversation spécifiques par rapport à d’autres"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez vos agents les plus performants et les moins performants grâce au classement intégré des performances des agents"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " est un puissant outil interactif d'exploration de données qui vous aide à trouver les conversations que vous avez le plus besoin d'évaluer."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faire bien plus"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les graphiques Conversation et Agent sont ", _interpolate(_named("paragraph_3_item_1")), " - En mettant à jour les filtres ou en cliquant sur les coupes du graphique segmenté, la liste des conversations sera automatiquement mise à jour. ", _interpolate(_named("paragraph_3_item_2")), " pour explorer les répartitions ou les rapports détaillés des différents éléments."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconnectés"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réagencez les attributs de filtres"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les informations clés des conversations affichent uniquement les ", _interpolate(_named("paragraph_4_item")), ". Nous supprimons automatiquement les interactions sans valeur réelle d'évaluation, comme les messages générés automatiquement, les pourriels, les réponses d’assistants ou les messages qui n'ont pas de véritable dialogue avec votre représentant du service d’assistance."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversations propres et closes"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce que je vois ici?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " sur ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " sur ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " sur ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " sur ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la récupération"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " N/A"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " du total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais nous sommes sûrs qu’il sera génial une fois arrivé."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondage personnalisé"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ne reste aucun commentaire sur le sondage"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune note de commentaires sur le sondage disponible pour l'instant..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reçu(es)"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette de commentaires"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes de commentaires sur les commentaires"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre de plage de dates appliqué"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " réponse"]), _normalize([_interpolate(_named("count")), " réponses"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d’assistance"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Découvrez pourquoi une note ", _interpolate(_list(0)), " a été obtenue en configurant les raisons dans les paramètres du sondage."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aucune raison ", _interpolate(_list(0)), " pour l'instant"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur les raisons du sondage"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations avec des étiquettes :"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " par"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raisons"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie du commentaire"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raisons ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facteurs ", _interpolate(_named("surveyType")), " présumés"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de la conversation"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes du service d'assistance"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Réponse au sondage (", _interpolate(_named("n")), ")"]), _normalize(["Réponse au sondage (", _interpolate(_named("n")), ")"]), _normalize(["Réponses au sondage (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun sujet"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversations avec ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " par utilisateurs"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de réponse"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La note ", _interpolate(_list(0)), " est la somme de toutes les réponses divisée par la somme des notes maximum"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l’objectif"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’objectif"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir un objectif"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un objectif"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Court"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Très long"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi-long"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déroutant"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inutile"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise assistance"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne assistance"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des émotions à profusion"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires pour l'agent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plainte"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragments"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème non résolu"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème résolu"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment négatif"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais résultat"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment positif"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éloges"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais produit"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance rapide"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance lente"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L’AQ Zendesk affecte automatiquement vos données ", _interpolate(_list(0)), " dans des catégories prédéfinies. Vous pouvez ainsi comprendre des informations complexes et explorer les causes sans devoir parcourir manuellement les commentaires écrits."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les notes"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les sondages"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la langue de la réponse"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du commentaire"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la taille du commentaire"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étant donné que 25 % des commentaires ne contiennent qu'un seul mot, les commentaires plus longs doivent être lus. Obtenez des commentaires plus pertinents sans effectuer de tâches manuelles, car nous le faisons automatiquement pour vous."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison des commentaires"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la raison des commentaires"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de notes correspondantes"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteurs présumés"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une catégorie de commentaire"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots de nuage de mots"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez des mots du nuage de mots"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " depuis la dernière période"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversations"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de calculer le taux d'envoi des sondages pour toutes les conversations en raison du manque de données."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages envoyés"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entonnoir du sondage"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " sur ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " sur ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " sur ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facteurs ", _interpolate(_named("surveyType")), " présumés"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L’AQ Zendesk  affecte automatiquement vos données ", _interpolate(_list(0)), " dans des catégories prédéfinies. Vous pouvez ainsi comprendre des informations complexes et explorer les causes sans devoir parcourir manuellement les commentaires écrits."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du commentaire"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étant donné que 25 % des commentaires ne contiennent qu'un seul mot, les commentaires plus longs doivent être lus. Obtenez des commentaires plus pertinents sans effectuer de tâches manuelles, car nous le faisons automatiquement pour vous."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses au sondage"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (moyenne)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI (moyenne)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tendances ", _interpolate(_list(0)), " par"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raisons"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteurs"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Date d'envoi du sondage ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Date de réponse au sondage ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Affecter une note ", _interpolate(_list(0)), " à"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données en fonction de"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création de la conversation"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au propriétaire du ticket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à l’agent avec le plus de réponses"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (moyenne)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " au fil du temps"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " et SQI"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conversations qui incluent ces mots :"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuage de mots"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT par les utilisateurs"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entonnoir du sondage"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses au sondage"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes du service d'assistance"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT dans le temps"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuage de mots"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune source"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune langue"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune raison"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune étiquette"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catégorie de commentaire"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données disponibles car aucune affectation n'a été créée ou aucun cycle n'a été commencé. Revenez plus tard ou ajustez les filtres."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveautés"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " élément"]), _normalize([_interpolate(_named("count")), " éléments"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversation"]), _normalize([_interpolate(_named("count")), " conversations"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite avec des erreurs mineures"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite/échec"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Évaluations ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT moyen = [somme des notes des commentaires ÷ (nombre de toutes les réponses x note maximum possible)] x 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La note CSAT de l’AQ Zendesk normalise votre note de commentaires sur une échelle de 0 à 100 % et calcule la moyenne des notes normalisées."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La note de satisfaction client (CSAT) quantifie le degré de satisfaction de vos clients à l’égard de vos services."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Chaque évaluation représente l'évaluation d'une personne sur une conversation. Une conversation peut avoir plusieurs évaluations (si plusieurs évaluateurs évaluent la même conversation)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez l'évolution des notes des évaluateurs au fil du temps."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez l'évolution des notes des agents au fil du temps."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " réponse"]), _normalize([_interpolate(_named("n")), " réponse"]), _normalize([_interpolate(_named("n")), " réponses"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " évaluation"]), _normalize([_interpolate(_named("n")), " évaluation"]), _normalize([_interpolate(_named("n")), " évaluations"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations :"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La NQI (note de qualité interne) est le résultat des évaluations de vos conversations."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NQI = somme des notes des évaluations ÷ nombre d'évaluations"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est calculé comme une moyenne, en tenant compte de toutes les notes d'évaluation (moyenne pondérée de la note de la catégorie) correspondant aux conditions de filtre sélectionnées."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de chargement de la carte."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez vos filtres pour réessayer."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le taux de réussite indique le résultat des évaluations de vos conversations."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Échouez si la note d’évaluation est inférieure ou égale à ", _interpolate(_named("failScore"))])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Réussissez si la note d’évaluation est supérieure ou égale à ", _interpolate(_named("passScore"))])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est déterminé par une référence prédéfinie, ce qui signifie que vous :"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de réussite = Somme des évaluations réussies ÷ Somme du nombre total d'évaluations"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le taux de réussite indique en pourcentage le nombre d'évaluations réussies sans échec dans les catégories critiques ou non critiques."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["par ex. si l’évaluateur donne 3 évaluations ce jour-là, le nombre affiché est la moyenne des 3 notes. Découvrez comment chaque cellule est calculée ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["par ex. si l'agent reçoit 3 évaluations ce jour-là, le nombre affiché est la moyenne des 3 notes. Découvrez comment chaque cellule est calculée ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non pris en compte : pondération de la catégorie, statut critique (contrairement aux Notes dans le temps)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comme les catégories peuvent être marquées n/a dans les évaluations, le nombre d'évaluations par catégorie peut être inférieur au nombre total d'évaluations données par l’évaluateur. Découvrez comment chaque cellule est calculée"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non pris en compte : pondération de la catégorie, statut critique (contrairement aux Notes dans le temps)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comme les catégories peuvent être marquées n/a dans les évaluations, le nombre d'évaluations par catégorie peut être inférieur au nombre total d'évaluations par agent. Découvrez comment chaque cellule est calculée"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non pris en compte : pondération de la catégorie, statut critique (contrairement aux Notes dans le temps)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " les groupes de catégories s'affichent séparément. La note de chaque groupe est calculée d'après la note de catégorie moyenne de ce groupe pendant la même période. Découvrez comment chaque cellule est calculée ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque :"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non pris en compte : pondération de la catégorie, statut critique (contrairement aux Notes dans le temps)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " les groupes de catégories s'affichent séparément. La note de chaque groupe est calculée d'après la note de catégorie moyenne de ce groupe pendant la même période. Découvrez comment chaque cellule est calculée ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Référence ", _interpolate(_named("count")), " %"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence d'erreur critique"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le taux d’absence d'erreur critique indique en pourcentage le nombre d'évaluations réussies sans échec dans une catégorie critique."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de qualité interne"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres causes profondes"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement périodique"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement quotidien"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement mensuel"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement hebdomadaire"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement annuel"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes dans le temps"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage en jours ou en semaines."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul = note totale pour la journée ou la semaine divisée par le nombre total d'évaluations données"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage en jours ou en semaines."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul = note totale pour la journée ou la semaine divisée par le nombre total d'évaluations reçues"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes par catégorie"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichez la moyenne combinée de toutes les évaluations de catégorie données par l’évaluateur pendant cette période."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez la moyenne combinée de toutes les évaluations de catégories reçues par agent pendant cette période."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes profondes des mauvaises évaluations"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes profondes des évaluations"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes des catégories dans le temps"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysez la comparaison des catégories dans le temps."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez les notes moyennes données pour chaque catégorie pour un jour ou une semaine de votre choix."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysez la comparaison des catégories dans le temps."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez les notes moyennes reçues pour chaque catégorie pour un jour ou une semaine de votre choix."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes en fonction des évaluations"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivés"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie critique"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de notation"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne/totale"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure les auto-évaluations"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporte toutes les fiches"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donné(es)"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["conversation"]), _normalize(["conversations"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["élément"]), _normalize(["éléments"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " réponse"]), _normalize([_interpolate(_named("n")), " réponse"]), _normalize([_interpolate(_named("n")), " réponses"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " évaluation"]), _normalize([_interpolate(_named("n")), " évaluation"]), _normalize([_interpolate(_named("n")), " évaluations"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Afficher un(e) autre"]), _normalize(["Afficher ", _interpolate(_named("n")), " autres"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 derniers jours"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 derniers jours"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semaine dernière"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période personnalisée"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestres"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaines"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ans"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de chargement de la carte. Modifiez vos filtres pour réessayer."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée à afficher."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement mensuel"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableaux de bord"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations supplémentaires."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos filtres actifs incluent les données de différentes échelles d’évaluation."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les épingles de cartes avec ces filtres"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les épingles de tableau de bord avec ces filtres"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai d’évaluation moyen"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catégorie"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de catégorie"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Commentaire"]), _normalize(["Commentaires"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grille"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien membre"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de qualité interne"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI (moyenne)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations réussies"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler la carte"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler le tableau de bord"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cause profonde"]), _normalize(["Causes profondes"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID d'évaluation"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d’évaluation"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne évaluée"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer les causes profondes"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les causes profondes vous permettent de savoir pourquoi les conversations obtiennent des notes faibles"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune cause profonde définie pour cette catégorie"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note/total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories d’évaluations"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de catégories d’évaluations"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de catégories"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser l’ordre"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regrouper les catégories par"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure les auto-évaluations"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclure les auto-évaluations"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher uniquement les auto-évaluations"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun hashtag trouvé"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags des commentaires"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les hashtags des commentaires"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune étiquette trouvée"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes du service d'assistance"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez des étiquettes du service d'assistance"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune fiche d’évaluation trouvée"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez des fiches d’évaluation"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-évaluations"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune connexion correspondante"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de la conversation"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une source"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de filtres"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail correspondant"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de fois donné(es)"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout le monde"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les groupes"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe correspondant."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les groupes"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les fiches"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données en fonction de"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de création de l'évaluation"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création de la conversation"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher deux décimales"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les paramètres"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les espaces de travail"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout le compte"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'espace de travail"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisée"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drapeaux"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nourriture et boissons"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animaux et nature"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objets"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes et corps"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages et lieux"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvent utilisé(s)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la recherche"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frimousses et émoticônes"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symboles"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun émoji trouvé"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir une conversation"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des tâches"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " ou parcourez votre liste de ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous utilisez actuellement l’espace de travail ", _interpolate(_named("workspace")), " qui n'est pas connecté à ce domaine. Cliquez ici pour le connecter maintenant."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connexions disponibles"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous ne pouvez pas envoyer de commentaires sur ", _interpolate(_named("host")), " pour l'instant."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayez votre service d'assistance comme onglet actif."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce domaine ne peut pas encore être évalué. Si c'est le cas, prévenez votre administrateur."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter ce domaine"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Connexion avec ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de patienter pendant la configuration"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'obtenir l'URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils qui sont déjà connectés au compte d'AQ Zendesk de l'organisation."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun outil connecté au compte d'AQ Zendesk de l'organisation."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous permet d’évaluer les sites Web pour lesquels l'URL reste toujours la même, en ajoutant un hachage unique à la fin avant de laisser une évaluation"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de compte"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos domaines"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines qui sont déjà connectés à cet espace de travail."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez évaluer n'importe quelle page sur les domaines suivants"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines disponibles pour évaluation"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL unique"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun service d'assistance connecté pour l'instant. Rendez-vous sur ", _interpolate(_named("link")), " pour en ajouter un."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'extension de navigateur AQ Zendesk ne peut être utilisée\nqu’avec les forfaits Professional et Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez d’espace de travail"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné :"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez un nom"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez une valeur"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs personnalisés"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est la première fois que nous rencontrons cette conversation. Indiquez des détails."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de la conversation :"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose permettant d’identifier le ticket plus tard"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez l'agent assigné et un sujet"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer cette conversation"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non affecté"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contactez votre gestionnaire ou passez à un espace de travail à partir des ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les auto-évaluations sont désactivées pour cet espace de travail."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au revoir"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette URL ne peut pas être évaluée. Naviguez jusqu'à une conversation."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous d'abord à l’AQ Zendesk par le biais de votre fournisseur SSO, puis réessayez."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec la connexion unique"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture de la fenêtre contextuelle de connexion."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec votre compte d’AQ Zendesk "])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification en cours..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de compte?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accès à cette conversation. Si vous devez avoir accès, contactez votre gestionnaire."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d’accès"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez que votre connexion est activée dans l’AQ Zendesk "])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer vos connexions"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à une conversation dans votre service d'assistance pour continuer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL non valide"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reconnexion est presque terminée."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un instant"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez de recharger la page pour revenir au cours des choses"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libérez-vous des frontières virtuelles de votre service d'assistance et évaluez les conversations n'importe où."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez votre abonnement à niveau pour utiliser l'extension"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer votre abonnement"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion au service d’assistance n’est pas liée à cet espace de travail"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre service d’assistance ne reconnaît pas ce ticket ou AQ Zendesk n’y a pas accès"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de trouver la conversation"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle activité"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez reçu des commentaires"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion réussie"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez utiliser l'application maintenant"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez toutes les catégories"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction permet d’ajouter les requêtes aux favoris et de les filtrer plus tard"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire pour cette conversation"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire les commentaires"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir dans l’AQ Zendesk "])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les commentaires et toutes les notifications s'afficheront ici. Rien pour l'instant..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur l’AQ Zendesk "])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des conversations"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contient l’ensemble des"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est l’ensemble des"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne contient pas l’intégralité des"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’est pas l’ensemble des"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supérieure à celle d’un agent"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inférieure à celle d’un agent"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faux"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est supérieur à"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contient l’un des"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est l’un des"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne contient pas de"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'est pas l'un des"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vrai"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non respecté(es)"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commenté(es)"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commenté(es) par moi"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas commenté"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non commenté(es) par moi"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critique pour évaluation"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contient"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 derniers jours"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durant"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les 14 derniers jours"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les dernières 24 h"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 derniers jours"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les 7 derniers jours"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le mois dernier"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la semaine dernière"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce mois-ci"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cette semaine"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jusqu’à"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd’hui"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détecté(es)"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["existe"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["étoile ajoutée par moi"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["étoile non ajoutée par moi"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inclut"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supérieur(es) à"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inférieur(e) à"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jusqu'à"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’est pas"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’est pas"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["négatif"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respecté"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non complexe"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non détecté"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’existe pas"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’inclut pas"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non reçu(es)"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non vu(es)"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positif"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non évalué(es)"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non évalué(es) par moi"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évalué(es)"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évalué(es) par moi"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reçu(es)"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critique pour évaluation"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contient"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supérieur(es) à"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inférieur(e) à"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jusqu'à"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’est pas"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne contient pas"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’est pas"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consulté"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mon commentaire a des réponses"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oui"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories personnalisées"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus personnalisé"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez une condition"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d’assistance"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesures"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation et commentaires"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les conditions"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare le traitement des conversations de votre assistant à celle d’un agent moyen"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’assistant est bloqué dans une boucle, répétant le même message plusieurs fois"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses de l’assistant dans les conversations"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un type d’assistant impliqué dans la conversation"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un assistant impliqué dans la conversation"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le contenu de la conversation contient l’expression ", _interpolate(_list(0)), " dans le ou les messages de l’agent"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez automatiquement les appels dépourvus de la déclaration de divulgation obligatoire, comme « Cet appel sera enregistré » et autres expressions similaires."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une personne ou un assistant impliqué(e) dans la conversation"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agent ou l’assistant évalué dans la conversation"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a demandé une assistance de niveau supérieur"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiments positifs et négatifs détectés dans la conversation"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifie l'agent le plus actif dans les conversations grâce à l'analyse de l'IA"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent a conclu la conversation poliment"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent comprend et accepte les sentiments du client"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse les fautes de grammaire, d'orthographe et de style de l’agent"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse l'ensemble de la conversation à la recherche d’expressions de salutation typiques"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent comprend la demande ou l'inquiétude du client"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse la facilité avec laquelle un texte peut être compris, en tenant compte de la complexité des mots et de la longueur des phrases"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse l'ensemble de la conversation à la recherche d'une solution"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse le ton de l'agent tout au long de la conversation"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signes d'attrition de la clientèle. Le client envisageait un changement ou a promis de se désengager."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe auquel appartient la conversation."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contenu ou le texte du message d'agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de réponses d'agent dans les conversations"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent affecté à l’évaluation de la conversation"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La durée de la conversation téléphonique en secondes"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contenu ou le texte de la conversation"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le canal de communication utilisé pour la conversation (par ex. courriel, clavardage)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires ajoutés à la conversation"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date d'ajout d'un commentaire à la conversation"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiments positifs et négatifs détectés dans la conversation"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date à laquelle le client a répondu au sondage de satisfaction de la clientèle"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique si l'évaluation de la conversation a fait l'objet d'un différend"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le canal utilisé par le client pour entamer la conversation"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de réponses de clients dans les conversations"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date du dernier message dans la conversation"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rôle ou le type d'utilisateur impliqué dans la conversation"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le silence entre l'agent et le client a dépassé le seuil"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a demandé une assistance de niveau supérieur"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a exprimé une extrême gratitude ou était très satisfait de l'assistance reçue"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client ou l'agent a explicitement demandé un suivi"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte ou l'enregistrement de l'intégralité de la conversation réalisé par le centre d'appels"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique si la conversation est marquée avec une étoile"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags utilisés dans les commentaires pour catégoriser le contenu"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue de la conversation détectée par l'IA"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date du dernier message dans la conversation"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de notes privées dans les conversations"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre moyen de caractères par message dans les conversations"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de réponses destinées aux clients dans les conversations"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un participant dont les informations sont visibles publiquement"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre moyen de mots par message dans les conversations"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les catégories utilisées pour noter ou évaluer la conversation"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut du processus d’évaluation de la conversation"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un participant ou une personne impliquée dans la conversation"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une adresse courriel associée à la conversation"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique si l’évaluation a été consultée"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’évaluateur qui a évalué la conversation"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de la dernière évaluation de la conversation"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent évalué dans la conversation"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout commentaire spécifique fourni par le client au sujet de sa satisfaction quant à la conversation"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La note de satisfaction du client pour la conversation"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La note de satisfaction du client par message dans la conversation"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fiche d’évaluation utilisée pour évaluer la conversation"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les ANS donnés n'ont pas été respectés"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de source ou de connexion à l'origine de la conversation"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversation était inhabituelle ou atypique, et il y avait plus d'échanges pour résoudre le problème"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La question du sondage portant sur le contenu de la conversation"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le titre ou le nom du sondage associé à la conversation"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent affecté à l’évaluation de la conversation"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marque ou le produit/service spécifique associé à la conversation"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le canal de communication utilisé pour la conversation (par ex. courriel, clavardage)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de clôture ou de résolution de la conversation"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de création initiale de la conversation"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de création initiale de la conversation"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date à laquelle le client a répondu au sondage de satisfaction de la clientèle"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une brève description ou un résumé de la conversation"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de référence externe lié à la conversation"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dossier ou un regroupement spécifique dans lequel la conversation est organisée"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le formulaire ou le modèle utilisé pour structurer la conversation"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID de référence externe lié à la conversation"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boîte de messagerie ou le compte de messagerie par le biais duquel la conversation a été reçue"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone associé à la conversation"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le niveau de priorité affecté à la conversation"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre total de réponses dans la conversation"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'état ou le statut actuel de la conversation"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut actuel de la conversation"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sujet ou la thématique de la conversation"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de réponses d'agent dans les conversations"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les étiquettes affectées pour catégoriser la conversation"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type ou la catégorie de la conversation"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de dernière mise à jour de la conversation"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de dernière mise à jour de la conversation"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le support ou canal utilisé pour la conversation"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contenu d'une transcription dans une conversation"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conversations qui ne peuvent pas être évaluées par le biais de l'analyse de l'IA"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'agents participant aux conversations"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique si la conversation a été lue"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'importe quel message"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficacité de communication de l’assistant"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétition de l’assistant"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses de l’assistant"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’assistant"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant (bot)"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens de l’appel"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message du client"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps mort"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgation de l’enregistrement manquante"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remontée"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu de la transcription de l’appel"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments avec une étoile"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inadmissible pour l’évaluation"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué(es) par"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation via"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthographe et grammaire"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message d’agent"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent le plus actif"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de réponses d’agents"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de l’appel (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu des conversations"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal du message"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette de commentaire"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du commentaire"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de réponse au sondage CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différend"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal du client"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses du client"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la dernière réponse"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’utilisateur"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message d’accueil"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque d'attrition"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps mort"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service exceptionnel"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcription"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag du commentaire"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas particulier"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compréhension"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la dernière réponse"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de notes privées de la conversation"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre moyen de caractères"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre moyen de mots"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses publiques"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant public"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de notation"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation et commentaire"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de révision"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisibilité"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel associée"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associés"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation reçue"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d’évaluation évaluée"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation consultée"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’évaluation"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne évaluée"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire sur le sondage"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT par message"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de satisfaction (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution proposée"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de source"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question du sondage"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du sondage"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque de la conversation"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine de la marque"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de la conversation"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de réponse au sondage CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date d'envoi du sondage CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de la conversation"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant externe"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de conversation"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier de conversations"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de conversation"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de conversation"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant externe"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte de messagerie de la conversation"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité de la conversation"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses à la conversation"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État de la conversation"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la conversation"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet de la conversation"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de réponses d’agents"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette du service d’assistance"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette du service d’assistance"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de conversation"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mise à jour"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mise à jour"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonalité"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu de la transcription"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'agents participants"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut consulté"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité de vulnérabilité"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé de vulnérabilité"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement de vie de vulnérabilité"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’AQ automatique"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une condition liée à une date"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un nom à votre filtre"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un nom de filtre"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne connectée"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anciens membres de l'espace de travail"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de l'espace de travail"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter : ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer le filtre"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez avoir au moins 1 filtre lié aux dates"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez des options"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une option"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre essai se termine dans ", _interpolate(_named("n")), " jour. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre essai se termine <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>dans ", _interpolate(_named("n")), " jour</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre essai se termine dans ", _interpolate(_named("n")), " jours. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre essai se termine <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>dans ", _interpolate(_named("n")), " jours</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre essai se termine aujourd'hui. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre essai se termine <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>aujourd'hui</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez un abonnement dès maintenant"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " jour(s)"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il vous reste ", _interpolate(_named("days")), " dans votre essai"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez atteint la limite d'utilisateurs pour votre forfait. ", _interpolate(_named("link")), " pour en savoir plus ou pour mettre votre forfait à niveau."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connectez votre service d'assistance"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela nous permettra d'extraire vos tickets pour que vous puissiez les évaluer."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre service d'assistance"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous utilisez AQ Zendesk avec des échantillons de données. Pour supprimer les échantillons de données et importer vos propres conversations, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échantillon de données"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiez les informations utiles ci-dessous"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenez une capture d’écran de la page entière pour fournir le contexte"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez notre équipe d’assistance à la clientèle pour résoudre ce problème :"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de référence :"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiez les informations utiles"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs, assistants et espaces de travail"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs et espaces de travail"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd’hui"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce mois-ci"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cette semaine"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " évalué ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " évalué ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " évalués ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveautés"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccourcis clavier"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clavardez avec nous"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de charger notre clavardage (Utilisez-vous peut-être un bloqueur de publicités?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encadrement"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["communauté"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d’accueil"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations données"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de connaissances"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de compte"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'application a été mise à jour. ", _interpolate(_named("buttonStart")), "Rechargez", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez commencer à utiliser l'AQ Zendesk dans votre espace de travail :"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous vous informons que vous avez déjà reçu une invitation pour utiliser l’AQ Zendesk dans le cadre d'un espace de travail. Si vous le souhaitez, vous pouvez continuer, mais cela créera une ", _interpolate(_named("bold")), " pour vous."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation distincte"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oui, je continue avec ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un nouveau compte"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr(e)?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " vous demande de rejoindre ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de connexion Internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle épingle"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation rapide"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concentrer et développer l'éditeur de commentaires"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer ou afficher le panneau latéral"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à la conversation suivante"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir la conversation initiale"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à la conversation précédente"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner la note la plus élevée à toutes les catégories"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner la note la plus basse à toutes les catégories"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mélanger les conversations (tri aléatoire)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dites-nous"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccourcis clavier"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la fenêtre des raccourcis"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez atteint la limite de ", _interpolate(_named("charLimit")), " caractères"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le type d’image ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") n'est pas autorisé"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La taille de l’image ", _interpolate(_named("name")), " est trop importante (5 Mo autorisés)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de chargement de l'éditeur de commentaires"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer pour téléverser"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les modèles"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle ajouté"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une cause profonde"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre, notamment les raisons de la suppression"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de l’évaluation"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’employés"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous contacterons pour vous aider à configurer votre compte"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’entreprise"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de contact de l'entreprise"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment avez-vous entendu parler pour la première fois d’AQ Zendesk ?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un canal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un nombre d'agents"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tous les champs obligatoires"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez accepter d’avoir lu et accepté nos conditions et notre politique de confidentialité."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal Slack axé sur l'assistance"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicité"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ami(e) ou collègue"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire de compte ou place de marché du service d'assistance"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site de référence"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteur de recherche (Google, Bing, etc.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médias sociaux"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlez-nous de vous et de votre entreprise."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du compte"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis prêt."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regardez, apprenez à connaître l'application, supprimez et ajoutez des éléments."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créé. Et ensuite?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt à intégrer vos vraies conversations?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez vos propres données"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer à explorer"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez avec des exemples de données"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous pouvons donc commencer à importer vos tickets pour que vous puissiez les évaluer."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez le compte de votre service d'assistance"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai changé d'avis."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'inscription"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Épingle créée. ", _interpolate(_named("buttonStart")), "Accédez aux épingles", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les épingles de coaching sont disponibles avec le forfait Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre le forfait à niveau"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tous les forfaits"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez vos notes pour préparer la prochaine séance individuelle ou de coaching."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingles pour le coaching"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des commentaires ou ajoutez d'autres utilisateurs avec ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette conversation a été supprimée"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle épingle"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une épingle"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'épingle"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnement des épingles"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les permissions nécessaires pour voir cette conversation"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler la carte"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler la conversation"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler le tableau de bord"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingle enregistrée"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler cette carte"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler cette conversation"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler ce tableau de bord"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler sous un utilisateur"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un utilisateur"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes la seule personne à pouvoir voir ce que vous épinglez. Pour partager avec d'autres personnes, mentionnez-les en ajoutant ", "@", " dans les commentaires."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " est désormais invité à cette conversation"]), _normalize([_interpolate(_named("agentsNames")), " sont désormais invités à cette conversation"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 commentaire"]), _normalize([_interpolate(_named("n")), " commentaires"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vous avez mentionné ", _interpolate(_named("participants")), " mais il/elle ne participe pas à la conversation."]), _normalize(["Vous avez mentionné ", _interpolate(_named("participants")), " mais ils/elles ne participent pas à la conversation."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " participant(s)"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participants"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous empêcher ", _interpolate(_named("agentName")), " d'accéder à cette épingle?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne rien faire"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas l'inviter à cette conversation"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un commentaire"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inviter"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inviter à cette conversation"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un utilisateur"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fil"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action ne peut pas être annulée."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela supprimera aussi tous les commentaires associés à cette épingle."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nouveau message"]), _normalize([_interpolate(_named("n")), " nouveau message"]), _normalize([_interpolate(_named("n")), " nouveaux messages"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes le seul à pouvoir voir vos épingles. Pour partager les épingles, ", "@", " quelqu'un dans les commentaires."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " a épinglé ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un tableau de bord"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une conversation"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une note"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuez une mise à niveau pour profiter des épingles de coaching"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les épingles de coaching sont disponibles avec le forfait Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les épingles de coaching sont disponibles avec le forfait Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à niveau pour obtenir la fonctionnalité"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparez vos séances individuelles ou enregistrez quelque chose pour une autre fois."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action aura pour effet de supprimer définitivement l'épingle et tous les commentaires ajoutés."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'épingle"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'épingle"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du dossier"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épinglez des éléments pour y revenir plus tard"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récents en premier"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus anciens en premier"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez une tendance ou une conversation intéressante du tableau de bord pour préparer vos séances individuelles ou notez-la pour une autre fois."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez une épingle en cliquant sur le bouton ci-dessus ou sur l'icône d'épingle dans la vue Conversation ou Tableau de bord."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingler"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune épingle créée"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aucun résultat pour ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez d'utiliser des mots-clés différents ou de vérifier les fautes de frappe."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle épingle"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par utilisateur, mot-clé..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par ordre alphabétique"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récemment créée(s)"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récemment modifiée(s)"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayant le plus d'épingles"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Je souhaite évaluer ", _interpolate(_named("n")), " conversation ", _interpolate(_named("schedule"))]), _normalize(["Je souhaite évaluer ", _interpolate(_named("n")), " conversations ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif mis à jour"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir un objectif"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez définir un objectif personnel pour le nombre de conversations que vous souhaitez évaluer."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous les jours"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous les mois"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toutes les semaines"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer l'objectif"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONNEL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des canaux"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un canal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager uniquement les commentaires sur le sondage avec des commentaires"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encouragez vos collègues en mettant en avant la qualité de leur service et les excellents commentaires de leurs clients."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager les commentaires positifs sur le sondage avec Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune chaîne trouvée"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager automatiquement les commentaires avec les notes ci-dessus"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations de l’assistant d’éloges"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez un rappel en début de semaine si vous n'avez effectué aucune évaluation."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappels"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel hebdomadaire pour effectuer des évaluations"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez des notifications sur les nouvelles évaluations, les réponses, les mentions et les différends dans Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses au fil"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez des notifications au sujet des nouvelles évaluations, réponses, mentions et des nouveaux différends."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends qui me concernent"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions dans les commentaires"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles évaluations reçues"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses dans les fils"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport avec statistiques"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez des rapports sur les évaluations données et reçues."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quotidiens"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensuelle"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hebdomadaires"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les jours"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 3 heures"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 6 heures"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immédiatement"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les mois"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les semaines"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'administrateur de votre compte a verrouillé les paramètres de notification."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une période"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications personnelles"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (actif)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une nouvelle connexion via un réseau social à ce compte :"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous supprimez une méthode de connexion active, vous serez déconnecté. Vous pouvez vous connecter à nouveau avec votre adresse courriel alternative."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la méthode de connexion"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ajouté comme option de connexion"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de connexion"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout réussi de Slack comme option de connexion"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr(e)?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un courriel vous sera envoyé à ", _interpolate(_named("email")), " pour réinitialiser votre mot de passe."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse courriel principale"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez notre courriel de confirmation dans votre boîte de réception"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien magique"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour changer cela, changez votre ", _interpolate(_named("link")), " au préalable."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’adresse courriel ", _interpolate(_named("email")), " sera associée à ce mot de passe."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil mis à jour"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de profil"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour de meilleurs résultats, téléversez une photo carrée (256 x 256 pixels ou plus)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel principale"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la semaine"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres personnels"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez au moins une option"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du modèle"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le modèle dans"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contenu de modèle"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nom de modèle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le modèle"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le modèle"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez des modèles de commentaires pour accélérer l’évaluation, l'épinglage d’éléments et le coaching."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé dans"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le modèle"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer définitivement ce modèle?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le modèle"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez des modèles de commentaires pour accélérer l’évaluation, l'épinglage d’éléments et le coaching."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle créé"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de commentaire créé"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de commentaire supprimé"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de commentaire enregistré"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encadrement"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingles"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations et différends"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour obtenir l’accès, contactez l’", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assistance à la clientèle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte trouvé"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour l’instant, utilisez ", _interpolate(_named("link")), " pour accéder à AQ Zendesk."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non migré"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ Assistant"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indicateurs"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notes"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistiques"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["étalonnage"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversations"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtres"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluation"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donné(es)"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes commentaires"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes différends"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes évaluations"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactions"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initié(es)"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le sondage"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingles"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encadrement"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnels"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créer un mot de passe"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identifiants"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courriel"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse courriel"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connexion"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réinitialiser"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluer l'objectif"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modèles"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le modèle"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compte"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturation"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entreprise"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d’assistance"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licences"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paramètres"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifications"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle catégorie"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message conflictuel trouvé"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez ou supprimez l’un des messages conflictuels"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilisez une icône ", _interpolate(_named("placeholder")), " pour exclure les données sensibles de QA automatique. Par exemple, au lieu d’utiliser « Prenez soin de vous, Jean », utilisez « Prenez soin de vous, ", _interpolate(_named("placeholder")), " » pour représenter n'importe quel nom."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum ", _interpolate(_named("limit")), " lignes"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer la règle à"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de 10 catégories personnalisées"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez atteint la limite de ", _interpolate(_named("limit")), " catégories personnalisées"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une condition"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une ligne"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la condition"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un message d’agent"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinon note"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alors note"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une catégorie de fiche d’évaluation"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez « / » pour ajouter une variable"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les directions"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message d'agent ne peut pas être vide"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message ne doit pas dépasser 300 caractères"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les sources"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les canaux"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les étiquettes"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les conversations"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précisez les endroits où s'applique la règle."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations spécifiques"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une information Focus"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message d'agent ne peut pas être vide"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de 10 informations personnalisées"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez atteint la limite de ", _interpolate(_named("limit")), " informations personnalisées"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez le nom de l’information"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’information"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une catégorie"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’information"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négatif"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutre"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positif"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder aux paramètres de l'espace de travail"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas maintenant"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour commencer à utiliser cette catégorie, allez aux paramètres de la fiche d’évaluation de votre espace de travail et ajoutez-la à une fiche d’évaluation de votre choix."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation créée"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuez automatiquement une note aux conversations closes en identifiant des mots-clés ou des expressions spécifiques."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne fonctionne pas avec les transcriptions vocales."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Félicitations! ✋ Vous avez atteint le maximum de ", _interpolate(_named("limit")), " catégories d’évaluations automatisées. Vous êtes le champion de la catégorisation! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de catégories atteinte"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez que tous les champs sont correctement remplis"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un nom à votre catégorie d’évaluation"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la catégorie"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle catégorie d’évaluation"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle catégorie d’évaluation"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuez automatiquement une note aux conversations closes en identifiant des mots-clés ou des expressions spécifiques. Ne fonctionne pas avec les transcriptions vocales."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentez automatiquement les conversations fermées en identifiant des mots-clés ou des expressions spécifiques. Fonctionne uniquement sur les transcriptions."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez automatiquement les conversations en identifiant des mots-clés ou des expressions spécifiques dans les messages des agents et des assistants."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser cette catégorie d’évaluation, allez aux paramètres de fiche d’évaluation de votre espace de travail et ajoutez-la à une fiche d’évaluation."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez automatiquement les conversations en fonction de mots-clés ou d’expressions spécifiques dans les messages."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible pour"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de l’information"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette information s’affiche dans Focus en fonction de critères définis."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de la fiche d’évaluation"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette catégorie peut être utilisée dans les fiches d’évaluation en fonction de critères définis."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assistant"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clavardage"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bientôt disponible"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnalisé"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prédéfini"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fiche d’évaluation"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["focus"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["centre d’appels"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications enregistrées"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astuce :"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur l’icône ", "{··}", " pour exclure des noms, des détails d'entreprises ou toute autre information sensible pendant les vérifications de clôture d'AQ automatique."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clôture approuvée"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une clôture approuvée"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture approuvée"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des exemptions"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'exemption"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemption"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une salutation approuvée"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la salutation approuvée"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutation approuvée"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez les mots ici, séparés par des sauts de ligne"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée après chaque mot pour créer une nouvelle entrée"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue applicable"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple, au lieu d’utiliser « Prenez soin de vous, Jean », vous pouvez utiliser « Prenez soin de vous, ", "{··}", " » pour représenter n'importe quel nom."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balise de contenu dynamique"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemptions"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 exemption ajoutée"]), _normalize([_interpolate(_named("count")), " exemptions ajoutées"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Exemption déjà ajoutée"]), _normalize(["Exemptions déjà ajoutées : ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une exemption"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune exemption trouvée"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune exemption ajoutée"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemption (sensible à la casse)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ajouter des exemptions"]), _normalize(["Ajouter 1 exemption"]), _normalize(["Ajouter ", _interpolate(_named("count")), " exemptions"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez un mot ou une expression"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemption enregistrée"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["langue"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les langues"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez une langue"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Clôture déjà ajoutée"]), _normalize(["Clôtures déjà ajoutées : ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clôture"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clôture approuvée trouvée"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clôture approuvée"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clôture"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une clôture"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture ajoutée"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture enregistrée"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le formulaire contient des entrées en double : ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Salutation déjà ajoutée"]), _normalize(["Salutations déjà ajoutées : ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un message d’accueil"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune salutation approuvée trouvée"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune salutation approuvée"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message d’accueil"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un message d’accueil"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une salutation"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutation ajoutée"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutation enregistrée"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une exemption"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Supprimer l'exemption"]), _normalize(["Supprimer les exemptions"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Supprimer définitivement cette exemption?"]), _normalize(["Supprimer définitivement ces exemptions?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'exemption"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Une ou plusieurs exemptions comportent plus de ", _interpolate(_list(0)), " caractères"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), " caractères sur ", _interpolate(_named("maxPhraseLength"))])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour par"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez vos propres approbations en fonction de votre marque et de vos normes de service. Quand elles sont ajoutées, l’AQ automatique ne détecte que les clôtures approuvées. Ces modifications s'appliqueront aux conversations futures."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite maximale de 100 clôtures approuvées. Si vous voulez en ajouter d'autres, il est peut-être temps de revoir vos clôtures existantes et de les perfectionner."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de clôture approuvée atteinte"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôtures approuvées"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots et expressions que vous voudriez que l’AQ automatique ne considère pas comme des fautes d'orthographe ou de grammaire. Ces modifications s'appliqueront aux conversations futures."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer la liste"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifiez vos propres salutations, adaptées à votre marque et aux normes de service. Une fois ajoutées, l’AQ automatique ne détecte que les salutations approuvées. Ces modifications s'appliqueront aux conversations futures."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite maximale de 100 salutations approuvées. Si vous voulez en ajouter d'autres, il est peut-être temps de revoir vos salutations existantes et de les perfectionner."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite des salutations approuvées atteinte"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutations approuvées"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas maintenant"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la catégorie"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer définitivement cette catégorie d’évaluation?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la liste des catégories"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La catégorie supprimée ne s’affichera pas pour les nouvelles conversations et les nouveaux filtres. Les données historiques des tableaux de bord et des conversations restent intactes."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La catégorie sera supprimée de toutes les fiches d’évaluation auxquelles elle a été ajoutée."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la suppression de la catégorie de fiche d’évaluation"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de fiche d’évaluation supprimée"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la catégorie de fiche d’évaluation"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les catégories de AQ automatique"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " réalisées par le modèle d’IA seront conservées dans la fiche d’évaluation, mais ne seront plus automatiquement notées"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La désactivation de l’intégration LLM ne désactive pas complètement la fonction AQ automatique. Voici ce qui se passera :"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données historiques"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " seront conservées dans le tableau de bord d’AQ automatique"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les causes profondes ajoutées manuellement"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " seront conservées, mais toutes les causes profondes basées sur les LLM seront supprimées"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivation d’AQ automatique basée sur les LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique utilise l’IA et les Grands modèles de langage (LLM) pour noter automatiquement des catégories spécifiques. Vos données sont traitées dans le cadre d’un accord sur le traitement de données (DPA) strict et ne sont jamais utilisées pour entraîner des modèles."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur l'IA générative"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’AQ automatique utilise Azure OpenAI Service de Microsoft pour ", _interpolate(_named("automatically_scoring_categories")), ". Vos données sont traitées dans le cadre d'un DPA strict uniquement pour vous fournir l’AQ automatique et ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notant automatiquement certaines catégories"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'est jamais utilisé pour entraîner des modèles OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique basée sur les LLM activée"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique basée sur les LLM désactivée"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique basée sur les LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notations automatisées grâce à l’IA dans AQ automatique"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des données des catégories d’AQ automatique basées sur MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentement Microsoft Azure retiré"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentement Microsoft Azure donné. Les conversations seront bientôt évaluées automatiquement."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez toujours utiliser l'AQ automatique, mais voici ce qu'il se passera"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La désactivation du service MS Azure OpenAI ne désactivera pas la fonctionnalité AQ automatique."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez le faire dans la vue des catégories de la fiche d’évaluation."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les catégories AQ automatique alimentées par le modèle resteront sur la fiche d’évaluation, mais ne seront pas notées automatiquement."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les causes profondes que vous avez ajoutées manuellement seront conservées. Toutes les causes profondes générées par le modèle seront supprimées."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données historiques sont conservées sur le tableau de bord AQ automatique."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver le traitement des données par le service MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus large gamme de catégories AQ automatique."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance multilingue prête à l'emploi."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout rapide de nouvelles catégories."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précision d’évaluation améliorée. GPT-3.5 montre une conscience contextuelle de niveau humain."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiez des dernières avancées de la technologie d’IA."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici ce dont vous bénéficierez"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le traitement des données par le service MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentement donné"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun consentement donné"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les nouveaux utilisateurs sont affectés à cet espace de travail"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail par défaut"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Heure sur 12 heures (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Heure sur 24 heures (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de l’heure"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lun."])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dim."])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil de l'entreprise mis à jour"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la semaine"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration personnalisée"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’identifiant de la clé d’accès et la clé secrète se trouvent généralement sous Gestion de l’accès > Utilisateurs"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de la clé d’accès AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la modification de la clé d’accès secrète"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la valeur de la clé d’accès secrète"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion permet de définir une seule région qui se trouve généralement sous Localisation"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une clé secrète"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé d’accès secrète AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. wJalrXUtnFEMI/K7MDENG/bPxRficyEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverez toutes les informations pertinentes dans Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Généralement disponible dans les paramètres de l’instance Amazon Connect et sur la page de stockage des données"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de stockage des enregistrements des appels"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l’ajout d’Amazon Connect. Vérifiez que tous les champs sont correctement remplis, puis réessayez."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’emplacement de stockage doit être au format compartiment/dossier séparé par une barre oblique."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour ajouter une connexion, contactez ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance à la clientèle"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous vos tickets Zendesk seront automatiquement synchronisés. Vous pouvez choisir d’exclure certains tickets."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie-Pacifique (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie-Pacifique (Séoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie-Pacifique (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie-Pacifique (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie-Pacifique (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (Centre)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Francfort)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Zurich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Irlande)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe (Londres)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région du serveur"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen-Orient (Émirats arabes unis)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la région du serveur"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amérique du Sud (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est des États-Unis (Virginie)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est des États-Unis 2 ( Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É.-U. Ouest (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion déjà ajoutée"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la connexion"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniser uniquement les conversations"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canal un, canal deux"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'ID unique de cette connexion est ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 mois"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 mois"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conversations inactives seront supprimées après la période de rétention sélectionnée. Les évaluations envoyées restent visibles dans les vues Activité et Tableau de bord."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de conservation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la période de conservation"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, cette action est irréversible."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends que cela supprimera toutes les données d'assistance pour cette connexion depuis l’AQ Zendesk, notamment les conversations, les champs et les étiquettes."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluations envoyées ne seront pas affectées et resteront visibles dans des vues spécifiques."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupe un, groupe deux"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclure"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["développement actif"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette intégration est encore en ", _interpolate(_named("activeDevelopment")), ". Il est possible que certains détails manquent (comme les pièces jointes ou les champs personnalisés)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boîte de messagerie un, boîte de messagerie deux"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["étiquette un, étiquette deux"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret du jeton API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez « Noms de champs de ticket » dans Freshdesk sous Paramètres → Champs de ticket"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez « Noms de champs personnalisés » dans Help Scout sous Paramètres → Champs personnalisés"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez « Noms des conversations Klass » dans Kustomer sous Paramètres → Klasses → Conversation"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez « Nom de champ personnalisé » dans Live Agent sous Paramètres → Champs personnalisés"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez « Nom de champ personnalisé » dans Wix sous Paramètres → Champs personnalisés"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparez les ID de champ par des virgules. Vous pouvez trouver ces ID dans Zendesk sous Admin → Champs de ticket."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez quelques utilisateurs de votre service d'assistance pour évaluer vos premières conversations. Ne vous inquiétez pas, nous ne les informerons pas à moins que vous ne les invitiez à devenir membres de l'espace de travail."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration réussie"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des membres"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous allons maintenant commencer l'extraction de vos conversations."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domaine : ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identifiant du service d'assistance : ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'authentification de votre connexion"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est prêt. Voici votre nouvelle connexion."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines connexions n'ont pas été mises à jour ou sont devenues inactives. Essayez de mettre ces connexions à jour ou de les supprimer de votre liste."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID client de l'API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret client de l’API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Créez un nouveau jeton API à partir de Paramètres → Compte → API et SDK. L'URL de redirection à utiliser est ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de compte"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre service d'assistance pour commencer"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si votre compte Intercom est configuré pour être servi depuis l'UE ou l'Australie, mettez cela à jour."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Adresse courriel que vous utilisez pour vous connecter à ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquez le nom, l'adresse courriel et le numéro de téléphone de l'utilisateur final. Les identifiants bancaires sont déjà masqués pour toutes les connexions au service d'assistance."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune intégration pour le moment. Ajoutez-en une maintenant."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez besoin des rôles suivants :"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une connexion"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle connexion"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous désélectionnez cette option, vous pourrez ajouter manuellement cette connexion à des espaces de travail spécifiques uniquement"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à tous les espaces de travail existants"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les espaces de travail"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret de clé API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canaux"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Connexion ", _interpolate(_list(0)), " supprimée"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la connexion"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez récupérer votre jeton API ci-dessous."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuil CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la connexion ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez « supprimer la connexion » pour supprimer cette connexion"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la connexion"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez et surveillez vos sources de données connectées."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la connexion"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparer les noms de champs par des virgules"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la mise à jour"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un nom unique à votre intégration"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les options avancées"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les champs de données sensibles"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne stockez jamais les URL de pièces jointes"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer les pièces jointes"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne stockez jamais le contenu des conversations (vous pouvez l'extraire à la demande plus tard à partir du service d'assistance)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer le contenu des conversations"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne stockez jamais le sujet des conversations (vous pouvez l'extraire à la demande plus tard à partir du service d'assistance)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer la ligne d’objet"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactif"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Votre sous-domaine ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire correspondre l'ID de l'équipe Inbox"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID numérique de votre équipe Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours…"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîtes de messagerie"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre la connexion à jour manuellement"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les données des clients"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du champ sensible"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du champ sensible"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler la connexion"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler la connexion"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à une sandbox Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Configuration d'une connexion avec ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les options avancées"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraire uniquement les conversations avec une note inférieure ou égale au seuil défini"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer un seuil CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisez uniquement les conversations qui..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez les dernières conversations"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la connexion"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion mise à jour"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre service d'assistance"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour résilier votre abonnement et supprimer votre compte, contactez ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance à la clientèle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression d’un ticket dans Zendesk Support (ou un autre service d’assistance) n’affecte pas les évaluations des conversations associées. Vous pouvez encore les consulter et les gérer."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La suppression d’un ticket dans Zendesk QA supprime également les évaluations de conversation associées.", _interpolate(_named("line_break")), "La suppression d’un ticket dans Zendesk Support (ou un autre service d’assistance) n’affecte pas les évaluations de conversation associées. Vous pouvez encore les consulter et les gérer."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les évaluations"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression des évaluations est définitive. Cela affecte aussi les données des tableaux de bord."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer toutes les évaluations"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer tout"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les évaluations ont été supprimées"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer tout"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque des évaluations sont associées à des tickets supprimés, elles sont affichées ici."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore d'évaluation"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher l’ID du ticket"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID du ticket : ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations supprimées"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les espaces de travail par défaut ne peuvent pas être supprimés. Pour supprimer cet espace de travail, sélectionnez un autre espace de travail par défaut dans ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action ne peut pas être annulée."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retirera et supprimera toutes les données"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je comprends que cela ", _interpolate(_named("removeAllData")), " liées à l'espace de travail, notamment les utilisateurs et les connexions de l’espace de travail."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’espace de travail"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez « supprimer l'espace de travail » pour supprimer cet espace de travail"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer l’espace de travail ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer l’espace de travail"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez sûr de ce que vous faites ou consultez un professionnel au préalable."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez si la personne évaluée par défaut est sélectionnée en fonction de l'agent le plus fréquent ou de l'agent assigné actuel."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent le plus actif"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne évaluée par défaut"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettez aux utilisateurs d’évaluer leurs propres conversations et aux agents d’effectuer des évaluations"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'espace de travail a été supprimé"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'espace de travail a été mis à jour"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adoptez une notation neutre en cachant les évaluations données précédemment par d'autres évaluateurs"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notation impartiale"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action ne peut pas être annulée."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez que cela enlèvera et supprimera toutes les données associées à cet espace de travail, y compris les utilisateurs et les connexions de l'espace de travail."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’espace de travail"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de l'espace de travail..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-évaluations"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien magique"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion unique Okta"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien magique"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de désactiver votre méthode de connexion actuelle"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les méthodes d'authentification ont été mises à jour"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisez certains types de méthodes d'authentification pour tous les utilisateurs."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/Connexion unique"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifications"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPTE"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs et espaces de travail"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez et gérez des espaces de travail, et invitez ou connectez des utilisateurs."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rester sur la page"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la page"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter cette page et perdre toutes les modifications?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifications"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble qu’il n’y ait aucune conversation à importer. Commencez par avoir des conversations avec votre service d'assistance, puis revenez ici pour les analyser."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation trouvée"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer cette catégorie de système"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la catégorie"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression des catégories est définitive. Cela effacera toutes les données de catégorie des rapports."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rétablir cette catégorie, vous devrez la recréer."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la catégorie"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une catégorie"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les catégories automatiques remplissent automatiquement la fiche d’évaluation avec les notes suggérées pour vous permettre de gagner du temps lors de l’évaluation. Les catégories manuelles sont remplies de toutes pièces par les évaluateurs."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthographe et grammaire"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutation"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compréhension"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisibilité"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution proposée"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catégorie trouvée"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune cause profonde trouvée"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune fiche d’évaluation trouvée"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez une autre recherche ou modifiez les filtres"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " catégories"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " catégories"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " catégories"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les catégories"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " catégories"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fiches d’évaluation"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fiches d’évaluation"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fiches d’évaluation"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les fiches d’évaluation"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " fiches d’évaluation"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuts"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuts"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuts"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les statuts"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statuts"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les types"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " types"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaces de travail"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaces de travail"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail trouvé"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaces de travail"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les espaces de travail"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaces de travail"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La pondération de ", _interpolate(_named("weight")), " contribue à ", _interpolate(_named("percentage")), " % au score global"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la fiche d’évaluation"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression des fiches d’évaluation est définitive. Cela effacera toutes les données des fiches d’évaluation des rapports."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rétablir cette fiche d’évaluation, vous devrez la recréer."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la fiche d’évaluation"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactif"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes profondes"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Échelle d’évaluation mise à jour. ", _interpolate(_named("buttonStart")), "Commencez l’évaluation", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'AQ Zendesk définit une échelle binaire par défaut, mais vous pouvez aussi opter pour une échelle plus granulaire :"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous voulez voir différentes options d'échelles d’échelle, configurées par catégorie, allez à vos ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie/Ton"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connaissance des produits"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En mettant à jour les échelles d’évaluation :"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre ancienne échelle d’évaluation et les catégories correspondantes seront archivées (pas supprimées)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines données de tableau de bord seront affectées puisque votre ancienne et votre nouvelle échelle risquent de ne pas être comparables."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloner la configuration de mes catégories d’évaluations existantes dans la nouvelle échelle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment mettre les échelles d’évaluation à jour?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez modifier le format d'affichage de la fiche d’évaluation (chiffre ou émojis) et rendre les catégories obligatoires ou facultatives."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser des émojis plutôt que des chiffres"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les utilisateurs à ignorer des catégories"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'échelle d’évaluation"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Les catégories de 1 espace de travail ont été clonées"]), _normalize(["Les catégories de ", _interpolate(_named("workspacesAmount")), " espaces de travail ont été clonées"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de l'espace de travail"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites apparaître automatiquement les appels pour lesquels l'agent n'indique pas au client qu’il s’attend à un silence dans la conversation."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversation comporte des moments de temps mort qui durent plus longtemps que le seuil défini."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez un seuil de temps mort maximum dans les appels"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps mort"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations supprimées ne s’affichent pas dans les nouvelles conversations et les nouveaux filtres. Les données historiques des tableaux de bord et des conversations restent intactes."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la suppression de l’information Focus"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Focus supprimée"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’information Focus"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez les conversations contenant des mots ou expressions spécifiques dans les transcriptions et les messages."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez automatiquement les appels dépourvus de la déclaration de divulgation obligatoire, comme « Cet appel sera enregistré » et autres expressions similaires."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s’agit d’identifier une déclaration de divulgation d’enregistrement dans l’appel en utilisant un grand modèle de langage (LLM) qui comprend dynamiquement le contexte, éliminant ainsi la nécessité de prédéfinir des expressions."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgation de l’enregistrement manquante"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites apparaître automatiquement les appels pour lesquels l'agent n'indique pas au client qu’il s’attend à un silence dans la conversation."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps mort"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifiez les moments de temps mort dans un appel"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez modifier votre abonnement? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") et nous serons heureux de vous aider."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysez automatiquement 100 % des conversations entre les agents, les externalisations des processus commerciaux, les canaux et les langues. L’IA détecte les problèmes, les lacunes en matière de connaissances et les opportunités de coaching pour améliorer le service."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture à 100 % de l’AQ automatique"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires et coaching des agents"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations IA Focus"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports et tableaux de bord avancés"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des sentiments et filtrage"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions à la demande"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation des affectations et définition des objectifs"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching et questionnaires"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance de la qualité (AQ) Zendesk"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les fonctionnalités de Professional, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (ancienne version)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion unique SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks pour une notification immédiate en cas d'échec critique"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration d'entrepôt de données"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement d'intégrations personnalisées"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire du succès clients dédié"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès direct à nos équipes de produits et d'ingénierie via Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les fonctionnalités de Suite Growth, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite IA"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique pour une couverture à 100 %"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des informations clés des conversations basées sur l'IA pour mieux comprendre les données"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrichissement et filtrage des données du service d'assistance améliorés par l'IA"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus : Détection automatisée des interactions incontournables"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des sentiments et filtrage"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports et informations sur les performances basés sur l'IA"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction des conversations à la demande"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi du temps d’évaluation"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses du taux de réussite"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les fonctionnalités de Suite IA, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite Enterprise"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package de mise en œuvre Enterprise, incluant l'intégration Salesforce et Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement d'intégrations personnalisées"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion unique SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration d'entrepôt de données"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks pour une notification immédiate en cas d'échec critique"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire du succès clients dédié"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès direct à nos équipes de produits et d'ingénierie"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès anticipé et influence sur notre feuille de route"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite Growth"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations automatiques des évaluations et définition d'objectifs"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching et analyses ciblés"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires pour la gestion de l'apprentissage"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des rôles et des permissions des utilisateurs"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquage des données sensibles"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de filtrage avancées et recherche de conversation"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation personnalisables avec plusieurs évaluations, pondérations modifiables et options d'échec automatique"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension de navigateur pour évaluer les conversations n'importe où"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des causes profondes"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports de performances pour comparer les agents, les équipes et les externalisations de processus métier"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages CSAT et analyses des commentaires des clients"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les fonctionnalités de Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (ancienne version)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs échelles d’évaluation par conversation"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations clés des conversations interactives basées sur l'IA"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer les affectations"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épingles pour le coaching"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances de coaching"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des sentiments et filtrage"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrage en fonction de la complexité de la conversation"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations clés des conversations"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation conditionnelles"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des causes profondes"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse CSAT basée sur l'IA avancée"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage de la note"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension de navigateur pour évaluer une conversation n'importe où"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez les conversations incontournables grâce à Focus"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction des conversations à la demande"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi du temps d’évaluation"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait personnalisé"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (ancienne version)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre illimité d’évaluations sur les conversations"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation au format CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations par les pairs et auto-évaluations"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation avec des pondérations modifiables et des options d'échec automatique"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de filtrage performantes"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs d’évaluation quotidiens, hebdomadaires ou mensuels"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages CSAT et analyses élémentaires"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableaux de bord"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports permettant de comparer les agents, les équipes et les externalisations de processus métier"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des rôles et des privilèges des utilisateurs"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite modifier mon abonnement à AQ Zendesk.\n\nMettez en évidence ou indiquez une option et indiquez le nombre de licences que vous souhaitez modifier :\nJ’aimerais [ajouter X licences] [supprimer X licences] [mettre à niveau et passer au forfait AQ Zendesk]\n\nVeuillez partager le nom de votre entreprise et le nom associé à votre compte AQ Zendesk, ainsi que tout autre détail que nous devons connaître pour traiter ce changement."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de l’abonnement AQ Zendesk "])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite acheter un abonnement AQ Zendesk.\n\nVeuillez partager le nom de votre entreprise et le nom associé à votre compte AQ Zendesk, ainsi que tout autre détail que nous devons connaître pour traiter cette demande :\n\nVeuillez également nous faire part de toute question spécifique que vous aimeriez aborder avant l’achat :\n\nMerci!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat d’un abonnement AQ Zendesk "])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre abonnement prend fin le ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " jours restants)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre essai se termine le ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " restant(s))"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 jour"]), _normalize([_interpolate(_named("n")), " jours"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (date d’expiration : ", _interpolate(_named("expires")), ") le ", _interpolate(_named("dayOfMonth")), " de chaque mois."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (date d’expiration : ", _interpolate(_named("expires")), ") le ", _interpolate(_named("dayOfMonth")), " de chaque mois."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (date d’expiration : ", _interpolate(_named("expires")), ") le ", _interpolate(_named("dayOfMonth")), "er de chaque mois."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (date d’expiration : ", _interpolate(_named("expires")), ") le ", _interpolate(_named("dayOfMonth")), " de chaque mois."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (date d’expiration : ", _interpolate(_named("expires")), ") le ", _interpolate(_named("dayOfMonth")), " de chaque mois."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (date d’expiration : ", _interpolate(_named("expires")), ") le ", _interpolate(_named("dayOfMonth")), " de chaque mois."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facturé à ", _interpolate(_named("card")), " (expiration ", _interpolate(_named("expires")), ") le ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se terminant par"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Expiré"]), _normalize(["Expiration dans 1 jour"]), _normalize(["Expiration dans ", _interpolate(_named("expiresIn")), " jours"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le prix de départ inclut ", _interpolate(_named("n")), " licences utilisateur"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["commence à ", _interpolate(_named("sum")), "/mois"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par utilisateur et par mois"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par an"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par mois"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trimestre"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimez l'annulation planifiée"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous voulez continuer à utiliser l'AQ Zendesk, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre abonnement a été annulé le ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre abonnement arrive à expiration le ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essai de ", _interpolate(_named("days")), " jour(s) sans frais supplémentaires!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisées"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("n")), " utilisateurs. Le nombre de licences ne peut pas être inférieur à ce chiffre."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'abonnement reprendra le ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre forfait d’AQ Zendesk actuel (", _interpolate(_named("plan")), ") n'inclut pas les fonctionnalités basées sur l'IA. Pour tirer le meilleur parti des fonctionnalités d'intelligence du centre d’appels, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour annuler votre abonnement, contactez-nous ", _interpolate(_named("link")), " ou clavardez avec nous."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de gestion de la qualité"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de la qualité"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement mis à jour"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une carte de crédit"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les forfaits"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les informations de facturation"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalle de facturation"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement sera résilié à la fin de la période de facturation."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous pour modifier"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait actuel"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs personnalisés"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rétrograder"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel (économisez 15 %)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les mois"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer annuellement"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les mois"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l’abonnement"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfaits Explore"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essai gratuit"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation manuelle"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez évaluer et confirmer le résumé de commande final à l'étape suivante."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus populaire"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de méthode de paiement active."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'essai"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprenez votre abonnement actuel pour changer de forfait"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre l'abonnement en pause"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les mois"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les trimestres"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver l'abonnement"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre l'abonnement"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l’essai maintenant"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulée"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renouvelable"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En pause"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'abonnement"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essai"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à niveau"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à niveau l'abonnement"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisée"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenez une minute pour partager vos commentaires sur votre expérience d'assistance à la clientèle. Merci d'avance. ", "{", "{", "agentName", "}", "}", " apprécie vos commentaires!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondage CSAT de ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment évalueriez-vous l'assistance que vous avez reçue de ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nous vous en sommes très reconnaissants. Nous utiliserons vos commentaires afin d’améliorer ", _interpolate(_list(0)), " pour tout le monde."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus au sujet des sondages"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de sondage"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez modifier cela ultérieurement."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesurez et améliorez la satisfaction de la clientèle grâce aux sondages CSAT automatisés et disponibles en plusieurs langues."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun sondage créé"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un sondage"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du sondage"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sondage a été créé"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essayez de modifier le mot-clé ou ", _interpolate(_named("contact_us")), " si vous voulez voir cela ici"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informez-nous"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune langue trouvée"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez une ou plusieurs langues dans la liste ci-dessous. ", _interpolate(_named("contact_us")), " si la langue que vous voulez utiliser n’est pas répertoriée ici."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des langues"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informez-nous"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une langue"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dernière publication ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dernier enregistrement ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question d'introduction"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé de l'échelle de gauche"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet du courriel"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raisons"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé de l'échelle de droite"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'il manque les traductions d'un sondage, les textes de la langue de base seront affichés à la place"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer les traductions"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions manquantes"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["N'oubliez pas d’évaluer toutes vos traductions avant de les appliquer. Modifiez le fichier téléchargé, enregistrez-le et téléversez-le à nouveau si nécessaire. Si un jeton ", _interpolate(_named("token_example")), " ne s'affiche pas sous la forme d'une étiquette, c’est qu’il se trouve dans un format incorrect ou dans un champ qui ne prend pas en charge les jetons."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluez les traductions"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduit"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résume la conversation et affiche des suggestions de commentaires."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance de l’IA d’AQ Zendesk (en anglais)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez modifier les types après leur création"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce type existe déjà. Sélectionnez un autre nom."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez le type personnalisé en tant qu'option sélectionnable dans la liste déroulante Type de sondage et regroupez tous les sondages créés dans le tableau de bord."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le ou les mots et appuyez sur Entrée pour enregistrer"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les types personnalisés"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de type ne peut pas être vide"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le type"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type personnalisé n'a pas été enregistré. Appuyez sur Entrée pour l'enregistrer."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel de l’expéditeur"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’expéditeur"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dans quelle mesure êtes-vous d'accord avec l'énoncé suivant : « ", _interpolate(_named("agentName")), " a facilité le traitement de mon problème »?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A-t-il été facile d'obtenir l'aide de ", _interpolate(_named("brandName")), " jusqu'à présent?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans l'ensemble, a-t-il été facile de résoudre votre problème aujourd'hui?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous pu facilement interagir avec notre équipe?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de la politique de confidentialité (facultatif)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL des conditions d’utilisation (facultatif)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comment évaluez-vous l’assistance que vous avez reçue de ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment s'est déroulé le service pour cette demande?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle mesure êtes-vous satisfait(e) du représentant de l'assistance?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dans quelle mesure êtes-vous satisfait(e) du service que vous avez reçu de ", _interpolate(_named("agentName")), " aujourd'hui?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question personnalisée"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Définissez votre propre question ", _interpolate(_named("type")), " personnalisée..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez une question personnalisée"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser les URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez votre message électronique"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous modifiez le nom d'un type de question personnalisé, il sera mis à jour dans tous les sondages qui l'utilisent, sans affecter les données recueillies."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les types personnalisés"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet du courriel"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question d'introduction"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau type personnalisé"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jeton de personnalisation"]), _normalize(["Jetons de personnalisation"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Faites glisser le jeton de personnalisation et déposez-le dans le champ ci-dessous."]), _normalize(["Faites glisser les jetons de personnalisation et déposez-les dans les champs ci-dessous."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversation ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'heure actuelle, seul l'envoi des sondages CSAT par courriel est possible. Nous nous efforçons de vous offrir plus d'opportunités, comme les sondages CSAT du service d’assistance, les sondages par SMS, etc."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synchroniser ou renouveler la connexion"]), _normalize(["Synchroniser ou renouveler les connexions"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniser ou renouveler la connexion ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["La connexion n'est pas synchronisée correctement"]), _normalize(["Les connexions ne sont pas synchronisées correctement."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désélectionner la case correspondante ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'authentifier maintenant ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez au moins une règle d'envoi de sondage pour activer la connexion"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette connexion est actuellement désactivée. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette connexion masque les données client sensibles. Pour activer l'envoi du sondage,\n", _interpolate(_named("link")), " dans les paramètres."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette connexion nécessite des permissions supplémentaires pour envoyer des sondages.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'authentifier maintenant"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ Zendesk a besoin de permissions supplémentaires pour Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " et ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 heure"]), _normalize([_interpolate(_named("n")), " heures"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minute"]), _normalize(["1 minute"]), _normalize([_interpolate(_named("n")), " minutes"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["heure"]), _normalize(["heures"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " pour configurer les règles d'envoi des sondages."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" jour"]), _normalize(["jours"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les liens vers les sondages arrivent à expiration"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les liens expirent dans"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empêcher de répondre aux sondages si trop de temps s'est écoulé"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["sondage ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparez les étiquettes par des virgules ou appuyez sur Entrée après l'écriture. Assurez-vous que chacun d'entre eux est répertorié précisément comme dans Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez les conditions d'envoi de sondage."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles d'envoi de sondage"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles d'envoi pour le suivi des sondages"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un sondage ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " existe déjà.\nPour résoudre ce conflit, modifiez les règles ici ou dans les ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez déjà configuré une connexion avec des règles de correspondance pour le sondage ", _interpolate(_named("name")), ". Pour résoudre ce conflit, modifiez les règles ici ou dans les ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une autre règle"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une marque"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une condition"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une valeur"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une étiquette"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correspondant aux mêmes règles"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une valeur"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel envoyé dans"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de suivi envoyé dans"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bout de code incorporé et suivi par courriel"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le code"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joignez un sondage AQ Zendesk à vos courriels en ajoutant le bout de code à votre configuration de Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide d'installation"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du bout de code"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bout de code copié dans le presse-papiers"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la copie du bout de code"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bout de code incorporé"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sondage contient des modifications non enregistrées. Enregistrez-le à nouveau pour vous assurer que le bout de code reflète les dernières mises à jour."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une connexion"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune connexion ajoutée pour l'instant"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrez les courriels, le clavardage en direct, les appels téléphoniques et plus encore dans AQ Zendesk grâce à vos connexions"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur l'envoi de sondage"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles d'envoi de sondage"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles d'envoi pour le suivi des sondages"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une règle de marque"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une règle"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifier"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + suivi par courriel"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre compte Intercom au sondage CSAT d’AQ Zendesk "])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer via"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui reçoit le sondage et quand"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les règles"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sondages intégrés permettent à vos clients de répondre à un sondage sans quitter le clavardage."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles de livraison par connexion"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un sondage ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " existe déjà.\nRésolvez le conflit en désactivant la connexion ou en modifiant les règles ici ou dans le ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez les conditions d'envoi du sondage"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Création par ", _interpolate(_named("name")), " le ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous supprimez cette langue, elle ne sera plus visible pour les participants et tous les textes traduits seront supprimés."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la langue"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action ne peut pas être annulée."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez aussi désactiver la langue en la cachant dans le sondage. Cela vous permettra de conserver toutes vos traductions en place sans les montrer aux participants."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière modification par ", _interpolate(_named("name")), " le ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus au sujet des langues utilisées pour les sondages"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avant d'ajouter d'autres langues, assurez-vous d’avoir finalisé les modifications apportées aux étapes Style et Contenu. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une langue"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs traduits"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible pour les participants"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier de traduction"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la langue"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" téléchargez un fichier de traduction"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluez les traductions"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traduction terminée. ", _interpolate(_named("review_link")), " ou ", _interpolate(_named("download_link")), " pour effectuer des modifications."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" téléchargez un fichier de traduction"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluez ce qui manque"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une partie du contenu n'a pas encore été traduit et sera remplacé par de l’anglais. ", _interpolate(_named("review_link")), " ou ", _interpolate(_named("download_link")), " pour ajouter les traductions manquantes."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez le sondage"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le sondage contient des modifications non enregistrées. ", _interpolate(_named("save")), " pour télécharger le fichier de traduction avec les dernières modifications."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser le fichier de traduction"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une échelle d’évaluation"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournit aux clients une liste déroulante dans laquelle ils peuvent sélectionner un motif expliquant l’évaluation"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte de l'échelle de gauche (facultatif)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un motif"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La raison ne peut pas être vide"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette raison existe déjà"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les raisons de votre note dans la liste déroulante"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression d'une raison et son ajout à nouveau avec le même nom ou la modification du nom d'une raison créent toujours une nouvelle entrée. Cela a une incidence sur les données affichées dans votre tableau de bord."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le motif"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une raison supprimée reste dans les rapports du tableau de bord, mais ne sera plus visible pour les nouveaux participants au sondage. Cela s'applique également aux traductions associées."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une raison"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer une raison « ", _interpolate(_list(0)), " »"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte de l'échelle de droite (facultatif)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur une échelle de 5 points, 1 et 2 sont des notes négatives, 3 est neutre et 4-5 sont des notes positives"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez du texte à l'échelle"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Échelle ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de notation"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’AQ Zendesk est en train d'importer vos conversations. Ce processus peut prendre jusqu'à 24 heures."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci sera utilisé comme jeton de personnalisation tout au long du sondage."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la marque"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutons et liens"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisez l'apparence de votre sondage"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrière-plan du logo"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les formats JPG, PNG et GIF sont pris en charge"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser le logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir en beauté"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un message que les utilisateurs verront après avoir envoyé leurs commentaires"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de remerciement"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de couleur hexadécimal incorrect"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel incorrecte"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peut pas être vide"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorrecte"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non visible :"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible :"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages envoyés"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages envoyés au cours des 30 derniers jours"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sondage a été supprimé"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sondage a été dupliqué"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel d'aperçu a été envoyé"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sondage a été publié"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sondage a été mis à jour"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sondage a été mis en pause"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre le sondage en pause"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez l'adresse courriel pour envoyer l'aperçu du sondage"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre", "@", "courriel.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la langue pour l'aperçu"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un courriel d'aperçu"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un exemple de sondage à votre adresse courriel. Les évaluations de l'aperçu du sondage ne seront pas stockées."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aperçu du sondage ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du sondage par courriel dans le Web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relancer"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme brouillon"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la marque"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nom propre à la marque peut être ajouté à l'étape de style"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l’assistance"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant de l’assistance"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimée"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En pause"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondage CSAT de ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour et reprendre le sondage"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous ne voyez pas ce courriel correctement ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas été satisfait de l'assistance que j'ai reçue car..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisé par l’AQ Zendesk "])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le motif"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de la conversation"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple : Le client a rencontré des problèmes lors de la mise à jour de la méthode de paiement. L'agent a partagé un article qui pourrait être utile. Cela a fonctionné."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisé par l’IA AQ Zendesk "])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour vos commentaires!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est la raison principale de votre note?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dites-nous pourquoi vous avez donné cette note..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effort élevé"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible effort"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliquez ici pour ouvrir le sondage"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre question personnalisée s'affiche ici"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous avez donné à votre expérience ", _interpolate(_list(0)), " une note de"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les commentaires"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se désabonner"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Très insatisfait(e)"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Très satisfait"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez que le statut actuel du sondage et les règles d'envoi ne seront pas inclus dans le double du sondage"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dupliquer le sondage « ", _interpolate(_list(0)), " »"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez le bout de code de courriel incorporé, n’oubliez pas de le supprimer de Zendesk avant de mettre le sondage en pause"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune réponse n'est recueillie quand le sondage est en pause."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre le sondage en pause"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez à supprimer le bout de code de Zendesk avant de mettre le sondage en pause."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le sondage"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez le bout de code de courriel incorporé, n’oubliez pas de le supprimer de Zendesk avant de supprimer le sondage."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action supprimera définitivement le sondage. Les utilisateurs qui ont reçu le sondage peuvent encore le remplir et toutes les données associées au sondage resteront dans AQ Zendesk."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer le sondage ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez à supprimer le bout de code de Zendesk avant de supprimer le sondage."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications appliquées seront visibles par les nouveaux participants."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier le sondage"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez à copier la dernière version du bout de code intégré après la publication du sondage."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez le bout de code de courriel incorporé, n'oubliez pas de le copier dans Zendesk après avoir repris le sondage."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuez d'envoyer le sondage et de recueillir les réponses."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour et reprendre le sondage"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas d'ajouter le bout de code incorporé à Zendesk après avoir repris le sondage."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre le sondage à jour"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veillez à copier la dernière version du bout de code intégré après avoir mis le sondage à jour."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce sondage? Cette action ne peut pas être annulée."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le sondage?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre essai est arrivé à expiration, mais vous pouvez continuer de profiter d’AQ Zendesk en effectuant une mise à niveau et en passant à un compte complet."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre essai est arrivé à expiration, mais vous pouvez continuer de profiter d’AQ Zendesk en effectuant une mise à niveau et en passant à un compte complet. Pour cela, contactez votre administrateur local."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre essai est terminé"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez à niveau maintenant"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre essai gratuit est terminé."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer à utiliser la version complète d’AQ Zendesk, saisissez vos informations de paiement."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer à utiliser la version complète d’AQ Zendesk, l'administrateur de votre compte doit saisir vos informations de paiement."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le paiement"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un utilisateur"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Création le ", _interpolate(_named("date")), " par ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Affectation ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous soyez encore en train de travailler là-dessus. Vous perdrez toutes les informations que vous avez ajoutées jusqu'ici si vous quittez ce ticket sans enregistrer les modifications."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette affectation se répétera un ", _interpolate(_named("weekDay")), " sur deux"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette affectation sera répétée tous les ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Toutes les personnes évaluées sont couvertes en un cycle"]), _normalize(["Toutes les personnes évaluées sont couvertes en ", _interpolate(_named("nr")), " cycles"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversation"]), _normalize([_interpolate(_named("n")), " conversations"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["agent(s) sur ", _interpolate(_named("nr"))]), _normalize(["agent sur ", _interpolate(_named("nr"))]), _normalize(["agents sur ", _interpolate(_named("nr"))])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["participant"]), _normalize(["participants"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversation par cycle"]), _normalize([_interpolate(_named("n")), " conversation par cycle"]), _normalize([_interpolate(_named("n")), " conversations par cycle"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " par personne évaluée à la suite"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["personne évaluée"]), _normalize(["personnes évaluées"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["évaluateur"]), _normalize(["évaluateurs"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes évaluées"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateurs"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs de l'espace de travail"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs de l'espace de travail sauf les agents"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cycle ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non récurrente"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Affectation ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur les affectations"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une affectation"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action supprimera définitivement l'affectation et ses données du tableau de bord. Toutes les évaluations associées resteront dans AQ Zendesk."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer l'affectation ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation supprimée"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation dupliquée"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune affectation ajoutée pour l'instant"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de création de l'affectation"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'affectation"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cycle est actualisé et une nouvelle liste des tâches est créée. Les évaluations déjà données comptent toujours pour l'objectif."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immédiatement"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Au début du cycle suivant, le ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand publier les modifications?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications apportées à cette affectation seront publiées avec cette affectation"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir l'affectation comme active?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récurrente le jour de début de l’affectation"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier vendredi de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième vendredi de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième vendredi de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième vendredi de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier vendredi de chaque mois"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier lundi de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième lundi de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième lundi de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième lundi de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier lundi de chaque mois"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier samedi de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième samedi de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième samedi de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième samedi de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier samedi de chaque mois"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier dimanche de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième dimanche de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième dimanche de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième dimanche de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier dimanche de chaque mois"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier jeudi de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième jeudi de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième jeudi de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième jeudi de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier jeudi de chaque mois"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier mardi de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième mardi de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième mardi de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième mardi de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier mardi de chaque mois"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le premier mercredi de chaque mois"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le deuxième mercredi de chaque mois"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le troisième mercredi de chaque mois"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le quatrième mercredi de chaque mois"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier mercredi de chaque mois"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récurrence personnalisée"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 1er de chaque mois"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconde"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troisième"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quatrième"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétitions"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour de la semaine"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre fin"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuseau horaire"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de cycle"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'affectation"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de base"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au début de chaque cycle d'affectation, une liste prédéterminée de conversations est générée à des fins d'évaluation."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres avancés"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer automatiquement les nouvelles conversations quand l'objectif d’évaluation n'est pas atteint"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La récupération automatique n’est pas possible avec les objectifs de pourcentage (%) d’évaluation"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit être un nombre entier, pas un nombre décimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser le remplacement des conversations (rôle d’évaluateur et supérieur)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement de conversations"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les évaluateurs à se voir attribuer leurs propres conversations pour évaluation"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-évaluations"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluations"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre minimum d'évaluations doit être d'au moins 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquer le nombre d'évaluations minimum pour chaque personne évaluée"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversations"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pourcentage (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluations"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluations au total"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque évaluateur doit effectuer un nombre défini d’évaluations"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluateurs doivent évaluer collectivement un nombre défini de conversations"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre minimum d'évaluations doit être d'au moins 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objectif de l’évaluation doit être compris entre 1 et 100 %"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluer l'objectif"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque personne évaluée doit faire évaluer un nombre minimum de ses conversations totales"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluateurs peuvent choisir des conversations dans le groupe partagé"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluateurs se voient attribuer des conversations à partir du groupe partagé"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approche de l'affectation"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les assistants"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les participants à l’espace de travail"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs de l'espace de travail"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des personnes évaluées"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sera évalué?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les personnes évaluées"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez qui évaluer"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les exclusions sont autorisées uniquement quand tous les utilisateurs d’un espace de travail ou un groupe sont sélectionnés"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateurs et personnes évaluées"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Actuel : ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace de travail par défaut"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels types d'utilisateurs doivent être sélectionnés comme personnes évaluées?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent le plus actif"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure les personnes évaluées (facultatif)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les utilisateurs à exclure"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure les évaluateurs (facultatif)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les utilisateurs à exclure"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs de l'espace de travail sauf les agents"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des évaluateurs"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui effectuera les évaluations?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez des évaluateurs"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les auto-évaluations ne sont pas autorisées dans cet espace de travail"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les auto-évaluations"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les auto-évaluations ne sont pas autorisées dans cet espace de travail."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conversation correspondante aujourd'hui"]), _normalize(["Conversations correspondantes aujourd'hui"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiché comme si l’affectation avait commencé aujourd'hui."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chiffres à venir sont susceptibles de varier."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune personne évaluée sélectionnée"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun évaluateur sélectionné"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Personne évaluée"]), _normalize(["Personnes évaluées"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Évaluateur"]), _normalize(["Évaluateurs"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération automatique : autorisée s'il n’y a pas suffisamment de conversations"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les vendredis à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les lundis à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les samedis à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les dimanches à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les jeudis à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les mardis à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " les mercredis à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tous les jours à partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Commence le ", _interpolate(_named("startDateTime")), " et se termine le ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " conditions de date"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " conditions de conversation et du service d'assistance"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune conversation et ", _interpolate(_named("count")), " condition du service d'assistance"]), _normalize(["Aucune conversation et ", _interpolate(_named("count")), " conditions du service d'assistance"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversation et aucune condition du service d'assistance"]), _normalize([_interpolate(_named("count")), " conversations et aucune condition du service d'assistance"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Approche : les évaluateurs doivent choisir des conversations"]), _normalize(["Approche : des conversations sont affectées aux évaluateurs"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrigez les erreurs mises en évidence dans le résumé pour continuer"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif : les évaluateurs doivent évaluer toutes les conversations"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Objectif : les évaluateurs doivent évaluer une conversation"]), _normalize(["Objectif : les évaluateurs doivent évaluer ", _interpolate(_named("count")), " conversations"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objectif : les évaluateurs doivent évaluer ", _interpolate(_named("count")), " % de conversations"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif : aucun objectif d’évaluation défini"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif : chaque personne évaluée doit faire évaluer toutes ses conversations"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objectif : chaque personne évaluée doit faire évaluer ", _interpolate(_named("count")), " du total de ses conversations"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objectif : chaque personne évaluée doit faire évaluer ", _interpolate(_named("count")), " % du total de ses conversations"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Objectif : chaque évaluateur doit faire une évaluation"]), _normalize(["Objectif : chaque évaluateur doit faire ", _interpolate(_named("count")), " évaluations"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Remplacement : les évaluateurs ne peuvent pas remplacer les conversations"]), _normalize(["Remplacement : les évaluateurs peuvent remplacer les conversations"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-évaluations : les évaluateurs peuvent se voir affecter leurs propres conversations"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune condition de date définie"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nom d'affectation défini"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " de ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les deux semaines"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les jours"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les mois"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamais"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les semaines"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée du cycle"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir comme actif"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme brouillon"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation enregistrée comme brouillon"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation définie comme inactive"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir comme inactif"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une affectation"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation créée"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme actif"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme inactif"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les mises à jour de l'affectation en attente prendront effet au cours du prochain cycle, commençant le ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier les modifications"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactif"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'affectation"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation mise à jour"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l’agent ou l’assistant a terminé la conversation."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent a conclu la conversation poliment"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si la conversation est terminée"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse les erreurs de grammaire, d’orthographe et de style dans les interactions avec l’agent et l’assistant."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse les fautes de grammaire, d'orthographe et de style de l’agent"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse les fautes de grammaire, d'orthographe et de style de l’agent"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orthographe et grammaire"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse les salutations des agents et des assistants de la conversation."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse l'ensemble de la conversation à la recherche d’expressions de salutation typiques"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse l'ensemble de la conversation à la recherche de salutations typiques"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message d’accueil"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent comprend et accepte les sentiments du client"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifie les cas où l'agent a reflété le sentiment du client ou utilisé des mots bienveillants comme Admettre et Reconnaître"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent comprend la demande ou l'inquiétude du client"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse si l'agent comprend la demande ou l'inquiétude du client"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compréhension"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse la facilité avec laquelle un texte peut être compris, en tenant compte de la complexité des mots et de la longueur des phrases"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse la facilité avec laquelle un texte peut être compris, en tenant compte de la complexité des mots et de la longueur des phrases"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce message contient 1 phrase trop longue, ce qui rend sa lecture difficile"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ce message contient ", _interpolate(_list(0)), " phrases trop longues, ce qui rend sa lecture difficile"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisibilité"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse l'ensemble de la conversation à la recherche d'une solution"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse l'ensemble de la conversation à la recherche d'une solution"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution proposée"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse le ton de l'agent tout au long de la conversation"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse le ton de l'agent tout au long de la conversation"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonalité"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune erreur n’a été détectée dans les messages de l'agent"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de déterminer les fautes de grammaire"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune salutation détectée. Si nécessaire, ajoutez-en de nouvelles par le biais de votre administrateur dans Paramètres."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a conclu poliment la conversation"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune expression de clôture détectée. Ajoutez-en de nouvelles si nécessaire par le biais de votre administrateur dans Paramètres"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a reflété le sentiment du client ou a utilisé des mots bienveillants"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de détecter si l'agent a reflété le sentiment du client ou a utilisé des mots bienveillants"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agent a utilisé des phrases longues et des mots complexes, ce qui peut rendre difficile la compréhension pour certains"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a utilisé des mots complexes, ce qui peut rendre la compréhension difficile pour certains"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a utilisé des phrases longues, ce qui peut rendre la compréhension difficile pour certains"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a utilisé un langage facile à comprendre"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données insuffisantes pour noter cette catégorie"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a eu du mal à comprendre la question/demande du client"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a bien compris la question/demande du client"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de détecter si l'agent a compris la question/demande du client"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ton de l'agent pendant la conversation"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ton de l'agent pendant la conversation"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ton de l'agent pendant la conversation"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ton de l'agent pendant la conversation"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ton de l'agent pendant la conversation"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de déterminer le ton de l'agent"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversation n'a pas été terminée"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversation a été terminée"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de détecter si la conversation a été terminée"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a manqué d'empathie envers le client et ses problèmes"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent a fait preuve d'empathie envers le client et ses problèmes"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de détecter si l'agent a fait preuve d'empathie envers le client et ses problèmes "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client n'a pas été salué pendant la conversation"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client a été salué pendant la conversation"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de détecter une salutation typique"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agent n'a pas proposé de solution au client"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agent a proposé une solution au client"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de détecter si l'agent a proposé une solution au client"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots complexes"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrases longues"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammaire"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faute d'orthographe"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négatif"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apologétique"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calme"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joyeux"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curieux"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encourageant"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 clôture"]), _normalize([_interpolate(_named("n")), " clôtures"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’AQ automatique ne vérifie que les clôtures approuvées. Si cette catégorie est définie comme critique, la non-utilisation des clôtures approuvées entraînera l'échec de l’évaluation."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôtures approuvées"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôtures approuvées"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 exemption"]), _normalize([_interpolate(_named("n")), " exemptions"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots et expressions que l’AQ automatique ne considère pas comme des fautes d'orthographe ou de grammaire."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemptions en matière de grammaire et d'orthographe"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemptions d’AQ automatique"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 salutation"]), _normalize([_interpolate(_named("n")), " salutations"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’AQ automatique vérifie uniquement les salutations approuvées. Si cette catégorie est définie comme critique, la non-utilisation des salutations approuvées entraînera l'échec de l’évaluation."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutations approuvées"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutations approuvées"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’AQ automatique"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une catégorie pour l'AQ automatique"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes profondes affectées automatiquement :"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte → Paramètres"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certaines catégories exploitent MS Azure, pour offrir une vaste gamme de fonctionnalités et de langues prises en charge. Pour en savoir plus, examinez votre ", _interpolate(_named("link")), " ou contactez un administrateur pour qu’il le fasse."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accédez à une liste élargie de catégories et de langues prises en charge en utilisant MS Azure. Examinez votre ", _interpolate(_named("link")), " ou contactez un administrateur pour qu’il le fasse."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser l'AQ automatique"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une note est automatiquement affectée aux catégories d’AQ automatique, sur la base d'une échelle d’évaluation fixe. Vous pourrez ajuster l’évaluation manuellement ultérieurement. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une catégorie pour l'AQ automatique"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une note affectée automatiquement dépend de la sévérité des erreurs et de leur nombre. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes profondes"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les catégories d’évaluations pertinentes sont visibles par les évaluateurs. Les catégories d’évaluations s'affichent en fonction des conditions que vous avez configurées lors de la création d'une catégorie."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clavardage"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intégration"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["téléphone"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez le type de catégorie d’évaluation pour l'espace de travail « ", _interpolate(_named("workspace")), " »."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluateurs doivent sélectionner manuellement la bonne fiche d’évaluation pour voir les catégories d’évaluations pertinentes pour chaque conversation."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment changer le type de catégorie d’évaluation?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les catégories"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le type de catégorie d’évaluation?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation conditionnelle"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation statique"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de catégorie d’évaluation"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de catégorie d’évaluation"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action ne peut pas être annulée."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimera toutes les évaluations"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez aussi conserver vos statistiques sans supprimer de données en archivant une catégorie."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je comprends que cela ", _interpolate(_named("deleteAllRatings")), " données pour cette catégorie."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. le ton est parfait"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la catégorie uniquement si"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["« Toujours » sera ignoré. Non cumulable avec d'autres conditions."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toujours"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes du service d'assistance"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une condition"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de satisfaction (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de source"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de la conversation"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une catégorie a besoin d'un nom"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter la catégorie à une fiche d’évaluation"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une catégorie"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une catégorie"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation ajoutée"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une catégorie archivée n'est pas visible sur la fiche d’évaluation, mais elle vous permet de conserver vos statistiques sans supprimer de données."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver la catégorie"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Archiver la catégorie ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation archivée"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la catégorie"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez « supprimer la catégorie » pour supprimer cette catégorie"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer la catégorie"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer la catégorie ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation supprimée"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation dupliquée"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de catégories d’évaluation ajouté"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de catégories d’évaluation supprimé"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre des groupes de catégories d’évaluation modifié"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de catégories d’évaluation mis à jour"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation désarchivée"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation mise à jour"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand un agent échoue dans une catégorie critique, toutes les catégories de l'évaluation échouent automatiquement."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie d’évaluation critique"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catégorie dans ce groupe"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle catégorie d’évaluation"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la catégorie"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer toutes les catégories et évaluer les données de ce groupe"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous supprimez le groupe de catégories, toutes les catégories qui y sont liées ne seront plus regroupées."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer le groupe de catégories ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous décidez de supprimer les catégories de ce groupe, vous perdrez toutes les données d'évaluation associées à ces catégories."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluateurs peuvent sélectionner plusieurs raisons comme cause profonde"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluateurs peuvent sélectionner plusieurs raisons comme cause profonde"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raisons multiples"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par ex. Tonalité"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouveau groupe de catégories"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau groupe"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter « Autre »"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une cause profonde"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette cause profonde existe déjà"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une cause profonde"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez les causes profondes pour expliquer l’évaluation"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de notation"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivés"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des fiches d’évaluation à cette catégorie d’évaluation"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désarchiver"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la catégorie"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les évaluations"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiché pour"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations négatives"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations négatives et neutres"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, mettre à jour"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondération"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les catégories qui sont plus importantes pour vous peuvent avoir plus de poids dans le calcul de la note du ticket."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pondération (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur l'étalonnage"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur l'étalonnage"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des séances d'étalonnage régulières permettent aux évaluateurs d'aligner leurs techniques d’évaluation et de s'assurer que les agents reçoivent le même niveau de commentaires cohérents et objectifs."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les forfaits"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " est un processus qui aide votre équipe à rester sur la même longueur d’onde quant à la manière dont toutes les interactions avec les clients doivent être traitées et évaluées."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L'étalonnage est disponible pour nos forfaits ", _interpolate(_list(0)), " et ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluations données précédemment ne s'affichent pas quand une conversation est ajoutée à la séance."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non visible"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évaluations données précédemment sont considérées comme des évaluations d'étalonnage. Une seule évaluation de conversation par évaluateur est ajoutée à la séance. Les évaluations spécifiques aux messages ne sont pas affichées."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité sur les évaluations données précédemment"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’étalonnage est un processus qui aide votre équipe à rester sur la même longueur d’onde quant à la manière dont toutes les interactions avec les clients doivent être traitées et évaluées. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ont un accès illimité à toutes les évaluations données pendant une séance d'étalonnage."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toujours voir toutes les évaluations"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piste"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir envoyé une évaluation, les utilisateurs voient les évaluations données par les autres évaluateurs pendant la séance d'étalonnage."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les évaluations d'étalonnage après l'envoi d'une évaluation"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ont un accès illimité à toutes les évaluations données pendant une séance d'étalonnage."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toujours voir toutes les évaluations"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir envoyé une évaluation, les utilisateurs voient les évaluations données par les autres évaluateurs pendant la séance d'étalonnage."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les évaluations d'étalonnage après l'envoi d'une évaluation"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir envoyé une évaluation, les utilisateurs voient les évaluations données par les autres évaluateurs pendant la séance d'étalonnage."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les évaluations d'étalonnage après l'envoi d'une évaluation"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ne verront que les évaluations qu'ils ont données au cours d'une séance d'étalonnage."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher uniquement ses propres évaluations"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'étalonnage mis à jour"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité des évaluations d'étalonnage"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez le seuil pour les mesures du tableau de bord en comblant les lacunes selon le système de mesures internes de vos équipes. Évaluez le seuil dans le tableau de bord."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étalonnage"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuil"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuil mis à jour"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un instant..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vérifiez que vous disposez des permissions d'administrateur et ", _interpolate(_named("retry")), ", ou ", _interpolate(_named("contact")), " si vous avez besoin d'aide."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nous contacter"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que nous n'avons pas réussi à obtenir l'accès à l'API de votre service d'assistance."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réessayez"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous connectons votre service d'assistance. Vous serez redirigé dans quelques instants."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorisé(e)"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Retournez à votre ", _interpolate(_list(0)), " pour ajouter une nouvelle connexion de service d'assistance."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous ayez manqué une ou deux étapes."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une organisation avec le même domaine de messagerie existe déjà"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page des connexions"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attendant..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patientez quelques instants pendant que nous vérifions vos permissions."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attendant..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration en cours"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créez un espace de travail"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour continuer à utiliser AQ Zendesk,. ", _interpolate(_named("link"))])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presque prêt à être utilisé"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail trouvé"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de compte"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer à utiliser AQ Zendesk, votre gestionnaire doit vous affecter à un espace de travail."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous supprimez votre compte, toutes les données en notre possession à votre sujet seront définitivement supprimées - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toutes les évaluations données, le contenu de conversation, les espaces de travail, etc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La déconnexion de l'intégration désactivera toutes les notifications AQ Zendesk via Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'intégration de Slack a été déconnectée d’AQ Zendesk."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez des paramètres de notifications par défaut pour tous les nouveaux utilisateurs du compte."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer les paramètres pour tous les utilisateurs"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous apprêtez à remplacer les paramètres de notification pour tous les utilisateurs du compte"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ne pourront pas définir leurs propres paramètres de notification."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de notification mis à jour"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte en cas d'échec d'une catégorie critique"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte quand le CSAT reçoit une réponse"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencheurs"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un espace de travail et des données de démonstration"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recréez les données de démonstration et conservez l'espace de travail de démonstration."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'espace de travail et les données de démonstration"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les données de démo"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les données"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gérer le mode démo du compte « ", _interpolate(_named("accountName")), " »"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode démo"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/6059285322522-%C3%80-propos-des-fonctionnalit%C3%A9s-d-IA-g%C3%A9n%C3%A9rative-dans-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043712839450-Configuration-des-param%C3%A8tres-de-s%C3%A9curit%C3%A9-avanc%C3%A9s-pour-les-connexions-du-service-d-assistance"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043747123354-Configuration-de-la-notation-automatique-dans-Zendesk-QA-avec-QA-automatique"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7418648293018-%C3%89valuation-des-performances-des-agents-IA-avec-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043724530842-Configuration-de-l-%C3%A9talonnage-dans-Zendesk-QA"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043759436954-Analyse-des-scores-des-cat%C3%A9gories-dans-le-temps"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043747231642-Utilisation-de-la-vue-des-Informations-cl%C3%A9s-des-conversations"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043747649690-Cr%C3%A9ation-de-plusieurs-fiches-d-%C3%A9valuation"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043701093786-Calculs-du-tableau-de-bord-des-%C3%A9valuations-Zendesk-QA"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043759449114-Recherche-de-conversations-%C3%A0-%C3%A9valuer-en-utilisant-des-filtres-personnalis%C3%A9s"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043760162074-Gestion-des-utilisateurs-et-des-espaces-de-travail"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043759543194-Utilisation-des-notes-%C3%A9pingl%C3%A9es-pour-coaching-des-agents"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043747142938-Gestion-des-groupes-dans-Zendesk-QA"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043759586074-Utilisation-des-informations-Focus-Zendesk-QA-pour-filtrer-les-conversations"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/articles/7043759396250-Suivi-du-temps-d-%C3%A9valuation-dans-Zendesk-QA"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/fr/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rester sur la page"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la page"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter cette page et perdre toutes les modifications?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez terminé l’affectation"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une affectation"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation terminée"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune affectation trouvée"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez actuellement aucune tâche à évaluer."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez l'affectation ou attendez que le cycle suivant commence."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenter de modifier les filtres"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'affectation"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garder un œil sur cette page pour les mises à jour"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune tâche pour ce cycle"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation à afficher"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer la tâche comme terminée"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez évalué cet utilisateur."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune auto-évaluation dans cette affectation"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez en cliquant sur l'icône d'étalonnage dans l'en-tête de la vue de conversation."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation ajoutée"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’échéance"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une affectation"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une séance d'étalonnage"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos affectations"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres affectations"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune affectation active"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune affectation"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les personnes évaluées"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les personnes évaluées sauf moi"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune personne évaluée trouvée"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une personne évaluée"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les statuts"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À faire"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les évaluateurs"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les évaluateurs sauf moi"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectées à moi"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun évaluateur trouvé"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un évaluateur"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jours"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jours"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jour"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jours"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jours"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jour"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " heures"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " heures"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " heure"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " heures"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " heures"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " heure"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutes"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minute"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s.o."])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs de l'espace de travail"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur actuel"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel objectif pour ce cycle"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel évaluateur"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un évaluateur"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter les conversations"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter la conversation"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversations à déplacer"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle actuel"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'affectation"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun évaluateur trouvé"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un évaluateur"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me réaffecter les conversations"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter les conversations à un autre utilisateur"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateurs"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " jour"]), _normalize([_interpolate(_named("n")), " jour"]), _normalize([_interpolate(_named("n")), " jours"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " heure"]), _normalize([_interpolate(_named("n")), " heure"]), _normalize([_interpolate(_named("n")), " heures"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les cycles terminés"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les séances terminées"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre objectif"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une raison afin de supprimer cette conversation"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expliquez pourquoi vous supprimez cette conversation"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la conversation"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous conserver les modifications?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une raison pour remplacer cette conversation"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les modifications"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garder"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conserver les modifications"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement introuvable"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expliquez pourquoi vous remplacez cette conversation"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous encore supprimer cette conversation de l’affectation? Cela réduira l'objectif de 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer la conversation"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le brouillon"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le brouillon"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me le réaffecter"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter à un autre utilisateur"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaffecter et évaluer"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la conversation"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser une évaluation"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objectif : ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " évalué(es)"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations effectuées"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les cycles terminés"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les séances terminées"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps restant"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation remplacée"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la liste des tâches"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " plus tard"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " seconde"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " secondes"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " j"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " j ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " j ", _interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " j"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " jour(s)"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " j ", _interpolate(_named("minutes")), " m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " min ", _interpolate(_named("seconds")), " s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " mois"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ans"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l’instant"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les permissions nécessaires. Contactez votre administrateur pour obtenir de l’aide."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page que vous cherchez n’existe pas."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inattendue est survenue. Contactez l'assistance pour obtenir de l'aide."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès refusé. Vous n'êtes pas autorisé à effectuer cette action."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page que vous cherchez n’existe pas ou vous n’y avez pas accès."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous allez un peu trop vite et nous avons du mal à vous suivre."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications enregistrées"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " appliqué"]), _normalize([_interpolate(_named("n")), " appliqué"]), _normalize([_interpolate(_named("n")), " appliqués"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critique"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ancien membre"]), _normalize(["un ancien membre"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " sélectionné(e)"]), _normalize([_interpolate(_named("n")), " sélectionné(e)"]), _normalize([_interpolate(_named("n")), " sélectionné(e)s"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... et +1 de plus"]), _normalize(["... et +", _interpolate(_named("n")), " de plus"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 de plus"]), _normalize(["+", _interpolate(_named("n")), " de plus"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n’est pas"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " réponse"]), _normalize([_interpolate(_named("n")), " réponse"]), _normalize([_interpolate(_named("n")), " réponses"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd’hui"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vendredi"]), _normalize(["Vendredis"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lundi"]), _normalize(["Lundis"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Samedi"]), _normalize(["Samedis"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dimanche"]), _normalize(["Dimanches"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jeudi"]), _normalize(["Jeudis"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mardi"]), _normalize(["Mardis"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mercredi"]), _normalize(["Mercredis"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrateur"]), _normalize(["un Administrateur"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gestionnaire de compte"]), _normalize(["un Gestionnaire de compte"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utilisateur"]), _normalize(["un Utilisateur"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AQ automatique"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre séance est arrivée à expiration."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peut pas être vide."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élément trouvé."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune personne ne correspond."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Propriétaire"]), _normalize(["un Propriétaire"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de commencer, lisez et acceptez nos conditions, et nous serons prêts à démarrer."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sûr(e)?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations effectuées dans l'ensemble"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["un Agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Piste"]), _normalize(["une Piste"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gestionnaire"]), _normalize(["un Gestionnaire"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Évaluateur"]), _normalize(["un Évaluateur"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur anonyme"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent assigné"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour copier"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone de danger"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (facultative)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation requise"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les modifications non enregistrées?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d’assistance"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQI"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamais"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suiv."])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de réussite"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres personnels"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préc."])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rétablir"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne évaluée"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues/vues"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations effectuées par un membre de l'espace de travail"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les modifications"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle binaire"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de 3 points"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de 4 points"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de 5 points"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche d'évaluation"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une fréquence"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires sur le sondage"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plage de temps"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondération"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous avez été invité(e) par ", _interpolate(_list(0)), " à rejoindre AQ Zendesk."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La connexion des utilisateurs se fait automatiquement à partir du Centre d’administration. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les utilisateurs"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur la gestion des utilisateurs"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certains utilisateurs sont automatiquement configurés et synchronisés à partir de votre fournisseur d'identité. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Permission de compte de « ", _interpolate(_list(0)), " » modifiée"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus au sujet des permissions utilisateur"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne plus me le demander"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toutes les modifications effectuées sur la page des utilisateurs sont répercutées dans les espaces de travail. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela signifie que l'accès des utilisateurs à certaines parties d’AQ Zendesk peut changer"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la permission au niveau du compte"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilisateur supprimé"]), _normalize([_interpolate(_named("n")), " utilisateurs supprimés"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quand vous supprimez un utilisateur, il perd l'accès à votre compte. Leurs évaluations, commentaires et notes resteront dans AQ Zendesk. ", _interpolate(_named("linebreak")), "La suppression d'un utilisateur n'affecte pas le nombre de licences de votre abonnement."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cet utilisateur sera supprimé de tous les espaces de travail que vous gérez. ", _interpolate(_named("linebreak")), " Leurs évaluations, commentaires et notes resteront intacts."]), _normalize(["Ils seront supprimés de tous les espaces de travail que vous gérez. ", _interpolate(_named("linebreak")), " L'ensemble de leurs évaluations, commentaires et notes restera intact."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Supprimer ", _interpolate(_named("name"))]), _normalize(["Supprimer les utilisateurs ", _interpolate(_named("name"))])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peut tout voir et gérer tous les paramètres et la facturation"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peut tout voir et gérer les paramètres personnels et de l'espace de travail, sauf la facturation"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions de compte"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les permissions spécifiques aux espaces de travail permettent à l'utilisateur de voir et de modifier les données au sein des espaces de travail dans lesquels il se trouve."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur AQ Assistant"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos assistants s’affichent ici car les conversations auxquelles ils ont participé sont synchronisées"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun assistant synchronisé"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le marquage d’un assistant en tant qu’utilisateur lui donne accès à la connexion. Ses données d’assistant historiques resteront disponibles dans les tableaux de bord."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun de ces assistants trouvé"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez une nouvelle recherche en utilisant un autre mot-clé ou en utilisant une autre orthographe."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des assistants"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom d’assistant"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme utilisateur"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les administrateurs et les gestionnaires de compte peuvent marquer les assistants comme utilisateurs"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’assistant"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier clavardage"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marqué manuellement"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluable"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’assistant est supprimé de l’analyse automatique et de toutes les affectations. Les nouvelles données ne s’affichent pas dans les tableaux de bord, ce qui garantit des rapports précis et une gestion des assistants simplifiée."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant génératif"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant de flux de travail"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au(x) groupe(s)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les groupes"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer du ou des groupes"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un groupe"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission du compte"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à/aux espace(s) de travail"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière activité"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle au niveau du compte"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque utilisateur reçoit une permission de niveau du compte et/ou de l’espace de travail. Les permissions d'administrateur sont désormais au niveau du compte et les autres au niveau de l'espace de travail."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer d’un/des espace(s) de travail"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission pour l'espace de travail"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions gérées dans le groupe"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme assistant"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fait de marquer un utilisateur comme assistant supprime son accès à la connexion. Ses données d’utilisateur historiques resteront disponibles dans les tableaux de bord."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimez l’assistant de l’analyse automatique et de toutes les affectations. Les nouvelles données ne s’affichent pas dans les tableaux de bord, ce qui garantit des rapports précis et une gestion des assistants simplifiée."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure l’assistant des avis"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur marqué comme assistant"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul le type de permission Utilisateur peut être marqué comme assistant"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs avec plusieurs comptes ne peuvent pas être marqués comme un assistant"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par utilisateur"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistants"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs, assistants et espaces de travail"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPACES DE TRAVAIL"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs et espaces de travail"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d’autorisations"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté manuellement"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation en attente"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action requise"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...et un autre"]), _normalize(["et ", _interpolate(_named("n")), " autres"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer la permission par"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Supprimer et réinviter pour modifier le rôle de ", _interpolate(_named("n")), " utilisateur en attente d’invitation."]), _normalize(["Supprimer et réinviter pour modifier le rôle de ", _interpolate(_named("n")), " utilisateurs en attente d'invitation"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Modifier les détails de ", _interpolate(_named("name"))]), _normalize(["Modifier les détails de ", _interpolate(_named("n")), " utilisateurs sélectionnés"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilisateur mis à jour."]), _normalize([_interpolate(_named("n")), " utilisateurs mis à jour."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ce groupe contient 1 utilisateur d'un autre espace de travail."]), _normalize(["Ce groupe contient ", _interpolate(_named("n")), " utilisateurs d'un autre espace de travail."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » archivé"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe archivé"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le groupe"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » créé"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sans titre"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer définitivement ce groupe?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable du groupe"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle dans le groupe"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » masqué"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce groupe ne sera visible nulle part ailleurs dans AQ Zendesk "])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer uniquement les permissions des espaces de travail"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe non visible dans AQ Zendesk "])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piste"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe sans titre"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un autre groupe porte déjà ce nom"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un groupe archivé porte déjà ce nom"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre rôle vous permet uniquement de gérer les membres de ce groupe. Pour effectuer d’autres modifications, contactez un utilisateur disposant de permissions plus élevées."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer en tant que responsable du groupe"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » rétabli"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » enregistré"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir en tant que responsable du groupe"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » désarchivé"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications non enregistrées"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter vos utilisateurs existants à ce groupe"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom ou adresse courriel"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des membres"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe « ", _interpolate(_named("name")), " » visible"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chaque membre est ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus au sujet des groupes"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains attributs des groupes sont automatiquement provisionnés et synchronisés à partir de votre fournisseur d'identité."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus au sujet des groupes"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un groupe"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez facilement les membres de votre équipe et reproduisez votre structure organisationnelle."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez au moins 1 membre"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez un nom de groupe"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un groupe avec ce nom existe déjà"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du groupe"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez les membres de votre équipe et reproduisez votre structure organisationnelle."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun groupe"]), _normalize(["1 groupe"]), _normalize([_interpolate(_named("count")), " groupes"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un nouveau groupe ou activez un groupe archivé."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe actif disponible"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe archivé"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun de ces groupes trouvés"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez une nouvelle recherche en utilisant un autre mot-clé ou en utilisant une autre orthographe."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe ajouté"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["dans ces"]), _normalize(["dans"]), _normalize(["dans"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez par nom de groupe"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivés"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Renvoi possible ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licence(s) restante(s)"]), _normalize([_interpolate(_named("n")), " licence restante"]), _normalize([_interpolate(_named("n")), " licences restantes"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de licences est actuellement limité, car votre abonnement est en pause. Réactivez-le pour ajouter plus d'utilisateurs."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de licences pour votre compte. Vous pouvez remplacer des utilisateurs en désactivant un utilisateur actif ou en ajoutant des licences."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter les détails de paiement"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " sélectionné"]), _normalize([_interpolate(_named("n")), " sélectionnés"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilisateur sélectionné"]), _normalize([_interpolate(_named("n")), " utilisateurs sélectionnés"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ligne"]), _normalize([_interpolate(_named("n")), " ligne"]), _normalize([_interpolate(_named("n")), " lignes"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez des utilisateurs en vrac à partir de leur nom ou de leur adresse courriel."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez les données ici, séparées par des virgules ou de nouvelles lignes"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher les utilisateurs en vrac"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Modifier les permissions de l'espace de travail pour ", _interpolate(_named("name"))]), _normalize(["Modifier les permissions de l'espace de travail pour ", _interpolate(_named("n")), " membres sélectionnés"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions de modification"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la permission d'espace de travail"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Supprimer ", _interpolate(_named("name")), " de l'espace de travail"]), _normalize(["Supprimer ", _interpolate(_named("name")), " membres de l’espace de travail"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " membre supprimé"]), _normalize([_interpolate(_named("n")), " membres supprimés"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Supprimer le membre"]), _normalize(["Supprimer les membres"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cet utilisateur sera supprimé de l'espace de travail."]), _normalize(["Ces utilisateurs seront supprimés de l'espace de travail."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Permission pour l'espace de travail de « ", _interpolate(_list(0)), " » modifiée."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des membres"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les conversations et les évaluations associées"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout ce qu'il y a dans l'espace de travail, mais gérer uniquement les questionnaires, les affectations, les différends et les séances d'étalonnage"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions pour les espaces de travail"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir et gérer tout dans l'espace de travail"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout dans l'espace de travail, sauf les paramètres de l'espace de travail"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connecter des utilisateurs"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs des espaces de travail que vous gérez ont été ajoutés à cet espace de travail."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez à un administrateur de le faire."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitez directement de nouveaux utilisateurs vous-même ou demandez à un administrateur de le faire."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " ou ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur à afficher"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " utilisateur ajouté"]), _normalize([_interpolate(_named("n")), " utilisateurs ajoutés"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ajouter des membres"]), _normalize(["Ajouter un membre"]), _normalize(["Ajouter ", _interpolate(_named("n")), " membres"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la permission d'espace de travail"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les utilisateurs de l'espace de travail"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ajouter des membres à ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez les courriels ici, séparés par de nouvelles lignes ou des virgules"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " courriel(s) sur ", _interpolate(_list(1)), " correspondant(s)"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" avec des utilisateurs déjà connectés :"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Échec de la mise en correspondance de ", _interpolate(_list(0)), " courriels sur ", _interpolate(_list(1))])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez l'orthographe ou connectez-les plus tard individuellement"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" avec les utilisateurs du service d'assistance :"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilisateur connecté"]), _normalize([_interpolate(_named("n")), " utilisateurs connectés"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilisateur invité"]), _normalize([_interpolate(_named("n")), " utilisateurs invités"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion en vrac"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation en vrac"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple :\n\nanne", "@", "exemple.com, jean", "@", "exemple.com\n\nanne", "@", "exemple.com\nanne", "@", "exemple.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous depuis votre ou vos services d'assistance pour évaluer leurs interactions"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Connecter l'utilisateur"]), _normalize(["Connecter les utilisateurs"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter individuellement"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez que les utilisateurs connectés accèdent à AQ Zendesk , vous pouvez les inviter ici."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter les utilisateurs connectés"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Inviter un utilisateur"]), _normalize(["Inviter des utilisateurs"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer l'invitation"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les utilisateurs à connecter à partir de votre ou vos services d'assistance pour évaluer leurs interactions"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer votre abonnement"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter les utilisateurs du service d'assistance"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un nom unique à votre espace de travail"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si cette option est activée, toutes les connexions de compte existantes sont automatiquement ajoutées au nouvel espace de travail."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter toutes les connexions à cet espace de travail"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un espace de travail"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'espace de travail"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un espace de travail"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ajoutés directement au compte (sans affecter d'espace de travail) se voient affecter un rôle au niveau du compte. Vous pouvez les ajouter aux espaces de travail ultérieurement."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rôle d'utilisateur au niveau Compte est automatiquement affecté aux utilisateurs invités. Vous pourrez les mettre à niveau vers un rôle d'administrateur ultérieurement."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout cela fonctionne"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez les courriels ici, séparés par des nouvelles lignes ou des virgules."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utilisateur invité par courriel"]), _normalize([_interpolate(_named("n")), " utilisateurs invités par courriel"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs ajoutés à un espace de travail se voient automatiquement affecter un rôle d'utilisateur au niveau du compte. Vous pourrez les mettre à niveau vers un rôle d'administrateur ultérieurement."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une autre"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter des utilisateurs uniquement dans le compte sans les ajouter à un espace de travail"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs invités se voient uniquement affecter la permission de compte et ne sont pas ajoutés aux espaces de travail."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter directement des utilisateurs dans un espace de travail et affecter des permissions pour cet espace de travail"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des utilisateurs à un espace de travail"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation en vrac"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter individuellement"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter seulement dans le compte"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter dans un espace de travail"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Inviter des utilisateurs"]), _normalize(["Inviter un utilisateur"]), _normalize(["Inviter ", _interpolate(_named("n")), " utilisateurs"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter en vrac des utilisateurs par courriel"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Supprimer l'utilisateur"]), _normalize(["Supprimer les utilisateurs"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désélectionner"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter des utilisateurs par courriel"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une permission"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer l'invitation"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur connecté"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur en attente d'invitation par courriel"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un espace de travail"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents IA"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d’administration"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance de la qualité"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des ressources"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " terminée"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " terminée"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " terminées"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cycle ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateur"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'affectation"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagnez du temps et configurez des affectations d’évaluations automatiques au lieu de rechercher et d'affecter manuellement les conversations à l’évaluation."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Échéance le ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter des séances dans la section « Étalonnage » du menu latéral de la vue de conversation."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance ajoutée pour l’instant"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["référence"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter des conversations à une séance en cliquant sur l'icône d'étalonnage dans l'en-tête de la vue des conversations."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation ajoutée pour l'instant"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucune évaluation n'a été donnée. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser une évaluation sur l'étalonnage"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause profonde"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du chargement de la palette de commandes."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvé par ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement de la palette de commandes..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pages, filtres, conversations, paramètres..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre privé"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre public"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["À partir de ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis votre API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Affichage de ", _interpolate(_named("rangeStart")), " à ", _interpolate(_named("rangeEnd")), " sur ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Affichage de ", _interpolate(_named("rangeStart")), " à ", _interpolate(_named("rangeEnd")), " sur ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le panneau latéral"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le panneau latéral"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la barre latérale"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends acceptés"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends rejetés"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends ouverts"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends partiellement acceptés"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations effectuées"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations vues"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acceptés"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ouverts"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partiellement acceptés"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " du total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptés"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement acceptés"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusés"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["évaluation"]), _normalize(["évaluations"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d’évaluations qui ont été vues par les personnes évaluées"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'évaluations qui ont été vues"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluateurs ayant un différend"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestataires"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends par catégorie"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différends dans le temps"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de résolution moyen"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de résolution moyen (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de résolution moyen (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolue"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de différends"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptés"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue de la réponse"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune langue trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les langues"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteurs présumés"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun facteur trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les facteurs"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots de nuage de mots"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mot trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les mots"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le(s) plus récent(es)"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le(s) plus ancien(nes)"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement acceptés"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a réagi avec ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusés"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolue"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une couleur de peau"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune séance trouvée"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catégorie trouvée"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du commentaire"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune taille trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les tailles"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre trouvé"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les groupes"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun hashtag trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune valeur trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les valeurs"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun champ personnalisé trouvé"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquettes du service d'assistance"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune étiquette de service d'assistance trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les étiquettes du service d'assistance"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des résultats..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de question"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun type de question trouvé"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une réaction"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiches d’évaluation"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune fiche d’évaluation trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les fiches d’évaluation"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune note trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les notes"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sources"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune source trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les sources"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison des commentaires"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune raison trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les raisons"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondages"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun sondage trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les sondages"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canaux"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune chaîne trouvée"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les canaux"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun espace de travail trouvé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les espaces de travail"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux questionnaires"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le questionnaire a été supprimé ou n'a pas encore été publié. Vérifiez à nouveau le lien ou retournez à la liste des questionnaires."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce questionnaire n'a pas été partagé avec un espace de travail auquel vous appartenez. Demandez à un administrateur de vous ajouter à un espace de travail auquel ce questionnaire a accès."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le questionnaire est indisponible"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès au questionnaire accordé aux espaces de travail auxquels vous n'appartenez pas. Échec d'enregistrement et de publication du questionnaire."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez à un administrateur d'enregistrer et de publier le questionnaire."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez marquer au moins l'une des réponses comme correcte."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peut pas être vide."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes en train de voir un aperçu du questionnaire."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les espaces de travail qui peuvent accéder au questionnaire. Les utilisateurs appartenant à plusieurs espaces de travail ne peuvent répondre qu'une seule fois au questionnaire. La sélection de « Tous les espaces de travail » n’inclura pas les nouveaux espaces de travail une fois le questionnaire publié."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès au questionnaire"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter le mode Aperçu"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre au questionnaire"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier le questionnaire"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de questionnaire"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer comme brouillon"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau questionnaire"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désarchiver"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une option"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez-le"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ça a l'air sympa, non?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre réponse a été envoyée"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre questionnaire a été enregistré"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre questionnaire a été publié"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre questionnaire a été supprimé"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une question"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver le questionnaire"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un questionnaire archivé et ses résultats ne s'afficheront plus dans la liste des questionnaires. Vous pouvez toujours accéder aux résultats d'un questionnaire archivé et le désarchiver à tout moment."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver le questionnaire"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois publié, un questionnaire ne peut plus être modifié. Tous les questionnaires publiés seront accessibles à tous les utilisateurs de votre compte."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier le questionnaire?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce questionnaire? Cette action ne peut pas être annulée."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le questionnaire?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois envoyées, vos réponses ne peuvent pas être modifiées."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le questionnaire?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désarchiver le questionnaire"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le désarchivage d’un questionnaire le rend disponible dans l'aperçu du questionnaire. Cela permettra aux anciens participants d'accéder à leurs résultats et aux nouveaux participants de répondre au questionnaire."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désarchiver le questionnaire"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question en double"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (facultative)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer la question vers le bas"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer la question vers le haut"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les questions avec réponses sont affichées dans l'aperçu."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la question"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le questionnaire"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire sans titre"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du questionnaire"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action aura pour effet de supprimer définitivement le questionnaire et ses résultats."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez aussi accéder aux résultats en archivant le questionnaire."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondu"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le questionnaire"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action aura pour effet de supprimer définitivement le questionnaire et ses résultats."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer le questionnaire ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les questionnaires aident à renforcer la confiance et les compétences de l'équipe, à offrir des expériences cohérentes et à intégrer les nouveaux membres."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez rechercher des questionnaires en fonction de leur nom ou du nom de l'auteur."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun questionnaire créé pour l’instant"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter la liste des questionnaires"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note moyenne"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire/Auteur"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire/Auteur/Espaces de travail"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiées"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaces de travail"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par questionnaire, par auteur"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivés"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les questionnaires"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le questionnaire a été archivé."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le questionnaire a été désarchivé."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce questionnaire n'a reçu aucune réponse des utilisateurs dans le ou les espaces de travail sélectionnés."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce questionnaire n'a reçu aucune réponse avant d'être archivé."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune réponse"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici, vous verrez le détail des réponses au questionnaire.\nPersonne n'a encore répondu au questionnaire. Il serait utile de le partager."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune réponse jusqu’à maintenant"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses :"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note moyenne :"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Copie"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien copié"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Questionnaires de ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le tableau des performances, vous pouvez rechercher le nom du participant."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter le tableau des performances"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par participant"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau des performances"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer le tableau des performances"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela signifie que vous ne pourrez plus filtrer ce hashtag dans le tableau de bord et que les commentaires ne seront plus remplis automatiquement. Cette action est irréversible."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des hashtags"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des hashtags"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les membres à créer de nouveaux hashtags"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisées"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer le hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de hashtag est verrouillée"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de hashtag est déverrouillée"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag « ", _interpolate(_list(0)), " » supprimé"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag « ", _interpolate(_list(0)), " » renommé en « ", _interpolate(_list(1)), " »"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag ajouté"]), _normalize([_interpolate(_named("count")), " hashtags ajoutés"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau nom"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun hashtag créé"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renommer le hashtag « ", _interpolate(_named("tagRenamed")), " »"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparer les hashtags par des virgules ou des sauts de ligne"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ne vous inquiétez pas. Vous pouvez le faire ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion liée à l'espace de travail"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre une connexion"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre une connexion à cet espace de travail"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez votre administrateur pour effectuer des modifications ici"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communiquez avec AQ Zendesk pour apporter des modifications ici"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune connexion sous le compte"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous n'ayez pas encore effectué l'intégration de votre plateforme de service à la clientèle."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous supprimez une connexion, les conversations seront supprimées de l'espace de travail ", _interpolate(_named("workspaceName")), " et toutes les données les concernant seront supprimées, notamment les évaluations et les différends."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la connexion"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la connexion ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une connexion"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pendant la période choisie, certaines catégories d'AQ automatique actives n'étaient pas présentes dans votre fiche d’évaluation. Les conversations antérieures à votre dernière modification le ", _interpolate(_named("date")), " peuvent ne pas avoir été analysées pour ces catégories."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est ici que s'affichent tous les commentaires qui vous sont donnés. Rien à montrer pour l'instant."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune évaluation reçue."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher uniquement les activités de commentaire"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est ici que s'affichent tous les commentaires que vous avez donnés. Commencez par la vue Conversations."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune évaluation donnée."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations données"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluations reçues"])}
  }
}