import { computed, reactive } from 'vue'
import { CollapsablePageNav } from '@/modules/shared/Navigation/components/page-nav/types'
import { updateUserSetting } from '@/api/user-settings'
import { session } from '@/composables/useSession'
import { screens } from '@/composables/useBreakpoints'

// We need to initialize the values in reactive({...}). Otherwise the reactivity is lost.
const pageNavState = reactive(
  Object.values(CollapsablePageNav).reduce(
    (prev, curr) => {
      const persisted = (session.user?.settings.subNavOpen || {})[curr]
      let open = persisted !== undefined ? persisted : true

      // Only initialize with persisted state if screen size is lg or above
      if (window.innerWidth < screens.lg) open = false

      return { ...prev, [curr]: open }
    },
    {} as Record<CollapsablePageNav, boolean>,
  ),
)

export const isPageNavOpen = computed(() => pageNavState)

export const togglePageNav = async (navType: CollapsablePageNav, state: boolean) => {
  pageNavState[navType] = state
  if (window.innerWidth < screens.lg) return

  await updateUserSetting('subNavOpen', {
    ...(session.user?.settings.subNavOpen || {}),
    [navType]: state,
  })
}
