import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_csat_donut_card = _resolveComponent("csat-donut-card")!
  const _component_csat_funnel_graph = _resolveComponent("csat-funnel-graph")!
  const _component_workspaces_timeline_card = _resolveComponent("workspaces-timeline-card")!
  const _component_timeline_card = _resolveComponent("timeline-card")!
  const _component_tags_card = _resolveComponent("tags-card")!
  const _component_word_cloud_card = _resolveComponent("word-cloud-card")!
  const _component_csat_by_users_card = _resolveComponent("csat-by-users-card")!
  const _component_csat_breakdown_card = _resolveComponent("csat-breakdown-card")!
  const _component_survey_replies_card = _resolveComponent("survey-replies-card")!
  const _component_conversation_list = _resolveComponent("conversation-list")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.root)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.dashboard)
    }, [
      (_ctx.viewSettings.cardsVisible.csat || _ctx.viewSettings.cardsVisible.surveyFunnel)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            (_ctx.viewSettings.cardsVisible.csat)
              ? (_openBlock(), _createBlock(_component_csat_donut_card, {
                  key: 0,
                  "filter-params": _ctx.filterParams,
                  class: _normalizeClass(_ctx.$style.donut),
                  onChangeTarget: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', { ..._ctx.modelValue, target: $event })))
                }, null, 8 /* PROPS */, ["filter-params", "class"]))
              : _createCommentVNode("v-if", true),
            (_ctx.viewSettings.cardsVisible.surveyFunnel)
              ? (_openBlock(), _createBlock(_component_csat_funnel_graph, {
                  key: 1,
                  "filter-params": _ctx.filterParams,
                  class: _normalizeClass(_ctx.$style.funnel)
                }, null, 8 /* PROPS */, ["filter-params", "class"]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.viewSettings.cardsVisible.csatOverTime)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            _createVNode(_component_workspaces_timeline_card, {
              class: _normalizeClass(_ctx.$style.workspacesTimeline),
              "filter-params": _ctx.filterParams
            }, null, 8 /* PROPS */, ["class", "filter-params"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.viewSettings.cardsVisible.csatTrendsBy)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            _createVNode(_component_timeline_card, {
              class: _normalizeClass(_ctx.$style.timeline),
              "filter-params": _ctx.filterParams
            }, null, 8 /* PROPS */, ["class", "filter-params"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.viewSettings.cardsVisible.tags || _ctx.viewSettings.cardsVisible.wordCloud)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            (_ctx.viewSettings.cardsVisible.tags)
              ? (_openBlock(), _createBlock(_component_tags_card, {
                  key: 0,
                  "filter-params": _ctx.filterParams
                }, null, 8 /* PROPS */, ["filter-params"]))
              : _createCommentVNode("v-if", true),
            (_ctx.viewSettings.cardsVisible.wordCloud)
              ? (_openBlock(), _createBlock(_component_word_cloud_card, {
                  key: 1,
                  "filter-params": _ctx.filterParams
                }, null, 8 /* PROPS */, ["filter-params"]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.viewSettings.cardsVisible.csatByUsers)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            _createVNode(_component_csat_by_users_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.viewSettings.cardsVisible.csatBy)
        ? (_openBlock(), _createElementBlock("div", {
            key: 5,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            _createVNode(_component_csat_breakdown_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.viewSettings.cardsVisible.surveyReplies)
        ? (_openBlock(), _createElementBlock("div", {
            key: 6,
            class: _normalizeClass(_ctx.$style.row)
          }, [
            _createVNode(_component_survey_replies_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _createVNode(_component_conversation_list, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"])
  ], 2 /* CLASS */))
}