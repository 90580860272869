import { type AgentColorMap } from '../composables/useTicketAgentColors'

interface ColorStyle {
  text: string
  activeText: string
  bg: string
  'border-40': string
  'border-50': string
  'border-60': string
}

export const TRANSCRIPTION_COLORS: AgentColorMap<ColorStyle> = {
  blue: {
    text: 'text-blue-50',
    activeText: 'text-blue-60',
    bg: 'bg-blue-50',
    'border-40': 'border-blue-40',
    'border-50': 'border-blue-50',
    'border-60': 'border-blue-60',
  },
  green: {
    text: 'text-green-50',
    activeText: 'text-green-60',
    bg: 'bg-green-50',
    'border-40': 'border-green-40',
    'border-50': 'border-green-50',
    'border-60': 'border-green-60',
  },
  purple: {
    text: 'text-purple-50',
    activeText: 'text-purple-60',
    bg: 'bg-purple-50',
    'border-40': 'border-purple-40',
    'border-50': 'border-purple-50',
    'border-60': 'border-purple-60',
  },
  teal: {
    text: 'text-teal-50',
    activeText: 'text-teal-60',
    bg: 'bg-teal-50',
    'border-40': 'border-teal-40',
    'border-50': 'border-teal-50',
    'border-60': 'border-teal-60',
  },
  orange: {
    text: 'text-orange-50',
    activeText: 'text-orange-60',
    bg: 'bg-orange-50',
    'border-40': 'border-orange-40',
    'border-50': 'border-orange-50',
    'border-60': 'border-orange-60',
  },
  pink: {
    text: 'text-pink-50',
    activeText: 'text-pink-60',
    bg: 'bg-pink-50',
    'border-40': 'border-pink-40',
    'border-50': 'border-pink-50',
    'border-60': 'border-pink-60',
  },
  red: {
    text: 'text-red-50',
    activeText: 'text-red-60',
    bg: 'bg-red-50',
    'border-40': 'border-red-40',
    'border-50': 'border-red-50',
    'border-60': 'border-red-60',
  },
} as const
