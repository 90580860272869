import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'

export default createSharedComposable(() => {
  const tagFilter = ref('')
  const showAddModal = ref(false)

  return {
    tagFilter,
    showAddModal,
  }
})
