import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reviewer_item = _resolveComponent("reviewer-item")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_percentage_cell = _resolveComponent("percentage-cell")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "csat-by-users-card",
    "data-rows-prop": "agentStatistics",
    "api-method": _ctx.getCsatByUsers,
    title: _ctx.$t('csat_dashboard.csat_by_users.title', [_ctx.questionTypeLabel]),
    params: _ctx.filterParams,
    onSortChanged: _ctx.handleSortChange
  }, {
    default: _withCtx(({ data, sortBy, setPage }) => [
      _createVNode(_component_data_grid, {
        data: _ctx.processData(data),
        "sort-by": sortBy,
        "inline-pagination": "",
        "set-page": setPage,
        "hide-total": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_data_grid_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "user",
                "identity-column": "",
                label: _ctx.COLUMN_LABELS_BY_ID.user,
                width: "200px",
                align: "left"
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_reviewer_item, { reviewer: value }, null, 8 /* PROPS */, ["reviewer"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_data_grid_section, {
            class: _normalizeClass(_ctx.$style.dataSection)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "ticketCount",
                "not-sortable": "",
                label: _ctx.COLUMN_LABELS_BY_ID.ticketCount
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "csatTicketCount",
                label: _ctx.$t('csat_dashboard.csat_by_users.csat_ticket_count', [_ctx.questionTypeLabel])
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "responseRate",
                label: _ctx.COLUMN_LABELS_BY_ID.responseRate
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    "no-background": "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "responseRateChange",
                label: _ctx.COLUMN_LABELS_BY_ID.responseRateChange
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    "no-background": "",
                    colorful: "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "csat",
                label: _ctx.questionTypeLabel
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    threshold: _ctx.csatTarget,
                    value: value
                  }, null, 8 /* PROPS */, ["threshold", "value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "csatChange",
                label: _ctx.COLUMN_LABELS_BY_ID.csatChange
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    "no-background": "",
                    colorful: "",
                    "show-sign": "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "sort-by", "set-page"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["api-method", "title", "params", "onSortChanged"]))
}