import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-base font-medium" }
const _hoisted_2 = { class: "text-gray-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-sm", _ctx.$style.wrapper])
  }, [
    _renderSlot(_ctx.$slots, "icon"),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.message), 1 /* TEXT */)
  ], 2 /* CLASS */))
}