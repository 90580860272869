import { computed, ref } from 'vue'

import type { ScorecardType } from '@/types/workspace'
import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import { groupCategories } from '@/modules/shared/Review/utils'

import { getWorkspaceSettings } from '../../api'
import type { GroupedCategory } from '../../api/types'
import * as api from '../../api/categories'

export const scorecardType = ref<ScorecardType | undefined>(undefined)
export const scorecards = ref<Scorecard[]>([])
export const categoryGroups = ref<api.RatingCategoryGroup[]>([])
export const categories = ref<api.Category[]>([])

export const groupedCategories = computed(
  () =>
    groupCategories(
      categories.value.filter((c) => !c.deletedAt) as any,
      categoryGroups.value,
      true,
    ) as any as GroupedCategory[],
)

export const activeCategories = computed(() =>
  categories.value
    .filter((c) => !c.isArchived && !c.deletedAt)
    .map((c) => ({
      ...c,
      categoryName: c.name,
      categoryId: c.id,
    })),
)

export const getCategories = async (workspaceId: number) => {
  const { data } = await api.getCategories(workspaceId)

  categories.value = data
}

export const getCategoryGroups = async (workspaceId: number) => {
  const { data } = await api.getCategoryGroups(workspaceId)

  categoryGroups.value = data
}

const getScoreCardType = async (workspaceId: number) => {
  const { scorecardType: newScorecardType } = await getWorkspaceSettings(workspaceId)

  scorecardType.value = newScorecardType
}

const getScorecards = async (workspaceId: number) => {
  const { data } = await api.getTeamScorecards(workspaceId)

  scorecards.value = data.map((s) => ({ ...s, id: s.id.toString() }))
}

export const refreshData = (workspaceId: number) => {
  getCategories(workspaceId)
  getCategoryGroups(workspaceId)
  getScoreCardType(workspaceId)
  getScorecards(workspaceId)
}
