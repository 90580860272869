import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { AutoQaSpotlightType } from '@/types/spotlight'

import { getAutoQaSpotlightSettings } from '../api'

export default createSharedComposable(() => {
  const { data, refetch } = useQuery({
    queryKey: ['auto-qa-spotlight-settings'],
    queryFn: () => getAutoQaSpotlightSettings(),
  })

  const CUSTOM_SPOTLIGHTS_LIMIT = 20

  const presetSpotlights = computed(() =>
    data.value?.spotlights.filter((s) => s.spotlight !== (AutoQaSpotlightType.CustomPhraseSearch as string)),
  )
  const customSpotlights = computed(() =>
    data.value?.spotlights.filter((s) => s.spotlight === (AutoQaSpotlightType.CustomPhraseSearch as string)),
  )

  const customSpotLightLimitReached = computed(() => customSpotlights.value?.length >= CUSTOM_SPOTLIGHTS_LIMIT)

  return {
    presetSpotlights,
    refetch,
    customSpotLightLimitReached,
    customSpotlights,
    CUSTOM_SPOTLIGHTS_LIMIT,
  }
})
