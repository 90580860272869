export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사의 Zendesk 하위 도메인을 입력합니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk에 로그인"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사에 기존 계정이 있습니다."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 계정으로 가입"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 회사 계정으로 가입하려면 관리자에게 초대를 보내 달라고 요청하세요."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자에게 초대를 요청하세요."])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마음이 바뀌었습니다."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입 취소"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사에 기존 계정이 있습니다. 회사 계정으로 가입하시겠어요, 아니면 새 계정을 만드시겠어요?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지침 복사"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 계정 만들기"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 계정이 있음"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미결제 플랜 청구서가 결제될 때까지는 Zendesk QA 계정에 액세스할 수 없습니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜이 보류 상태입니다"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 찾을 수 없습니다. 다시 시도하세요."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호를 받으세요."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["다시 시도하거나 ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일과 비밀번호 조합이 잘못되었습니다."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자에게 새 초대를 요청하세요."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 초대 링크는 유효하지 않거나 이미 사용되었습니다."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " 로그인 방법이 계정에서 비활성화됩니다."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 초대는 올바르지 않거나 사용 중입니다. 관리자에게 새 초대를 요청하세요."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대 정보를 로드하지 못했습니다."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입하지 못했습니다. 이 이메일을 가진 사용자가 이미 있을 수 있습니다."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러 번 연속으로 로그인을 시도하여 계정이 차단되었습니다. 받은 편지함에서 차단 해제 방법에 대한 지침이 담긴 이메일을 확인하세요."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["먼저 이메일을 확인하세요."])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["올바른 이메일 계정을 입력하세요."])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 반드시 입력해야 합니다."])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 반드시 입력해야 합니다."])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재설정"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 코드"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크 보내기"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업무용 이메일"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업무용 이메일"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.kr/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주요 서비스 계약"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 요구 사항과 일치하지 않습니다."])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호 고지"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.kr/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["안녕하세요, ", _interpolate(_named("invitee_name")), "님."])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("organization")), " 조직에 가입"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 확인되었습니다. 이제 아래에서 새 계정으로 로그인하실 수 있습니다."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google로 계속"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 초대 링크"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크를 사용하여 로그인"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인으로 돌아가기"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack으로 계속"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk로 계속"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 확인되었습니다. 이제 아래에서 새 계정으로 로그인하실 수 있습니다."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google로 로그인"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크를 통해 로그인"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack으로 로그인"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에 로그인"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 삭제되었습니다."])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 없으신가요?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["올바른 코드를 입력하세요."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 반드시 입력해야 합니다."])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["올바른 이메일 계정을 입력하세요."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 반드시 입력해야 합니다."])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 코드"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 로그인 옵션 보기"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["기존 방법을 통해 ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크를 사용하여 로그인"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 코드가 포함된 이메일이 발송되었습니다."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인할 때 사용할 수 있는 이메일이 발송되었습니다."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크를 보내는 중 오류가 발생했습니다. 다시 시도하세요."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 통해 로그인"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 만들기"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소문자 1개"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대문자 1개"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호는 다음을 포함해야 합니다."])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 비밀번호 길이는 128자입니다."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기억하기 쉽고 추측하기 어려움"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 8자"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 만들기"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 잊으셨나요?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나의 소문자"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나의 숫자"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나의 대문자"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 재설정하려면 이메일 주소를 입력하세요. 그러면 안내 이메일을 보내드립니다."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["올바른 이메일 계정을 입력하세요."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 반드시 입력해야 합니다."])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "(으)로 이메일을 보냈습니다."])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 받은 편지함이나 스팸 폴더에 나타나지 않으면 입력한 이메일을 확인하세요."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인으로 돌아가기"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 확인하세요."])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), " 비밀번호 재설정 이메일을 받게 됩니다."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정에 기존 비밀번호가 있는 경우"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["해당 ", _interpolate(_named("bold")), " 경우 비밀번호 재설정 링크를 보내드립니다."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 계정과 연결된"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 기억하셨나요?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재설정 링크를 보내는 중 오류가 발생했습니다. 다시 시도하세요."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크를 사용하여 가입"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호방침"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["가입하면 Zendesk QA ", _interpolate(_named("terms_of_service")), " 및 ", _interpolate(_named("privacy_policy")), "에 동의하는 것입니다."])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 약관"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 있나요?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크를 통해 가입"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업무용 이메일로 가입"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack으로 가입"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에 가입"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google로 가입"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 Zendesk 계정이 있습니다."])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["가입하거나 로그인하여 ", _interpolate(_named("direct_install_name")), " 설정을 완료하세요"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인이 맞나요? 받은 편지함에서 확인 이메일을 확인하세요."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 설정 완료됨"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA는 데스크톱에서 가장 잘 작동합니다. 대화 검토를 시작하려면 데스크톱에서 로그인하세요."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 예약"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 데모를 예약하면 Zendesk QA에서 무엇을 할 수 있는지 보여드리겠습니다."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여가 요청됨"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["마음이 바뀌어 ", _interpolate(_named("cancel")), "하고 싶습니다."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), "님이 해당 계정에 가입하도록 초대합니다."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정에 가입"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 계정 만들기"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 로그인해 보세요."])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["피드백에 대해 받은 반응: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 반응"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), "님이 내 댓글에 반응함"]), _normalize(["여러 명이 내 댓글에 반응함"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), "님이 내 답장에 반응함"]), _normalize(["여러 명이 내 답장에 반응함"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " 님이 내 검토에 반응함"]), _normalize(["여러 명이 내 검토에 반응함"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답장"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공됨"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 피드백"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반응"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작됨"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["댓글을 읽음으로 표시하시겠어요?"]), _normalize(["모든 댓글을 읽음으로 표시하시겠어요?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["이의 신청을 읽음으로 표시하시겠어요?"]), _normalize(["모든 이의 신청을 읽음으로 표시하시겠어요?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["설문조사 피드백을 읽음으로 표시하시겠어요?"]), _normalize(["모든 설문조사 피드백을 읽음으로 표시하시겠어요?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토를 읽음으로 표시하시겠어요?"]), _normalize(["모든 검토를 읽음으로 표시하시겠어요?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["댓글을 읽음으로 표시함"]), _normalize(["모든 댓글을 읽음으로 표시함"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["이의 신청을 읽음으로 표시함"]), _normalize(["모든 이의 신청을 읽음으로 표시함"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["설문조사 피드백을 읽음으로 표시함"]), _normalize(["모든 설문조사 피드백을 읽음으로 표시함"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토를 읽음으로 표시함"]), _normalize(["모든 검토를 읽음으로 표시함"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 읽음으로 표시"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["읽음으로 표시"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 수"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 사람이 검토에 이의를 신청하면 여기에 나타납니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 없음"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결해야 하는 이의 신청이 배정되면 여기에 나타납니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 이의 신청 없음"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토에 이의를 신청하면 여기에 나타납니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작한 이의 신청 없음"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 모든 피드백이 여기에 나타납니다. 아직 표시할 내용이 없습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 피드백 없음"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 및 답장에 대한 모든 반응이 여기에 나타납니다. 아직 표시할 내용이 없습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 반응 없음"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 열기"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 제공한 모든 피드백이 여기에 표시됩니다. 대화 보기에서 먼저 검토를 남기세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공한 검토 없음"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 모든 피드백이 여기에 나타납니다. 아직 표시할 내용이 없습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토 없음"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글을 찾을 수 없음"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글로 남기는 모든 피드백이 여기에 나타납니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공한 댓글 없음"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글을 찾을 수 없음"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 아래의 모든 댓글, ", "@", "멘션 또는 댓글에 대한 답장이 여기에 나타납니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 댓글 없음"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락된 이의 신청 없음"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 이의 신청 없음"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부된 이의 신청 없음"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결된 이의 신청 없음"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위의 필터에서 다른 옵션을 선택하여 다시 시도하세요."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위 필터에서 다른 사용자를 선택하여 다시 시도하세요."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공한 댓글"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 댓글"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 이의 신청"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 이의 신청"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작한 이의 신청"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 설문조사 피드백"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공한 검토"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동 목록 토글"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "개 검토됨"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "개 검토됨"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "개 검토됨"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 후 다시 시작"])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 남음"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 설정"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 목록 토글"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 및 ", _interpolate(_list(1)), " 사이에 코칭 세션을 만들 수 없습니다. 현재 동일한 워크스페이스에 있지 않기 때문입니다."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 및 ", _interpolate(_list(1)), " 사이에 코칭 세션이 이미 있습니다. 세션에 액세스하려면 ", _interpolate(_list(0)), "에게 문의하세요."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 및 ", _interpolate(_list(1)), " 사이에 새 세션이 만들어졌습니다."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코치"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코치 선택"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 대상자"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 대상자 선택"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 만들기"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 만들기"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부 정보"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 추가"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 이름"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기한 지남"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예정"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 만들기"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션을 찾을 수 없음"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 검토 시간"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 기간 동안 표시할 데이터 없음"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["세션에 핀이 추가되었습니다. ", _interpolate(_named("buttonStart")), "표시", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음에 추가..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가됨"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 기간의 마지막 핀에 도달했습니다."])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), "에서 핀의 작동 방식을 확인하세요."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 아래에 고정된 것이 없는 것 같습니다. 핀을 사용하여 1:1 상담을 준비하거나 다른 시간을 위해 저장할 수 있습니다."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 대상자"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 추가된 핀 없음"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["다음은 ", _interpolate(_named("name")), " 아래에 만든 모든 핀입니다. 세션에 추가된 핀은 다른 참여자와 ", _interpolate(_named("bold")), "됩니다."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로 공유됨"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "이(가) 선택한 기간 동안 대화 검토 또는 CSAT에 대한 피드백을 받지 못한 것 같습니다."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 없음"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 기간의 마지막 댓글에 도달했습니다."])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메트릭"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님의 점수"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 작업 항목"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "에 ", _interpolate(_named("user")), "님이 완료함"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["작성자: ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 작업 항목을 추가하려면 입력하세요."])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 회의에서 무엇을 얻었나요? 다음 단계는 무엇인가요?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 항목"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), "에 ", _interpolate(_named("name")), "님이 만듦"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 삭제"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["영구적으로 ", _interpolate(_named("deleteSession")), "됩니다. 참여자는 콘텐츠 및 비공개 메모를 포함하여 세션에 액세스할 수 없게 됩니다."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["세션을 만든 날짜: ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("updatedDate")), "에 ", _interpolate(_named("name")), "님이 마지막으로 편집함"])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요점 및 메모"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모를 업데이트함"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션에서 나가기"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 사용자는 이 시점부터 세션 세부 정보에 액세스할 수 있습니다."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 선택"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유됨"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 공유"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["사용자는 더 이상 ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " 세션의 일부가 아닙니다."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님은 더 이상 이 세션의 일부가 아닙니다."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), "님이 이제 이 세션에 액세스할 수 있습니다."]), _normalize(["신규 사용자 ", _interpolate(_named("n")), "명이 이제 이 세션에 액세스할 수 있습니다."])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 삭제"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 세션 삭제"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 삭제"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코치"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님이 추가한 메모가 여기에 나타납니다."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오른쪽 패널에서 고정된 항목을 토론 포인트로 추가"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나만 볼 수 있는 메모..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 메모"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개 메모 업데이트됨"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30일"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코치"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 대상자"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 항목"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 세션"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기한 지남"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 개요"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 보기"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나: 코치"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나: 코칭 대상자"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 세션"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유 세션"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 항목을 보려면 필터를 전환해 보세요."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 항목 없음"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 항목"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받고 확인된 검토"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음 · 확인됨"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 후 진행 상황"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 피드백을 받지 못한 것 같습니다."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자의 이름이 올바른지 확인하세요."])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사용자를 찾을 수 없음"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 수 있는 데이터 없음"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일 IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이렇게 하면 <b>", _interpolate(_named("name")), "</b>의 <b>모든 세션</b>(2)이 영구적으로 삭제됩니다. 참여자는 콘텐츠 및 비공개 메모를 포함한 모든 세션에 액세스할 수 없게 됩니다."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 세션의 끝에 도달했습니다."])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 세션은 멘토와 코칭 대상자 간의 그룹 활동으로 성과, 기회, 장단기 목표에 대해 논의합니다."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코치 및 코칭 대상자의 이름으로 세션을 검색할 수 있습니다."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션을 찾을 수 없음"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 만든 세션 없음"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 기록 삭제"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 기록 삭제 "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 세션"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 세션"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코치"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 대상자"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료로 표시"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미완료로 표시"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름으로 검색"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름 바꾸기"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 목록 토글"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스케줄되지 않음"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 메시지에 답장"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 세션이 만료되었습니다. 더 이상 검토를 남길 수 없습니다."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 이미 검토를 남겼습니다."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션의 기한이 지났으므로 더 이상 이 대화를 검토할 수 없습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 검토 없음"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창 닫기"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 검토 남기기"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부 정보"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요약"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내용"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["스피커 ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "초"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침묵"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자 편집"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 검토"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 재생으로 돌아가기"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발표자"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내용"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 사용자"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터를 만들려면 먼저 연결을 추가하세요."])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 없음"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "님이 ", _interpolate(_named("time")), "에 만듦"])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "님이 ", _interpolate(_named("time")), "에 마지막으로 업데이트함"])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["필터가 없는 것 같습니다. 이 페이지에 액세스하려면 먼저 필터를 만들어야 합니다. 대화를 보려면 ", _interpolate(_named("recommendedFilter")), "(으)로 시작하세요. 계속하려면 아래에서 이 필터를 만드세요."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일 동안"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 범위 없이 만든 필터에 '지난 30일 내 작성됨' 조건이 추가됩니다."])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 플랜에서는 필터를 사용할 수 없으므로 결과를 표시할 때 필터가 고려되지 않습니다."])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 옵션을 가져오는 중 시간이 초과되었습니다. 다시 시도하세요."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터링에 대해 알아보기"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자 = 로그인한 사람"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["찾은 대화: ", _interpolate(_named("count"))]), _normalize(["찾은 대화: ", _interpolate(_named("count"))]), _normalize(["찾은 대화: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 플랜에서는 사용할 수 없습니다. 이 기능을 사용하려면 업그레이드하세요."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건 추가"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI 제공"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제로 전환"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 만들기"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 만들기"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 필드"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특정 설정에 따라 필터 옵션을 로드하고 있습니다. 초기 로드 시 몇 분 정도 걸릴 수 있습니다."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터에 설명하는 이름을 지정하세요."])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 이름"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 선택"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 편집"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 저장"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 수 있는 값 없음"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로드 중..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾을 수 없음"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값을 선택하세요."])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시 대상"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 세션만 ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 세션 표시 ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기한"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 세션 편집"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 만들기"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 세션 만들기"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 중 제공된 모든 조정 검토가 영구 삭제됩니다."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 삭제"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), " 조정 세션 삭제"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 세션 없음"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 세션 없음"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 세션 없음"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 세션 없음"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 추가"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개 필터 만들기"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 필터 만들기"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 추가됨"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복제"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터가 영구 삭제됩니다."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 삭제"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " 필터 삭제"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 이의 신청"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나에 대한 이의 신청"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 등록한 이의 신청"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 등록"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 필터 없음"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 비공개 필터 없음"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 공개 필터 없음"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개 필터"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 필터"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cta")), "하시겠습니까?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임시 필터의 보기입니다."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 구성 중..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 업데이트됨"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 자동 QA 카테고리"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["마지막 업데이트: ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복잡한 단어"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 검토는 티켓 변경에 따라 동적으로 업데이트됩니다."])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에서 강조 표시"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴 문장"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예측된 자동 점수 없음"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류를 감지할 수 없음"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복잡한 단어 표시"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴 문장 표시"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 대화"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨텍스트가 충분하지 않아 자동 QA에서 이 카테고리를 평가할 수 없습니다. 직접 검토할 것을 권장합니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동화된 대화 인사이트를 발견하여 검토 프로세스를 간소화하세요. 중요한 대화를 강조 표시하고 팀을 위해 눈에 띄는 긍정적 사례를 보여줍니다."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[통화]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정됨"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제됨"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "님이 이의 신청을 수락했습니다"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "님에게 이의 신청을 보냄"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 검토 대상자가 이 피드백의 잘못된 수신자라고 생각하는 이유를 설명하세요."])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글을 추가하고 새 검토 대상자 설정"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 추가"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이러한 점수가 부당하게 평가되었다고 생각하는 이유를 설명하세요(필수)."])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 추가(필수)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 이의 신청을 거부하는 이유를 설명하세요."])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 추가"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 대상"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 대상"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 대상"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 검토 대상자 선택"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 피드백"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 피드백"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자 변경"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 대상"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 검토 이의 신청"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수에 동의하지 않음"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나에 대한 검토가 아님"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 선택"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "님이 이의 신청을 부분적으로 수락함"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), "님이 이의 신청을 거부함"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락됨"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부분 수락됨"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부됨"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록됨"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "님이 이의 신청을 시작함: 검토"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "님이 이의 신청을 시작함: 잘못된 검토 대상자"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토에 대해 다른 조치를 취하려면 등록된 이의 신청을 해결해야 합니다."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 점수 선택"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 삭제"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 이의 신청을 버리시겠습니까?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 삭제"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 해결 시간"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 공정한 점수 선택(선택 사항)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 검토"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 검토 대상자"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 수락"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 수락"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 피드백"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 검토 대상자"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 피드백"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 거부"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 거부"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 보기"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집됨"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), ", 작성자: ", _interpolate(_named("author")), "(", _interpolate(_named("date")), ")"])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["받는 사람: ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토됨"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 완료"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배정 대상:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글도 남기기"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부 스코어카드에 대해 알아보기"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 배정 대상:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나만 선택"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 이름의 GIF를 찾을 수 없습니다."])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 먼저 검토를 남기세요."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 플랜이 일시 중지된 상태입니다. 피드백을 제공하려면 다시 활성화하세요."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시자:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화를 검토하는 데 얼마나 많은 시간이 소모되었는지 보여줍니다."])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 선택"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "\" 카테고리에 대한 근본 원인 누락"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 평가 또는 댓글 추가"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이모지 추가"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["축소"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 필드 확장"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 필드 축소"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서식 숨기기"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창 숨기기"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서식 표시"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창 표시"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 제출"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리 평가"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 카테고리 평가"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중을 위해 이 대화 저장"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중을 위해 저장"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 과제"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 과제 주기에서 대화 바꾸기"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 검토"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준 검토"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 삭제"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 삭제됨"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 댓글을 삭제하시겠어요?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 작성자"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 해시태그는 삭제되었거나 이름이 변경되었습니다."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마음에 드는 점이나 개선할 점을 공유해 주세요..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 남기기"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 댓글이 영구 삭제됩니다."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 삭제"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[메시지]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님은 아직 이 피드백을 보지 못했습니다."])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님이 이 피드백을 보았습니다."])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 구성원"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF 검색..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 키를 눌러 새 해시태그를 만듭니다."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 해시태그를 관리자가 잠갔습니다."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기능을 사용하여 사례를 북마크하고 나중에 필터링하세요."])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준으로 표시"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 피드백"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 대화"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 없음"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료된 검토 없음"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작하려면 아래에 검토를 남기세요."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남은 검토 없음"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가되지 않음"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 담당자"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 대화"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준으로 제거"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답장"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 검토에 답장"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 사이드바 숨기기"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 및 추가된 모든 댓글이 영구 삭제됩니다."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 삭제"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 삭제"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 삭제됨"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님이 검토를 삭제함"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 완료"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 남기려면 이 작업을 본인에게 재배정하세요."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 사용자에 대한 검토를 이미 남겼습니다."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "에 대한 검토를 남겼습니다."])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 표시할 카테고리가 없습니다."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스에서는 자가 검토를 사용할 수 없습니다."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장하지 않음"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 검토를 초안으로 저장하시겠어요?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 초안 버리기"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버리기"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 사이드바 표시"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불합격"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사소한 오류와 함께 합격"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 숫자를 계산하는 중..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 남기기"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 메시지 검토"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스스로 검토"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화 검토"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성자"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토할 사용자 없음"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토 사용 중지됨"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 찾을 수 없음"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 사용자"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로드 중..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 선택"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["검토 1개"]), _normalize(["검토 ", _interpolate(_named("count")), "개"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 평점 선택"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 관리자가 자가 검토를 허용하지 않았습니다."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 핀에 태그를 지정하려면 Enter 키를 누르세요."])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별표 표시됨"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 세션 만들기"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트는 헬프 데스크 데이터를 시각적으로 보여줍니다. 대화형 차트를 사용하여 어느 부분에 QA 노력을 집중해야 할지 이해하고, 사각 지대를 찾아내며, 성과에 따라 검토할 올바른 상담사를 찾으세요."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("language")), "(으)로 번역"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음에 추가:"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미배정"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 보는 중"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 검토 중"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화는 제거되었으므로 더 이상 사용할 수 없습니다."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾고 계신 이 대화는 Zendesk QA에 존재하지 않습니다."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화가 있는 모든 고정"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 중"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 제거"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션에서 대화를 제거하면 해당 대화에 제공된 모든 검토가 삭제됩니다."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), "에서 대화 제거"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 만든 조정 세션이 없습니다."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화가 세션에 추가되었습니다."])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화가 세션에서 제거되었습니다."])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 세션에 추가/제거"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화의 링크 복사"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 대화 없음"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객의 이메일"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객의 ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객의 이름"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 양식 이름"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT(첫 번째 응답 대기 시간)"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫 번째 응답 대기 시간"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우선 순위"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 댓글"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만족도 점수"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR(첫 번째 해결까지 걸린 시간)"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫 번째 해결까지 걸린 시간"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원본"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로드 중..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화 아래에 존재하지 않거나 삭제된 메시지입니다."])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 없음"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 대화 없음"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 찾을 수 없음"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원래 대화 열기"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 연결할 수 없음 - URL 누락"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자 없음"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 고정"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 읽으려면 클릭하세요."])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토하려면 클릭하세요."])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브라우저 확장 기능을 통해 만든 대화"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 만족도 설문조사 보기"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 보기"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적 감성의 메시지"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적 감성의 메시지"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어로 번역"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원본 표시"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 삭제"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["대화 삭제는 영구적입니다. 또한 대시보드를 포함하여 Zendesk QA에서 설문조사, 대화 내용 및 통화 내용, 검토 등 모든 관련 데이터를 삭제합니다.", _interpolate(_named("lineBreak")), "Zendesk Support 또는 사용 중인 다른 헬프 데스크 소프트웨어에서 이 대화에 계속 액세스할 수 있습니다."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["대화 삭제는 영구적입니다. 또한 대시보드를 포함하여 Zendesk QA에서 설문조사, 대화 내용, 검토 등 모든 관련 데이터를 삭제합니다. 대화가 여러 워크스페이스에 속한 경우에는 모든 워크스페이스에서 삭제됩니다.", _interpolate(_named("lineBreak")), "Zendesk Support 또는 사용 중인 다른 헬프 데스크 소프트웨어에서 이 대화에 계속 액세스할 수 있습니다."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 삭제"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에서 대화 삭제"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["에스컬레이션으로 인해 이 대화가 완료되지 않았습니다. ", _interpolate(_named("optionsUrl")), "으로 이동하고 태그를 확인하세요. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["에스컬레이션으로 인해 이 대화가 완료되지 않았습니다. 태그 유효성 검사에 대해 관리자에게 문의하세요. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 > 고급 옵션"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그에 대해 알아보기"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료됨"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록됨"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결됨"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["담당자가 ", _interpolate(_named("target")), "님으로 변경됨"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미배정"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), "님이 ", _interpolate(_named("target")), " 매크로를 적용함"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["대화가 ", _interpolate(_named("target")), " 평점을 받음"])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " 사용자가 대화를 삭제함"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["상태가 다음으로 변경됨: ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), "(으)로 태그 지정"])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), "(으)로 팀 변경됨"])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), "님이 ", _interpolate(_named("target")), "에 대한 대화를 스누즈함"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), "님이 대화의 스누즈를 해제함"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇이 루프에 빠져 메시지를 계속 반복합니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 반복"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 메시지가 연속해서 한 번 이상 반복되는 봇 반복"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 문구를 찾았습니다."])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평균 상담사가 대화를 봇보다 ", _interpolate(_named("percent")), " 더 효율적으로 처리합니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 커뮤니케이션 효율성"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["봇이 대화를 평균 상담사보다 ", _interpolate(_named("percent")), " 더 효율적으로 처리했습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 커뮤니케이션 효율성"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 규정 준수 및 투명성을 위해 필요한 녹음을 참여자에게 알리는 필수 설명이 부족함"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["녹음 고지 누락"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "에 대한 서비스 수준 계약(SLA)을 위반했습니다."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA 위반함"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA 규칙에 대한 메트릭이 목표 시간을 초과함"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 감소의 징후입니다. 고객이 전환을 고려 중이거나 이탈 가능성이 있습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈 위험 식별됨"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["대화에 설정된 임계값인 ", _interpolate(_list(0)), "초보다 오래 지속되는 침묵의 순간이 있습니다."])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["인스턴스 1개"]), _normalize(["인스턴스 ", _interpolate(_named("count")), "개"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["최대 ", _interpolate(_list(0)), "초"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침묵"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 상위 수준의 지원을 요청함"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에스컬레이션 요청됨"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 찬사를 표했거나 받은 지원에 매우 만족함"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탁월한 서비스"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이나 상담사가 명시적으로 후속 작업을 요청함"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후속 작업 요청"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 불만족을 보임"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적 감성"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 비정상적이거나 비정형적이었고 해결하는 데 더 많은 시간이 걸림"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상값 감지됨"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 만족함"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적 감성"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 없음으로 설정"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "에 ", _interpolate(_named("author")), "님이 마지막으로 편집함"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["작성자: ", _interpolate(_named("author")), "(", _interpolate(_named("date")), ")"])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유 추가(선택 사항)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발견된 인사이트 없음"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 추가"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크를 계정에 연결하고 대화 목록을 볼 수 있도록 최소한 하나의 필터를 만드세요."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 대화 없음"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 설정 보기"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만듦"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 업데이트"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1일 후)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(이번 달)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(이번 주)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID로 찾기"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("searchQuery")), "에 대한 검색 결과"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 사람들이 검토함"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글을 남김"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신순"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오래된 순"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무작위"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 업데이트됨"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 답장함"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정렬 기준"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["읽지 않음"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 검토함"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가져오기 프로세스에 다소 시간이 걸릴 수 있습니다. 완료되면 이메일로 알려 드리겠습니다."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에서 대화를 가져오는 중"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["한도: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 보기의 헤더에 있는 조정 아이콘을 클릭하여 추가합니다."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에 보고할 내용이 없습니다. 다른 필터나 정렬 옵션을 사용하여 다시 시도하세요."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 이상값이 없습니다."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 대화를 찾아 헤더에 있는 별표 아이콘을 클릭하여 여기에 북마크하세요."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터를 변경하거나 다른 헬프 데스크 상담사를 초대하여 대화를 더 추가해 보세요."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제한 없음"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내부 메모"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 로드하는 중입니다. 일부 콘텐츠가 여전히 누락되었을 수 있습니다."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 사용자"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 찾을 수 없음"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 구성원"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 사용자"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 대화 없음"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 대화가 없음"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료되지 않은 검토는 여기 표시됩니다. 30일 이내에 다시 읽고 수정한 후 제출하세요."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 초안 없음"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별표 표시된 대화 없음"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 대화 없음"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치 항목 없음"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 메시지로 돌아가기"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 답장"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 답장"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 목록 토글"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 사용자"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "에 통화에 응답함"])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "에 통화 종료됨"])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), "에 전화함"])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토됨"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 검토함"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["오디오 파일을 로드하지 못했습니다. ", _interpolate(_list(0)), "에 로그인되어 있는지 확인하세요."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 만족도 설문조사 댓글"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오디오 로드 중 오류"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 작성자"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화를 걸 수 없음"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크에서 듣기"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aircall에서 듣기"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재생 속도"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 검토"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시스템"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내용"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["높음"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["낮음"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중간"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 사용자가 아님"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA는 월 평균 CSAT를 기준으로 자동으로 상담사의 순위를 매깁니다. 클릭 한 번으로 다른 상담사보다 CSAT가 낮은 상담사가 처리한 대화를 찾을 수 있습니다."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT별 순위 지정"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중간"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["높음"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["낮음"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최저"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최고"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 사용자가 아님"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티켓 하나"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["볼륨"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA는 한 달에 처리하는 대화 수에 따라 자동으로 상담사를 성과 그룹으로 분류합니다. 클릭 한 번으로 다른 상담사보다 볼륨이 적은 상담사가 처리한 대화를 찾을 수 있습니다."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["볼륨별 순위 지정"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토됨"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토되지 않음"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 상태"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["낮음"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["높음"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중립"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 사용자가 아님"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA는 대화의 평균 감성에 따라 자동으로 상담사의 순위를 매깁니다. 클릭 한 번으로 동료보다 더 많은 불만 고객을 처리한 상담사의 상호작용을 찾으세요."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성별 순위 지정"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감지되지 않음"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감지됨"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상값"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주의가 필요한 대화입니다. 머신 러닝 모델이 추가 검토를 위해 복잡하거나 비정형적인 상호작용을 식별합니다."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상값"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 만족 또는 불만을 표시한 경우를 검토하기 위한 대화를 선택합니다. 드롭다운을 사용하여 대화에서 찾고 있는 감성 유형을 선택합니다. 필터 속성을 맨 위로 드래그하여 모든 상호작용에서 선택한 감성의 비율을 봅니다."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 상태"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토되지 않음"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토됨"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA는 대화에서 언어를 자동으로 감지합니다. 드롭다운을 사용하여 검토할 특정 언어로 된 대화를 선택합니다. 필터 속성을 맨 위로 드래그하면 헬프 데스크에서 사용되는 모든 언어의 개요를 볼 수 있습니다."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 인사이트"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["인사이트를 사용하면 일반 대화 필터보다 ", _interpolate(_named("paragraph_2_item")), "할 수 있습니다. 헬프 데스크의 모든 데이터에 대한 시각적 개요를 확인하고 다음과 같은 인사이트를 얻으세요."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 인사이트 사용에 대해 알아보기"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 사람들과 관련하여 특정 대화 속성의 상대적 중요성 이해"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본으로 제공되는 상담사 성과 순위로 최고 성과 및 저성과 상담사 찾기"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), "는 가장 검토가 필요한 대화를 찾는 데 도움이 되는 강력한 대화형 데이터 마이닝 도구입니다."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["훨씬 더 많은 작업을 수행"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["대화 및 상담사 차트가 ", _interpolate(_named("paragraph_3_item_1")), " - 필터를 업데이트하거나 둘 중 하나에서 세분화된 차트 조각을 클릭하면 대화 목록이 자동으로 업데이트됩니다. ", _interpolate(_named("paragraph_3_item_2")), "하여 다양한 요소의 자세한 분류나 비율을 살펴보세요."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상호 연결됨"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 속성을 다시 정렬"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["대화 인사이트는 ", _interpolate(_named("paragraph_4_item")), "만 표시합니다. 자동 생성된 메시지, 스팸, 봇 답장 또는 지원 담당자와 적절한 대화가 이루어지지 않은 메시지 등 실제 검토 가치가 없는 상호작용을 자동으로 제거합니다."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정리 및 종료된 대화"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에 무엇이 표시되나요?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "/", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가져오지 못함"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 해당 사항 없음"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), "/총계)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 로드"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하지만 좋은 점수가 기대됩니다."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 설문조사"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 피드백 없음"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 사용할 수 있는 설문조사 피드백 점수가 없습니다..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평점"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 태그"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 댓글 태그"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 범위 필터 적용됨"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["답장 ", _interpolate(_named("count")), "개"]), _normalize(["답장 ", _interpolate(_named("count")), "개"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["설문조사 설정에서 이유를 설정하여 ", _interpolate(_list(0)), " 점수를 받은 이유를 자세히 알아보세요."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["아직 ", _interpolate(_list(0)), " 이유 없음"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 이유에 대해 알아보기"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그가 있는 대화:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0))])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 카테고리"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이유 ", _interpolate(_named("surveyType")), "개"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["예측 ", _interpolate(_named("surveyType")), " 동인"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 소스"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["설문조사 응답(", _interpolate(_named("n")), ")"]), _normalize(["설문조사 응답(", _interpolate(_named("n")), ")"]), _normalize(["설문조사 응답(", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 없음"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "(이)가 있는 대화"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["사용자별 ", _interpolate(_list(0))])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답률"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 점수는 모든 응답의 합을 최대 점수의 합으로 나눈 값입니다."])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상 편집"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상 제거"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상 설정"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상 추가"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["짧음"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매우 긺"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긺"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중간 길이"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["혼동"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유용하지 않음"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불량 지원"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["훌륭한 지원"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["풍부한 감성"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사를 위한 피드백"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불만 사항"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미분류"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문제가 해결되지 않음"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문제 해결됨"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적 감성"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나쁜 결과"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적 감성"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["칭찬"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 제품"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환불"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빠른 지원"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["느린 지원"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사합니다."])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA는 ", _interpolate(_list(0)), " 데이터를 미리 정의된 카테고리로 자동 배정합니다. 따라서 서면 피드백을 일일이 살펴보지 않고도 복잡한 인사이트를 이해하고 원인을 파악할 수 있습니다."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 점수"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 설문조사"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 언어 선택"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 크기"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 크기 선택"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글의 25%가 한 단어로 구성되어 있으므로 긴 댓글은 반드시 읽어야 합니다. 자동으로 처리되므로 수동 작업을 하지 않고도 더욱 유용한 피드백을 받을 수 있습니다."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 이유"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 이유 선택"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 점수 없음"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예측 동인"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 카테고리 선택"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 구름 단어"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 구름 단어 선택"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["지난 기간 이후 ", _interpolate(_list(0))])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "개 대화"]), _normalize([_interpolate(_named("n")), "개 대화"]), _normalize([_interpolate(_named("n")), "개 대화"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 부족으로 인해 모든 대화의 설문조사 전송 비율을 계산할 수 없습니다."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸 설문조사"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 깔때기"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["예측 ", _interpolate(_named("surveyType")), " 동인"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA는 ", _interpolate(_list(0)), " 데이터를 미리 정의된 카테고리로 자동 배정합니다. 따라서 서면 피드백을 일일이 살펴보지 않고도 복잡한 인사이트를 이해하고 원인을 파악할 수 있습니다."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 크기"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글의 25%가 한 단어로 구성되어 있으므로 긴 댓글은 반드시 읽어야 합니다. 자동으로 처리되므로 수동 작업을 하지 않고도 더욱 유용한 피드백을 받을 수 있습니다."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 응답"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "(평균)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 수"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS(평균)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 추세 기준:"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 수"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동인"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 설문조사 보낸 날짜"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 설문조사 응답 날짜"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 점수를 다음으로 배정"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 표시 기준:"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 작성일"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티켓 소유자"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 많은 답장을 받은 상담사"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "(평균)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["시간 흐름에 따른 ", _interpolate(_list(0))])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 수"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 및 IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 수"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 단어를 포함하는 대화:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 구름"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자별 CSAT"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 개요"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 깔때기"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 응답"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따른 CSAT"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 구름"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 없음"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스 없음"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 없음"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유 없음"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 없음"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 카테고리 없음"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 없음"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배정이 만들어지지 않았거나 주기가 시작되지 않았으므로 사용할 수 있는 데이터가 없습니다. 나중에 다시 확인하거나 필터를 조정하세요."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 소식 살펴보기"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["항목 ", _interpolate(_named("count")), "개"]), _normalize(["항목 ", _interpolate(_named("count")), "개"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["대화 ", _interpolate(_named("count")), "개"]), _normalize(["대화 ", _interpolate(_named("count")), "개"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불합격"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사소한 오류와 함께 합격"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격/불합격"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균(시간)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균(분)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평점 ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 CSAT = [피드백 점수의 합 ÷ (모든 응답의 수 × 가능한 최대 점수)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA CSAT 점수는 피드백 점수를 0~100% 범위 내에서 정규화하고 표준화된 점수의 평균을 구합니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT(고객 만족도 점수)는 고객이 서비스에 얼마나 만족하는지 수치화합니다."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 각 검토는 하나의 대화에 대해 한 사람이 수행한 검토를 나타냅니다. 대화에 두 개 이상의 검토가 있을 수 있습니다(두 명 이상의 검토자가 동일한 대화를 검토하는 경우)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따른 검토자의 점수 변화를 추적하세요."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따른 상담사 점수의 변화를 추적하세요."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["응답 ", _interpolate(_named("n")), "개"]), _normalize(["응답 ", _interpolate(_named("n")), "개"]), _normalize(["응답 ", _interpolate(_named("n")), "개"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["검토 ", _interpolate(_named("n")), "개"]), _normalize(["검토 ", _interpolate(_named("n")), "개"]), _normalize(["검토 ", _interpolate(_named("n")), "개"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS(내부 품질 점수)는 대화 검토의 결과입니다."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = 검토 점수의 합 ÷ 검토 수"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 필터 조건과 일치하는 모든 검토 점수(카테고리 점수의 가중 평균)를 고려하여 평균으로 계산됩니다."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드를 로드하지 못했습니다."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터를 변경하여 다시 시도하세요."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격 비율은 대화 검토의 결과를 나타냅니다."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["검토 점수가 ", _interpolate(_named("failScore")), " 이하인 경우 불합격"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["검토 점수가 ", _interpolate(_named("passScore")), " 이상인 경우 합격"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 정의된 기준에 따라 결정됩니다."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격 비율 = 합격한 검토의 합 ÷ 총 검토의 합"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격 비율은 중요 카테고리나 비중요 카테고리에서 불합격하지 않고 합격한 검토 수의 비율(%)을 나타냅니다."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["예를 들어, 검토자가 이 날에 3개의 검토를 제공하는 경우 표시되는 숫자는 3개 점수의 평균입니다. ", _interpolate(_named("link")), "에서 각 셀의 계산 방식을 확인하세요."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["예를 들어, 상담사가 이 날에 3개의 검토를 받은 경우 표시되는 숫자는 3개 점수의 평균입니다. ", _interpolate(_named("link")), "에서 각 셀의 계산 방식을 확인하세요."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 가중치, 중요 상태는 고려되지 않습니다(시간 경과에 따른 점수와 다름)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토에서 카테고리가 '해당 없음'으로 표시될 수 있으므로 카테고리당 검토 수가 검토자의 총 검토 수보다 적을 수 있습니다. 각 셀의 계산 방식을 확인하세요."])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 가중치, 중요 상태는 고려되지 않습니다(시간 경과에 따른 점수와 다름)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토에서 카테고리가 '해당 없음'으로 표시될 수 있으므로 카테고리당 검토 수가 상담사당 총 검토 수보다 적을 수 있습니다. 각 셀의 계산 방식을 확인하세요."])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 가중치, 중요 상태는 고려되지 않습니다(시간 경과에 따른 점수와 다름)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " 카테고리 그룹은 별도로 표시됩니다. 각 그룹의 점수는 같은 기간 동안 해당 그룹의 평균 카테고리 점수로 계산됩니다. ", _interpolate(_named("link")), "에서 각 셀의 계산 방식을 확인하세요."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참고:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 가중치, 중요 상태는 고려되지 않습니다(시간 경과에 따른 점수와 다름)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " 카테고리 그룹은 별도로 표시됩니다. 각 그룹의 점수는 같은 기간 동안 해당 그룹의 평균 카테고리 점수로 계산됩니다. ", _interpolate(_named("link")), "에서 각 셀의 계산 방식을 확인하세요."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["기준선 ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심각한 오류 없음"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심각한 오류 없음 비율은 중요 카테고리에서 불합격하지 않고 합격한 검토 수의 비율(%)을 나타냅니다."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내부 품질 점수"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'기타' 근본 원인"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개요"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주기적 변화"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일일 변화"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간 변화"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주간 변화"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 변화"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따른 점수"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일일 또는 주간 단위로 봅니다."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계산식 = 일 또는 주에 대한 총 점수 ÷ 제공된 총 검토 수"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일일 또는 주간 단위로 봅니다."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계산식 = 일 또는 주에 대한 총 점수 ÷ 받은 총 검토 수"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리별 점수"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기간 동안 검토자가 제공한 모든 카테고리 평점의 결합 평균을 확인합니다."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기간 동안 상담사당 받은 모든 카테고리 평점의 결합 평균을 확인합니다."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["낮은 평점의 근본 원인"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평점의 근본 원인"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따른 카테고리 점수"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따라 카테고리가 어떻게 비교되는지 분석합니다."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 날짜 또는 주에 각 카테고리에 대해 제공된 평균 점수를 확인합니다."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따라 카테고리가 어떻게 비교되는지 분석합니다."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 날짜 또는 주에 각 카테고리에 대해 받은 평균 점수를 확인합니다."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토별 점수"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관됨"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요 카테고리"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 척도"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균/총계"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토 제외"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카드 내보내기"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공됨"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["대화"]), _normalize(["대화"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["항목"]), _normalize(["항목"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" 응답 ", _interpolate(_named("n")), "개"]), _normalize(["응답 ", _interpolate(_named("n")), "개"]), _normalize(["응답 ", _interpolate(_named("n")), "개"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["검토 ", _interpolate(_named("n")), "개"]), _normalize(["검토 ", _interpolate(_named("n")), "개"]), _normalize(["검토 ", _interpolate(_named("n")), "개"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1개 더 표시"]), _normalize([_interpolate(_named("n")), "개 더 표시"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 7일"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난달"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난주"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 달"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 주"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 기간"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개월"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분기"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드를 로드하지 못했습니다. 필터를 변경하여 다시 시도하세요."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 데이터가 없습니다."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간 변화"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개요"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세한 정보."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 필터에는 다양한 평가 척도의 데이터가 포함됩니다."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 필터가 있는 모든 카드 핀"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 필터가 있는 모든 대시보드 핀"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 검토 시간"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 다운로드"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 없음"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 인사이트"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그래프"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["댓글"]), _normalize(["댓글"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매트릭스"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 구성원"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내부 품질 점수"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS(평균)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격한 검토"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 고정"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드 고정"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평점"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["근본 원인"]), _normalize(["근본 원인"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 시간"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인 설정"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인을 통해 대화의 점수가 낮은 이유를 알 수 있습니다."])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 카테고리에 설정된 근본 원인이 없습니다."])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수/총계"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 그룹"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 그룹"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순서 사용자 지정"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 그룹화 기준:"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 선택"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토 제외"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토 포함"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토만 표시"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 지우기"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그를 찾을 수 없음"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 해시태그"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 해시태그 선택"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그를 찾을 수 없음"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그 검색"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드를 찾을 수 없음"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 선택"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 연결 없음"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 소스"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스 선택"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가 필터"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 워크스페이스 없음"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 총 시간"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 사용자"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 그룹"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 그룹이 없습니다."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 그룹"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 표시"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 표시 기준:"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 작성일"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 작성일"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소수점 이하 두 자리 표시"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정 보기"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보기"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 계정"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 이름"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 없음"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["깃발"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음식과 음료"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동물과 자연"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사물"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사람과 신체"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행 및 명소"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 사용"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 결과"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스마일 및 이모티콘"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기호"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이모지를 찾을 수 없음"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 열기"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 목록으로 돌아가기"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " 목록을 ", _interpolate(_named("connect")), " 또는 찾아보기"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["현재 이 도메인에 연결되어 있지 않은 ", _interpolate(_named("workspace")), " 워크스페이스를 사용 중입니다. 지금 연결하려면 여기를 클릭하세요."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 연결"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("host")), "에 대한 피드백을 아직 제공할 수 없습니다."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크를 활성 탭으로 설정하세요."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 검토할 수 없는 도메인입니다. 그렇다면 관리자에게 알리세요."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 도메인 추가"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("host")), "(으)로 연결"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정 중이니 잠시만 기다려 주세요."])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL을 가져올 수 없음"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조직의 Zendesk QA 계정과 이미 연결된 도구입니다."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조직의 Zendesk QA 계정과 연결된 도구가 없습니다."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 남기기 전에 끝에 고유한 해시를 추가하여 URL이 항상 동일하게 유지되는 웹사이트를 검토할 수 있습니다."])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 전환"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 도메인"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스와 이미 연결된 도메인입니다."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 도메인의 모든 페이지를 검토할 수 있습니다."])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토할 수 있는 도메인"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확장 기능"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고유 URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["아직 연결된 헬프 데스크가 없습니다. 추가하려면 ", _interpolate(_named("link")), "(으)로 이동하세요."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 브라우저 확장 기능은\nProfessional 및 Advanced 플랜에서만 사용할 수 있습니다."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 전환"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력하세요."])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값을 입력하세요."])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 필드"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫 번째 대화입니다. 몇 가지 세부 정보를 제공하세요."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 참조:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 티켓을 식별하기 위한 절차입니다."])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환불"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자와 제목 둘 다 제공"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만들기"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만드는 중입니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화 검토"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미배정"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["관리자에게 문의하거나 ", _interpolate(_named("settings")), "에서 다른 워크스페이스로 전환하세요."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스에는 자가 검토 기능이 사용 중지되어 있습니다."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안녕히 가세요"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 URL을 검토할 수 없습니다. 대화로 이동합니다."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["먼저 SSO 제공업체를 통해 Zendesk QA에 로그인한 다음 다시 시도하세요."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO로 로그인"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 팝업을 여는 중입니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 계정으로 로그인"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠시만 기다려 주세요..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증하는 중..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 계정이 없으신가요?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 액세스할 수 있는 권한이 없습니다. 필요한 경우 관리자에게 문의하세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["액세스 권한 없음"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에서 연결이 활성화되어 있는지 확인하세요."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 관리"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 오류"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속하려면 헬프 데스크의 대화로 이동하세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 연결하는 중입니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠시만 기다려 주세요."])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지를 다시 로드하여 원활하게 다시 진행하세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문제가 발생했습니다."])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크의 가상 경계를 벗어나 어디에서든 대화를 검토하세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확장 기능을 사용하려면 플랜을 업그레이드하세요."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 관리"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 연결이 이 워크스페이스에 연결되어 있지 않습니다."])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 헬프 데스크에서 이 티켓을 인식할 수 없거나 Zendesk QA에서 액세스할 수 없습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 찾을 수 없음"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 활동"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백이 있습니다."])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 성공"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이제 앱을 사용할 수 있습니다."])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리 평가"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기능을 사용하여 사례를 북마크하고 나중에 필터링하세요."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로드 중..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 대한 피드백 없음"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 읽기"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에서 열기"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에 모든 피드백과 알림이 표시됩니다. 아직 없습니다..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에서 자세히 보기"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 세부 정보"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 포함"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 같음"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 포함하지 않음"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 아님"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사보다 우수함"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사보다 저조함"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음보다 큼"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일부를 포함"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일부와 같음"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어느 것도 포함하지 않음"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어느 것도 아님"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위반함"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글을 남김"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 댓글을 남김"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글을 남기지 않음"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 댓글을 남기지 않음"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 위해 중요"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이내"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작:"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 14일"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 24시간"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 7일"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난달"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난주"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 달"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 주"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료:"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어제"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감지됨"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["존재"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 별표 표시함"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 별표 표시하지 않음"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음을 포함"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같음"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음보다 큼"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음부터"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음보다 작음"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음까지"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같지 않음"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같음"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같지 않음"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위반하지 않음"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복잡하지 않음"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감지되지 않음"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["존재하지 않음"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함하지 않음"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받지 못함"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회되지 않음"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토되지 않음"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 검토하지 않음"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토됨"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 검토함"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 위해 중요"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같음"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음보다 큼"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음부터:"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음보다 작음"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음까지:"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같음"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같지 않음"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함하지 않음"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같지 않음"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회됨"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 댓글에 답장이 있음"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아니요"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 카테고리"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 강조 표시"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건 검색"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메트릭"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 및 피드백"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 조건 표시"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇의 대화 처리를 평균 상담사와 비교합니다."])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇이 루프에 빠져 같은 메시지를 연속해서 반복합니다."])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 봇 답장 수"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 관련된 봇의 유형"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 참여한 봇"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["대화 콘텐츠에 상담사 메시지의 ", _interpolate(_list(0)), " 문구가 포함되어 있습니다."])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'이 통화는 녹음됩니다' 등과 같은 필수 고지 문구가 없는 통화를 자동으로 식별합니다."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 관련된 사람 또는 봇"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에서 검토 중인 상담사나 봇"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 상위 수준의 지원을 요청함"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에서 감지된 긍정적 및 부정적 감성"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 분석을 통해 대화에서 가장 활동적인 상담사 식별"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 대화를 정중하게 마무리했는지 분석"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 감정을 이해하고 인정하는지 분석"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사의 문법, 철자 및 스타일 오류 분석"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반적인 인사말 문구가 있는지 전체 대화 분석"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 질문이나 우려 사항을 이해하는지 분석"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어의 복잡성과 문장의 길이를 고려하여 텍스트가 얼마나 쉽게 이해될 수 있는지 분석"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 솔루션에 대한 전체 대화 분석"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내내 상담사의 어조 분석"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 감소의 징후입니다. 고객이 전환을 고려 중이거나 이탈 가능성이 있습니다."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 속한 그룹입니다."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 메시지의 콘텐츠 또는 텍스트"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내 상담사 답장 수"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 검토하도록 배정된 상담사"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 대화가 지속된 시간(초)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 콘텐츠 또는 텍스트"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 사용된 커뮤니케이션 채널(예: 이메일, 채팅)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 추가된 댓글"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 댓글이 추가된 날짜"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에서 감지된 긍정적 및 부정적 감성"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 고객 만족도 설문조사에 응답한 날짜"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 검토에 대한 이의 신청 여부"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 대화를 시작하기 위해 사용한 채널"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내 고객 응답 수"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내 마지막 메시지의 날짜"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 관련된 사용자의 역할 또는 유형"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사와 고객 간의 침묵이 임계값을 초과했습니다."])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 상위 수준의 지원을 요청함"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 찬사를 표했거나 받은 지원에 매우 만족함"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이나 상담사가 명시적으로 후속 작업을 요청함"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음성으로 수행된 전체 대화의 텍스트 또는 녹음"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 별표 표시 여부"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글에서 콘텐츠를 분류하는 데 사용되는 해시태그"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI가 감지한 대화의 언어"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내 마지막 메시지의 날짜"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 비공개 메모 수"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 메시지당 평균 문자 수"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에서 고객을 대상으로 한 답장 수"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보가 공개적으로 표시되는 참여자"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 메시지당 평균 단어 수"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 평가하는 데 사용되는 카테고리"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 검토 프로세스의 상태"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 참여한 참여자 또는 사람"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 연결된 이메일"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 조회 여부"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 평가한 검토자"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 마지막으로 검토한 날짜"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에서 검토 중인 상담사"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 만족도와 관련하여 고객이 제공한 구체적인 의견이나 피드백"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 대한 고객 만족도 점수"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 메시지별 고객 만족도 점수"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 검토에 사용되는 스코어카드"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 SLA를 위반함"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 시작된 소스 또는 연결의 유형"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 비정상적이거나 비정형적이었고 해결하는 데 더 많은 시간이 걸림"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 콘텐츠와 관련된 설문조사 질문"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화와 연결된 설문조사의 제목 또는 이름"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 검토하도록 배정된 상담사"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화와 연결된 브랜드 또는 특정 제품/서비스"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 사용된 커뮤니케이션 채널(예: 이메일, 채팅)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 종료 또는 해결된 날짜"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 처음 만든 날짜"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 처음 만든 날짜"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객이 고객 만족도 설문조사에 응답한 날짜"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 대한 간략한 설명 또는 요약"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 연결된 외부 참조 ID"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 구성되어 있는 폴더 또는 특정 그룹"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 구성하는 데 사용되는 양식 또는 기본서식"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 연결된 외부 참조 ID"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 수신하는 데 사용된 사서함 또는 이메일 계정"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화와 연결된 전화번호"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 배정된 우선 순위 수준"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 총 답장 수"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 상태 또는 현재 상태"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 현재 상태"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 제목 또는 주제"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내 상담사 답장 수"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 분류하기 위해 배정된 태그"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화의 유형 또는 카테고리"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 마지막으로 업데이트된 날짜"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 마지막으로 업데이트된 날짜"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 이루어진 매체 또는 채널"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 녹취 내용"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 분석을 통해 검토할 수 없는 대화"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 참여 중인 상담사 수"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 조회 여부"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 메시지"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 커뮤니케이션 효율성"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 반복"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 답장 수"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 유형"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화 방향"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클라이언트 메시지"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침묵"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["녹음 고지 누락"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에스컬레이션"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 녹취 내용"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별표 표시됨"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 부적격"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토한 사람"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 수단"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤법 및 문법"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 메시지"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 활동적인 상담사"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 답장 수"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 지속 시간(초)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 콘텐츠"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지 채널"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 태그"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 남긴 날짜"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 설문조사 응답 날짜"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공감"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 채널"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 답장 수"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 답장 날짜"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 유형"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이탈 위험"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침묵"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에스컬레이션"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탁월한 서비스"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후속 작업"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내용"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 해시태그"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상값"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이해도"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 답장 날짜"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 비공개 메모 수"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 문자 수"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 단어 수"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 답장 수"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 참여자"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 날짜"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평점 및 댓글"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 상태"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가독성"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 이메일"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답장 수"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토된 스코어카드"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 조회됨"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 날짜"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 댓글"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지당 CSAT"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만족도 점수(CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["솔루션 제공됨"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스 유형"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 질문"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 제목"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 브랜드"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 도메인"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 채널"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료 날짜"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 날짜"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 날짜"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 설문조사 응답 날짜"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 설문조사 전송 날짜"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 설명"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 필드"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 폴더"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 양식"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 그룹"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["외부 ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 사서함"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 우선 순위"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 답장 수"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 상태"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 상태"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 제목"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 답장 수"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 유형"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트 날짜"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트 날짜"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어조"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["녹취 내용"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여 상담사 수"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회 상태"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취약성 기능"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취약성 상태"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취약성 수명 이벤트"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 카테고리"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 관련 조건 선택"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터에 이름 추가"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 이름 입력"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인한 사람"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 워크스페이스 구성원"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 구성원"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " 추가"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 적용"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 1개의 날짜 관련 필터가 있어야 합니다."])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 선택"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 선택"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판이 ", _interpolate(_named("n")), "일 후에 종료됩니다. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판이 <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), "일 후에</strong></a> 종료됩니다."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판이 ", _interpolate(_named("n")), "일 후에 종료됩니다. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판이 <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), "일 후에</strong></a> 종료됩니다."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판이 오늘 종료됩니다. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가판이 <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>오늘</strong></a> 종료됩니다."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 플랜을 설정하세요"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "일"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판 기간이 ", _interpolate(_named("days")), " 남았습니다."])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["해당 플랜의 사용자 한도에 도달했습니다. ", _interpolate(_named("link")), "하여 자세히 알아보거나 플랜을 업그레이드하세요."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기를 클릭"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크를 연결하세요"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이렇게 하면 검토할 티켓을 가져올 수 있습니다."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 연결"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["샘플 데이터로 Zendesk QA를 사용하고 있습니다. 샘플 데이터를 삭제하고 자신의 대화를 가져오려면 ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 데이터"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래에서 유용한 정보를 복사하세요."])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 페이지 스크린샷을 찍어 전후상황을 제공하세요."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 문제를 해결하려면 고객지원팀에 문의하세요."])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문제가 발생했습니다."])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류 참조:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유용한 정보 복사"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자, 봇 및 워크스페이스"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 및 워크스페이스"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 달"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 주"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " 검토됨 ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " 검토됨 ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " 검토됨 ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 기능"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단축 키"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 채팅을 시작하세요"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채팅을 로드할 수 없습니다.(광고 차단 프로그램을 사용하고 계신가요?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음으로 이동..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커뮤니티"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈페이지"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 검토"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지식창고"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 전환"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["애플리케이션을 업데이트했습니다. ", _interpolate(_named("buttonStart")), "다시 로드", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA를 워크스페이스의 일부로 사용하려면 다음을 수행하세요."])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA를 워크스페이스의 일부로 사용할 수 있도록 초대되셨습니다. 물론 계속 진행할 수도 있지만 이렇게 하면 ", _interpolate(_named("bold")), "이 만들어집니다."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별도 조직"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["예, ", _interpolate(_named("link")), "으로 계속하겠습니다."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 계정"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속하시겠습니까?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), "님이 ", _interpolate(_named("account")), "에 가입하도록 요청합니다."])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인터넷에 연결되어 있지 않습니다."])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 핀 추가"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빠른 탐색"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 편집기 집중 및 확장"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이드 패널 숨기기 또는 표시"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 대화로 이동"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원래 대화 열기"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 대화로 이동"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리에 가능한 가장 높은 평점 제공"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리에 가능한 가장 낮은 평점 제공"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 순서 섞기(무작위로 다시 정렬)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알려주세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단축 키"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로 가기 모달 토글"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("charLimit")), "자 제한에 도달했습니다."])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이미지 ", _interpolate(_named("name")), " 유형(", _interpolate(_named("type")), ")은 허용되지 않습니다."])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이미지 ", _interpolate(_named("name")), " 크기가 너무 큽니다(5MB 허용)."])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 편집기를 로드하지 못했습니다."])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드롭하여 업로드"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["텍스트"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 관리"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 기본서식 없음"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인 추가"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뒤로"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 삭제 이유 포함"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평점을 준 이유"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직원 수"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 설정을 도와드리기 위해 연락드리겠습니다."])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 이름"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 연락처 번호"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에 대해 처음에 어떻게 알게 되셨나요?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 선택"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 수 선택"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 필수 필드 입력"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약관 및 개인정보 보호방침을 읽고 동의해야 합니다."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원 중심 Slack 채널"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 또는 동료"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 마켓플레이스 또는 계정 관리자"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참조 사이트"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 엔진(Google, Bing 등)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소셜 미디어"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인과 회사에 대해 알려주세요."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 세부 정보"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["준비되었습니다."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["천천히 둘러보며 앱에 대해 알아보고 항목을 삭제하거나 추가하세요."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 만들었습니다. 다음은 무엇일까요?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실제 대화를 가져올 준비가 되셨나요?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자체 데이터 추가"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["둘러보기 시작"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["샘플 데이터로 사용해 보기"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토할 티켓을 가져올 수 있습니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 계정 연결"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마음이 바뀌었습니다."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입 취소"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["핀을 만들었습니다. ", _interpolate(_named("buttonStart")), "핀으로 이동", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 핀은 Professional 플랜에서 사용할 수 있습니다."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 업그레이드"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 플랜 보기"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모를 저장하여 다음 1:1 상담 또는 코칭 세션을 준비하세요."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 핀"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "(으)로 댓글을 달거나 다른 사람을 추가하세요."])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화는 삭제되었습니다."])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 핀 만들기"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀 만들기"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀 편집"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀 작동 방식"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화를 볼 수 있는 권한이 없습니다."])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 고정"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 고정"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드 고정"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀 저장됨"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 카드 고정"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화 고정"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대시보드 고정"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 아래에 고정"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 선택"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고정한 항목은 본인만 볼 수 있습니다. 다른 사람들과 공유하려면 댓글에서 ", "@", "멘션하세요."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), "님이 이제 이 대화에 초대됨"]), _normalize([_interpolate(_named("agentsNames")), "님이 이제 이 대화에 초대됨"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["댓글 1개"]), _normalize(["댓글 ", _interpolate(_named("n")), "개"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("participants")), "을(를) 멘션했지만 이 대화에는 없습니다."]), _normalize([_interpolate(_named("participants")), "을(를) 멘션했지만 이 대화에는 없습니다."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["참여자 ", _interpolate(_named("n")), "명"]), _normalize(["참여자 ", _interpolate(_named("n")), "명"]), _normalize(["참여자 ", _interpolate(_named("n")), "명"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 핀에 액세스하지 못하도록 ", _interpolate(_named("agentName")), "을(를) 제거하시겠습니까?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아무 것도 하지 않기"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 초대하지 않음"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 달기"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화에 초대"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 제거"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스레드"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업은 실행 취소할 수 없습니다."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 핀 아래의 모든 댓글도 삭제됩니다."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["새 메시지 ", _interpolate(_named("n")), "개"]), _normalize(["새 메시지 ", _interpolate(_named("n")), "개"]), _normalize(["새 메시지 ", _interpolate(_named("n")), "개"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인만 핀을 볼 수 있습니다. 핀을 공유하려면 댓글에서 누군가를 ", "@", "하세요."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " 님이 다음을 고정함: ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 핀을 받으려면 업그레이드하세요."])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 핀은 Professional 플랜에서 사용할 수 있습니다."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 핀은 Professional 플랜에서 사용할 수 있습니다."])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기능을 이용하려면 업그레이드하세요."])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 상담을 준비하거나 나중을 위해 저장하세요."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀과 추가된 모든 댓글이 영구 삭제됩니다."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀 삭제"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀 삭제"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더 이름"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 다시 볼 수 있도록 항목 고정"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답장"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신 항목 먼저"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오래된 항목 먼저"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["흥미로운 대시보드 추세 또는 대화를 저장하여 1:1 상담을 준비하거나 나중을 위해 메모해 두세요."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 또는 대시보드 보기에서 위의 버튼이나 핀 아이콘을 클릭하여 핀을 만듭니다."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고정"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 핀 없음"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "에 대한 검색 결과 없음"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 키워드를 사용하거나 오타가 있는지 확인하세요."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 핀 만들기"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사람"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자별, 키워드별 검색..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사전순"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 만듦"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 편집함"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대부분의 핀"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("schedule")), " ", _interpolate(_named("n")), "개 대화를 검토하는 것이 목표입니다"]), _normalize([_interpolate(_named("schedule")), " ", _interpolate(_named("n")), "개 대화를 검토하는 것이 목표입니다"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표 업데이트됨"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표 설정"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토하려는 대화 수에 대한 개인별 목표를 설정할 수 있습니다."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매일"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매달"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매주"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표 검토"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 로드 중"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 선택"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글이 있는 설문조사 피드백만 공유"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탁월한 서비스 품질과 훌륭한 고객 피드백을 강조하여 동료들의 역량을 강화하세요."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적인 설문조사 피드백을 Slack에 공유"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널을 찾을 수 없음"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위 점수와 함께 자동으로 피드백 공유"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["칭찬 봇 연동"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아무 검토도 하지 않은 경우 한 주가 시작될 때 알림을 받습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 알림"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주간 검토 알림"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack에서 새 검토, 답장, 멘션 및 이의 신청에 대한 알림을 받으세요."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멘션"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평점"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스레드 답장"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 연동"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 검토, 답장, 멘션 및 이의 신청에 대해 알립니다."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나와 관련된 이의 신청"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글의 멘션"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로 받은 검토"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스레드의 답장"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통계가 포함된 보고서"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 검토와 받은 검토에 대한 보고서를 받습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보고서"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매일"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매주"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매일"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3시간마다"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6시간마다"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉시"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매주"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 관리자가 알림 설정을 잠갔습니다."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기간 선택"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 알림"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "(활성)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 계정에 새 소셜 로그인 추가:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 로그인 방법을 제거하면 로그아웃됩니다. 대체 이메일로 다시 로그인할 수 있습니다."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 방법 제거"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google을 로그인 옵션으로 추가했습니다."])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 옵션"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack을 로그인 옵션으로 추가했습니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 추가"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 추가"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 만들기"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속하시겠습니까?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방법"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["비밀번호를 재설정하기 위한 이메일이 ", _interpolate(_named("email")), "(으)로 전송됩니다."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 이메일"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 편지함에서 확인 이메일을 확인하세요."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["변경하려면 먼저 ", _interpolate(_named("link")), "을 변경하세요."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " 이메일이 이 비밀번호와 연결됩니다."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 업데이트됨"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필 사진"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최상의 결과를 얻으려면 정사각형 사진(256x256픽셀 이상)을 업로드하세요."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 이메일"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 주가 시작되는 요일"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 설정"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 옵션을 선택하세요."])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 콘텐츠"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 이름"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 표시 위치"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 콘텐츠 추가"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 이름 추가"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 만들기"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 만들기"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 기본서식을 만들어 검토, 항목 고정 및 코칭 속도를 높이세요."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용됨"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 삭제"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기본서식을 영구 삭제하시겠어요?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 편집"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 기본서식을 만들어 검토, 항목 고정 및 코칭 속도를 높이세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 기본서식 없음"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 기본서식을 만들었습니다."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 기본서식을 삭제했습니다."])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 기본서식을 저장했습니다."])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 및 이의 신청"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["액세스하려면 ", _interpolate(_named("link")), "에 문의하세요."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 지원"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 찾을 수 없음"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["지금은 ", _interpolate(_named("link")), "에서 Zendesk QA에 액세스하세요."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk로 돌아가기"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정이 마이그레이션되지 않음"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 QA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개요"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메트릭"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통계"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공됨"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 댓글"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 이의 신청"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 검토"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반응"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받음"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작됨"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 피드백"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핀"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인용"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 만들기"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자격 증명"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재설정"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 목표"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식 만들기"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본서식"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연동 서비스"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조직"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 단위 라이선스"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 카테고리"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충돌 메시지를 찾았습니다."])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충돌하는 메시지 중 하나 변경 또는 제거"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("placeholder")), " 아이콘을 사용하여 자동 QA에서 민감한 데이터를 제외합니다. 예를 들어 '항상 건강히 잘 지내세요, John님' 대신 '항상 건강히 잘 지내세요, ", _interpolate(_named("placeholder")), "님'을 사용하여 모든 이름을 대표합니다."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["최대 ", _interpolate(_named("limit")), "개 행"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["규칙 적용 대상"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10개의 사용자 지정 카테고리 한도에 도달했습니다."])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("limit")), "개의 사용자 지정 카테고리 한도에 도달했습니다."])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건 추가"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행 추가"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["및"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건 삭제"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 메시지 조건"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실행 조건"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불충족 점수"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충족 점수"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 카테고리 만들기"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변수를 추가하려면 '/'를 사용하세요."])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 방향"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 메시지는 반드시 입력해야 합니다."])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지는 300자를 초과할 수 없습니다."])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["텍스트"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 소스"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 채널"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 태그"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 대화"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["규칙이 적용되는 범위를 좁히세요."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특정 대화"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시 인사이트 만들기"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 메시지는 반드시 입력해야 합니다."])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10개의 사용자 지정 인사이트 한도에 도달했습니다."])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("limit")), "개의 사용자 지정 인사이트 한도에 도달했습니다."])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트 이름 입력"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트 이름"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 만들기"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트 유형"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중립"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긍정적"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 설정으로 이동"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 평가 카테고리를 사용하려면 워크스페이스 스코어카드 설정으로 이동하여 원하는 스코어카드에 추가하세요."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리를 만들었습니다."])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특정 키워드나 문구를 식별하여 종료된 대화에 자동으로 점수를 매깁니다."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음성 통화 내용에서는 작동하지 않습니다."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["축하합니다! ✋ ", _interpolate(_named("limit")), "에서 자동 평가 카테고리를 초과했습니다. 카테고리화의 마법사이시군요! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 제한에 도달"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 필드가 올바르게 채워졌는지 확인하세요."])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 이름 지정"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 이름"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 평가 카테고리"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 평가 카테고리"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특정 키워드나 문구를 식별하여 종료된 대화에 자동으로 점수를 매깁니다. 음성 통화 내용에서는 작동하지 않습니다."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특정 키워드나 문구를 식별하여 종료된 대화를 자동으로 표시합니다. 대화 내용에서만 작동합니다."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 및 봇 메시지에서 특정 키워드나 문구를 식별하여 자동으로 대화에 점수를 매깁니다."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 평가 카테고리를 사용하려면 워크스페이스 스코어카드 설정으로 이동하여 스코어카드에 추가하세요."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지의 특정 키워드나 문구를 기준으로 자동으로 대화에 점수를 매깁니다."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 대상"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사이트 설정"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 인사이트는 정의된 기준에 따라 강조 표시에 표시됩니다."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 설정"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 카테고리는 정의된 기준에 따라 스코어카드에서 사용할 수 있습니다."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채팅"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곧 제공 예정"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 정의됨"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["음성"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 저장"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 저장됨"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팁:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["{··}", "를 클릭하여 자동 QA의 맺음말 검사 중 이름, 회사 세부 정보 또는 기타 민감한 정보를 제외합니다."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말 추가"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말 편집"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외 추가"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외 편집"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말 추가"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말 편집"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["줄 바꿈으로 구분하여 여기에 단어를 붙여넣으세요."])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 항목의 각 단어 다음에 Enter 키를 누릅니다."])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 언어"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예를 들어 '항상 건강히 잘 지내세요, John님' 대신 '항상 건강히 잘 지내세요, ", "{··}", "님'을 사용하여 모든 이름을 대표합니다."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동적 콘텐츠 자리 표시자"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["예외 1개 추가됨"]), _normalize(["예외 ", _interpolate(_named("count")), "개 추가됨"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["예외가 이미 추가됨"]), _normalize(["예외가 이미 추가됨: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외 추가"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외를 찾을 수 없음"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 예외 없음"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외(대소문자 구분)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["예외 추가"]), _normalize(["예외 1개 추가"]), _normalize(["예외 ", _interpolate(_named("count")), "개 추가"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 또는 문구 검색"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외 저장됨"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 언어"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 검색"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["맺음말이 이미 추가됨"]), _normalize(["맺음말이 이미 추가됨: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말 추가"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말 찾을 수 없음"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말 없음"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말 추가"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말 검색"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말 추가됨"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말 저장됨"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["양식에 중복 항목이 있습니다. ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["인사말이 이미 추가됨"]), _normalize(["인사말이 이미 추가됨: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말 추가"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말을 찾을 수 없음"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말 없음"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말 추가"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말 검색"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말 추가됨"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말 저장됨"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외 추가"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["예외 삭제"]), _normalize(["예외 삭제"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["이 예외를 영구 삭제하시겠어요?"]), _normalize(["이러한 예외를 영구 삭제하시겠어요?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예외 편집"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["하나 이상의 예외가 ", _interpolate(_list(0)), "자를 초과합니다."])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), "자"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트됨"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트한 사람"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변수"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 및 서비스 표준에 부합하는 자체 맺음말을 정의하세요. 추가하면 자동 QA가 승인된 맺음말만 감지합니다. 변경 내용은 향후 대화에 적용됩니다."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말 최대 한도인 100개에 도달했습니다. 더 많은 맺음말을 추가하려면 기존 인사말을 다시 살펴보고 수정해야 할 수도 있습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말 한도 도달"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA에서 맞춤법이나 문법 오류로 무시하려는 단어 및 문구입니다. 변경 내용은 향후 대화에 적용됩니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무시 목록"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 및 서비스 표준에 부합하는 자체 인사말을 지정하세요. 추가하면 자동 QA가 승인된 인사말만 감지합니다. 변경 내용은 향후 대화에 적용됩니다."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말 최대 한도인 100개에 도달했습니다. 더 많은 인사말을 추가하려면 기존 인사말을 다시 살펴보고 수정해야 할 수도 있습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말 한도 도달"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 삭제"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 평가 카테고리를 영구 삭제하시겠어요?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 목록 토글"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제된 카테고리는 새 대화 및 필터에 나타나지 않습니다. 대시보드 및 대화의 기록 데이터는 그대로 유지됩니다."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리가 추가된 모든 스코어카드에서 제거됩니다."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 카테고리를 삭제하지 못했습니다."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 카테고리 삭제됨"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 카테고리 삭제"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 카테고리"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AI 모델이 지원하는 ", _interpolate(_named("highlight")), "는 스코어카드에 계속 유지되지만 더 이상 자동으로 점수가 매겨지지는 않습니다."])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 연동을 해제한다고 해서 자동 QA 기능이 완전히 해제되는 것은 아닙니다. 다음과 같은 상황이 발생할 수 있습니다."])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기록 데이터"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "는 자동 QA 대시보드에 계속 유지됩니다."])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 추가된 근본 원인"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "은 계속 유지되지만 모든 LLM 기반 근본 원인은 제거됩니다."])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 기반 자동 QA 사용 중지"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 중지"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA는 AI 및 대규모 언어 모델(LLM)을 사용하여 특정 카테고리에 자동으로 점수를 매깁니다. 데이터는 엄격한 데이터 처리 계약(DPA)에 따라 처리되며, 어떤 모델 학습에도 사용되지 않습니다."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성형 AI에 대해 알아보기"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["자동 QA는 ", _interpolate(_named("automatically_scoring_categories")), "에 Microsoft Azure OpenAI 서비스를 사용합니다. 사용자의 데이터는 사용자에게 자동 QA를 제공하기 위한 목적으로만 엄격한 DPA 하에서 처리되며 ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일부 카테고리를 자동으로 점수 매기기"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenAI 모델을 학습시키는 데 사용되지 않습니다"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 기반 자동 QA 사용 설정됨"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 기반 자동 QA 사용 중지됨"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM 기반 자동 QA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA에서 AI로 자동 점수 매기기"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure 기반 자동 QA 카테고리의 데이터 처리"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure 동의 철회됨"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure 동의함 대화가 곧 자동 평점을 받게 됩니다."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA를 계속 사용할 수 있지만 다음과 같이 됩니다."])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI 서비스를 사용 중지해도 자동 QA 기능이 해제되지 않습니다."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이는 스코어카드 카테고리 보기에서 수행할 수 있습니다."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모델에서 제공하는 자동 QA 카테고리는 스코어카드에 계속 남아 있지만 자동으로 점수가 매겨지지는 않습니다."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 추가한 근본 원인은 그대로 유지됩니다. 모델이 생성한 모든 근본 원인이 제거됩니다."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기록 데이터는 자동 QA 대시보드에 계속 남아 있습니다."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI 서비스의 데이터 처리 사용 중지"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 넓은 범위의 자동 QA 카테고리."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉시 사용 가능한 다국어 지원."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신속하게 새로운 카테고리 추가."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 정확도 향상. GPT-3.5는 인간 수준의 상황 인식을 보여줍니다."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신 AI 기술의 혜택."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공되는 기능은 다음과 같습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI 서비스로 데이터 처리 사용"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동의함"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동의하지 않음"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 신규 사용자가 이 워크스페이스에 배정됩니다."])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 워크스페이스"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12시간 형식 시간(", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24시간 형식 시간(", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 형식"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 프로필 업데이트됨"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 주가 시작되는 요일"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 연동"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["액세스 키 ID와 비밀키는 일반적으로 '액세스 관리' > '사용자' 아래에 있습니다."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 액세스 키 ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect 추가"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀 액세스 키 변경 취소"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀 액세스 키 값 변경"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결을 통해 일반적으로 '위치' 아래에서 하나의 리전만 정의할 수 있습니다."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 리전"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀키 추가"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 비밀 액세스 키"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect에서 모든 관련 정보를 찾을 수 있습니다."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반적으로 Amazon Connect 인스턴스 설정 및 데이터 저장 공간 페이지 아래에 있습니다."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 녹음의 저장 위치"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect를 추가할 때 오류가 발생했습니다. 모든 필드를 올바르게 입력했는지 확인하고 다시 시도하세요."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 위치는 슬래시로 구분된 버킷/폴더 형식이어야 합니다."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["연결을 추가하려면 ", _interpolate(_named("link")), "에 문의하세요."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 지원팀"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 Zendesk 티켓이 자동으로 동기화됩니다. 선택한 티켓을 제외하도록 선택할 수 있습니다."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아시아 태평양(도쿄)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아시아 태평양(서울)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아시아 태평양(오사카)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아시아 태평양(뭄바이)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아시아 태평양(시드니)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캐나다(중부)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유럽(프랑크푸르트)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유럽(취리히)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유럽(아일랜드)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유럽(런던)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버 지역"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중동(아랍에미리트)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서버 지역 선택"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남아메리카(상파울루)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미국 동부(버지니아)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미국 동부 2(오하이오)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미국 서부(오리건)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결이 이미 추가됨"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 시작"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같은 대화만 동기화"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널 1, 채널 2"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 연결의 고유 ID는 ", _interpolate(_named("tokenId")), "입니다."])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1개월"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1년"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3개월"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6개월"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 보존 기간이 지나면 비활성 대화가 삭제됩니다. 제출된 검토는 활동 및 대시보드 보기에 계속 표시됩니다."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유 기간"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보존 기간 선택"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업은 되돌릴 수 없습니다."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화, 필드 및 태그를 포함하여 Zendesk QA에서 이 연결에 대한 모든 헬프 데스크 관련 데이터가 제거된다는 사실을 이해합니다."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출된 검토는 영향을 받지 않으며 특정 보기에 계속 표시됩니다."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 1, 그룹 2"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개발"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 연동 서비스는 아직 ", _interpolate(_named("activeDevelopment")), " 중에 있습니다. 첨부 파일이나 사용자 지정 필드 등 일부 세부 정보가 누락되었을 수 있습니다."])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사서함 1, 사서함 2"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 1, 태그 2"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 토큰"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 토큰 비밀키"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk의 '설정' → '티켓 필드'에서 '티켓 필드 이름'를 찾습니다."])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout의 'Settings' → 'Custom fields'에서 'Custom Field Names'을 찾습니다."])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomer의 '설정' → 'Klasses' → '대화'에서 'Klass 대화 이름'을 찾습니다."])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Agent의 '설정' → '사용자 지정 필드'에서 '사용자 지정 필드 이름'을 찾습니다."])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wix의 '설정' → '사용자 지정 필드'에서 '사용자 지정 필드 이름'을 찾습니다."])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쉼표로 필드 ID를 구분하세요. Zendesk의 '관리자' → '티켓 필드'에서 이러한 ID를 찾을 수 있습니다."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["몇 명의 헬프 데스크 사용자를 연결하여 첫 번째 대화를 검토하세요. 걱정하지 마세요. 워크스페이스 구성원으로 초대하지 않는 한 알림을 보내지 않습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연동 완료"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원 추가"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이제 대화를 가져오기 시작합니다."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["도메인: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["헬프 데스크 식별자: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결을 인증할 수 없음"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 설정되었습니다. 새 연결입니다."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일부 연결이 업데이트되지 않았거나 비활성화되었습니다. 이러한 연결을 업데이트하거나 목록에서 제거해 보세요."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 클라이언트 ID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 클라이언트 비밀키"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["설정 → 계정 → API 및 SDK에서 새 API 토큰을 만듭니다. 사용할 리디렉션 URL은 ", _interpolate(_named("redirectUrl")), "입니다."])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작하려면 헬프 데스크를 선택하세요."])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 계정이 EU 또는 호주에서 제공되도록 설정된 경우에는 이를 업데이트하세요."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "에 로그인할 때 사용하는 이메일"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최종 사용자의 이름, 이메일 및 전화번호를 마스킹합니다. 모든 헬프 데스크 연결에 대해 은행 자격 증명은 이미 마스킹되어 있습니다."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 연동 서비스가 없습니다. 지금 추가하세요."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같은 역할이 필요합니다."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 만들기"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 연결 추가"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 옵션을 선택 취소하면 이 연결을 특정 워크스페이스에만 수동으로 추가할 수 있습니다."])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 기존 워크스페이스에 연결"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 키"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 키 비밀키"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["연결 ", _interpolate(_list(0)), " 삭제됨"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 이름"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 시간"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래에서 API 토큰을 확인할 수 있습니다."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 임계값"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connectionName")), " 연결 삭제"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 연결을 제거하려면 '연결 삭제'를 입력하세요."])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 삭제"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결된 데이터 소스를 관리하고 모니터링하세요."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 편집"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쉼표로 필드 이름을 구분하세요."])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트하지 못했습니다."])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연동 서비스에 고유한 이름을 지정하세요."])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 옵션 숨기기"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민감한 데이터 필드 숨기기"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부 파일 URL 저장 안 함"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부 파일 무시"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 콘텐츠를 저장하지 않음(나중에 헬프 데스크에서 요청 시 가져올 수 있음)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 콘텐츠 무시"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 제목을 저장하지 않음(나중에 헬프 데스크에서 요청 시 가져올 수 있음)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목줄 무시"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 하위 도메인"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 지역"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 편지함 팀 ID 일치"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 팀의 숫자 ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사서함"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 연결 업데이트"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 데이터 마스킹"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 없음"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민감한 필드 이름"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민감한 필드 ID"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 갱신"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 갱신"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce 샌드박스에 연결"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "(으)로 연결 설정하기"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 옵션 표시"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정된 임계값 이하의 점수를 가진 대화만 가져옵니다."])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 임계값 적용"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같은 대화만 동기화..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 대화 받기"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 업데이트"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결을 업데이트했습니다."])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 이름"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 헬프 데스크"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["플랜을 취소하고 계정을 삭제하려면 ", _interpolate(_named("link")), "에 문의하세요."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 지원팀"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 삭제"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Support(또는 다른 헬프 데스크)에서 티켓을 삭제해도 연결된 대화 검토에는 영향을 미치지 않습니다. 계속해서 보고 관리할 수 있습니다."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA 에서 티켓을 삭제하면 연결된 대화 검토도 삭제됩니다.", _interpolate(_named("line_break")), "Zendesk Support(또는 다른 헬프 데스크)에서 티켓을 삭제해도 연결된 대화 검토에는 영향을 미치지 않습니다. 계속해서 보고 관리할 수 있습니다."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 관리"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 삭제는 영구적입니다. 대시보드의 데이터에도 영향을 미칩니다."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 검토 삭제"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 삭제"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 검토 삭제됨"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 삭제"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제된 티켓과 연결된 검토가 있으면 여기에 표시됩니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 검토 없음"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티켓 ID 검색"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["티켓 ID: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제된 대화"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["기본 워크스페이스는 삭제할 수 없습니다. 이 워크스페이스를 삭제하려면 ", _interpolate(_named("link")), "에서 다른 기본 워크스페이스를 선택하세요."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 설정"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업은 실행 취소할 수 없습니다."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 데이터를 제거 및 삭제"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 작업은 워크스페이스 사용자 및 연결을 포함하여 이 워크스페이스와 관련된 ", _interpolate(_named("removeAllData")), "하는 것을 이해합니다."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 삭제"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스를 삭제하려면 '워크스페이스 삭제'를 입력하세요."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["워크스페이스 ", _interpolate(_named("workspaceName")), " 삭제"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 삭제"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 수행하려는 작업에 대해 분명히 확인하거나 우선 전문가와 상담하세요."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 자주 활동하는 상담사 또는 현재 담당자를 기준으로 기본 검토 대상자를 선택할지 여부를 선택합니다."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 활동적인 상담사"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 검토 대상자"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사람들이 자신의 대화를 검토할 수 있도록 허용하고 상담사가 검토를 수행할 수 있도록 합니다."])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스가 삭제되었습니다."])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스가 업데이트되었습니다."])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전에 다른 검토자가 제공한 검토를 숨겨 중립적인 채점이 가능하도록 합니다."])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편견 없는 채점"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["색"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업은 실행 취소할 수 없습니다."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 사용자 및 연결을 포함하여 이 워크스페이스와 관련된 모든 데이터가 제거되고 삭제됩니다."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 삭제"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 삭제하는 중..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매직 링크"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 로그인 방법을 비활성화할 수 없습니다."])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 방법이 업데이트되었습니다."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 사용자에 대해 특정 유형의 인증 방법을 허용합니다."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 및 워크스페이스"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 만들고 관리하며, 사용자를 초대하거나 연결하세요."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지에 머무르기"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지에서 나가기"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 페이지에서 나가면 모든 변경 내용을 잃게 됩니다."])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가져올 대화가 없는 것 같습니다. 먼저 헬프 데스크와 대화를 나눈 다음 여기로 돌아와서 분석하세요."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 찾을 수 없음"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 시스템 카테고리는 삭제할 수 없습니다."])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 삭제"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 삭제는 영구적입니다. 리포팅에서 모든 카테고리 데이터가 지워집니다."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 카테고리를 복원하려면 다시 만들어야 합니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 삭제"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 검색"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 카테고리는 추천 평점으로 스코어카드 를 자동으로 채워 검토 시간을 절약해 줍니다. 수동 카테고리는 처음부터 검토자가 채웁니다."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 업데이트"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공감"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤법 및 문법"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이해도"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가독성"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["솔루션 제공됨"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어조"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리를 찾을 수 없음"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인을 찾을 수 없음"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드를 찾을 수 없음"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 검색을 시도하거나 필터를 변경하세요."])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["카테고리 ", _interpolate(_named("count")), "개"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["카테고리 ", _interpolate(_named("count")), "개"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["카테고리 ", _interpolate(_named("count")), "개"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["카테고리 ", _interpolate(_named("count")), "개"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["스코어카드 ", _interpolate(_named("count")), "개"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["스코어카드 ", _interpolate(_named("count")), "개"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["스코어카드 ", _interpolate(_named("count")), "개"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 스코어카드"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["스코어카드 ", _interpolate(_named("count")), "개"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["상태 ", _interpolate(_named("count")), "개"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["상태 ", _interpolate(_named("count")), "개"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["상태 ", _interpolate(_named("count")), "개"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 상태"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["상태 ", _interpolate(_named("count")), "개"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["유형 ", _interpolate(_named("count")), "개"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["유형 ", _interpolate(_named("count")), "개"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["유형 ", _interpolate(_named("count")), "개"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 유형"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["유형 ", _interpolate(_named("count")), "개"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["워크스페이스 ", _interpolate(_named("count")), "개"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["워크스페이스 ", _interpolate(_named("count")), "개"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 찾을 수 없음"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["워크스페이스 ", _interpolate(_named("count")), "개"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["워크스페이스 ", _interpolate(_named("count")), "개"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), "의 가중치가 전체 점수의 ", _interpolate(_named("percentage")), "%를 차지합니다."])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 삭제"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 삭제는 영구적입니다. 리포팅에서 모든 스코어카드 데이터가 지워집니다."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 스코어카드를 복원하려면 다시 만들어야 합니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 삭제"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 업데이트"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가 척도를 업데이트했습니다. ", _interpolate(_named("buttonStart")), "검토 시작", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA는 기본적으로 이진 척도를 설정하지만 더 세분화된 척도를 선택할 수도 있습니다."])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["카테고리별로 구성된 다양한 평가 척도 옵션을 보려면 다음으로 이동하세요. ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공감/어조"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 지식"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 척도 업데이트:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 평가 척도 및 해당 카테고리가 삭제되지 않고 보관됩니다."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 척도와 새 척도를 비교할 수 없으므로 일부 대시보드 데이터가 영향을 받습니다."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기존 평가 카테고리 설정을 새 척도로 복제"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 척도를 업데이트하시겠습니까?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드 표시 형식(숫자 또는 이모지)을 변경하고 카테고리를 필수 또는 선택 사항으로 설정할 수 있습니다."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자 대신 이모지 사용"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자가 카테고리를 건너뛸 수 있도록 허용"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결 방법"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 척도 유형"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["워크스페이스 1개에 대한 카테고리 복제됨"]), _normalize(["워크스페이스 ", _interpolate(_named("workspacesAmount")), "개에 대한 카테고리 복제됨"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 설정"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("messageType")), "인 경우"])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 침묵이 예상되는 경우 상담사가 고객에게 미리 안내하지 않는 통화를 자동으로 표시합니다."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화에 설정된 임계값보다 오래 지속되는 침묵의 순간이 있습니다."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 중 최대 침묵 임계값 정의"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침묵"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제된 인사이트는 새 대화 및 필터에 나타나지 않습니다. 대시보드 및 대화의 기록 데이터는 그대로 유지됩니다."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시 인사이트를 삭제하지 못함"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시 인사이트 삭제됨"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시 인사이트 삭제"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내용 및 메시지에서 특정 단어나 문구가 포함된 대화를 봅니다."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'이 통화는 녹음됩니다' 등과 같은 필수 고지 문구가 없는 통화를 자동으로 식별합니다."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사전 설정된 문구가 필요 없이 동적으로 전후상황을 파악하는 대규모 언어 모델(LLM)을 사용하여 통화에서 녹음 고지 문구를 식별하는 것을 목표로 합니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["녹음 고지 누락"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 침묵이 예상되는 경우 상담사가 고객에게 미리 안내하지 않는 통화를 자동으로 표시합니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침묵"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화 중 침묵의 순간 지정"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["플랜을 변경해야 하나요? ", _interpolate(_named("link")), "(", _interpolate(_named("email")), "). 성심껏 도와드리겠습니다."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러 상담사, BPO, 채널 및 언어에 걸친 대화를 100% 자동으로 분석하세요. AI는 문제, 지식 격차 및 코칭 기회를 감지하여 서비스를 개선합니다."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 100% 적용 범위"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 피드백 및 코칭"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시 AI 인사이트"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 리포팅 및 대시보드"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성 분석 및 필터링"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문형 번역"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배정 및 목표 설정 검토"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 및 퀴즈"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 품질 보증(QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional의 모든 기능 + 추가 기능"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급(레거시)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심각한 불합격에 대한 즉각적인 알림을 위한 웹훅"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 웨어하우스 연동"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce 서비스 클라우드 연동"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 연동 서비스 개발"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전담 고객 성공 관리자"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack을 통해 제품 및 엔지니어링 팀에 직접 액세스"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite의 모든 기능 + 추가 기능"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% 범위 적용 자동 QA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 기반 대화 인사이트로 데이터 이해"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI로 향상된 헬프 데스크 데이터 강화 및 필터링"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시: 반드시 검토해야 하는 상호작용에 대한 자동 감지"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성 분석 및 필터링"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 기반 성과 리포팅 및 인사이트"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문형 대화 번역"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 시간 추적"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격률 애널리틱스"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite의 모든 기능 + 추가 기능"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce 및 Genesys 연동 서비스를 포함한 엔터프라이즈 구현 패키지"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 연동 서비스 개발"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 웨어하우스 연동"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심각한 불합격에 대한 즉각적인 알림을 위한 웹훅"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전담 고객 성공 관리자"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 및 엔지니어링 팀에 직접 액세스"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사전 체험 및 로드맵에 미치는 영향"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 검토 과제 및 목표 설정"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대상 지정 코칭 및 애널리틱스"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["학습 관리를 위한 퀴즈"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 역할 및 권한 관리"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민감한 데이터 마스킹"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 필터링 옵션 및 대화 검색"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러 평점, 조정 가능한 가중치 및 자동 불합격 옵션으로 사용자 지정 가능한 스코어카드"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어디서나 대화를 검토할 수 있는 브라우저 확장 기능"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인 분석"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사, 팀, BPO를 비교하기 위한 성과 리포팅"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 설문조사 및 고객 피드백 애널리틱스"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기능"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter의 모든 기능 + 추가 기능"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional(레거시)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화당 여러 평가 척도"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 연동"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 기반 대화형 대화 인사이트"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 검토"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 핀"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코칭 세션"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성 분석 및 필터링"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 복잡성에 따른 필터링"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 인사이트"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부 스코어카드"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인 분석"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 AI 기반 CSAT 애널리틱스"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채점 조정"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어디서나 대화를 검토할 수 있는 브라우저 확장 기능"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시를 통해 반드시 검토해야 하는 대화 감지"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문형 대화 번역"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 시간 추적"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 플랜"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter(레거시)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무제한 대화 검토"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 내보내기"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동료 간 검토 및 자가 검토"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 가능한 가중치 및 자동 불합격 옵션이 있는 스코어카드"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강력한 필터링 옵션"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일별, 주별 또는 월별 검토 목표"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 설문조사 및 기본 애널리틱스"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사, 팀, BPO를 비교하기 위한 보고서"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 역할 및 권한 관리"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기능"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 플랜을 변경하려고 합니다.\n\n하나의 옵션을 강조 표시하거나 지정하고 변경하려는 사용자 단위 라이선스 수를 지정합니다.\n[X개 사용자 단위 라이선스를 추가] [X개 사용자 단위 라이선스를 제거] [Zendesk QA 플랜으로 업그레이드]하고 싶습니다.\n\n회사 이름 및 Zendesk QA 계정과 연결된 이름은 물론 이 변경을 처리하기 위해 알아야 할 기타 모든 세부 정보를 공유해 주세요."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 플랜 변경"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 플랜을 구매하려고 합니다.\n\n회사 이름 및 Zendesk QA 계정과 연결된 이름은 물론 이 요청을 처리하기 위해 알아야 할 기타 모든 세부 정보를 공유해 주세요.\n\n구매하기 전에 해결하고자 하는 구체적인 질문도 알려주세요.\n\n감사합니다!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 플랜 구매"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["플랜이 ", _interpolate(_named("date")), "에 종료됩니다(", _interpolate(_named("days")), " 남음)."])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["평가판이 ", _interpolate(_named("date")), "에 종료됩니다(", _interpolate(_named("days")), " 남음)."])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1일"]), _normalize([_interpolate(_named("n")), "일"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매월 ", _interpolate(_named("dayOfMonth")), "일 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매월 ", _interpolate(_named("dayOfMonth")), "일 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매월 ", _interpolate(_named("dayOfMonth")), "일 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매월 ", _interpolate(_named("dayOfMonth")), "일 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매월 ", _interpolate(_named("dayOfMonth")), "일 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매월 ", _interpolate(_named("dayOfMonth")), "일 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("billingDate")), "에 ", _interpolate(_named("card")), " 카드(", _interpolate(_named("expires")), "에 만료)로 청구됩니다."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["끝 번호:"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["만료됨"]), _normalize(["1일 후 만료"]), _normalize([_interpolate(_named("expiresIn")), "일 후 만료"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["시작 가격에는 사용자 라이선스 ", _interpolate(_named("n")), "개가 포함됩니다."])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["최소 ", _interpolate(_named("sum")), "/월"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/월(사용자 1인당)"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/연"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/월"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분기"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 스케줄 삭제"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA를 계속 사용하려면 ", _interpolate(_named("link")), "하세요."])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "에 플랜이 취소되었습니다."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "에 플랜이 만료될 예정입니다."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["추가 비용 없이 ", _interpolate(_named("days")), "일 평가판을 이용해 보세요."])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용됨"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "명의 사용자가 있습니다. 사용자 단위 라이선스 수는 이보다 낮을 수 없습니다."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["플랜이 ", _interpolate(_named("time")), "에 다시 시작됩니다."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["현재 Zendesk QA 플랜(", _interpolate(_named("plan")), ")은 AI 기반 기능을 포함하지 않습니다. 음성 인텔리전스 기능을 최대한 활용하려면 ", _interpolate(_named("link")), "하세요."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["플랜을 취소하려면 ", _interpolate(_named("link")), "에서 문의하거나 지금 채팅을 시작하세요."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["품질 관리 계획"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["품질 관리"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜을 업데이트했습니다."])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신용카드 추가"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 플랜"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구 정보 보기"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구 간격"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 취소"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구 기간이 끝나면 플랜이 취소됩니다."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집 관련 문의"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 플랜"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 가격"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다운그레이드"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간(15% 할인)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 결제"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 편집"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 둘러보기"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료 평가판"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 청구됨"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 단계에서 최종 주문 요약을 검토하고 확인할 수 있습니다."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최고 인기글"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화된 결제 수단이 없습니다."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가판 사용 중"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜을 변경하려면 현재 플랜을 다시 시작하세요."])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 일시 중지"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월간"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분기별"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 재활성화"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 다시 시작"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 단위 라이선스"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 평가판 시작"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소됨"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비갱신"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시 중지됨"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 상태"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가판"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업그레이드"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 업그레이드"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠시 시간을 내어 고객 지원 경험에 대한 피드백을 제공해 주세요. 미리 감사드립니다. ", "{", "{", "agentName", "}", "}", "은(는) 고객님의 피드백을 소중하게 생각합니다!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안녕하세요. ", "{", "{", "customerName", "}", "}", "님! 👋"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "의 CSAT 설문조사"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["{", "{", "agentName", "}", "}", "의 지원을 어떻게 평가하시겠습니까?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["정말 감사합니다. 보내주신 피드백은 ", _interpolate(_list(0)), "에서 더 나은 서비스를 제공하는 데 사용됩니다."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사에 대해 알아보기"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 유형 선택"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나중에 변경할 수 있습니다."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국어로 제공되는 자동 CSAT 설문조사로 고객 만족도를 측정하고 개선하세요."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 설문조사 없음"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 만들기"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 이름"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 만들었습니다."])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["키워드를 변경해 보세요. 또는 여기에서 목록을 보려면 ", _interpolate(_named("contact_us"))])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["의견을 보내주세요."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어를 찾을 수 없음"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["아래 목록에서 하나 이상의 언어를 선택하세요. 여기에 사용하려는 언어가 없으면 ", _interpolate(_named("contact_us"))])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 추가"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["의견 보내기"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 추가"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["마지막 게시: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["마지막 저장: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 질문"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소개 질문"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["왼쪽 척도 레이블"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 제목"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오른쪽 척도 레이블"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사에 누락된 번역이 있는 경우에는 기본 언어의 텍스트가 대신 표시됩니다."])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역 적용"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역 누락"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["적용하기 전에 모든 번역을 검토하세요. 다운로드한 파일을 변경하고 저장한 다음 필요한 경우 다시 업로드합니다. ", _interpolate(_named("token_example")), " 토큰이 태그로 나타나지 않는 경우 잘못된 형식이거나 토큰을 지원하지 않는 필드에 있는 것입니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역 검토"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역됨"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 요약하고 댓글 추천을 표시합니다."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI 어시스트(영문)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형을 만든 후에는 편집할 수 있습니다."])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 유형은 이미 있습니다. 다른 이름을 선택하세요."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 유형 드롭다운에서 사용자 지정 유형을 선택 가능한 옵션으로 저장하고 대시보드에서 만든 모든 설문조사를 그룹화합니다."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어를 입력하고 Enter 키를 눌러 저장하세요."])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 유형 편집"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형 이름은 반드시 입력해야 합니다."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형 정의"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 유형을 저장하지 못했습니다. Enter 키를 눌러 저장합니다."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸 사람의 이메일"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸 사람의 이름"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["다음 문장에 동의하는 정도를 나타내주세요. “", _interpolate(_named("agentName")), "이(가) 내 문제를 쉽게 해결할 수 있게 해 주었나요?”"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["지금까지 ", _interpolate(_named("brandName")), "의 도움을 받는 것이 얼마나 쉬웠나요?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전반적으로 오늘 얼마나 쉽게 문제를 해결했나요?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저희 팀과 얼마나 쉽게 소통했나요?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호방침 URL(선택 사항)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 약관 URL(선택 사항)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["고객님이 ", _interpolate(_named("agentName")), "에게 받았던 지원 서비스에 대해 어떻게 평가하시나요?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 요청에 대한 서비스는 어땠나요?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원 담당자에 얼마나 만족하셨나요?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["오늘 ", _interpolate(_named("agentName")), "의 서비스에 얼마나 만족하시나요?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 질문"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["사용자 지정 ", _interpolate(_named("type")), " 질문 정의..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 질문 입력"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL 사용자 지정"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 메시지 설정"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 질문 유형의 이름을 편집하면 수집된 데이터에 영향을 주지 않고 해당 질문 유형을 사용하는 모든 설문조사에서 업데이트됩니다."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 유형 편집"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 제목"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소개 질문"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 질문"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 사용자 지정 유형 만들기"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["개인화 토큰"]), _normalize(["개인화 토큰"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["개인화 토큰을 아래 필드에 드래그 앤 드롭합니다."]), _normalize(["개인화 토큰을 아래 필드에 드래그 앤 드롭합니다."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["대화 ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금은 이메일을 통해서만 CSAT 설문조사를 보낼 수 있습니다. Zendesk는 헬프 데스크 CSAT 설문조사, SMS 설문조사 등 더 많은 기회를 제공하기 위해 노력하고 있습니다."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["연결 동기화 또는 갱신"]), _normalize(["연결 동기화 또는 갱신"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 동기화 또는 갱신 ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["연결이 올바르게 동기화되지 않습니다."]), _normalize(["연결이 올바르게 동기화되지 않습니다."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 확인란을 선택 취소 ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 인증 ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 설문조사 발송 규칙을 추가하여 연결을 활성화하세요."])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 연결은 현재 사용 중지되어 있습니다. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 연결은 민감한 클라이언트 데이터를 마스킹합니다. 설문조사 발송을 사용 설정하려면 설정에서\n", _interpolate(_named("link")), "하세요."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 연결에 설문조사를 보낼 수 있는 추가 권한이 필요합니다.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 인증"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA를 사용하려면 Intercom에 더 많은 권한이 필요합니다."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " 및 ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1시간"]), _normalize([_interpolate(_named("n")), "시간"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "분"]), _normalize(["1분"]), _normalize([_interpolate(_named("n")), "분"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["시간"]), _normalize(["시간"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), "하여 설문조사 보내기 규칙을 구성합니다."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" 일"]), _normalize(["일"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 링크 만료"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 시간 후 링크 만료"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간이 너무 많이 경과한 경우 설문조사에 응답하지 않도록 합니다."])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["및"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 설문조사 ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쉼표로 태그를 구분하거나 작성한 후 Enter 키를 누릅니다. 각 전화번호가 Intercom에서처럼 정확하게 나열되도록 하세요."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 발송의 기준이 되는 조건을 설정합니다."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 발송 규칙"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 후속 작업 발송 규칙"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["설문조사 ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), "이(가) 이미 있습니다.\n여기 또는 ", _interpolate(_named("link")), "에서 규칙을 수정하여 충돌을 해결하세요."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 설문조사에 대한 일치 규칙으로 이미 연결을 설정했습니다. 여기 또는 ", _interpolate(_named("link")), "에서 규칙을 수정하여 충돌을 해결하세요."])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 규칙 추가"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 추가"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건 선택"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값 검색"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그 추가"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 규칙 일치"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값을 선택하세요."])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 시간 후 이메일 전송"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 시간 후 후속 이메일 전송"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임베디드 스니펫 및 이메일 후속 작업"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드 복사"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 설정에 스니펫 코드를 추가하여 Zendesk QA 설문조사를 이메일에 첨부하세요."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설치 가이드"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 열기"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스니펫 미리 보기"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스니펫 코드를 클립보드에 복사했습니다."])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스니펫을 복사하지 못했습니다."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임베디드 스니펫"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 설문조사에 저장되지 않은 변경 내용이 있습니다. 스니펫 코드가 최신 업데이트를 반영하도록 다시 저장하세요."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 추가"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 추가된 연결 없음"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결과 함께 이메일, 실시간 채팅, 전화 통화 등을 Zendesk QA로 가져오세요."])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 전달에 대해 알아보기"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 발송 규칙"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 후속 작업 발송 규칙"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 규칙 추가"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["규칙 추가"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + 이메일 후속 작업"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 계정을 Zendesk QA에 연결하기 CSAT 설문조사"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음을 통해 전달"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 받는 사람과 시기"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["규칙 편집"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 설정됨"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앱 내 설문조사를 통해 고객이 채팅에서 나가지 않고 설문조사를 완료할 수 있습니다."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom을 실행"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결당 발송 규칙"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["설문조사 ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), "이(가) 이미 있습니다.\n여기 또는 ", _interpolate(_named("link")), "에서 연결을 사용 중지하거나 규칙을 수정하여 충돌을 해결하세요."])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발송"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 발송의 기준이 되는 조건 설정"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), "에 ", _interpolate(_named("name")), "님이 만듦"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("languageName")), " 제거"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 언어를 제거하면 어떤 응답자에게도 더 이상 표시되지 않으며 번역된 모든 텍스트가 삭제됩니다."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 제거"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업은 실행 취소할 수 없습니다."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 설문조사에서 언어를 숨겨 사용 중지할 수 있습니다. 이렇게 하면 응답자에게 표시하지 않고 모든 번역을 그대로 유지할 수 있습니다."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), "에 ", _interpolate(_named("name")), "님이 마지막으로 편집함"])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 언어에 대해 알아보기"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["다른 언어를 추가하기 전에 스타일링 및 콘텐츠 단계에서 변경 작업을 완료했는지 확인하세요. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 추가"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역된 필드"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답자에게 표시"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본값"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역 파일 다운로드"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 제거"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 번역 파일을 다운로드"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역을 검토"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["번역이 완료되었습니다. ", _interpolate(_named("review_link")), "하거나 ", _interpolate(_named("download_link")), "하여 필요한 변경 내용을 적용하세요."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 번역 파일을 다운로드"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누락된 부분을 검토"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["일부 콘텐츠는 아직 번역되지 않았으며 영어로 대체됩니다. ", _interpolate(_named("review_link")), "하거나 ", _interpolate(_named("download_link")), "하여 누락된 번역을 추가하세요."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 저장"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["설문조사에 저장되지 않은 변경 내용이 포함되어 있습니다. ", _interpolate(_named("save")), "하여 최신 변경 내용이 포함된 번역 파일을 다운로드하세요"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["번역 파일 업로드"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 평가 척도 선택"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객에게 평점을 주는 이유를 선택할 수 있는 드롭다운을 제공합니다."])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이모지"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["왼쪽 척도 텍스트(선택 사항)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유 추가"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유는 반드시 입력해야 합니다."])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 이유는 이미 있습니다."])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수의 이유에 대한 드롭다운 표시"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유를 삭제하고 같은 이름으로 다시 추가하거나 이유 이름을 변경하면 항상 새 항목이 만들어집니다. 따라서 대시보드에 표시되는 데이터에 영향을 미칩니다."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유 삭제"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제된 이유는 대시보드 리포팅에 계속 남아 있지만 새 설문조사 응답자에게는 보이지 않습니다. 이는 관련 번역에도 적용됩니다."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유 삭제"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이유 \"", _interpolate(_list(0)), "\" 삭제"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오른쪽 척도 텍스트(선택 사항)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5점 척도에서 1과 2는 부정적 점수, 3은 중립, 4와 5는 긍정적 점수로 간주됩니다."])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["축척 텍스트 추가"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 척도"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 척도"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에서 대화를 가져오는 중입니다. 이 프로세스는 최대 24시간까지 걸릴 수 있습니다."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 전반에 걸쳐 개인화 토큰으로 사용됩니다."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 이름"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼 및 링크"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 디자인 사용자 지정"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고 배경"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, PNG 및 GIF 형식이 지원됩니다."])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스타일링"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로고 업로드"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스타일리시한 마무리"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백을 제출한 후 사람들이 보게 될 메시지를 입력하세요."])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사 메시지"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 16진수 색 코드"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 이메일 주소"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 비워 둘 수 없습니다."])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시되지 않음:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 날짜"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보낸 설문조사"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난 30일 동안 보낸 설문조사"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 삭제했습니다."])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사가 중복되었습니다."])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기 이메일을 보냈습니다."])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사가 게시되었습니다."])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사가 업데이트되었습니다."])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사가 일시 중지되었습니다."])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복제"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시 중지"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 일시 중지"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 미리 보기를 보낼 이메일을 입력하세요."])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 볼 언어 선택"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기 이메일 보내기"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일로 설문조사 예시를 보냅니다. 설문조사 미리 보기의 평점은 저장되지 않습니다."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 설문조사 미리 보기"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["웹에서 이메일 설문조사 미리 보기"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 시작"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안으로 저장"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드 이름"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드별 이름은 스타일링 단계에서 추가할 수 있습니다."])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객 이름"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원 ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원 담당자"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제됨"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시 중지됨"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "의 CSAT 설문조사"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되지 않은 변경 내용"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 업데이트 및 다시 시작"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 이메일이 올바르게 표시되지 않는 경우 ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제가 받은 지원에 만족하지 못했습니다. 이유는..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA 제공"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유 선택"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 요약"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: 고객이 결제 수단을 업데이트하는 데 문제가 발생했습니다. 상담사가 도움이 될 만한 문서를 공유했습니다. 도움이 되었습니다."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA AI 제공"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 약관"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백을 주셔서 감사합니다."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수를 준 주된 이유는 무엇인가요?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 평점을 준 이유를 알려주세요..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["많은 노력"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적은 노력"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기를 클릭하여 설문조사를 여세요."])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 질문이 여기에 나타납니다."])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 경험에 다음 점수를 주셨습니다."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 제출"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감사합니다."])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 취소"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매우 불만족"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매우 만족"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 설문조사 상태 및 전달 규칙은 설문조사 복제본에 포함되지 않습니다."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["설문조사 '", _interpolate(_list(0)), "' 중복"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임베디드 이메일 스니펫을 사용하는 경우에는 설문조사를 일시 중지하기 전에 반드시 Zendesk에서 제거하세요."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사가 일시 중지되면 응답이 수집되지 않습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 일시 중지"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 일시 중지하기 전에 반드시 Zendesk에서 스니펫을 제거하세요."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 삭제"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임베디드 이메일 스니펫을 사용하는 경우에는 설문조사를 삭제하기 전에 반드시 Zendesk에서 제거하세요."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업을 수행하면 설문조사가 영구 삭제됩니다. 설문조사를 받은 사용자는 여전히 설문조사를 작성할 수 있으며 설문조사와 관련된 모든 데이터는 Zendesk QA에 남게 됩니다."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["설문조사 ", _interpolate(_named("surveyName")), " 삭제"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 삭제하기 전에 반드시 Zendesk에서 스니펫을 제거하세요."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용된 변경 내용이 새 응답자에게 표시됩니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 게시"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 게시한 후에는 반드시 임베디드 스니펫의 최신 버전을 복사하세요."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임베디드 이메일 스니펫을 사용하는 경우에는 설문조사를 다시 시작한 후 반드시 Zendesk에 복사하세요."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속해서 설문조사를 보내고 응답을 수집합니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 업데이트 및 다시 시작"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 다시 시작한 후에는 반드시 Zendesk에 임베디드 스니펫을 추가하세요."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 업데이트"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 업데이트한 후에는 반드시 임베디드 스니펫의 최신 버전을 복사하세요."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 설문조사를 삭제하시겠습니까? 이 작업은 실행 취소할 수 없습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 삭제하시겠어요?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가판이 만료되었지만 정식 계정으로 업그레이드하여 Zendesk QA를 계속 이용하실 수 있습니다."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가판이 만료되었지만 정식 계정으로 업그레이드하여 Zendesk QA를 계속 이용하실 수 있습니다. 로컬 관리자에게 문의하여 설정하세요."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가판이 종료되었습니다."])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 업그레이드"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료 평가판이 종료되었습니다."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA의 정식 버전을 계속 사용하려면 결제 세부 정보를 입력하세요."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA의 정식 버전을 계속 사용하려면 계정 관리자가 결제 세부 정보를 입력해야 합니다."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 관리"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "에 ", _interpolate(_named("authorName")), "님이 만듦"])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 과제"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 작업 중인 것 같습니다. 변경 내용을 저장하지 않고 나가면 지금까지 추가한 모든 정보를 잃게 됩니다."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 과제는 매주 ", _interpolate(_named("weekDay")), "마다 격주로 반복됩니다."])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 과제는 매주 ", _interpolate(_named("weekDay")), "마다 반복됩니다."])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1주기에 모든 검토 대상자 포함"]), _normalize([_interpolate(_named("nr")), "주기에 모든 검토 대상자 포함"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "개 대화"]), _normalize([_interpolate(_named("n")), "개 대화"]), _normalize([_interpolate(_named("n")), "개 대화"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["/", _interpolate(_named("nr")), "명의 상담사"]), _normalize(["/", _interpolate(_named("nr")), "명의 상담사"]), _normalize(["/", _interpolate(_named("nr")), "명의 상담사"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["참여자"]), _normalize(["참여자"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["주기당 대화 ", _interpolate(_named("n")), "개"]), _normalize(["주기당 대화 ", _interpolate(_named("n")), "개"]), _normalize(["주기당 대화 ", _interpolate(_named("n")), "개"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["검토 대상자당 연속 ", _interpolate(_named("reviewsCount"))])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토 대상자"]), _normalize(["검토 대상자"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토자"]), _normalize(["검토자"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스 사용자"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사를 제외한 모든 워크스페이스 사용자"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleNumber")), "주기 • ", _interpolate(_named("startDate")), "~", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "~", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비반복"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 과제"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 중"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제에 대해 알아보기"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 만들기"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업을 수행하면 과제가 영구 삭제되고 대시보드에서 해당 데이터가 제거됩니다. 이와 관련된 모든 검토는 Zendesk QA에 계속 남아 있게 됩니다."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["과제 ", _interpolate(_named("assignmentName")), " 삭제"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 삭제됨"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 복제됨"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 추가된 과제 없음"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 만들지 못했습니다."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 이름"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주기가 새로고침되고 새 작업 목록이 만들어집니다. 이미 제공된 검토도 목표에 반영됩니다."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉시"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "에 다음 주기 시작 시"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언제 변경 내용을 게시하나요?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 과제에 대한 변경 내용이 이 과제와 함께 게시됩니다."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 활성으로 설정하시겠어요?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 시작일에 반복"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 금요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 금요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 금요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 금요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 금요일"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 월요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 월요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 월요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 월요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 월요일"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 토요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 토요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 토요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 토요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 토요일"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 일요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 일요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 일요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 일요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 일요일"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 목요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 목요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 목요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 목요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 목요일"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 화요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 화요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 화요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 화요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 화요일"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 첫 번째 수요일"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 두 번째 수요일"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 세 번째 수요일"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 네 번째 수요일"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 마지막 수요일"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 반복"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 1일"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫 번째"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["두 번째"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세 번째"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네 번째"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반복"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평일"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과 없음"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간대"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주기 설정"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 이름"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 정보"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 과제 주기가 시작될 때 평가를 위해 미리 결정된 대화 목록이 생성됩니다."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 설정"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 목표가 충족되지 않으면 자동으로 새 대화 가져오기"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 목표 비율(%)에서는 자동 가져오기를 사용할 수 없습니다."])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소수점이 아닌 정수여야 합니다."])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 바꾸기 허용(검토자 역할 이상)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 바꾸기"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자가 검토를 위해 자신의 대화를 배정하도록 허용"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 수"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 검토 수는 1개 이상이어야 합니다."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 검토 대상자에 대한 최소 검토 수를 지정합니다."])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비율(%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 수"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개 검토/총계"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 검토자는 정해진 수의 검토를 수행해야 합니다."])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자는 정해진 수의 대화를 함께 검토해야 합니다."])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 검토 수는 1개 이상이어야 합니다."])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 목표는 1~100% 사이여야 합니다."])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표 검토"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 검토 대상자는 총 대화 중 최소 개수를 검토받아야 합니다."])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자는 공유 풀에서 대화를 선택할 수 있습니다."])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자에게 공유 풀의 대화가 배정됩니다."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 접근 방식"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 봇"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스 참여자"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스 사용자"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자 추가"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누가 검토되나요?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자 선택"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상 선택"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스 사용자 또는 그룹이 선택된 경우에만 제외가 허용됩니다."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 및 검토 대상자"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["현재: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 기본값"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어떤 유형의 사용자를 검토 대상자로 선택해야 하나요?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 활동적인 상담사"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자 제외(선택 사항)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외할 사용자 선택"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 제외(선택 사항)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외할 사용자 선택"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사를 제외한 모든 워크스페이스 사용자"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 추가"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누가 검토를 수행하나요?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 선택"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스에서는 자가 검토가 허용되지 않습니다."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토 허용"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스에서는 자가 검토가 허용되지 않습니다."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["오늘 일치하는 대화"]), _normalize(["오늘 일치하는 대화"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제가 오늘 시작된 것처럼 표시합니다."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["향후 수치는 다를 수 있습니다."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 검토 대상자 없음"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 검토자 없음"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토 대상자"]), _normalize(["검토 대상자"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토자"]), _normalize(["검토자"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 가져오기: 대화가 충분하지 않은 경우 허용"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 금요일에"])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 월요일에"])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 토요일에"])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 일요일에"])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 목요일에"])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 화요일에"])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "부터 ", _interpolate(_named("cycleLength")), " 수요일에"])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["매일 ", _interpolate(_named("startDateTime")), "부터"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), "에 시작하고 ", _interpolate(_named("endDateTime")), "에 종료됩니다."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 날짜 조건"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["대화 및 헬프 데스크 조건 ", _interpolate(_named("count")), "개"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["대화 없음, 헬프 데스크 조건 ", _interpolate(_named("count")), "개"]), _normalize(["대화 없음, 헬프 데스크 조건 ", _interpolate(_named("count")), "개"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["대화 ", _interpolate(_named("count")), "개, 헬프 데스크 조건 없음"]), _normalize(["대화 ", _interpolate(_named("count")), "개, 헬프 데스크 조건 없음"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["접근 방식: 검토자가 대화 선택"]), _normalize(["접근 방식: 검토자에게 대화 배정"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속하려면 요약에 강조 표시된 오류를 수정하세요."])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표: 검토자가 모든 대화를 검토해야 함"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["목표: 검토자가 하나의 대화를 검토해야 함"]), _normalize(["목표: 검토자가 ", _interpolate(_named("count")), "개의 대화를 검토해야 함"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["목표: 검토자가 ", _interpolate(_named("count")), "%의 대화를 검토해야 함"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표: 설정된 검토 목표 없음"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표: 각 검토 대상자가 자신의 모든 대화를 검토해야 함"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["목표: 각 검토 대상자가 총 대화 중 ", _interpolate(_named("count")), "개를 검토해야 함"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["목표: 각 검토 대상자가 총 대화 중 ", _interpolate(_named("count")), "%를 검토해야 함"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["목표: 각 검토자가 하나의 검토를 수행해야 함"]), _normalize(["목표: 각 검토자가 ", _interpolate(_named("count")), "개의 검토를 수행해야 함"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["바꾸기: 검토자가 대화를 바꿀 수 없음"]), _normalize(["바꾸기: 검토자가 대화를 바꿀 수 있음"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자가 검토: 검토자가 자신의 대화에 배정될 수 있음"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정된 날짜 조건 없음"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정된 과제 이름 없음"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요약"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateTime")), "의 ", _interpolate(_named("weekday"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["격주"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매일"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안 함"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매주"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주기 길이"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성으로 설정"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안으로 저장"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 초안으로 저장함"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제가 비활성으로 설정됨"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성으로 설정"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 만들기"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 만듦"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성으로 표시"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성으로 표시"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["과제에 대한 보류 중인 업데이트는 ", _interpolate(_list(0)), "에 시작하는 다음 주기에 적용됩니다."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 게시"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 업데이트"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 업데이트됨"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사나 봇이 대화를 마무리했는지 분석합니다."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 대화를 정중하게 마무리했는지 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 마무리되었는지 분석"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사와 봇 상호작용에서 문법, 맞춤법 및 스타일 오류를 분석합니다."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사의 문법, 철자 및 스타일 오류 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사의 문법, 철자 및 스타일 오류 분석"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤법 및 문법"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사 및 봇 인사말에 대한 대화를 분석합니다."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반적인 인사말 문구가 있는지 전체 대화 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반적인 인사말이 있는지 전체 대화 분석"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 감정을 이해하고 인정하는지 분석"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객 정서를 반영하거나 '인정' 및 '공감'과 같은 배려하는 표현을 사용한 사례를 식별합니다."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공감"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 질문이나 우려 사항을 이해하는지 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 질문이나 우려 사항을 이해하는지 분석"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이해도"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어의 복잡성과 문장의 길이를 고려하여 텍스트가 얼마나 쉽게 이해될 수 있는지 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어의 복잡성과 문장의 길이를 고려하여 텍스트가 얼마나 쉽게 이해될 수 있는지 분석"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 메시지에는 너무 긴 문장이 1개 포함되어 있어 읽기가 어렵습니다."])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["이 메시지에는 너무 긴 문장이 ", _interpolate(_list(0)), "개 포함되어 있어 읽기가 어렵습니다."])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가독성"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 솔루션에 대한 전체 대화 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 솔루션에 대한 전체 대화 분석"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["솔루션 제공됨"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내내 상담사의 어조 분석"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 내내 상담사의 어조 분석"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어조"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사의 메시지에서 실수를 감지할 수 없음"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문법 오류를 확인할 수 없음"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사말 문구를 찾을 수 없습니다. 필요한 경우 설정에서 관리자를 통해 새 항목을 추가하세요."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 정중하게 대화를 마무리함"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맺음말 문구를 찾을 수 없습니다. 필요한 경우 설정에서 관리자를 통해 새 항목을 추가하세요."])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객 정서를 반영하거나 배려하는 표현을 사용함"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객 정서를 반영했는지 또는 배려하는 표현을 사용했는지 감지할 수 없음"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 긴 문장과 복잡한 단어를 사용하여 일부 사람들이 이해하기 어려울 수 있음"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 복잡한 단어를 사용하여 일부 사람들이 이해하기 어려울 수 있음"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 긴 문장을 사용하여 일부 사람들이 이해하기 어려울 수 있음"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 이해하기 쉬운 언어를 사용함"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 카테고리를 평가하기에 데이터가 충분하지 않음"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 질문/요청을 파악하는 데 어려움을 겪음"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 질문/요청을 잘 이해함"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객의 질문/요청을 이해했는지 여부를 감지할 수 없음"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 상담사의 어조"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 상담사의 어조"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 상담사의 어조"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 상담사의 어조"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 상담사의 어조"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사의 어조를 확인할 수 없음"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 마무리되지 않음"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 마무리됨"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 마무리되었는지 감지할 수 없음"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객과 고객의 문제에 대한 상담사의 공감 부족"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객과 고객의 문제에 대해 상담사가 공감을 나타냄"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객과 고객의 문제에 공감을 표시했는지 감지할 수 없음 "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 고객에게 인사말을 건네지 않음"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 중 고객에게 인사말을 건넴"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반적인 인사말을 감지할 수 없음"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객에게 솔루션을 제공하지 않음"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객에게 솔루션을 제공함"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 고객에게 솔루션을 제공했는지 감지할 수 없음"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복잡한 단어"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["긴 문장"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문법"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤법 오류"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사과"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침착함"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쾌활함"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호기심 있음"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문적임"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["협력적임"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스타일"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["맺음말 1개"]), _normalize(["맺음말 ", _interpolate(_named("n")), "개"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA는 승인된 맺음말만 확인합니다. 이 카테고리가 '중요'로 설정되어 있는 경우 승인된 맺음말을 사용하지 않으면 검토에 불합격합니다."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 맺음말"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["예외 1개"]), _normalize(["예외 ", _interpolate(_named("n")), "개"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 및 문구 자동 QA는 철자 또는 문법 오류로 무시합니다."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤법 및 문법 예외"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 예외"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["인사말 1개"]), _normalize(["인사말 ", _interpolate(_named("n")), "개"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA는 승인된 인사말만 확인합니다. 이 카테고리가 '중요'로 설정되어 있는 경우 승인된 인사말을 사용하지 않으면 검토에 불합격합니다."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승인된 인사말"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 카테고리"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 카테고리 선택"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 배정된 근본 원인:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 → 설정"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["일부 카테고리는 MS Azure를 활용하여 확장된 기능과 지원되는 언어를 제공합니다. ", _interpolate(_named("link")), "에서 자세한 내용을 확인하거나 관리자에게 문의하세요."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["MS Azure를 활용하여 확장된 카테고리 및 지원되는 언어 목록에 액세스하세요. ", _interpolate(_named("link")), "을 검토하거나 관리자에게 연락하여 요청하세요."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 사용"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["자동 QA 카테고리에는 고정 평가 척도를 사용하여 자동으로 점수가 배정됩니다. 나중에 수동으로 평점을 조정할 수 있습니다. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA 카테고리 선택"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["자동으로 배정되는 점수는 실수의 심각도와 실수의 개수에 따라 다릅니다. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 평가 카테고리만 검토자에게 표시됩니다. 카테고리를 만들 때 설정한 조건에 따라 평가 카테고리가 표시됩니다."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채팅"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["온보딩"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["워크스페이스 '", _interpolate(_named("workspace")), "'에 대한 평가 카테고리 유형을 선택하세요."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자가 각 대화에 대한 관련 평가 카테고리를 보려면 올바른 스코어카드를 수동으로 선택해야 합니다."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 유형을 변경하시겠어요?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 변경"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 유형을 변경하시겠어요?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부 평가 카테고리"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정적 평가 카테고리"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 유형"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 설정"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업은 실행 취소할 수 없습니다."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 평점이 삭제"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 카테고리를 보관하여 데이터를 삭제하지 않고 통계를 유지합니다."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["이 카테고리에 대해 제공된 ", _interpolate(_named("deleteAllRatings")), "된다는 것에 동의합니다."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: 어조 완벽"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 경우에만 카테고리 표시"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'항상'은 무시됩니다. 다른 조건과 함께 사용할 수 없습니다."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항상"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건 선택"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만족도 점수(CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스 유형"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 채널"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리에 이름이 필요합니다."])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드에 카테고리 추가"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 만들기"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 만들기"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 추가됨"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관된 카테고리는 스코어카드에 표시되지 않지만 데이터를 삭제하지 않고 통계를 유지할 수 있습니다."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 보관"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 카테고리 보관"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 보관됨"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 삭제"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 카테고리를 삭제하려면 'delete category'를 입력하세요."])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 삭제"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["카테고리 ", _interpolate(_list(0)), " 삭제"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 삭제됨"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 중복됨"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 그룹 추가됨"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 그룹 삭제됨"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 그룹 순서 변경됨"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 그룹 업데이트됨"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 보관 취소됨"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 카테고리 업데이트됨"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담사가 중요 카테고리에 불합격하면 해당 검토의 모든 카테고리가 자동으로 불합격됩니다."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요 평가 카테고리"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복제"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 그룹에 카테고리 없음"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 평가 카테고리 만들기"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 편집"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 카테고리 삭제 및 이 그룹의 데이터 검토"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 그룹을 삭제하면 연결된 모든 카테고리가 그룹 해제된 상태로 남게 됩니다."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " 카테고리 그룹 삭제"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 아래의 카테고리를 삭제하면 이러한 카테고리와 연결된 모든 검토 데이터를 잃게 됩니다."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 이름"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자는 근본 원인으로 하나 이상의 이유를 선택할 수 있습니다."])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자는 근본 원인으로 하나 이상의 이유를 선택할 수 있습니다."])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러 이유"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예: 어조"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 카테고리 그룹 추가"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 그룹"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'기타' 추가"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인 추가"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 근본 원인이 이미 있습니다."])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인 추가"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인을 추가하여 평점 설명"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 척도"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관됨"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 평가 카테고리에 스코어카드 추가"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관 취소"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리 업데이트"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 평점"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시 대상"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적 평점"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부정적 및 중립 평점"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예, 업데이트합니다."])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가중치"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 중요한 카테고리는 티켓 점수 계산에서 더 많은 가중치를 가질 수 있습니다."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["가중치(", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정에 대해 알아보기"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정에 대해 알아보기"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정기적인 조정 세션을 진행하면 검토자가 평가 기법을 조정하여 상담사가 동일한 수준의 일관되고 편견 없는 피드백을 받을 수 있습니다."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 보기"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "은 팀이 모든 고객 상호작용을 처리하고 평가하는 방법에 대해 동일한 정보를 공유하는 데 도움이 되는 프로세스입니다."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["조정은 ", _interpolate(_list(0)), " 및 ", _interpolate(_list(1)), " 플랜에서 사용할 수 있습니다."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화가 세션에 추가될 때 이전에 제공된 검토는 표시되지 않습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시되지 않음"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전에 제공된 검토는 조정 검토로 간주됩니다. 검토자당 하나의 대화 검토만 세션에 추가됩니다. 메시지별 검토는 표시되지 않습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전에 제공된 검토의 표시 대상"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["조정은 팀이 모든 고객 상호작용을 처리하고 평가하는 방법에 대해 동일한 정보를 공유하는 데 도움이 되는 프로세스입니다. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자는 조정 세션 중 제공된 모든 검토에 무제한 액세스할 수 있습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항상 모든 검토 보기"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리드"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 제출한 후 사용자는 조정 세션 중 다른 검토자가 제공한 평가를 볼 수 있습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 제출한 후 모든 조정 검토 보기"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자는 조정 세션 중 제공된 모든 검토에 무제한 액세스할 수 있습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항상 모든 검토 보기"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 제출한 후 사용자는 조정 세션 중 다른 검토자가 제공한 평가를 볼 수 있습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 제출한 후 모든 조정 검토 보기"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 제출한 후 사용자는 조정 세션 중 다른 검토자가 제공한 평가를 볼 수 있습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토를 제출한 후 모든 조정 검토 보기"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자는 조정 세션 동안 제공된 자신의 검토만 볼 수 있습니다."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자신의 검토만 보기"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 설정을 업데이트했습니다."])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 검토의 표시 대상"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팀 내부 메트릭 시스템에 따라 부족한 부분을 채워 대시보드 메트릭의 임계값을 설정하세요. 대시보드에서 임계값을 검토합니다."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임계값"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임계값을 업데이트했습니다."])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠시만 기다려 주세요..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["관리자 권한이 있는지 확인하고 ", _interpolate(_named("retry")), "하거나 추가 도움을 위해 ", _interpolate(_named("contact")), "해 주세요."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자의 헬프 데스크 API에 액세스하지 못한 것 같습니다."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 시도"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크에 연결하는 중입니다. 잠시 후 리디렉션됩니다."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 권한 부여됨"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "로 돌아가서 새 헬프 데스크 연결을 추가합니다."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한두 단계를 놓친 것 같습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 이메일 도메인을 가진 조직이 이미 있습니다."])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 페이지"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그동안..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한을 확인하는 동안 잠시 기다려 주세요."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그동안..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연동 중"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 만드세요"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA를 계속 사용하려면 ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 준비가 거의 완료되었습니다."])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 찾을 수 없음"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 전환"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA를 계속 사용하려면 관리자가 사용자를 워크스페이스에 배정해야 합니다."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["계정을 삭제하면 ", _interpolate(_named("bold")), " 보유한 모든 데이터가 영구 삭제됩니다."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지정된 모든 평점, 대화 콘텐츠, 워크스페이스 등"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack에 추가"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 해제"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연동 서비스를 연결 해제하면 Slack을 통한 모든 Zendesk QA 알림이 사용 중지됩니다."])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 연결 해제"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 연동이 Zendesk QA에서 연결 해제되었습니다."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 신규 계정 사용자에 대한 기본 알림 설정을 정의합니다."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 사용자에 대한 설정 무시"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 계정 사용자에 대한 알림 설정을 무시하려고 합니다."])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자는 자신의 알림 설정을 정의할 수 없습니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 설정을 업데이트했습니다."])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["웹훅"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요 카테고리에서 불합격일 때 알림"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT가 응답을 받을 때 알림"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트리거"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 워크스페이스 및 데이터 만들기"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 데이터를 다시 만들고 데모 워크스페이스를 유지하세요."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 워크스페이스 및 데이터 제거"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 데이터 새로 고침"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 새로 고침"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["계정 '", _interpolate(_named("accountName")), "' 관리 데모 모드"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데모 모드"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/6059285322522-Zendesk%EC%9D%98-%EC%83%9D%EC%84%B1%ED%98%95-AI-%EA%B8%B0%EB%8A%A5%EC%97%90-%EB%8C%80%ED%95%9C-%EC%A0%95%EB%B3%B4"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043712839450-%ED%97%AC%ED%94%84-%EB%8D%B0%EC%8A%A4%ED%81%AC-%EC%97%B0%EA%B2%B0%EC%9D%84-%EC%9C%84%ED%95%9C-%EA%B3%A0%EA%B8%89-%EB%B3%B4%EC%95%88-%EC%84%A4%EC%A0%95-%EC%9D%B4%ED%95%B4%ED%95%98%EA%B8%B0"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043747123354-%EC%9E%90%EB%8F%99-QA%EB%A5%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EC%97%AC-Zendesk-QA%EC%97%90%EC%84%9C-%EC%9E%90%EB%8F%99-%EC%A0%90%EC%88%98-%EB%A7%A4%EA%B8%B0%EA%B8%B0-%EC%84%A4%EC%A0%95%ED%95%98%EA%B8%B0"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7418648293018-Zendesk-QA%EB%A5%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EC%97%AC-AI-%EC%83%81%EB%8B%B4%EC%82%AC%EC%9D%98-%EC%84%B1%EA%B3%BC-%ED%8F%89%EA%B0%80%ED%95%98%EA%B8%B0"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043724530842-Zendesk-QA%EC%97%90%EC%84%9C-%EC%A1%B0%EC%A0%95-%EC%84%A4%EC%A0%95%ED%95%98%EA%B8%B0"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043759436954-%EC%8B%9C%EA%B0%84-%EA%B2%BD%EA%B3%BC%EC%97%90-%EB%94%B0%EB%A5%B8-%EC%B9%B4%ED%85%8C%EA%B3%A0%EB%A6%AC-%EC%A0%90%EC%88%98-%EB%B6%84%EC%84%9D%ED%95%98%EA%B8%B0"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043747231642-%EB%8C%80%ED%99%94-%EC%9D%B8%EC%82%AC%EC%9D%B4%ED%8A%B8-%EB%B3%B4%EA%B8%B0-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043747649690-%EC%97%AC%EB%9F%AC-%EA%B0%9C%EC%9D%98-%EC%8A%A4%EC%BD%94%EC%96%B4%EC%B9%B4%EB%93%9C-%EB%A7%8C%EB%93%A4%EA%B8%B0"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043701093786-Zendesk-QA-%EA%B2%80%ED%86%A0-%EB%8C%80%EC%8B%9C%EB%B3%B4%EB%93%9C-%EA%B3%84%EC%82%B0-%EC%9D%B4%ED%95%B4%ED%95%98%EA%B8%B0"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043759449114-%EC%82%AC%EC%9A%A9%EC%9E%90-%EC%A7%80%EC%A0%95-%ED%95%84%ED%84%B0%EB%A5%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EC%97%AC-%EA%B2%80%ED%86%A0%ED%95%A0-%EB%8C%80%ED%99%94-%EC%B0%BE%EA%B8%B0"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043760162074-%EC%82%AC%EC%9A%A9%EC%9E%90-%EB%B0%8F-%EC%9B%8C%ED%81%AC%EC%8A%A4%ED%8E%98%EC%9D%B4%EC%8A%A4-%EA%B4%80%EB%A6%AC%ED%95%98%EA%B8%B0"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043759543194-%EA%B3%A0%EC%A0%95%EB%90%9C-%EB%A9%94%EB%AA%A8%EB%A5%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EC%97%AC-%EC%83%81%EB%8B%B4%EC%82%AC-%EC%BD%94%EC%B9%AD"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043747142938-Zendesk-QA%EC%97%90%EC%84%9C-%EA%B7%B8%EB%A3%B9-%EA%B4%80%EB%A6%AC%ED%95%98%EA%B8%B0"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043759586074-Zendesk-QA-%EA%B0%95%EC%A1%B0-%ED%91%9C%EC%8B%9C-%EC%9D%B8%EC%82%AC%EC%9D%B4%ED%8A%B8%EB%A5%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EC%97%AC-%EB%8C%80%ED%99%94-%ED%95%84%ED%84%B0%EB%A7%81%ED%95%98%EA%B8%B0"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/articles/7043759396250-Zendesk-QA%EC%97%90%EC%84%9C-%EA%B2%80%ED%86%A0-%EC%8B%9C%EA%B0%84-%EC%B6%94%EC%A0%81%ED%95%98%EA%B8%B0"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/ko/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지에 머무르기"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지에서 나가기"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 페이지에서 나가면 모든 변경 내용을 잃게 됩니다."])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 완료했습니다."])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 만들기"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 완료됨"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 찾을 수 없음"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 검토할 작업이 없습니다."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제를 조정하거나 다음 주기가 시작될 때까지 기다리세요."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터를 조정해 보세요."])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 편집"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 페이지에서 업데이트를 확인하세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 주기에 대한 작업 없음"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 대화 없음"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업을 완료로 표시"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 사용자를 검토했습니다."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 과제에 자가 검토 없음"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 보기의 헤더에 있는 조정 아이콘을 클릭하여 추가합니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 대화 없음"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기한"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 만들기"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 세션 만들기"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 배정"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타 배정"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 배정 없음"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배정 없음"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 검토 대상자"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나를 제외한 모든 검토 대상자"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자를 찾을 수 없음"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자 검색"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거됨"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 상태"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["할 일"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 검토자"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나를 제외한 모든 검토자"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나에게 배정됨"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자를 찾을 수 없음"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 검색"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "일"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "일"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "일"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "일"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "일"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "일"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "시간"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "시간"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "시간"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "시간"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "시간"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "시간"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "분"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "분"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "분"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "분"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "분"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), "분"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 사항 없음"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재배정"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스 사용자"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 검토자"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 주기의 새 목표"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 검토자"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 선택"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 재배정"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 재배정"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동할 대화"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 주기"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 편집"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자를 찾을 수 없음"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자 검색"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나에게 대화 재배정"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 사용자에게 대화 재배정"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개요"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "일"]), _normalize([_interpolate(_named("n")), "일"]), _normalize([_interpolate(_named("n")), "일"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "시간"]), _normalize([_interpolate(_named("n")), "시간"]), _normalize([_interpolate(_named("n")), "시간"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료된 주기 숨기기"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료된 세션 숨기기"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화를 제거하려면 이유를 추가하세요."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화를 제거하는 이유를 설명하세요."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 제거"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용을 유지하시겠어요?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화를 바꾸려면 이유를 추가하세요."])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 버리기"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유지"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 유지"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체를 찾을 수 없음"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대화를 바꾸는 이유를 설명하세요."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그래도 과제에서 이 대화를 제거하시겠어요? 목표가 1 감소합니다."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바꾸기"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 바꾸기"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안 삭제"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안 편집"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나에게 재배정"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 사용자에게 재배정"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재배정 및 검토"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 제거"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 남기기"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간단히 보기"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 보기"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["목표: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), "검토됨"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 완료"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료된 주기 표시"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료된 세션 표시"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남은 시간"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화를 대체함"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 목록 토글"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 후"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "초"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "초"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "일"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "일 ", _interpolate(_named("hours")), "시간"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "일 ", _interpolate(_named("hours")), "시간 ", _interpolate(_named("minutes")), "분"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "일"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "일"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "일 ", _interpolate(_named("minutes")), "분"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "시간"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "시간 ", _interpolate(_named("minutes")), "분"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "시간 ", _interpolate(_named("minutes")), "분 ", _interpolate(_named("seconds")), "초"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "시간 ", _interpolate(_named("seconds")), "초"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "분"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "분 ", _interpolate(_named("seconds")), "초"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "개월"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "초"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "년"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방금"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 없습니다. 도움이 필요하면 관리자에게 문의하세요."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾고 계신 페이지가 존재하지 않습니다."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예기치 않은 오류가 발생했습니다. 지원팀에 문의하여 도움을 받으세요."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["액세스가 거부되었습니다. 해당 작업을 수행할 수 없습니다."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾고 계신 페이지가 존재하지 않거나 이 페이지에 액세스할 수 없습니다."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["속도가 너무 빨라 따라잡는 데 문제가 있습니다."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["없음"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 저장됨"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되지 않은 변경 내용"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["및"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "개 적용됨"]), _normalize([_interpolate(_named("n")), "개 적용됨"]), _normalize([_interpolate(_named("n")), "개 적용됨"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결됨"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 시간"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 사람"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같음"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["이전 구성원"]), _normalize(["이전 구성원"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "개 선택됨"]), _normalize([_interpolate(_named("n")), "개 선택됨"]), _normalize([_interpolate(_named("n")), "개 선택됨"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 업데이트"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... 그 외 1개"]), _normalize(["... 그 외 ", _interpolate(_named("n")), "개"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["그 외 1개"]), _normalize(["그 외 ", _interpolate(_named("n")), "개"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["없음"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음과 같지 않음"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거됨"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["답장 ", _interpolate(_named("n")), "개"]), _normalize(["답장 ", _interpolate(_named("n")), "개"]), _normalize(["답장 ", _interpolate(_named("n")), "개"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["금요일"]), _normalize(["금요일"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["월요일"]), _normalize(["월요일"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["토요일"]), _normalize(["토요일"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["일요일"]), _normalize(["일요일"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["목요일"]), _normalize(["목요일"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["화요일"]), _normalize(["화요일"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["수요일"]), _normalize(["수요일"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어제"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["관리자"]), _normalize(["관리자"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["계정 관리자"]), _normalize(["계정 관리자"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["사용자"]), _normalize(["사용자"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사됨"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 알아보기"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션이 만료되었습니다."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 비워 둘 수 없습니다."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾은 항목이 없습니다."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일치하는 사람이 없습니다."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["소유자"]), _normalize(["소유자"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 알아보기"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 알아보기"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 알아보기"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작하기 전에 약관을 읽고 동의하면 사용하실 수 있습니다."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보냄"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확실한가요?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 검토 완료"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트됨"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["상담사"]), _normalize(["상담사"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["리드"]), _normalize(["리드"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["관리자"]), _normalize(["관리자"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토자"]), _normalize(["검토자"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["익명 사용자"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담당자"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뒤로"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곧"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사하려면 클릭하세요."])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크 복사"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만들기"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위험 영역"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명(선택 사항)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인 필요"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 안 함"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되지 않은 변경 내용을 버리시겠어요?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복제"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내보내기"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빈도"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안 함"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합격 비율"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 설정"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간단히 표시"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제거"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 보내기"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복원"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토/확인한 검토"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 구성원이 수행한 검토"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["역할"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 내용 저장"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이진 척도"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3점 척도"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4점 척도"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5점 척도"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빈도 선택"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간단히 보기"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 보기"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["건너뛰기"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강조 표시"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사 피드백"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 범위"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되지 않은 변경 내용"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가중치"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님이 Zendesk QA에 참여하도록 초대했습니다."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["사용자는 관리 센터에서 자동으로 연결됩니다. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 관리"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 관리에 대해 알아보기"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["일부 사용자는 ID 제공업체로부터 자동으로 프로비저닝 및 동기화됩니다. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "'의 계정 권한이 변경되었습니다."])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 권한에 대해 알아보기"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 묻지 않기"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["사용자 페이지에서 수행된 모든 변경 내용이 워크스페이스에 반영됩니다. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉, Zendesk QA의 특정 부분에 대한 사용자의 액세스 권한이 변경될 수 있습니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 수준 권한 변경"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["사용자 ", _interpolate(_named("n")), "명 제거됨"]), _normalize(["사용자 ", _interpolate(_named("n")), "명 제거됨"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["사용자를 제거하면 계정에 액세스할 수 없게 됩니다. 고객의 검토, 댓글 및 평점은 Zendesk QA에 계속 남게 됩니다. ", _interpolate(_named("linebreak")), "사용자를 제거해도 플랜의 사용자 단위 라이선스 수에는 영향을 미치지 않습니다."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["이 사용자는 관리하는 모든 워크스페이스에서 제거됩니다. ", _interpolate(_named("linebreak")), " 해당 검토, 댓글 및 평점은 그대로 유지됩니다."]), _normalize(["관리하는 모든 워크스페이스에서 제거됩니다. ", _interpolate(_named("linebreak")), " 모든 검토, 댓글 및 평점은 그대로 유지됩니다."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["사용자 ", _interpolate(_named("name")), "명 제거"]), _normalize(["사용자 ", _interpolate(_named("name")), "명 제거"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 항목을 보고 모든 설정 및 청구를 관리할 수 있음"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구를 제외한 모든 항목을 보고 개인 및 워크스페이스 설정을 관리할 수 있음"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 권한"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스별 권한을 통해 사용자는 자신이 있는 워크스페이스 내에서 데이터를 보고 편집할 수 있습니다."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 QA에 대해 알아보기"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇이 참여한 대화가 동기화되므로 봇이 여기에 나타납니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동기화된 봇 없음"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇을 사용자로 표시하면 로그인 액세스 권한이 부여됩니다. 이들의 기록 봇 데이터는 대시보드에서 계속 사용할 수 있습니다."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 봇을 찾을 수 없습니다."])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 키워드나 철자를 사용하여 다시 검색해 보세요."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 검색"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 이름으로 검색"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자로 표시"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자와 계정 관리자만 봇을 사용자로 표시할 수 있습니다."])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇 이름"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 채팅"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 표시됨"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 가능"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아니요"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇이 자동 분석 및 모든 배정에서 제거됩니다. 새 데이터가 대시보드에 나타나지 않으므로 정확한 리포팅과 더 쉬운 봇 관리가 보장됩니다."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성형 봇"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알 수 없음"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크플로우 봇"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹에 추가"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 그룹"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 없음"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹에서 제거"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 선택"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 권한"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 추가"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 이름"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 활성"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 수준 역할"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 사용자에게 계정 또는 워크스페이스 수준 권한이 배정됩니다. 관리자 권한은 현재 계정 수준이지만 다른 권한은 워크스페이스 수준입니다."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에서 제거"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 권한"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹에서 관리되는 권한"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부 정보 편집"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇으로 표시"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 봇으로 표시하면 로그인 액세스 권한이 제거됩니다. 이전 사용자 데이터는 대시보드에서 계속 사용할 수 있습니다."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자동 분석 및 모든 배정에서 봇을 제거합니다. 새 데이터가 대시보드에 나타나지 않으므로 정확한 리포팅과 더 쉬운 봇 관리가 보장됩니다."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토에서 봇 제외"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자가 봇으로 표시됨"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 유형 사용자만 봇으로 표시할 수 있습니다."])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러 계정을 가진 사용자는 봇으로 표시될 수 없습니다"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자별 검색"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["봇"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자, 봇 및 워크스페이스"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 및 워크스페이스"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 없음"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결됨"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 추가됨"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류 중인 초대"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 사용자"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조치 필요"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...그 외 1개"]), _normalize(["...그 외 ", _interpolate(_named("n")), "개"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한을 다음으로 변경"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["보류 중인 초대 사용자 ", _interpolate(_named("n")), "명의 역할을 변경하려면 제거하고 다시 초대합니다."]), _normalize(["보류 중인 초대 사용자 ", _interpolate(_named("n")), "명의 역할을 변경하려면 제거하고 다시 초대합니다."])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["선택한 사용자 ", _interpolate(_named("name")), "에 대한 세부 정보 편집"]), _normalize(["선택한 사용자 ", _interpolate(_named("n")), "에 대한 세부 정보 편집"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["사용자 ", _interpolate(_named("n")), "명이 업데이트되었습니다."]), _normalize(["사용자 ", _interpolate(_named("n")), "명이 업데이트되었습니다."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["이 그룹에는 다른 워크스페이스의 사용자 1명이 포함되어 있습니다."]), _normalize(["이 그룹에는 다른 워크스페이스의 사용자 ", _interpolate(_named("n")), "명이 포함되어 있습니다."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹을 보관함"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관된 그룹"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 만들기"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹을 만듦"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 없는 그룹"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 그룹을 영구 삭제하시겠어요?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 삭제"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 삭제"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 편집"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 리드"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 역할"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹 숨김"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 그룹은 Zendesk QA의 다른 곳에서는 보이지 않습니다."])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 권한만 관리"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA에 그룹이 표시되지 않음"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리드"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 없는 그룹"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 그룹에 이미 이 이름이 있습니다."])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관된 그룹에 이미 이 이름이 있습니다."])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 역할은 이 그룹의 구성원만 관리할 수 있습니다. 추가 변경에 대해서는 더 높은 권한을 가진 사용자에게 문의하세요."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 리드에서 제거"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹을 복원함"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹을 저장함"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 리드로 설정"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹을 보관 취소함"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장되지 않은 변경 내용"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 그룹에 기존 사용자 추가"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름 또는 이메일로 검색"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원 추가"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' 그룹 표시"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["구성원: ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹에 대해 알아보기"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹의 일부 속성은 ID 제공업체로부터 자동으로 프로비저닝 및 동기화됩니다."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹에 대해 알아보기"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 만들기"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쉽게 팀원을 구성하고 조직 구조를 모방하세요."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1명 이상의 구성원 추가"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 이름 추가"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 이름의 그룹이 이미 있습니다."])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 구성원"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 이름"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팀원을 구성하고 조직 구조를 모방하세요."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["그룹 없음"]), _normalize(["그룹 1개"]), _normalize(["그룹 ", _interpolate(_named("count")), "개"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 그룹을 만들거나 대신 보관된 그룹을 활성화하세요."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 활성 그룹 없음"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관된 그룹 없음"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 그룹을 찾을 수 없음"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 키워드나 철자를 사용하여 다시 검색해 보세요."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가된 그룹 없음"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["다음 그룹"]), _normalize(["다음 그룹"]), _normalize(["다음 그룹"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹 이름으로 검색"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관됨"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 다시 보낼 수 있음"])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["사용자 단위 라이선스 ", _interpolate(_named("n")), "개 남음"]), _normalize(["사용자 단위 라이선스 ", _interpolate(_named("n")), "개 남음"]), _normalize(["사용자 단위 라이선스 ", _interpolate(_named("n")), "개 남음"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜이 일시 중지되었으므로 현재 사용자 단위 라이선스 수가 제한되어 있습니다. 사용자를 더 추가하려면 다시 활성화하세요."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 사용자 단위 라이선스 한도에 도달했습니다. 활성 사용자를 비활성화하거나 사용자 단위 라이선스를 더 추가하여 사용자를 바꿀 수 있습니다."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 세부 정보 보기"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "개 선택됨"]), _normalize([_interpolate(_named("n")), "개 선택됨"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "명의 사용자 선택됨"]), _normalize([_interpolate(_named("n")), "명의 사용자 선택됨"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "개 로우"]), _normalize([_interpolate(_named("n")), "개 로우"]), _normalize([_interpolate(_named("n")), "개 로우"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름이나 이메일 주소별로 사용자를 일괄 검색합니다."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쉼표나 줄 바꿈으로 구분하여 여기에 데이터를 붙여넣으세요."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 일괄 검색"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), "에 대한 워크스페이스 권한 편집"]), _normalize(["선택한 구성원 ", _interpolate(_named("n")), "명에 대한 워크스페이스 권한 편집"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 편집"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 권한 선택"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["워크스페이스에서 ", _interpolate(_named("name")), " 제거"]), _normalize(["워크스페이스에서 구성원 ", _interpolate(_named("name")), "명 제거"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["구성원 ", _interpolate(_named("n")), "명 제거됨"]), _normalize(["구성원 ", _interpolate(_named("n")), "명 제거됨"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["구성원 제거"]), _normalize(["구성원 제거"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["이 사용자는 워크스페이스에서 제거됩니다."]), _normalize(["이러한 사용자는 워크스페이스에서 제거됩니다."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "'의 워크스페이스 권한이 변경되었습니다."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원 추가"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 및 관련 검토를 봅니다."])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스의 모든 항목을 볼 수 있지만 퀴즈, 과제, 이의 신청 및 조정 세션만 관리합니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 권한"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 내에서 모든 항목을 보고 관리합니다."])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 설정을 제외한 워크스페이스의 모든 항목을 봅니다."])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 연결"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리하는 워크스페이스의 모든 사용자가 이 워크스페이스에 추가되었습니다."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자에게 요청하세요."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접 신규 사용자를 초대하거나 관리자에게 요청하세요."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " 또는 ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 사용자 없음"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" 사용자 ", _interpolate(_named("n")), "명 추가됨"]), _normalize(["사용자 ", _interpolate(_named("n")), "명 추가됨"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["구성원 추가"]), _normalize(["구성원 추가"]), _normalize(["구성원 ", _interpolate(_named("n")), "명 추가"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 권한 선택"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 대한 사용자 선택"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "에 구성원 추가"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["줄 바꿈이나 쉼표로 구분하여 여기에 이메일을 붙여넣으세요."])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "개 이메일 일치"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 이미 연결된 사용자 포함:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), "개 이메일을 일치시킬 수 없음"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맞춤법을 검사하거나 나중에 개별적으로 연결하세요."])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 헬프 데스크 사용자 포함:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "명의 사용자 연결됨"]), _normalize([_interpolate(_named("n")), "명의 사용자 연결됨"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "명의 사용자 초대됨"]), _normalize([_interpolate(_named("n")), "명의 사용자 초대됨"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일괄 연결"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일괄 초대"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크에서 연결하여 상호작용을 검토합니다."])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["사용자 연결"]), _normalize(["사용자 연결"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개별적으로 연결"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결된 사용자들이 Zendesk QA에 액세스할 수 있도록 하려면 여기에서 초대하면 됩니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결된 사용자 초대"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["사용자 초대"]), _normalize(["사용자 초대"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대 건너뛰기"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크에서 연결할 사용자를 선택하여 상호작용을 검토하세요."])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플랜 관리"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 사용자 연결"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 고유한 이름을 지정하세요."])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 설정하면 모든 기존 계정 연결이 자동으로 새 워크스페이스에 추가됩니다."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스에 모든 연결 추가"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 만들기"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 이름"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 만들기"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 배정하지 않고 계정에 직접 추가된 사용자에게는 계정 수준 역할이 배정됩니다. 나중에 워크스페이스에 추가할 수 있습니다."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대된 사용자에게는 계정 수준의 사용자 역할이 자동으로 배정됩니다. 나중에 관리자 역할로 업그레이드할 수 있습니다."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 모두 가능합니다."])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["줄 바꿈이나 쉼표로 구분하여 여기에 이메일을 붙여넣으세요."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["이메일로 ", _interpolate(_named("n")), "명의 사용자 초대됨"]), _normalize(["이메일로 ", _interpolate(_named("n")), "명의 사용자 초대됨"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 추가된 사용자에게는 계정 수준의 사용자 역할이 자동으로 배정됩니다. 나중에 관리자 역할로 업그레이드할 수 있습니다."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 항목 추가"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 워크스페이스에 추가하지 않고 계정에만 초대하세요."])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대된 사용자는 계정 권한만 배정되며 어떤 워크스페이스에도 추가되지 않습니다."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 워크스페이스에 직접 초대하고 워크스페이스 권한을 배정하세요."])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 사용자 추가"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일괄 초대"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개별적으로 초대"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정에만 초대"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 초대"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["사용자 초대"]), _normalize(["사용자 초대"]), _normalize([_interpolate(_named("n")), "명의 사용자 초대"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 통해 사용자 일괄 초대"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["사용자 삭제"]), _normalize(["사용자 삭제"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 취소"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 통해 사용자 초대"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 없음"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한 선택"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대 다시 보내기"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결된 사용자"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류 중인 이메일 초대 사용자"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 사용자"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스 선택"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 상담사"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리 센터"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["품질 보증"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인적 자원 관리"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "개 완료됨"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "개 완료됨"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "개 완료됨"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["주기 ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "~", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토자"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과제 편집"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수동으로 대화를 찾아서 검토할 대화를 배정하는 대신 시간을 절약하고 자동 검토 과제를 설정하세요."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " 기한"])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 보기의 사이드 메뉴에 있는 '조정' 섹션에서 세션을 추가할 수 있습니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 추가된 세션 없음"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션 없음"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화 보기의 헤더에 있는 조정 아이콘을 클릭하여 세션에 대화를 추가할 수 있습니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 추가된 대화 없음"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["제공된 검토가 없습니다. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정 검토 남기기"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["근본 원인"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음으로 이동"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명령 팔레트를 로드하지 못했습니다."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 결과 없음"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID로 찾음"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명령 팔레트 로드 중…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지, 필터, 대화, 설정…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대화"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개 필터"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공개 필터"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "에서"])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API에서"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("label")), " ", _interpolate(_named("total")), "개 중에서 ", _interpolate(_named("rangeStart")), "~", _interpolate(_named("rangeEnd")), "번째 표시 중"]), _normalize([_interpolate(_named("label")), " ", _interpolate(_named("total")), "개 중에서 ", _interpolate(_named("rangeStart")), "~", _interpolate(_named("rangeEnd")), "번째 표시 중"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이드 패널 숨기기"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이드 패널 표시"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사이드바 토글"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 수락됨"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 거부됨"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청 등록"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청이 부분적으로 수락됨"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 완료"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인한 검토"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락됨"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록됨"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부분 수락됨"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/총계"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부됨"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락됨"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록됨"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부분 수락됨"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부됨"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["검토"]), _normalize(["검토"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토 대상자가 확인한 검토 수"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인한 검토 수"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청된 검토자"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이의 신청자"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리별 이의 신청"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 경과에 따른 이의 신청"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록됨"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 해결 시간"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 해결 시간(시간)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 해결 시간(분)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결됨"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 이의 신청 수"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 선택"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총계"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락됨"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 언어"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 언어"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예측 동인"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동인을 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 동인"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어 구름 단어"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단어를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 단어"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멘션"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신순"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오래된 순"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록됨"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부분 수락됨"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "님이 반응함 ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부됨"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해결됨"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검토"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피부색 선택"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세션을 찾을 수 없음"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리를 찾을 수 없음"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 크기"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["크기를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 크기"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터를 찾을 수 없음"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그룹을 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 그룹"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 해시태그"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값을 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 값"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 지정 필드를 찾을 수 없음"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬프 데스크 태그를 찾을 수 없습니다."])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 헬프 데스크 태그"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과 로드 중..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문 유형"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문 유형을 찾을 수 없음"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반응 추가"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스코어카드를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 스코어카드"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 점수"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 소스"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드백 이유"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이유를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 이유"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문조사를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 설문조사"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채널을 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 채널"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 사용자"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스를 찾을 수 없음"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 워크스페이스"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈로 돌아가기"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈가 삭제되었거나 아직 게시되지 않았습니다. 링크를 다시 확인하거나 퀴즈 목록으로 돌아가세요."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 퀴즈는 내가 속한 워크스페이스에 공유되지 않았습니다. 관리자에게 이 퀴즈가 액세스할 수 있는 워크스페이스에 본인을 추가해 달라고 요청하세요."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈를 사용할 수 없음"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["속하지 않은 워크스페이스에 대한 퀴즈 액세스 권한이 부여되었습니다. 퀴즈를 저장하고 게시할 수 없습니다."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리자에게 퀴즈를 저장하고 게시하도록 요청하세요."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변 중 하나 이상을 올바른 답변으로 표시해야 합니다."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 비워 둘 수 없습니다."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 퀴즈 미리 보기를 보고 계십니다."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈에 액세스할 수 있는 워크스페이스를 선택합니다. 여러 워크스페이스에 속한 사용자는 퀴즈에 한 번만 답변할 수 있습니다. 퀴즈가 게시된 후에는 '모든 워크스페이스'를 선택하면 새로 만든 워크스페이스가 포함되지 않습니다."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 액세스"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복제"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기 모드 종료"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내보내기"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리 보기"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 풀기"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 게시"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 설정"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안으로 저장"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 퀴즈 만들기"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관 취소"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 추가"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["멋지죠?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변이 제출되었습니다."])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈가 저장되었습니다."])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈가 게시되었습니다."])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈가 제거되었습니다."])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문 추가"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 보관"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관된 퀴즈와 그 결과는 더 이상 퀴즈 목록에 나타나지 않습니다. 보관된 퀴즈의 결과에 계속 액세스하고 필요할 때마다 보관 취소할 수 있습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 보관"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시된 후에는 퀴즈를 변경하거나 편집할 수 없습니다. 계정의 모든 사용자가 게시된 모든 퀴즈에 액세스할 수 있습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈를 게시하시겠습니까?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 퀴즈를 삭제하시겠습니까? 이 작업은 실행 취소할 수 없습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈를 삭제하시겠어요?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출된 답변은 변경할 수 없습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈를 제출하시겠어요?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 보관 취소"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 보관을 취소하면 퀴즈 개요에서 사용할 수 있습니다. 이를 통해 이전 참여자는 자신의 결과에 액세스할 수 있고, 신규 참여자는 퀴즈를 풀 수 있습니다."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 보관 취소"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복 질문"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명(선택 사항)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문을 아래로 이동"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문을 위로 이동"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변이 있는 질문만 미리 보기에 표시됩니다."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문 제거"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 제출"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목 없는 퀴즈"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 제목"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈와 해당 결과가 영구 삭제됩니다."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 퀴즈를 보관하여 결과에 액세스할 수 있습니다."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변함"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 삭제"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈와 해당 결과가 영구 삭제됩니다."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["퀴즈 ", _interpolate(_list(0)), " 삭제"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복제"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈는 팀의 자신감과 스킬을 강화하고, 일관된 경험을 제공하며, 새 팀원을 온보딩하는 데 도움이 됩니다."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 이름이나 작성자 이름으로 퀴즈를 검색할 수 있습니다."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 만든 퀴즈가 없음"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 목록 내보내기"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 점수"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료 날짜"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈/작성자"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈 / 작성자 / 워크스페이스"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시됨"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈, 작성자별 검색"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보관됨"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초안"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 퀴즈"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈가 보관되었습니다."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈가 보관 취소되었습니다."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 워크스페이스에 있는 사용자로부터 이 퀴즈에 대한 응답을 받지 못했습니다."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 퀴즈는 보관되기 전에 응답을 받지 못했습니다."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답 없음"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에서 퀴즈 답변 내역을 볼 수 있습니다.\n아직 아무도 퀴즈를 작성하지 않았습니다. 공유해 주시면 도움이 될 것입니다."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 응답 없음"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["응답:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 점수:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개요"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - 복사"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크가 복사되었습니다."])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 결과 없음"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "의 퀴즈"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리더보드에서 참여자의 이름을 검색할 수 있습니다."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리더보드 내보내기"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점수"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참여자별 검색"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리더보드"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퀴즈"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리더보드 토글"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉, 대시보드에서 이 해시태그를 필터링할 수 없으며 더 이상 댓글에서 자동 완성되지 않습니다. 이 작업은 되돌릴 수 없습니다."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그, 해시태그, 해시태그, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 만들기"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 만들기"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 만들기"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구성원이 새 해시태그를 만들 수 있도록 허용"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용됨"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 삭제"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["해시태그 ", _interpolate(_list(0)), " 삭제"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 만들기가 잠겼습니다."])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 만들기가 잠금 해제되었습니다."])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "' 해시태그를 삭제했습니다."])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["해시태그 '", _interpolate(_list(0)), "'의 이름이 '", _interpolate(_list(1)), "'(으)로 변경되었습니다."])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["해시태그가 1개 추가되었습니다."]), _normalize(["해시태그가 ", _interpolate(_named("count")), "개 추가되었습니다."])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 이름"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만든 해시태그 없음"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름 바꾸기"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["해시태그 '", _interpolate(_named("tagRenamed")), "' 이름 바꾸기"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쉼표나 줄 바꿈으로 해시태그를 구분하세요."])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해시태그 검색"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["걱정 마세요. ", _interpolate(_named("link_copy")), " 수행할 수 있습니다."])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에서"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워크스페이스에 대한 연결"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 수립"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 워크스페이스에 연결 수립"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에서 변경하려면 관리자에게 문의하세요."])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에서 변경하려면 Zendesk QA에 문의하세요."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 아래에 연결 없음"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 고객 서비스 플랫폼과 연동하지 않으신 것 같습니다."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["연결을 삭제하면 워크스페이스 ", _interpolate(_named("workspaceName")), "에서 대화를 제거하고 검토 및 이의 신청을 포함하여 관련된 모든 데이터를 삭제합니다."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 삭제"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connectionName")), " 연결 삭제"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 선택"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["선택한 기간 내에 특정 활성 자동 QA 카테고리가 스코어카드에 없었습니다. ", _interpolate(_named("date")), "에 적용한 마지막 변경사항 이전의 대화는 이러한 카테고리에 대해 분석되지 않았을 수 있습니다."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에 제공된 모든 피드백이 표시됩니다. 아직 표시할 내용 없음"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토가 없습니다."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["댓글 활동만 표시"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자가 제공한 모든 피드백이 여기에 표시됩니다. 대화 보기에서 시작하세요."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 검토가 없습니다."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제공된 검토"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 검토"])}
  }
}