import {
  type TemplateFilter_TemplateFilterTypeCode as TemplateFilterType,
  TemplateFilterCondition_TemplateFilterConditionCode as TemplateFilterConditionCode,
  type TemplateFilter,
  type CustomCategoryTemplateCondition,
} from '@zendesk/zqa-services/autoqa'

import i18n from '@/i18n'
import type { DeconstructedOption, Value } from '@/modules/conversations/utils/filters'
import { type Filter, type ConversationFilterType } from './types'

export const mapCondition = (condition: TemplateFilter['conditions'][number]) => ({
  name: condition.condition,
  label: i18n.t(condition.labelCode),
  valueRequired: true,
})

export const mapFilter = (condition: TemplateFilter): Filter => ({
  id: condition.type,
  label: i18n.t(condition.label),
  showAddSimilarButton: false,
  description: i18n.t(`filter.option.description.${condition.type.toLowerCase()}`),
  conditions: condition.conditions.map(mapCondition),
  values: condition.values,
})

export const mapFilterConditions = (filterItem: TemplateFilter) => {
  if (filterValueAsCondition.includes(filterItem.type)) {
    return filterItem.values.map((item) => ({
      condition: item.value,
      labelCode: 'filter.condition.label.' + item.value.toLowerCase().replace(/ /g, '_'),
      valueRequired: false,
    }))
  }

  return filterItem.conditions.map((c) => {
    return {
      ...c,
      valueRequired: true,
    }
  })
}

export const mapFilters = (filters: TemplateFilter[]): ConversationFilterType[] => {
  return (
    filters?.map((filter) => ({
      ...filter,
      name: filter.type,
      labelCode: i18n.t(filter.label),
      filterOption: filter.type,
      description: '',
      validSourceType: [],
      conditions: mapFilterConditions(filter),
    })) || []
  )
}

export const updateConditions = (filters: DeconstructedOption[]) => {
  return filters.map((filter) => {
    const condition: TemplateFilterConditionCode = filterValueAsCondition.includes(filter.option.name)
      ? TemplateFilterConditionCode.STRING_LIST_EQUALS
      : (filter.condition.name as TemplateFilterConditionCode)
    const values = filterValueAsCondition.includes(filter.option.name)
      ? [{ value: filter.condition.name }]
      : Array.isArray(filter.values) &&
        filter.values.map((val: string | Value) => {
          const value = typeof val === 'string' ? val : val.value
          return { value }
        })

    return {
      type: filter.option.name as TemplateFilterType,
      condition,
      values,
    }
  })
}

const conditionMap = {
  [TemplateFilterConditionCode.STRING_LIST_EQUALS]: TemplateFilterConditionCode.STRING_EQUALS,
  [TemplateFilterConditionCode.STRING_LIST_NOT_EQUALS]: TemplateFilterConditionCode.STRING_NOT_EQUALS,
  [TemplateFilterConditionCode.STRING_LIST_CONTAINS]: TemplateFilterConditionCode.STRING_CONTAINS,
  [TemplateFilterConditionCode.STRING_LIST_NOT_CONTAINS]: TemplateFilterConditionCode.STRING_NOT_CONTAINS,
  [TemplateFilterConditionCode.ALL_STRINGS_EQUAL]: TemplateFilterConditionCode.STRING_EQUALS,
  [TemplateFilterConditionCode.ALL_STRINGS_NOT_EQUAL]: TemplateFilterConditionCode.STRING_NOT_EQUALS,
  [TemplateFilterConditionCode.ALL_STRINGS_CONTAIN]: TemplateFilterConditionCode.STRING_CONTAINS,
  [TemplateFilterConditionCode.ALL_STRINGS_NOT_CONTAIN]: TemplateFilterConditionCode.STRING_NOT_CONTAINS,
  [TemplateFilterConditionCode.STRING_EQUALS]: TemplateFilterConditionCode.STRING_LIST_EQUALS,
  [TemplateFilterConditionCode.STRING_NOT_EQUALS]: TemplateFilterConditionCode.STRING_LIST_NOT_EQUALS,
  [TemplateFilterConditionCode.STRING_CONTAINS]: TemplateFilterConditionCode.STRING_LIST_CONTAINS,
  [TemplateFilterConditionCode.STRING_NOT_CONTAINS]: TemplateFilterConditionCode.STRING_LIST_NOT_CONTAINS,
}

export const addCustomConditionMessagePhrase = (selectedCondition: CustomCategoryTemplateCondition) => {
  const newPhrase = { value: '' }

  const condition =
    selectedCondition.values.length === 1 ? conditionMap[selectedCondition.condition] : selectedCondition.condition

  return {
    condition,
    type: selectedCondition.type,
    values: [...selectedCondition.values, newPhrase],
  }
}

export const removeCustomConditionMessagePhrase = (
  selectedCondition: CustomCategoryTemplateCondition,
  index: number,
) => {
  const condition =
    selectedCondition.values.length === 2 ? conditionMap[selectedCondition.condition] : selectedCondition.condition

  selectedCondition.values.splice(index, 1)

  return {
    condition,
    type: selectedCondition.type,
    values: selectedCondition.values,
  }
}
export const mapErrors = (messageConditions: CustomCategoryTemplateCondition[]) => {
  const allValues = messageConditions.map((c) => c.values.map((v) => v.value)).flat()

  const hasErrors = allValues.some((v) => !v || v.length <= 0 || v.length > 300)
  const posConditionPhrases = messageConditions?.[0]?.values?.map((v) => v.value.toLowerCase()) || []
  const negConditionPhrases = messageConditions?.[1]?.values?.map((v) => v.value.toLowerCase()) || []
  const hasDuplicates = posConditionPhrases.some((v) => negConditionPhrases.includes(v))

  if (!hasErrors && !hasDuplicates) return { ratingFilters: {}, empty: false, conflict: false }

  let messageType: TemplateFilterType
  let empty = false
  let conflict = false

  const mappedErrors = messageConditions.reduce((acc, c, conditionIndex) => {
    messageType = c.type
    // since there is max 2 conditions, we can get the opposite index by subtracting the current index from 1
    const oppositeIndex = 1 - conditionIndex

    const phraseErrors = c.values.reduce((accErr, v, i) => {
      if (!v.value || v.value.length <= 0) {
        empty = true
        accErr[i] = { error: i18n.t('settings.categories.create.filters.agent_message.errors.empty'), type: 'empty' }
        return accErr
      }

      if (v.value.length > 300) {
        accErr[i] = {
          error: i18n.t('settings.categories.create.filters.agent_message.errors.too_long'),
          type: 'too_long',
        }
        return accErr
      }
      const oppositeValues = messageConditions?.[oppositeIndex]?.values.map((oppositeV) =>
        oppositeV.value.toLowerCase(),
      )
      const hasDuplicatesInOpposite = oppositeValues?.includes(v.value.toLowerCase())

      if (hasDuplicatesInOpposite) {
        conflict = true
        accErr[i] = { error: i18n.t('settings.auto_qa.conflicting_error_msg'), type: 'conflicting' }
        return accErr
      }

      return accErr
    }, {})

    acc[c.condition] = phraseErrors
    return acc
  }, {})

  return { ratingFilters: { [messageType]: mappedErrors }, empty, conflict }
}

// For some AutoQA filter types we need to treat the value as a condition
// and here we define which filter types those are
export const filterValueAsCondition = ['TICKET_SENTIMENT', 'TICKET_ESCALATION']

export const POSITIVE_FILTER_CONDITIONS_SINGLE = ['STRING_EQUALS', 'STRING_CONTAINS']

export const POSITIVE_FILTER_CONDITIONS_MULTIPLE = [
  'STRING_LIST_EQUALS',
  'ALL_STRINGS_EQUAL',
  'STRING_LIST_CONTAINS',
  'ALL_STRINGS_CONTAIN',
]

export const NEGATIVE_FILTER_CONDITIONS_SINGLE = ['STRING_NOT_EQUALS', 'STRING_NOT_CONTAINS']

export const NEGATIVE_FILTER_CONDITIONS_MULTIPLE = [
  'STRING_LIST_NOT_EQUALS',
  'ALL_STRINGS_NOT_EQUAL',
  'STRING_LIST_NOT_CONTAINS',
  'ALL_STRINGS_NOT_CONTAIN',
]
