import { partition, sortBy } from 'lodash-es'
import { AgentDistributionCategory, TicketDistributionCategory } from '@/modules/conversations/types'

export const sortMap = {
  [TicketDistributionCategory.Csat]: ['5', '4', '3', '2', '1', '', 'unoffered', 'offered'],
  [TicketDistributionCategory.Complexity]: ['true', 'false'],
  [TicketDistributionCategory.Sentiment]: ['POSITIVE', 'NEGATIVE', ''],
  [TicketDistributionCategory.Reviewed]: ['true', 'false'],
  [AgentDistributionCategory.Performance]: [
    'superstar',
    'high performer',
    'good',
    'lowperformer',
    'sampler',
    'onemessage',
    '',
  ],
  [AgentDistributionCategory.Csat]: [
    'High satisfaction score',
    'Medium satisfaction score',
    'Low satisfaction score',
    'no csat',
    '',
  ],
  [AgentDistributionCategory.Sentiment]: [
    'High positive sentiment',
    'Positive ~ Negative sentiment',
    'High negative sentiment',
    'no sentiment',
    '',
  ],
  [AgentDistributionCategory.Reviewed]: ['true', 'false'],
}

const valuesAtTheBottomOfList = ['', '-']

export default (values, category) => {
  if (!values) return
  const order = sortMap[category]

  if (order) {
    return values.sort((a, b) => {
      const aOrder = order.indexOf(a.value)
      const bOrder = order.indexOf(b.value)
      return (aOrder === -1 ? 10000 : aOrder) - (bOrder === -1 ? 10000 : bOrder)
    })
  }

  const [sortedValues, naValues] = partition(sortBy(values, 'value'), (s) => !valuesAtTheBottomOfList.includes(s.value))
  return [...sortedValues, ...naValues]
}
